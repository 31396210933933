import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import * as React from "react";
import { AllRoutePairsSelect } from "../../../../../../common/components/data-driven-inputs/selects/all-route-pair-select/AllRoutePairsSelect";
import { VehicleTypeSelect } from "../../../../../../common/components/data-driven-inputs/selects/vehicle-type-select/VehicleTypeSelect";
import {
  allTypesLabel,
  allTypesValue,
  filterRowOptionsInitialState,
} from "../../constants/constants";
import { AgrArticleDistanceTypesSelect } from "./AgrArticleDistanceTypesSelect";
import { AgrArticleLengthRange } from "./AgrArticleLengthRange";
import { AgrArticleSelect } from "./AgrArticleSelect";
import { FilterRowOptions } from "./helpers/FilteredOptions";

interface Props {
  filterOptions: FilterRowOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterRowOptions>>;
}

export const AgreementArticlesFilterRow: React.FC<Props> = ({
  filterOptions,
  setFilterOptions,
}) => {
  return (
    <Row alignItems={"center"}>
      <Heading variant="h5">Filter by:</Heading>
      <Space />
      <Box width={"190px"}>
        <AgrArticleDistanceTypesSelect
          onValueChange={(distanceTypeCode) =>
            setFilterOptions({ ...filterOptions, distanceTypeCode })
          }
          isClearable
          value={filterOptions.distanceTypeCode}
        />
      </Box>
      <Space num={SpaceValues.EIGHT} />
      <Box width={"120px"}>
        <AgrArticleSelect
          onValueChange={(articleCode) =>
            setFilterOptions({ ...filterOptions, articleCode })
          }
          isClearable
          value={filterOptions.articleCode}
        />
      </Box>
      <Space num={SpaceValues.EIGHT} />
      <Box width={"200px"}>
        <VehicleTypeSelect
          onValueChange={(vehicleTypeCode) =>
            setFilterOptions({ ...filterOptions, vehicleTypeCode })
          }
          isClearable
          value={filterOptions.vehicleTypeCode}
          allTypesLabel={allTypesLabel}
          allTypesValue={allTypesValue}
        />
      </Box>
      <Space num={SpaceValues.EIGHT} />
      <Box width={"150px"}>
        <AllRoutePairsSelect
          onValueChange={(routePairCode) =>
            setFilterOptions({ ...filterOptions, routePairCode })
          }
          isClearable
          value={filterOptions.routePairCode}
        />
      </Box>
      <Space num={SpaceValues.EIGHT} />
      <AgrArticleLengthRange
        fromValue={filterOptions.lengthFrom}
        toValue={filterOptions.lengthTo}
        onValueChangeFromValue={(lengthFrom) =>
          setFilterOptions({ ...filterOptions, lengthFrom })
        }
        onValueChangeToValue={(lengthTo) =>
          setFilterOptions({ ...filterOptions, lengthTo })
        }
      />
      <Space num={SpaceValues.EIGHT} />
      <FlatButton
        label={"Clear all"}
        onClick={() => setFilterOptions(filterRowOptionsInitialState)}
      />
    </Row>
  );
};
