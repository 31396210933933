import { formatPercentWithSign } from "@/common/numbers";
import { Indent, Txt } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import {
  getHighlightedPositiveNegativeBgColor,
  getHighlightedPositiveNegativeTextColor,
} from "../../../../../../../common/components/table/HighlightedPositiveNegativeCell";
import { BulkEditRatesStatTableData } from "../models/BulkEditRatesStatBoxData";
import { SailingTypeCode } from "@/gql/graphql";

export type BulkEditRatesRouteImpactTableColumn =
  | "routePairCode"
  | "peakTargetAcc"
  | "peakActualAcc"
  | "peakTargetUnacc"
  | "peakActualUnacc"
  | "shoulderTargetAcc"
  | "shoulderActualAcc"
  | "shoulderTargetUnacc"
  | "shoulderActualUnacc"
  | "offPeakTargetAcc"
  | "offPeakActualAcc"
  | "offPeakTargetUnacc"
  | "offPeakActualUnacc";

export const createBulkEditRatesRouteImpactTableConfig =
  (): StandardTableConfigWithGroups<
    BulkEditRatesStatTableData,
    BulkEditRatesRouteImpactTableColumn
  > => {
    return {
      keyResolver: (item) => item.routePairCode,
      disableSorting: true,
      rowIndent: 1,
      columns: {
        routePairCode: createColumnConfig(
          (item) => item.peakData.accompanied.routePairCode,
          {
            columnLabel: "Route",
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt>
                  {item.peakData.accompanied.routePairCode ||
                    item.peakData.unaccompanied.routePairCode}
                </Txt>
              </Indent>
            ),
          }
        ),
        peakTargetAcc: createColumnConfig(
          (item) => item.peakData.accompanied.target,
          {
            columnLabel: "Target",
            itemLabelFormatter: (value, item) => {
              return item.peakData.accompanied.accompanied &&
                item.peakData.accompanied.sailingTypeCode ===
                  SailingTypeCode.P &&
                item.peakData.accompanied.target === null
                ? ""
                : item.peakData.accompanied.accompanied &&
                  item.peakData.accompanied.sailingTypeCode ===
                    SailingTypeCode.P &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
          }
        ),
        peakTargetUnacc: createColumnConfig(
          (item) => item.peakData.unaccompanied.target,
          {
            itemLabelFormatter: (value, item) => {
              return !item.peakData.unaccompanied.accompanied &&
                item.peakData.unaccompanied.sailingTypeCode ===
                  SailingTypeCode.P &&
                item.peakData.unaccompanied.target === null
                ? ""
                : !item.peakData.unaccompanied.accompanied &&
                  item.peakData.unaccompanied.sailingTypeCode ===
                    SailingTypeCode.P &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
            columnLabel: "Target",
          }
        ),
        peakActualAcc: createColumnConfig(
          (item) => item.peakData.accompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    item.peakData.accompanied.accompanied &&
                    item.peakData.accompanied.sailingTypeCode ===
                      SailingTypeCode.P
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {item.peakData.accompanied.accompanied &&
                    item.peakData.accompanied.sailingTypeCode ===
                      SailingTypeCode.P &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.peakData.accompanied.actual ?? 0
              ),
          }
        ),
        peakActualUnacc: createColumnConfig(
          (item) => item.peakData.unaccompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    !item.peakData.unaccompanied.accompanied &&
                    item.peakData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.P
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {!item.peakData.unaccompanied.accompanied &&
                    item.peakData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.P &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.peakData.unaccompanied.actual ?? 0
              ),
          }
        ),

        shoulderTargetAcc: createColumnConfig(
          (item) => item.shoulderData.accompanied.target,
          {
            columnLabel: "Target",
            itemLabelFormatter: (value, item) => {
              return item.shoulderData.accompanied.accompanied &&
                item.shoulderData.accompanied.sailingTypeCode ===
                  SailingTypeCode.S &&
                item.shoulderData.accompanied.target === null
                ? ""
                : item.shoulderData.accompanied.accompanied &&
                  item.shoulderData.accompanied.sailingTypeCode ===
                    SailingTypeCode.S &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
          }
        ),
        shoulderTargetUnacc: createColumnConfig(
          (item) => item.shoulderData.unaccompanied.target,
          {
            itemLabelFormatter: (value, item) => {
              return !item.shoulderData.unaccompanied.accompanied &&
                item.shoulderData.unaccompanied.sailingTypeCode ===
                  SailingTypeCode.S &&
                item.shoulderData.unaccompanied.target === null
                ? ""
                : !item.shoulderData.unaccompanied.accompanied &&
                  item.shoulderData.unaccompanied.sailingTypeCode ===
                    SailingTypeCode.S &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
            columnLabel: "Target",
          }
        ),
        shoulderActualAcc: createColumnConfig(
          (item) => item.shoulderData.accompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    item.shoulderData.accompanied.accompanied &&
                    item.shoulderData.accompanied.sailingTypeCode ===
                      SailingTypeCode.S
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {item.shoulderData.accompanied.accompanied &&
                    item.shoulderData.accompanied.sailingTypeCode ===
                      SailingTypeCode.S &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.shoulderData.accompanied.actual ?? 0
              ),
          }
        ),
        shoulderActualUnacc: createColumnConfig(
          (item) => item.shoulderData.unaccompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    !item.shoulderData.unaccompanied.accompanied &&
                    item.shoulderData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.S
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {!item.shoulderData.unaccompanied.accompanied &&
                    item.shoulderData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.S &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.shoulderData.unaccompanied.actual ?? 0
              ),
          }
        ),

        offPeakTargetAcc: createColumnConfig(
          (item) => item.offPeakData.accompanied.target,
          {
            columnLabel: "Target",
            itemLabelFormatter: (value, item) => {
              return item.offPeakData.accompanied.accompanied &&
                item.offPeakData.accompanied.sailingTypeCode ===
                  SailingTypeCode.O &&
                item.offPeakData.accompanied.target === null
                ? ""
                : item.offPeakData.accompanied.accompanied &&
                  item.offPeakData.accompanied.sailingTypeCode ===
                    SailingTypeCode.O &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
          }
        ),
        offPeakTargetUnacc: createColumnConfig(
          (item) => item.offPeakData.unaccompanied.target,
          {
            itemLabelFormatter: (value, item) => {
              return !item.offPeakData.unaccompanied.accompanied &&
                item.offPeakData.unaccompanied.sailingTypeCode ===
                  SailingTypeCode.O &&
                item.offPeakData.unaccompanied.target === null
                ? ""
                : !item.offPeakData.unaccompanied.accompanied &&
                  item.offPeakData.unaccompanied.sailingTypeCode ===
                    SailingTypeCode.O &&
                  value !== null
                ? formatPercentWithSign(value, 2)
                : "";
            },
            columnLabel: "Target",
          }
        ),
        offPeakActualAcc: createColumnConfig(
          (item) => item.offPeakData.accompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    item.offPeakData.accompanied.accompanied &&
                    item.offPeakData.accompanied.sailingTypeCode ===
                      SailingTypeCode.O
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {item.offPeakData.accompanied.accompanied &&
                    item.offPeakData.accompanied.sailingTypeCode ===
                      SailingTypeCode.O &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.offPeakData.accompanied.actual ?? 0
              ),
          }
        ),
        offPeakActualUnacc: createColumnConfig(
          (item) => item.offPeakData.unaccompanied.actual,
          {
            columnLabel: "Actual",
            itemLabelFormatter: (value) =>
              formatPercentWithSign(value, 1, true, 1),
            renderCell: ({ label, value, item }) => (
              <Indent>
                <Txt
                  color={
                    !item.offPeakData.unaccompanied.accompanied &&
                    item.offPeakData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.O
                      ? getHighlightedPositiveNegativeTextColor(value)
                      : ""
                  }
                >
                  {!item.offPeakData.unaccompanied.accompanied &&
                    item.offPeakData.unaccompanied.sailingTypeCode ===
                      SailingTypeCode.O &&
                    label}
                </Txt>
              </Indent>
            ),
            backgroundResolver: (item) =>
              getHighlightedPositiveNegativeBgColor(
                item.offPeakData.unaccompanied.actual ?? 0
              ),
          }
        ),
      },
      columnGroups: {
        noLabelColumnGroup: {
          columnOrder: ["routePairCode"],
        },
        peakTargetAndActualAcc: {
          columnOrder: ["peakTargetAcc", "peakActualAcc"],
          label: "Peak - Acc",
          borderLeft: true,
        },
        peakTargetAndActualUnacc: {
          columnOrder: ["peakTargetUnacc", "peakActualUnacc"],
          label: "Peak - Unacc",
        },
        shoulderTargetAndActualAcc: {
          columnOrder: ["shoulderTargetAcc", "shoulderActualAcc"],
          label: "Shoulder - Acc",
          borderLeft: true,
        },
        shoulderTargetAndActualUnacc: {
          columnOrder: ["shoulderTargetUnacc", "shoulderActualUnacc"],
          label: "Shoulder - Unacc",
        },
        offPeakTargetAndActualAcc: {
          columnOrder: ["offPeakTargetAcc", "offPeakActualAcc"],
          label: "Off peak - Acc",
          borderLeft: true,
        },
        offPeakTargetAndActualUnacc: {
          columnOrder: ["offPeakTargetUnacc", "offPeakActualUnacc"],
          label: "Off peak - Unacc",
        },
      },
      columnGroupOrder: [
        "noLabelColumnGroup",
        "peakTargetAndActualAcc",
        "peakTargetAndActualUnacc",
        "shoulderTargetAndActualAcc",
        "shoulderTargetAndActualUnacc",
        "offPeakTargetAndActualAcc",
        "offPeakTargetAndActualUnacc",
      ],
    };
  };
