import { IndentValues } from "@/common/utils/IndentValues";
import { Indent, Text, useArraySet } from "@stenajs-webui/core";
import { stenaStatusInProgressAlt } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { TargetStatus } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

const statusItems: Array<TargetStatus> = [
  { name: "With targets", code: "with_targets", id: "with_targets" },
  { name: "Without targets", code: "without_targets", id: "without_targets" },
];

interface Props {
  enabled?: boolean;
  label?: string;
}

export const TargetStatusFilterSection: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { targetStatuses, setTargetStatuses } = useFilterWorkspace();

  const { toggle } = useArraySet(
    targetStatuses,
    setTargetStatuses,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = statusItems.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"targetStatuses"}
      label={label}
      enabled={enabled}
      numSelected={targetStatuses.length}
      leftIcon={stenaStatusInProgressAlt}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          <Indent num={IndentValues.SIXTEEN}>
            <Text size={"small"}>Show customers</Text>
          </Indent>
          {statusItems.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!targetStatuses.find(
                  (targetStatus) => targetStatus.id === item.id
                )
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
