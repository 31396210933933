import { gql } from "apollo-boost";

export const agreementDocumentsFragments = gql`
  fragment AgreementDocuments on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    isParent
    preselectedHeadAgreement {
      id
    }
    custStatus {
      id
      name
    }
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    statisticalGrouping {
      id
    }
    invoiceCurrency {
      id
      code
    }
    seller {
      id
      name
    }
    salesRegion {
      id
      name
    }
    hasRebateAgreements
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        name
      }
    }
    agreementDocuments {
      id
      agreementId
      customerNumber
      fileName
      agreementValidFromDate
      agreementValidToDate
      agreementDocumentType
      negotiationYear
      writeHistory {
        createdAt {
          isoString
        }
        createdBy {
          id
          name
        }
      }
    }
  }
`;
