import { DateRangeInputValues } from "@/common/components/date-range-year-and-month-input/DateRangeYearAndMonthInput";

export const getNslhSearchInvoicesDateRangeInitialState = (
  currentDate: Date
): DateRangeInputValues => {
  const currentYear = currentDate.getUTCFullYear();
  const yearFrom = currentYear;
  const monthFrom = currentDate.getUTCMonth();
  const yearTo = currentYear;
  const monthTo = monthFrom + 1;

  return {
    monthFrom: monthFrom === 0 ? 12 : monthFrom,
    monthTo,
    yearFrom: monthFrom === 0 ? yearFrom - 1 : yearFrom,
    yearTo,
  };
};
