import { formatDate } from "@/common/formatters/DateFormatter";
import { Indent, Text } from "@stenajs-webui/core";

export const createStandardTableCell = (
  rowValue: string | number | undefined | null,
  isDateField: boolean = false
) => {
  if (!rowValue) {
    return (
      <Indent>
        <Text>{"Not set"}</Text>
      </Indent>
    );
  }

  if (isDateField) {
    return (
      <Indent>
        <Text>{formatDate(rowValue as string)}</Text>
      </Indent>
    );
  }

  return (
    <Indent>
      <Text>{rowValue}</Text>
    </Indent>
  );
};
