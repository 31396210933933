import { SpaceValues } from "@/common/utils/SpaceValues";
import { useMsal } from "@azure/msal-react";
import { Row, Space } from "@stenajs-webui/core";
import { Link, Spinner } from "@stenajs-webui/elements";
import React from "react";
import { useDownloadPdf } from "@/features/product-and-price/head-agreement/common/hooks/UseDownloadPdf";
import { SurchargeDocumentsTableItem } from "../config/SurchargeDocumentsTableConfig";
import { ApiErrorResult } from "./SurchargeDocumentsUploadModal";

export interface PreviewPdf {
  item: SurchargeDocumentsTableItem;
  setError: React.Dispatch<React.SetStateAction<ApiErrorResult | undefined>>;
}

export const SurchargeDocumentsPreviewPdfLink: React.FC<PreviewPdf> = ({
  item,
  setError,
}) => {
  const { instance } = useMsal();
  const { handleDownload, loading } = useDownloadPdf(instance, setError);
  const onClick = () => {
    const documentId = item.id;
    handleDownload(
      `/download/additional-charges/${documentId}`,
      item.fileName ?? "",
      true
    );
  };

  return (
    <Row alignItems={"center"} indent>
      <Link onClick={onClick}>{item.fileName}</Link>
      <Space num={loading ? SpaceValues.SIXTEEN : SpaceValues.THIRTYSIX} />
      {loading && <Spinner size="tiny" />}
    </Row>
  );
};
