import * as React from "react";
import { useMemo } from "react";
import {
  TableCell,
  TableHeadItem,
  TableHeadRow,
  TableRow,
} from "@stenajs-webui/grid";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { Row, Text } from "@stenajs-webui/core";
import { orderBy } from "lodash";
import { Banner, Spinner } from "@stenajs-webui/elements";
import { ErrorScreen } from "@stenajs-webui/panels";
import { ApolloError } from "apollo-boost";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { cssColor } from "@stenajs-webui/theme";
import { SwitchHeadAgreementAllAgreementsFragment } from "@/gql/graphql";

interface Props {
  loading?: boolean;
  headAgreements: SwitchHeadAgreementAllAgreementsFragment[];
  error?: ApolloError | undefined;
  onSelectHeadAgreement: (headAgreementId: string) => void;
  selectedHeadAgreementId?: string;
}

export const HeadAgreementListSwitcher: React.FC<Props> = ({
  headAgreements,
  loading,
  error,
  selectedHeadAgreementId,
  onSelectHeadAgreement,
}) => {
  const orderedHeadAgreements = useMemo(
    () =>
      orderBy(
        headAgreements,
        [(agreement) => agreement.valid.end?.isoString],
        ["desc"]
      ),
    [headAgreements]
  );

  return (
    <>
      <TableHeadRow indent={2}>
        <TableHeadItem label={"Status"} width={100} />
        <TableHeadItem
          label={"Number"}
          width={70}
          justifyContent={"flex-end"}
        />
        <TableHeadItem label={"Validity"} width={190} />
        <TableHeadItem label={"Name"} flex={1} />
        <TableHeadItem label={""} width={100} />
      </TableHeadRow>
      {loading && (
        <Row spacing={8} justifyContent={"center"}>
          <Spinner />
        </Row>
      )}
      {error && (
        <Row spacing={8} justifyContent={"center"}>
          <ErrorScreen text={error.message} />
        </Row>
      )}
      {orderedHeadAgreements.length === 0 && !loading && !error ? (
        <Banner variant="info" headerText="No Header agreements found" />
      ) : (
        orderedHeadAgreements.map((agreement) => (
          <TableRow
            key={agreement.id}
            indent={2}
            hoverBackground={cssColor("--lhds-color-ui-200")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              onSelectHeadAgreement(agreement.id);
            }}
          >
            <TableCell width={100}>
              <HeadAgreementStatusCodeChip statusCode={agreement.statusCode} />
            </TableCell>
            <TableCell width={70} justifyContent={"flex-end"}>
              <Text>{agreement.headAgreementNumber}</Text>
            </TableCell>
            <TableCell width={190}>
              <Text>{formatGQLDateRange(agreement.valid)}</Text>
            </TableCell>
            <TableCell flex={1}>
              <Text>{agreement.name}</Text>
            </TableCell>
            <TableCell width={100}>
              {selectedHeadAgreementId === agreement.id && (
                <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                  Selected
                </Text>
              )}
            </TableCell>
          </TableRow>
        ))
      )}
    </>
  );
};
