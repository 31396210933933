import {
  RouteTargetRoutePairDescriptionQuery,
  RouteTargetRoutePairDescriptionQueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";

const query = gql`
  query RouteTargetRoutePairDescription($id: ID!) {
    productPrice {
      routePair {
        byId(id: $id) {
          id
          description
        }
      }
    }
  }
`;

export const useRouteTargetPageRoutePairDescription = (
  routePairCode: string
) => {
  const { data, loading } = useQuery<
    RouteTargetRoutePairDescriptionQuery,
    RouteTargetRoutePairDescriptionQueryVariables
  >(query, {
    variables: { id: routePairCode },
  });

  const routePairDescription = data?.productPrice.routePair.byId?.description;

  return {
    routePairDescription,
    loading,
  };
};
