import { Indent } from "@stenajs-webui/core";
import { Checkbox } from "@stenajs-webui/forms";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { toggleCheckedBookingStateForCustomer } from "../redux/reducers";
import { createNoShowLateHandlingBookingSelectionStateSelectors } from "../redux/selectors";

interface Props {
  customerNo: string;
  bookingNo: number;
  disabled: boolean;
}

const _BookingTableCheckbox: React.FC<Props> = ({
  customerNo,
  bookingNo,
  disabled,
}) => {
  const dispatch = useDispatch();
  const rowSelectionState = useSelector(
    useCallback(
      (state: StoreState) =>
        createNoShowLateHandlingBookingSelectionStateSelectors.getNoShowLateHandlingBookingSelectionState(
          state,
          customerNo,
          bookingNo
        ),
      [customerNo, bookingNo]
    )
  );

  const updateSelectionState = () => {
    dispatch(toggleCheckedBookingStateForCustomer({ bookingNo, customerNo }));
  };

  return (
    <Indent>
      <Checkbox
        size="small"
        checked={rowSelectionState}
        onChange={updateSelectionState}
        disabled={disabled}
      />
    </Indent>
  );
};

export const BookingTableCheckbox = memo(_BookingTableCheckbox);
