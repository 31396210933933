import { StandardTable } from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../config/redux/RootReducer";
import { noShowLateHandlingRoutePricesTableConfig } from "./config/NoShowLateHandlingRoutePricesTableConfig";
import { NoShowLateHandlingPricesRow } from "./config/RowsAndColumnsConfig";
import { NoShowLateHandlingCreateValidFromDateModal } from "./NoShowLateHandlingCreateValidFromDateModal";
import { noShowLateHandlingRoutePricesCurrentlySelectedActions } from "./redux/actions";
import { CurrentlySelectedSibling } from "./redux/reducers";
import { fetchNoShowLateHandlingRoutePrices } from "./redux/thunks/NoShowLateHandlingRoutePricesFetcher";

interface Props {
  routes: EditableEntityState<NoShowLateHandlingPricesRow>[];
  item: EditableEntityState<NoShowLateHandlingPricesRow>;
  routePairCode: string;
}

const currentlySelectedDataSelector = (state: StoreState) =>
  state.noShowLateHandlingPricesCurrentlySelected.selected;
const progressStateSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.progress;
const bannerStateSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.progress.bannerState;

export const NoShowLateHandlingRoutePricesTable: React.FC<Props> = ({
  routes,
  item,
  routePairCode,
}) => {
  const dispatch = useDispatch();
  const currentlySelectedData = useSelector(currentlySelectedDataSelector);
  const bannerState = useSelector(bannerStateSelector);
  const { loading } = useSelector(progressStateSelector);

  useEffect(() => {
    dispatch(fetchNoShowLateHandlingRoutePrices());
  }, [dispatch]);

  const updateNoShowLateHandlingTableRow = useCallback(
    (previousId: string, id: string) => {
      const routePairCode = previousId.split(":")[0];

      const updatedCurrentlySelectedData = currentlySelectedData
        .flat()
        .map((data): CurrentlySelectedSibling => {
          if (data.routePairCode === routePairCode) {
            return {
              currentlySelectedId: id,
              routePairCode: routePairCode,
            };
          }
          return data;
        });

      dispatch(
        noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
          updatedCurrentlySelectedData
        )
      );
    },
    [currentlySelectedData, dispatch]
  );

  const config = useMemo(() => {
    return noShowLateHandlingRoutePricesTableConfig({
      updateSelectedRows: updateNoShowLateHandlingTableRow,
      bannerState,
    });
  }, [updateNoShowLateHandlingTableRow, bannerState]);

  return (
    <>
      <StandardTable items={routes} config={config} loading={loading} />
      <NoShowLateHandlingCreateValidFromDateModal
        routePairCode={routePairCode}
        item={item}
      />
    </>
  );
};
