import {
  createEntityActions,
  createEntityReducer,
  createEntitySelectors,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../../../../config/redux/RootReducer";
import { emptyNotesFormModel, NotesFormModel } from "./NotesFormModel";
import { wrapActionsWithReducerIdGate } from "../../../../../../common/redux/ReducerIdGateActionWrapper";

export type NotesFormState = EntityState<NotesFormModel>;

const reducerId = "routeAgreementDetails.notes";

export const notesFormRedux = {
  reducer: reducerIdGate(
    reducerId,
    createEntityReducer<NotesFormModel>(emptyNotesFormModel)
  ),
  actions: wrapActionsWithReducerIdGate(reducerId, {
    ...createEntityActions(),
  }),
  selectors: createEntitySelectors<StoreState, NotesFormModel>(
    (state) => state.routeAgreementDetails.workspace.notes
  ),
};
