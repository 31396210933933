import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import {
  RateSheetChanges,
  RateSheetPriceChangesInput,
  UpdateRateSheetsRouteAgreementItemInput,
} from "@/gql/graphql";
import { transformCargoStatusCodeToEntity } from "../../../../../common/transformers/CargoStatusTransformer";
import { BulkEditRatesState } from "../redux/reducer";
import { BulkEditRatePriceTableItem } from "../types";

export const routeAgreementHasChanges = (
  item: UpdateRateSheetsRouteAgreementItemInput
): boolean => item.changes.updated.length > 0;

export const transformBulkEditRatesChangesToInput = (
  routeAgreementPriceId: string,
  state: BulkEditRatesState
): Omit<RateSheetChanges, "deleted" | "created"> => {
  const editedAndPersistedList = Object.values(state).map((_, i) => {
    if (i === 0) {
      return {
        persisted: state.persisted,
        edited: state.edited,
      };
    }
    return null;
  });

  const updated = editedAndPersistedList
    .filter(
      (r) =>
        r?.edited[routeAgreementPriceId] !== r?.persisted[routeAgreementPriceId]
    )
    .map((l) => ({
      routeAgreementPriceId,
      changes: transformBulkEditRatesRowChangesToInput(
        l?.persisted[routeAgreementPriceId]!,
        l?.edited[routeAgreementPriceId]!
      ),
    }));

  return {
    updated,
  };
};

export const transformBulkEditRatesRowChangesToInput = (
  persisted: BulkEditRatePriceTableItem,
  edited: BulkEditRatePriceTableItem
): RateSheetPriceChangesInput => {
  const newEmptyLoadedValues =
    persisted.cargoStatusCode !== edited.cargoStatusCode
      ? transformCargoStatusCodeToEntity(edited.cargoStatusCode)
      : null;
  return {
    acc:
      persisted.unaccompanied !== edited.unaccompanied
        ? { value: edited.unaccompanied === false }
        : null,
    unacc:
      persisted.unaccompanied !== edited.unaccompanied
        ? { value: edited.unaccompanied === true }
        : null,
    additionalFrom:
      persisted.additionalFrom !== edited.additionalFrom
        ? { value: Number(commaToDotTransform(String(edited.additionalFrom))) }
        : null,
    empty: newEmptyLoadedValues ? { value: newEmptyLoadedValues.empty } : null,
    loaded: newEmptyLoadedValues
      ? { value: newEmptyLoadedValues.loaded }
      : null,
    lengthFrom:
      persisted.lengthFrom !== edited.lengthFrom
        ? { value: edited.lengthFrom }
        : null,
    lengthTo:
      persisted.lengthTo !== edited.lengthTo
        ? { value: edited.lengthTo }
        : null,
    weightFrom:
      persisted.weightFrom !== edited.weightFrom
        ? { value: edited.weightFrom }
        : null,
    weightTo:
      persisted.weightTo !== edited.weightTo
        ? { value: edited.weightTo }
        : null,
    meterPrice:
      persisted.meterPrice !== edited.meterPrice
        ? { value: { amount: commaToDotTransform(edited.meterPrice) } }
        : null,
    newPrice:
      persisted.newPrice !== edited.newPrice
        ? { value: { amount: commaToDotTransform(edited.newPrice) } }
        : null,
    vehicleTypeId:
      persisted.vehicleType?.id !== edited.vehicleType?.id
        ? { value: edited.vehicleType?.id }
        : null,
    actual:
      persisted.actual !== edited.actual ? { value: edited.actual } : null,
    avgGrossPrice:
      persisted.avgGrossPrice !== edited.avgGrossPrice
        ? {
            value: edited.avgGrossPrice
              ? { amount: edited.avgGrossPrice }
              : null,
          }
        : null,
    lastPrice:
      persisted.lastPrice !== edited.lastPrice
        ? {
            value: edited.lastPrice ? { amount: edited.lastPrice } : null,
          }
        : null,
    lastChargedFrom:
      persisted.lastChargedFrom !== edited.lastChargedFrom
        ? { value: edited.lastChargedFrom }
        : null,
    lastMeterPrice:
      persisted.lastMeterPrice !== edited.lastMeterPrice
        ? {
            value: edited.lastMeterPrice
              ? { amount: edited.lastMeterPrice }
              : null,
          }
        : null,
    avgLength:
      persisted.avgLength !== edited.avgLength
        ? { value: edited.avgLength }
        : null,

    avgRebate:
      persisted.avgRebate !== edited.avgRebate
        ? {
            value: edited.avgRebate ? { amount: edited.avgRebate } : null,
          }
        : null,
    volume:
      persisted.volume !== edited.volume ? { value: edited.volume } : null,
  };
};
