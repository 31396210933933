import * as React from "react";
import { KeyboardEventHandler, useCallback, useRef, useState } from "react";
import {
  Box,
  Column,
  Indent,
  Row,
  Text,
  useDebounce,
} from "@stenajs-webui/core";
import { TextInput, ValueAndOnValueChangeProps } from "@stenajs-webui/forms";
import { InputSpinner } from "@stenajs-webui/elements";
import { gql, useQuery } from "@apollo/client";
import { ListBox, ListBoxOption } from "../../list-box/ListBox";
import { cssColor } from "@stenajs-webui/theme";
import {
  MatricesByKeyWordFragment,
  MatricesTypeSearchListQuery,
  MatricesTypeSearchListQueryVariables,
} from "@/gql/graphql";

interface Props extends ValueAndOnValueChangeProps<MatricesByKeyWordFragment> {}

const query = gql`
  fragment MatricesByKeyWord on Matrix {
    id
    keywords
    validityKey
    timeslots {
      timeFrom
      timeTo
      weekDay
    }
  }
  query MatricesTypeSearchList($query: String!) {
    productPrice {
      matrices {
        byKeyword(query: $query) {
          ...MatricesByKeyWord
        }
      }
    }
  }
`;

export interface MatrixTypeSearchListOption extends ListBoxOption {
  matrixType: MatricesByKeyWordFragment;
}

export const MatrixTypeSearchList: React.FC<Props> = ({
  value,
  onValueChange,
}) => {
  const [text, setText] = useState("");

  const debouncedText = useDebounce(text, 100);

  const { data, loading } = useQuery<
    MatricesTypeSearchListQuery,
    MatricesTypeSearchListQueryVariables
  >(query, {
    variables: { query: debouncedText },
    skip: debouncedText.length < 3,
    onCompleted: (r) => {
      const matrixTypes = r?.productPrice.matrices.byKeyword ?? [];
      const item = matrixTypes[0];
      if (!value && onValueChange && item) {
        onValueChange(item);
      }
    },
  });

  const list =
    data?.productPrice.matrices.byKeyword.map<MatrixTypeSearchListOption>(
      (item) => ({
        value: item.id,
        label: item.keywords.join(" "),
        matrixType: item,
      })
    );

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const focusOnInput = () => {
    inputRef.current?.focus();
  };

  const focusOnList = useCallback(() => {
    listRef.current?.focus();
  }, [listRef]);

  const onKeyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (ev) => {
      if (ev.key === "ArrowDown") {
        focusOnList();
        ev.stopPropagation();
        ev.preventDefault();
      }
    },
    [focusOnList]
  );

  return (
    <Column width={"400px"}>
      <Row alignItems={"center"}>
        <Text>Keyword</Text>
        <Indent num={2} />
        <Box flex={1}>
          <TextInput
            placeholder={"Enter keywords to search"}
            value={text}
            onValueChange={setText}
            inputRef={inputRef}
            onKeyDown={onKeyDownHandler}
            contentRight={loading ? <InputSpinner /> : undefined}
          />
        </Box>
      </Row>
      {list == null && (
        <Row justifyContent={"center"} spacing={4}>
          <Text size="small" color={cssColor("--lhds-color-ui-500")}>
            Enter at least three characters to search.
          </Text>
        </Row>
      )}
      {list && !list.length && (
        <Row justifyContent={"center"} spacing={4}>
          <Text size="small" color={cssColor("--lhds-color-ui-500")}>
            Found no matching matrices.
          </Text>
        </Row>
      )}
      {list && list.length > 0 && (
        <ListBox<MatrixTypeSearchListOption>
          options={list}
          onMoveUp={focusOnInput}
          value={
            value && {
              value: value.id,
              label: value.keywords.join(" "),
              matrixType: value,
            }
          }
          onValueChange={(v) => onValueChange && onValueChange(v.matrixType)}
          height={"360px"}
          listRef={listRef}
        />
      )}
    </Column>
  );
};
