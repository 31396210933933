import * as React from "react";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateMultiLegAgreementModal } from "./CreateMultiLegAgreementModal";

export interface Params {
  customerId: string;
  headAgreementId: string;
}

interface Props {}

export const CreateMultiLegAgreementRoute: React.FC<Props> = () => {
  const { customerId, headAgreementId } = useParams() as {
    customerId: string;
    headAgreementId: string;
  };
  const navigate = useNavigate();

  const onRequestClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <CreateMultiLegAgreementModal
      customerId={customerId}
      headAgreementId={headAgreementId}
      isOpen
      onRequestClose={onRequestClose}
    />
  );
};
