import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ErrorScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { useParams } from "react-router-dom";
import { StatisticsByRoutePairDetails } from "./StatisticsByRoutePairDetails";

interface Props {}

interface StatisticsBySellerDetailsScreenParams {
  routeId?: string;
  year?: string;
}

export const StatisticsByRoutePairDetailsScreen: React.FC<Props> = () => {
  const { routeId } = useParams() as StatisticsBySellerDetailsScreenParams;
  const { year } = useStatisticsYearSelect();

  if (!routeId) {
    return <ErrorScreen text={"No such seller."} />;
  }

  return <StatisticsByRoutePairDetails routeId={routeId} year={year} />;
};
