import { SeparatorLine } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  Tab,
  TabMenu,
  stenaCalculate,
  stenaPlus,
} from "@stenajs-webui/elements";
import * as React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../../../RouteFactory";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { MultiLegAgreement } from "../../../types";
import { ExistingAgreementList } from "./ExistingAgreementList";
import { NoAgreementBox } from "./NoAgreementBox";
import { ExistingMultiLegAgreementList } from "./multileg/ExistingMultiLegAgreementList";
import { customerAgreementActions } from "./redux/actions";
import { AgreementTabs } from "./redux/reducers";
import { customerAgreementSelectors } from "./redux/selectors";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  customerId: string;
  routeAgreements: Array<RouteAgreement>;
  multiLegAgreements: Array<MultiLegAgreement>;
  headAgreementId: string;
  year: number;
}

const tabs: Array<AgreementTabs> = [
  AgreementTabs.Route,
  AgreementTabs.MultiLeg,
];

const resolveFlatButtonLabel = (tab: AgreementTabs): string => {
  return tab === AgreementTabs.Route
    ? "New route agreement"
    : "New multi leg agreement";
};

export const ExistingAgreementListBox: React.FC<Props> = ({
  year,
  customerId,
  headAgreementId,
  routeAgreements,
  multiLegAgreements,
}) => {
  const { currentTabSelected } = useSelector(
    customerAgreementSelectors.getTabState
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        contentLeft={
          <TabMenu>
            {tabs.map((t, i) => (
              <Fragment key={i}>
                <Tab
                  label={`${t} (${
                    i === 0 ? routeAgreements.length : multiLegAgreements.length
                  })`}
                  selected={t === currentTabSelected}
                  onClick={() => {
                    localStorage.setItem("AgreementTabSelected", t);
                    dispatch(
                      customerAgreementActions.updateTabState({
                        currentTabSelected: t,
                      })
                    );
                  }}
                />
              </Fragment>
            ))}
          </TabMenu>
        }
        contentRight={
          <>
            {currentTabSelected === AgreementTabs.Route && (
              <FlatButton
                label={"Edit rates"}
                leftIcon={stenaCalculate}
                onClick={() =>
                  navigate(
                    routeFactory.productAndPrice.headAgreement.bulkEditRateHeadAgreement(
                      {
                        customerId,
                        headAgreementId,
                      }
                    )
                  )
                }
              />
            )}
            {currentTabSelected === AgreementTabs.Route ? (
              <FlatButton
                label={resolveFlatButtonLabel(currentTabSelected)}
                leftIcon={stenaPlus}
                data-testid={testIdConstantsWebapp.newRouteAgreementButton}
                onClick={() =>
                  navigate(
                    routeFactory.productAndPrice.routeAgreement.createRouteAgreement(
                      {
                        headAgreementId,
                        customerId,
                      }
                    )
                  )
                }
              />
            ) : (
              <FlatButton
                label={resolveFlatButtonLabel(currentTabSelected)}
                leftIcon={stenaPlus}
                onClick={() =>
                  navigate(
                    routeFactory.productAndPrice.multiLegAgreement.createMultiLegRouteAgreement(
                      {
                        headAgreementId,
                        customerId,
                      }
                    )
                  )
                }
              />
            )}
          </>
        }
      />
      <SeparatorLine />
      <CardBody>
        {currentTabSelected === AgreementTabs.Route ? (
          routeAgreements.length > 0 ? (
            <ExistingAgreementList
              customerId={customerId}
              routeAgreements={routeAgreements}
              headAgreementId={headAgreementId}
              year={year}
            />
          ) : (
            <NoAgreementBox
              customerId={customerId}
              headAgreementId={headAgreementId}
              bannerHeader={"No route agreement exists."}
              buttonLabel={resolveFlatButtonLabel(AgreementTabs.Route)}
              isMultiLeg={false}
            />
          )
        ) : multiLegAgreements.length > 0 ? (
          <ExistingMultiLegAgreementList
            customerId={customerId}
            multiLegAgreements={multiLegAgreements}
            headAgreementId={headAgreementId}
            year={year}
          />
        ) : (
          <NoAgreementBox
            customerId={customerId}
            headAgreementId={headAgreementId}
            bannerHeader={"No multi leg agreement exists."}
            buttonLabel={resolveFlatButtonLabel(AgreementTabs.MultiLeg)}
            isMultiLeg={true}
          />
        )}
      </CardBody>
    </Card>
  );
};
