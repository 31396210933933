import { StatisticsAdvancedDataListColumns } from "./StatisticsByAdvancedTableConfig";

export const advancedInfoColumnOrder: Array<
  Partial<StatisticsAdvancedDataListColumns>
> = ["customerName"];

export const noLabelColumnOrder: Array<
  Partial<StatisticsAdvancedDataListColumns>
> = ["negStatus", "custNo", "custIndex", "statNo", "parentNo", "seller"];

export const routeSailingAndUnitColumnOrder: Array<
  Partial<StatisticsAdvancedDataListColumns>
> = ["route", "sailing", "unit"];

export const avgPriceColumnOrder: Array<
  Partial<StatisticsAdvancedDataListColumns>
> = ["gross", "net"];

export const targetRateAndVolumesColumnOrder: Array<
  Partial<StatisticsAdvancedDataListColumns>
> = ["target", "rate", "volume12Month", "volumeStat12Month", "expVolume"];
