import * as React from "react";
import { Box } from "@stenajs-webui/core";
import { BreadCrumbs, Crumb } from "@stenajs-webui/elements";
import { PageHeader, PageHeading } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import { routeFactory } from "../../../../../../RouteFactory";
import { Link, useParams } from "react-router-dom";
import { useGetCopyToOtherCustomerBreadCrumbNameQuery } from "../../hooks/UseCopyToOtherCustomerBreadCrumbName";

export const CopyHaToOtherCustomerHeader: React.FC = () => {
  const { customerId, headAgreementId } = useParams() as {
    customerId: string;
    headAgreementId: string;
  };

  const { breadCrumbName } =
    useGetCopyToOtherCustomerBreadCrumbNameQuery(customerId);

  return (
    <Box background={cssColor("--lhds-color-ui-50")}>
      <PageHeader
        renderPageHeading={() => (
          <PageHeading heading={"Copy header agreement to other customer"} />
        )}
        renderBreadCrumbs={() => (
          <BreadCrumbs>
            <Crumb
              label={"Negotiation plan"}
              render={(props) => (
                <Link
                  to={routeFactory.productAndPrice.customer.customerList()}
                  {...props}
                />
              )}
            />
            <Crumb
              label={breadCrumbName}
              render={(props) => (
                <Link
                  to={routeFactory.productAndPrice.customer.customerHeadAgreement(
                    {
                      customerId,
                      headAgreementId,
                    }
                  )}
                  {...props}
                />
              )}
            />
            <Crumb label={"Copy HA to other customer"} />
          </BreadCrumbs>
        )}
      />
    </Box>
  );
};
