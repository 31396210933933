import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Heading, Row, Space, Txt } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
} from "@stenajs-webui/elements";
import { CheckboxWithLabel, TextArea } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import { useSpecificationInformation } from "./hooks/useSpecificationInformation";
import { useEffect } from "react";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import StepContainer from "../../common/components/StepContainer";
import StepCardBody from "../../common/components/StepCardBody";

const SpecificationsStep = ({
  customerId,
  year,
  month,
  headAgreementNumber,
  modeOfPaymemt,
  invoiceCurrency,
  validFrom,
  validTo,
  includeCreditLimit,
  setIncludeCreditLimit,
  includeThresholds,
  setIncludeThresholds,
  specificationsText,
  setSpecificationsText,
  setIsNextAvailable,
}: {
  customerId: string;
  year: number;
  month: number;
  headAgreementNumber?: number;
  modeOfPaymemt?: string;
  invoiceCurrency?: string;
  validFrom?: string;
  validTo?: string;
  includeCreditLimit: boolean;
  setIncludeCreditLimit: React.Dispatch<React.SetStateAction<boolean>>;
  includeThresholds: boolean;
  setIncludeThresholds: React.Dispatch<React.SetStateAction<boolean>>;
  specificationsText: string;
  setSpecificationsText: React.Dispatch<React.SetStateAction<string>>;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { customerInfo, loading } = useSpecificationInformation(
    customerId,
    year,
    month
  );
  useEffect(() => {
    setIsNextAvailable(!loading);
  }, [loading, setIsNextAvailable]);
  const thresholdTypeNoShow = customerInfo?.customerThresholds?.find(
    (item) => item.thresholdTypeCode === "NOSHOW"
  );
  const thresholdTypeLateHandling = customerInfo?.customerThresholds?.find(
    (item) => item.thresholdTypeCode === "LATE_HANDL"
  );

  const pluralize = (value: number, singularText: string, pluralText: string) =>
    value.toString().concat(value === 1 ? singularText : pluralText);

  const getTermsOfPayment = () =>
    pluralize(
      customerInfo?.termsOfPaymentMonths || 0,
      " month ",
      " months "
    ).concat(pluralize(customerInfo?.termsOfPaymentDays || 0, " day", " days"));

  const credit = customerInfo?.creditLimit;
  const creditLimit =
    credit != null
      ? new Intl.NumberFormat(navigator.language).format(credit)
      : "";

  return (
    <>
      {loading ? (
        <Row justifyContent={"center"}>
          <Spinner />
        </Row>
      ) : (
        <StepContainer>
          <CardHeader text="Agreement and payment" />
          <StepCardBody>
            <Txt>Select what to display in document</Txt>
            <Space num={SpaceValues.SIXTEEN} />
            <Row>
              <CheckboxWithLabel
                label={"Credit limit"}
                checked={includeCreditLimit}
                onChange={() => setIncludeCreditLimit(!includeCreditLimit)}
              />
              <Space num={SpaceValues.SIXTEEN} />
              <CheckboxWithLabel
                label={"Threshold (NSLH)"}
                checked={includeThresholds}
                onChange={() => setIncludeThresholds(!includeThresholds)}
              />
            </Row>
            <Space num={SpaceValues.TWENTYFOUR} />
            <Card>
              <CardBody>
                <Heading variant="h5">Agreement specifications</Heading>
                <Space num={SpaceValues.SIXTEEN} />
                <Row justifyContent={"space-between"}>
                  <Box>
                    <Txt>Agreement number: {headAgreementNumber || ""}</Txt>
                    <Space num={SpaceValues.SIXTEEN} />
                    <Txt>Terms of payment: {getTermsOfPayment()}</Txt>
                    <Space num={SpaceValues.SIXTEEN} />
                    {includeThresholds && (
                      <>
                        <Txt>
                          {"Threshold No Show: "}
                          {thresholdTypeNoShow?.percentage
                            ? thresholdTypeNoShow?.percentage + "%"
                            : ""}
                        </Txt>
                        <Space num={SpaceValues.SIXTEEN} />
                      </>
                    )}
                  </Box>
                  <Space num={SpaceValues.SIXTEEN} />
                  <Box>
                    <Txt>
                      Validity period: {validFrom} - {validTo}
                    </Txt>
                    <Space num={SpaceValues.SIXTEEN} />
                    <Txt>Currency: {invoiceCurrency || ""}</Txt>
                    <Space num={SpaceValues.SIXTEEN} />
                    {includeThresholds && (
                      <Txt>
                        {"Threshold Late Handling: "}
                        {thresholdTypeLateHandling?.percentage
                          ? thresholdTypeLateHandling?.percentage + "%"
                          : ""}
                      </Txt>
                    )}
                  </Box>
                  <Box>
                    <Txt>Payment mode: {modeOfPaymemt}</Txt>
                    <Space num={SpaceValues.SIXTEEN} />
                    {includeCreditLimit && (
                      <>
                        <Txt>Credit Limit: {creditLimit} </Txt>{" "}
                      </>
                    )}
                  </Box>
                </Row>

                <Space num={SpaceValues.TWENTYFOUR} />
                <Label
                  text={"Agreement report specifications area"}
                  optional
                  maxCharacters={150}
                  indent={IndentValues.SIXTEEN}
                  spacing={SpacingValues.SIXTEEN}
                  background={cssColor("--lhds-color-blue-100")}
                  borderColor={cssColor("--lhds-color-blue-300")}
                >
                  <TextArea
                    rows={5}
                    maxLength={150}
                    value={specificationsText}
                    onValueChange={setSpecificationsText}
                  />
                </Label>
              </CardBody>
            </Card>
          </StepCardBody>
        </StepContainer>
      )}
    </>
  );
};

export default SpecificationsStep;
