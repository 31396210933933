import { Indent, Row, useBoolean, useDebounce } from "@stenajs-webui/core";
import {
  InputSpinner,
  PrimaryButton,
  SecondaryButton,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModalWithLock } from "../../../../common/components/loading-modals/LoadingModalWithLock";
import { StoreState } from "../../../../config/redux/RootReducer";
import { useUpdateExpectedVolumesMutation } from "../hooks/UseUpdateExpectedVolumesMutation";
import { expectedVolumeActions } from "../redux/ExpectedVolumeRedux";
import { fetchExpectedVolume } from "../thunks/ExpectedVolumeFetcher";
import { getExpectedVolumeChanges } from "../util/ChangesCollector";
import { transformChangesToInput } from "../util/ChangesTransformer";
import { usePrompt } from "@/common/hooks/usePrompt";

interface Props {
  customerId: string;
  year: number;
  customerStatisticalGrouping: string;
}

const selector = (state: StoreState) =>
  state.expectedVolume.expectedVolumesByRoutePair;

export const ExpectedVolumeActionButtons: React.FC<Props> = ({
  customerId,
  year,
  customerStatisticalGrouping,
}) => {
  const dispatch = useDispatch();

  const expectedVolumesByRoutePair = useSelector(selector);
  const [saving, setSaving, setNotSaving] = useBoolean(false);

  const debounced = useDebounce(expectedVolumesByRoutePair, 1000);

  const { updateExpectedVolumes } = useUpdateExpectedVolumesMutation();

  const onClickDiscard = () => {
    dispatch(fetchExpectedVolume(customerId, year));
  };

  const onClickSave = useCallback(async () => {
    dispatch(
      expectedVolumeActions.setProgress({
        bannerState: undefined,
      })
    );
    setSaving();
    try {
      const changes = getExpectedVolumeChanges(expectedVolumesByRoutePair);

      const r = await updateExpectedVolumes(
        transformChangesToInput(year, customerStatisticalGrouping, changes)
      );
      const updateResult =
        r.data?.productPrice.expectedVolume.updateExpectedVolumes;

      const errors = [
        ...(r.errors?.map((e) => e.message) ?? []),
        ...((updateResult &&
          "errors" in updateResult &&
          updateResult.errors.map((error) => error.message)) ||
          []),
      ];

      if (errors.length) {
        dispatch(
          expectedVolumeActions.setProgress({
            bannerState: {
              headerText: "Could not save expected volumes",
              items: errors.map((text) => ({ text })),
            },
          })
        );
      } else {
        dispatch(fetchExpectedVolume(customerId, year));
      }
    } catch (e) {
      dispatch(
        expectedVolumeActions.setProgress({
          bannerState: {
            headerText: "Could not save expected volumes",
            items: [{ text: e.message }],
          },
        })
      );
    }
    setNotSaving();
  }, [
    dispatch,
    expectedVolumesByRoutePair,
    customerStatisticalGrouping,
    customerId,
    year,
    updateExpectedVolumes,
    setSaving,
    setNotSaving,
  ]);

  const canSave = useMemo(() => {
    const r = getExpectedVolumeChanges(debounced);
    return r.length > 0 && debounced === expectedVolumesByRoutePair;
  }, [debounced, expectedVolumesByRoutePair]);

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: canSave,
  });

  return (
    <Row alignItems={"center"}>
      {saving && <LoadingModalWithLock label={"Saving expected volumes..."} />}

      {debounced !== expectedVolumesByRoutePair && <InputSpinner />}
      <Indent />
      <SecondaryButton
        label={"Discard"}
        onClick={onClickDiscard}
        disabled={!canSave || saving}
      />
      <Indent />
      <PrimaryButton
        label={"Save"}
        disabled={!canSave}
        onClick={onClickSave}
        loading={saving}
      />
    </Row>
  );
};
