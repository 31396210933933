import { DateField, SpectrumDateFieldProps } from "@adobe/react-spectrum";
import { CalendarDate, DateValue } from "@internationalized/date";
import { getYear } from "date-fns";
import * as React from "react";

interface Props extends SpectrumDateFieldProps<DateValue> {}

export const DateInput: React.FC<Props> = ({ ...props }) => {
  const yearOfToday = getYear(new Date());

  return (
    <DateField
      aria-label="Date input"
      placeholderValue={new CalendarDate(yearOfToday, 1, 1)}
      {...props}
    />
  );
};
