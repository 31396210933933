import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {}

export const GoogleAnalyticsPageTracker: React.FC<Props> = () => {
  const location = useLocation();

  useEffect(() => {
    const afterFirstRender = setTimeout(() => {
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({ event: "pageview" });
      }
    }, 500);
    return () => clearTimeout(afterFirstRender);
  }, [location]);

  return null;
};
