import { AddVehicleTypeFormModel } from "../add-vehicle-type/models/AddVehicleTypeFormModel";
import { parseFloatElseThrow, parseIntElseUndefined } from "@/common/numbers";
import { RateSheetRouteAgreementPrice } from "../types";
import { uuid } from "../../../../common/uuid/UuidFactory";
import { transformAccUnaccCodeToUnaccompaniedBoolean } from "../../../../common/transformers/UnaccompaniedTransformer";
import { AllVehiclesFragment } from "@/gql/graphql";

export const transformAddVehicleTypeFormToPrice = (
  formModel: AddVehicleTypeFormModel,
  allVehicleTypes: Array<AllVehiclesFragment>
): RateSheetRouteAgreementPrice => {
  const lengthFrom = parseFloatElseThrow(formModel.lengthFrom);
  const lengthTo = parseFloatElseThrow(formModel.lengthTo);
  const weightFrom = parseIntElseUndefined(formModel.weightFrom);
  const weightTo = parseIntElseUndefined(formModel.weightTo);

  if (lengthFrom === undefined) {
    throw new Error("Length from is missing.");
  }
  if (lengthTo === undefined) {
    throw new Error("Length to is missing.");
  }
  if (weightFrom === undefined) {
    throw new Error("Weight from is missing.");
  }
  if (weightTo === undefined) {
    throw new Error("Weight to is missing.");
  }
  if (formModel.vehicleTypeId === undefined) {
    throw new Error("Vehicle type is missing.");
  }

  return {
    id: uuid(),
    avgRebate: null,
    vehicleType:
      allVehicleTypes.find((v) => v.id === formModel.vehicleTypeId) ?? null,
    lengthFrom,
    lengthTo,
    weightFrom,
    weightTo,
    cargoStatusCode: formModel.cargoStatusCode,
    meterPrice: {
      amount: "0",
    },
    newPrice: {
      amount: "0",
    },
    volume: null,
    lastPrice: null,
    avgGrossPrice: null,
    avgNetPrice: null,
    additionalFrom: 0,
    unaccompanied: transformAccUnaccCodeToUnaccompaniedBoolean(
      formModel.unaccompanied
    ),
    actual: null,
    lastChargedFrom: null,
    avgLength: null,
    lastMeterPrice: null,
  };
};
