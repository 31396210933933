import * as React from "react";
import { SwitchWithLabel, SwitchWithLabelProps } from "@stenajs-webui/forms";
import { multilegAgreementCanBeEditedByStatusCode } from "../../../../features/product-and-price/customer-multileg-route/util/MultiLegAgreementCalculator";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props extends SwitchWithLabelProps {
  multilegAgreementStatusCode: HeadAgreementStatusCode;
}

export const SwitchWithLabelForMultilegSalesReady: React.FC<Props> = ({
  multilegAgreementStatusCode,
  disabled,
  ...switchProps
}) => {
  const disabledByMultilegAgreement = !multilegAgreementCanBeEditedByStatusCode(
    multilegAgreementStatusCode
  );

  return (
    <SwitchWithLabel
      disabled={disabledByMultilegAgreement || disabled}
      {...switchProps}
    />
  );
};
