import { initial, last } from "lodash";

export const formatMissingFieldsMessage = (
  fieldNames: Array<string>
): string | undefined => {
  if (fieldNames.length === 0) {
    return undefined;
  }
  if (fieldNames.length === 1) {
    return `Missing ${fieldNames[0]}.`;
  }
  return `Missing ${initial(fieldNames).join(", ")} and ${last(fieldNames)}.`;
};
