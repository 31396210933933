export type WeekDayCode = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
export type WeekDayLongName =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

interface WeekDay {
  code: WeekDayCode;
  short: string;
  dayOfWeek: number;
  long: WeekDayLongName;
}

const weekDaysByCode: Record<WeekDayCode, WeekDay> = {
  mon: { code: "mon", short: "M", dayOfWeek: 1, long: "Monday" },
  tue: { code: "tue", short: "T", dayOfWeek: 2, long: "Tuesday" },
  wed: { code: "wed", short: "W", dayOfWeek: 3, long: "Wednesday" },
  thu: { code: "thu", short: "T", dayOfWeek: 4, long: "Thursday" },
  fri: { code: "fri", short: "F", dayOfWeek: 5, long: "Friday" },
  sat: { code: "sat", short: "S", dayOfWeek: 6, long: "Saturday" },
  sun: { code: "sun", short: "S", dayOfWeek: 7, long: "Sunday" },
};

export const allWeekDays: Array<WeekDay> = [
  weekDaysByCode.mon,
  weekDaysByCode.tue,
  weekDaysByCode.wed,
  weekDaysByCode.thu,
  weekDaysByCode.fri,
  weekDaysByCode.sat,
  weekDaysByCode.sun,
];

export const getWeekDayForCode = (code: WeekDayCode): WeekDay =>
  weekDaysByCode[code];

export const getWeekDayForString = (code: string): WeekDay | undefined =>
  weekDaysByCode[code.toLowerCase() as WeekDayCode];
