import * as React from "react";
import { useCallback, useState } from "react";
import { Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { useNavigate } from "react-router-dom";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { useCreateMultiLegAgreement } from "../../common/hooks/UseCreateMultilegAgreementMutation";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { routeFactory } from "../../../../../RouteFactory";
import {
  CreateMultiLegAgreementFormModel,
  emptyMultiLegAgreementFormModel,
} from "../../common/components/edit-and-create-multi-leg-agreement-form/model/EditAndCreateMultiLegAgreementFormModel";
import { CreateMultiLegAgreementForm } from "../../common/components/edit-and-create-multi-leg-agreement-form/CreateMultiLegAgreementForm";
import { createMultiLegRouteAgreementMutationInput } from "../../common/components/edit-and-create-multi-leg-agreement-form/edit-multi-leg-agreement-form-sections/transformers/CreateMultiLegRouteAgreementMutationInput";

interface Props {
  customerId: string;
  headAgreementId: string;
  onClickCancel?: () => void;
  initialStartDate?: Date;
  initialEndDate?: Date;
}

export const CreateMultiLegAgreement: React.FC<Props> = ({
  customerId,
  headAgreementId,
  onClickCancel,
  initialStartDate,
  initialEndDate,
}) => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<Error | undefined>();

  const [formModel, setFormModel] = useState<CreateMultiLegAgreementFormModel>({
    ...emptyMultiLegAgreementFormModel,
    headAgreementId,
    valid: {
      startDate: initialStartDate,
      endDate: initialEndDate,
    },
  });

  const setFields = useCallback(
    (fields: Partial<CreateMultiLegAgreementFormModel>) => {
      setFormModel({ ...formModel, ...fields });
    },
    [setFormModel, formModel]
  );

  const { createMultiLegRouteAgreement, loading, error } =
    useCreateMultiLegAgreement();

  const onSubmitHandler = async () => {
    setValidationError(undefined);
    try {
      const createMultiLegRouteAgreementInput =
        createMultiLegRouteAgreementMutationInput(formModel);

      if (createMultiLegRouteAgreementInput) {
        const { data } = await createMultiLegRouteAgreement({
          variables: { createMultiLegRouteAgreementInput },
        });

        const createResult =
          data?.productPrice.multiLegRouteAgreement
            .createMultiLegRouteAgreement;

        if (createResult && "errors" in createResult) {
          setValidationError(new Error(createResult.errors.join("\n")));
        } else if (createResult) {
          navigate(
            routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
              {
                customerId,
                headAgreementId,
                multiLegAgreementId: createResult.multiLegRouteAgreement.id,
              }
            ),
            {
              replace: true,
            }
          );
        }
      }
    } catch (e) {
      setValidationError(e);
    }
  };

  return (
    <Column spacing indent>
      <CreateMultiLegAgreementForm formData={formModel} setFields={setFields} />
      <Spacing />
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <ValidationErrorMessage
          error={error || validationError}
          width={"260px"}
        />
        <Row alignItems={"center"}>
          <FlatButton label={"Cancel"} onClick={onClickCancel} />
          <Indent />
          <PrimaryButton
            label={"Create agreement"}
            onClick={onSubmitHandler}
            loading={loading}
          />
        </Row>
      </Row>
      <Space num={2} />
    </Column>
  );
};
