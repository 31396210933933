import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { Space } from "@stenajs-webui/core";
import {
  Card,
  CardHeader,
  FlatButton,
  stenaArrowCircleUp,
} from "@stenajs-webui/elements";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import * as React from "react";
import { SurchargeDocumentsActionPanel } from "./components/SurchargeDocumentsActionPanel";
import { SurchargeDocumentsPageHeader } from "./components/SurchargeDocumentsPageHeader";
import {
  SurchargeDocumentsTableColumns,
  surchargeDocumentsTableConfig,
} from "./config/SurchargeDocumentsTableConfig";
import { useMemo, useState } from "react";
import {
  ApiErrorResult,
  SurchargeDocumentsUploadModal,
} from "./components/SurchargeDocumentsUploadModal";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { SurchargeDocumentsQuery } from "@/gql/graphql";

export const surchargeDocumentsQuery = gql`
  query SurchargeDocuments {
    productPrice {
      additionalCharges {
        all {
          id
          seaArea {
            name
            code
          }
          currency
          fileName
          validFrom
          createdBy
          createdDate
        }
      }
    }
  }
`;

export const SurchargeDocumentsView: React.FC = () => {
  const { data, loading, error, refetch } = useQuery<SurchargeDocumentsQuery>(
    surchargeDocumentsQuery
  );
  const additionalCharges = data?.productPrice.additionalCharges.all;
  const [validationError, setValidationError] = useState<ApiErrorResult>();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const { tableContext } = useLocalStateTableContext(
    "surcharge-documents-table",
    createStandardTableInitialState<SurchargeDocumentsTableColumns>()
  );
  const selectedIds = tableContext.state.selectedIds.selectedIds;

  const config = useMemo(() => {
    return surchargeDocumentsTableConfig(setValidationError);
  }, [setValidationError]);

  return (
    <>
      <SurchargeDocumentsPageHeader validationError={validationError} />
      <PageLayout>
        <Card>
          <CardHeader
            text="All documents"
            contentRight={
              <FlatButton
                label="Upload"
                leftIcon={stenaArrowCircleUp}
                onClick={() => setUploadModalOpen(true)}
              />
            }
          />
          {selectedIds.length > 0 && (
            <SurchargeDocumentsActionPanel
              refetchSurchargeDocuments={refetch}
              numItemsSelected={selectedIds.length}
              tableContext={tableContext}
            />
          )}
          <StandardTable
            config={config}
            items={additionalCharges}
            noItemsLabel={"No documents found."}
            loading={loading}
            error={error}
            tableContext={tableContext}
          />
          <Space />
        </Card>
      </PageLayout>
      <SurchargeDocumentsUploadModal
        isModalOpen={uploadModalOpen}
        setIsModalOpen={setUploadModalOpen}
        refetchSurchargeDocuments={refetch}
        onRequestClose={() => setUploadModalOpen(false)}
        setValidationErrors={setValidationError}
      />
    </>
  );
};
