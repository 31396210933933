import { Box, Indent, Space } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import * as React from "react";
import { FormulaTypeSelect } from "../../../../../../../common/components/data-driven-inputs/selects/formula-type-select/FormulaTypeSelect";
import { FormulaFormInputs } from "./FormulaFormInputs";
import { FormulaFormInputsQuery } from "@/gql/graphql";

interface Props {
  articleTypeId: string;
  formulaTypeId?: string;
  articleId: string;
  onChangeFormulaTypeId: (formulaTypeId: string | undefined) => void;
  formulaType:
    | FormulaFormInputsQuery["productPrice"]["formula"]["byId"]
    | null
    | undefined;
}

export const FormulaPane: React.FC<Props> = ({
  articleTypeId,
  formulaTypeId,
  articleId,
  onChangeFormulaTypeId,
  formulaType,
}) => {
  return (
    <>
      <Indent num={0}>
        <Label text={"Select formula"}>
          <Box width={"280px"}>
            <FormulaTypeSelect
              value={formulaTypeId}
              onValueChange={onChangeFormulaTypeId}
            />
          </Box>
        </Label>

        <Space num={2} />

        {formulaTypeId && formulaType && (
          <>
            <FormulaFormInputs
              articleTypeId={articleTypeId}
              articleId={articleId}
              formulaType={formulaType}
            />
          </>
        )}
      </Indent>
    </>
  );
};
