import { Box, Indent, Text } from "@stenajs-webui/core";
import {
  StandardTable,
  StandardTableConfig,
  createColumnConfig,
} from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import React from "react";
import { routeFactory } from "../../../../../../../../RouteFactory";
import { HeadAgreementStatusCodeChip } from "../../../../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { Link } from "../../../../../../../../common/navigation/Link";
import { MultiLegAgreement } from "../../../../types";
import { transformMultiLegAgreementsToTableColumns } from "../transformers/transformers";
import { MultiLegRouteAgreementActionButtons } from "./MultiLegAgreementActionButtons";
import { MultiLegAgreementRouteAgreementsTable } from "./MultiLegAgreementRouteAgreementsTable";

export interface ExistingMultiLegAgreementTableColumns
  extends MultiLegAgreement {
  customerId: string;
  headAgreementId: string;
  year: number;
  multiLegRouteCode: string;
  id: string;
  salesReady: string;
  number: number | null;
  name: string;
  validity: string;
  agrCurrency: string;
}

const config: StandardTableConfig<
  ExistingMultiLegAgreementTableColumns,
  | keyof Pick<
      ExistingMultiLegAgreementTableColumns,
      | "multiLegRouteCode"
      | "salesReady"
      | "statusCode"
      | "number"
      | "name"
      | "validity"
      | "agrCurrency"
    >
  | "actions"
> = {
  keyResolver: (item) => String(item.id),
  enableExpandCollapse: true,
  showHeaderExpandCollapse: true,
  disableSorting: true,
  rowBackgroundResolver: (item) =>
    false ? cssColor("--lhds-color-green-50") : undefined,
  columns: {
    multiLegRouteCode: createColumnConfig((item) => item, {
      renderCell: ({ label, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
              {
                customerId: item.customerId,
                headAgreementId: item.headAgreementId,
                multiLegAgreementId: item.id,
              }
            )}
          >
            {item.multiLegRouteCode}
          </Link>
        </Indent>
      ),
      columnLabel: "ML Route",
      minWidth: "97px",
    }),
    salesReady: createColumnConfig((item) => item.salesReady, {
      columnLabel: "Sales ready",
      minWidth: "110px",
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Text>{value}</Text>
        </Indent>
      ),
    }),
    statusCode: createColumnConfig((item) => item.statusCode, {
      renderCell: ({ label, value }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={value} />
        </Indent>
      ),
      minWidth: "90px",
      columnLabel: "Status",
    }),
    number: createColumnConfig((item) => item.number ?? "", {
      columnLabel: "Number",
      minWidth: "80px",
    }),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
      minWidth: "250px",
    }),
    validity: createColumnConfig((item) => item.validity, {
      columnLabel: "Validity",
      minWidth: "180px",
    }),
    agrCurrency: createColumnConfig((item) => item.currency.code, {
      columnLabel: "Currency",
      minWidth: "80px",
    }),
    actions: createColumnConfig((item) => item, {
      justifyContentCell: "flex-left",
      columnLabel: "",
      renderCell: ({ label, item }) => (
        <MultiLegRouteAgreementActionButtons
          multiLegAgreement={item}
          year={item.year}
        />
      ),
      width: "76px",
      disableGridCell: true,
    }),
  },
  renderRowExpansion: (item) => (
    <Box background={cssColor("--lhds-color-ui-50")}>
      <MultiLegAgreementRouteAgreementsTable
        routeAgreements={item.routeAgreements}
        year={item.year}
        multiLegAgreementId={item.id}
      />
    </Box>
  ),
  columnOrder: [
    "multiLegRouteCode",
    "salesReady",
    "statusCode",
    "number",
    "name",
    "validity",
    "agrCurrency",
    "actions",
  ],
};
interface Props {
  customerId: string;
  multiLegAgreements: Array<MultiLegAgreement>;
  headAgreementId: string;
  year: number;
}

export const ExistingMultiLegAgreementList: React.FC<Props> = ({
  multiLegAgreements,
  customerId,
  headAgreementId,
  year,
}) => {
  const sortedMultiLegAgreements = [...multiLegAgreements].sort((a, b) => {
    return a.route.code.localeCompare(b.route.code);
  });

  return (
    <Box>
      <Text
        variant={"bold"}
        size={"small"}
        color={cssColor("--lhds-color-ui-600")}
      >
        {"ALL ROUTES"}
      </Text>
      <Box overflowX={"auto"}>
        <Box display={"table"}>
          <StandardTable
            config={config}
            items={transformMultiLegAgreementsToTableColumns(
              sortedMultiLegAgreements,
              customerId,
              headAgreementId,
              year
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
