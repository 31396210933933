import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { RouteQuery, RouteQueryVariables } from "@/gql/graphql";

export const useRoute = (routeCode: string | undefined) => {
  const query = gql`
    query Route($input: ID!) {
      productPrice {
        route {
          byId(id: $input) {
            id
            name
          }
        }
      }
    }
  `;

  const { data, loading, error } = useQuery<RouteQuery, RouteQueryVariables>(
    query,
    {
      variables: {
        input: routeCode ?? "",
      },
    }
  );
  return { data, loading, error };
};
