import { DateRangeDualTextInput } from "@stenajs-webui/calendar";
import {
  Box,
  Heading,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
  Txt,
} from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { TextArea, TextInput } from "@stenajs-webui/forms";
import * as React from "react";
import { HeadAgreementFormModel } from "../models/HeadAgreementFormModel";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import {
  ByCustomerNumberOrIndexFragment,
  CopyHeadAgreementTargetCustomerQuery,
} from "@/gql/graphql";

interface Props {
  value: HeadAgreementFormModel;
  onValueChange: (value: HeadAgreementFormModel) => void;
  isUpdateForm?: boolean;
  targetHeadAgreement?: CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"];
  targetCustomer?: ByCustomerNumberOrIndexFragment;
  copyHaToOtherCustomer?: boolean;
}
export const HeadAgreementForm: React.FC<Props> = ({
  value,
  onValueChange,
  targetHeadAgreement,
  targetCustomer,
  copyHaToOtherCustomer,
}) => {
  return (
    <>
      {copyHaToOtherCustomer ? (
        <>
          <Box>
            <Heading variant="h5">Customer</Heading>
            <Spacing num={0.5} />
            <Txt>{`${targetCustomer?.name ?? ""} (${
              targetCustomer?.custNo ?? ""
            } - ${targetCustomer?.custIndex ?? ""})`}</Txt>
          </Box>
          <Spacing num={2} />
          {!targetHeadAgreement && (
            <>
              <Heading variant="h5">New header agreement</Heading>
              <Spacing num={0.5} />
            </>
          )}
        </>
      ) : null}

      <Row>
        <Label text={"Name"} maxCharacters={50}>
          <Row width={"300px"} height={"34px"}>
            <TextInput
              value={value.name}
              variant={value.name === "" ? "error" : "standard"}
              maxLength={50}
              onValueChange={(name) => onValueChange({ ...value, name })}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              data-testid={testIdConstantsWebapp.nameInput}
            />
          </Row>
        </Label>
        <Indent num={2} />

        <Label text={"Validity"}>
          <Box>
            <DateRangeDualTextInput
              value={value.valid}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              onValueChange={(valid) => onValueChange({ ...value, valid })}
            />
          </Box>
        </Label>
        <Indent num={2} />
        {value.headAgreementNumber && (
          <>
            <Label text={"Agr. number"}>
              <Box justifyContent={"center"} height={"34px"}>
                <Text>{value.headAgreementNumber}</Text>
              </Box>
            </Label>
            <Indent num={2} />
          </>
        )}
      </Row>
      <Spacing num={2} />
      <Row>
        <Box flex={1}>
          <Label
            text={"Agreement report specifications area"}
            optional
            maxCharacters={150}
          >
            <TextArea
              rows={5}
              value={value.specifications}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              onValueChange={(specifications) =>
                onValueChange({ ...value, specifications })
              }
              maxLength={150}
              data-testid={
                testIdConstantsWebapp.agreementReportSpecificationsArea
              }
            />
          </Label>
        </Box>
        <Indent />
        <Box flex={1}>
          <Label
            text={"Agreement report condition area"}
            optional
            maxCharacters={150}
          >
            <TextArea
              rows={5}
              value={value.condition}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              onValueChange={(condition) =>
                onValueChange({ ...value, condition })
              }
              maxLength={150}
              data-testid={testIdConstantsWebapp.agreementReportConditionArea}
            />
          </Label>
        </Box>
      </Row>
      <Space num={4} />
      <Row>
        <Box flex={1}>
          <Label text={"Agreement report last page area"} optional>
            <TextArea
              rows={5}
              value={value.lastPage}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              onValueChange={(lastPage) =>
                onValueChange({ ...value, lastPage })
              }
              data-testid={testIdConstantsWebapp.agreementReportLastPageArea}
            />
          </Label>
        </Box>
        <Indent />
        <Box flex={1} display={"inline-flex"}>
          <Label text={"Internal notes"} optional>
            <TextArea
              rows={5}
              value={value.internalNotes}
              disabled={
                (targetHeadAgreement && copyHaToOtherCustomer) ?? undefined
              }
              onValueChange={(internalNotes) =>
                onValueChange({ ...value, internalNotes })
              }
              data-testid={testIdConstantsWebapp.InternalNotes}
            />
          </Label>
        </Box>
      </Row>
    </>
  );
};
