import { gql } from "apollo-boost";
import { Seller } from "../../types/FilterEntitys";
import { useQuery } from "@apollo/client";
import { SellersFilterQuery } from "@/gql/graphql";

const sellersFilterQuery = gql`
  query SellersFilter {
    productPrice {
      user {
        all {
          id
          name
        }
        me {
          id
          name
        }
      }
    }
  }
`;

export const useFetchAllSellers = () => {
  const { data, error, loading, refetch } = useQuery<SellersFilterQuery>(
    sellersFilterQuery,
    {
      fetchPolicy: "cache-first",
    }
  );
  const me = data?.productPrice.user.me;
  const meList = me
    ? [
        {
          name: me.name,
          id: me.id,
          code: "me",
        },
      ]
    : [];
  const sellers = data?.productPrice.user.all
    ? [
        ...data?.productPrice.user.all.map<Seller>((r) => ({
          id: r.id,
          code: r.id,
          name: r.name,
        })),
        ...meList,
      ]
    : [];
  return { sellers, error, loading, refetch };
};
