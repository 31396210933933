import { MatrixFormItem, MatrixFormModel } from "./MatrixFormModel";
import {
  getModifiedOrNull,
  getPersistedDeletedDiff,
  getRequiredValidDatesInputOrNull,
} from "../../utils/InputTransformerHelpers";
import { MatrixFormInput, MatrixItemChangesInput } from "@/gql/graphql";

export const transformMatrixFormToInput = (
  formModel: MatrixFormModel,
  persisted: MatrixFormModel | undefined
): MatrixFormInput => {
  const { created, deleted, rest } = getPersistedDeletedDiff(
    formModel.matrices
  );
  return {
    created: created.map((m) => transformMatrixItem(m, undefined)),
    deleted: deleted.map((m) => ({ id: m.id })),
    updated: rest.map((m) => ({
      id: m.id,
      changes: transformMatrixItem(
        m,
        persisted?.matrices.find((p) => p.id === m.id)
      ),
    })),
  };
};

const transformMatrixItem = (
  formModel: MatrixFormItem,
  persisted: MatrixFormItem | undefined
): MatrixItemChangesInput => {
  return {
    matrixTypeId: getModifiedOrNull(formModel.matrixId, persisted?.matrixId),
    valid: getRequiredValidDatesInputOrNull(formModel, persisted),
  };
};
