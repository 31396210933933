import { StoreState } from "../../../../config/redux/RootReducer";
import {
  NslhSearchInvoicesByFilterData,
  NslhSearchInvoicesByInvoiceNoData,
  NslhSearchInvoicesCustomerData,
  NslhSearchInvoicesCustomerSelectedData,
  NslhSearchInvoicesDateRangeInput,
  NslhSearchInvoicesError,
  NslhSearchInvoicesInvoiceNoInput,
} from "./reducers";

export const createNoShowLateHandlingSearchInvoicesStateSelectors = {
  getNslhSearchInvoiceCustomerData: (
    state: StoreState
  ): NslhSearchInvoicesCustomerData => {
    return state.noShowLateHandlingSearchInvoices.nslhSearchInvoiceCustomerData;
  },

  getNslhSearchInvoicesByFilterData: (
    state: StoreState
  ): NslhSearchInvoicesByFilterData => {
    return state.noShowLateHandlingSearchInvoices
      .nslhSearchInvoicesByFilterData;
  },

  getNslhSearchInvoicesByInvoiceNoData: (
    state: StoreState
  ): NslhSearchInvoicesByInvoiceNoData => {
    return state.noShowLateHandlingSearchInvoices
      .nslhSearchInvoicesByInvoiceNoData;
  },

  getNslhSearchInvoicesInvoiceNoInput: (
    state: StoreState
  ): NslhSearchInvoicesInvoiceNoInput => {
    return state.noShowLateHandlingSearchInvoices
      .nslhSearchInvoicesInvoiceNoInput;
  },

  getNslhSearchInvoicesCustomerSelectedData: (
    state: StoreState
  ): NslhSearchInvoicesCustomerSelectedData => {
    return state.noShowLateHandlingSearchInvoices
      .nslhSearchInvoicesCustomerSelectedData;
  },

  getNslhSearchInvoicesDateRangeInput: (
    state: StoreState
  ): NslhSearchInvoicesDateRangeInput => {
    return state.noShowLateHandlingSearchInvoices
      .nslhSearchInvoicesDateRangeInput;
  },

  getNslhSearchInvoicesLoading: (state: StoreState): boolean => {
    return state.noShowLateHandlingSearchInvoices.nslhSearchInvoicesLoading;
  },

  getNslhSearchInvoicesError: (state: StoreState): NslhSearchInvoicesError => {
    return state.noShowLateHandlingSearchInvoices.nslhSearchInvoicesError;
  },
};
