import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { getYearFromDateString } from "@/common/dates/util";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { useMutation } from "@apollo/client";
import { Row, Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
  SecondaryButton,
} from "@stenajs-webui/elements";
import {
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { LoadingModalWithLock } from "../../../../../common/components/loading-modals/LoadingModalWithLock";
import { PageSubHeader } from "../../../../../common/components/page-header/PageSubHeader";
import { BannerErrorWithLinks } from "../../../../../common/error/BannerErrorWithLinks";
import { useLocalErrorWithPath } from "../../../../../common/error/UseLocalError";
import { transformFreightPricingPortalErrorToErrorWithPaths } from "../../../../../common/error/transformers/TransformFreightPricingPortalErrorToErrorWithPaths";
import { isDefined } from "../../../../../common/types/IsDefined";
import { useSetNegotiationStatus } from "../../../customers/customer-negotiation-status/hooks/UseSetNegotiationStatusMutation";
import { SelectedMultiLegAgreementColumns } from "../../copy/components/MultiLegAgreements";
import { SelectedRouteAgreementColumns } from "../../copy/components/SelectRouteAgreements";
import { ActivateMultiLegAgreementsTable } from "../config/ActivateHeadAgreementMultiLegTableConfig";
import { ActivateRouteAgreementsTable } from "../config/ActivateHeadAgreementTableConfig";
import { activateHeadAgreementStatusConfirmationActions } from "../redux";
import { ActivateHeadAgreementHeader } from "./ActivateHeadAgreementHeader";
import { ActivateHeadAgreementInformationSection } from "./ActivateHeadAgreementInformationSection";
import {
  ActivateHeadAgrHeadAgrFragment,
  ActivateHeadAgreementMutation,
  ActivateHeadAgreementMutationVariables,
  ActivateHeadAgreementRouteAgreementsFragment,
  CustomerListNegotationStatusByYearFragment,
  NegotiationStatusCode,
  RouteAgreementsFragment,
} from "@/gql/graphql";

export type SectionalRouteAgreements = (
  | RouteAgreementsFragment
  | ActivateHeadAgreementRouteAgreementsFragment
)[];

export const NEGOTIATION_STATUS_DONE: CustomerListNegotationStatusByYearFragment =
  {
    id: NegotiationStatusCode.D,
    name: "Done",
    presentationOrder: null,
  };

const activateHeadAgreementMutation = gql`
  fragment ActivateHeadAgreementSuccessHeadAgreement on HeadAgreement {
    id
    statusCode
    rowVersion
    routeAgreements {
      id
      statusCode
      rowVersion
    }
  }
  mutation ActivateHeadAgreement(
    $headAgreementId: ID!
    $routeAgreements: [ActivateRouteAgreementInput!]
    $multiLegAgreements: [ActivateMultiLegAgreementInput!]
    $rowVersion: String!
  ) {
    productPrice {
      headAgreement {
        activateHeadAgreement(
          headAgreementId: $headAgreementId
          routeAgreements: $routeAgreements
          multiLegAgreements: $multiLegAgreements
          rowVersion: $rowVersion
        ) {
          ... on ActivateHeadAgreementSuccessResult {
            headAgreement {
              ...ActivateHeadAgreementSuccessHeadAgreement
            }
          }
          ... on ErrorResult {
            message
            statusCode
            description
            errors {
              message
              path
            }
          }
        }
      }
    }
  }
`;

export type AgrRoute = {
  id: string;
  routes: Route[];
};
export type Route = {
  id: string;
  code: string;
  routePair: RoutePair;
};

export type RoutePair = {
  code: string;
};

interface Props {
  headAgreement: ActivateHeadAgrHeadAgrFragment;
}
export const ActivateHeadAgreementContainer: React.FC<Props> = ({
  headAgreement,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localErrorWithPath, setLocalErrorWithPath } = useLocalErrorWithPath();
  const [mutate, { loading, called }] = useMutation<
    ActivateHeadAgreementMutation,
    ActivateHeadAgreementMutationVariables
  >(activateHeadAgreementMutation);

  const { setNegotiationStatus } = useSetNegotiationStatus();

  const sectionalRouteAgreements = headAgreement.routeAgreements.filter(
    (ra) => !ra.multiLegAgrRouteId
  );

  const initialState = useMemo(
    () =>
      createStandardTableInitialState<SelectedRouteAgreementColumns>(
        undefined,
        undefined,
        headAgreement.routeAgreements.map((ra) => ra.id),
        undefined
      ),
    [headAgreement.routeAgreements]
  );

  const initialMultiLegState = useMemo(
    () =>
      createStandardTableInitialState<SelectedMultiLegAgreementColumns>(
        undefined,
        undefined,
        headAgreement.multiLegAgrRoutes.map((mlAgreement) => mlAgreement.id),
        undefined
      ),
    [headAgreement.multiLegAgrRoutes]
  );

  const { tableContext } =
    useLocalStateTableContext<SelectedRouteAgreementColumns>(
      "activate-head-agreement",
      initialState
    );

  const { tableContext: multiLegTableContext } =
    useLocalStateTableContext<SelectedMultiLegAgreementColumns>(
      "activate-head-agreement-multi-leg",
      initialMultiLegState
    );

  const handleSubmit = async () => {
    setLocalErrorWithPath(undefined);
    const selectedIds = tableContext.state.selectedIds.selectedIds;
    const selectedMultiLegIds =
      multiLegTableContext.state.selectedIds.selectedIds;

    if (selectedIds.length < 1 && selectedMultiLegIds.length < 1) {
      setLocalErrorWithPath([
        {
          detailText:
            "At least one multi leg or route agreement needs to be selected.",
        },
      ]);
      return;
    }

    const routeAgreements = selectedIds
      .map((id) => {
        const rowVersion = sectionalRouteAgreements.find(
          (ra) => ra.id === id
        )?.rowVersion;

        if (!rowVersion) {
          return null;
        }
        return {
          id,
          rowVersion: rowVersion,
        };
      })
      .filter(isDefined);

    const multiLegAgreements = selectedMultiLegIds
      .map((id) => {
        const rowVersion = headAgreement.multiLegAgrRoutes.find(
          (multiLegAgrRoute) => multiLegAgrRoute.id === id
        )?.rowVersion;

        if (!rowVersion) {
          return null;
        }
        return {
          id,
          rowVersion: rowVersion,
        };
      })
      .filter(isDefined);

    const variables: ActivateHeadAgreementMutationVariables = {
      headAgreementId: headAgreement.id,
      rowVersion: headAgreement.rowVersion,
      routeAgreements: routeAgreements.length ? routeAgreements : null,
      multiLegAgreements: multiLegAgreements.length ? multiLegAgreements : null,
    };

    try {
      const response = await mutate({
        variables,
      });
      const activateResult =
        response.data?.productPrice.headAgreement.activateHeadAgreement;
      if (activateResult && "errors" in activateResult) {
        setLocalErrorWithPath(
          transformFreightPricingPortalErrorToErrorWithPaths(
            activateResult
          ) ?? [
            {
              detailText: "Something went wrong. Please try again",
            },
          ]
        );
      } else {
        await navigate(
          routeFactory.productAndPrice.customer.customerHeadAgreement({
            customerId: headAgreement.customer.id,
            headAgreementId: headAgreement.id,
          })
        );
        await setStatusToDone();
      }
    } catch (error) {
      setLocalErrorWithPath([
        {
          detailText: "Something went wrong. Please try again",
        },
      ]);
    }
  };

  const setStatusToDone = async () => {
    try {
      const customerYear = getYearFromDateString(
        headAgreement.valid.start?.isoString
      );
      await setNegotiationStatus(
        [headAgreement.customer.id],
        customerYear,
        NEGOTIATION_STATUS_DONE
      );
      dispatch(
        activateHeadAgreementStatusConfirmationActions.setStatusActivationState(
          {
            modalOpen: true,
          }
        )
      );
    } catch (error) {
      setLocalErrorWithPath([
        {
          detailText: "Could not change status for customer",
        },
      ]);
    }
  };

  return (
    <>
      <ActivateHeadAgreementHeader
        headAgreement={headAgreement}
        customer={headAgreement.customer}
      />
      <PageLayout>
        <Card>
          <CardHeader text={"Options"} />
          <CardBody>
            <ActivateHeadAgreementInformationSection
              headAgreement={headAgreement}
            />
            <PageSubHeader title={"Select route agreements to include"} />

            <ActivateRouteAgreementsTable
              routeAgreements={sectionalRouteAgreements}
              tableContext={tableContext}
            />

            <PageSubHeader title={"Select multi leg agreements to include"} />

            <ActivateMultiLegAgreementsTable
              multiLegAgreements={headAgreement.multiLegAgrRoutes}
              tableContext={multiLegTableContext}
            />
            {loading && (
              <LoadingModalWithLock
                label={"Activating header agreement. Thanks for your patience."}
              />
            )}

            {localErrorWithPath && (
              <>
                <Space num={SpaceValues.SIXTEEN} />
                <Row justifyContent={"flex-end"}>
                  {localErrorWithPath && (
                    <BannerErrorWithLinks
                      headerText={"Unable to activate header agreement"}
                      errors={localErrorWithPath}
                      sectionRouteAgreements={sectionalRouteAgreements}
                    />
                  )}
                </Row>
              </>
            )}
            <Space num={SpaceValues.SIXTEEN} />
            <Row alignItems={"center"} justifyContent={"flex-end"}>
              <SecondaryButton label={"Cancel"} onClick={() => navigate(-1)} />
              <Space num={SpaceValues.SIXTEEN} />
              <PrimaryButton
                label={"Activate agreement"}
                onClick={handleSubmit}
                success={called && !loading && !localErrorWithPath}
              />
            </Row>
            <Space num={SpaceValues.SIXTEEN} />
          </CardBody>
        </Card>
      </PageLayout>
    </>
  );
};
