import * as React from "react";
import { Helmet } from "react-helmet";
import { RateSheetTable } from "../../../../../rate-sheet/components/RateSheetTable";
import { RouteAgreementDetailsStateFragment } from "@/gql/graphql";

interface Props {
  routeAgreementId: string;
  headAgreementId: string;
  routePairId: string;
  currencyLabel: string | undefined;
  customerId: string;
  routeAgreement: RouteAgreementDetailsStateFragment;
}

export const MultiLegRatesPanel: React.FC<Props> = ({
  customerId,
  ...props
}) => {
  return (
    <>
      <Helmet title="Rates - Route agreement - FPP" />
      <RateSheetTable
        {...props}
        bulkUpdateModeEnabled={false}
        disableStatBoxes
        isMultiLegRates
      />
    </>
  );
};
