import { Indent, Row, Space, Text } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import React from "react";
import { CreateMultiLegAgreementRouteFormModel } from "../../../../features/product-and-price/multileg-agreement/common/components/create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  setFields: (value: Partial<CreateMultiLegAgreementRouteFormModel>) => void;
  formData: CreateMultiLegAgreementRouteFormModel;
}

export const SailingCategorySelectionRow: React.FC<Props> = ({
  formData,
  setFields,
}) => {
  const otherThanSpecialIsSelected =
    formData.peak || formData.offPeak || formData.shoulder;

  return (
    <Label text={"Sailing cat."}>
      <Row alignItems={"center"}>
        <CheckboxWithLabel
          label={"Peak"}
          value={formData.peak}
          disabled={formData.special}
          onValueChange={(peak) => setFields({ peak })}
        />
        <Space num={3} />
        <CheckboxWithLabel
          label={"Off-Peak"}
          value={formData.offPeak}
          disabled={formData.special}
          onValueChange={(offPeak) => setFields({ offPeak })}
        />
        <Space num={3} />
        <CheckboxWithLabel
          label={"Shoulder"}
          value={formData.shoulder}
          disabled={formData.special}
          onValueChange={(shoulder) => setFields({ shoulder })}
        />
        <Indent num={3}>
          <Text color={cssColor("--lhds-color-ui-500")}>or</Text>
        </Indent>
        <CheckboxWithLabel
          label={"Special"}
          value={formData.special}
          disabled={otherThanSpecialIsSelected}
          onValueChange={(special) => setFields({ special })}
        />
      </Row>
    </Label>
  );
};
