import * as React from "react";
import { SelectProps } from "@stenajs-webui/select";
import { Column, Indent, Space, Text } from "@stenajs-webui/core";
import { NegotiationYearSelect } from "../negotiation-year-select/NegotiationYearSelect";
import { ApolloError } from "apollo-boost";
import { cssColor } from "@stenajs-webui/theme";
import { ZIndex } from "@/common/utils/ZIndexEnum";

export interface AdminTargetNegotiationYearSelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: number;
  heading?: string;
  selectLabel?: string;
  onSelectYearChange: (value: number) => void;
  defaultYearSelectionLoading: boolean;
  defaultYearSelectionError: ApolloError | undefined;
}

export const AdminTargetNegotiationYearSelect: React.FC<
  AdminTargetNegotiationYearSelectProps
> = ({
  onSelectYearChange,
  value,
  selectLabel,
  defaultYearSelectionLoading,
  defaultYearSelectionError,
  ...selectProps
}) => {
  return (
    <Column display="flex" flexDirection="row" style={{ zIndex: ZIndex.low }}>
      <Column
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Text size="small" color={cssColor("--lhds-color-ui-500")}>
          {selectLabel}
        </Text>
        <Space />
      </Column>
      <Indent num={0.5} />
      <Column width={125}>
        <NegotiationYearSelect
          value={value}
          {...selectProps}
          onValueChange={onSelectYearChange}
          additionalLoading={defaultYearSelectionLoading}
        />
      </Column>
    </Column>
  );
};
