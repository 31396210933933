import { Space } from "@stenajs-webui/core";
import { Chip, Tag } from "@stenajs-webui/elements";
import * as React from "react";
import { NegotiationStatusItem } from "../neg-status-chip-multi-select/NegStatusChipMultiSelect";
import { Fragment } from "react";

interface Props {
  selectedStatuses: NegotiationStatusItem[];
  onClickRemove: (selectedStatus: NegotiationStatusItem) => void;
}

export const NegotiationStatusChips: React.FC<Props> = ({
  selectedStatuses,
  onClickRemove,
}) => {
  return (
    <>
      {selectedStatuses
        .map((selectedStatus, key) => {
          return (
            <Fragment key={key}>
              <Chip
                label={selectedStatus.label}
                onClickRemove={() => onClickRemove(selectedStatus)}
              />
              <Space horizontal />
            </Fragment>
          );
        })
        .slice(0, 2)}
      {selectedStatuses.length > 2 && (
        <Tag label={`+${selectedStatuses.length - 2}`} size="small" />
      )}
    </>
  );
};
