import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { sum } from "lodash";
import { StoreState } from "../../../../config/redux/RootReducer";

export enum CheckboxState {
  CHECKED = 0,
  UNCHECKED = 1,
  PARTIALLY_CHECKED = 2,
}

export const createNoShowLateHandlingBookingSelectionStateSelectors = {
  getNoShowLateHandlingBookingSelectionState: (
    state: StoreState,
    customerId: string,
    bookingNo: number
  ): boolean => {
    return (
      state.noShowLateHandlingBookingAndCheckboxSelection
        .checkBoxSelectionState[customerId]?.[bookingNo] ?? false
    );
  },
  getNoShowLateHandlingCustomerSelectionState: (
    state: StoreState,
    customerId: string,
    bookingNos: Array<number>
  ): CheckboxState => {
    const amountOfSelectedBookings = bookingNos.filter(
      (bn) =>
        state.noShowLateHandlingBookingAndCheckboxSelection
          .checkBoxSelectionState[customerId]?.[bn]
    ).length;

    if (bookingNos.length === amountOfSelectedBookings) {
      return CheckboxState.CHECKED;
    } else if (amountOfSelectedBookings > 0) {
      return CheckboxState.PARTIALLY_CHECKED;
    }

    return CheckboxState.UNCHECKED;
  },
  getNumberOfSelectedBookings: (state: StoreState): number => {
    const numberOfSelectedBookings = sum(
      Object.values(
        state.noShowLateHandlingBookingAndCheckboxSelection
          .checkBoxSelectionState
      ).flatMap((checkBoxStateByCustomer) => {
        return Object.values(checkBoxStateByCustomer).filter((value) => value)
          .length;
      })
    );

    return numberOfSelectedBookings;
  },
  getAreAllBookingsNoCharge: (
    state: StoreState,
    customerNo: string
  ): boolean | undefined => {
    return state.noShowLateHandlingBookingAndCheckboxSelection.noShowLateHandlingHandleBookingsData
      ?.find((data) => data.customer.custNo === Number(customerNo))
      ?.customerBookings.every(
        (booking) => booking.handlingStatus === BookingStatusOptions.NOTCHARGE
      );
  },
  getHandlingStatusForCustomerBooking: (
    state: StoreState,
    customerNo: string,
    bookingNo: number
  ): string | undefined => {
    return state.noShowLateHandlingBookingAndCheckboxSelection.noShowLateHandlingHandleBookingsData
      ?.find((data) => data.customer.custNo === Number(customerNo))
      ?.customerBookings.find((booking) => booking.bookingNo === bookingNo)
      ?.handlingStatus;
  },
};
