import { cssColor } from "@stenajs-webui/theme";
import { Heading, Indent, Row, Txt } from "@stenajs-webui/core";
import { Icon, stenaSailingRoundTrip } from "@stenajs-webui/elements";

export const routePairLabelWithDescriptionForTables = (
  routePairDescription: string
) => {
  const routePairParts = routePairDescription?.split("<->");
  const icon = (
    <Icon
      icon={stenaSailingRoundTrip}
      color={cssColor("--lhds-color-ui-700")}
    />
  );

  if (routePairDescription && !routePairDescription.includes("<->")) {
    return <Txt>{routePairDescription}</Txt>;
  }
  if (!routePairDescription) {
    return "";
  }

  return (
    <Row alignItems={"center"} indent>
      <Txt>{`${routePairParts[0]}`}</Txt>
      <Indent num={0.25} />
      {icon}
      <Indent num={0.25} />
      <Txt>{`${routePairParts[1]}`}</Txt>
    </Row>
  );
};
export const renderRoutePairLabelWithIcon = (
  routePairDescription: string,
  routePairCode?: string
) => {
  const routePairParts = routePairDescription?.split("<->");
  const icon = (
    <Icon
      icon={stenaSailingRoundTrip}
      color={cssColor("--lhds-color-ui-700")}
    />
  );

  if (routePairDescription && !routePairDescription.includes("<->")) {
    return <Heading>{routePairDescription}</Heading>;
  }
  if (!routePairDescription) {
    return "";
  }

  return (
    <Row alignItems={"center"}>
      {routePairCode && (
        <>
          <Heading>{routePairCode}</Heading>
          <Indent num={1} />
        </>
      )}
      <Heading>
        {`${routePairParts[0]}`}
        <Indent num={0.25} />
      </Heading>

      <Indent num={0.25} />
      {icon}
      <Indent num={0.25} />
      <Heading>{`${routePairParts[1]}`}</Heading>
    </Row>
  );
};
