import * as React from "react";
import { Window } from "@stenajs-webui/modal";
import { MissingParamBoundary } from "./MissingParamBoundary";

interface Props {
  customerId?: string;
  headAgreementId?: string;
  isOpen?: boolean;
  onRequestClose?: () => void;
}

export const CreateRouteAgreementModal: React.FC<Props> = ({
  isOpen = false,
  onRequestClose,
  ...props
}) => {
  return (
    <Window
      isOpen
      onRequestClose={onRequestClose}
      indent={0}
      spacing={0}
      width={"100%"}
      headerText="Create route agreement"
    >
      <MissingParamBoundary {...props} onRequestClose={onRequestClose} />
    </Window>
  );
};
