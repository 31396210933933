import React, { useRef } from "react";
import { Box, Row, Spacing } from "@stenajs-webui/core";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import { StandardTable } from "@stenajs-webui/grid";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import { parseTimeString } from "../../common/transformers/time";
import {
  transformAncillaryCosts,
  transformSurchargeCosts,
} from "../transformers/TransformPriceCalculatorResult";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { BookingSearchResultData } from "../../PriceCalculatorFormFetcher";
import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { transformSailingTypeCodeToSailingTypeName } from "../../../../common/transformers/SailingTypeTransformer";
import { transformPriceDetailTableRows } from "../transformers/TransformPriceDetailTableRow";
import { gtmTransmitter } from "../../../../common/utils/GtmHelper";
import { PriceCalculatorEventsAndActions } from "../../../../common/utils/price-calculator-gtm/PriceCalculatorGtmHelper";
import {
  FetchConsignmentFragment,
  FetchPriceCalcFragment,
} from "@/gql/graphql";

interface Props {
  bookingSearchResultData: BookingSearchResultData;
}
const transformQueryToPriceDetailTableRow = (
  priceCalculatorData: FetchPriceCalcFragment
): Array<PriceDetailTableRow> => {
  return [
    ...priceCalculatorData.ancillaryRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformAncillaryCosts(record, priceCalculatorData, index)
    ),
    ...priceCalculatorData.surchargeRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformSurchargeCosts(
          record,
          priceCalculatorData,
          index + priceCalculatorData.ancillaryRecords.length
        )
    ),
  ];
};

const createTripInformation = (
  sailingArchive: FetchConsignmentFragment["sailingArchive"]
) => {
  let tripInformation = `${sailingArchive.route.name}, ${
    sailingArchive.departureDate
  },
  ${parseTimeString(sailingArchive.departureTime)}`;
  if (sailingArchive.sailingType) {
    tripInformation += `, ${transformSailingTypeCodeToSailingTypeName(
      sailingArchive.sailingType
    )}`;
  }

  return tripInformation;
};

export const PriceCalculatorBookingDetailSectionalTab: React.FC<Props> = ({
  bookingSearchResultData,
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);

  const consignmentData = bookingSearchResultData.consignmentData[0];
  const priceCalculatorData = bookingSearchResultData.priceCalcData[0];
  const tripInformation = createTripInformation(consignmentData.sailingArchive);
  const vehicleInformation = `${consignmentData.vehicleType.shortDescription}, ${consignmentData.length}m`;
  const renderCopy = (html: string): string => {
    gtmTransmitter({
      event: PriceCalculatorEventsAndActions.PriceCalculatorEvent,
      actionEvent: PriceCalculatorEventsAndActions.ClickedCopyToClipboard,
    });
    if (consignmentData.vehicleType) {
      return `<b>${tripInformation}</b>
      <br>
      <br>
      <b>${vehicleInformation}</b>
      ${headingH5HolderForCopy.current?.innerHTML}
      ${html}`;
    }

    return `<b>${tripInformation}</b>
    <br>
    <br>
    <b>${vehicleInformation}</b>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  const priceDetailTableRows: PriceDetailTableRow[] =
    transformPriceDetailTableRows(
      priceCalculatorData,
      transformQueryToPriceDetailTableRow(priceCalculatorData)
    );

  return (
    <>
      <Box spacing={2} indent={3}>
        <Row justifyContent={"space-between"}>
          <Box>
            <PaddedHeading variant={"h4"}>{tripInformation}</PaddedHeading>
          </Box>

          <StandardTableHtmlCopyToClipboardButton
            config={tableConfigHtmlExport}
            items={priceDetailTableRows.filter((tr) => tr.priceExclVat > 0)}
            renderContent={renderCopy}
            size={"medium"}
          />
        </Row>
      </Box>

      <Box indent={3}>
        <PaddedHeading variant={"h4"}>{vehicleInformation}</PaddedHeading>
      </Box>
      <Row indent={3}>
        <Box ref={headingH5HolderForCopy}>
          <PaddedHeading variant={"h5"}>Articles specification</PaddedHeading>
        </Box>
      </Row>
      <StandardTable config={tableConfig} items={priceDetailTableRows} />
      <Spacing num={1} />
    </>
  );
};
