export const sortByOtherArray = <T>(
  itemsArray: Array<T>,
  sortingArr: Array<T>
) => {
  const newList = [...itemsArray];
  newList.sort(function (a, b) {
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  });
  return newList;
};
