import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Box, SeparatorLine, Space, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
} from "@stenajs-webui/elements";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { StandardTableAction, TableContext } from "@stenajs-webui/grid";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GaActionButton } from "../../../../../common/utils/tracking/enums/actions/GaActionButton";
import { trackEvent } from "../../../../../common/utils/tracking/helper/GaTracker";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { bulkEditRatesFilterSettings } from "../config/BulkEditRatesFilterConfig";
import { BulkEditRatesTableColumn } from "../config/BulkEditRatesTableConfig";
import { bulkEditRatesStandardTableActions } from "../redux/reducer";
import { bulkEditRatesSelectors } from "../redux/selectors";
import { BulkEditRatesTable } from "./BulkEditRatesTable";
import { BulkEditRatesToolbar } from "./BulkEditRatesToolbar";
import { BulkEditRatesRouteImpactTable } from "./stat-boxes/components/BulkEditRatesRouteImpactTable";
import { RouteAgreementsRatesByFilterQuery } from "@/gql/graphql";

interface Props {
  loading: boolean;
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  error: ApolloError | undefined;
}

export const BulkEditRatesTableWrapper: React.FC<Props> = ({
  loading,
  routeAgreementRates,
  error,
}) => {
  const { workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const totalNoOfRates =
    routeAgreementRates?.productPrice.routeAgreementPrice.byFilter
      .numberOfAgrRouteRates;
  const filteredNoOfRates =
    routeAgreementRates?.productPrice.routeAgreementPrice.byFilter
      .agrRoutePrices?.length;
  const dispatch = useDispatch();

  const [routeImpactOpen, setRouteImpactOpen, setRouteImpactClosed] =
    useBoolean(false);

  const [promptVisible, showPrompt, hidePrompt] = useBoolean(false);

  const selectedRowIds = useSelector(bulkEditRatesSelectors.getSelectedRowIds);

  const standardTableSelector = useCallback(
    (state: StoreState) => bulkEditRatesSelectors.getTableState(state),
    []
  );
  const standardTableState = useSelector(standardTableSelector);
  const tableContext = useMemo<TableContext<BulkEditRatesTableColumn>>(() => {
    return {
      state: standardTableState,
      actions: bulkEditRatesStandardTableActions,
      dispatch: (action: StandardTableAction<BulkEditRatesTableColumn>) => {
        dispatch(action);
      },
    };
  }, [dispatch, standardTableState]);

  const routeImpactClick = () => {
    if (routeImpactOpen) {
      trackEvent({
        action: GaActionButton.toggleOff,
      });
      setRouteImpactClosed();
    } else {
      trackEvent({
        action: GaActionButton.toggleOn,
      });
      setRouteImpactOpen();
    }
  };

  return (
    <Card>
      <CardHeader
        text={
          totalNoOfRates
            ? `${filteredNoOfRates} of ${totalNoOfRates} Route agreements rates`
            : `${filteredNoOfRates ?? 0} Route agreements rates`
        }
        contentRight={
          <FlatButton
            label={"Route impact"}
            leftIcon={faEye}
            onClick={routeImpactClick}
          />
        }
      />
      <SeparatorLine />
      {routeImpactOpen && (
        <>
          <BulkEditRatesRouteImpactTable
            routeAgreementRates={routeAgreementRates}
            loading={loading}
          />
          <SeparatorLine />
        </>
      )}
      <SearchFilterContext
        state={workspaceState}
        actions={createFppSearchFilterActions("bulkEditRatesFilter")}
        dispatch={dispatch}
      >
        {selectedRowIds.length <= 0 ? (
          <CardBody>
            {
              // FIXME: unsaved changes modal
            }
            <FppSearchFilter settings={bulkEditRatesFilterSettings} />
          </CardBody>
        ) : (
          <BulkEditRatesToolbar
            tableContext={tableContext}
            numItemsSelected={selectedRowIds.length}
            routeAgreementRates={routeAgreementRates}
            promptVisible={promptVisible}
            showPrompt={showPrompt}
            hidePrompt={hidePrompt}
          />
        )}

        <SeparatorLine />
        <Box indent={IndentValues.TWENTYFOUR}>
          <BulkEditRatesTable
            routeAgreementRates={routeAgreementRates}
            tableContext={tableContext}
            loading={loading}
            bulkUpdateModeEnabled={selectedRowIds.length > 0}
            error={error}
            promptVisible={promptVisible}
          />
          {totalNoOfRates && <SeparatorLine />}
          <Space num={SpaceValues.TWENTYFOUR} />
        </Box>
      </SearchFilterContext>
    </Card>
  );
};
