export enum AllRouteTargetChartDisplayStatusChange {
  clickEvent = "Route Target Chart Display Status Change",
  hideChart = "Hide chart",
  showChart = "Show chart",
}

export enum AllRouteTargetChartLegendDisplay {
  clickEvent = "Route Target Chart Legend Display",
}

export enum AllRoutesChartRadioSelection {
  clickEvent = "Route Target Chart Radio Click",
  netSelected = "Net price",
  grossSelected = "Gross price",
}

export enum AllRouteTargetsSingleRouteDiscardClicked {
  clickEvent = "Route Target Discard Data",
}

export enum AllRouteTargetsSingleRouteSaveClicked {
  clickEvent = "Route Target Save Data",
}
