import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { notesFormRedux } from "../NotesFormRedux";
import { NotesForm } from "./NotesForm";

interface Props {}

export const NotesPanel: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const notesFormModel = useSelector(notesFormRedux.selectors.getEntity);

  const setFields = useCallback(
    (fields) => {
      dispatch(notesFormRedux.actions.setEntityFields(fields));
    },
    [dispatch]
  );

  return (
    <>
      <Helmet title="Notes - Route agreement - FPP" />
      <Card>
        <CardHeader text={"Notes"} />
        <CardBody width={"800px"}>
          <NotesForm value={notesFormModel} setFields={setFields} />
        </CardBody>
      </Card>
    </>
  );
};
