import { formatUnacc } from "../../../../../common/formatters/AccUnaccFormatter";
import { RateSheetTableRowsState, RateSheetTableRowState } from "../../reducer";

export const rateSheetTableDataSortTransformer = (
  prices: RateSheetTableRowsState | undefined
) => {
  return prices?.slice().sort((a, b) => {
    return (
      sortByVehicleType(b, a) ||
      sortByLengthTo(b, a) ||
      sortByWeightTo(b, a) ||
      sortByCargoStatusCode(b, a) ||
      sortByUnacc(b, a)
    );
  });
};

const sortByVehicleType = (
  b: RateSheetTableRowState,
  a: RateSheetTableRowState
) => {
  const firstItemVehicle = a.persisted?.vehicleType?.id ?? "All";
  const nextItemVehicle = b.persisted?.vehicleType?.id ?? "All";

  return firstItemVehicle.localeCompare(nextItemVehicle);
};

const sortByLengthTo = (
  b: RateSheetTableRowState,
  a: RateSheetTableRowState
) => {
  return (a.persisted?.lengthTo ?? 0) - (b.persisted?.lengthTo ?? 0);
};

const sortByWeightTo = (
  b: RateSheetTableRowState,
  a: RateSheetTableRowState
) => {
  return (a.persisted?.weightTo ?? 0) - (b.persisted?.weightTo ?? 0);
};

const sortByCargoStatusCode = (
  b: RateSheetTableRowState,
  a: RateSheetTableRowState
) => {
  return (
    a.persisted?.cargoStatusCode.localeCompare(
      b.persisted?.cargoStatusCode ?? ""
    ) ?? 0
  );
};

const sortByUnacc = (b: RateSheetTableRowState, a: RateSheetTableRowState) => {
  return formatUnacc(a.persisted?.unaccompanied).localeCompare(
    formatUnacc(b.persisted?.unaccompanied)
  );
};
