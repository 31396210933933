import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { CustomerMultilegRouteFetcher } from "./CustomerMultilegRouteFetcher";
import {
  CustomerMultilegRouteFetcherQuery,
  CustomerMultilegRouteFetcherQueryVariables,
} from "@/gql/graphql";
import { HeadAgreementFragments } from "../../head-agreement/details/hooks/HeadAgreementFragments";

interface Props {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementId: string;
}

export const customerMultiLegRouteFetcherQuery = gql`
  ${HeadAgreementFragments}

  fragment RouteAgreementsForMultilegRoute on MultiLegRouteAgreement {
    id
    idNumber
    workFlowStatusCode
    statusCode
    rowVersion
    headAgreement {
      id
      name
      customer {
        id
        name
        custNo
      }
      breadCrumbName
      statusCode
      hasSomeRouteAgreementsWithMultiLeg
      valid {
        end {
          isoString
        }
        start {
          isoString
        }
      }
    }
    name
    routeLegs {
      id
      legNo
      legRoute {
        id
        code
        name
      }
    }
    routeAgreements {
      id
      rowVersion
      name
      agreementNumber
      routeDescription
      sailingTypeCodes
      statusCode
      currency {
        id
        code
      }
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
      hasConditions
      routes {
        id
        routePair {
          id
          code
        }
      }
    }
    route {
      id
      name
    }
  }

  fragment HeadAgreementForMultileg on HeadAgreement {
    id
    name
    statusCode
    breadCrumbName
    headAgreementNumber
    hasSomeRouteAgreementsWithMultiLeg
    rowVersion
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    routeAgreements {
      ...RouteAgreementFields
    }
    multiLegAgrRoutes {
      id
      name
      headAgreement {
        id
        headAgreementNumber
        customer {
          id
          name
        }
        statusCode
      }
      route {
        id
        code
        name
      }
      currency {
        id
        code
        name
        shortDescription
        longDescription
      }
      statusCode
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
      dateCreated
      createdBy
      dateModified
      modifiedBy
      idNumber
      rowVersion
      workFlowStatusCode
      routeAgreements {
        ...RouteAgreementFields
      }
      routeLegs {
        id
        legRoute {
          id
          code
          name
        }
        route {
          id
          code
          name
        }
      }
    }
  }
  query CustomerMultilegRouteFetcher(
    $multiLegAgreementId: ID!
    $headAgreementId: ID!
    $customerId: ID!
    $year: Int!
  ) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...HeadAgreementForMultileg
        }
      }
      multiLegRouteAgreement {
        byId(id: $multiLegAgreementId) {
          ...RouteAgreementsForMultilegRoute
        }
      }
    }
  }
`;

export const CustomerMultilegRouteFetcherWrapper: React.FC<Props> = ({
  headAgreementId,
  customerId,
  multiLegAgreementId,
}) => {
  const { defaultYear } = useNegotiationYears();
  const { loading, error, data } = useQuery<
    CustomerMultilegRouteFetcherQuery,
    CustomerMultilegRouteFetcherQueryVariables
  >(customerMultiLegRouteFetcherQuery, {
    variables: {
      multiLegAgreementId,
      headAgreementId,
      customerId,
      year: defaultYear,
    },
  });

  const multiLegRouteAgreement = data?.productPrice.multiLegRouteAgreement.byId;
  const headAgreement = data?.productPrice.headAgreement.byId;

  if (error) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (loading || !headAgreement || !multiLegRouteAgreement) {
    return (
      <Spacing num={8}>
        <LoadingScreen />
      </Spacing>
    );
  }

  return (
    <>
      <CustomerMultilegRouteFetcher
        headAgreement={headAgreement}
        customerId={customerId}
        multiLegRouteAgreement={multiLegRouteAgreement}
      />
    </>
  );
};
