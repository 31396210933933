import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  CanCustomerChangeRouteAgreementTypeQuery,
  CanCustomerChangeRouteAgreementTypeQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query CanCustomerChangeRouteAgreementType($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          canChangeRouteAgreementType
          defaultRouteAgreementType
          isStandardCustomer
        }
      }
    }
  }
`;

export const useCanCustomerChangeRouteAgreementTypeQuery = (
  customerId: string
) => {
  const { data, loading, error } = useQuery<
    CanCustomerChangeRouteAgreementTypeQuery,
    CanCustomerChangeRouteAgreementTypeQueryVariables
  >(query, {
    variables: {
      customerId,
    },
  });

  const canChangeRouteAgreementType =
    data?.productPrice.customers.byId?.canChangeRouteAgreementType ?? false;

  const defaultRouteAgreementType =
    data?.productPrice.customers.byId?.defaultRouteAgreementType;

  return {
    canChangeRouteAgreementType,
    defaultRouteAgreementType,
    loading,
    error,
  };
};
