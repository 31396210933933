import { ArticlesFormModel } from "../features/articles/models/ArticlesFormModel";
import { emptyArticlesFormModelInitialState } from "../features/articles/reducer";
import {
  ConditionsFormModel,
  emptyConditionsFormModel,
} from "../features/conditions/ConditionsFormModel";
import {
  emptyMatrixFormModel,
  MatrixFormModel,
} from "../features/matrix/MatrixFormModel";
import {
  emptyNotesFormModel,
  NotesFormModel,
} from "../features/notes/NotesFormModel";
import {
  emptyRoutesFormModel,
  RoutesFormModel,
} from "../features/routes/RoutesFormModel";

export interface RouteAgreementFormModel {
  articles: ArticlesFormModel;
  conditions: ConditionsFormModel;
  routes: RoutesFormModel;
  notes: NotesFormModel;
  matrix: MatrixFormModel;
}

export const emptyRouteAgreementFormModel: RouteAgreementFormModel = {
  articles: emptyArticlesFormModelInitialState,
  conditions: emptyConditionsFormModel,
  routes: emptyRoutesFormModel,
  notes: emptyNotesFormModel,
  matrix: emptyMatrixFormModel,
};
