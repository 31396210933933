import { useArraySet } from "@stenajs-webui/core";
import { stenaPerformance } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { SailingCategory } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { sortSailingCategories } from "../../utils/sortings";
import { useFetchAllSailingCategories } from "../../hooks/FilterFetchers/UseFetchAllSailingCategories";

interface Props {
  enabled?: boolean;
  label?: string;
}
export const SailingCategoryFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <SailingCategoryFilterSectionInternal {...props} /> : <></>;
const SailingCategoryFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { sailingCategories, setSailingCategories } = useFilterWorkspace();
  const {
    error,
    loading,
    refetch,
    sailingCategories: items,
  } = useFetchAllSailingCategories();

  const sortedSailingCategories = (): Array<SailingCategory> => {
    return items.sort((a, b) => {
      return sortSailingCategories(a.code, b.code);
    });
  };

  const { toggle } = useArraySet(
    sailingCategories,
    setSailingCategories,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"sailingCategories"}
      label={label}
      enabled={enabled}
      numSelected={sailingCategories.length}
      leftIcon={stenaPerformance}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {sortedSailingCategories().map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!sailingCategories.find(
                  (salingCategory) => salingCategory.id === item.id
                )
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
