import * as React from "react";
import { useCallback, useMemo } from "react";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../RouteFactory";
import { Box } from "@stenajs-webui/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  BreadCrumbs,
  ResultListBanner,
  ResultListBannerState,
  stenaBusinessAgreement,
} from "@stenajs-webui/elements";
import { StoreState } from "../../../../config/redux/RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { MultilegRoutePairSelect } from "../../../../common/components/data-driven-inputs/selects/multileg-route-pair-select/MultilegRoutePairSelect";
import { SwitchWithLabelForMultilegSalesReady } from "../../../../common/components/data-driven-inputs/switches/SwitchWithLabelForMultilegSalesReady";
import { CustomerMultilegRouteSaveButton } from "./CustomerMultilegRouteSaveButton";
import { customerMultiLegRouteRedux } from "../redux";
import { areObjectsEqual } from "../../../../common/compare/ObjectEqual";
import { uniq } from "lodash";
import {
  CustomerHeaderCustomersByIdFragment,
  HeadAgreementForMultilegFragment,
  RouteAgreementsForMultilegRouteFragment,
} from "@/gql/graphql";

interface Props {
  customer: CustomerHeaderCustomersByIdFragment;
  routePairId: string;
  headAgreement: HeadAgreementForMultilegFragment;
  multilegAgreementForRoutePair: RouteAgreementsForMultilegRouteFragment;
  loadingCustomer: boolean;
}

const errorSelector = (state: StoreState) =>
  state.customerRoute.progress.errors;

export const CustomerMultilegRouteHeader: React.FC<Props> = ({
  routePairId,
  customer,
  headAgreement,
  multilegAgreementForRoutePair,
  loadingCustomer,
}) => {
  const saveErrors = useSelector(errorSelector);
  const dispatch = useDispatch();
  const { multiLegAgreementId } = useParams() as {
    multiLegAgreementId: string;
  };

  const navigate = useNavigate();

  const customerId = customer.id;
  const headAgreementId = headAgreement.id;

  const bannerState = useMemo<ResultListBannerState | undefined>(() => {
    if (saveErrors && saveErrors.length) {
      const distinctErrorMessages = uniq(
        saveErrors.map((error) => error.message)
      );

      return {
        headerText: "Unable to save",
        items: uniq(
          distinctErrorMessages.map((e) => ({
            text: e,
          }))
        ),
      };
    }
  }, [saveErrors]);

  const multiLegAgreement = useSelector(
    (state: StoreState) => state.customerMultiLegRoute.multiLegAgreement
  );

  const setSalesReady = useCallback(
    (isSalesReady) =>
      dispatch(customerMultiLegRouteRedux.actions.setSalesReady(isSalesReady)),
    [dispatch]
  );

  const hasChanges = useMemo(
    () =>
      !areObjectsEqual(multiLegAgreement.editable, multiLegAgreement.persisted),
    [multiLegAgreement]
  );
  if (!multiLegAgreement) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={"Multi leg route rates"}
        loading={loadingCustomer}
        icon={stenaBusinessAgreement}
        contentAbove={
          bannerState ? (
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          ) : undefined
        }
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Negotiation plan"}
              path={routeFactory.productAndPrice.customer.customerList()}
            />
            <Crumb
              label={customer?.breadCrumbName ?? ""}
              path={routeFactory.productAndPrice.customer.customerHeadAgreement(
                {
                  customerId,
                  headAgreementId: headAgreementId,
                }
              )}
            />
            <Crumb
              label={`${routePairId} (${multilegAgreementForRoutePair.idNumber})`}
              path={routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
                {
                  customerId,
                  headAgreementId,
                  multiLegAgreementId: multiLegAgreementId,
                }
              )}
            />
          </BreadCrumbs>
        }
        contentLeft={
          <Box width={"170px"}>
            <MultilegRoutePairSelect
              value={multilegAgreementForRoutePair.id}
              headAgreementId={headAgreementId}
              onValueChange={(multiLegAgreementId) =>
                navigate(
                  routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
                    {
                      customerId,
                      headAgreementId,
                      multiLegAgreementId,
                    }
                  )
                )
              }
            />
          </Box>
        }
        contentRight={
          <>
            <SwitchWithLabelForMultilegSalesReady
              multilegAgreementStatusCode={
                multilegAgreementForRoutePair.statusCode
              }
              label={"Sales ready"}
              value={multiLegAgreement.editable.isSalesReady}
              onValueChange={setSalesReady}
            />
            <CustomerMultilegRouteSaveButton
              multiLegAgreementToSave={multilegAgreementForRoutePair}
              headAgreement={headAgreement}
              routePairId={routePairId}
              canSave={hasChanges}
              customerId={customerId}
            />
          </>
        }
      />
    </>
  );
};
