import { isAfter, isSameDay } from "date-fns";

export const ensureValidBreakDate = (date?: Date): Date => {
  if (!date) {
    throw new Error("Break date is required.");
  }
  let now = new Date();
  if (isSameDay(date, now)) {
    return date;
  }
  if (isAfter(date, now)) {
    return date;
  }
  throw new Error("Break date must be today or later.");
};
