import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

import { statisticsDetailsFragment } from "../../../common/fragments/DetailsFragment";
import {
  NegotiationStatusCode,
  UseRoutePairDetailsVolumeAndRatesQuery,
  UseRoutePairDetailsVolumeAndRatesQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment volumeChangeChartData on VolumeChangeChartData {
    expectedVolume {
      acc
      unacc
    }
    lastYearVolume {
      acc
      unacc
    }
  }

  fragment volumeChangeTableData on VolumeChangeTableData {
    acc {
      diff
      expected
      lastYear
    }
    total {
      diff
      expected
      lastYear
    }
    unacc {
      diff
      expected
      lastYear
    }
  }

  fragment rateChangeTableData on RateChangeTableData {
    total {
      rate
      target
    }
    acc {
      rate
      target
    }
    unacc {
      rate
      target
    }
  }

  query UseRoutePairDetailsVolumeAndRates(
    $routePairId: ID!
    $currencyCode: String!
    $negotiationStatuses: [NegotiationStatusCode!]!
    $year: Int!
  ) {
    statistics {
      routePairDetails {
        volumeAndRates(
          routePairId: $routePairId
          negotiationStatusCodes: $negotiationStatuses
          year: $year
        ) {
          ...statisticsDetailsFragment
        }
        threeYearsAverageMeterPrices(
          routePairId: $routePairId
          currencyCode: $currencyCode
          year: $year
          negotiationStatusCodes: $negotiationStatuses
        ) {
          averageMeterPriceRequestedYear
          averageMeterPriceRequestedYearMinus1
          averageMeterPriceRequestedYearMinus2
          averageMeterPriceDiffInPercentFromPreviousYear
          averageMeterPriceDiffInPercentFromPreviousYearMinus1
          averageMeterPriceDiffInPercentFromPreviousYearMinus2
        }
      }
    }
  }
  ${statisticsDetailsFragment}
`;

export const useRoutePairDetailsVolumeAndRatesQuery = (
  routePairId: string,
  currencyCode: string,
  negotiationStatusCodes: Array<NegotiationStatusCode>,
  year: number
) => {
  const { data, error, loading } = useQuery<
    UseRoutePairDetailsVolumeAndRatesQuery,
    UseRoutePairDetailsVolumeAndRatesQueryVariables
  >(query, {
    variables: {
      routePairId,
      currencyCode,
      negotiationStatuses: negotiationStatusCodes,
      year,
    },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    volumeAndRates: data?.statistics.routePairDetails.volumeAndRates,
    avgMeterPrices:
      data?.statistics.routePairDetails.threeYearsAverageMeterPrices,
  };
};
