import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import {
  dotToCommaTransform,
  cleanNumericTextFieldInput,
} from "@/common/formatters/NumericTextFieldInputCleaner";
import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import * as React from "react";
import { useMemo } from "react";
import { VehiclePluginsSelect } from "../../../../../common/components/data-driven-inputs/selects/vehicle-plugins-select/VehiclePluginsSelect";
import { NumericTextInputNoScroll } from "../../../../../common/components/forms/NumericTextInputNoScroll";
import FormGroup from "../../../../../common/forms/FormGroup";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { InputFieldSetWidthLabeled } from "../../PriceCalculatorFormPanel";
import { PriceCalculatorVehicleTypeSelect } from "./PriceCalculatorVehicleSelect";

interface Props {
  isMultileg: boolean;
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorVehicleDetails: React.FC<Props> = ({
  isMultileg,
  formData,
  onChangeFormData,
}) => {
  const tradeVehicleSelected = useMemo(() => {
    return /([T][1-9])|([U][1-9])/.test(formData.vehicleType ?? "");
  }, [formData.vehicleType]);

  const palletUnitsVehicleSelected = useMemo(() => {
    return /[G][1-9]/.test(formData.vehicleType ?? "");
  }, [formData.vehicleType]);

  const { width } = useWindowSize();

  const getWidthForVehicleField = (): number => {
    if (width > 1780) return 190;
    if (width > 1680) return 160;
    if (width > 1580) return 145;
    if (width >= 1420) return 110;

    return 90;
  };

  return (
    <FormGroup title={"Vehicle details"}>
      <Row>
        <InputFieldSetWidthLabeled
          width={getWidthForVehicleField()}
          label={"Vehicle type"}
        >
          <PriceCalculatorVehicleTypeSelect
            isMultileg={isMultileg}
            formData={formData}
            onChangeFormData={onChangeFormData}
            menuPortalTarget={document.body}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled label={"Length (m)"} width={60}>
          <NumericTextInput
            value={dotToCommaTransform(formData.vehicleLength ?? "")}
            onValueChange={(value) => {
              const newValue = cleanNumericTextFieldInput(value ?? "");
              onChangeFormData("vehicleLength", newValue);
            }}
            maxNumbers={2}
            maxDecimals={1}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled label={"Width (m)"} width={60}>
          <NumericTextInput
            value={dotToCommaTransform(formData.vehicleWidth ?? "")}
            onValueChange={(value) => {
              const newValue = cleanNumericTextFieldInput(value ?? "");
              onChangeFormData("vehicleWidth", newValue);
            }}
            maxNumbers={2}
            maxDecimals={1}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled label={"Weight (kg)"} width={90}>
          <NumericAgrVehicleWeightTextInput
            value={formData.vehicleWeight}
            onValueChange={(value) => {
              const newValue = cleanNumericTextFieldInput(value ?? "");
              onChangeFormData("vehicleWeight", newValue);
            }}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled label={"Plugins"} width={75}>
          <VehiclePluginsSelect
            value={formData.vehiclePlugins}
            onValueChange={(value) => onChangeFormData("vehiclePlugins", value)}
          />
        </InputFieldSetWidthLabeled>
      </Row>
      {tradeVehicleSelected && (
        <Column>
          <Spacing />
          <InputFieldSetWidthLabeled
            label={"Trade vehicle weight (kg)"}
            width={100}
          >
            <NumericAgrVehicleWeightTextInput
              value={String(formData.vehicleTradeWeight)}
              onValueChange={(value) =>
                onChangeFormData("vehicleTradeWeight", value)
              }
            />
          </InputFieldSetWidthLabeled>
        </Column>
      )}
      {palletUnitsVehicleSelected && (
        <Column>
          <Spacing />
          <InputFieldSetWidthLabeled label={"No. of units"} width={75}>
            <NumericTextInputNoScroll
              value={formData.vehicleTradeUnits?.toString()}
              onValueChange={(value) =>
                onChangeFormData("vehicleTradeUnits", parseFloat(value))
              }
              min={0}
            />
          </InputFieldSetWidthLabeled>
        </Column>
      )}
    </FormGroup>
  );
};
