import { UnitCategoryCode, SailingTypeCode } from "@/gql/graphql";

export const transformCodeToFullName = (
  unitCategoryCode: string,
  sailingTypeCode: string
): string => {
  if (
    unitCategoryCode === UnitCategoryCode.Acc &&
    sailingTypeCode === SailingTypeCode.O
  )
    return "Off peak - Acc";
  if (
    unitCategoryCode === UnitCategoryCode.Unacc &&
    sailingTypeCode === SailingTypeCode.O
  )
    return "Off peak - UnAcc";
  if (
    unitCategoryCode === UnitCategoryCode.Acc &&
    sailingTypeCode === SailingTypeCode.P
  )
    return "Peak - Acc";
  if (
    unitCategoryCode === UnitCategoryCode.Unacc &&
    sailingTypeCode === SailingTypeCode.P
  )
    return "Peak - UnAcc";
  if (
    unitCategoryCode === UnitCategoryCode.Acc &&
    sailingTypeCode === SailingTypeCode.S
  )
    return "Shoulder - Acc";
  if (
    unitCategoryCode === UnitCategoryCode.Unacc &&
    sailingTypeCode === SailingTypeCode.S
  )
    return "Shoulder - UnAcc";

  throw new Error("Unit category code or sailing type code did not match.");
};

export const transformCodeAndNumber = (
  count: number,
  unitCategoryCode: string,
  sailingTypeCode: string
): string => {
  return `${transformCodeToFullName(
    unitCategoryCode,
    sailingTypeCode
  )} (${count})`;
};
