import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { useParams } from "react-router-dom";
import { MultiLegRouteAgreementDetailsFetcher } from "./MultiLegRouteAgreementDetailsFetcher";

export interface MultiLegRouteAgreementDetailsScreenParams {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementCode: string;
  routeAgreementId: string;
  tab?: "rates" | "routes" | "conditions" | "notes" | "articles" | "matrix";
}

interface Props {}

export const MultiLegRouteAgreementDetailsScreen: React.FC<Props> = () => {
  const { routeAgreementId, headAgreementId, customerId, multiLegAgreementId } =
    useParams() as {
      routeAgreementId: string;
      headAgreementId: string;
      customerId: string;
      multiLegAgreementId: string;
    };
  if (!routeAgreementId) {
    return <ErrorScreen text={"Missing routeAgreementId."} />;
  }
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }
  if (!multiLegAgreementId) {
    return <ErrorScreen text={"Missing multiLegAgreementId."} />;
  }
  return (
    <MultiLegRouteAgreementDetailsFetcher
      routeAgreementId={routeAgreementId}
      headAgreementId={headAgreementId}
      customerId={customerId}
      multiLegAgreementId={multiLegAgreementId}
    />
  );
};
