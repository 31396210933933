export enum GeneralEvents {
  GeneralSearch = "General Search",
}

export enum GeneralSearchClasses {
  SidebarDrawerButton = "sidebar-drawer-button",
}

export enum GeneralTargetClasses {
  SidebarMenuIcon = ".NavBarSideMenuButton-module_icon__Art-L",
  SidebarMenuWrapper = ".NavBarSideMenuButton-module_sidebarMenuButton__SlbsU",
}

export enum TopBarEvents {
  GlobalHeaderEvent = "Global header event",
  ClickApplicationName = "Click application name",
  ClickUserIcon = "Click User Icon",
  ClickLogOut = "Click Log out",
  OpenMenu = "Open Menu",
}
