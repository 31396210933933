import * as React from "react";
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useQuery } from "@apollo/client";

import { orderBy } from "lodash";
import { gql } from "apollo-boost";
import { useDispatch } from "react-redux";
import { profileActions } from "../../../../../common/redux/profile/actions";
import { NegotiationYearsQuery } from "@/gql/graphql";

const defaultFullYear = new Date().getUTCFullYear();

interface NegotiationYearContextValue {
  availableYears: NegotiationYearsQuery["productPrice"]["negotiationYear"]["availableYears"];
  defaultYear: number;
  currentYear: number;
}

const NegotiationYearContext = createContext<NegotiationYearContextValue>({
  availableYears: [{ id: String(defaultFullYear), year: defaultFullYear }],
  defaultYear: defaultFullYear,
  currentYear: defaultFullYear,
});

const query = gql`
  query NegotiationYears {
    productPrice {
      negotiationYear {
        availableYears {
          id
          year
        }
      }
    }
  }
`;

export const useNegotiationYears = () => useContext(NegotiationYearContext);

export const NegotiationYearGate: React.FC<{
  spinner: ReactElement;
}> = ({ children, spinner }) => {
  const { data, loading } = useQuery<NegotiationYearsQuery>(query, {
    fetchPolicy: "cache-first",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.productPrice.negotiationYear.availableYears.length) {
      const highestAvailableYear = Math.max(
        ...data.productPrice.negotiationYear.availableYears.map((yr) => yr.year)
      );

      dispatch(
        profileActions.setGlobalState({ currentYear: highestAvailableYear })
      );
    }
  }, [data, dispatch]);

  const contextValue = useMemo<NegotiationYearContextValue>(() => {
    const availableYears =
      data?.productPrice.negotiationYear.availableYears ?? [];

    return {
      availableYears,
      defaultYear:
        orderBy(availableYears, ["year"], ["desc"])[0]?.year ?? defaultFullYear,
      currentYear: defaultFullYear,
    };
  }, [data]);

  return loading ? (
    spinner
  ) : (
    <NegotiationYearContext.Provider value={contextValue}>
      {children}
    </NegotiationYearContext.Provider>
  );
};
