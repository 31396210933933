import { GlobalState } from "./reducers";
import { StoreState } from "../../../config/redux/RootReducer";

export const profileSelectors = {
  getGlobal: (state: StoreState): GlobalState => state.profile.globalState,
};

export const createProfileFilterStateSelectors = {
  getFilterRenderState: (state: StoreState): boolean => {
    return state.filterRenderState.filterSearchedApplied;
  },
};
