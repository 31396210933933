import { SpaceValues } from "@/common/utils/SpaceValues";
import { Row, Space, Txt } from "@stenajs-webui/core";
import { SecondaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ModalFormModel } from "./SurchargeDocumentsUploadModal";

interface Props {
  formModel: ModalFormModel;
  setFormModel: React.Dispatch<React.SetStateAction<ModalFormModel>>;
}

export const SurchargeDocumentsUploadButton: React.FC<Props> = ({
  formModel,
  setFormModel,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const blob =
    selectedFile && new Blob([selectedFile], { type: "application/pdf" });

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event?.target?.files?.[0]);
  };

  useEffect(() => {
    setFormModel({
      ...formModel,
      fileName: selectedFile?.name,
      blob,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <Row alignItems={"center"}>
      <Txt>{formModel.fileName}</Txt>
      {formModel.fileName && <Space num={SpaceValues.SIXTEEN} />}
      <input
        ref={fileRef}
        hidden
        type="file"
        accept={".pdf"}
        onChange={handleFileSelect}
      />
      <SecondaryButton
        label={selectedFile?.name ? "Change file" : "Select file"}
        onClick={() => fileRef.current?.click()}
      />
    </Row>
  );
};
