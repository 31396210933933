import { Indent, useBoolean } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  ResultListBanner,
  ResultListBannerState,
  SecondaryButton,
  stenaSailingRoute,
} from "@stenajs-webui/elements";
import { ActionPrompt, Popover } from "@stenajs-webui/tooltip";
import _, { uniq } from "lodash";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModalWithLock } from "../../../../../common/components/loading-modals/LoadingModalWithLock";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { StoreState } from "../../../../../config/redux/RootReducer";

import { usePrompt } from "@/common/hooks/usePrompt";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { useParams } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { bulkEditRatesActions } from "../redux/reducer";
import { bulkEditRatesSelectors } from "../redux/selectors";
import { BulkEditRatesSaveButton } from "./BulkEditRatesSaveButton";
import { RouteAgreementsRatesByFilterQuery } from "@/gql/graphql";

export interface Props {
  custIndex: string;
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  refetch: () => Promise<unknown>;
}

export const BulkEditRatesHeader: React.FC<Props> = ({
  custIndex,
  routeAgreementRates,
  refetch,
}) => {
  const dispatch = useDispatch();
  const { headAgreementId, customerId } = useParams() as {
    headAgreementId: string;
    customerId: string;
  };
  const [visible, show, hide] = useBoolean(false);

  const saveChangesLoading = useSelector(
    bulkEditRatesSelectors.getSaveChangesLoading
  );
  const originalValueRow = useSelector(
    bulkEditRatesSelectors.getPersistedBulkEditRatesTableRows
  );
  const editableValueRow = useSelector(
    bulkEditRatesSelectors.getEditedBulkEditRatesTableRows
  );

  const hasNoChanges =
    _.isEqual(originalValueRow, editableValueRow) || saveChangesLoading;

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: !hasNoChanges,
  });

  const errorSelector = (state: StoreState) =>
    state.bulkEditRatesState.saveErrors;
  const saveErrors = useSelector(errorSelector);
  const bannerState = useMemo<ResultListBannerState | undefined>(() => {
    if (saveErrors && saveErrors.length) {
      const distinctErrorMessages = uniq(
        saveErrors.map((error) => error.message)
      );

      return {
        headerText: "Unable to save",
        items: uniq(
          distinctErrorMessages.map((e) => ({
            text: e,
          }))
        ),
      };
    }
  }, [saveErrors]);

  return (
    <>
      <PageHeader
        icon={stenaSailingRoute}
        title={"Edit rates"}
        width={"100vw"}
        sticky
        offsetLeft={0}
        offsetTop={0}
        zIndex={ZIndex.low}
        contentAbove={
          bannerState ? (
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          ) : undefined
        }
        contentRight={
          <>
            <Popover
              visible={visible}
              onClickOutside={hide}
              placement={"bottom"}
              content={
                <ActionPrompt
                  onNo={hide}
                  onYes={async () => {
                    hide();
                    dispatch(bulkEditRatesActions.clearSaveErrors());
                    dispatch(bulkEditRatesActions.revertAll());
                    refetch();
                  }}
                />
              }
            >
              <SecondaryButton
                label={"Discard"}
                onClick={show}
                disabled={hasNoChanges}
              />
            </Popover>
            <Indent />
            <BulkEditRatesSaveButton
              disabled={hasNoChanges}
              routeAgreementRates={routeAgreementRates}
            />
          </>
        }
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Negotiation Plan"}
              path={routeFactory.productAndPrice.customer.customerList()}
            />
            <Crumb
              label={custIndex}
              path={routeFactory.productAndPrice.customer.customerHeadAgreement(
                { customerId, headAgreementId }
              )}
            />
            <Crumb label={"Edit rates"} isLast />
          </BreadCrumbs>
        }
      />
      {saveChangesLoading && (
        <LoadingModalWithLock label="Saving new rates to all route agreements. Thanks for your patience" />
      )}
    </>
  );
};
