import { AppThunk } from "../../../../../config/redux/RootReducer";
import { raisePriceForRouteAgreementPrice } from "../hooks/UseBulkUpdateRatesLogic";
import { keys } from "lodash";
import { batch } from "react-redux";
import { BulkUpdateRatesFormModel } from "../models/BulkUpdateRatesFormModel";

export const raisePricesUsingSelectionThunk =
  (formModel: BulkUpdateRatesFormModel): AppThunk =>
  async (dispatch, getState) => {
    const tableState = getState().rateSheet.table;
    const standardTableState = getState().rateSheet.standardTable;
    const routeAgreementIds = keys(tableState);
    batch(() => {
      routeAgreementIds.forEach((routeAgreementId) => {
        const tableStateForRa = tableState[routeAgreementId];
        const standardTableStateForRa = standardTableState[routeAgreementId];
        if (!standardTableStateForRa) {
          return;
        }
        const selectedRouteAgreementPriceIds =
          standardTableStateForRa.selectedIds.selectedIds;

        selectedRouteAgreementPriceIds.forEach((routeAgreementPriceId) => {
          const rateSheetTableRowState = tableStateForRa.rows.find(
            (t) => t.routeAgreementPriceId === routeAgreementPriceId
          );

          if (!rateSheetTableRowState) {
            return;
          }

          raisePriceForRouteAgreementPrice(
            dispatch,
            routeAgreementId,
            routeAgreementPriceId,
            rateSheetTableRowState,
            formModel
          );
        });
      });
    });
  };
