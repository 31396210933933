import React from "react";
import { Icon, InputSpinner, stenaAngleDown } from "@stenajs-webui/elements";
import { CrudStatusIndicator } from "@stenajs-webui/grid";
import { Box } from "@stenajs-webui/core";
import { useSetTargetNegotiationStatus } from "../hooks/UseSetTargetNegotiationStatus";
import { TargetNegotiationStatusSelect } from "../../../../../../common/components/data-driven-inputs/selects/target-negotiation-status-select/TargetNegotiationStatusSelect";
import { CellFieldClickGate } from "../../../../../../common/components/cell-field-click-gate/CellFieldClickGate";
import { getApolloErrorMessage } from "../../../../../../common/string/GraphQlErrorParser";
import { useSelector } from "react-redux";
import { routeTargetsOverviewSelectors } from "../../../redux/routeTargetsOverviewSelectors";
import { useDefaultYearSelection } from "../../../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { IconSize } from "@/common/components/icons/IconSize";
import { TargetNegotiationStatus, TargetOverview } from "../../../types";

interface Props {
  targetOverview: TargetOverview;
  routeTargetYear?: number;
  border?: string;
  borderRadius?: string;
}

export const RouteTargetStatusEditor: React.FC<Props> = ({
  targetOverview,
  border,
  borderRadius,
}) => {
  const { error, loading, setTargetNegotiationStatus } =
    useSetTargetNegotiationStatus();

  const { year: defaultYear } = useDefaultYearSelection();

  const currentYear = useSelector(
    routeTargetsOverviewSelectors.getSelectedNegotiationYear
  );

  const setRouteTargetNegotiationStatus = (
    targetNegotiationStatus: TargetNegotiationStatus | undefined
  ) => {
    if (targetNegotiationStatus) {
      setTargetNegotiationStatus(
        targetOverview.routePairCode,
        currentYear ?? defaultYear,
        targetNegotiationStatus
      ).catch((e) => {
        console.error(e);
      });
    }
  };

  const isRouteTargetNegotiationStatusDone =
    targetOverview.negotiationStatus?.code.toUpperCase() === "D";

  return (
    <>
      <CellFieldClickGate
        disableClick={loading || isRouteTargetNegotiationStatusDone}
        label={targetOverview.negotiationStatus?.description ?? ""}
        border={border}
        borderRadius={borderRadius}
        right={
          loading ? (
            <InputSpinner />
          ) : error ? (
            <CrudStatusIndicator
              crudStatus={{
                hasError: true,
                errorMessage: getApolloErrorMessage(error),
              }}
            />
          ) : (
            <Icon icon={stenaAngleDown} size={IconSize.Small} />
          )
        }
        renderContent={({ onRequestClose }) => (
          <Box width={"100%"}>
            <TargetNegotiationStatusSelect
              value={targetOverview.negotiationStatus?.code ?? undefined}
              onValueChange={(e) => setRouteTargetNegotiationStatus(e)}
              autoFocus
              openMenuOnFocus
              onMenuClose={onRequestClose}
              menuPortalTarget={document.body}
            />
          </Box>
        )}
      />
    </>
  );
};
