import { ByRouteRowItem, VolumeAndTargetList } from "../types";
import { transformResultCommonFieldsToTableRow } from "../../../common/transformers/CommonTableRowTransformer";
import { StatisticsResultListItem } from "@/features/statistics/common/config/list/ResultTableConfig";
import { ByCustomerOrVolume } from "@/features/statistics/common/utils/ByCustomerOrVolumeEnum";

export const transformResultToTableRow = (
  row: ByRouteRowItem,
  volumeAndRates: VolumeAndTargetList,
  checkedRadioButton: ByCustomerOrVolume
): StatisticsResultListItem => {
  const volumeAndTargetForSelected = volumeAndRates.find(
    (v) => v.routePair.id === row.routePair.id
  )?.volumeAndTargetForSelected;

  return {
    ownerId: row.routePair.id,
    ownerName: row.routePair.code,
    routePair: row.routePair.description,
    ...transformResultCommonFieldsToTableRow(row, checkedRadioButton),
    ...{
      ...volumeAndTargetForSelected,
    },
  };
};
