import { Box } from "@stenajs-webui/core";
import { Banner, FlatButton, stenaPlus } from "@stenajs-webui/elements";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../../../RouteFactory";

interface Props {
  customerId: string;
  headAgreementId: string;
  bannerHeader: string;
  buttonLabel: string;
  isMultiLeg: boolean;
}

export const NoAgreementBox: React.FC<Props> = ({
  customerId,
  headAgreementId,
  bannerHeader,
  buttonLabel,
  isMultiLeg,
}) => {
  const navigate = useNavigate();

  return (
    <Box alignItems={"center"} justifyContent={"center"} height={150}>
      <Banner
        headerText={bannerHeader}
        variant="info"
        contentRight={
          <FlatButton
            leftIcon={stenaPlus}
            label={buttonLabel}
            onClick={() => {
              if (!isMultiLeg) {
                return navigate(
                  routeFactory.productAndPrice.routeAgreement.createRouteAgreement(
                    {
                      headAgreementId,
                      customerId,
                    }
                  )
                );
              }

              navigate(
                routeFactory.productAndPrice.multiLegAgreement.createMultiLegRouteAgreement(
                  {
                    headAgreementId,
                    customerId,
                  }
                )
              );
            }}
          />
        }
      />
    </Box>
  );
};
