import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { initialSearchFilterFormModel } from "@/features/search-filter/redux/reducer";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDefaultYearSelection } from "../../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { useRoutePairTarget } from "../../hooks/UseRoutePairTarget";
import { routePairTargetStandardTableActions } from "../../redux";
import { fetchDataByFilter } from "../../thunks/FetchDataByFilter";
import { RoutePairCustomerTargetTable } from "./RoutePairCustomerTargetTable";
import { useRoutePairNegotiationYearStatus } from "./hooks/UseRoutePairNegotiationYearStatus";

interface Props {
  currency: string;
  year: number;
  routePairCode: string;
  yearLoading: boolean;
}

const _RoutePairCustomerTargetDataFetcher: React.FC<Props> = ({
  year,
  routePairCode,
  currency,
  yearLoading,
}) => {
  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { year: defaultYear } = useDefaultYearSelection();

  const [prevCurrency, setPrevCurrency] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(routePairTargetStandardTableActions.clearSelection());
  }, [year, routePairCode, currency, dispatch]);

  useEffect(() => {
    const defaultFilterString = JSON.stringify(initialSearchFilterFormModel);
    const committedFilterString = JSON.stringify(committedState);

    if (
      defaultFilterString !== committedFilterString ||
      (prevCurrency !== currency && prevCurrency !== null)
    ) {
      setPrevCurrency(currency);
      dispatch(fetchDataByFilter(routePairCode, committedState, defaultYear));
    }
  }, [
    committedState,
    dispatch,
    routePairCode,
    currency,
    prevCurrency,
    defaultYear,
  ]);

  const { data, loading } = useRoutePairTarget();
  const { data: routePairStatusData, loading: routePairStatusLoading } =
    useRoutePairNegotiationYearStatus(routePairCode, year);

  const combinedLoading = loading || routePairStatusLoading || yearLoading;

  return (
    <RoutePairCustomerTargetTable
      tableData={data}
      loading={combinedLoading}
      routePairNavigationYear={routePairStatusData ?? undefined}
    />
  );
};

export const RoutePairCustomerTargetDataFetcher = React.memo(
  _RoutePairCustomerTargetDataFetcher
);
