import { StandardTableState } from "@stenajs-webui/grid";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { BulkEditRatesTableColumn } from "../config/BulkEditRatesTableConfig";
import { bulkEditRatesStandardTableInitialState } from "./reducer";

export const bulkEditRatesSelectors = {
  getTableState: (
    state: StoreState
  ): StandardTableState<BulkEditRatesTableColumn> =>
    state.bulkEditRatesTableState.standardTable ??
    bulkEditRatesStandardTableInitialState,
  getBulkEditRatesState: (state: StoreState) => state.bulkEditRatesState,
  getSelectedRowIds: (state: StoreState) =>
    state.bulkEditRatesTableState.standardTable.selectedIds.selectedIds,
  getPersistedBulkEditRatesTableRows: (state: StoreState) =>
    state.bulkEditRatesState.persisted,
  getEditedBulkEditRatesTableRows: (state: StoreState) =>
    state.bulkEditRatesState.edited,
  getSaveChangesLoading: (state: StoreState) =>
    state.bulkEditRatesState.bulkEditRatesLoading.saveChangesLoading,
  getBulkUpdateRatesLoading: (state: StoreState) =>
    state.bulkEditRatesState.bulkEditRatesLoading.bulkUpdateLoading,
};
