import { SectionChips } from "@stenajs-webui/filter";

export const DepartureChips = ({
  endDate,
  endTime,
  startDate,
  startTime,
}: {
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
}) => {
  const getLabel = () =>
    ""
      .concat(startDate || startTime ? "Dep from: " : "")
      .concat(startDate ? startDate : "")
      .concat(startTime ? ", ".concat(startTime) : "")
      .concat(endDate || endTime ? "Dep to: " : "")
      .concat(endDate ? endDate : "")
      .concat(endTime ? endTime : "");
  const enabled = startDate || startTime || endDate || endTime;
  return (
    <SectionChips
      sectionId={"departureInput"}
      chips={
        enabled
          ? [
              {
                value: "departure",
                label: getLabel(),
              },
            ]
          : undefined
      }
    />
  );
};
