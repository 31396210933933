import * as React from "react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { conditionsFormRedux } from "../ConditionsFormRedux";
import { ConditionsForm } from "./ConditionsForm";

interface Props {}

export const ConditionsPanel: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const conditionsFormModel = useSelector(
    conditionsFormRedux.selectors.getEntity
  );

  const setFields = useCallback(
    (fields) => {
      dispatch(conditionsFormRedux.actions.setEntityFields(fields));
    },
    [dispatch]
  );

  return (
    <>
      <Helmet title="Conditions - Route agreement - FPP" />

      <ConditionsForm value={conditionsFormModel} setFields={setFields} />
    </>
  );
};
