import { PageLayout } from "@/common/components/page-layout/PageLayout";
import * as React from "react";
import { Helmet } from "react-helmet";
import { StatisticsByCustomerTable } from "./StatisticsByCustomerTable";

export const StatisticsByCustomerListPanel: React.FC = () => {
  return (
    <>
      <Helmet title="Customer - Statistics - FPP" />
      <PageLayout>
        <StatisticsByCustomerTable />
      </PageLayout>
    </>
  );
};
