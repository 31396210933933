import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Row, Space, Text } from "@stenajs-webui/core";
import { Card, FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import { Window } from "@stenajs-webui/modal";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../../../../common/hooks/UsePrevious";
import { useDefaultYearSelection } from "../../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { gtmTransmitter } from "../../../../../common/utils/GtmHelper";
import { AdminTargetEventsAndActions } from "../../../../../common/utils/admin-target/AdminTargetGtmHelper";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { AllRoutesTableStatisticsHeader } from "./AllRoutesTableStatisticsHeader";
import { createAllRoutesTableConfig } from "./config/AllRoutesTableConfig";
import { adminTargetActions } from "./redux/actions";
import { adminTargetSelectors } from "./redux/selectors";
import { fetchAllRoutesAdminTarget } from "./redux/thunks/AdminTargetAllRoutesFetcher";

interface Props {}

export const AllRoutesTableStatistics: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {
    year,
    handleYearChange,
    error,
    loading: defaultYearSelectionLoading,
  } = useDefaultYearSelection();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const prevYear = usePrevious(year);
  const newlySelectedYear = useRef(year);

  const allRoutesData = useSelector(
    (state: StoreState) => state.adminTarget.allRoutesData
  );

  useEffect(() => {
    (async () => {
      await dispatch(fetchAllRoutesAdminTarget(year));
    })();
  }, [dispatch, year]);

  const { loading } = useSelector(adminTargetSelectors.getProgress);

  const onSelectedYearChange = (newYear: number) => {
    gtmTransmitter({
      event: AdminTargetEventsAndActions.adminTargetEvent,
      actionEvent: AdminTargetEventsAndActions.changeNegYear,
    });

    const unSavedChangedExist = allRoutesData.some((ard) => {
      return JSON.stringify(ard.editable) !== JSON.stringify(ard.persisted);
    });

    if (unSavedChangedExist && newYear !== year) {
      setModalOpen(true);
      newlySelectedYear.current = newYear;
      return;
    }

    handleYearChange(newYear);
  };

  const handleCancelYearChange = () => {
    handleYearChange(prevYear);
    setModalOpen(false);
  };

  const handleDiscard = () => {
    const persistedData = allRoutesData.map((data) => {
      return {
        id: data.id,
        editable: data.persisted,
        persisted: data.persisted,
      };
    });

    handleYearChange(newlySelectedYear.current);
    dispatch(adminTargetActions.resetTableState(persistedData));
    setModalOpen(false);
  };

  const config = useMemo(() => {
    return createAllRoutesTableConfig(year);
  }, [year]);

  return (
    <>
      <Window
        header={
          <Row justifyContent={"flex-start"}>
            <Text size="large" variant={"bold"}>
              Please confirm
            </Text>
          </Row>
        }
        isOpen={modalOpen}
        indent={IndentValues.TWENTYFOUR}
        spacing={SpacingValues.SIXTEEN}
        onRequestClose={() => setModalOpen(false)}
      >
        <Text size="medium">
          You have unsaved changes. You can discard your changes or cancel.
        </Text>
        <Space num={SpaceValues.THIRTYTWO} />
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <FlatButton label="Cancel" onClick={handleCancelYearChange} />
          <Space num={SpaceValues.THIRTYTWO} />
          <PrimaryButton label="Discard changes" onClick={handleDiscard} />
        </Box>
      </Window>
      <Card>
        <AllRoutesTableStatisticsHeader
          heading={"All routes"}
          selectLabel={"Negotiation year"}
          onSelectYearChange={onSelectedYearChange}
          value={year}
          defaultYearSelectionLoading={defaultYearSelectionLoading}
          defaultYearSelectionError={error}
        />
        <StandardTable
          config={config}
          items={allRoutesData}
          loading={loading}
        />
      </Card>
    </>
  );
};
