import { StatBoxesData } from "../models/StatBoxData";
import {
  groupCustomerStatisticsBySailingCategoryCode,
  groupCustomerStatisticsByUnitCategoryCode,
} from "../../product-and-price/customer-statistics/util/CustomerStatisticsGrouper";
import { CustomerStatisticsByFilterItemsFragment } from "@/gql/graphql";

export interface RoutePriceVariant extends RoutePriceVariantSummationFields {
  id: string;
  customerId: string;
  accompanied: boolean;
}

interface RoutePriceVariantSummationFields {
  target: number | null;
  actual: number;
  volume: number;
  statVolume: number;
  expectedVolume: number;
  expectedStatVolume: number;
}

const sumStatisticalData = (data: CustomerStatisticsByFilterItemsFragment[]) =>
  data.reduce<RoutePriceVariantSummationFields>(
    (acc, item) => {
      acc.expectedVolume += item.expectedVolume ?? 0;
      acc.expectedStatVolume += item.expectedStatVolume ?? 0;
      acc.statVolume += item.statVolume12Months ?? 0;
      acc.actual += item.actualOutcomeInPercent ?? 0;
      if (item.targetInPercent !== null) {
        if (acc.target === null) {
          acc.target = 0;
          acc.target += item.targetInPercent ?? 0;
        } else {
          acc.target += item.targetInPercent ?? 0;
        }
      }
      acc.volume += item.volume12Months ?? 0;
      return acc;
    },
    {
      target: null,
      actual: 0,
      volume: 0,
      statVolume: 0,
      expectedVolume: 0,
      expectedStatVolume: 0,
    }
  );

const transformToRoutePriceVariant = (
  data: CustomerStatisticsByFilterItemsFragment[],
  accompanied: boolean,
  customerId: string
): RoutePriceVariant => {
  const {
    expectedVolume,
    expectedStatVolume,
    actual,
    statVolume,
    target,
    volume,
  } = sumStatisticalData(data);

  return {
    accompanied,
    actual,
    target,
    statVolume,
    volume,
    expectedVolume,
    expectedStatVolume,
    customerId,
    id: data[0]?.id ?? "dummy",
  };
};

export const calculateCustomerRouteStatisticsTable = (
  data: CustomerStatisticsByFilterItemsFragment[],
  customerId: string
): StatBoxesData => {
  const customerStatisticsBySailingCategoryCode =
    groupCustomerStatisticsBySailingCategoryCode(data);

  const peak = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.peak
  );

  const shoulder = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.shoulder
  );

  const offPeak = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.offPeak
  );

  const peakData = [
    transformToRoutePriceVariant(peak.accompanied, true, customerId),
    transformToRoutePriceVariant(peak.unaccompanied, false, customerId),
  ];

  const shoulderData = [
    transformToRoutePriceVariant(shoulder.accompanied, true, customerId),
    transformToRoutePriceVariant(shoulder.unaccompanied, false, customerId),
  ];

  const offPeakData = [
    transformToRoutePriceVariant(offPeak.accompanied, true, customerId),
    transformToRoutePriceVariant(offPeak.unaccompanied, false, customerId),
  ];

  return {
    peakData,
    shoulderData,
    offPeakData,
  };
};
