import React, { ReactElement, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { profileActions } from "../../common/redux/profile/actions";
import { useDispatch } from "react-redux";
import { ErrorPage } from "../../LoginPage";
import { useMsal } from "@azure/msal-react";
import { UserProfileGateQuery } from "@/gql/graphql";

const userProfileGateQuery = gql`
  query UserProfileGate {
    productPrice {
      userProfile {
        me {
          id
          userName
          fullName
          claims
        }
      }
    }
  }
`;

interface Props {
  spinner: ReactElement;
}

export const UserProfileGate: React.FC<Props> = ({ children, spinner }) => {
  const { data, error } = useQuery<UserProfileGateQuery>(userProfileGateQuery);
  const dispatch = useDispatch();
  const [hasUserProfile, setHasUserProfile] = useState<boolean>(false);
  const { accounts } = useMsal();

  useEffect(() => {
    if (data) {
      dispatch(
        profileActions.setGlobalState({
          id: data?.productPrice.userProfile.me.id,
          userName: data?.productPrice.userProfile.me.userName,
          fullName: data?.productPrice.userProfile.me.fullName,
          claims: data?.productPrice.userProfile.me.claims,
        })
      );
      setHasUserProfile(true);
    }
  }, [data, dispatch]);

  if (error) {
    const errorText = error.message
      ? `${error.message}. For user: ${
          accounts.length > 0 ? accounts[0].username : "No account found"
        }`
      : "";
    return <ErrorPage text={errorText} />;
  }

  if (!hasUserProfile) {
    return spinner;
  }

  return <>{children}</>;
};
