import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { parseFloatElseUndefined } from "@stenajs-webui/core";
import { Dispatch } from "react";
import {
  agreementArticlesActions,
  AgreementArticlesState,
} from "../../../redux/reducer";
import {
  BulkUpdateAgreementArticlesFormModel,
  RaiseByType,
} from "../models/BulkUpdateAgreementArticlesFormModel";

const calculateAndDispatchRaiseAction = (
  formModel: BulkUpdateAgreementArticlesFormModel,
  previousValue: string,
  agrRouteArticlePriceId: string,
  dispatch: Dispatch<any>
) => {
  try {
    const newValue = (
      formModel.raiseByType === RaiseByType.ABSOLUTE
        ? increaseByAbsoluteValue(formModel.raiseByAbsolute)
        : increaseByPercent(previousValue, formModel.raiseByPercent)
    ).toFixed(2);
    const newValidFrom = formModel.validFromDate?.toString();
    if (newValue && newValidFrom) {
      dispatch(
        agreementArticlesActions.setValue({
          id: agrRouteArticlePriceId,
          fields: {
            newPrice: newValue,
            newValidFrom,
          },
        })
      );
    }
  } catch (e) {}
};
export const raisePriceForAgreementArticlesPrice = (
  dispatch: Dispatch<any>,
  agrRouteArticlePriceId: string,
  agreementArticlesState: AgreementArticlesState,
  formModel: BulkUpdateAgreementArticlesFormModel
) => {
  const getTableItemPreviousValue = () => {
    const tableItem = agreementArticlesState.tableItems.find(
      (item) => item.persisted.id === agrRouteArticlePriceId
    );
    return String(tableItem?.persisted.price);
  };
  if (
    (formModel.raiseByType === RaiseByType.ABSOLUTE &&
      formModel.raiseByAbsolute) ||
    (formModel.raiseByType === RaiseByType.PERCENT && formModel.raiseByPercent)
  ) {
    calculateAndDispatchRaiseAction(
      formModel,
      getTableItemPreviousValue(),
      agrRouteArticlePriceId,
      dispatch
    );
  }
};
const increaseByAbsoluteValue = (newValueAsString: string): number => {
  const newValue = parseFloatElseUndefined(
    commaToDotTransform(newValueAsString)
  );

  if (newValue === undefined) {
    throw new Error("Invalid value.");
  }
  return newValue;
};
const increaseByPercent = (
  previousValue: string,
  percentAsString: string
): number => {
  const percent = parseFloatElseUndefined(commaToDotTransform(percentAsString));
  const currentPrice = parseFloatElseUndefined(
    commaToDotTransform(previousValue)
  );
  if (percent === undefined) {
    throw new Error("Invalid percent.");
  }
  if (currentPrice === undefined) {
    throw new Error("Current price is not set, cannot increase by percent.");
  }
  return currentPrice * (1 + percent / 100);
};
