import { GoodsMultiSelectQuery } from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";
import { MultiSelect, MultiSelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo } from "react";

interface Props extends Omit<MultiSelectProps<any>, "value" | "onChange"> {
  value?: Array<string>;
  onValueChange?: (value: Array<string>) => void;
}

const query = gql`
  query GoodsMultiSelect {
    productPrice {
      goods {
        all {
          id
          code
          name
        }
      }
    }
  }
`;

export const useAllGoods = () => useQuery<GoodsMultiSelectQuery>(query);

interface GoodsOption {
  label: string;
  value: string;
}

export const GoodsMultiSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...multiSelectProps
}) => {
  const { data, loading } = useAllGoods();

  const goods = useMemo(() => {
    return data?.productPrice.goods.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return goods.map((r) => ({
      label: r.name,
      value: r.id,
    }));
  }, [goods]);

  const selected = useMemo(() => {
    if (!value || !value.length || !options || !options.length) {
      return [];
    }
    return options.filter((o) => {
      return value.find((v) => o.value === v);
    });
  }, [options, value]);

  return (
    <MultiSelect
      isLoading={loading}
      options={options}
      value={selected}
      onChange={(v) =>
        onValueChange?.(
          (v as GoodsOption[])?.map((vv: GoodsOption) => vv?.value) ?? []
        )
      }
      {...multiSelectProps}
    />
  );
};
