import { StoreState } from "../../../../config/redux/RootReducer";
import { combineReducers } from "redux";
import {
  createEntityActions,
  createEntityReducer,
  createRecordObjectActions,
  EntityState,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";

interface HeadAgreementStatusConfirmationState {
  modalOpen: boolean;
}

export const activateHeadAgreementStatusConfirmationReducerId =
  "activateHeadAgreementStatusConfirmation";

export const activateHeadAgreementStatusConfirmationReducer = reducerIdGate(
  activateHeadAgreementStatusConfirmationReducerId,
  combineReducers({
    activationStatusConfirmation:
      createEntityReducer<HeadAgreementStatusConfirmationState>({
        modalOpen: false,
      }),
  })
);

export interface NegotiationStatusActivationState {
  activationStatusConfirmation: EntityState<HeadAgreementStatusConfirmationState>;
}

export const activateHeadAgreementStatusConfirmationSelectors = {
  getStatusActivationState: (
    state: StoreState
  ): EntityState<HeadAgreementStatusConfirmationState> =>
    state.negotiationStatusActivation.activationStatusConfirmation,
};

export const activateHeadAgreementStatusConfirmationActions = {
  clearAll: () =>
    reducerIdGateAction(
      activateHeadAgreementStatusConfirmationReducerId,
      createRecordObjectActions().clearAllRecords()
    ),
  setStatusActivationState: (
    fields: Partial<HeadAgreementStatusConfirmationState>
  ) =>
    reducerIdGateAction(
      activateHeadAgreementStatusConfirmationReducerId,
      createEntityActions<HeadAgreementStatusConfirmationState>().setEntityFields(
        fields
      )
    ),
};
