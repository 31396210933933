import {
  CustomerRouteRouteAgreementFragment,
  RateSheetTablePricesFragment,
} from "@/gql/graphql";
import { BulkEditRatePrice, BulkEditRatePriceTableItem } from "../types";

export const transformRatesByFilterQueryToBulkEditRatesPriceRow = (
  value: BulkEditRatePrice
): BulkEditRatePriceTableItem => {
  return {
    id: value.id,
    actual: value.actual,
    additionalFrom: value.additionalFrom?.toString() ?? null,
    avgGrossPrice: value.avgGrossPrice?.amount ?? null,
    avgLength: value.avgLength,
    cargoStatusCode: value.cargoStatusCode,
    lastChargedFrom: value.lastChargedFrom,
    lastMeterPrice: value.lastMeterPrice?.amount ?? null,
    lastPrice: value.lastPrice?.amount ?? null,
    lengthFrom: value.lengthFrom,
    lengthTo: value.lengthTo,
    meterPrice: value.meterPrice.amount,
    newPrice: value.newPrice.amount,
    avgRebate: value.avgRebate?.amount ?? null,
    volume: value.volume,
    unaccompanied: value.unaccompanied,
    vehicleType: value.vehicleType,
    weightFrom: value.weightFrom,
    weightTo: value.weightTo,
    routeAgreement: {
      id: value.routeAgreement.id,
      agreementNumber: value.routeAgreement.agreementNumber,
      currency: {
        id: value.routeAgreement.currency.id,
        code: value.routeAgreement.currency.code,
      },
      name: value.routeAgreement.name,
      prices: value.routeAgreement.prices,
      routeDescription: value.routeAgreement.routeDescription,
      routes: value.routeAgreement.routes,
      rowVersion: value.routeAgreement.rowVersion,
      sailingTypeCodes: value.routeAgreement.sailingTypeCodes,
      statusCode: value.routeAgreement.statusCode,
      isSalesReady: value.routeAgreement.isSalesReady,
      valid: {
        start: value.routeAgreement.valid.start
          ? {
              isoString: value.routeAgreement.valid.start.isoString,
            }
          : null,
        end: value.routeAgreement.valid.end
          ? {
              isoString: value.routeAgreement.valid.end.isoString,
            }
          : null,
      },
    },
  };
};

export const transformUpdateResultToBulkEditRatePriceTableItem = (
  ra: CustomerRouteRouteAgreementFragment,
  value: RateSheetTablePricesFragment
): BulkEditRatePriceTableItem => {
  return {
    id: value.id,
    actual: value.actual,
    additionalFrom: value.additionalFrom?.toString() ?? null,
    avgGrossPrice: value.avgGrossPrice?.amount ?? null,
    avgLength: value.avgLength,
    cargoStatusCode: value.cargoStatusCode,
    lastChargedFrom: value.lastChargedFrom,
    lastMeterPrice: value.lastMeterPrice?.amount ?? null,
    lastPrice: value.lastPrice?.amount ?? null,
    lengthFrom: value.lengthFrom,
    lengthTo: value.lengthTo,
    meterPrice: value.meterPrice.amount,
    newPrice: value.newPrice.amount,
    avgRebate: value.avgRebate?.amount ?? null,
    volume: value.volume,
    unaccompanied: value.unaccompanied,
    vehicleType: value.vehicleType ?? null,
    weightFrom: value.weightFrom,
    weightTo: value.weightTo,
    routeAgreement: {
      id: ra.id,
      agreementNumber: ra.agreementNumber,
      currency: {
        id: ra.currency.id,
        code: ra.currency.code,
      },
      name: ra.name,
      prices: ra.prices,
      routeDescription: ra.routeDescription,
      routes: ra.routes,
      rowVersion: ra.rowVersion,
      sailingTypeCodes: ra.sailingTypeCodes,
      statusCode: ra.statusCode,
      isSalesReady: ra.isSalesReady,
      valid: {
        start: ra.valid.start
          ? {
              isoString: ra.valid.start.isoString,
            }
          : null,
        end: ra.valid.end
          ? {
              isoString: ra.valid.end.isoString,
            }
          : null,
      },
    },
  };
};
