import { firstLetterCapital } from "@/common/string/GeneralStringFormatting";
import { FilterEntity } from "@stenajs-webui/filter";
import { StatInfoResult } from "../hooks/UseLoadStats";
import { CustomerInfoResult } from "../hooks/UseLoadCustomers";

export const transformQueryResultToFilterEntity = (
  value: StatInfoResult | CustomerInfoResult
): FilterEntity => {
  return {
    id: String(value.custNo),
    code: String(value.custNo),
    name: value.custIndex,
  };
};

export const emptyLoadedOptions = (emptyLoaded: string[]) => {
  const emptyLoadedOptions = emptyLoaded?.map((elb) => {
    return {
      value: elb,
      label: firstLetterCapital(elb),
    };
  });
  return emptyLoadedOptions;
};
