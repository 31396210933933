import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../../../../../common/error/GqlErrorFragment";
import {
  CopyMultiLegRouteAgreementMutation,
  CopyMultiLegRouteAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation CopyMultiLegRouteAgreement(
    $copyMultiLegRouteAgreementInput: CopyMultiLegAgreementInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        copyMultiLegRouteAgreement(input: $copyMultiLegRouteAgreementInput) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on CopyMultiLegRouteAgreementSuccessResult {
            multiLegAgreement {
              id
            }
          }
        }
      }
    }
  }
`;

export const useCopyMultiLegRouteAgreementMutation = () =>
  useMutation<
    CopyMultiLegRouteAgreementMutation,
    CopyMultiLegRouteAgreementMutationVariables
  >(query);
