import * as React from "react";
import { useCallback, useState } from "react";
import { Row, Spacing } from "@stenajs-webui/core";
import { PrimaryButton } from "@stenajs-webui/elements";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { AddVehicleTypeForm } from "./AddVehicleTypeForm";
import {
  AddVehicleTypeFormModel,
  emptyAddVehicleTypeFormModel,
} from "../models/AddVehicleTypeFormModel";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { RouteAgreementsForMultilegRouteFragment } from "@/gql/graphql";

interface Props {
  multiLegAgreement?: RouteAgreementsForMultilegRouteFragment;
  addVehicleTypeToAllRoutesRadioButton?: boolean;
  onRequestClose?: () => void;
  onAddRow: (
    formModel: AddVehicleTypeFormModel,
    multiLegRouteAgreementId?: string
  ) => void;
}

export const AddVehicleType: React.FC<Props> = ({
  onAddRow,
  onRequestClose,
  multiLegAgreement,
  addVehicleTypeToAllRoutesRadioButton,
}) => {
  const [formModel, setFormModel] = useState<AddVehicleTypeFormModel>(
    emptyAddVehicleTypeFormModel
  );

  const [localError, setLocalError] = useState<Error | undefined>();

  const [addToAllRouteAgreements, setAddToAllRouteAgreements] =
    useState<boolean>(true);
  const submitHandler = useCallback(async () => {
    setLocalError(undefined);
    if (addToAllRouteAgreements) {
      multiLegAgreement?.routeAgreements.forEach((routeAgreement) => {
        try {
          onAddRow(formModel, routeAgreement.id);
          if (onRequestClose) {
            onRequestClose();
          }
        } catch (e) {
          setLocalError(e);
        }
      });
    }
    if (!addToAllRouteAgreements || !addVehicleTypeToAllRoutesRadioButton) {
      try {
        onAddRow(formModel);
        if (onRequestClose) {
          onRequestClose();
        }
      } catch (e) {
        setLocalError(e);
      }
    }
  }, [
    formModel,
    multiLegAgreement,
    addVehicleTypeToAllRoutesRadioButton,
    addToAllRouteAgreements,
    onAddRow,
    onRequestClose,
  ]);

  return (
    <>
      <AddVehicleTypeForm
        value={formModel}
        onValueChange={setFormModel}
        addToAllRouteAgreements={addToAllRouteAgreements}
        setAddToAllRouteAgreements={setAddToAllRouteAgreements}
        addVehicleTypeToAllRoutesRadioButton={
          addVehicleTypeToAllRoutesRadioButton
        }
      />
      <Spacing />
      <Row justifyContent={"flex-end"}>
        <PrimaryButton
          label={"Add"}
          onClick={submitHandler}
          data-testid={
            testIdConstantsWebapp.createRouteRatesAddVehicleAddButton
          }
        />
      </Row>
      <Row justifyContent={"flex-end"} spacing>
        <ValidationErrorMessage error={localError} />
      </Row>
    </>
  );
};
