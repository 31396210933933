import { Box, Column, Space, Txt } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { TextArea, TextInput } from "@stenajs-webui/forms";
import * as React from "react";
import { SailingCategorySelectionRow } from "../../../../../../common/components/forms/checkbox-sailingcategory-selection/CheckBoxSailingCategorySelection";
import { formatDateString } from "@/common/dates/formatters";
import { LegSelectionRadioButtonRow } from "./LegSelectionRadioButtonRow";
import { CreateMultiLegAgreementRouteFormModel } from "./model/CreateMultiLegAgreementRouteFormModel";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  routeLegOptions: Array<string>;
  formData: CreateMultiLegAgreementRouteFormModel;
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  setFields: (value: Partial<CreateMultiLegAgreementRouteFormModel>) => void;
}

export const CreateMultiLegAgreementRouteForm: React.FC<Props> = ({
  formData,
  setFields,
  multiLegAgreement,
  routeLegOptions,
}) => {
  return (
    <Column>
      <Label text={"ML agreement validity"}>
        <Box width={"500px"}>
          <Txt>
            {formData.valid.startDate
              ? formatDateString(formData.valid.startDate)
              : "ML start date missing."}{" "}
            -{" "}
            {formData.valid.endDate
              ? formatDateString(formData.valid.endDate)
              : "ML end date missing."}
          </Txt>
        </Box>
      </Label>
      <Space num={4} />
      <Label text={"ML agreement currency"}>
        <Box width={"500px"}>
          <Txt>{formData.currencyCode}</Txt>
        </Box>
      </Label>
      <Space num={4} />
      <Label text={"Select leg"}>
        <Box width={"500px"}>
          <LegSelectionRadioButtonRow
            routeLegOptions={routeLegOptions}
            value={formData.selectedRouteCode}
            onValueChange={(selectedRouteCode) => {
              const name = multiLegAgreement.routeLegs.find(
                (routeLeg) => routeLeg.legRoute.code === selectedRouteCode
              )?.legRoute.name;
              setFields({ selectedRouteCode, name });
            }}
          />
        </Box>
      </Label>
      <Space num={2} />
      <Box width={"500px"}>
        <SailingCategorySelectionRow
          formData={formData}
          setFields={setFields}
        />
      </Box>
      <Space num={2} />
      <Label text={"Name"} optional={true}>
        <Box width={"500px"}>
          <TextInput
            autoFocus
            value={formData.name}
            onValueChange={(name) => setFields({ name })}
          />
        </Box>
      </Label>
      <Space num={4} />
      <Label text={"External description"} optional={true}>
        <Box width={"500px"}>
          <TextArea
            style={{ minHeight: 100 }}
            autoFocus
            value={formData.externalDescription}
            onValueChange={(externalDescription) =>
              setFields({ externalDescription })
            }
          />
        </Box>
      </Label>
      <Space num={2} />
    </Column>
  );
};
