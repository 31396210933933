export const NslhInvoiceTab = {
  overview: "overview",
  invoiceSpecification: "invoice-specification",
};

export interface Tab {
  label: string;
  value: string;
}

export const listInvoicesTabs: Array<Tab> = [
  { label: "Overview", value: NslhInvoiceTab.overview },
  {
    label: "Invoice specification",
    value: NslhInvoiceTab.invoiceSpecification,
  },
];
