import * as React from "react";
import { useContext, useMemo } from "react";
import { Row } from "@stenajs-webui/core";
import { StatisticsBySellerDetailsHeader } from "./StatisticsBySellerDetailsHeader";
import { useSellerDetailsVolumeAndRatesQuery } from "../hooks/UseSellerDetailsVolumeAndRatesQuery";
import { useSellerDetailsSummaryQuery } from "../hooks/UseSellerDetailsSummaryQuery";
import { transformSelectedStatusesToNegotiationStatusCodes } from "../../../common/transformers/NegotiationStatusFormModelTransformer";
import { Helmet } from "react-helmet";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { Spinner } from "@stenajs-webui/elements";
import { StatisticsDetailsView } from "@/features/statistics/common/statistics-details-view/StatisticsDetailsView";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import {
  NegotiationStatusCode,
  SellerDetailsSummaryQuery,
} from "@/gql/graphql";

interface Props {
  sellerId: string;
  year: number;
}

export const StatisticsBySellerDetails: React.FC<Props> = ({
  sellerId,
  year,
}) => {
  const { handleCurrencyChange, currency } = useCurrencySelection();
  const { summary, loading } = useSellerDetailsSummaryQuery(sellerId, year);

  return (
    <>
      <Helmet title="Seller - Statistics - FPP" />
      <StatisticsBySellerDetailsHeader
        sellerId={sellerId}
        currency={currency}
        handleCurrencyChange={handleCurrencyChange}
      />
      {loading || !summary ? (
        <Row justifyContent={"center"}>
          <Spinner />
        </Row>
      ) : (
        <DetailsDataLoader
          currency={currency}
          year={year}
          sellerId={sellerId}
          summary={summary}
        />
      )}
    </>
  );
};

const DetailsDataLoader = ({
  sellerId,
  year,
  currency,
  summary,
}: {
  sellerId: string;
  year: number;
  currency: string;
  summary: SellerDetailsSummaryQuery["statistics"]["sellerDetails"]["summary"]; //UseSellerDetailsSummaryQuery_statistics_sellerDetails_summary;
}) => {
  const { sellerSelectedStatuses, setSellerSelectedStatuses } = useContext(
    StatisticsContext
  ) as StatisticsContextType;

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(sellerSelectedStatuses),
    [sellerSelectedStatuses]
  );
  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;

  const {
    volumeAndRates,
    loading: volumeAndRatesLoading,
    averageMeterPrices,
  } = useSellerDetailsVolumeAndRatesQuery(
    sellerId,
    currency,
    negotiationStatusCodes,
    year
  );

  return (
    <StatisticsDetailsView
      currency={currency}
      year={year}
      summary={summary}
      selectedNegotiationStatuses={sellerSelectedStatuses}
      setSelectedNegotiationStatuses={setSellerSelectedStatuses}
      volumeAndRates={volumeAndRates}
      volumeAndRatesLoading={volumeAndRatesLoading}
      averageMeterPrices={averageMeterPrices}
    />
  );
};
