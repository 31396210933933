import { gql } from "apollo-boost";

export const customerStatisticsFragment = gql`
  fragment CustomerStatistics on CustomerStatisticsEntry {
    id
    expectedVolume
    statVolume12Months
    unitCategoryCode
    expectedStatVolume
    sailingCategoryCode
    volume12Months
    actualOutcomeInPercent
  }
`;
