import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import * as React from "react";
import { GridContainer } from "../../../../../common/components/grid-container/GridContainer";
import { HeadAgreementInfoPairBox } from "../../../info-boxes/head-agreement/HeadAgreementInfoPairBox";
import {
  AgreementArticlesCustomer,
  AgreementArticlesHeadAgreement,
} from "../types";

interface Props {
  headAgreement?: AgreementArticlesHeadAgreement;
  customer: AgreementArticlesCustomer;
  switchButtonDisabled?: boolean;
}

export const AgreementArticlesInfoBoxes: React.FC<Props> = ({
  headAgreement,
  customer,
  switchButtonDisabled,
}) => {
  return (
    <GridContainer boxMinWidth={300} boxMaxWidth={"500px"}>
      {headAgreement && (
        <HeadAgreementInfoPairBox
          customerId={customer?.id ?? ""}
          headAgreement={headAgreement}
          switchButtonDisabled={switchButtonDisabled}
        />
      )}
      {customer && <CustomerInfoPairBox customer={customer} popoverEnabled />}
    </GridContainer>
  );
};
