import { useQuery } from "@apollo/client";
import { Row } from "@stenajs-webui/core";
import { Spinner } from "@stenajs-webui/elements";
import { ErrorScreen } from "@stenajs-webui/panels";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ReportHeader } from "./ReportHeader";
import { ChildParentSelector } from "../../common/components/child-parent-selection/ChildParentSelector";
import { WizardWrapper } from "./WizardWrapper";
import {
  ChildCustomersByStatusFragment,
  ReportScreenQuery,
  ReportScreenQueryVariables,
} from "@/gql/graphql";
import { reportScreenQuery } from "./queries/reportScreenQuery";

const LoadingComponent = () => (
  <Row justifyContent={"center"} spacing>
    <Spinner />
  </Row>
);

const getErrorMessage = (hasCustomer: boolean, hasHeadAgreement: boolean) =>
  hasCustomer
    ? hasHeadAgreement
      ? "Could not load customer and head agreement"
      : "Could not load head agreement"
    : "Could not load customer.";

export const ReportScreen: React.FC = () => {
  const { headAgreementId, customerId, reportType, parent } = useParams() as {
    headAgreementId: string;
    customerId: string;
    reportType: string;
    parent?: string;
  };
  const [childrenForReport, setChildrenForReport] = useState<
    string[] | undefined
  >();
  const [childCustomers, setChildCustomers] = useState<
    ChildCustomersByStatusFragment[]
  >([]);

  const { data, loading, error } = useQuery<
    ReportScreenQuery,
    ReportScreenQueryVariables
  >(reportScreenQuery, {
    variables: {
      customerId,
      headAgreementId,
      codes: ["AG_FIRST", "AG_FIRSTOF"],
    },
    fetchPolicy: "network-only",
  });

  const customer = data?.productPrice.customers.byId;
  const headAgreement = data?.productPrice.headAgreement.byId;
  const headAgreementYear = Number(
    headAgreement?.valid.start?.isoString.slice(0, 4)
  );
  const agreementTexts = data?.productPrice.agreementTexts.byAgreementTextCodes;
  const numberOfChildren = customer?.noOfChildren ?? 0;

  return (
    <>
      <Helmet title="Create report - Customer - FPP" />
      <ReportHeader
        loading={loading}
        customer={customer ?? null}
        headAgreement={headAgreement ?? null}
        customerId={customerId}
        headAgreementId={headAgreementId}
        reportType={reportType}
      />
      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorScreen text={getErrorMessage(!customer, !headAgreement)} />
      ) : customer && headAgreement ? (
        numberOfChildren === 0 || childrenForReport ? (
          <WizardWrapper
            headAgreement={headAgreement}
            customer={customer}
            childCustomers={childCustomers}
            agreementTexts={agreementTexts ?? []}
            reportType={reportType}
            childrenForReport={childrenForReport ?? []}
            parent={Number(parent)}
          />
        ) : (
          <ChildParentSelector
            reportType={reportType}
            numberOfChildren={numberOfChildren}
            headAgreementYear={headAgreementYear}
            setChildCustomers={setChildCustomers}
            setChildrenForReport={setChildrenForReport}
          />
        )
      ) : null}
    </>
  );
};
