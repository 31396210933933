import {
  StatisticsSearchAdvancedListByFilterOptions,
  UseAdvancedStatisticsDataListQuery,
  UseAdvancedStatisticsDataListQueryVariables,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { UseAdvancedStatisticsDataList } from "./UseStatisticsByAdvancedListQuery";

export const useAdvancedStatisticsDataListQuery = (
  filterOptions: StatisticsSearchAdvancedListByFilterOptions
) => {
  const [loadAdvancedStatisticsData, { loading, data, error }] = useLazyQuery<
    UseAdvancedStatisticsDataListQuery,
    UseAdvancedStatisticsDataListQueryVariables
  >(UseAdvancedStatisticsDataList, {
    variables: { byFilterFilterOptions: filterOptions },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    advancedStatisticsData: data?.statistics.advancedList.byFilter?.items,
    loadAdvancedStatisticsData,
  };
};
