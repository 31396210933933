import React, { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { CurrencySelectQuery } from "@/gql/graphql";

export interface CurrencySelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange: (value: string) => void;
  containerWidth?: string;
}

const query = gql`
  query CurrencySelect {
    productPrice {
      currency {
        all {
          id
          name
        }
      }
    }
  }
`;

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  onValueChange,
  containerWidth,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<CurrencySelectQuery>(query);

  const options = useMemo(() => {
    const currencies = data?.productPrice.currency.all;
    return (currencies ?? []).map((curr) => ({
      label: curr.id,
      value: curr.name,
    }));
  }, [data]);

  let selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  if (!selected && options[0]) {
    selected = options[0];
  }

  return (
    <Select
      {...selectProps}
      styles={{
        container: (base: CSSObject) => ({
          ...base,
          width: `${containerWidth}`,
        }),
        menuPortal: (base: CSSObject) => ({
          ...base,
          zIndex: ZIndex.highest,
        }),
      }}
      value={selected}
      isLoading={loading}
      options={options}
      onChange={(item: any) => onValueChange && onValueChange(item.value)}
    />
  );
};
