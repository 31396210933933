import { RouteAgreementTypeCode } from "@/gql/graphql";
import { DateStringRange } from "@stenajs-webui/calendar";

export interface EditRouteAgreementFormModel {
  routeIds: Array<string>;
  name: string;
  valid: DateStringRange;
  externalDescription: string;
  isSalesReady: boolean;
  type: RouteAgreementTypeCode;
  currencyId?: string;
  peak: boolean;
  offPeak: boolean;
  shoulder: boolean;
  special: boolean;
  includePartnerRoutes: boolean;
}

export const emptyEditRouteAgreementFormModel: EditRouteAgreementFormModel = {
  routeIds: [],
  name: "",
  valid: { endDate: "", startDate: "" },
  externalDescription: "",
  isSalesReady: false,
  type: RouteAgreementTypeCode.Cond,
  currencyId: undefined,
  peak: false,
  offPeak: false,
  shoulder: false,
  special: false,
  includePartnerRoutes: false,
};
