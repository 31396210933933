import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsDetailsFragment } from "../../../common/fragments/DetailsFragment";
import {
  NegotiationStatusCode,
  UseSellerDetailsVolumeAndRatesQuery,
  UseSellerDetailsVolumeAndRatesQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseSellerDetailsVolumeAndRates(
    $sellerId: ID!
    $currencyCode: String!
    $negotiationStatuses: [NegotiationStatusCode!]!
    $year: Int!
  ) {
    statistics {
      sellerDetails {
        volumeAndRates(
          sellerId: $sellerId
          negotiationStatusCodes: $negotiationStatuses
          year: $year
        ) {
          ...statisticsDetailsFragment
        }
        threeYearsAverageMeterPrices(
          sellerId: $sellerId
          currencyCode: $currencyCode
          year: $year
          negotiationStatusCodes: $negotiationStatuses
        ) {
          averageMeterPriceRequestedYear
          averageMeterPriceRequestedYearMinus1
          averageMeterPriceRequestedYearMinus2
          averageMeterPriceDiffInPercentFromPreviousYear
          averageMeterPriceDiffInPercentFromPreviousYearMinus1
          averageMeterPriceDiffInPercentFromPreviousYearMinus2
        }
      }
    }
  }
  ${statisticsDetailsFragment}
`;

export const useSellerDetailsVolumeAndRatesQuery = (
  sellerId: string,
  currencyCode: string,
  negotiationStatusCodes: Array<NegotiationStatusCode>,
  year: number
) => {
  const { data, error, loading } = useQuery<
    UseSellerDetailsVolumeAndRatesQuery,
    UseSellerDetailsVolumeAndRatesQueryVariables
  >(query, {
    variables: {
      sellerId,
      currencyCode,
      negotiationStatuses: negotiationStatusCodes,
      year,
    },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    volumeAndRates: data?.statistics.sellerDetails.volumeAndRates,
    averageMeterPrices:
      data?.statistics.sellerDetails.threeYearsAverageMeterPrices,
  };
};
