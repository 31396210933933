import * as React from "react";
import styled from "@emotion/styled";
import { MatrixTimeslot } from "../../../types";
import { COLUMN_WIDTH, WeekDaysColumn } from "./WeekDaysColumn";
import { MatrixTableBackgroundLines } from "./MatrixTableBackgroundLines";
import { MatrixTableHeader } from "./MatrixTableHeader";
import { MatrixTableTimeslotBars } from "./MatrixTableTimeslotBars";

interface Props {
  timeslots: MatrixTimeslot[];
}

export const HEADER_HEIGHT = 40;
const WEEKDAY_COLUMN_WIDTH = 100;

const GridContainerMatrix = styled.div`
  display: grid;
  grid-template-columns: ${WEEKDAY_COLUMN_WIDTH}px repeat(25, ${COLUMN_WIDTH}px);
  grid-template-rows: ${HEADER_HEIGHT}px;
  grid-auto-rows: 20px;
  grid-gap: 10px 0;
`;

export const MatrixScheduleView: React.FC<Props> = ({ timeslots }) => (
  <GridContainerMatrix>
    <WeekDaysColumn timeslots={timeslots} />
    <MatrixTableHeader />
    <MatrixTableBackgroundLines />
    <MatrixTableTimeslotBars timeslots={timeslots} />
  </GridContainerMatrix>
);
