import { AppThunk } from "../../../../config/redux/RootReducer";
import {
  createEditableEntityActions,
  createEntityListActions,
  createRecordObjectActions,
  EditableEntitySetEditableEntityFieldsAction,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { ExpectedVolumeTableRow } from "../types";
import {
  expectedVolumeActions,
  expectedVolumeReducerId,
} from "../redux/ExpectedVolumeRedux";
import { batch } from "react-redux";

export const copyLastYear =
  (routePairId: string): AppThunk =>
  async (dispatch, getState) => {
    const rows =
      getState().expectedVolume.expectedVolumesByRoutePair[routePairId];

    batch(() => {
      rows.volumes.forEach((row) => {
        dispatch(
          expectedVolumeActions.setRoutePairMetaFields(routePairId, {
            hasVolume: true,
          })
        );

        dispatch(
          reducerIdGateAction(
            expectedVolumeReducerId,
            createRecordObjectActions().recordAction(
              routePairId,
              createEntityListActions<
                ExpectedVolumeTableRow,
                EditableEntitySetEditableEntityFieldsAction<ExpectedVolumeTableRow>
              >().actionByFieldsMatch(
                { id: row.editable.id },
                createEditableEntityActions<ExpectedVolumeTableRow>().setEditableEntityFields(
                  {
                    accompaniedExpectedVolPeak:
                      row.editable.accompanied12MonthVolPeak,
                    accompaniedExpectedVolShoulder:
                      row.editable.accompanied12MonthVolShoulder,
                    accompaniedExpectedVolOffPeak:
                      row.editable.accompanied12MonthVolOffPeak,
                    unaccompaniedExpectedVolPeak:
                      row.editable.unaccompanied12MonthVolPeak,
                    unaccompaniedExpectedVolShoulder:
                      row.editable.unaccompanied12MonthVolShoulder,
                    unaccompaniedExpectedVolOffPeak:
                      row.editable.unaccompanied12MonthVolOffPeak,
                  }
                )
              )
            )
          )
        );
      });
    });
  };
