const currentYear = new Date().getUTCFullYear();
const currentMonth = new Date().getUTCMonth() + 1;

export interface DateSelectInputValues {
  year: number;
  month: number;
}

export const initialDateInputState: DateSelectInputValues = {
  year: currentYear,
  month: currentMonth,
};
