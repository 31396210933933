import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { useAllGoods } from "../goods-multi-select/GoodsMultiSelect";
import { GoodsMultiSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

interface GoodsOption {
  label: string;
  value: string;
  data: GoodsMultiSelectQuery["productPrice"]["goods"]["all"];
}

export const GoodsSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useAllGoods();

  const goods = useMemo(() => {
    return data?.productPrice.goods.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return goods.map((r) => ({
      label: `${r.name} (${r.id})`,
      value: r.id,
      data: r,
    }));
  }, [goods]);

  const selected = useMemo(
    () => options.filter((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected}
      onChange={(v: GoodsOption) => onValueChange?.((v as GoodsOption).value)}
      {...selectProps}
    />
  );
};
