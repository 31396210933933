import * as React from "react";
import { Box, Text } from "@stenajs-webui/core";
import { Collapsible } from "@stenajs-webui/panels";
import styles from "./CollapsableSection.module.css";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  collapsed: boolean;
  onToggleCollapsed: () => void;
  label: string;
  rightInfoLabelNumber?: number;
  rightInfoLabel?: string;
}

const borderBottom = `1px solid ${cssColor("--lhds-color-ui-300")}`;

export const CollapsibleSection: React.FC<Props> = ({
  children,
  label,
  collapsed,
  onToggleCollapsed,
  rightInfoLabelNumber,
  rightInfoLabel,
}) => {
  return (
    <Box borderBottom={collapsed ? undefined : borderBottom}>
      <Collapsible
        label={label}
        className={styles.collapsibleButton}
        collapsed={collapsed}
        contentRight={
          <>
            {<Text variant={"bold"}>{rightInfoLabelNumber}&nbsp;</Text>}
            {<Text>{rightInfoLabel}</Text>}
          </>
        }
        onClick={onToggleCollapsed}
      >
        {children}
      </Collapsible>
    </Box>
  );
};
