import { HeadingVariant, Row, Space } from "@stenajs-webui/core";
import { Property } from "csstype";
import * as React from "react";
import { ReactNode } from "react";
import { PaddedHeading } from "../padded-heading/PaddedHeading";

interface Props {
  title?: string;
  contentNearRight?: ReactNode;
  contentRight?: ReactNode;
  contentLeft?: ReactNode;
  infoRow?: ReactNode;
  variant?: HeadingVariant;
  whiteSpace?: Property.WhiteSpace;
  space?: number;
}

export const PageSubHeader: React.FC<Props> = ({
  title,
  contentRight,
  contentNearRight,
  contentLeft,
  infoRow,
  variant = "h4",
  whiteSpace,
  space = 3,
}) => {
  return (
    <>
      <Row alignItems={"center"} justifyContent={"space-between"}>
        <Row alignItems={"center"} height={"62px"}>
          {contentLeft}
          <PaddedHeading variant={variant} whiteSpace={whiteSpace}>
            {title}
          </PaddedHeading>
          {contentNearRight && (
            <>
              <Space num={space} />
              {contentNearRight}
            </>
          )}
        </Row>
        <Row alignItems={"center"}>{contentRight}</Row>
      </Row>
      {infoRow}
    </>
  );
};
