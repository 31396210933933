import { useQuery } from "@apollo/client";
import { Box, SeparatorLine } from "@stenajs-webui/core";
import { Card } from "@stenajs-webui/elements";
import gql from "graphql-tag";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useFadeState } from "../../../../../common/hooks/UseFadedState";
import { gtmTransmitter } from "../../../../../common/utils/GtmHelper";
import { AllRouteTargetChartDisplayStatusChange } from "../../../../../common/utils/route-target-gtm/RouteTargetChartGtmHelper";
import { RouteTargetErrorOccured } from "../../../../../common/utils/route-target-gtm/RouteTargetGtmHelper";
import { TargetSummaryChart } from "./TargetSummaryChart";
import { TargetSummaryHeader } from "./TargetSummaryHeader";
import { TargetSummaryStatistics } from "./TargetSummaryStatistics";
import { TargetSummaryStatisticsData } from "./types";
import {
  CategoryDataFragment,
  TargetSummaryQuery,
  TargetSummaryQueryVariables,
} from "@/gql/graphql";

interface Props {
  currency: string;
  selectedYear: number;
}

const targetSummaryQuery = gql`
  fragment CustomerDataPoints on CustomerDataPoints {
    custNo
    avgNetMeterPrice
    avgGrossMeterPrice
  }
  fragment CategoryData on DetailedCategoryData {
    unitCategoryCode
    sailingTypeCode
    noOfAccounts
    gmTarget
    customerTarget
    customerDataPoints {
      ...CustomerDataPoints
    }
  }
  query TargetSummary($byIdInput: GetTargetSummaryInput) {
    productPrice {
      targetSummary {
        byId(input: $byIdInput) {
          noOfAccounts
          volume
          totalGmTarget
          totalCustomerTarget
          completionRate
          categoryData {
            ...CategoryData
          }
        }
      }
    }
  }
`;

interface TargetData {
  targetChartData: Array<CategoryDataFragment>;
  targetSummaryStatisticsData: TargetSummaryStatisticsData;
}

export const TargetSummary: React.FC<Props> = ({ currency, selectedYear }) => {
  const [chartOpen, setChartOpen] = useState(false);
  const [isRefetching, setIsRefetching] = useFadeState<boolean>(false, 1000);

  const { routePairCode } = useParams() as { routePairCode: string };

  const { previousData, data, loading, error } = useQuery<
    TargetSummaryQuery,
    TargetSummaryQueryVariables
  >(targetSummaryQuery, {
    variables: useMemo(
      () => ({
        byIdInput: {
          currency: currency,
          year: selectedYear,
          routePairCode: routePairCode,
        },
      }),
      [currency, selectedYear, routePairCode]
    ),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const handleChartOpen = (): void => {
    gtmTransmitter({
      event: AllRouteTargetChartDisplayStatusChange.clickEvent,
      chartDisplayStatus: chartOpen
        ? AllRouteTargetChartDisplayStatusChange.hideChart
        : AllRouteTargetChartDisplayStatusChange.showChart,
    });
    setChartOpen((prev) => !prev);
  };

  const targetSummaryData = data?.productPrice.targetSummary.byId;

  const targetData = useMemo((): TargetData | undefined => {
    if (targetSummaryData) {
      const {
        categoryData,
        completionRate,
        noOfAccounts,
        totalCustomerTarget,
        totalGmTarget,
        volume,
      } = targetSummaryData;

      const statisticsData = {
        volume,
        totalGmTarget,
        totalCustomerTarget,
        completionRate,
        noOfAccounts,
        categoryData: categoryData.map((cd) => {
          return {
            gmTarget: cd.gmTarget,
            customerTarget: cd.customerTarget,
            category: `${cd.sailingTypeCode}-${cd.unitCategoryCode}`,
          };
        }),
      };

      return {
        targetChartData: targetSummaryData.categoryData,
        targetSummaryStatisticsData: statisticsData,
      };
    }

    return undefined;
  }, [targetSummaryData]);

  useEffect(() => {
    if (error) {
      gtmTransmitter({
        event: RouteTargetErrorOccured.errorEvent,
      });
    }
  }, [error]);

  useEffect(() => {
    if (previousData !== undefined) {
      setIsRefetching(true);
    }
  }, [previousData, setIsRefetching]);

  return (
    <Card overflow={"auto"}>
      <TargetSummaryHeader
        heading="Price and target overview"
        handleChartOpen={handleChartOpen}
        chartOpen={chartOpen}
        loading={loading}
      />
      <SeparatorLine />
      <Box display={!chartOpen ? "none" : ""} minWidth={1500}>
        <TargetSummaryChart
          loading={loading}
          targetChartData={targetData?.targetChartData}
          currency={currency}
          error={error}
        />
        {chartOpen && <SeparatorLine />}
      </Box>
      <TargetSummaryStatistics
        targetSummaryStatisticsData={targetData?.targetSummaryStatisticsData}
        loading={loading}
        error={error}
        isRefetching={isRefetching}
      />
    </Card>
  );
};
