import { routeFactory } from "@/RouteFactory";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { createResultTableConfig } from "@/features/statistics/common/config/list/ResultTableConfig";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ResultTable } from "@/features/statistics/common/table/ResultTable";
import { transformSelectedStatusesToNegotiationStatusCodes } from "@/features/statistics/common/transformers/NegotiationStatusFormModelTransformer";
import { Box, Row, SeparatorLine } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import * as React from "react";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRoutePairListQuery } from "../hooks/UseRoutePairListQuery";
import { useRoutePairListVolumeAndTargetQuery } from "../hooks/UseRoutePairListVolumeAndTargetQuery";
import { transformResultToTableRow } from "../transformers/ResultTableRowTransformer";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { useDispatch } from "react-redux";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { statisticsByRouteFilterSettings } from "../config/StatisticsByRouteFilterConfig";
import { NegotiationStatusCode } from "@/gql/graphql";

export const StatisticsByRoutePairTable: React.FC = () => {
  const sticky = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { committedState, workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { year: negotiationYear } = useStatisticsYearSelect();
  const { currency: currencyCode } = useCurrencySelection();
  const { routePairs } = committedState;

  const {
    routePairCustomerOrVolumeFilter,
    setRoutePairCustomerOrVolumeFilter,
    routePairSelectedStatuses,
    setRoutePairSelectedStatuses,
  } = useContext(StatisticsContext) as StatisticsContextType;

  const { loading, routePairItems } = useRoutePairListQuery({
    year: negotiationYear,
    routePairIds: routePairs.map((u) => u.id),
  });

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(
        routePairSelectedStatuses
      ),
    [routePairSelectedStatuses]
  );

  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;

  const { loading: loadingVolumeAndTarget, volumeAndTarget } =
    useRoutePairListVolumeAndTargetQuery({
      year: negotiationYear,
      routePairIds: routePairs.map((u) => u.id),
      negotiationStatusCodes,
      currencyCode,
    });

  const resultRows = useMemo(
    () =>
      routePairItems?.map((routePairItem) =>
        transformResultToTableRow(
          routePairItem,
          loadingVolumeAndTarget ? [] : volumeAndTarget ?? [],
          routePairCustomerOrVolumeFilter
        )
      ),
    [
      routePairItems,
      volumeAndTarget,
      loadingVolumeAndTarget,
      routePairCustomerOrVolumeFilter,
    ]
  );

  const resultConfig = createResultTableConfig(
    "Route",
    (id) => navigate(routeFactory.statistics.routeDetails(id)),
    currencyCode,
    routePairSelectedStatuses,
    setRoutePairSelectedStatuses,
    routePairCustomerOrVolumeFilter,
    setRoutePairCustomerOrVolumeFilter,
    sticky,
    true
  );

  return (
    <Card minWidth={"1070px"}>
      <CardHeader text={"Route"} />
      <CardBody>
        <Row>
          <Box>
            <SearchFilterContext
              state={workspaceState}
              actions={createFppSearchFilterActions(
                "statistics.statisticsByRoutePair"
              )}
              dispatch={dispatch}
            >
              <FppSearchFilter settings={statisticsByRouteFilterSettings} />
            </SearchFilterContext>
          </Box>
        </Row>
      </CardBody>
      <SeparatorLine />
      <ResultTable
        items={resultRows}
        config={resultConfig}
        loading={loading || loadingVolumeAndTarget}
        noItemsLabel={"Please select routes to show statistics."}
      />
    </Card>
  );
};
