import * as React from "react";
import { SecondaryButton } from "@stenajs-webui/elements";
import { ActionPrompt, Popover } from "@stenajs-webui/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { routeAgreementOriginalSelectors } from "../redux";
import { useResetForms } from "../hooks/UseResetForms";
import { useBoolean } from "@stenajs-webui/core";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";

interface Props {
  disabled?: boolean;
  onDiscardChanges: () => void;
  dataTestid?: string;
}

export const RouteAgreementDiscardChangesButton: React.FC<Props> = ({
  disabled,
  onDiscardChanges,
  dataTestid,
}) => {
  const dispatch = useDispatch();
  const [visible, show, hide] = useBoolean(false);
  const originalFormModel = useSelector(
    routeAgreementOriginalSelectors.getEntity
  );
  const { resetForms } = useResetForms(originalFormModel);

  const onYesHandler = () => {
    resetForms();
    hide();
    onDiscardChanges();
    dispatch(refetchStatBoxesWithRateSheetsChanges());
  };

  return (
    <Popover
      visible={visible}
      onClickOutside={hide}
      content={<ActionPrompt onNo={hide} onYes={onYesHandler} />}
      appendTo={document.body}
    >
      <SecondaryButton
        label={"Discard"}
        onClick={show}
        disabled={disabled}
        data-Testid={dataTestid}
      />
    </Popover>
  );
};
