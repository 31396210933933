import { SurchargeDocumentsQuery } from "@/gql/graphql";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { SurchargeDocumentsPreviewPdfLink } from "../components/SurchargeDocumentsPreviewPdfLink";
import { ApiErrorResult } from "../components/SurchargeDocumentsUploadModal";

export interface SurchargeDocumentsTableItem
  extends Omit<
    SurchargeDocumentsQuery["productPrice"]["additionalCharges"]["all"]["0"],
    "__typename"
  > {}

export type SurchargeDocumentsTableColumns =
  | keyof Omit<SurchargeDocumentsTableItem, "id" | "createdBy" | "createdDate">;

export const surchargeDocumentsTableConfig = (
  setError: React.Dispatch<React.SetStateAction<ApiErrorResult | undefined>>
): StandardTableConfig<
  SurchargeDocumentsTableItem,
  SurchargeDocumentsTableColumns
> => ({
  keyResolver: (item) => item.id,
  rowIndent: 0.5,
  showHeaderCheckbox: true,
  showRowCheckbox: true,
  columns: {
    fileName: {
      itemValueResolver: (item) => item.fileName,
      renderCell: ({ item }) => (
        <SurchargeDocumentsPreviewPdfLink item={item} setError={setError} />
      ),
      columnLabel: "Filename",
    },
    seaArea: createColumnConfig((item) => item.seaArea?.name, {}),
    validFrom: createColumnConfig((item) => item.validFrom, {}),
    currency: createColumnConfig((item) => item.currency, {}),
  },
  columnOrder: ["fileName", "seaArea", "validFrom", "currency"],
});
