import { useFetchAllVehicleTypes } from "@/features/search-filter/hooks/FilterFetchers/UseFetchAllVehicleTypes";
import { VehicleType } from "@/features/search-filter/types/FilterEntitys";
import {
  SearchFilterSectionChipModel,
  SectionChips,
} from "@stenajs-webui/filter";
import _ from "lodash";

const getFilterChipsByAccompanied = (
  allVehicleTypes: VehicleType[],
  selectedVehicleTypes: VehicleType[],
  isAccompanied: boolean
) => {
  const filteredChips: SearchFilterSectionChipModel[] = [];
  const name = isAccompanied ? "Accompanied" : "Unaccompanied";
  const allFilterByAcc = allVehicleTypes.filter(
    (vehicleType) => vehicleType.accompanied === isAccompanied
  );

  const selectedFilteredByAcc = selectedVehicleTypes.filter(
    (vehicleType) => vehicleType.accompanied === isAccompanied
  );

  if (
    _.isEqual(
      allFilterByAcc.sort((a, b) => (a.code < b.code ? -1 : 1)),
      selectedFilteredByAcc.sort((a, b) => (a.code < b.code ? -1 : 1))
    )
  ) {
    filteredChips.push({ label: name, value: name });
  } else {
    selectedFilteredByAcc.forEach((selected) =>
      filteredChips.push({ label: selected.name, value: selected.id })
    );
  }
  return filteredChips;
};

const calculateChips = (
  allVehicleTypes: VehicleType[],
  selectedVehicleTypes: VehicleType[]
) => {
  const accompaniedChips = getFilterChipsByAccompanied(
    allVehicleTypes,
    selectedVehicleTypes,
    true
  );
  const unAccompaniedChips = getFilterChipsByAccompanied(
    allVehicleTypes,
    selectedVehicleTypes,
    false
  );
  return _.concat(accompaniedChips, unAccompaniedChips);
};

export const VehicleTypeChips = ({
  enabled,
  selectedVehicleTypes,
}: {
  enabled?: boolean;
  selectedVehicleTypes: VehicleType[];
}) => (
  <>
    {enabled && (
      <VehicleTypeChipsInternal
        enabled={enabled}
        selectedVehicleTypes={selectedVehicleTypes}
      />
    )}
  </>
);

const VehicleTypeChipsInternal = ({
  selectedVehicleTypes,
}: {
  enabled?: boolean;
  selectedVehicleTypes: VehicleType[];
}) => {
  const { items } = useFetchAllVehicleTypes();
  const chips = calculateChips(items, selectedVehicleTypes);
  return <SectionChips sectionId={"vehicleType"} chips={chips} />;
};
