import { errorFragment } from "@/common/error/GqlErrorFragment";
import {
  ChangeHeadAgreementStatusMutation,
  ChangeHeadAgreementStatusMutationVariables,
} from "@/gql/graphql";
import { gql, useMutation } from "@apollo/client";

const query = gql`
  ${errorFragment}
  mutation ChangeHeadAgreementStatus(
    $headAgreementId: ID!
    $status: HeadAgreementStatusCode!
    $rowVersion: String!
  ) {
    productPrice {
      headAgreement {
        changeHeadAgreementStatus(
          headAgreementId: $headAgreementId
          rowVersion: $rowVersion
          status: $status
        ) {
          ... on UpdateHeadAgreementSuccessResult {
            headAgreement {
              id
              statusCode
              rowVersion
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useChangeHeadAgreementStatusMutation = () =>
  useMutation<
    ChangeHeadAgreementStatusMutation,
    ChangeHeadAgreementStatusMutationVariables
  >(query);
