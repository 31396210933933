import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { StoreState } from "../../../../config/redux/RootReducer";
import { createNoShowLateHandlingSearchInvoicesStateSelectors } from "../redux/selectors";

import { setNslhSearchInvoicesByInvoiceNoData } from "../redux/reducers";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import {
  SearchByInvoiceNoInvoicesQuery,
  SearchByInvoiceNoInvoicesQueryVariables,
} from "@/gql/graphql";

const queryByInvoiceNo = gql`
  fragment QueryByInvoiceNoNslhInvoice on NslhInvoice {
    id
    invoiceNo
    consignmentNo
  }
  query SearchByInvoiceNoInvoices($id: ID!) {
    productPrice {
      noShowLateHandling {
        nslhInvoice {
          byId(id: $id) {
            ...QueryByInvoiceNoNslhInvoice
          }
        }
      }
    }
  }
`;

const queryInvoiceNoParameterSelector = (state: StoreState) =>
  createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesInvoiceNoInput(
    state
  );

export const useNslhInvoiceQueryByInvoiceNumber = () => {
  const dispatch = useDispatch();
  const invoiceNo = useSelector(queryInvoiceNoParameterSelector);

  const [error, setError] = useState<FreightPricingPortalError | undefined>(
    undefined
  );

  const [fetchNslhInvoicesByInvoiceNumber, { loading }] = useLazyQuery<
    SearchByInvoiceNoInvoicesQuery,
    SearchByInvoiceNoInvoicesQueryVariables
  >(queryByInvoiceNo, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      dispatch(
        setNslhSearchInvoicesByInvoiceNoData(
          data.productPrice.noShowLateHandling.nslhInvoice.byId
        )
      );
    },
    onError: (err) => {
      if (err) {
        const fppError = transformApolloErrorToFreightPricingPortalErrors(err);
        if (fppError.length > 0) {
          setError(transformApolloErrorToFreightPricingPortalErrors(err)[0]);
        }
        return;
      }
    },
    variables: {
      id: String(invoiceNo),
    },
  });

  return {
    fetchNslhInvoicesByInvoiceNumber,
    loading,
    error,
    setError,
  };
};
