import {
  InternalOneMonthBookingsQuery,
  InternalOneMonthBookingsQueryVariables,
} from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";

const queryInternalOneMonthBookings = gql`
  query InternalOneMonthBookings($custNo: Int!, $year: Int!, $month: Int!) {
    productPrice {
      noShowLateHandling {
        nslhFollowUp {
          byFilter(input: { custNo: $custNo, month: $month, year: $year }) {
            id
            customer {
              id
              custNo
              custIndex
              name
              customerThresholds(year: $year, month: $month) {
                thresholdTypeCode
                percentage
              }
            }
            routePair {
              id
              code
              description
            }
            nslhRouteVolume(year: $year, month: $month) {
              id
              routePair {
                id
                code
              }
              shippedVolume
            }
            shippedVolume
            noOfNoShows
            noOfExcludedNoShows
            noOfLateHandlings
            noOfExcludedLateHandlings
            nslhBookingVolume(year: $year, month: $month) {
              id
              thresholdNoShowPercentage
              thresholdLateHandleBookings
              thresholdLateHandlePercentage
              thresholdNoShowBookings
            }
            nslhBookings {
              id
              customer {
                id
                custNo
              }
              consignmentNo
              articlePrice
              bookingNo
              bookingVersion
              customerReference
              departureDate
              departureTime
              handlingStatus
              handlingType
              handlingTypeGrouped
              revisedDate
              revisedTime
              trailerReg
              vehicleReg
              vehicleType {
                id
                shortDescription
              }
              route {
                id
                routePair {
                  id
                  code
                  description
                }
              }
              lateHandlingDate
              lateHandlingUser
            }
          }
        }
      }
    }
  }
`;

export const useFetchOneMonthBookings = (
  custNo: number,
  year: number,
  month: number
) => {
  const { data, error, loading } = useQuery<
    InternalOneMonthBookingsQuery,
    InternalOneMonthBookingsQueryVariables
  >(queryInternalOneMonthBookings, {
    fetchPolicy: "cache-and-network",
    variables: {
      custNo,
      year,
      month,
    },
  });

  const oneMonthBookings =
    data?.productPrice.noShowLateHandling.nslhFollowUp.byFilter ?? null;

  return {
    oneMonthBookings,
    error,
    loading,
  };
};
