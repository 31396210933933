import { AppThunk } from "../../../config/redux/RootReducer";
import { apolloClient } from "../../../apollo-client/ApolloClient";
import { statBoxesRedux } from "../redux";
import { calculateCustomerRouteStatisticsTable } from "../transformers/StatBoxDataTransformer";
import { mergeStatBoxQueryData } from "../transformers/StatBoxQueryDataMerger";
import { gql } from "@apollo/client";
import {
  FetchStatBoxesQueryVariables,
  FetchStatBoxesQuery,
} from "@/gql/graphql";

const query = gql`
  fragment CustomerStatisticsByFilterItems on CustomerStatisticsEntry {
    id
    expectedVolume
    statVolume12Months
    unitCategoryCode
    expectedStatVolume
    sailingCategoryCode
    volume12Months
    actualOutcomeInPercent
    targetInPercent
  }
  query FetchStatBoxes($customerId: ID!, $routePairId: ID!, $year: Int!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          statisticsByFilter(routePairId: $routePairId, year: $year) {
            items {
              ...CustomerStatisticsByFilterItems
            }
          }
        }
      }
    }
  }
`;

export const clearAndFetchStatBoxes =
  (params: FetchStatBoxesQueryVariables): AppThunk =>
  async (dispatch) => {
    await dispatch(statBoxesRedux.actions.clearAll());
    await dispatch(statBoxesRedux.actions.setQueryParams(params));
    await dispatch(refetchStatBoxes());
  };

export const refetchStatBoxes = (): AppThunk => async (dispatch, getState) => {
  dispatch(statBoxesRedux.actions.setLoading());

  const queryParams = getState().statBoxes.queryParams;

  if (!queryParams) {
    console.error("Can not fetch stat boxes, query params are not set.");
    return;
  }

  try {
    const r = await apolloClient.query<
      FetchStatBoxesQuery,
      FetchStatBoxesQueryVariables
    >({
      query,
      variables: queryParams,
      fetchPolicy: "network-only",
    });

    const items =
      r.data.productPrice.customers.byId?.statisticsByFilter.items ?? [];

    dispatch(
      statBoxesRedux.actions.setQueryData(mergeStatBoxQueryData({}, items))
    );

    const transformedData = calculateCustomerRouteStatisticsTable(
      items,
      queryParams.customerId
    );

    dispatch(statBoxesRedux.actions.setStatBoxesData(transformedData));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(statBoxesRedux.actions.setNotLoading());
  }
};
