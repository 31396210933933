import {
  AvailableYearFragment,
  DefaultNegotiationYearSelectQuery,
} from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";
import { ApolloError } from "apollo-boost";
import { useEffect, useState } from "react";

const currentYear = new Date().getUTCFullYear();
export interface YearSelection {
  year: number;
  handleYearChange: (year: number) => void;
  loading: boolean;
  error: ApolloError | undefined;
  availableYears: AvailableYearFragment[] | undefined;
}

const query = gql`
  fragment AvailableYear on NegotiationYear {
    id
    year
  }
  query DefaultNegotiationYearSelect {
    productPrice {
      negotiationYear {
        availableYears {
          ...AvailableYear
        }
      }
    }
  }
`;

export const useDefaultYearSelection = (): YearSelection => {
  const { data, loading, error } =
    useQuery<DefaultNegotiationYearSelectQuery>(query);

  const highestAvailableYear =
    data?.productPrice.negotiationYear.availableYears.reduce(
      (a, b) => Math.max(a, b.year),
      0
    );
  const [year, setYear] = useState(highestAvailableYear ?? currentYear);

  useEffect(() => {
    if (data) {
      setYear(highestAvailableYear ?? currentYear);
    }
  }, [data, highestAvailableYear]);

  const handleYearChange = (selectedYear: number) => {
    setYear(selectedYear);
  };

  return {
    year,
    handleYearChange,
    loading,
    error,
    availableYears: data?.productPrice.negotiationYear.availableYears,
  };
};
