import { AppThunk } from "../../../../../config/redux/RootReducer";
import { keys } from "lodash";
import { RateSheetTableRowState } from "../../reducer";
import { rateSheetStandardTableWrapperActions } from "../../redux";
import { routeAgreementCanBeEdited } from "../../../route-agreement/util/RouteAgreementCalculator";

type QuickSelectionFilter = (row: RateSheetTableRowState) => boolean;

export const bulkUpdateQuickSelectRowsByFilter =
  (filter: QuickSelectionFilter): AppThunk =>
  async (dispatch, getState) => {
    const routeAgreementsById = getState().rateSheet.routeAgreements;
    const tableState = getState().rateSheet.table;
    const routeAgreementIds = keys(tableState);
    await Promise.all(
      routeAgreementIds
        .filter((routeAgreementId) => {
          const r = routeAgreementsById[routeAgreementId];
          return !!r && routeAgreementCanBeEdited(r);
        })
        .map(async (routeAgreementId) => {
          const rows = tableState[routeAgreementId].rows;
          const priceIds = rows
            .filter(filter)
            .map((r) => r.routeAgreementPriceId);
          await dispatch(
            rateSheetStandardTableWrapperActions.selectPriceRows(
              routeAgreementId,
              priceIds
            )
          );
        })
    );
  };
