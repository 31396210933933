import { Box, Space, Text } from "@stenajs-webui/core";
import React from "react";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  label: string;
  selectedYear: string;
}

export const RouteTargetNegotiationYearInfoPair: React.FC<Props> = ({
  selectedYear,
  label,
}) => {
  return (
    <Box row indent={2.5}>
      <Text size="small" color={cssColor("--lhds-color-ui-500")}>
        {label}
      </Text>
      <Space />
      <Text size="small" color={cssColor("--lhds-color-ui-700")}>
        {selectedYear}
      </Text>
    </Box>
  );
};
