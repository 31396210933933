import {
  ArticleItemFormModel,
  ArticlePriceFormModel,
  ArticlesFormModel,
  ArticlesFormulaDataFormModel,
} from "./ArticlesFormModel";
import { parseFloatElseThrow } from "@/common/numbers";
import {
  getModifiedOrNull,
  getModifiedParsedNumberOrNull,
  getModifiedPriceOrNull,
  getPersistedDeletedDiff,
} from "../../../utils/InputTransformerHelpers";
import { isEqual } from "lodash";
import {
  ArticlesFormInput,
  DeleteArticleItemInput,
  ArticleItemChangesInput,
  UpdateArticleItemInput,
  RequiredFormulaDataFormInput,
  ArticlePriceInput,
  ArticlePriceChangesInput,
  FormulaDataFormInput,
  UseAllFormulaTypesQuery,
  FormulaFormInputsIncludesFragment,
} from "@/gql/graphql";

export const transformArticleFormToInput = (
  formModel: ArticlesFormModel,
  persisted: ArticlesFormModel,
  allFormulaTypes: UseAllFormulaTypesQuery["productPrice"]["formula"]["all"]
): ArticlesFormInput => {
  const { created, deleted, rest } = getPersistedDeletedDiff(
    Object.values(formModel.articles)
  );

  return {
    deleted: deleted.map<DeleteArticleItemInput>((d) => ({ articleId: d.id })),
    created: created.map<ArticleItemChangesInput>((a) =>
      transformArticleToInput(a, undefined, allFormulaTypes)
    ),
    updated: rest.map<UpdateArticleItemInput>((a) => ({
      articleId: a.id,
      changes: transformArticleToInput(
        a,
        Object.values(persisted.articles).find((p) => p.id === a.id),
        allFormulaTypes
      ),
    })),
  };
};

export const transformArticleToInput = (
  article: ArticleItemFormModel,
  persisted: ArticleItemFormModel | undefined,
  allFormulaTypes: UseAllFormulaTypesQuery["productPrice"]["formula"]["all"]
): ArticleItemChangesInput => {
  return {
    articleTypeId: getModifiedOrNull(
      article.articleTypeId,
      persisted?.articleTypeId
    ),
    maxPrice: getModifiedPriceOrNull(article.maxPrice, persisted?.maxPrice),
    maxNumberOf: getModifiedParsedNumberOrNull(
      article.maxNumberOf,
      persisted?.maxNumberOf
    ),
    includedInPrice: getModifiedOrNull(
      article.includedInPrice,
      persisted?.includedInPrice
    ),
    prices: transformPrices(article.prices, persisted?.prices ?? []),
    formulaTypeId: getModifiedOrNull(
      article.formulaTypeId!,
      persisted?.formulaTypeId
    ),
    formulaData: getFormulaDataOrNull(
      article.formulaTypeId,
      article.formulaData,
      persisted?.formulaData,
      allFormulaTypes
    ),
  };
};

const getFormulaDataOrNull = (
  formulaTypeId: string | undefined,
  formulaData: ArticlesFormulaDataFormModel,
  persisted: ArticlesFormulaDataFormModel | undefined,
  allFormulaTypes: UseAllFormulaTypesQuery["productPrice"]["formula"]["all"]
): RequiredFormulaDataFormInput | null => {
  if (isEqual(formulaData, persisted)) {
    return null;
  }

  if (!formulaTypeId) {
    return null;
  }

  const formulaType = allFormulaTypes.find((f) => f.id === formulaTypeId);

  return {
    value: transformFormulaFormToInput(formulaData, formulaType?.includes),
  };
};

export const transformPrices = (
  prices: Array<ArticlePriceFormModel>,
  persisted: Array<ArticlePriceFormModel>
): ArticlePriceInput => {
  const deleted = prices.filter((a) => a.deleted && a.persisted);
  const created = prices.filter((a) => !a.deleted && !a.persisted);
  const updated = prices.filter((a) => !a.deleted && a.persisted);

  return {
    deleted: deleted.map((p) => ({ articlePriceId: p.id })),
    created: created.map((p) => transformArticlePriceFormToInput(p, undefined)),
    updated: updated.map((p) => ({
      articlePriceId: p.id,
      changes: transformArticlePriceFormToInput(
        p,
        persisted.find((pp) => pp.id === p.id)
      ),
    })),
  };
};

export const transformArticlePriceFormToInput = (
  price: ArticlePriceFormModel,
  persisted: ArticlePriceFormModel | undefined
): ArticlePriceChangesInput => {
  return {
    vehicleTypeId: getModifiedOrNull(
      price.vehicleTypeId!,
      persisted?.vehicleTypeId
    ),
    price: getModifiedPriceOrNull(price.price, persisted?.price),
    percent: getModifiedParsedNumberOrNull(price.percent, persisted?.percent),
    weightFrom: getModifiedParsedNumberOrNull(
      price.weightFrom,
      persisted?.weightFrom
    ),
    weightTo: getModifiedParsedNumberOrNull(
      price.weightTo,
      persisted?.weightTo
    ),
    lengthFrom: getModifiedParsedNumberOrNull(
      price.lengthFrom,
      persisted?.lengthFrom
    ),
    lengthTo: getModifiedParsedNumberOrNull(
      price.lengthTo,
      persisted?.lengthTo
    ),
  };
};

const transformFormulaFormToInput = (
  formula: ArticlesFormulaDataFormModel,
  includes: FormulaFormInputsIncludesFragment | undefined
): FormulaDataFormInput => {
  return {
    drivers: getFormulaDataFieldValue(formula, "drivers", includes)!,
    interval1PercentValue: getFormulaDataFieldValue(
      formula,
      "interval1PercentValue",
      includes
    )!,
    interval2PercentValue: getFormulaDataFieldValue(
      formula,
      "interval2PercentValue",
      includes
    )!,
    interval3PercentValue: getFormulaDataFieldValue(
      formula,
      "interval3PercentValue",
      includes
    )!,
    interval4PercentValue: getFormulaDataFieldValue(
      formula,
      "interval4PercentValue",
      includes
    )!,
    intervalAPercentValue: getFormulaDataFieldValue(
      formula,
      "intervalAPercentValue",
      includes
    )!,
    intervalBPercentValue: getFormulaDataFieldValue(
      formula,
      "intervalBPercentValue",
      includes
    )!,
    intervalCPercentValue: getFormulaDataFieldValue(
      formula,
      "intervalCPercentValue",
      includes
    )!,
    intervalDPercentValue: getFormulaDataFieldValue(
      formula,
      "intervalDPercentValue",
      includes
    )!,
    intervalLength: getFormulaDataFieldValue(
      formula,
      "intervalLength",
      includes
    )!,
    maxPercent: getFormulaDataFieldValue(formula, "maxPercent", includes)!,
    percent: getFormulaDataFieldValue(formula, "percent", includes)!,
    weight: getFormulaDataFieldValue(formula, "weight", includes)!,
    width: getFormulaDataFieldValue(formula, "width", includes)!,
  };
};

const getFormulaDataFieldValue = (
  formula: ArticlesFormulaDataFormModel,
  field: keyof ArticlesFormulaDataFormModel,
  includes: FormulaFormInputsIncludesFragment | undefined
): number | undefined => {
  if (includes && !includes[field]) {
    return undefined;
  }
  const stringValue = formula[field];
  if (!stringValue) {
    return undefined;
  }
  return parseFloatElseThrow(stringValue);
};
