import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import {
  CountryMultiChipSelectAllQuery,
  CountryMultiSelectAllActiveQuery,
} from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string | null;
  onValueChange?: (value: string) => void;
  countries:
    | CountryMultiChipSelectAllQuery["productPrice"]["country"]["all"]
    | CountryMultiSelectAllActiveQuery["productPrice"]["country"]["allActive"];
  allowNoneSelected?: boolean;
}

export const CountrySelectUi: React.FC<Props> = ({
  onValueChange,
  value,
  countries,
  allowNoneSelected = false,
  ...selectProps
}) => {
  const options = useMemo(() => {
    const countryOptions = countries.map((r) => ({
      label: r.name,
      value: r.id,
    }));
    return allowNoneSelected
      ? [
          {
            label: "None",
            value: null,
          },
          ...countryOptions,
        ]
      : countryOptions;
  }, [countries, allowNoneSelected]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      options={options}
      value={selected || null}
      onChange={(item: any) => onValueChange?.(item.value)}
      {...selectProps}
    />
  );
};
