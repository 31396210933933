import * as React from "react";
import { NumericTextInput, NumericTextInputProps } from "@stenajs-webui/forms";

interface Props extends NumericTextInputProps {}

export const NumericTextInputNoScroll: React.FC<Props> = ({ ...props }) => (
  <NumericTextInput
    {...props}
    onWheel={() => (document.activeElement as HTMLElement).blur()}
  />
);
