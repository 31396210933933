import { useDispatch, useSelector } from "react-redux";
import { profileSelectors } from "@/common/redux/profile/selectors";
import { gql, useSubscription } from "@apollo/client";
import { appendNotification } from "@/features/notification-center/redux/slice";
import {
  FailSubscription,
  SuccessSubscription,
  UpsertedExpectedVolumeSuccessSubscription,
} from "@/gql/graphql";

export const NotificationSubscriber = () => {
  const dispatch = useDispatch();
  const { userName } = useSelector(profileSelectors.getGlobal);

  useSubscription<UpsertedExpectedVolumeSuccessSubscription | null>(
    gql`
      subscription UpsertedExpectedVolumeSuccess($userName: String!) {
        upsertedExpectedVolumeSuccessful(userName: $userName) {
          id
          text
          title
        }
      }
    `,
    {
      variables: {
        userName: userName,
      },
      onData: ({ data: { data } }) => {
        if (data) {
          dispatch(
            appendNotification({
              ...data.upsertedExpectedVolumeSuccessful,
              autoClose: false,
            })
          );
        }
      },
    }
  );
  useSubscription<SuccessSubscription | null>(
    gql`
      subscription Success($userName: String!) {
        agreementReportCreationSuccessful(userName: $userName) {
          id
          text
          title
        }
      }
    `,
    {
      variables: {
        userName: userName,
      },
      onData: ({ data: { data } }) => {
        if (data) {
          dispatch(
            appendNotification({
              ...data.agreementReportCreationSuccessful,
              autoClose: false,
            })
          );
        }
      },
    }
  );
  useSubscription<FailSubscription | null>(
    gql`
      subscription Fail($userName: String!) {
        agreementReportCreationFailed(userName: $userName) {
          id
          text
          title
        }
      }
    `,
    {
      variables: {
        userName: userName,
      },
      onData: ({ data: { data } }) => {
        if (data) {
          dispatch(
            appendNotification({
              ...data.agreementReportCreationFailed,
              autoClose: false,
            })
          );
        }
      },
    }
  );

  return null;
};
