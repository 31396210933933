import { gql, useQuery } from "@apollo/client";
import { Select, SelectProps } from "@stenajs-webui/select";
import { filter } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import {
  AllRoutePairsSelectQuery,
  AllRoutePairsSelectQueryVariables,
} from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

const query = gql`
  query AllRoutePairsSelect($typeCodes: [String!]!) {
    productPrice {
      routePair {
        byRouteTypeCodes(typeCodes: $typeCodes) {
          id
          code
          seaArea {
            id
            name
          }
        }
      }
    }
  }
`;

export const AllRoutePairsSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<
    AllRoutePairsSelectQuery,
    AllRoutePairsSelectQueryVariables
  >(query, {
    variables: {
      typeCodes: ["O", "P", "M"],
    },
  });

  const all = data?.productPrice.routePair.byRouteTypeCodes ?? [];

  const irishNorth = filter(all, ["seaArea.id", "IRISHNORTH"]);

  const irishSouth = filter(all, ["seaArea.id", "IRISHSOUTH"]);

  const northSea = filter(all, ["seaArea.id", "NORTHSEA"]);

  const scand = filter(all, ["seaArea.id", "SCAND"]);

  const germ = filter(all, ["seaArea.id", "GERM"]);

  const baltic = filter(all, ["seaArea.id", "BALTIC"]);

  const baltics = filter(all, ["seaArea.id", "BALTICS"]);

  const optionsIrishNorth = useMemo(() => {
    return irishNorth.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [irishNorth]);

  const optionsIrishSouth = useMemo(() => {
    return irishSouth.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [irishSouth]);

  const optionsNorthSea = useMemo(() => {
    return northSea.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [northSea]);

  const optionsScand = useMemo(() => {
    return scand.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [scand]);

  const optionsGerm = useMemo(() => {
    return germ.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [germ]);

  const optionsBaltic = useMemo(() => {
    return baltic.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [baltic]);

  const optionsBaltics = useMemo(() => {
    return baltics.map((s) => ({
      label: s.code,
      value: s.id,
    }));
  }, [baltics]);

  const selected = useMemo(() => {
    return optionsIrishNorth
      .concat(
        optionsIrishSouth,
        optionsNorthSea,
        optionsScand,
        optionsGerm,
        optionsBaltic,
        optionsBaltics
      )
      .find((o) => o.value === value);
  }, [
    optionsIrishNorth,
    optionsIrishSouth,
    optionsNorthSea,
    optionsScand,
    optionsGerm,
    optionsBaltic,
    optionsBaltics,
    value,
  ]);

  return (
    <Select
      isLoading={loading}
      options={[
        {
          label: "Irish sea north",
          options: optionsIrishNorth,
        },
        {
          label: "Irish sea south",
          options: optionsIrishSouth,
        },
        {
          label: "North sea",
          options: optionsNorthSea,
        },

        {
          label: "Scandinavia",
          options: optionsScand,
        },
        {
          label: "Germany",
          options: optionsGerm,
        },
        {
          label: "Baltic sea south",
          options: optionsBaltic,
        },
        {
          label: "Baltic sea north",
          options: optionsBaltics,
        },
      ]}
      value={selected || null}
      placeholder={"Route"}
      onChange={(v: any) => {
        if (!v) {
          onValueChange?.("");
        } else if (onValueChange && v) {
          onValueChange?.(v.value);
        }
      }}
      {...selectProps}
    />
  );
};
