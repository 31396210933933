import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";
import { SearchFilterChips, SectionChips } from "@stenajs-webui/filter";
import { useContext } from "react";
import { FppFilterChipsWithoutBookingNo } from "./FppFilterChipsWithoutBookingNo";

export const FppSearchFilterChips = ({
  enableChipClearButtons,
  settings,
}: {
  settings: FppFilterSettings;
  enableChipClearButtons?: boolean;
}) => {
  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  return (
    <SearchFilterChips disableClearAllButton>
      {!committedState.bookingNo ? (
        <FppFilterChipsWithoutBookingNo
          settings={settings}
          enableChipClearButtons={enableChipClearButtons}
        />
      ) : (
        <SectionChips
          sectionId={"bookingNo"}
          chips={
            committedState.bookingNo
              ? [
                  {
                    value: committedState.bookingNo,
                    label: "Booking ".concat(committedState.bookingNo),
                  },
                ]
              : undefined
          }
        />
      )}
    </SearchFilterChips>
  );
};
