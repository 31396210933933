import { RouteAgreementFormModel } from "../models/RouteAgreementFormModel";

import { transformRateSheetChangesToInput } from "../../../customer-route/transformers/ModifiedToInputTransformer";
import { RateSheetTableRowsState } from "../../../rate-sheet/reducer";
import { transformNotesFormToInput } from "../features/notes/NotesInputTransformer";
import { transformRoutesFormToInput } from "../features/routes/RoutesInputTransformers";
import { transformArticleFormToInput } from "../features/articles/models/ArticlesInputTransformer";
import { transformConditionsFormToInput } from "../features/conditions/ConditionsInputTransformer";
import { transformMatrixFormToInput } from "../features/matrix/MatrixInputTransformer";
import {
  RouteAgreementFormInput,
  UpdateRouteAgreementInput,
  UseAllFormulaTypesQuery,
} from "@/gql/graphql";

export const createUpdateFormInput = (
  routeAgreementId: string,
  rowVersion: string,
  formModel: RouteAgreementFormModel,
  persisted: RouteAgreementFormModel,
  rateSheetTableState: RateSheetTableRowsState,
  allFormulaTypes: UseAllFormulaTypesQuery["productPrice"]["formula"]["all"]
): UpdateRouteAgreementInput => {
  try {
    return {
      formModel: transformFormToInput(formModel, persisted, allFormulaTypes),
      routeAgreementId,
      rowVersion,
      rateSheetChanges: transformRateSheetChangesToInput(rateSheetTableState),
    };
  } catch (e) {
    throw new Error(e.message + " Please change and try again.");
  }
};

export const createUpdateFormInputForSalesReady = (
  routeAgreementId: string,
  rowVersion: string,
  isSalesReady: boolean
): UpdateRouteAgreementInput => ({
  rowVersion,
  routeAgreementId,
  rateSheetChanges: { created: [], deleted: [], updated: [] },
  formModel: {
    routes: {
      isSalesReady: { value: isSalesReady },
      currencyId: null,
      externalDescription: null,
      name: null,
      offPeak: null,
      peak: null,
      routeIds: null,
      shoulder: null,
      special: null,
      type: null,
      valid: null,
    },
    articles: { created: [], deleted: [], updated: [] },
    matrix: { created: [], deleted: [], updated: [] },
    conditions: {
      countryReceiver: null,
      countrySender: null,
      goods: null,
      standby: null,
      transit: null,
    },
    notes: {
      comment: null,
      externalComment: null,
      note: null,
    },
  },
});

const transformFormToInput = (
  formModel: RouteAgreementFormModel,
  persisted: RouteAgreementFormModel,
  allFormulaTypes: UseAllFormulaTypesQuery["productPrice"]["formula"]["all"]
): RouteAgreementFormInput => {
  return {
    articles: transformArticleFormToInput(
      formModel.articles,
      persisted.articles,
      allFormulaTypes
    ),
    conditions: transformConditionsFormToInput(
      formModel.conditions,
      persisted.conditions
    ),
    routes: transformRoutesFormToInput(formModel.routes, persisted.routes),
    notes: transformNotesFormToInput(formModel.notes, persisted.notes),
    matrix: transformMatrixFormToInput(formModel.matrix, persisted.matrix),
  };
};
