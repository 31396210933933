import { gql } from "@apollo/client";
import { ReportScreenFragments } from "./fragments";

export const reportScreenQuery = gql`
  ${ReportScreenFragments}
  fragment ReportScreenCustomer on Customer {
    id
    name
    breadCrumbName
    sortPortCountry {
      id
      code
    }
    statisticalGroupingId
    custIndex
    termsOfPaymentMonths
    termsOfPaymentDays
    creditLimit
    custNo
    crmLink
    countryCode
    modeOfPay
    invoiceCurrency {
      code
    }
    seller {
      id
      sellerFirstName
      sellerLastName
      sellerPhoneNumber
      sellerEmailAddress
    }
    noOfChildren
    ...CustomerSubscriberSelector
    ...CreateReportHeaderCustomer
  }
  fragment ReportScreenHeadagreement on HeadAgreement {
    name
    id
    lastPage
    internalComments
    condition
    specifications
    headAgreementNumber
    valid {
      end {
        isoString
      }
      start {
        isoString
      }
    }
    rowVersion
    routeAgreements {
      ...RouteAgreements
    }
    ...CreateReportHeader
    ...MultiLegRouteAgreements
  }

  query ReportScreen($customerId: ID!, $headAgreementId: ID!, $codes: [ID!]!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...ReportScreenCustomer
        }
      }
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...ReportScreenHeadagreement
        }
      }
      agreementTexts {
        byAgreementTextCodes(codes: $codes) {
          id
          code
          value
        }
      }
    }
  }
`;
