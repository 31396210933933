import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { gtmTransmitter } from "../../../../../../common/utils/GtmHelper";
import { RouteTargetErrorOccured } from "../../../../../../common/utils/route-target-gtm/RouteTargetGtmHelper";
import {
  RoutePairNegYearStatusByRoutePairFragment,
  RoutePairNegotiationYearStatusQuery,
  RoutePairNegotiationYearStatusQueryVariables,
} from "@/gql/graphql";

const routePairNegotiationYearStatusQuery = gql`
  fragment RoutePairNegYearStatusByRoutePair on RoutePairNegotiationYear {
    id
    routePair {
      id
      code
    }
    status
    volumeTarget
    year
  }
  query RoutePairNegotiationYearStatus($routePairCode: String!, $year: Int!) {
    productPrice {
      routePairNegotiationYear {
        byRoutePairAndYear(routePairCode: $routePairCode, year: $year) {
          ...RoutePairNegYearStatusByRoutePair
        }
      }
    }
  }
`;

export type RoutePairNavigationYear = RoutePairNegYearStatusByRoutePairFragment;

export const useRoutePairNegotiationYearStatus = (
  routePairCode: string,
  year: number
) => {
  const { data, loading, error } = useQuery<
    RoutePairNegotiationYearStatusQuery,
    RoutePairNegotiationYearStatusQueryVariables
  >(routePairNegotiationYearStatusQuery, {
    variables: { routePairCode, year },
  });

  useMemo(() => {
    if (error) {
      gtmTransmitter({
        event: RouteTargetErrorOccured.errorEvent,
      });
    }
  }, [error]);

  const routePairData =
    data?.productPrice.routePairNegotiationYear.byRoutePairAndYear;

  return { data: routePairData, loading, error };
};
