import { NegotiationStatusItem } from "./common/neg-status-chip-multi-select/NegStatusChipMultiSelect";
import { ByCustomerOrVolume } from "./common/utils/ByCustomerOrVolumeEnum";
import React, { PropsWithChildren, createContext, useState } from "react";

export interface StatisticsContextType {
  sellerCustomerOrVolumeFilter: ByCustomerOrVolume;
  setSellerCustomerOrVolumeFilter: React.Dispatch<
    React.SetStateAction<ByCustomerOrVolume>
  >;
  routePairCustomerOrVolumeFilter: ByCustomerOrVolume;
  setRoutePairCustomerOrVolumeFilter: React.Dispatch<
    React.SetStateAction<ByCustomerOrVolume>
  >;
  salesRegionCustomerOrVolumeFilter: ByCustomerOrVolume;
  setSalesRegionCustomerOrVolumeFilter: React.Dispatch<
    React.SetStateAction<ByCustomerOrVolume>
  >;
  sellerSelectedStatuses: NegotiationStatusItem[];
  setSellerSelectedStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >;
  routePairSelectedStatuses: NegotiationStatusItem[];
  setRoutePairSelectedStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >;
  salesRegionSelectedStatuses: NegotiationStatusItem[];
  setSalesRegionSelectedStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >;
}

const StatisticsContext = createContext<StatisticsContextType | null>(null);

const StatisticsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [sellerSelectedStatuses, setSellerSelectedStatuses] = useState<
    Array<NegotiationStatusItem>
  >([]);

  const [routePairSelectedStatuses, setRoutePairSelectedStatuses] = useState<
    Array<NegotiationStatusItem>
  >([]);

  const [salesRegionSelectedStatuses, setSalesRegionSelectedStatuses] =
    useState<Array<NegotiationStatusItem>>([]);
  const [sellerCustomerOrVolumeFilter, setSellerCustomerOrVolumeFilter] =
    useState<ByCustomerOrVolume>(ByCustomerOrVolume.CUSTOMER);

  const [routePairCustomerOrVolumeFilter, setRoutePairCustomerOrVolumeFilter] =
    useState<ByCustomerOrVolume>(ByCustomerOrVolume.CUSTOMER);

  const [
    salesRegionCustomerOrVolumeFilter,
    setSalesRegionCustomerOrVolumeFilter,
  ] = useState<ByCustomerOrVolume>(ByCustomerOrVolume.CUSTOMER);

  return (
    <StatisticsContext.Provider
      value={{
        routePairSelectedStatuses,
        setRoutePairSelectedStatuses,
        salesRegionSelectedStatuses,
        setSalesRegionSelectedStatuses,
        sellerSelectedStatuses,
        setSellerSelectedStatuses,
        sellerCustomerOrVolumeFilter,
        setSellerCustomerOrVolumeFilter,
        routePairCustomerOrVolumeFilter,
        salesRegionCustomerOrVolumeFilter,
        setRoutePairCustomerOrVolumeFilter,
        setSalesRegionCustomerOrVolumeFilter,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

export { StatisticsProvider, StatisticsContext };
