import * as React from "react";
import { Indent, Row, Text } from "@stenajs-webui/core";
import { Icon, stenaExclamationTriangle } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  text: string;
  width?: string;
}

export const ErrorMessage: React.FC<Props> = ({ text, width }) => {
  return (
    <Row alignItems={"flex-start"} width={width}>
      <Icon
        icon={stenaExclamationTriangle}
        color={cssColor("--lhds-color-red-500")}
      />
      <Indent />
      <Text color={cssColor("--lhds-color-red-500")}>{text}</Text>
    </Row>
  );
};
