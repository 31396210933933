import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../../../common/error/GqlErrorFragment";
import {
  ChangeRouteAgreementStatusMutation,
  ChangeRouteAgreementStatusMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation ChangeRouteAgreementStatus(
    $routeAgreementId: ID!
    $status: HeadAgreementStatusCode!
    $rowVersion: String!
  ) {
    productPrice {
      routeAgreement {
        changeRouteAgreementStatus(
          routeAgreementId: $routeAgreementId
          rowVersion: $rowVersion
          status: $status
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRouteAgreementSuccessResult {
            routeAgreement {
              id
              statusCode
              rowVersion
            }
          }
        }
      }
    }
  }
`;

export const useChangeRouteAgreementStatusMutation = () =>
  useMutation<
    ChangeRouteAgreementStatusMutation,
    ChangeRouteAgreementStatusMutationVariables
  >(query);
