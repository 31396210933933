import { gql } from "apollo-boost";
export const ChildCustomerFragment = gql`
  fragment ChildCustomers on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    preselectedHeadAgreement {
      id
    }
    statisticalGrouping {
      id
    }
    invoiceCurrency {
      id
      code
    }
    hasRebateAgreements
    salesRegion {
      id
      name
    }
    seller {
      id
      name
    }
    custStatus {
      id
      name
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        name
      }
      modifiedBy {
        name
      }
      modifiedAt {
        isoString
      }
    }
    isParent
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }

    childCustomers {
      id
      name
      custIndex
      custNo
      preselectedHeadAgreement {
        id
      }
    }
  }
`;
