import * as React from "react";
import { Tag } from "@stenajs-webui/elements";
import { unreachable } from "../../lib/unreachable";
import { startCase } from "lodash";
import { Box } from "@stenajs-webui/core";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  statusCode: HeadAgreementStatusCode;
  size?: "small" | "medium";
}

const getChipVariantByStatusCode = (statusCode: HeadAgreementStatusCode) => {
  switch (statusCode) {
    case HeadAgreementStatusCode.Active:
      return "success";
    case HeadAgreementStatusCode.Offered:
      return "success";
    case HeadAgreementStatusCode.Pending:
      return "warning";
    case HeadAgreementStatusCode.Void:
      return "passive";
    default:
      return unreachable(statusCode);
  }
};

export const HeadAgreementStatusCodeChip: React.FC<Props> = ({
  statusCode,
  size,
}) => {
  return (
    <Box style={{ margin: "auto 0" }}>
      <Tag
        variant={getChipVariantByStatusCode(statusCode)}
        label={startCase(statusCode.toUpperCase())}
        size={size}
      />
    </Box>
  );
};
