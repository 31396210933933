import { ExpectedVolumeColumns } from "./ExpectedVolumeTableConfig";

export const noLabelColumnOrder: Array<ExpectedVolumeColumns> = [
  "indentLeft",
  "customerName",
  "customerNo",
  "customerIndex",
  "customerIsParent",
];

export const accompaniedColumnOrder: Array<ExpectedVolumeColumns> = [
  "accompanied12MonthVolPeak",
  "accompanied12MonthVolShoulder",
  "accompanied12MonthVolOffPeak",
];

export const accompaniedExpectedColumnOrder: Array<ExpectedVolumeColumns> = [
  "accompaniedExpectedVolPeak",
  "accompaniedExpectedVolShoulder",
  "accompaniedExpectedVolOffPeak",
];

export const unaccompaniedColumnOrder: Array<ExpectedVolumeColumns> = [
  "unaccompanied12MonthVolPeak",
  "unaccompanied12MonthVolShoulder",
  "unaccompanied12MonthVolOffPeak",
];

export const unaccompaniedExpectedColumnOrder: Array<ExpectedVolumeColumns> = [
  "unaccompaniedExpectedVolPeak",
  "unaccompaniedExpectedVolShoulder",
  "unaccompaniedExpectedVolOffPeak",
  "indentRight",
];
