import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { routePairLabelWithDescriptionForTables } from "../../../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import { createAllRoutesStatisticsTableCellRenderer } from "../AllRoutesStatisticsTableCell";
import { createStandardTableCell } from "../AllRoutesTableStatisticsStandardCell";
import {
  AllRoutesTableColumnKeys,
  AllRoutesTableRow,
} from "./RowsAndColumnsConfig";

export type EditableAllRoutesTableFields =
  | "volumeTarget"
  | "peakAcc"
  | "peakUnAcc"
  | "shoulderAcc"
  | "shoulderAcc"
  | "shoulderUnAcc"
  | "offPeakAcc"
  | "offPeakUnAcc";

const currentYear = new Date().getUTCFullYear();

export const createAllRoutesTableConfig = (currentSelectedYear: number) => {
  const allRoutesTableConfig: StandardTableConfig<
    EditableEntityState<AllRoutesTableRow>,
    AllRoutesTableColumnKeys
  > = {
    disableInfiniteList: true,
    disableSorting: false,
    enableGridCell: true,
    stickyHeader: true,
    headerRowOffsetTop: "154px",
    keyResolver: (item) => item.editable.routePairCode,
    columns: {
      fillFirst: {
        itemValueResolver: (item) => "",
        columnLabel: "",
        width: "24px",
        sticky: true,
        disableGridCell: true,
      },
      routePairCode: {
        itemValueResolver: (item) => item.editable.routePairCode,
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.editable.routePairCode),
        columnLabel: "Route",
        sticky: true,
        left: "24px",
        width: "160px",
        disableGridCell: true,
      },
      routePair: {
        itemValueResolver: (item) => item.editable.routePair,
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          routePairLabelWithDescriptionForTables(
            item.editable.routePair?.description ?? ""
          ),
        columnLabel: "Directions",
        disableGridCell: true,
      },
      volumeTarget: createColumnConfig((item) => item.editable.volumeTarget, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        borderLeft: true,
        renderCell: createAllRoutesStatisticsTableCellRenderer("volumeTarget"),
        columnLabel: "Volume",
        minWidth: "85px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      peakAcc: createColumnConfig((item) => item.editable.peakAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("peakAcc"),
        columnLabel: "Peak - Acc",
        minWidth: "100px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      peakUnAcc: createColumnConfig((item) => item.editable.peakUnAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("peakUnAcc"),
        columnLabel: "Peak - UnAcc",
        minWidth: "120px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      shoulderAcc: createColumnConfig((item) => item.editable.shoulderAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("shoulderAcc"),
        columnLabel: "Shoulder - Acc",
        minWidth: "125px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      shoulderUnAcc: createColumnConfig((item) => item.editable.shoulderUnAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("shoulderUnAcc"),
        columnLabel: "Shoulder - UnAcc",
        minWidth: "145px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      offPeakAcc: createColumnConfig((item) => item.editable.offPeakAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("offPeakAcc"),
        columnLabel: "Off peak - Acc",
        minWidth: "125px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      offPeakUnAcc: createColumnConfig((item) => item.editable.offPeakUnAcc, {
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        renderCell: createAllRoutesStatisticsTableCellRenderer("offPeakUnAcc"),
        columnLabel: "Off peak - UnAcc",
        minWidth: "140px",
        isEditable: currentSelectedYear >= currentYear,
      }),
      fillLast: createColumnConfig((item) => "", {
        columnLabel: "",
        width: "24px",
        disableGridCell: true,
      }),
    },

    columnOrder: [
      "fillFirst",
      "routePairCode",
      "routePair",
      "volumeTarget",
      "peakAcc",
      "peakUnAcc",
      "shoulderAcc",
      "shoulderUnAcc",
      "offPeakAcc",
      "offPeakUnAcc",
      "fillLast",
    ],
  };

  return allRoutesTableConfig;
};
