import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsDetailsFragment } from "../../../common/fragments/DetailsFragment";
import {
  NegotiationStatusCode,
  TotalDetailsVolumeAndRatesQuery,
  TotalDetailsVolumeAndRatesQueryVariables,
} from "@/gql/graphql";

const query = gql`
  ${statisticsDetailsFragment}
  query TotalDetailsVolumeAndRates(
    $negotiationStatuses: [NegotiationStatusCode!]!
    $year: Int!
    $currencyCode: String!
  ) {
    statistics {
      totalDetails {
        volumeAndRates(
          negotiationStatusCodes: $negotiationStatuses
          year: $year
        ) {
          ...statisticsDetailsFragment
        }
        threeYearsAverageMeterPrices(
          currencyCode: $currencyCode
          year: $year
          negotiationStatusCodes: $negotiationStatuses
        ) {
          averageMeterPriceRequestedYear
          averageMeterPriceRequestedYearMinus1
          averageMeterPriceRequestedYearMinus2
          averageMeterPriceDiffInPercentFromPreviousYear
          averageMeterPriceDiffInPercentFromPreviousYearMinus1
          averageMeterPriceDiffInPercentFromPreviousYearMinus2
        }
      }
    }
  }
`;

export const useTotalDetailsVolumeAndRatesQuery = (
  negotiationStatusCodes: Array<NegotiationStatusCode>,
  year: number,
  currencyCode: string
) => {
  const { data, error, loading } = useQuery<
    TotalDetailsVolumeAndRatesQuery,
    TotalDetailsVolumeAndRatesQueryVariables
  >(query, {
    variables: {
      negotiationStatuses: negotiationStatusCodes,
      year,
      currencyCode,
    },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    volumeAndRates: data?.statistics.totalDetails.volumeAndRates,
    averageMeterPrices:
      data?.statistics.totalDetails.threeYearsAverageMeterPrices,
  };
};
