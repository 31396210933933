import {
  createEditableEntityActions,
  createEditableEntityReducer,
  EditableEntityState,
} from "@stenajs-webui/redux";
import { combineReducers } from "redux";
import { AppThunk, StoreState } from "../../../config/redux/RootReducer";
import { batch } from "react-redux";
import { CustomerMultiLegAgreement } from "./types";
import { transformGqlMultiLegToMultiLegAgreementEntity } from "./transformers/MultiLegGqlToEntityTransformer";

export interface CustomerMultiLegRouteState {
  multiLegAgreement: EditableEntityState<MultiLegAgreement>;
}
export interface MultiLegAgreement {
  isSalesReady: boolean;
}
export interface MultiLegLeg {
  id: string;
  routeAgreementIds: string[];
}

export const customerMultiLegRouteAgreementDetailsSelector = (
  state: StoreState
) => state.customerMultiLegRoute;

export const customerMultiLegRouteRedux = {
  reducer: combineReducers<CustomerMultiLegRouteState>({
    multiLegAgreement: createEditableEntityReducer<MultiLegAgreement>({
      isSalesReady: false,
    }),
  }),
  actions: {
    loadMultiLegAgreement:
      (multiLegAgreement: CustomerMultiLegAgreement): AppThunk =>
      async (dispatch) => {
        const multiLegAgreementEntity =
          transformGqlMultiLegToMultiLegAgreementEntity(multiLegAgreement);
        batch(() => {
          dispatch(
            customerMultiLegRouteRedux.actions.setSalesReadyPersisted(
              multiLegAgreementEntity.isSalesReady
            )
          );
        });
      },
    setSalesReadyPersisted: (isSalesReady: boolean) =>
      createEditableEntityActions<MultiLegAgreement>().setEntity({
        isSalesReady,
      }),
    setSalesReady: (isSalesReady: boolean) =>
      createEditableEntityActions<MultiLegAgreement>().setEditableEntityFields({
        isSalesReady,
      }),
  },
};
