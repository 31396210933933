import * as React from "react";
import { StatisticsHeader } from "./statistics-header/StatisticsHeader";
import { StatisticsTabRouter } from "./StatisticsTabRouter";

export const StatisticsScreen: React.FC = () => (
  <>
    <StatisticsHeader />
    <StatisticsTabRouter />
  </>
);
