import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { groupBy } from "lodash";

interface RouteAgreementsPerRoutePair {
  [key: string]: Array<RouteAgreement>;
}

export const groupRouteAgreementsByRoutePair = (
  routeAgreements: Array<RouteAgreement>
): RouteAgreementsPerRoutePair => {
  return groupBy(routeAgreements, (r) => r.routes[0].routePair.id);
};

interface RouteAgreementsPerSeaArea {
  [key: string]: Array<RouteAgreement>;
}

export const groupRouteAgreementsBySeaArea = (
  routeAgreements: Array<RouteAgreement>
): RouteAgreementsPerSeaArea => {
  return groupBy(routeAgreements, (r) => r.routes[0].routePair.seaArea.id);
};
