import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppFilterSettings } from "../../types/FppFilterSettings";
import { AverageGrossPriceFilterSection } from "./AverageGrossPriceFilterSection";
import { AverageNetPriceFilterSection } from "./AverageNetPriceFilterSection";
import { CountryFilterSection } from "./CountryFilterSection";
import { CustomerCategoryFilterSection } from "./CustomerCategoryFilterSection";
import { CustomerFilterSection } from "./CustomerFilterSection";
import { CustomerTypeFilterSection } from "./CustomerTypeFilterSection";
import { EmptyLoadedFilterSection } from "./EmptyLoadedFilterSection";
import { IncludeCustomersWithFilterSection } from "./IncludeCustomersWithFilterSection";
import { NegotiationStatusFilterSection } from "./NegotiationStatusFilterSection";
import { NegotiationYearFilterSection } from "./NegotiationYearFilterSection";
import { NetworkVolumeFilterSection } from "./NetworkVolumeFilterSection";
import { RateFilterSection } from "./RateFilterSection";
import { RegionFilterSection } from "./RegionFilterSection";
import { RouteAgreementSalingCategoryFilterSection } from "./RouteAgreementSalingCategoryFilterSection";
import { RouteFilterSection } from "./RouteFilterSection";
import { RouteVolumeFilterSection } from "./RouteVolumeFilterSection";
import { SailingCategoryFilterSection } from "./SailingCategoryFilterSection";
import { SalesRegionFilterSection } from "./SalesRegionFilterSection";
import { SellersFilterSection } from "./SellerFilterSection";
import { StatNetworkVolumeFilterSection } from "./StatNetworkVolume";
import { StatNumberAndIndexFilterSection } from "./StatNumberAndIndexFilterSection";
import { StatRouteVolumeFilterSection } from "./StatRouteVolume";
import { TargetFilterSection } from "./TargetFilterSection";
import { TargetStatusFilterSection } from "./TargetStatusFilterSection";
import { TwelveMonthVolumeFilterSection } from "./TwelveMonthVolumeFilterSection";
import { UnaccFilterSection } from "./UnaccFilterSection";
import { UnitTypeFilterSection } from "./UnitTypeFilterSection";
import { VehicleLengthFilterSection } from "./VehicleLengthFilterSection";
import { VehicleTypeFilterSection } from "./VehicleTypeFilterSection";
import { VehicleWeightFilterSection } from "./VehicleWeightFilterSection";
import { YearAndMonthFilterSection } from "./YearAndMonthFilterSection";
import { BookingNumberSearchFilterSection } from "./BookingNumberSearchFilterSection";
import { BookAndCheckInNoteFilterSection } from "./BookAndCHeckInNoteFilterSection";
import { DepartureInputSection } from "./DepartureInputSection";
import { NoShowLateHandlingTypeSection } from "./NoShowLateHandlingTypeSection";
import { NoShowLateHandlingStatusSection } from "./NoShowLateHandlingStatusSection";

export const FppSearchFilterSections = ({
  settings,
}: {
  settings: FppFilterSettings;
}) => {
  const filterWorkspace = useFilterWorkspace();
  const {
    month,
    setMonth,
    negotiationYear: customerYear,
    setNegotiationYear: handleCustomerYear,
    year,
    setYear,
  } = filterWorkspace;

  const {
    customerTypeEnabled,
    customerTypeHeader,
    includeCustomersWithEnabled,
    includeCustomersWithHeader,
    negotiationStatusEnabled,
    negotiationStatusHeader,
    negotiationYearEnabled,
    negotiationYearHeader,
    routePairEnabled,
    routePairHeader,
    salesRegionEnabled,
    salesRegionHeader,
    sellerEnabled,
    sellerHeader,
    customerEnabled,
    customerHeader,
    sailingCategoryEnabled,
    sailingCategoryHeader,
    routeAgreementSailingCategoryEnabled,
    routeAgreementSailingCategoryHeader,
    unitTypeEnabled,
    unitTypeHeader,
    averageNetPriceEnabled,
    averageNetPriceHeader,
    averageGrossPriceEnabled,
    averageGrossPriceHeader,
    targetEnabled,
    targetHeader,
    rateEnabled,
    rateHeader,
    twelveMonthVolumeEnabled,
    twelveMonthVolumeHeader,
    countryEnabled,
    countryHeader,
    networkVolumeEnabled,
    networkVolumeHeader,
    routeVolumeEnabled,
    routeVolumeHeader,
    customerCategoryEnabled,
    customerCategoryHeader,
    regionEnabled,
    regionHeader,
    statNumberAndIndexEnabled,
    statNumberAndIndexHeader,
    statNetworkVolumeEnabled,
    statNetworkVolumeHeader,
    statRouteVolumeEnabled,
    statRouteVolumeHeader,
    targetStatusEnabled,
    targetStatusHeader,
    vehicleTypeEnabled,
    vehicleTypeLabel,
    vehicleLengthEnabled,
    vehicleLengthHeader,
    vehicleWeightEnabled,
    vehicleWeightHeader,
    emptyLoadedEnabled,
    emptyLoadedHeader,
    unaccEnabled,
    unaccHeader,
    yearMonthEnabled,
    yearMonthHeader,
    bcNoteEnabled,
    bcNoteHeader,
    bookingNumberSearchEnabled,
    bookingNumberSearchHeader,
    departureInputEnabled,
    departureInputHeader,
    noShowLateHandlingStatusSearchEnabled,
    noShowLateHandlingStatusSearchHeader,
    noShowLateHandlingTypeSearchEnabled,
    noShowLateHandlingTypeSearchHeader,
  } = settings;
  return (
    <>
      <NegotiationYearFilterSection
        enabled={negotiationYearEnabled}
        label={negotiationYearHeader}
        year={customerYear}
        handleYearChange={handleCustomerYear}
      />
      <YearAndMonthFilterSection
        enabled={yearMonthEnabled}
        label={yearMonthHeader}
        year={year}
        handleYearChange={setYear}
        handleMonth={setMonth}
        month={month}
      />
      <NegotiationStatusFilterSection
        label={negotiationStatusHeader}
        enabled={negotiationStatusEnabled}
      />
      <CustomerFilterSection enabled={customerEnabled} label={customerHeader} />
      <SellersFilterSection label={sellerHeader} enabled={sellerEnabled} />
      <CustomerTypeFilterSection
        label={customerTypeHeader}
        enabled={customerTypeEnabled}
      />
      <IncludeCustomersWithFilterSection
        label={includeCustomersWithHeader}
        enabled={includeCustomersWithEnabled}
      />
      <RouteFilterSection enabled={routePairEnabled} label={routePairHeader} />
      <SalesRegionFilterSection
        label={salesRegionHeader}
        enabled={salesRegionEnabled}
      />
      <SailingCategoryFilterSection
        enabled={sailingCategoryEnabled}
        label={sailingCategoryHeader}
      />
      <RouteAgreementSalingCategoryFilterSection
        enabled={routeAgreementSailingCategoryEnabled}
        label={routeAgreementSailingCategoryHeader}
      />

      <UnitTypeFilterSection enabled={unitTypeEnabled} label={unitTypeHeader} />

      <AverageNetPriceFilterSection
        enabled={averageNetPriceEnabled}
        label={averageNetPriceHeader}
      />

      <AverageGrossPriceFilterSection
        enabled={averageGrossPriceEnabled}
        label={averageGrossPriceHeader}
      />

      <TargetFilterSection enabled={targetEnabled} label={targetHeader} />

      <RateFilterSection enabled={rateEnabled} label={rateHeader} />

      <TwelveMonthVolumeFilterSection
        enabled={twelveMonthVolumeEnabled}
        label={twelveMonthVolumeHeader}
      />

      <CountryFilterSection enabled={countryEnabled} label={countryHeader} />

      <NetworkVolumeFilterSection
        enabled={networkVolumeEnabled}
        label={networkVolumeHeader}
      />

      <RouteVolumeFilterSection
        enabled={routeVolumeEnabled}
        label={routeVolumeHeader}
      />

      <CustomerCategoryFilterSection
        enabled={customerCategoryEnabled}
        label={customerCategoryHeader}
      />

      <RegionFilterSection label={regionHeader} enabled={regionEnabled} />

      <StatNumberAndIndexFilterSection
        enabled={statNumberAndIndexEnabled}
        label={statNumberAndIndexHeader}
      />

      <StatNetworkVolumeFilterSection
        enabled={statNetworkVolumeEnabled}
        label={statNetworkVolumeHeader}
      />

      <StatRouteVolumeFilterSection
        enabled={statRouteVolumeEnabled}
        label={statRouteVolumeHeader}
      />

      <TargetStatusFilterSection
        label={targetStatusHeader}
        enabled={targetStatusEnabled}
      />

      <VehicleTypeFilterSection
        enabled={vehicleTypeEnabled}
        label={vehicleTypeLabel}
      />

      <VehicleLengthFilterSection
        enabled={vehicleLengthEnabled}
        label={vehicleLengthHeader}
      />

      <VehicleWeightFilterSection
        enabled={vehicleWeightEnabled}
        label={vehicleWeightHeader}
        maxNumbers={6}
      />

      <EmptyLoadedFilterSection
        label={emptyLoadedHeader}
        enabled={emptyLoadedEnabled}
      />

      <UnaccFilterSection label={unaccHeader} enabled={unaccEnabled} />

      <NoShowLateHandlingStatusSection
        enabled={noShowLateHandlingStatusSearchEnabled}
        label={noShowLateHandlingStatusSearchHeader}
      />

      <NoShowLateHandlingTypeSection
        enabled={noShowLateHandlingTypeSearchEnabled}
        label={noShowLateHandlingTypeSearchHeader}
      />

      <BookAndCheckInNoteFilterSection
        enabled={bcNoteEnabled}
        label={bcNoteHeader}
      />
      <DepartureInputSection
        enabled={departureInputEnabled}
        label={departureInputHeader}
      />
      <BookingNumberSearchFilterSection
        enabled={bookingNumberSearchEnabled}
        label={bookingNumberSearchHeader}
      />
    </>
  );
};
