import {
  CalculateThresholdInput,
  CalculateThresholdMutation,
  CalculateThresholdMutationVariables,
} from "@/gql/graphql";
import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const mutation = gql`
  mutation CalculateThreshold($input: CalculateThresholdInput!) {
    productPrice {
      noShowLateHandlingBookings {
        calculateThreshold(input: $input) {
          ... on CalculateThresholdSuccessResult {
            success
          }
          ... on CalculateThresholdErrorResult {
            errors {
              message
              path
            }
          }
        }
      }
    }
  }
`;

export const useCalculateThresholdMutation = () => {
  const [mutate, { loading, error, data }] = useMutation<
    CalculateThresholdMutation,
    CalculateThresholdMutationVariables
  >(mutation);

  const calculateThreshold = useCallback(
    (input: CalculateThresholdInput) => {
      return mutate({
        variables: {
          input,
        },
        refetchQueries: ["NoShowLateHandlingBookingList"],
        awaitRefetchQueries: true,
      });
    },
    [mutate]
  );
  return { loading, error, calculateThreshold, data };
};
