import { Column, Spacing, useBoolean } from "@stenajs-webui/core";
import { FlatButton, stenaPen, stenaPercentage } from "@stenajs-webui/elements";
import { Drawer } from "@stenajs-webui/modal";
import * as React from "react";
import { DrawerHeader } from "../../../../../common/components/drawer/DrawerHeader";
import { RateSheetTableRowState } from "../../reducer";
import { RateEditPanelPrice } from "./RateEditPanelPrice";
import { RateEditPanelVehicle } from "./RateEditPanelVehicle";
import { useDispatch } from "react-redux";
import { rateSheetTableActions } from "../../actions";
import { rateSheetStandardTableWrapperActions } from "../../redux";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  rateSheetTableRowState: RateSheetTableRowState;
  routeAgreementId: string;
  routeAgreementPriceId: string;
  currencyLabel?: string;
  editableRouteAgreement: boolean;
  disableStatBoxes?: boolean;
}

export const EditRateRowButton: React.FC<Props> = ({
  rateSheetTableRowState,
  currencyLabel,
  routeAgreementId,
  routeAgreementPriceId,
  editableRouteAgreement,
  disableStatBoxes,
}) => {
  const [isOpenVehicle, openVehicle, closeVehicle] = useBoolean(false);
  const [isOpenPrice, openPrice, closePrice] = useBoolean(false);

  const dispatch = useDispatch();

  function onOpenPrice() {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.addHighlightRow(routeAgreementPriceId)
      )
    );

    openPrice();
  }

  function onRequestClosePrice() {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.removeHighlightRow(routeAgreementPriceId)
      )
    );
    closePrice();
  }

  function onOpenVehicle() {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.addHighlightRow(routeAgreementPriceId)
      )
    );

    openVehicle();
  }

  function onRequestCloseVehicle() {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.removeHighlightRow(routeAgreementPriceId)
      )
    );
    closeVehicle();
  }

  return (
    <>
      <FlatButton
        leftIcon={stenaPen}
        onClick={onOpenVehicle}
        disabled={!editableRouteAgreement}
      />
      <FlatButton
        leftIcon={stenaPercentage}
        onClick={onOpenPrice}
        disabled={!editableRouteAgreement}
      />
      <Drawer
        isOpen={isOpenVehicle}
        width={"260px"}
        onRequestClose={onRequestCloseVehicle}
        slideFrom={"right"}
        background={cssColor("--lhds-color-ui-50")}
        zIndex={ZIndex.highest}
        portalTarget={document.body}
      >
        <Column overflowY={"auto"} height={"100%"}>
          <DrawerHeader
            label={"Edit vehicle"}
            onClickClose={onRequestCloseVehicle}
          />
          <RateEditPanelVehicle
            onRequestClose={onRequestCloseVehicle}
            rateSheetTableRowState={rateSheetTableRowState}
            currencyLabel={currencyLabel}
            routeAgreementId={routeAgreementId}
            routeAgreementPriceId={routeAgreementPriceId}
            disableStatBoxes={disableStatBoxes}
          />
          <Spacing num={4} />
        </Column>
      </Drawer>
      <Drawer
        isOpen={isOpenPrice}
        width={"260px"}
        onRequestClose={onRequestClosePrice}
        slideFrom={"right"}
        background={cssColor("--lhds-color-ui-50")}
        zIndex={ZIndex.highest}
        portalTarget={document.body}
      >
        {isOpenPrice && (
          <Column overflowY={"auto"} height={"100%"}>
            <DrawerHeader label={"Raise price"} onClickClose={closePrice} />
            <RateEditPanelPrice
              onRequestClose={onRequestClosePrice}
              rateSheetTableRowState={rateSheetTableRowState}
              currencyLabel={currencyLabel}
              routeAgreementId={routeAgreementId}
              routeAgreementPriceId={routeAgreementPriceId}
              disableStatBoxes={disableStatBoxes}
            />
            <Spacing num={4} />
          </Column>
        )}
      </Drawer>
    </>
  );
};
