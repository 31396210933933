import { ZIndex } from "@/common/utils/ZIndexEnum";
import { css, Global } from "@emotion/react";
import { Box } from "@stenajs-webui/core";
import { Card, CardHeader } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { OPEN_AGREEMENT } from "../../../common/claims/constants";
import { hasClaim } from "../../../common/claims/utils";
import { UnAuthorizedView } from "../../../common/components/unauthorized-view/UnAuthorizedView";
import { profileSelectors } from "../../../common/redux/profile/selectors";
import { StoreState } from "../../../config/redux/RootReducer";
import { NoShowLateHandlingRoutePricesHeader } from "./NoShowLateHandlingRoutePricesHeader";
import { NoShowLateHandlingRoutePricesTable } from "./NoShowLateHandlingRoutePricesTable";

interface Props {}

const selector = (state: StoreState) => {
  return state.noShowLateHandlingPricesCurrentlySelected.selected
    .flat()
    .map(({ currentlySelectedId }) => {
      return state.noShowLateHandlingPrices.allNoShowHandlingPriceData.find(
        (row) => row.id === currentlySelectedId
      )!;
    });
};

const bannerStateSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.progress.bannerState;

const addValidFromModalSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.addValidFromModal.routePairCode;

export const NoShowLateHandlingRoutePricesScreen: React.FC<Props> = () => {
  const { claims } = useSelector(profileSelectors.getGlobal);
  const routes = useSelector(selector);
  const bannerState = useSelector(bannerStateSelector);
  const routePairCode = useSelector(addValidFromModalSelector);
  const item = routes.find((i) => i.persisted.routePairCode === routePairCode)!;

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Route prices - NSLH - FPP" />
      <NoShowLateHandlingRoutePricesHeader />
      {hasClaim(claims, [OPEN_AGREEMENT]) ? (
        <Card>
          <Box
            //FIXME: when CardHeader has the necessary props for sticky.
            background={cssColor("--lhds-color-ui-50")}
            position={"sticky"}
            top={bannerState ? 210 : 97}
            left={0}
            zIndex={ZIndex.lowest}
            width={"100vw"}
            borderRadius={"var(--swui-border-radius)"}
          >
            <CardHeader text={`${routes.length} Routes`} />
          </Box>

          <NoShowLateHandlingRoutePricesTable
            routes={routes}
            item={item}
            routePairCode={routePairCode}
          />
        </Card>
      ) : (
        <UnAuthorizedView />
      )}
    </>
  );
};
