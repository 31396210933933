import { useArraySet } from "@stenajs-webui/core";
import { stenaSailingCargo } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { EmptyLoaded } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { firstLetterCapital } from "@/common/string/GeneralStringFormatting";
import { CargoStatusCode } from "@/gql/graphql";

const emptyLoadedItems: Array<EmptyLoaded> = [
  {
    name: firstLetterCapital(CargoStatusCode.Both),
    code: CargoStatusCode.Both,
    id: CargoStatusCode.Both,
  },
  {
    name: firstLetterCapital(CargoStatusCode.Empty),
    code: CargoStatusCode.Empty,
    id: CargoStatusCode.Empty,
  },
  {
    name: firstLetterCapital(CargoStatusCode.Loaded),
    code: CargoStatusCode.Loaded,
    id: CargoStatusCode.Loaded,
  },
];

interface Props {
  enabled?: boolean;
  label?: string;
}

export const EmptyLoadedFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <EmptyLoadedFilterSectionInternal {...props} /> : <></>;

const EmptyLoadedFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { emptyLoaded, setEmptyLoaded } = useFilterWorkspace();

  const { toggle } = useArraySet(
    emptyLoaded,
    setEmptyLoaded,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = emptyLoadedItems.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"emptyLoaded"}
      label={label}
      enabled={enabled}
      numSelected={emptyLoaded.length}
      leftIcon={stenaSailingCargo}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {emptyLoadedItems.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={!!emptyLoaded.find((elb) => elb.id === item.id)}
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
