import { combineReducers } from "redux";
import {
  createFppSearchFilterReducer,
  SearchFilterState,
} from "@/features/search-filter/redux/reducer";

export interface StatisticsByRoutePairState {
  filter: SearchFilterState;
}

export const statisticsByRoutePairReducer =
  combineReducers<StatisticsByRoutePairState>({
    filter: createFppSearchFilterReducer("statistics.statisticsByRoutePair"),
  });
