import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateHeadAgreementStatusConfirmationActions,
  activateHeadAgreementStatusConfirmationSelectors,
} from "../redux";
import { Window } from "@stenajs-webui/modal";
import { Column, Indent, Row, Space, Spacing, Text } from "@stenajs-webui/core";
import {
  FlatButton,
  InputSpinner,
  PrimaryButton,
  ResultListBanner,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { CrudStatusIndicator } from "@stenajs-webui/grid";
import { getApolloErrorMessage } from "../../../../../common/string/GraphQlErrorParser";
import { useSetNegotiationStatusForChildCustomers } from "../../../customers/customer-negotiation-status/hooks/UseSetNegotiationStatusForChildCustomersMutation";
import { NEGOTIATION_STATUS_DONE } from "./ActivateHeadAgreementContainer";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementPanelCustomersFragment } from "@/gql/graphql";

const gray = cssColor("--lhds-color-ui-500");
interface Props {
  customer: HeadAgreementPanelCustomersFragment;
  negotiationYear: number;
}
export const ActivateHeadAgreementStatusConfirmationModal: React.FC<Props> = ({
  customer,
  negotiationYear,
}) => {
  const dispatch = useDispatch();
  const { modalOpen } = useSelector(
    activateHeadAgreementStatusConfirmationSelectors.getStatusActivationState
  );

  const { error, loading, setNegotiationStatusForChildren } =
    useSetNegotiationStatusForChildCustomers();
  const {
    bannerState,
    setBannerState,
    clearBannerResult,
    setBannerResultWithErrors,
  } = useResultListBannerState();

  const closeModal = useCallback(() => {
    dispatch(
      activateHeadAgreementStatusConfirmationActions.setStatusActivationState({
        modalOpen: false,
      })
    );
    clearBannerResult();
  }, [dispatch, clearBannerResult]);

  const setConditionalChildrenNegotiationStatus = useCallback(async () => {
    try {
      await setNegotiationStatusForChildren(
        customer.id,
        negotiationYear,
        NEGOTIATION_STATUS_DONE
      );
      closeModal();
    } catch (e) {
      setBannerState({
        headerText: "Unable to change status",
      });
      setBannerResultWithErrors([e]);
    }
  }, [
    customer.id,
    negotiationYear,
    setNegotiationStatusForChildren,
    closeModal,
    setBannerResultWithErrors,
    setBannerState,
  ]);

  const isParent = customer?.childCustomers?.length > 0;

  return (
    <Window
      headerText={`Successfully activated agreement`}
      isOpen={modalOpen}
      onRequestClose={closeModal}
      width={"580px"}
    >
      <Indent num={2}>
        <Spacing>
          <Text>
            Customer negotiation status for {negotiationYear} has been set to
            Done
          </Text>
          {isParent ? (
            <Spacing>
              <Space />
              <Text variant={"bold"}>Parent customer info</Text>
              <Space />
              <Row>
                <Column minWidth={"64px"}>
                  <Text color={gray}>Name</Text>
                  <Text color={gray}>Number</Text>
                  <Text color={gray}>Index</Text>
                  <Text color={gray}>Children</Text>
                </Column>
                <Column>
                  <Text>{customer.name}</Text>
                  <Text>{customer.custNo}</Text>
                  <Text>{customer.custIndex}</Text>
                  <Text>{customer.childCustomers.length}</Text>
                </Column>
              </Row>
              <Space num={2} />
              <Text>
                Change negotiation status for all child accounts to done?
              </Text>
            </Spacing>
          ) : (
            <Space num={2} />
          )}
          {bannerState && (
            <ResultListBanner variant={"error"} bannerState={bannerState} />
          )}
          {isParent ? (
            <>
              <Space num={4} />
              <Row justifyContent={"flex-end"} alignItems={"center"}>
                {loading ? (
                  <InputSpinner />
                ) : error ? (
                  <CrudStatusIndicator
                    crudStatus={{
                      hasError: true,
                      errorMessage: getApolloErrorMessage(error),
                    }}
                  />
                ) : null}
                <Indent />
                <FlatButton label={"No"} onClick={closeModal} />
                <Indent />
                <PrimaryButton
                  label={"Yes change status"}
                  onClick={async () => {
                    try {
                      await setConditionalChildrenNegotiationStatus();
                    } catch (e) {
                      setBannerState({
                        headerText: "Unable to change status",
                        text: e.message,
                      });
                    }
                  }}
                />
              </Row>
            </>
          ) : (
            <Row justifyContent={"flex-end"} alignItems={"center"}>
              <PrimaryButton label={"OK"} onClick={closeModal} />
            </Row>
          )}
        </Spacing>
      </Indent>
    </Window>
  );
};
