import { PriceCalculatorFormData } from "../../PriceCalculatorFormFetcher";

export const formDataStateTransformer = (formData: PriceCalculatorFormData) => {
  return {
    ...formData,
    conditionsGoods: formData.conditionsGoods
      ? [...formData.conditionsGoods]
      : [],
    conditionsAncillary: formData.conditionsAncillary
      ? [...formData.conditionsAncillary]
      : [],
  };
};
