import { TableContext } from "@stenajs-webui/grid";
import { batch } from "react-redux";
import { AppThunk } from "../../../../../config/redux/RootReducer";
import { BulkUpdateRatesFormModel } from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { BulkEditRatesTableColumn } from "../config/BulkEditRatesTableConfig";
import { raisePriceForRouteAgreementPrice } from "../hooks/UseBulkUpdateRatesLogic";
import { bulkEditRatesActions } from "../redux/reducer";
import { fetchBulkEditRatesActualChangeForAllPriceRows } from "./FetchActualChangeForAllPriceRows";

export const raisePricesUsingSelectionThunk =
  (
    formModel: BulkUpdateRatesFormModel,
    tableContext: TableContext<BulkEditRatesTableColumn>
  ): AppThunk =>
  async (dispatch, getState) => {
    const tableState = getState().bulkEditRatesState;
    const selectedPricesIds =
      getState().bulkEditRatesTableState.standardTable.selectedIds.selectedIds;

    batch(() => {
      selectedPricesIds.forEach((selectedPrice) => {
        const standardTableStateForRa =
          tableState.edited[selectedPrice]?.routeAgreement;
        if (!standardTableStateForRa) {
          return;
        }

        selectedPricesIds.forEach((routeAgreementPriceId) => {
          raisePriceForRouteAgreementPrice(
            dispatch,
            routeAgreementPriceId,
            tableState,
            formModel
          );
        });
      });
    });
    dispatch(fetchBulkEditRatesActualChangeForAllPriceRows());
    dispatch(bulkEditRatesActions.setBulkUpdateRatesLoading(false));
    tableContext.dispatch(tableContext.actions.clearSelection());
  };
