import { ExpectedVolumesByRoutePair } from "../redux/ExpectedVolumeRedux";
import { ExpectedVolumeTableRow } from "../types";
import { EditableEntityState } from "@stenajs-webui/redux";
import { isEqual } from "lodash";

export interface ExpectedVolumesChanges {
  routePairId: string;
  modifiedVolumes: Array<ModifiedVolumeForChange>;
}

export interface ModifiedVolumeForChange {
  customerNo: string;
  accompaniedExpectedVolOffPeak: number | null;
  accompaniedExpectedVolPeak: number | null;
  accompaniedExpectedVolShoulder: number | null;
  unaccompaniedExpectedVolOffPeak: number | null;
  unaccompaniedExpectedVolPeak: number | null;
  unaccompaniedExpectedVolShoulder: number | null;
}

export const getExpectedVolumeChanges = (
  expectedVolumesByRoutePair: ExpectedVolumesByRoutePair
): Array<ExpectedVolumesChanges> => {
  const routePairIds = Object.keys(expectedVolumesByRoutePair);
  return routePairIds
    .map((routePairId): ExpectedVolumesChanges => {
      const { volumes } = expectedVolumesByRoutePair[routePairId];
      return {
        routePairId,
        modifiedVolumes: volumes
          .filter(volumeIsModified)
          .map((a): ModifiedVolumeForChange => {
            return {
              customerNo: a.editable.customerNo,
              accompaniedExpectedVolOffPeak: valueForUpdateChecker(
                a.editable.accompaniedExpectedVolOffPeak,
                a.persisted.accompaniedExpectedVolOffPeak
              ),
              accompaniedExpectedVolPeak: valueForUpdateChecker(
                a.editable.accompaniedExpectedVolPeak,
                a.persisted.accompaniedExpectedVolPeak
              ),
              accompaniedExpectedVolShoulder: valueForUpdateChecker(
                a.editable.accompaniedExpectedVolShoulder,
                a.persisted.accompaniedExpectedVolShoulder
              ),
              unaccompaniedExpectedVolOffPeak: valueForUpdateChecker(
                a.editable.unaccompaniedExpectedVolOffPeak,
                a.persisted.unaccompaniedExpectedVolOffPeak
              ),
              unaccompaniedExpectedVolPeak: valueForUpdateChecker(
                a.editable.unaccompaniedExpectedVolPeak,
                a.persisted.unaccompaniedExpectedVolPeak
              ),
              unaccompaniedExpectedVolShoulder: valueForUpdateChecker(
                a.editable.unaccompaniedExpectedVolShoulder,
                a.persisted.unaccompaniedExpectedVolShoulder
              ),
            };
          }),
      };
    })
    .filter((p) => p.modifiedVolumes.length > 0);
};

const volumeIsModified = (
  a: EditableEntityState<ExpectedVolumeTableRow>
): boolean => {
  if (a.persisted === a.editable) {
    return false;
  }
  return !isEqual(a.persisted, a.editable);
};

const valueForUpdateChecker = (
  a: number | undefined,
  b: number | undefined
): number | null => {
  return a !== b && (a || a === 0) && !isNaN(a) ? a : null;
};
