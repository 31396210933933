import { SailingTypeCode } from "@/gql/graphql";

export const formatSailingTypeCodes = (
  sailingTypeCodes: SailingTypeCode[]
): string => {
  const sailingTypeCodesString = sailingTypeCodes.join("/");
  const pExists = sailingTypeCodesString.indexOf("P");
  if (pExists) {
    let textShort = sailingTypeCodesString.substring(0, pExists);
    let textShortTwo = sailingTypeCodesString.substring(
      pExists,
      sailingTypeCodesString.length
    );
    if (textShortTwo.length > 0 && textShort.length > 0) {
      if (textShort.charAt(textShort.length - 1) === "/") {
        return (
          textShortTwo + "/" + textShort.substring(0, textShort.length - 1)
        );
      } else {
        return textShortTwo + "/" + textShort;
      }
    }
  }
  return sailingTypeCodesString;
};
