import { HeadAgreementStatusCode } from "@/gql/graphql";

export const multilegAgreementCanBeEdited = ({
  statusCode,
}: {
  statusCode: HeadAgreementStatusCode;
}): boolean => multilegAgreementCanBeEditedByStatusCode(statusCode);

export const multilegAgreementCanBeEditedByStatusCode = (
  statusCode: HeadAgreementStatusCode
): boolean => {
  if (statusCode === HeadAgreementStatusCode.Active) {
    return false;
  }
  if (statusCode === HeadAgreementStatusCode.Void) {
    return false;
  }
  return true;
};
