import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Column, Row, Space, Text } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { MatrixTypeSearchList } from "../../../../../../../common/components/data-driven-inputs/search-lists/MatrixTypeSearchList";
import { PageSubHeader } from "../../../../../../../common/components/page-header/PageSubHeader";
import { uuid } from "../../../../../../../common/uuid/UuidFactory";
import { routesFormRedux } from "../../routes/RoutesFormRedux";
import { createEmptyMatrixItem } from "../MatrixFormModel";
import { matrixFormRedux } from "../MatrixFormRedux";
import { MatrixListItemFetcher } from "./MatrixListItemFetcher";
import { HEADER_HEIGHT, MatrixScheduleView } from "./MatrixScheduleView";
import { RouteAgreementDetailsStateFragment } from "@/gql/graphql";

interface Props {}

export const MatrixPanel: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const [selectedMatrix, setSelectedMatrix] = useState<
    | RouteAgreementDetailsStateFragment["matrices"]["0"]["matrix"]
    | undefined
    | null
  >();

  const { matrices } = useSelector(matrixFormRedux.selectors.getEntity);
  const { valid } = useSelector(routesFormRedux.selectors.getEntity);

  const canAdd = Boolean(selectedMatrix?.id);

  const onAdd = useCallback(() => {
    if (selectedMatrix?.id) {
      const matrixItem = createEmptyMatrixItem(
        uuid(),
        selectedMatrix.id,
        valid
      );
      dispatch(
        matrixFormRedux.actions.setEntity({
          matrices: [matrixItem, ...matrices],
        })
      );
    }
  }, [dispatch, selectedMatrix, matrices, valid]);

  const matricesNotDeleted = useMemo(
    () => matrices.filter((m) => !m.deleted),
    [matrices]
  );

  return (
    <>
      <Helmet title="Matrix - Route agreement - FPP" />
      <Card>
        <CardHeader text={"Add Matrix"} />
        <CardBody>
          <Row>
            <Box width={"400px"}>
              <MatrixTypeSearchList
                value={selectedMatrix ?? undefined}
                onValueChange={setSelectedMatrix}
              />
            </Box>
            <Space />
            <Column overflowX={"auto"} flex={1}>
              <Row justifyContent={"flex-end"}>
                <PrimaryButton
                  label={"Add"}
                  onClick={onAdd}
                  disabled={!canAdd}
                />
              </Row>
              <Space num={SpaceValues.THIRTYTWO} />
              {selectedMatrix ? (
                <Box overflowX={"auto"}>
                  <MatrixScheduleView timeslots={selectedMatrix.timeslots} />
                  <Space num={SpaceValues.THIRTYTWO} />
                  <Box height={HEADER_HEIGHT}>
                    <Text size="small" variant={"bold"}>
                      Keywords
                    </Text>
                  </Box>
                  {selectedMatrix.keywords.map((keyword) => (
                    <Text size="small" key={keyword}>
                      {keyword}
                    </Text>
                  ))}
                  <Space num={SpaceValues.SIXTEEN} />
                </Box>
              ) : (
                <Row justifyContent={"center"}>
                  <Text color={cssColor("--lhds-color-ui-500")}>
                    No matrix selected.
                  </Text>
                </Row>
              )}
            </Column>
          </Row>
        </CardBody>
      </Card>
      <Box>
        <PageSubHeader title={"Added matrices"} />
        {matricesNotDeleted.length === 0 && (
          <Row
            justifyContent={"center"}
            alignItems={"center"}
            spacing={SpacingValues.SIXTEEN}
            indent={IndentValues.SIXTEEN}
            shadow={"box"}
            background={cssColor("--lhds-color-ui-50")}
          >
            <Text color={cssColor("--lhds-color-ui-500")}>
              No matrices added.
            </Text>
          </Row>
        )}
        {matricesNotDeleted.length > 0 &&
          matricesNotDeleted.map((matrixItem, index) => (
            <Fragment key={matrixItem.id}>
              <MatrixListItemFetcher matrixItem={matrixItem} />
              <Space
                num={matricesNotDeleted.length !== index + 1 ? 2 : undefined}
              />
            </Fragment>
          ))}
      </Box>
    </>
  );
};
