import { ErrorWithPath } from "../BannerErrorWithLinks";
import { FreightPricingPortalError } from "../models/FreightPricingPortalError";

export const transformFreightPricingPortalErrorToErrorWithPaths = (
  error: FreightPricingPortalError
): ErrorWithPath[] | null => {
  if (!error) {
    return null;
  }
  return (
    error.errors?.map<ErrorWithPath>((errorDetail) => ({
      detailText: errorDetail.message,
      path: errorDetail.path ?? undefined,
    })) ?? null
  );
};
