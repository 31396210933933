import { toStringElseEmptyString } from "@/common/numbers";
import { RouteAgreementForForm } from "../../../types";
import {
  transformArticlePriceToFormModel,
  transformArticlePriceValidityToFormModel,
} from "./ArticlePriceFormModel";
import { uuid } from "../../../../../../../common/uuid/UuidFactory";
import { fromPairs } from "lodash";
import { SortArticleBy } from "../components/ArticleSortBySelect";
import { RouteAgreementArticlesFragment } from "@/gql/graphql";

export interface ArticlesFormModel {
  articles: Record<string, ArticleItemFormModel>;
  articleIds: Array<string>;
  expandedArticleState: Record<string, boolean>;
}

export const emptyArticlesFormModel: ArticlesFormModel = {
  articles: {},
  articleIds: [],
  expandedArticleState: {},
};

export interface ArticleItemFormModel {
  id: string;
  persisted: boolean;
  deleted: boolean;
  articleTypeId: string;
  maxPrice: string;
  maxNumberOf: string;
  includedInPrice: boolean;
  formulaTypeId?: string;
  formulaData: ArticlesFormulaDataFormModel;
  prices: Array<ArticlePriceFormModel>;
  validities: Array<ArticlePriceValidityFormModel>;
}

export interface ArticlePriceFormModel {
  id: string;
  persisted: boolean;
  deleted: boolean;
  vehicleTypeId?: string | null;
  price: string;
  percent: string;
  weightFrom: string;
  weightTo: string;
  validFrom?: string;
  lengthFrom: string;
  lengthTo: string;
}

export interface ArticlePriceValidityFormModel {
  id: string;
  agrRouteArticlePriceId: string;
  percentage: string | null;
  price: string;
  validFrom: string | null;
  validFromDate: Date | null;
}

export interface ArticlesFormulaDataFormModel {
  drivers?: string;
  interval1PercentValue?: string;
  interval2PercentValue?: string;
  interval3PercentValue?: string;
  interval4PercentValue?: string;
  intervalAPercentValue?: string;
  intervalBPercentValue?: string;
  intervalCPercentValue?: string;
  intervalDPercentValue?: string;
  intervalLength?: string;
  maxPercent?: string;
  percent?: string;
  weight?: string;
  width?: string;
}

export const createEmptyArticleItemFormModel = (
  articleTypeId: string,
  id: string = uuid(),
  includedInPrice: boolean = false
): ArticleItemFormModel => ({
  id,
  articleTypeId,
  persisted: false,
  deleted: false,
  includedInPrice,
  maxPrice: "",
  maxNumberOf: "",
  formulaTypeId: undefined,
  formulaData: {},
  prices: [],
  validities: [],
});

export const transformRouteAgreementToArticleForm = (
  routeAgreement: RouteAgreementForForm
): ArticlesFormModel => {
  const articles = routeAgreement.articles.map(transformArticleToForm);
  return {
    articles: fromPairs(articles.map((a) => [a.id, a])),
    articleIds: sortArticlesBy(SortArticleBy.CodeDesc, articles).map(
      (a) => a.id
    ),
    expandedArticleState: fromPairs(articles.map((a) => [a.id, false])),
  };
};

const transformArticleToForm = (
  article: RouteAgreementArticlesFragment
): ArticleItemFormModel => {
  return {
    id: article.id,
    maxPrice: toStringElseEmptyString(article.maxPrice),
    maxNumberOf: toStringElseEmptyString(article.maxNumberOf),
    includedInPrice: article.includedInPrice,
    articleTypeId: article.articleType.id,
    prices: article.prices.map(transformArticlePriceToFormModel),
    validities: article.validities.map(
      transformArticlePriceValidityToFormModel
    ),
    persisted: true,
    deleted: false,
    formulaTypeId: article.formulaType?.id,
    formulaData: transformFormulaDataToForm(article.formulaData),
  };
};

const transformFormulaDataToForm = (
  formulaData: RouteAgreementArticlesFragment["formulaData"]
): ArticlesFormulaDataFormModel =>
  formulaData
    ? {
        drivers: toStringElseUndefined(formulaData.drivers),
        interval1PercentValue: toStringElseUndefined(
          formulaData.interval1PercentValue
        ),
        interval2PercentValue: toStringElseUndefined(
          formulaData.interval2PercentValue
        ),
        interval3PercentValue: toStringElseUndefined(
          formulaData.interval3PercentValue
        ),
        interval4PercentValue: toStringElseUndefined(
          formulaData.interval4PercentValue
        ),
        intervalAPercentValue: toStringElseUndefined(
          formulaData.intervalAPercentValue
        ),
        intervalBPercentValue: toStringElseUndefined(
          formulaData.intervalBPercentValue
        ),
        intervalCPercentValue: toStringElseUndefined(
          formulaData.intervalCPercentValue
        ),
        intervalDPercentValue: toStringElseUndefined(
          formulaData.intervalDPercentValue
        ),
        intervalLength: toStringElseUndefined(formulaData.intervalLength),
        maxPercent: toStringElseUndefined(formulaData.maxPercent),
        percent: toStringElseUndefined(formulaData.percent),
        weight: toStringElseUndefined(formulaData.weight),
        width: toStringElseUndefined(formulaData.width),
      }
    : {};

const toStringElseUndefined = (
  num: number | null | undefined
): string | undefined => {
  if (num == null) {
    return undefined;
  }
  return String(num);
};

export const sortArticlesBy = (
  sortBy: SortArticleBy,
  articles: Array<ArticleItemFormModel>
) => {
  return articles
    .filter((a) => !a.deleted)
    .sort((a, b) => {
      if (sortBy === SortArticleBy.CodeAsc) {
        return a.articleTypeId.localeCompare(b.articleTypeId);
      }
      if (sortBy === SortArticleBy.CodeDesc) {
        return b.articleTypeId.localeCompare(a.articleTypeId);
      }

      return (
        Number(b.includedInPrice) - Number(a.includedInPrice) ||
        a.articleTypeId.localeCompare(b.articleTypeId)
      );
    });
};
