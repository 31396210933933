import { FetchPriceCalcFragment } from "@/gql/graphql";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";

export const transformSurchargeCosts = (
  query: FetchPriceCalcFragment["surchargeRecords"]["0"],
  priceCalc: FetchPriceCalcFragment,
  index: number
): PriceDetailTableRow => {
  return {
    id: index.toString(),
    name: query.articleType.name,
    code: query.articleType.code,
    vatRate: query.surchargeVatRate ?? 0,
    vatAmount: query.surchargeVatAmount ?? 0,
    priceExclVat: query.surchargePrice,
    currencyCode: priceCalc.baseCurrencyCode,
    priceInclVat: query.surchargePrice + (query.surchargeVatAmount ?? 0),
  };
};

export const transformAncillaryCosts = (
  query: FetchPriceCalcFragment["ancillaryRecords"]["0"],
  priceCalc: FetchPriceCalcFragment,
  index: number
): PriceDetailTableRow => {
  return {
    id: index.toString(),
    name: query.ancillaryCode,
    code: query.ancillaryCode,
    vatRate: query.ancillaryVatRate ?? 0,
    vatAmount: query.ancillaryVatAmount ?? 0,
    priceExclVat: query.ancillaryPrice,
    currencyCode: priceCalc.baseCurrencyCode,
    priceInclVat: query.ancillaryPrice + (query.ancillaryVatAmount ?? 0),
  };
};
