import { InfoPair } from "@/common/components/info-pair/InfoPair";
import {
  formatDateTime,
  formatHoursMinutesWithColon,
  formatDate,
} from "@/common/formatters/DateFormatter";
import { Box } from "@stenajs-webui/core";
import * as React from "react";
import { useMemo } from "react";
import { firstLetterCapital } from "../../../common/string/GeneralStringFormatting";
import { NoShowLateHandlingHandleBookingsRow } from "./config/RowsAndColumnsConfig";
import { HandlingCategories } from "./NoShowLateHandlingHandleBookingsScreen";

interface Props {
  customerBooking?: NoShowLateHandlingHandleBookingsRow;
  handlingCategories?: HandlingCategories;
}

export const NoShowLateHandlingHandleBookingsDetailView: React.FC<Props> = ({
  customerBooking,
  handlingCategories,
}) => {
  const handlingTypeAndStatus = useMemo(() => {
    const handlingType =
      handlingCategories?.handlingTypes?.find(
        (type) => type.code === customerBooking?.codeNoShowLateType.code
      )?.name ?? "";

    const handlingStatus =
      handlingCategories?.handlingStatuses?.find(
        (status) => status.code === customerBooking?.handlingStatus
      )?.name ?? "";

    return {
      type: handlingType,
      status: handlingStatus,
    };
  }, [
    customerBooking?.handlingStatus,
    customerBooking?.codeNoShowLateType,
    handlingCategories?.handlingStatuses,
    handlingCategories?.handlingTypes,
  ]);

  const lateHandlingDate =
    formatDateTime(customerBooking?.lateHandlingDate ?? "").substr(0, 10) +
    "," +
    formatDateTime(customerBooking?.lateHandlingDate ?? "").substr(10);

  return (
    <Box indent={2} spacing>
      <InfoPair
        leftContent={"B&C note"}
        leftTextVariant={"bold"}
        leftWidth={"30%"}
        rightContent={customerBooking?.note}
        rightWidth={"70%"}
        rowContainerWidth={"100%"}
      />
      <InfoPair
        leftContent={"Number"}
        leftTextVariant={"bold"}
        leftWidth={"30%"}
        rightContent={customerBooking?.bookingNo}
        rightWidth={"70%"}
        rowContainerWidth={"100%"}
      />
      <InfoPair
        leftContent={"Version"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.bookingVersion}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Type"}
        leftTextVariant={"bold"}
        rightContent={handlingTypeAndStatus.type}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Status"}
        leftTextVariant={"bold"}
        rightContent={handlingTypeAndStatus.status}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Exclude reason"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.excludeReason?.description}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Route"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.route.code}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Dep. date"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.departureDate}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Dep. time"}
        leftTextVariant={"bold"}
        rightContent={formatHoursMinutesWithColon(
          customerBooking?.departureTime ?? ""
        )}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"NSLH date"}
        leftTextVariant={"bold"}
        rightContent={lateHandlingDate}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"NSLH user"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.lateHandlingUser}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"VT"}
        leftTextVariant={"bold"}
        rightContent={firstLetterCapital(
          customerBooking?.vehicleType?.shortDescription ?? ""
        )}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Veh reg."}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.vehicleReg}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Trailer reg."}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.trailerReg}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Cust. ref"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.customerReference}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Consignment no."}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.consignmentNo}
        leftTextWhiteSpace={"nowrap"}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Revision date"}
        leftTextVariant={"bold"}
        rightContent={
          customerBooking?.revisedDate
            ? formatDate(customerBooking?.revisedDate)
            : ""
        }
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"Revision time"}
        leftTextVariant={"bold"}
        rightContent={formatHoursMinutesWithColon(
          customerBooking?.revisedTime ?? ""
        )}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      <InfoPair
        leftContent={"LL msg"}
        leftTextVariant={"bold"}
        rightContent={customerBooking?.loadingListMessage}
        rowContainerWidth={"100%"}
        leftWidth={"30%"}
        rightWidth={"70%"}
      />
      {customerBooking?.consignment?.sailingArchive.route.code &&
        customerBooking?.consignment?.sailingArchive.departureDate &&
        customerBooking?.consignment?.sailingArchive.departureTime && (
          <InfoPair
            leftContent={"Actually shipped"}
            leftTextWhiteSpace={"nowrap"}
            leftTextVariant={"bold"}
            rightContent={`${
              customerBooking?.consignment?.sailingArchive.route.code
            }, ${
              customerBooking?.consignment?.sailingArchive.departureDate
            }, ${formatHoursMinutesWithColon(
              customerBooking?.consignment?.sailingArchive.departureTime ?? ""
            )}`}
            rowContainerWidth={"100%"}
            leftWidth={"30%"}
            rightWidth={"70%"}
          />
        )}
    </Box>
  );
};
