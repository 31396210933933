import {
  ActualChangeForPriceRowQuery,
  ActualChangeForPriceRowQueryVariables,
} from "@/gql/graphql";
import { gql } from "apollo-boost";
import { isEqual } from "lodash";
import { apolloClient } from "../../../../apollo-client/ApolloClient";
import { AppThunk, StoreState } from "../../../../config/redux/RootReducer";
import { rateSheetTableActions } from "../actions";
import { rateSheetStandardTableWrapperActions } from "../redux";
import { rateSheetTableSelectors } from "../selectors";

export const actualChangeForPriceRowQuery = gql`
  query ActualChangeForPriceRow(
    $meterPrice: Float!
    $newPrice: Float!
    $additionalFrom: Float!
    $avgGrossPrice: Float
    $avgLength: Float
    $oldAdditionalFrom: Float
    $oldFixPrice: Float
    $oldVarPrice: Float
  ) {
    productPrice {
      actualPriceChange {
        calculateActualPriceChange(
          meterPrice: $meterPrice
          newPrice: $newPrice
          additionalFrom: $additionalFrom
          avgGrossPrice: $avgGrossPrice
          avgLength: $avgLength
          oldAdditionalFrom: $oldAdditionalFrom
          oldFixPrice: $oldFixPrice
          oldVarPrice: $oldVarPrice
        )
      }
    }
  }
`;

const getCalculateActualForPriceRowQueryVariables = (
  state: StoreState,
  routeAgreementId: string,
  routeAgreementPriceId: string
): ActualChangeForPriceRowQueryVariables => {
  const rowState = rateSheetTableSelectors
    .getTableState(state)
    [routeAgreementId]?.rows.find(
      (r) => r.routeAgreementPriceId === routeAgreementPriceId
    );

  if (!rowState) {
    throw new Error(
      `No row state available for price. routeAgreementId=${routeAgreementId} routeAgreementPriceId=${routeAgreementPriceId}`
    );
  }

  const { editable } = rowState;

  const avgGrossPrice = editable.avgGrossPrice?.amount ?? null;
  const oldFixPrice = editable?.lastPrice?.amount ?? null;
  const oldVarPrice = editable?.lastMeterPrice?.amount ?? null;

  return {
    additionalFrom: editable.additionalFrom ?? 0,
    meterPrice: parseFloat(editable.meterPrice.amount ?? 0),
    newPrice: parseFloat(editable.newPrice.amount ?? 0),
    avgGrossPrice: avgGrossPrice == null ? null : parseFloat(avgGrossPrice),
    avgLength: editable.avgLength ?? null,
    oldAdditionalFrom: editable?.lastChargedFrom ?? null,
    oldFixPrice: oldFixPrice == null ? null : parseFloat(oldFixPrice),
    oldVarPrice: oldVarPrice == null ? null : parseFloat(oldVarPrice),
  };
};

export const fetchActualChangeForSinglePriceRow =
  (routeAgreementId: string, routeAgreementPriceId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const variables: ActualChangeForPriceRowQueryVariables =
        getCalculateActualForPriceRowQueryVariables(
          getState(),
          routeAgreementId,
          routeAgreementPriceId
        );
      const r = await apolloClient.query<
        ActualChangeForPriceRowQuery,
        ActualChangeForPriceRowQueryVariables
      >({
        query: actualChangeForPriceRowQuery,
        fetchPolicy: "network-only",
        variables,
      });

      const actual =
        r.data?.productPrice.actualPriceChange.calculateActualPriceChange ??
        null;

      const latestVariables: ActualChangeForPriceRowQueryVariables =
        getCalculateActualForPriceRowQueryVariables(
          getState(),
          routeAgreementId,
          routeAgreementPriceId
        );

      const userHasNotChangedInputs = isEqual(latestVariables, variables);

      if (userHasNotChangedInputs) {
        dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            routeAgreementId,
            rateSheetTableActions.setEditableActualPriceChangeAction(
              routeAgreementPriceId,
              actual
            )
          )
        );
      }
    } catch (e) {
      console.debug(e);
    }
  };
