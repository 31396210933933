import { useArraySet } from "@stenajs-webui/core";
import { stenaPerformance } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { sailingTypeOptionsWithCodeM } from "../../utils/sortings";
import { useFetchAllRouteAgreementSailingCategories } from "../../hooks/FilterFetchers/UseFetchAllRouteAgreementSailingCategories";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const RouteAgreementSalingCategoryFilterSection: React.FC<Props> = (
  props
) =>
  props.enabled ? (
    <RouteAgreementSalingCategoryFilterSectionInternal {...props} />
  ) : (
    <></>
  );
const RouteAgreementSalingCategoryFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const {
    routeAgreementSailingCategories,
    setRouteAgreementSailingCategories,
  } = useFilterWorkspace();
  const {
    error,
    loading,
    refetch,
    routeAgreementSailingCategories: items,
  } = useFetchAllRouteAgreementSailingCategories();
  const { toggle } = useArraySet(
    routeAgreementSailingCategories,
    setRouteAgreementSailingCategories,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };

  return (
    <FppSearchFilterSection
      sectionId={"routeAgreementSailingCategories"}
      label={label}
      enabled={enabled}
      numSelected={routeAgreementSailingCategories.length}
      leftIcon={stenaPerformance}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {sailingTypeOptionsWithCodeM(items).map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!routeAgreementSailingCategories.find(
                  (salingCategory) => salingCategory.id === item.id
                )
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
