import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/client";
import {
  PriceCalculatorSailingsQuery,
  PriceCalculatorSailingsQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment PriceCalcSailings on Sailing {
    id
    departureTime
    sailingTypeCode
    statusCode
  }
  query PriceCalculatorSailings($routeCode: String!, $departureDate: String!) {
    productPrice {
      sailings {
        byRouteCodeDeparture(
          routeCode: $routeCode
          departureDate: $departureDate
        ) {
          ...PriceCalcSailings
        }
      }
    }
  }
`;

export const useSailingsByRouteCodeDepartureDate = () => {
  const [fetchSailings, { loading: sailingDeparturesLoading, data }] =
    useLazyQuery<
      PriceCalculatorSailingsQuery,
      PriceCalculatorSailingsQueryVariables
    >(query);

  const sailings = data?.productPrice.sailings?.byRouteCodeDeparture?.filter(
    (sailing) =>
      sailing.statusCode.toUpperCase() === "A" ||
      sailing.statusCode.toUpperCase() === "F"
  );

  return { fetchSailings, sailingDeparturesLoading, sailings };
};
