import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  CreateHeadAgreementInput,
  CreateHeadAgreementMutation,
  CreateHeadAgreementMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  ${errorFragment}
  mutation CreateHeadAgreement($input: CreateHeadAgreementInput!) {
    productPrice {
      headAgreement {
        createHeadAgreement(input: $input) {
          ... on CreateHeadAgreementSuccessResult {
            customer {
              id
            }
            headAgreement {
              id
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useCreateHeadAgreementMutation = () => {
  const [mutate, { error, loading }] = useMutation<
    CreateHeadAgreementMutation,
    CreateHeadAgreementMutationVariables
  >(mutation);

  const createHeadAgreement = useCallback(
    (input: CreateHeadAgreementInput) => {
      return mutate({ variables: { input } });
    },
    [mutate]
  );

  return {
    createHeadAgreement,
    error,
    loading,
  };
};
