import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import {
  MultilegRoutePairSelectQuery,
  MultilegRoutePairSelectQueryVariables,
} from "@/gql/graphql";

interface Props extends Omit<SelectProps<unknown>, "value" | "onChange"> {
  value?: string;
  headAgreementId: string;
  onValueChange?: (value: string) => void;
}

const query = gql`
  query MultilegRoutePairSelect($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          multiLegAgrRoutes {
            id
            statusCode
            name
            route {
              id
            }
          }
        }
      }
    }
  }
`;

export const MultilegRoutePairSelect: React.FC<Props> = ({
  onValueChange,
  value,
  headAgreementId,
  ...selectProps
}) => {
  const { data, loading } = useQuery<
    MultilegRoutePairSelectQuery,
    MultilegRoutePairSelectQueryVariables
  >(query, {
    variables: { headAgreementId },
  });

  const multilegRoutePairs = useMemo(() => {
    return data?.productPrice.headAgreement.byId?.multiLegAgrRoutes ?? [];
  }, [data]);

  const allOptions = useMemo(
    () =>
      multilegRoutePairs
        .map((multilegRoutePair) => ({
          label: `${multilegRoutePair.route.id} - ${multilegRoutePair.statusCode}`,
          value: multilegRoutePair.id,
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [multilegRoutePairs]
  );

  const selected = useMemo(
    () => allOptions.find((o) => o.value === value),
    [allOptions, value]
  );

  return (
    <Select
      isLoading={loading}
      options={allOptions}
      value={selected as any}
      menuPortalTarget={document.body}
      onChange={(v: any) => onValueChange?.(v.value)}
      {...selectProps}
    />
  );
};
