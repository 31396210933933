import { EditableEntityState } from "@stenajs-webui/redux";
import { NoShowLateHandlingPricesRow } from "../config/RowsAndColumnsConfig";

export const handleUpdateRoutePricesDataForUpdateTransformer = (
  data: EditableEntityState<NoShowLateHandlingPricesRow>
) => {
  const {
    editable: {
      id,
      routePairCode,
      validFrom,
      noShowDkk,
      noShowEur,
      noShowSek,
      noShowNok,
      noShowPln,
      noShowGbp,
      lateHandlingDkk,
      lateHandlingEur,
      lateHandlingSek,
      lateHandlingNok,
      lateHandlingPln,
      lateHandlingGbp,
    },
  } = data;

  return {
    id,
    routePairCode,
    validFrom,
    noShowDkk,
    noShowEur,
    noShowSek,
    noShowNok,
    noShowPln,
    noShowGbp,
    lateHandlingDkk,
    lateHandlingEur,
    lateHandlingSek,
    lateHandlingNok,
    lateHandlingPln,
    lateHandlingGbp,
  };
};
