import * as React from "react";
import { GridContainerWrapper } from "./GridContainerWrapper";

export interface GridContainerProps {
  boxMinWidth: number;
  boxMaxWidth?: string;
  children?: React.ReactNode;
}

export const GridContainer: React.FC<GridContainerProps> = ({
  boxMinWidth,
  boxMaxWidth,
  children,
}) => {
  return (
    <GridContainerWrapper boxMinWidth={boxMinWidth} boxMaxWidth={boxMaxWidth}>
      {children}
    </GridContainerWrapper>
  );
};
