import { useQuery } from "@apollo/client";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import {
  MovementType,
  PriceCalculatorFormData,
} from "../../../PriceCalculatorFormFetcher";
import { VehicleTypeSelectExtendedQuery } from "@/gql/graphql";

type VehicleTypes =
  VehicleTypeSelectExtendedQuery["productPrice"]["vehicleType"]["all"];

const setInitialValuesByMovementType = (
  vehicleTypes: VehicleTypes,
  movementType: MovementType,
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>
) => {
  const isSectional = vehicleTypes.filter(
    (a) => a.isMultilegBookable === false
  )[0];
  const isMultilegBookable = vehicleTypes.filter(
    (a) => a.isMultilegBookable === true
  )[0];
  const valuesByMovementType =
    movementType === MovementType.SECTIONAL ? isSectional : isMultilegBookable;

  onChangeFormData("vehicleType", valuesByMovementType.code);
  onChangeFormData(
    "vehicleShortDescription",
    valuesByMovementType.shortDescription
  );
  onChangeFormData("vehicleLength", String(valuesByMovementType.length) ?? "");
  onChangeFormData("vehicleWidth", String(valuesByMovementType.width) ?? "");
  onChangeFormData("vehicleWeight", String(valuesByMovementType.weight) ?? "");
  onChangeFormData("drivers", valuesByMovementType.noOfDrivers ?? 0);
  onChangeFormData("passengersAdults", valuesByMovementType.noOfDrivers ?? 0);
};

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  isMultileg: boolean;
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

const query = gql`
  query VehicleTypeSelectExtended {
    productPrice {
      vehicleType {
        all {
          id
          shortDescription
          code
          length
          noOfDrivers
          weight
          width
          isMultilegBookable
        }
      }
    }
  }
`;

export const PriceCalculatorVehicleTypeSelect: React.FC<Props> = ({
  isMultileg,
  formData,
  onChangeFormData,
  ...selectProps
}) => {
  const { data, loading } = useQuery<VehicleTypeSelectExtendedQuery>(query, {
    onCompleted(data) {
      setInitialValuesByMovementType(
        data.productPrice.vehicleType.all,
        formData.movementType,
        onChangeFormData
      );
    },
  });

  const all = useMemo(() => {
    return data?.productPrice.vehicleType.all ?? [];
  }, [data]);

  const onChangeVehicle = useCallback(
    (value: string) => {
      const selectedVehicle = all.find((vehicle) => vehicle.id === value);
      if (selectedVehicle) {
        onChangeFormData(
          "vehicleShortDescription",
          selectedVehicle.shortDescription
        );
        onChangeFormData(
          "vehicleLength",
          String(selectedVehicle.length) ?? undefined
        );
        onChangeFormData(
          "vehicleWidth",
          String(selectedVehicle.width) ?? undefined
        );
        onChangeFormData(
          "vehicleWeight",
          String(selectedVehicle.weight) ?? undefined
        );
        onChangeFormData("drivers", selectedVehicle.noOfDrivers ?? undefined);
        onChangeFormData(
          "passengersAdults",
          selectedVehicle.noOfDrivers ?? undefined
        );
      }
      onChangeFormData("vehicleType", value);
    },
    [all, onChangeFormData]
  );

  const options = useMemo(() => {
    if (isMultileg) {
      return all.map((s) => {
        if (s.isMultilegBookable) {
          return {
            label: s.shortDescription,
            value: s.id,
          };
        }

        return {};
      });
    } else {
      return all.map((s) => ({
        label: s.shortDescription,
        value: s.id,
      }));
    }
  }, [all, isMultileg]);

  const filteredOptions = options.filter((opt) => {
    return Object.keys(opt).length !== 0 && opt.constructor === Object;
  });

  const initialValue = useMemo(() => {
    const valueExist = filteredOptions.some(
      (fo) => fo.value === formData.vehicleType
    );

    if (valueExist) {
      return formData.vehicleType;
    }

    if (filteredOptions.length === 0) {
      return "";
    }

    return filteredOptions[0].value;
  }, [formData.vehicleType, filteredOptions]);

  const selected = useMemo(() => {
    const selectedValue = filteredOptions?.find(
      (o) => o?.value === initialValue
    );
    if (!selectedValue) {
      return "";
    }

    return selectedValue;
  }, [filteredOptions, initialValue]);

  return (
    <Select
      isLoading={loading}
      options={filteredOptions}
      value={selected || null}
      onChange={(v: any) => onChangeVehicle?.(v.value)}
      {...selectProps}
    />
  );
};
