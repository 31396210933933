import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsListFragment } from "../../../common/fragments/ListFragment";
import {
  UseRoutePairDetailsSummaryQuery,
  UseRoutePairDetailsSummaryQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseRoutePairDetailsSummary($routePairId: ID!, $year: Int!) {
    statistics {
      routePairDetails {
        summary(routePairId: $routePairId, year: $year) {
          ...statisticsListFragment
        }
      }
    }
  }
  ${statisticsListFragment}
`;

export const useRoutePairDetailsSummaryQuery = (
  routePairId: string,
  year: number
) => {
  const { data, loading, error } = useQuery<
    UseRoutePairDetailsSummaryQuery,
    UseRoutePairDetailsSummaryQueryVariables
  >(query, { variables: { routePairId, year }, fetchPolicy: "network-only" });

  return {
    error,
    loading,
    summary: data?.statistics.routePairDetails.summary,
  };
};
