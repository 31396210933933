import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import { DelayedRender } from "../../common/components/delayed-render/DelayedRender";
import { LoginPage } from "../../LoginPage";
import { apiRequest } from "./AuthConfig";

interface Props {
  msalInstance: PublicClientApplication;
}

export const AuthMsalGate: React.FC<Props> = ({ children, msalInstance }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <DelayedRender>
          <LoginPage
            msalInstance={msalInstance}
            onPressLogin={() => msalInstance.loginRedirect(apiRequest)}
          />
        </DelayedRender>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};
