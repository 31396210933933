import * as React from "react";
import { useCallback, useState } from "react";
import { Indent, Row } from "@stenajs-webui/core";
import { EditRouteAgreementForm } from "../../common/components/edit-route-agreement-form/EditRouteAgreementForm";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import {
  EditRouteAgreementFormModel,
  emptyEditRouteAgreementFormModel,
} from "../../common/components/models/EditRouteAgreementFormModel";
import { useCreateRouteAgreementMutation } from "../hooks/UseCreateRouteAgreementMutation";
import { createCreateRouteAgreementMutationInput } from "../transformers/CreateRouteAgreementInputTransformer";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { routeFactory } from "../../../../../RouteFactory";
import { useNavigate } from "react-router-dom";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { RouteAgreementTypeCode } from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
  onClickCancel?: () => void;
  canChangeRouteAgreementType: boolean;
  defaultRouteAgreementType: RouteAgreementTypeCode;
  initialStartDate?: string;
  initialEndDate?: string;
}

export const CreateRouteAgreement: React.FC<Props> = ({
  customerId,
  headAgreementId,
  onClickCancel,
  defaultRouteAgreementType,
  canChangeRouteAgreementType,
  initialStartDate,
  initialEndDate,
}) => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<Error | undefined>();

  const [formModel, setFormModel] = useState<EditRouteAgreementFormModel>({
    ...emptyEditRouteAgreementFormModel,
    type: defaultRouteAgreementType,
    valid: {
      startDate: initialStartDate,
      endDate: initialEndDate,
    },
  });

  const setFields = useCallback(
    (fields: Partial<EditRouteAgreementFormModel>) => {
      setFormModel({ ...formModel, ...fields });
    },
    [setFormModel, formModel]
  );

  const { createRouteAgreement, loading, error } =
    useCreateRouteAgreementMutation();

  const onSubmitHandler = async () => {
    setValidationError(undefined);
    try {
      const input = createCreateRouteAgreementMutationInput(
        headAgreementId,
        formModel
      );
      if (input) {
        const { data } = await createRouteAgreement({
          variables: { input },
        });

        const createResult =
          data?.productPrice.routeAgreement.createRouteAgreement;

        if (createResult && "errors" in createResult) {
          setValidationError(
            new Error(
              createResult.errors.map((error) => error.message).join("\n")
            )
          );
        } else if (createResult) {
          const {
            headAgreement: { id: headAgreementId },
            routeAgreement: {
              id: routeAgreementId,
              routes: [
                {
                  routePair: { id: routePairId },
                },
              ],
            },
          } = createResult;

          await navigate(
            routeFactory.productAndPrice.routeAgreement.routeAgreementDetails({
              customerId,
              headAgreementId,
              routeAgreementId,
              routePairId,
              tab: "rates",
            }),
            {
              replace: true,
            }
          );
        }
      }
    } catch (error) {
      setValidationError(error);
    }
  };

  return (
    <EditRouteAgreementForm
      value={formModel}
      setFields={setFields}
      disableType={!canChangeRouteAgreementType}
    >
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <ValidationErrorMessage
          error={error || validationError}
          width={"260px"}
        />
        <Row alignItems={"center"}>
          <FlatButton
            label={"Cancel"}
            onClick={onClickCancel}
            data-testid={
              testIdConstantsWebapp.createRouteAgreementModalCancelButton
            }
          />
          <Indent />
          <PrimaryButton
            label={"Create agreement"}
            onClick={onSubmitHandler}
            loading={loading}
            data-testid={
              testIdConstantsWebapp.createRouteAgreementModalCreateAgreementButton
            }
          />
        </Row>
      </Row>
    </EditRouteAgreementForm>
  );
};
