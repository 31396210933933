import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import { StatisticsSearchAdvancedListByFilterOptions } from "@/gql/graphql";

export const advancedStatisticsListDataFilterVariablesTransformer = (
  committedFilter: WorkspaceState,
  year: number,
  selectedCurrency: string
): StatisticsSearchAdvancedListByFilterOptions => {
  return {
    currency: selectedCurrency,
    negotiationYear: year,
    avgGrossMeterPriceFrom: committedFilter.averageGrossPrice.from!,
    avgGrossMeterPriceTo: committedFilter.averageGrossPrice.to!,
    avgNetMeterPriceFrom: committedFilter.averageNetPrice.from!,
    avgNetMeterPriceTo: committedFilter.averageNetPrice.to!,
    custNos: committedFilter.customers?.map((c) => Number(c.id)) ?? [],
    negotiationStatuses: committedFilter.negotiationStatus.map((s) => s.code),
    rateTo: committedFilter.rate.to!,
    rateFrom: committedFilter.rate.from!,
    sellers: committedFilter.sellers?.map((s) => s.id) ?? [],
    routePairCodes: committedFilter.routePairs.map((rp) => rp.id) ?? [],
    sailingCategories:
      committedFilter.sailingCategories?.map((sc) => sc.id) ?? [],
    salesRegions:
      committedFilter.salesRegions?.map((sr) => Number(sr.id)) ?? [],
    targetTo: committedFilter.target.to!,
    targetFrom: committedFilter.target.from!,
    twelveMonthVolumeTo: committedFilter.twelveMonthVolume.to!,
    twelveMonthVolumeFrom: committedFilter.twelveMonthVolume.from!,
    unitTypes: committedFilter.unitTypes?.map((ut) => ut.id) ?? [],
  };
};
