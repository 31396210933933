import { SpacingValues } from "@/common/utils/SpacingValues";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Customer } from "../types";
import { AgreementDocuments } from "./AgreementDocuments";
import {
  AgreementDocumentsQuery,
  AgreementDocumentsQueryVariables,
} from "@/gql/graphql";
import { agreementDocumentsFragments } from "../fragments/AgreementDocumentFragments";

const query = gql`
  ${agreementDocumentsFragments}
  query AgreementDocuments($customerNo: ID!) {
    productPrice {
      customers {
        byId(id: $customerNo) {
          ...AgreementDocuments
        }
      }
    }
  }
`;

export interface AgreementDocumentsScreenParams {
  customerNo: string;
}

export const AgreementDocumentsScreen: React.FC = () => {
  const { customerId: customerNo } = useParams() as { customerId: string };
  const [customer, setCustomer] = useState<Customer>(null);

  const { error, loading } = useQuery<
    AgreementDocumentsQuery,
    AgreementDocumentsQueryVariables
  >(query, {
    fetchPolicy: "cache-and-network",
    variables: {
      customerNo,
    },
    onCompleted(data) {
      setCustomer(data.productPrice.customers.byId);
    },
  });

  if (loading) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!customerNo) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={"Missing customerId."} />
      </Spacing>
    );
  }

  return (
    <>
      <Helmet title="Documents - Customer - FPP" />
      <AgreementDocuments customer={customer} />
    </>
  );
};
