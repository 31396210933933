import { useState } from "react";
import download from "downloadjs";
import { getHttp } from "@/apollo-client/ApolloClientFactory";
import { IPublicClientApplication } from "@azure/msal-browser";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { ApiErrorResult } from "@/features/admin/upload-surcharge-documents/components/SurchargeDocumentsUploadModal";

export const useDownloadPdf = (
  instance: IPublicClientApplication,
  setError:
    | React.Dispatch<
        React.SetStateAction<FreightPricingPortalError | undefined>
      >
    | React.Dispatch<React.SetStateAction<ApiErrorResult | undefined>>
) => {
  const [loading, setLoading] = useState(false);

  const transformFetchResponseToError = async (
    response: Response
  ): Promise<FreightPricingPortalError | ApiErrorResult | undefined> => {
    if (!response.ok) {
      const data = await response.json();
      return data;
    }
  };

  const handleDownload = async (
    fetchUrl: string,
    fileName: string,
    preview: boolean = false
  ) => {
    setLoading(true);

    let response: Response | undefined = undefined;

    try {
      response = await getHttp(fetchUrl, instance);
      if (response && response.ok) {
        setError(undefined);
        const blob = await response.blob();
        presentPdf(blob, fileName, preview);
      } else {
        if (response) {
          throw await transformFetchResponseToError(response);
        }
      }
    } catch (error) {
      if (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return { handleDownload, loading };
};

const presentPdf = (blob: Blob, fileName: string, preview: boolean) => {
  if (preview) {
    openBlob(blob);
  } else {
    download(blob, fileName);
  }
};

function openBlob(blob: Blob) {
  const url = URL.createObjectURL(blob);
  const popup = window.open(url);
  if (!popup) {
    return;
  }

  const intervalID = setInterval(() => {
    if (popup.closed) {
      URL.revokeObjectURL(url);
      clearInterval(intervalID);
    }
  }, 10_000);
}
