import { useCallback } from "react";
import { RouteAgreementFormModel } from "../models/RouteAgreementFormModel";
import { conditionsFormRedux } from "../features/conditions/ConditionsFormRedux";
import { routesFormRedux } from "../features/routes/RoutesFormRedux";
import { notesFormRedux } from "../features/notes/NotesFormRedux";
import { articleFormActions } from "../features/articles/actions";
import { batch, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { matrixFormRedux } from "../features/matrix/MatrixFormRedux";
import { rateSheetTableActions } from "../../../rate-sheet/actions";
import { rateSheetTableSelectors } from "../../../rate-sheet/selectors";
import { RateSheetTableState } from "../../../rate-sheet/reducer";
import {
  createRecordObjectActions,
  RecordObjectState,
  reducerIdGateAction,
} from "@stenajs-webui/redux";

export const useResetForms = (originalFormModel: RouteAgreementFormModel) => {
  const dispatch = useDispatch();
  const table = useSelector(rateSheetTableSelectors.getTableState);

  const resetForms = useCallback(
    () =>
      batch(() =>
        dispatchResetRouteAgreementForms(originalFormModel, dispatch, table)
      ),
    [dispatch, originalFormModel, table]
  );

  return {
    resetForms,
  };
};

export const dispatchResetRouteAgreementForms = (
  originalFormModel: RouteAgreementFormModel,
  dispatch: Dispatch,
  table: RecordObjectState<RateSheetTableState>
) => {
  dispatch(conditionsFormRedux.actions.setEntity(originalFormModel.conditions));
  dispatch(routesFormRedux.actions.setEntity(originalFormModel.routes));
  dispatch(notesFormRedux.actions.setEntity(originalFormModel.notes));
  dispatch(articleFormActions.setFormModel(originalFormModel.articles));
  dispatch(matrixFormRedux.actions.setEntity(originalFormModel.matrix));
  const routeAgreementIds = Object.keys(table);
  routeAgreementIds.forEach((routeAgreementId) => {
    dispatch(
      reducerIdGateAction(
        "rateSheetTableRows",
        createRecordObjectActions().recordAction(
          routeAgreementId,
          rateSheetTableActions.revertAllModifications()
        )
      )
    );
  });
};
