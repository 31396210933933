import { Box, Indent, Row } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import React, { Fragment } from "react";

interface Props {
  routeLegOptions: Array<string>;
  value?: string;
  onValueChange: (value: string) => void;
}

const boxWrapperStyle = { marginBottom: 15, marginRight: 15 };

export const LegSelectionRadioButtonRow: React.FC<Props> = ({
  value = "",
  onValueChange,
  routeLegOptions,
}) => {
  return (
    <Row alignItems={"center"} flexWrap={"wrap"}>
      {routeLegOptions &&
        routeLegOptions.map((rlo, i) => (
          <Box style={{ ...boxWrapperStyle }}>
            <Fragment key={rlo}>
              {i > 0 && <Indent num={1.5} />}
              <RadioButtonWithLabel
                label={rlo}
                checked={value === rlo}
                onValueChange={() => onValueChange(rlo)}
              />
            </Fragment>
          </Box>
        ))}
    </Row>
  );
};
