import { formatDateString } from "@/common/dates/formatters";
import { validateCreateMultiLegRouteAgreementForm } from "../../../../../components/util/ValidateCreateMultiLegRouteAgreementForm";
import { CreateMultiLegAgreementFormModel } from "../../model/EditAndCreateMultiLegAgreementFormModel";

export interface CreateMultiLegRouteAgreementInput {
  name: string;
  headAgreementId: string;
  currencyCode: string;
  routeCode: string;
  validFrom: string;
  validTo: string;
}

export const createMultiLegRouteAgreementMutationInput = (
  formModel: CreateMultiLegAgreementFormModel
): CreateMultiLegRouteAgreementInput => {
  validateCreateMultiLegRouteAgreementForm(formModel);
  return {
    headAgreementId: formModel.headAgreementId,
    currencyCode: formModel.currencyCode,
    name: formModel.name,
    routeCode: formModel.routeCode,
    validFrom: formatDateString(formModel.valid.startDate!),
    validTo: formatDateString(formModel.valid.endDate!),
  };
};
