import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const bulkEditRatesFilterSettings: FppFilterSettings = {
  routePairEnabled: true,
  routePairHeader: "Route",
  routeAgreementSailingCategoryEnabled: true,
  routeAgreementSailingCategoryHeader: "Sailing type",
  vehicleTypeEnabled: true,
  vehicleTypeLabel: "Vehicle type",
  vehicleLengthEnabled: true,
  vehicleWeightEnabled: true,
  emptyLoadedEnabled: true,
  emptyLoadedHeader: "Empty/Loaded",
  unaccEnabled: true,
};
