import { gql } from "apollo-boost";

export const agreementArticlesHeadAgreementFragment = gql`
  fragment AgreementArticlesHeadAgreement on HeadAgreement {
    id
    statusCode
    name
    headAgreementNumber
    hasSomeRouteAgreementsWithMultiLeg
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }
`;
export const agreementArticlesCustomerFragment = gql`
  fragment AgreementArticlesCustomer on Customer {
    id
    name
    custIndex
    custNo
    statisticalGrouping {
      id
    }
    hasRebateAgreements
    salesRegion {
      id
      name
    }
    seller {
      id
      name
    }
    custStatus {
      id
      name
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        name
      }
    }
    isParent
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    breadCrumbName
    invoiceCurrency {
      id
      code
    }
  }
`;
export const agreementArticlesRouteAgreementFragment = gql`
  fragment AgreementArticlesRouteAgreement on RouteAgreement {
    name
    id
    rowVersion
    statusCode
    routes {
      id
      code
      routePair {
        id
        description
        code
      }
      distanceType {
        id
        description
        code
      }
    }
    sailingTypeCodes
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    articles {
      id
      articleType {
        code
        updatable
      }
      prices {
        id
        vehicleType {
          code
          shortDescription
        }
        lengthFrom
        lengthTo
        weightFrom
        weightTo
        price {
          amount
        }
      }
      validities {
        id
        price
        validFrom
        agrRouteArticlePriceId
      }
    }
  }
`;
