import { Box, Indent, Row, Spacing, Txt } from "@stenajs-webui/core";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import { TextArea } from "@stenajs-webui/forms";
import * as React from "react";
import { Window } from "@stenajs-webui/modal";

interface Props {
  isOpen: boolean;
  noteValue: string;
  setNoteValue: (value: React.SetStateAction<string>) => void;
  onSave: () => void;
  onRequestClose: () => void;
}

export const CreateNoteModal: React.FC<Props> = ({
  isOpen = false,
  noteValue,
  setNoteValue,
  onSave,
  onRequestClose,
}) => {
  return (
    <Window
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      indent={2}
      width={"100%"}
      header={
        <Txt variant={"bold"} size={"large"}>
          {"Create invoices note"}
        </Txt>
      }
    >
      <Label text={"Note"} optional maxCharacters={25}>
        <Box width={"500px"}>
          <TextArea
            rows={5}
            value={noteValue}
            onValueChange={(note) => setNoteValue(note)}
            maxLength={25}
          />
        </Box>
      </Label>
      <Spacing />
      <Row justifyContent={"flex-end"} alignItems={"center"}>
        <FlatButton label={"Cancel"} onClick={onRequestClose} />
        <Indent />
        <PrimaryButton label={"Save & apply"} onClick={onSave} />
      </Row>
    </Window>
  );
};
