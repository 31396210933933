import { gql } from "apollo-boost";
import { RoutePair } from "../../types/FilterEntitys";
import { useQuery } from "@apollo/client";
import { RoutePairFilterQuery } from "@/gql/graphql";

export const routePairFilterQuery = gql`
  fragment seaAreaStatusOwn on SeaArea {
    id
    name
  }
  query RoutePairFilter {
    productPrice {
      routePair {
        allWithStatusOwn {
          id
          code
          seaArea {
            ...seaAreaStatusOwn
          }
        }
      }
    }
  }
`;

export const useFetchAllRoutePairs = () => {
  const { data, loading, error, refetch } = useQuery<RoutePairFilterQuery>(
    routePairFilterQuery,
    { fetchPolicy: "cache-first" }
  );
  const items =
    data?.productPrice.routePair.allWithStatusOwn.map<RoutePair>((r) => ({
      id: r.id,
      code: r.code,
      name: r.code,
      seaArea: r.seaArea,
    })) ?? [];
  return { items, loading, error, refetch };
};
