import { Action, AnyAction, combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { ThunkAction } from "redux-thunk";
import {
  negotiationYearRedux,
  NegotiationYearState,
} from "../../common/components/data-driven-inputs/selects/negotiation-year-select/redux";
import {
  filterRenderReducer,
  FilterRenderState,
  profileReducer,
  ProfileState,
} from "../../common/redux/profile/reducers";
import {
  statBoxesRedux,
  StatBoxesState,
} from "../../features/customer-route-statistic-boxes/redux";
import {
  NoShowLateHandlingBookingAndCheckboxState,
  noShowLateHandlingHandleBookingsCheckboxSelectionReducer,
  noShowLateHandlingHandleBookingsReducer,
  NoShowLateHandlingHandleBookingsState,
} from "../../features/no-show-late-handling/handle-bookings/redux/reducers";
import {
  nslhInternalOverviewReducer,
  NslhInternalOverviewState,
} from "../../features/no-show-late-handling/overview/redux/reducers";
import {
  noShowLateHandlingPricesCurrentlySelectedReducer,
  noShowLateHandlingPricesReducer,
  NoShowLateHandlingRoutePricesCurrenctlySelectedState,
  NoShowLateHandlingRoutePricesState,
} from "../../features/no-show-late-handling/route-prices/redux/reducers";
import {
  noShowLateHandlingSearchInvoicesReducer,
  NoShowLateHandlingSearchInvoicesState,
} from "../../features/no-show-late-handling/search-invoices/redux/reducers";
import {
  customerMultiLegRouteRedux,
  CustomerMultiLegRouteState,
} from "../../features/product-and-price/customer-multileg-route/redux";
import {
  customerRouteRedux,
  CustomerRouteState,
} from "../../features/product-and-price/customer-route/redux";
import {
  customerStatisticsReducer,
  CustomerStatisticsState,
} from "../../features/product-and-price/customer-statistics/redux";
import {
  customerListReducer,
  CustomerListState,
} from "../../features/product-and-price/customers/customer-list/redux";
import {
  expectedVolumeReducer,
  ExpectedVolumeState,
} from "../../features/product-and-price/expected-volume/redux/ExpectedVolumeRedux";
import {
  activateHeadAgreementStatusConfirmationReducer,
  NegotiationStatusActivationState,
} from "../../features/product-and-price/head-agreement/activate/redux";
import {
  agreementArticlesReducer,
  AgreementArticlesState,
} from "../../features/product-and-price/head-agreement/agreement-articles/redux/reducer";
import {
  customerAgreementReducer,
  CustomerAgreementState,
} from "../../features/product-and-price/head-agreement/details/features/existing-agreement/components/redux/reducers";
import {
  bulkEditRatesFilterReducer,
  BulkEditRatesFilterState,
} from "../../features/product-and-price/rate-sheet/bulk-edit-rate/components/filter/reducer";
import {
  bulkEditRatesStatBoxesReducer,
  BulkEditRatesStatBoxesState,
} from "../../features/product-and-price/rate-sheet/bulk-edit-rate/components/stat-boxes/redux/reducer";
import {
  bulkEditRatesReducer,
  BulkEditRatesState,
  bulkEditRatesTableReducer,
  BulkEditRatesTableState,
} from "../../features/product-and-price/rate-sheet/bulk-edit-rate/redux/reducer";
import {
  rateSheetReducer,
  RateSheetState,
} from "../../features/product-and-price/rate-sheet/redux";
import {
  routeAgreementDetailsReducer,
  RouteAgreementDetailsState,
} from "../../features/product-and-price/route-agreement/details/redux";
import {
  statisticsReducer,
  StatisticsState,
} from "../../features/statistics/redux";
import {
  adminTargetReducer,
  AdminTargetState,
} from "../../features/targeting-tool/admin-target/components/all-routes/redux/reducers";
import {
  customerTargetReducer,
  CustomerTargetState,
} from "../../features/targeting-tool/route-target/features/route-pair-target-table/redux/reducers";
import {
  routeTargetReducer,
  RouteTargetState,
} from "../../features/targeting-tool/route-target/redux";
import {
  routeTargetsOverviewReducer,
  RouteTargetsOverviewState,
} from "../../features/targeting-tool/route-targets-overview/redux/routeTargetsOverviewReducer";
import {
  notificationsReducer,
  NotificationsState,
} from "@/features/notification-center/redux/slice";

export type AppThunk<TAction extends Action = AnyAction> = ThunkAction<
  Promise<void>,
  StoreState,
  undefined,
  TAction
>;

export interface StoreState {
  customerList: CustomerListState & PersistPartial;
  rateSheet: RateSheetState;
  routeAgreementDetails: RouteAgreementDetailsState;
  expectedVolume: ExpectedVolumeState;
  customerStatistics: CustomerStatisticsState;
  customerRoute: CustomerRouteState;
  customerMultiLegRoute: CustomerMultiLegRouteState;
  statistics: StatisticsState;
  statBoxes: StatBoxesState;
  negotiationStatusActivation: NegotiationStatusActivationState;
  routeTarget: RouteTargetState;
  routeTargetsOverview: RouteTargetsOverviewState;
  negotiationYear: NegotiationYearState;
  adminTarget: AdminTargetState;
  profile: ProfileState;
  customerAgreement: CustomerAgreementState;
  noShowLateHandlingPrices: NoShowLateHandlingRoutePricesState;
  noShowLateHandlingPricesCurrentlySelected: NoShowLateHandlingRoutePricesCurrenctlySelectedState;
  noShowLateHandlingHandleBookings: NoShowLateHandlingHandleBookingsState;
  noShowLateHandlingBookingAndCheckboxSelection: NoShowLateHandlingBookingAndCheckboxState;
  noShowLateHandlingSearchInvoices: NoShowLateHandlingSearchInvoicesState;
  nslhInternalOverview: NslhInternalOverviewState;
  filterRenderState: FilterRenderState;
  customerTargetState: CustomerTargetState;
  bulkEditRatesFilter: BulkEditRatesFilterState;
  bulkEditRatesTableState: BulkEditRatesTableState;
  bulkEditRatesState: BulkEditRatesState;
  notifications: NotificationsState;
  bulkEditRatesStatBoxes: BulkEditRatesStatBoxesState;
  agreementArticles: AgreementArticlesState;
}

export const rootReducer = combineReducers<StoreState>({
  customerList: persistReducer(
    { key: "customerList", storage },
    customerListReducer
  ),
  rateSheet: rateSheetReducer,
  routeAgreementDetails: routeAgreementDetailsReducer,
  customerStatistics: customerStatisticsReducer,
  expectedVolume: expectedVolumeReducer,
  customerRoute: customerRouteRedux.reducer,
  customerMultiLegRoute: customerMultiLegRouteRedux.reducer,
  statistics: statisticsReducer,
  statBoxes: statBoxesRedux.reducer,
  negotiationStatusActivation: activateHeadAgreementStatusConfirmationReducer,
  routeTarget: routeTargetReducer,
  routeTargetsOverview: routeTargetsOverviewReducer,
  negotiationYear: negotiationYearRedux.reducer,
  adminTarget: adminTargetReducer,
  profile: profileReducer,
  notifications: notificationsReducer,
  customerAgreement: customerAgreementReducer,
  noShowLateHandlingPrices: noShowLateHandlingPricesReducer,
  noShowLateHandlingPricesCurrentlySelected:
    noShowLateHandlingPricesCurrentlySelectedReducer,
  noShowLateHandlingHandleBookings: noShowLateHandlingHandleBookingsReducer,
  noShowLateHandlingBookingAndCheckboxSelection:
    noShowLateHandlingHandleBookingsCheckboxSelectionReducer,
  noShowLateHandlingSearchInvoices: noShowLateHandlingSearchInvoicesReducer,
  filterRenderState: filterRenderReducer,
  nslhInternalOverview: nslhInternalOverviewReducer,
  customerTargetState: customerTargetReducer,
  bulkEditRatesFilter: bulkEditRatesFilterReducer,
  bulkEditRatesTableState: bulkEditRatesTableReducer,
  bulkEditRatesState: bulkEditRatesReducer,
  bulkEditRatesStatBoxes: bulkEditRatesStatBoxesReducer,
  agreementArticles: agreementArticlesReducer,
});
