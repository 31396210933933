import { Space, Text } from "@stenajs-webui/core";
import { Icon, stenaExclamationTriangle } from "@stenajs-webui/elements";
import { EntityCrudStatus, ModifiedFieldItemState } from "@stenajs-webui/redux";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { CrudStatusIndicator, hasIndicatorContent } from "@stenajs-webui/grid";
import { ModifiedFieldSeparator } from "../../../../common/components/table/ModifiedFieldSeparator";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { ZIndex } from "@/common/utils/ZIndexEnum";

interface Props {
  warningOnEmpty?: string;
  isEditable?: boolean;
  value?: string;
  crudStatus?: EntityCrudStatus;
  modifiedField?: ModifiedFieldItemState;
  includeOldValue?: boolean;
}

export const RateSheetModifiedField: React.FC<Props> = ({
  isEditable,
  warningOnEmpty,
  value,
  modifiedField,
  crudStatus,
  includeOldValue,
}) => {
  const showEmptyFieldWarning =
    warningOnEmpty && modifiedField?.modified && modifiedField?.newValue === "";

  const hasCrudIndicator = crudStatus && hasIndicatorContent(crudStatus);

  const hasRightIcon = showEmptyFieldWarning || hasCrudIndicator;

  return (
    <>
      {includeOldValue ? (
        <>
          <Text
            color={isEditable ? "var(--swui-primary-action-color)" : undefined}
          >
            {value}
          </Text>

          {modifiedField?.newValue !== undefined && (
            <>
              <ModifiedFieldSeparator />
              <Text color={"var(--swui-primary-action-color)"} variant={"bold"}>
                {dotToCommaTransform(modifiedField.newValue)}
              </Text>
            </>
          )}
        </>
      ) : (
        <>
          <Text
            color={isEditable ? "var(--swui-primary-action-color)" : undefined}
            variant={modifiedField?.modified ? "bold" : undefined}
          >
            {dotToCommaTransform(
              modifiedField?.newValue
                ? modifiedField.newValue
                : value
                ? value
                : ""
            )}
          </Text>
        </>
      )}
      {hasRightIcon && <Space />}
      {showEmptyFieldWarning ? (
        <Tooltip label={warningOnEmpty!} zIndex={ZIndex.lowest}>
          <Icon
            icon={stenaExclamationTriangle}
            color={"var(--lhds-color-orange-600)"}
            size={14}
          />
        </Tooltip>
      ) : (
        <CrudStatusIndicator crudStatus={crudStatus} />
      )}
    </>
  );
};
