import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const customerListSearchFilterSettings: FppFilterSettings = {
  enableChipClearButtons: false,
  routePairEnabled: true,
  routePairHeader: "Routes",
  sellerEnabled: true,
  negotiationStatusEnabled: true,
  salesRegionEnabled: true,
  customerTypeEnabled: true,
  includeCustomersWithEnabled: true,
  negotiationYearEnabled: true,
  noneSelectedChipLabels: {
    negotiationStatusNoneSelectedLabel: "All negotiation statuses",
  },
};
