import { formatThousandsCheckNull } from "@/common/numbers";
import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import { negotiationStatusCodeTransformer } from "../../../../common/formatters/NegotiationStatusCodeTransformer";
import { transformAccUnaccEntityToSingleLetter } from "../../../../common/transformers/UnaccompaniedTransformer";
import {
  advancedInfoColumnOrder,
  avgPriceColumnOrder,
  noLabelColumnOrder,
  routeSailingAndUnitColumnOrder,
  targetRateAndVolumesColumnOrder,
} from "./StatisticsByAdvancedColumnOrder";
import { Indent } from "@stenajs-webui/core";

export interface StatisticsAdvancedDataListItem {
  id: string;
  customerName: string | null;
  negStatus: string | null;
  custNo: number;
  custIndex: string | null;
  statNo: number | null;
  parentNo: number | null;
  seller: string | null;
  route: string | null;
  sailing: string | null;
  unit: string | null;
  gross: number | null;
  net: number | null;
  target: number | null;
  rate: number | null;
  volume12Month: number;
  volumeStat12Month: number | null;
  expVolume: number | null;
}

export type StatisticsAdvancedDataListColumns = keyof Omit<
  StatisticsAdvancedDataListItem,
  "id"
>;

export const createByAdvancedResultTableConfig = (
  currency: string
): StandardTableConfigWithGroups<
  StatisticsAdvancedDataListItem,
  StatisticsAdvancedDataListColumns
> => ({
  keyResolver: (item) => item.id,
  disableSorting: false,
  stickyHeader: true,
  stickyColumnGroups: "first",
  initialSortOrder: "customerName",
  initialSortOrderDesc: false,
  rowIndent: 2,
  columns: {
    customerName: createColumnConfig((item) => item.customerName, {
      columnLabel: "Name",
      width: "180px",
      justifyContentCell: "left",
      justifyContentHeader: "left",
    }),
    negStatus: createColumnConfig((item) => item.negStatus, {
      columnLabel: "Neg status",
      minWidth: "120px",
      itemLabelFormatter: (item) =>
        negotiationStatusCodeTransformer(item ?? ""),
      justifyContentCell: "left",
      justifyContentHeader: "left",
    }),
    custNo: createColumnConfig((item) => item.custNo, {
      columnLabel: "Cust no.",
      minWidth: "90px",
    }),
    custIndex: createColumnConfig((item) => item.custIndex, {
      columnLabel: "Index",
      minWidth: "94px",
    }),
    statNo: createColumnConfig((item) => item.statNo, {
      columnLabel: "Stat no.",
      minWidth: "88px",
    }),
    parentNo: createColumnConfig((item) => item.parentNo, {
      columnLabel: "Parent no.",
      minWidth: "103px",
    }),
    seller: createColumnConfig((item) => item.seller, {
      columnLabel: "Seller",
      minWidth: "80px",
    }),
    route: createColumnConfig((item) => item.route, {
      columnLabel: "Route",
      minWidth: "66px",
    }),
    sailing: createColumnConfig((item) => item.sailing, {
      columnLabel: "Sailing",
      minWidth: "69px",
    }),
    unit: createColumnConfig((item) => item.unit, {
      columnLabel: "Unit",
      minWidth: "57px",
      itemLabelFormatter: (value) =>
        transformAccUnaccEntityToSingleLetter(value ?? ""),
    }),
    gross: {
      itemValueResolver: ({ gross }) => (gross === null ? -Infinity : gross),
      columnLabel: "Gross",
      minWidth: "64px",
      renderCell: ({ item }) => (
        <Indent>{formatThousandsCheckNull(item.gross)}</Indent>
      ),
    },
    net: {
      itemValueResolver: ({ net }) => (net === null ? -Infinity : net),
      columnLabel: "Net",
      minWidth: "64px",
      renderCell: ({ item }) => (
        <Indent>{formatThousandsCheckNull(item.net)}</Indent>
      ),
    },
    target: {
      itemValueResolver: ({ target }) => (target === null ? -Infinity : target),
      columnLabel: "Target (%)",
      width: "105px",
      renderCell: ({ item }) => (
        <Indent>
          {item.target === 0
            ? item.target
            : formatThousandsCheckNull(item.target)}
        </Indent>
      ),
    },
    rate: {
      itemValueResolver: ({ rate }) => (rate === null ? -Infinity : rate),
      columnLabel: "Rate (%)",
      width: "90px",
      renderCell: ({ item }) => (
        <Indent>
          {item.rate === 0 ? item.rate : formatThousandsCheckNull(item.rate)}
        </Indent>
      ),
    },
    volume12Month: createColumnConfig((item) => item.volume12Month, {
      columnLabel: "12m vol.",
      width: "95px",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    volumeStat12Month: createColumnConfig((item) => item.volumeStat12Month, {
      columnLabel: "12m stat vol.",
      width: "110px",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    expVolume: {
      itemValueResolver: ({ expVolume }) =>
        expVolume === null ? -Infinity : expVolume,
      columnLabel: "Exp vol.",
      width: "90px",
      renderCell: ({ item }) => (
        <Indent>{formatThousandsCheckNull(item.expVolume)}</Indent>
      ),
    },
  },
  columnGroups: {
    customerInfo: {
      columnOrder: advancedInfoColumnOrder,
      label: "Customer info",
    },
    noLabel: {
      columnOrder: noLabelColumnOrder,
      borderLeft: true,
    },
    routeSailingAndUnit: {
      columnOrder: routeSailingAndUnitColumnOrder,
      borderLeft: true,
      label: "Route, sailing and unit",
    },
    avgPrice: {
      columnOrder: avgPriceColumnOrder,
      label: `${currency}/m`,
      borderLeft: true,
    },
    targetRateAndVolumes: {
      columnOrder: targetRateAndVolumesColumnOrder,
      label: "Target, rate and volumes",
      borderLeft: true,
    },
  },
  columnGroupOrder: [
    "customerInfo",
    "noLabel",
    "routeSailingAndUnit",
    "avgPrice",
    "targetRateAndVolumes",
  ],
});
