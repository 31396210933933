import { SpaceValues } from "@/common/utils/SpaceValues";
import { useQuery } from "@apollo/client";
import { Column, Row, Space, Text } from "@stenajs-webui/core";
import { Banner, Card, CardHeader, FlatButton } from "@stenajs-webui/elements";
import { SwitchWithLabel } from "@stenajs-webui/forms";
import { ErrorScreen, LoadingPanel } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import { sortBy } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NegotiationYearSelect } from "../../../../common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { PageLayout } from "../../../../common/components/page-layout/PageLayout";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { expectedVolumeSelectors } from "../redux/ExpectedVolumeRedux";
import { fetchExpectedVolume } from "../thunks/ExpectedVolumeFetcher";
import { ExpectedVolumeForRoutePair } from "./ExpectedVolumeForRoutePair";
import { ExpectedVolumeHeader } from "./ExpectedVolumeHeader";
import { CustomerExpectedVolumeQuery } from "@/gql/graphql";

interface Props {
  customerId: string;
}

const query = gql`
  query CustomerExpectedVolume($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          statisticalGrouping {
            id
          }
        }
      }
      routePair {
        allWithStatusOwn {
          id
          code
        }
      }
    }
  }
`;

export const CustomerExpectedVolumePane: React.FC<Props> = ({ customerId }) => {
  const dispatch = useDispatch();
  const { defaultYear } = useNegotiationYears();
  const [year, setYear] = useState(defaultYear);
  const [includeRoutesWithNoVolume, setIncludeRoutesWithNoVolume] =
    useState(false);
  const {
    data,
    error: routePairsError,
    loading: routePairsLoading,
  } = useQuery<CustomerExpectedVolumeQuery>(query, {
    variables: { customerId },
  });

  useEffect(() => {
    dispatch(fetchExpectedVolume(customerId, year));
  }, [dispatch, customerId, year]);

  const { bannerState, loading } = useSelector(
    expectedVolumeSelectors.getProgress
  );

  const showEmptyState = useSelector(
    expectedVolumeSelectors.getExpectedVolumesAreAllEmpty
  );

  const allRoutePairs = sortBy(
    data?.productPrice.routePair.allWithStatusOwn ?? [],
    (a) => a.code
  );

  const statisticalGroupingId =
    data?.productPrice.customers.byId?.statisticalGrouping?.id ?? "0";

  return (
    <>
      <ExpectedVolumeHeader
        customerId={customerId}
        year={year}
        bannerState={bannerState}
      />

      <PageLayout>
        <Card>
          <CardHeader
            text={"Routes"}
            contentRight={
              <Row alignItems={"center"}>
                <Text>Negotiation year</Text>
                <Space />
                <Column width={100}>
                  <NegotiationYearSelect
                    value={year}
                    onValueChange={(value) => setYear(value)}
                  />
                </Column>
                <Space num={SpaceValues.NINETYTWO} />
                <SwitchWithLabel
                  label={"Include routes with no volume"}
                  value={includeRoutesWithNoVolume}
                  onValueChange={(value) => setIncludeRoutesWithNoVolume(value)}
                />
              </Row>
            }
          />
        </Card>
        {loading || routePairsLoading ? (
          <LoadingPanel />
        ) : routePairsError ? (
          <ErrorScreen text={routePairsError.message} />
        ) : !includeRoutesWithNoVolume && showEmptyState ? (
          <Row alignItems={"center"} justifyContent={"center"}>
            <Banner
              variant={"info"}
              text={"No expected volume matches the current filter"}
            >
              <Row flex={1} justifyContent={"flex-end"}>
                <FlatButton
                  onClick={() => setIncludeRoutesWithNoVolume(true)}
                  label={"Include routes with no volume"}
                />
              </Row>
            </Banner>
          </Row>
        ) : null}

        {allRoutePairs.map((routePair) => (
          <ExpectedVolumeForRoutePair
            selectedYear={year}
            key={routePair.id}
            statNo={statisticalGroupingId}
            routePairCode={routePair.id}
            hideIfNoItems={!includeRoutesWithNoVolume}
          />
        ))}
      </PageLayout>
    </>
  );
};
