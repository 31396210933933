import { HeadAgreementStatusCodeChip } from "@/common/components/chips/HeadAgreementStatusCodeChip";
import { formatSailingTypeCodesToString } from "@/common/formatters/SailingStatusCodeFormatter";
import { Indent } from "@stenajs-webui/core";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";

export type RouteAgreementsTableConfigColumnKeys =
  | "BusinessRegion"
  | "Route"
  | "Direction"
  | "Status"
  | "Name"
  | "Number"
  | "Type"
  | "Validity"
  | "Currency"
  | "ExternalDescription"
  | "ExternalComment"
  | "Articles";

export const routeAgreementsTableConfig: StandardTableConfig<
  ReportScreenHeadagreementFragment["routeAgreements"]["0"],
  RouteAgreementsTableConfigColumnKeys
> = {
  keyResolver: (item) => item.id,
  showHeaderCheckbox: true,
  showRowCheckbox: true,
  stickyHeader: true,
  disableInfiniteList: true,
  columns: {
    BusinessRegion: createColumnConfig(
      (item) => item.routes[0].routePair.seaArea.name,
      { minWidth: "134px" }
    ),
    Route: createColumnConfig((item) => item.routes[0].routePair.code),
    Direction: createColumnConfig((item) => item.routeDescription),
    Status: createColumnConfig((item) => item.statusCode, {
      renderCell: ({ value }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={value} />
        </Indent>
      ),
    }),
    Name: createColumnConfig((item) => item.name),
    Number: createColumnConfig((item) => item.agreementNumber),
    Type: createColumnConfig((item) =>
      formatSailingTypeCodesToString(item.sailingTypeCodes)
    ),
    Validity: createColumnConfig((item) =>
      formatDateStringRangeWithSwedishTimeZone({
        dateStringFrom: item.valid.start?.isoString,
        dateStringTo: item.valid.end?.isoString,
      })
    ),
    Currency: createColumnConfig((item) => item.currency.code),
    ExternalDescription: createColumnConfig((item) => item.externalDescription),
    ExternalComment: createColumnConfig((item) => item.externalComment),
    Articles: createColumnConfig((item) => (item.hasArticles ? "Y" : "N")),
  },
  columnOrder: [
    "BusinessRegion",
    "Route",
    "Direction",
    "Status",
    "Name",
    "Number",
    "Type",
    "Validity",
    "Currency",
    "ExternalDescription",
    "ExternalComment",
    "Articles",
  ],
};
