export enum AllRouteTargetStatusChange {
  searchEvent = "Route Target Status Change",
}

export enum AllRouteTargetNegotiationYearChange {
  searchEvent = "Route Target Negotiation Year Change",
}

export enum RouteTargetGeneralCurrencyChange {
  searchEvent = "Route Target General Currency Change",
}

export enum RouteTargetErrorOccured {
  errorEvent = "Route Target Error Event Occured",
}
