import {
  Box,
  Column,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
  Txt,
} from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import {
  CheckboxWithLabel,
  RadioButtonWithLabel,
  TextArea,
  TextInput,
} from "@stenajs-webui/forms";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { formatDateString } from "@/common/dates/formatters";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { EditRouteAgreementFormModel } from "../../../route-agreement/common/components/models/EditRouteAgreementFormModel";
import { cssColor } from "@stenajs-webui/theme";
import { RouteAgreementTypeCode } from "@/gql/graphql";

interface Props {
  value: EditRouteAgreementFormModel;
  setFields: (value: Partial<EditRouteAgreementFormModel>) => void;
  disableType: boolean;
  multiLegRoute: string;
  routeLeg: string;
}

const selector = (state: StoreState) =>
  state.routeAgreementDetails.addNameClicked;

export const EditMultiLegRouteAgreementForm: React.FC<Props> = ({
  value,
  setFields,
  children,
  disableType,
  multiLegRoute,
  routeLeg,
}) => {
  const addNameClicked = useSelector(selector);
  const nameInputFieldRefHolder = useRef<HTMLInputElement>(null);
  const otherThanSpecialIsSelected =
    value.peak || value.offPeak || value.shoulder;

  useEffect(() => {
    if (nameInputFieldRefHolder.current) {
      nameInputFieldRefHolder.current.focus();
    }
  }, [addNameClicked]);

  return (
    <Column>
      <Indent num={3}>
        <Label text={"ML Code"}>
          <Box width={"500px"}>
            <Txt>{multiLegRoute}</Txt>
          </Box>
        </Label>
        <Space num={2} />
        <Label text={"Validity"}>
          <Box width={"500px"}>
            <Txt>
              {value.valid.startDate
                ? formatDateString(new Date(value.valid.startDate))
                : "ML RA start date missing."}{" "}
              -{" "}
              {value.valid.endDate
                ? formatDateString(new Date(value.valid.endDate))
                : "ML RA end date missing."}
            </Txt>
          </Box>
        </Label>
        <Space num={2} />
        <Label text={"Currency"}>
          <Box width={"500px"}>
            <Txt>{value.currencyId}</Txt>
          </Box>
        </Label>
        <Space num={2} />
        <Label text={"Route/leg"}>
          <Box width={"500px"}>
            <Txt>{routeLeg}</Txt>
          </Box>
        </Label>
        <Space num={2} />
        <Label text={"Name"} optional={true}>
          <Box width={"500px"}>
            <TextInput
              inputRef={nameInputFieldRefHolder}
              value={value.name}
              onValueChange={(name) => setFields({ name })}
            />
          </Box>
        </Label>
        <Space num={2} />

        <Label text={"Type"}>
          <Row alignItems={"center"}>
            <RadioButtonWithLabel
              label={"Conditional"}
              checked={value.type === RouteAgreementTypeCode.Cond}
              onValueChange={() =>
                setFields({ type: RouteAgreementTypeCode.Cond })
              }
              disabled={disableType}
            />
            <Indent num={1.5} />
            <RadioButtonWithLabel
              label={"Base"}
              checked={value.type === RouteAgreementTypeCode.Base}
              onValueChange={() =>
                setFields({ type: RouteAgreementTypeCode.Base })
              }
              disabled={disableType}
            />
          </Row>
        </Label>
        <Space num={4} />
        <Label text={"Sailing cat."}>
          <Row alignItems={"center"}>
            <CheckboxWithLabel
              label={"Peak"}
              value={value.peak}
              disabled={value.special}
              onValueChange={(peak) => setFields({ peak })}
            />
            <Space num={3} />
            <CheckboxWithLabel
              label={"Off-Peak"}
              value={value.offPeak}
              disabled={value.special}
              onValueChange={(offPeak) => setFields({ offPeak })}
            />
            <Space num={3} />
            <CheckboxWithLabel
              label={"Shoulder"}
              value={value.shoulder}
              disabled={value.special}
              onValueChange={(shoulder) => setFields({ shoulder })}
            />
            <Indent num={3}>
              <Text color={cssColor("--lhds-color-ui-500")}>or</Text>
            </Indent>
            <CheckboxWithLabel
              label={"Special"}
              value={value.special}
              disabled={otherThanSpecialIsSelected}
              onValueChange={(special) => setFields({ special })}
            />
          </Row>
        </Label>
        <Space num={4} />
        <Label text={"External description"} optional>
          <Box width={"500px"}>
            <TextArea
              rows={5}
              value={value.externalDescription}
              onValueChange={(externalDescription) =>
                setFields({ externalDescription })
              }
            />
          </Box>
        </Label>

        {children && (
          <>
            <Spacing />
            {children}
          </>
        )}
      </Indent>
    </Column>
  );
};
