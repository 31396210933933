import {
  createEditableEntityActions,
  createEntityActions,
  createEntityListActions,
  EditableEntitySetEditableEntityFieldsAction,
  EditableEntityState,
  EntityListState,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { NoShowLateHandlingPricesRow } from "../config/RowsAndColumnsConfig";
import {
  AddValidFromModalState,
  CurrentlySelectedSibling,
  noShowLateHandlingPricesAddValidFromModalReducerId,
  noShowLateHandlingPricesCurrentlySelectedReducerId,
  noShowLateHandlingPricesReducerId,
  ProgressState,
} from "./reducers";
import { AllNoShowLateHandlingPricesFragment } from "@/gql/graphql";

export const noShowLateHandlingRoutePricesActions = {
  setRowsNoShowLateHandlingPricesAll: (
    fields: EntityListState<
      EditableEntityState<AllNoShowLateHandlingPricesFragment>
    >
  ) =>
    reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityListActions<
        EditableEntityState<AllNoShowLateHandlingPricesFragment>
      >().setList(fields)
    ),
  setProgress: (fields: Partial<ProgressState>) =>
    reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityActions<ProgressState>().setEntityFields(fields)
    ),
  setFieldsForRow: (
    id: string,
    fields: Partial<AllNoShowLateHandlingPricesFragment>
  ) => {
    return reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityListActions<
        AllNoShowLateHandlingPricesFragment,
        EditableEntitySetEditableEntityFieldsAction<AllNoShowLateHandlingPricesFragment>
      >().actionByFieldsMatch(
        { id },
        createEditableEntityActions<AllNoShowLateHandlingPricesFragment>().setEditableEntityFields(
          fields
        )
      )
    );
  },
  resetTableState: (
    fields: EntityListState<EditableEntityState<NoShowLateHandlingPricesRow>>
  ) => {
    return reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityListActions<
        EditableEntityState<NoShowLateHandlingPricesRow>
      >().setList(fields)
    );
  },

  removeRow: (row: EditableEntityState<NoShowLateHandlingPricesRow>) => {
    return reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityListActions<
        EditableEntityState<NoShowLateHandlingPricesRow>
      >().remove(row)
    );
  },

  addNewSiblingRow: (
    fields: EditableEntityState<NoShowLateHandlingPricesRow>
  ) => {
    return reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      createEntityListActions<
        EditableEntityState<NoShowLateHandlingPricesRow>
      >().addAtStart(fields)
    );
  },

  setAddValidFromModalOpen: (fields: AddValidFromModalState) => {
    return reducerIdGateAction(
      noShowLateHandlingPricesReducerId,
      reducerIdGateAction(
        noShowLateHandlingPricesAddValidFromModalReducerId,
        createEntityActions<AddValidFromModalState>().setEntity(fields)
      )
    );
  },
};

export const noShowLateHandlingRoutePricesCurrentlySelectedActions = {
  setSelectedFields: (fields: Array<CurrentlySelectedSibling>) =>
    reducerIdGateAction(
      noShowLateHandlingPricesCurrentlySelectedReducerId,
      createEntityListActions<CurrentlySelectedSibling>().setList(fields)
    ),
};
