import * as React from "react";
import { Row, Space, Text } from "@stenajs-webui/core";
import { FlatButton, Icon, stenaTimes } from "@stenajs-webui/elements";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  label?: string;
  onClickClose?: () => void;
  iconLeft?: IconDefinition;
  iconLeftSvg?: JSX.Element;
}

const borderBottom = `1px solid ${cssColor("--lhds-color-ui-300")}`;

export const DrawerHeader: React.FC<Props> = ({
  onClickClose,
  label,
  iconLeft,
  iconLeftSvg,
}) => {
  return (
    <Row
      height={"55px"}
      minHeight={"55px"}
      background={cssColor("--lhds-color-ui-50")}
      indent
      alignItems={"center"}
      justifyContent={"space-between"}
      borderBottom={borderBottom}
    >
      <Row alignItems={"center"} indent>
        {iconLeftSvg ? (
          <>
            {iconLeftSvg}
            <Space num={2} />
          </>
        ) : iconLeft ? (
          <>
            <Icon
              icon={iconLeft}
              color={cssColor("--lhds-color-ui-700")}
              size={14}
            />
            <Space num={2} />
          </>
        ) : (
          ""
        )}
        <Text size="large" color={cssColor("--lhds-color-ui-900")}>
          {label}
        </Text>
      </Row>
      <FlatButton leftIcon={stenaTimes} onClick={onClickClose} />
    </Row>
  );
};
