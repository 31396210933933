import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { PriceDetailTableRow } from "./PriceDetailTableRow";
import { PriceDetailTableColumnKeys } from "./PriceDetailsTableColumnKeys";

const itemRenderTransform = (
  rowId: string,
  rowValue: string | number,
  renderFooter: string
) => {
  return rowId === "FOOTER" ? renderFooter : rowValue;
};

export const tableConfigHtmlExport: StandardTableConfig<
  PriceDetailTableRow,
  PriceDetailTableColumnKeys
> = {
  keyResolver: (item) => item.id,
  columns: {
    name: createColumnConfig(
      (item) => itemRenderTransform(item.id, item.name, "Total"),
      {
        justifyContentHeader: "start",
      }
    ),
    code: createColumnConfig(
      (item) => itemRenderTransform(item.id, item.code, ""),
      {
        justifyContentHeader: "end",
        justifyContentCell: "end",
      }
    ),
    priceExclVat: createColumnConfig(
      (item) =>
        itemRenderTransform(
          item.id,
          item.priceExclVat.toFixed(2),
          item.priceExclVat.toFixed(2)
        ),
      {
        justifyContentHeader: "end",
        justifyContentCell: "end",
      }
    ),
    vatAmount: createColumnConfig(
      (item) =>
        itemRenderTransform(
          item.id,
          item.vatAmount.toFixed(2),
          item.vatAmount.toFixed(2)
        ),
      {
        justifyContentHeader: "end",
        justifyContentCell: "end",
      }
    ),
    vatRate: createColumnConfig(
      (item) =>
        itemRenderTransform(item.id, item.vatRate.toFixed(0) + " %", ""),
      {
        justifyContentHeader: "end",
        justifyContentCell: "end",
      }
    ),
    priceInclVat: createColumnConfig(
      (item) =>
        itemRenderTransform(
          item.id,
          item.priceInclVat.toFixed(2) + " " + item.currencyCode,
          item.priceInclVat.toFixed(2) + " " + item.currencyCode
        ),
      {
        justifyContentHeader: "end",
        justifyContentCell: "end",
      }
    ),
  },

  columnOrder: [
    "name",
    "code",
    "priceExclVat",
    "vatAmount",
    "vatRate",
    "priceInclVat",
  ],
};
