import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";

export const convertValueForTableCell = (value: number) => {
  if (value === 0 || value < 0)
    return `${dotToCommaTransform(value.toFixed(2).toString())} %`;

  return `+${dotToCommaTransform(value.toFixed(2).toString())} %`;
};

export const convertValueForTableCellWithOnlyTwoDecimals = (value: number) => {
  return `${dotToCommaTransform(value.toFixed(2).toString())}`;
};
