import {
  SearchFilterState,
  createFppSearchFilterReducer,
} from "@/features/search-filter/redux/reducer";
import { combineReducers } from "redux";

export interface StatisticsByAdvancedState {
  filter: SearchFilterState;
}

export const statisticsByAdvancedReducer =
  combineReducers<StatisticsByAdvancedState>({
    filter: createFppSearchFilterReducer("statistics.statisticsByAdvanced"),
  });
