import { RouteAgreementForForm } from "../../types";

export interface NotesFormModel {
  note: string;
  comment: string;
  externalComment: string;
}

export const emptyNotesFormModel: NotesFormModel = {
  note: "",
  comment: "",
  externalComment: "",
};

export const transformRouteAgreementToNotesForm = (
  routeAgreement: RouteAgreementForForm
): NotesFormModel => {
  return {
    note: routeAgreement.note ?? "",
    comment: routeAgreement.comment ?? "",
    externalComment: routeAgreement.externalComment ?? "",
  };
};
