import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { filter } from "lodash";
import { AgrArticleSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<unknown>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

const query = gql`
  query AgrArticleSelect {
    productPrice {
      articleType {
        all {
          id
          updatable
          code
        }
      }
    }
  }
`;

export const AgrArticleSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<AgrArticleSelectQuery>(query);

  const agrArticles = useMemo(() => {
    return filter(data?.productPrice.articleType.all, ["updatable", true]);
  }, [data]);

  const allOptions = useMemo(
    () =>
      agrArticles
        .map((article) => ({
          label: article.code,
          value: article.code,
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [agrArticles]
  );

  const selected = useMemo(
    () => allOptions.find((o) => o.value === value),
    [allOptions, value]
  );

  return (
    <Select
      isLoading={loading}
      options={allOptions}
      value={selected || null}
      onChange={(v: any) => {
        if (!v) {
          onValueChange?.("");
        } else if (onValueChange && v) {
          onValueChange?.(v.value);
        }
      }}
      placeholder={"Article"}
      {...selectProps}
    />
  );
};
