import { RouteAgreementFormModel } from "../models/RouteAgreementFormModel";
import { RouteAgreementForForm } from "../types";
import { transformRouteAgreementToArticleForm } from "../features/articles/models/ArticlesFormModel";
import { transformRouteAgreementToConditionsForm } from "../features/conditions/ConditionsFormModel";
import { transformRouteAgreementToRoutesForm } from "../features/routes/RoutesFormModel";
import { transformRouteAgreementToNotesForm } from "../features/notes/NotesFormModel";
import { transformRouteAgreementToMatrixForm } from "../features/matrix/MatrixFormModel";

export const transformRouteAgreementToForm = (
  routeAgreement: RouteAgreementForForm
): RouteAgreementFormModel => {
  return {
    conditions: transformRouteAgreementToConditionsForm(routeAgreement),
    routes: transformRouteAgreementToRoutesForm(routeAgreement),
    articles: transformRouteAgreementToArticleForm(routeAgreement),
    notes: transformRouteAgreementToNotesForm(routeAgreement),
    matrix: transformRouteAgreementToMatrixForm(routeAgreement),
  };
};
