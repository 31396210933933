import { format } from "date-fns";

export const currentMonth = new Date().getUTCMonth() + 1;
export const currentDate = format(new Date(), "yyyy-MM-dd");
export const currentYear = new Date().getUTCFullYear();
export const nextYear = new Date().getUTCFullYear() + 1;
export const yearAfterNext = new Date().getUTCFullYear() + 2;

export const years = [
  {
    id: currentYear,
    year: currentYear,
  },
  {
    id: nextYear,
    year: nextYear,
  },
  {
    id: yearAfterNext,
    year: yearAfterNext,
  },
];

export const monthsInNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const yearOptions = years.map((y) => ({
  label: y.id,
  value: y.year,
}));

export const monthOptions = monthsInNumbers.map((month) => ({
  label: `${month <= 9 ? "0" + month : month}`,
  value: month,
}));
