import TippyComponent from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { Instance } from "tippy.js";
import { MutableRefObject, useRef } from "react";
import styles from "./tooltip.module.css";

export const tippyStyles = {
  noPadding: styles.noPadding,
  actionMenu: styles.actionMenu,
};

export type TippyInstance = Instance;
export const Tippy = TippyComponent;

export type TippyElement<TElement extends HTMLElement> = TElement & {
  _tippy: TippyInstance;
};

export const useTippyInstance = <TElement extends HTMLElement>(): [
  (element: TippyElement<TElement> | null) => void,
  MutableRefObject<TippyInstance | undefined>
] => {
  const tippyRef = useRef<TippyInstance>();

  return [
    (element: TippyElement<TElement> | null) => {
      tippyRef.current = element?._tippy ?? undefined;
    },
    tippyRef,
  ];
};
