import { SailingTypeCode } from "@/gql/graphql";
import { sortByOtherArray } from "../list/SortByOtherArray";

export const formatSailingTypeCodesToString = (
  codes: Array<SailingTypeCode>
): string => sortByOtherArray(codes, ["P", "S", "O", "M"]).join("/");

export const formatLongSalingTypeCodesToString = (
  codes: Array<SailingTypeCode>
): string => {
  const salingTypeCodes = codes.map((code) => {
    switch (code) {
      case SailingTypeCode.M:
        return "Special";
      case SailingTypeCode.O:
        return "Off-Peak";
      case SailingTypeCode.P:
        return "Peak";
      case SailingTypeCode.S:
        return "Shoulder";
      default:
        return code;
    }
  });

  return sortByOtherArray(salingTypeCodes, [
    "Peak",
    "Shoulder",
    "Off-Peak",
    "Special",
  ]).join(" / ");
};

export const formatSailingTypeCodes = (
  codes: Array<SailingTypeCode>
): Array<SailingTypeCode> =>
  sortByOtherArray(codes, [
    SailingTypeCode.P,
    SailingTypeCode.S,
    SailingTypeCode.O,
    SailingTypeCode.M,
  ]);

export const formatLongSailingType = (code: SailingTypeCode): string => {
  switch (code) {
    case SailingTypeCode.M:
      return "Special";
    case SailingTypeCode.O:
      return "Off-Peak";
    case SailingTypeCode.P:
      return "Peak";
    case SailingTypeCode.S:
      return "Shoulder";
    default:
      return code;
  }
};
