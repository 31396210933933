import { BoxplotChart } from "@carbon/charts-react";
import { BoxplotChartOptions } from "@carbon/charts/interfaces";
import { Box, Space, Text } from "@stenajs-webui/core";
import * as React from "react";

type ChartData = {
  group: string;
  key?: number;
  value?: number;
};

interface Props {
  currency: string;
  data: Array<ChartData> | undefined;
  options: BoxplotChartOptions;
}

export const Chart: React.FC<Props> = ({ currency, data, options }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <>
          <BoxplotChart data={data} options={options} />
          <Box
            className="priceCurrencyBox"
            display="flex"
            justifyContent="flex-end"
            width="100px"
            style={{
              transform: "translateY(-5px) translateX(10px)",
            }}
          >
            <Text variant="bold">Avg price/m</Text>
            <Space />
            <Text variant="bold">{currency}</Text>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="450px"
        >
          <Text>No average prices exist</Text>
        </Box>
      )}
    </>
  );
};
