import { errorFragment } from "@/common/error/GqlErrorFragment";
import { gql } from "@apollo/client";

export const updateHeadAgreementTextsMutation = gql`
  ${errorFragment}
  mutation UpdateHeadAgreementTexts($input: UpdateHeadAgreementInput!) {
    productPrice {
      headAgreement {
        updateHeadAgreement(input: $input) {
          ... on UpdateHeadAgreementSuccessResult {
            headAgreement {
              id
              condition
              lastPage
              rowVersion
              specifications
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }

  ${errorFragment}
`;
