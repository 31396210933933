import { gql, useQuery } from "@apollo/client";
import { NoShowLateHandlingStatus } from "../../types/FilterEntitys";
import { NoShowLateHandlingStatusQuery } from "@/gql/graphql";

export const noShowLateHandlingStatusQuery = gql`
  query NoShowLateHandlingStatus {
    productPrice {
      noShowLateHandlingStatus {
        all {
          id
          code
          description
          presentationOrder
        }
      }
    }
  }
`;

export const useFetchAllNoShowLateHandlingStatuses = () => {
  const { data, error, loading, refetch } =
    useQuery<NoShowLateHandlingStatusQuery>(noShowLateHandlingStatusQuery, {
      fetchPolicy: "cache-first",
    });

  const handlingStatuses =
    data?.productPrice.noShowLateHandlingStatus.all.map<NoShowLateHandlingStatus>(
      (r) => ({
        id: r.id,
        code: r.code,
        name: r.description,
      })
    ) ?? [];

  return { handlingStatuses, error, loading, refetch };
};
