import { ResultListBannerState } from "@stenajs-webui/elements";
import {
  createEditableEntityReducer,
  createEntityListReducer,
  createEntityReducer,
  EditableEntityState,
  EntityListState,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { combineReducers } from "redux";
import { NoShowLateHandlingPricesRow } from "../config/RowsAndColumnsConfig";

export interface ProgressState {
  loading: boolean;
  bannerState?: ResultListBannerState;
}

export interface CurrentlySelectedSibling {
  routePairCode: string;
  currentlySelectedId: string;
}
export interface AddValidFromModalState {
  isOpen: boolean;
  routePairCode: string;
}
export interface NoShowLateHandlingRoutePricesState {
  allNoShowHandlingPriceData: EntityListState<
    EditableEntityState<NoShowLateHandlingPricesRow>
  >;
  progress: EntityState<ProgressState>;
  addValidFromModal: EntityState<AddValidFromModalState>;
}

export interface NoShowLateHandlingRoutePricesCurrenctlySelectedState {
  selected: EntityListState<Array<CurrentlySelectedSibling>>;
}

export const noShowLateHandlingPricesReducerId =
  "noShowLateHandlingRouteAllPrices";

export const noShowLateHandlingPricesCurrentlySelectedReducerId =
  "noShowLateHandlingRouteAllPricesCurrentlySelected";

export const noShowLateHandlingPricesAddValidFromModalReducerId =
  "noShowLateHandlingPricesAddValidFromModalReducerId";

export const noShowLateHandlingPricesRowInitalState: NoShowLateHandlingPricesRow =
  {
    id: "",
    routePairCode: "",
    lateHandlingDkk: 0,
    lateHandlingEur: 0,
    lateHandlingGbp: 0,
    lateHandlingNok: 0,
    lateHandlingPln: 0,
    lateHandlingSek: 0,
    noShowDkk: 0,
    noShowEur: 0,
    noShowGbp: 0,
    noShowNok: 0,
    noShowPln: 0,
    noShowSek: 0,
    validFrom: "",
    createdBy: "",
    dateCreated: "",
    newlyAddedRow: false,
    routePair: {
      description: "",
    },
  };

export const newlyAddedRowState: NoShowLateHandlingPricesRow = {
  ...noShowLateHandlingPricesRowInitalState,
  newlyAddedRow: true,
};

export const notYetCreatedRowState: NoShowLateHandlingPricesRow = {
  ...noShowLateHandlingPricesRowInitalState,
  newlyAddedRow: false,
};

export const noShowLateHandlingPricesReducer = reducerIdGate(
  noShowLateHandlingPricesReducerId,
  combineReducers({
    allNoShowHandlingPriceData: createEntityListReducer(
      createEditableEntityReducer<NoShowLateHandlingPricesRow>(
        noShowLateHandlingPricesRowInitalState
      )
    ),
    progress: createEntityReducer<ProgressState>({
      loading: false,
      bannerState: undefined,
    }),
    addValidFromModal: reducerIdGate(
      noShowLateHandlingPricesAddValidFromModalReducerId,
      createEntityReducer<AddValidFromModalState>({
        isOpen: false,
        routePairCode: "",
      })
    ),
  })
);

export const noShowLateHandlingPricesCurrentlySelectedReducer = reducerIdGate(
  noShowLateHandlingPricesCurrentlySelectedReducerId,
  combineReducers({
    selected: createEntityListReducer(
      createEntityReducer<Array<CurrentlySelectedSibling>>([
        {
          currentlySelectedId: "",
          routePairCode: "",
        },
      ])
    ),
  })
);
