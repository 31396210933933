import { padStart } from "lodash";

export const padHour = (hour: number): string => padStart(`${hour}`, 2, "0");

const getDecimalDiff = (startTimeString: string, endTimeString: string) => {
  const startHour = parseDecimalTime(startTimeString);
  const endHour = parseDecimalTime(endTimeString);
  return endHour - startHour;
};
export const formatTimeRangeShort = (
  startTimeString: string,
  endTimeString: string
): string =>
  getDecimalDiff(startTimeString, endTimeString) < 3
    ? formatTimeString(startTimeString)
    : formatTimeRange(startTimeString, endTimeString);

export const formatTimeRange = (
  startTimeString: string,
  endTimeString: string
): string =>
  [formatTimeString(startTimeString), formatTimeString(endTimeString)].join(
    " - "
  );

const formatTimeString = (timeString: string): string =>
  timeString.substr(0, 2) + ":" + timeString.substr(2, 2);

export const parseDecimalTime = (timeString: string): number => {
  const hours = parseHours(timeString);
  const minutes = parseMinutes(timeString) / 60;
  return hours + minutes;
};

export const calculateDuration = (
  startTimeString: string,
  endTimeString: string
): [number, number] => {
  const startHours = parseHours(startTimeString);
  const startMinutes = parseMinutes(startTimeString);
  const endHours = parseHours(endTimeString);
  const endMinutes = parseMinutes(endTimeString);

  const diffInMinutes =
    (endHours - startHours) * 60 + (endMinutes - startMinutes);

  const hoursDiff = Math.floor(diffInMinutes / 60);
  const minutesDiff = diffInMinutes - hoursDiff * 60;

  return [hoursDiff, minutesDiff];
};

const parseHours = (timeString: string): number =>
  parseInt(timeString.substr(0, 2), 10);

export const parseMinutes = (timeString: string): number =>
  parseInt(timeString.substr(2, 2), 10);

export const formatDuration = (timeFrom: string, timeTo: string) => {
  const [hours, minutes] = calculateDuration(timeFrom, timeTo);
  const parts = [];
  if (hours > 0) {
    parts.push(hours + "h");
  }
  if (minutes > 0) {
    parts.push(hours + "min");
  }
  return parts.join(" ");
};
