import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import {
  CustomerType,
  IncludeCustomersWith,
  NegotiationStatus,
  RoutePair,
  SalesRegion,
  Seller,
} from "@/features/search-filter/types/FilterEntitys";
import { useArraySet } from "@stenajs-webui/core";

export const useFilterChips = () => {
  const {
    negotiationStatus,
    setNegotiationStatus,
    routePairs,
    setRoutePairs,
    includeCustomersWith,
    setIncludeCustomersWith,
    salesRegions,
    setSalesRegions,
    sellers,
    setSellers,
    customerTypes,
    setCustomerTypes,
    commit,
  } = useFilterWorkspace();

  const { remove: removeNegotiationStatus } = useArraySet(
    negotiationStatus,
    setNegotiationStatus,
    (a, b) => a.id === b.id
  );
  const { remove: removeCustomerType } = useArraySet(
    customerTypes,
    setCustomerTypes,
    (a, b) => a.id === b.id
  );
  const { remove: removeIncludeCustomersWith } = useArraySet(
    includeCustomersWith ?? [],
    setIncludeCustomersWith,
    (a, b) => a.id === b.id
  );
  const { remove: removeSalesRegion } = useArraySet(
    salesRegions ?? [],
    setSalesRegions,
    (a, b) => a.id === b.id
  );
  const { remove: removeRoutePair } = useArraySet(
    routePairs,
    setRoutePairs,
    (a, b) => a.id === b.id
  );

  const { remove: removeSellers } = useArraySet(
    sellers,
    setSellers,
    (a, b) => a.id === b.id
  );

  const onClickRemoveNegotiationStatus = (
    statusToRemove: NegotiationStatus
  ) => {
    removeNegotiationStatus(statusToRemove);
    commit();
  };

  const onClickRemoveCustomerType = (customerTypeToRemove: CustomerType) => {
    removeCustomerType(customerTypeToRemove);
    commit();
  };

  const onClickRemoveSeller = (sellerToRemove: Seller) => {
    removeSellers(sellerToRemove);
    commit();
  };

  const onClickRemoveIncludeCustomersWith = (
    includeCustomersWithToRemove: IncludeCustomersWith
  ) => {
    removeIncludeCustomersWith(includeCustomersWithToRemove);
    commit();
  };

  const onClickRemoveRoutePair = (routePair: RoutePair) => {
    removeRoutePair(routePair);
    commit();
  };

  const onClickRemoveSalesRegion = (salesRegion: SalesRegion) => {
    removeSalesRegion(salesRegion);
    commit();
  };

  return {
    onClickRemoveNegotiationStatus,
    onClickRemoveCustomerType,
    onClickRemoveIncludeCustomersWith,
    onClickRemoveRoutePair,
    onClickRemoveSeller,
    onClickRemoveSalesRegion,
    includeCustomersWith,
    sellers,
    negotiationStatus,
    routePairs,
    salesRegions,
    customerTypes,
  };
};
