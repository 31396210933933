import { environment } from "../../../../config/environment";

declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}
export interface TrackerArgs {
  action: string;
  value?: any;
  nonInteraction?: boolean;
  transport?: string;
}

const QA_GA_TRACKING_ID = "G-MLB1K4L6J0";
const PROD_GA_TRACKING_ID = "G-YGGC9ST8E6";

export const addGA4 = () => {
  const trackerId = environment.isEnvironmentProd()
    ? PROD_GA_TRACKING_ID
    : QA_GA_TRACKING_ID;

  let script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=" + trackerId;
  script.async = true;
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", trackerId, { send_page_view: true });
  document.head.appendChild(script);
};

export const trackEvent = (args: TrackerArgs) => {
  window.gtag("event", args.action, {
    value: args.value,
  });
};
