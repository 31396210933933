import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Indent } from "@stenajs-webui/core";
import { TextInput } from "@stenajs-webui/forms";
import {
  StandardTableCellRenderer,
  UseGridCellResult,
} from "@stenajs-webui/grid";
import {
  EditableEntityState,
  ModifiedFieldItemState,
} from "@stenajs-webui/redux";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertEditorValueToValidInput } from "@/common/numbers";
import { profileSelectors } from "../../../../../common/redux/profile/selectors";
import { inputFieldValidation } from "../../../../../common/regex/InputFieldValidation";
import { AllStatisticsTableModifiedField } from "../all-routes/AllStatisticsTableModifiedField";
import { EditableAllRoutesTableFields } from "./config/AllRoutesTableConfig";
import { AllRoutesTableRow } from "./config/RowsAndColumnsConfig";
import { adminTargetActions } from "./redux/actions";

type AcceptedTypes = string | undefined | number | null;

export const createAllRoutesStatisticsTableCellRenderer =
  (
    field: EditableAllRoutesTableFields
  ): StandardTableCellRenderer<
    AcceptedTypes,
    EditableEntityState<AllRoutesTableRow>
  > =>
  ({ label, item, gridCell, isEditable, value, zIndex, isSelected }) => {
    const originalValue = item.persisted[field];
    const editableValue = item.editable[field];
    const id = item.persisted.id;

    return (
      <AllRoutesStatisticsTableCell
        persistedValue={String(originalValue ?? "")}
        editableValue={String(editableValue ?? "")}
        value={label}
        entityId={id}
        gridCell={gridCell}
        isEditable={isEditable}
        field={field}
      />
    );
  };

interface AllRoutesStatisticsTableCellProps {
  entityId: string;
  value: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  field: EditableAllRoutesTableFields;
  persistedValue: string;
  editableValue: string;
}

const currentYear = new Date().getUTCFullYear();

export const AllRoutesStatisticsTableCell: React.FC<
  AllRoutesStatisticsTableCellProps
> = ({
  entityId,
  value,
  isEditable,
  gridCell,
  persistedValue,
  editableValue,
  field,
}) => {
  const { currentYear: currentlySelectedYear } = useSelector(
    profileSelectors.getGlobal
  );
  const dispatch = useDispatch();
  const [isValidInput, setIsValidInput] = useState<boolean>(true);

  const yearIsModifiable = currentYear <= currentlySelectedYear;

  const modifiedField: ModifiedFieldItemState =
    editableValue === persistedValue
      ? { id: "" }
      : { id: "", modified: true, newValue: value };

  const onDone = useCallback(() => {
    const validationResult = inputFieldValidation(gridCell.editorValue).test(
      gridCell.editorValue
    );

    if (!validationResult) {
      setIsValidInput(true);
      gridCell.revertEditorValue();
      gridCell.stopEditing();
      return;
    }

    dispatch(
      adminTargetActions.setFieldsForRow(entityId, {
        [field]: convertEditorValueToValidInput(gridCell.editorValue),
      })
    );
    gridCell.stopEditingAndMove("right");
  }, [dispatch, field, entityId, gridCell]);

  const validateInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult = inputFieldValidation(e.target.value).test(
      e.target.value
    );
    setIsValidInput(validationResult);
  };

  const currentValue = gridCell.editorValue
    ? convertValueForTableCell(Number(gridCell.editorValue))
    : "Not set";

  return isEditable && gridCell.isEditing && yearIsModifiable ? (
    <Tooltip
      label={"Please provide a valid input"}
      zIndex={ZIndex.medium}
      variant="warning"
      disabled={isValidInput}
    >
      <Indent flex={1} row alignItems={"center"} width={75}>
        <TextInput
          onValueChange={gridCell.setEditorValue}
          onChange={(e) => validateInputOnChange(e)}
          value={gridCell.editorValue}
          onDone={onDone}
          onEsc={gridCell.stopEditingAndRevert}
          autoFocus
          selectAllOnMount={!gridCell.lastKeyEvent}
          variant={!isValidInput ? "warning" : "standard"}
        />
      </Indent>
    </Tooltip>
  ) : (
    <Indent width={100}>
      <AllStatisticsTableModifiedField
        value={currentValue || "Not set"}
        modifiedField={modifiedField}
        isEditable={isEditable}
      />
    </Indent>
  );
};

const convertValueForTableCell = (value: number): string => {
  if (value === 0)
    return `${dotToCommaTransform(value.toFixed(2).toString())} %`;
  if (value > 0)
    return `+${dotToCommaTransform(value.toFixed(2).toString())} %`;

  return `${dotToCommaTransform(value.toFixed(2).toString())} %`;
};
