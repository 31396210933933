import { DateInput } from "@/common/components/date-input/DateInput";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useState } from "react";
import { AgreementArticlesCustomer } from "../../types";
import {
  BulkUpdateAgreementArticlesFormModel,
  RaiseByType,
} from "./models/BulkUpdateAgreementArticlesFormModel";
import { validFromDateError } from "./validators/validators";

interface Props {
  value: BulkUpdateAgreementArticlesFormModel;
  customer: AgreementArticlesCustomer;
  onValueChange: (value: BulkUpdateAgreementArticlesFormModel) => void;
}

export const BulkUpdateAgreementArticlesForm: React.FC<Props> = ({
  value,
  customer,
  onValueChange,
}) => {
  const [validationState, setValidationState] = useState<"invalid" | undefined>(
    undefined
  );

  return (
    <Row alignItems={"center"}>
      <RadioButtonWithLabel
        label="Percentual change"
        checked={value.raiseByType === RaiseByType.PERCENT}
        onValueChange={() =>
          onValueChange({
            ...value,
            raiseByType: RaiseByType.PERCENT,
            raiseByAbsolute: "",
          })
        }
      />
      <Space num={SpaceValues.EIGHT} />
      <Box width={"70px"}>
        <NumericTextInput
          value={value.raiseByPercent}
          onValueChange={(raiseByPercent) =>
            onValueChange({ ...value, raiseByPercent })
          }
          useNegativeValues
          maxDecimals={2}
          placeholder={"±NN %"}
          disabled={value.raiseByType === RaiseByType.ABSOLUTE}
        />
      </Box>
      <Space num={SpaceValues.TWENTYFOUR} />
      <RadioButtonWithLabel
        label={`New price (${customer?.invoiceCurrency.code})`}
        checked={value.raiseByType === RaiseByType.ABSOLUTE}
        onValueChange={() =>
          onValueChange({
            ...value,
            raiseByType: RaiseByType.ABSOLUTE,
            raiseByPercent: "",
          })
        }
      />
      <Space num={SpaceValues.EIGHT} />
      <Box width={"100px"}>
        <NumericTextInput
          value={value.raiseByAbsolute}
          onValueChange={(raiseByAbsolute) =>
            onValueChange({ ...value, raiseByAbsolute })
          }
          useNegativeValues
          maxDecimals={2}
          disabled={value.raiseByType === RaiseByType.PERCENT}
        />
      </Box>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Txt>{"Set valid from date to"}</Txt>
      <Space num={SpaceValues.EIGHT} />

      <Tooltip label={"Invalid date"} disabled={validationState === undefined}>
        <DateInput
          value={value.validFromDate}
          onChange={(validFromDate) =>
            onValueChange({ ...value, validFromDate })
          }
          validationState={validationState}
          onBlur={() =>
            value.validFromDate &&
            validFromDateError(value.validFromDate?.toString()) &&
            setValidationState("invalid")
          }
          onFocus={() => setValidationState(undefined)}
        />
      </Tooltip>
    </Row>
  );
};
