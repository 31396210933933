import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";
import * as React from "react";
import { StatisticsByAdvancedListCard } from "./StatisticsByAdvancedListCard";

interface Props {}

export const StatisticsByAdvancedListPanel: React.FC<Props> = () => {
  return (
    <SearchFilterSelectorProvider reducerId={"statistics.statisticsByAdvanced"}>
      <StatisticsByAdvancedListCard />
    </SearchFilterSelectorProvider>
  );
};
