import { Indent, Row, Space, Text, Txt } from "@stenajs-webui/core";
import {
  Banner,
  Icon,
  InputSpinner,
  stenaCheck,
} from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { useState } from "react";
import { useLocalError } from "../../../../../../../../../common/error/UseLocalError";
import { useFormModelState } from "../../../../../../../../../common/hooks/UseFormModelState";
import { routeFactory } from "../../../../../../../../../RouteFactory";
import { MultiLegAgreement } from "../../../../../types";
import { useCopyMultiLegRouteAgreementMutation } from "../hooks/UseCopyMultiLegRouteAgreement";
import { CopyToOtherCustomerFormMultiLeg } from "./CopyToOtherCustomerFormMultiLeg";
import { CopyToOtherCustomerMultiLegResultPane } from "./CopyToOtherCustomerMultiLegResultPane";
import {
  AllHeadAgreementsFragment,
  ByCustomerNumberOrIndexFragment,
} from "@/gql/graphql";

export interface CopyMultiLegAgreementParams {
  customer: ByCustomerNumberOrIndexFragment;
  headAgreement: AllHeadAgreementsFragment;
}

interface Props {
  multiLegAgreement: MultiLegAgreement;
  onRequestClose: () => void;
}

export const SelectCustomerAndHeadAgreementMultiLegModal: React.FC<Props> = ({
  multiLegAgreement,
  onRequestClose,
}) => {
  const [success, setSuccess] = useState(false);
  const { localError, setLocalError, clearLocalError } = useLocalError();

  const [formData, onChangeFormData] = useFormModelState<
    Partial<CopyMultiLegAgreementParams>
  >({
    customer: undefined,
    headAgreement: undefined,
  });

  const [copyRouteAgreement, { loading }] =
    useCopyMultiLegRouteAgreementMutation();

  const handleSubmit = async () => {
    if (!formData.headAgreement || !formData.customer) {
      return;
    }

    clearLocalError();

    const { data } = await copyRouteAgreement({
      variables: {
        copyMultiLegRouteAgreementInput: {
          headAgreementId: formData.headAgreement.id,
          multiLegAgreementId: multiLegAgreement.id,
          breakDate: null,
          rowVersion: multiLegAgreement.rowVersion,
        },
      },
      refetchQueries:
        formData.customer.id === multiLegAgreement.headAgreement.customer.id
          ? ["HeadAgreementQuery"]
          : undefined,
    });

    const mutationResult =
      data?.productPrice.multiLegRouteAgreement.copyMultiLegRouteAgreement;

    if (mutationResult && "errors" in mutationResult) {
      setLocalError(
        new Error(
          mutationResult.errors.map((error) => error.message).join("\n")
        )
      );
    } else {
      setSuccess(true);
    }
  };

  return (
    <Window
      isOpen={true}
      width={"750px"}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading}
      onRequestClose={!loading ? onRequestClose : undefined}
      header={
        success ? (
          <Row alignItems={"center"}>
            <Icon icon={stenaCheck} />
            <Space num={2} />
            <Text size="large">Successfully copied agreement</Text>
          </Row>
        ) : loading ? (
          <Row alignItems={"center"}>
            <InputSpinner size={"small"} />
            <Space num={2} />
            <Text size="large">Copying...</Text>
          </Row>
        ) : (
          <Txt size="large" variant="bold">
            Copy multi leg agreement to other customer
          </Txt>
        )
      }
    >
      {(success || loading) && formData.customer && formData.headAgreement ? (
        <CopyToOtherCustomerMultiLegResultPane
          loading={loading}
          success={success}
          fromCustomerName={multiLegAgreement.headAgreement.customer.name}
          fromHeadAgreementNumber={
            multiLegAgreement.headAgreement.headAgreementNumber
          }
          toCustomerName={formData.customer.name}
          toHeadAgreementNumber={formData.headAgreement.headAgreementNumber}
          targetCustomerPath={routeFactory.productAndPrice.customer.customerHeadAgreement(
            {
              customerId: formData.customer.id,
              headAgreementId: formData.headAgreement.id,
            }
          )}
        />
      ) : (
        <CopyToOtherCustomerFormMultiLeg
          onChange={onChangeFormData}
          formData={formData}
          multiLegAgreement={multiLegAgreement}
          onCancel={onRequestClose}
          onRequestSubmit={handleSubmit}
        />
      )}
      {localError && (
        <>
          <Space num={2} />
          <Indent num={2}>
            <Banner text={localError.message} variant="error" />
          </Indent>
          <Space num={2} />
        </>
      )}
    </Window>
  );
};
