import { ErrorScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { HeadAgreementPanel } from "./HeadAgreementPanel";
import { Outlet, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface CustomerScreenParams {
  customerId: string;
  headAgreementId?: string;
}

interface Props {}

export const CustomerHeadAgreementScreen: React.FC<Props> = ({ children }) => {
  const { customerId, headAgreementId } = useParams() as {
    customerId: string;
    headAgreementId: string;
  };

  if (!customerId) {
    return <ErrorScreen text={"Missing custIndex."} />;
  }

  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }

  return (
    <>
      <Helmet title="Detail HA - Customer - FPP" />
      <HeadAgreementPanel
        customerId={customerId}
        headAgreementId={headAgreementId}
      />
      <Outlet />
    </>
  );
};
