import { IndentValues } from "@/common/utils/IndentValues";
import styled from "@emotion/styled";
import { Indent, Row, Txt } from "@stenajs-webui/core";

interface NegotiationStatusCircleProps {
  StatusColor: string;
}
interface NegotiationStatusItemProps {
  text: string;
  backgroundColor: string;
}
const NegotiationStatusCircle = styled.div<NegotiationStatusCircleProps>`
  border-radius: 50%;
  height: 14px;
  width: 14px;
  background: ${({ StatusColor }) => StatusColor};
`;

const NegotiationStatusItem = ({
  backgroundColor,
  text,
}: NegotiationStatusItemProps) => {
  return (
    <Row alignItems={"center"}>
      <NegotiationStatusCircle StatusColor={backgroundColor} />
      <Indent num={IndentValues.FOUR} />
      <Txt>{text}</Txt>
      <Indent num={IndentValues.SIXTEEN} />
    </Row>
  );
};

const NegoitaionStatusTextContainer = () => {
  return (
    <Row width={400}>
      <NegotiationStatusItem backgroundColor={"#3363a6"} text={"Done"} />
      <NegotiationStatusItem backgroundColor={"#b5cfee"} text={"Started"} />
      <NegotiationStatusItem backgroundColor={"#efeff2"} text={"Todo"} />
    </Row>
  );
};

export default NegoitaionStatusTextContainer;
