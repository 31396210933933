import styled from "@emotion/styled";
import { Row } from "@stenajs-webui/core";

interface SegmentProps {
  width: string;
  backgroundColor: string;
}

const PillSegment = styled.div<SegmentProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
  height: 16px;
`;

const PillDiagram = ({
  doneStatus,
  startedStatus,
  todoStatus,
}: {
  doneStatus: number;
  startedStatus: number;
  todoStatus: number;
}) => {
  const donePercentage = doneStatus.toString().concat("%");
  return (
    <Row overflow={"hidden"} borderRadius={16} height={16} width={400}>
      <PillSegment backgroundColor={"#3363a6"} width={donePercentage} />
      <PillSegment
        backgroundColor={"#b5cfee"}
        width={startedStatus.toString().concat("%")}
      />
      <PillSegment
        backgroundColor={"#efeff2"}
        width={todoStatus.toString().concat("%")}
      />
    </Row>
  );
};
export default PillDiagram;
