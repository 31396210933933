import { Indent, Text, Txt } from "@stenajs-webui/core";
import { Icon, stenaCheck } from "@stenajs-webui/elements";
import {
  createColumnConfig,
  StandardTable,
  StandardTableColumnOptions,
  StandardTableConfig,
} from "@stenajs-webui/grid";

import * as React from "react";
import { useMemo } from "react";
import { Link } from "../../../../../../../../common/navigation/Link";
import { routeFactory } from "../../../../../../../../RouteFactory";
import {
  RouteAgreement,
  WriteHistory,
} from "../../../../../../customers/customer-details/types";
import { MultiLegAgreementRouteAgreementDelete } from "./MultiLegAgreementRouteDeletion";
import { cssColor } from "@stenajs-webui/theme";
import {
  formatGQLDateTime,
  formatGQLDateTimeRelative,
  formatGQLDateRange,
} from "@/common/formatters/DateFormatter";
import { IconSize } from "@/common/components/icons/IconSize";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  routeAgreements: Array<RouteAgreement>;
  year: number;
  multiLegAgreementId: string;
}

const BooleanCell = ({ value }: { value: boolean }) => {
  if (!value) {
    return (
      <Indent>
        <Txt>{"---"}</Txt>
      </Indent>
    );
  }

  return (
    <Indent>
      <Icon
        icon={stenaCheck}
        color={cssColor("--lhds-color-green-300")}
        size={IconSize.Small}
      />
    </Indent>
  );
};

const booleanCellOptions: StandardTableColumnOptions<
  MultiLegAgreementRouteAgreementsTableColumns,
  boolean,
  | keyof Pick<
      MultiLegAgreementRouteAgreementsTableColumns,
      | "agreementNumber"
      | "route"
      | "name"
      | "type"
      | "conditions"
      | "articles"
      | "matrix"
      | "modifiedAt"
    >
  | "actions"
> = {
  renderCell: ({ label, value }) => <BooleanCell value={value} />,
};

const config: StandardTableConfig<
  MultiLegAgreementRouteAgreementsTableColumns,
  | keyof Pick<
      MultiLegAgreementRouteAgreementsTableColumns,
      | "agreementNumber"
      | "route"
      | "name"
      | "type"
      | "conditions"
      | "articles"
      | "matrix"
      | "modifiedAt"
    >
  | "actions"
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: true,
  rowIndent: 2.6,
  columns: {
    agreementNumber: createColumnConfig((item) => item.agreementNumber, {
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
              {
                customerId: item.customerId,
                headAgreementId: item.headAgreementId,
                routeAgreementId: item.id,
                multiLegAgreementId: item.multiLegAgreementId,
                tab: "rates",
              }
            )}
          >
            {label}
          </Link>
        </Indent>
      ),
      columnLabel: "Agreement",
      minWidth: "90px",
    }),
    route: createColumnConfig((item) => item.route, {
      minWidth: "80px",
    }),
    name: createColumnConfig((item) => item.name, {
      minWidth: "200px",
    }),
    type: createColumnConfig((item) => item.type, {
      minWidth: "70px",
    }),
    conditions: createColumnConfig((item) => item.conditions, {
      ...booleanCellOptions,
      minWidth: "90px",
    }),
    articles: createColumnConfig((item) => item.articles, {
      ...booleanCellOptions,
      minWidth: "90px",
    }),
    matrix: createColumnConfig((item) => item.matrix, {
      ...booleanCellOptions,
      minWidth: "90px",
    }),
    modifiedAt: createColumnConfig((item) => item.modifiedAt, {
      renderCell: ({ label, value }) => (
        <Indent>
          <Text title={formatGQLDateTime(value)}>
            {formatGQLDateTimeRelative(value)}
          </Text>
        </Indent>
      ),
      minWidth: "140px",
      columnLabel: "Modified",
    }),
    actions: createColumnConfig((item) => item, {
      justifyContentCell: "flex-end",
      columnLabel: "",
      renderCell: ({ label, item }) => (
        <MultiLegAgreementRouteAgreementDelete
          routeAgreement={item}
          year={item.year}
        />
      ),
      minWidth: "40px",
      disableGridCell: true,
    }),
  },
  columnOrder: [
    "agreementNumber",
    "route",
    "name",
    "type",
    "conditions",
    "articles",
    "matrix",
    "modifiedAt",
    "actions",
  ],
};

interface MultiLegAgreementRouteAgreementsTableColumns extends RouteAgreement {
  id: string;
  headAgreementId: string;
  year: number;
  customerId: string;
  routePairId: string;
  agreementNumber: number;
  name: string;
  route: string;
  type: string;
  status: HeadAgreementStatusCode;
  validity: string;
  currencyCode: string;
  conditions: boolean;
  articles: boolean;
  rowVersion: string;
  matrix: boolean;
  modifiedAt: WriteHistory;
  multiLegAgreementId: string;
}

const transformMultiLegAgreementRouteAgreementsToMultiLegAgreementRouteAgreementsTableColumns =
  (
    routeAgreement: RouteAgreement,
    year: number,
    multiLegAgreementId: string
  ): MultiLegAgreementRouteAgreementsTableColumns => ({
    ...routeAgreement,
    id: routeAgreement.id,
    year,
    headAgreementId: routeAgreement.headAgreement.id,
    agreementNumber: routeAgreement.agreementNumber,
    customerId: routeAgreement.headAgreement.customer.id,
    name: routeAgreement.name,
    routePairId: routeAgreement.routes[0].routePair.id,
    route: routeAgreement.routes.map((r) => r.id).join(", "),
    type: routeAgreement.sailingTypeCodes.join("/"),
    status: routeAgreement.statusCode,
    validity: formatGQLDateRange(routeAgreement.valid),
    articles: routeAgreement.hasArticles,
    conditions: routeAgreement.hasConditions,
    currencyCode: routeAgreement.currency.code,
    matrix: routeAgreement.hasMatrix,
    rowVersion: routeAgreement.rowVersion,
    modifiedAt:
      routeAgreement.writeHistory.modifiedAt ??
      routeAgreement.writeHistory.createdAt,
    multiLegAgreementId,
  });

export const MultiLegAgreementRouteAgreementsTable: React.FC<Props> = ({
  routeAgreements,
  year,
  multiLegAgreementId,
}) => {
  const transformedRouteAgreements = useMemo(() => {
    return routeAgreements
      .map((ra) =>
        transformMultiLegAgreementRouteAgreementsToMultiLegAgreementRouteAgreementsTableColumns(
          ra,
          year,
          multiLegAgreementId
        )
      )
      .sort((a, b) => {
        if (a.route > b.route) return 1;
        if (a.route < b.route) return -1;
        if (a.route === b.route) {
          if (a.agreementNumber > b.agreementNumber) return 1;
          if (a.agreementNumber < b.agreementNumber) return -1;
        }

        return 0;
      });
  }, [routeAgreements, year, multiLegAgreementId]);

  return <StandardTable config={config} items={transformedRouteAgreements} />;
};
