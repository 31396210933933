import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { cssColor } from "@stenajs-webui/theme";
import { ReactNode } from "react";

interface InfoBoxItemProps {
  leftContent: string;
  rightContent: string | number | ReactNode;
  popoverEnabled?: boolean;
}

export const InfoBoxItem: React.FC<InfoBoxItemProps> = ({
  leftContent,
  rightContent,
  popoverEnabled,
}) => {
  return (
    <InfoPair
      leftContent={leftContent}
      leftTextColor={cssColor("--lhds-color-ui-500")}
      leftTextSize={"small"}
      leftTextWhiteSpace={"nowrap"}
      rightTextSize={"small"}
      rightTextWhiteSpace={"nowrap"}
      rightTextColor={cssColor("--lhds-color-ui-700")}
      indentAfterComponent={1.5}
      borderBottom={false}
      rightContent={rightContent}
      leftMinWidth={popoverEnabled ? "65px" : null}
      rightWidth={popoverEnabled ? "70%" : null}
      indentBetweenRows={popoverEnabled ? 1 : undefined}
    />
  );
};
