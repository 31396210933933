import { ErrorScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { CustomerDetailsPanel } from "./CustomerDetailsPanel";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface NoHeadAgreementSelectedScreenParams {
  customerId: string;
}

interface Props {}

export const CustomerDetailsScreen: React.FC<Props> = () => {
  const { customerId } = useParams() as { customerId: string };

  if (!customerId) {
    return <ErrorScreen text={"Missing custIndex."} />;
  }

  return (
    <>
      <Helmet title="Detail - Customer - FPP" />
      <CustomerDetailsPanel customerId={customerId} />
    </>
  );
};
