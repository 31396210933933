import * as React from "react";
import { ComponentProps } from "react";
import { ReactComponent as AR } from "./svg/AR.svg";
import { ReactComponent as C2 } from "./svg/C2.svg";
import { ReactComponent as C3 } from "./svg/C3.svg";
import { ReactComponent as C4 } from "./svg/C4.svg";
import { ReactComponent as CC } from "./svg/CC.svg";
import { ReactComponent as CP } from "./svg/CP.svg";
import { ReactComponent as CT } from "./svg/CT.svg";
import { ReactComponent as FP } from "./svg/FP.svg";
import { ReactComponent as FT } from "./svg/FT.svg";
import { ReactComponent as G1 } from "./svg/G1.svg";
import { ReactComponent as G2 } from "./svg/G2.svg";
import { ReactComponent as HB } from "./svg/HB.svg";
import { ReactComponent as LO } from "./svg/LO.svg";
import { ReactComponent as R2 } from "./svg/R2.svg";
import { ReactComponent as R4 } from "./svg/R4.svg";
import { ReactComponent as RE } from "./svg/RE.svg";
import { ReactComponent as RI } from "./svg/RI.svg";
import { ReactComponent as RT } from "./svg/RT.svg";
import { ReactComponent as S1 } from "./svg/S1.svg";
import { ReactComponent as S2 } from "./svg/S2.svg";
import { ReactComponent as SI } from "./svg/SI.svg";
import { ReactComponent as SU } from "./svg/SU.svg";
import { ReactComponent as T1 } from "./svg/T1.svg";
import { ReactComponent as T2 } from "./svg/T2.svg";
import { ReactComponent as T3 } from "./svg/T3.svg";
import { ReactComponent as T4 } from "./svg/T4.svg";
import { ReactComponent as T5 } from "./svg/T5.svg";
import { ReactComponent as TR, ReactComponent as U5 } from "./svg/TR.svg";
import { ReactComponent as U1 } from "./svg/U1.svg";
import { ReactComponent as U2 } from "./svg/U2.svg";
import { ReactComponent as U3 } from "./svg/U3.svg";
import { ReactComponent as U4 } from "./svg/U4.svg";
import { ReactComponent as VA } from "./svg/VA.svg";
import { ReactComponent as ALL } from "./svg/ALL.svg";

interface Props {
  vehicleTypeCode?: string | undefined;
}

export const VehicleTypeIcon: React.FC<Props> = ({ vehicleTypeCode }) => {
  const svgProps: ComponentProps<typeof AR> = {
    height: "32px",
    width: "32px",
  };

  switch (vehicleTypeCode) {
    case "AR":
      return <AR {...svgProps} />;
    case "C2":
      return <C2 {...svgProps} />;
    case "C3":
      return <C3 {...svgProps} />;
    case "C4":
      return <C4 {...svgProps} />;
    case "CC":
      return <CC {...svgProps} />;
    case "CP":
      return <CP {...svgProps} />;
    case "CT":
      return <CT {...svgProps} />;
    case "FP":
      return <FP {...svgProps} />;
    case "FT":
      return <FT {...svgProps} />;
    case "G1":
      return <G1 {...svgProps} />;
    case "G2":
      return <G2 {...svgProps} />;
    case "HB":
      return <HB {...svgProps} />;
    case "LO":
      return <LO {...svgProps} />;
    case "R2":
      return <R2 {...svgProps} />;
    case "R4":
      return <R4 {...svgProps} />;
    case "RE":
      return <RE {...svgProps} />;
    case "RI":
      return <RI {...svgProps} />;
    case "RT":
      return <RT {...svgProps} />;
    case "S1":
      return <S1 {...svgProps} />;
    case "S2":
      return <S2 {...svgProps} />;
    case "SI":
      return <SI {...svgProps} />;
    case "SU":
      return <SU {...svgProps} />;
    case "T1":
      return <T1 {...svgProps} />;
    case "T2":
      return <T2 {...svgProps} />;
    case "T3":
      return <T3 {...svgProps} />;
    case "T4":
      return <T4 {...svgProps} />;
    case "T5":
      return <T5 {...svgProps} />;
    case "TR":
      return <TR {...svgProps} />;
    case "U1":
      return <U1 {...svgProps} />;
    case "U2":
      return <U2 {...svgProps} />;
    case "U3":
      return <U3 {...svgProps} />;
    case "U4":
      return <U4 {...svgProps} />;
    case "U5":
      return <U5 {...svgProps} />;
    case "VA":
      return <VA {...svgProps} />;
    case undefined:
      return <ALL {...svgProps} />;
    default:
      return <></>;
  }
};
