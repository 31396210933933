import { Column, Row, Space, Text } from "@stenajs-webui/core";
import { Spinner } from "@stenajs-webui/elements";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { stripGqlErrorText } from "../../../../../common/string/GraphQlErrorParser";
import { BannerByErrorCode } from "../../../../statistics/common/error/BannerByErrorCode";
import { TargetSummaryStatisticsBox } from "./TargetSummaryStatisticsBox";
import { TargetSummaryStatisticsData } from "./types";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  targetSummaryStatisticsData?: TargetSummaryStatisticsData;
  loading: boolean;
  error?: ApolloError;
  isRefetching: boolean;
}

export const TargetSummaryStatistics: React.FC<Props> = ({
  targetSummaryStatisticsData,
  loading,
  error,
  isRefetching,
}) => {
  if (loading) {
    return (
      <Column>
        <Row
          indent={3}
          spacing={3.5}
          alignItems={"center"}
          justifyContent={"center"}
          display="flex"
          flexDirection="row"
        >
          <Spinner size="medium" />
        </Row>
      </Column>
    );
  }

  if (error) {
    return (
      <Column>
        <Row
          indent={2}
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
          display="flex"
          flexDirection="row"
        >
          <BannerByErrorCode
            title="Unable to show statistics"
            text={stripGqlErrorText(error.message)}
            error={error}
          />
        </Row>
      </Column>
    );
  }
  if (!targetSummaryStatisticsData) {
    return (
      <Column>
        <Row
          indent={3}
          spacing={3.5}
          alignItems={"center"}
          justifyContent={"center"}
          display="flex"
          flexDirection="row"
        >
          <Text>No statistics exist</Text>
        </Row>
      </Column>
    );
  }

  const {
    volume,
    totalGmTarget,
    totalCustomerTarget,
    noOfAccounts,
    completionRate,
    categoryData,
  } = targetSummaryStatisticsData;

  const peakAcc = categoryData.find((cd) => cd.category === "P-ACC");
  const peakUnAcc = categoryData.find((cd) => cd.category === "P-UNACC");
  const shoulderAcc = categoryData.find((cd) => cd.category === "S-ACC");
  const shoulderUnAcc = categoryData.find((cd) => cd.category === "S-UNACC");
  const offPeakAcc = categoryData.find((cd) => cd.category === "O-ACC");
  const offPeakUnAcc = categoryData.find((cd) => cd.category === "O-UNACC");

  return (
    <Row
      indent={2}
      spacing={2}
      alignItems={"center"}
      justifyContent={"space-between"}
      display="flex"
      flexDirection="row"
      borderTop={cssColor("--lhds-color-ui-300")}
    >
      <Row>
        <TargetSummaryStatisticsBox
          heading="Volume"
          leftValue={volume}
          hasPercentage={false}
          rightValue={null}
          border={`1px solid ${cssColor("--lhds-color-ui-300")}`}
          hasReachedTarget={false}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Rate"
          leftValue={totalGmTarget}
          hasPercentage={false}
          border={`1px solid ${cssColor("--lhds-color-ui-300")}`}
          rightValue={totalCustomerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <Space num={1.5} />
        <TargetSummaryStatisticsBox
          heading="Peak - Acc"
          leftValue={peakAcc?.gmTarget}
          hasPercentage={false}
          rightValue={peakAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Peak - Unacc"
          leftValue={peakUnAcc?.gmTarget}
          hasPercentage={false}
          rightValue={peakUnAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Shoulder - Acc"
          leftValue={shoulderAcc?.gmTarget}
          hasPercentage={false}
          rightValue={shoulderAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Shoulder - Unacc"
          leftValue={shoulderUnAcc?.gmTarget}
          hasPercentage={false}
          rightValue={shoulderUnAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Off peak - Acc"
          leftValue={offPeakAcc?.gmTarget}
          hasPercentage={false}
          rightValue={offPeakAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Off peak - Unacc"
          leftValue={offPeakUnAcc?.gmTarget}
          hasPercentage={false}
          rightValue={offPeakUnAcc?.customerTarget}
          hasReachedTarget={true}
          loading={isRefetching}
        />
      </Row>
      <Row>
        <TargetSummaryStatisticsBox
          heading="Customers"
          leftValue={noOfAccounts}
          hasPercentage={false}
          rightValue={null}
          hasReachedTarget={false}
          isNotFormatted={true}
          loading={isRefetching}
        />
        <TargetSummaryStatisticsBox
          heading="Completion rate"
          leftValue={completionRate}
          hasPercentage={true}
          rightValue={null}
          hasReachedTarget={false}
          loading={isRefetching}
        />
      </Row>
    </Row>
  );
};
