import { Box } from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { InfoBoxItem } from "../InfoBoxItem";
import { MultiLegRouteAgreementBox } from "../types";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";
interface Props {
  multiLegRouteAgreement: MultiLegRouteAgreementBox;
  actionMenuOpen: boolean;
  openActionMenu: () => void;
  closeActionMenu: () => void;
}

export const MultiLegRouteAgreementInfoPairPopover: React.FC<Props> = ({
  multiLegRouteAgreement,
  actionMenuOpen,
  openActionMenu,
  closeActionMenu,
}) => {
  return (
    <Popover
      placement={"right"}
      animation={false}
      visible={actionMenuOpen}
      disablePadding
      onClickOutside={closeActionMenu}
      appendTo={document.body}
      content={
        <Box indent={2} spacing={1}>
          <InfoBoxItem
            leftContent={"Name"}
            rightContent={multiLegRouteAgreement.name}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Cur."}
            rightContent={multiLegRouteAgreement.currency.code}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"No."}
            rightContent={multiLegRouteAgreement.agreementNumber}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created"}
            rightContent={
              formatDateStringWithSwedishTimeZone({
                dateString:
                  multiLegRouteAgreement.writeHistory?.createdAt?.isoString,
                options: {
                  dateStyle: "short",
                  timeStyle: "short",
                },
              }) ?? ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created by"}
            rightContent={
              multiLegRouteAgreement.writeHistory?.createdBy?.name || ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified"}
            rightContent={
              (multiLegRouteAgreement.writeHistory?.modifiedAt?.isoString &&
                formatDateStringWithSwedishTimeZone({
                  dateString:
                    multiLegRouteAgreement.writeHistory?.modifiedAt?.isoString,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                })) ??
              ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified by"}
            rightContent={
              multiLegRouteAgreement.writeHistory?.modifiedBy?.name || ""
            }
            popoverEnabled
          />
        </Box>
      }
    >
      <Box width={16}>
        <FlatButton
          leftIcon={stenaInfoCircle}
          onClick={actionMenuOpen ? closeActionMenu : openActionMenu}
        />
      </Box>
    </Popover>
  );
};
