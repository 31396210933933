import { SpacingValues } from "@/common/utils/SpacingValues";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Customer } from "../types";
import { RebateAgreementDocuments } from "./RebateAgreementDocuments";
import {
  RebateAgreementDocumentsQuery,
  RebateAgreementDocumentsQueryVariables,
} from "@/gql/graphql";
import { rebateAgreementDocumentsFragment } from "../fragments/RebateAgreementDocumentFragments";

const query = gql`
  ${rebateAgreementDocumentsFragment}
  query RebateAgreementDocuments($customerNo: ID!) {
    productPrice {
      customers {
        byId(id: $customerNo) {
          ...RebateAgreementDocuments
        }
      }
    }
  }
`;

export const RebateAgreementDocumentsScreen: React.FC = () => {
  const { customerId: customerNo } = useParams() as { customerId: string };
  const [customer, setCustomer] = useState<Customer | null>(null);

  const { error, loading } = useQuery<
    RebateAgreementDocumentsQuery,
    RebateAgreementDocumentsQueryVariables
  >(query, {
    fetchPolicy: "cache-and-network",
    variables: {
      customerNo,
    },
    onCompleted(data) {
      setCustomer(data.productPrice.customers.byId);
    },
  });

  if (loading) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!customerNo) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={"Missing customerId."} />
      </Spacing>
    );
  }

  return (
    <>
      <Helmet title="Documents - Customer - FPP" />
      {customer && <RebateAgreementDocuments customer={customer} />}
    </>
  );
};
