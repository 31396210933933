import { SpaceValues } from "@/common/utils/SpaceValues";
import { useMsal } from "@azure/msal-react";
import { Row, Space } from "@stenajs-webui/core";
import { Link, Spinner } from "@stenajs-webui/elements";
import React from "react";
import { AgreementDocumentsTableItem } from "../models";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { useDownloadPdf } from "@/features/product-and-price/head-agreement/common/hooks/UseDownloadPdf";

export interface PreviewPdf {
  item: AgreementDocumentsTableItem;
  error: FreightPricingPortalError | undefined;
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >;
}

export const PreviewPdfLink: React.FC<PreviewPdf> = ({
  item,
  error,
  setError,
}) => {
  const { instance } = useMsal();
  const { handleDownload, loading } = useDownloadPdf(instance, setError);
  const onClick = () => {
    const customerNumber = String(item.customerNumber);
    handleDownload(
      `/download/agreement-document?customerNumber=${customerNumber}&documentId=${item.id}`,
      item.fileName,
      true
    );
  };

  return (
    <Row alignItems={"center"} indent>
      <Link onClick={onClick}>{item.fileName}</Link>
      <Space num={loading ? SpaceValues.SIXTEEN : SpaceValues.THIRTYSIX} />
      {loading && <Spinner size="tiny" />}
    </Row>
  );
};
