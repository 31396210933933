import { SpacingValues } from "@/common/utils/SpacingValues";
import { Spacing } from "@stenajs-webui/core";
import React from "react";
import { transformAuditTrackStringToDisplayableJsx } from "./transformers/TransformAuditTrackText";

interface Props {
  text: string;
}

export const PriceCalcResultPaneAuditTrackErrorTab: React.FC<Props> = ({
  text,
}) => {
  return (
    <Spacing num={SpacingValues.TWENTYFOUR}>
      {transformAuditTrackStringToDisplayableJsx(text)}
    </Spacing>
  );
};
