import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setNslhSearchInvoicesByFilterData } from "../redux/reducers";
import _ from "lodash";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import {
  SearchNslhInvoiceByCustomerDateRangeQuery,
  SearchNslhInvoiceByCustomerDateRangeQueryVariables,
} from "@/gql/graphql";

export const nslhInvoicesByFilterQuery = gql`
  fragment SearchNslhInvoiceByFilter on NslhInvoiceInfo {
    id
    customer {
      id
      custNo
      custIndex
    }
    noShowLateTypeCodes
    lateHandlingDate
    nslhInvoice {
      ...SearchNslhInvoiceByCustomerDateRange
    }
  }
  fragment SearchNslhInvoiceByCustomerDateRange on NslhInvoice {
    id
    invoiceNo
    invoiceCurrencyCode
    netAmount
  }
  query SearchNslhInvoiceByCustomerDateRange(
    $input: NslhInvoiceByFilterInput!
  ) {
    productPrice {
      noShowLateHandling {
        nslhInvoice {
          byFilter(input: $input) {
            ...SearchNslhInvoiceByFilter
          }
        }
      }
    }
  }
`;

export const useNslhInvoiceQueryByFilter = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState<FreightPricingPortalError | undefined>(
    undefined
  );

  const [fetchInvoicesByCustomerNumberDateRange, { loading }] = useLazyQuery<
    SearchNslhInvoiceByCustomerDateRangeQuery,
    SearchNslhInvoiceByCustomerDateRangeQueryVariables
  >(nslhInvoicesByFilterQuery, {
    fetchPolicy: "cache-and-network",
    onError: (err) => {
      if (err) {
        const fppError = transformApolloErrorToFreightPricingPortalErrors(err);
        if (fppError.length > 0) {
          setError(transformApolloErrorToFreightPricingPortalErrors(err)[0]);
        }
        return;
      }
    },

    onCompleted: (data) => {
      const invoiceSorted = _.orderBy(
        data.productPrice.noShowLateHandling.nslhInvoice.byFilter,
        ["lateHandlingDate", "invoiceNo"],
        ["desc", "asc"]
      );
      dispatch(setNslhSearchInvoicesByFilterData(invoiceSorted));
    },
  });

  return {
    fetchInvoicesByCustomerNumberDateRange,
    loading,
    error,
    setError,
  };
};
