import { FetchPriceCalcFragment } from "@/gql/graphql";

export const calculateSeaFreightVatRate = (
  priceClc: FetchPriceCalcFragment
) => {
  // 1. Calculate VAT for SEA freight: Total VAT - SUM(all additional vat) = Sea freight VAT
  // 2. seaFreightPrice / Sea freight VAT = SeaFreight VAT rate
  const seaFreightVatAmount =
    priceClc.totalVat - calculateAdditionalRecordsVatAmount(priceClc);
  return (seaFreightVatAmount / priceClc.seaFreightPrice) * 100;
};

export const calculateSeaFreightVatAmount = (
  priceClc: FetchPriceCalcFragment
) => {
  return priceClc.totalVat - calculateAdditionalRecordsVatAmount(priceClc);
};

export const calculateSeaFreightIncludingVatAmount = (
  priceClc: FetchPriceCalcFragment
) => {
  return priceClc.seaFreightPrice + calculateSeaFreightVatAmount(priceClc);
};

export const calculateAdditionalRecordsVatAmount = (
  priceClc: FetchPriceCalcFragment
) => {
  let totalVat = 0;
  if (priceClc.ancillaryRecords && priceClc.ancillaryRecords.length > 0) {
    for (let i = 0; i < priceClc.ancillaryRecords.length; i++) {
      totalVat += priceClc.ancillaryRecords[i].ancillaryVatAmount ?? 0;
    }
  }
  if (priceClc.surchargeRecords && priceClc.surchargeRecords.length > 0) {
    for (let i = 0; i < priceClc.surchargeRecords.length; i++) {
      totalVat += priceClc.surchargeRecords[i].surchargeVatAmount ?? 0;
    }
  }
  return totalVat;
};
