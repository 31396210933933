import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import {
  createFppSearchFilterReducer,
  SearchFilterState,
} from "@/features/search-filter/redux/reducer";
import { NslhBookingDataFragment } from "@/gql/graphql";

export type CheckBoxSelectionState = Record<string, Record<string, boolean>>;

export type NoShowLateHandlingBookingsData =
  | NslhBookingDataFragment[]
  | undefined;

export interface NoShowLateHandlingBookingAndCheckboxState {
  checkBoxSelectionState: CheckBoxSelectionState;
  noShowLateHandlingHandleBookingsData: NoShowLateHandlingBookingsData;
}

const initialNoShowLateHandlingBookingAndCheckboxState: NoShowLateHandlingBookingAndCheckboxState =
  {
    checkBoxSelectionState: {},
    noShowLateHandlingHandleBookingsData: undefined,
  };

export const noShowLateHandlingHandleBookingsReducerId =
  "noShowLateHandlingHandleBookingsReducerId";

interface SetCheckedBookingPayload {
  customerNo: string;
  bookingNo: number;
}

interface SetCheckedCustomerRowPayload {
  customerNo: string;
  bookingNos: Array<number>;
}

export const noShowLateHandlingHandleBookingsSlice = createSlice({
  name: noShowLateHandlingHandleBookingsReducerId,
  initialState: initialNoShowLateHandlingBookingAndCheckboxState,
  reducers: {
    toggleCheckedBookingStateForCustomer(
      state,
      {
        payload: { bookingNo, customerNo },
      }: PayloadAction<SetCheckedBookingPayload>
    ) {
      if (!state.checkBoxSelectionState[customerNo]) {
        state.checkBoxSelectionState[customerNo] = {};
      }
      const previouslyCheckedState =
        state.checkBoxSelectionState[customerNo][bookingNo];

      state.checkBoxSelectionState[customerNo][bookingNo] =
        !previouslyCheckedState;
    },
    toggleCheckedCustomerRowStateForCustomer(
      state,
      {
        payload: { bookingNos, customerNo },
      }: PayloadAction<SetCheckedCustomerRowPayload>
    ) {
      if (!state.checkBoxSelectionState[customerNo]) {
        state.checkBoxSelectionState[customerNo] = {};
      }

      const numberOfCurrentlySelectedBookings = bookingNos.filter(
        (bn) => state.checkBoxSelectionState[customerNo][bn]
      ).length;

      if (numberOfCurrentlySelectedBookings > 0) {
        delete state.checkBoxSelectionState[customerNo];
      } else {
        bookingNos.forEach(
          (bn) => (state.checkBoxSelectionState[customerNo][bn] = true)
        );
      }
    },
    checkAllBookingsForCustomer(
      state,
      {
        payload: { bookingNos, customerNo },
      }: PayloadAction<SetCheckedCustomerRowPayload>
    ) {
      if (!state.checkBoxSelectionState[customerNo]) {
        state.checkBoxSelectionState[customerNo] = {};
      }

      bookingNos.forEach(
        (bn) => (state.checkBoxSelectionState[customerNo][bn] = true)
      );
    },
    clearSelectionState(state) {
      state.checkBoxSelectionState = {};
    },
    setNoShowLateHandlingBookingsData(
      state,
      {
        payload: noShowLateHandlingBookingsData,
      }: PayloadAction<NoShowLateHandlingBookingsData>
    ) {
      state.noShowLateHandlingHandleBookingsData =
        noShowLateHandlingBookingsData;
    },
  },
});

export interface NoShowLateHandlingHandleBookingsState {
  filter: SearchFilterState;
}

export const noShowLateHandlingHandleBookingsReducer =
  combineReducers<NoShowLateHandlingHandleBookingsState>({
    filter: createFppSearchFilterReducer("noShowLateHandlingHandleBookings"),
  });

export const {
  toggleCheckedBookingStateForCustomer,
  toggleCheckedCustomerRowStateForCustomer,
  checkAllBookingsForCustomer,
  clearSelectionState,
  setNoShowLateHandlingBookingsData,
} = noShowLateHandlingHandleBookingsSlice.actions;

export const noShowLateHandlingHandleBookingsCheckboxSelectionReducer =
  noShowLateHandlingHandleBookingsSlice.reducer;
