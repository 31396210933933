import React from "react";
import { Indent, Text } from "@stenajs-webui/core";
import { formatDate } from "@/common/formatters/DateFormatter";

export const createHandleBookingsStandardTableCell = (
  rowValue: string | number | undefined | null,
  isDateField: boolean = false
) => {
  if (!rowValue) {
    return (
      <Indent>
        <Text>{""}</Text>
      </Indent>
    );
  }

  if (isDateField) {
    return (
      <Indent>
        <Text>{formatDate(rowValue as string)}</Text>
      </Indent>
    );
  }

  return (
    <Indent>
      <Text>{rowValue}</Text>
    </Indent>
  );
};
