import { ApolloError } from "apollo-boost";

export const getApolloErrorMessage = (error: ApolloError): string =>
  stripGqlErrorText(error.message);

export const stripGqlErrorText = (s: string): string =>
  s.replace("GraphQL error: ", "");

export const getGQLErrors = (error?: ApolloError): Error | undefined => {
  if (!error) {
    return undefined;
  }
  return new Error(
    stripGqlErrorText(error.graphQLErrors.flatMap((e) => e.message).join("\n"))
  );
};
