import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  StatisticsSearchRoutePairListVolumeAndTargetByFilterOptions,
  UseRoutePairListVolumeAndTargetQuery,
  UseRoutePairListVolumeAndTargetQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment VolumeAndTargetForSelectedRoutePair on StatisticsByRoutePairListVolumeAndTargetItem {
    routePair {
      id
    }
    volumeAndTargetForSelected {
      averageNegotiationRate
      averageTarget
      expectedVolume
      lastYearVolume
      volume
      avgMeterPrice
      avgMeterPriceDiffInPercent
    }
  }
  query UseRoutePairListVolumeAndTarget(
    $filterOptions: StatisticsSearchRoutePairListVolumeAndTargetByFilterOptions!
  ) {
    statistics {
      routePairList {
        volumeAndTarget(filterOptions: $filterOptions) {
          items {
            ...VolumeAndTargetForSelectedRoutePair
          }
        }
      }
    }
  }
`;

export const useRoutePairListVolumeAndTargetQuery = (
  filterOptions: StatisticsSearchRoutePairListVolumeAndTargetByFilterOptions
) => {
  const { error, loading, data, refetch } = useQuery<
    UseRoutePairListVolumeAndTargetQuery,
    UseRoutePairListVolumeAndTargetQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    skip: filterOptions.routePairIds.length === 0 || filterOptions.year == null,
  });

  return {
    refetch,
    error,
    loading,
    volumeAndTarget: data?.statistics.routePairList.volumeAndTarget.items,
  };
};
