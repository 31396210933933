import { Row, Space } from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { agreementArticlesActions } from "../../redux/reducer";
import { AgreementArticlesCustomer } from "../../types";
import { AgreementArticlesTableColumns } from "../table/config/RowsAndColumnsConfig";
import { BulkUpdateAgreementArticlesForm } from "./BulkUpdateAgreementArticlesForm";
import { emptyBulkUpdateAgreementArticlesFormModel } from "./models/BulkUpdateAgreementArticlesFormModel";
import { raiseNewPricesUsingSelectionThunk } from "./thunks/RaiseNewPricesUsingSelectionThunk";
import { applyChangesDisabled } from "./validators/validators";

interface Props {
  customer: AgreementArticlesCustomer;
  tableContext: TableContext<AgreementArticlesTableColumns>;
  numItemsSelected: number;
}

export const BulkUpdateAgreementArticlesToolbar: React.FC<Props> = ({
  customer,
  tableContext,
  numItemsSelected,
}) => {
  const dispatch = useDispatch();
  const [formModel, setFormModel] = useState(
    emptyBulkUpdateAgreementArticlesFormModel
  );

  const clearForm = () => {
    dispatch(tableContext.actions.clearSelection());
    setFormModel(emptyBulkUpdateAgreementArticlesFormModel);
  };

  const onClickApply = async () => {
    dispatch(agreementArticlesActions.setBulkUpdateLoading(true));
    dispatch(raiseNewPricesUsingSelectionThunk(formModel, tableContext));
  };

  return (
    <SelectedItemsActionsPanel
      numItemsSelected={numItemsSelected}
      afterLabelContent={<FlatButton label={"Clear"} onClick={clearForm} />}
      rightContent={
        <Row alignItems={"center"}>
          <BulkUpdateAgreementArticlesForm
            value={formModel}
            customer={customer}
            onValueChange={setFormModel}
          />
          <Space num={3} />
          <PrimaryButton
            label={"Apply changes"}
            disabled={applyChangesDisabled(formModel)}
            onClick={() => onClickApply()}
          />
        </Row>
      }
    />
  );
};
