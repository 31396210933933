import { HeadAgreementByIdFragment } from "@/gql/graphql";
import { EditMultiLegAgreementFormModel } from "../../../common/components/edit-and-create-multi-leg-agreement-form/model/EditAndCreateMultiLegAgreementFormModel";

export const createEditMultiLegFormModel = (
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"]
): EditMultiLegAgreementFormModel => {
  return {
    currencyCode: multiLegAgreement.currency.code,
    name: multiLegAgreement.name,
    multiLegAgreementId: multiLegAgreement.id,
    rowVersion: multiLegAgreement.rowVersion,
    valid: {
      endDate: multiLegAgreement.valid?.end?.isoString
        ? new Date(multiLegAgreement.valid.end?.isoString)
        : undefined,
      startDate: multiLegAgreement.valid?.start?.isoString
        ? new Date(multiLegAgreement.valid.start?.isoString)
        : undefined,
    },
  };
};
