import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateAgreementArticlesInput,
  UpdateAgreementRouteArticlePriceMutation,
  UpdateAgreementRouteArticlePriceMutationVariables,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import { agreementArticlesRouteAgreementFragment } from "../../fragments/AgreementArticlesFragment";

const mutation = gql`
  ${errorFragment}
  ${agreementArticlesRouteAgreementFragment}
  mutation UpdateAgreementRouteArticlePrice(
    $input: UpdateAgreementArticlesInput!
  ) {
    productPrice {
      routeAgreement {
        updateAgreementArticles(input: $input) {
          __typename
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateAgreementArticlesSuccessResult {
            routeAgreement {
              ...AgreementArticlesRouteAgreement
            }
          }
        }
      }
    }
  }
`;

export const useUpdateAgreementRouteArticlePriceMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    UpdateAgreementRouteArticlePriceMutation,
    UpdateAgreementRouteArticlePriceMutationVariables
  >(mutation);

  const updateAgreementRouteArticlePrice = useCallback(
    (input: UpdateAgreementArticlesInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );

  return {
    updateAgreementRouteArticlePrice,
    loading,
    error,
  };
};
