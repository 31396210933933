import { Indent, Space } from "@stenajs-webui/core";
import { Children, PropsWithChildren, useState } from "react";
import { WizardStepHeader } from "./components/WizardStepHeader";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ErrorWithPath } from "@/common/error/BannerErrorWithLinks";
import React from "react";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";

export type StepConfiguration = {
  title: string;
  validate: () => Promise<boolean>;
};

interface WizardComponentProps {
  stepConfigurations: StepConfiguration[];
  errorMessages: ErrorWithPath[] | undefined;
  isNextAvailable: boolean;
  sectionalRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorWithPath[]>>;
  completeWizard: () => void;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WizardComponent = ({
  children,
  stepConfigurations,
  errorMessages,
  isNextAvailable,
  sectionalRouteAgreements,
  setErrorMessages,
  completeWizard,
  setIsNextAvailable,
}: PropsWithChildren<WizardComponentProps>) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isWizardLoading, setIsWizardLoading] = useState(false);

  const moveToNextStep = async () => {
    setErrorMessages([]);
    const nextStep = currentStepIndex + 1;
    if (nextStep < stepConfigurations.length) {
      setIsWizardLoading(true);
      const passedValidation = await stepConfigurations
        .at(currentStepIndex)
        ?.validate();
      setIsWizardLoading(false);
      if (passedValidation) {
        setCurrentStepIndex(nextStep);
      }
    }
  };

  const moveToPreviousStep = () => {
    const previousStep = currentStepIndex - 1;
    if (previousStep >= 0) {
      setIsNextAvailable(true);
      setCurrentStepIndex(previousStep);
    }
  };
  const isLastStep = stepConfigurations.length === currentStepIndex + 1;
  return (
    <>
      <Indent num={IndentValues.TWENTYFOUR}>
        <WizardStepHeader
          sectionalRouteAgreements={sectionalRouteAgreements}
          isNextAvailable={isNextAvailable}
          steps={stepConfigurations}
          moveToNextStep={isLastStep ? completeWizard : moveToNextStep}
          moveToPreviousStep={moveToPreviousStep}
          currentStep={stepConfigurations[currentStepIndex]}
          currentIndex={currentStepIndex}
          isWizardLoading={isWizardLoading}
          errorMessages={errorMessages}
          isLastStep={isLastStep}
        />
        <Space num={SpaceValues.TWENTYFOUR} />
        {Children.toArray(children)[currentStepIndex]}
      </Indent>
    </>
  );
};
