import { PathMatch, useLocation, useMatch } from "react-router-dom";

export interface UseAllowedGlobalSearchRoutes {
  isAdminGFTSearchRoute: PathMatch | null;
  isRoutesTargetsRouteSearchRoute: PathMatch | null;
}

export const useAllowedGlobalSearchRoutes = () => {
  const location = useLocation();
  const routeCode = location.pathname.split("/routes-targets/").pop();

  // Admin Group Freight Targets
  const adminGFTHiddenSearch = "/admin/*";
  const isAdminGFTSearchRoute = useMatch(adminGFTHiddenSearch);

  // Route Targets Route
  const routesTargetsRouteHiddenSearch = `/routes-targets/${routeCode}`;
  const isRoutesTargetsRouteSearchRoute = useMatch(
    routesTargetsRouteHiddenSearch
  );

  // Target Overview Route
  const targetOverviewRouteHiddenSearch = `/routes-targets`;
  const isTargetOverviewRouteSearchRoute = useMatch(
    targetOverviewRouteHiddenSearch
  );

  // Statistics view
  const statisticsHiddenSearch = "/statistics/*";
  const isStatisticsSearchRoute = useMatch(statisticsHiddenSearch);

  // No Show Late Handing
  const NslhHiddenSearch = "/nslh/*";
  const isNslhRoute = useMatch(NslhHiddenSearch);

  const globalSearchShouldBeVisible =
    !isAdminGFTSearchRoute &&
    !isRoutesTargetsRouteSearchRoute &&
    !isTargetOverviewRouteSearchRoute &&
    !isStatisticsSearchRoute &&
    !isNslhRoute;

  return {
    globalSearchShouldBeVisible,
  };
};
