import { StoreState } from "../../../config/redux/RootReducer";
import { RateSheetTableRowsState, RateSheetTableState } from "./reducer";
import { RecordObjectState } from "@stenajs-webui/redux";

export const rateSheetTableSelectors = {
  getTableState: (state: StoreState): RecordObjectState<RateSheetTableState> =>
    state.rateSheet.table,
  getTableRowsStateForRouteAgreement: (
    state: StoreState,
    routeAgreementId: string
  ): RateSheetTableRowsState | undefined =>
    state.rateSheet.table[routeAgreementId]?.rows,

  getTableStateForRouteAgreement: (
    state: StoreState,
    routeAgreementId: string
  ): RateSheetTableState | undefined => state.rateSheet.table[routeAgreementId],
};
