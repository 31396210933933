import { gql } from "@apollo/client";

export const errorFragment = gql`
  fragment ErrorFragment on ErrorResult {
    errors {
      message
      path
    }
  }
`;
