import {
  PriceCalculatorMultiLegResultQuery,
  PriceCalculatorMultiLegResultQueryVariables,
} from "@/gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";
import { fetchPriceCalcFragment } from "../PriceCalculatorFormFetcher";

export const useMultiLegPriceCalculation = () => {
  const queryMultiLeg = gql`
    ${fetchPriceCalcFragment}
    query PriceCalculatorMultiLegResult($input: PriceCalcInput!) {
      priceCalc {
        byMultiLegInput(variables: $input) {
          __typename
          ... on PriceCalcError {
            error
            errorType
          }
          ... on PriceCalcMultipleList {
            list {
              ...FetchPriceCalc
            }
          }
        }
      }
    }
  `;
  const [calculateMultiLegPrice, { loading, data, error }] = useLazyQuery<
    PriceCalculatorMultiLegResultQuery,
    PriceCalculatorMultiLegResultQueryVariables
  >(queryMultiLeg, {
    fetchPolicy: "cache-and-network",
  });

  return {
    calculateMultiLegPrice,
    loading,
    data,
    error,
  };
};
