export enum RouteTargetBulkEdit {
  BulkEditEvent = "Route target bulk edit",
  SelectMethodAction = "SelectMethod",
  DismissAction = "Dismiss",
  ClickedApplyAction = "ClickedApply",
  Overwrite = "Overwrite",
  GrossPriceWarning = "GrossPriceWarning",
  GrossPrice = "Gross price",
  SetTarget = "Set target",
  YES = "YES",
}
