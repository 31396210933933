import {
  PriceCalculatorMultiLegResultQuery,
  PriceCalculatorMultipleResultQuery,
  PriceCalculatorResultQuery,
} from "@/gql/graphql";

export const getPriceCalculatorData = (
  value: PriceCalculatorResultQuery["priceCalc"]["byInput"]
) => {
  if (value.__typename === "PriceCalc") {
    return value;
  }
  return null;
};

export const getPriceCalculatorError = (
  value: PriceCalculatorResultQuery["priceCalc"]["byInput"]
) => {
  if (value.__typename === "PriceCalcError") {
    return value;
  }
  return null;
};
export const getPriceCalculatorMultipleData = (
  value: PriceCalculatorMultipleResultQuery["priceCalc"]["byMultipleInput"]
) => {
  if (value.__typename === "PriceCalcMultipleList") {
    return value;
  }
  return null;
};
export const getPriceCalculatorMultipleError = (
  value: PriceCalculatorMultipleResultQuery["priceCalc"]["byMultipleInput"]
) => {
  if (value.__typename === "PriceCalcError") {
    return value;
  }
  return null;
};
export const getPriceCalculatorMultiLegData = (
  value: PriceCalculatorMultiLegResultQuery["priceCalc"]["byMultiLegInput"]
) => {
  if (value.__typename === "PriceCalcMultipleList") {
    return value;
  }
  return null;
};
export const getPriceCalculatorMultiLegError = (
  value: PriceCalculatorMultiLegResultQuery["priceCalc"]["byMultiLegInput"]
) => {
  if (value.__typename === "PriceCalcError") {
    return value;
  }
  return null;
};
