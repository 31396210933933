import { parseISO } from "date-fns";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { MultiLegAgreement } from "../../../types";
import { HeadAgreementStatusCode } from "@/gql/graphql";

export const breakDateIsRequired = (
  agreement: Partial<RouteAgreement> | Partial<MultiLegAgreement>
): boolean => {
  if (agreement.valid?.start && agreement.valid.end) {
    return (
      agreement.statusCode === HeadAgreementStatusCode.Active &&
      new Date() > parseISO(agreement.valid.start.isoString) &&
      new Date() < parseISO(agreement.valid.end.isoString)
    );
  } else {
    return false;
  }
};
