import { AppThunk } from "../../../../../../../config/redux/RootReducer";
import { apolloClient } from "../../../../../../../apollo-client/ApolloClient";
import { gql } from "apollo-boost";
import { routeCustomerTargetActions } from "../actions";
import { transformRoutPairTargetQueryToAllCustomerTargetsTableRow } from "../../../../transformers/TargetRoutePairTransformers";
import { customerTargetActions } from "../reducers";
import {
  RoutePairTargetQuery,
  RoutePairTargetQueryVariables,
} from "@/gql/graphql";

export const fetchRoutePairCustomerTargetFragment = gql`
  fragment FetchRoutePairCustomerTarget on TargetRoutePair {
    id
    year
    routePairCode
    customerCategory {
      code
      description
    }
    customerName
    seller
    custNo
    salesRegion {
      id
      code
      shortName
    }
    unitCategoryCode
    statNo
    statNetworkVolume
    statRouteVolume
    statRouteRowVolume
    custNetworkVolume
    custRouteVolume
    custRouteRowVolume
    avgGrossMeterPrice
    avgNetMeterPrice
    targetInPercent
    sailingCategory {
      id
      code
      name
    }
    customerCountry {
      id
      code
      name
    }
  }
`;

const routePairTargetQuery = gql`
  ${fetchRoutePairCustomerTargetFragment}
  query RoutePairTarget($filter: TargetRoutePairInput!) {
    productPrice {
      targetRoutePair {
        byFilter(filter: $filter) {
          items {
            ...FetchRoutePairCustomerTarget
          }
        }
      }
    }
  }
`;

export const fetchCustomerRouteTargetsByFilter =
  (variablesFilter: RoutePairTargetQueryVariables): AppThunk =>
  async (dispatch) => {
    dispatch(
      routeCustomerTargetActions.setRouteCustomerTargetState({ loading: true })
    );
    try {
      const { data, errors } = await apolloClient.query<
        RoutePairTargetQuery,
        RoutePairTargetQueryVariables
      >({
        query: routePairTargetQuery,
        variables: {
          filter: variablesFilter.filter,
        },
        fetchPolicy: "network-only",
      });

      if (errors && errors.length) {
        dispatch(
          routeCustomerTargetActions.setRouteCustomerTargetState({
            bannerState: {
              headerText: "Something went wrong please try again.",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      } else {
        const routeCustomerTargetData =
          data.productPrice.targetRoutePair.byFilter.items;

        if (routeCustomerTargetData) {
          const allCustomerTargetTableRows = routeCustomerTargetData.map(
            transformRoutPairTargetQueryToAllCustomerTargetsTableRow
          );
          dispatch(customerTargetActions.setState(allCustomerTargetTableRows));
        }
      }
    } catch (error) {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          bannerState: {
            headerText: "Something went wrong please try again.",
            items: [{ text: error.message }],
          },
        })
      );
    } finally {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          loading: false,
        })
      );
    }
  };
