import { RoutesFormInput } from "@/gql/graphql";
import { EditRouteAgreementFormModel } from "../../../common/components/models/EditRouteAgreementFormModel";
import {
  arrayIsEqual,
  getModifiedOrNull,
  getRequiredValidDatesInputOrNull,
} from "../../utils/InputTransformerHelpers";

export const transformRoutesFormToInput = (
  formModel: EditRouteAgreementFormModel,
  persisted: EditRouteAgreementFormModel
): RoutesFormInput => {
  if (!formModel.currencyId) {
    throw new Error("Currency is required.");
  }

  if (!formModel.valid.startDate) {
    throw new Error("Valid start date is required.");
  }

  if (!formModel.valid.endDate) {
    throw new Error("Valid end date is required.");
  }
  return {
    routeIds: getModifiedOrNull(
      formModel.routeIds,
      persisted.routeIds,
      arrayIsEqual
    ),
    offPeak: getModifiedOrNull(formModel.offPeak, persisted.offPeak),
    peak: getModifiedOrNull(formModel.peak, persisted.peak),
    shoulder: getModifiedOrNull(formModel.shoulder, persisted.shoulder),
    special: getModifiedOrNull(formModel.special, persisted.special),
    valid: getRequiredValidDatesInputOrNull(formModel.valid, persisted.valid),
    name: getModifiedOrNull(formModel.name, persisted.name),
    type: getModifiedOrNull(formModel.type, persisted.type),
    externalDescription: getModifiedOrNull(
      formModel.externalDescription,
      persisted.externalDescription
    ),
    isSalesReady: getModifiedOrNull(
      formModel.isSalesReady,
      persisted.isSalesReady
    ),
    currencyId: getModifiedOrNull<string>(
      formModel.currencyId,
      persisted.currencyId
    ),
  };
};
