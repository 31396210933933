import { Space, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  stenaBusinessAgreement,
} from "@stenajs-webui/elements";
import * as React from "react";
import { HeadAgreementStatusCodeChip } from "../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { InfoBoxItem } from "../InfoBoxItem";
import { RouteAgreementBox } from "../types";
import { RouteAgreementInfoPairPopover } from "./RouteAgreementInfoPairPopover";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";

interface Props {
  routeAgreement: RouteAgreementBox;
}

export const RouteAgreementInfoPairBox: React.FC<Props> = ({
  routeAgreement,
}) => {
  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Route agreement"}
        leftIcon={stenaBusinessAgreement}
        contentRight={
          <RouteAgreementInfoPairPopover
            routeAgreement={routeAgreement}
            actionMenuOpen={actionMenuOpen}
            openActionMenu={openActionMenu}
            closeActionMenu={closeActionMenu}
          />
        }
      />
      <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
        <HeadAgreementStatusCodeChip
          statusCode={routeAgreement.statusCode}
          size={"small"}
          key={"head-agreement-infobox-key-0"}
        />
        <Space num={1} />
        <InfoBoxItem
          leftContent={"Validity"}
          rightContent={formatDateStringRangeWithSwedishTimeZone({
            dateStringFrom: routeAgreement.valid.start?.isoString,
            dateStringTo: routeAgreement.valid.end?.isoString,
          })}
        />
        <InfoBoxItem
          leftContent={"Type"}
          rightContent={routeAgreement.sailingTypeCodes.join(", ")}
        />
        <InfoBoxItem
          leftContent={"Route"}
          rightContent={
            routeAgreement.agreementNumber && routeAgreement.routeDescription
          }
        />
      </CardBody>
    </Card>
  );
};
