import * as React from "react";
import { useEffect } from "react";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { RouteAgreementDetails } from "./RouteAgreementDetails";
import { useDispatch, useSelector } from "react-redux";
import { routeAgreementDetailsSelector } from "../redux";
import { populateEditRouteAgreementState } from "../thunks/RouteAgreementDetailsStatePopulator";
import { statBoxesRedux } from "../../../../customer-route-statistic-boxes/redux";

interface Props {
  customerId: string;
  headAgreementId: string;
  routePairId: string;
  routeAgreementId: string;
}

export const RouteAgreementDetailsFetcher: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { routeAgreementId, headAgreementId } = props;

  const {
    routeAgreement,
    progress: { loading, error },
  } = useSelector(routeAgreementDetailsSelector);

  useEffect(() => {
    dispatch(populateEditRouteAgreementState(routeAgreementId));
    dispatch(statBoxesRedux.actions.setHeadAgreementId(headAgreementId));
  }, [dispatch, routeAgreementId, headAgreementId]);

  if (loading) {
    return (
      <Spacing num={8}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!routeAgreement) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={"No such route agreement."} />
      </Spacing>
    );
  }

  return <RouteAgreementDetails {...props} routeAgreement={routeAgreement} />;
};
