import { Box, Column, Row, Space, Text } from "@stenajs-webui/core";
import * as React from "react";
import { HEADER_HEIGHT, MatrixScheduleView } from "./MatrixScheduleView";
import { MatricesTypeSearchListQuery } from "@/gql/graphql";

interface Props {
  matrix: MatricesTypeSearchListQuery["productPrice"]["matrices"]["byKeyword"]["0"];
}

export const MatrixAndKeywords: React.FC<Props> = ({ matrix }) => {
  return (
    <Row>
      <Column width={"380px"}>
        <Box height={HEADER_HEIGHT}>
          <Text size="small" variant={"bold"}>
            Keywords
          </Text>
        </Box>
        {matrix.keywords.map((keyword) => (
          <Text size="small" key={keyword}>
            {keyword}
          </Text>
        ))}
      </Column>
      <Space num={4} />
      <MatrixScheduleView timeslots={matrix.timeslots} />
    </Row>
  );
};
