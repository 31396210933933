import { gql, useQuery } from "@apollo/client";
import { SailingCategory } from "../../types/FilterEntitys";
import { RouteAgreementSailingCategoryFilterQuery } from "@/gql/graphql";

const routeAgreementSailingCategoryFilterQuery = gql`
  query RouteAgreementSailingCategoryFilter {
    productPrice {
      agrRouteSailingTypes {
        all {
          id
          code
          description
        }
      }
    }
  }
`;

export const useFetchAllRouteAgreementSailingCategories = () => {
  const { data, error, loading, refetch } =
    useQuery<RouteAgreementSailingCategoryFilterQuery>(
      routeAgreementSailingCategoryFilterQuery,
      {
        fetchPolicy: "cache-first",
      }
    );

  const routeAgreementSailingCategories =
    data?.productPrice.agrRouteSailingTypes.all.map<SailingCategory>((r) => ({
      id: r.id,
      code: r.id,
      name: r.description,
    })) ?? [];

  return { routeAgreementSailingCategories, error, loading, refetch };
};
