import { routeFactory } from "@/RouteFactory";
import { HeadAgreementListSwitcher } from "@/features/product-and-price/head-agreement/list/components/HeadAgreementListSwitcher";
import { useNavigate, useParams } from "react-router-dom";
import { useHeadAgreementsByCustomer } from "@/features/product-and-price/head-agreement/list/hooks/UseHeadAgreementsSwitcher";
import { Window } from "@stenajs-webui/modal";

interface HeaderAgreementPickerModalProps {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reportType: string;
  parentCustomerId: string;
}

export const HeaderAgreementPickerModal = ({
  isOpen,
  setIsModalOpen,
  reportType,
  parentCustomerId,
}: HeaderAgreementPickerModalProps) => {
  const navigate = useNavigate();
  const { headAgreements, error, loading } =
    useHeadAgreementsByCustomer(parentCustomerId);
  const { customerId } = useParams() as { customerId: string };

  return (
    <Window
      headerText="Select parent header agreement"
      draggable
      isOpen={isOpen}
      width={"800px"}
      onRequestClose={() => setIsModalOpen(false)}
      indent={0}
    >
      <HeadAgreementListSwitcher
        headAgreements={headAgreements || []}
        error={error}
        loading={loading}
        onSelectHeadAgreement={(headAgreementId: string) =>
          navigate(
            routeFactory.productAndPrice.headAgreement.createReport({
              customerId,
              headAgreementId: headAgreementId,
              reportType: reportType,
              parent: parentCustomerId,
            })
          )
        }
      />
    </Window>
  );
};
