import { CurrencyRadioButtonRow } from "@/common/components/data-driven-inputs/radio-buttons/CurrencyRadioButtonRow";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { DateTextInput } from "@stenajs-webui/calendar";
import { Column, Row, Space } from "@stenajs-webui/core";
import {
  Label,
  PrimaryButton,
  SecondaryButton,
  stenaArrowUp,
} from "@stenajs-webui/elements";
import React, { useState } from "react";
import { SurchargeDocumentsUploadButton } from "./SurchargeDocumentsUploadButton";
import { SurchargeDocumentsSeaAreaSelect } from "./SurchargeDocumentsSeaAreaSelect";
import { postHttpWithFormData } from "@/apollo-client/ApolloClientFactory";
import { useMsal } from "@azure/msal-react";
import { profileSelectors } from "@/common/redux/profile/selectors";
import { useSelector } from "react-redux";
import { Window } from "@stenajs-webui/modal";

export interface ModalFormModel {
  fileName?: string;
  seaArea?: string;
  currency?: string;
  validFrom?: string;
  blob?: Blob;
}

export interface ApiErrorResult {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  Extensions?: {
    error?: {
      identifier: string;
      Message: string;
    };
  };
}

const initialFormModel: ModalFormModel = {
  fileName: undefined,
  seaArea: undefined,
  currency: "NONE",
  validFrom: undefined,
  blob: undefined,
};

interface Props {
  isModalOpen: boolean;
  onRequestClose: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchSurchargeDocuments: () => void;
  setValidationErrors: React.Dispatch<
    React.SetStateAction<ApiErrorResult | undefined>
  >;
}

const isFormModelValid = ({
  blob,
  currency,
  fileName,
  seaArea,
  validFrom,
}: ModalFormModel) =>
  blob && currency && fileName && seaArea && validFrom ? true : false;

export const SurchargeDocumentsUploadModal: React.FC<Props> = ({
  isModalOpen,
  onRequestClose,
  setIsModalOpen,
  refetchSurchargeDocuments,
  setValidationErrors,
}) => {
  const { instance } = useMsal();
  const [formModel, setFormModel] = useState<ModalFormModel>(initialFormModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userName } = useSelector(profileSelectors.getGlobal);

  const onCloseModal = () => {
    onRequestClose();
    setFormModel(initialFormModel);
  };

  const uploadSurchargeDocument = async () => {
    setIsLoading(true);
    const { seaArea, currency, validFrom } = formModel;

    const metadata = {
      seaArea,
      currency,
      validFrom,
      createdBy: userName,
      additionalChargeType: "surcharge",
    };

    const formData = new FormData();
    formData.append("file", formModel.blob!, formModel.fileName);
    formData.append("metadata", JSON.stringify(metadata));
    setValidationErrors(undefined);
    const response = await postHttpWithFormData(
      "/upload/additional-charges-document",
      formData,
      instance
    );
    setIsLoading(false);
    if (response?.ok) {
      refetchSurchargeDocuments();
      setIsModalOpen(false);
      setFormModel(initialFormModel);
    } else if (!response?.ok) {
      const error: ApiErrorResult = await response?.json();
      setValidationErrors(error);
      setIsModalOpen(false);
      setFormModel(initialFormModel);
    }
  };

  return (
    <Window
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      width={"100%"}
      headerText={"Upload document"}
      indent={IndentValues.TWENTYFOUR}
      spacing={SpacingValues.TWENTYFOUR}
    >
      <Column minWidth={600}>
        <Row>
          <Label text="Select file to upload">
            <SurchargeDocumentsUploadButton
              formModel={formModel}
              setFormModel={setFormModel}
            />
          </Label>
        </Row>

        <Space num={SpaceValues.TWENTYFOUR} />
        <Row>
          <Label text="Valid from">
            <DateTextInput
              value={formModel.validFrom}
              onValueChange={(validFrom) => {
                setFormModel({ ...formModel, validFrom });
              }}
              portalTarget={document.body}
              width={"200px"}
            />
          </Label>
        </Row>

        <Space num={SpaceValues.TWENTYFOUR} />
        <Row>
          <Label text="Sea area">
            <SurchargeDocumentsSeaAreaSelect
              width={"200px"}
              value={formModel.seaArea}
              onValueChange={(seaArea) =>
                setFormModel({ ...formModel, seaArea: String(seaArea) })
              }
              placeholder={"No specific area"}
            />
          </Label>
        </Row>

        <Space num={SpaceValues.TWENTYFOUR} />
        <Label text="Currency">
          <CurrencyRadioButtonRow
            value={formModel.currency}
            onValueChange={(currency) =>
              setFormModel({ ...formModel, currency })
            }
            noneOptionEnabled
          />
        </Label>

        <Space num={SpaceValues.FORTYEIGHT} />
        <Row justifyContent={"flex-end"}>
          <SecondaryButton label="Cancel" onClick={onCloseModal} />
          <Space num={SpaceValues.SIXTEEN} />
          <PrimaryButton
            loading={isLoading}
            disabled={!isFormModelValid(formModel)}
            label="Upload"
            onClick={() => uploadSurchargeDocument()}
            leftIcon={stenaArrowUp}
          />
        </Row>
      </Column>
    </Window>
  );
};
