import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import { profileSelectors } from "../../../../common/redux/profile/selectors";
import { AppThunk } from "../../../../config/redux/RootReducer";
import { routeTargetsOverviewSelectors } from "../../route-targets-overview/redux/routeTargetsOverviewSelectors";
import { fetchCustomerRouteTargetsByFilter } from "../features/route-pair-target-table/redux/thunks/CustomerRouteTargetFetcher";
import { transformRoutePairTargetStateToQueryInput } from "../transformers/TargetRoutePairTransformers";

export const fetchDataByFilter =
  (
    routePairCode: string,
    committedState: WorkspaceState,
    defaultYearFallback: number
  ): AppThunk =>
  async (dispatch, getState) => {
    const { currentCurrency } = profileSelectors.getGlobal(getState());

    const currentYear =
      routeTargetsOverviewSelectors.getSelectedNegotiationYear(getState());

    const variablesFilter = transformRoutePairTargetStateToQueryInput(
      committedState,
      currentCurrency,
      routePairCode,
      currentYear ?? defaultYearFallback
    );

    dispatch(fetchCustomerRouteTargetsByFilter(variablesFilter));
  };
