import { stripLineFromSellerName } from "@/common/transformers/SellerNameTransformer";
import { StatisticsCustomerDataListItem } from "../config/StatisticsByCustomerTableConfig";
import { CustomerListForStatisticsQuery } from "@/gql/graphql";

export const transformCustomerItemsToTableItems = (
  customerItems:
    | CustomerListForStatisticsQuery["statistics"]["customerList"]["byFilter"]["items"]
    | undefined
): Array<StatisticsCustomerDataListItem> => {
  if (!customerItems) return [];
  return customerItems?.map(
    ({
      customerName,
      customerNumber,
      negotiationStatus,
      parentNumber,
      sellerId,
      statNumber,
      volumeAndTargetForSelected,
    }) => {
      return {
        customerName,
        customerNumber,
        negotiationStatus,
        parentNumber,
        sellerId: stripLineFromSellerName(sellerId ?? "").toUpperCase(),
        statNumber,
        averageNegotiationRate:
          volumeAndTargetForSelected.averageNegotiationRate,
        averageTarget: volumeAndTargetForSelected.averageTarget,
        avgMeterPrice: volumeAndTargetForSelected.avgMeterPrice,
        avgMeterPriceDiffInPercent:
          volumeAndTargetForSelected.avgMeterPriceDiffInPercent,
        expectedVolume: volumeAndTargetForSelected.expectedVolume,
        lastYearVolume: volumeAndTargetForSelected.lastYearVolume,
      };
    }
  );
};
