import { Row } from "@stenajs-webui/core";
import { FlatButton, stenaClear, stenaTrash } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { SurchargeDocumentsTableColumns } from "../config/SurchargeDocumentsTableConfig";
import { gql, useMutation } from "@apollo/client";
import {
  DeleteAdditionalChargesMutation,
  DeleteAdditionalChargesMutationVariables,
} from "@/gql/graphql";

const deleteAdditionalChargesMutation = gql`
  mutation deleteAdditionalCharges($additionalCharges: [String!]!) {
    productPrice {
      additionalCharges {
        deleteAdditionalCharges(additionalCharges: $additionalCharges) {
          ... on DeleteAdditionalChargesSuccessResult {
            success
          }
        }
      }
    }
  }
`;

interface Props {
  numItemsSelected: number;
  tableContext: TableContext<SurchargeDocumentsTableColumns>;
  refetchSurchargeDocuments: () => void;
}

export const SurchargeDocumentsActionPanel: React.FC<Props> = ({
  numItemsSelected,
  tableContext,
  refetchSurchargeDocuments,
}) => {
  const [mutate, { loading }] = useMutation<
    DeleteAdditionalChargesMutation,
    DeleteAdditionalChargesMutationVariables
  >(deleteAdditionalChargesMutation);
  return (
    <SelectedItemsActionsPanel
      numItemsSelected={numItemsSelected}
      afterLabelContent={
        <Row alignItems={"center"}>
          <FlatButton
            label={"Clear all"}
            onClick={() =>
              tableContext.dispatch(tableContext.actions.clearSelection())
            }
            leftIcon={stenaClear}
          />
          <FlatButton
            loading={loading}
            label={"Delete"}
            onClick={async () => {
              await mutate({
                variables: {
                  additionalCharges: tableContext.state.selectedIds.selectedIds,
                },
              });
              tableContext.dispatch(tableContext.actions.clearSelection());
              refetchSurchargeDocuments();
            }}
            leftIcon={stenaTrash}
          />
        </Row>
      }
    />
  );
};
