import { SpacingValues } from "@/common/utils/SpacingValues";
import { useQuery } from "@apollo/client";
import { Card, Spinner } from "@stenajs-webui/elements";
import { ErrorPanel } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import { gql } from "apollo-boost";
import * as React from "react";
import { MatrixFormItem } from "../MatrixFormModel";
import { MatrixItemForm } from "./MatrixItemForm";
import {
  MatrixListItemFetcherQuery,
  MatrixListItemFetcherQueryVariables,
} from "@/gql/graphql";
interface Props {
  matrixItem: MatrixFormItem;
}

const query = gql`
  fragment MatrixListItemMatrices on Matrix {
    id
    keywords
    validityKey
    timeslots {
      timeFrom
      timeTo
      weekDay
    }
  }
  query MatrixListItemFetcher($matrixId: ID!) {
    productPrice {
      matrices {
        byId(id: $matrixId) {
          ...MatrixListItemMatrices
        }
      }
    }
  }
`;

export const MatrixListItemFetcher: React.FC<Props> = ({ matrixItem }) => {
  const { data, loading, error } = useQuery<
    MatrixListItemFetcherQuery,
    MatrixListItemFetcherQueryVariables
  >(query, {
    variables: {
      matrixId: matrixItem.matrixId,
    },
  });

  const matrix = data?.productPrice.matrices.byId;

  if (loading) {
    return (
      <Card
        background={cssColor("--lhds-color-ui-50")}
        spacing={SpacingValues.TWENTYFOUR}
        alignItems={"center"}
      >
        <Spinner />
      </Card>
    );
  }

  if (error || !matrix) {
    return (
      <Card spacing={SpacingValues.TWENTYFOUR}>
        <ErrorPanel text={error ? error.message : "Could not find matrix."} />
      </Card>
    );
  }

  return <MatrixItemForm matrixItem={matrixItem} matrix={matrix} />;
};
