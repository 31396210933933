import { NumericAgrVehicleLengthTextInput } from "@/common/components/numeric-agr-vehicle-length-text-input/NumericAgrVehicleLengthTextInput";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import { Icon, Label, stenaArrowWideRight } from "@stenajs-webui/elements";
import * as React from "react";
import { AddVehicleToAllRouteAgrRadioButton } from "../../../../../common/components/data-driven-inputs/radio-buttons/add-vehicle-to-all-route-agreements/AddVehicleToAllRouteAgrRadioButton";
import { AccUnaccSelect } from "../../../../../common/components/data-driven-inputs/selects/acc-unacc-select/AccUnaccSelect";
import { CargoStatusSelect } from "../../../../../common/components/data-driven-inputs/selects/cargo-status-select/CargoStatusSelect";
import { VehicleTypeWithAllSelect } from "../../../../../common/components/data-driven-inputs/selects/vehicle-type-with-all-select/VehicleTypeWithAllSelect";
import { AddVehicleTypeFormModel } from "../models/AddVehicleTypeFormModel";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  value: AddVehicleTypeFormModel;
  addVehicleTypeToAllRoutesRadioButton?: boolean;
  addToAllRouteAgreements?: boolean;
  setAddToAllRouteAgreements: (active: boolean) => void;
  onValueChange: (value: AddVehicleTypeFormModel) => void;
}

export const AddVehicleTypeForm: React.FC<Props> = ({
  value,
  addVehicleTypeToAllRoutesRadioButton,
  addToAllRouteAgreements,
  setAddToAllRouteAgreements,
  onValueChange,
}) => {
  return (
    <Column>
      <Label text={"Add vehicle type"}>
        <VehicleTypeWithAllSelect
          value={value.vehicleTypeId}
          onValueChange={(vehicleTypeId) =>
            onValueChange({ ...value, vehicleTypeId })
          }
        />
      </Label>
      <Spacing num={1.5} />
      <Label text={"Length"}>
        <Row alignItems={"center"}>
          <NumericAgrVehicleLengthTextInput
            value={value.lengthFrom}
            onValueChange={(lengthFrom) =>
              onValueChange({ ...value, lengthFrom })
            }
            dataTestid={
              testIdConstantsWebapp.createRouteRatesAddVehicleLengthFrom
            }
          />
          <Indent num={0.5}>
            <Icon icon={stenaArrowWideRight} size={14} />
          </Indent>
          <NumericAgrVehicleLengthTextInput
            value={value.lengthTo}
            onValueChange={(lengthTo) => onValueChange({ ...value, lengthTo })}
            dataTestid={
              testIdConstantsWebapp.createRouteRatesAddVehicleLengthTo
            }
          />
        </Row>
      </Label>
      <Spacing num={1.5} />
      <Label text={"Weight"}>
        <Row alignItems={"center"}>
          <NumericAgrVehicleWeightTextInput
            value={value.weightFrom}
            onValueChange={(weightFrom) =>
              onValueChange({ ...value, weightFrom })
            }
            dataTestid={
              testIdConstantsWebapp.createRouteRatesAddVehicleWeightFrom
            }
          />
          <Indent num={0.5}>
            <Icon icon={stenaArrowWideRight} size={14} />
          </Indent>
          <NumericAgrVehicleWeightTextInput
            value={value.weightTo}
            onValueChange={(weightTo) => onValueChange({ ...value, weightTo })}
            dataTestid={
              testIdConstantsWebapp.createRouteRatesAddVehicleWeightTo
            }
          />
        </Row>
      </Label>
      <Spacing num={1.5} />
      <Label text={"ELB"}>
        <Column>
          <CargoStatusSelect
            value={value.cargoStatusCode}
            onValueChange={(cargoStatusCode) =>
              onValueChange({
                ...value,
                cargoStatusCode,
              })
            }
          />
        </Column>
      </Label>
      <Spacing num={1.5} />
      <Label text={"Acc / Unacc"}>
        <AccUnaccSelect
          value={value.unaccompanied}
          onValueChange={(unaccompanied) =>
            onValueChange({ ...value, unaccompanied })
          }
        />
      </Label>
      {addVehicleTypeToAllRoutesRadioButton ? (
        <>
          <Spacing num={1.5} />
          <Label text={"Add to all route agreements"} />
          <AddVehicleToAllRouteAgrRadioButton
            addToAllRouteAgreements={addToAllRouteAgreements}
            setAddToAllRouteAgreements={setAddToAllRouteAgreements}
          />
        </>
      ) : null}
    </Column>
  );
};
