import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { customerMultiLegRouteRedux } from "../redux";
import { CustomerMultilegRoute } from "./CustomerMultilegRoute";
import { populateMultiLegRouteAgreementRouteAgreementState } from "../../route-agreement/details/thunks/MultiLegRouteAgreementRouteAgreementStatePopulator";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import {
  HeadAgreementForMultilegFragment,
  RouteAgreementsForMultilegRouteFragment,
} from "@/gql/graphql";

interface Props {
  multiLegRouteAgreement: RouteAgreementsForMultilegRouteFragment;
  headAgreement: HeadAgreementForMultilegFragment;
  customerId: string;
}

export const CustomerMultilegRouteFetcher: React.FC<Props> = ({
  multiLegRouteAgreement,
  headAgreement,
  customerId,
}) => {
  const dispatch = useDispatch();
  const multiLegRouteAgreementId = multiLegRouteAgreement.id;

  const { defaultYear } = useNegotiationYears();

  const routeAgreementYear = React.useMemo(() => {
    const dateString = headAgreement?.valid.start?.isoString;
    if (dateString) {
      return new Date(dateString).getUTCFullYear();
    }
    return defaultYear;
  }, [defaultYear, headAgreement]);

  useEffect(() => {
    dispatch(
      customerMultiLegRouteRedux.actions.loadMultiLegAgreement(
        multiLegRouteAgreement
      )
    );
  }, [dispatch, multiLegRouteAgreement]);

  useEffect(() => {
    dispatch(
      populateMultiLegRouteAgreementRouteAgreementState(
        multiLegRouteAgreementId
      )
    );
  }, [dispatch, multiLegRouteAgreementId]);

  return (
    <CustomerMultilegRoute
      routePairId={multiLegRouteAgreement.route.id}
      headAgreement={headAgreement}
      customerId={customerId}
      multiLegAgreement={multiLegRouteAgreement}
      routeAgreementYear={routeAgreementYear}
    />
  );
};
