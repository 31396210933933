import { HeadAgreementFormModel } from "../../common/models/HeadAgreementFormModel";
import { format } from "date-fns";
import { limitStringElseThrow } from "../../../../../common/string/StringLimiteter";
import { DateFormats } from "@/common/dates/DateFormats";
import { UpdateHeadAgreementInput } from "@/gql/graphql";

export const transformHeadAgreementFormToInput = (
  headAgreementId: string,
  rowVersion: string,
  formModel: HeadAgreementFormModel
): UpdateHeadAgreementInput => {
  if (!formModel.name) {
    throw new Error("You need to type a name for the agreement");
  }

  if (!formModel.valid.startDate) {
    throw new Error("Validity start date is required.");
  }

  if (!formModel.valid.endDate) {
    throw new Error("Validity end date is required.");
  }

  return {
    headAgreementId,
    rowVersion,
    internalNotes: {
      value: formModel.internalNotes ?? "",
    },
    lastPage: {
      value: formModel.lastPage ?? "",
    },
    condition: {
      value: limitStringElseThrow(formModel.condition ?? "", 150, "condition"),
    },
    specifications: {
      value: limitStringElseThrow(
        formModel.specifications ?? "",
        150,
        "specifications"
      ),
    },
    name: {
      value: formModel.name ?? "",
    },
    valid: {
      startDate: format(formModel.valid.startDate, DateFormats.fullDate),
      endDate: format(formModel.valid.endDate, DateFormats.fullDate),
    },
  };
};
