import * as React from "react";
import { GridContainer } from "../../../common/components/grid-container/GridContainer";
import { useCustomerHeaderHeadAgreementById } from "../customers/common/customer-header/hooks/UseCustomerHeaderHeadAgreementById";
import { CustomerInfoPairBox } from "./customer/CustomerInfoPairBox";
import { HeadAgreementInfoPairBox } from "./head-agreement/HeadAgreementInfoPairBox";
import { MultiLegAgreementInfoPairBox } from "./multi-leg-agreement/MultiLegAgreementInfoPairBox";
import { MultiLegRouteAgreementInfoPairBox } from "./multi-leg-route-agreement/MultiLegRouteAgreementInfoPairBox";
import {
  CustomerHeaderCustomersByIdFragment,
  HeadAgreementByIdFragment,
  RouteAgreementHeaderRouteAgreementFragment,
} from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  customer: CustomerHeaderCustomersByIdFragment;
  routeAgreement?: RouteAgreementHeaderRouteAgreementFragment | null;
  multiLegAgreement?: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  switchButtonDisabled?: boolean;
}

export const MultiLegViewInfoPairBoxes: React.FC<Props> = ({
  headAgreementId,
  routeAgreement,
  customer,
  switchButtonDisabled,
  multiLegAgreement,
}) => {
  const { headAgreement } = useCustomerHeaderHeadAgreementById(headAgreementId);
  return (
    <GridContainer boxMinWidth={384}>
      {routeAgreement && (
        <MultiLegRouteAgreementInfoPairBox
          multiLegRouteAgreement={routeAgreement}
        />
      )}
      {multiLegAgreement && (
        <MultiLegAgreementInfoPairBox multiLegAgreement={multiLegAgreement} />
      )}

      {headAgreementId && headAgreement && (
        <HeadAgreementInfoPairBox
          headAgreement={headAgreement}
          customerId={customer.id}
          switchButtonDisabled={switchButtonDisabled}
        />
      )}
      <CustomerInfoPairBox customer={customer} popoverEnabled />
    </GridContainer>
  );
};
