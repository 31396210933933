import React from "react";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";
import { Text } from "@stenajs-webui/core";

interface Props {
  isEditable?: boolean;
  value?: string;
  modifiedField?: ModifiedFieldItemState;
}

export const RoutePairCustomerTargetEditableTableCell: React.FC<Props> = ({
  isEditable,
  value,
  modifiedField,
}) => {
  const fieldValue = value ? value : "---";

  return (
    <>
      <Text
        color={isEditable ? "var(--swui-primary-action-color)" : undefined}
        variant={modifiedField?.modified ? "bold" : undefined}
      >
        {fieldValue}
      </Text>
    </>
  );
};
