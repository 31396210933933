import { AccountSpecificationCard } from "@/common/components/invoice-specification-cards/AccountSpecificationCard";
import { OverviewTableWrapper } from "@/common/components/overview/OverviewTableWrapper";
import { OverviewBooking } from "@/common/components/overview/models/OverviewBooking";
import { ErrorDisplay } from "@/common/error/components/ErrorDisplay";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Row, Space } from "@stenajs-webui/core";
import { Banner, stenaInfoCircle } from "@stenajs-webui/elements";
import React from "react";
import { NslhOverviewCustomerSelectedData } from "./redux/reducers";

interface Props {
  accountCardData: NslhOverviewCustomerSelectedData;
  overviewBookings: OverviewBooking[] | null;
  error?: FreightPricingPortalError;
}

export const OverviewScreen: React.FC<Props> = ({
  accountCardData,
  overviewBookings,
  error,
}) => {
  if (error) {
    return (
      <>
        <Row justifyContent={"center"}>
          <ErrorDisplay error={error} />
        </Row>
      </>
    );
  }

  if (!overviewBookings) {
    return (
      <>
        <Row justifyContent={"center"}>
          <Banner
            variant={"info"}
            text={"Please select a customer and month to search for invoices."}
            icon={stenaInfoCircle}
          />
        </Row>
      </>
    );
  }

  if (overviewBookings && overviewBookings.length === 0) {
    return (
      <>
        <Row justifyContent={"center"}>
          <Banner
            variant={"info"}
            text={"Nothing found for this month"}
            icon={stenaInfoCircle}
          />
        </Row>
      </>
    );
  }

  const overviewBooking =
    overviewBookings && overviewBookings.length > 0
      ? overviewBookings[0]
      : null;

  if (overviewBookings) {
    return (
      <>
        {overviewBooking && (
          <Row width={"100%"}>
            <AccountSpecificationCard
              accountNumber={accountCardData?.custNo}
              accountName={accountCardData?.name ?? ""}
              accountIndex={accountCardData?.custIndex ?? ""}
              lateHandlingThreshold={String(
                overviewBooking.customerThresholdLateHandlingPercentage
              )}
              noShowThreshold={String(
                overviewBooking.customerThresholdNoShowPercentage
              )}
            />
          </Row>
        )}
        <Space num={SpaceValues.TWENTYFOUR} />
        <OverviewTableWrapper overviewBookings={overviewBookings} />
      </>
    );
  }

  return null;
};
