import { RouteAgreementTypeCode } from "@/gql/graphql";
import { DateRange } from "@stenajs-webui/calendar";

export interface CreateMultiLegAgreementRouteFormModel {
  name: string;
  headAgreementId: string;
  currencyCode: string;
  routeCode: string;
  type: RouteAgreementTypeCode;
  externalDescription: string;
  multiLegAgreementId: string;
  peak: boolean;
  offPeak: boolean;
  shoulder: boolean;
  special: boolean;
  selectedRouteCode: string;
  valid: DateRange;
}

export const emptyCreateMultiLegAgreementRouteFormModel: CreateMultiLegAgreementRouteFormModel =
  {
    name: "",
    headAgreementId: "",
    currencyCode: "EUR",
    routeCode: "",
    type: RouteAgreementTypeCode.Cond,
    externalDescription: "",
    multiLegAgreementId: "",
    valid: {},
    peak: false,
    offPeak: false,
    shoulder: false,
    special: false,
    selectedRouteCode: "",
  };
