import * as React from "react";
import { PageHeader } from "../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../common/components/bread-crumbs/Crumb";
import { BreadCrumbs, stenaBusinessNslh } from "@stenajs-webui/elements";

export const NoShowLateHandlingHandleBookingsHeader: React.FC = () => {
  return (
    <PageHeader
      title={"Handle bookings"}
      sticky
      icon={stenaBusinessNslh}
      width={"100vw"}
      offsetLeft={0}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb label={"NO SHOW & LATE HANDLING"} />
          <Crumb label={"HANDLE BOOKINGS"} isLast={true} />
        </BreadCrumbs>
      }
    />
  );
};
