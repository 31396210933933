import { StoreState } from "../../../../config/redux/RootReducer";
import {
  NslhOverviewCustomerSelectedData,
  NslhOverviewData,
  NslhOverviewError,
} from "./reducers";
import { DateSelectInputValues } from "./utils";

export const createNslhInternalOverviewStateSelectors = {
  getNslhOverviewDateRangeInput: (state: StoreState): DateSelectInputValues => {
    return state.nslhInternalOverview.nslhOverviewDateRangeInput;
  },

  getNslhOverviewCustomerSelectedData: (
    state: StoreState
  ): NslhOverviewCustomerSelectedData => {
    return state.nslhInternalOverview.nslhOverviewCustomerSelectedData;
  },

  getNslhOverviewAccountCardData: (
    state: StoreState
  ): NslhOverviewCustomerSelectedData => {
    return state.nslhInternalOverview.nslhOverviewAccountCardData;
  },

  getNslhOverviewdData: (state: StoreState): NslhOverviewData => {
    return state.nslhInternalOverview.nslhOverviewData;
  },

  getNslhOverviewLoading: (state: StoreState): boolean => {
    return state.nslhInternalOverview.nslhOverviewLoading;
  },

  getNslhOverviewError: (state: StoreState): NslhOverviewError => {
    return state.nslhInternalOverview.nslhOverviewError;
  },
};
