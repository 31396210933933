// 1-3 whole numbers. Can be followed by , or . followed by 1-2 decimals.
// All other formats are not allowed.
export const inputFieldValidation = (stringToTest: string): RegExp => {
  if (stringToTest.includes("+") || stringToTest.includes("-")) {
    return new RegExp(
      "^([\\+?\\|\\-?\\]?[0-9]\\d{0,3}((?:\\,\\d{1,2})|(?:\\.\\d{1,2}))?$)"
    );
  }

  return new RegExp(
    "^([\\+?\\|\\-?\\]?[0-9]\\d{0,2}((?:\\,\\d{1,2})|(?:\\.\\d{1,2}))?$)"
  );
};

export const inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue: RegExp =
  new RegExp("^([0-9]\\d{0,10000}((?:\\,\\d{1,2})|(?:\\.\\d{1,2}))?$)");

export const inputFieldValidationOnlyNumbersCommasAndDots: RegExp = new RegExp(
  "^[0-9]{0,20}([^,.][,.][0-9]{0,2})?$"
);
