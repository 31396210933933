import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { articleFormActions } from "../actions";

export enum SortArticleBy {
  CodeAsc = "CODE_ASC",
  CodeDesc = "CODE_DESC",
  IncludeInPrice = "INCLUDE_IN_PRICE",
}

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value: string;
  onValueChange?: (value: SortArticleBy) => void;
}

export interface ArticleSortByOptions {
  label: string;
  value: SortArticleBy;
}

const options: Array<ArticleSortByOptions> = [
  {
    label: "Name (a-z)",
    value: SortArticleBy.CodeAsc,
  },
  {
    label: "Name (z-a)",
    value: SortArticleBy.CodeDesc,
  },
  {
    label: "Include in price",
    value: SortArticleBy.IncludeInPrice,
  },
];

export const ArticleSortBySelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const selected = useMemo(() => {
    return options.filter((o) => o.value === value);
  }, [value]);
  const dispatch = useDispatch();

  return (
    <Select
      menuPortalTarget={document.body}
      options={options}
      value={selected}
      {...selectProps}
      onChange={(opt: ArticleSortByOptions | null) => {
        if (onValueChange && opt) {
          onValueChange(opt.value);
          dispatch(articleFormActions.sortArticleForm(opt.value));
        }
      }}
    />
  );
};
