import { BySellerRowItem, VolumeAndRates } from "../types";
import { transformResultCommonFieldsToTableRow } from "../../../common/transformers/CommonTableRowTransformer";
import { ByCustomerOrVolume } from "@/features/statistics/common/utils/ByCustomerOrVolumeEnum";
import { StatisticsResultListItem } from "@/features/statistics/common/config/list/ResultTableConfig";

export const transformResultToTableRow = (
  row: BySellerRowItem,
  volumeAndRates: VolumeAndRates,
  checkedRadioButton: ByCustomerOrVolume
): StatisticsResultListItem => {
  const volumeAndTargetForSelected = volumeAndRates.find(
    (v) => v.seller.id === row.seller.id
  )?.volumeAndTargetForSelected;

  return {
    ownerName: row.seller.name,
    ownerId: row.seller.id,
    ...transformResultCommonFieldsToTableRow(row, checkedRadioButton),
    ...{
      ...volumeAndTargetForSelected,
    },
  };
};
