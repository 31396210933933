import { Indent, Row, Spacing, Txt, useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  PrimaryButton,
  ResultItem,
  ResultListBanner,
  stenaTrash,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import { EditableEntityState } from "@stenajs-webui/redux/dist/features/reducer-factories/editable-entity-reducer/editable-entity-reducer";
import { Tooltip } from "@stenajs-webui/tooltip";
import { useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../config/redux/RootReducer";
import { NoShowLateHandlingPricesRow } from "./config/RowsAndColumnsConfig";
import { useNoShowLateHandlingDeleteValidFromDateMutation } from "./hooks/UseNoShowLateHandlingDeleteValidFromDateMutation";
import {
  noShowLateHandlingRoutePricesActions,
  noShowLateHandlingRoutePricesCurrentlySelectedActions,
} from "./redux/actions";
import { CurrentlySelectedSibling } from "./redux/reducers";
import { currentMonth, currentYear } from "./util/GetDatesForSelect";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  routePairCode: string;
  item: EditableEntityState<NoShowLateHandlingPricesRow>;
}

const currentlySelectedDataSelector = (state: StoreState) => {
  return state.noShowLateHandlingPricesCurrentlySelected.selected;
};

const allValidFromDataSelector = (state: StoreState) => {
  return state.noShowLateHandlingPrices.allNoShowHandlingPriceData;
};

const validFromInFutureResolver = (
  item: EditableEntityState<NoShowLateHandlingPricesRow>
) => {
  const firstDayOfCurrentMonth = new Date(`${currentYear}-${currentMonth}-01`);
  const firstDayInValidFromMonth = new Date(
    `${item.persisted.validFrom.substr(0, 4)}-${item.persisted.validFrom.substr(
      5,
      2
    )}-01`
  );

  return firstDayOfCurrentMonth <= firstDayInValidFromMonth;
};

export const NoShowLateHandlingDeleteValidFromDate: React.FC<Props> = ({
  routePairCode,
  item,
}) => {
  const dispatch = useDispatch();
  const [isOpen, open, close] = useBoolean(false);
  const { deleteValidFromDate, loading } =
    useNoShowLateHandlingDeleteValidFromDateMutation();
  const { setBannerState, bannerState } = useResultListBannerState(undefined);
  const currentlySelectedData = useSelector(currentlySelectedDataSelector);
  const allValidFromData = useSelector(allValidFromDataSelector);

  const matchedRoutePairCodeValidFromItems = useMemo(() => {
    return allValidFromData
      .flat()
      .filter(
        (validFromRow) => validFromRow.editable.routePairCode === routePairCode
      );
  }, [allValidFromData, routePairCode]);

  const isDisabled = matchedRoutePairCodeValidFromItems.length <= 1;

  const selectedValidFromRow = useMemo(() => {
    if (matchedRoutePairCodeValidFromItems.length > 1) {
      return matchedRoutePairCodeValidFromItems
        .filter((routePairItem) => routePairItem.id !== item.id)
        .sort((a, b) =>
          a.editable.validFrom.localeCompare(b.editable.validFrom)
        )[0];
    }

    return undefined;
  }, [matchedRoutePairCodeValidFromItems, item.id]);

  const onDeleteValidFromDate = async () => {
    if (item.persisted.newlyAddedRow) {
      const updatedCurrentlySelectedData = currentlySelectedData
        .flat()
        .map((data): CurrentlySelectedSibling => {
          if (
            selectedValidFromRow &&
            selectedValidFromRow.id &&
            data.routePairCode === routePairCode
          ) {
            return {
              currentlySelectedId: selectedValidFromRow.id,
              routePairCode,
            };
          }
          return data;
        });

      batch(() => {
        dispatch(
          noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
            updatedCurrentlySelectedData
          )
        );
        dispatch(noShowLateHandlingRoutePricesActions.removeRow(item));
      });
      close();
      return;
    }

    if (!item.persisted.newlyAddedRow) {
      const { data } = await deleteValidFromDate({
        routePairCode,
        validFrom: item.persisted.validFrom,
      });

      const result =
        data?.productPrice.noShowLateHandlingPrices.deleteValidFromDate;

      if (result && "errors" in result) {
        setBannerState({
          headerText: "Could not delete valid from date",
          items: result.errors.map<ResultItem>((error) => ({
            text: error.message,
          })),
        });
      } else {
        const updatedCurrentlySelectedData = currentlySelectedData
          .flat()
          .map((data): CurrentlySelectedSibling => {
            if (
              selectedValidFromRow &&
              selectedValidFromRow.id &&
              data.routePairCode === routePairCode
            ) {
              return {
                currentlySelectedId: selectedValidFromRow.id,
                routePairCode,
              };
            }
            return data;
          });

        batch(() => {
          dispatch(
            noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
              updatedCurrentlySelectedData
            )
          );
          dispatch(noShowLateHandlingRoutePricesActions.removeRow(item));
        });
        close();
      }
    }
  };

  const isValidFromInFuture = useMemo(() => {
    return validFromInFutureResolver(item);
  }, [item]);

  return (
    <>
      {isDisabled ? (
        <Tooltip
          appendTo={document.body}
          label={
            "You are not allowed to delete the last valid from date for a route"
          }
        >
          <FlatButton
            leftIcon={stenaTrash}
            onClick={open}
            size={"small"}
            disabled={!isValidFromInFuture || isDisabled}
          />
        </Tooltip>
      ) : (
        <FlatButton
          variant="danger"
          leftIcon={stenaTrash}
          onClick={open}
          size={"small"}
          disabled={!isValidFromInFuture}
        />
      )}

      <Window
        isOpen={isOpen}
        width={"500px"}
        spacing={3}
        indent={3}
        onRequestClose={close}
        header={
          <Txt variant={"bold"} size={"large"}>
            Delete valid from date ({routePairCode})
          </Txt>
        }
      >
        <Spacing num={0.5} />
        <Txt>
          {
            "Do you wish to delete the valid from date and prices for this route?"
          }
        </Txt>
        <Spacing num={2} />
        {bannerState && (
          <Row display={"flex"} spacing={1} justifyContent={"flex-end"}>
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          </Row>
        )}
        <Row display={"flex"} justifyContent={"flex-end"}>
          <FlatButton label={"No"} onClick={close} />
          <Indent />
          <PrimaryButton
            label={"Yes, delete it"}
            onClick={onDeleteValidFromDate}
            loading={loading}
            data-testid={
              testIdConstantsWebapp.routePriceDeleteNewValidFromDateConfirmButton
            }
          />
        </Row>
      </Window>
    </>
  );
};
