import { NslhBookingDataFragment } from "@/gql/graphql";
import { ResultListBannerState } from "@stenajs-webui/elements";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { transformApolloErrorToFreightPricingPortalErrors } from "../../../common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import { HandlingCategories } from "./NoShowLateHandlingHandleBookingsScreen";
import { noShowLateHandlingHandleCustomerDataTableConfig } from "./config/NoShowLateHandlingHandleBookingsTableConfig";
import { NoShowLateHandlingHandleCustomerDataTableColumnKeys } from "./config/RowsAndColumnsConfig";
import { NoShowLateHandlingBookingsDataToTableRowTransformer } from "./transformers/NoShowLateHandlingBookingsDataToTableRowTransformer";

interface Props {
  noShowLateHandlingBookingsData: Array<NslhBookingDataFragment>;
  loading?: boolean;
  error?: ApolloError | undefined;
  handlingCategories: HandlingCategories;
  filterSearchApplied: boolean;
}

const sumReduce = (a: number, b: number) => {
  return a + b;
};

export const NoShowLateHandlingHandleBookingsCustomerDataTable: React.FC<
  Props
> = ({
  noShowLateHandlingBookingsData,
  loading,
  error,
  handlingCategories,
  filterSearchApplied,
}) => {
  const tableRowItems = useMemo(
    () =>
      noShowLateHandlingBookingsData?.map((csd) =>
        NoShowLateHandlingBookingsDataToTableRowTransformer(csd)
      ),
    [noShowLateHandlingBookingsData]
  );

  const { tableContext } = useLocalStateTableContext(
    "no-show-late-handling-handle-bookings-customer-data-table",
    createStandardTableInitialState<NoShowLateHandlingHandleCustomerDataTableColumnKeys>(
      "customerName",
      false
    )
  );

  useEffect(() => {
    tableContext.dispatch(tableContext.actions.collapseAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    numberOfToDo,
    numberOfToBeInvoiced,
    numberOfDoNotInvoice,
    numberOfNotChargeable,
    numberOfThreshold,
    numberOfInvoiced,
  } = useMemo(() => {
    return {
      numberOfToDo: noShowLateHandlingBookingsData
        .map((data) => data.noOfToDo)
        .reduce(sumReduce, 0),
      numberOfToBeInvoiced: noShowLateHandlingBookingsData
        .map((data) => data.noOfToBeInvoiced)
        .reduce(sumReduce, 0),
      numberOfInvoiced: noShowLateHandlingBookingsData
        .map((data) => data.noOfInvoiced)
        .reduce(sumReduce, 0),
      numberOfThreshold: noShowLateHandlingBookingsData
        .map((data) => data.noOfThreshold)
        .reduce(sumReduce, 0),
      numberOfDoNotInvoice: noShowLateHandlingBookingsData
        .map((data) => data.noOfDoNotInvoice)
        .reduce(sumReduce, 0),
      numberOfNotChargeable: noShowLateHandlingBookingsData
        .map((data) => data.noOfNotChargeable)
        .reduce(sumReduce, 0),
    };
  }, [noShowLateHandlingBookingsData]);

  const total =
    numberOfToDo +
    numberOfToBeInvoiced +
    numberOfInvoiced +
    numberOfThreshold +
    numberOfNotChargeable +
    numberOfDoNotInvoice;

  const config = useMemo(() => {
    return noShowLateHandlingHandleCustomerDataTableConfig({
      numberOfToDo,
      numberOfToBeInvoiced,
      numberOfInvoiced,
      numberOfThreshold,
      numberOfDoNotInvoice,
      numberOfNotChargeable,
      total,
      handlingCategories,
    });
  }, [
    numberOfToDo,
    numberOfToBeInvoiced,
    numberOfInvoiced,
    numberOfThreshold,
    numberOfDoNotInvoice,
    numberOfNotChargeable,
    total,
    handlingCategories,
  ]);

  const bannerState: ResultListBannerState | undefined = useMemo(() => {
    if (handlingCategories.error) {
      return {
        headerText: "Failed fetching handling categories",
        items: [{ text: handlingCategories.error }],
      };
    } else if (error) {
      return {
        headerText: error.message,
        items: transformApolloErrorToFreightPricingPortalErrors(error).flatMap(
          (err) => {
            return err.errors.map((err) => ({ text: err.message }));
          }
        ),
      };
    }

    return undefined;
  }, [error, handlingCategories.error]);
  return (
    <StandardTable
      loading={loading}
      bannerError={bannerState}
      items={tableRowItems ?? []}
      config={config}
      tableContext={tableContext}
      noItemsHeader={
        filterSearchApplied
          ? "Your search did not return any results"
          : "Search for bookings to handle"
      }
      noItemsLabel={
        filterSearchApplied
          ? "Try using less filter options to get results"
          : "Please use the filters to search for bookings"
      }
    />
  );
};
