import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";

import {
  Box,
  Column,
  Heading,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
} from "@stenajs-webui/core";
import { Card, CardHeader } from "@stenajs-webui/elements";
import { CustomerContactTable } from "./components/CustomerContactTable";

import { SpaceValues } from "@/common/utils/SpaceValues";
import StepContainer from "../../common/components/StepContainer";
import StepCardBody from "../../common/components/StepCardBody";
import { ReportScreenCustomerFragment } from "@/gql/graphql";

type CustomerDetailsStepProps = {
  customerSubscribers: ReportScreenCustomerFragment["subscriberContactsForReport"];
  customer: ReportScreenCustomerFragment | null | undefined;
  reportType: String;
  selectedCustomerSubscriber:
    | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
    | undefined;
  setSelectedCustomerSubscriber: React.Dispatch<
    React.SetStateAction<
      | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
      | undefined
    >
  >;
};
export const CustomerDetailsStep = ({
  customerSubscribers,
  reportType,
  customer,
  selectedCustomerSubscriber,
  setSelectedCustomerSubscriber,
}: CustomerDetailsStepProps) => {
  const customerFirstname = selectedCustomerSubscriber?.subscriber.firstName;
  const customerLastname = selectedCustomerSubscriber?.subscriber.surName;
  const customerFullname = "".concat(
    customerFirstname || "",
    " ",
    customerLastname || ""
  );

  const sellerFullName = "".concat(
    customer?.seller.sellerFirstName || "",
    " ",
    customer?.seller.sellerLastName || ""
  );
  return (
    <StepContainer>
      <CardHeader text="Select customer contact" />

      <StepCardBody>
        <CustomerContactTable
          customerContacts={customerSubscribers}
          setSelectedCustomerSubscriber={setSelectedCustomerSubscriber}
          selectedCustomerId={
            selectedCustomerSubscriber?.subscriber.id ||
            selectedCustomerSubscriber?.id ||
            ""
          }
        />
        <Space num={SpaceValues.TWENTYFOUR} />
        <Card minHeight={"300px"}>
          <Space num={SpaceValues.TWENTYFOUR} />
          <Box alignItems={"center"}>
            <Heading variant="h3">Freight Agreement {reportType}</Heading>
          </Box>
          <Indent num={IndentValues.TWENTYFOUR}>
            <Spacing num={SpacingValues.SIXTEEN} />
            <Heading variant="h4">{customer?.name}</Heading>
            <Spacing num={SpacingValues.EIGHT} />

            <Row justifyContent={"space-between"} width={"100%"}>
              <Column>
                <Box>
                  <Heading variant="h5">Stena Line contact details</Heading>
                  <Space num={SpaceValues.FOUR} />
                  <Text>{sellerFullName}</Text>
                  <Space num={SpaceValues.FOUR} />
                  {customer?.seller.sellerPhoneNumber ? (
                    <Text>{customer?.seller.sellerPhoneNumber || ""}</Text>
                  ) : (
                    <Text>{customer?.seller.sellerEmailAddress || ""}</Text>
                  )}
                </Box>
              </Column>
              <Column>
                <Box>
                  <Heading variant="h5">Customer contact details</Heading>
                  <Space num={SpaceValues.FOUR} />
                  <Text>{customerFullname}</Text>
                  <Space num={SpaceValues.FOUR} />
                  <Text>
                    {selectedCustomerSubscriber?.subscriber.telNo || ""}
                  </Text>
                  <Space num={SpaceValues.FOUR} />
                  <Text>{selectedCustomerSubscriber?.email || ""}</Text>
                </Box>
              </Column>
            </Row>
          </Indent>
          <Space num={SpaceValues.TWENTYFOUR} />
        </Card>
      </StepCardBody>
    </StepContainer>
  );
};
