import * as React from "react";
import { SwitchWithLabel, SwitchWithLabelProps } from "@stenajs-webui/forms";
import { RouteAgreement } from "../../../../features/product-and-price/customers/customer-details/types";
import { routeAgreementCanBeEditedByStatusCode } from "../../../../features/product-and-price/route-agreement/util/RouteAgreementCalculator";

interface Props extends SwitchWithLabelProps {
  routeAgreement: Pick<RouteAgreement, "statusCode">;
}

export const SwitchWithLabelForRouteAgreementSalesReady: React.FC<Props> = ({
  routeAgreement,
  disabled,
  ...switchProps
}) => {
  const disabledByRa = !routeAgreementCanBeEditedByStatusCode(
    routeAgreement.statusCode
  );

  return (
    <SwitchWithLabel disabled={disabledByRa || disabled} {...switchProps} />
  );
};
