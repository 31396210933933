import {
  CustomerInfoSearchQuery,
  CustomerInfoSearchQueryVariables,
  InfoSearchBySComerNumberOrIndexFragment,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { gql } from "apollo-boost";

const customerSearchQuery = gql`
  fragment infoSearchBySComerNumberOrIndex on Customer {
    id
    custIndex
    custStatus {
      id
      name
      code
    }
    custNo
    name
    parent {
      id
    }
  }
  query CustomerInfoSearch($query: String!) {
    productPrice {
      customers {
        byCustomerNumberOrIndex(query: $query) {
          ...infoSearchBySComerNumberOrIndex
        }
      }
    }
  }
`;
export type CustomerInfoResult = InfoSearchBySComerNumberOrIndexFragment;
export const useLoadCustomers = () => {
  const [fetchCustomers, { loading, data, error }] = useLazyQuery<
    CustomerInfoSearchQuery,
    CustomerInfoSearchQueryVariables
  >(customerSearchQuery);

  return { fetchCustomers, loading, data, error };
};
