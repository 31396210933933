import { PageLayout } from "@/common/components/page-layout/PageLayout";
import * as React from "react";
import { Helmet } from "react-helmet";
import { StatisticsBySalesRegionTable } from "./StatisticsBySalesRegionTable";

export const StatisticsBySalesRegionListPanel: React.FC = () => {
  return (
    <>
      <Helmet title="Sales region - Statistics - FPP" />
      <PageLayout>
        <StatisticsBySalesRegionTable />
      </PageLayout>
    </>
  );
};
