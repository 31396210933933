import * as React from "react";
import { Box, Column, Row, Space, Txt } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { DateRangeDualTextInput } from "@stenajs-webui/calendar";
import { CurrencyRadioButtonRow } from "../../../../../../common/components/data-driven-inputs/radio-buttons/CurrencyRadioButtonRow";
import { CreateMultiLegAgreementFormModel } from "./model/EditAndCreateMultiLegAgreementFormModel";
import { EditMultiLegAgreementRouteSelector } from "./edit-multi-leg-agreement-form-sections/EditMultiLegAgreementRouteSelector";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  formData: CreateMultiLegAgreementFormModel;
  setFields: (value: Partial<CreateMultiLegAgreementFormModel>) => void;
}

export const CreateMultiLegAgreementForm: React.FC<Props> = ({
  formData,
  setFields,
}) => {
  return (
    <Column>
      <Row alignItems={"flex-start"}>
        <Label text={"Select ML route"}>
          <Box width={"500px"}>
            <EditMultiLegAgreementRouteSelector
              value={formData.routeCode}
              onValueChange={(routeCode: string, name: string) =>
                setFields({ routeCode, name })
              }
            />
          </Box>
        </Label>
      </Row>
      <Space num={4} />
      <Label text={"Name"} optional={true}>
        <Box width={"500px"}>
          <TextInput
            autoFocus
            value={formData.name}
            onValueChange={(name) => setFields({ name })}
          />
        </Box>
      </Label>
      <Space num={4} />
      <Txt
        color={cssColor("--lhds-color-ui-600")}
        size={"small"}
        whiteSpace={"nowrap"}
      >
        Validity (will apply to route agreements as well)
      </Txt>
      <Space />
      <Box width={"fit-content"}>
        <DateRangeDualTextInput
          value={formData.valid}
          onValueChange={(valid) => setFields({ valid })}
        />
      </Box>
      <Space num={4} />
      <Label
        text={"Agreement currency (will apply to route agreements as well)"}
      >
        <Box>
          <CurrencyRadioButtonRow
            value={formData.currencyCode}
            onValueChange={(currencyCode) => setFields({ currencyCode })}
          />
        </Box>
      </Label>
      <Space num={4} />
      <Box width={"500px"}>
        <Txt size={"small"}>
          {
            "Note: Route agreements will be automatically created for this multi leg. One for each leg."
          }
        </Txt>
      </Box>
      <Space num={4} />
    </Column>
  );
};
