import { useQuery } from "@apollo/client";
import { Column, Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocalError } from "../../../../../common/error/UseLocalError";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";
import { clearPriceStatisticsForRouteAgreementPrice } from "../../../route-agreement/details/features/routes/thunks/ClearAllPriceStatisticsForRouteAgreement";
import { rateSheetTableActions } from "../../actions";
import { RateSheetTableRowState } from "../../reducer";
import { rateSheetStandardTableWrapperActions } from "../../redux";
import { fetchActualChangeForSinglePriceRow } from "../../thunks/FetchActualChangeForSinglePriceRow";
import {
  createRouteAgreementPriceCalculateStatisticsInput,
  fetchStatisticsForSinglePriceRow,
} from "../../thunks/FetchStatisticsForSinglePriceRow";
import {
  transformFormModelToPrice,
  transformPriceToFormModel,
} from "../transformers/FormModelTransformer";
import { EditRate } from "./EditRate";
import { RateEditPanelQuery, RateEditPanelQueryVariables } from "@/gql/graphql";

interface Props {
  onRequestClose: () => void;
  rateSheetTableRowState: RateSheetTableRowState;
  routeAgreementId: string;
  routeAgreementPriceId: string;
  currencyLabel?: string;
  disableStatBoxes?: boolean;
}

const query = gql`
  query RateEditPanel($routeAgreementId: ID!) {
    productPrice {
      vehicleType {
        all {
          id
          code
          name
          shortDescription
          accompanied
        }
      }
      routeAgreement {
        byId(id: $routeAgreementId) {
          id
          sailingTypeCodes
          valid {
            start {
              isoString
            }
          }
          routes {
            id
            code
          }
          currency {
            id
            code
          }
          headAgreement {
            id
            customer {
              id
            }
          }
        }
      }
    }
  }
`;

export const RateEditPanelVehicle: React.FC<Props> = ({
  rateSheetTableRowState,
  routeAgreementId,
  routeAgreementPriceId,
  onRequestClose,
  disableStatBoxes,
}) => {
  const dispatch = useDispatch();
  const [isApplyingRateChange, setIsApplyingRateChange] =
    useState<boolean>(false);
  const { clearLocalError, setLocalError, localError } = useLocalError();
  const [editFormModel, setEditFormModel] = useState(() =>
    transformPriceToFormModel(rateSheetTableRowState.editable)
  );

  const { error, loading, data } = useQuery<
    RateEditPanelQuery,
    RateEditPanelQueryVariables
  >(query, {
    variables: {
      routeAgreementId,
    },
  });

  const allVehicleTypes = data?.productPrice.vehicleType.all ?? [];
  const routeAgreement = data?.productPrice.routeAgreement.byId;

  const onSuccessCallback = async () => {
    await dispatch(
      fetchActualChangeForSinglePriceRow(
        routeAgreementId,
        routeAgreementPriceId
      )
    );

    if (!disableStatBoxes) dispatch(refetchStatBoxesWithRateSheetsChanges());
    onRequestClose();
    setIsApplyingRateChange(false);
  };

  const onClickApplyChanges = async () => {
    setIsApplyingRateChange(true);
    clearLocalError();
    try {
      await dispatch(
        rateSheetStandardTableWrapperActions.tableRows.recordAction(
          routeAgreementId,
          rateSheetTableActions.setEditablePriceFields(
            routeAgreementPriceId,
            transformFormModelToPrice(editFormModel, allVehicleTypes)
          )
        )
      );

      await dispatch(
        clearPriceStatisticsForRouteAgreementPrice(
          routeAgreementId,
          routeAgreementPriceId
        )
      );

      if (routeAgreement) {
        await dispatch(
          fetchStatisticsForSinglePriceRow(
            createRouteAgreementPriceCalculateStatisticsInput(
              routeAgreement,
              routeAgreementPriceId,
              editFormModel
            )
          )
        );
      }
      await onSuccessCallback();
    } catch (e) {
      setLocalError(e);
      setIsApplyingRateChange(false);
    }
  };

  if (loading) {
    return (
      <Spacing num={6}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (localError) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={localError.message} />
      </Spacing>
    );
  }

  return (
    <Column>
      <EditRate
        value={editFormModel}
        onValueChange={setEditFormModel}
        onClickApplyChanges={onClickApplyChanges}
        loading={isApplyingRateChange}
      />
    </Column>
  );
};
