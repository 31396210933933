import { Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { CustomerSelect } from "../../../../../../../../../common/components/data-driven-inputs/selects/customer-select/CustomerSelect";
import { HeadAgreementByCustomerIdSelect } from "../../../../../../../../../common/components/data-driven-inputs/selects/head-agreement-by-customer-select/HeadAgreementByCustomerSelect";
import { PageHeaderItem } from "../../../../../../../../../common/components/page-header/PageHeaderItem";
import { FormModelChangeHandler } from "../../../../../../../../../common/forms/types";
import { MultiLegAgreement } from "../../../../../types";
import { CopyMultiLegAgreementParams } from "./SelectCustomerAndHeadAgreementModalForMultiLeg";

interface CopyToOtherCustomerFormProps {
  formData: Partial<CopyMultiLegAgreementParams>;
  onChange: FormModelChangeHandler<Partial<CopyMultiLegAgreementParams>>;
  multiLegAgreement: MultiLegAgreement;
  onCancel: () => void;
  onRequestSubmit: () => void;
}

export const CopyToOtherCustomerFormMultiLeg: React.FC<
  CopyToOtherCustomerFormProps
> = ({ multiLegAgreement, formData, onChange, onRequestSubmit, onCancel }) => {
  return (
    <Column indent={2} spacing={2}>
      <Label text={"Multi leg agreement to copy"}>
        <Row>
          <PageHeaderItem
            label={"Route"}
            value={multiLegAgreement.route.code}
          />
          <Space num={3} />
          <PageHeaderItem
            label={"Agr. no."}
            value={multiLegAgreement.idNumber}
          />
          <Space num={3} />
          <PageHeaderItem label={"Name"} value={multiLegAgreement.name} />
        </Row>
      </Label>
      <Spacing num={2} />
      <Column>
        <Label text={"Select target customer"}>
          <CustomerSelect
            value={formData.customer?.id}
            onValueChange={(option) => onChange("customer", option)}
          />
        </Label>
      </Column>
      <Spacing num={2} />
      <Column>
        <Label text={"Select target header agreement"}>
          <HeadAgreementByCustomerIdSelect
            key={formData.customer?.id}
            customerId={formData.customer?.id}
            onValueChange={(option) => onChange("headAgreement", option)}
            value={formData.headAgreement?.id}
          />
        </Label>
      </Column>

      <Spacing num={2} />

      <Row justifyContent={"flex-end"}>
        <FlatButton onClick={onCancel} label={"Cancel"} />
        <Indent />
        <PrimaryButton
          disabled={!formData.headAgreement}
          onClick={onRequestSubmit}
          label={"Copy agreement"}
        />
      </Row>
    </Column>
  );
};
