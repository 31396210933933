import React, { PropsWithChildren, createContext, useState } from "react";
import { createFPPSearchFilterSelectors } from "../redux/selectors";
import { WorkspaceState } from "../redux/reducer";
import { SearchFilterState as WebUiSearchFilterState } from "@stenajs-webui/filter";
import { useSelector } from "react-redux";

export interface SearchFilterSelectorContextType {
  workspaceState: WebUiSearchFilterState<WorkspaceState>;
  committedState: WorkspaceState;
  isCommitted: boolean;
  setIsCommitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchFilterSelectorContext =
  createContext<SearchFilterSelectorContextType | null>(null);

const SearchFilterSelectorProvider = ({
  children,
  reducerId,
}: PropsWithChildren<{ reducerId: string }>) => {
  const [isCommitted, setIsCommitted] = useState(false);
  const committedState = useSelector(
    createFPPSearchFilterSelectors(reducerId).getCommittedState
  );
  const workspaceState = useSelector(
    createFPPSearchFilterSelectors(reducerId).getWorkspaceState
  );

  return (
    <SearchFilterSelectorContext.Provider
      value={{ committedState, workspaceState, isCommitted, setIsCommitted }}
    >
      {children}
    </SearchFilterSelectorContext.Provider>
  );
};

export { SearchFilterSelectorProvider, SearchFilterSelectorContext };
