import { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { rateSheetTableSelectors } from "../../selectors";
import { raisePriceForRouteAgreementPrice } from "../../bulk-update-rates/hooks/UseBulkUpdateRatesLogic";
import { BulkUpdateRatesFormModel } from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";

export const useRaisePriceLogic = () => {
  const dispatch = useDispatch();
  const rateSheetState = useSelector(rateSheetTableSelectors.getTableState);

  const raisePriceInModifiedState = useCallback(
    (
      routeAgreementId: string,
      routeAgreementPriceId: string,
      formModel: BulkUpdateRatesFormModel
    ) => {
      batch(() => {
        const state = rateSheetState[routeAgreementId];
        const rateSheetTableRowState = state?.rows.find(
          (s) => s.routeAgreementPriceId === routeAgreementPriceId
        );
        if (rateSheetTableRowState) {
          raisePriceForRouteAgreementPrice(
            dispatch,
            routeAgreementId,
            routeAgreementPriceId,
            rateSheetTableRowState,
            formModel
          );
        }
      });
    },
    [dispatch, rateSheetState]
  );

  return {
    raisePriceInModifiedState,
  };
};
