import { PageLayout } from "@/common/components/page-layout/PageLayout";
import * as React from "react";
import { Helmet } from "react-helmet";
import { StatisticsBySellerTable } from "./StatisticsBySellerTable";
import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";

export const StatisticsBySellerListPanel: React.FC = () => {
  return (
    <>
      <Helmet title="Seller - Statistics - FPP" />
      <PageLayout>
        <SearchFilterSelectorProvider
          reducerId={"statistics.statisticsBySeller"}
        >
          <StatisticsBySellerTable />
        </SearchFilterSelectorProvider>
      </PageLayout>
    </>
  );
};
