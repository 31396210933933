import { ArticlePrice, ArticlePriceValidity } from "../types";
import {
  ArticlePriceFormModel,
  ArticlePriceValidityFormModel,
} from "./ArticlesFormModel";
import {
  MAX_LENGTH,
  MAX_WEIGHT,
  MIN_LENGTH,
  MIN_WEIGHT,
} from "../../../../../rate-sheet/add-vehicle-type/models/AddVehicleTypeFormModel";
import { parseISO } from "date-fns";

export const createEmptyArticlesPriceFormModel = (
  id: string
): ArticlePriceFormModel => ({
  id,
  persisted: false,
  deleted: false,
  vehicleTypeId: null,
  lengthFrom: String(MIN_LENGTH),
  lengthTo: String(MAX_LENGTH),
  price: "",
  percent: "",
  weightFrom: String(MIN_WEIGHT),
  weightTo: String(MAX_WEIGHT),
});

export const transformArticlePriceToFormModel = (
  articlePrice: ArticlePrice
): ArticlePriceFormModel => ({
  id: articlePrice.id,
  weightTo: String(articlePrice.weightTo),
  weightFrom: String(articlePrice.weightFrom),
  price: String(articlePrice.price.amount),
  percent: String(articlePrice.percent),
  persisted: true,
  deleted: false,
  lengthTo: String(articlePrice.lengthTo),
  lengthFrom: String(articlePrice.lengthFrom),
  vehicleTypeId: articlePrice.vehicleType?.id ?? null,
});

export const transformArticlePriceValidityToFormModel = (
  articlePriceValidity: ArticlePriceValidity
): ArticlePriceValidityFormModel => ({
  id: articlePriceValidity.id,
  price: String(articlePriceValidity.price),
  percentage: String(articlePriceValidity.percentage),
  validFrom: articlePriceValidity.validFrom,
  validFromDate: articlePriceValidity.validFrom
    ? parseISO(articlePriceValidity.validFrom)
    : null,
  agrRouteArticlePriceId: articlePriceValidity.agrRouteArticlePriceId,
});
