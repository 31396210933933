import React, { Fragment } from "react";
import { Row, Space, Text } from "@stenajs-webui/core";
import { SailingStatusChip } from "../../../../common/components/chips/SailingStatusChip";
import { SecondaryButton, stenaAngleRight } from "@stenajs-webui/elements";
import { routeFactory } from "../../../../RouteFactory";
import { RateSheetTable } from "../../rate-sheet/components/RateSheetTable";
import { useNavigate } from "react-router-dom";
import { RouteAgreementsForMultilegRouteFragment } from "@/gql/graphql";

interface Props {
  headAgreement: RouteAgreementsForMultilegRouteFragment["headAgreement"];
  routeAgreement: RouteAgreementsForMultilegRouteFragment["routeAgreements"]["0"];
  multiLegAgreement: RouteAgreementsForMultilegRouteFragment;
  customerId: string;
  bulkUpdateModeEnabled: boolean;
}

export const CustomerMultilegRouteRateSheetListItem: React.FC<Props> =
  React.memo(
    ({
      routeAgreement,
      headAgreement,
      multiLegAgreement,
      customerId,
      bulkUpdateModeEnabled,
    }) => {
      const navigate = useNavigate();
      return (
        <RateSheetTable
          hideBulkUpdateButton
          routeAgreementId={routeAgreement.id}
          routeAgreement={routeAgreement}
          multiLegAgreement={multiLegAgreement}
          currencyLabel={routeAgreement.currency.code}
          bulkUpdateModeEnabled={bulkUpdateModeEnabled}
          isMultiLegRates={true}
          addVehicleTypeToAllRoutesRadioButton={true}
          disableStatBoxes={true}
          headerLeft={
            <Row alignItems={"center"}>
              <Text size={"large"}>{routeAgreement.agreementNumber}</Text>
              <Space num={2} />
              <Text size={"large"}>{routeAgreement.routeDescription}</Text>
            </Row>
          }
          headerCenter={
            <Row alignItems={"center"}>
              {routeAgreement.sailingTypeCodes.map((sailingTypeCode) => (
                <Fragment key={sailingTypeCode}>
                  <Space num={2} />
                  <SailingStatusChip statusCode={sailingTypeCode} />
                </Fragment>
              ))}
              <Space num={1} />
              <Text size={"small"}>{routeAgreement.name}</Text>
            </Row>
          }
          headerRight={
            <SecondaryButton
              label={"To agreement"}
              rightIcon={stenaAngleRight}
              onClick={() =>
                navigate(
                  routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
                    {
                      customerId,
                      headAgreementId: headAgreement.id,
                      routeAgreementId: routeAgreement.id,
                      multiLegAgreementId: multiLegAgreement.id,
                      tab: "rates",
                    }
                  )
                )
              }
            />
          }
        />
      );
    }
  );
