import { useSelector } from "react-redux";
import { useOrderedCustomerTargetTableRows } from "../features/route-pair-target-table/hooks/UseOrderedCustomerTargetTableRows";
import { StoreState } from "../../../../config/redux/RootReducer";
import { isDefined } from "@/common/types/IsDefined";

export const useRoutePairTarget = () => {
  const loading = useSelector(
    (state: StoreState) =>
      state.routeTarget.list.routeCustomerSortLoadState.loading
  );

  const filteredRouteCustomerTargetData =
    useOrderedCustomerTargetTableRows().filter(isDefined);

  return {
    data: filteredRouteCustomerTargetData,
    loading: loading,
  };
};
