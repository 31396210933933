import { gql, useQuery } from "@apollo/client";
import {
  StatisticsSearchRoutePairListByFilterOptions,
  UseRoutePairListQuery,
  UseRoutePairListQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment NumberVolumeStatisticsRoutePair on NumberVolumeStatisticsItem {
    percentageOfAccount
    percentageOfVolume
  }
  fragment RoutePairListItems on StatisticsByRoutePairListItem {
    routePair {
      id
      code
      description
    }
    negotiationStatusStatistics {
      accounts
      statistics {
        negotiationStatus {
          id
          name
        }
        numberVolumeStatistics {
          ...NumberVolumeStatisticsRoutePair
        }
      }
    }
  }
  query UseRoutePairList(
    $filterOptions: StatisticsSearchRoutePairListByFilterOptions!
  ) {
    statistics {
      routePairList {
        byFilter(filterOptions: $filterOptions) {
          items {
            ...RoutePairListItems
          }
        }
      }
    }
  }
`;

export const useRoutePairListQuery = (
  filterOptions: StatisticsSearchRoutePairListByFilterOptions
) => {
  const { error, loading, data } = useQuery<
    UseRoutePairListQuery,
    UseRoutePairListQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    skip: filterOptions.routePairIds.length === 0 || filterOptions.year == null,
  });

  return {
    error,
    loading,
    routePairItems: data?.statistics.routePairList.byFilter?.items,
  };
};
