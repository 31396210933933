import { HeadAgreementStatusCode } from "@/gql/graphql";
import { DateRange } from "@stenajs-webui/calendar";
import { endOfYear, startOfYear } from "date-fns";

export interface HeadAgreementFormModel {
  name: string;
  valid: DateRange;
  headAgreementNumber?: number;
  statusCode?: HeadAgreementStatusCode;
  specifications: string;
  condition: string;
  lastPage: string;
  internalNotes?: string;
}

export const createEmptyHeadAgreementFormModel = (
  name: string = ""
): HeadAgreementFormModel => ({
  name,
  statusCode: HeadAgreementStatusCode.Pending,
  valid: {
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  },
  condition: "",
  lastPage: "",
  specifications: "",
});
