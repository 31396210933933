import { stripDateString } from "@/common/dates/formatters";
import { Indent } from "@stenajs-webui/core";
import { ResultListBannerState } from "@stenajs-webui/elements";
import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { cssColor } from "@stenajs-webui/theme";
import { differenceInMonths } from "date-fns";
import { routePairLabelWithDescriptionForTables } from "../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import { createStandardTableCell } from "../../../targeting-tool/admin-target/components/all-routes/AllRoutesTableStatisticsStandardCell";
import { NoShowLateHandlingCreateValidFromDateButton } from "../NoShowLateHandlingCreateValidFromDateButton";
import { NoShowLateHandlingDeleteValidFromDate } from "../NoShowLateHandlingDeleteValidFromDate";
import { createNoShowLateHandlingTableCellRenderer } from "../NoShowLateHandlingRoutePricesTableCell";
import { NoShowLateHandlingValidFromSelect } from "../NoShowLateHandlingValidFromSelect";
import { dataHasBeenModified } from "../util/DataHasBeenModified";
import {
  addAndDeleteValidFromColumnOrder,
  lateHandlingColumnOrder,
  noLabelColumnOrder,
  noShowColumnOrder,
} from "./NoShowLateHandlingTableColumnGroups";
import {
  NoShowLateHandlingPricesRow,
  NoShowLateHandlingTableColumnKeys,
} from "./RowsAndColumnsConfig";

interface NoShowLateHandlingRoutePricesTableConfigVariables {
  updateSelectedRows: (previousId: string, id: string) => void;
  bannerState?: ResultListBannerState;
}

const currentDate = new Date();

const isCellEditable = (validFrom: string) => {
  const persistedDate = new Date(validFrom);
  const difference = differenceInMonths(currentDate, persistedDate);

  return difference <= 0;
};

export const noShowLateHandlingRoutePricesTableConfig = ({
  updateSelectedRows,
  bannerState,
}: NoShowLateHandlingRoutePricesTableConfigVariables) => {
  const noShowLateHandlingTableConfig: StandardTableConfigWithGroups<
    EditableEntityState<NoShowLateHandlingPricesRow>,
    NoShowLateHandlingTableColumnKeys
  > = {
    disableInfiniteList: true,
    disableSorting: true,
    enableGridCell: true,
    stickyColumnGroups: "both",
    stickyHeader: true,
    headerRowOffsetTop: bannerState ? "266px" : "153px",
    keyResolver: (item) => item.editable.routePairCode,
    rowBackgroundResolver: (item) =>
      item.editable.newlyAddedRow
        ? cssColor("--lhds-color-blue-50")
        : undefined,
    columns: {
      indentLeft: createColumnConfig((item) => "", {
        columnLabel: "",
        width: "16px",
        disableGridCell: true,
      }),
      routePairCode: createColumnConfig((item) => item.editable.routePairCode, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.editable.routePairCode),
        columnLabel: "Route",
        disableGridCell: true,
        width: "80px",
      }),
      routePair: {
        itemValueResolver: (item) => item.editable.routePair,
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          routePairLabelWithDescriptionForTables(
            item.editable.routePair?.description ?? ""
          ),
        columnLabel: "Directions",
        width: "220px",
        disableGridCell: true,
      },
      validFrom: createColumnConfig((item) => item.editable.validFrom, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => (
          <Indent num={1} className={"validFromColumn"}>
            <NoShowLateHandlingValidFromSelect
              isDisabled={dataHasBeenModified(item)}
              containerWidth={"130px"}
              routePairCode={item.editable.routePairCode}
              onValueChange={(previousId, id) =>
                updateSelectedRows(previousId, id)
              }
            />
          </Indent>
        ),
        columnLabel: "Valid from",
        disableGridCell: true,
        width: "145px",
      }),
      noShowEur: createColumnConfig((item) => item.editable.noShowEur, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowEur"),
        columnLabel: "EUR",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      noShowGbp: createColumnConfig((item) => item.editable.noShowGbp, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowGbp"),
        columnLabel: "GPB",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      noShowSek: createColumnConfig((item) => item.editable.noShowSek, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowSek"),
        columnLabel: "SEK",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      noShowDkk: createColumnConfig((item) => item.editable.noShowDkk, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowDkk"),
        columnLabel: "DKK",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      noShowNok: createColumnConfig((item) => item.editable.noShowNok, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowNok"),
        columnLabel: "NOK",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      noShowPln: createColumnConfig((item) => item.editable.noShowPln, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: createNoShowLateHandlingTableCellRenderer("noShowPln"),
        columnLabel: "PLN",
        minWidth: "50px",
        isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
      }),
      lateHandlingEur: createColumnConfig(
        (item) => item.editable.lateHandlingEur,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingEur"),
          columnLabel: "EUR",
          minWidth: "50px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),
      lateHandlingGbp: createColumnConfig(
        (item) => item.editable.lateHandlingGbp,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingGbp"),
          columnLabel: "GBP",
          minWidth: "50px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),
      lateHandlingSek: createColumnConfig(
        (item) => item.editable.lateHandlingSek,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingSek"),
          columnLabel: "SEK",
          minWidth: "50px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),

      lateHandlingDkk: createColumnConfig(
        (item) => item.editable.lateHandlingDkk,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingDkk"),
          columnLabel: "DKK",
          minWidth: "90px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),
      lateHandlingNok: createColumnConfig(
        (item) => item.editable.lateHandlingNok,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingNok"),
          columnLabel: "NOK",
          minWidth: "50px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),
      lateHandlingPln: createColumnConfig(
        (item) => item.editable.lateHandlingPln,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell:
            createNoShowLateHandlingTableCellRenderer("lateHandlingPln"),
          columnLabel: "PLN",
          minWidth: "50px",
          isEditable: ({ persisted }) => isCellEditable(persisted.validFrom),
        }
      ),
      dateCreated: createColumnConfig((item) => item.editable.dateCreated, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(stripDateString(item.editable.dateCreated)),
        columnLabel: "Date created",
        disableGridCell: true,
        minWidth: "120px",
      }),
      createdBy: createColumnConfig((item) => item.editable.createdBy, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.editable.createdBy),
        columnLabel: "Created by",
        disableGridCell: true,
        minWidth: "120px",
      }),
      addValidFrom: createColumnConfig((item) => item, {
        justifyContentCell: "flex-start",
        columnLabel: "",
        renderCell: ({ label, item }) => (
          <Indent num={1} className="addValidFromColumn">
            <NoShowLateHandlingCreateValidFromDateButton
              routePairCode={item.persisted.routePairCode}
              disabled={dataHasBeenModified(item)}
            />
          </Indent>
        ),
        width: "40px",
        disableGridCell: true,
      }),
      deleteValidFrom: createColumnConfig((item) => item, {
        justifyContentCell: "flex-start",
        columnLabel: "",
        renderCell: ({ label, item }) => (
          <div className="deleteValidFromColumn">
            <NoShowLateHandlingDeleteValidFromDate
              routePairCode={item.editable.routePairCode}
              item={item}
            />
          </div>
        ),
        width: "40px",
        disableGridCell: true,
      }),
      indentRight: createColumnConfig((item) => "", {
        columnLabel: "",
        disableGridCell: true,
        width: "16px",
      }),
    },
    columnGroups: {
      noLabelGroup: {
        columnOrder: noLabelColumnOrder,
      },
      noShowColumnOrder: {
        borderLeft: true,
        columnOrder: noShowColumnOrder,
        label: "No Show",
      },
      lateHandlingColumnOrder: {
        borderLeft: true,
        columnOrder: lateHandlingColumnOrder,
        label: "Late Handling (cancel & transfer)",
      },
      addAndDeleteValidFromColumnOrder: {
        borderLeft: true,
        columnOrder: addAndDeleteValidFromColumnOrder,
      },
    },
    columnGroupOrder: [
      "noLabelGroup",
      "noShowColumnOrder",
      "lateHandlingColumnOrder",
      "addAndDeleteValidFromColumnOrder",
    ],
  };

  return noShowLateHandlingTableConfig;
};
