import * as React from "react";
import { AllRoutesTableStatistics } from "./components/all-routes/AllRoutesTableStatistics";
import { AdminTargetHeader } from "./AdminTargetHeader";
import { StoreState } from "../../../config/redux/RootReducer";
import { useSelector } from "react-redux";
import { profileSelectors } from "../../../common/redux/profile/selectors";
import { TARGET_ADMIN_CLAIM } from "../../../common/claims/constants";
import { hasClaim } from "../../../common/claims/utils";
import { UnAuthorizedView } from "../../../common/components/unauthorized-view/UnAuthorizedView";
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import { usePrompt } from "@/common/hooks/usePrompt";

interface Props {}

export const AdminTargetScreen: React.FC<Props> = () => {
  const selector = (state: StoreState) => state.adminTarget.allRoutesData;
  const allRoutesData = useSelector(selector);
  const { claims } = useSelector(profileSelectors.getGlobal);

  const unSavedChangedExist = allRoutesData.some((ard) => {
    return JSON.stringify(ard.editable) !== JSON.stringify(ard.persisted);
  });

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: unSavedChangedExist,
  });

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Group Freight Targets" />
      <AdminTargetHeader />
      {hasClaim(claims, [TARGET_ADMIN_CLAIM]) ? (
        <AllRoutesTableStatistics />
      ) : (
        <UnAuthorizedView />
      )}
    </>
  );
};
