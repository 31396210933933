import * as React from "react";
import { stenaCheck, Tab as WebUiTab } from "@stenajs-webui/elements";
import { useMatch, useNavigate } from "react-router-dom";

interface Props {
  label: string;
  path: string;
  hasBadge?: boolean;
  dataTestid?: string;
}

export const Tab: React.FC<Props> = ({
  path,
  label,
  hasBadge = false,
  dataTestid,
}) => {
  const navigate = useNavigate();
  const match = useMatch(path);
  return (
    <WebUiTab
      label={label}
      onClick={() => navigate(path)}
      selected={!!match}
      leftIcon={hasBadge ? stenaCheck : undefined}
      data-testid={dataTestid}
    />
  );
};
