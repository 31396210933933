import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { groupBy, values } from "lodash";
import { OptionProps, components } from "react-select";
import { Tag, TagVariant } from "@stenajs-webui/elements";
import { Box, Indent, Row } from "@stenajs-webui/core";
import {
  RouteAgreementsByRoutePairFragment,
  RoutePairFragment,
  RoutePairInSeaAreaSelectQuery,
  RoutePairInSeaAreaSelectQueryVariables,
} from "@/gql/graphql";

interface Props extends Omit<SelectProps<unknown>, "value" | "onChange"> {
  value?: string;
  headAgreementId: string;
  onValueChange?: (value: string) => void;
}

const query = gql`
  fragment RoutePair on RoutePair {
    id
    seaArea {
      id
      name
    }
  }
  fragment RouteAgreementsByRoutePair on RouteAgreement {
    id
    isSalesReady
    routes {
      id
      routePair {
        id
      }
    }
  }
  query RoutePairInSeaAreaSelect($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          routeAgreements {
            ...RouteAgreementsByRoutePair
          }
          routePairs {
            ...RoutePair
          }
        }
      }
    }
  }
`;

interface RoutePairOption {
  label: string;
  value: string;
  totalCount: number;
  salesReadyCount: number;
}
const transformRoutePairToOption = (
  r: RoutePairFragment,
  routeAgreements: RouteAgreementsByRoutePairFragment[]
): RoutePairOption => ({
  label: r.id,
  value: r.id,
  salesReadyCount: routeAgreements.filter((ra) => ra.isSalesReady).length,
  totalCount: routeAgreements.length,
});

const Option = (props: OptionProps) => {
  const { totalCount, salesReadyCount } = props.data as RoutePairOption;
  const tagVariant: TagVariant =
    totalCount === salesReadyCount ? "success" : "info";
  return (
    <components.Option {...props}>
      <Row>
        <Box minWidth={"35px"}>{props.children}</Box>
        <Indent num={0.5} />
        <Tag
          label={`${salesReadyCount} / ${totalCount}`}
          variant={tagVariant}
          size={"small"}
        />
      </Row>
    </components.Option>
  );
};

export const RoutePairInSeaAreaSelect: React.FC<Props> = ({
  onValueChange,
  value,
  headAgreementId,
  ...selectProps
}) => {
  const { data, loading } = useQuery<
    RoutePairInSeaAreaSelectQuery,
    RoutePairInSeaAreaSelectQueryVariables
  >(query, {
    variables: { headAgreementId },
  });

  const routePairs = useMemo(() => {
    return data?.productPrice.headAgreement.byId?.routePairs ?? [];
  }, [data]);

  const routeAgreementsByRoutePair = useMemo(() => {
    return groupBy(
      data?.productPrice.headAgreement.byId?.routeAgreements ?? [],
      (ra) => ra.routes[0]?.routePair.id
    );
  }, [data]);

  const allOptions = useMemo(
    () =>
      routePairs.map((rp) =>
        transformRoutePairToOption(rp, routeAgreementsByRoutePair[rp.id] ?? [])
      ),
    [routePairs, routeAgreementsByRoutePair]
  );

  const options = useMemo(() => {
    return values(groupBy(routePairs, (rp) => rp.seaArea.id))
      .map((rps) => ({
        label: rps[0].seaArea.name ?? "",
        options: rps.map((rp) =>
          transformRoutePairToOption(
            rp,
            routeAgreementsByRoutePair[rp.id] ?? []
          )
        ),
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [routePairs, routeAgreementsByRoutePair]);

  const selected = useMemo(
    () => allOptions.find((o) => o.value === value),
    [allOptions, value]
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected as any}
      menuPortalTarget={document.body}
      onChange={(v: any) => onValueChange?.(v.value)}
      {...selectProps}
      components={{ Option }}
    />
  );
};
