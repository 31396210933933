export const limitStringLength = (s: string, max: number): string => {
  if (s.length > max) {
    return `${s.substr(0, max - 1)}…`;
  }
  return s;
};

export const limitStringElseThrow = (
  s: string,
  max: number,
  fieldName?: string
): string => {
  if (s.length > max) {
    throw new Error(
      fieldName
        ? `Field "${fieldName}" is too long, max ${max} characters is allowed.`
        : `Entered text is too long, max ${max} characters is allowed.`
    );
  }
  return s;
};
