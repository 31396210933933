import {
  SearchFilterState,
  createFppSearchFilterReducer,
} from "@/features/search-filter/redux/reducer";
import { combineReducers } from "redux";

export interface BulkEditRatesFilterState {
  filter: SearchFilterState;
}

export const bulkEditRatesFilterReducer =
  combineReducers<BulkEditRatesFilterState>({
    filter: createFppSearchFilterReducer("bulkEditRatesFilter"),
  });
