import { Box, Indent, Row, Spacing, Txt } from "@stenajs-webui/core";
import { RadioButton } from "@stenajs-webui/forms";
import * as React from "react";

interface Props {
  isMultiLeg?: boolean;
  addToAllRouteAgreements?: boolean;
  setAddToAllRouteAgreements: (active: boolean) => void;
}

export const AddVehicleToAllRouteAgrRadioButton: React.FC<Props> = ({
  isMultiLeg,
  addToAllRouteAgreements,
  setAddToAllRouteAgreements,
}) => {
  return (
    <Box>
      <Row>
        <RadioButton
          checked={addToAllRouteAgreements === true}
          onClick={() => setAddToAllRouteAgreements(true)}
        />
        <Indent num={0.5} />
        <Txt size={"medium"}>{"Yes"}</Txt>
      </Row>
      <Spacing num={1} />
      <Row>
        <RadioButton
          checked={addToAllRouteAgreements === false}
          onClick={() => setAddToAllRouteAgreements(false)}
        />
        <Indent num={0.5} />
        <Txt size={"medium"}>{"No"}</Txt>
      </Row>
    </Box>
  );
};
