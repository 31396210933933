import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Indent, Space, Txt } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  Link,
} from "@stenajs-webui/elements";
import { TextArea } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import parse, {
  HTMLReactParserOptions,
  Element as ParserElement,
  domToReact,
} from "html-react-parser";
import StepContainer from "../../common/components/StepContainer";
import StepCardBody from "../../common/components/StepCardBody";
import { ReportScreenQuery } from "@/gql/graphql";

const ConditionsStep = ({
  reportType,
  conditionsTexts,
  setConditionsTexts,
}: {
  reportType: string;
  conditionsTexts: {
    condition: string;
    agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
  };
  setConditionsTexts: React.Dispatch<
    React.SetStateAction<{
      condition: string;
      agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
    }>
  >;
}) => {
  const getAgreementText = () => {
    if (reportType === "offer") {
      return conditionsTexts.agreementTexts.find(
        (text) => text.code === "AG_FIRSTOF"
      );
    } else
      return conditionsTexts.agreementTexts.find(
        (text) => text.code === "AG_FIRST"
      );
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof ParserElement && domNode.attribs) {
        if (domNode.name === "b") {
          return (
            <Txt variant="bold">{domToReact(domNode.children, options)}</Txt>
          );
        }
        if (domNode.name === "a") {
          return (
            <Link
              href={"https://www.stenalinefreight.com/general-terms"}
              target={"_blank"}
            >
              www.stenalinefreight.com/general-terms
            </Link>
          );
        }
        if (domNode.name === "p") {
          return (
            <>
              <Txt>{domToReact(domNode.children, options)}</Txt>
              <Space num={SpaceValues.TWELVE} />
            </>
          );
        }
      }
    },
  };

  return (
    <StepContainer>
      <CardHeader text="Additional conditions" />
      <StepCardBody>
        <Card>
          <CardBody>
            {parse(getAgreementText()?.value ?? "", options)}
            <Space num={SpaceValues.TWENTYFOUR} />
            <Box
              background={cssColor("--lhds-color-blue-100")}
              borderColor={cssColor("--lhds-color-blue-300")}
              minHeight={"170px"}
              alignContent={"center"}
            >
              <Space num={SpaceValues.SIXTEEN} />
              <Indent num={IndentValues.SIXTEEN}>
                <Label text={"Add your info"} optional maxCharacters={150} />
              </Indent>

              <Box>
                <Indent num={IndentValues.SIXTEEN}>
                  <TextArea
                    rows={5}
                    maxLength={150}
                    value={conditionsTexts?.condition}
                    onValueChange={(value) =>
                      setConditionsTexts({
                        ...conditionsTexts,
                        condition: value,
                      })
                    }
                  />
                </Indent>
              </Box>
            </Box>
          </CardBody>
        </Card>
      </StepCardBody>
    </StepContainer>
  );
};

export default ConditionsStep;
