import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsListFragment } from "../../../common/fragments/ListFragment";
import {
  TotalDetailsSummaryQuery,
  TotalDetailsSummaryQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query totalDetailsSummary($year: Int!) {
    statistics {
      totalDetails {
        summary(year: $year) {
          ...statisticsListFragment
        }
      }
    }
  }
  ${statisticsListFragment}
`;

export const useTotalDetailsSummaryQuery = (year: number) => {
  const { data, loading, error } = useQuery<
    TotalDetailsSummaryQuery,
    TotalDetailsSummaryQueryVariables
  >(query, { variables: { year }, fetchPolicy: "network-only" });

  return {
    error,
    loading,
    summary: data?.statistics.totalDetails.summary,
  };
};
