import { Configuration } from "@azure/msal-browser";

const CLIENT_CLIENT_ID =
  (window.FREIGHT_PRICING_PORTAL_ENV || {}).REACT_APP_CLIENT_CLIENT_ID ?? "";
const API_CLIENT_ID =
  (window.FREIGHT_PRICING_PORTAL_ENV || {}).REACT_APP_API_CLIENT_ID ?? "";
const TENANT_ID =
  (window.FREIGHT_PRICING_PORTAL_ENV || {}).REACT_APP_TENANT_ID ?? "";

const locationHost = window.location.origin;
export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: `${locationHost}/`,
    postLogoutRedirectUri: `${locationHost}/`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginSilentRequest = {
  scopes: [
    `api://${API_CLIENT_ID}/CommonAPI`,
    `api://${API_CLIENT_ID}/PricingAPI`,
    `api://${API_CLIENT_ID}/AgreementAPI`,
    `api://${API_CLIENT_ID}/FollowUpAPI`,
    `api://${API_CLIENT_ID}/CustomerAPI`,
  ],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
  scopes: [
    `api://${API_CLIENT_ID}/CommonAPI`,
    `api://${API_CLIENT_ID}/PricingAPI`,
    `api://${API_CLIENT_ID}/AgreementAPI`,
    `api://${API_CLIENT_ID}/FollowUpAPI`,
    `api://${API_CLIENT_ID}/CustomerAPI`,
  ],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};
