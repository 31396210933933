import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Heading, Indent, Row, Space } from "@stenajs-webui/core";
import { Select } from "@stenajs-webui/select";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useMemo } from "react";
import { CustomerSelect } from "../../../../common/components/data-driven-inputs/selects/customer-select/CustomerSelect";
import { FormModelChangeHandler } from "../../../../common/forms/types";
import { gtmTransmitter } from "../../../../common/utils/GtmHelper";
import { PriceCalcSearch } from "../../../../common/utils/price-calculator-gtm/PriceCalculatorGtmHelper";
import {
  PriceCalculatorFormData,
  SearchEntityType,
} from "../../PriceCalculatorFormFetcher";
import { PriceCalculatorBookingSearch } from "./PriceCalculatorBookingSearch";
import { PriceCalculatorSearchCustomerDetails } from "./PriceCalculatorSearchCustomerDetails";

interface Props {
  formData: PriceCalculatorFormData;
  setPriceUpdatePending: (isPriceUpdating: boolean) => void;
  onChange: FormModelChangeHandler<PriceCalculatorFormData>;
  onSelectConsignmentVersion: (
    bookingNum: number,
    consignmentVersion: number
  ) => void;
  isLoading: boolean;
}

export const PriceCalculatorSearch: React.FC<Props> = ({
  formData,
  setPriceUpdatePending,
  onChange,
  onSelectConsignmentVersion,
  isLoading,
}) => {
  const options = useMemo(() => {
    return [
      {
        label: "Customer",
        value: SearchEntityType.CUSTOMER,
      },
      {
        label: "Booking",
        value: SearchEntityType.BOOKING,
      },
    ];
  }, []);

  const selected = useMemo(
    () => options.find((o) => o.value === formData.searchEntityType),
    [formData.searchEntityType, options]
  );

  return formData.selectedCustomerNum ? (
    <PriceCalculatorSearchCustomerDetails
      selectedCustomerNum={formData.selectedCustomerNum}
      onChangeCustomer={() => {
        onChange("selectedCustomerNum", undefined);
        setPriceUpdatePending(true);
      }}
      mode={
        formData.searchEntityType === SearchEntityType.BOOKING
          ? "booking"
          : "customer"
      }
    />
  ) : (
    <Box indent={IndentValues.TWENTYFOUR}>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Box
        background={cssColor("--lhds-color-ui-200")}
        indent={IndentValues.THIRTYTWO}
      >
        <Space num={SpaceValues.TWENTYFOUR} />
        <Heading
          variant={"h4"}
          style={{ margin: 0 }}
          data-testid={testIdConstantsWebapp.priceCalculatorSearchFieldHeader}
        >
          Search customer or booking
        </Heading>
        <Space num={SpaceValues.EIGHT} />
        <Row>
          <Box width={"120px"}>
            <Select
              defaultValue={options[0]}
              options={options}
              value={selected}
              onChange={(val: any) => onChange("searchEntityType", val.value)}
            />
          </Box>
          <Indent />
          <Box
            width={"250px"}
            height={"32px"}
            data-testid={
              formData.searchEntityType === SearchEntityType.CUSTOMER
                ? testIdConstantsWebapp.priceCalculatorCustomerSearchField
                : null
            }
          >
            {formData.searchEntityType === SearchEntityType.BOOKING ? (
              <PriceCalculatorBookingSearch
                isLoading={isLoading}
                onSelectConsignmentVersion={onSelectConsignmentVersion}
              />
            ) : (
              <CustomerSelect
                value={String(formData?.selectedCustomerNum)}
                onValueChange={(option) => {
                  gtmTransmitter({
                    event: PriceCalcSearch.searchEvent,
                    searchedFor: PriceCalcSearch.customer,
                  });
                  onChange("selectedCustomerNum", option.custNo);
                }}
                placeholder={"Number/Index"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          </Box>
        </Row>
        <Space num={SpacingValues.TWENTYFOUR} />
      </Box>
    </Box>
  );
};
