import { uuid } from "@/common/uuid/UuidFactory";
import { SubscriptionPayload } from "@/features/notification-center/redux/slice";

const getCreateAgreementDocumentDurationStatement = (
  numberOfReports: number
) => {
  if (numberOfReports < 15) {
    return "a few seconds";
  } else if (numberOfReports >= 15 && numberOfReports < 25) {
    return "up to a minute";
  } else {
    return "a few minutes";
  }
};

export const createNotificationCreateAgreementDocuments = (
  numberOfReports: number,
  id = uuid()
): SubscriptionPayload => ({
  id,
  title: "Started creating agreement documents",
  text: `You will get a notification when all your documents has been created. It will take ${getCreateAgreementDocumentDurationStatement(
    numberOfReports
  )}`,
  autoClose: true,
});

export const createNotificationExpectedVolume = (
  id = uuid()
): SubscriptionPayload => ({
  id,
  title: "Expected volume updating",
  text: `Update started. You will be notified when completed.`,
  autoClose: true,
});
