import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { EditHeadAgreement } from "./EditHeadAgreement";

export interface EditHeadAgreementScreenParams {
  customerId: string;
  headAgreementId: string;
}
interface Props {}

export const EditHeadAgreementScreen: React.FC<Props> = () => {
  const { headAgreementId, customerId } = useParams() as {
    headAgreementId: string;
    customerId: string;
  };

  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }
  return (
    <>
      <Helmet title="Edit HA - Customer - FPP" />
      <EditHeadAgreement
        customerId={customerId}
        headAgreementId={headAgreementId}
      />
    </>
  );
};
