import { gql } from "@apollo/client";
import { apolloClient } from "../../../../apollo-client/ApolloClient";
import { AppThunk } from "../../../../config/redux/RootReducer";
import { profileActions } from "../actions";
import {
  AvailableYearsFragment,
  ProfileNegotiationYearSelectQuery,
} from "@/gql/graphql";

const query = gql`
  fragment AvailableYears on NegotiationYear {
    id
    year
  }
  query ProfileNegotiationYearSelect {
    productPrice {
      negotiationYear {
        availableYears {
          ...AvailableYears
        }
      }
    }
  }
`;

interface AvailableYear extends AvailableYearsFragment {}

export const fetchNegotiationYearsForProfile =
  (): AppThunk => async (dispatch) => {
    const res = await apolloClient.query<ProfileNegotiationYearSelectQuery>({
      query,
      fetchPolicy: "network-only",
    });

    const availableYears: Array<AvailableYear> =
      res.data.productPrice.negotiationYear.availableYears;

    const highestYear = availableYears
      .map((yearData) => yearData.year)
      .reduce((y1, y2) => Math.max(y1, y2));

    dispatch(
      profileActions.setGlobalState({
        currentYear: highestYear,
        fetchErrors: res.errors,
      })
    );
  };
