import {
  formatDateStringRangeWithSwedishTimeZone,
  formatDateStringWithSwedishTimeZone,
} from "@/common/dates/formatters";
import {
  commaToDotTransform,
  dotToCommaTransform,
} from "@/common/formatters/NumericTextFieldInputCleaner";
import { firstLetterCapital } from "@/common/string/GeneralStringFormatting";
import { Indent, Txt } from "@stenajs-webui/core";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { cssColor } from "@stenajs-webui/theme";
import { isEqual } from "lodash";
import { AgreementArticlesTableItem } from "../../../types";
import { isBeforeTomorrow } from "../../bulk-update/validators/validators";
import { createAgreementArticlesStandardTableCell } from "../components/CreateAgreementArticlesStandardTableCell";
import { createAgreementArticlesTableCellRenderer } from "../components/CreateAgreementArticlesTableCellRenderer";
import { AgreementArticlesTableColumns } from "./RowsAndColumnsConfig";
import { CreateAgreementArticlePriceItemInput } from "@/gql/graphql";

const calculateDifferenceInPercent = (
  item: EditableEntityState<AgreementArticlesTableItem>
) => {
  if (item.editable.price && item.editable.newPrice) {
    const percentualValue =
      ((Number(commaToDotTransform(item.editable.newPrice)) -
        item.editable.price) /
        item.editable.price) *
      100;
    return dotToCommaTransform(String(+percentualValue.toFixed(2)));
  }
  return;
};

const errorBackgroundResolver = (
  tableRowErrors: Array<CreateAgreementArticlePriceItemInput>,
  tableItem: EditableEntityState<AgreementArticlesTableItem>,
  agreementArticles: EditableEntityState<AgreementArticlesTableItem>[]
) => {
  const rowError = tableRowErrors.find(
    (rowItem) => rowItem.agrRouteArticlePriceId === tableItem.id
  );

  const editableItem = agreementArticles.find(
    (article) => article.id === tableItem.id
  )?.editable;
  const persistedItem = agreementArticles.find(
    (article) => article.id === tableItem.id
  )?.persisted;

  const hasNoChanges = isEqual(editableItem, persistedItem);

  if (
    !hasNoChanges &&
    rowError &&
    (tableItem.editable.newPrice === "" ||
      tableItem.editable.newValidFrom?.length !== 10 ||
      isBeforeTomorrow(tableItem.editable.newValidFrom))
  ) {
    return cssColor("--lhds-color-red-50");
  }
  return undefined;
};

export const agreementArticlesTableConfig = (
  tableRowErrors: Array<CreateAgreementArticlePriceItemInput>,
  agreementArticles: EditableEntityState<AgreementArticlesTableItem>[]
) => {
  const agreementArticlesTableConfig: StandardTableConfig<
    EditableEntityState<AgreementArticlesTableItem>,
    AgreementArticlesTableColumns
  > = {
    disableInfiniteList: true,
    enableGridCell: true,
    showRowCheckbox: true,
    showHeaderCheckbox: true,
    rowBackgroundResolver: (item) =>
      errorBackgroundResolver(tableRowErrors, item, agreementArticles),
    keyResolver: (item) => item.editable.id,
    columns: {
      distanceTypeDescription: createColumnConfig(
        (item) => item.editable.distanceTypeDescription,
        {
          renderCell: ({ label, value, item }) =>
            createAgreementArticlesStandardTableCell(
              item.editable.distanceTypeDescription &&
                item.editable.distanceTypeCode &&
                `${item.editable.distanceTypeDescription} (${item.editable.distanceTypeCode})`
            ),
          columnLabel: "Group",
          minWidth: "170px",
          disableGridCell: true,
        }
      ),
      articleCode: {
        itemValueResolver: (item) => item.editable.articleCode,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(item.editable.articleCode),
        columnLabel: "Article",
        disableGridCell: true,
      },
      routeDescription: {
        itemValueResolver: (item) => item.editable.routeDescription,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            item.editable.routeDescription
          ),
        minWidth: "120px",
        columnLabel: "Direction",
        disableGridCell: true,
      },
      sailingTypesString: {
        itemLabelFormatter: (value, item) =>
          item.editable.sailingTypesString ?? "",
        itemValueResolver: (item) => item.editable.sailingTypesString,
        columnLabel: "Sailing",
        disableGridCell: true,
      },
      validity: {
        itemValueResolver: (item) => item.editable.agrRouteValidFrom,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            formatDateStringRangeWithSwedishTimeZone({
              dateStringFrom: item.editable.agrRouteValidFrom,
              dateStringTo: item.editable.agrRouteValidTo,
            })
          ),
        minWidth: "180px",
        columnLabel: "Validity",
        disableGridCell: true,
      },
      agrRouteName: {
        itemValueResolver: (item) => item.editable.agrRouteName,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Txt
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "150px",
              }}
              title={label}
              whiteSpace={"nowrap"}
            >
              {label}
            </Txt>
          </Indent>
        ),
        columnLabel: "Name",
        disableGridCell: true,
      },
      vehicleTypeDescription: {
        itemValueResolver: (item) => item.editable.vehicleTypeDescription,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            firstLetterCapital(item.editable.vehicleTypeDescription)
          ),
        columnLabel: "Vehicle type",
        minWidth: "150px",
        disableGridCell: true,
      },
      lengthFrom: {
        itemValueResolver: (item) => item.editable.lengthFrom,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            dotToCommaTransform(String(item.editable.lengthFrom))
          ),
        columnLabel: "Length from",
        minWidth: "120px",
        disableGridCell: true,
      },
      lengthTo: {
        itemValueResolver: (item) => item.editable.lengthTo,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            dotToCommaTransform(String(item.editable.lengthTo))
          ),
        columnLabel: "Length to",
        minWidth: "105px",
        disableGridCell: true,
      },
      weightFrom: {
        itemValueResolver: (item) => item.editable.weightFrom,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(item.editable.weightFrom),
        columnLabel: "Weight from",
        minWidth: "120px",
        disableGridCell: true,
      },
      weightTo: {
        itemValueResolver: (item) => item.editable.weightTo,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(item.editable.weightTo),
        columnLabel: "Weight to",
        minWidth: "105px",
        disableGridCell: true,
      },
      price: {
        itemValueResolver: (item) => item.editable.price,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            dotToCommaTransform(String(item.editable.price?.toFixed(2) ?? ""))
          ),
        columnLabel: "Current price",
        minWidth: "125px",
        disableGridCell: true,
        borderLeft: true,
      },
      newPrice: createColumnConfig((item) => item.editable.newPrice, {
        renderCell: createAgreementArticlesTableCellRenderer(
          "newPrice",
          tableRowErrors,
          agreementArticles
        ),
        columnLabel: "New price",
        width: "150px",
        isEditable: true,
      }),
      diff: {
        itemValueResolver: (item) => item.editable.diff,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            calculateDifferenceInPercent(item)
          ),
        disableGridCell: true,
        columnLabel: "Diff (%)",
        minWidth: "90px",
      },
      validFrom: {
        itemValueResolver: (item) => item.editable.validFrom,
        renderCell: ({ label, value, item }) =>
          createAgreementArticlesStandardTableCell(
            item.editable.validFrom
              ? formatDateStringWithSwedishTimeZone({
                  dateString: item.editable.validFrom,
                })
              : formatDateStringWithSwedishTimeZone({
                  dateString: item.editable.agrRouteValidFrom,
                })
          ),
        disableGridCell: true,
        columnLabel: "Valid from",
        minWidth: "110px",
        borderLeft: true,
      },
      newValidFrom: createColumnConfig((item) => item.editable.newValidFrom, {
        renderCell: createAgreementArticlesTableCellRenderer(
          "newValidFrom",
          tableRowErrors,
          agreementArticles
        ),
        columnLabel: "New valid from",
        width: "176px",
        isEditable: true,
        gridCellOptions: {
          allowedInputType: "none",
        },
      }),
    },
    columnOrder: [
      "distanceTypeDescription",
      "articleCode",
      "routeDescription",
      "sailingTypesString",
      "validity",
      "agrRouteName",
      "vehicleTypeDescription",
      "lengthFrom",
      "lengthTo",
      "weightFrom",
      "weightTo",
      "price",
      "newPrice",
      "diff",
      "validFrom",
      "newValidFrom",
    ],
  };

  return agreementArticlesTableConfig;
};
