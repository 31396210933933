import { gql } from "apollo-boost";

export const statisticsDetailsFragment = gql`
  fragment volumeChangeChartData on VolumeChangeChartData {
    expectedVolume {
      acc
      unacc
    }
    lastYearVolume {
      acc
      unacc
    }
  }

  fragment volumeChangeTableData on VolumeChangeTableData {
    acc {
      diff
      expected
      lastYear
    }
    total {
      diff
      expected
      lastYear
    }
    unacc {
      diff
      expected
      lastYear
    }
  }

  fragment rateChangeTableData on RateChangeTableData {
    total {
      rate
      target
    }
    acc {
      rate
      target
    }
    unacc {
      rate
      target
    }
  }

  fragment statisticsDetailsFragment on StatisticsDetailsVolumeAndRatesResult {
    rateChange {
      charts {
        total {
          averageRateChange
          averageTarget
        }
        peak {
          averageRateChange
          averageTarget
        }
        shoulder {
          averageRateChange
          averageTarget
        }
        offPeak {
          averageRateChange
          averageTarget
        }
      }
      tables {
        total {
          ...rateChangeTableData
        }
        peak {
          ...rateChangeTableData
        }
        shoulder {
          ...rateChangeTableData
        }
        offPeak {
          ...rateChangeTableData
        }
      }
      tables {
        total {
          total {
            rate
            target
          }
          acc {
            rate
            target
          }
          unacc {
            rate
            target
          }
        }
      }
    }
    volumeChange {
      charts {
        total {
          ...volumeChangeChartData
        }
        peak {
          ...volumeChangeChartData
        }
        shoulder {
          ...volumeChangeChartData
        }
        offPeak {
          ...volumeChangeChartData
        }
      }
      tables {
        total {
          ...volumeChangeTableData
        }
        peak {
          ...volumeChangeTableData
        }
        shoulder {
          ...volumeChangeTableData
        }
        offPeak {
          ...volumeChangeTableData
        }
      }
    }
  }
`;
