import * as React from "react";
import { Box, Heading, Spacing } from "@stenajs-webui/core";

interface Props {
  title: string;
}
const FormGroup: React.FC<Props> = ({ title, children }) => {
  return (
    <Box>
      <Spacing />
      <Heading variant={"h4"} style={{ margin: 0 }}>
        {title}
      </Heading>
      <Spacing />
      {children}
    </Box>
  );
};

export default FormGroup;
