import * as React from "react";
import { useEffect, useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { AllLiveStockFragment, LiveStockQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  isActive?: boolean;
  onValueChange?: (value: string) => void;
}

const query = gql`
  fragment AllLiveStock on LiveStock {
    id
    code
    description
    id
    presentationOrder
  }
  query LiveStock {
    productPrice {
      liveStock {
        all {
          ...AllLiveStock
        }
      }
    }
  }
`;

interface LiveStockOption {
  label: string;
  value: string;
  data: AllLiveStockFragment;
}

export const LiveStockSelect: React.FC<Props> = ({
  onValueChange,
  isActive,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<LiveStockQuery>(query);

  const livestock = useMemo(() => {
    return data?.productPrice.liveStock.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    const livestockArrayForSort = [...livestock];
    return livestockArrayForSort
      .sort((l) => l.presentationOrder)
      .map((r) => ({
        label: `${r.description}`,
        value: r.id,
        data: r,
      }));
  }, [livestock]);

  const selected = useMemo(
    () => options.filter((o) => o.value === value),
    [options, value]
  );

  const defaultValue = isActive && options[0] ? options[0] : null;
  const currentSelectionValue = selected.length ? selected : defaultValue;

  useEffect(() => {
    onValueChange?.(defaultValue?.value ?? "");
  }, [defaultValue, onValueChange]);

  return (
    <Select
      isLoading={loading}
      options={options}
      value={currentSelectionValue}
      onChange={(o: LiveStockOption) => onValueChange?.(o.value)}
      {...selectProps}
    />
  );
};
