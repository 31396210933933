import { groupBy } from "lodash";
import { ExpectedVolumeItem, ExpectedVolumeTableRow } from "../types";
import {
  ExpectedVolumeFetcherQuery,
  ExpectedVolumeFragment,
  RelatedCustomersFragment,
  SailingTypeCode,
  UnitCategoryCode,
} from "@/gql/graphql";

interface ExpectedVolumeCollectionItem {
  routePairId: string;
  routePairHasVolume: boolean;
  expectedVolumeTableRows: Array<ExpectedVolumeTableRow>;
}

export const collectExpectedVolumeRows = (
  routePairs: ExpectedVolumeFetcherQuery["productPrice"]["routePair"]["allWithStatusOwn"],
  expectedVolumeByFilter: ExpectedVolumeFragment,
  relatedCustomers: RelatedCustomersFragment[]
): Array<ExpectedVolumeCollectionItem> => {
  const dataGroupedByRoute = groupBy(
    expectedVolumeByFilter.volumes ?? [],
    (row) => row.routePair.id
  );

  return routePairs.map(({ id: routePairId }) => {
    const rows = dataGroupedByRoute[routePairId] ?? [];
    const dataGroupedByCustNo = groupBy(rows, (row) => row.customer.id);

    const expectedVolumeTableRows = relatedCustomers.map((customer) =>
      transformColumn(customer, dataGroupedByCustNo[customer.id] ?? [])
    );

    return {
      routePairId,
      routePairHasVolume: rows.some(
        (row) => row.volume12Months > 0 || (row.expectedVolume ?? 0) > 0
      ),
      expectedVolumeTableRows,
    };
  });
};

const transformColumn = (
  customer: RelatedCustomersFragment,
  entries: ExpectedVolumeItem[]
): ExpectedVolumeTableRow => {
  const { name, custIndex, custNo, isParent } = customer;

  return {
    id: String(custNo),
    customerNo: String(custNo),
    customerName: name,
    customerIndex: custIndex,
    customerIsParent: isParent,
    accompanied12MonthVolOffPeak:
      findColumn(entries, SailingTypeCode.O, UnitCategoryCode.Acc)
        ?.volume12Months ?? 0,
    accompanied12MonthVolPeak:
      findColumn(entries, SailingTypeCode.P, UnitCategoryCode.Acc)
        ?.volume12Months ?? 0,
    accompanied12MonthVolShoulder:
      findColumn(entries, SailingTypeCode.S, UnitCategoryCode.Acc)
        ?.volume12Months ?? 0,
    accompaniedExpectedVolOffPeak:
      findColumn(entries, SailingTypeCode.O, UnitCategoryCode.Acc)
        ?.expectedVolume ?? undefined,
    accompaniedExpectedVolPeak:
      findColumn(entries, SailingTypeCode.P, UnitCategoryCode.Acc)
        ?.expectedVolume ?? undefined,
    accompaniedExpectedVolShoulder:
      findColumn(entries, SailingTypeCode.S, UnitCategoryCode.Acc)
        ?.expectedVolume ?? undefined,
    unaccompanied12MonthVolOffPeak:
      findColumn(entries, SailingTypeCode.O, UnitCategoryCode.Unacc)
        ?.volume12Months ?? 0,
    unaccompanied12MonthVolPeak:
      findColumn(entries, SailingTypeCode.P, UnitCategoryCode.Unacc)
        ?.volume12Months ?? 0,
    unaccompanied12MonthVolShoulder:
      findColumn(entries, SailingTypeCode.S, UnitCategoryCode.Unacc)
        ?.volume12Months ?? 0,
    unaccompaniedExpectedVolOffPeak:
      findColumn(entries, SailingTypeCode.O, UnitCategoryCode.Unacc)
        ?.expectedVolume ?? undefined,
    unaccompaniedExpectedVolPeak:
      findColumn(entries, SailingTypeCode.P, UnitCategoryCode.Unacc)
        ?.expectedVolume ?? undefined,
    unaccompaniedExpectedVolShoulder:
      findColumn(entries, SailingTypeCode.S, UnitCategoryCode.Unacc)
        ?.expectedVolume ?? undefined,
  };
};

const findColumn = (
  entries: ExpectedVolumeItem[],
  scc: SailingTypeCode,
  ucc: UnitCategoryCode
) =>
  entries.find(
    (e) => e.sailingCategoryCode === scc && e.unitCategoryCode === ucc
  );
