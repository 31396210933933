import { useArraySet } from "@stenajs-webui/core";
import { stenaSailingLocation } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllSalesRegions } from "../../hooks/FilterFetchers/UseFetchAllSalesRegion";

interface Props {
  enabled?: boolean;
  label?: string;
}
export const SalesRegionFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <SalesRegionFilterSectionInternal {...props} /> : <></>;
const SalesRegionFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { salesRegions, setSalesRegions } = useFilterWorkspace();
  const {
    refetch,
    error,
    loading,
    salesRegions: items,
  } = useFetchAllSalesRegions();

  const { toggle } = useArraySet(
    salesRegions,
    setSalesRegions,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"salesRegions"}
      label={label}
      enabled={enabled}
      numSelected={salesRegions.length}
      leftIcon={stenaSailingLocation}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {items.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!salesRegions.find((salesRegion) => salesRegion.id === item.id)
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
