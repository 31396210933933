import { stenaFinancePaid } from "@stenajs-webui/elements";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { RangeFilter } from "../../types/RangeFilter";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { RangeControl } from "../RangeControl";

interface Props {
  enabled?: boolean;
  label?: string;
  labelFrom?: string;
  labelTo?: string;
  maxDecimals?: number;
  maxNumbers?: number;
}

export const AverageGrossPriceFilterSection: React.FC<Props> = ({
  enabled,
  label,
  labelFrom,
  labelTo,
  maxDecimals,
  maxNumbers,
}) => {
  const { averageGrossPrice, setAverageGrossPrice } = useFilterWorkspace();

  const onFilterValueChange = (rangeFilter: RangeFilter) => {
    setAverageGrossPrice({ ...rangeFilter });
  };

  const numberOfActiveFilters = () => {
    return averageGrossPrice.from || averageGrossPrice.to ? 1 : 0;
  };

  return (
    <FppSearchFilterSection
      sectionId={"averageGrossPrice"}
      label={label}
      enabled={enabled}
      numSelected={numberOfActiveFilters()}
      leftIcon={stenaFinancePaid}
      children={
        <RangeControl<RangeFilter>
          value={averageGrossPrice}
          valueFrom={averageGrossPrice.from}
          valueTo={averageGrossPrice.to}
          onValueChange={onFilterValueChange}
          labelFrom={labelFrom ?? "From"}
          labelTo={labelTo ?? "To"}
          maxNumbers={maxNumbers}
          maxDecimals={maxDecimals}
        />
      }
    />
  );
};
