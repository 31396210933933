import { formatDateString } from "@/common/dates/formatters";
import { cleanNumericTextFieldInput } from "@/common/formatters/NumericTextFieldInputCleaner";
import { parseFloatElseUndefined } from "@/common/numbers";
import { RequiredPriceInput, RequiredValidDatesInput } from "@/gql/graphql";
import { DateStringRange } from "@stenajs-webui/calendar";
import { isSameDay } from "date-fns";
import { isEqual, sortBy } from "lodash";

interface WithPersistedDeleted {
  persisted: boolean;
  deleted: boolean;
}

interface ArrayDiff<T> {
  created: Array<T>;
  deleted: Array<T>;
  rest: Array<T>;
}

export const getPersistedDeletedDiff = <T extends WithPersistedDeleted>(
  list: Array<T>
): ArrayDiff<T> => {
  const deleted = list.filter((a) => a.deleted && a.persisted);
  const created = list.filter((a) => !a.deleted && !a.persisted);
  const rest = list.filter((a) => !a.deleted && a.persisted);
  return {
    deleted,
    created,
    rest,
  };
};

export const arrayIsEqual = <T>(
  array1: Array<T>,
  array2: Array<T> | undefined
): boolean => {
  if (!array2) {
    return false;
  }
  return isEqual(sortBy(array1), sortBy(array2));
};

export const getModifiedOrNull = <T>(
  modified: T,
  persisted: T | undefined,
  equalFunc?: (a: T, b: T | undefined) => boolean
): { value: T } | null => {
  if (equalFunc) {
    if (equalFunc(modified, persisted)) {
      return null;
    }
  } else if (modified === persisted) {
    return null;
  }
  return {
    value: modified,
  };
};

export const getModifiedParsedNumberOrNull = (
  modified: string,
  persisted: string | undefined
): { value: number } | null => {
  if (modified === persisted) {
    return null;
  }
  const value = parseFloatElseUndefined(cleanNumericTextFieldInput(modified));

  if (value == null) {
    return null;
  }

  return {
    value,
  };
};

export const getModifiedPriceOrNull = (
  modified: string,
  persisted: string | undefined
): RequiredPriceInput | null => {
  if (modified === persisted) {
    return null;
  }
  if (!modified) {
    return null;
  }
  return {
    value: {
      amount: modified,
    },
  };
};

export const getModifiedOrNullTransformed = <T, R>(
  modified: T,
  persisted: T,
  transformer: (value: T) => R
): { value: R } | null => {
  if (modified === persisted) {
    return null;
  }
  return {
    value: transformer(modified),
  };
};

type ValidDatesEqualType = "sameDay";

export const getRequiredValidDatesInputOrNull = (
  modified: DateStringRange,
  persisted: DateStringRange | undefined,
  equalType: ValidDatesEqualType = "sameDay"
): RequiredValidDatesInput | null => {
  if (!modified.startDate) {
    throw new Error("Missing start date.");
  }
  if (!modified.endDate) {
    throw new Error("Missing end date.");
  }
  const r = {
    value: {
      startDate: formatDateString(new Date(modified.startDate)),
      endDate: formatDateString(new Date(modified.endDate)),
    },
  };

  if (!persisted || !persisted.startDate || !persisted.endDate) {
    return r;
  }

  if (
    equalType === "sameDay" &&
    (!persisted.startDate ||
      isSameDay(new Date(modified.startDate), new Date(persisted.startDate))) &&
    (!persisted.endDate ||
      isSameDay(new Date(modified.endDate), new Date(persisted.endDate)))
  ) {
    return null;
  }

  return r;
};
