import { ConditionsFormModel } from "./ConditionsFormModel";
import { getModifiedOrNull } from "../../utils/InputTransformerHelpers";
import { ConditionsFormInput } from "@/gql/graphql";

export const transformConditionsFormToInput = (
  formModel: ConditionsFormModel,
  persisted: ConditionsFormModel | undefined
): ConditionsFormInput => {
  return {
    countrySender: getModifiedOrNull(
      formModel.countrySender,
      persisted?.countrySender
    ),
    countryReceiver: getModifiedOrNull(
      formModel.countryReceiver,
      persisted?.countryReceiver
    ),
    goods: getModifiedOrNull(formModel.goods, persisted?.goods),
    standby: getModifiedOrNull(formModel.standby, persisted?.standby),
    transit: getModifiedOrNull(formModel.transit, persisted?.transit),
  };
};
