import { TableContext } from "@stenajs-webui/grid";
import { batch } from "react-redux";
import { BulkUpdateAgreementArticlesFormModel } from "../models/BulkUpdateAgreementArticlesFormModel";
import { agreementArticlesActions } from "../../../redux/reducer";
import { AgreementArticlesTableColumns } from "../../table/config/RowsAndColumnsConfig";
import { raisePriceForAgreementArticlesPrice } from "../hooks/UseBulkUpdateNewPricesLogic";
import { AppThunk } from "../../../../../../../config/redux/RootReducer";

export const raiseNewPricesUsingSelectionThunk =
  (
    formModel: BulkUpdateAgreementArticlesFormModel,
    tableContext: TableContext<AgreementArticlesTableColumns>
  ): AppThunk =>
  async (dispatch, getState) => {
    const agreementArticlesState = getState().agreementArticles;
    const selectedIds =
      getState().agreementArticles.standardTableState.selectedIds.selectedIds;

    batch(() => {
      selectedIds.forEach((id) => {
        raisePriceForAgreementArticlesPrice(
          dispatch,
          id,
          agreementArticlesState,
          formModel
        );
      });
    });
    dispatch(agreementArticlesActions.setBulkUpdateLoading(false));
    tableContext.dispatch(tableContext.actions.clearSelection());
  };
