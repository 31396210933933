import { gql } from "@apollo/client";

export const ReportScreenFragments = gql`
  fragment SubscriberContactSubscriber on Subscriber {
    id
    firstName
    surName
    telNo
  }
  fragment SubscriberContactsForReport on SubscriberContact {
    id
    email
    subscriber {
      ...SubscriberContactSubscriber
    }
  }
  fragment CustomerSubscriberSelector on Customer {
    id
    subscriberContactsForReport {
      ...SubscriberContactsForReport
    }
  }

  fragment RouteAgreements on RouteAgreement {
    routeDescription
    id
    statusCode
    agreementNumber
    name
    externalDescription
    externalComment
    multiLegAgrRouteId
    hasArticles
    routes {
      id
      code
      routePair {
        code
        seaArea {
          name
        }
      }
    }
    sailingTypeCodes
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    currency {
      id
      code
    }
    hasMatrix
  }

  fragment CreateReportHeader on HeadAgreement {
    id
    breadCrumbName
  }

  fragment CreateReportHeaderCustomer on Customer {
    id
    name
    custNo
    custIndex
    breadCrumbName
  }

  fragment MultiLegRouteAgreements on HeadAgreement {
    id
    multiLegAgrRoutes {
      id
      statusCode
      name
      idNumber
      workFlowStatusCode
      routeAgreements {
        isSalesReady
      }
      currency {
        id
        code
      }
      route {
        id
        code
      }
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
    }
  }
`;
