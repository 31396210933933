import { AllRoutesTableRow } from "../config/RowsAndColumnsConfig";
import { combineReducers } from "redux";
import {
  createEditableEntityReducer,
  createEntityListReducer,
  createEntityReducer,
  EditableEntityState,
  EntityListState,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { ResultListBannerState } from "@stenajs-webui/elements";

export interface ProgressState {
  loading: boolean;
  bannerState?: ResultListBannerState;
}

export const adminTargetReducerId = "adminTargetAllRoutes";

export interface AdminTargetState {
  allRoutesData: EntityListState<EditableEntityState<AllRoutesTableRow>>;
  progress: EntityState<ProgressState>;
}

export const adminTargetReducer = reducerIdGate(
  adminTargetReducerId,
  combineReducers({
    allRoutesData: createEntityListReducer(
      createEditableEntityReducer<AllRoutesTableRow>({
        id: "",
        routePairCode: "",
        year: new Date().getUTCFullYear(),
        offPeakAcc: undefined,
        offPeakUnAcc: undefined,
        peakAcc: undefined,
        peakUnAcc: undefined,
        shoulderAcc: undefined,
        shoulderUnAcc: undefined,
        volumeTarget: undefined,
      })
    ),
    progress: createEntityReducer<ProgressState>({
      loading: false,
      bannerState: undefined,
    }),
  })
);
