import * as React from "react";
import { Helmet } from "react-helmet";
import { MissingPage } from "./MissingPage";

interface Props {}

export const MissingPageScreen: React.FC<Props> = () => {
  return (
    <>
      <Helmet title="404 - Page not found" />
      <MissingPage />
    </>
  );
};
