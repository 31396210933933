import * as React from "react";
import { Row, Text } from "@stenajs-webui/core";
import { RateSheetTableRowState } from "../reducer";
import { formatPercentWithSign } from "@/common/numbers";
import { ModifiedFieldSeparator } from "../../../../common/components/table/ModifiedFieldSeparator";
import { getHighlightedPositiveNegativeTextColor } from "../../../../common/components/table/HighlightedPositiveNegativeCell";

interface Props {
  state: RateSheetTableRowState;
  includeOldValue?: boolean;
}

export const ActualChangeInPercent: React.FC<Props> = React.memo(
  ({ state, includeOldValue }) => {
    const { editable, persisted } = state;

    const isActualModified = editable.actual !== persisted?.actual;

    if (!includeOldValue) {
      const valueToShow = isActualModified
        ? editable.actual
        : persisted?.actual;
      return (
        <Row alignItems={"center"}>
          <Text
            color={getHighlightedPositiveNegativeTextColor(valueToShow ?? null)}
            variant={isActualModified ? "bold" : undefined}
          >
            {valueToShow == null
              ? "-"
              : formatPercentWithSign(valueToShow / 100, 2)}
          </Text>
        </Row>
      );
    }

    return (
      <Row alignItems={"center"}>
        <Text
          color={getHighlightedPositiveNegativeTextColor(
            persisted?.actual ?? null
          )}
        >
          {persisted?.actual == null
            ? "-"
            : formatPercentWithSign(persisted?.actual / 100, 2)}
        </Text>
        {isActualModified && persisted && (
          <>
            <ModifiedFieldSeparator />{" "}
            <Text
              color={getHighlightedPositiveNegativeTextColor(editable.actual)}
              variant={"bold"}
            >
              {editable.actual == null
                ? "-"
                : formatPercentWithSign(editable.actual / 100, 2)}
            </Text>
          </>
        )}
      </Row>
    );
  }
);
