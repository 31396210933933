import { routeFactory } from "@/RouteFactory";
import { Link } from "@/common/navigation/Link";
import { Indent } from "@stenajs-webui/core";
import { StandardTableConfig } from "@stenajs-webui/grid";
import { childCustomersTableCell } from "../components/ChildCustomersTableCell";
import { ChildCustomersTableItem } from "../types";

export const childCustomersTableConfig: StandardTableConfig<
  ChildCustomersTableItem,
  "custIndex" | "name" | "custNo"
> = {
  enableGridCell: false,
  initialSortOrder: "custIndex",
  keyResolver: (item) => item.id,
  columns: {
    custIndex: {
      itemValueResolver: (item) => item.custIndex,
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId: String(item.custNo),
              headAgreementId: item.preselectedHeadAgreement?.id ?? "",
            })}
          >
            {item.custIndex}
          </Link>
        </Indent>
      ),
      columnLabel: "Index",
    },
    name: {
      itemValueResolver: (item) => item.name,
      renderCell: ({ label, value, item }) =>
        childCustomersTableCell(item.name),
      columnLabel: "Name",
    },
    custNo: {
      itemValueResolver: (item) => item.custNo,
      renderCell: ({ label, value, item }) =>
        childCustomersTableCell(item.custNo),
      columnLabel: "Customer number",
    },
  },
  columnOrder: ["custIndex", "name", "custNo"],
};
