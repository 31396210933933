import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { Box, Indent, Row, Txt } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import { Tooltip } from "@stenajs-webui/tooltip";
import { VehicleTypeIcon } from "../../../../../common/components/vehicle-type-icon/VehicleTypeIcon";
import {
  MAX_LENGTH,
  MAX_WEIGHT,
  MIN_LENGTH,
  MIN_WEIGHT,
} from "../../add-vehicle-type/models/AddVehicleTypeFormModel";
import { bulkEditRatesStandardTableCell } from "../components/BulkEditRatesStandardTableCell";
import { BulkEditRatePrice } from "../types";
import { vehicleTypeAll } from "./constants";

export const vehicleLength = (item: BulkEditRatePrice) => {
  if (
    (item.lengthFrom == null || item.lengthFrom === MIN_LENGTH) &&
    (item.lengthTo == null || item.lengthTo >= MAX_LENGTH)
  ) {
    return bulkEditRatesStandardTableCell("Any");
  }
  return bulkEditRatesStandardTableCell(
    `${dotToCommaTransform(String(item.lengthFrom))} to ${dotToCommaTransform(
      String(item.lengthTo)
    )}`
  );
};

export const weightFromTo = (item: BulkEditRatePrice) => {
  if (
    (item.weightFrom == null || item.weightFrom === MIN_WEIGHT) &&
    (item.weightTo == null || item.weightTo >= MAX_WEIGHT)
  ) {
    return bulkEditRatesStandardTableCell("Any");
  }
  return bulkEditRatesStandardTableCell(
    `${dotToCommaTransform(String(item.weightFrom))} to ${dotToCommaTransform(
      String(item.weightTo)
    )}`
  );
};

export const vehicleTypeCell = (
  item: BulkEditRatePrice,
  setRoutePriceId: (routePriceId: string) => void,
  open: () => void
) => {
  return (
    <Row alignItems={"center"} indent>
      <Box justifyContent={"flex-start"} justifyItems={"center"} width={"32px"}>
        <Tooltip
          label={item.vehicleType?.shortDescription ?? "All vehicle types"}
        >
          <VehicleTypeIcon vehicleTypeCode={item.vehicleType?.id} />
        </Tooltip>
      </Box>
      <Indent />
      <Indent>
        <Txt
          onClick={() => {
            setRoutePriceId(item.id);
            open();
          }}
          style={{ cursor: "pointer" }}
          color={cssColor("--lhds-color-blue-600")}
        >
          {item.vehicleType === null ? vehicleTypeAll : item.vehicleType?.code}
        </Txt>
      </Indent>
    </Row>
  );
};
