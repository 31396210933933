import { stenaStatusInProgress } from "@stenajs-webui/elements";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { FPPFilterCheckBoxList } from "./FPPFilterCheckBoxList";
import { useFetchAllNoShowLateHandlingStatuses } from "../../hooks/FilterFetchers/UseFetchAllNoShowLateHandlingStatuses";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const NoShowLateHandlingStatusSection: React.FC<Props> = (props) =>
  props.enabled ? (
    <NoShowLateHandlingStatusSectionInternal {...props} />
  ) : (
    <></>
  );
const NoShowLateHandlingStatusSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const {
    handlingStatuses: items,
    loading,
    refetch,
  } = useFetchAllNoShowLateHandlingStatuses();
  const {
    noShowLateHandlingStatus,
    setNoShowLateHandlingStatus,
    noShowLateHandlingType,
  } = useFilterWorkspace();
  return (
    <FppSearchFilterSection
      sectionId={"noShowLateHandlingStatus"}
      enabled={enabled}
      label={label ?? "Status"}
      leftIcon={stenaStatusInProgress}
      loading={loading}
      onRetry={refetch}
      numSelected={noShowLateHandlingType.length}
    >
      <FPPFilterCheckBoxList
        checkboxes={items}
        selectedValues={noShowLateHandlingStatus}
        setValue={setNoShowLateHandlingStatus}
      />
    </FppSearchFilterSection>
  );
};
