import * as React from "react";
import { Row, Space, Text } from "@stenajs-webui/core";
import { Icon, InputSpinner, stenaCheck } from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import { CopyHeadAgreementFormModel } from "../../model/CopyHeadAgreementFormModel";
import { HeadAgreement } from "../../types";
import { CopyHaToOtherCustomerResultPane } from "./CopyHaToOtherCustomerResultPane";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { CopyHeadAgreementTargetCustomerQuery } from "@/gql/graphql";

interface Props {
  open: boolean;
  onRequestClose: Function;
  success: boolean;
  loading: boolean;
  headAgreement: HeadAgreement;
  targetHeadAgreement:
    | CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"]
    | undefined;
  targetCustomerPath: string;
  originalCustomerPath: string;
  formModel: CopyHeadAgreementFormModel;
}

export const CopyHaToOtherCustomerModal: React.FC<Props> = ({
  open,
  onRequestClose,
  success,
  loading,
  headAgreement,
  targetHeadAgreement,
  targetCustomerPath,
  originalCustomerPath,
  formModel,
}) => {
  return (
    <Window
      width={"100%"}
      isOpen={open}
      onRequestClose={() => {
        onRequestClose();
      }}
      indent={IndentValues.TWENTYFOUR}
      spacing={SpacingValues.TWENTYFOUR}
      header={
        success ? (
          <Row alignItems={"center"}>
            <Icon icon={stenaCheck} />
            <Space num={2} />
            <Text size="large">Copy successful</Text>
          </Row>
        ) : loading ? (
          <Row alignItems={"center"}>
            <InputSpinner size={"small"} />
            <Space num={2} />
            <Text size="large">Copying agreements</Text>
          </Row>
        ) : null
      }
    >
      <CopyHaToOtherCustomerResultPane
        loading={loading}
        success={success}
        targetCustomerPath={targetCustomerPath}
        headAgreement={headAgreement}
        targetHeadAgreement={targetHeadAgreement}
        originalCustomerPath={originalCustomerPath}
        formModel={formModel}
      />
    </Window>
  );
};
