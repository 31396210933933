import { AppThunk } from "../../../../../config/redux/RootReducer";
import { noShowLateHandlingRoutePricesActions } from "../actions";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { noShowLateHandlingAllRoutePricesMutation } from "./NoShowLateHandlingRoutePricesQuery";
import { NoShowLateHandlingPricesRow } from "../../config/RowsAndColumnsConfig";
import {
  NoShowLateHandlingAllRoutePricesMutation,
  NoShowLateHandlingAllRoutePricesMutationVariables,
} from "@/gql/graphql";

type RoutePricesData = {
  id: string;
  routePairCode: string;
  validFrom: string;
  noShowDkk: number;
  noShowEur: number;
  noShowSek: number;
  noShowNok: number;
  noShowPln: number;
  noShowGbp: number;
  lateHandlingDkk: number;
  lateHandlingEur: number;
  lateHandlingSek: number;
  lateHandlingNok: number;
  lateHandlingPln: number;
  lateHandlingGbp: number;
};

export type UpdateAllRoutePricesData = Array<RoutePricesData>;

export const updateAllRoutePrices =
  (
    data: UpdateAllRoutePricesData,
    routePricesStateData: {
      id: string | undefined;
      editable: NoShowLateHandlingPricesRow;
      persisted: NoShowLateHandlingPricesRow;
    }[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      noShowLateHandlingRoutePricesActions.setProgress({ loading: true })
    );

    try {
      const { errors } = await apolloClient.mutate<
        NoShowLateHandlingAllRoutePricesMutation,
        NoShowLateHandlingAllRoutePricesMutationVariables
      >({
        variables: {
          updateRoutePricesInput: data,
        },
        mutation: noShowLateHandlingAllRoutePricesMutation,
      });
      if (errors && errors.length) {
        dispatch(
          noShowLateHandlingRoutePricesActions.setProgress({
            bannerState: {
              headerText: "Could not update route prices",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      } else {
        dispatch(
          noShowLateHandlingRoutePricesActions.setRowsNoShowLateHandlingPricesAll(
            routePricesStateData
          )
        );
      }
    } catch (error) {
      dispatch(
        noShowLateHandlingRoutePricesActions.setProgress({
          bannerState: {
            headerText: "Could not update route prices",
            items: [{ text: "Please try again by clicking the 'Save'-button" }],
          },
        })
      );
    } finally {
      dispatch(
        noShowLateHandlingRoutePricesActions.setProgress({ loading: false })
      );
    }
  };
