import React, { useCallback, useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { TargetNegotiationStatus } from "../../../../../features/targeting-tool/route-targets-overview/types";
import { gtmTransmitter } from "../../../../utils/GtmHelper";
import { AllRouteTargetStatusChange } from "../../../../utils/route-target-gtm/RouteTargetGtmHelper";
import { TargetStatusSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: TargetNegotiationStatus | undefined) => void;
}

const query = gql`
  fragment allTargetNegotiationStatus on TargetNegotiationStatus {
    id
    code
    description
    presentationOrder
  }
  query TargetStatusSelect {
    productPrice {
      targetNegotiationStatus {
        all {
          ...allTargetNegotiationStatus
        }
      }
    }
  }
`;

export const TargetNegotiationStatusSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<TargetStatusSelectQuery>(query);

  const all = useMemo(() => {
    return data?.productPrice.targetNegotiationStatus.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return all
      .map((s) => ({
        label: s.description,
        value: s.code,
      }))
      .reverse();
  }, [all]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  const onValueChangeHandler = useCallback(
    (item: any) => {
      if (onValueChange) {
        gtmTransmitter({
          event: AllRouteTargetStatusChange.searchEvent,
        });
        const selectedNegStatus = all?.find((o) => o.code === item.value);
        onValueChange(selectedNegStatus);
      }
    },
    [all, onValueChange]
  );

  return (
    <Select
      {...selectProps}
      isLoading={loading}
      options={options}
      value={selected}
      onChange={onValueChangeHandler}
    />
  );
};
