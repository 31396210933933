import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  StatisticsSearchSellerListByFilterOptions,
  SellerListQuery,
  SellerListQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment SellerListItems on StatisticsBySellerListItem {
    seller {
      id
      name
    }
    negotiationStatusStatistics {
      accounts
      statistics {
        negotiationStatus {
          id
          name
        }
        numberVolumeStatistics {
          percentageOfAccount
          percentageOfVolume
        }
      }
    }
  }
  query SellerList($filterOptions: StatisticsSearchSellerListByFilterOptions!) {
    statistics {
      sellerList {
        byFilter(filterOptions: $filterOptions) {
          items {
            ...SellerListItems
          }
        }
      }
    }
  }
`;

export const useSellerListQuery = (
  filterOptions: StatisticsSearchSellerListByFilterOptions
) => {
  const { error, loading, data } = useQuery<
    SellerListQuery,
    SellerListQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    skip: filterOptions.sellerIds.length === 0 || filterOptions.year == null,
  });

  return {
    error,
    loading,
    sellerItems: data?.statistics.sellerList.byFilter?.items,
  };
};
