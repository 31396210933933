import { CreateRouteAgreementInput } from "@/gql/graphql";
import { EditRouteAgreementFormModel } from "../../common/components/models/EditRouteAgreementFormModel";
import { validateCreateRouteAgreementForm } from "../util/CreateRouteAgreementFormValidator";
import { formatDateString } from "@/common/dates/formatters";

export const createCreateRouteAgreementMutationInput = (
  headAgreementId: string,
  formModel: EditRouteAgreementFormModel
): CreateRouteAgreementInput => {
  validateCreateRouteAgreementForm(formModel);
  return {
    headAgreementId,
    currencyId: formModel.currencyId!,
    externalDescription: formModel.externalDescription,
    name: formModel.name,
    routeIds: formModel.routeIds,
    offPeak: formModel.offPeak,
    peak: formModel.peak,
    shoulder: formModel.shoulder,
    special: formModel.special,
    type: formModel.type,
    validFrom: formatDateString(new Date(formModel.valid.startDate!)),
    validTo: formatDateString(new Date(formModel.valid.endDate!)),
  };
};
