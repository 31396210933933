import { Indent, Row, Text } from "@stenajs-webui/core";
import * as React from "react";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  label?: string;
  value?: string | number | null;
}

export const PriceCalculatorSearchInfoRowItem: React.FC<Props> = ({
  label,
  value,
}) => {
  return (
    <Row alignItems={"center"}>
      {label && (
        <>
          <Text color={cssColor("--lhds-color-ui-500")}>{label}</Text>
          <Indent num={0.5} />
        </>
      )}
      <Text color={cssColor("--lhds-color-ui-700")}>{value}</Text>
    </Row>
  );
};
