import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  CreateMultiLegAgreementMutation,
  CreateMultiLegAgreementMutationVariables,
} from "@/gql/graphql";

export const mutation = gql`
  ${errorFragment}
  mutation CreateMultiLegAgreement(
    $createMultiLegRouteAgreementInput: CreateMultiLegRouteAgreementInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        createMultiLegRouteAgreement(
          input: $createMultiLegRouteAgreementInput
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on CreateMultiLegRouteAgreementSuccessResult {
            multiLegRouteAgreement {
              id
              headAgreement {
                id
              }
              routeAgreements {
                id
                routes {
                  routePair {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreateMultiLegAgreement = () => {
  const [createMultiLegRouteAgreement, { loading, error }] = useMutation<
    CreateMultiLegAgreementMutation,
    CreateMultiLegAgreementMutationVariables
  >(mutation);

  return {
    createMultiLegRouteAgreement,
    loading,
    error,
  };
};
