import React from "react";
import { ResultItem, ResultListBanner } from "@stenajs-webui/elements";
import { useNavigate, useParams } from "react-router-dom";
import { extractInfoFromPath, routeFactory } from "../../RouteFactory";
import { SectionalRouteAgreements } from "../../features/product-and-price/head-agreement/activate/components/ActivateHeadAgreementContainer";

export interface ErrorWithPath {
  detailText: string;
  path?: string;
}

interface Props {
  headerText: string;
  subHeaderText?: string;
  errors: ErrorWithPath[];
  sectionRouteAgreements: SectionalRouteAgreements;
}

export const BannerErrorWithLinks: React.FC<Props> = ({
  headerText,
  subHeaderText,
  errors,
  sectionRouteAgreements,
}) => {
  const navigate = useNavigate();
  const { customerId, headAgreementId } = useParams() as {
    customerId: string;
    headAgreementId: string;
  };
  const resultItems = errors.map<ResultItem>((error) => {
    const pathInfo = extractInfoFromPath(error.path);
    if (pathInfo && pathInfo.agrRouteNumber) {
      return {
        text: error.detailText,
        linkText: pathInfo.agrRouteNumber,
        onClickLink: () =>
          navigate(
            routeFactory.productAndPrice.linkToErroredResource(
              customerId,
              headAgreementId,
              sectionRouteAgreements,
              error.path ?? ""
            )
          ),
      };
    }
    return {
      text: error.detailText,
    };
  });
  return (
    <>
      <ResultListBanner
        bannerState={{
          headerText: headerText,
          text: subHeaderText,
          items: resultItems,
        }}
        variant={"error"}
      />
    </>
  );
};
