import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { css, Global } from "@emotion/react";
import { Column } from "@stenajs-webui/core";
import { BreadCrumbs } from "@stenajs-webui/elements";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Crumb } from "../../../common/components/bread-crumbs/Crumb";
import { NslhPageHeader } from "../NslhPageHeader";
import { OverviewFilterBar } from "./components/OverviewFilterBar";
import { useFetchInternalOverviewBookings } from "./hooks/UseFetchInternalOverviewBookings";
import { OverviewScreen } from "./OverviewScreen";
import { createNslhInternalOverviewStateSelectors } from "./redux/selectors";
import { InternalOverviewQuery } from "@/gql/graphql";
type NslhFollowUpByFilter =
  InternalOverviewQuery["productPrice"]["noShowLateHandling"]["nslhFollowUp"]["byFilter"];

interface Props {}
export interface OverviewBooking extends NslhFollowUpByFilter {
  pathToOneMonthBookings: string;
}

export const OverviewScreenWrapper: React.FC<Props> = () => {
  const { fetchOverviewData, loading, error, setError } =
    useFetchInternalOverviewBookings();
  const overviewBookings = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewdData
  );

  const accountCardData = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewAccountCardData
  );

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Overview - NSLH - FPP" />
      <NslhPageHeader
        actionBar={
          <OverviewFilterBar
            fetchOverviewData={fetchOverviewData}
            error={error}
            loading={loading}
            setError={setError}
          />
        }
        breadCrumbs={
          <BreadCrumbs>
            <Crumb label={"No show & Late handling"} />
            <Crumb label={"Follow up"} isLast={true} />
          </BreadCrumbs>
        }
        headingText={"Follow up"}
      />
      <Column
        indent={IndentValues.TWENTYFOUR}
        spacing={SpacingValues.TWENTYFOUR}
      >
        <OverviewScreen
          overviewBookings={overviewBookings}
          accountCardData={accountCardData}
          error={error}
        />
      </Column>
    </>
  );
};
