import * as React from "react";
import { Box, Heading, Indent, Row, Spacing, Text } from "@stenajs-webui/core";
import { Link } from "@stenajs-webui/elements";
import { CopyHeadAgreementFormModel } from "../../model/CopyHeadAgreementFormModel";
import { HeadAgreement } from "../../types";
import { formatDateString } from "@/common/dates/formatters";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  CopyHeadAgreementTargetCustomerQuery,
  HeadAgreementStatusCode,
} from "@/gql/graphql";

interface CopyToOtherCustomerResultPaneProps {
  loading: boolean;
  success: boolean;
  headAgreement: HeadAgreement;
  originalCustomerPath: string;
  targetHeadAgreement:
    | CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"]
    | undefined;
  targetCustomerPath: string;
  formModel: CopyHeadAgreementFormModel;
}

export const CopyHaToOtherCustomerResultPane: React.FC<
  CopyToOtherCustomerResultPaneProps
> = ({
  loading,
  success,
  headAgreement,
  targetHeadAgreement,
  targetCustomerPath,
  originalCustomerPath,
  formModel,
}) => {
  const validStart = formatDateString(
    new Date(headAgreement.valid.start?.isoString ?? "")
  );
  const validEnd = formatDateString(
    new Date(headAgreement.valid.end?.isoString ?? "")
  );
  const targetValidStart = formatDateString(
    new Date(
      targetHeadAgreement
        ? targetHeadAgreement?.valid.start?.isoString ?? ""
        : formModel.valid.startDate ?? ""
    )
  );
  const targetValidEnd = formatDateString(
    new Date(
      targetHeadAgreement
        ? targetHeadAgreement?.valid.end?.isoString ?? ""
        : formModel.valid.endDate ?? ""
    )
  );
  const targetName = targetHeadAgreement
    ? targetHeadAgreement?.name
    : formModel.name;
  const targetStatusCode = targetHeadAgreement
    ? targetHeadAgreement?.statusCode
    : formModel.statusCode ?? HeadAgreementStatusCode.Pending;

  return (
    <Box>
      <Row alignItems={"center"}>
        <Box>
          <Heading variant="h4">Original</Heading>
          <Spacing num={0.5} />
          <Text>
            {`${validStart} - ${validEnd} - ${headAgreement.name} - ${headAgreement.statusCode}`}
          </Text>
        </Box>
        <Indent num={4} />
        <Box>
          <Heading variant="h4">Target</Heading>
          <Spacing num={0.5} />
          <Text>
            {`${targetValidStart} - ${targetValidEnd} - ${targetName} - ${targetStatusCode}`}
          </Text>
        </Box>
      </Row>
      <Spacing num={SpacingValues.TWELVE} />
      <Row justifyContent={"flex-end"}>
        <Link href={originalCustomerPath} disabled={loading && !success}>
          Back to original customer
        </Link>
        <Indent num={IndentValues.TWELVE} />
        <Link href={targetCustomerPath} disabled={loading && !success}>
          Go to target customer
        </Link>
      </Row>
    </Box>
  );
};
