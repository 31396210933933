import { HeadAgreement } from "../types";
import { CopyHeadAgreementFormModel } from "../model/CopyHeadAgreementFormModel";
import { formatMissingFieldsMessage } from "../../../../../common/error/ErrorFormatter";
import { limitStringElseThrow } from "../../../../../common/string/StringLimiteter";
import { endOfYear, startOfYear } from "date-fns";
import { formatDateString } from "@/common/dates/formatters";
import {
  CopyHeadAgreementInput,
  CopyHeadAgreementTargetCustomerQuery,
  ValidDatesInput,
} from "@/gql/graphql";

export const transformToFormModel = (
  headAgreement: HeadAgreement,
  targetHeadAgreement?: CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"],
  targetCustomerId?: string
): CopyHeadAgreementFormModel => {
  return {
    name: targetHeadAgreement
      ? targetHeadAgreement.name ?? ""
      : targetCustomerId && !targetHeadAgreement
      ? ""
      : headAgreement.name,
    valid: {
      startDate: targetHeadAgreement
        ? (targetHeadAgreement.valid.start &&
            new Date(targetHeadAgreement.valid.start.isoString)) ??
          undefined
        : targetCustomerId && !targetHeadAgreement
        ? startOfYear(new Date())
        : (headAgreement.valid.start &&
            new Date(headAgreement.valid.start.isoString)) ??
          undefined,
      endDate: targetHeadAgreement
        ? (targetHeadAgreement.valid.end &&
            new Date(targetHeadAgreement.valid.end.isoString)) ??
          undefined
        : targetCustomerId && !targetHeadAgreement
        ? endOfYear(new Date())
        : (headAgreement.valid.end &&
            new Date(headAgreement.valid.end.isoString)) ??
          undefined,
    },
    specifications: targetHeadAgreement
      ? targetHeadAgreement.specifications ?? ""
      : targetCustomerId && !targetHeadAgreement
      ? ""
      : headAgreement.specifications ?? "",
    condition: targetHeadAgreement
      ? targetHeadAgreement.condition ?? ""
      : targetCustomerId && !targetHeadAgreement
      ? ""
      : headAgreement.condition ?? "",
    lastPage: targetHeadAgreement
      ? targetHeadAgreement.lastPage ?? ""
      : targetCustomerId && !targetHeadAgreement
      ? ""
      : headAgreement.lastPage ?? "",
    internalNotes: targetHeadAgreement
      ? targetHeadAgreement.internalComments ?? ""
      : targetCustomerId && !targetHeadAgreement
      ? ""
      : headAgreement.internalComments ?? "",
  };
};

export const transformFormModelToInput = (
  headAgreementId: string,
  formModel: CopyHeadAgreementFormModel,
  selectedRouteAgreementIds: Array<string>,
  selectedMultiLegAgreementIds: Array<string>,
  toExistingHeaderAgreementId?: string | null,
  toCustNo?: number | null
): CopyHeadAgreementInput => {
  const missing = [];
  const otherErrors = [];

  if (!formModel.valid.startDate) {
    missing.push("start date");
  }
  if (!formModel.valid.endDate) {
    missing.push("end date");
  }
  if (!formModel.name) {
    otherErrors.push("You need to type a name for the agreement");
  }

  if (
    formModel.valid.startDate &&
    formModel.valid.endDate &&
    formModel.valid.startDate > formModel.valid.endDate
  ) {
    otherErrors.push(
      "The valid agreement from date must always be less than the valid to date"
    );
  }

  if (missing.length) {
    otherErrors.push(formatMissingFieldsMessage(missing));
  }

  if (otherErrors.length) {
    throw new Error(otherErrors.join(" "));
  }

  const validInput: ValidDatesInput = {
    startDate: formatDateString(formModel.valid.startDate!),
    endDate: formatDateString(formModel.valid.endDate!),
  };

  return {
    headAgreementId,
    name: { value: formModel.name },
    valid: validInput,
    condition: {
      value: limitStringElseThrow(formModel.condition, 150, "condition"),
    },
    internalNotes: formModel.internalNotes
      ? {
          value: formModel.internalNotes,
        }
      : null,
    lastPage: {
      value: formModel.lastPage,
    },
    specifications: {
      value: limitStringElseThrow(
        formModel.specifications,
        150,
        "specifications"
      ),
    },
    includedRouteAgreements: selectedRouteAgreementIds.map(
      (routeAgreementId) => ({
        routeAgreementId,
        valid: validInput,
      })
    ),
    includedMultiLegAgreements:
      selectedMultiLegAgreementIds.length > 0
        ? selectedMultiLegAgreementIds.map((multiLegAgreementId) => ({
            multiLegAgreementId,
            valid: validInput,
          }))
        : null,
    toExistingHeaderAgreementId: toExistingHeaderAgreementId ?? null,
    toCustNo: toCustNo ?? null,
  };
};
