import { gql, useQuery } from "@apollo/client";
import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo, useState } from "react";
import { ArticleItemFormModel } from "../../../../../features/product-and-price/route-agreement/details/features/articles/models/ArticlesFormModel";
import { ArticleTypesMultiSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  onValueChange?: (value: Array<string>) => void;
  articleState: Array<ArticleItemFormModel>;
  selectedArticleIds: Array<string>;
}

const query = gql`
  fragment AllArticleTypes on ArticleType {
    id
    code
    name
    needsPricingData
    usesPercent
  }
  query ArticleTypesMultiSelect {
    productPrice {
      articleType {
        all {
          ...AllArticleTypes
        }
      }
    }
  }
`;

export const useAllArticleTypes = () => {
  return useQuery<ArticleTypesMultiSelectQuery>(query);
};

interface SelectState {
  label: string;
  value: string;
}

export const ArticleTypesSelect: React.FC<Props> = ({
  onValueChange,
  articleState,
  selectedArticleIds,
  ...selectProps
}) => {
  const { data, loading } = useAllArticleTypes();
  const [currentlySelected, setCurrentlySelected] = useState<SelectState>({
    label: "Select article to add",
    value: "Selected article id",
  });

  const articles = useMemo(() => {
    return data?.productPrice.articleType.all ?? [];
  }, [data]);

  const selectedArticleTypes = useMemo(() => {
    return articleState
      .filter((as) => selectedArticleIds.includes(as.id))
      .map((as) => as.articleTypeId);
  }, [articleState, selectedArticleIds]);

  const options = useMemo(() => {
    return articles
      .filter((art) => !selectedArticleTypes.includes(art.id))
      .map((art) => ({
        label: art.name,
        value: art.id,
      }));
  }, [articles, selectedArticleTypes]);

  return (
    <Select
      isLoading={loading}
      options={options}
      value={currentlySelected}
      menuPortalTarget={document.body}
      onChange={(v: SelectState | null) => {
        if (onValueChange && v) {
          const newSelectedState = [...selectedArticleIds, v.value];
          setCurrentlySelected(v);
          onValueChange(newSelectedState);
        }
      }}
      {...selectProps}
    />
  );
};
