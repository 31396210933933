import { css, Global } from "@emotion/react";
import { Row, Spacing } from "@stenajs-webui/core";
import { LoadingScreen } from "@stenajs-webui/panels";
import React from "react";
import { useParams } from "react-router-dom";
import { useFetchOneMonthBookings } from "./hooks/UseFetchOneMonthBookings";
import { transformByBookingsFilterToInvoiceSpecification } from "./transformers/TransformByBookingsFilterToInvoiceSpecification";
import { routeFactory } from "../../../RouteFactory";
import { NslhInvoiceTab } from "../config/tabConfig";
import { ViewSpecificationScreenHeader } from "@/common/components/invoice-specification/components/ViewSpecificationScreenHeader";
import { OneMonthBookingsView } from "@/common/components/one-month-bookings/components/OneMonthBookingsView";
import { ErrorDisplay } from "@/common/error/components/ErrorDisplay";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Helmet } from "react-helmet";

export const OneMonthBookingsScreen: React.FC = () => {
  const { year, month, accountNo } = useParams() as {
    year: string;
    month: string;
    accountNo: string;
  };

  const { oneMonthBookings, loading, error } = useFetchOneMonthBookings(
    Number(accountNo),
    Number(year),
    Number(month)
  );

  if (loading) {
    return (
      <>
        <ViewSpecificationScreenHeader
          pageHeading={`Bookings for ${year}-${String(month).padStart(2, "0")}`}
          previousRoute={routeFactory.noShowLateHandling.followUpTabView(
            NslhInvoiceTab.overview
          )}
          breadCrumbLabel={"Bookings"}
        />
        <Spacing num={SpacingValues.TWELVE} />
        <Row justifyContent={"center"} width={"100%"}>
          <LoadingScreen />
        </Row>
      </>
    );
  }

  if (error) {
    const fppError = transformApolloErrorToFreightPricingPortalErrors(error);
    return (
      <Row justifyContent={"center"}>
        <ErrorDisplay
          error={fppError && fppError.length > 0 ? fppError[0] : error}
        />
      </Row>
    );
  }

  if (!oneMonthBookings || oneMonthBookings.length === 0) {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Bookings - NSLH - FPP" />
      <ViewSpecificationScreenHeader
        pageHeading={`Bookings for ${year}-${String(month).padStart(2, "0")}`}
        previousRoute={routeFactory.noShowLateHandling.followUpTabView(
          NslhInvoiceTab.overview
        )}
        breadCrumbLabel={"Bookings"}
      />
      <OneMonthBookingsView
        oneMonthBookingsData={transformByBookingsFilterToInvoiceSpecification(
          oneMonthBookings
        )}
      />
    </>
  );
};
