import { SpacingValues } from "@/common/utils/SpacingValues";
import { Spacing } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { useEffect } from "react";
import StepContainer from "../../common/components/StepContainer";
import StepCardBody from "../../common/components/StepCardBody";
import { SurchargesTable } from "./SurchargesTable";
import { AdditionalSurchargeTableColumnKeys } from "./common/surcharges-table-config";
import { useAdditionalSurchargeDocuments } from "./hooks/UseAdditionalSurchargeDocuments";

interface Props {
  tableContext: TableContext<AdditionalSurchargeTableColumnKeys>;
  headAgreementId: string;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  yearValidFrom: number;
  yearValidTo: number;
}

const SurchargeStep = ({
  tableContext,
  headAgreementId,
  setIsNextAvailable,
  yearValidFrom,
  yearValidTo,
}: Props) => {
  const { additionalSurchargeDocuments, loading } =
    useAdditionalSurchargeDocuments(yearValidFrom, yearValidTo);
  useEffect(() => {
    setIsNextAvailable(!loading);
  }, [loading, setIsNextAvailable]);

  return (
    <StepContainer>
      <CardHeader text="Surcharge appendices to include" />
      <StepCardBody>
        <Spacing num={SpacingValues.TWELVE} />

        <SurchargesTable
          tableContext={tableContext}
          loading={loading}
          additionalSurchargeDocuments={additionalSurchargeDocuments}
        />
      </StepCardBody>
    </StepContainer>
  );
};
export default SurchargeStep;
