import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { useQuery } from "@apollo/client";
import { Box, Row, SeparatorLine, Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  UseResultListBannerStateResult,
} from "@stenajs-webui/elements";
import {
  StandardTable,
  StandardTableAction,
  TableContext,
} from "@stenajs-webui/grid";
import { gql } from "apollo-boost";
import * as React from "react";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { routeAgreementCanBeEdited } from "../../route-agreement/util/RouteAgreementCalculator";
import { AddVehicleTypeButton } from "../add-vehicle-type/components/AddVehicleTypeButton";
import { BulkUpdateRatesButton } from "../bulk-update-rates/components/BulkUpdateRatesButton";
import { routeAgreementCanBeBulkUpdated } from "../bulk-update-rates/validators/RouteAgreementBulkUpdateValidator";
import {
  RateSheetTableColumn,
  createRateSheetTableConfig,
} from "../config/RateSheetTableConfig";
import { useRateSheetTableState } from "../hooks/UseRateSheetTableState";
import {
  rateSheetStandardTableActions,
  rateSheetStandardTableSelectors,
  wrapRateSheetStandardTableAction,
} from "../redux";
import { rateSheetTableDataSortTransformer } from "./transformers/RateSheetTableDataSortTransformer";
import {
  RateSheetTableQuery,
  RouteAgreementFieldsFragment,
  RouteAgreementsForMultilegRouteFragment,
} from "@/gql/graphql";

interface Props extends RateSheetTableDataProps {
  routeAgreementId: string;
  currencyLabel?: string;
  headerRight?: ReactNode;
  headerLeft?: ReactNode;
  headerCenter?: ReactNode;
  contentBelowHeader?: ReactNode;
  bulkUpdateModeEnabled: boolean;
  disableStatBoxes?: boolean;
  hideBulkUpdateButton?: boolean;
  isMultiLegRates?: boolean;
  addVehicleTypeToAllRoutesRadioButton?: boolean;
  bannerHook?: UseResultListBannerStateResult;
}

export interface RateSheetTableDataProps {
  loading?: boolean;
  error?: Error;
  routeAgreement: Pick<RouteAgreementFieldsFragment, "id" | "statusCode">;
  multiLegAgreement?: RouteAgreementsForMultilegRouteFragment;
}

const query = gql`
  fragment AllVehicles on VehicleType {
    id
    code
    name
    shortDescription
    accompanied
  }
  query RateSheetTable {
    productPrice {
      vehicleType {
        all {
          ...AllVehicles
        }
      }
    }
  }
`;

export const RateSheetTable: React.FC<Props> = React.memo(
  ({
    routeAgreementId,
    currencyLabel,
    headerRight,
    headerLeft,
    headerCenter,
    contentBelowHeader,
    routeAgreement,
    error,
    multiLegAgreement,
    loading,
    bulkUpdateModeEnabled,
    hideBulkUpdateButton,
    isMultiLegRates,
    addVehicleTypeToAllRoutesRadioButton,
    disableStatBoxes = false,
    bannerHook,
  }) => {
    const dispatch = useDispatch();
    const { width } = useWindowSize();

    const standardTableSelector = useCallback(
      (state: StoreState) =>
        rateSheetStandardTableSelectors.getTableState(state, routeAgreementId),
      [routeAgreementId]
    );

    const standardTableState = useSelector(standardTableSelector);

    const { data } = useQuery<RateSheetTableQuery>(query);

    const allVehicleTypes = data?.productPrice.vehicleType.all ?? [];

    const { prices, addRateSheetTableItem, selectedId } =
      useRateSheetTableState(routeAgreementId, allVehicleTypes);

    const sortedPrices = rateSheetTableDataSortTransformer(prices);

    useEffect(() => {
      return () => {
        bannerHook?.clearBannerResult();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableContext = useMemo<TableContext<RateSheetTableColumn>>(() => {
      return {
        state: standardTableState,
        actions: rateSheetStandardTableActions,
        dispatch: (action: StandardTableAction<RateSheetTableColumn>) => {
          dispatch(wrapRateSheetStandardTableAction(action, routeAgreementId));
        },
      };
    }, [routeAgreementId, dispatch, standardTableState]);

    const config = useMemo(() => {
      const editableRouteAgreement = bulkUpdateModeEnabled
        ? false
        : routeAgreement
        ? routeAgreementCanBeEdited(routeAgreement)
        : false;

      const showCheckboxes =
        bulkUpdateModeEnabled && routeAgreementCanBeBulkUpdated(routeAgreement);

      return createRateSheetTableConfig(
        routeAgreementId,
        dispatch,
        editableRouteAgreement,
        showCheckboxes,
        width,
        isMultiLegRates,
        disableStatBoxes,
        currencyLabel,
        selectedId
      );
    }, [
      dispatch,
      routeAgreementId,
      currencyLabel,
      routeAgreement,
      bulkUpdateModeEnabled,
      selectedId,
      width,
      isMultiLegRates,
      disableStatBoxes,
    ]);

    const editableRouteAgreement =
      !bulkUpdateModeEnabled && routeAgreementCanBeEdited(routeAgreement);

    const loadingTable = useMemo(() => {
      return (loading ?? true) && typeof prices === "undefined";
    }, [loading, prices]);
    return (
      <Card flex={1}>
        <CardHeader
          text={headerLeft ? undefined : "Rates"}
          contentLeft={headerLeft}
          contentCenter={headerCenter}
          contentRight={
            <Row>
              {routeAgreement && (
                <>
                  <AddVehicleTypeButton
                    onAddRow={addRateSheetTableItem}
                    editableRouteAgreement={editableRouteAgreement}
                    multiLegAgreement={multiLegAgreement}
                    addVehicleTypeToAllRoutesRadioButton={
                      addVehicleTypeToAllRoutesRadioButton
                    }
                  />
                  {!hideBulkUpdateButton && (
                    <>
                      <Space />
                      <BulkUpdateRatesButton
                        routeAgreementId={routeAgreement.id}
                        currencyLabel={currencyLabel ?? ""}
                        editableRouteAgreement={editableRouteAgreement}
                      />
                    </>
                  )}
                </>
              )}
              {headerRight && (
                <>
                  <Space />
                  {headerRight}
                </>
              )}
            </Row>
          }
        />
        {contentBelowHeader && <CardBody>{contentBelowHeader}</CardBody>}
        <SeparatorLine />
        <CardBody>
          <Box overflowX={"auto"}>
            <StandardTable
              tableId={routeAgreementId}
              tableContext={tableContext}
              config={config}
              items={sortedPrices}
              error={error}
              loading={loadingTable}
            />
          </Box>
        </CardBody>
      </Card>
    );
  }
);
