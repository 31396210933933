import { CurrencySelect } from "@/common/components/data-driven-inputs/selects/currency-select/CurrencySelect";
import { NegotiationYearSelect } from "@/common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Space } from "@stenajs-webui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { ErrorPage } from "../../../../LoginPage";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { generalStatisticsSelectors } from "../redux/selectors";
import { StatisticsTabMenu } from "../tab-menu/StatisticsTabMenu";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { useStatisticsYearSelect } from "../hooks/UseStatisticsYearSelector";
import { stenaStatisticsBar } from "@stenajs-webui/elements";

export const StatisticsHeader: React.FC = () => {
  const {
    generalState: { fetchErrors },
  } = useSelector(generalStatisticsSelectors.getGeneralStatisticsState);

  const { year, handleYearChange } = useStatisticsYearSelect();
  const { handleCurrencyChange, currency } = useCurrencySelection();

  if (fetchErrors && fetchErrors.length > 0) {
    const errorMessage = fetchErrors
      ?.map((fe) => {
        return fe.message;
      })
      .join(" ");

    return <ErrorPage text={errorMessage} />;
  }

  return (
    <PageHeader
      icon={stenaStatisticsBar}
      title={"Statistics"}
      tabs={<StatisticsTabMenu />}
      zIndex={ZIndex.low}
      width={"100%"}
      contentRight={
        <>
          <NegotiationYearSelect
            value={year}
            onValueChange={handleYearChange}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <CurrencySelect
            value={currency}
            onValueChange={handleCurrencyChange}
            containerWidth={"80px"}
          />
        </>
      }
    />
  );
};
