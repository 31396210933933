import * as React from "react";
import { Box, Column, Space, Txt } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { DateRangeDualTextInput } from "@stenajs-webui/calendar";
import { CurrencyRadioButtonRow } from "../../../../../../common/components/data-driven-inputs/radio-buttons/CurrencyRadioButtonRow";
import { EditMultiLegAgreementFormModel } from "./model/EditAndCreateMultiLegAgreementFormModel";

interface Props {
  formData: EditMultiLegAgreementFormModel;
  setFields: (value: Partial<EditMultiLegAgreementFormModel>) => void;
}

export const EditMultiLegAgreementForm: React.FC<Props> = ({
  formData,
  setFields,
}) => {
  return (
    <Column>
      <Label text={"Name"} optional={true}>
        <Box width={"500px"}>
          <TextInput
            autoFocus
            value={formData.name}
            onValueChange={(name) => setFields({ name })}
          />
        </Box>
      </Label>
      <Space num={4} />
      <Label text={"Validity (will apply to route agreements as well)"}>
        <Box width={"fit-content"}>
          <DateRangeDualTextInput
            value={formData.valid}
            onValueChange={(valid) => setFields({ valid })}
          />
        </Box>
      </Label>

      <Space num={4} />
      <Label
        text={"Agreement currency (will apply to route agreements as well)"}
      >
        <Box>
          <CurrencyRadioButtonRow
            value={formData.currencyCode}
            onValueChange={(currencyCode) => setFields({ currencyCode })}
          />
        </Box>
      </Label>
      <Space num={4} />
      <Box width={"500px"}>
        <Txt size={"small"}>
          {
            "Note: Route agreements will be automatically created for this multi leg. One for each leg."
          }
        </Txt>
      </Box>
      <Space num={4} />
    </Column>
  );
};
