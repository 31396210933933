import { StoreState } from "@/config/redux/RootReducer";
import { SearchFilterState, WorkspaceState } from "./reducer";
import { SearchFilterState as WebUiSearchFilterState } from "@stenajs-webui/filter";
import _ from "lodash";
type FilterState = {
  filter: SearchFilterState;
};
export type GenericStoreState = {
  [key in keyof StoreState]: FilterState;
};

export const createFPPSearchFilterSelectors = (reducerId: string) => ({
  getWorkspaceState: (
    state: GenericStoreState
  ): WebUiSearchFilterState<WorkspaceState> =>
    _.get(state, reducerId).filter.workspace,
  getCommittedState: (state: GenericStoreState): WorkspaceState =>
    _.get(state, reducerId).filter.committed,
});
