import { SpacingValues } from "@/common/utils/SpacingValues";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import { isAfter, parseISO, startOfToday } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { batch, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { agreementArticlesActions } from "../redux/reducer";
import { transformAgreementRouteArticlePricesTableRow } from "../transformers/transformers";
import { AgreementArticlesView } from "./AgreementArticlesView";
import {
  AgreementArticlesFetcherQuery,
  AgreementArticlesFetcherQueryVariables,
} from "@/gql/graphql";
import {
  agreementArticlesCustomerFragment,
  agreementArticlesHeadAgreementFragment,
  agreementArticlesRouteAgreementFragment,
} from "../fragments/AgreementArticlesFragment";

const query = gql`
  ${agreementArticlesCustomerFragment}
  ${agreementArticlesRouteAgreementFragment}
  ${agreementArticlesHeadAgreementFragment}
  query AgreementArticlesFetcher($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...AgreementArticlesHeadAgreement
          customer {
            ...AgreementArticlesCustomer
          }
          routeAgreements {
            ...AgreementArticlesRouteAgreement
          }
        }
      }
    }
  }
`;

export const AgreementArticlesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { headAgreementId } = useParams() as { headAgreementId: string };
  const { error, data, loading } = useQuery<
    AgreementArticlesFetcherQuery,
    AgreementArticlesFetcherQueryVariables
  >(query, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      headAgreementId: headAgreementId,
    },
    onCompleted(data) {
      const filteredRa =
        data?.productPrice.headAgreement.byId?.routeAgreements.filter(
          (ra) =>
            ra.statusCode === "ACTIVE" &&
            isAfter(parseISO(ra.valid.end?.isoString!), startOfToday())
        );
      const customer = data.productPrice.headAgreement.byId?.customer;

      if (filteredRa && headAgreementId) {
        const agreementRouteArticlePricesTableRow = filteredRa?.flatMap(
          (routeAgreement) => {
            return routeAgreement.articles
              .filter((article) => article.articleType.updatable === true)
              .flatMap((article) => {
                return article.prices.map((articlePrice) =>
                  transformAgreementRouteArticlePricesTableRow(
                    customer,
                    routeAgreement,
                    routeAgreement.routes,
                    article,
                    articlePrice,
                    article.validities
                  )
                );
              });
          }
        );
        batch(() => {
          dispatch(agreementArticlesActions.setRouteAgreements(filteredRa));
          if (agreementRouteArticlePricesTableRow) {
            dispatch(
              agreementArticlesActions.setState(
                agreementRouteArticlePricesTableRow
              )
            );
          }
          dispatch(agreementArticlesActions.setTableLoading(false));
        });
      }
    },
  });

  const headAgreement = data?.productPrice.headAgreement.byId;
  const customer = data?.productPrice.headAgreement.byId?.customer;

  useEffect(() => {
    return () => {
      dispatch(agreementArticlesActions.setState([]));
      dispatch(agreementArticlesActions.clearSaveErrors());
      dispatch(agreementArticlesActions.clearAllTableRowErrors());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (!headAgreement) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={"No such header agreement."} />
      </Spacing>
    );
  }

  if (!customer) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <ErrorScreen text={"No such customer."} />
      </Spacing>
    );
  }

  return (
    <>
      <Helmet title="Agreement articles - FPP" />
      <AgreementArticlesView
        headAgreement={headAgreement}
        customer={customer}
        tableError={error}
      />
    </>
  );
};
