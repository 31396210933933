import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import {
  AgreementArticlePriceChangesInput,
  AgreementArticlesChanges,
  UpdateAgreementArticlesItemInput,
} from "@/gql/graphql";
import { EditableEntityState } from "@stenajs-webui/redux";
import { isAfter, parse, startOfToday } from "date-fns";
import { isEqual } from "lodash";
import { AgreementArticlesTableItem } from "../types";

export const agreementArticlesHasChanges = (
  item: UpdateAgreementArticlesItemInput
): boolean => item.changes.created.length > 0;

export const transformAgreementArticleChangesToInput = (
  state: EditableEntityState<AgreementArticlesTableItem>[],
  agrRouteArticleId: string,
  agrRouteArticlePriceId: string,
  agrRouteId: string
): AgreementArticlesChanges => {
  const editedAndPersistedList = state.map((item, i) => {
    return [
      {
        persisted: item.persisted,
        edited: item.editable,
      },
    ];
  });

  const created = editedAndPersistedList
    .flatMap((newList) => newList)
    .filter(
      (filteredList) =>
        !isEqual(filteredList.edited, filteredList.persisted) &&
        filteredList.edited.agrRouteArticlePriceId === agrRouteArticlePriceId &&
        filteredList.persisted.agrRouteArticlePriceId === agrRouteArticlePriceId
    )
    .map((list) => {
      return {
        agrRouteArticleId,
        agrRouteArticlePriceId,
        agrRouteId,
        changes: transformAgreementArticleRowChangesToInput(
          list?.persisted,
          list?.edited
        ),
      };
    });

  return {
    created,
    deleted: [],
    updated: [],
  };
};

export const transformAgreementArticleRowChangesToInput = (
  persisted: AgreementArticlesTableItem,
  edited: AgreementArticlesTableItem
): AgreementArticlePriceChangesInput => {
  const today = startOfToday();
  return {
    agrRouteArticleId: {
      value:
        persisted.agrRouteArticleId !== edited.agrRouteArticleId
          ? edited.agrRouteArticleId
          : null,
    },
    agrRouteArticlePriceValidityId: {
      value:
        persisted.agrRouteArticlePriceValidityId !==
        edited.agrRouteArticlePriceValidityId
          ? edited.agrRouteArticlePriceValidityId
          : null,
    },
    agrRouteName: {
      value:
        persisted.agrRouteName !== edited.agrRouteName
          ? edited.agrRouteName
          : null,
    },
    agrRouteArticlePriceId: {
      value:
        persisted.agrRouteArticlePriceId !== edited.agrRouteArticlePriceId
          ? edited.agrRouteArticlePriceId
          : null,
    },
    agrRouteValidFrom: {
      value:
        persisted.agrRouteValidFrom !== edited.agrRouteValidFrom
          ? edited.agrRouteValidFrom
          : null,
    },
    agrRouteValidTo: {
      value:
        persisted.agrRouteValidTo !== edited.agrRouteValidTo
          ? edited.agrRouteValidTo
          : null,
    },
    articleCode: {
      value:
        persisted.articleCode !== edited.articleCode
          ? edited.articleCode
          : null,
    },
    currencyCode: {
      value:
        persisted.currencyCode !== edited.currencyCode
          ? edited.currencyCode
          : null,
    },
    lengthFrom: {
      value:
        persisted.lengthFrom !== edited.lengthFrom ? edited.lengthFrom : null,
    },
    lengthTo: {
      value: persisted.lengthTo !== edited.lengthTo ? edited.lengthTo : null,
    },
    price:
      edited.newPrice && edited.newPrice !== ""
        ? { value: { amount: commaToDotTransform(edited.newPrice) } }
        : null,
    validFrom:
      edited.newValidFrom && edited.newValidFrom !== ""
        ? { value: edited.newValidFrom }
        : edited.validFrom &&
          isAfter(parse(edited.validFrom, "yyyy-MM-dd", today), today)
        ? { value: edited.validFrom }
        : null,
    vehicleTypeDescription: {
      value:
        persisted.vehicleTypeDescription !== edited.vehicleTypeDescription
          ? edited.vehicleTypeDescription
          : null,
    },
    weightFrom: {
      value:
        persisted.weightFrom !== edited.weightFrom ? edited.weightFrom : null,
    },
    weightTo: {
      value: persisted.weightTo !== edited.weightTo ? edited.weightTo : null,
    },

    distanceTypeCode: {
      value:
        persisted.distanceTypeCode !== edited.distanceTypeCode
          ? edited.distanceTypeCode
          : null,
    },
    distanceTypeDescription: {
      value:
        persisted.distanceTypeDescription !== edited.distanceTypeDescription
          ? edited.distanceTypeDescription
          : null,
    },
    routeDescription: {
      value:
        persisted.routeDescription !== edited.routeDescription
          ? edited.routeDescription
          : null,
    },
    routePairCode: {
      value:
        persisted.routePairCode !== edited.routePairCode
          ? edited.routePairCode
          : null,
    },
    routePairDescription: {
      value:
        persisted.routePairDescription !== edited.routePairDescription
          ? edited.routePairDescription
          : null,
    },
    sailingTypesString: {
      value:
        persisted.sailingTypesString !== edited.sailingTypesString
          ? edited.sailingTypesString
          : null,
    },
    vehicleTypeCode: {
      value:
        persisted.vehicleTypeCode !== edited.vehicleTypeCode
          ? edited.vehicleTypeCode
          : null,
    },
  };
};
