import React from "react";
import { ApolloError } from "apollo-boost";
import { ErrorCode } from "../../../../common/errors";
import { Banner } from "@stenajs-webui/elements";

interface Props {
  title: string;
  text?: string;
  error: ApolloError;
  contentRight?: React.ReactNode;
}

export const BannerByErrorCode: React.FC<Props> = ({
  title,
  error,
  text = error.message,
  contentRight = null,
}) => {
  const errorCode = error.graphQLErrors[0]?.extensions?.errorCode;
  switch (errorCode) {
    case ErrorCode[ErrorCode.NO_NEGOTIATION_STATUS_SELECTED]:
      return <Banner headerText={title} text={text} variant={"info"} />;
    default:
      return (
        <Banner
          headerText={title}
          text={text}
          variant={"error"}
          contentRight={contentRight}
        />
      );
  }
};
