import { SpaceValues } from "@/common/utils/SpaceValues";
import { GaActionFilter } from "@/common/utils/tracking/enums/actions/GaActionFilter";
import { trackEvent } from "@/common/utils/tracking/helper/GaTracker";
import { Column, Row, Space } from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import {
  SearchFilterActionsContext,
  SearchFilterButton,
  SearchFilterDrawer,
} from "@stenajs-webui/filter";
import * as React from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useFilterWorkspace } from "../hooks/UseFilterWorkspace";
import { FppSearchFilterChips } from "./chips/components/FppSearchFilterChips";
import { FppSearchFilterSections } from "./sections/FppSearchFilterSections";
import { FppFilterSettings } from "../types/FppFilterSettings";

interface Props {
  settings: FppFilterSettings;
}
export const FppSearchFilter: React.FC<Props> = ({ settings }) => {
  const dispatch = useDispatch();

  const filterWorkspace = useFilterWorkspace();
  const searchFilterActions = useContext(SearchFilterActionsContext);

  const onSearchSubmit = () => {
    filterWorkspace.commit();
    dispatch(searchFilterActions.closeFilters());
  };

  const clearFilter = () => {
    filterWorkspace.clearFilter();
  };

  return (
    <>
      <Row alignItems={"center"}>
        <SearchFilterButton />
        <Space />
        <FppSearchFilterChips
          enableChipClearButtons={settings.enableChipClearButtons}
          settings={settings}
        />
      </Row>
      {
        // TODO: Indexera componenter? Så utvecklaren får bestämma vilken ordning de syns i filtret?
        // TODO: Singular eller plural namn på alla sectioner?
      }
      <SearchFilterDrawer>
        <FppSearchFilterSections settings={settings} />
        <Column>
          <Space num={SpaceValues.SIXTEEN} />
          <>
            <PrimaryButton label={"Search"} onClick={() => onSearchSubmit()} />
            <Space num={SpaceValues.SIXTEEN} />
            <FlatButton
              label={"Clear filter"}
              onClick={() => {
                trackEvent({
                  action: GaActionFilter.clearFacets,
                });
                clearFilter();
              }}
            />
          </>
        </Column>
      </SearchFilterDrawer>
    </>
  );
};
