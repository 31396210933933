import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { useMemo } from "react";
import {
  HeadAgreementInfoRowSectionQuery,
  HeadAgreementInfoRowSectionQueryVariables,
} from "@/gql/graphql";

export const headAgreementInfoRowSectionQuery = gql`
  fragment HeadAgreementInfoRowSection on HeadAgreement {
    id
    statusCode
    name
    headAgreementNumber
    breadCrumbName
    hasSomeRouteAgreementsWithMultiLeg
    statusCode
    rowVersion
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }
  query HeadAgreementInfoRowSection($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...HeadAgreementInfoRowSection
        }
      }
    }
  }
`;

export const useCustomerHeaderHeadAgreementById = (headAgreementId: string) => {
  const options = useMemo<
    QueryHookOptions<
      HeadAgreementInfoRowSectionQuery,
      HeadAgreementInfoRowSectionQueryVariables
    >
  >(
    () => ({
      variables: { headAgreementId },
      fetchPolicy: "cache-and-network",
    }),
    [headAgreementId]
  );

  const { data, loading, error } = useQuery<
    HeadAgreementInfoRowSectionQuery,
    HeadAgreementInfoRowSectionQueryVariables
  >(headAgreementInfoRowSectionQuery, options);

  return {
    headAgreement: data?.productPrice.headAgreement.byId ?? undefined,
    loading,
    error,
  };
};
