import { RateSheetTableColumn } from "./RateSheetTableConfig";

export const noLabelColumnOrder: Array<RateSheetTableColumn> = [
  "length",
  "weight",
  "cargoStatusCode",
  "unaccompanied",
];

export const vehicleInfoColumnOrder: Array<RateSheetTableColumn> = [
  "vehicleType",
];

export const lastYearColumnOrder: Array<RateSheetTableColumn> = [
  "volume",
  "avgLength",
  "avgGrossPrice",
  "lastPrice",
  "lastMeterPrice",
  "lastChargedFrom",
];

export const newPriceAndActualColumnOrder: Array<RateSheetTableColumn> = [
  "newPrice",
  "meterPrice",
  "additionalFrom",
  "actual",
];

export const actionsColumnOrder: Array<RateSheetTableColumn> = ["actions"];
