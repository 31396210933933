import { SailingCategory } from "../types/FilterEntitys";

export const sortSailingCategories = (
  firstCode: string,
  secondCode: string
) => {
  if (firstCode === "P" && secondCode === "S") return -1;
  if (firstCode === "P" && secondCode === "O") return -1;
  if (firstCode === "S" && secondCode === "O") return -1;

  return 1;
};

const sortSailingTypesWithCodeM = (firstCode: string, secondCode: string) => {
  if (firstCode === "P" && secondCode === "S") return -1;
  if (firstCode === "P" && secondCode === "O") return -1;
  if (firstCode === "S" && secondCode === "O") return -1;
  if (firstCode === "O" && secondCode === "M") return -1;

  return 1;
};

export const sailingTypeOptionsWithCodeM = (
  sailingTypes: Array<SailingCategory>
) => {
  const sailingTypeOptions = sailingTypes
    ?.map((sailingType) => ({
      id: sailingType.id,
      code: sailingType.code,
      name: `${sailingType.name} (${sailingType.code})`,
    }))
    .sort((a, b) => {
      return sortSailingTypesWithCodeM(a.id, b.id);
    });
  return sailingTypeOptions;
};
