import { useArraySet } from "@stenajs-webui/core";
import { stenaSailingLocation } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllRegions } from "../../hooks/FilterFetchers/UseFetchAllRegions";

interface Props {
  enabled?: boolean;
  label?: string;
}
export const RegionFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <RegionFilterSectionInternal {...props} /> : <></>;

const RegionFilterSectionInternal: React.FC<Props> = ({ enabled, label }) => {
  const { regions, setRegions } = useFilterWorkspace();
  const { error, loading, refetch, salesRegions: items } = useFetchAllRegions();
  const { toggle } = useArraySet(regions, setRegions, (a, b) => a.id === b.id);

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"regions"}
      label={label}
      enabled={enabled}
      numSelected={regions.length}
      leftIcon={stenaSailingLocation}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {items.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={!!regions.find((region) => region.id === item.id)}
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
