import { useMsal } from "@azure/msal-react";
import { Indent } from "@stenajs-webui/core";
import { FlatButton, stenaDownload } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import React from "react";
import { Tooltip } from "@stenajs-webui/tooltip";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { RebateAgreementDocumentsTableItem } from "../models";
import { useDownloadPdf } from "@/features/product-and-price/head-agreement/common/hooks/UseDownloadPdf";

export interface DownloadFile {
  item: RebateAgreementDocumentsTableItem;
  error: FreightPricingPortalError | undefined;
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >;
}

export const DownloadPdfButton: React.FC<DownloadFile> = ({
  item,
  error,
  setError,
}) => {
  const { instance } = useMsal();
  const { handleDownload, loading } = useDownloadPdf(instance, setError);
  const onClickButton = () => {
    const customerNumber = String(item.customerNumber);
    handleDownload(
      `/download/rebate-agreement-document?customerNumber=${customerNumber}&documentId=${item.id}`,
      item.fileName
    );
  };

  return (
    <Tooltip label={"Download"}>
      <Indent>
        <FlatButton
          onClick={onClickButton}
          title={"Download .pdf"}
          color={cssColor("--lhds-color-blue-500")}
          rightIcon={loading ? undefined : stenaDownload}
          size={"medium"}
          loading={loading}
        />
      </Indent>
    </Tooltip>
  );
};
