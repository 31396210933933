import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { createResultTableConfig } from "@/features/statistics/common/config/list/ResultTableConfig";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ResultTable } from "@/features/statistics/common/table/ResultTable";
import { SalesRegionCodes } from "@/features/statistics/common/utils/SalesRegionsCodesEnum";
import { Card, CardHeader } from "@stenajs-webui/elements";
import * as React from "react";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { transformSelectedStatusesToNegotiationStatusCodes } from "../../../common/transformers/NegotiationStatusFormModelTransformer";
import { useSalesRegionListQuery } from "../hooks/UseSalesRegionListQuery";
import { useSalesRegionListVolumeAndTargetQuery } from "../hooks/UseSalesRegionListVolumeAndTargetQuery";
import { transformResultToTableRow } from "../transformers/ResultTableRowTransformer";
import { NegotiationStatusCode } from "@/gql/graphql";

export const StatisticsBySalesRegionTable: React.FC = () => {
  const navigate = useNavigate();
  const { year: negotiationYear } = useStatisticsYearSelect();
  const { currency: currencyCode } = useCurrencySelection();
  const {
    salesRegionCustomerOrVolumeFilter,
    setSalesRegionCustomerOrVolumeFilter,
    salesRegionSelectedStatuses,
    setSalesRegionSelectedStatuses,
  } = useContext(StatisticsContext) as StatisticsContextType;

  const { loading, salesRegionItems } = useSalesRegionListQuery({
    year: negotiationYear,
    salesRegionCodes: Object.values(SalesRegionCodes),
  });

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(
        salesRegionSelectedStatuses
      ),
    [salesRegionSelectedStatuses]
  );

  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;

  const { loading: loadingVolumeAndTarget, volumeAndTarget } =
    useSalesRegionListVolumeAndTargetQuery({
      year: negotiationYear,
      salesRegionCodes: Object.values(SalesRegionCodes),
      negotiationStatusCodes,
      currencyCode,
    });

  const resultRows = useMemo(
    () =>
      salesRegionItems?.map((salesRegionItem) =>
        transformResultToTableRow(
          salesRegionItem,
          loadingVolumeAndTarget ? [] : volumeAndTarget ?? [],
          salesRegionCustomerOrVolumeFilter
        )
      ),
    [
      salesRegionItems,
      volumeAndTarget,
      loadingVolumeAndTarget,
      salesRegionCustomerOrVolumeFilter,
    ]
  );

  const resultConfig = createResultTableConfig(
    "Region",
    (id) => navigate(routeFactory.statistics.salesRegionDetails(id)),
    currencyCode,
    salesRegionSelectedStatuses,
    setSalesRegionSelectedStatuses,
    salesRegionCustomerOrVolumeFilter,
    setSalesRegionCustomerOrVolumeFilter
  );

  return (
    <Card minWidth={"900px"}>
      <CardHeader text={"Sales Region"} />
      <ResultTable
        items={resultRows}
        config={resultConfig}
        loading={loading || loadingVolumeAndTarget}
      />
    </Card>
  );
};
