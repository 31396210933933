import * as React from "react";
import { KeyboardEventHandler, RefObject, useRef } from "react";
import { Row, Text } from "@stenajs-webui/core";
import styles from "./ListBox.module.css";
import cx from "classnames";

export interface ListBoxOption {
  value: string;
  label: string;
}

interface Props<TOption extends ListBoxOption> {
  value?: TOption;
  onValueChange?: (value: TOption) => void;
  options: Array<TOption>;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  tabIndex?: number;
  listRef?: RefObject<HTMLUListElement>;
  height?: string;
  maxHeight?: string;
}

export const ListBox = function BrowsableList<TOption extends ListBoxOption>({
  value,
  onValueChange,
  options,
  onMoveUp,
  onMoveDown,
  tabIndex = 0,
  listRef,
  height,
  maxHeight,
}: Props<TOption>) {
  const internalListRef = useRef<HTMLUListElement>(null);

  const activeListRef = listRef ?? internalListRef;

  const onValueChangeHandler = (
    ev: React.KeyboardEvent<HTMLUListElement>,
    index: number
  ) => {
    if (onValueChange) {
      const optionElements =
        activeListRef.current?.querySelectorAll('[role="option"]') ?? [];
      optionElements[index]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      onValueChange(options[index]);
      ev.stopPropagation();
      ev.preventDefault();
    }
  };

  const onKeyDownHandler: KeyboardEventHandler<HTMLUListElement> = (ev) => {
    if (onValueChange) {
      const currentIndex = options.findIndex((o) => o.value === value?.value);

      if (ev.key === "ArrowDown") {
        if (currentIndex < options.length - 1) {
          onValueChangeHandler(ev, currentIndex + 1);
        } else if (onMoveDown) {
          onMoveDown();
          ev.stopPropagation();
          ev.preventDefault();
        }
      }
      if (ev.key === "ArrowUp") {
        if (currentIndex > 0) {
          onValueChangeHandler(ev, currentIndex - 1);
        } else if (onMoveUp) {
          onMoveUp();
          ev.stopPropagation();
          ev.preventDefault();
        }
      }
      if (ev.key === "Home") {
        onValueChangeHandler(ev, 0);
      }
      if (ev.key === "End") {
        onValueChangeHandler(ev, options.length - 1);
      }
    }
  };

  return (
    <ul
      className={styles.listBox}
      ref={activeListRef}
      tabIndex={tabIndex}
      onKeyDown={onKeyDownHandler}
      style={{ height, maxHeight, padding: "4px 0" }}
    >
      {options.map((item, i) => {
        const isSelected = value?.value === item.value;
        return (
          <li
            onClick={() => onValueChange && onValueChange(item)}
            role={"option"}
            aria-selected={isSelected}
            style={{ padding: "2px 0" }}
          >
            <Row
              alignItems={"center"}
              indent={2}
              spacing
              className={cx(styles.listBoxItem, {
                [styles.selected]: isSelected,
              })}
            >
              <Text>{item.label}</Text>
            </Row>
          </li>
        );
      })}
    </ul>
  );
};
