import { createEntityActions, reducerIdGateAction } from "@stenajs-webui/redux";
import {
  customerAgreementsReducerId,
  CustomerAgreementTabState,
} from "./reducers";

export const customerAgreementActions = {
  updateTabState: (fields: Partial<CustomerAgreementTabState>) =>
    reducerIdGateAction(
      customerAgreementsReducerId,
      createEntityActions<CustomerAgreementTabState>().setEntityFields(fields)
    ),
};
