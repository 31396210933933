import { CSSObject } from "@emotion/react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../config/redux/RootReducer";

export interface NoShowLateHandlingSelectValidFrom
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  onValueChange: (previousId: string, selectedId: string) => void;
  containerWidth?: string;
  routePairCode: string;
  isDisabled: boolean;
}

export const NoShowLateHandlingValidFromSelect: React.FC<
  NoShowLateHandlingSelectValidFrom
> = ({
  onValueChange,
  containerWidth,
  routePairCode,
  isDisabled,
  ...selectProps
}) => {
  const { value, options } = useSelector((state: StoreState) => {
    const rowData = groupBy(
      state.noShowLateHandlingPrices.allNoShowHandlingPriceData,
      (d) => d.editable.routePairCode
    );

    return {
      options: rowData[routePairCode].map((o) => ({
        label: o.editable.validFrom,
        value: o.id,
      })),
      value:
        state.noShowLateHandlingPricesCurrentlySelected.selected
          .flat()
          .find((s) => s.routePairCode === routePairCode)
          ?.currentlySelectedId ?? "",
    };
  });

  let selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  if (!selected && options[0]) {
    selected = options[0];
  }

  return (
    <Select
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      {...selectProps}
      styles={{
        container: (base: CSSObject) => ({
          ...base,
          width: `${containerWidth}`,
        }),
      }}
      isDisabled={isDisabled}
      value={selected}
      closeMenuOnScroll={(e: Event) => e && true}
      options={options}
      onChange={(item: any) =>
        onValueChange && onValueChange(value, item.value)
      }
    />
  );
};
