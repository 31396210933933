import * as React from "react";
import { Indent, Text } from "@stenajs-webui/core";
import { RateSheetTableRowState } from "../reducer";
import { ModifiedHighlightText } from "./ModifiedHighlightText";
import {
  MAX_LENGTH,
  MIN_LENGTH,
} from "../add-vehicle-type/models/AddVehicleTypeFormModel";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";

interface Props {
  item: RateSheetTableRowState;
}

export const LengthCell: React.FC<Props> = ({ item }) => {
  if (
    (item.editable.lengthFrom == null ||
      item.editable.lengthFrom === MIN_LENGTH) &&
    (item.editable.lengthTo == null || item.editable.lengthTo >= MAX_LENGTH)
  ) {
    if (
      (item.persisted?.lengthFrom == null ||
        item.persisted?.lengthFrom === MIN_LENGTH) &&
      (item.persisted?.lengthTo == null ||
        item.persisted?.lengthTo >= MAX_LENGTH)
    ) {
      return <ModifiedHighlightText editable={"Any"} persisted={"Any"} />;
    } else {
      return <ModifiedHighlightText editable={"Any"} persisted={""} />;
    }
  }
  return (
    <>
      <ModifiedHighlightText
        editable={item.editable.lengthFrom}
        persisted={item.persisted?.lengthFrom}
        dotToCommaTransform={dotToCommaTransform}
      />
      <Indent num={0.5}>
        <Text>to</Text>
      </Indent>
      <ModifiedHighlightText
        editable={item.editable.lengthTo}
        persisted={item.persisted?.lengthTo}
        dotToCommaTransform={dotToCommaTransform}
      />
    </>
  );
};
