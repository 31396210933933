import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import { CargoStatusCode } from "../../utils/constants";
import { RouteAgreementPricesByFilterInput } from "@/gql/graphql";

export const transformFilterModelToFilterInput = (
  agreementId: string,
  committedState: WorkspaceState
): RouteAgreementPricesByFilterInput => {
  const routePairCodes = committedState.routePairs.map((rp) => rp.code);
  const sailingTypeCodes = committedState.routeAgreementSailingCategories.map(
    (sc) => sc.code
  );

  const vehicleTypeAllChecked = committedState.vehicleTypeAll;
  const vehicleTypeAllString = [""];
  const vehicleTypeCodesWithoutTypeAll = committedState.vehicleTypes.map(
    (vt) => vt.code
  );

  const vehicleTypeCodes = vehicleTypeAllChecked
    ? vehicleTypeCodesWithoutTypeAll.concat(vehicleTypeAllString)
    : vehicleTypeCodesWithoutTypeAll;

  const lengthFrom =
    committedState.vehicleLength.from === undefined
      ? null
      : committedState.vehicleLength.from;
  const lengthTo =
    committedState.vehicleLength.to === undefined
      ? null
      : committedState.vehicleLength.to;

  const weightFrom =
    committedState.vehicleWeight.from === undefined
      ? null
      : committedState.vehicleWeight.from;
  const weightTo =
    committedState.vehicleWeight.to === undefined
      ? null
      : committedState.vehicleWeight.to;

  const emptyLoaded = committedState.emptyLoaded.map((el) => el.code);

  const unaccompanied = committedState.unacc.map((unacc) => {
    if (unacc.code === CargoStatusCode.both) return null;
    if (unacc.code === CargoStatusCode.unaccompanied) return "Y";
    if (unacc.code === CargoStatusCode.accompanied) return "N";
    return "";
  });

  return {
    agreementId,
    routePairCodes,
    sailingTypeCodes,
    vehicleTypeCodes,
    lengthFrom,
    lengthTo,
    weightFrom,
    weightTo,
    emptyLoaded,
    unaccompanied,
  };
};
