import {
  Box,
  Column,
  Row,
  Space,
  Spacing,
  Txt,
  useBoolean,
} from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { BulkUpdateRatesForm } from "../../bulk-update-rates/components/BulkUpdateRatesForm";
import {
  BulkUpdateRatesFormModel,
  RaiseType,
} from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { validateBulkUpdateFormModel } from "../../bulk-update-rates/validators/FormModelValidator";
import { useRaisePriceLogic } from "../hooks/UseRaisePriceLogic";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  onSuccess: () => void;
  value: BulkUpdateRatesFormModel;
  onValueChange: (value: BulkUpdateRatesFormModel) => void;
  routeAgreementId: string;
  routeAgreementPriceId: string;
  currencyLabel?: string;
}

export const RaisePrice: React.FC<Props> = ({
  onSuccess,
  value,
  onValueChange,
  currencyLabel,
  routeAgreementId,
  routeAgreementPriceId,
}) => {
  const [localError, setLocalError] = useState<Error | undefined>();
  const { raisePriceInModifiedState } = useRaisePriceLogic();
  const [visible, show, hide] = useBoolean(false);

  const standardTableSelector = useCallback(
    (state: StoreState) => {
      const selectedRouteAgreement = Object.values(
        state.rateSheet.routeAgreements
      ).find((ra) => {
        return ra?.id === routeAgreementId;
      });

      return selectedRouteAgreement?.prices;
    },
    [routeAgreementId]
  );

  const selectedRouteAgreementPrices = useSelector(standardTableSelector);

  const submitHandler = useCallback(async () => {
    const selectedRouteAgreementPrice = selectedRouteAgreementPrices?.find(
      (price) => price.id === routeAgreementPriceId
    );
    setLocalError(undefined);
    try {
      if (
        selectedRouteAgreementPrice &&
        Number(selectedRouteAgreementPrice.meterPrice.amount ?? 0) < 1 &&
        value.raiseType === RaiseType.BY_METER_PRICE
      ) {
        show();
        return;
      }
      validateBulkUpdateFormModel(value);
      raisePriceInModifiedState(routeAgreementId, routeAgreementPriceId, value);
      onSuccess();
    } catch (e) {
      setLocalError(e);
    }
  }, [
    selectedRouteAgreementPrices,
    show,
    value,
    onSuccess,
    routeAgreementId,
    routeAgreementPriceId,
    raisePriceInModifiedState,
  ]);

  const onYesPromptSelectedRaise = useCallback(async () => {
    setLocalError(undefined);
    try {
      validateBulkUpdateFormModel(value);
      raisePriceInModifiedState(routeAgreementId, routeAgreementPriceId, value);
      onSuccess();
    } catch (e) {
      setLocalError(e);
    }
  }, [
    value,
    onSuccess,
    routeAgreementId,
    routeAgreementPriceId,
    raisePriceInModifiedState,
  ]);

  return (
    <Column indent={2} spacing={2}>
      <BulkUpdateRatesForm
        currencyLabel={currencyLabel}
        value={value}
        onValueChange={onValueChange}
        disableFilters
      />
      <Spacing />
      <Row justifyContent={"center"}>
        <PrimaryButton label={"Apply raise"} onClick={submitHandler} />
      </Row>
      {visible && (
        <>
          <Spacing />
          <Box width={"100%"}>
            <Box
              background={cssColor("--lhds-color-orange-50")}
              indent={2}
              spacing={2}
              borderRadius={2}
            >
              <Txt size={"small"}>
                You have selected a row where the meter price is 0. Are you sure
                you want to continue?
              </Txt>
            </Box>
            <Space num={2} />
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Space num={2} />
              <PrimaryButton label="Yes" onClick={onYesPromptSelectedRaise} />
              <Space num={2} />
              <FlatButton label="No" onClick={hide} />
            </Box>
          </Box>
        </>
      )}
      {localError && (
        <Row justifyContent={"flex-end"} spacing>
          <ValidationErrorMessage error={localError} />
        </Row>
      )}
    </Column>
  );
};
