import * as React from "react";
import { Window } from "@stenajs-webui/modal";
import { MultiLegAgreement } from "../../../../types";
import { useBannerError } from "../../../../../../../../common/error/UseBannerError";
import {
  Column,
  Indent,
  Row,
  SeparatorLine,
  Space,
  Spacing,
  Txt,
} from "@stenajs-webui/core";
import { HeadAgreementStatusCodeChip } from "../../../../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import {
  Banner,
  FlatButton,
  Icon,
  Label,
  PrimaryButton,
  stenaExclamationTriangle,
} from "@stenajs-webui/elements";
import { canDeleteAgreement } from "../../util/AgreementStatusUtil";
import { useDeleteMultiLegRouteAgreementMutation } from "./hooks/UseDeleteMultiLegRouteAgreementMutation";
import { headAgreementQuery } from "../../../../hooks/UseHeadAgreementById";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementQuery, HeadAgreementQueryVariables } from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  year: number;
  multiLegAgreement: MultiLegAgreement;
  onRequestClose: () => void;
}

export const ConfirmMultiLegRouteAgreementDeleteModal: React.FC<Props> = ({
  multiLegAgreement,
  headAgreementId,
  year,
  onRequestClose,
}) => {
  const canDelete =
    multiLegAgreement.routeAgreements.some((ra) =>
      canDeleteAgreement(ra.statusCode)
    ) || multiLegAgreement.routeAgreements.length === 0;

  const [deleteMultiLegRouteAgreement, { loading }] =
    useDeleteMultiLegRouteAgreementMutation();

  const {
    setBannerError,
    hasBannerError,
    clearBannerError,
    bannerErrorText,
    bannerErrorHeaderText,
  } = useBannerError();

  const onDeleteMultiLegRouteAgreement = async () => {
    clearBannerError();
    try {
      const { data } = await deleteMultiLegRouteAgreement({
        variables: {
          deleteMultiLegRouteAgreementMultiLegRouteAgreementId:
            multiLegAgreement.id,
          deleteMultiLegRouteAgreementRowVersion: multiLegAgreement.rowVersion,
        },
        update: (store, { data }) => {
          const mutationResult =
            data?.productPrice.multiLegRouteAgreement
              .deleteMultiLegRouteAgreement;
          if (!(mutationResult && "errors" in mutationResult)) {
            const query = store.readQuery<
              HeadAgreementQuery,
              HeadAgreementQueryVariables
            >({
              query: headAgreementQuery,
              variables: {
                customerId: multiLegAgreement.headAgreement.customer.id,
                year,
                headAgreementId,
              },
            });

            if (query && query.productPrice.headAgreement.byId) {
              const newQuery: HeadAgreementQuery = {
                ...query,
                productPrice: {
                  ...query.productPrice,
                  headAgreement: {
                    ...query.productPrice.headAgreement,
                    byId: {
                      ...query.productPrice.headAgreement.byId,
                      multiLegAgrRoutes:
                        query.productPrice.headAgreement.byId.multiLegAgrRoutes.filter(
                          (ra) => ra.id !== multiLegAgreement.id
                        ),
                    },
                  },
                },
              };

              store.writeQuery({
                query: headAgreementQuery,
                variables: {
                  customerId: multiLegAgreement.headAgreement.customer.id,
                  year,
                  headAgreementId,
                },
                data: newQuery,
              });
            }
          }
        },
      });

      const mutationResult =
        data?.productPrice.multiLegRouteAgreement.deleteMultiLegRouteAgreement;
      if (mutationResult && "errors" in mutationResult) {
        throw new Error(mutationResult.errors.join("\n"));
      } else {
        onRequestClose();
      }
    } catch (err) {
      setBannerError("Failed to delete", err);
    }
  };

  return (
    <Window
      isOpen={true}
      width={"500px"}
      onRequestClose={onRequestClose}
      header={
        <Txt variant={"bold"} size={"large"}>
          {"Delete multi leg agreement?"}
        </Txt>
      }
    >
      <Column indent spacing>
        <Column indent spacing>
          <Txt variant={"bold"}>Multi leg agreement</Txt>
          <Spacing num={3}>
            <Label textWidth={"58px"} text={"Status"} row>
              <HeadAgreementStatusCodeChip
                statusCode={multiLegAgreement.statusCode}
              />
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Id number"} row>
              <Txt>{multiLegAgreement.idNumber}</Txt>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Name"} row>
              <Txt>{multiLegAgreement.name}</Txt>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Currency"} row>
              <Txt>{multiLegAgreement.currency.code}</Txt>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Validity"} row>
              <Txt>{formatGQLDateRange(multiLegAgreement.valid)}</Txt>
            </Label>
          </Spacing>

          {!canDelete && (
            <>
              <Row
                border={`1px solid ${cssColor("--lhds-color-ui-300")}`}
                indent
                spacing
              >
                <Icon
                  icon={stenaExclamationTriangle}
                  color={cssColor("--lhds-color-blue-500")}
                />
                <Indent />
                <Txt>
                  You can only delete multi leg agreements with status PENDING.
                </Txt>
              </Row>
              <Spacing num={2} />
            </>
          )}
          <Row justifyContent={"flex-end"}>
            <FlatButton onClick={onRequestClose} label={"No"} />
            <Space num={2} />
            <PrimaryButton
              loading={loading}
              disabled={!canDelete}
              variant={"danger"}
              onClick={onDeleteMultiLegRouteAgreement}
              label={"Yes, delete it"}
            />
          </Row>
        </Column>
        {hasBannerError && bannerErrorText && (
          <>
            <Space num={4} />
            <Banner
              text={bannerErrorText}
              headerText={bannerErrorHeaderText}
              variant={"error"}
            />
          </>
        )}
      </Column>
    </Window>
  );
};
