import { gql } from "apollo-boost";
import { UnitType } from "../../types/FilterEntitys";
import { useQuery } from "@apollo/client";
import { UnitTypeFilterQuery } from "@/gql/graphql";

const unitTypeFilterQuery = gql`
  query UnitTypeFilter {
    productPrice {
      unitType {
        all {
          id
          code
          name
        }
      }
    }
  }
`;

export const UseFetchAllUnitTypes = () => {
  const { data, error, loading, refetch } = useQuery<UnitTypeFilterQuery>(
    unitTypeFilterQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  const unitTypes =
    data?.productPrice.unitType.all.map<UnitType>((r) => ({
      id: r.id,
      code: r.id,
      name: r.name,
    })) ?? [];
  return {
    unitTypes,
    error,
    loading,
    refetch,
  };
};
