import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import { stripLineFromSellerName } from "../../../../common/transformers/SellerNameTransformer";
import { createStandardTableCell } from "../../../targeting-tool/admin-target/components/all-routes/AllRoutesTableStatisticsStandardCell";
import { CreateStandardNumberTableCellNoShowLateHandlingHandleBookings } from "../components/CreateStandardNumberTableCellNoShowLateHandlingHandleBookings";
import { CustomerCheckboxWrapper } from "../components/CustomerCheckboxWrapper";
import { HandlingCategories } from "../NoShowLateHandlingHandleBookingsScreen";
import { NoShowLateHandlingHandleCustomerBookingsTable } from "../NoShowLateHandlingHandleCustomerBookingsTable";
import {
  bookingStatusColumnOrder,
  customerInfoColumnOrder,
} from "./NoShowLateHandlingHandleBookingsTableColumnGroups";
import {
  NoShowLateHandlingHandleCustomerDataRow,
  NoShowLateHandlingHandleCustomerDataTableColumnKeys,
} from "./RowsAndColumnsConfig";

interface ConfigVariables {
  numberOfToDo: number;
  numberOfToBeInvoiced: number;
  numberOfInvoiced: number;
  numberOfThreshold: number;
  numberOfDoNotInvoice: number;
  numberOfNotChargeable: number;
  total: number;
  handlingCategories: HandlingCategories;
}

export const noShowLateHandlingHandleCustomerDataTableConfig = ({
  numberOfDoNotInvoice,
  numberOfInvoiced,
  numberOfThreshold,
  numberOfToBeInvoiced,
  numberOfToDo,
  numberOfNotChargeable,
  total,
  handlingCategories,
}: ConfigVariables) => {
  const noShowLateHandlingTableConfig: StandardTableConfigWithGroups<
    NoShowLateHandlingHandleCustomerDataRow,
    NoShowLateHandlingHandleCustomerDataTableColumnKeys
  > = {
    disableInfiniteList: true,
    disableSorting: false,
    enableExpandCollapse: true,
    showHeaderExpandCollapse: true,
    headerRowOffsetTop: "114px",
    initialSortOrder: "customerName",
    enableGridCell: true,
    stickyHeader: true,
    keyResolver: (item) => item.id,
    columns: {
      checkboxColumn: createColumnConfig((item) => "", {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => {
          return (
            <CustomerCheckboxWrapper
              customerNo={item.id}
              bookings={item.bookingsData}
              disableCashCustomers={item.customer.modeOfPay === "C"}
            />
          );
        },
        columnLabel: "",
        width: "48px",
        disableGridCell: true,
      }),
      customerName: createColumnConfig((item) => item.customer.name, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        columnLabel: "Name",
        width: "300px",
      }),
      custNo: createColumnConfig((item) => item.customer.custNo, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.customer.custNo),
        columnLabel: "No.",
        minWidth: "37px",
      }),
      custIndex: createColumnConfig((item) => item.customer.custIndex, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.customer.custIndex),
        columnLabel: "Index",
        minWidth: "50px",
      }),
      region: createColumnConfig((item) => item.region, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(item.region),
        columnLabel: "Region",
        minWidth: "40px",
      }),
      seller: createColumnConfig((item) => item.seller, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createStandardTableCell(stripLineFromSellerName(item.seller)),
        columnLabel: "Seller",
        minWidth: "50px",
      }),
      noOfToDo: createColumnConfig((item) => item.noOfToDo, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfToDo
          ),
        columnLabel: `Todo (${numberOfToDo})`,
        minWidth: "120px",
      }),
      noOfToBeInvoiced: createColumnConfig((item) => item.noOfToBeInvoiced, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfToBeInvoiced
          ),
        columnLabel: `TBI (${numberOfToBeInvoiced})`,
        minWidth: "100px",
      }),
      noOfInvoiced: createColumnConfig((item) => item.noOfInvoiced, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfInvoiced
          ),
        columnLabel: `Invoiced (${numberOfInvoiced})`,
        minWidth: "110px",
      }),
      noOfThreshold: createColumnConfig((item) => item.noOfThreshold, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfThreshold
          ),
        columnLabel: `Threshold (${numberOfThreshold})`,
        minWidth: "120px",
      }),
      noOfDoNotInvoice: createColumnConfig((item) => item.noOfDoNotInvoice, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfDoNotInvoice
          ),
        columnLabel: `DNI (${numberOfDoNotInvoice})`,
        minWidth: "100px",
      }),
      noOfNotChargeable: createColumnConfig((item) => item.noOfNotChargeable, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.noOfNotChargeable
          ),
        columnLabel: `Not Chargeable (${numberOfNotChargeable})`,
        minWidth: "100px",
      }),
      total: createColumnConfig((item) => item.total, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          CreateStandardNumberTableCellNoShowLateHandlingHandleBookings(
            item.total
          ),
        columnLabel: `Total (${total})`,
        minWidth: "120px",
      }),
    },
    renderRowExpansion: (item) => (
      <NoShowLateHandlingHandleCustomerBookingsTable
        customerNo={item.id}
        customerBookingsData={item.bookingsData}
        handlingCategories={handlingCategories}
        isCashCustomer={item.customer.modeOfPay === "C"}
        customerNrOfInvoiced={item.noOfInvoiced}
      />
    ),
    columnGroups: {
      customerInfo: {
        columnOrder: customerInfoColumnOrder,
        label: "Customer info",
      },
      bookingStatus: {
        borderLeft: true,
        columnOrder: bookingStatusColumnOrder,
        label: "Booking status (all bookings for selected month)",
      },
    },
    columnGroupOrder: ["customerInfo", "bookingStatus"],
  };

  return noShowLateHandlingTableConfig;
};
