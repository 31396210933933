import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { NegotiationStatusByYear } from "../../customer-list/types";
import {
  SetNegotiationStatusForChildrenMutation,
  SetNegotiationStatusForChildrenMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  mutation SetNegotiationStatusForChildren(
    $input: SetNegotiationStatusInput!
    $year: Int!
  ) {
    productPrice {
      customer {
        setNegotiationStatusForChildren(input: $input) {
          customer {
            id
            childCustomers {
              id
              negotiationStatusByYear(year: $year) {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`;

export const useSetNegotiationStatusForChildCustomers = () => {
  const [mutate, { loading, error }] = useMutation<
    SetNegotiationStatusForChildrenMutation,
    SetNegotiationStatusForChildrenMutationVariables
  >(mutation);

  const setNegotiationStatusForChildren = useCallback(
    (
      customerId: string,
      year: number,
      negotiationStatus: NegotiationStatusByYear
    ) => {
      return mutate({
        variables: {
          input: {
            customerId,
            year,
            negotiationStatusId: negotiationStatus.id,
          },
          year,
        },
      });
    },
    [mutate]
  );

  return {
    setNegotiationStatusForChildren,
    error,
    loading,
  };
};
