import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Indent, Spacing } from "@stenajs-webui/core";
import { StandardTable, TableContext } from "@stenajs-webui/grid";
import {
  MultiLegAgreementsTableConfigColumnKeys,
  multiLegAggreementsTableConfig,
} from "../common/multi-leg-agreements-table-config";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";

export const MultiLegTable = ({
  multilegAgreementRoutes,
  multilegRoutesAgreementTableContext,
}: {
  multilegAgreementRoutes: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
  multilegRoutesAgreementTableContext: TableContext<MultiLegAgreementsTableConfigColumnKeys>;
}) => {
  return (
    <>
      <Spacing num={SpacingValues.SIXTEEN}>
        <Indent num={IndentValues.EIGHT}>
          <StandardTable
            tableId="multileg-route-agreements-table"
            items={multilegAgreementRoutes}
            config={multiLegAggreementsTableConfig}
            tableContext={multilegRoutesAgreementTableContext}
          />
        </Indent>
      </Spacing>
    </>
  );
};
