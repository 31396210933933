import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import { ApolloError } from "@apollo/client";
import { SeparatorLine } from "@stenajs-webui/core";
import { Card, CardBody } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import * as React from "react";
import { childCustomersTableConfig } from "../config/ChildCustomersTableConfig";
import { Customer, CustomerChildren } from "../types";
import { ChildCustomersHeader } from "./ChildCustomersHeader";

interface Props {
  customer: Customer;
  childCustomers: CustomerChildren;
  error: ApolloError | undefined;
}

export const ChildCustomers: React.FC<Props> = ({
  customer,
  childCustomers,
  error,
}) => {
  const breadCrumbName = customer?.breadCrumbName ?? "";
  const customerId = String(customer?.custNo) ?? "";
  const customerHeadAgreementId = customer?.preselectedHeadAgreement?.id ?? "";

  return (
    <>
      <ChildCustomersHeader
        breadCrumbName={breadCrumbName}
        customerId={customerId}
        headAgreementId={customerHeadAgreementId}
      />
      <PageLayout>
        {customer && (
          <CustomerInfoPairBox customer={customer} parentChildLinkDisabled />
        )}

        <Card>
          <CardBody>
            <StandardTable
              items={childCustomers}
              config={childCustomersTableConfig}
              error={error}
              noItemsLabel={"No children found."}
            />
            <SeparatorLine />
          </CardBody>
        </Card>
      </PageLayout>
    </>
  );
};
