import { EditableEntityState } from "@stenajs-webui/redux";
import { AppThunk } from "../../../../../config/redux/RootReducer";
import { format, startOfMonth } from "date-fns";
import { batch } from "react-redux";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { NoShowLateHandlingPricesRow } from "../../config/RowsAndColumnsConfig";
import {
  noShowLateHandlingRoutePricesActions,
  noShowLateHandlingRoutePricesCurrentlySelectedActions,
} from "../actions";
import { CurrentlySelectedSibling, notYetCreatedRowState } from "../reducers";
import { transformNoShowLateHandlingRoutePricesQueryToNoShowLateHandlingPrices } from "../transformers";
import { noShowLateHandlingRoutePricesQuery } from "./NoShowLateHandlingRoutePricesQuery";
import { NoShowLateHandlingRoutePricesQuery } from "@/gql/graphql";

export const fetchNoShowLateHandlingRoutePrices =
  (): AppThunk => async (dispatch) => {
    dispatch(
      noShowLateHandlingRoutePricesActions.setProgress({
        loading: true,
        bannerState: undefined,
      })
    );
    try {
      const { data, errors } =
        await apolloClient.query<NoShowLateHandlingRoutePricesQuery>({
          query: noShowLateHandlingRoutePricesQuery,
          fetchPolicy: "network-only",
        });

      if (errors && errors.length) {
        dispatch(
          noShowLateHandlingRoutePricesActions.setProgress({
            loading: false,
            bannerState: {
              headerText: "Could not fetch all no show late handling prices.",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      } else {
        const noShowLateHandlingPrices =
          transformNoShowLateHandlingRoutePricesQueryToNoShowLateHandlingPrices(
            data.productPrice.noShowLateHandlingPrices?.all
          );

        batch(() => {
          const siblingsDataArray =
            data.productPrice.routePair.allWithStatusOwn.map<
              EditableEntityState<NoShowLateHandlingPricesRow>[]
            >((route) => {
              const nslhPrices = noShowLateHandlingPrices.find(
                (nslhp) => nslhp.routePairCode === route.code
              );
              if (!nslhPrices) {
                const getDefaultValidFromDate = () => {
                  const breakDate = new Date("2022-01-01");
                  const today = new Date();

                  return format(
                    breakDate < today ? startOfMonth(today) : breakDate,
                    "yyyy-MM-dd"
                  );
                };
                return [
                  {
                    id: `${route.code}:${getDefaultValidFromDate()}`,
                    editable: {
                      ...notYetCreatedRowState,
                      id: `${route.code}:${getDefaultValidFromDate()}`,
                      routePairCode: route.code,
                      routePair: {
                        description: route.description,
                      },
                      validFrom: getDefaultValidFromDate(),
                    },
                    persisted: {
                      ...notYetCreatedRowState,
                      id: `${route.code}:${getDefaultValidFromDate()}`,
                      routePairCode: route.code,
                      routePair: {
                        description: route.description,
                      },
                      validFrom: getDefaultValidFromDate(),
                    },
                  },
                ];
              }
              return nslhPrices.siblings.map((sibling) => ({
                id: sibling.id,
                editable: {
                  ...sibling,
                  newlyAddedRow: false,
                },
                persisted: {
                  ...sibling,
                  newlyAddedRow: false,
                },
              }));
            });

          const siblingData = siblingsDataArray.flat();
          const initialCurrentSelectedData =
            data.productPrice.routePair.allWithStatusOwn.map(
              (nslh): CurrentlySelectedSibling => {
                const filteredSiblingData = siblingData.filter(
                  (routePair) => routePair.persisted.routePairCode === nslh.code
                );
                return {
                  currentlySelectedId:
                    filteredSiblingData.length !== 0
                      ? filteredSiblingData[0].id ?? ""
                      : "",
                  routePairCode: nslh.code,
                };
              }
            );

          dispatch(
            noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
              initialCurrentSelectedData
            )
          );
          dispatch(
            noShowLateHandlingRoutePricesActions.setRowsNoShowLateHandlingPricesAll(
              siblingData
            )
          );
        });
      }
    } catch (error) {
      console.error({ error });
      dispatch(
        noShowLateHandlingRoutePricesActions.setProgress({
          bannerState: {
            headerText: "Could not fetch all no show late handling prices.",
            items: [{ text: error.message }],
          },
        })
      );
    } finally {
      dispatch(
        noShowLateHandlingRoutePricesActions.setProgress({ loading: false })
      );
    }
  };
