import { RateSheetTableAction } from "./actions";
import { RateSheetRouteAgreementPrice } from "./types";
import { Reducer } from "redux";
import { isEqual } from "lodash";
import { uuid } from "../../../common/uuid/UuidFactory";

export type RateSheetTableRowsState = Array<RateSheetTableRowState>;

export type RateSheetTableState = {
  rows: RateSheetTableRowsState;
  selectedRowId: string | null;
};

export interface RateSheetTableRowState {
  routeAgreementPriceId: string;
  persisted: RateSheetRouteAgreementPrice | null;
  editable: RateSheetRouteAgreementPrice;
  modified: boolean;
  deleted: boolean;
  isDrawerOpen?: boolean;
}

export const rateSheetTableReducer: Reducer<
  RateSheetTableState,
  RateSheetTableAction
> = (
  state = { rows: [], selectedRowId: null },
  action
): RateSheetTableState => {
  switch (action.type) {
    case "RATE_SHEET:CLEAR_ALL": {
      return { rows: [], selectedRowId: null };
    }

    case "RATE_SHEET:POPULATE_WITH_PERSISTED_PRICES": {
      const { prices } = action;
      return {
        selectedRowId: null,
        rows: prices.map<RateSheetTableRowState>((r) => ({
          editable: r,
          persisted: r,
          routeAgreementPriceId: r.id,
          modified: false,
          deleted: false,
        })),
      };
    }

    case "RATE_SHEET:UPSERT_WITH_PERSISTED_PRICE": {
      const { price } = action;

      const alreadyExists = state.rows.some(
        (s) => s.routeAgreementPriceId === price.id
      );

      if (alreadyExists) {
        return {
          ...state,
          rows: state.rows.map((p) =>
            p.routeAgreementPriceId === price.id
              ? {
                  editable: price,
                  persisted: price,
                  routeAgreementPriceId: price.id,
                  modified: false,
                  deleted: false,
                }
              : p
          ),
        };
      } else {
        return {
          ...state,
          rows: [
            {
              editable: price,
              persisted: price,
              routeAgreementPriceId: price.id,
              modified: false,
              deleted: false,
            },
            ...state.rows,
          ],
        };
      }
    }

    case "RATE_SHEET:CLEAR_NOT_PERSISTED_PRICES": {
      return { ...state, rows: state.rows.filter((p) => p.persisted) };
    }

    case "RATE_SHEET:REVERT_ALL_MODIFICATIONS": {
      return {
        ...state,
        rows: state.rows
          .filter((r) => r.persisted)
          .map<RateSheetTableRowState>((r) => ({
            ...r,
            editable: { ...r.persisted! },
            modified: false,
            deleted: false,
          })),
      };
    }

    case "RATE_SHEET:CREATE_NEW_PRICE": {
      const { price } = action;
      return {
        ...state,
        rows: [
          {
            routeAgreementPriceId: price.id,
            persisted: null,
            editable: price,
            modified: true,
            deleted: false,
          },
          ...state.rows,
        ],
      };
    }

    case "RATE_SHEET:SET_EDITABLE_ACTUAL_PRICE_CHANGE": {
      const { actual, routeAgreementPriceId } = action;
      return {
        ...state,
        rows: state.rows.map<RateSheetTableRowState>((item) => {
          if (item.routeAgreementPriceId === routeAgreementPriceId) {
            if (item.editable == null) {
              throw new Error("Cannot update item that does not exist.");
            }
            const newVar: RateSheetTableRowState = {
              ...item,
              editable: { ...item.editable, actual },
            };
            const modified = !isEqual(newVar.editable, item.persisted);
            return { ...newVar, modified };
          } else {
            return item;
          }
        }),
      };
    }
    case "RATE_SHEET:SET_EDITABLE_PRICE_FIELDS": {
      const { fields, routeAgreementPriceId } = action;
      return {
        ...state,
        rows: state.rows.map<RateSheetTableRowState>((item) => {
          if (item.routeAgreementPriceId === routeAgreementPriceId) {
            if (item.editable == null) {
              throw new Error("Cannot update item that does not exist.");
            }
            const newVar: RateSheetTableRowState = {
              ...item,
              editable: { ...item.editable, ...fields },
            };
            const modified = !isEqual(newVar.editable, item.persisted);
            return { ...newVar, modified };
          } else {
            return item;
          }
        }),
      };
    }

    case "RATE_SHEET:SET_EDITABLE_NEW_PRICE": {
      const { newPrice, routeAgreementPriceId } = action;
      return {
        ...state,
        rows: state.rows.map<RateSheetTableRowState>((item) => {
          if (item.routeAgreementPriceId === routeAgreementPriceId) {
            if (item.editable == null) {
              throw new Error("Cannot update item that does not exist.");
            }
            const newItem: RateSheetTableRowState = {
              ...item,
              editable: { ...item.editable, newPrice: { amount: newPrice } },
            };
            const modified = !isEqual(newItem.editable, item.persisted);
            return { ...newItem, modified };
          } else {
            return item;
          }
        }),
      };
    }

    case "RATE_SHEET:ADD_HIGHLIGHT_ROW": {
      const { routeAgreementPriceId } = action;

      return {
        ...state,
        selectedRowId: routeAgreementPriceId,
      };
    }

    case "RATE_SHEET:REMOVE_HIGHLIGHT_ROW": {
      const { routeAgreementPriceId } = action;

      if (state.selectedRowId === routeAgreementPriceId) {
        return {
          ...state,
          selectedRowId: null,
        };
      } else {
        return state;
      }
    }

    case "RATE_SHEET:SET_EDITABLE_METER_PRICE": {
      const { meterPrice, routeAgreementPriceId } = action;
      return {
        ...state,
        rows: state.rows.map<RateSheetTableRowState>((item) => {
          if (item.routeAgreementPriceId === routeAgreementPriceId) {
            if (item.editable == null) {
              throw new Error("Cannot update item that does not exist.");
            }
            const newVar: RateSheetTableRowState = {
              ...item,
              editable: {
                ...item.editable,
                meterPrice: { amount: meterPrice },
              },
            };
            const modified = !isEqual(newVar.editable, item.persisted);
            return { ...newVar, modified };
          } else {
            return item;
          }
        }),
      };
    }

    case "RATE_SHEET:MARK_PRICE_AS_DELETED": {
      const { routeAgreementPriceId } = action;

      const item = state.rows.find(
        (r) => r.routeAgreementPriceId === routeAgreementPriceId
      );

      if (item === undefined) {
        return state;
      }

      if (item.persisted) {
        return {
          ...state,
          rows: state.rows.map((item) =>
            item.routeAgreementPriceId === routeAgreementPriceId
              ? {
                  ...item,
                  modified: true,
                  deleted: true,
                }
              : item
          ),
        };
      } else {
        return {
          ...state,
          rows: state.rows.filter(
            (item) => item.routeAgreementPriceId !== routeAgreementPriceId
          ),
        };
      }
    }

    case "RATE_SHEET:REMOVE_DELETED_MARK": {
      const { routeAgreementPriceId } = action;

      const item = state.rows.find(
        (r) => r.routeAgreementPriceId === routeAgreementPriceId
      );

      if (item === undefined) {
        return state;
      }

      if (item.persisted) {
        return {
          ...state,
          rows: state.rows.map((item) => {
            if (item.routeAgreementPriceId === routeAgreementPriceId) {
              const modified = !isEqual(item.persisted, item.editable);
              return {
                ...item,
                modified,
                deleted: false,
              };
            } else {
              return item;
            }
          }),
        };
      } else {
        return {
          ...state,
          rows: state.rows.filter(
            (item) => item.routeAgreementPriceId !== routeAgreementPriceId
          ),
        };
      }
    }

    case "RATE_SHEET:MAKE_COPY_OF_ROW": {
      const { routeAgreementPriceId } = action;
      const index = state.rows.findIndex(
        (r) => r.routeAgreementPriceId === routeAgreementPriceId
      );
      if (index < 0) {
        throw new Error("Can not copy, no item with specified id.");
      }
      const source = state.rows[index];

      const newId = uuid();

      const copy: RateSheetTableRowState = {
        deleted: false,
        editable: {
          ...source.editable,
          id: newId,
        },
        modified: true,
        persisted: null,
        routeAgreementPriceId: newId,
      };
      const newState = { rows: [...state.rows] };
      newState.rows.splice(index + 1, 0, copy);
      return { ...state, rows: newState.rows };
    }
  }
};
