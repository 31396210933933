import { RoutesFormModel } from "../RoutesFormModel";

export const routeAgreementUpdateShouldTriggerRecalculation = (
  fields: Partial<RoutesFormModel>
): boolean =>
  "routeIds" in fields ||
  "currencyId" in fields ||
  "peak" in fields ||
  "offPeak" in fields ||
  "shoulder" in fields ||
  "special" in fields;
