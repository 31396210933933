import * as React from "react";
import { Tab } from "../../../../common/components/tabs/Tab";
import { routeFactory } from "../../../../RouteFactory";
import { TabMenu } from "@stenajs-webui/elements";

interface Props {}

export const StatisticsTabMenu: React.FC<Props> = () => {
  return (
    <TabMenu>
      <Tab label={"Total"} path={routeFactory.statistics.totalTab()} />
      <Tab
        label={"Sales region"}
        path={routeFactory.statistics.salesRegionTab()}
      />

      <Tab label={"Route"} path={routeFactory.statistics.routeTab()} />
      <Tab label={"Seller"} path={routeFactory.statistics.sellerTab()} />
      <Tab label={"Customer"} path={routeFactory.statistics.customerTab()} />
      <Tab label={"Advanced"} path={routeFactory.statistics.advancedTab()} />
    </TabMenu>
  );
};
