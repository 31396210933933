import * as React from "react";
import { Text } from "@stenajs-webui/core";
import { MatrixTimeslot } from "../../../types";
import { cssColor } from "@stenajs-webui/theme";
import { allWeekDays } from "@/common/dates/WeekDays";

export const COLUMN_WIDTH = 32;

interface Props {
  timeslots: MatrixTimeslot[];
}

export const WeekDaysColumn: React.FC<Props> = ({ timeslots }) => (
  <>
    {allWeekDays.map(({ code, dayOfWeek, long }) => {
      const dayHasTimeSlots = timeslots.some((slot) => slot.weekDay === code);
      return (
        <div
          key={code}
          style={{
            gridColumn: `1 / span 1`,
            gridRow: `${dayOfWeek + 1} / span 1`,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: COLUMN_WIDTH,
          }}
        >
          <Text
            size={"small"}
            variant={"bold"}
            color={
              !dayHasTimeSlots ? cssColor("--lhds-color-ui-500") : undefined
            }
          >
            {long}
          </Text>
        </div>
      );
    })}
  </>
);
