import {
  ByStatCustomerNumberOrIndexFragment,
  StatInfoSearchQuery,
  StatInfoSearchQueryVariables,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { gql } from "apollo-boost";

const statInfoSearchQuery = gql`
  fragment ByStatCustomerNumberOrIndex on Customer {
    id
    custIndex
    custStatus {
      id
      name
      code
    }
    custNo
    name
    parent {
      id
    }
  }
  query StatInfoSearch($query: String!) {
    productPrice {
      customers {
        byStatCustomerNumberOrIndex(query: $query) {
          ...ByStatCustomerNumberOrIndex
        }
      }
    }
  }
`;
export type StatInfoResult = ByStatCustomerNumberOrIndexFragment;
export const useLoadStats = () => {
  const [fetchStats, { loading, data, error }] = useLazyQuery<
    StatInfoSearchQuery,
    StatInfoSearchQueryVariables
  >(statInfoSearchQuery);

  return { fetchStats, loading, data, error };
};
