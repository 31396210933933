import { AppThunk } from "../../../../config/redux/RootReducer";
import { rateSheetStandardTableWrapperActions } from "../../rate-sheet/redux";
import { rateSheetTableActions } from "../../rate-sheet/actions";
import { cleanRatePrice } from "../../rate-sheet/util/PriceCleaner";
import { customerRouteRedux } from "../../customer-route/redux";
import { transformRouteAgreementToRoutesForm } from "../../route-agreement/details/features/routes/RoutesFormModel";
import { UpdateRateSheetsForMultiLegRouteAgreementMutation } from "@/gql/graphql";

export const updateUpdatedRateSheetsInState =
  (
    rateSheets: UpdateRateSheetsForMultiLegRouteAgreementMutation["productPrice"]["routeAgreementPrice"]["updateMultiLegRateSheets"]
  ): AppThunk =>
  async (dispatch) => {
    rateSheets.forEach((rateSheet) => {
      if (rateSheet.__typename === "UpdateRateSheetsSuccessResult") {
        dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            rateSheet.routeAgreement.id,
            rateSheetTableActions.populateWithPersistedPrices(
              rateSheet.routeAgreement.prices.map(cleanRatePrice)
            )
          )
        );
        dispatch(
          customerRouteRedux.actions.setRouteAgreementModel(
            rateSheet.routeAgreement.id,
            {
              ...transformRouteAgreementToRoutesForm(rateSheet.routeAgreement),
              rowVersion: rateSheet.routeAgreement.rowVersion,
            }
          )
        );
      }
    });
  };
