import React, { useRef } from "react";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import { StandardTable } from "@stenajs-webui/grid";
import { CustomerSearchResultData } from "../../PriceCalculatorFormFetcher";
import { Box, Row, Spacing, Txt } from "@stenajs-webui/core";
import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { transformSailingTypeCodeToSailingTypeName } from "../../../../common/transformers/SailingTypeTransformer";
import {
  transformPriceDetailTableRows,
  transformQueryToPriceDetailTableRow,
} from "../transformers/TransformPriceDetailTableRow";
import { gtmTransmitter } from "../../../../common/utils/GtmHelper";
import { PriceCalculatorEventsAndActions } from "../../../../common/utils/price-calculator-gtm/PriceCalculatorGtmHelper";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  customerSearchResultData: CustomerSearchResultData;
  routeName: string;
}

export const PriceCalculatorCustomerDetailSectionalTab: React.FC<Props> = ({
  customerSearchResultData,
  routeName,
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);
  const formData = customerSearchResultData.formData;
  const priceCalculatorData = customerSearchResultData.priceCalcData[0];

  const formDataDepartureTime =
    formData.departureTime?.slice(0, 2) +
    ":" +
    formData.departureTime?.slice(2, 4);

  let tripInformation = `${routeName}, ${formData.sailingDate},
  ${
    formData.departureTimeManual
      ? formData.departureTime
      : formDataDepartureTime
  }`;

  if (formData.sailingType) {
    tripInformation += `, ${transformSailingTypeCodeToSailingTypeName(
      formData.sailingType
    )}`;
  }

  const vehicleInformation = `${formData.vehicleShortDescription}, ${formData.vehicleLength}m`;

  const renderCopy = (html: string): string => {
    gtmTransmitter({
      event: PriceCalculatorEventsAndActions.PriceCalculatorEvent,
      actionEvent: PriceCalculatorEventsAndActions.ClickedCopyToClipboard,
    });
    if (formData.vehicleType) {
      return `<b>${tripInformation}</b>
      <br>
      <br>
       <b>${vehicleInformation}</b>
      ${headingH5HolderForCopy.current?.innerHTML}
      ${html}`;
    }

    return `<b>${tripInformation}</b>
    <br>
    <br>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  const priceDetailTableRows: PriceDetailTableRow[] =
    transformPriceDetailTableRows(
      priceCalculatorData,
      transformQueryToPriceDetailTableRow(priceCalculatorData)
    );

  return (
    <>
      <Box spacing={3} indent={3} style={{ paddingBottom: "8px" }}>
        <Row justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <PaddedHeading
              variant={"h4"}
              padding={0}
              data-testid={
                testIdConstantsWebapp.priceCalculatorResultTripInformation
              }
            >
              {tripInformation}
            </PaddedHeading>
          </Box>

          <StandardTableHtmlCopyToClipboardButton
            config={tableConfigHtmlExport}
            items={priceDetailTableRows.filter((tr) => tr.priceExclVat > 0)}
            renderContent={renderCopy}
            size={"medium"}
          />
        </Row>
      </Box>
      <Box indent={3} spacing={2} style={{ paddingTop: "0px" }}>
        <Txt
          size={"large"}
          data-testid={testIdConstantsWebapp.priceCalculatorResultVehicleType}
        >
          {vehicleInformation}
        </Txt>
      </Box>
      <Row indent={3} spacing={1} style={{ paddingTop: "0px" }}>
        <Box ref={headingH5HolderForCopy}>
          <PaddedHeading variant={"h5"} padding={0}>
            Articles specification
          </PaddedHeading>
        </Box>
      </Row>
      <div data-testid={testIdConstantsWebapp.priceCalculatorResultPriceTable}>
        <StandardTable config={tableConfig} items={priceDetailTableRows} />
      </div>
      <Spacing num={1} />
    </>
  );
};
