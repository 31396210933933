import { StoreState } from "../../../../../config/redux/RootReducer";

export const agreementArticlesSelectors = {
  getAgreementArticlesTableItems: (state: StoreState) =>
    state.agreementArticles.tableItems,
  getStandardTableState: (state: StoreState) =>
    state.agreementArticles.standardTableState,
  getTableLoading: (state: StoreState) =>
    state.agreementArticles.agreementArticlesLoading.tableLoading,
  getBulkUpdateLoading: (state: StoreState) =>
    state.agreementArticles.agreementArticlesLoading.bulkUpdateLoading,
  getSaveChangesLoading: (state: StoreState) =>
    state.agreementArticles.agreementArticlesLoading.saveChangesLoading,
  getRouteAgreements: (state: StoreState) =>
    state.agreementArticles.routeAgreements,
  getSaveErrors: (state: StoreState) => state.agreementArticles.saveErrors,
  getTableRowErrors: (state: StoreState) =>
    state.agreementArticles.tableRowErrors,
};
