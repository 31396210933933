import { tippyStyles } from "@/common/components/tooltip/tippy";
import styled from "@emotion/styled";
import { Box, Row, Space } from "@stenajs-webui/core";
import {
  ActionMenuItem,
  SecondaryButton,
  stenaAngleDown,
  stenaCheck,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useState } from "react";
import { NegotiationStatusChips } from "../negotiation-status-chips/NegotiationStatusChips";
import { NegotiationStatusCode } from "@/gql/graphql";

const StyledSecondaryButton = styled(SecondaryButton)`
  color: var(--swui-field-text-color);
  font-weight: var(--swui-font-weight-text);
  justify-content: space-between;
  background: var(--swui-field-bg-enabled);
`;

const StyledActionMenuItem = styled(ActionMenuItem)`
  font-weight: var(--swui-font-weight-text);
`;

export interface NegotiationStatusItem {
  value: string;
  label: string;
}

const statuses: Array<NegotiationStatusItem> = [
  { value: NegotiationStatusCode.T, label: "Todo" },
  { value: NegotiationStatusCode.D, label: "Done" },
  { value: NegotiationStatusCode.I, label: "In progress" },
  { value: NegotiationStatusCode.P, label: "Parked" },
  { value: NegotiationStatusCode.Ro, label: "Ready to offer" },
  { value: NegotiationStatusCode.Ra, label: "Ready to activate" },
  { value: NegotiationStatusCode.R, label: "Renegotiated" },
];

interface Props {
  selectedStatuses: NegotiationStatusItem[];
  setSelectedStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >;
}

export const NegStatusChipMultiSelect: React.FC<Props> = ({
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const [isOpen, setOpen] = useState(false);
  // Temporary selected statuses since we do not want to fetch when an item is clicked.
  // The actual fetch happens in the popovers onClickOutside prop by calling setSelectedStatuses(TempSelectedStatuses);
  const [TempSelectedStatuses, setTempSelectedStatuses] =
    useState<NegotiationStatusItem[]>(selectedStatuses);

  const onClickSelect = (status: NegotiationStatusItem) => {
    TempSelectedStatuses.find(
      (selectedStatus) => selectedStatus.value === status.value
    )
      ? setTempSelectedStatuses(
          TempSelectedStatuses.filter(
            (selectedStatus) => selectedStatus.value !== status.value
          )
        )
      : setTempSelectedStatuses([...TempSelectedStatuses, status]);
  };

  const onClickChipRemove = (selectedStatus: NegotiationStatusItem) => {
    selectedStatuses.find((status) => status.value === selectedStatus.value) &&
      setSelectedStatuses(
        selectedStatuses.filter(
          (status) => status.value !== selectedStatus.value
        )
      );
    TempSelectedStatuses.find(
      (status) => status.value === selectedStatus.value
    ) &&
      setTempSelectedStatuses(
        TempSelectedStatuses.filter(
          (status) => status.value !== selectedStatus.value
        )
      );
  };

  return (
    <Row>
      <Box>
        <Popover
          visible={isOpen}
          onClickOutside={() => {
            setOpen(false);
            setSelectedStatuses(TempSelectedStatuses);
          }}
          placement={"bottom-start"}
          disablePadding
          className={tippyStyles.actionMenu}
          arrow={false}
          content={
            <>
              {statuses.map((status, key) => {
                return (
                  <StyledActionMenuItem
                    key={key}
                    label={status.label}
                    onClick={() => onClickSelect(status)}
                    rightIcon={
                      TempSelectedStatuses.find(
                        (selectedStatus) =>
                          selectedStatus.value === status.value
                      )
                        ? stenaCheck
                        : undefined
                    }
                  />
                );
              })}
            </>
          }
        >
          <StyledSecondaryButton
            label="Status filter"
            rightIcon={stenaAngleDown}
            onClick={() => setOpen(true)}
          />
        </Popover>
      </Box>
      <Space horizontal />
      <NegotiationStatusChips
        selectedStatuses={TempSelectedStatuses}
        onClickRemove={onClickChipRemove}
      />
    </Row>
  );
};
