import { FormulaTypeSelectQuery } from "@/gql/graphql";

export type FormulaField = keyof Omit<
  FormulaTypeSelectQuery["productPrice"]["formula"]["all"]["0"]["includes"],
  "__typename"
>;

export const allFormulaFields: Array<FormulaField> = [
  "drivers",
  "interval1PercentValue",
  "interval2PercentValue",
  "interval3PercentValue",
  "interval4PercentValue",
  "intervalAPercentValue",
  "intervalBPercentValue",
  "intervalCPercentValue",
  "intervalDPercentValue",
  "intervalLength",
  "maxPercent",
  "percent",
  "weight",
  "width",
];
