import { AllMultiLegForMultiLegEditQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Box, Column, Row } from "@stenajs-webui/core";
import { Select } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMemo } from "react";

const query = gql`
  query AllMultiLegForMultiLegEdit {
    productPrice {
      route {
        allMultiLeg {
          id
          name
          code
        }
      }
    }
  }
`;

interface Props {
  value: string;
  onValueChange: (value: string, name: string) => void;
}

export interface ApiRouteLeg {
  id: string;
  legNo: number;
  legRoute: { code: string };
}

export type MultiLegOptionData =
  AllMultiLegForMultiLegEditQuery["productPrice"]["route"]["allMultiLeg"];

export interface MultiLegOption {
  label: string;
  value: string;
  name: string;
}

export const EditMultiLegAgreementRouteSelector: React.FC<Props> = ({
  value,
  onValueChange,
}) => {
  const { loading, data } = useQuery<AllMultiLegForMultiLegEditQuery>(query);

  const multiLegRoutes = useMemo(() => {
    return data?.productPrice?.route?.allMultiLeg;
  }, [data]);

  const options = useMemo<MultiLegOption[] | undefined>(() => {
    return multiLegRoutes
      ?.map((r) => ({
        label: r.code,
        value: r.id,
        name: r.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [multiLegRoutes]);

  const selected = useMemo(
    () => options?.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Column>
      <Row>
        <Box width={"500px"}>
          <Select
            isLoading={loading}
            options={options}
            isClearable
            value={selected}
            onChange={(value) => {
              const option = value as MultiLegOption;
              onValueChange(option?.value, option?.name);
            }}
          />
        </Box>
      </Row>
    </Column>
  );
};
