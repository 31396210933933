import { HeadAgreementStatusCodeChip } from "@/common/components/chips/HeadAgreementStatusCodeChip";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";
import { Space } from "@stenajs-webui/core";
import { CardBody } from "@stenajs-webui/elements";
import { InfoBoxItem } from "../InfoBoxItem";
import { HeadAgreementBox } from "../types";

interface Props {
  headAgreement: HeadAgreementBox;
}

export const HeadAgreementInfoCardBody: React.FC<Props> = ({
  headAgreement,
}) => (
  <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
    <HeadAgreementStatusCodeChip
      statusCode={headAgreement.statusCode}
      size={"small"}
      key={"head-agreement-infobox-key-0"}
    />
    <Space />
    <InfoBoxItem
      leftContent={"Validity"}
      rightContent={formatDateStringRangeWithSwedishTimeZone({
        dateStringFrom: headAgreement.valid.start?.isoString,
        dateStringTo: headAgreement.valid.end?.isoString,
      })}
      key={"head-agreement-infobox-key-1"}
    />
    <InfoBoxItem
      leftContent={"No."}
      rightContent={headAgreement.headAgreementNumber}
      key={"head-agreement-infobox-key-2"}
    />
  </CardBody>
);
