import { useMatch } from "react-router-dom";
import { routeFactory } from "../../../RouteFactory";
import { NslhInvoiceTab } from "../../no-show-late-handling/config/tabConfig";

export const UseTabMatches = () => {
  const tabMatches = {
    customersTabMatch: useMatch(
      routeFactory.productAndPrice.productAndPriceApplication()
    ),
    statisticsTabMatch: useMatch(
      `${routeFactory.statistics.statisticsRoot()}/*`
    ),
    priceCalculatorTabMatch: useMatch(
      routeFactory.priceCalculator.priceCalculatorRoot()
    ),
    routeTargetsTabMatch: useMatch(
      routeFactory.targetingTool.routeTargets.allRoutesMatch()
    ),
    groupFreightTargetsMatch: useMatch(
      routeFactory.targetingTool.adminTarget.allRoutes()
    ),
    surchargeDocumentsMatch: useMatch(routeFactory.admin.surchargeDocuments()),
    noShowLateHandlingRoutePricesMatch: useMatch(
      routeFactory.noShowLateHandling.routePrices()
    ),
    noShowLateHandlingHandleBookingsMatch: useMatch(
      routeFactory.noShowLateHandling.handleBookings()
    ),
    noShowLateHandlingSearchInvoicesMatch: useMatch(
      routeFactory.noShowLateHandling.invoiceSpecification(
        NslhInvoiceTab.invoiceSpecification
      )
    ),
    noShowLateHandlingSearchFollowUpMatch: useMatch(
      routeFactory.noShowLateHandling.followUpTabView("*")
    ),
  };
  return {
    tabMatches,
  };
};
