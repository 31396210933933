import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import { CheckboxWithLabel, Switch } from "@stenajs-webui/forms";
import * as React from "react";
import { LiveStockSelect } from "../../../../../common/components/data-driven-inputs/selects/live-stock-select/LiveStockSelect";
import { NumericTextInputNoScroll } from "../../../../../common/components/forms/NumericTextInputNoScroll";
import FormGroup from "../../../../../common/forms/FormGroup";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { InputFieldSetWidthLabeled } from "../../PriceCalculatorFormPanel";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorCargoGoodsDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <FormGroup title={"Cargo details"}>
      <Row>
        <InputFieldSetWidthLabeled width={80} label={"HG = Class 1 or 7"}>
          <Spacing num={0.25} />
          <Switch
            value={formData.cargoHazardousGoods}
            onValueChange={(value) =>
              onChangeFormData("cargoHazardousGoods", value)
            }
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled width={80} label={"Weight/Line"}>
          <NumericAgrVehicleWeightTextInput
            value={formData.cargoHazardousGoodsRowWeight}
            onValueChange={(value) =>
              onChangeFormData("cargoHazardousGoodsRowWeight", value)
            }
            disabled={!formData.cargoHazardousGoods}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled width={80} label={"No of rows"}>
          <NumericTextInputNoScroll
            value={formData.cargoHazardousGoodsAmountRows?.toString()}
            onValueChange={(value) =>
              onChangeFormData("cargoHazardousGoodsAmountRows", parseInt(value))
            }
            min={0}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />
        <Column>
          <Spacing num={1.8} />
          <CheckboxWithLabel
            label={"Qualified goods"}
            size={"standard"}
            value={formData.cargoHazardousGoodsQualified}
            onValueChange={(value) =>
              onChangeFormData("cargoHazardousGoodsQualified", value)
            }
            disabled={!formData.cargoHazardousGoods}
          />
        </Column>
      </Row>
      <Spacing num={1.5} />
      <Row>
        <InputFieldSetWidthLabeled width={80} label={"Animals"}>
          <Spacing num={0.25} />
          <Switch
            value={formData.livestock}
            onValueChange={(value) => onChangeFormData("livestock", value)}
          />
        </InputFieldSetWidthLabeled>
        <Indent num={1.5} />
        <InputFieldSetWidthLabeled label={"Animal type"} width={120}>
          <LiveStockSelect
            value={formData.livestock ? formData.livestockType : undefined}
            onValueChange={(value) => {
              onChangeFormData("livestockType", value);
            }}
            menuPortalTarget={document.body}
            isDisabled={!formData.livestock}
            isActive={formData.livestock}
          />
        </InputFieldSetWidthLabeled>
        <Indent num={1.5} />
      </Row>
      <Spacing />
    </FormGroup>
  );
};
