import {
  SwitchHeadAgreementQuery,
  SwitchHeadAgreementQueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import { sortBy } from "lodash";

const query = gql`
  fragment SwitchHeadAgreementAllAgreements on HeadAgreement {
    id
    name
    statusCode
    headAgreementNumber
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
  }
  query SwitchHeadAgreement($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          allHeadAgreements {
            ...SwitchHeadAgreementAllAgreements
          }
        }
      }
    }
  }
`;

export const useHeadAgreementsByCustomer = (customerId: string) => {
  const { data, error, loading } = useQuery<
    SwitchHeadAgreementQuery,
    SwitchHeadAgreementQueryVariables
  >(query, { variables: { customerId }, fetchPolicy: "network-only" });

  const headAgreements =
    data?.productPrice.customers.byId?.allHeadAgreements ?? [];
  const defaultHeadAgreement = headAgreements
    ? sortBy(headAgreements, "valid.start.isoString").reverse()[0]
    : null;

  return {
    headAgreements,
    defaultHeadAgreement,
    error,
    loading,
  };
};
