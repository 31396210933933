import { RoutePairVolume } from "@/common/components/invoice-specification/models/InvoiceSpecification";
import { OneMonthBookings } from "@/common/components/one-month-bookings/models/OneMonthBookings";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";
import { InternalOneMonthBookingsQuery } from "@/gql/graphql";

export const transformByBookingsFilterToInvoiceSpecification = (
  data: InternalOneMonthBookingsQuery["productPrice"]["noShowLateHandling"]["nslhFollowUp"]["byFilter"]
): OneMonthBookings => {
  // One object per Route pair
  const routePairVolumes: RoutePairVolume[] = data.map((nslhFollowUp) => {
    return {
      routePairCode: nslhFollowUp.routePair.code ?? null,
      shippedVolume: nslhFollowUp.shippedVolume ?? null,
    };
  });

  const firstNslhFollowUp = data[0];

  const thresholdNoShowPercentage =
    firstNslhFollowUp.customer.customerThresholds.find(
      (customerThreshold) =>
        customerThreshold.thresholdTypeCode === NoShowLateHandlingTypes.NOSHOW
    )?.percentage;
  const thresholdLateHandlingPercentage =
    firstNslhFollowUp.customer.customerThresholds.find(
      (customerThreshold) =>
        customerThreshold.thresholdTypeCode ===
        NoShowLateHandlingTypes.LATE_HANDL
    )?.percentage;

  const bookings = data.map((nslhFollowUp) => nslhFollowUp.nslhBookings).flat();

  return {
    id: firstNslhFollowUp.id,
    custName: firstNslhFollowUp.customer.name,
    custNo: firstNslhFollowUp.customer.custNo,
    custIndex: firstNslhFollowUp.customer.custIndex,
    thresholdLateHandlingPercentage: thresholdLateHandlingPercentage ?? null,
    thresholdNoShowPercentage: thresholdNoShowPercentage ?? null,
    routePairVolumes: routePairVolumes,
    nslhBookings: bookings.map((booking) => ({
      id: booking.id,
      articlePrice: booking.articlePrice,
      bookingNo: booking.bookingNo,
      consignmentNo: booking.consignmentNo,
      bookingVersion: booking.bookingVersion,
      custNo: booking.customer.custNo,
      customerReference: booking.customerReference,
      departureDate: booking.departureDate,
      handlingType: booking.handlingType,
      handlingTypeGrouped: booking.handlingTypeGrouped,
      departureTime: booking.departureTime,
      handlingStatus: booking.handlingStatus,
      revisedDate: booking.revisedDate,
      revisedTime: booking.revisedTime,
      routePairCode: booking.route.routePair.code,
      routeCode: booking.route.id,
      routePairDescription: booking.route.routePair.description,
      trailerReg: booking.trailerReg,
      vehicleType: booking.vehicleType.shortDescription,
      vehicleReg: booking.vehicleReg,
      lateHandlingUser: booking.lateHandlingUser,
      lateHandlingDate: booking.lateHandlingDate,
    })),
  };
};
