import { Indent, Row, Text } from "@stenajs-webui/core";
import * as React from "react";
import { Property } from "csstype";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  label?: string;
  value?: string | number | null;
  whiteSpace?: Property.WhiteSpace;
}

export const PageHeaderItem: React.FC<Props> = ({
  label,
  value,
  whiteSpace,
}) => {
  return (
    <Row alignItems={"center"}>
      {label && (
        <>
          <Text
            size="small"
            color={cssColor("--lhds-color-ui-500")}
            whiteSpace={whiteSpace}
          >
            {label}
          </Text>
          <Indent num={0.5} />
        </>
      )}
      <Text
        size="small"
        color={cssColor("--lhds-color-ui-700")}
        whiteSpace={whiteSpace}
      >
        {value}
      </Text>
    </Row>
  );
};
