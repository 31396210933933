import { HeadAgreementStatusCode } from "@/gql/graphql";

export const routeAgreementCanBeEdited = ({
  statusCode,
}: {
  statusCode: HeadAgreementStatusCode;
}): boolean => routeAgreementCanBeEditedByStatusCode(statusCode);

export const routeAgreementCanBeEditedByStatusCode = (
  statusCode: HeadAgreementStatusCode
): boolean => {
  if (statusCode === HeadAgreementStatusCode.Active) {
    return false;
  }
  if (statusCode === HeadAgreementStatusCode.Void) {
    return false;
  }
  return true;
};
