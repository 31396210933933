import { routeFactory } from "@/RouteFactory";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Row, Spacing, Txt } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  stenaBusinessAgreement,
  stenaPlusCircle,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  customerId: string;
}

export const NoHeadAgreementExistBox: React.FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();
  const navigateToCreateHeaderAgreement = () =>
    navigate(
      routeFactory.productAndPrice.headAgreement.createHeadAgreement({
        customerId,
      })
    );
  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Header agreement"}
        leftIcon={stenaBusinessAgreement}
      />
      <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
        <Spacing num={SpacingValues.FOUR} />
        <Row
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Txt>{"Missing header agreement"}</Txt>
          <FlatButton
            leftIcon={stenaPlusCircle}
            label={"Create header agreement"}
            onClick={navigateToCreateHeaderAgreement}
          />
        </Row>
        <Spacing num={SpacingValues.FOUR} />
      </CardBody>
    </Card>
  );
};
