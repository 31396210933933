import * as React from "react";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { Column, Spacing } from "@stenajs-webui/core";
import { CustomerHeader } from "../../../customers/common/customer-header/CustomerHeader";
import { CustomerHeaderStats } from "../../../customer-statistics/components/CustomerHeaderStats";
import { customerStatisticsFragment } from "../../../customer-statistics/fragment";
import { RouteAgreementList } from "./RouteAgreementList";
import { useNegotiationYears } from "../../../customers/common/negotiation-year/NegotiationYearGate";
import { ActivateHeadAgreementStatusConfirmationModal } from "../../activate/components/ActivateHeadAgreementStatusConfirmationModal";
import { useCustomer } from "../../../customers/common/customer-header/UseCustomer";
import { CustomerViewInfoPairBoxes } from "../../../info-boxes/CustomerViewInfoPairBoxes";
import { PageLayout } from "../../../../../common/components/page-layout/PageLayout";
import {
  HeadAgreementPanelQueryVariables,
  HeadAgreementPanelQuery,
  CustomerHeaderStatsQuery,
  CustomerHeaderStatsQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
}

const query = gql`
  fragment HeadAgreementPanelCustomers on Customer {
    id
    custIndex
    custNo
    name
    isParent
    childCustomers {
      id
    }
    statisticsByFilter(year: $year) {
      items {
        ...CustomerStatistics
      }
    }
  }
  query HeadAgreementPanel(
    $customerId: ID!
    $headAgreementId: ID!
    $year: Int!
  ) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...HeadAgreementPanelCustomers
        }
      }
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          statusCode
          name
          headAgreementNumber
          breadCrumbName
          hasSomeRouteAgreementsWithMultiLeg
          statusCode
          rowVersion
          customer {
            custNo
          }
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
          writeHistory {
            createdAt {
              isoString
            }
            createdBy {
              id
              name
            }
            modifiedAt {
              isoString
            }
            modifiedBy {
              id
              name
            }
          }
        }
      }
    }
  }
  ${customerStatisticsFragment}
`;

const customerHeaderStatsQuery = gql`
  query CustomerHeaderStats($customerId: ID!, $year: Int!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          statisticsByFilter(year: $year) {
            items {
              ...CustomerStatistics
            }
          }
        }
      }
    }
  }
  ${customerStatisticsFragment}
`;

export const HeadAgreementPanel: React.FC<Props> = ({
  headAgreementId,
  customerId,
}) => {
  const { defaultYear: statisticalYear } = useNegotiationYears();

  const variables = useMemo<HeadAgreementPanelQueryVariables>(
    () => ({
      customerId,
      headAgreementId,
      year: statisticalYear,
    }),
    [customerId, headAgreementId, statisticalYear]
  );

  const { loading, error, data } = useQuery<
    HeadAgreementPanelQuery,
    HeadAgreementPanelQueryVariables
  >(query, {
    variables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const customer = data?.productPrice.customers.byId;
  const headAgreement = data?.productPrice.headAgreement.byId;
  const headAgreementValidStartYear = headAgreement?.valid.start?.isoString;

  const statsVariables: CustomerHeaderStatsQueryVariables = {
    customerId,
    year: headAgreementValidStartYear
      ? new Date(headAgreementValidStartYear).getUTCFullYear()
      : statisticalYear,
  };
  const {
    loading: statsLoading,
    error: statsError,
    data: statsData,
  } = useQuery<CustomerHeaderStatsQuery, CustomerHeaderStatsQueryVariables>(
    customerHeaderStatsQuery,
    {
      variables: statsVariables,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const customerHeaderStatsData =
    statsData?.productPrice.customers.byId?.statisticsByFilter.items ?? [];

  const routeAgreementYear = useMemo(() => {
    if (headAgreementValidStartYear) {
      return new Date(headAgreementValidStartYear).getUTCFullYear();
    } else {
      return statisticalYear;
    }
  }, [statisticalYear, headAgreementValidStartYear]);

  const { customer: customerInfoBox } = useCustomer(
    customerId,
    routeAgreementYear
  );

  if (loading || statsLoading) {
    return (
      <Spacing num={8}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (statsError) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={statsError.message} />
      </Spacing>
    );
  }

  if (!customer) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={"No such customer."} />
      </Spacing>
    );
  }

  if (!headAgreement) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={"No such header agreement."} />
      </Spacing>
    );
  }

  if (customer.custNo !== headAgreement.customer.custNo) {
    return (
      <Spacing num={8}>
        <ErrorScreen
          text={"Header agreement does not belong to current customer"}
        />
      </Spacing>
    );
  }

  return (
    <Column>
      <CustomerHeader
        customerId={customerId}
        negotiationYear={routeAgreementYear}
        headAgreement={headAgreement}
        headAgreementBreadCrumbName={headAgreement.breadCrumbName}
        loading={loading}
      />
      <PageLayout>
        {customerInfoBox && (
          <CustomerViewInfoPairBoxes
            customer={customerInfoBox}
            headAgreement={headAgreement}
            headAgreementId={headAgreementId}
            justifyContentRow={"flex-start"}
            showDocumentsButton
            customerPopoverEnabled
          />
        )}
        <CustomerHeaderStats
          data={customerHeaderStatsData}
          customerId={customerId}
        />

        <RouteAgreementList
          customerId={customerId}
          headAgreementId={headAgreementId}
          year={routeAgreementYear}
        />
        <ActivateHeadAgreementStatusConfirmationModal
          customer={customer}
          negotiationYear={routeAgreementYear}
        />
      </PageLayout>
    </Column>
  );
};
