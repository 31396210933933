import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import * as React from "react";
import { useMemo } from "react";
import { Spinner } from "@stenajs-webui/elements";
import { Box, Row } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { LoadingPreferencesRadioQuery } from "@/gql/graphql";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}
const query = gql`
  query LoadingPreferencesRadio {
    productPrice {
      loadingPreference {
        all {
          id
          code
          description
          presentationOrder
        }
      }
    }
  }
`;

export const PriceCalculatorPreferenceRadioGroup: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const { data, loading } = useQuery<LoadingPreferencesRadioQuery>(query);

  const loadingPreferences = useMemo(() => {
    return data?.productPrice.loadingPreference.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    const loadingPreferencesArrayForSort = [...loadingPreferences];
    return loadingPreferencesArrayForSort
      .sort((l) => l.presentationOrder)
      .map((r) => ({
        label: `${r.description}`,
        value: r.id,
        data: r,
      }));
  }, [loadingPreferences]);

  const selected = useMemo(
    () => options.filter((o) => o.value === formData.loadingPreference),
    [options, formData.loadingPreference]
  );
  if (loading)
    return (
      <Box height={"120px"}>
        <Spinner size={"medium"} />
      </Box>
    );
  return (
    <Row flexWrap={"wrap"}>
      {options?.map((option, index) => (
        <Box flex={"30%"} height={"40px"} key={index}>
          <RadioButtonWithLabel
            label={option.label}
            value={option.value}
            checked={selected && selected[0] === option}
            onClick={() => {
              if (formData.loadingPreference === option.value) {
                onChangeFormData("loadingPreference", "");
              }
            }}
            onValueChange={() =>
              onChangeFormData("loadingPreference", option.value)
            }
          />
        </Box>
      ))}
    </Row>
  );
};
