import { gql, useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import { batch, useDispatch } from "react-redux";
import { bulkEditRatesStatBoxesActions } from "../components/stat-boxes/redux/reducer";
import { calculateCustomerStatisticsBulkEditRatesTable } from "../components/stat-boxes/transformers/BulkEditRatesStatBoxDataTransformer";
import { mergeBulkEditRatesStatBoxQueryData } from "../components/stat-boxes/transformers/BulkEditRatesStatBoxQueryDataMerger";
import { bulkEditRatesActions } from "../redux/reducer";
import { transformRatesByFilterQueryToBulkEditRatesPriceRow } from "../transformers/BulkEditRatesTransformers";
import {
  RouteAgreementPricesByFilterInput,
  RouteAgreementsRatesByFilterQuery,
  RouteAgreementsRatesByFilterQueryVariables,
  RoutePairFilterQuery,
} from "@/gql/graphql";
import { routePairFilterQuery } from "@/features/search-filter/hooks/FilterFetchers/UseFetchAllRoutePairs";

const routeAgreementsRatesByFilterQuery = gql`
  fragment RouteAgreementForRouteAgreementPrice on RouteAgreement {
    id
    agreementNumber
    routes {
      id
      routePair {
        id
        code
        description
      }
    }
    name
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    currency {
      id
      code
    }
    prices {
      avgRebate {
        amount
      }
    }
    isSalesReady
    rowVersion
    sailingTypeCodes
    statusCode
    routeDescription
  }

  fragment VehicleTypeForAgrRoutePrices on VehicleType {
    id
    code
    name
    shortDescription
    accompanied
  }
  fragment ItemByRoutePairCodesForCustomers on CustomerStatisticsEntry {
    id
    expectedVolume
    statVolume12Months
    unitCategoryCode
    expectedStatVolume
    routePairCode
    volume12Months
    actualOutcomeInPercent
    targetInPercent
    sailingCategoryCode
  }

  fragment AgreementRoutePrices on RouteAgreementPrice {
    id
    lengthFrom
    lengthTo
    weightFrom
    weightTo
    cargoStatusCode
    unaccompanied
    vehicleType {
      ...VehicleTypeForAgrRoutePrices
    }
    avgGrossPrice {
      amount
    }
    avgLength
    lastPrice {
      amount
    }
    meterPrice {
      amount
    }
    lastChargedFrom
    newPrice {
      amount
    }
    meterPrice {
      amount
    }
    lastMeterPrice {
      amount
    }
    avgRebate {
      amount
    }
    volume
    additionalFrom
    actual
    routeAgreement {
      ...RouteAgreementForRouteAgreementPrice
    }
  }
  query RouteAgreementsRatesByFilter(
    $byFilterInput: RouteAgreementPricesByFilterInput!
    $routePairCodes: [String!]
    $year: Int!
    $customerId: ID!
  ) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          statisticsByRoutePairCodes(
            year: $year
            routePairCodes: $routePairCodes
          ) {
            itemsByRoutePairCodes {
              ...ItemByRoutePairCodesForCustomers
            }
          }
        }
      }
      routeAgreementPrice {
        byFilter(input: $byFilterInput) {
          numberOfAgrRouteRates
          agrRoutePrices {
            ...AgreementRoutePrices
          }
        }
      }
    }
  }
`;

function getArrayOrFallbackIfEmpty<T>(
  a: T[] | null | undefined,
  fallback: T[]
) {
  if (a && a.length > 0) {
    return a;
  } else {
    return fallback;
  }
}

export const useRouteAgreementsRatesByFilterQuery = (
  byFilterInput: RouteAgreementPricesByFilterInput | null,
  customerId: string,
  year: number
) => {
  const useRoutePairsInFilter = () => {
    return useQuery<RoutePairFilterQuery>(routePairFilterQuery, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  };
  const { data: routePairs } = useRoutePairsInFilter();

  const variables = {
    routePairCodes: getArrayOrFallbackIfEmpty(
      byFilterInput?.routePairCodes,
      routePairs?.productPrice.routePair.allWithStatusOwn.map(
        (rp) => rp.code
      ) ?? []
    ),
    customerId,
    year,
  };

  const dispatch = useDispatch();
  const { data, loading, error, refetch } = useQuery<
    RouteAgreementsRatesByFilterQuery,
    RouteAgreementsRatesByFilterQueryVariables
  >(routeAgreementsRatesByFilterQuery, {
    variables: {
      byFilterInput: byFilterInput!,
      ...variables,
    },
    skip: !byFilterInput || !routePairs,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
    onCompleted(data) {
      const bulkEditRatesPrices =
        data.productPrice.routeAgreementPrice.byFilter.agrRoutePrices;

      const customerStatItemsByRoutePairCodes =
        data.productPrice.customers.byId?.statisticsByRoutePairCodes
          .itemsByRoutePairCodes;

      if (bulkEditRatesPrices) {
        const bulkEditRatesPricesTableRow = bulkEditRatesPrices.map(
          transformRatesByFilterQueryToBulkEditRatesPriceRow
        );

        batch(() => {
          dispatch(bulkEditRatesActions.clear());
          dispatch(bulkEditRatesActions.setState(bulkEditRatesPricesTableRow));
          dispatch(bulkEditRatesActions.setSaveChangesLoading(false));
        });
      }

      const headAgreementId = byFilterInput?.agreementId;
      if (customerStatItemsByRoutePairCodes && headAgreementId) {
        const groupByRoutePairCodes = groupBy(
          customerStatItemsByRoutePairCodes,
          (i) => i.routePairCode
        );

        const groupedList = Object.keys(groupByRoutePairCodes).map(
          (routePairCode) =>
            calculateCustomerStatisticsBulkEditRatesTable(
              groupByRoutePairCodes[routePairCode],
              customerId,
              routePairCode
            )
        );

        dispatch(bulkEditRatesStatBoxesActions.setQueryParams(variables));

        const queryData = mergeBulkEditRatesStatBoxQueryData(
          {},
          customerStatItemsByRoutePairCodes
        );
        dispatch(bulkEditRatesStatBoxesActions.setQueryData(queryData));

        dispatch(
          bulkEditRatesStatBoxesActions.setHeadAgreementId(headAgreementId)
        );
        dispatch(bulkEditRatesStatBoxesActions.setStatBoxesData(groupedList));
      }
    },
  });
  const routeAgreementRates = data;

  return {
    routeAgreementRates,
    refetch,
    loading,
    error,
  };
};
