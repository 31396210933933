import * as React from "react";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../../RouteFactory";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  BreadCrumbs,
  FlatButton,
  stenaStatisticsBar,
} from "@stenajs-webui/elements";
import { Space } from "@stenajs-webui/core";
import { NegotiationYearSelect } from "@/common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { useNavigate } from "react-router-dom";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { CurrencySelect } from "@/common/components/data-driven-inputs/selects/currency-select/CurrencySelect";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { useDispatch } from "react-redux";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { useContext } from "react";
import { SearchFilterActionsContext } from "@stenajs-webui/filter";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import { transformStatusItemsToFilterStatus } from "@/features/statistics/common/utils/statisticsUtil";
import {
  StatisticsBySalesRegionDetailsHeaderQuery,
  StatisticsBySalesRegionDetailsHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  salesRegionId: string;
  currency: string;
  handleCurrencyChange: (currency: string) => void;
}

const query = gql`
  query StatisticsBySalesRegionDetailsHeader($salesRegionId: ID!) {
    productPrice {
      salesRegion {
        byId(id: $salesRegionId) {
          id
          code
          name
        }
      }
    }
  }
`;

export const StatisticsBySalesRegionDetailsHeader: React.FC<Props> = ({
  salesRegionId,
  currency,
  handleCurrencyChange,
}) => {
  const { data, loading } = useQuery<
    StatisticsBySalesRegionDetailsHeaderQuery,
    StatisticsBySalesRegionDetailsHeaderQueryVariables
  >(query, { variables: { salesRegionId } });

  const { salesRegionSelectedStatuses } = React.useContext(
    StatisticsContext
  ) as StatisticsContextType;

  const salesRegion = data?.productPrice.salesRegion.byId;
  const { year, handleYearChange } = useStatisticsYearSelect();
  const onSelectCurrencyChange = (currency: string) => {
    handleCurrencyChange(currency);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchFilterActions = useContext(SearchFilterActionsContext);
  const filterWorkspace = useFilterWorkspace();

  const updateAndSetCustomerTabState = () => {
    if (salesRegion) {
      dispatch(searchFilterActions.clearFormModel());
      dispatch(
        searchFilterActions.setFormModelFields({
          negotiationStatus: transformStatusItemsToFilterStatus(
            salesRegionSelectedStatuses
          ),
          salesRegions: [
            {
              code: salesRegion.code,
              id: salesRegion.id,
              name: salesRegion.name,
            },
          ],
        })
      );
      filterWorkspace.commit();
    }
  };
  return (
    <>
      <PageHeader
        title={salesRegion?.name ?? ""}
        zIndex={ZIndex.low}
        icon={stenaStatisticsBar}
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Sales region"}
              path={routeFactory.statistics.salesRegionTab()}
            />
            <Crumb
              label={salesRegion?.name ?? ""}
              isLast
              path={
                salesRegion
                  ? routeFactory.statistics.salesRegionDetails(salesRegion.id)
                  : ""
              }
            />
          </BreadCrumbs>
        }
        contentRight={
          <>
            <FlatButton
              disabled={loading}
              label="Show customer list "
              onClick={() => {
                updateAndSetCustomerTabState();
                navigate(routeFactory.statistics.customerTab());
              }}
            />
            <Space num={SpaceValues.SIXTEEN} />
            <NegotiationYearSelect
              value={year}
              onValueChange={(value) => handleYearChange(value)}
            />
            <Space num={SpaceValues.SIXTEEN} />
            <CurrencySelect
              value={currency}
              onValueChange={onSelectCurrencyChange}
              containerWidth={"80px"}
            />
          </>
        }
      />
    </>
  );
};
