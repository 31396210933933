import { StoreState } from "../../../../../../config/redux/RootReducer";
import {
  RouteCustomerGeneralState,
  RouteCustomerTargetState,
} from "./reducers";
import { StandardTableState } from "@stenajs-webui/grid";
import { AllCustomerTableColumnKeys } from "../config/RowsAndColumnsConfig";
import { routePairTargetStandardTableInitialState } from "../../../redux";

export const routeCustomerTargetSelectors = {
  getState: (state: StoreState): RouteCustomerTargetState =>
    state.routeTarget.list,
  getTableState: (
    state: StoreState
  ): StandardTableState<AllCustomerTableColumnKeys> =>
    state.routeTarget.standardTable ?? routePairTargetStandardTableInitialState,
  getSelectedRowIds: (state: StoreState) =>
    state.routeTarget.standardTable.selectedIds.selectedIds,
  hasSelectedRows: (state: StoreState): boolean =>
    state.routeTarget.standardTable.selectedIds.selectedIds.length > 0,
  getRouteCustomerSortLoadState: (
    state: StoreState
  ): RouteCustomerGeneralState =>
    state.routeTarget.list.routeCustomerSortLoadState,
  getDiscardCountClickedState: (state: StoreState) =>
    state.routeTarget.list.discardClickedState,
  getListOrder: (state: StoreState) => state.customerTargetState.listOrder,
  getPersistedCustomerTargetTableRows: (state: StoreState) =>
    state.customerTargetState.persisted,
  getEditedCustomerTargetTableRows: (state: StoreState) =>
    state.customerTargetState.edited,
};
