import { StandardTable } from "@stenajs-webui/grid";
import { StoreState } from "../../../../../../../config/redux/RootReducer";
import { uniq } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { createBulkEditRatesRouteImpactTableConfig } from "../config/BulkEditRatesRouteImpactTableConfig";
import { BulkEditRatesStatTableData } from "../models/BulkEditRatesStatBoxData";
import { RouteAgreementsRatesByFilterQuery } from "@/gql/graphql";

function getAccompainedUnaccompaniedFromRoutePriceVariant<
  T extends { accompanied: boolean }
>(variants: T[]): { accompanied: T; unaccompanied: T } {
  return {
    accompanied: variants.find((v) => v.accompanied)!,
    unaccompanied: variants.find((v) => !v.accompanied)!,
  };
}

const config = createBulkEditRatesRouteImpactTableConfig();
interface Props {
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  loading: boolean;
}

export const BulkEditRatesRouteImpactTable: React.FC<Props> = React.memo(
  ({ loading, routeAgreementRates }) => {
    const selector = (state: StoreState) => state.bulkEditRatesStatBoxes;
    const { statBoxesData } = useSelector(selector);

    const routePairCodesInRatesTable = uniq(
      routeAgreementRates?.productPrice.routeAgreementPrice.byFilter.agrRoutePrices?.flatMap(
        (rap) => rap.routeAgreement.routes.map((route) => route.routePair.code)
      )
    );

    const tableItems = statBoxesData
      ?.filter((sbd) => routePairCodesInRatesTable.includes(sbd.routePairCode))
      .map<BulkEditRatesStatTableData>((sbd) => {
        return {
          routePairCode: sbd.routePairCode,
          offPeakData: getAccompainedUnaccompaniedFromRoutePriceVariant(
            sbd.offPeakData
          ),
          peakData: getAccompainedUnaccompaniedFromRoutePriceVariant(
            sbd.peakData
          ),
          shoulderData: getAccompainedUnaccompaniedFromRoutePriceVariant(
            sbd.shoulderData
          ),
        };
      });

    return (
      <StandardTable config={config} items={tableItems} loading={loading} />
    );
  }
);
