import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createInternalStandardTableActions,
  createStandardTableActions,
  createStandardTableInitialState,
  createStandardTableReducer,
  StandardTableState,
} from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { AgreementArticlesTableColumns } from "../components/table/config/RowsAndColumnsConfig";
import {
  AgreementArticlesRouteAgreements,
  AgreementArticlesTableItem,
} from "../types";
import { CreateAgreementArticlePriceItemInput } from "@/gql/graphql";

export const agreementArticlesReducerId = "agreementArticles";
export const standardTableInitialState =
  createStandardTableInitialState<AgreementArticlesTableColumns>(
    "distanceTypeDescription"
  );

export const agreementArticlesStandardTableActions = createStandardTableActions(
  agreementArticlesReducerId,
  createInternalStandardTableActions<AgreementArticlesTableColumns>()
);

const agreementArticlesStandardTableReducer =
  createStandardTableReducer<AgreementArticlesTableColumns>(
    agreementArticlesReducerId
  );

export interface AgreementArticlesState {
  tableItems: Array<EditableEntityState<AgreementArticlesTableItem>>;
  standardTableState: StandardTableState<AgreementArticlesTableColumns>;
  routeAgreements: AgreementArticlesRouteAgreements;
  agreementArticlesLoading: {
    tableLoading: boolean;
    bulkUpdateLoading: boolean;
    saveChangesLoading: boolean;
  };
  saveErrors?: Array<Error>;
  tableRowErrors: Array<CreateAgreementArticlePriceItemInput>;
}

const initialState: AgreementArticlesState = {
  tableItems: [],
  standardTableState: standardTableInitialState,
  routeAgreements: [],
  agreementArticlesLoading: {
    tableLoading: true,
    bulkUpdateLoading: false,
    saveChangesLoading: false,
  },
  saveErrors: [],
  tableRowErrors: [],
};

const agreementArticlesSlice = createSlice({
  name: agreementArticlesReducerId,
  initialState,
  reducers: {
    revertAll(state) {
      state.tableItems.forEach((item) => {
        item.editable.newPrice = item.persisted.newPrice;
        item.editable.newValidFrom = item.persisted.newValidFrom;
      });
    },
    setValue(
      state,
      action: PayloadAction<{
        id: string;
        fields: Partial<AgreementArticlesTableItem>;
      }>
    ) {
      const itemEdited = getStateItem(state, action.payload.id);
      if (itemEdited != null) {
        Object.assign(itemEdited.editable, action.payload.fields);
      }
    },
    setState(
      state,
      action: PayloadAction<
        Array<EditableEntityState<AgreementArticlesTableItem>>
      >
    ) {
      state.tableItems = action.payload;
    },
    populateTableWithUpdatedData(
      state,
      action: PayloadAction<
        Array<EditableEntityState<AgreementArticlesTableItem>>
      >
    ) {
      action.payload.forEach((tableItem) => {
        const itemEdited = getStateItem(state, tableItem.id ?? "");
        if (itemEdited) {
          Object.assign(itemEdited.editable, tableItem.editable);
          Object.assign(itemEdited.persisted, tableItem.persisted);
        }
      });
    },
    setRouteAgreements(
      state,
      action: PayloadAction<AgreementArticlesRouteAgreements>
    ) {
      state.routeAgreements = action.payload;
    },
    setTableLoading(state, action: PayloadAction<boolean>) {
      state.agreementArticlesLoading.tableLoading = action.payload;
    },
    setBulkUpdateLoading(state, action: PayloadAction<boolean>) {
      state.agreementArticlesLoading.bulkUpdateLoading = action.payload;
    },
    setSaveChangesLoading(state, { payload: loading }: PayloadAction<boolean>) {
      state.agreementArticlesLoading.saveChangesLoading = loading;
    },
    clearSaveErrors(state) {
      state.saveErrors = undefined;
    },
    setSaveError(state, { payload: error }: PayloadAction<Error | undefined>) {
      state.saveErrors = error ? [error] : undefined;
    },
    setSaveErrors(state, { payload: errors }: PayloadAction<Array<Error>>) {
      state.saveErrors = errors;
    },
    setTableRowErrors(
      state,
      {
        payload: tableRowErrors,
      }: PayloadAction<Array<CreateAgreementArticlePriceItemInput>>
    ) {
      state.tableRowErrors = tableRowErrors;
    },
    clearAllTableRowErrors(state) {
      state.tableRowErrors = [];
    },
    clearTableRowError(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) {
      const tableRows = state.tableRowErrors.filter(
        (tableRow) => tableRow.agrRouteArticlePriceId !== action.payload.id
      );
      state.tableRowErrors = tableRows;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action: any) => {
      state.standardTableState = agreementArticlesStandardTableReducer(
        state.standardTableState,
        action
      );
    });
  },
});

export const {
  reducer: agreementArticlesReducer,
  actions: agreementArticlesActions,
} = agreementArticlesSlice;

function getStateItem(state: AgreementArticlesState, id: string) {
  return state.tableItems.find((item) => item.id === id);
}
