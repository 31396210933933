import { useWindowSize } from "@/common/hooks/UseWindowSize";

export interface DynamicSizes {
  formFetcherCurrentSize: number;
  resultPaneCurrentSize: number;
}

export const useDynamicSize = (): DynamicSizes => {
  const { width } = useWindowSize();

  const getSizeForActionBar = (): number => {
    if (width > 1880) return 950;
    if (width > 1780) return 900;
    if (width > 1680) return 800;
    if (width >= 1580) return 750;

    if (width <= 1420) return 600;
    if (width <= 1580) return 650;

    return 600;
  };

  const getSizeForResultPane = (): number => {
    if (width > 1880) return 900;
    if (width > 1780) return 850;
    if (width > 1680) return 800;
    if (width >= 1580) return 775;

    if (width <= 1330) return 634;
    if (width <= 1380) return 675;
    if (width <= 1480) return 700;
    if (width <= 1580) return 725;

    return 625;
  };

  return {
    formFetcherCurrentSize: getSizeForActionBar(),
    resultPaneCurrentSize: getSizeForResultPane(),
  };
};
