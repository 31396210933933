import { RouteAgreementDetailsStateFragment } from "@/gql/graphql";
import { UseResultListBannerStateResult } from "@stenajs-webui/elements";
import * as React from "react";
import { Helmet } from "react-helmet";
import { CustomerRouteStatisticsBoxes } from "../../../../../../customer-route-statistic-boxes/components/CustomerRouteStatisticsBoxes";
import { RateSheetTable } from "../../../../../rate-sheet/components/RateSheetTable";

interface Props {
  routeAgreementId: string;
  headAgreementId: string;
  routePairId: string;
  currencyLabel: string | undefined;
  customerId: string;
  statBoxesYear: number;
  routeAgreement: RouteAgreementDetailsStateFragment;
  bannerHook: UseResultListBannerStateResult;
}

export const RatesPanel: React.FC<Props> = ({
  customerId,
  statBoxesYear,
  headAgreementId,
  ...props
}) => {
  return (
    <>
      <Helmet title="Rates - Route agreement - FPP" />
      <CustomerRouteStatisticsBoxes />
      <RateSheetTable {...props} bulkUpdateModeEnabled={false} />
    </>
  );
};
