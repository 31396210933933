import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";

import { errorFragment } from "../../../../common/error/GqlErrorFragment";
import {
  ChangeMultiLegAgreementWorkFlowStatusMutation,
  ChangeMultiLegAgreementWorkFlowStatusMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation ChangeMultiLegAgreementWorkFlowStatus(
    $changeMultiLegRouteAgreementWorkFlowStatusInput: ChangeMultiLegRouteAgreementWorkFlowStatusInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        changeMultiLegRouteAgreementSalesReadyStatus(
          input: $changeMultiLegRouteAgreementWorkFlowStatusInput
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateMultiLegRouteAgreementSuccessResult {
            multiLegAgreement {
              id
              workFlowStatusCode
              rowVersion
            }
          }
        }
      }
    }
  }
  ${errorFragment}
`;

export const useChangeMultiLegAgreementWorkFlowStatusMutation = () =>
  useMutation<
    ChangeMultiLegAgreementWorkFlowStatusMutation,
    ChangeMultiLegAgreementWorkFlowStatusMutationVariables
  >(query);
