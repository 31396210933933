import { batch } from "react-redux";
import { AppThunk } from "../../../../../../../config/redux/RootReducer";
import { adminTargetActions } from "../actions";
import { adminTargetQuery } from "./gql";
import { apolloClient } from "../../../../../../../apollo-client/ApolloClient";
import { AdminTargetQuery, AdminTargetQueryVariables } from "@/gql/graphql";

export const fetchAllRoutesAdminTarget =
  (year: number): AppThunk =>
  async (dispatch) => {
    dispatch(adminTargetActions.setProgress({ loading: true }));

    try {
      const { data: adminTargetData, errors } = await apolloClient.query<
        AdminTargetQuery,
        AdminTargetQueryVariables
      >({
        variables: {
          byYearYear: year,
        },
        fetchPolicy: "network-only",
        query: adminTargetQuery,
      });

      if (errors && errors.length) {
        dispatch(
          adminTargetActions.setProgress({
            bannerState: {
              headerText: "Could not fetch all routes data",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      } else {
        const allRoutesData = adminTargetData.productPrice.targetAdmin.byYear;
        if (allRoutesData) {
          batch(() => {
            const editedData = allRoutesData.map((data) => {
              return {
                id: data.id,
                editable: data,
                persisted: data,
              };
            });
            dispatch(
              adminTargetActions.setRowsAdminTargetAllRoutes(editedData)
            );
          });
        }
      }
    } catch (error) {
      dispatch(
        adminTargetActions.setProgress({
          bannerState: {
            headerText: "Could not fetch all routes data",
            items: [{ text: error.message }],
          },
        })
      );
    } finally {
      dispatch(adminTargetActions.setProgress({ loading: false }));
    }
  };
