import * as React from "react";
import { ReactNode } from "react";
import { Box, Indent, Row, Text } from "@stenajs-webui/core";
import { formatPercent } from "@/common/numbers";
import { cssColor } from "@stenajs-webui/theme";

export interface BarStatsData {
  volume12Months: number;
  color: string;
  statVolume12Months: number;
  expected: number;
  id: string;
}

interface Props {
  label: ReactNode;
  data: BarStatsData[];
  valueKey: keyof Pick<
    BarStatsData,
    "expected" | "statVolume12Months" | "volume12Months"
  >;
  width: number;
  totalSum: number;
}

export const BarStat: React.FC<Props> = ({
  label,
  valueKey,
  data,
  totalSum,
  width,
  children,
}) => {
  const nonEmptyBars = data
    .map((g) => ({
      id: g.id,
      color: g.color,
      value: g[valueKey] / totalSum,
    }))
    .filter((data) => data.value > 0.005);

  return (
    <Row alignItems={"center"} indent={1}>
      <Row width={"120px"}>{label}</Row>
      <Indent />
      <Row>
        {nonEmptyBars.length === 0 && (
          <Box width={width}>
            <Text>No data</Text>
          </Box>
        )}
        {nonEmptyBars.length > 0 && (
          <Box
            width={width}
            display="flex"
            flexDirection="row"
            style={{ marginTop: 0.75 }}
          >
            {nonEmptyBars.map((g, i) => (
              <div
                key={g.id}
                style={{
                  position: "relative",
                  backgroundColor: g.color,
                  borderTopRightRadius:
                    i === nonEmptyBars.length - 1 ? "5px" : "0px",
                  borderBottomRightRadius:
                    i === nonEmptyBars.length - 1 ? "5px" : "0px",
                  borderTopLeftRadius: i === 0 ? "5px" : "0px",
                  borderBottomLeftRadius: i === 0 ? "5px" : "0px",
                  height: 8,
                  marginLeft: i !== 0 ? -5 : 0,
                  paddingLeft: i !== 0 ? 5 : 0,
                  width: g.value * width,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: -20,
                    right: i !== 0 ? 0 : "auto",
                  }}
                >
                  <Text
                    color={cssColor("--lhds-color-ui-500")}
                    size={"small"}
                    variant={"bold"}
                  >
                    {formatPercent(g.value, 0)}
                  </Text>
                </div>
              </div>
            ))}
          </Box>
        )}
      </Row>
      {children}
    </Row>
  );
};
