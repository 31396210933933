import {
  UpdateExpectedVolumesInput,
  UpdateExpectedVolumesItemChangesInput,
  UpdateExpectedVolumesItemInput,
} from "@/gql/graphql";
import {
  ExpectedVolumesChanges,
  ModifiedVolumeForChange,
} from "./ChangesCollector";
import _ from "lodash";

export const removeNullValues = (object: Object) =>
  Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null));

export const addDefinedVolumeChangeValues = (
  incomingChanges: ModifiedVolumeForChange
) => {
  const updateObject = { customerId: incomingChanges.customerNo };
  for (const [key, value] of Object.entries(
    removeNullValues(_.omit(incomingChanges, "customerNo"))
  )) {
    _.set(updateObject, key, { value });
  }
  return updateObject;
};

export const transformChangesToInput = (
  negotiationYear: number,
  customerStatisticalGrouping: string,
  changes: Array<ExpectedVolumesChanges>
): UpdateExpectedVolumesInput => ({
  negotiationYear,
  customerStatisticalGrouping: Number(customerStatisticalGrouping),
  changesPerRoute: changes.map<UpdateExpectedVolumesItemInput>((c) => ({
    routePairId: c.routePairId,
    changesForCustomer:
      c.modifiedVolumes.map<UpdateExpectedVolumesItemChangesInput>((v) =>
        addDefinedVolumeChangeValues(v)
      ),
  })),
});
