import { Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { CountrySelect } from "../../../../../common/components/data-driven-inputs/selects/country-select/CountrySelect";
import FormGroup from "../../../../../common/forms/FormGroup";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { InputFieldSetWidthLabeled } from "../../PriceCalculatorFormPanel";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorConditions: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <FormGroup title={"Conditions"}>
      <Column>
        <Row>
          <CheckboxWithLabel
            label={"Standby"}
            value={formData.conditionsStandby}
            onValueChange={(value) =>
              onChangeFormData("conditionsStandby", value)
            }
          />
          <Indent num={1.5} />
          <CheckboxWithLabel
            label={"Transit"}
            value={formData.conditionsTransit}
            onValueChange={(value) =>
              onChangeFormData("conditionsTransit", value)
            }
          />
        </Row>
      </Column>
      <Spacing num={1.5} />
      <Row>
        <InputFieldSetWidthLabeled label={"Country sender"} width={200}>
          <CountrySelect
            value={formData.conditionsSenderCountryCode ?? ""}
            onValueChange={(value) =>
              onChangeFormData("conditionsSenderCountryCode", value)
            }
            menuPortalTarget={document.body}
          />
        </InputFieldSetWidthLabeled>
        <Indent num={1.5} />
        <InputFieldSetWidthLabeled label={"Country receiver"} width={200}>
          <CountrySelect
            value={formData.conditionsReceiverCountryCode ?? ""}
            onValueChange={(value) =>
              onChangeFormData("conditionsReceiverCountryCode", value)
            }
            menuPortalTarget={document.body}
          />
        </InputFieldSetWidthLabeled>
      </Row>
      <Spacing num={6} />
    </FormGroup>
  );
};
