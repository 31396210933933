import { gql, useQuery } from "@apollo/client";
import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { filter } from "lodash";
import { VehicleTypeSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string | undefined;
  onValueChange?: (value: string | undefined) => void;
  allTypesValue?: string;
  allTypesLabel?: string;
}

const query = gql`
  query VehicleTypeSelect {
    productPrice {
      vehicleType {
        all {
          id
          shortDescription
          code
          accompanied
        }
      }
    }
  }
`;

export const VehicleTypeSelect: React.FC<Props> = ({
  value,
  onValueChange,
  allTypesLabel,
  allTypesValue,
  ...selectProps
}) => {
  const { data, loading } = useQuery<VehicleTypeSelectQuery>(query);
  const all = data?.productPrice.vehicleType.all ?? [];

  const allAccompanied = filter(all, ["accompanied", true]);
  const allUnaccompanied = filter(all, ["accompanied", false]);

  const optionsAcc = useMemo(() => {
    return allAccompanied.map((s) => ({
      label: s.shortDescription,
      value: s.id,
    }));
  }, [allAccompanied]);

  const optionsUnacc = useMemo(() => {
    return allUnaccompanied.map((s) => ({
      label: s.shortDescription,
      value: s.id,
    }));
  }, [allUnaccompanied]);

  const optionsAll = useMemo(() => {
    return [
      {
        value: allTypesValue ?? "",
        label: allTypesLabel ?? "",
      },
    ];
  }, [allTypesValue, allTypesLabel]);

  const selected = useMemo(() => {
    return optionsAcc
      .concat(optionsUnacc, optionsAll)
      .find((o) => o.value === value);
  }, [optionsAcc, optionsUnacc, value, optionsAll]);

  return (
    <Select
      isLoading={loading}
      options={[
        {
          options: optionsAll,
        },
        {
          label: "Accompanied",
          options: optionsAcc,
        },
        {
          label: "Unaccompanied",
          options: optionsUnacc,
        },
      ]}
      value={selected || null}
      placeholder={"Vehicle type"}
      onChange={(v: any) => {
        if (!v) {
          onValueChange?.("");
        } else if (onValueChange && v) {
          onValueChange?.(v.value);
        }
      }}
      {...selectProps}
    />
  );
};
