import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Row, useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  stenaClear,
  stenaStatisticsBar,
  stenaStatusInProgress,
} from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { Window } from "@stenajs-webui/modal";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerListTableColumnKeys } from "../config/CustomerListTableConfig";
import { ExpectedVolumeModalContent } from "./ExpectedVolumeModalContent";
import { NegotiationStatusModalContent } from "./NegotiationStatusModalContent";
import { ApolloQueryResult } from "@apollo/client";
import { CustomerListQueryVariables, CustomerListQuery } from "@/gql/graphql";

interface Props {
  tableContext: TableContext<CustomerListTableColumnKeys>;
  customerNumbers: Array<string>;
  negotiationYear: number;
  refetch: (
    variables?: Partial<CustomerListQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<CustomerListQuery>>;
}

export const BulkUpdateCustomersToolbar: React.FC<Props> = ({
  tableContext,
  customerNumbers,
  negotiationYear,
  refetch,
}) => {
  const dispatch = useDispatch();
  const [modalIsOpen, modalOpen, modalClose] = useBoolean(false);
  const [modalContent, setModalContent] = useState<
    "negStatus" | "expectedVolume" | undefined
  >(undefined);

  const setModal = (
    modalContent: "negStatus" | "expectedVolume" | undefined
  ) => {
    setModalContent(modalContent);
    modalOpen();
  };

  const clearAll = () => {
    dispatch(tableContext.actions.clearSelection());
    setModalContent(undefined);
  };

  const closeModal = () => {
    modalClose();
    setModalContent(undefined);
  };

  return (
    <>
      <Box position={"sticky"} zIndex={ZIndex.lowest} top={56}>
        <SelectedItemsActionsPanel
          numItemsSelected={customerNumbers.length}
          afterLabelContent={
            <Row alignItems={"center"}>
              <FlatButton
                label={"Clear all"}
                onClick={() => clearAll()}
                leftIcon={stenaClear}
              />
              <FlatButton
                label={"Change negotiation status"}
                onClick={() => setModal("negStatus")}
                leftIcon={stenaStatusInProgress}
              />
              <FlatButton
                label={"Set expected volume"}
                onClick={() => setModal("expectedVolume")}
                leftIcon={stenaStatisticsBar}
              />
            </Row>
          }
        />
      </Box>

      <Window
        style={{ overlay: { justifyContent: "center" } }}
        headerText={
          modalContent === "negStatus"
            ? "Change negotiation status"
            : "Set expected volume"
        }
        isOpen={modalIsOpen}
        width={"450px"}
        indent={3}
        spacing={3}
        onRequestClose={closeModal}
      >
        {modalContent === "negStatus" ? (
          <NegotiationStatusModalContent
            modalClose={closeModal}
            customerNumbers={customerNumbers}
            negotiationYear={negotiationYear}
            clearAll={clearAll}
          />
        ) : (
          <ExpectedVolumeModalContent
            modalClose={closeModal}
            refetch={refetch}
            customerNumbers={customerNumbers}
            negotiationYear={negotiationYear}
            clearAll={clearAll}
          />
        )}
      </Window>
    </>
  );
};
