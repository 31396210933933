import * as React from "react";
import { CustomerList } from "./CustomerList";
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";

interface Props {}

export const CustomerListScreen: React.FC<Props> = () => (
  <>
    <Global
      styles={css`
        body {
          display: table;
        }
      `}
    />
    <Helmet title="Negotiation plan - FPP" />
    <SearchFilterSelectorProvider reducerId={"customerList"}>
      <CustomerList />
    </SearchFilterSelectorProvider>
  </>
);
