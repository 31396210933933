import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { HeadAgreementQuery, HeadAgreementQueryVariables } from "@/gql/graphql";
import { HeadAgreementFragments } from "./HeadAgreementFragments";

export const headAgreementQuery = gql`
  ${HeadAgreementFragments}
  fragment HeadAgreementById on HeadAgreement {
    id
    name
    statusCode
    headAgreementNumber
    hasSomeRouteAgreementsWithMultiLeg
    rowVersion
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    routeAgreements {
      ...RouteAgreementFields
    }
    multiLegAgrRoutes {
      id
      name
      headAgreement {
        id
        headAgreementNumber
        customer {
          id
          name
        }
        statusCode
      }
      route {
        id
        code
        name
      }
      currency {
        id
        code
        name
        shortDescription
        longDescription
      }
      statusCode
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
      dateCreated
      createdBy
      dateModified
      modifiedBy
      idNumber
      rowVersion
      workFlowStatusCode
      routeAgreements {
        ...RouteAgreementFields
      }
      routeLegs {
        id
        legRoute {
          id
          code
          name
        }
        route {
          id
          code
          name
        }
      }
    }
  }
  query HeadAgreement($headAgreementId: ID!, $customerId: ID!, $year: Int!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...HeadAgreementById
        }
      }
    }
  }
`;

export const useHeadAgreementById = (
  headAgreementId: string,
  customerId: string,
  year: number
) => {
  const options = useMemo<
    QueryHookOptions<HeadAgreementQuery, HeadAgreementQueryVariables>
  >(
    () => ({
      fetchPolicy: "cache-and-network",
      variables: { headAgreementId, customerId, year },
    }),
    [headAgreementId, customerId, year]
  );

  const { data, loading, error } = useQuery<
    HeadAgreementQuery,
    HeadAgreementQueryVariables
  >(headAgreementQuery, options);

  return {
    headAgreement: data?.productPrice.headAgreement.byId ?? undefined,
    loading,
    error,
  };
};
