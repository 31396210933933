import {
  createEntityActions,
  createEntityReducer,
  createEntitySelectors,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../../../../config/redux/RootReducer";
import { emptyRoutesFormModel, RoutesFormModel } from "./RoutesFormModel";
import { wrapActionsWithReducerIdGate } from "../../../../../../common/redux/ReducerIdGateActionWrapper";

const reducerId = "routeAgreementDetails.routes";

export type RoutesFormState = EntityState<RoutesFormModel>;

export const routesFormRedux = {
  reducer: reducerIdGate(
    reducerId,
    createEntityReducer<RoutesFormModel>(emptyRoutesFormModel)
  ),
  actions: wrapActionsWithReducerIdGate(reducerId, {
    ...createEntityActions<RoutesFormModel>(),
  }),
  selectors: createEntitySelectors<StoreState, RoutesFormModel>(
    (state) => state.routeAgreementDetails.workspace.routes
  ),
};
