import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";

import { errorFragment } from "../../../../../../../../../common/error/GqlErrorFragment";
import {
  ActivateMultiLegAgreementMutation,
  ActivateMultiLegAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation ActivateMultiLegAgreement(
    $activateMultiLegAgreementInput: ActivateMultiLegAgreementInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        activateMultiLegAgreement(input: $activateMultiLegAgreementInput) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateMultiLegRouteAgreementSuccessResult {
            multiLegAgreement {
              id
              statusCode
              rowVersion
            }
          }
        }
      }
    }
  }
`;

export const useActivateMultiLegAgreementMutation = () =>
  useMutation<
    ActivateMultiLegAgreementMutation,
    ActivateMultiLegAgreementMutationVariables
  >(query);
