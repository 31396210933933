import { StandardTableCellRenderer } from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { AgreementArticlesTableItem } from "../../../types";
import {
  AgreementArticlesTableCell,
  EditableAgreementArticlesTableFields,
} from "../AgreementArticlesTableCell";
import { CreateAgreementArticlePriceItemInput } from "@/gql/graphql";

type AcceptedTypes = string | undefined | number | null;

export const createAgreementArticlesTableCellRenderer =
  (
    field: EditableAgreementArticlesTableFields,
    tableRowErrors?: CreateAgreementArticlePriceItemInput[],
    agreementArticles?: EditableEntityState<AgreementArticlesTableItem>[]
  ): StandardTableCellRenderer<
    AcceptedTypes,
    EditableEntityState<AgreementArticlesTableItem>
  > =>
  ({ label, item, gridCell, isEditable, value, zIndex, isSelected }) => {
    const originalValue = item.persisted[field];
    const editableValue = item.editable[field];
    const id = item.persisted.id;

    return (
      <AgreementArticlesTableCell
        persistedValue={String(originalValue ?? "")}
        editableValue={String(editableValue ?? "")}
        value={label}
        entityId={id}
        gridCell={gridCell}
        isEditable={isEditable}
        field={field}
        tableRowErrors={tableRowErrors}
        agreementArticles={agreementArticles}
      />
    );
  };
