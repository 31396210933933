import * as React from "react";
import { useMemo } from "react";
import { MultiSelect, MultiSelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";

import {
  getOptionForRoute,
  getOptionsForSelect,
} from "./RouteInRoutePairSelectCalculator";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { RouteInRoutePairSelectQuery } from "@/gql/graphql";

interface Props extends Omit<MultiSelectProps<any>, "value" | "onChange"> {
  value?: Array<string>;
  includePartnerRoutes: boolean;
  onValueChange?: (value: Array<string>) => void;
}

const query = gql`
  query RouteInRoutePairSelect(
    $routeTypes: [String!]!
    $excludeOnlyAsPartOfMultiLegRoutes: Boolean!
    $includeNotValid: Boolean!
  ) {
    productPrice {
      route {
        byRouteTypes(
          routeTypes: $routeTypes
          excludeOnlyAsPartOfMultiLegRoutes: $excludeOnlyAsPartOfMultiLegRoutes
          includeNotValid: $includeNotValid
        ) {
          id
          code
          name
          routeType
          routePair {
            id
            code
            seaArea {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const RouteInRoutePairSelect: React.FC<Props> = ({
  onValueChange,
  value,
  includePartnerRoutes,
  ...multiSelectProps
}) => {
  const { data, loading } = useQuery<RouteInRoutePairSelectQuery>(query, {
    variables: {
      routeTypes: ["O", "P"],
      excludeOnlyAsPartOfMultiLegRoutes: false,
      includeNotValid: false,
    },
    fetchPolicy: "network-only",
  });
  const routes = useMemo(() => {
    return includePartnerRoutes
      ? data?.productPrice.route.byRouteTypes
      : data?.productPrice.route.byRouteTypes.filter(
          (route) => route.routeType === "O"
        );
  }, [data, includePartnerRoutes]);

  const allRouteOptions = useMemo(
    () => routes?.map(getOptionForRoute),
    [routes]
  );

  const options = useMemo(() => {
    return getOptionsForSelect(value, routes ?? []);
  }, [value, routes]);

  const selected = useMemo(
    () =>
      value
        ? value.map((v) => allRouteOptions?.find((o) => o.value === v))
        : [],
    [allRouteOptions, value]
  );

  return (
    <div data-testid={testIdConstantsWebapp.createRouteAgreementSelectRoute}>
      <MultiSelect
        isLoading={loading}
        options={options}
        value={selected}
        onChange={(vs: any) =>
          onValueChange?.(vs?.map((v: any) => v.value) ?? [])
        }
        {...multiSelectProps}
      />
    </div>
  );
};
