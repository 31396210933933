import * as React from "react";
import { useCanCustomerChangeRouteAgreementTypeQuery } from "../../common/hooks/UseCanCustomerChangeRouteAgreementTypeQuery";
import { Spacing } from "@stenajs-webui/core";
import { LoadingScreen } from "@stenajs-webui/panels";
import { CreateRouteAgreement } from "./CreateRouteAgreement";
import { gql } from "apollo-boost";
import {
  CreateRouteAgreementFetcherQuery,
  CreateRouteAgreementFetcherQueryVariables,
  RouteAgreementTypeCode,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";

interface Props {
  customerId: string;
  headAgreementId: string;
  onClickCancel?: () => void;
}

const query = gql`
  query CreateRouteAgreementFetcher($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
        }
      }
    }
  }
`;

export const CreateRouteAgreementFetcher: React.FC<Props> = (props) => {
  const {
    loading: loadingCanChangeType,
    canChangeRouteAgreementType,
    defaultRouteAgreementType = RouteAgreementTypeCode.Cond,
  } = useCanCustomerChangeRouteAgreementTypeQuery(props.customerId);

  const { data, loading } = useQuery<
    CreateRouteAgreementFetcherQuery,
    CreateRouteAgreementFetcherQueryVariables
  >(query, {
    variables: {
      headAgreementId: props.headAgreementId,
    },
  });

  if (loadingCanChangeType || loading) {
    return (
      <Spacing num={6}>
        <LoadingScreen />
      </Spacing>
    );
  }

  const valid = data?.productPrice.headAgreement.byId?.valid;

  return (
    <CreateRouteAgreement
      {...props}
      canChangeRouteAgreementType={canChangeRouteAgreementType}
      defaultRouteAgreementType={defaultRouteAgreementType}
      initialStartDate={valid?.start?.isoString ?? ""}
      initialEndDate={valid?.end?.isoString ?? ""}
    />
  );
};
