import { EditableHeadAgreement } from "../../edit/types";
import { HeadAgreementFormModel } from "../models/HeadAgreementFormModel";

export const transformToFormModel = (
  gqlModel: EditableHeadAgreement
): HeadAgreementFormModel => ({
  name: gqlModel.name,
  valid: {
    startDate:
      (gqlModel.valid.start?.isoString &&
        new Date(gqlModel.valid.start.isoString)) ||
      undefined,
    endDate:
      (gqlModel.valid.end?.isoString &&
        new Date(gqlModel.valid.end.isoString)) ||
      undefined,
  },
  headAgreementNumber: gqlModel.headAgreementNumber,
  statusCode: gqlModel.statusCode,
  condition: gqlModel.condition ?? "",
  internalNotes: gqlModel.internalComments ?? "",
  lastPage: gqlModel.lastPage ?? "",
  specifications: gqlModel.specifications ?? "",
});
