import { useQuery } from "@apollo/client";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import { useMemo } from "react";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { CSSObject } from "@emotion/react";
import { Box } from "@stenajs-webui/core";
import { SurchargeDocumentsSeaAreaQuery } from "@/gql/graphql";

const allSeaAreaTypeOption = [
  {
    label: "All",
    value: "ALL",
  },
];

const surchargeDocumentsSeaAreaQuery = gql`
  query SurchargeDocumentsSeaArea {
    productPrice {
      seaArea {
        all {
          id
          name
        }
      }
    }
  }
`;

export interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  width?: string | number;
  onValueChange?: (value: number) => void;
}

export const SurchargeDocumentsSeaAreaSelect: React.FC<Props> = ({
  onValueChange,
  value,
  width,
  ...selectProps
}) => {
  const { data, loading } = useQuery<SurchargeDocumentsSeaAreaQuery>(
    surchargeDocumentsSeaAreaQuery
  );

  const seaAreas = data?.productPrice.seaArea.all;

  const optionsByQuery = useMemo(() => {
    return (seaAreas ?? []).map((seaArea) => ({
      label: seaArea.name,
      value: seaArea.id,
    }));
  }, [seaAreas]);

  const options = allSeaAreaTypeOption.concat(optionsByQuery);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Box width={width}>
      <Select
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: CSSObject) => ({
            ...base,
            zIndex: ZIndex.highest,
          }),
        }}
        {...selectProps}
        isLoading={loading}
        options={options}
        value={selected}
        onChange={(item: any) => onValueChange && onValueChange(item.value)}
      />
    </Box>
  );
};
