import { useArraySet } from "@stenajs-webui/core";
import { stenaStack } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllCustomerCategories } from "../../hooks/FilterFetchers/UseFetchAllCustomerCategories";

interface Props {
  enabled?: boolean;
  label?: string;
}
export const CustomerCategoryFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <CustomerCategoryFilterSectionInternal {...props} /> : <></>;

const CustomerCategoryFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { customerCategories, setCustomerCategories } = useFilterWorkspace();
  const {
    customerCategories: items,
    error,
    loading,
    refetch,
  } = useFetchAllCustomerCategories();
  const { toggle } = useArraySet(
    customerCategories,
    setCustomerCategories,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"customerCategories"}
      label={label}
      enabled={enabled}
      numSelected={customerCategories.length}
      leftIcon={stenaStack}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {items.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!customerCategories.find(
                  (customerCategory) => customerCategory.id === item.id
                )
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
