import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";

import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  CreateMultiLegRouteAgreementRouteMutation,
  CreateMultiLegRouteAgreementRouteMutationVariables,
} from "@/gql/graphql";

export const mutation = gql`
  ${errorFragment}
  mutation CreateMultiLegRouteAgreementRoute(
    $createMultiLegRouteAgreementRouteInput: CreateMultiLegRouteAgreementRouteInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        createMultiLegRouteAgreementRoute(
          input: $createMultiLegRouteAgreementRouteInput
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on CreateMultiLegRouteAgreementRouteSuccessResult {
            routeAgreement {
              id
              agreementNumber
              name
              breadCrumbName
              routes {
                id
                code
                name
                routePair {
                  id
                }
              }
              typeCode
              sailingTypeCodes
              currency {
                id
                code
                name
              }
              isSalesReady
              routeDescription
              hasArticles
              hasConditions
              hasMatrix
              valid {
                start {
                  isoString
                }
                end {
                  isoString
                }
              }
              note
              comment
              multiLegAgrRouteId
              externalComment
              rowVersion
            }
            headAgreement {
              id
              name
              statusCode
              valid {
                start {
                  isoString
                }
                end {
                  isoString
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreateMultiLegAgreementRoute = () => {
  const [createMultiLegRouteAgreementRoute, { loading, error }] = useMutation<
    CreateMultiLegRouteAgreementRouteMutation,
    CreateMultiLegRouteAgreementRouteMutationVariables
  >(mutation);

  return {
    createMultiLegRouteAgreementRoute,
    loading,
    error,
  };
};
