import { useArraySet } from "@stenajs-webui/core";
import { stenaSailingCargo } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { UseFetchAllUnitTypes } from "../../hooks/FilterFetchers/UseFetchAllUnitTypes";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const UnitTypeFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <UnitTypeFilterSectionInternal {...props} /> : <></>;

const UnitTypeFilterSectionInternal: React.FC<Props> = ({ enabled, label }) => {
  const { unitTypes, setUnitTypes } = useFilterWorkspace();
  const { error, loading, refetch, unitTypes: items } = UseFetchAllUnitTypes();
  const { toggle } = useArraySet(
    unitTypes,
    setUnitTypes,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"unitTypes"}
      label={label}
      enabled={enabled}
      numSelected={unitTypes.length}
      leftIcon={stenaSailingCargo}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {items.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={!!unitTypes.find((unitType) => unitType.id === item.id)}
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
