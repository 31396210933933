import * as React from "react";
import { Box } from "@stenajs-webui/core";
import { StandardTable } from "@stenajs-webui/grid";
import { statBoxTableConfig } from "../config/StatBoxTableConfig";
import { RoutePriceVariant } from "../transformers/StatBoxDataTransformer";
import { Card, CardBody, Spinner } from "@stenajs-webui/elements";
import { useSelector } from "react-redux";
import { StoreState } from "../../../config/redux/RootReducer";
import { CustomerRouteStatisticsBoxHeader } from "./CustomerRouteStatisticsBoxHeader";

interface Props {
  items: Array<RoutePriceVariant> | undefined;
  sailingTypeCode: string;
  sailingTypeName: string;
}

const selector = (state: StoreState) => state.statBoxes;

export const CustomerRouteStatisticsBox: React.FC<Props> = React.memo(
  ({ sailingTypeCode, sailingTypeName, items }) => {
    const { loading } = useSelector(selector);
    return (
      <Card>
        <CustomerRouteStatisticsBoxHeader
          loading={Boolean(loading && items && items.length)}
          sailingTypeCode={sailingTypeCode}
          sailingTypeName={sailingTypeName}
        />
        {loading && !items ? (
          <Box height={"161px"} alignItems={"center"} justifyContent={"center"}>
            <Spinner size={"small"} />
          </Box>
        ) : (
          <CardBody>
            <StandardTable config={statBoxTableConfig} items={items} />
          </CardBody>
        )}
      </Card>
    );
  }
);
