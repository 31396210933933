import { AppThunk } from "../../../../../config/redux/RootReducer";
import { fetchActualChangeForSinglePriceRow } from "../../thunks/FetchActualChangeForSinglePriceRow";

export const afterBulkUpdateRefresh =
  (
    routeAgreementId: string,
    routeAgreementPriceIdList: Array<string>
  ): AppThunk =>
  async (dispatch) => {
    await Promise.all(
      routeAgreementPriceIdList.map(
        async (routeAgreementPriceId) =>
          await dispatch(
            fetchActualChangeForSinglePriceRow(
              routeAgreementId,
              routeAgreementPriceId
            )
          )
      )
    );
  };
