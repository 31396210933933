import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityState } from "@stenajs-webui/redux";
import { BulkEditRatesStatBoxesData } from "../models/BulkEditRatesStatBoxData";
import {
  ItemByRoutePairCodesForCustomersFragment,
  RouteAgreementsRatesByFilterQueryVariables,
} from "@/gql/graphql";

export type BulkEditRatesStatBoxesState =
  EntityState<BulkEditRatesStatBoxesEntity>;

export type BulkEditRatesStatBoxQueryData = Record<
  string,
  ItemByRoutePairCodesForCustomersFragment
>;

export const bulkEditRatesStatBoxesReducerId = "bulkEditRatesStatBoxes";

interface BulkEditRatesStatBoxesEntity {
  loading?: boolean;
  queryParams?: Omit<
    RouteAgreementsRatesByFilterQueryVariables,
    "byFilterInput"
  >;
  queryData?: BulkEditRatesStatBoxQueryData;
  headAgreementId?: string;
  statBoxesData?: Array<BulkEditRatesStatBoxesData>;
}

const initialState: BulkEditRatesStatBoxesEntity = {
  loading: false,
  headAgreementId: "",
  queryData: {},
  queryParams: { customerId: "", year: 0, routePairCodes: [] },
};

const bulkEditRatesStateBoxesSlice = createSlice({
  name: bulkEditRatesStatBoxesReducerId,
  initialState,
  reducers: {
    clearAll(state) {
      state.loading = false;
      state.queryParams = undefined;
      state.statBoxesData = undefined;
      state.queryData = undefined;
    },
    setLoading(state, { payload: loading }: PayloadAction<boolean>) {
      state.loading = loading;
    },
    setQueryParams(
      state,
      {
        payload: queryParams,
      }: PayloadAction<
        Omit<RouteAgreementsRatesByFilterQueryVariables, "byFilterInput">
      >
    ) {
      state.queryParams = queryParams;
    },
    setQueryData(
      state,
      { payload: queryData }: PayloadAction<BulkEditRatesStatBoxQueryData>
    ) {
      state.queryData = queryData;
    },
    setStatBoxesData(
      state,
      {
        payload: statBoxesData,
      }: PayloadAction<Array<BulkEditRatesStatBoxesData>>
    ) {
      state.statBoxesData = statBoxesData;
    },
    setHeadAgreementId(
      state,
      { payload: headAgreementId }: PayloadAction<string>
    ) {
      state.headAgreementId = headAgreementId;
    },
  },
});

export const {
  reducer: bulkEditRatesStatBoxesReducer,
  actions: bulkEditRatesStatBoxesActions,
} = bulkEditRatesStateBoxesSlice;
