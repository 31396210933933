import {
  DeleteValidFromDateInput,
  DeleteValidFromDateMutation,
  DeleteValidFromDateMutationVariables,
} from "@/gql/graphql";
import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const mutation = gql`
  mutation DeleteValidFromDate($input: DeleteValidFromDateInput!) {
    productPrice {
      noShowLateHandlingPrices {
        deleteValidFromDate(input: $input) {
          __typename
          ... on DeleteValidFromDateErrorResult {
            errors {
              message
              path
            }
          }
          ... on DeleteValidFromDateSuccessResult {
            success
          }
        }
      }
    }
  }
`;

export const useNoShowLateHandlingDeleteValidFromDateMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    DeleteValidFromDateMutation,
    DeleteValidFromDateMutationVariables
  >(mutation);
  const deleteValidFromDate = useCallback(
    (input: DeleteValidFromDateInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );
  return {
    deleteValidFromDate,
    loading,
    error,
  };
};
