import {
  Box,
  Column,
  Row,
  Space,
  Spacing,
  Txt,
  useBoolean,
} from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";
import { useBulkUpdateRatesLogic } from "../hooks/UseBulkUpdateRatesLogic";
import {
  emptyBulkUpdateRatesFormModel,
  RaiseType,
} from "../models/BulkUpdateRatesFormModel";
import { afterBulkUpdateRefresh } from "../thunks/AfterBulkUpdateRefresher";
import { validateBulkUpdateFormModel } from "../validators/FormModelValidator";
import { BulkUpdateRatesForm } from "./BulkUpdateRatesForm";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  routeAgreementId: string;
  currencyLabel?: string;
  onRequestClose?: () => void;
}

export const BulkUpdateRates: React.FC<Props> = ({
  routeAgreementId,
  currencyLabel,
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const [formModel, setFormModel] = useState(emptyBulkUpdateRatesFormModel);
  const { bulkUpdateRates } = useBulkUpdateRatesLogic(routeAgreementId);
  const [localError, setLocalError] = useState<Error | undefined>();

  const canSubmit = formModel !== emptyBulkUpdateRatesFormModel;

  const [visible, show, hide] = useBoolean(false);

  const routeAgreementPricesSelector = useCallback(
    (state: StoreState) => {
      const selectedRouteAgreement = Object.values(
        state.rateSheet.routeAgreements
      ).find((ra) => {
        return ra?.id === routeAgreementId;
      });

      return selectedRouteAgreement?.prices;
    },
    [routeAgreementId]
  );

  const selectedRouteAgreementPrices = useSelector(
    routeAgreementPricesSelector
  );
  const submitHandler = useCallback(async () => {
    setLocalError(undefined);

    try {
      if (
        selectedRouteAgreementPrices?.some(
          (price) => Number(price?.meterPrice.amount ?? 0) < 1
        ) &&
        formModel.raiseType === RaiseType.BY_METER_PRICE
      ) {
        show();
        return;
      }

      validateBulkUpdateFormModel(formModel);
      const routeAgreementPriceIdList = bulkUpdateRates(formModel);

      if (onRequestClose) {
        onRequestClose();
      }

      await dispatch(
        afterBulkUpdateRefresh(routeAgreementId, routeAgreementPriceIdList)
      );

      await dispatch(refetchStatBoxesWithRateSheetsChanges());
    } catch (e) {
      setLocalError(e);
    }
  }, [
    selectedRouteAgreementPrices,
    show,
    formModel,
    bulkUpdateRates,
    onRequestClose,
    dispatch,
    routeAgreementId,
  ]);

  const onYesPromptSelectedRaise = useCallback(async () => {
    setLocalError(undefined);
    try {
      validateBulkUpdateFormModel(formModel);
      const routeAgreementPriceIdList = bulkUpdateRates(formModel);

      if (onRequestClose) {
        onRequestClose();
      }

      await dispatch(
        afterBulkUpdateRefresh(routeAgreementId, routeAgreementPriceIdList)
      );

      await dispatch(refetchStatBoxesWithRateSheetsChanges());
    } catch (e) {
      setLocalError(e);
    }
  }, [formModel, bulkUpdateRates, onRequestClose, dispatch, routeAgreementId]);
  return (
    <>
      <Column>
        <BulkUpdateRatesForm
          value={formModel}
          onValueChange={setFormModel}
          currencyLabel={currencyLabel}
        />
      </Column>
      <Spacing />
      {!visible && (
        <Row justifyContent={"flex-end"}>
          <PrimaryButton
            label={"Apply"}
            disabled={!canSubmit}
            onClick={submitHandler}
          />
        </Row>
      )}
      {visible && (
        <>
          <Box width={"100%"}>
            <Box
              background={cssColor("--lhds-color-orange-50")}
              indent={2}
              spacing={2}
              borderRadius={2}
            >
              <Txt size={"small"}>
                You have selected a row where the meter price is 0. Are you sure
                you want to continue?
              </Txt>
            </Box>
            <Space num={2} />
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Space num={2} />
              <PrimaryButton label="Yes" onClick={onYesPromptSelectedRaise} />
              <Space num={2} />
              <FlatButton label="No" onClick={hide} />
            </Box>
          </Box>
        </>
      )}
      <Row justifyContent={"flex-end"} spacing>
        <ValidationErrorMessage error={localError} />
      </Row>
    </>
  );
};
