import { gql, useQuery } from "@apollo/client";
import { NegotiationStatus } from "../../types/FilterEntitys";
import { NegotiationStatusesFilterQuery } from "@/gql/graphql";

const negotiationStatusesFilterQuery = gql`
  query NegotiationStatusesFilter {
    productPrice {
      negotiationStatus {
        all {
          id
          name
        }
      }
    }
  }
`;
export const useFetchAllNegotiationStatuses = () => {
  const { data, loading, error, refetch } =
    useQuery<NegotiationStatusesFilterQuery>(negotiationStatusesFilterQuery, {
      fetchPolicy: "cache-first",
    });
  const negotiationStatuses =
    data?.productPrice.negotiationStatus.all.map<NegotiationStatus>((r) => ({
      id: r.id,
      code: r.id,
      name: r.name,
    })) ?? [];

  return { negotiationStatuses, loading, error, refetch };
};
