import { Box, Row, Space } from "@stenajs-webui/core";
import { TextInput } from "@stenajs-webui/forms";
import * as React from "react";
import { NumberDecimalsSelect } from "../../../../../common/components/data-driven-inputs/selects/number-decimals-select/NumberDecimalsSelect";
import { RaiseByTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-by-type-select/RaiseByTypeSelect";
import { RaiseTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-type-select/RaiseTypeSelect";
import { BulkUpdateRatesFormModel } from "../models/BulkUpdateRatesFormModel";

interface Props {
  value: BulkUpdateRatesFormModel;
  onValueChange: (value: BulkUpdateRatesFormModel) => void;
  currencyLabel?: string;
}

export const BulkUpdateManyForm: React.FC<Props> = ({
  value,
  onValueChange,
  currencyLabel,
}) => {
  return (
    <Row alignItems={"center"}>
      <Box width={"60px"}>
        <TextInput
          value={value.raiseBy}
          onValueChange={(raiseBy) => onValueChange({ ...value, raiseBy })}
        />
      </Box>
      <Space />
      <Box width={"130px"}>
        <RaiseByTypeSelect
          currencyLabel={currencyLabel}
          value={value.raiseByType}
          onValueChange={(raiseByType) =>
            onValueChange({ ...value, raiseByType })
          }
        />
      </Box>
      <Space />
      <Box width={"140px"}>
        <NumberDecimalsSelect
          value={value.numberDecimals}
          onValueChange={(numberDecimals) =>
            onValueChange({ ...value, numberDecimals })
          }
        />
      </Box>
      <Space />
      <Box width={"150px"}>
        <RaiseTypeSelect
          value={value.raiseType}
          onValueChange={(raiseType) => onValueChange({ ...value, raiseType })}
        />
      </Box>
    </Row>
  );
};
