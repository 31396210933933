import * as React from "react";
import { Window } from "@stenajs-webui/modal";
import { EditMultiLegAgreementSetup } from "./EditMultiLegAgreementSetup";
import { Txt } from "@stenajs-webui/core";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  onRequestClose: () => void;
}

export const EditMultiLegAgreementModal: React.FC<Props> = ({
  onRequestClose,
  ...props
}) => {
  return (
    <Window
      isOpen
      onRequestClose={onRequestClose}
      indent={2}
      width={"100%"}
      header={
        <Txt variant={"bold"} size={"large"}>
          {`Edit multi leg agreement (${props.multiLegAgreement.route.code})`}
        </Txt>
      }
    >
      <EditMultiLegAgreementSetup {...props} onRequestClose={onRequestClose} />
    </Window>
  );
};
