import { AppThunk } from "../../../../../config/redux/RootReducer";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { routeAgreementDetailsRecalculationProgressActions } from "../../../route-agreement/details/redux";
import { rateSheetStandardTableWrapperActions } from "../../../rate-sheet/redux";
import { rateSheetTableActions } from "../../../rate-sheet/actions";
import { batch } from "react-redux";
import { createInputForFetchRecalcStatsForSingleRouteAgreementPrice } from "../InputTransformer";
import {
  RecalcStatsForSingleRouteAgreementPriceQuery,
  RecalcStatsForSingleRouteAgreementPriceQueryVariables,
} from "@/gql/graphql";

const statisticsQuery = gql`
  query RecalcStatsForSingleRouteAgreementPrice(
    $input: RouteAgreementPriceCalculateStatisticsInput!
  ) {
    productPrice {
      routeAgreementPrice {
        calculateStatistics(input: $input) {
          routeAgreementPriceId
          avgGrossPrice {
            amount
          }
          avgLength
          avgRebate {
            amount
          }
          volume
        }
      }
    }
  }
`;

const setProgress = routeAgreementDetailsRecalculationProgressActions.setEntity;

export const fetchStatsForSingleRouteAgreementCalculation =
  (routeAgreementId: string, customerId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setProgress({ error: undefined, loading: true }));

    const routeAgreement = getState().routeAgreementDetails.workspace;

    if (!routeAgreement) {
      dispatch(
        setProgress({
          error: new Error(
            "Could not find route agreement when trying to calculate route agreement statistics."
          ),
          loading: false,
        })
      );
      return;
    }

    const rateSheet = getState().rateSheet.table[routeAgreementId]?.rows;
    if (!rateSheet || !rateSheet.length) {
      return;
    }

    const actions = await Promise.all(
      rateSheet.map(async (r) => {
        const input =
          createInputForFetchRecalcStatsForSingleRouteAgreementPrice({
            customerId,
            routeAgreementId,
            formModel: routeAgreement.routes,
            rateSheetTableRowState: r,
          });

        try {
          const { errors, data } = await apolloClient.query<
            RecalcStatsForSingleRouteAgreementPriceQuery,
            RecalcStatsForSingleRouteAgreementPriceQueryVariables
          >({
            query: statisticsQuery,
            variables: {
              input,
            },
            fetchPolicy: "network-only",
          });
          if (errors && errors.length) {
            return new Error(
              "Failed when fetching statistics for price id=" +
                r.routeAgreementPriceId
            );
          }

          const { calculateStatistics } = data.productPrice.routeAgreementPrice;

          return rateSheetStandardTableWrapperActions.tableRows.recordAction(
            input.routeAgreementId,
            rateSheetTableActions.setEditablePriceFields(
              input.routeAgreementPriceId,
              {
                avgGrossPrice: calculateStatistics.avgGrossPrice,
                volume: calculateStatistics.volume,
                avgLength: calculateStatistics.avgLength,
                avgRebate: calculateStatistics.avgRebate,
              }
            )
          );
        } catch (e) {
          return e as Error;
        }
      })
    );

    let numFailed = 0;

    batch(() => {
      actions.forEach((a) => {
        if (a instanceof Error) {
          numFailed++;
        } else {
          dispatch(a);
        }
      });
    });

    dispatch(
      setProgress({
        error:
          numFailed > 0
            ? new Error(`${numFailed} of ${actions.length} failed.`)
            : undefined,
        loading: false,
      })
    );
  };
