import { ApolloError } from "@apollo/client";
import * as React from "react";
import { PageLayout } from "../../../../../common/components/page-layout/PageLayout";
import {
  AgreementArticlesCustomer,
  AgreementArticlesHeadAgreement,
} from "../types";
import { AgreementArticlesHeader } from "./AgreementArticlesHeader";
import { AgreementArticlesInfoBoxes } from "./AgreementArticlesInfoBoxes";
import { AgreementArticlesTableWrapper } from "./table/AgreementArticlesTableWrapper";

interface Props {
  headAgreement: AgreementArticlesHeadAgreement;
  customer: AgreementArticlesCustomer;
  tableError: ApolloError | undefined;
}

export const AgreementArticlesView: React.FC<Props> = ({
  headAgreement,
  customer,
  tableError,
}) => {
  return (
    <>
      <AgreementArticlesHeader
        customer={customer}
        headAgreementId={headAgreement?.id ?? ""}
      />
      <PageLayout>
        <AgreementArticlesInfoBoxes
          customer={customer}
          switchButtonDisabled={true}
          headAgreement={headAgreement}
        />
        <AgreementArticlesTableWrapper error={tableError} customer={customer} />
      </PageLayout>
    </>
  );
};
