import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RouteTargetsOverviewState {
  selectedYear: number | null;
}

const initialRouteTargetsOverviewState: RouteTargetsOverviewState = {
  selectedYear: null,
};

export const RouteTargetsOverviewReducerId = "routeTargetsOverviewReducerId";

export const routeTargetsOverviewReducerSlice = createSlice({
  name: RouteTargetsOverviewReducerId,
  initialState: initialRouteTargetsOverviewState,
  reducers: {
    setRouteTargetsOverviewNegotiationYear(
      state,
      { payload: selectedYearState }: PayloadAction<number>
    ) {
      state.selectedYear = selectedYearState;
    },
  },
});

export const { setRouteTargetsOverviewNegotiationYear } =
  routeTargetsOverviewReducerSlice.actions;

export const routeTargetsOverviewReducer =
  routeTargetsOverviewReducerSlice.reducer;
