import { useArraySet } from "@stenajs-webui/core";
import { stenaSailingTrailer } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { Unacc } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

const unaccItems: Array<Unacc> = [
  { id: "Accompanied", code: "Accompanied", name: "Accompanied" },
  { id: "Unaccompanied", code: "Unaccompanied", name: "Unaccompanied" },
  { id: "Both", code: "Both", name: "Both" },
];

interface Props {
  enabled?: boolean;
  label?: string;
}

export const UnaccFilterSection: React.FC<Props> = ({ enabled, label }) => {
  const { unacc, setUnacc } = useFilterWorkspace();

  const { toggle } = useArraySet(unacc, setUnacc, (a, b) => a.id === b.id);

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = unaccItems.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"unacc"}
      label={label}
      enabled={enabled}
      numSelected={unacc.length}
      leftIcon={stenaSailingTrailer}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {unaccItems.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={!!unacc.find((unacc) => unacc.id === item.id)}
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
