import { useArraySet } from "@stenajs-webui/core";
import { stenaBan } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { IncludeCustomersWith } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

const includeWithItems: Array<IncludeCustomersWith> = [
  {
    name: "No current agreement",
    code: "no_current_agreement",
    id: "no_current_agreement",
  },
  {
    name: "Status blocked",
    code: "status_blocked",
    id: "status_blocked",
  },
];

interface Props {
  enabled?: boolean;
  label?: string;
}
export const IncludeCustomersWithFilterSection: React.FC<Props> = (props) =>
  props.enabled ? (
    <IncludeCustomersWithFilterSectionInternal {...props} />
  ) : (
    <></>
  );

const IncludeCustomersWithFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { includeCustomersWith, setIncludeCustomersWith } =
    useFilterWorkspace();

  const { toggle } = useArraySet(
    includeCustomersWith,
    setIncludeCustomersWith,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = includeWithItems.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"includeCustomersWith"}
      label={label}
      enabled={enabled}
      numSelected={includeCustomersWith.length}
      leftIcon={stenaBan}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {includeWithItems.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!includeCustomersWith.find((status) => status.id === item.id)
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
