import { GlobalState, initialProfileState, profileReducerId } from "./reducers";
import { createEntityActions, reducerIdGateAction } from "@stenajs-webui/redux";

export const profileActions = {
  setGlobalState: (fields: Partial<GlobalState>) =>
    reducerIdGateAction(
      profileReducerId,
      createEntityActions<GlobalState>().setEntityFields(fields)
    ),
  resetGlobalState: () =>
    reducerIdGateAction(
      profileReducerId,
      createEntityActions<GlobalState>().setEntityFields(initialProfileState)
    ),
};
