import {
  createEntityActions,
  createEntityReducer,
  EntityState,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { StatBoxesData } from "./models/StatBoxData";
import {
  CustomerStatisticsByFilterItemsFragment,
  FetchStatBoxesQueryVariables,
} from "@/gql/graphql";

export type StatBoxesState = EntityState<StatBoxesEntity>;

export type StatBoxQueryData = Record<
  string,
  CustomerStatisticsByFilterItemsFragment
>;

interface StatBoxesEntity {
  loading: boolean;
  queryParams?: FetchStatBoxesQueryVariables;
  queryData?: StatBoxQueryData;
  headAgreementId?: string;
  statBoxesData?: StatBoxesData;
}

const reducerId = "statBoxes";

export const statBoxesRedux = {
  reducer: reducerIdGate(
    reducerId,
    createEntityReducer<StatBoxesEntity>({
      loading: false,
    })
  ),
  actions: {
    clearAll: () =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          loading: false,
          queryParams: undefined,
          statBoxesData: undefined,
        })
      ),
    setLoading: () =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          loading: true,
        })
      ),
    setNotLoading: () =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          loading: false,
        })
      ),
    setQueryParams: (queryParams: FetchStatBoxesQueryVariables) =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          queryParams,
        })
      ),
    setQueryData: (queryData: StatBoxQueryData) =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          queryData,
        })
      ),
    setStatBoxesData: (statBoxesData: StatBoxesData) =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          statBoxesData,
        })
      ),
    setHeadAgreementId: (headAgreementId: string) =>
      reducerIdGateAction(
        reducerId,
        createEntityActions<StatBoxesEntity>().setEntityFields({
          headAgreementId,
        })
      ),
  },
};
