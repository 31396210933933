import { HandleBookingDataFragment } from "@/gql/graphql";

export interface NoShowLateHandlingHandleCustomerDataRow {
  id: string;
  customer: {
    custNo: number;
    custIndex: string;
    name: string;
    modeOfPay: string;
  };
  region: string;
  seller: string;
  noOfToDo: number;
  noOfToBeInvoiced: number;
  noOfInvoiced: number;
  noOfDoNotInvoice: number;
  noOfNotChargeable: number;
  noOfThreshold: number;
  total: number;
  bookingsData: Array<NoShowLateHandlingHandleBookingsRow>;
}

export interface ExtendedNoShowLateHandlingHandleCustomerDataRow
  extends NoShowLateHandlingHandleCustomerDataRow {
  checkboxColumn: string;
}

export type NoShowLateHandlingHandleCustomerDataTableColumnKeys =
  | keyof Omit<
      ExtendedNoShowLateHandlingHandleCustomerDataRow,
      "id" | "bookingsData" | "noOfHandleBookings" | "customer"
    >
  | "customerName"
  | "custNo"
  | "custIndex";

export interface NoShowLateHandlingHandleBookingsRow
  extends HandleBookingDataFragment {}

export interface ExtendedNoShowLateHandlingHandleBookingsRow
  extends NoShowLateHandlingHandleBookingsRow {
  checkboxColumn: string;
  routeCode: string;
}

export type NoShowLateHandlingHandleBookingsTableColumnKeys = keyof Omit<
  ExtendedNoShowLateHandlingHandleBookingsRow,
  | "month"
  | "consignment"
  | "vehicleReg"
  | "trailerReg"
  | "customerReference"
  | "revisedTime"
  | "revisedDate"
  | "modifiedBy"
  | "dateModified"
  | "id"
  | "loadingListMessage"
  | "route"
  | "consignmentVersion"
  | "actualSailDepartureDate"
  | "actualSailDepartureTime"
  | "actualRouteCode"
  | "consignmentNo"
  | "__typename"
>;

export const noShowLateHandlingHandleBookingsColumnOrder: Array<NoShowLateHandlingHandleBookingsTableColumnKeys> =
  [
    "checkboxColumn",
    "bookingNo",
    "bookingVersion",
    "codeNoShowLateType",
    "handlingStatus",
    "excludeReason",
    "routeCode",
    "departureDate",
    "departureTime",
    "vehicleType",
    "lateHandlingUser",
    "lateHandlingDate",
    "note",
  ];
