import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const NSHLHFilterSettings: FppFilterSettings = {
  customerEnabled: true,
  customerHeader: "Customer",
  sellerEnabled: true,
  sellerHeader: "Seller",
  routePairEnabled: true,
  routePairHeader: "Route",
  salesRegionEnabled: true,
  yearMonthEnabled: true,
  bcNoteEnabled: true,
  bcNoteHeader: "B&C note",
  departureInputEnabled: true,
  departureInputHeader: "Departure",
  bookingNumberSearchEnabled: true,
  bookingNumberSearchHeader: "Booking",
  noShowLateHandlingTypeSearchEnabled: true,
  noShowLateHandlingTypeSearchHeader: "Handling type",
  noShowLateHandlingStatusSearchEnabled: true,
  noShowLateHandlingStatusSearchHeader: "Status",
};
