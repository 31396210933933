import * as React from "react";
import { useParams } from "react-router-dom";
import { ErrorScreen } from "@stenajs-webui/panels";
import { StatisticsBySellerDetails } from "./StatisticsBySellerDetails";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";

interface Props {}

interface StatisticsBySellerDetailsScreenParams {
  sellerId?: string;
  year?: string;
}

export const StatisticsBySellerDetailsScreen: React.FC<Props> = () => {
  const { sellerId } = useParams() as StatisticsBySellerDetailsScreenParams;
  const { year } = useStatisticsYearSelect();

  if (!sellerId) {
    return <ErrorScreen text={"No such seller."} />;
  }

  return <StatisticsBySellerDetails sellerId={sellerId} year={year} />;
};
