import { startCase } from "lodash";

export const formatCompanyName = (s: string | undefined): string | undefined =>
  s && s.split(" ").map(formatCompanyNameWord).join(" ");

const formatCompanyNameWord = (word: string): string => {
  const isUpperCase = word.toUpperCase() === word;
  if (isUpperCase && word.length > 3) {
    return startCase(word.toLowerCase());
  }
  return word;
};
