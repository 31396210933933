import {
  createEditableEntityActions,
  createEntityActions,
  createEntityListActions,
  EditableEntitySetEditableEntityFieldsAction,
  EditableEntityState,
  EntityListState,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { AllRoutesTableRow } from "../config/RowsAndColumnsConfig";
import { adminTargetReducerId, ProgressState } from "./reducers";

export const adminTargetActions = {
  setRowsAdminTargetAllRoutes: (
    fields: EntityListState<EditableEntityState<AllRoutesTableRow>>
  ) =>
    reducerIdGateAction(
      adminTargetReducerId,
      createEntityListActions<EditableEntityState<AllRoutesTableRow>>().setList(
        fields
      )
    ),
  setProgress: (fields: Partial<ProgressState>) =>
    reducerIdGateAction(
      adminTargetReducerId,
      createEntityActions<ProgressState>().setEntityFields(fields)
    ),
  setFieldsForRow: (routeRowId: string, fields: Partial<AllRoutesTableRow>) => {
    return reducerIdGateAction(
      adminTargetReducerId,
      createEntityListActions<
        AllRoutesTableRow,
        EditableEntitySetEditableEntityFieldsAction<AllRoutesTableRow>
      >().actionByFieldsMatch(
        { id: routeRowId },
        createEditableEntityActions<AllRoutesTableRow>().setEditableEntityFields(
          fields
        )
      )
    );
  },
  resetTableState: (
    fields: EntityListState<EditableEntityState<AllRoutesTableRow>>
  ) => {
    return reducerIdGateAction(
      adminTargetReducerId,
      createEntityListActions<EditableEntityState<AllRoutesTableRow>>().setList(
        fields
      )
    );
  },
};
