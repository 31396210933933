import { AppThunk } from "../../../../../config/redux/RootReducer";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { routeAgreementDetailsRecalculationProgressActions } from "../../../route-agreement/details/redux";
import { rateSheetStandardTableWrapperActions } from "../../../rate-sheet/redux";
import { rateSheetTableActions } from "../../../rate-sheet/actions";
import { batch } from "react-redux";
import { createInputForFetchRecalcStatsForMultipleRouteAgreementPrice } from "../InputTransformer";

import { flatten } from "lodash";
import { RouteAgreementForRecalc } from "../hooks/UseRecalculationOfPriceRowsIsRequiredBeforeSave";
import { routeAgreementCanBeEditedByStatusCode } from "../../../route-agreement/util/RouteAgreementCalculator";
import {
  RecalcPriceStatsForMultipleRouteAgreementsQuery,
  RecalcPriceStatsForMultipleRouteAgreementsQueryVariables,
  RouteAgreementForRecalcStatsQuery,
  RouteAgreementForRecalcStatsQueryVariables,
} from "@/gql/graphql";

const statisticsQuery = gql`
  query RecalcPriceStatsForMultipleRouteAgreements(
    $input: RouteAgreementPriceCalculateStatisticsInput!
  ) {
    productPrice {
      routeAgreementPrice {
        calculateStatistics(input: $input) {
          routeAgreementPriceId
          avgGrossPrice {
            amount
          }
          avgLength
          avgRebate {
            amount
          }
          volume
        }
      }
    }
  }
`;

const routeAgreementQuery = gql`
  query RouteAgreementForRecalcStats($routeAgreementId: ID!) {
    productPrice {
      routeAgreement {
        byId(id: $routeAgreementId) {
          id

          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
          currency {
            id
          }
          sailingTypeCodes

          routes {
            id
            code
          }
        }
      }
    }
  }
`;

const setProgress = routeAgreementDetailsRecalculationProgressActions.setEntity;

export const fetchStatsForMultiRouteAgreementCalculation =
  (
    routeAgreements: Array<RouteAgreementForRecalc>,
    customerId: string
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setProgress({ error: undefined, loading: true }));

    const actions = flatten(
      await Promise.all(
        routeAgreements
          .filter((r) => routeAgreementCanBeEditedByStatusCode(r.statusCode))
          .map(async (inputRouteAgreement) => {
            const { data, errors } = await apolloClient.query<
              RouteAgreementForRecalcStatsQuery,
              RouteAgreementForRecalcStatsQueryVariables
            >({
              query: routeAgreementQuery,
              variables: {
                routeAgreementId: inputRouteAgreement.id,
              },
            });

            if (errors && errors.length) {
              return new Error(
                "No such route agreement when trying to recalculate statistics for prices."
              );
            }

            const routeAgreement = data.productPrice.routeAgreement.byId;

            if (!routeAgreement) {
              return [];
            }

            const rateSheet =
              getState().rateSheet.table[inputRouteAgreement.id]?.rows;

            if (!rateSheet) {
              return [];
            }

            const actions = await Promise.all(
              (rateSheet ?? []).map(async (r) => {
                const input =
                  createInputForFetchRecalcStatsForMultipleRouteAgreementPrice({
                    customerId,
                    routeAgreement,
                    rateSheetTableRowState: r,
                  });

                try {
                  const { errors, data } = await apolloClient.query<
                    RecalcPriceStatsForMultipleRouteAgreementsQuery,
                    RecalcPriceStatsForMultipleRouteAgreementsQueryVariables
                  >({
                    query: statisticsQuery,
                    variables: {
                      input,
                    },
                    fetchPolicy: "network-only",
                  });
                  if (errors && errors.length) {
                    return new Error(
                      "Failed when fetching statistics for price id=" +
                        r.routeAgreementPriceId
                    );
                  }

                  const { calculateStatistics } =
                    data.productPrice.routeAgreementPrice;

                  return rateSheetStandardTableWrapperActions.tableRows.recordAction(
                    input.routeAgreementId,
                    rateSheetTableActions.setEditablePriceFields(
                      input.routeAgreementPriceId,
                      {
                        avgGrossPrice: calculateStatistics.avgGrossPrice,
                        volume: calculateStatistics.volume,
                        avgLength: calculateStatistics.avgLength,
                        avgRebate: calculateStatistics.avgRebate,
                      }
                    )
                  );
                } catch (e) {
                  return e as Error;
                }
              })
            );

            return actions;
          })
      )
    );

    let numFailed = 0;

    batch(() => {
      actions.forEach((a) => {
        if (a instanceof Error) {
          numFailed++;
        } else {
          dispatch(a);
        }
      });
    });

    dispatch(
      setProgress({
        error:
          numFailed > 0
            ? new Error(`${numFailed} of ${actions.length} failed.`)
            : undefined,
        loading: false,
      })
    );
  };
