import { RateSheetRouteAgreementPrice } from "./types";

export type RateSheetTableAction =
  | RateSheetSetEditableActualPriceChangeAction
  | RateSheetClearAllAction
  | RateSheetPopulateWithPersistedPricesAction
  | RateSheetCreateNewPriceAction
  | RateSheetMarkPriceAsDeletedAction
  | RateSheetSetEditablePriceFieldsAction
  | RateSheetSetEditableNewPriceAction
  | RateSheetSetEditableMeterPriceAction
  | RateSheetRemoveDeletedMarkAction
  | RateSheetMarkAddHighlightRowAction
  | RateSheetMarkRemoveHighlightRowAction
  | RateSheetMakeCopyOfRowAction
  | RateSheetRevertAllModificationsAction
  | RateSheetUpsertWithPersistedPriceAction
  | RateSheetClearNotPersistedPricesAction;

interface RateSheetClearAllAction {
  type: "RATE_SHEET:CLEAR_ALL";
}

interface RateSheetRevertAllModificationsAction {
  type: "RATE_SHEET:REVERT_ALL_MODIFICATIONS";
}

interface RateSheetPopulateWithPersistedPricesAction {
  type: "RATE_SHEET:POPULATE_WITH_PERSISTED_PRICES";
  prices: RateSheetRouteAgreementPrice[];
}

interface RateSheetUpsertWithPersistedPriceAction {
  type: "RATE_SHEET:UPSERT_WITH_PERSISTED_PRICE";
  price: RateSheetRouteAgreementPrice;
}

interface RateSheetClearNotPersistedPricesAction {
  type: "RATE_SHEET:CLEAR_NOT_PERSISTED_PRICES";
}

interface RateSheetCreateNewPriceAction {
  type: "RATE_SHEET:CREATE_NEW_PRICE";
  price: RateSheetRouteAgreementPrice;
}

interface RateSheetSetEditableActualPriceChangeAction {
  type: "RATE_SHEET:SET_EDITABLE_ACTUAL_PRICE_CHANGE";
  routeAgreementPriceId: string;
  actual: number | null;
}

interface RateSheetSetEditablePriceFieldsAction {
  type: "RATE_SHEET:SET_EDITABLE_PRICE_FIELDS";
  routeAgreementPriceId: string;
  fields: Partial<RateSheetRouteAgreementPrice>;
}

interface RateSheetSetEditableMeterPriceAction {
  type: "RATE_SHEET:SET_EDITABLE_METER_PRICE";
  routeAgreementPriceId: string;
  meterPrice: string;
}

interface RateSheetSetEditableNewPriceAction {
  type: "RATE_SHEET:SET_EDITABLE_NEW_PRICE";
  routeAgreementPriceId: string;
  newPrice: string;
}

interface RateSheetMarkPriceAsDeletedAction {
  type: "RATE_SHEET:MARK_PRICE_AS_DELETED";
  routeAgreementPriceId: string;
}

interface RateSheetMarkAddHighlightRowAction {
  type: "RATE_SHEET:ADD_HIGHLIGHT_ROW";
  routeAgreementPriceId: string;
}

interface RateSheetMarkRemoveHighlightRowAction {
  type: "RATE_SHEET:REMOVE_HIGHLIGHT_ROW";
  routeAgreementPriceId: string;
}

interface RateSheetMakeCopyOfRowAction {
  type: "RATE_SHEET:MAKE_COPY_OF_ROW";
  routeAgreementPriceId: string;
}

interface RateSheetRemoveDeletedMarkAction {
  type: "RATE_SHEET:REMOVE_DELETED_MARK";
  routeAgreementPriceId: string;
}

export const rateSheetTableActions = {
  clearAll: (): RateSheetClearAllAction => ({ type: "RATE_SHEET:CLEAR_ALL" }),
  revertAllModifications: (): RateSheetRevertAllModificationsAction => ({
    type: "RATE_SHEET:REVERT_ALL_MODIFICATIONS",
  }),
  populateWithPersistedPrices: (
    prices: RateSheetRouteAgreementPrice[]
  ): RateSheetPopulateWithPersistedPricesAction => ({
    type: "RATE_SHEET:POPULATE_WITH_PERSISTED_PRICES",
    prices,
  }),
  upsertWithPersistedPrice: (
    price: RateSheetRouteAgreementPrice
  ): RateSheetUpsertWithPersistedPriceAction => ({
    type: "RATE_SHEET:UPSERT_WITH_PERSISTED_PRICE",
    price,
  }),
  clearNotPersistedPrices: (): RateSheetClearNotPersistedPricesAction => ({
    type: "RATE_SHEET:CLEAR_NOT_PERSISTED_PRICES",
  }),
  createNewPrice: (
    price: RateSheetRouteAgreementPrice
  ): RateSheetCreateNewPriceAction => ({
    type: "RATE_SHEET:CREATE_NEW_PRICE",
    price,
  }),
  markPriceAsDeleted: (
    routeAgreementPriceId: string
  ): RateSheetMarkPriceAsDeletedAction => ({
    type: "RATE_SHEET:MARK_PRICE_AS_DELETED",
    routeAgreementPriceId,
  }),
  setEditablePriceFields: (
    routeAgreementPriceId: string,
    fields: Partial<RateSheetRouteAgreementPrice>
  ): RateSheetSetEditablePriceFieldsAction => ({
    type: "RATE_SHEET:SET_EDITABLE_PRICE_FIELDS",
    routeAgreementPriceId,
    fields,
  }),
  setEditableActualPriceChangeAction: (
    routeAgreementPriceId: string,
    actual: number | null
  ): RateSheetSetEditableActualPriceChangeAction => ({
    type: "RATE_SHEET:SET_EDITABLE_ACTUAL_PRICE_CHANGE",
    routeAgreementPriceId,
    actual,
  }),
  setEditableMeterPriceEntity: (
    routeAgreementPriceId: string,
    meterPrice: string
  ): RateSheetSetEditableMeterPriceAction => ({
    type: "RATE_SHEET:SET_EDITABLE_METER_PRICE",
    routeAgreementPriceId,
    meterPrice,
  }),
  setEditableNewPriceEntity: (
    routeAgreementPriceId: string,
    newPrice: string
  ): RateSheetSetEditableNewPriceAction => ({
    type: "RATE_SHEET:SET_EDITABLE_NEW_PRICE",
    routeAgreementPriceId,
    newPrice,
  }),
  removeDeletedMark: (
    routeAgreementPriceId: string
  ): RateSheetRemoveDeletedMarkAction => ({
    type: "RATE_SHEET:REMOVE_DELETED_MARK",
    routeAgreementPriceId,
  }),
  addHighlightRow: (
    routeAgreementPriceId: string
  ): RateSheetMarkAddHighlightRowAction => ({
    type: "RATE_SHEET:ADD_HIGHLIGHT_ROW",
    routeAgreementPriceId,
  }),
  removeHighlightRow: (
    routeAgreementPriceId: string
  ): RateSheetMarkRemoveHighlightRowAction => ({
    type: "RATE_SHEET:REMOVE_HIGHLIGHT_ROW",
    routeAgreementPriceId,
  }),

  makeCopyOfRow: (
    routeAgreementPriceId: string
  ): RateSheetMakeCopyOfRowAction => ({
    type: "RATE_SHEET:MAKE_COPY_OF_ROW",
    routeAgreementPriceId,
  }),
};
