import React from "react";
import { BookingTableCheckbox } from "./BookingTableCheckBox";

interface Props {
  customerNo: string;
  bookingNo: number;
  disableCashCustomers: boolean;
}

export const BookingTableCheckboxWrapper: React.VFC<Props> = ({
  bookingNo,
  customerNo,
  disableCashCustomers,
}) => {
  return (
    <BookingTableCheckbox
      customerNo={customerNo}
      bookingNo={bookingNo}
      disabled={disableCashCustomers}
    />
  );
};
