import { Row } from "@stenajs-webui/core";
import * as React from "react";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useStatisticsYearSelect } from "../../../common/hooks/UseStatisticsYearSelector";
import { useTotalDetailsSummaryQuery } from "../hooks/UseTotalDetailsSummaryQuery";
import { useTotalDetailsVolumeAndRatesQuery } from "../hooks/UseTotalDetailsVolumeAndRatesQuery";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { Spinner } from "@stenajs-webui/elements";
import { StatisticsDetailsView } from "@/features/statistics/common/statistics-details-view/StatisticsDetailsView";
import { transformSelectedStatusesToNegotiationStatusCodes } from "@/features/statistics/common/transformers/NegotiationStatusFormModelTransformer";
import { NegotiationStatusItem } from "@/features/statistics/common/neg-status-chip-multi-select/NegStatusChipMultiSelect";
import { NegotiationStatusCode, TotalDetailsSummaryQuery } from "@/gql/graphql";

interface Props {}

export const StatisticsTotalDetails: React.FC<Props> = () => {
  const { year } = useStatisticsYearSelect();
  const { currency } = useCurrencySelection();

  const { summary, loading: summaryLoading } =
    useTotalDetailsSummaryQuery(year);

  return (
    <>
      <Helmet title="Total - Statistics - FPP" />
      {summaryLoading || !summary ? (
        <Row justifyContent={"center"}>
          <Spinner />
        </Row>
      ) : (
        <DetailsDataLoader currency={currency} year={year} summary={summary} />
      )}
    </>
  );
};

const DetailsDataLoader = ({
  currency,
  summary,
  year,
}: {
  currency: string;
  year: number;
  summary: TotalDetailsSummaryQuery["statistics"]["totalDetails"]["summary"];
}) => {
  const [selectedNegotiationStatuses, setSelectedNegotiationStatuses] =
    useState<Array<NegotiationStatusItem>>([]);

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(
        selectedNegotiationStatuses
      ),
    [selectedNegotiationStatuses]
  );

  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;
  const { volumeAndRates, loading, averageMeterPrices } =
    useTotalDetailsVolumeAndRatesQuery(negotiationStatusCodes, year, currency);

  return (
    <>
      <StatisticsDetailsView
        currency={currency}
        summary={summary}
        year={year}
        volumeAndRates={volumeAndRates}
        volumeAndRatesLoading={loading}
        selectedNegotiationStatuses={selectedNegotiationStatuses}
        setSelectedNegotiationStatuses={setSelectedNegotiationStatuses}
        averageMeterPrices={averageMeterPrices}
      />
    </>
  );
};
