import { isEqual } from "lodash";
import { FormModelCopyState } from "../components/RouteAgreementSaveButton";

export const formModelsAreEqual = (
  formModel: FormModelCopyState,
  otherFormModel: FormModelCopyState
): boolean => {
  return (
    isEqual(formModel.conditions, otherFormModel.conditions) &&
    isEqual(formModel.matrix, otherFormModel.matrix) &&
    isEqual(formModel.notes, otherFormModel.notes) &&
    isEqual(formModel.routes, otherFormModel.routes) &&
    isEqual(formModel.articles.articles, otherFormModel.articles.articles)
  );
};
