import { Space, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  stenaIntermodal,
} from "@stenajs-webui/elements";
import * as React from "react";
import { HeadAgreementStatusCodeChip } from "../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { InfoBoxItem } from "../InfoBoxItem";
import { MultiLegRouteAgreementBox } from "../types";
import { MultiLegRouteAgreementInfoPairPopover } from "./MultiLegRouteAgreementInfoPairPopover";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";

interface Props {
  multiLegRouteAgreement: MultiLegRouteAgreementBox;
}

export const MultiLegRouteAgreementInfoPairBox: React.FC<Props> = ({
  multiLegRouteAgreement,
}) => {
  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Multi leg route agreement"}
        leftIcon={stenaIntermodal}
        contentRight={
          <MultiLegRouteAgreementInfoPairPopover
            multiLegRouteAgreement={multiLegRouteAgreement}
            actionMenuOpen={actionMenuOpen}
            openActionMenu={openActionMenu}
            closeActionMenu={closeActionMenu}
          />
        }
      />
      <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
        <HeadAgreementStatusCodeChip
          statusCode={multiLegRouteAgreement.statusCode}
          size={"small"}
          key={"head-agreement-infobox-key-0"}
        />
        <Space num={1} />
        <InfoBoxItem
          leftContent={"Validity"}
          rightContent={formatDateStringRangeWithSwedishTimeZone({
            dateStringFrom: multiLegRouteAgreement.valid.start?.isoString,
            dateStringTo: multiLegRouteAgreement.valid.end?.isoString,
          })}
        />
        <InfoBoxItem
          leftContent={"Route"}
          rightContent={multiLegRouteAgreement.routeDescription}
          key={"head-agreement-infobox-key-2"}
        />
        <InfoBoxItem
          leftContent={"No."}
          rightContent={multiLegRouteAgreement.agreementNumber}
        />
        <InfoBoxItem
          leftContent={"Type."}
          rightContent={multiLegRouteAgreement.sailingTypeCodes
            .map((stc: string) => stc)
            .join(", ")}
          key={"head-agreement-infobox-key-1"}
        />
      </CardBody>
    </Card>
  );
};
