import { FppFilterSettings } from "@/features/search-filter/types/FppFilterSettings";

export const statisticsByAdvancedFilterSettings: FppFilterSettings = {
  customerEnabled: true,
  customerHeader: "Number/index",
  sellerEnabled: true,
  sailingCategoryEnabled: true,
  sailingCategoryHeader: "Sailing category",
  unitTypeEnabled: true,
  unitTypeHeader: "Unit type",
  averageNetPriceEnabled: true,
  averageNetPriceHeader: "Average net price/m",
  averageGrossPriceEnabled: true,
  averageGrossPriceHeader: "Average gross price/m",
  targetEnabled: true,
  rateEnabled: true,
  twelveMonthVolumeEnabled: true,
  twelveMonthVolumeHeader: "12m Vol",
  routePairEnabled: true,
  routePairHeader: "Routes",
  negotiationStatusEnabled: true,
  salesRegionEnabled: true,
  salesRegionHeader: "Sales region",
};
