export const transformSailingTypeCodeToSailingTypeName = (
  sailingTypeCode: string
): string => {
  switch (sailingTypeCode.toUpperCase()) {
    case "O":
      return "Off-Peak";
    case "S":
      return "Shoulder";
    case "P":
      return "Peak";
    default:
      return "";
  }
};

export const transformSailingTypeToSingleLetter = (
  sailingType: string
): string => {
  switch (sailingType.toUpperCase()) {
    case "OFFPEAK":
      return "O";
    case "SHOULDER":
      return "S";
    case "PEAK":
      return "P";
    default:
      return "";
  }
};
