import { useArraySet } from "@stenajs-webui/core";
import { stenaUserSingle } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { CustomerType } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

const typesItems: Array<CustomerType> = [
  {
    name: "Child",
    id: "child",
    code: "child",
  },
  { name: "Parent", code: "parent", id: "parent" },
];

interface Props {
  enabled?: boolean;
  label?: string;
}
export const CustomerTypeFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <CustomerTypeFilterSectionInternal {...props} /> : <></>;

const CustomerTypeFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { customerTypes, setCustomerTypes } = useFilterWorkspace();

  const { toggle } = useArraySet(
    customerTypes,
    setCustomerTypes,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = typesItems.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"customerTypes"}
      label={label}
      enabled={enabled}
      numSelected={customerTypes.length}
      leftIcon={stenaUserSingle}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {typesItems.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={!!customerTypes.find((type) => type.id === item.id)}
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
