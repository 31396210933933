import { Row, Text } from "@stenajs-webui/core";
import { getHighlightedPositiveNegativeTextColor } from "../../../../../common/components/table/HighlightedPositiveNegativeCell";
import { ModifiedFieldSeparator } from "../../../../../common/components/table/ModifiedFieldSeparator";
import { StoreState } from "../../../../../config/redux/RootReducer";
import * as React from "react";
import { useSelector } from "react-redux";
import { formatPercentWithSign } from "@/common/numbers";

interface Props {
  entityId: string;
  includeOldValue?: boolean;
}

export const BulkEditRatesActualChangeInPercent: React.FC<Props> = React.memo(
  ({ entityId, includeOldValue }) => {
    const persisted = useSelector((state: StoreState) => {
      return state.bulkEditRatesState.persisted[entityId];
    });
    const edited = useSelector((state: StoreState) => {
      return state.bulkEditRatesState.edited[entityId];
    });

    const isActualModified = edited?.actual !== persisted?.actual;

    if (!includeOldValue) {
      const valueToShow = isActualModified ? edited?.actual : persisted?.actual;
      return (
        <Row alignItems={"center"}>
          <Text
            color={getHighlightedPositiveNegativeTextColor(
              Number(valueToShow) ?? null
            )}
            variant={isActualModified ? "bold" : undefined}
          >
            {valueToShow == null
              ? "-"
              : formatPercentWithSign(Number(valueToShow) / 100, 2)}
          </Text>
        </Row>
      );
    }

    return (
      <Row alignItems={"center"}>
        <Text
          color={getHighlightedPositiveNegativeTextColor(
            Number(persisted?.actual) ?? null
          )}
        >
          {persisted?.actual == null
            ? "-"
            : formatPercentWithSign(Number(persisted?.actual) / 100, 2)}
        </Text>
        {isActualModified && persisted && (
          <>
            <ModifiedFieldSeparator />{" "}
            <Text
              color={getHighlightedPositiveNegativeTextColor(
                Number(edited?.actual)
              )}
              variant={"bold"}
            >
              {Number(edited?.actual) == null
                ? "-"
                : formatPercentWithSign(Number(edited?.actual) / 100, 2)}
            </Text>
          </>
        )}
      </Row>
    );
  }
);
