import {
  createColumnConfig,
  StandardTableConfigWithNoGroups,
} from "@stenajs-webui/grid";
import { negotiationStatusSumFormatter } from "../../utils/statisticsUtil";
export interface NegotiationStatusListItem {
  id: string;
  todo?: number;
  inProgress?: number;
  parked?: number;
  readyToOffer?: number;
  readyToActivate?: number;
  done?: number;
  renegotiation?: number;
}
export type NegotiationStatusListColumns = keyof Omit<
  NegotiationStatusListItem,
  "id"
>;

export const createNegotiationStatusTableConfig =
  (): StandardTableConfigWithNoGroups<
    NegotiationStatusListItem,
    NegotiationStatusListColumns
  > => ({
    keyResolver: (item) => item.id,
    disableSorting: true,
    columns: {
      todo: createColumnConfig((item) => item.todo, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        borderLeft: true,
      }),
      inProgress: createColumnConfig((item) => item.inProgress, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        minWidth: "100px",
        borderLeft: true,
      }),

      parked: createColumnConfig((item) => item.parked, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        borderLeft: true,
      }),
      readyToOffer: createColumnConfig((item) => item.readyToOffer, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        columnLabel: "Rdy. to offer",
        minWidth: "110px",
        borderLeft: true,
      }),
      readyToActivate: createColumnConfig((item) => item.readyToActivate, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        columnLabel: "Rdy. to activate",
        minWidth: "130px",
        borderLeft: true,
      }),
      done: createColumnConfig((item) => item.done, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        borderLeft: true,
      }),
      renegotiation: createColumnConfig((item) => item.renegotiation, {
        itemLabelFormatter: negotiationStatusSumFormatter,
        columnLabel: "Renegotiated",
        borderLeft: true,
      }),
    },
    columnOrder: [
      "todo",
      "inProgress",
      "parked",
      "readyToOffer",
      "readyToActivate",
      "done",
      "renegotiation",
    ],
  });
