import * as React from "react";
import { range } from "lodash";
import { cssColor } from "@stenajs-webui/theme";

export const MatrixTableBackgroundLines: React.FC = () => (
  <>
    {range(25).map((i) => (
      <div
        key={i}
        style={{
          borderLeft: `1px dotted ${cssColor("--lhds-color-ui-500")}`,
          height: "100%",
          gridColumn: `${i + 2} / span 1`,
          gridRow: `2 / span 7`,
        }}
      />
    ))}
  </>
);
