import * as React from "react";
import { EditMultiLegAgreementFormModel } from "../../common/components/edit-and-create-multi-leg-agreement-form/model/EditAndCreateMultiLegAgreementFormModel";
import { EditMultiLegAgreement } from "./EditMultiLegAgreement";
import { createEditMultiLegFormModel } from "./transformers/CreateEditMultiLegFormModel";
import { useNegotiationYears } from "../../../customers/common/negotiation-year/NegotiationYearGate";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  onRequestClose: () => void;
}

export const EditMultiLegAgreementSetup: React.FC<Props> = (props) => {
  const { multiLegAgreement } = props;
  const { defaultYear } = useNegotiationYears();

  const multiLegFormModel: EditMultiLegAgreementFormModel =
    createEditMultiLegFormModel(multiLegAgreement);

  return (
    <EditMultiLegAgreement
      {...props}
      multiLegFormModel={multiLegFormModel}
      customerId={multiLegAgreement.headAgreement.customer.id}
      headAgreementId={multiLegAgreement.headAgreement.id}
      year={defaultYear}
    />
  );
};
