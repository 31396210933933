import { SalesRegionFilterQuery } from "@/gql/graphql";
import { SalesRegion } from "../../types/FilterEntitys";
import { salesRegionFilterQuery } from "./UseFetchAllRegions";
import { useQuery } from "@apollo/client";

export const useFetchAllSalesRegions = () => {
  const { data, error, loading, refetch } = useQuery<SalesRegionFilterQuery>(
    salesRegionFilterQuery,
    {
      fetchPolicy: "cache-first",
    }
  );
  const salesRegions =
    data?.productPrice.salesRegion.all.map<SalesRegion>((r) => ({
      id: r.id,
      code: r.id,
      name: r.name,
    })) ?? [];

  return { salesRegions, error, loading, refetch };
};
