import { useNavigate } from "react-router-dom";
import { Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback, useState } from "react";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { routeFactory } from "../../../../../RouteFactory";
import { CreateMultiLegAgreementRouteForm } from "../../common/components/create-multi-leg-agreement-route-form/CreateMultiLegRouteAgreementForm";
import { CreateMultiLegAgreementRouteFormModel } from "../../common/components/create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";
import { createMultiLegAgreementRouteMutationInput } from "../../common/components/edit-and-create-multi-leg-agreement-form/edit-multi-leg-agreement-form-sections/transformers/CreateMultiLegAgreementRouteMutationInput";
import { useCreateMultiLegAgreementRoute } from "../../common/hooks/UseCreateMultilegAgreementRouteMutation";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  customerId: string;
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  multiLegFormModel: CreateMultiLegAgreementRouteFormModel;
  routeLegOptions: Array<string>;
  onRequestClose: () => void;
}

export const CreateMultiLegRouteAgreement: React.FC<Props> = ({
  multiLegFormModel,
  onRequestClose,
  routeLegOptions,
  customerId,
  multiLegAgreement,
}) => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<Error | undefined>();

  const [formModel, setFormModel] =
    useState<CreateMultiLegAgreementRouteFormModel>({
      ...multiLegFormModel,
    });

  const setFields = useCallback(
    (fields: Partial<CreateMultiLegAgreementRouteFormModel>) => {
      setFormModel({ ...formModel, ...fields });
    },
    [setFormModel, formModel]
  );

  const { createMultiLegRouteAgreementRoute, loading, error } =
    useCreateMultiLegAgreementRoute();

  const onSubmitHandler = async () => {
    setValidationError(undefined);
    try {
      const createMultiLegRouteAgreementRouteInput =
        createMultiLegAgreementRouteMutationInput(formModel);

      if (createMultiLegRouteAgreementRouteInput) {
        const { data } = await createMultiLegRouteAgreementRoute({
          variables: { createMultiLegRouteAgreementRouteInput },
        });

        const createResult =
          data?.productPrice.multiLegRouteAgreement
            .createMultiLegRouteAgreementRoute;

        if (createResult && "errors" in createResult) {
          const errorList = createResult.errors
            .map((e) => {
              return e.message;
            })
            .join("\n");
          setValidationError(new Error(errorList));
        } else if (createResult) {
          const {
            headAgreement: { id: headAgreementId },
            routeAgreement: { id: routeAgreementId },
          } = createResult;

          navigate(
            routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
              {
                customerId,
                headAgreementId,
                routeAgreementId,
                multiLegAgreementId: multiLegAgreement.id,
                tab: "rates",
              }
            ),
            {
              replace: true,
            }
          );
        }
      }
    } catch (e) {
      setValidationError(e);
    }
  };

  return (
    <Column spacing indent>
      <CreateMultiLegAgreementRouteForm
        formData={formModel}
        multiLegAgreement={multiLegAgreement}
        setFields={setFields}
        routeLegOptions={routeLegOptions}
      />
      <Spacing />
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <ValidationErrorMessage
          error={error || validationError}
          width={"260px"}
        />
        <Row alignItems={"center"}>
          <FlatButton label={"Cancel"} onClick={onRequestClose} />
          <Indent />
          <PrimaryButton
            label={"Create agreement"}
            onClick={onSubmitHandler}
            loading={loading}
          />
        </Row>
      </Row>
      <Space num={2} />
    </Column>
  );
};
