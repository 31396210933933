import { Text } from "@stenajs-webui/core";

interface Props<T> {
  persisted: T | undefined;
  editable: T;
  formatter?: (value: T) => string;
  dotToCommaTransform?: (value: string) => string;
}

export const ModifiedHighlightText = function <
  T extends string | number | boolean | null | undefined
>({ editable, persisted, formatter, dotToCommaTransform }: Props<T>) {
  let output = formatter ? formatter(editable) : editable;

  if (dotToCommaTransform) {
    output = dotToCommaTransform(String(output));
  }

  return (
    <Text variant={editable !== persisted ? "bold" : undefined}>{output}</Text>
  );
};
