import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Indent } from "@stenajs-webui/core";
import { TextInput } from "@stenajs-webui/forms";
import {
  StandardTableCellRenderer,
  UseGridCellResult,
} from "@stenajs-webui/grid";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useCallback, useState } from "react";
import { inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue } from "../../../../common/regex/InputFieldValidation";
import { EditableFields } from "../config/RateSheetTableConfig";
import { RateSheetTableRowState } from "../reducer";
import { RateSheetRouteAgreementPrice } from "../types";
import { RateSheetModifiedField } from "./RateSheetModifiedField";

export const createRateSheetTableCellRenderer =
  (
    field: EditableFields,
    originalValueResolver: (
      price: RateSheetRouteAgreementPrice | null
    ) => string,
    includeOldValue?: boolean
  ): StandardTableCellRenderer<string, RateSheetTableRowState> =>
  ({ label, item, gridCell, isEditable }) => {
    return (
      <RateSheetTableCell
        originalValue={originalValueResolver(item.persisted)}
        value={label}
        entityId={item.routeAgreementPriceId}
        gridCell={gridCell}
        isEditable={isEditable}
        field={field}
        includeOldValue={includeOldValue}
      />
    );
  };

interface RateSheetTableCellProps {
  entityId: string;
  originalValue: string;
  value: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  field: EditableFields;
  includeOldValue?: boolean;
}

export const RateSheetTableCell: React.FC<RateSheetTableCellProps> = ({
  entityId,
  originalValue,
  value,
  isEditable,
  gridCell,
  field,
  includeOldValue,
}) => {
  const [isValidInput, setIsValidInput] = useState<boolean>(true);
  const modifiedField: ModifiedFieldItemState =
    value === originalValue
      ? { id: "" }
      : { id: "", modified: true, newValue: value };

  const onDone = useCallback(() => {
    const validationResult =
      inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue.test(
        gridCell.editorValue
      );

    if (!validationResult) {
      setIsValidInput(true);
      gridCell.revertEditorValue();
      gridCell.stopEditing();
      return;
    }

    gridCell.stopEditingAndMove("right");
  }, [gridCell]);

  const validateInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult =
      inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue.test(
        e.target.value
      );
    setIsValidInput(validationResult);
  };

  return isEditable && gridCell.isEditing ? (
    <Tooltip
      label={"Please provide a valid input"}
      zIndex={ZIndex.lowest}
      variant="warning"
      disabled={isValidInput}
    >
      <Indent row alignItems={"center"}>
        <TextInput
          onValueChange={gridCell.setEditorValue}
          value={dotToCommaTransform(gridCell.editorValue)}
          onChange={(e) => validateInputOnChange(e)}
          onDone={onDone}
          onEsc={gridCell.stopEditingAndRevert}
          autoFocus
          selectAllOnMount={!gridCell.lastKeyEvent}
        />
      </Indent>
    </Tooltip>
  ) : (
    <Indent row alignItems={"center"}>
      <RateSheetModifiedField
        value={dotToCommaTransform(originalValue)}
        modifiedField={modifiedField}
        isEditable={isEditable}
        warningOnEmpty={"Empty is not allowed."}
        includeOldValue={includeOldValue}
      />
    </Indent>
  );
};
