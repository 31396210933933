import { CargoStatusCode } from "@/gql/graphql";
import { AccUnaccCode } from "../../../../../common/transformers/UnaccompaniedTransformer";

export const MAX_WEIGHT = 999_999;
export const MIN_WEIGHT = 0;
export const MAX_LENGTH = 999;
export const MIN_LENGTH = 0;

export interface AddVehicleTypeFormModel {
  vehicleTypeId?: string | null;
  lengthFrom: string;
  lengthTo: string;
  weightFrom: string;
  weightTo: string;
  cargoStatusCode: CargoStatusCode;
  unaccompanied: AccUnaccCode;
}

export const emptyAddVehicleTypeFormModel: AddVehicleTypeFormModel = {
  vehicleTypeId: null,
  lengthFrom: String(MIN_LENGTH),
  lengthTo: String(MAX_LENGTH),
  weightFrom: String(MIN_WEIGHT),
  weightTo: String(MAX_WEIGHT),
  unaccompanied: "BOTH",
  cargoStatusCode: CargoStatusCode.Both,
};
