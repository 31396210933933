import { Box, Heading, Space, Text } from "@stenajs-webui/core";
import { Spinner } from "@stenajs-webui/elements";
import * as React from "react";
import { cssColor } from "@stenajs-webui/theme";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";

interface Props {
  border?: string;
  leftValue?: number | null;
  hasPercentage?: boolean;
  rightValue?: number | null;
  heading: string;
  hasReachedTarget: boolean;
  isNotFormatted?: boolean;
  loading: boolean;
}

const convertValueToFixed = (value: number | null | undefined): string => {
  if (value && value > 0) return `+ ${dotToCommaTransform(value.toFixed(2))}`;
  if (value === null || value === undefined) return `Not set`;

  return ` ${dotToCommaTransform(value.toFixed(2))}`;
};

export const TargetSummaryStatisticsBox: React.FC<Props> = ({
  border,
  leftValue,
  hasPercentage,
  rightValue,
  heading,
  hasReachedTarget,
  isNotFormatted,
  loading,
}) => {
  return (
    <Box
      spacing={1.5}
      indent={1.5}
      border={border ?? border}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Box>
          <Text
            whiteSpace="nowrap"
            size="small"
            color={cssColor("--lhds-color-ui-600")}
          >
            {heading}
          </Text>
        </Box>
        <Space />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Heading
            whiteSpace="nowrap"
            variant="h5"
            color={cssColor("--lhds-color-ui-900")}
          >
            {isNotFormatted ? leftValue : convertValueToFixed(leftValue)}
            {hasPercentage && "%"}
          </Heading>
          <Space />
          {hasReachedTarget && (
            <ReachedTargetComponent
              reachedTarget={rightValue}
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface ReachedTargetProps {
  reachedTarget?: number | null;
  loading: boolean;
}

export const ReachedTargetComponent: React.FC<ReachedTargetProps> = ({
  reachedTarget,
  loading,
}) => {
  return (
    <Box background={cssColor("--lhds-color-ui-200")} indent={1} spacing={0.5}>
      {loading ? (
        <Spinner size="tiny" />
      ) : (
        <Text size="smaller" variant="bold" whiteSpace="nowrap">
          {reachedTarget ? convertValueToFixed(reachedTarget) : "Not set"}
        </Text>
      )}
    </Box>
  );
};
