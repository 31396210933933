import { SailingTypeCode, UnitCategoryCode } from "@/gql/graphql";
import { groupBy } from "lodash";

export const groupCustomerStatisticsBySailingCategoryCode = <
  T extends { sailingCategoryCode: SailingTypeCode }
>(
  data: T[]
) => {
  const {
    P: peak = [],
    O: offPeak = [],
    S: shoulder = [],
    M: special = [],
  } = groupBy(data, (d) => d.sailingCategoryCode);

  return { peak, offPeak, shoulder, special };
};

export const groupCustomerStatisticsByUnitCategoryCode = <
  T extends { unitCategoryCode: UnitCategoryCode }
>(
  data: T[]
) => {
  const { ACC: accompanied = [], UNACC: unaccompanied = [] } = groupBy(
    data,
    (d) => d.unitCategoryCode
  );

  return { accompanied, unaccompanied };
};
