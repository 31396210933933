import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";

import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  CreateRouteAgreementMutation,
  CreateRouteAgreementMutationVariables,
} from "@/gql/graphql";

export const mutation = gql`
  ${errorFragment}
  mutation CreateRouteAgreement($input: CreateRouteAgreementInput!) {
    productPrice {
      routeAgreement {
        createRouteAgreement(input: $input) {
          ... on CreateRouteAgreementSuccessResult {
            routeAgreement {
              id
              routes {
                id
                routePair {
                  id
                }
              }
            }
            headAgreement {
              id
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useCreateRouteAgreementMutation = () => {
  const [createRouteAgreement, { loading, error }] = useMutation<
    CreateRouteAgreementMutation,
    CreateRouteAgreementMutationVariables
  >(mutation);

  return {
    createRouteAgreement,
    loading,
    error,
  };
};
