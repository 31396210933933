import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, SeparatorLine } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import React from "react";
import { NegotiationYearSelector } from "./NegotiationYearSelector";

export interface Props {
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

export const RouteTargetsOverviewListHeader: React.FC<Props> = ({
  setYear,
}) => {
  return (
    <Box
      //FIXME: when CardHeader has the necessary props for sticky.
      top={0}
      left={0}
      zIndex={ZIndex.lowest}
      position={"sticky"}
      width={"100vw"}
      background={"var(--lhds-color-ui-50)"}
      borderRadius={"var(--swui-border-radius)"}
    >
      <CardHeader
        text={"All routes"}
        data-testid={testIdConstantsWebapp.routeTargetsOverviewNegotiationYear}
        contentRight={<NegotiationYearSelector setYear={setYear} />}
      />
      <SeparatorLine />
    </Box>
  );
};
