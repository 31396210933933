import {
  createInternalStandardTableActions,
  createStandardTableActions,
  createStandardTableInitialState,
  createStandardTableReducer,
  StandardTableAction,
  StandardTableState,
} from "@stenajs-webui/grid";
import {
  createEntityActions,
  createEntityReducer,
  createRecordObjectActions,
  createRecordObjectReducer,
  EntityAction,
  EntityState,
  RecordObjectAction,
  RecordObjectState,
  reducerIdGate,
  reducerIdGateAction,
  ReducerIdGateAction,
} from "@stenajs-webui/redux";
import { keys } from "lodash";
import { batch } from "react-redux";
import { combineReducers } from "redux";
import { wrapActionsWithReducerIdGate } from "../../../common/redux/ReducerIdGateActionWrapper";
import { AppThunk, StoreState } from "../../../config/redux/RootReducer";
import { RateSheetTableAction } from "./actions";
import { RateSheetTableColumn } from "./config/RateSheetTableConfig";
import { rateSheetTableReducer, RateSheetTableState } from "./reducer";
import { RateSheetRouteAgreement } from "./types";

export interface RateSheetState {
  table: RecordObjectState<RateSheetTableState>;
  standardTable: RecordObjectState<StandardTableState<RateSheetTableColumn>>;
  routeAgreements: RecordObjectState<
    EntityState<RateSheetRouteAgreement | undefined>
  >;
}

export const rateSheetReducer = combineReducers<RateSheetState>({
  table: reducerIdGate(
    "rateSheetTableRows",
    createRecordObjectReducer<RateSheetTableAction, RateSheetTableState>(
      rateSheetTableReducer
    )
  ),
  standardTable: reducerIdGate(
    "rateSheetStandardTable",
    createRecordObjectReducer(
      createStandardTableReducer<RateSheetTableColumn>("rateSheet")
    )
  ),
  routeAgreements: reducerIdGate(
    "rateSheetRouteAgreements",
    createRecordObjectReducer(
      createEntityReducer<RateSheetRouteAgreement | undefined>(undefined)
    )
  ),
});

export const rateSheetStandardTableWrapperActions = {
  tableRows: wrapActionsWithReducerIdGate("rateSheetTableRows", {
    ...createRecordObjectActions<RateSheetTableAction>(),
  }),
  setRouteAgreements:
    (routeAgreements: Array<RateSheetRouteAgreement>): AppThunk =>
    async (dispatch) =>
      batch(() => {
        routeAgreements.forEach((routeAgreement) => {
          dispatch(
            reducerIdGateAction(
              "rateSheetRouteAgreements",
              createRecordObjectActions<
                EntityAction<RateSheetRouteAgreement>
              >().recordAction(
                routeAgreement.id,
                createEntityActions<RateSheetRouteAgreement>().setEntity(
                  routeAgreement
                )
              )
            )
          );
        });
      }),
  clearAll: (): AppThunk => async (dispatch) =>
    batch(() => {
      dispatch(
        rateSheetStandardTableWrapperActions.tableRows.clearAllRecords()
      );
      dispatch(
        reducerIdGateAction(
          "rateSheetStandardTable",
          createRecordObjectActions().clearAllRecords()
        )
      );
    }),
  clearTableState: (): AppThunk => async (dispatch) =>
    batch(() => {
      dispatch(
        reducerIdGateAction(
          "rateSheetStandardTable",
          createRecordObjectActions().clearAllRecords()
        )
      );
    }),
  selectPriceRows: (
    routeAgreementId: string,
    routeAgreementPriceIds: Array<string>
  ) =>
    wrapRateSheetStandardTableAction(
      rateSheetStandardTableActions.setSelectedIds(routeAgreementPriceIds),
      routeAgreementId
    ),
  checkAllPriceRows: (): AppThunk => async (dispatch, getState) => {
    const tableState = getState().rateSheet.table;
    const routeAgreementIds = keys(tableState);

    routeAgreementIds.forEach((routeAgreementId) => {
      const routeAgreementPriceIds = tableState[routeAgreementId].rows.map(
        (r) => r.routeAgreementPriceId
      );
      dispatch(
        wrapRateSheetStandardTableAction(
          rateSheetStandardTableActions.setSelectedIds(routeAgreementPriceIds),
          routeAgreementId
        )
      );
    });
  },
  clearAllCheckboxes: (): AppThunk => async (dispatch, getState) => {
    const tableState = getState().rateSheet.table;
    const routeAgreementIds = keys(tableState);
    await Promise.all(
      routeAgreementIds.map(
        async (routeAgreementId) =>
          await dispatch(
            wrapRateSheetStandardTableAction(
              rateSheetStandardTableActions.clearSelection(),
              routeAgreementId
            )
          )
      )
    );
  },
};

export const rateSheetStandardTableActions = createStandardTableActions(
  "rateSheet",
  createInternalStandardTableActions<RateSheetTableColumn>()
);

export const rateSheetStandardTableInitialState =
  createStandardTableInitialState<RateSheetTableColumn>();

export const rateSheetStandardTableSelectors = {
  getState: (
    state: StoreState
  ): RecordObjectState<StandardTableState<RateSheetTableColumn>> =>
    state.rateSheet.standardTable,
  getTableState: (
    state: StoreState,
    tableId: string
  ): StandardTableState<RateSheetTableColumn> =>
    state.rateSheet.standardTable[tableId] ??
    rateSheetStandardTableInitialState,
  allPriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const rateSheetRows = Object.values(tableState);
    const rateSheetRowsLength = rateSheetRows.flatMap((t) => t.rows.length);
    const totalRowsLength = rateSheetRowsLength.reduce((acc, curValue) => {
      return acc + curValue;
    }, 0);
    const selectedIdsLength = Object.values(
      state.rateSheet.standardTable
    ).flatMap((table) => table.selectedIds.selectedIds).length;

    const routeAgreementIds = keys(tableState);
    const containsSomeWithNotAllSelected = routeAgreementIds
      .map((routeAgreementId) => {
        const tableStateForRa = state.rateSheet.standardTable[routeAgreementId];
        if (!tableStateForRa) {
          return false;
        }

        return (
          tableStateForRa &&
          selectedIdsLength >= 1 &&
          selectedIdsLength === totalRowsLength
        );
      })
      .every((r) => r);
    return containsSomeWithNotAllSelected;
  },
  noPriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const noSelectedRow = routeAgreementIds
      .map((routeAgreementId) => {
        const tableStateForRa = state.rateSheet.standardTable[routeAgreementId];
        if (!tableStateForRa) {
          return true;
        }
        if (
          tableStateForRa &&
          tableStateForRa.selectedIds.selectedIds.length === 0
        ) {
          return true;
        }
        return false;
      })
      .every((r) => r);
    return noSelectedRow;
  },
  accompaniedPriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const accompaniedPriceRows = routeAgreementIds
      .map((routeAgreementId) => {
        const standardTableStateForRa =
          state.rateSheet.standardTable[routeAgreementId];
        if (!standardTableStateForRa) {
          return false;
        }
        const accompaniedIds = Object.values(tableState).flatMap((table) =>
          table.rows
            .filter((r) => r.editable.vehicleType?.accompanied === true)
            .flatMap((b) => b.routeAgreementPriceId)
        );
        const selectedIds = Object.values(
          state.rateSheet.standardTable
        ).flatMap((table) => table.selectedIds.selectedIds);

        return (
          standardTableStateForRa &&
          accompaniedIds.length >= 1 &&
          accompaniedIds.length === selectedIds.length &&
          JSON.stringify(accompaniedIds) === JSON.stringify(selectedIds)
        );
      })
      .some((r) => r);
    return accompaniedPriceRows;
  },
  unAccompaniedPriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const unAccompaniedPriceRows = routeAgreementIds
      .map((routeAgreementId) => {
        const standardTableStateForRa =
          state.rateSheet.standardTable[routeAgreementId];
        if (!standardTableStateForRa) {
          return false;
        }
        const unAccompaniedIds = Object.values(tableState).flatMap((table) =>
          table.rows
            .filter((r) => r.editable.vehicleType?.accompanied === false)
            .flatMap((b) => b.routeAgreementPriceId)
        );
        const selectedIds = Object.values(
          state.rateSheet.standardTable
        ).flatMap((table) => table.selectedIds.selectedIds);
        return (
          standardTableStateForRa &&
          unAccompaniedIds.length >= 1 &&
          unAccompaniedIds.length === selectedIds.length &&
          JSON.stringify(unAccompaniedIds) === JSON.stringify(selectedIds)
        );
      })
      .some((r) => r);
    return unAccompaniedPriceRows;
  },
  vehicleTypeAllPriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const typeAllPriceRows = routeAgreementIds
      .map((routeAgreementId) => {
        const standardTableStateForRa =
          state.rateSheet.standardTable[routeAgreementId];
        if (!standardTableStateForRa) {
          return false;
        }
        const typAllIds = Object.values(tableState).flatMap((table) =>
          table.rows
            .filter((r) => r.editable.vehicleType === null)
            .flatMap((b) => b.routeAgreementPriceId)
        );
        const selectedIds = Object.values(
          state.rateSheet.standardTable
        ).flatMap((table) => table.selectedIds.selectedIds);

        return (
          standardTableStateForRa &&
          typAllIds.length >= 1 &&
          typAllIds.length === selectedIds.length &&
          JSON.stringify(typAllIds) === JSON.stringify(selectedIds)
        );
      })
      .some((r) => r);
    return typeAllPriceRows;
  },
  somePriceRowsAreSelected: (state: StoreState): boolean => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const numSelectedPerRa = routeAgreementIds.map((routeAgreementId) => {
      const tableStateForRa = state.rateSheet.standardTable[routeAgreementId];
      if (!tableStateForRa) {
        return 0;
      }
      return tableStateForRa.selectedIds.selectedIds.length;
    });
    return numSelectedPerRa.some((r) => r > 0);
  },
  numPriceRowsSelected: (state: StoreState): number => {
    const tableState = state.rateSheet.table;
    const routeAgreementIds = keys(tableState);
    const numSelectedPerRa = routeAgreementIds.map((routeAgreementId) => {
      const tableStateForRa = state.rateSheet.standardTable[routeAgreementId];
      if (!tableStateForRa) {
        return 0;
      }
      return tableStateForRa.selectedIds.selectedIds.length;
    });
    return numSelectedPerRa.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
  },
};

export const wrapRateSheetStandardTableAction = (
  action: StandardTableAction<RateSheetTableColumn>,
  routeAgreementId: string
): ReducerIdGateAction<
  RecordObjectAction<StandardTableAction<RateSheetTableColumn>>
> =>
  reducerIdGateAction(
    "rateSheetStandardTable",
    createRecordObjectActions<
      StandardTableAction<RateSheetTableColumn>
    >().recordAction(routeAgreementId, action)
  );
