import { RouteAgreementForForm } from "../../types";
import {
  EditRouteAgreementFormModel,
  emptyEditRouteAgreementFormModel,
} from "../../../common/components/models/EditRouteAgreementFormModel";
import { SailingTypeCode } from "@/gql/graphql";

export type RoutesFormModel = EditRouteAgreementFormModel;
export const emptyRoutesFormModel = emptyEditRouteAgreementFormModel;

interface SailingTypeCodeEntity {
  offPeak: boolean;
  peak: boolean;
  shoulder: boolean;
  special: boolean;
}

export const transformSailingTypeCodeToEntity = (
  sailingTypeCodes: SailingTypeCode[]
): SailingTypeCodeEntity => ({
  offPeak: sailingTypeCodes.includes(SailingTypeCode.O),
  peak: sailingTypeCodes.includes(SailingTypeCode.P),
  shoulder: sailingTypeCodes.includes(SailingTypeCode.S),
  special: sailingTypeCodes.includes(SailingTypeCode.M),
});

export const transformRouteAgreementToRoutesForm = (
  routeAgreement: Pick<
    RouteAgreementForForm,
    | "name"
    | "currency"
    | "routes"
    | "externalDescription"
    | "isSalesReady"
    | "typeCode"
    | "valid"
    | "sailingTypeCodes"
  >
): EditRouteAgreementFormModel => {
  return {
    name: routeAgreement.name,
    currencyId: routeAgreement.currency.id,
    routeIds: routeAgreement.routes.map((r) => r.id),
    externalDescription: routeAgreement.externalDescription ?? "",
    isSalesReady: routeAgreement.isSalesReady,
    type: routeAgreement.typeCode,
    valid: {
      startDate: routeAgreement.valid.start?.isoString ?? "",
      endDate: routeAgreement.valid.end?.isoString ?? "",
    },
    includePartnerRoutes: routeAgreement.routes.find((r) => r.routeType === "P")
      ? true
      : false,
    ...transformSailingTypeCodeToEntity(routeAgreement.sailingTypeCodes),
  };
};
