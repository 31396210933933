import { GraphQLError } from "graphql";
import { combineReducers } from "redux";
import {
  createEntityReducer,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProfileState {
  globalState: EntityState<GlobalState>;
}

export interface GlobalState {
  id: string;
  userName: string;
  fullName: string;
  claims: string[];
  currentCurrency: string;
  currentYear: number;
  fetchErrors?: readonly GraphQLError[] | undefined;
}

export enum Currencies {
  EUR = "EUR",
  NOK = "NOK",
  SEK = "SEK",
  GBP = "GBP",
  DKK = "DKK",
  PLN = "PLN",
}

export const initialProfileState: GlobalState = {
  id: "",
  userName: "",
  fullName: "",
  claims: [],
  currentYear: new Date().getUTCFullYear(),
  currentCurrency: Currencies.EUR,
  fetchErrors: undefined,
};

export const profileReducerId = "profileReducerId";

export const profileReducer = reducerIdGate(
  profileReducerId,
  combineReducers({
    globalState: createEntityReducer<GlobalState>(initialProfileState),
  })
);

interface SetFilterSearchPayload {
  value: boolean;
}

export interface FilterRenderState {
  filterSearchedApplied: boolean;
}

export const filterSearchReducerId = "initialFilterSearchReducerId";

export const filterRenderSlice = createSlice({
  name: filterSearchReducerId,
  initialState: { filterSearchedApplied: false },
  reducers: {
    setFilterSearch(
      state,
      { payload: { value } }: PayloadAction<SetFilterSearchPayload>
    ) {
      return {
        filterSearchedApplied: value,
      };
    },
  },
});

export const { setFilterSearch } = filterRenderSlice.actions;

export const filterRenderReducer = filterRenderSlice.reducer;
