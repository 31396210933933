import { AppThunk } from "../../../../../config/redux/RootReducer";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import {
  routeAgreementDetailsOriginalActions,
  routeAgreementDetailsProgressActions,
  routeAgreementDetailsRouteAgreementActions,
} from "../redux";
import { rateSheetStandardTableWrapperActions } from "../../../rate-sheet/redux";
import { rateSheetTableActions } from "../../../rate-sheet/actions";
import { cleanRatePrice } from "../../../rate-sheet/util/PriceCleaner";
import { transformRouteAgreementToForm } from "../transformer/RouteAgreementToFormTransformer";

import { conditionsFormRedux } from "../features/conditions/ConditionsFormRedux";
import { routesFormRedux } from "../features/routes/RoutesFormRedux";
import { notesFormRedux } from "../features/notes/NotesFormRedux";
import { articleFormActions } from "../features/articles/actions";
import { matrixFormRedux } from "../features/matrix/MatrixFormRedux";
import { batch } from "react-redux";
import {
  RouteAgreementDetailsStatePopulatorQuery,
  RouteAgreementDetailsStatePopulatorQueryVariables,
} from "@/gql/graphql";
import { rateSheetTablePricesFragment } from "../fragments/RouteAgreementPageFragment";

const query = gql`
  ${rateSheetTablePricesFragment}

  fragment ArticlePrices on ArticlePrice {
    id
    vehicleType {
      id
      name
    }
    price {
      amount
    }
    percent
    lengthFrom
    lengthTo
    weightFrom
    weightTo
  }
  fragment ArticleValidities on ArticlePriceValidity {
    id
    agrRouteArticlePriceId
    percentage
    price
    validFrom
  }
  fragment RouteAgreementMatrices on RouteAgreementMatrix {
    id
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    matrix {
      id
      keywords
      validityKey
      timeslots {
        weekDay
        timeFrom
        timeTo
      }
    }
  }
  fragment RouteAgreementArticles on RouteAgreementArticle {
    id
    articleType {
      id
      code
    }
    maxPrice
    maxNumberOf
    includedInPrice
    prices {
      ...ArticlePrices
    }
    validities {
      ...ArticleValidities
    }
    formulaType {
      id
      name
    }
    formulaData {
      drivers
      interval1PercentValue
      interval2PercentValue
      interval3PercentValue
      interval4PercentValue
      intervalAPercentValue
      intervalBPercentValue
      intervalCPercentValue
      intervalDPercentValue
      intervalLength
      maxPercent
      percent
      weight
      width
    }
  }
  fragment RouteAgreementDetailsState on RouteAgreement {
    id
    name
    typeCode
    statusCode
    agreementNumber
    rowVersion
    transit
    standby
    sailingTypeCodes
    externalDescription
    isSalesReady
    routeDescription
    note
    comment
    externalComment
    multiLegAgrRouteId
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
    prices {
      id
      ...RateSheetTablePrices
    }
    routes {
      id
      code
      routeType
    }
    senderCountries {
      id
    }
    receiverCountries {
      id
    }
    goods {
      id
    }
    matrices {
      ...RouteAgreementMatrices
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    currency {
      id
      code
    }
    articles {
      ...RouteAgreementArticles
    }
    headAgreement {
      headAgreementNumber
      id
      name
      breadCrumbName
      valid {
        start {
          isoString
        }
      }
    }
  }
  query RouteAgreementDetailsStatePopulator($routeAgreementId: ID!) {
    productPrice {
      routeAgreement {
        byId(id: $routeAgreementId) {
          ...RouteAgreementDetailsState
        }
      }
    }
  }
`;

const setProgress = routeAgreementDetailsProgressActions.setEntity;

export const populateEditRouteAgreementState =
  (routeAgreementId: string, disableProgressFlag?: boolean): AppThunk =>
  async (dispatch) => {
    /**
     * Clean state
     */
    if (!disableProgressFlag) {
      batch(() => {
        dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            routeAgreementId,
            rateSheetTableActions.clearAll()
          )
        );

        dispatch(rateSheetStandardTableWrapperActions.clearAll());

        dispatch(routeAgreementDetailsRouteAgreementActions.setEntity(null));

        dispatch(
          setProgress({
            error: undefined,
            loading: true,
          })
        );
      });
    }

    /**
     * Fetch route agreement and prices
     */
    let r;
    try {
      r = await apolloClient.query<
        RouteAgreementDetailsStatePopulatorQuery,
        RouteAgreementDetailsStatePopulatorQueryVariables
      >({
        query,
        variables: { routeAgreementId },
        fetchPolicy: "network-only",
      });
    } catch (e) {
      dispatch(
        setProgress({
          error: e,
          loading: false,
        })
      );
      return;
    }

    if (r.errors && r.errors.length) {
      dispatch(
        setProgress({
          error: r.errors[0],
          loading: false,
        })
      );
      return;
    }

    dispatch(
      setProgress({
        error: undefined,
        loading: false,
      })
    );

    const routeAgreement = r.data.productPrice.routeAgreement.byId;

    if (!routeAgreement) {
      dispatch(
        setProgress({
          error: new Error("No such route agreement"),
          loading: false,
        })
      );
      return;
    }

    /**
     * Update state
     */

    batch(() => {
      dispatch(
        routeAgreementDetailsRouteAgreementActions.setEntity(routeAgreement)
      );

      const prices = routeAgreement?.prices ?? [];

      dispatch(
        rateSheetStandardTableWrapperActions.setRouteAgreements([
          routeAgreement,
        ])
      );

      dispatch(
        rateSheetStandardTableWrapperActions.tableRows.recordAction(
          routeAgreementId,
          rateSheetTableActions.populateWithPersistedPrices(
            prices.map(cleanRatePrice)
          )
        )
      );

      const formModel = transformRouteAgreementToForm(routeAgreement);

      dispatch(routeAgreementDetailsOriginalActions.setEntity(formModel));
      dispatch(conditionsFormRedux.actions.setEntity(formModel.conditions));
      dispatch(routesFormRedux.actions.setEntity(formModel.routes));
      dispatch(notesFormRedux.actions.setEntity(formModel.notes));
      dispatch(articleFormActions.setFormModel(formModel.articles));
      dispatch(matrixFormRedux.actions.setEntity(formModel.matrix));
    });
  };
