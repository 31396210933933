import {
  StandardTableCellRenderer,
  UseGridCellResult,
} from "@stenajs-webui/grid";
import { ExpectedVolumeTableRow } from "../types";
import * as React from "react";
import { useCallback } from "react";
import {
  EditableEntityState,
  ModifiedFieldItemState,
} from "@stenajs-webui/redux";
import { TextInput } from "@stenajs-webui/forms";
import { Indent } from "@stenajs-webui/core";
import { ExpectedVolumeColumns } from "../config/ExpectedVolumeTableConfig";
import { useDispatch } from "react-redux";
import { expectedVolumeActions } from "../redux/ExpectedVolumeRedux";
import { parseFloatElseUndefined } from "@/common/numbers";
import { RateSheetModifiedField } from "../../rate-sheet/components/RateSheetModifiedField";

export const createEditableCellRenderer =
  (
    field: ExpectedVolumeColumns,
    routePairId: string
  ): StandardTableCellRenderer<
    string,
    EditableEntityState<ExpectedVolumeTableRow>
  > =>
  ({ label, item, gridCell, isEditable, value }) => {
    const originalValue = item.persisted[field];
    const editableValue = item.editable[field];
    return (
      <EditableTableCell
        persistedValue={String(originalValue ?? "")}
        editableValue={String(editableValue ?? "")}
        gridCell={gridCell}
        isEditable={isEditable}
        field={field}
        routePairId={routePairId}
        volumeId={item.persisted.id}
      />
    );
  };

interface EditableTableCellProps {
  persistedValue: string;
  editableValue: string;
  field: ExpectedVolumeColumns;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  routePairId: string;
  volumeId: string;
}

export const EditableTableCell: React.FC<EditableTableCellProps> = ({
  persistedValue,
  editableValue,
  field,
  isEditable,
  gridCell,
  routePairId,
  volumeId,
}) => {
  const dispatch = useDispatch();

  const modifiedField: ModifiedFieldItemState =
    editableValue === persistedValue
      ? { id: "" }
      : { id: "", modified: true, newValue: editableValue || "-" };

  const onDone = useCallback(() => {
    dispatch(
      expectedVolumeActions.setFieldsForRow(routePairId, volumeId, {
        [field]: parseFloatElseUndefined(gridCell.editorValue),
      })
    );
    gridCell.stopEditingAndMove("right");
  }, [dispatch, field, routePairId, volumeId, gridCell]);
  return isEditable && gridCell.isEditing ? (
    <TextInput
      onValueChange={gridCell.setEditorValue}
      value={gridCell.editorValue}
      onDone={onDone}
      onEsc={gridCell.stopEditingAndRevert}
      autoFocus
      selectAllOnMount={!gridCell.lastKeyEvent}
    />
  ) : (
    <Indent row alignItems={"center"}>
      <RateSheetModifiedField
        value={persistedValue || "-"}
        modifiedField={modifiedField}
        isEditable={isEditable}
      />
    </Indent>
  );
};
