import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { isDefined } from "@/common/types/IsDefined";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  Box,
  Indent,
  Row,
  SeparatorLine,
  Space,
  Text,
} from "@stenajs-webui/core";
import {
  Banner,
  FlatButton,
  PrimaryButton,
  Spinner,
  stenaCheck,
} from "@stenajs-webui/elements";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { TableContext } from "@stenajs-webui/grid";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileSelectors } from "../../../../../common/redux/profile/selectors";
import { gtmTransmitter } from "../../../../../common/utils/GtmHelper";
import { RouteTargetBulkEdit } from "../../../../../common/utils/route-target-gtm/RouteTargetBulkEditGtmHelper";
import { RouteTargetTableEvents } from "../../../../../common/utils/route-target-gtm/RouterTargetTableGtmHelper";
import { useBulkUpdateLogicHandler } from "./hooks/UseBulkUpdateLogicHandler";
import { routeCustomerTargetActions } from "./redux/actions";
import { RoutePairCustomerTargetSortedColumnState } from "./redux/reducers";
import { routeCustomerTargetSelectors } from "./redux/selectors";

export interface Props {
  countSelected: number;
  selectedRowIds: Array<string>;
  tableContext: TableContext<RoutePairCustomerTargetSortedColumnState>;
}

export interface SelectedState {
  grossSelected: boolean;
  targetSelected: boolean;
}

interface FieldValues {
  targetValue: string;
  grossValue: string;
}

const initialFormState: FieldValues = {
  targetValue: "",
  grossValue: "",
};

export interface WarningState {
  displayRowsWithoutGrossPriceWarning: boolean;
  displayAlreadyEditedWarning: boolean;
  numberOfAlreadyEdited: number;
}

export const RoutePairCustomerTargetBulkEditing: React.FC<Props> = ({
  countSelected,
  selectedRowIds,
  tableContext,
}) => {
  const { currentCurrency } = useSelector(profileSelectors.getGlobal);
  const [isApplyingChange, setIsApplyingChange] = useState<boolean>(false);

  const routeCustomerTargetDataRecord = useSelector(
    routeCustomerTargetSelectors.getEditedCustomerTargetTableRows
  );

  const routeCustomerTargetData = Object.values(
    routeCustomerTargetDataRecord
  ).filter(isDefined);
  const { bulkShouldShowWarning, bulkUpdate, bulkUpdateAfterConfirmation } =
    useBulkUpdateLogicHandler({ tableContext });
  const [warningState, setWarningState] = useState<WarningState>({
    displayRowsWithoutGrossPriceWarning: false,
    displayAlreadyEditedWarning: false,
    numberOfAlreadyEdited: 0,
  });

  const [checkBoxesSelected, setCheckBoxesSelected] = useState<SelectedState>({
    grossSelected: false,
    targetSelected: true,
  });

  const [formData, setFormData] = useState(initialFormState);

  const shouldRenderSelectedHeader =
    !warningState.displayRowsWithoutGrossPriceWarning &&
    !warningState.displayAlreadyEditedWarning;

  const dispatch = useDispatch();

  const grossSelected = () => {
    gtmTransmitter({
      event: RouteTargetBulkEdit.BulkEditEvent,
      actionEvent: RouteTargetBulkEdit.SelectMethodAction,
      labelEvent: RouteTargetBulkEdit.GrossPrice,
    });
    setWarningState((prevState) => ({
      ...prevState,
      isValidGrossInput: true,
      isValidTargetInput: true,
    }));

    setFormData(initialFormState);

    setCheckBoxesSelected({
      grossSelected: true,
      targetSelected: false,
    });
  };

  const targetSelected = () => {
    gtmTransmitter({
      event: RouteTargetBulkEdit.BulkEditEvent,
      actionEvent: RouteTargetBulkEdit.SelectMethodAction,
      labelEvent: RouteTargetBulkEdit.SetTarget,
    });
    setWarningState((prevState) => ({
      ...prevState,
      isValidGrossInput: true,
      isValidTargetInput: true,
    }));

    setWarningState((prevState) => ({
      ...prevState,
      displayRowsWithoutGrossPriceWarning: false,
    }));

    setFormData(initialFormState);

    setCheckBoxesSelected({
      grossSelected: false,
      targetSelected: true,
    });
  };

  const dismissAlreadyEditedWarning = () => {
    setWarningState((prevState) => ({
      ...prevState,
      displayAlreadyEditedWarning: false,
    }));
  };

  const tableReset = () => {
    gtmTransmitter({
      event: RouteTargetBulkEdit.BulkEditEvent,
      actionEvent: RouteTargetBulkEdit.DismissAction,
    });
    tableContext.dispatch(tableContext.actions.clearSelection());
    dispatch(
      routeCustomerTargetActions.setRouteCustomerTargetState({
        isWarningVisible: false,
      })
    );
  };

  useEffect(() => {
    const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
      return selectedRowIds.includes(rctd.id ?? "");
    });

    if (selectedRows.every((sr) => !!sr.gross)) {
      setWarningState((prevState) => ({
        ...prevState,
        displayRowsWithoutGrossPriceWarning: false,
      }));
      return;
    }
  }, [selectedRowIds, routeCustomerTargetData]);

  useEffect(() => {
    if (warningState.displayRowsWithoutGrossPriceWarning) {
      gtmTransmitter({
        event: RouteTargetBulkEdit.BulkEditEvent,
        actionEvent: RouteTargetBulkEdit.GrossPriceWarning,
      });
    }
    if (
      warningState.displayRowsWithoutGrossPriceWarning ||
      warningState.displayAlreadyEditedWarning
    ) {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          isWarningVisible: true,
        })
      );
    } else {
      dispatch(
        routeCustomerTargetActions.setRouteCustomerTargetState({
          isWarningVisible: false,
        })
      );
    }
  }, [dispatch, warningState]);

  const applyBulkEditChange = () => {
    const showWarning: boolean = bulkShouldShowWarning({
      setWarningState,
      selectedRowIds,
      checkBoxesSelected,
      routeCustomerTargetData,
    });

    gtmTransmitter({
      event: RouteTargetBulkEdit.BulkEditEvent,
      actionEvent: RouteTargetBulkEdit.ClickedApplyAction,
    });
    gtmTransmitter({
      event: RouteTargetTableEvents.clickEvent,
      actionEvent: RouteTargetTableEvents.editTarget,
    });

    if (!showWarning) {
      setIsApplyingChange(true);
      const timeWait = setTimeout(() => {
        bulkUpdate({
          setWarningState,
          formData,
          selectedRowIds,
          checkBoxesSelected,
          routeCustomerTargetData,
          setFormData,
        });
        setIsApplyingChange(false);
      }, 1);
      return () => clearTimeout(timeWait);
    }
  };

  const applyBulkEditChangeAfterWarning = () => {
    gtmTransmitter({
      event: RouteTargetBulkEdit.BulkEditEvent,
      actionEvent: RouteTargetBulkEdit.Overwrite,
      labelEvent: RouteTargetBulkEdit.YES,
    });

    setIsApplyingChange(true);
    const timeWait = setTimeout(() => {
      bulkUpdateAfterConfirmation({
        setWarningState,
        formData,
        selectedRowIds,
        checkBoxesSelected,
        routeCustomerTargetData,
        setFormData,
      });
      setIsApplyingChange(false);
    }, 1);
    return () => clearTimeout(timeWait);
  };

  const renderGrossPrice = (
    <Row display="flex" alignItems="center">
      <RadioButtonWithLabel
        checked={checkBoxesSelected.grossSelected}
        label="Change gross price by"
        textColor={cssColor("--lhds-color-ui-700")}
        onChange={grossSelected}
      />
      <Space num={SpaceValues.TWELVE} />
      <Box width={"120px"}>
        <NumericTextInput
          disabled={!checkBoxesSelected.grossSelected}
          value={formData.grossValue}
          name="grossValue"
          onValueChange={(value) =>
            setFormData({ ...formData, grossValue: value })
          }
          useNegativeValues
          maxNumbers={3}
          maxDecimals={2}
          contentRight={
            <Box
              display="flex"
              flexDirection="row"
              width="70px"
              justifyContent="center"
              alignItems="center"
            >
              <SeparatorLine size="30px" vertical={true} />
              <Text
                style={{ marginLeft: "8px" }}
                color={cssColor("--lhds-color-ui-500")}
              >
                {currentCurrency}
              </Text>
            </Box>
          }
          size={2}
        />
      </Box>
    </Row>
  );

  const renderTargetSelected = (
    <Row alignItems="center">
      <RadioButtonWithLabel
        checked={checkBoxesSelected.targetSelected}
        label="Set target to"
        textColor={cssColor("--lhds-color-ui-700")}
        onChange={targetSelected}
      />
      <Space num={SpaceValues.TWELVE} />
      <Box width={"120px"}>
        <NumericTextInput
          disabled={!checkBoxesSelected.targetSelected}
          value={formData.targetValue}
          name="targetValue"
          onValueChange={(value) =>
            setFormData({ ...formData, targetValue: value })
          }
          useNegativeValues
          maxNumbers={3}
          maxDecimals={2}
          contentRight={
            <Box
              display="flex"
              flexDirection="row"
              width="70px"
              justifyContent="center"
              alignItems="center"
            >
              <SeparatorLine size="30px" vertical={true} />
              <Text
                style={{ marginLeft: "12.5px" }}
                color={cssColor("--lhds-color-ui-500")}
              >
                %
              </Text>
            </Box>
          }
          size={2}
        />
      </Box>
    </Row>
  );

  const renderSelectedHeader = (
    <Row>
      {renderGrossPrice}
      <Space num={SpaceValues.TWENTYFOUR} />
      {renderTargetSelected}
      <Space num={SpaceValues.TWENTYFOUR} />
      <PrimaryButton
        left={
          isApplyingChange && (
            <Spinner size={"tiny"} color={cssColor("--lhds-color-ui-500")} />
          )
        }
        leftIcon={isApplyingChange ? stenaCheck : undefined}
        label={isApplyingChange ? "Applying" : "Apply"}
        disabled={isApplyingChange}
        onClick={applyBulkEditChange}
      />
    </Row>
  );

  const renderWarnings = (
    <Box
      display="flex"
      width={"100%"}
      borderTop='1px solid var("--lhds-color-orange-300)'
    >
      {warningState.displayRowsWithoutGrossPriceWarning && (
        <Banner
          text="Target can not be calculated. One or more selected rows have no gross price. Please deselect rows with no gross price or change method for applying targets."
          variant="warning"
        />
      )}
      {warningState.displayAlreadyEditedWarning && (
        <Banner
          text={`Already set targets will be affected. ${warningState.numberOfAlreadyEdited} rows already have targets set. Would you like to overwrite them?`}
          variant="warning"
          contentRight={
            <Box display="flex" flexDirection="row">
              <FlatButton label="No" onClick={dismissAlreadyEditedWarning} />
              <Space num={2} />
              <PrimaryButton
                label="Yes"
                onClick={applyBulkEditChangeAfterWarning}
              />
            </Box>
          }
        />
      )}
      <Indent indent={IndentValues.TWENTYFOUR} />
    </Box>
  );

  return (
    <>
      <Row display={"grid"}>
        <SelectedItemsActionsPanel
          numItemsSelected={countSelected}
          afterLabelContent={
            <Row display="flex" alignItems="center">
              <Row maxHeight={"32px"}>
                <FlatButton label="Clear all" onClick={tableReset} />
              </Row>
            </Row>
          }
          rightContent={
            <>{shouldRenderSelectedHeader ? renderSelectedHeader : undefined}</>
          }
        />
      </Row>
      {!shouldRenderSelectedHeader && renderWarnings}
    </>
  );
};
