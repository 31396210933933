import { gql, useQuery } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo } from "react";
import { NegotiationYearSelectQuery } from "@/gql/graphql";

export interface NegotiationYearSelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: number;
  onValueChange?: (value: number) => void;
  additionalLoading?: boolean;
}

const query = gql`
  query NegotiationYearSelect {
    productPrice {
      negotiationYear {
        availableYears {
          id
          year
        }
      }
    }
  }
`;

export const NegotiationYearSelect: React.FC<NegotiationYearSelectProps> = ({
  onValueChange,
  value,
  additionalLoading,
  ...selectProps
}) => {
  const { data, loading } = useQuery<NegotiationYearSelectQuery>(query);

  const years = data?.productPrice.negotiationYear.availableYears;

  const options = useMemo(() => {
    return (years ?? []).map((year) => ({
      label: year.id,
      value: year.year,
    }));
  }, [years]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base: CSSObject) => ({
          ...base,
          zIndex: ZIndex.highest,
        }),
      }}
      {...selectProps}
      isLoading={loading || additionalLoading}
      options={options}
      value={selected}
      onChange={(item: any) => onValueChange && onValueChange(item.value)}
    />
  );
};
