import * as React from "react";
import { useEffect } from "react";
import { Indent, Row, Spacing } from "@stenajs-webui/core";
import { DateTextInput } from "@stenajs-webui/calendar";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { RouteSelect } from "../../../../../common/components/data-driven-inputs/selects/route-select/RouteSelect";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import FormGroup from "../../../../../common/forms/FormGroup";
import { InputFieldSetWidthLabeled } from "../../PriceCalculatorFormPanel";
import { PriceCalculatorDepartureTimeInput } from "./PriceCalculatorDepartureTimeInput";
import { PriceCalculatorDepartureCategorySelect } from "./PriceCalculatorDepartureCategorySelect";
import {
  enterManuallyDefaultOption,
  SailingCategoryTimeTableSelect,
} from "../../../../../common/components/data-driven-inputs/selects/sailing-category-departure-select/SailingCategoryTimeTableSelect";
import { useSailingsByRouteCodeDepartureDate } from "./hooks/UseSailingsByRouteCodeDepartureDate";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  formData: PriceCalculatorFormData;
  isSectional?: boolean;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorSailingDetails: React.FC<Props> = ({
  formData,
  isSectional,
  onChangeFormData,
}) => {
  const { sailingDeparturesLoading, sailings, fetchSailings } =
    useSailingsByRouteCodeDepartureDate();

  useEffect(() => {
    if (formData.routeCode && formData.sailingDate)
      fetchSailings({
        variables: {
          routeCode: formData.routeCode,
          departureDate: formData.sailingDate,
        },
      });
  }, [formData.routeCode, formData.sailingDate, fetchSailings]);

  return (
    <FormGroup title={"Sailing details"}>
      <Row>
        <InputFieldSetWidthLabeled
          minWidth={90}
          width={200}
          label={"Route"}
          data-testid={
            testIdConstantsWebapp.priceCalculatorSectionalRouteSelect
          }
        >
          <RouteSelect
            labelFormat={(route) => route.code}
            value={formData.routeCode}
            onValueChange={(value) => {
              onChangeFormData("routeCode", value);
              onChangeFormData("departureTime", "");
            }}
            menuPortalTarget={document.body}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />

        <InputFieldSetWidthLabeled minWidth={120} label={"Date"}>
          <DateTextInput
            value={formData.sailingDate}
            onValueChange={(value) => {
              onChangeFormData("sailingDate", value);
            }}
            portalTarget={document.body}
            calendarProps={{
              highlightToday: true,
            }}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />
        {isSectional ? (
          <InputFieldSetWidthLabeled
            minWidth={105}
            width={185}
            label={"Timetable"}
            data-testid={
              testIdConstantsWebapp.priceCalculatorSectionalTimetableSelect
            }
          >
            <SailingCategoryTimeTableSelect
              value={
                formData.departureTimeManual
                  ? enterManuallyDefaultOption.value
                  : formData.sailingType
              }
              onValueChange={(selectedCategory) => {
                const enterTimeManually = selectedCategory.value === "Manual";
                onChangeFormData("departureTimeManual", enterTimeManually);
                if (!enterTimeManually) {
                  onChangeFormData("sailingType", selectedCategory.value);
                  onChangeFormData(
                    "departureTime",
                    selectedCategory.departureTime
                  );
                }
              }}
              loading={sailingDeparturesLoading}
              sailings={sailings}
            />
          </InputFieldSetWidthLabeled>
        ) : null}
        <Indent />

        <InputFieldSetWidthLabeled
          minWidth={80}
          width={140}
          label={"Sailing category"}
          data-testid={
            testIdConstantsWebapp.priceCalculatorSectionalSailingCategorySelect
          }
        >
          <PriceCalculatorDepartureCategorySelect
            value={
              formData.departureTimeManual ? formData.sailingType : undefined
            }
            onValueChange={(sailingCategory) => {
              onChangeFormData("sailingType", sailingCategory.value);
            }}
            disabled={!formData.departureTimeManual}
          />
        </InputFieldSetWidthLabeled>

        <Indent />

        <InputFieldSetWidthLabeled
          minWidth={68}
          width={140}
          label={"Departure time"}
          data-testid={
            testIdConstantsWebapp.priceCalculatorSectionalDepartureTimeInput
          }
        >
          <PriceCalculatorDepartureTimeInput
            value={formData.departureTimeManual ? formData.departureTime : ""}
            onValueChange={(value) => {
              onChangeFormData("departureTime", value);
            }}
            disabled={!formData.departureTimeManual}
          />
        </InputFieldSetWidthLabeled>
      </Row>
      <Spacing />
    </FormGroup>
  );
};
