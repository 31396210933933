import { Box } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  ResultListBanner,
  stenaBusinessAgreement,
} from "@stenajs-webui/elements";
import { uniq } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { RoutePairInSeaAreaSelect } from "../../../../common/components/data-driven-inputs/selects/all-route-pair-select/RoutePairInSeaAreaSelect";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { StoreState } from "../../../../config/redux/RootReducer";
import { routeFactory } from "../../../../RouteFactory";
import { CustomerRouteSaveButton } from "./CustomerRouteSaveButton";
import {
  CustomerHeaderCustomersByIdFragment,
  CustomerRouteFetcherHeadAgreementFragment,
  CustomerRouteFetcherRouteAgreementFragment,
} from "@/gql/graphql";

interface Props {
  customer: CustomerHeaderCustomersByIdFragment;
  routePairId: string;
  headAgreement: CustomerRouteFetcherHeadAgreementFragment;
  routeAgreementsForRoutePair: Array<CustomerRouteFetcherRouteAgreementFragment>;
  loadingCustomer: boolean;
}

const errorSelector = (state: StoreState) =>
  state.customerRoute.progress.errors;

export const CustomerRouteHeader: React.FC<Props> = ({
  routePairId,
  customer,
  headAgreement,
  routeAgreementsForRoutePair,
  loadingCustomer,
}) => {
  const saveErrors = useSelector(errorSelector);

  const navigate = useNavigate();

  const customerId = customer.id;
  const headAgreementId = headAgreement.id;

  return (
    <PageHeader
      title={"Route rates"}
      loading={loadingCustomer}
      icon={stenaBusinessAgreement}
      contentAbove={
        saveErrors ? (
          <ResultListBanner
            bannerState={{
              headerText: "Unable to save",
              items: uniq(
                saveErrors.map((e) => ({
                  text: e.message,
                }))
              ),
            }}
            variant={"error"}
          />
        ) : undefined
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={customer?.breadCrumbName ?? ""}
            path={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId,
              headAgreementId: headAgreementId,
            })}
          />
          <Crumb
            path={routeFactory.productAndPrice.customer.customerRoutePair({
              customerId,
              headAgreementId,
              routePairId,
            })}
            label={routePairId.toUpperCase()}
            isLast
          />
        </BreadCrumbs>
      }
      contentRight={
        <CustomerRouteSaveButton
          routeAgreementsToSave={routeAgreementsForRoutePair}
          headAgreement={headAgreement}
          routePairId={routePairId}
        />
      }
      contentLeft={
        <Box width={"100px"}>
          <RoutePairInSeaAreaSelect
            value={routePairId}
            headAgreementId={headAgreementId}
            onValueChange={(routePairId) =>
              navigate(
                routeFactory.productAndPrice.customer.customerRoutePair({
                  customerId,
                  headAgreementId,
                  routePairId,
                })
              )
            }
          />
        </Box>
      }
    />
  );
};
