import {
  NslhCustomersSelectQuery,
  NslhCustomersSelectQueryVariables,
} from "@/gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";

export const queryCustomersByQuery = gql`
  fragment NslhCustomerSelect on Customer {
    id
    custNo
    custIndex
    name
  }
  query NslhCustomersSelect($query: String!) {
    productPrice {
      customers {
        byCustomerNumberOrIndex(query: $query) {
          ...NslhCustomerSelect
        }
      }
    }
  }
`;

export const useNslhCustomersByQuery = (queryString: string) => {
  const [getCustomersByQuery, { data, loading, error }] = useLazyQuery<
    NslhCustomersSelectQuery,
    NslhCustomersSelectQueryVariables
  >(queryCustomersByQuery, {
    variables: {
      query: queryString,
    },
  });

  return { getCustomersByQuery, data, loading, error };
};
