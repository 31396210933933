import { Row, Space, Text } from "@stenajs-webui/core";
import { Icon, stenaExclamationTriangle } from "@stenajs-webui/elements";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";
import { cssColor } from "@stenajs-webui/theme";
import { Tooltip } from "@stenajs-webui/tooltip";

import * as React from "react";

interface Props {
  isEditable?: boolean;
  value?: string;
  modifiedField?: ModifiedFieldItemState;
  error?: boolean;
}

const fieldValueResolver = (value: string | undefined) => {
  return !value || value === "0,00" ? "Not set" : value;
};

export const AgreementArticlesModifiedField: React.FC<Props> = ({
  isEditable,
  value,
  modifiedField,
  error,
}) => {
  return (
    <Tooltip label={"Invalid date"} disabled={!error}>
      <Row>
        <Text
          color={
            isEditable ? cssColor("--swui-primary-action-color") : undefined
          }
          variant={modifiedField?.modified ? "bold" : undefined}
        >
          {fieldValueResolver(value)}
        </Text>
        {error ? (
          <>
            <Space />
            <Icon
              icon={stenaExclamationTriangle}
              color={cssColor("--lhds-color-red-500")}
              size={14}
            />
          </>
        ) : null}
      </Row>
    </Tooltip>
  );
};
