import { routeFactory } from "@/RouteFactory";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { BreadCrumbs, ResultListBanner } from "@stenajs-webui/elements";
import * as React from "react";
import { Customer } from "../types";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { uniq } from "lodash";

interface Props {
  customer: Customer;
  downloadError: FreightPricingPortalError | undefined;
}

export const RebateAgreementDocumentsHeader: React.FC<Props> = ({
  customer,
  downloadError,
}) => {
  const distinctErrorMessages = uniq(
    downloadError?.errors.map((error) => error.message)
  );

  return (
    <PageHeader
      title={"Documents"}
      contentAbove={
        downloadError && (
          <ResultListBanner
            bannerState={{
              headerText: "Unable to download document",
              items: distinctErrorMessages.map((error) => ({
                text: error,
              })),
            }}
            variant={"error"}
          />
        )
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation Plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={customer?.breadCrumbName ?? ""}
            path={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId: customer?.id ?? "",
              headAgreementId: customer?.preselectedHeadAgreement?.id,
            })}
          />
          <Crumb label={"Documents"} isLast />
        </BreadCrumbs>
      }
    />
  );
};
