import { ArticleTypesMultiSelectQuery } from "@/gql/graphql";
import { SeparatorLine } from "@stenajs-webui/core";
import { CardBody } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../../../config/redux/RootReducer";
import { articleFormActions } from "../actions";
import { articleFormSelectors } from "../selectors";
import { ExpansionPaneHeader } from "./ExpansionPaneHeader";

interface Props {
  title: string;
  onClickRemove: () => void;
  articleType: ArticleTypesMultiSelectQuery["productPrice"]["articleType"]["all"]["0"];
  removeDisabled: boolean;
  articleId: string;
}

export const ExpansionPane: React.FC<Props> = ({
  children,
  title,
  articleType,
  onClickRemove,
  removeDisabled,
  articleId,
}) => {
  const dispatch = useDispatch();
  const expandedArticleStateForArticle = useSelector((state: StoreState) =>
    articleFormSelectors.getSpecificArticleExpandedState(state, articleId)
  );

  return (
    <>
      <ExpansionPaneHeader
        expanded={expandedArticleStateForArticle}
        onClickExpand={() => {
          dispatch(articleFormActions.toggleExpandedCard(articleId));
        }}
        onClickRemove={onClickRemove}
        title={title}
        removeDisabled={removeDisabled}
        articleId={articleId}
        articleType={articleType}
      />
      {expandedArticleStateForArticle && (
        <>
          <SeparatorLine />
          <CardBody
            borderLeft={
              expandedArticleStateForArticle
                ? `4px solid ${cssColor("--lhds-color-blue-600")}`
                : undefined
            }
          >
            {children}
          </CardBody>
        </>
      )}
    </>
  );
};
