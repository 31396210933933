import { useFetchAllRoutePairs } from "@/features/search-filter/hooks/FilterFetchers/UseFetchAllRoutePairs";
import { RoutePair } from "@/features/search-filter/types/FilterEntitys";
import {
  SearchFilterSectionChipModel,
  SectionChips,
} from "@stenajs-webui/filter";
import _ from "lodash";
import { useFilterChips } from "../hooks/UseFilterChips";

export const RoutePairChips = ({
  enabled,
  noSelectedLabel,
  selectedRoutePairs,
}: {
  enabled?: boolean;
  noSelectedLabel?: string;
  selectedRoutePairs: RoutePair[];
  enableChipClearButtons?: boolean;
}) => {
  return (
    <>
      {enabled && (
        <RoutePairChipsInternal
          noSelectedLabel={noSelectedLabel}
          selectedRoutePairs={selectedRoutePairs}
        />
      )}
    </>
  );
};

const getFilterChipsBySeaArea = (
  selectedRoutePairs: RoutePair[],
  allRoutePairs: RoutePair[],
  seaAreaId:
    | "IRISHNORTH"
    | "IRISHSOUTH"
    | "NORTHSEA"
    | "SCAND"
    | "GERM"
    | "BALTICS"
    | "BALTIC"
) => {
  let chipsBySeaArea: SearchFilterSectionChipModel[] = [];
  const allBySeaArea = allRoutePairs.filter(
    (routePair) => routePair.seaArea?.id === seaAreaId
  );
  const selectedBySeaArea = selectedRoutePairs.filter(
    (routePair) => routePair.seaArea?.id === seaAreaId
  );
  if (selectedBySeaArea.length === 0) {
    return chipsBySeaArea;
  }
  if (
    _.isEqual(
      allBySeaArea.sort((a, b) => (a.code < b.code ? -1 : 1)),
      selectedBySeaArea.sort((a, b) => (a.code < b.code ? -1 : 1))
    )
  ) {
    chipsBySeaArea.push({
      label: selectedBySeaArea.at(0)?.seaArea?.name ?? "",
      value: seaAreaId,
    });
  } else {
    selectedBySeaArea.forEach((routePair) =>
      chipsBySeaArea.push({ label: routePair.name, value: routePair.id })
    );
  }
  return chipsBySeaArea;
};

const calculateChips = (
  allRoutePairs: RoutePair[],
  selectedRoutePairs: RoutePair[]
) => {
  const IRISHNORTH = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "IRISHNORTH"
  );
  const IRISHSOUTH = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "IRISHSOUTH"
  );
  const NORTHSEA = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "NORTHSEA"
  );
  const SCAND = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "SCAND"
  );
  const GERM = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "GERM"
  );
  const BALTICS = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "BALTICS"
  );

  const BALTIC = getFilterChipsBySeaArea(
    selectedRoutePairs,
    allRoutePairs,
    "BALTIC"
  );

  const chips = _.concat(
    IRISHNORTH,
    IRISHSOUTH,
    NORTHSEA,
    SCAND,
    GERM,
    BALTICS,
    BALTIC
  );

  return chips.length > 0 ? chips : undefined;
};

const RoutePairChipsInternal = ({
  noSelectedLabel,
  selectedRoutePairs,
  enableChipClearButtons,
}: {
  noSelectedLabel?: string;
  selectedRoutePairs: RoutePair[];
  enableChipClearButtons?: boolean;
}) => {
  const { onClickRemoveRoutePair } = useFilterChips();
  const { items } = useFetchAllRoutePairs();
  const chips = calculateChips(items, selectedRoutePairs);
  return (
    <SectionChips
      sectionId={"routePairs"}
      emptyChipLabel={noSelectedLabel ?? undefined}
      chips={chips}
      onClickRemoveOnChip={
        enableChipClearButtons === true
          ? (chip) =>
              onClickRemoveRoutePair(
                selectedRoutePairs.find(
                  (routePair) => routePair.id === chip.value
                )!
              )
          : undefined
      }
    />
  );
};
