import { HeadAgreementFormModel } from "../../common/models/HeadAgreementFormModel";
import { formatMissingFieldsMessage } from "../../../../../common/error/ErrorFormatter";
import { formatDateString } from "@/common/dates/formatters";
import { CreateHeadAgreementInput } from "@/gql/graphql";

export const transformCreateHeadAgreementInput = (
  customerId: string,
  formModel: HeadAgreementFormModel
): CreateHeadAgreementInput => {
  const missing = [];
  const otherErrors = [];

  if (!formModel.valid.startDate) {
    missing.push("start date");
  }
  if (!formModel.valid.endDate) {
    missing.push("end date");
  }
  if (!formModel.name) {
    otherErrors.push("You need to type a name for the agreement");
  }
  if (!formModel.statusCode) {
    missing.push("status");
  }

  if (missing.length) {
    otherErrors.push(formatMissingFieldsMessage(missing));
  }
  if (otherErrors.length) {
    throw new Error(otherErrors.join(" "));
  }
  return {
    customerId,
    internalNotes: formModel.internalNotes!,
    lastPage: formModel.lastPage,
    name: formModel.name!,
    condition: formModel.condition,
    specifications: formModel.specifications,
    status: formModel.statusCode!,
    validFrom: formatDateString(formModel.valid.startDate!),
    validTo: formatDateString(formModel.valid.endDate!),
  };
};
