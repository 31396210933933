import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { RouteAgreementDetailsFetcher } from "./RouteAgreementDetailsFetcher";
import { useParams } from "react-router-dom";
import { TabCode } from "../config/TabConfig";

export interface RouteAgreementDetailsScreenParams {
  customerId: string;
  headAgreementId: string;
  routePairId: string;
  routeAgreementId: string;
  tab?: TabCode;
}

interface Props {}

export const RouteAgreementDetailsScreen: React.FC<Props> = () => {
  const { routeAgreementId, headAgreementId, customerId, routePairId } =
    useParams() as {
      routeAgreementId: string;
      headAgreementId: string;
      customerId: string;
      routePairId: string;
    };

  if (!routeAgreementId) {
    return <ErrorScreen text={"Missing routeAgreementId."} />;
  }
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }
  if (!routePairId) {
    return <ErrorScreen text={"Missing routePairId."} />;
  }
  return (
    <RouteAgreementDetailsFetcher
      routeAgreementId={routeAgreementId}
      headAgreementId={headAgreementId}
      customerId={customerId}
      routePairId={routePairId}
    />
  );
};
