import {
  SearchFilterAction,
  FilterCheckbox,
  FilterCheckboxList,
} from "@stenajs-webui/filter";

export interface FilterCheckboxType {
  id: string;
  name: string;
  code?: string;
}

const handleCheckboxChange = (
  notes: FilterCheckboxType[],
  checkedNote: FilterCheckboxType
) =>
  notes.find((note) => note.id === checkedNote.id)
    ? notes.filter((note) => note.id !== checkedNote.id)
    : [...notes, checkedNote];

interface FilterCheckBoxListProps {
  checkboxes: FilterCheckboxType[];
  selectedValues: FilterCheckboxType[];
  setValue: (newValue: FilterCheckboxType[]) => SearchFilterAction<unknown>;
}
export const FPPFilterCheckBoxList = ({
  checkboxes,
  selectedValues,
  setValue,
}: FilterCheckBoxListProps) => {
  return (
    <FilterCheckboxList>
      {checkboxes.map((checkbox) => (
        <FilterCheckbox
          key={checkbox.id}
          label={checkbox.name}
          value={!!selectedValues.find((n) => n.id === checkbox.id)}
          onValueChange={() =>
            setValue(handleCheckboxChange(selectedValues, checkbox))
          }
        />
      ))}
    </FilterCheckboxList>
  );
};
