import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: number;
  onValueChange?: (value: number) => void;
}

const pluginOptions = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
];

export const VehiclePluginsSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const selected = useMemo(
    () => pluginOptions.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      {...selectProps}
      value={selected || null}
      options={pluginOptions}
      onChange={(item: any) => onValueChange && onValueChange(item.value)}
    />
  );
};
