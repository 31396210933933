import * as React from "react";
import { CardHeader, InputSpinner, Tag } from "@stenajs-webui/elements";

interface CustomerRouteStatisticsBoxHeaderProps {
  loading: boolean;
  sailingTypeCode: string;
  sailingTypeName: string;
}

export const CustomerRouteStatisticsBoxHeader: React.FC<
  CustomerRouteStatisticsBoxHeaderProps
> = ({ loading, sailingTypeCode, sailingTypeName }) => (
  <CardHeader
    text={sailingTypeName}
    variant={"compact"}
    contentLeft={<Tag label={sailingTypeCode} variant={"passive"} />}
    contentAfterHeading={loading && <InputSpinner />}
  />
);
