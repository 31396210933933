import { gql } from "apollo-boost";

export const UseAdvancedStatisticsDataList = gql`
  query UseAdvancedStatisticsDataList(
    $byFilterFilterOptions: StatisticsSearchAdvancedListByFilterOptions!
  ) {
    statistics {
      advancedList {
        byFilter(filterOptions: $byFilterFilterOptions) {
          items {
            customerName
            id
            negotiationStatus
            custNo
            custIndex
            statNo
            parentNo
            seller
            routePairCode
            sailingCategoryCode
            unitCategoryCode
            last12MonthVol
            last12MonthStatVol
            avgGrossMeterPrice
            avgNetMeterPrice
            expectedVol
            targetInPercent
            actualOutcomeInPercent
          }
        }
      }
    }
  }
`;
