import * as React from "react";
import { SelectProps } from "@stenajs-webui/select";
import { Column, Text } from "@stenajs-webui/core";
import { CurrencySelect } from "../../../../common/components/data-driven-inputs/selects/currency-select/CurrencySelect";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { cssColor } from "@stenajs-webui/theme";

export interface RouteTargetCurrencySelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  selectLabel?: string;
  onSelectCurrencyChange: (value: string) => void;
}

export const RouteTargetCurrencySelect: React.FC<
  RouteTargetCurrencySelectProps
> = ({ onSelectCurrencyChange, value, selectLabel, ...selectProps }) => {
  return (
    <Column
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width={150}
      zIndex={ZIndex.highest}
    >
      <Text size="small" color={cssColor("--lhds-color-ui-500")}>
        {selectLabel}
      </Text>
      <CurrencySelect
        {...selectProps}
        containerWidth={"55%"}
        value={value}
        onValueChange={onSelectCurrencyChange}
      />
    </Column>
  );
};
