import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreState } from "@/config/redux/RootReducer";

export interface SubscriptionPayload {
  id: string;
  title: string;
  text: string | null;
  autoClose: boolean;
}

export interface NotificationsState {
  notifications: SubscriptionPayload[];
}

const INITIAL_STATE: NotificationsState = {
  notifications: [],
};

const slice = createSlice({
  name: "notifications",
  initialState: INITIAL_STATE,
  reducers: {
    removeNotificationById(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
      }>
    ) {
      state.notifications = state.notifications.filter(
        (i) => i.id !== payload.id
      );
    },
    appendNotification(state, { payload }: PayloadAction<SubscriptionPayload>) {
      state.notifications.push(payload);
    },
  },
});

export const {
  actions: { removeNotificationById, appendNotification },
  reducer: notificationsReducer,
} = slice;

export const getNotificationsState = (state: StoreState) => state.notifications;

export const getNotificationsList = (state: StoreState) =>
  getNotificationsState(state).notifications;
