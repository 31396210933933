import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { StatisticsByAdvancedListPanel } from "../statistics-by-advanced/list/StatisticsByAdvancedListPanel";
import { StatisticsByRoutePairListPanel } from "../statistics-by-route-pair/list/components/StatisticsByRoutePairListPanel";
import { StatisticsBySalesRegionListPanel } from "../statistics-by-sales-region/list/components/StatisticsBySalesRegionListPanel";
import { StatisticsBySellerListPanel } from "../statistics-by-seller/list/components/StatisticsBySellerListPanel";
import { StatisticsTotalDetails } from "../statistics-total/details/components/StatisticsTotalDetails";
import { StatisticsByCustomerListPanel } from "../statistics-by-customer/list/components/StatisticsByCustomerListPanel";

export const StatisticsTabRouter: React.FC = () => (
  <Routes>
    <Route path={"/total"} element={<StatisticsTotalDetails />} />
    <Route
      path={"/sales-region"}
      element={<StatisticsBySalesRegionListPanel />}
    />
    <Route path={"/route"} element={<StatisticsByRoutePairListPanel />} />
    <Route path={"/seller"} element={<StatisticsBySellerListPanel />} />
    <Route path={"/customer"} element={<StatisticsByCustomerListPanel />} />
    <Route path={"/advanced"} element={<StatisticsByAdvancedListPanel />} />
  </Routes>
);
