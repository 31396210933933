import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CopyHeadAgreementInput,
  CopyHeadAgreementMutation,
  CopyHeadAgreementMutationVariables,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";

const mutation = gql`
  ${errorFragment}
  mutation CopyHeadAgreement($input: CopyHeadAgreementInput!) {
    productPrice {
      headAgreement {
        copyHeadAgreement(input: $input) {
          ... on UpdateHeadAgreementSuccessResult {
            headAgreement {
              id
              rowVersion
              customer {
                id
              }
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useCopyHeadAgreementMutation = () => {
  const [mutate, { error, loading }] = useMutation<
    CopyHeadAgreementMutation,
    CopyHeadAgreementMutationVariables
  >(mutation);

  const copyHeadAgreement = useCallback(
    (input: CopyHeadAgreementInput) => mutate({ variables: { input } }),
    [mutate]
  );

  return {
    copyHeadAgreement,
    error,
    loading,
  };
};
