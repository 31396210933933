import { browserHistory } from "@/history";
import { useEffect } from "react";

interface UsePromptProps {
  shouldBlock: boolean;
  message: string;
}

export function usePrompt({ shouldBlock, message }: UsePromptProps) {
  useEffect(() => {
    if (shouldBlock) {
      let unblock = browserHistory.block((tx) => {
        let proceed = window.confirm(message);
        if (proceed) {
          unblock();
          setTimeout(tx.retry, 0);
        }
      });

      return unblock;
    }
  }, [message, shouldBlock]);
}
