import { MaskedTextInputProps } from "@stenajs-webui/input-mask";
import * as React from "react";
import { TimeTextInput } from "@stenajs-webui/calendar";

interface Props
  extends Omit<MaskedTextInputProps, "value" | "onChange" | "mask"> {
  value?: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
}

export const PriceCalculatorDepartureTimeInput: React.FC<Props> = ({
  onValueChange,
  value,
  disabled,
}) => {
  return (
    <TimeTextInput
      value={value}
      onValueChange={onValueChange}
      useIcon={false}
      disabled={disabled}
    />
  );
};
