import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Row, Space, Spacing, Txt } from "@stenajs-webui/core";
import {
  PrimaryButton,
  ResultItem,
  ResultListBanner,
  SecondaryButton,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { useState } from "react";
import { useBulkUpdateExpectedVolumesMutation } from "../hooks/UseBulkUpdateExpectedVolumeMutation";
import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { ApolloQueryResult } from "@apollo/client";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { appendNotification } from "@/features/notification-center/redux/slice";
import { createNotificationExpectedVolume } from "@/features/notification-center/utils";
import { useDispatch } from "react-redux";
import {
  BulkUpdateExpectedVolumesActionEnum,
  CustomerListQuery,
  CustomerListQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerNumbers: Array<string>;
  negotiationYear: number;
  refetch: (
    variables?: Partial<CustomerListQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<CustomerListQuery>>;
  modalClose: () => void;
  clearAll: () => void;
}
const MinimumNumberOfCustomersForStartNotification = 50;

export const ExpectedVolumeModalContent: React.FC<Props> = ({
  customerNumbers,
  negotiationYear,
  refetch,
  modalClose,
  clearAll,
}) => {
  const dispatch = useDispatch();
  const [adjustByPercent, setAdjustByPercent] = useState<string>("");
  const [action, setAction] = useState<BulkUpdateExpectedVolumesActionEnum>(
    BulkUpdateExpectedVolumesActionEnum.Settozero
  );

  const { bulkUpdateExpectedVolumes, loading } =
    useBulkUpdateExpectedVolumesMutation();
  const { setBannerState, bannerState } = useResultListBannerState(undefined);
  const moreCustomersSelectedThanCondition =
    customerNumbers.length >= MinimumNumberOfCustomersForStartNotification;
  const setStartedJobNotification = () => {
    dispatch(appendNotification(createNotificationExpectedVolume()));
  };
  const onApply = async () => {
    if (action && customerNumbers) {
      if (moreCustomersSelectedThanCondition) {
        setStartedJobNotification();
      }
      const { data } = await bulkUpdateExpectedVolumes({
        action,
        customerNumbers: customerNumbers.map((customerNumber) =>
          Number(customerNumber)
        ),
        negotiationYear,
        adjustBy: adjustByPercent
          ? Number(commaToDotTransform(adjustByPercent))
          : null,
      });

      const result =
        data?.productPrice.expectedVolume.bulkUpdateExpectedVolumes;

      if (result && "errors" in result) {
        setBannerState({
          headerText: "Could not update expected volumes",
          items: result.errors.map<ResultItem>((error) => ({
            text: error.message,
          })),
        });
      } else {
        modalClose();
        clearAll();
        refetch();
      }
    }
  };

  return (
    <Box minHeight={"200px"} justifyContent={"space-between"}>
      <Box>
        <RadioButtonWithLabel
          label={"Set to zero"}
          checked={action === BulkUpdateExpectedVolumesActionEnum.Settozero}
          onChange={() =>
            setAction(BulkUpdateExpectedVolumesActionEnum.Settozero)
          }
        />
        <Space num={SpaceValues.SIXTEEN} />
        <RadioButtonWithLabel
          label={"Copy last 12 months"}
          checked={
            action === BulkUpdateExpectedVolumesActionEnum.Copylast12Months
          }
          onChange={() =>
            setAction(BulkUpdateExpectedVolumesActionEnum.Copylast12Months)
          }
        />
        <Space num={SpaceValues.SIXTEEN} />
        <Row alignItems={"center"}>
          <RadioButtonWithLabel
            label={"Copy last 12 months and adjust by"}
            checked={
              action ===
              BulkUpdateExpectedVolumesActionEnum.Copylast12Monthsandadjust
            }
            onChange={() =>
              setAction(
                BulkUpdateExpectedVolumesActionEnum.Copylast12Monthsandadjust
              )
            }
          />
          <Space horizontal />
          <Box width={"50px"}>
            <NumericTextInput
              value={adjustByPercent}
              onValueChange={(value) => setAdjustByPercent(value)}
              maxDecimals={1}
              maxNumbers={3}
            />
          </Box>
          <Space horizontal />
          <Txt>{"%"}</Txt>
        </Row>
      </Box>
      {bannerState && (
        <Spacing num={SpacingValues.TWENTYFOUR}>
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          </Box>
        </Spacing>
      )}
      <Row justifyContent={"flex-end"}>
        <SecondaryButton label="Cancel" onClick={modalClose} />
        <Space num={SpaceValues.SIXTEEN} />
        <PrimaryButton label="Apply" onClick={onApply} loading={loading} />
      </Row>
    </Box>
  );
};
