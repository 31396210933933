import * as React from "react";
import { useMemo } from "react";
import { Tag } from "@stenajs-webui/elements";
import { MatrixFormItem } from "../MatrixFormModel";
import { Indent } from "@stenajs-webui/core";
import { useSelector } from "react-redux";
import { matrixFormRedux } from "../MatrixFormRedux";
import { matrixItemDateIsOverlapping } from "../util/MatrixDateValidator";
import { isAfter } from "date-fns";

interface Props {
  matrixItem: MatrixFormItem;
}

export const MatrixItemValidationChips: React.FC<Props> = ({ matrixItem }) => {
  const { matrices } = useSelector(matrixFormRedux.selectors.getEntity);

  const hasOverlapping = useMemo(() => {
    try {
      return matrixItemDateIsOverlapping(matrixItem, matrices);
    } catch (e) {
      return false;
    }
  }, [matrixItem, matrices]);

  const hasInvalidDateRange = useMemo(
    () =>
      !!(
        matrixItem.startDate &&
        matrixItem.endDate &&
        isAfter(new Date(matrixItem.startDate), new Date(matrixItem.endDate))
      ),
    [matrixItem]
  );

  const hasMissingDate = useMemo(
    () => !matrixItem.startDate || !matrixItem.endDate,
    [matrixItem]
  );

  return (
    <>
      {!matrixItem.persisted && (
        <Indent>
          <Tag label={"New"} variant={"success"} />
        </Indent>
      )}
      {hasOverlapping && (
        <Indent>
          <Tag label={"Overlapping"} variant={"error"} />
        </Indent>
      )}
      {hasMissingDate && (
        <Indent>
          <Tag label={"Missing dates"} variant={"error"} />
        </Indent>
      )}
      {hasInvalidDateRange && (
        <Indent>
          <Tag label={"Invalid date range"} variant={"error"} />
        </Indent>
      )}
    </>
  );
};
