import { IndentValues } from "@/common/utils/IndentValues";
import { Box } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import {
  SailingCategoryStatisticsObjectType,
  sailingCategoryStatisticsTableConfig,
} from "./sailingCategoryStatisticsTableConfig";

interface SailingCategoryStatisticsTableProps {
  tableItems: SailingCategoryStatisticsObjectType[];
  width?: string;
  headerTitle: string;
  loading?: boolean;
  displayPercentageAfterValue?: boolean;
}
export const SailingCategoryStatisticsTable: React.FC<
  SailingCategoryStatisticsTableProps
> = ({
  tableItems,
  width = "50%",
  headerTitle,
  loading,
  displayPercentageAfterValue = false,
}) => {
  return (
    <Box width={width}>
      <CardHeader text={headerTitle} />
      <Box indent={IndentValues.TWENTYFOUR}>
        <StandardTable
          loading={loading}
          config={sailingCategoryStatisticsTableConfig(
            displayPercentageAfterValue
          )}
          items={tableItems}
        />
      </Box>
    </Box>
  );
};
