import { useCallback, useState } from "react";
import { FormModelChangeHandler } from "../forms/types";

export const useFormModelState = <T>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);

  const handleValueChange = useCallback<FormModelChangeHandler<T>>(
    (key, value) => {
      setState((state) => ({ ...state, [key]: value }));
    },
    []
  );

  return [state, handleValueChange, setState] as const;
};
