import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import { NoShowLateHandlingBookingsByFilterOptions } from "@/gql/graphql";

export const noShowLateHandlingBookingListDataFilterVariablesTransformer = (
  committedFilter: WorkspaceState
): NoShowLateHandlingBookingsByFilterOptions => {
  return {
    month: committedFilter.month,
    year: committedFilter.negotiationYear,
    bookingNo: committedFilter.bookingNo!,
    custNos: committedFilter.customers?.map((c) => Number(c.id)) ?? [],
    salesRegions: committedFilter.salesRegions?.map((sr) => Number(sr.id)),
    bcComments: transformBookAndCheckInNotesToStringInput(
      committedFilter.bookAndCheckInNotes.map((bc) => bc.id)
    ),
    handlingTypes: committedFilter.noShowLateHandlingType.map((ht) => ht.id),
    routePairCodes: committedFilter.routePairs.map((rp) => rp.id),
    sellers: committedFilter.sellers?.map((s) => s.id),
    handlingStatuses: committedFilter.noShowLateHandlingStatus.map((s) => s.id),
    fromDepartureTime: committedFilter.startTime!,
    toDepartureTime: committedFilter.endTime!,
    fromDepartureDate: {
      isoString: committedFilter.startDate ?? "",
    },
    toDepartureDate: {
      isoString: committedFilter.endDate ?? "",
    },
  };
};

const transformBookAndCheckInNotesToStringInput = (bcNotes: Array<string>) => {
  if (bcNotes.length === 0 || bcNotes.length === 2) {
    return "B";
  }

  if (bcNotes.includes("Y")) {
    return "Y";
  }

  return "N";
};
