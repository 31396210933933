import {
  Row,
  Box,
  Spacing,
  Heading,
  Indent,
  Space,
  Text,
} from "@stenajs-webui/core";

import {
  Card,
  PrimaryButton,
  SecondaryButton,
  stenaArrowWideLeft,
  stenaArrowWideRight,
  stenaCheckCircle,
} from "@stenajs-webui/elements";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { IndentValues } from "@/common/utils/IndentValues";
import { StepConfiguration } from "../WizardComponent";
import { cssColor } from "@stenajs-webui/theme";
import {
  BannerErrorWithLinks,
  ErrorWithPath,
} from "@/common/error/BannerErrorWithLinks";

import { useNavigate, useParams } from "react-router-dom";
import { routeFactory } from "@/RouteFactory";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";

interface WizardStepHeaderProps {
  steps: StepConfiguration[];
  moveToNextStep: () => void;
  moveToPreviousStep: () => void;
  currentStep: StepConfiguration;
  currentIndex: number;
  isWizardLoading: boolean;
  errorMessages: ErrorWithPath[] | undefined;
  isNextAvailable: boolean;
  isLastStep: boolean;
  sectionalRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
}

export const WizardStepHeader = ({
  steps,
  moveToNextStep,
  moveToPreviousStep,
  currentStep,
  currentIndex,
  isWizardLoading,
  errorMessages,
  isNextAvailable,
  isLastStep,
  sectionalRouteAgreements,
}: WizardStepHeaderProps) => {
  const navigate = useNavigate();
  const { headAgreementId, customerId } = useParams() as {
    headAgreementId: string;
    customerId: string;
  };
  return (
    <>
      <Card
        alignSelf={"center"}
        justifySelf={"center"}
        width={"100%"}
        indent={IndentValues.SIXTEEN}
      >
        <Spacing num={SpaceValues.SIXTEEN}>
          <Row
            justifyContent={"flex-start"}
            alignContent={"space-evenly"}
            justifySelf={"center"}
            alignSelf={"center"}
            width={"100%"}
            spacing={SpaceValues.SIXTEEN}
          >
            {steps.map((step, index) => (
              <HeaderStepInfo
                key={step.title}
                title={step.title}
                active={index === currentIndex}
                completed={index < currentIndex}
              />
            ))}
          </Row>

          <Row justifySelf={"center"} justifyContent={"space-between"} indent>
            <Row>
              <Heading variant="h3"> {currentStep.title} </Heading>
              <Indent num={IndentValues.TWENTY} />
            </Row>
            <Row justifyContent={"flex-end"}>
              <SecondaryButton
                size={"medium"}
                label={"Cancel"}
                onClick={() =>
                  navigate(
                    routeFactory.productAndPrice.customer.customerHeadAgreement(
                      {
                        customerId,
                        headAgreementId,
                      }
                    )
                  )
                }
                variant={"danger"}
              ></SecondaryButton>
              {currentIndex > 0 && (
                <>
                  <Indent num={IndentValues.EIGHT} />
                  <SecondaryButton
                    size={"medium"}
                    label={"Back"}
                    onClick={moveToPreviousStep}
                    leftIcon={stenaArrowWideLeft}
                  />
                </>
              )}

              <Indent num={IndentValues.FOUR} />
              <PrimaryButton
                size={"medium"}
                label={isLastStep ? "Create document" : "Next"}
                disabled={!isNextAvailable}
                onClick={moveToNextStep}
                leftIcon={isLastStep ? stenaCheckCircle : undefined}
                rightIcon={
                  isWizardLoading || isLastStep
                    ? undefined
                    : stenaArrowWideRight
                }
                loading={isWizardLoading}
              />
            </Row>
          </Row>

          <Space num={SpaceValues.EIGHT} />

          {errorMessages && errorMessages?.length > 0 && (
            <Row justifyContent={"flex-end"} minWidth={280}>
              <BannerErrorWithLinks
                headerText={"Validation failed"}
                errors={errorMessages}
                sectionRouteAgreements={sectionalRouteAgreements}
              />
              <Indent num={IndentValues.FOUR} />
            </Row>
          )}
        </Spacing>
      </Card>
    </>
  );
};

const HeaderStepInfo = ({
  title,
  active,
  completed,
}: {
  title: string;
  active: boolean;
  completed: boolean;
}) => {
  const backgroundColor = completed || active ? "#1D64AB" : "#CED0D7";
  return (
    <>
      <Box key={title} width={"100%"} indent>
        <div
          style={{
            width: "100%",
            height: "4px",
            background: backgroundColor,
            justifySelf: "center",
            alignSelf: "center",
            borderRadius: "16px",
          }}
        />
        <Space num={SpacingValues.EIGHT} />
        <Text
          size={"small"}
          color={
            completed || active ? "#1D64AB" : cssColor("--lhds-color-ui-600")
          }
        >
          {title}
        </Text>
      </Box>
      <Spacing num={SpacingValues.SIXTEEN} />
    </>
  );
};
