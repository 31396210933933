import { routeFactory } from "@/RouteFactory";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { createResultTableConfig } from "@/features/statistics/common/config/list/ResultTableConfig";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ResultTable } from "@/features/statistics/common/table/ResultTable";
import { transformSelectedStatusesToNegotiationStatusCodes } from "@/features/statistics/common/transformers/NegotiationStatusFormModelTransformer";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { useDispatch } from "react-redux";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { useNavigate } from "react-router";
import { useContext, useMemo } from "react";
import { useRouteListVolumeAndTargetQuery } from "../hooks/UseRouteListVolumeAndTargetQuery";
import { transformResultToTableRow } from "../transformers/ResultTableRowTransformer";
import { useSellerListQuery } from "../hooks/UseSellerListQuery";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { Box, Row, SeparatorLine } from "@stenajs-webui/core";
import { statisticsBySellerFilterSettings } from "../config/StatisticsBySellerFilterConfig";
import { NegotiationStatusCode } from "@/gql/graphql";

export const StatisticsBySellerTable: React.FC = () => {
  const sticky = true;
  const navigate = useNavigate();

  const {
    sellerCustomerOrVolumeFilter,
    setSellerCustomerOrVolumeFilter,
    sellerSelectedStatuses,
    setSellerSelectedStatuses,
  } = useContext(StatisticsContext) as StatisticsContextType;

  const dispatch = useDispatch();

  const { year: negotiationYear } = useStatisticsYearSelect();
  const { currency: currencyCode } = useCurrencySelection();

  const { committedState, workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;
  const sellers = committedState.sellers;
  const { loading, sellerItems } = useSellerListQuery({
    year: negotiationYear,
    sellerIds: sellers.map((u) => u.id),
  });

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(sellerSelectedStatuses),
    [sellerSelectedStatuses]
  );
  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;

  const { loading: loadingVolumeAndTarget, volumeAndTargets } =
    useRouteListVolumeAndTargetQuery({
      year: negotiationYear,
      sellerIds: sellers.map((u) => u.id),
      negotiationStatusCodes,
      currencyCode,
    });

  const resultRows = useMemo(
    () =>
      sellerItems?.map((s) =>
        transformResultToTableRow(
          s,
          loadingVolumeAndTarget ? [] : volumeAndTargets ?? [],
          sellerCustomerOrVolumeFilter
        )
      ),
    [
      sellerItems,
      volumeAndTargets,
      loadingVolumeAndTarget,
      sellerCustomerOrVolumeFilter,
    ]
  );

  const resultConfig = createResultTableConfig(
    "Seller",
    (id) => navigate(routeFactory.statistics.sellerDetails(id)),
    currencyCode,
    sellerSelectedStatuses,
    setSellerSelectedStatuses,
    sellerCustomerOrVolumeFilter,
    setSellerCustomerOrVolumeFilter,
    sticky
  );

  return (
    <Card minWidth={"900px"}>
      <CardHeader text={"Seller"} />
      <CardBody>
        <Row>
          <Box>
            <SearchFilterContext
              state={workspaceState}
              actions={createFppSearchFilterActions(
                "statistics.statisticsBySeller"
              )}
              dispatch={dispatch}
            >
              <FppSearchFilter settings={statisticsBySellerFilterSettings} />
            </SearchFilterContext>
          </Box>
        </Row>
      </CardBody>
      <SeparatorLine />
      <ResultTable items={resultRows} config={resultConfig} loading={loading} />
    </Card>
  );
};
