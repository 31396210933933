import * as React from "react";
import { useMemo } from "react";
import { FormModelChangeHandler } from "../../../../common/forms/types";
import { gql } from "apollo-boost";
import { PriceCalculatorFormData } from "../../PriceCalculatorFormFetcher";
import { useQuery } from "@apollo/client";
import { Select } from "@stenajs-webui/select";
import { Box, Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { formatDate } from "@/common/formatters/DateFormatter";
import { AllMultiLegQuery } from "@/gql/graphql";

const query = gql`
  query AllMultiLeg {
    productPrice {
      route {
        allMultiLeg {
          id
          name
          code
          routeLegs {
            id
            legNo
            legRoute {
              code
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export interface ApiRouteLeg {
  id: string;
  legNo: number;
  legRoute: { code: string };
}

export interface MultiLegOptionData {
  code: string;
  id: string;
  name: string;
  routeLegs?: Array<ApiRouteLeg>;
}
interface MultiLegOption {
  label: string;
  value: string;
  data: MultiLegOptionData;
}

export const transformToRouteLegs = (routeLegs: ApiRouteLeg[]) =>
  routeLegs.map((routeLeg, index) => ({
    routeCode: routeLeg.legRoute.code,
    sailingTypeCode: "P",
    departureDate: formatDate(new Date().toISOString()),
    departureTime: "11:11",
  }));

export const PriceCalculatorSelectMultiLegRoute: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const { loading, data } = useQuery<AllMultiLegQuery>(query);

  const multiLegRoutes = useMemo(() => {
    return data?.productPrice?.route?.allMultiLeg;
  }, [data]);

  const options = useMemo<MultiLegOption[] | undefined>(() => {
    return multiLegRoutes?.map((r) => ({
      label: r.code + " - " + r.name,
      value: r.id,
      data: r,
    }));
  }, [multiLegRoutes]);

  const selected = useMemo(
    () => options?.find((o) => o.value === formData.routeCode),
    [options, formData.routeCode]
  );

  return (
    <Column>
      <Spacing num={0.6} />
      <Row alignItems={"center"}>
        <Indent num={2} />
        <Label text={"Route"} row>
          <Box width={"240px"}>
            <Select
              isLoading={loading}
              options={options}
              isClearable
              value={selected}
              onChange={(value) => {
                onChangeFormData("multiLegRouteData", value?.data);
                onChangeFormData("routeCode", value?.data.code);
                value?.data?.routeLegs &&
                  onChangeFormData(
                    "routeLegs",
                    transformToRouteLegs(value.data.routeLegs)
                  );
              }}
              menuPortalTarget={document.body}
            />
          </Box>
        </Label>
      </Row>
    </Column>
  );
};
