import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";
import { BulkEditRatesFetcher } from "./BulkEditRatesFetcher";

export const BulkEditRatesScreen: React.FC = () => {
  return (
    <SearchFilterSelectorProvider reducerId={"bulkEditRatesFilter"}>
      <BulkEditRatesFetcher />
    </SearchFilterSelectorProvider>
  );
};
