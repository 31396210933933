import * as React from "react";

import {
  Card,
  CardHeader,
  PrimaryButton,
  SecondaryButton,
} from "@stenajs-webui/elements";
import { IndentValues } from "@/common/utils/IndentValues";
import { Box, Indent, Space } from "@stenajs-webui/core";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { useMemo } from "react";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ChildSelectorTableColumnKeys } from "./config/RowAndColumnConfig";
import { ChildSelectorTableConfig } from "./config/ChildSelectorTableConfig";
import {
  ChildCustomersByStatusFragment,
  ChildSelectorQuery,
  ChildSelectorQueryVariables,
} from "@/gql/graphql";
import { childSelectorQuery } from "./childSelectorQuery";
interface Props {
  headAgreementYear: number;
  setChildCustomers: React.Dispatch<
    React.SetStateAction<ChildCustomersByStatusFragment[]>
  >;
  setCreateReportForChildren: React.Dispatch<React.SetStateAction<boolean>>;
  setChildrenForReport: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
}

export const ChildSelector: React.FC<Props> = ({
  headAgreementYear: year,
  setChildCustomers,
  setChildrenForReport,
  setCreateReportForChildren,
}) => {
  const { headAgreementId, customerId, reportType } = useParams() as {
    headAgreementId: string;
    customerId: string;
    reportType: string;
  };

  const { data, loading, error } = useQuery<
    ChildSelectorQuery,
    ChildSelectorQueryVariables
  >(childSelectorQuery, {
    variables: {
      customerId,
      year,
      agreementId: headAgreementId,
      agreementDocumentType: reportType,
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      setChildCustomers(
        data?.productPrice?.customers?.byId?.childCustomersByStatus ?? []
      );
    },
  });

  const childCustomers =
    data?.productPrice.customers.byId?.childCustomersByStatus;

  const initialState = useMemo(
    () => createStandardTableInitialState<ChildSelectorTableColumnKeys>(),
    []
  );

  const { tableContext } = useLocalStateTableContext(
    "multi-leg-agreement-table-head-agreement",
    initialState
  );

  const selectedIds = tableContext.state.selectedIds.selectedIds;

  const handleSubmit = () => {
    setChildrenForReport(selectedIds);
  };

  return (
    <Box indent={IndentValues.TWENTYFOUR}>
      <Card>
        <CardHeader
          text={"Select Accounts"}
          contentRight={
            <>
              <SecondaryButton
                label="Cancel"
                variant="danger"
                onClick={() => setCreateReportForChildren(false)}
              />
              <Space />
              <PrimaryButton
                label="Next"
                disabled={selectedIds.length === 0}
                onClick={() => handleSubmit()}
              />
            </>
          }
        />
        <Indent num={IndentValues.EIGHT}>
          <StandardTable
            config={ChildSelectorTableConfig}
            items={childCustomers}
            tableContext={tableContext}
            loading={loading}
            error={error}
          />
        </Indent>
        <Space num={SpaceValues.SIXTEEN} />
      </Card>
    </Box>
  );
};
