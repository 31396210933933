import { gql, useLazyQuery } from "@apollo/client";
import {
  PriceCalculatorResultQuery,
  PriceCalculatorResultQueryVariables,
} from "@/gql/graphql";
import { fetchPriceCalcFragment } from "../PriceCalculatorFormFetcher";

export const usePriceCalculation = () => {
  const query = gql`
    ${fetchPriceCalcFragment}
    query PriceCalculatorResult($input: PriceCalcInput!) {
      priceCalc {
        byInput(variables: $input) {
          __typename
          ... on PriceCalcError {
            error
            errorType
          }
          ...FetchPriceCalc
        }
      }
    }
  `;
  const [calculatePrice, { loading, data, error }] = useLazyQuery<
    PriceCalculatorResultQuery,
    PriceCalculatorResultQueryVariables
  >(query, {
    fetchPolicy: "cache-and-network",
  });

  return {
    calculatePrice,
    loading,
    data,
    error,
  };
};
