import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  StatisticsSearchSalesRegionListVolumeAndTargetByFilterOptions,
  UseSalesRegionListVolumeAndTargetQuery,
  UseSalesRegionListVolumeAndTargetQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseSalesRegionListVolumeAndTarget(
    $filterOptions: StatisticsSearchSalesRegionListVolumeAndTargetByFilterOptions!
  ) {
    statistics {
      salesRegionList {
        volumeAndTarget(filterOptions: $filterOptions) {
          items {
            salesRegion {
              id
            }
            volumeAndTargetForSelected {
              averageNegotiationRate
              averageTarget
              avgMeterPrice
              avgMeterPriceDiffInPercent
              expectedVolume
              lastYearVolume
              volume
            }
          }
        }
      }
    }
  }
`;

export const useSalesRegionListVolumeAndTargetQuery = (
  filterOptions: StatisticsSearchSalesRegionListVolumeAndTargetByFilterOptions
) => {
  const { error, loading, data, refetch } = useQuery<
    UseSalesRegionListVolumeAndTargetQuery,
    UseSalesRegionListVolumeAndTargetQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    skip:
      filterOptions.salesRegionCodes.length === 0 || filterOptions.year == null,
  });

  return {
    refetch,
    error,
    loading,
    volumeAndTarget: data?.statistics.salesRegionList.volumeAndTarget.items,
  };
};
