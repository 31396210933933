import * as React from "react";
import { Indent, Row, Text } from "@stenajs-webui/core";
import { Icon, stenaLockClosed } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  status: HeadAgreementStatusCode;
}

export const ActiveIndicatorBox: React.FC<Props> = ({ status }) => {
  return (
    <Row
      borderColor={cssColor("--lhds-color-green-300")}
      borderWidth={1}
      borderStyle={"solid"}
      alignItems={"center"}
      indent={2}
      spacing={2}
      background={cssColor("--lhds-color-green-50")}
    >
      <Icon icon={stenaLockClosed} />
      <Indent />
      <Text>
        This agreement is <Text variant={"bold"}>{status}</Text>, you can only
        change validity, name and notes.
      </Text>
    </Row>
  );
};
