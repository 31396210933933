import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  CopyHeadAgreementFetcher,
  useCopyHeadAgreementTargetCustomerQuery,
} from "../CopyHeadAgreementFetcher";
import { CopyHaToOtherCustomerHeader } from "./CopyHaToOtherCustomerHeader";
import { SelectCustomerAndHeadAgreementCard } from "./SelectCustomerAndHeadAgreementCard";
import { Helmet } from "react-helmet";
import { ByCustomerNumberOrIndexFragment } from "@/gql/graphql";

export const targetCustomerInitialState: ByCustomerNumberOrIndexFragment = {
  id: "",
  name: "",
  custNo: 0,
  custIndex: "",
};

export const CopyHaToOtherCustomerScreen: React.FC = () => {
  const { headAgreementId } = useParams() as { headAgreementId: string };
  const [targetCustomer, setTargetCustomer] =
    useState<ByCustomerNumberOrIndexFragment>(targetCustomerInitialState);
  const [targetHeadAgreementId, setTargetHeadAgreementId] = useState("");
  const [createNewHeadAgreementView, setCreatNewHeadAgreementView] =
    useState(false);
  const [copyHeadAgreementView, setCopyHeadAgreementView] = useState(false);

  const { targetHeadAgreement, getTargetCustomerByQuery, loading } =
    useCopyHeadAgreementTargetCustomerQuery(targetHeadAgreementId);

  return (
    <>
      <Helmet title="Copy HA to other customer - Customer - FPP" />
      <CopyHaToOtherCustomerHeader />
      {copyHeadAgreementView ? (
        <CopyHeadAgreementFetcher
          headAgreementId={headAgreementId}
          targetHeadAgreement={targetHeadAgreement ?? undefined}
          targetCustomer={targetCustomer}
          copyHaToOtherCustomer
        />
      ) : createNewHeadAgreementView ? (
        <CopyHeadAgreementFetcher
          headAgreementId={headAgreementId}
          targetCustomer={targetCustomer}
          copyHaToOtherCustomer
        />
      ) : (
        <SelectCustomerAndHeadAgreementCard
          targetCustomer={targetCustomer}
          targetHeadAgreementId={targetHeadAgreementId}
          loading={loading}
          selectButtonDisabled={
            (!targetCustomer && !targetHeadAgreementId) ||
            targetHeadAgreementId === "" ||
            loading
          }
          setTargetCustomer={setTargetCustomer}
          setTargetHeadAgreementId={setTargetHeadAgreementId}
          setCreatNewHeadAgreementView={() =>
            setCreatNewHeadAgreementView(true)
          }
          getTargetCustomerByHaId={() => getTargetCustomerByQuery()}
          rightButtonOnClick={() => {
            targetCustomer &&
              targetHeadAgreementId &&
              setCopyHeadAgreementView(true);
          }}
        />
      )}
    </>
  );
};
