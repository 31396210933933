import React from "react";
import { useSelector } from "react-redux";
import { routeCustomerTargetSelectors } from "../features/route-pair-target-table/redux/selectors";
import { usePrompt } from "@/common/hooks/usePrompt";

export const RouteTargetModifiedAlert: React.FC = ({ children }) => {
  const routeCustomerTargetEditedData = useSelector(
    routeCustomerTargetSelectors.getEditedCustomerTargetTableRows
  );
  const routeCustomerTargetPersistedData = useSelector(
    routeCustomerTargetSelectors.getPersistedCustomerTargetTableRows
  );
  const listOrder = useSelector(routeCustomerTargetSelectors.getListOrder);

  const unSavedChangedExist = listOrder.some((order) => {
    return (
      JSON.stringify(routeCustomerTargetEditedData[order]) !==
      JSON.stringify(routeCustomerTargetPersistedData[order])
    );
  });

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: unSavedChangedExist,
  });

  return <>{children}</>;
};
