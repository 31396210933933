import {
  Column,
  Indent,
  Row,
  SeparatorLine,
  Space,
  Spacing,
  Text,
} from "@stenajs-webui/core";
import {
  Banner,
  FlatButton,
  Icon,
  Label,
  PrimaryButton,
  stenaExclamationTriangle,
} from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { useBannerError } from "../../../../../../../common/error/UseBannerError";
import { formatLongSailingType } from "../../../../../../../common/formatters/SailingStatusCodeFormatter";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { headAgreementQuery } from "../../../hooks/UseHeadAgreementById";
import { canDeleteAgreement } from "../util/AgreementStatusUtil";
import { useDeleteRouteAgreementMutation } from "./UseDeleteRouteAgreementMutation";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementQuery, HeadAgreementQueryVariables } from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  year: number;
  routeAgreement: RouteAgreement;
  onRequestClose: () => void;
}

export const ConfirmRouteAgreementDeleteModal: React.FC<Props> = ({
  routeAgreement,
  headAgreementId,
  year,
  onRequestClose,
}) => {
  const [deleteRouteAgreement, { loading }] = useDeleteRouteAgreementMutation();

  const {
    setBannerError,
    hasBannerError,
    clearBannerError,
    bannerErrorText,
    bannerErrorHeaderText,
  } = useBannerError();
  const canDelete = canDeleteAgreement(routeAgreement.statusCode);

  const onDeleteRouteAgreement = async () => {
    clearBannerError();
    try {
      const { data } = await deleteRouteAgreement({
        variables: {
          routeAgreementId: routeAgreement.id,
          rowVersion: routeAgreement.rowVersion,
        },
        update: (store, { data }) => {
          const mutationResult =
            data?.productPrice.routeAgreement.deleteRouteAgreement;
          if (!(mutationResult && "errors" in mutationResult)) {
            const query = store.readQuery<
              HeadAgreementQuery,
              HeadAgreementQueryVariables
            >({
              query: headAgreementQuery,
              variables: {
                customerId: routeAgreement.headAgreement.customer.id,
                year,
                headAgreementId,
              },
            });

            if (query && query.productPrice.headAgreement.byId) {
              const newQuery: HeadAgreementQuery = {
                ...query,
                productPrice: {
                  ...query.productPrice,
                  headAgreement: {
                    ...query.productPrice.headAgreement,
                    byId: {
                      ...query.productPrice.headAgreement.byId,
                      routeAgreements:
                        query.productPrice.headAgreement.byId.routeAgreements.filter(
                          (ra) => ra.id !== routeAgreement.id
                        ),
                      multiLegAgrRoutes:
                        query.productPrice.headAgreement.byId?.multiLegAgrRoutes.map(
                          (mlar) => {
                            return {
                              ...mlar,
                              routeAgreements: mlar.routeAgreements.filter(
                                (ra) => ra.id !== routeAgreement.id
                              ),
                            };
                          }
                        ),
                    },
                  },
                },
              };

              store.writeQuery({
                query: headAgreementQuery,
                variables: {
                  customerId: routeAgreement.headAgreement.customer.id,
                  year,
                  headAgreementId,
                },
                data: newQuery,
              });
            }
          }
        },
      });

      const mutationResult =
        data?.productPrice.routeAgreement.deleteRouteAgreement;
      if (mutationResult && "errors" in mutationResult) {
        throw new Error(mutationResult.errors.join("\n"));
      } else {
        onRequestClose();
      }
    } catch (e) {
      setBannerError("Failed to delete", e);
    }
  };

  return (
    <Window
      isOpen={true}
      width={"500px"}
      onRequestClose={onRequestClose}
      headerText={
        routeAgreement.multiLegAgrRouteId
          ? `Delete ML route agreement ${routeAgreement.agreementNumber}?`
          : `Delete route agreement ${routeAgreement.agreementNumber}?`
      }
    >
      <Column indent spacing>
        <Column indent spacing>
          <Text variant={"bold"}>Agreement</Text>
          <Spacing num={3}>
            <Label textWidth={"58px"} text={"Number"} row>
              <Text>{routeAgreement.agreementNumber}</Text>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Route"} row>
              <Text>{routeAgreement.routeDescription}</Text>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Name"} row>
              <Text>{routeAgreement.name}</Text>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Type"} row>
              <Text>
                {routeAgreement.sailingTypeCodes
                  .map((code) => formatLongSailingType(code))
                  .join(", ")}
              </Text>
            </Label>
            <SeparatorLine />
            <Space />

            <Label textWidth={"58px"} text={"Validity"} row>
              <Text>{formatGQLDateRange(routeAgreement.valid)}</Text>
            </Label>
          </Spacing>

          {!canDelete && (
            <>
              <Row
                border={`1px solid ${cssColor("--lhds-color-ui-300")}`}
                indent
                spacing
              >
                <Icon
                  icon={stenaExclamationTriangle}
                  color={cssColor("--lhds-color-blue-500")}
                />
                <Indent />
                <Text>You can only delete agreements with status PENDING.</Text>
              </Row>
              <Spacing num={2} />
            </>
          )}
          <Row justifyContent={"flex-end"}>
            <FlatButton onClick={onRequestClose} label={"No"} />
            <Space num={2} />
            <PrimaryButton
              loading={loading}
              disabled={!canDelete}
              variant={"danger"}
              onClick={onDeleteRouteAgreement}
              label={"Yes, delete it"}
            />
          </Row>
        </Column>
        {hasBannerError && bannerErrorText && (
          <>
            <Space num={4} />
            <Banner
              text={bannerErrorText}
              headerText={bannerErrorHeaderText}
              variant={"error"}
            />
          </>
        )}
      </Column>
    </Window>
  );
};
