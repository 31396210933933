import {
  formatHoursMinutesWithColon,
  formatDateTime,
} from "@/common/formatters/DateFormatter";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Indent, Txt, useBoolean } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTable,
  StandardTableConfig,
} from "@stenajs-webui/grid";
import { Drawer } from "@stenajs-webui/modal";
import { cssColor } from "@stenajs-webui/theme";
import { Tooltip } from "@stenajs-webui/tooltip";
import { useCallback, useMemo, useState } from "react";
import { DrawerHeader } from "../../../common/components/drawer/DrawerHeader";
import { BookingTableCheckboxWrapper } from "./components/BookingTableCheckboxWrapper";
import { HandleBookingStatusEditor } from "./components/features/handle-booking-status/HandleBookingStatusEditor";
import {
  noShowLateHandlingHandleBookingsColumnOrder,
  NoShowLateHandlingHandleBookingsRow,
  NoShowLateHandlingHandleBookingsTableColumnKeys,
} from "./config/RowsAndColumnsConfig";
import { NoShowLateHandlingHandleBookingsDetailView } from "./NoShowLateHandlingHandleBookingsDetailView";
import { HandlingCategories } from "./NoShowLateHandlingHandleBookingsScreen";
import { createHandleBookingsStandardTableCell } from "./NoShowLateHandlingHandleBookingsStandardTableCell";
import { NoShowLateHandlingStatus } from "@/features/search-filter/types/FilterEntitys";

interface TableConfigVariables {
  open: () => void;
  isOpen: boolean;
  selectedBooking?: NoShowLateHandlingHandleBookingsRow;
  customerNo: string;
  setBookingNumber: (no: number) => void;
  handlingCategories: HandlingCategories;
  isCashCustomer: boolean;
  isStatusChangeDisabled?: boolean;
}

export const noShowLateHandlingHandleBookingsBookingsTableConfig = ({
  open,
  isOpen,
  selectedBooking,
  customerNo,
  setBookingNumber,
  handlingCategories,
  isCashCustomer,
  isStatusChangeDisabled,
}: TableConfigVariables) => {
  const noShowLateHandlingStatus = (
    code: string
  ): NoShowLateHandlingStatus | undefined => {
    return handlingCategories.handlingStatuses?.find(
      (status) => status.code === code
    );
  };

  const noShowLateHandlingTableConfig: StandardTableConfig<
    NoShowLateHandlingHandleBookingsRow,
    NoShowLateHandlingHandleBookingsTableColumnKeys
  > = {
    disableInfiniteList: true,
    disableSorting: false,
    initialSortOrder: "bookingNo",
    enableGridCell: true,
    stickyHeader: true,
    rowIndent: 4,
    keyResolver: (item) => String(item.bookingNo),
    rowBackgroundResolver: (item) =>
      isOpen && selectedBooking?.bookingNo === item.bookingNo
        ? cssColor("--lhds-color-blue-50")
        : undefined,
    columns: {
      checkboxColumn: createColumnConfig((item) => "", {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => {
          return (
            <BookingTableCheckboxWrapper
              customerNo={customerNo}
              bookingNo={item.bookingNo}
              disableCashCustomers={isCashCustomer}
            />
          );
        },
        columnLabel: "",
        width: "48px",
        disableGridCell: true,
      }),
      bookingNo: createColumnConfig((item) => item.bookingNo, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt
                onClick={() => {
                  setBookingNumber(item.bookingNo);
                  open();
                }}
                style={{ cursor: "pointer" }}
                color={cssColor("--lhds-color-blue-400")}
              >
                {item.bookingNo}
              </Txt>
            </Indent>
          );
        },
        columnLabel: "Booking no.",
        minWidth: "120px",
      }),
      bookingVersion: createColumnConfig((item) => item.bookingVersion, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.bookingVersion),
        columnLabel: "Ver.",
        minWidth: "37px",
      }),
      codeNoShowLateType: createColumnConfig(
        (item) =>
          handlingCategories.handlingTypes?.find(
            (type) => type.code === item.codeNoShowLateType.code
          )?.name ?? item.codeNoShowLateType,
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell: ({ label, value, item }) =>
            createHandleBookingsStandardTableCell(
              handlingCategories.handlingTypes?.find(
                (type) => type.code === item.codeNoShowLateType.code
              )?.name ?? ""
            ),
          columnLabel: "Type",
          minWidth: "110px",
        }
      ),
      handlingStatus: createColumnConfig((item) => item.handlingStatus, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => (
          <HandleBookingStatusEditor
            handlingStatusCode={
              noShowLateHandlingStatus(item.handlingStatus)?.code ?? ""
            }
            handlingStatusDescription={
              noShowLateHandlingStatus(item.handlingStatus)?.name ?? ""
            }
            bookingNo={item.bookingNo}
            cashCustomer={isCashCustomer}
            disabled={isStatusChangeDisabled ?? false}
          />
        ),
        columnLabel: "Status",
        minWidth: "130px",
      }),
      excludeReason: createColumnConfig((item) => item.excludeReason, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.excludeReason?.code),
        columnLabel: "Reason",
        minWidth: "50px",
      }),
      routeCode: createColumnConfig((item) => item.route.code, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.route.code),
        columnLabel: "Route",
        minWidth: "50px",
      }),
      departureDate: createColumnConfig((item) => item.departureDate, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.departureDate),
        columnLabel: "Dep. date",
        minWidth: "100px",
      }),
      departureTime: createColumnConfig(
        (item) => formatHoursMinutesWithColon(item.departureTime),
        {
          justifyContentHeader: "flex-start",
          justifyContentCell: "flex-start",
          renderCell: ({ label, value, item }) => {
            return (
              <Indent>
                <Txt>{formatHoursMinutesWithColon(item.departureTime)}</Txt>
              </Indent>
            );
          },
          columnLabel: "Dep. time",
          minWidth: "100px",
        }
      ),
      vehicleType: createColumnConfig((item) => item.vehicleType?.code, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.vehicleType?.code),
        columnLabel: "VT",
        minWidth: "50px",
      }),
      lateHandlingDate: createColumnConfig((item) => item.lateHandlingDate, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt>{formatDateTime(item.lateHandlingDate ?? "")}</Txt>
            </Indent>
          );
        },
        columnLabel: "NSLH date",
        minWidth: "140px",
      }),
      lateHandlingUser: createColumnConfig((item) => item.lateHandlingUser, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "flex-start",
        renderCell: ({ label, value, item }) =>
          createHandleBookingsStandardTableCell(item.lateHandlingUser),
        columnLabel: "NSLH user",
        minWidth: "80px",
      }),
      note: createColumnConfig((item) => item.note, {
        justifyContentHeader: "flex-start",
        justifyContentCell: "start",
        disableGridCell: true,
        renderCell: ({ label, value, item }) => {
          if (item.note === "" || item.note === null) {
            return <></>;
          }
          return (
            <Tooltip label={item.note} placement="top">
              <Txt>{`${
                item.note.length > 10
                  ? item.note.substring(0, 10) + "..."
                  : item.note
              }`}</Txt>
            </Tooltip>
          );
        },
        columnLabel: "B&C note",
        minWidth: "100px",
      }),
    },
    columnOrder: noShowLateHandlingHandleBookingsColumnOrder,
  };

  return noShowLateHandlingTableConfig;
};

interface Props {
  customerBookingsData: Array<NoShowLateHandlingHandleBookingsRow>;
  customerNo: string;
  handlingCategories: HandlingCategories;
  isCashCustomer: boolean;
  customerNrOfInvoiced: number;
}

export const NoShowLateHandlingHandleCustomerBookingsTable: React.FC<Props> = ({
  customerBookingsData,
  customerNo,
  handlingCategories,
  isCashCustomer,
  customerNrOfInvoiced,
}) => {
  const [isOpen, open, close] = useBoolean(false);
  const [selectedBooking, setSelectedBooking] = useState<
    NoShowLateHandlingHandleBookingsRow | undefined
  >(undefined);

  const setBookingNumber = useCallback(
    (bookingNo) => {
      setSelectedBooking(
        customerBookingsData.find((bd) => bd.bookingNo === bookingNo)
      );
    },
    [customerBookingsData]
  );

  const config = useMemo(() => {
    const isStatusChangeDisabled = customerNrOfInvoiced > 0;
    return noShowLateHandlingHandleBookingsBookingsTableConfig({
      open,
      isOpen,
      selectedBooking,
      customerNo,
      setBookingNumber,
      handlingCategories,
      isCashCustomer,
      isStatusChangeDisabled,
    });
  }, [
    open,
    setBookingNumber,
    customerNo,
    handlingCategories,
    isOpen,
    selectedBooking,
    customerNrOfInvoiced,
    isCashCustomer,
  ]);

  return (
    <Box background={cssColor("--lhds-color-ui-50")}>
      {isOpen && (
        <Drawer
          isOpen={isOpen}
          background={cssColor("--lhds-color-ui-50")}
          slideFrom={"right"}
          onRequestClose={close}
          zIndex={ZIndex.highest}
          width={"400px"}
        >
          <Box>
            <DrawerHeader label={"Booking details"} onClickClose={close} />
            <NoShowLateHandlingHandleBookingsDetailView
              customerBooking={selectedBooking}
              handlingCategories={handlingCategories}
            />
          </Box>
        </Drawer>
      )}
      <StandardTable
        items={customerBookingsData ?? []}
        config={config}
        noItemsHeader={"Customer has no bookings."}
      />
    </Box>
  );
};
