import {
  ByCustomerNumberOrIndexFragment,
  CustomersSelectQuery,
  CustomersSelectQueryVariables,
} from "@/gql/graphql";
import { gql, useApolloClient } from "@apollo/client";
import { useDebounce } from "@stenajs-webui/core";
import { AsyncSelect, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useState } from "react";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: ByCustomerNumberOrIndexFragment) => void;
  placeholder?: string;
}

const query = gql`
  fragment ByCustomerNumberOrIndex on Customer {
    id
    name
    custNo
    custIndex
  }
  query CustomersSelect($query: String!) {
    productPrice {
      customers {
        byCustomerNumberOrIndex(query: $query) {
          ...ByCustomerNumberOrIndex
        }
      }
    }
  }
`;

interface CustomerOption {
  label: string;
  value: string;
  data: ByCustomerNumberOrIndexFragment;
}

export const CustomerSelect: React.FC<Props> = ({
  value,
  onValueChange,
  defaultInputValue,
  placeholder,
  ...selectProps
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultInputValue
  );

  const debouncedInputValue = useDebounce(inputValue, 1000);

  const client = useApolloClient();

  const loadOptions = async (inputValue: string): Promise<CustomerOption[]> => {
    if (inputValue.length < 2) {
      return [];
    }

    const result = await client.query<
      CustomersSelectQuery,
      CustomersSelectQueryVariables
    >({
      query: query,
      variables: { query: inputValue },
    });

    return (
      result.data?.productPrice.customers.byCustomerNumberOrIndex.map(
        (customer) => ({
          data: customer,
          label: `${customer.name} (${customer.custIndex} ${customer.custNo})`,
          value: customer.id,
        })
      ) ?? []
    );
  };

  return (
    <AsyncSelect<CustomerOption>
      onInputChange={setInputValue}
      loadOptions={loadOptions}
      placeholder={
        placeholder ?? "Search for customer number or customer index"
      }
      onChange={(v: any) => onValueChange?.(v.data)}
      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
        if (inputValue.length === 0) {
          return "Search for customer number or customer index";
        }
        if (inputValue.length < 2 || debouncedInputValue !== inputValue) {
          return "Type at least 2 characters to search";
        }

        return "No customers found";
      }}
      {...selectProps}
    />
  );
};
