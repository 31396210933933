import * as React from "react";
import { useMemo } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { createEmptyHeadAgreementFormModel } from "../../common/models/HeadAgreementFormModel";
import { transformToFormModel } from "../../common/transformers/HeadAgreementFormModelTransformer";
import { HeadAgreementFormContainer } from "./HeadAgreementFormContainer";
import {
  HeadAgreementModelLoaderQuery,
  HeadAgreementModelLoaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  customerId: string;
}

const query = gql`
  fragment HeadAgreementModelLoaderHeadAgreements on HeadAgreement {
    id
    rowVersion
    headAgreementNumber
    statusCode
    internalComments
    specifications
    condition
    lastPage
    name
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    routeAgreements {
      id
      name
      routeDescription
      statusCode
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
      currency {
        id
        code
      }
    }
  }
  query HeadAgreementModelLoader($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...HeadAgreementModelLoaderHeadAgreements
        }
      }
    }
  }
`;

export const HeadAgreementModelLoader: React.FC<Props> = ({
  customerId,
  headAgreementId,
}) => {
  const { data, error, loading } = useQuery<
    HeadAgreementModelLoaderQuery,
    HeadAgreementModelLoaderQueryVariables
  >(query, {
    variables: {
      headAgreementId,
    },
  });

  const headAgreement = data?.productPrice.headAgreement.byId;

  const formModel = useMemo(() => {
    if (headAgreement) {
      return transformToFormModel(headAgreement);
    } else {
      return createEmptyHeadAgreementFormModel();
    }
  }, [headAgreement]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !headAgreement) {
    return <ErrorScreen />;
  }

  return (
    <HeadAgreementFormContainer
      initialFormModel={formModel}
      headAgreementId={headAgreementId}
      customerId={customerId}
      rowVersion={headAgreement.rowVersion}
    />
  );
};
