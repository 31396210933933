import { gql } from "apollo-boost";

export const validateRoutesForAgreementQuery = gql`
  query RoutesValidation(
    $headAgreementId: ID!
    $custNo: Int!
    $multiLegAgreements: [String!]
    $routeAgreements: [String!]
  ) {
    validateRoutesForAgreement(
      headAgreementId: $headAgreementId
      custNo: $custNo
      multiLegAgreements: $multiLegAgreements
      routeAgreements: $routeAgreements
    ) {
      validationResult {
        validationErrors {
          path
          errorMessage
          severityLevel
          statusCode
        }
        resourceId
        statusCode
      }
    }
  }
`;
