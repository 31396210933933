import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { RouteAgreementsForMultilegRouteFragment } from "@/gql/graphql";
import { Column, useBoolean } from "@stenajs-webui/core";
import { FlatButton, stenaSailingTruckAlt } from "@stenajs-webui/elements";
import { Drawer } from "@stenajs-webui/modal";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { DrawerHeader } from "../../../../../common/components/drawer/DrawerHeader";
import { AddVehicleTypeFormModel } from "../models/AddVehicleTypeFormModel";
import { AddVehicleType } from "./AddVehicleType";

interface Props {
  editableRouteAgreement: boolean;
  multiLegAgreement?: RouteAgreementsForMultilegRouteFragment;
  addVehicleTypeToAllRoutesRadioButton?: boolean;
  onAddRow: (
    formModel: AddVehicleTypeFormModel,
    multiLegRouteAgreementId?: string
  ) => void;
}

export const AddVehicleTypeButton: React.FC<Props> = ({
  onAddRow,
  multiLegAgreement,
  editableRouteAgreement,
  addVehicleTypeToAllRoutesRadioButton,
}) => {
  const [isOpen, open, close] = useBoolean(false);

  return (
    <>
      <FlatButton
        label={"Add vehicle type"}
        onClick={open}
        disabled={!editableRouteAgreement}
        data-testid={testIdConstantsWebapp.createRouteRatesAddVehicleType}
      />
      <Drawer
        isOpen={isOpen}
        onRequestClose={close}
        width={"260px"}
        slideFrom={"right"}
        background={cssColor("--lhds-color-ui-50")}
        zIndex={ZIndex.highest}
      >
        {isOpen && (
          <Column overflowY={"auto"} height={"100%"}>
            <DrawerHeader
              label={"Add vehicle"}
              onClickClose={close}
              iconLeft={stenaSailingTruckAlt}
            />
            <Column indent spacing>
              <AddVehicleType
                onRequestClose={close}
                multiLegAgreement={multiLegAgreement}
                addVehicleTypeToAllRoutesRadioButton={
                  addVehicleTypeToAllRoutesRadioButton
                }
                onAddRow={onAddRow}
              />
            </Column>
          </Column>
        )}
      </Drawer>
    </>
  );
};
