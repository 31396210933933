import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { headAgreementQuery } from "../../../head-agreement/details/hooks/UseHeadAgreementById";
import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  EditMultiLegRouteAgreementMutation,
  EditMultiLegRouteAgreementMutationVariables,
  HeadAgreementQuery,
  HeadAgreementQueryVariables,
  UpdateMultiLegRouteAgreementInput,
} from "@/gql/graphql";

export const mutation = gql`
  ${errorFragment}
  mutation EditMultiLegRouteAgreement(
    $updateMultiLegRouteAgreementInput: UpdateMultiLegRouteAgreementInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        updateMultiLegRouteAgreement(
          input: $updateMultiLegRouteAgreementInput
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateMultiLegRouteAgreementSuccessResult {
            multiLegAgreement {
              id
              headAgreement {
                id
              }
              name
              route {
                code
                name
                id
                routeLegs {
                  id
                  legNo
                  route {
                    id
                    code
                    name
                  }
                }
              }
              currency {
                id
                code
                name
                shortDescription
                longDescription
              }
              valid {
                start {
                  isoString
                }
                end {
                  isoString
                }
              }
              dateCreated
              createdBy
              dateModified
              modifiedBy
              idNumber
              rowVersion
              workFlowStatusCode
              statusCode
              routeAgreements {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useEditMultiLegRouteAgreement = () => {
  const [mutate, { loading, error }] = useMutation<
    EditMultiLegRouteAgreementMutation,
    EditMultiLegRouteAgreementMutationVariables
  >(mutation);

  const editMultiLegRouteAgreement = useCallback(
    (
      input: UpdateMultiLegRouteAgreementInput,
      customerId: string,
      headAgreementId: string,
      year: number
    ) => {
      return mutate({
        variables: {
          updateMultiLegRouteAgreementInput: input,
        },
        update: (cache, { data }) => {
          const mutationResult =
            data?.productPrice.multiLegRouteAgreement
              .updateMultiLegRouteAgreement;
          if (!(mutationResult && "errors" in mutationResult)) {
            const query = cache.readQuery<
              HeadAgreementQuery,
              HeadAgreementQueryVariables
            >({
              query: headAgreementQuery,
              variables: {
                customerId,
                headAgreementId,
                year,
              },
            });

            if (query && query.productPrice.headAgreement.byId) {
              const newQuery: HeadAgreementQuery = {
                ...query,
                productPrice: {
                  ...query.productPrice,
                  headAgreement: {
                    ...query.productPrice.headAgreement,
                    byId: {
                      ...query.productPrice.headAgreement.byId,
                      multiLegAgrRoutes: {
                        ...query.productPrice.headAgreement.byId
                          ?.multiLegAgrRoutes,
                      },
                      routeAgreements: {
                        ...query.productPrice.headAgreement.byId
                          .routeAgreements,
                      },
                      valid: {
                        ...query.productPrice.headAgreement.byId.valid,
                      },
                    },
                  },
                },
              };

              cache.writeQuery({
                query: headAgreementQuery,
                variables: {
                  customerId,
                  headAgreementId,
                  year,
                },
                data: newQuery,
              });
            }
          }
        },
      });
    },
    [mutate]
  );
  return {
    editMultiLegRouteAgreement,
    loading,
    error,
  };
};
