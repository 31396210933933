export enum TabsRouting {
  Rates = "Rates",
  RatesPath = "/rates",
  Routes = "Routes",
  RoutesPath = "/routes",
  Articles = "Articles",
  ArticlesPath = "/articles",
  Notes = "Notes",
  NotesPath = "/notes",
  Conditions = "Conditions",
  ConditionsPath = "/conditions",
  Matrix = "Matrix",
  MatrixPath = "/matrix",
}
