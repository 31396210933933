import { CustomerYearStatistics } from "../../customers/customer-details/types";
import {
  groupCustomerStatisticsBySailingCategoryCode,
  groupCustomerStatisticsByUnitCategoryCode,
} from "./CustomerStatisticsGrouper";
import { sumExpectedVsLastYear } from "./SumExpected";
import { BarStatsData } from "../../../../common/components/bar-stat-box/components/BarStat";
import { BarChartTableColumn } from "../../../../common/components/bar-stat-box/components/BarStatBox";
import { cssColor } from "@stenajs-webui/theme";

export const darkBlue = cssColor("--lhds-color-blue-900");
export const turquoise = cssColor("--lhds-color-turquoise-400");
export const middleBlue = cssColor("--lhds-color-blue-500");

interface BarStatsDataGroup {
  data: BarStatsData[];
  columns: BarChartTableColumn[];
}

export const calculateBarStatsData = (
  data: CustomerYearStatistics[]
): {
  unitCodeGroup: BarStatsDataGroup;
  sailingCodeGroup: BarStatsDataGroup;
} => {
  const { accompanied, unaccompanied } =
    groupCustomerStatisticsByUnitCategoryCode(data);
  const { peak, offPeak, shoulder } =
    groupCustomerStatisticsBySailingCategoryCode(data);

  const sailingCodeGroup = [
    sumExpectedVsLastYear(offPeak, "offPeak", darkBlue),
    sumExpectedVsLastYear(shoulder, "shoulder", turquoise),
    sumExpectedVsLastYear(peak, "peak", middleBlue),
  ];

  const unitCodeGroup = [
    sumExpectedVsLastYear(unaccompanied, "unaccompanied", darkBlue),
    sumExpectedVsLastYear(accompanied, "accompanied", middleBlue),
  ];

  return {
    sailingCodeGroup: {
      data: sailingCodeGroup,
      columns: [
        { dotColor: darkBlue, label: "O" },
        { dotColor: turquoise, label: "S" },
        { dotColor: middleBlue, label: "P" },
      ],
    },
    unitCodeGroup: {
      data: unitCodeGroup,
      columns: [
        { dotColor: darkBlue, label: "UNACC" },
        { dotColor: middleBlue, label: "ACC" },
        { label: "TOTAL" },
      ],
    },
  };
};
