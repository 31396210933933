import { NoteChoice } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { stenaSailingTicket } from "@stenajs-webui/elements";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import {
  FPPFilterCheckBoxList,
  FilterCheckboxType,
} from "./FPPFilterCheckBoxList";

const items: Array<NoteChoice> = [
  { id: "Y", name: "Yes", code: "YES" },
  { id: "N", name: "No", code: "NO" },
];
interface BookAndCheckInNoteFilterSectionProps {
  enabled?: boolean;
  label?: string;
}
export const BookAndCheckInNoteFilterSection: React.FC<
  BookAndCheckInNoteFilterSectionProps
> = ({ enabled, label }) => {
  const { bookAndCheckInNotes, setBookAndCheckInNotes } = useFilterWorkspace();
  return (
    <FppSearchFilterSection
      enabled={enabled}
      label={label ?? "Booking"}
      numSelected={bookAndCheckInNotes?.length ? 1 : undefined}
      sectionId={"bcNote"}
      leftIcon={stenaSailingTicket}
    >
      <FPPFilterCheckBoxList
        checkboxes={items}
        selectedValues={bookAndCheckInNotes}
        setValue={(newValue: FilterCheckboxType[]) =>
          setBookAndCheckInNotes(newValue as NoteChoice[])
        }
      />
    </FppSearchFilterSection>
  );
};
