import { RateSheetTableRowsState } from "../../rate-sheet/reducer";
import { RateSheetRouteAgreementPrice } from "../../rate-sheet/types";
import { transformCargoStatusCodeToEntity } from "../../../../common/transformers/CargoStatusTransformer";
import { RecordObjectState } from "@stenajs-webui/redux";
import {
  RateSheetChanges,
  RateSheetPriceChangesInput,
  UpdateRateSheetsRouteAgreementItemInput,
} from "@/gql/graphql";

export const routeAgreementHasChanges = (
  item: UpdateRateSheetsRouteAgreementItemInput
): boolean =>
  item.changes.created.length > 0 ||
  item.changes.updated.length > 0 ||
  item.changes.deleted.length > 0;

export const getRateSheetsForRouteAgreementsWithChanges = (
  records: RecordObjectState<RateSheetTableRowsState>
): RecordObjectState<RateSheetTableRowsState> => {
  const routeAgreementIds = Object.keys(records);
  return routeAgreementIds.reduce<RecordObjectState<RateSheetTableRowsState>>(
    (sum, routeAgreementId) => {
      const state = records[routeAgreementId];
      if (state) {
        const changes = getRateSheetsWithChanges(state);
        if (changes.length > 0) {
          sum[routeAgreementId] = changes;
        }
      }

      return sum;
    },
    {}
  );
};

export const getRateSheetsWithChanges = (
  state: RateSheetTableRowsState
): RateSheetTableRowsState => state.filter((s) => s.modified);

export const transformRateSheetChangesToInput = (
  state: RateSheetTableRowsState
): RateSheetChanges => {
  const updated = state
    .filter(
      (l) =>
        l.editable != null && l.persisted != null && l.modified && !l.deleted
    )
    .map((l) => ({
      routeAgreementPriceId: l.routeAgreementPriceId,
      changes: transformRateSheetRowChangesToInput(l.persisted!, l.editable!),
    }));

  const deleted = state
    .filter((l) => l.deleted)
    .map((l) => ({ routeAgreementPriceId: l.routeAgreementPriceId }));

  const created = state
    .filter((l) => l.persisted == null && l.editable != null)
    .map((l) => ({
      changes: transformRateSheetRowCreateToInput(l.editable!),
    }));

  return {
    created,
    deleted,
    updated,
  };
};

export const transformRateSheetRowChangesToInput = (
  persisted: RateSheetRouteAgreementPrice,
  editable: RateSheetRouteAgreementPrice
): RateSheetPriceChangesInput => {
  const newEmptyLoadedValues =
    persisted.cargoStatusCode !== editable.cargoStatusCode
      ? transformCargoStatusCodeToEntity(editable.cargoStatusCode)
      : null;
  return {
    acc:
      persisted.unaccompanied !== editable.unaccompanied
        ? { value: editable.unaccompanied === false }
        : null,
    unacc:
      persisted.unaccompanied !== editable.unaccompanied
        ? { value: editable.unaccompanied === true }
        : null,
    additionalFrom:
      persisted.additionalFrom !== editable.additionalFrom
        ? { value: editable.additionalFrom }
        : null,
    empty: newEmptyLoadedValues ? { value: newEmptyLoadedValues.empty } : null,
    loaded: newEmptyLoadedValues
      ? { value: newEmptyLoadedValues.loaded }
      : null,
    lengthFrom:
      persisted.lengthFrom !== editable.lengthFrom
        ? { value: editable.lengthFrom }
        : null,
    lengthTo:
      persisted.lengthTo !== editable.lengthTo
        ? { value: editable.lengthTo }
        : null,
    avgLength:
      persisted.avgLength !== editable.avgLength
        ? { value: editable.avgLength }
        : null,
    weightFrom:
      persisted.weightFrom !== editable.weightFrom
        ? { value: editable.weightFrom }
        : null,
    weightTo:
      persisted.weightTo !== editable.weightTo
        ? { value: editable.weightTo }
        : null,
    meterPrice:
      persisted.meterPrice !== editable.meterPrice
        ? { value: { amount: editable.meterPrice.amount } }
        : null,
    newPrice:
      persisted.newPrice !== editable.newPrice
        ? { value: { amount: editable.newPrice.amount } }
        : null,
    vehicleTypeId:
      persisted.vehicleType?.id !== editable.vehicleType?.id
        ? { value: editable.vehicleType?.id }
        : null,
    actual:
      persisted.actual !== editable.actual ? { value: editable.actual } : null,
    volume:
      persisted.volume !== editable.volume ? { value: editable.volume } : null,
    avgGrossPrice:
      persisted.avgGrossPrice?.amount !== editable.avgGrossPrice?.amount
        ? {
            value: editable.avgGrossPrice
              ? { amount: editable.avgGrossPrice?.amount }
              : null,
          }
        : null,
    avgRebate:
      persisted.avgRebate?.amount !== editable.avgRebate?.amount
        ? {
            value: editable.avgRebate
              ? { amount: editable.avgRebate?.amount }
              : null,
          }
        : null,
    lastPrice:
      persisted.lastPrice !== editable.lastPrice
        ? {
            value: editable.lastPrice
              ? { amount: editable.lastPrice.amount }
              : null,
          }
        : null,
    lastChargedFrom:
      persisted.lastChargedFrom !== editable.lastChargedFrom
        ? { value: editable.lastChargedFrom }
        : null,
    lastMeterPrice:
      persisted.lastMeterPrice !== editable.lastMeterPrice
        ? {
            value: editable.lastMeterPrice
              ? { amount: editable.lastMeterPrice.amount }
              : null,
          }
        : null,
  };
};

export const transformRateSheetRowCreateToInput = (
  editable: RateSheetRouteAgreementPrice
): RateSheetPriceChangesInput => {
  return {
    acc: { value: editable.unaccompanied === false },
    unacc: { value: editable.unaccompanied === true },
    additionalFrom: { value: editable.additionalFrom },
    empty: {
      value:
        editable.cargoStatusCode === "EMPTY" ||
        editable.cargoStatusCode === "BOTH",
    },
    loaded: {
      value:
        editable.cargoStatusCode === "LOADED" ||
        editable.cargoStatusCode === "BOTH",
    },
    lengthFrom: { value: editable.lengthFrom },
    lengthTo: { value: editable.lengthTo },
    weightFrom: { value: editable.weightFrom },
    weightTo: { value: editable.weightTo },
    meterPrice: { value: { amount: editable.meterPrice.amount } },
    newPrice: { value: { amount: editable.newPrice.amount } },
    vehicleTypeId: { value: editable.vehicleType?.id ?? null },
    actual: { value: editable.actual },
    avgLength: { value: editable.avgLength },
    avgGrossPrice: {
      value: editable.avgGrossPrice?.amount
        ? { amount: editable.avgGrossPrice?.amount }
        : null,
    },
    lastPrice: {
      value: editable.lastPrice?.amount
        ? { amount: editable.lastPrice?.amount }
        : null,
    },
    volume: { value: editable.volume },
  } as RateSheetPriceChangesInput;
};
