import { RouteAgreementForForm } from "../../types";

export interface ConditionsFormModel {
  countrySender: Array<string>;
  countryReceiver: Array<string>;
  goods: Array<string>;
  standby: boolean;
  transit: boolean;
}

export const emptyConditionsFormModel: ConditionsFormModel = {
  countryReceiver: [],
  countrySender: [],
  goods: [],
  standby: false,
  transit: false,
};

export const transformRouteAgreementToConditionsForm = (
  routeAgreement: RouteAgreementForForm
): ConditionsFormModel => {
  return {
    transit: routeAgreement.transit,
    standby: routeAgreement.standby,
    goods: routeAgreement.goods.map((g) => g.id),
    countrySender: routeAgreement.senderCountries.map((country) => country.id),
    countryReceiver: routeAgreement.receiverCountries.map(
      (country) => country.id
    ),
  };
};
