import { UseAllFormulaTypesQuery } from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";

const query = gql`
  query UseAllFormulaTypes {
    productPrice {
      formula {
        all {
          code
          id
          description
          includes {
            drivers
            interval1PercentValue
            interval2PercentValue
            interval3PercentValue
            interval4PercentValue
            intervalAPercentValue
            intervalBPercentValue
            intervalCPercentValue
            intervalDPercentValue
            intervalLength
            maxPercent
            percent
            weight
            width
          }
        }
      }
    }
  }
`;

export const useAllFormulaTypes = () => {
  const { data, loading, error } = useQuery<UseAllFormulaTypesQuery>(query);

  return {
    formulaTypes: data?.productPrice.formula.all,
    loading,
    error,
  };
};
