import { useLazyQuery } from "@apollo/client";
import {
  transformBookingToPriceCalculatorInput,
  transformToBookingSearchResultData,
} from "../price-calculator-result-panel/transformers/PriceCalculatorFormResultTransformer";
import { useEffect, useState } from "react";
import { ApolloError, gql } from "apollo-boost";
import {
  BookingSearchResultData,
  fetchConsignmentFragment,
  fetchPriceCalcFragment,
} from "../PriceCalculatorFormFetcher";
import { usePriceCalculation } from "./UsePriceCalculation";
import {
  getPriceCalculatorMultipleData,
  getPriceCalculatorMultipleError,
} from "../utils/UnionHelpers";
import {
  ConsignmentMultiLegQuery,
  ConsignmentMultiLegQueryVariables,
  ConsignmentQuery,
  ConsignmentQueryVariables,
  PriceCalcInput,
  PriceCalculatorMultipleResultQuery,
  PriceCalculatorMultipleResultQueryVariables,
} from "@/gql/graphql";

const movementTypeCodeMultiLeg = "M";

export const useConsignmentPriceCalculation = () => {
  const [resonseError, setResponseError] = useState<ApolloError | undefined>();
  const {
    calculatePrice,
    loading: priceCalculationLoading,
    data: priceCalculationData,
  } = usePriceCalculation();
  const [bookingResult, setBookingResult] = useState<BookingSearchResultData>();

  const queryConsignment = gql`
    ${fetchConsignmentFragment}
    query Consignment($bookingNum: ID!, $consignmentVersion: Int!) {
      priceCalc {
        consignments {
          byId(id: $bookingNum, version: $consignmentVersion) {
            ...FetchConsignment
          }
        }
      }
    }
  `;

  const queryConsignmentMultiLeg = gql`
    ${fetchConsignmentFragment}
    query ConsignmentMultiLeg($multilegId: ID!) {
      priceCalc {
        consignments {
          byMultiLegId(id: $multilegId) {
            ...FetchConsignment
          }
        }
      }
    }
  `;

  const queryMultiple = gql`
    ${fetchPriceCalcFragment}
    query PriceCalculatorMultipleResult($input: [PriceCalcInput!]!) {
      priceCalc {
        byMultipleInput(variables: $input) {
          __typename
          ... on PriceCalcError {
            error
            errorType
          }
          ... on PriceCalcMultipleList {
            list {
              ...FetchPriceCalc
            }
          }
        }
      }
    }
  `;

  const [
    fetchConsignment,
    { loading: loadingConsignment, data: dataConsignment },
  ] = useLazyQuery<ConsignmentQuery, ConsignmentQueryVariables>(
    queryConsignment,
    {
      async onCompleted(data) {
        const consignmentData = data?.priceCalc?.consignments?.byId;
        if (consignmentData) {
          const dataConsignmentTransformed =
            transformBookingToPriceCalculatorInput(consignmentData);
          calculateByBooking(
            dataConsignmentTransformed,
            consignmentData.referenceBookingNo
          );
        }
      },
    }
  );

  useEffect(() => {
    if (priceCalculationData) {
      const consignments = dataConsignment
        ? [dataConsignment?.priceCalc.consignments.byId]
        : [];
      setBookingResult(
        transformToBookingSearchResultData(priceCalculationData, consignments)
      );
    }
  }, [priceCalculationData, dataConsignment]);

  const [
    fetchConsignmentMultiLeg,
    { loading: loadingConsignmentMultiLeg, data: dataConsignmentMultiLeg },
  ] = useLazyQuery<ConsignmentMultiLegQuery, ConsignmentMultiLegQueryVariables>(
    queryConsignmentMultiLeg,
    {
      async onCompleted(data) {
        const consignmentMultiLegsData:
          | ConsignmentMultiLegQuery["priceCalc"]["consignments"]["byMultiLegId"]["0"][]
          | undefined = data?.priceCalc.consignments.byMultiLegId;

        if (consignmentMultiLegsData) {
          const dataConsignmentMultiLegsTransformed =
            consignmentMultiLegsData.map((consignmentMultiLeg) =>
              transformBookingToPriceCalculatorInput(consignmentMultiLeg)
            );
          // Needs to calculate Price for all consignments in array.
          const { error } = await calculateMultiplePrice({
            variables: {
              input: dataConsignmentMultiLegsTransformed,
            },
          });
          setResponseError(error);
        }
      },
    }
  );

  const [
    calculateMultiplePrice,
    { loading: loadingMultiple, data: dataMultiple },
  ] = useLazyQuery<
    PriceCalculatorMultipleResultQuery,
    PriceCalculatorMultipleResultQueryVariables
  >(queryMultiple, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (dataMultiple) {
      const multiLegPriceCalcData = getPriceCalculatorMultipleData(
        dataMultiple?.priceCalc.byMultipleInput
      );
      const multiLegPriceCalcError = getPriceCalculatorMultipleError(
        dataMultiple?.priceCalc.byMultipleInput
      );

      const priceCalcs = multiLegPriceCalcData
        ? multiLegPriceCalcData!.list
        : [];
      const consignments = dataConsignmentMultiLeg
        ? dataConsignmentMultiLeg.priceCalc.consignments.byMultiLegId
        : [];

      const bookingSearchResult: BookingSearchResultData = {
        consignmentData: consignments,
        priceCalcData: priceCalcs,
        handledError: multiLegPriceCalcError ?? undefined,
      };
      setBookingResult(bookingSearchResult);
    }
  }, [dataMultiple, dataConsignmentMultiLeg]);

  const calculateByBooking = async (
    input: PriceCalcInput,
    referenceBookingNo: number
  ) => {
    if (input.movementType === movementTypeCodeMultiLeg) {
      fetchConsignmentMultiLeg({
        variables: {
          multilegId: String(referenceBookingNo),
        },
      });
    } else {
      const { error } = await calculatePrice({
        variables: {
          input: input,
        },
      });
      setResponseError(error);
    }
  };

  return {
    fetchConsignment,
    loading:
      loadingConsignment ||
      loadingConsignmentMultiLeg ||
      loadingMultiple ||
      priceCalculationLoading,
    bookingResult,
    consignmentData: dataConsignment,
    error: resonseError,
  };
};
