import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { AncillariesFragment, AncillaryTypeQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

interface AncillaryOption {
  label: string;
  value: string;
  data: AncillariesFragment;
}

const query = gql`
  fragment Ancillaries on ArticleType {
    id
    code
    name
  }
  query AncillaryType {
    productPrice {
      articleType {
        ancillaries {
          ...Ancillaries
        }
      }
    }
  }
`;

export const AncillarySelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<AncillaryTypeQuery>(query);
  const ancillaries = useMemo(() => {
    return data?.productPrice.articleType.ancillaries ?? [];
  }, [data]);

  const options = useMemo(() => {
    return ancillaries.map((r) => ({
      label: `${r.name} (${r.id})`,
      value: r.id,
      data: r,
    }));
  }, [ancillaries]);

  const selected = useMemo(
    () => options.filter((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected}
      onChange={(value) => onValueChange?.((value as AncillaryOption).value)}
      {...selectProps}
    />
  );
};
