import { formatDateString } from "@/common/dates/formatters";
import { parseFloatElseThrow } from "@/common/numbers";
import {
  FetchAndPopulateRouteAgreementPriceStatisticsQuery,
  FetchAndPopulateRouteAgreementPriceStatisticsQueryVariables,
  RateEditPanelQuery,
  RouteAgreementPriceCalculateStatisticsInput,
} from "@/gql/graphql";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../apollo-client/ApolloClient";
import { transformCargoStatusEntityToCargoStatusCode } from "../../../../common/transformers/CargoStatusTransformer";
import { transformAccUnaccEntityToUnaccBooleanOrNull } from "../../../../common/transformers/UnaccompaniedTransformer";
import { AppThunk } from "../../../../config/redux/RootReducer";
import { transformSailingTypeCodeToEntity } from "../../route-agreement/details/features/routes/RoutesFormModel";
import { rateSheetTableActions } from "../actions";
import { EditRateFormModel } from "../edit-rate-row/models/EditRateFormModel";
import { rateSheetStandardTableWrapperActions } from "../redux";

const query = gql`
  query FetchAndPopulateRouteAgreementPriceStatistics(
    $input: RouteAgreementPriceCalculateStatisticsInput!
  ) {
    productPrice {
      routeAgreementPrice {
        calculateStatistics(input: $input) {
          routeAgreementPriceId
          avgGrossPrice {
            amount
          }
          avgLength
          avgRebate {
            amount
          }
          volume
        }
      }
    }
  }
`;

export const fetchStatisticsForSinglePriceRow =
  (input: RouteAgreementPriceCalculateStatisticsInput): AppThunk =>
  async (dispatch) => {
    const { errors, data } = await apolloClient.query<
      FetchAndPopulateRouteAgreementPriceStatisticsQuery,
      FetchAndPopulateRouteAgreementPriceStatisticsQueryVariables
    >({
      query,
      variables: {
        input,
      },
      fetchPolicy: "network-only",
    });

    if (errors && errors.length) {
      return;
    }

    const { calculateStatistics } = data.productPrice.routeAgreementPrice;

    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        input.routeAgreementId,
        rateSheetTableActions.setEditablePriceFields(
          input.routeAgreementPriceId,
          {
            avgLength: calculateStatistics.avgLength,
            avgGrossPrice: calculateStatistics.avgGrossPrice,
            volume: calculateStatistics.volume,
            avgRebate: calculateStatistics.avgRebate,
          }
        )
      )
    );
  };

export const createRouteAgreementPriceCalculateStatisticsInput = (
  routeAgreement: RateEditPanelQuery["productPrice"]["routeAgreement"]["byId"],
  routeAgreementPriceId: string,
  editFormModel: EditRateFormModel
): RouteAgreementPriceCalculateStatisticsInput => ({
  routeAgreementPriceId: routeAgreementPriceId,
  currencyCode: routeAgreement?.currency.code ?? "",
  routeCodes: routeAgreement?.routes.map((r) => r.code) ?? [],
  validFrom: {
    isoString:
      routeAgreement?.valid.start?.isoString ?? formatDateString(new Date()),
  },
  ...transformSailingTypeCodeToEntity(routeAgreement?.sailingTypeCodes ?? []),
  customerId: routeAgreement?.headAgreement.customer.id ?? "",
  lengthFrom: parseFloatElseThrow(editFormModel.lengthFrom),
  lengthTo: parseFloatElseThrow(editFormModel.lengthTo),
  vehicleTypeId: editFormModel.vehicleTypeId ?? "",
  routeAgreementId: routeAgreement?.id ?? "",
  cargoStatusCode: transformCargoStatusEntityToCargoStatusCode(editFormModel),
  unaccompanied: transformAccUnaccEntityToUnaccBooleanOrNull(editFormModel),
});
