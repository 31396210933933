import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  DeleteHeadAgreementMutation,
  DeleteHeadAgreementMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  ${errorFragment}
  mutation DeleteHeadAgreement($headAgreementId: ID!, $rowVersion: String!) {
    productPrice {
      headAgreement {
        deleteHeadAgreement(
          headAgreementId: $headAgreementId
          rowVersion: $rowVersion
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on DeleteHeadAgreementSuccessResult {
            customer {
              id
              preselectedHeadAgreement {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const useDeleteHeadAgreementMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    DeleteHeadAgreementMutation,
    DeleteHeadAgreementMutationVariables
  >(mutation);

  const deleteHeadAgreement = useCallback(
    (headAgreementId: string, rowVersion: string) =>
      mutate({ variables: { headAgreementId, rowVersion } }),
    [mutate]
  );

  return { deleteHeadAgreement, loading, error };
};
