import * as React from "react";
import { range } from "lodash";
import { Text } from "@stenajs-webui/core";
import { HEADER_HEIGHT } from "./MatrixScheduleView";
import { padHour } from "./util";
import { cssColor } from "@stenajs-webui/theme";

export const MatrixTableHeader: React.FC = () => (
  <>
    {range(25).map((i) => (
      <div
        key={i}
        style={{
          gridColumn: `${i + 2} / span 1`,
          gridRow: `1 / span 1`,
          marginLeft: "-5px",
          height: HEADER_HEIGHT,
        }}
      >
        <Text
          size="small"
          variant={"bold"}
          color={cssColor("--lhds-color-ui-700")}
        >
          {padHour(i)}
        </Text>
      </div>
    ))}
  </>
);
