import { gql } from "@apollo/client";
export const HeadAgreementFragments = gql`
  fragment RouteAgreementCreatedAt on DateTime {
    isoString
  }
  fragment RouteAgreementModifiedAt on DateTime {
    isoString
  }

  fragment RouteAgreementFields on RouteAgreement {
    id
    headAgreement {
      id
      headAgreementNumber
      customer {
        id
        name
      }
      statusCode
    }
    agreementNumber
    name
    statusCode
    isSalesReady
    sailingTypeCodes
    rowVersion
    hasArticles
    hasConditions
    hasMatrix
    multiLegAgrRouteId
    currency {
      id
      code
    }
    routeDescription
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    writeHistory {
      createdAt {
        ...RouteAgreementCreatedAt
      }
      modifiedAt {
        ...RouteAgreementModifiedAt
      }
    }
    routes {
      id
      routePair {
        id
        statisticsForCustomerIdAndYear(customerId: $customerId, year: $year) {
          averageNegotiationRate
          averageTargetRate
          expectedVolume
          volumeLast12Months
          volumeChange
        }
        seaArea {
          id
          name
        }
      }
    }
  }
`;
