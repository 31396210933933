import { Box } from "@stenajs-webui/core";
import { Banner, FlatButton, stenaPlus } from "@stenajs-webui/elements";
import React from "react";

interface Props {
  bannerHeader: string;
  bannerText: string;
  buttonLabel: string;
  onClick: () => void;
}

export const NoRouteAgreementBox: React.FC<Props> = ({
  bannerHeader,
  bannerText,
  buttonLabel,
  onClick,
}) => {
  return (
    <Box width={"50%"}>
      <Banner
        headerText={bannerHeader}
        text={bannerText}
        variant="info"
        contentRight={
          <FlatButton
            label={buttonLabel}
            leftIcon={stenaPlus}
            onClick={onClick}
          />
        }
      />
    </Box>
  );
};
