import * as React from "react";

import { IndentValues } from "@/common/utils/IndentValues";
import { Indent, Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
} from "@stenajs-webui/elements";
import { useState } from "react";
import { ChildSelector } from "./ChildSelector";
import { ChildCustomersByStatusFragment } from "@/gql/graphql";

interface ChildParentSelectorProps {
  reportType: string;
  numberOfChildren: number;
  headAgreementYear: number;
  setChildCustomers: React.Dispatch<
    React.SetStateAction<ChildCustomersByStatusFragment[]>
  >;
  setChildrenForReport: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
}

interface SelectChildrenOrParentProps {
  numberOfChildren: number;
  reportType: string;
  setChildrenForReport: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  setCreateReportForChildren: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChildParentSelector: React.FC<ChildParentSelectorProps> = ({
  numberOfChildren,
  headAgreementYear,
  setChildCustomers,
  setChildrenForReport,
  reportType,
}) => {
  const [createReportForChildren, setCreateReportForChildren] =
    useState<boolean>(false);
  return (
    <>
      {createReportForChildren === true ? (
        <ChildSelector
          headAgreementYear={headAgreementYear}
          setChildrenForReport={setChildrenForReport}
          setCreateReportForChildren={setCreateReportForChildren}
          setChildCustomers={setChildCustomers}
        />
      ) : (
        <SelectChildrenOrParent
          reportType={reportType}
          numberOfChildren={numberOfChildren}
          setChildrenForReport={setChildrenForReport}
          setCreateReportForChildren={setCreateReportForChildren}
        />
      )}
    </>
  );
};

const SelectChildrenOrParent: React.FC<SelectChildrenOrParentProps> = ({
  numberOfChildren,
  setChildrenForReport,
  setCreateReportForChildren,
  reportType,
}) => (
  <Indent num={IndentValues.TWENTYFOUR}>
    <Card>
      <CardHeader text={`Create ${reportType}`} />
      <CardBody flexDirection={"row"}>
        <PrimaryButton
          label="Only parent account"
          onClick={() => setChildrenForReport([])}
        />
        <Space />
        <PrimaryButton
          label={`Selected children accounts (${numberOfChildren})`}
          onClick={() => setCreateReportForChildren(true)}
        />
      </CardBody>
    </Card>
  </Indent>
);
