import { PrimaryButton } from "@stenajs-webui/elements";
import { compact, flatMap, values } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { LoadingModalWithLock } from "../../../../common/components/loading-modals/LoadingModalWithLock";
import { StoreState } from "../../../../config/redux/RootReducer";
import { refetchStatBoxes } from "../../../customer-route-statistic-boxes/thunks/FetchStatBoxes";
import { updateCustomerRouteStatisticsActualOutcome } from "../../../customer-route-statistic-boxes/thunks/UpdateCustomerRouteStatisticsActualOutcome";
import { rateSheetTableSelectors } from "../../rate-sheet/selectors";
import { getModifiedOrNull } from "../../route-agreement/details/utils/InputTransformerHelpers";
import { useMultiRecalculationOfPriceRowsIsRequiredBeforeSave } from "../../stats-calculation/recalc-stats-for-single-route-agreement-price/hooks/UseRecalculationOfPriceRowsIsRequiredBeforeSave";
import { useHasChangesInRouteAgreements } from "../hooks/UseHasChangesInRouteAgreements";
import { useUpdateRateSheetsForRouteAgreementMutation } from "../hooks/UseUpdateRateSheetsForRouteAgreementMutation";
import { customerRouteRedux } from "../redux";
import { saveCustomerRoutePairSalesReady } from "../thunks/CustomerRoutePairSalesReadySaver";
import { populateRoutePairRateTablesState } from "../thunks/RoutePairRatesPopulator";
import {
  routeAgreementHasChanges,
  transformRateSheetChangesToInput,
} from "../transformers/ModifiedToInputTransformer";
import { transformToUpdateCustomerRouteStatisticsActualOutcomeInput } from "../transformers/TransformToUpdateCustomerRouteStatisticsActualOutcomeInput";
import { Row, Space } from "@stenajs-webui/core";
import { CustomerRouteDiscardChangesButton } from "./CustomerRouteDiscardChangesButton";
import { usePrompt } from "@/common/hooks/usePrompt";
import {
  CustomerRouteFetcherHeadAgreementFragment,
  CustomerRouteFetcherQuery,
  UpdateRateSheetsRouteAgreementItemInput,
} from "@/gql/graphql";

interface Props {
  headAgreement: CustomerRouteFetcherHeadAgreementFragment;
  routePairId: string;
  routeAgreementsToSave: CustomerRouteFetcherQuery["productPrice"]["routeAgreement"]["byHeadAgreementAndRoutePair"];
}

const customerRouteSelector = (state: StoreState) => state.customerRoute;

export const CustomerRouteSaveButton: React.FC<Props> = ({
  routeAgreementsToSave,
  routePairId,
  headAgreement,
}) => {
  const dispatch = useDispatch();

  const { updateRateSheetsForRouteAgreement } =
    useUpdateRateSheetsForRouteAgreementMutation();
  const rateSheetState = useSelector(rateSheetTableSelectors.getTableState);
  const customerRouteState = useSelector(customerRouteSelector);
  const store = useStore<StoreState>();
  const loading = customerRouteState.progress.loading;

  const { recalculationOfPriceRowsIsRequiredBeforeSave } =
    useMultiRecalculationOfPriceRowsIsRequiredBeforeSave(routeAgreementsToSave);

  const hasChangesInRateSheets = useMemo(() => {
    const list = flatMap(values(rateSheetState), (v) => v.rows);
    return list.some((s) => s?.modified);
  }, [rateSheetState]);

  const { someRouteAgreementsHaveChanges } = useHasChangesInRouteAgreements();

  const hasChanges = hasChangesInRateSheets || someRouteAgreementsHaveChanges;

  const canSave = hasChanges && !recalculationOfPriceRowsIsRequiredBeforeSave;

  useEffect(() => {
    routeAgreementsToSave.map((routeAgreement) => {
      const ratesheetTableRowState = rateSheetState[routeAgreement.id];

      if (
        !hasChanges &&
        ratesheetTableRowState &&
        !ratesheetTableRowState.rows.find(
          (item) => item.modified && item.persisted === null
        )
      ) {
        dispatch(customerRouteRedux.actions.clearErrors());
      }
      return [];
    });
  }, [routeAgreementsToSave, dispatch, rateSheetState, hasChanges]);

  const submitHandler = async () => {
    dispatch(customerRouteRedux.actions.clearErrors());
    dispatch(customerRouteRedux.actions.setLoading(true));
    try {
      const routeAgreements = routeAgreementsToSave
        .map<UpdateRateSheetsRouteAgreementItemInput>((routeAgreement) => {
          const rateSheetTableRowState = rateSheetState[routeAgreement.id];

          if (!rateSheetTableRowState) {
            throw new Error("Missing information.");
          }
          const changes = transformRateSheetChangesToInput(
            rateSheetTableRowState.rows
          );

          const routeAgreementState =
            customerRouteState.routeAgreements[routeAgreement.id];

          return {
            routeAgreementId: routeAgreement.id,
            rowVersion: routeAgreement.rowVersion,
            isSalesReady: getModifiedOrNull(
              routeAgreementState?.editable.isSalesReady,
              routeAgreementState?.persisted?.isSalesReady
            ),
            changes,
          };
        })
        .filter(
          (ra) =>
            routeAgreementHasChanges(ra) ||
            customerRouteState.routeAgreements[ra.routeAgreementId]?.editable
              .isSalesReady !==
              customerRouteState.routeAgreements[ra.routeAgreementId]?.persisted
                .isSalesReady
        );

      const { data } = await updateRateSheetsForRouteAgreement({
        routeAgreements,
      });

      const errors =
        data?.productPrice.routeAgreementPrice.updateRateSheets.flatMap(
          (sheet) => {
            if (sheet.__typename === "ErrorResult") {
              return sheet.errors;
            } else {
              return [];
            }
          }
        );

      if (errors && errors.length > 0) {
        dispatch(customerRouteRedux.actions.setLoading(false));
        dispatch(
          customerRouteRedux.actions.setErrors(
            errors.map((e) => new Error(e.message))
          )
        );
        return;
      }

      if (data != null) {
        const updatedData = compact(
          data.productPrice.routeAgreementPrice.updateRateSheets.map(
            (sheet) => {
              if (sheet.__typename === "UpdateRateSheetsSuccessResult") {
                return {
                  rowVersion: sheet.routeAgreement.rowVersion,
                  id: sheet.routeAgreement.id,
                };
              } else {
                return null;
              }
            }
          )
        );

        if (updatedData != null) {
          await dispatch(saveCustomerRoutePairSalesReady(updatedData));
        }
      } else {
        dispatch(customerRouteRedux.actions.setLoading(false));
        dispatch(
          customerRouteRedux.actions.setError(
            new Error(
              "Unable to update/save. Please contact support if the problem persist."
            )
          )
        );
        return;
      }

      await dispatch(
        populateRoutePairRateTablesState(routePairId, headAgreement.id)
      );

      await dispatch(
        updateCustomerRouteStatisticsActualOutcome(
          transformToUpdateCustomerRouteStatisticsActualOutcomeInput(
            store.getState().statBoxes
          )
        )
      );

      dispatch(customerRouteRedux.actions.setLoading(false));
    } catch (e) {
      dispatch(customerRouteRedux.actions.setError(e));
    } finally {
      dispatch(customerRouteRedux.actions.setLoading(false));
    }
  };

  const afterDiscardHandler = useCallback(() => {
    dispatch(refetchStatBoxes());
    dispatch(customerRouteRedux.actions.clearErrors());
  }, [dispatch]);

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: hasChanges,
  });

  return (
    <Row alignItems={"center"}>
      {loading && <LoadingModalWithLock label={"Saving rates..."} />}

      <Space num={2} />
      <CustomerRouteDiscardChangesButton
        disabled={!canSave}
        onDiscard={afterDiscardHandler}
      />
      <Space num={2} />
      <PrimaryButton
        disabled={!canSave}
        label={"Save changes"}
        onClick={submitHandler}
        loading={loading}
      />
    </Row>
  );
};
