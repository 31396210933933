import { useBoolean } from "@stenajs-webui/core";
import { SecondaryButton } from "@stenajs-webui/elements";
import { ActionPrompt, Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useResetForms } from "../../route-agreement/details/hooks/UseResetForms";
import { routeAgreementOriginalSelectors } from "../../route-agreement/details/redux";

interface Props {
  disabled?: boolean;
  onDiscard: () => void;
}

export const CustomerMultilegRouteDiscardChangesButton: React.FC<Props> = ({
  disabled,
  onDiscard,
}) => {
  const [visible, show, hide] = useBoolean(false);

  const originalFormModel = useSelector(
    routeAgreementOriginalSelectors.getEntity
  );
  const { resetForms } = useResetForms(originalFormModel);

  const onDiscardHandler = useCallback(async () => {
    hide();
    resetForms();
    onDiscard();
  }, [resetForms, hide, onDiscard]);

  return (
    <Popover
      visible={visible}
      onClickOutside={hide}
      placement={"bottom"}
      content={<ActionPrompt onNo={hide} onYes={onDiscardHandler} />}
    >
      <SecondaryButton label={"Discard"} onClick={show} disabled={disabled} />
    </Popover>
  );
};
