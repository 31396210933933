import { SpaceValues } from "@/common/utils/SpaceValues";
import { Indent, Row, Space } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader, Label } from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { CountryMultiSelect } from "../../../../../../../common/components/data-driven-inputs/selects/country-multi-select/CountryMultiSelect";
import { GoodsMultiSelect } from "../../../../../../../common/components/data-driven-inputs/selects/goods-multi-select/GoodsMultiSelect";
import { ConditionsFormModel } from "../ConditionsFormModel";

interface Props {
  value: ConditionsFormModel;
  setFields: (value: Partial<ConditionsFormModel>) => void;
}

export const ConditionsForm: React.FC<Props> = React.memo(
  ({
    setFields,
    value: { goods, countryReceiver, countrySender, standby, transit },
  }) => {
    return (
      <Card>
        <CardHeader text={"Conditions"} />
        <CardBody width={"500px"}>
          <Label text={"Country sender"}>
            <CountryMultiSelect
              value={countrySender}
              onValueChange={(countrySender) => setFields({ countrySender })}
              optionsToRemove={countryReceiver}
            />
          </Label>
          <Space />
          <Label text={"Country receiver"}>
            <CountryMultiSelect
              value={countryReceiver}
              onValueChange={(countryReceiver) =>
                setFields({ countryReceiver })
              }
              optionsToRemove={countrySender}
            />
          </Label>
          <Space />
          <Label text={"Goods"}>
            <GoodsMultiSelect
              value={goods}
              onValueChange={(goods) => setFields({ goods })}
            />
          </Label>
          <Space num={SpaceValues.SIXTEEN} />
          <Row>
            <CheckboxWithLabel
              label={"Standby"}
              value={standby}
              onValueChange={(standby) => setFields({ standby })}
            />
            <Indent />
            <CheckboxWithLabel
              label={"Transit"}
              value={transit}
              onValueChange={(transit) => setFields({ transit })}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }
);
