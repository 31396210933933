export enum FormattingSize {
  "Large",
  "Small",
}

export type ReportOption = {
  formattingSize: FormattingSize;
  showSeaAreaHeader: boolean;
  includeArticle: boolean;
  includeMatrix: boolean;
  showAcc: boolean;
  showLoadedStatus: boolean;
  disabled: boolean;
};

export type ReportOptionProps = {
  reportOptions: ReportOption;
  setReportOptions: React.Dispatch<React.SetStateAction<ReportOption>>;
};
