import { gql, useQuery } from "@apollo/client";
import { CustomerCategory } from "../../types/FilterEntitys";
import { CustomerCategoryFilterQuery } from "@/gql/graphql";

const customerCategoryFilterQuery = gql`
  query CustomerCategoryFilter {
    productPrice {
      customerCategory {
        all {
          id
          code
          description
        }
      }
    }
  }
`;

export const useFetchAllCustomerCategories = () => {
  const { data, error, loading, refetch } =
    useQuery<CustomerCategoryFilterQuery>(customerCategoryFilterQuery, {
      fetchPolicy: "cache-first",
    });

  const customerCategories =
    data?.productPrice.customerCategory.all.map<CustomerCategory>((r) => ({
      id: r.id,
      code: r.code,
      name: `${r.code.toUpperCase()} - ${r.description}`,
    })) ?? [];

  return { customerCategories, error, loading, refetch };
};
