import {
  createEntityCrudStatusRedux,
  EntityCrudStatusState,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../config/redux/RootReducer";

export type CustomerStatisticsState = EntityCrudStatusState;

export const getCustomerStatistics = (
  state: StoreState
): CustomerStatisticsState => state.customerStatistics;

const { reducer, actions } = createEntityCrudStatusRedux<StoreState>(
  getCustomerStatistics
);

export const customerStatisticsReducer = reducer;

export const getCustomerStatisticsId = (
  customerId: string,
  validFromYear: number
) => `${customerId}-${validFromYear}`;

export const setCustomerStatisticsLoading = (
  customerId: string,
  validFromYear: number,
  loading: boolean
) =>
  actions.setEntity({
    id: getCustomerStatisticsId(customerId, validFromYear),
    loading,
  });

export const getCustomerStatisticsLoading =
  (customerId: string, validFromYear: number) => (state: StoreState) => {
    const id = getCustomerStatisticsId(customerId, validFromYear);
    return getCustomerStatistics(state).entities[id]?.loading ?? false;
  };
