import { RoutesFormState } from "../../route-agreement/details/features/routes/RoutesFormRedux";
import { RateSheetTableRowState } from "../../rate-sheet/reducer";
import { formatDateString } from "@/common/dates/formatters";
import { transformSailingTypeCodeToEntity } from "../../route-agreement/details/features/routes/RoutesFormModel";
import {
  RouteAgreementForRecalcStatsQuery,
  RouteAgreementPriceCalculateStatisticsInput,
} from "@/gql/graphql";

export const createInputForFetchRecalcStatsForSingleRouteAgreementPrice = ({
  customerId,
  routeAgreementId,
  formModel,
  rateSheetTableRowState,
}: {
  customerId: string;
  routeAgreementId: string;
  formModel: RoutesFormState;
  rateSheetTableRowState: RateSheetTableRowState;
}): RouteAgreementPriceCalculateStatisticsInput => {
  if (!formModel.currencyId) {
    throw new Error("Route agreement has no currency set.");
  }
  return {
    validFrom: {
      isoString: formModel.valid.startDate
        ? formatDateString(new Date(formModel.valid.startDate))
        : formatDateString(new Date()),
    },
    routeCodes: formModel.routeIds,
    currencyCode: formModel.currencyId,
    routeAgreementPriceId: rateSheetTableRowState.editable.id,
    offPeak: formModel.offPeak,
    special: formModel.special,
    shoulder: formModel.shoulder,
    peak: formModel.peak,
    customerId: customerId,
    cargoStatusCode: rateSheetTableRowState.editable.cargoStatusCode,
    lengthFrom: Number(rateSheetTableRowState.editable.lengthFrom),
    lengthTo: Number(rateSheetTableRowState.editable.lengthTo),
    vehicleTypeId: rateSheetTableRowState.editable.vehicleType?.id!,
    routeAgreementId,
    unaccompanied: rateSheetTableRowState.editable.unaccompanied,
  };
};

export const createInputForFetchRecalcStatsForMultipleRouteAgreementPrice = ({
  customerId,
  routeAgreement,
  rateSheetTableRowState,
}: {
  customerId: string;
  routeAgreement: RouteAgreementForRecalcStatsQuery["productPrice"]["routeAgreement"]["byId"];
  rateSheetTableRowState: RateSheetTableRowState;
}): RouteAgreementPriceCalculateStatisticsInput => ({
  validFrom: {
    isoString: routeAgreement?.valid?.start?.isoString
      ? formatDateString(new Date(routeAgreement.valid.start.isoString))
      : formatDateString(new Date()),
  },
  routeCodes: routeAgreement?.routes.map((r) => r.id) ?? [],
  currencyCode: routeAgreement?.currency.id ?? "",
  routeAgreementPriceId: rateSheetTableRowState.editable.id,
  ...transformSailingTypeCodeToEntity(routeAgreement?.sailingTypeCodes ?? []),
  customerId: customerId,
  cargoStatusCode: rateSheetTableRowState.editable.cargoStatusCode,
  lengthFrom: Number(rateSheetTableRowState.editable.lengthFrom),
  lengthTo: Number(rateSheetTableRowState.editable.lengthTo),
  vehicleTypeId: rateSheetTableRowState.editable.vehicleType?.id ?? "",
  routeAgreementId: routeAgreement?.id ?? "",
  unaccompanied: rateSheetTableRowState.editable.unaccompanied,
});
