import React, { useEffect } from "react";
import { Notification } from "@stenajs-webui/panels";
import { stenaInfoMegaphone } from "@stenajs-webui/elements";
import { Box, Column, Row, Txt } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import { SubscriptionPayload } from "../../features/notification-center/redux/slice";

interface Props {
  subscriptionPayload: SubscriptionPayload;
  onClose: (id: string) => void;
}
const timeToClose = 7 * 1_000;

export const NotificationItem: React.FC<Props> = ({
  subscriptionPayload,
  onClose,
}) => {
  useEffect(() => {
    if (subscriptionPayload.autoClose) {
      const timeoutId = setTimeout(
        () => onClose(subscriptionPayload.id),
        timeToClose
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPayload.autoClose, subscriptionPayload.id]);

  return (
    <Row key={subscriptionPayload.id} spacing={1}>
      <Box
        width={300}
        background={cssColor("--lhds-color-ui-50")}
        shadow={"box"}
      >
        <Notification
          text={subscriptionPayload.title}
          icon={stenaInfoMegaphone}
          onClose={() => onClose(subscriptionPayload.id)}
          variant="standard"
        >
          <Column gap={2} alignItems={"flex-start"}>
            <Txt>{subscriptionPayload.text}</Txt>
          </Column>
        </Notification>
      </Box>
    </Row>
  );
};
