import { SwitchHeadAgreementAllAgreementsFragment } from "@/gql/graphql";
import { Box } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { HeadAgreementListSwitcher } from "../../../head-agreement/list/components/HeadAgreementListSwitcher";

interface Props {
  headAgreements: SwitchHeadAgreementAllAgreementsFragment[];
  onSelectHeadAgreement: (headAgreementId: string) => void;
}

export const CustomerDetailsHeadAgreementsList: React.FC<Props> = ({
  headAgreements,
  onSelectHeadAgreement,
}) => {
  return headAgreements.length > 0 ? (
    <>
      <Box background={cssColor("--lhds-color-ui-50")} shadow={"box"}>
        <HeadAgreementListSwitcher
          headAgreements={headAgreements}
          onSelectHeadAgreement={onSelectHeadAgreement}
        />
      </Box>
    </>
  ) : null;
};
