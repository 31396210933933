import { routeFactory } from "@/RouteFactory";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { BreadCrumbs } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {
  customerId: string;
  breadCrumbName: string;
  headAgreementId: string;
}

export const ChildCustomersHeader: React.FC<Props> = ({
  customerId,
  breadCrumbName,
  headAgreementId,
}) => {
  return (
    <PageHeader
      title={"List of child customers"}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation Plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={breadCrumbName}
            path={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId,
              headAgreementId,
            })}
          />
          <Crumb label={"Children"} isLast />
        </BreadCrumbs>
      }
    />
  );
};
