import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { CreateMultiLegAgreementFetcher } from "./CreateMultiLegAgreementFetcher";

interface Props {
  customerId?: string;
  headAgreementId?: string;
  onRequestClose?: () => void;
}

export const MissingParamBoundary: React.FC<Props> = ({
  customerId,
  headAgreementId,
  onRequestClose,
}) => {
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }

  return (
    <CreateMultiLegAgreementFetcher
      headAgreementId={headAgreementId}
      customerId={customerId}
      onClickCancel={onRequestClose}
    />
  );
};
