import { FreightPricingPortalError } from "../models/FreightPricingPortalError";

export const transformFreightPricingPortalErrorsToString = (
  errors: FreightPricingPortalError[]
) => {
  return errors
    .map((error) =>
      error.errors.map((errorDetail) => errorDetail.message).join("\n")
    )
    .join("\n");
};
