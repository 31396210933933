import { NslhBookingDataFragment } from "@/gql/graphql";
import { NoShowLateHandlingHandleCustomerDataRow } from "../config/RowsAndColumnsConfig";

type NoShowLateHandlingBookingsData = NslhBookingDataFragment;

export const NoShowLateHandlingBookingsDataToTableRowTransformer = (
  bookingsData: NoShowLateHandlingBookingsData
): NoShowLateHandlingHandleCustomerDataRow => {
  const {
    id,
    customerBookings,
    customer,
    noOfDoNotInvoice,
    noOfInvoiced,
    noOfThreshold,
    noOfToBeInvoiced,
    noOfToDo,
    region,
    noOfNotChargeable,
    seller,
  } = bookingsData;
  return {
    id,
    noOfToBeInvoiced,
    noOfThreshold,
    noOfInvoiced,
    noOfDoNotInvoice,
    noOfNotChargeable,
    noOfToDo,
    region,
    customer,
    seller,
    total:
      noOfToBeInvoiced +
      noOfThreshold +
      noOfInvoiced +
      noOfDoNotInvoice +
      noOfNotChargeable +
      noOfToDo,
    bookingsData: customerBookings,
  };
};
