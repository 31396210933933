import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Heading, Indent, Spacing } from "@stenajs-webui/core";
import * as React from "react";
import { FormModelChangeHandler } from "../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../PriceCalculatorFormFetcher";
import { PriceCalculatorLegsTable } from "./sailing-details/PriceCalculatorLegsTable";
import { PriceCalculatorVehicleDetails } from "./sailing-details/PriceCalculatorVehicleDetails";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
}

export const PriceCalculatorMultiLeg: React.FC<Props> = ({
  formData,
  onChangeFormData,
  setState,
}) => {
  return (
    <>
      <Spacing num={SpacingValues.SIXTEEN} />
      <Indent num={IndentValues.THIRTYTWO}>
        <Heading variant={"h4"} style={{ margin: 0 }}>
          Legs
        </Heading>
      </Indent>

      <Spacing />
      <PriceCalculatorLegsTable
        formData={formData}
        onChangeFormData={onChangeFormData}
        setState={setState}
      />

      <Spacing />
      <Indent num={IndentValues.THIRTYTWO}>
        <PriceCalculatorVehicleDetails
          isMultileg={true}
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
      </Indent>
    </>
  );
};
