import { ZIndex } from "@/common/utils/ZIndexEnum";
import { CSSObject } from "@emotion/react";
import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo } from "react";

export interface MonthSelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: number;
  onValueChange?: (value: number) => void;
}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const options = months.map((month, index) => ({
  label: monthLabels[index],
  value: month,
}));

export const MonthSelect: React.FC<MonthSelectProps> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base: CSSObject) => ({
          ...base,
          zIndex: ZIndex.highest,
        }),
      }}
      {...selectProps}
      options={options}
      value={selected}
      onChange={(item: any) => onValueChange && onValueChange(item.value)}
    />
  );
};
