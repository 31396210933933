import { Box } from "@stenajs-webui/core";
import { Banner } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {}

export const UnAuthorizedView: React.FC<Props> = () => {
  return (
    <Box
      indent={2}
      spacing={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={"50%"} boxShadow={`1px 1px 8px 0px #C7C7D1`}>
        <Banner
          headerText="You are not authorized to view this page."
          variant="info"
        />
      </Box>
    </Box>
  );
};
