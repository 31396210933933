import { routeFactory } from "@/RouteFactory";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Row, Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
  ResultListBanner,
  SecondaryButton,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { uniq } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadAgreementForm } from "../../common/components/HeadAgreementForm";
import { createEmptyHeadAgreementFormModel } from "../../common/models/HeadAgreementFormModel";
import { useCreateHeadAgreementMutation } from "../hooks/UseCreateHeadAgreementMutation";
import { transformCreateHeadAgreementInput } from "../transformers/CreateHeadAgreementInputTransformer";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";

interface Props {
  customerId: string;
  nameProposal?: string;
}

export const CreateHeadAgreementPanel: React.FC<Props> = ({
  customerId,
  nameProposal,
}) => {
  const navigate = useNavigate();

  const { loading, createHeadAgreement } = useCreateHeadAgreementMutation();

  const [formModel, setFormModel] = useState(() =>
    createEmptyHeadAgreementFormModel(nameProposal)
  );

  const {
    bannerState,
    clearBannerResult,
    setBannerState,
    setBannerResultWithErrors,
    setBannerResultWithTexts,
  } = useResultListBannerState(undefined);

  const onClickHandler = async () => {
    clearBannerResult();
    try {
      const input = transformCreateHeadAgreementInput(customerId, formModel);
      if (input) {
        const { data } = await createHeadAgreement(input);

        const createResult =
          data?.productPrice.headAgreement.createHeadAgreement;
        if (createResult && "errors" in createResult) {
          setBannerState({ headerText: "Unable to create header agreement" });
          const distinctErrorMessages = uniq(
            createResult.errors.map((error) => error.message)
          );
          setBannerResultWithTexts(distinctErrorMessages);
        } else if (createResult) {
          const {
            customer: { id: customerId },
            headAgreement: { id: headAgreementId },
          } = createResult;

          await navigate(
            routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId,
              headAgreementId,
            })
          );
        }
      }
    } catch (e) {
      setBannerState({ headerText: "Unable to create header agreement" });
      setBannerResultWithErrors([e]);
    }
  };

  return (
    <Card>
      <CardHeader text={"Options"} />
      <CardBody>
        <HeadAgreementForm value={formModel} onValueChange={setFormModel} />
        {bannerState && (
          <>
            <Space num={SpaceValues.SIXTEEN} />
            <Row justifyContent={"flex-end"}>
              <ResultListBanner variant={"error"} bannerState={bannerState} />
            </Row>
          </>
        )}
        <Space num={SpaceValues.SIXTEEN} />
        <Row alignItems={"center"} justifyContent={"flex-end"}>
          <SecondaryButton
            label={"Cancel"}
            onClick={() => navigate(-1)}
            data-testid={
              testIdConstantsWebapp.createHeaderAgreementCancelButton
            }
          />
          <Space num={SpaceValues.SIXTEEN} />
          <PrimaryButton
            label={"Create header agreement"}
            onClick={onClickHandler}
            loading={loading}
            data-testid={
              testIdConstantsWebapp.createHeaderAgreementCreateButton
            }
          />
        </Row>
      </CardBody>
    </Card>
  );
};
