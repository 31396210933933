import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  SearchFilterSection,
  SearchFilterSectionProps,
} from "@stenajs-webui/filter";
import * as React from "react";
import { SearchFilterSectionKey } from "../redux/reducer";
import { Tag } from "@stenajs-webui/elements";

interface Props extends SearchFilterSectionProps<SearchFilterSectionKey> {
  enabled?: boolean;
  numSelected?: number;
  leftIcon?: IconDefinition;
}

export const FppSearchFilterSection: React.FC<Props> = ({
  enabled,
  numSelected,
  leftIcon,
  ...searchFilterSectionProps
}) => {
  if (!enabled) {
    return null;
  }
  return (
    <SearchFilterSection
      leftIcon={leftIcon}
      contentRight={
        numSelected && (
          <Tag size={"small"} variant={"info"} label={String(numSelected)} />
        )
      }
      {...searchFilterSectionProps}
    />
  );
};
