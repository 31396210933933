import styled from "@emotion/styled";

interface Props {
  boxMinWidth: number;
  boxMaxWidth?: string;
}

export const GridContainerWrapper = styled.div<Props>`
  display: grid;
  gap: 24px;

  @media only screen and (max-width: 500px) {
    gap: 12px;
  }

  grid-template-columns: repeat(
    auto-fit,
    minmax(${(p) => p.boxMinWidth || 300}px, ${(p) => p.boxMaxWidth || "1fr"})
  );

  > .gridSpanTwo {
    grid-column: span 2;
  }
`;
