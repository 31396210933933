import { Indent, Row, Space } from "@stenajs-webui/core";
import { Icon, stenaFinanceRebate } from "@stenajs-webui/elements";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { Dispatch } from "react";
import { formatUnacc } from "../../../../common/formatters/AccUnaccFormatter";
import { formatShortCargoStatus } from "../../../../common/formatters/CargoStatusFormatter";
import { parseFloatElseUndefined } from "@/common/numbers";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";
import { rateSheetTableActions } from "../actions";
import { ActualChangeInPercent } from "../components/ActualChangeInPercent";
import { LengthCell } from "../components/LenghCell";
import { ModifiedHighlightText } from "../components/ModifiedHighlightText";
import { RateSheetActionButtons } from "../components/RateSheetActionButtons";
import { createRateSheetTableCellRenderer } from "../components/RateSheetTableCell";
import { VehicleTypeCell } from "../components/VehicleTypeCell";
import { WeightCell } from "../components/WeightCell";
import { RateSheetTableRowState } from "../reducer";
import { rateSheetStandardTableWrapperActions } from "../redux";
import { fetchActualChangeForSinglePriceRow } from "../thunks/FetchActualChangeForSinglePriceRow";
import { RateSheetRouteAgreementPrice } from "../types";
import {
  actionsColumnOrder,
  lastYearColumnOrder,
  newPriceAndActualColumnOrder,
  noLabelColumnOrder,
  vehicleInfoColumnOrder,
} from "./RateSheetTableColumns";
import { cssColor } from "@stenajs-webui/theme";
import { Tooltip } from "@stenajs-webui/tooltip";
import {
  cleanNumericTextFieldInput,
  dotToCommaTransform,
  formatThousandsFromStringOrNumber,
} from "@/common/formatters/NumericTextFieldInputCleaner";

export type RateSheetTableColumn =
  | keyof Omit<
      RateSheetRouteAgreementPrice,
      | "id"
      | "lengthFrom"
      | "lengthTo"
      | "weightFrom"
      | "weightTo"
      | "avgNetPrice"
      | "avgRebate"
      | "__typename"
    >
  | "length"
  | "weight"
  | "actions";

export type EditableFields = "newPrice" | "meterPrice" | "additionalFrom";

const editableFieldsWidth = "110px";

export const createRateSheetTableConfig = (
  routeAgreementId: string,
  dispatch: Dispatch<any>,
  editableRouteAgreement: boolean,
  showCheckboxes: boolean,
  windowWidth: number,
  isMultiLegRates?: boolean,
  disableStatBoxes?: boolean,
  currencyLabel?: string,
  selectedRowId?: string | null
): StandardTableConfig<RateSheetTableRowState, RateSheetTableColumn> => {
  return {
    keyResolver: (item) => String(item.routeAgreementPriceId),
    enableGridCell: true,
    disableSorting: true,
    showHeaderCheckbox: showCheckboxes,
    stickyCheckboxColumn: showCheckboxes && windowWidth < 1365,
    showRowCheckbox: showCheckboxes,
    stickyColumnGroups:
      (showCheckboxes && windowWidth < 1365) ||
      (!showCheckboxes && windowWidth < 1320)
        ? "first"
        : undefined,
    rowBackgroundResolver: (item) =>
      item.routeAgreementPriceId === selectedRowId
        ? cssColor("--lhds-color-blue-50")
        : item.deleted
        ? cssColor("--lhds-color-red-50")
        : item.persisted == null
        ? cssColor("--lhds-color-blue-50")
        : undefined,
    columns: {
      vehicleType: {
        itemValueResolver: (item) => item.editable.vehicleType,
        columnLabel: "Vehicle type",
        disableGridCell: true,
        renderCell: ({ label, item }) => <VehicleTypeCell item={item} />,
        width: showCheckboxes ? "150px" : "120px",
        borderLeft: true,
      },
      volume: createColumnConfig((item) => item.editable.volume, {
        disableGridCell: true,
        columnLabel: "Volume",
        minWidth: "74px",
        renderCell: ({ label, value, item }) => (
          <Indent>
            {isMultiLegRates ? (
              "-"
            ) : (
              <ModifiedHighlightText
                persisted={item.persisted?.volume}
                editable={item.editable.volume}
                formatter={formatThousandsFromStringOrNumber}
              />
            )}
          </Indent>
        ),
      }),
      avgGrossPrice: createColumnConfig(
        (item) => item.persisted?.avgGrossPrice?.amount,
        {
          disableGridCell: true,
          columnLabel: "Avg. gross",
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          minWidth: "80px",
          renderCell: ({ label, value, item }) => (
            <Row indent alignItems={"center"}>
              {isMultiLegRates ? (
                "-"
              ) : (
                <>
                  <ModifiedHighlightText
                    persisted={item.persisted?.avgGrossPrice?.amount}
                    editable={item.editable.avgGrossPrice?.amount ?? ""}
                    formatter={dotToCommaTransform}
                  />
                  <Space />
                  {item.editable?.avgRebate?.amount !== "0" ? (
                    <Tooltip
                      label={`Avg. rebate: ${item.editable?.avgRebate?.amount} ${currencyLabel}`}
                    >
                      <Icon
                        icon={stenaFinanceRebate}
                        color={cssColor("--lhds-color-ui-700")}
                        size={16}
                      />
                    </Tooltip>
                  ) : undefined}
                </>
              )}
            </Row>
          ),
        }
      ),
      avgLength: createColumnConfig((item) => item.persisted?.avgLength, {
        disableGridCell: true,
        columnLabel: "Avg. length",
        justifyContentCell: "flex-start",
        justifyContentHeader: "flex-start",
        minWidth: "80px",
        renderCell: ({ label, value, item }) => (
          <Indent>
            {isMultiLegRates ? (
              "-"
            ) : (
              <ModifiedHighlightText
                persisted={item.persisted?.avgLength?.toString()}
                editable={item.editable.avgLength?.toString() ?? ""}
                formatter={dotToCommaTransform}
              />
            )}
          </Indent>
        ),
      }),
      length: createColumnConfig((item) => item, {
        renderCell: ({ label, value, item }) => (
          <Row indent alignItems={"center"}>
            <LengthCell item={item} />
          </Row>
        ),
        disableGridCell: true,
        minWidth: "80px",
        columnLabel: "Length",
      }),
      cargoStatusCode: createColumnConfig(
        (item) => item.editable.cargoStatusCode,
        {
          columnLabel: "ELB",
          minWidth: "56px",
          disableGridCell: true,
          renderCell: ({ label, value, item }) => (
            <Indent>
              <ModifiedHighlightText
                editable={item.editable.cargoStatusCode}
                persisted={item.persisted?.cargoStatusCode}
                formatter={formatShortCargoStatus}
              />
            </Indent>
          ),
        }
      ),
      unaccompanied: createColumnConfig((item) => item.editable.unaccompanied, {
        columnLabel: "Unacc",
        minWidth: "56px",
        disableGridCell: true,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <ModifiedHighlightText
              persisted={item.persisted?.unaccompanied}
              editable={item.editable.unaccompanied}
              formatter={formatUnacc}
            />
          </Indent>
        ),
      }),
      weight: createColumnConfig((item) => item, {
        renderCell: ({ label, value, item }) => (
          <Row indent alignItems={"center"}>
            <WeightCell item={item} />
          </Row>
        ),
        disableGridCell: true,
        columnLabel: "Weight",
        minWidth: "100px",
      }),
      lastPrice: createColumnConfig((item) => item.editable.lastPrice?.amount, {
        disableGridCell: true,
        columnLabel: "Price",
        justifyContentCell: "flex-start",
        justifyContentHeader: "flex-start",
        minWidth: "70px",
        renderCell: ({ label, value, item }) => (
          <Indent>
            <ModifiedHighlightText
              persisted={item.persisted?.lastPrice?.amount}
              editable={item.editable.lastPrice?.amount}
              formatter={formatThousandsFromStringOrNumber}
              dotToCommaTransform={dotToCommaTransform}
            />
          </Indent>
        ),
      }),
      lastMeterPrice: createColumnConfig(
        (item) =>
          dotToCommaTransform(
            formatThousandsFromStringOrNumber(
              item.editable.lastMeterPrice?.amount
            )
          ),
        {
          disableGridCell: true,
          columnLabel: "M. price",
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          minWidth: "66px",
        }
      ),
      lastChargedFrom: createColumnConfig(
        (item) =>
          dotToCommaTransform(
            formatThousandsFromStringOrNumber(item.editable.lastChargedFrom)
          ),
        {
          disableGridCell: true,
          columnLabel: "Ch. from",
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          minWidth: "66px",
        }
      ),
      newPrice: createColumnConfig((item) => item.editable.newPrice.amount, {
        width: editableFieldsWidth,
        justifyContentCell: "flex-start",
        justifyContentHeader: "flex-start",
        renderCell: createRateSheetTableCellRenderer(
          "newPrice",
          (price) => price?.newPrice.amount ?? "",
          true
        ),
        onChange: async (item, value) => {
          await dispatch(
            rateSheetStandardTableWrapperActions.tableRows.recordAction(
              routeAgreementId,
              rateSheetTableActions.setEditableNewPriceEntity(
                item.routeAgreementPriceId,
                cleanNumericTextFieldInput(value ?? "")
              )
            )
          );
          await dispatch(
            fetchActualChangeForSinglePriceRow(
              routeAgreementId,
              item.routeAgreementPriceId
            )
          );
          if (!disableStatBoxes)
            await dispatch(refetchStatBoxesWithRateSheetsChanges());
        },
        borderLeft: true,
        disableGridCell: !editableRouteAgreement,
        isEditable: editableRouteAgreement,
      }),
      meterPrice: createColumnConfig(
        (item) => item.editable.meterPrice.amount,
        {
          minWidth: editableFieldsWidth,
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          renderCell: createRateSheetTableCellRenderer(
            "meterPrice",
            (price) => price?.meterPrice.amount ?? "",
            true
          ),
          onChange: async (item, value) => {
            await dispatch(
              rateSheetStandardTableWrapperActions.tableRows.recordAction(
                routeAgreementId,
                rateSheetTableActions.setEditableMeterPriceEntity(
                  item.routeAgreementPriceId,
                  cleanNumericTextFieldInput(value ?? "")
                )
              )
            );
            await dispatch(
              fetchActualChangeForSinglePriceRow(
                routeAgreementId,
                item.routeAgreementPriceId
              )
            );

            if (!disableStatBoxes)
              await dispatch(refetchStatBoxesWithRateSheetsChanges());
          },
          columnLabel: "M. price",
          disableGridCell: !editableRouteAgreement,
          isEditable: editableRouteAgreement,
        }
      ),
      additionalFrom: createColumnConfig(
        (item) =>
          item.editable.additionalFrom != null
            ? String(item.editable.additionalFrom)
            : "-",
        {
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          renderCell: createRateSheetTableCellRenderer(
            "additionalFrom",
            (price) => String(price?.additionalFrom ?? ""),
            true
          ),
          onChange: async (item, value) => {
            await dispatch(
              rateSheetStandardTableWrapperActions.tableRows.recordAction(
                routeAgreementId,
                rateSheetTableActions.setEditablePriceFields(
                  item.routeAgreementPriceId,
                  {
                    additionalFrom: parseFloatElseUndefined(
                      cleanNumericTextFieldInput(value ?? "")
                    ),
                  }
                )
              )
            );
            await dispatch(
              fetchActualChangeForSinglePriceRow(
                routeAgreementId,
                item.routeAgreementPriceId
              )
            );

            if (!disableStatBoxes)
              await dispatch(refetchStatBoxesWithRateSheetsChanges());
          },
          columnLabel: "Ch. from",
          minWidth: "86px",
          disableGridCell: !editableRouteAgreement,
          isEditable: editableRouteAgreement,
        }
      ),
      actual: createColumnConfig((item) => item.editable.actual ?? 0, {
        justifyContentCell: "flex-start",
        justifyContentHeader: "flex-start",
        minWidth: editableFieldsWidth,
        disableGridCell: true,
        columnLabel: "Actual (%)",
        renderCell: ({ label, value, item }) => (
          <Indent>
            {isMultiLegRates ? "-" : <ActualChangeInPercent state={item} />}
          </Indent>
        ),
      }),
      actions: createColumnConfig((item) => item, {
        justifyContentCell: "flex-start",
        minWidth: "113px",
        columnLabel: "",
        borderLeft: true,
        renderCell: ({ label, item }) => (
          <RateSheetActionButtons
            routeAgreementId={routeAgreementId}
            routeAgreementPriceId={item.routeAgreementPriceId}
            rateSheetTableRowState={item}
            currencyLabel={currencyLabel}
            editableRouteAgreement={editableRouteAgreement}
            disableStatBoxes={disableStatBoxes}
          />
        ),
        disableGridCell: true,
      }),
    },
    columnOrder: [
      "vehicleType",
      "length",
      "weight",
      "cargoStatusCode",
      "unaccompanied",
      "volume",
      "avgGrossPrice",
      "avgLength",
      "lastPrice",
      "lastMeterPrice",
      "lastChargedFrom",
      "newPrice",
      "meterPrice",
      "additionalFrom",
      "actual",
      "actions",
    ],
    columnGroups: {
      vehicleInfo: {
        columnOrder: vehicleInfoColumnOrder,
        label: "Vehicle info",
        borderLeft: false,
      },
      noLabelColumnGroup: {
        columnOrder: noLabelColumnOrder,
        label: "",
      },
      lastYear: {
        columnOrder: lastYearColumnOrder,
        label: "Last year",
        borderLeft: true,
      },
      newActualAndPrice: {
        columnOrder: newPriceAndActualColumnOrder,
        label: "New - price & actual",
        borderLeft: true,
      },
      actions: {
        columnOrder: actionsColumnOrder,
        borderLeft: true,
      },
    },
    columnGroupOrder: [
      "vehicleInfo",
      "noLabelColumnGroup",
      "lastYear",
      "newActualAndPrice",
      "actions",
    ],
  };
};
