import {
  createEntityActions,
  createEntityReducer,
  createEntitySelectors,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../../../../config/redux/RootReducer";
import {
  ConditionsFormModel,
  emptyConditionsFormModel,
} from "./ConditionsFormModel";
import { wrapActionsWithReducerIdGate } from "../../../../../../common/redux/ReducerIdGateActionWrapper";

export type ConditionsFormState = EntityState<ConditionsFormModel>;

const reducerId = "routeAgreementDetails.conditions";

export const conditionsFormRedux = {
  reducer: reducerIdGate(
    reducerId,
    createEntityReducer<ConditionsFormModel>(emptyConditionsFormModel)
  ),
  actions: wrapActionsWithReducerIdGate(reducerId, {
    ...createEntityActions<ConditionsFormModel>(),
  }),
  selectors: createEntitySelectors<StoreState, ConditionsFormModel>(
    (state) => state.routeAgreementDetails.workspace.conditions
  ),
};
