import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { NegotiationStatusByYear } from "../../customer-list/types";
import {
  SetNegotiationStatusMutation,
  SetNegotiationStatusMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  mutation SetNegotiationStatus(
    $input: SetNegotiationStatusInputWithCustomerList!
    $year: Int!
  ) {
    productPrice {
      __typename
      customer {
        __typename
        setNegotiationStatus(input: $input) {
          __typename
          customer {
            __typename
            id
            negotiationStatusByYear(year: $year) {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useSetNegotiationStatus = () => {
  const [mutate, { loading, error }] = useMutation<
    SetNegotiationStatusMutation,
    SetNegotiationStatusMutationVariables
  >(mutation);
  const setNegotiationStatus = useCallback(
    (
      customerNumbers: Array<string>,
      year: number,
      negotiationStatus: NegotiationStatusByYear
    ) =>
      customerNumbers.map((custNo) => {
        return mutate({
          variables: {
            input: {
              customerNumbers,
              year,
              negotiationStatusId: negotiationStatus.id,
            },
            year,
          },
          optimisticResponse: {
            productPrice: {
              __typename: "ProductPriceMutationRoot",
              customer: {
                __typename: "CustomerMutationRoot",
                setNegotiationStatus: [
                  {
                    __typename: "SetNegotiationStatusResult",
                    customer: {
                      __typename: "Customer",
                      id: custNo,
                      negotiationStatusByYear: {
                        __typename: "NegotiationStatus",
                        ...negotiationStatus,
                      },
                    },
                  },
                ],
              },
            },
          },
        });
      }),
    [mutate]
  );

  return {
    setNegotiationStatus,
    error,
    loading,
  };
};
