import { Indent, Space, Txt } from "@stenajs-webui/core";
import {
  CardHeader,
  FlatButton,
  stenaCompress,
  stenaExpand,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArticleTypesSelect } from "../../../../../../../common/components/data-driven-inputs/selects/article-types-multi-select/ArticleTypesSelect";
import { articleFormActions } from "../actions";
import { articleFormSelectors } from "../selectors";
import { ArticleSortBySelect, SortArticleBy } from "./ArticleSortBySelect";

interface Props {
  disabled: boolean;
  sortedBy: string;
  setSortedBy: (value: SortArticleBy) => void;
}

export const ArticlesSelectBox: React.FC<Props> = ({
  disabled,
  sortedBy,
  setSortedBy,
}) => {
  const dispatch = useDispatch();
  const { articles, articleIds, expandedArticleState } = useSelector(
    articleFormSelectors.getState
  );

  const allExpanded = Object.values(expandedArticleState).every((val) => val);

  const toggleExpanded = () => {
    dispatch(articleFormActions.toggleAllExpandedCard());
  };

  return (
    <CardHeader
      text={`${articleIds.length} Articles`}
      contentRight={
        articleIds.length === 0 ? null : (
          <>
            <Txt size="small" color={cssColor("--lhds-color-ui-600")}>
              Sort by
            </Txt>
            <Space />
            <ArticleSortBySelect value={sortedBy} onValueChange={setSortedBy} />
            <Space num={2} />
            <Txt size="small" color={cssColor("--lhds-color-ui-600")}>
              Add article
            </Txt>
            <Space />
            <ArticleTypesSelect
              onValueChange={(selectedArticles) => {
                if (!disabled) {
                  dispatch(
                    articleFormActions.setArticleTypesKeepArticles(
                      selectedArticles
                    )
                  );
                }
              }}
              articleState={Object.values(articles)}
              selectedArticleIds={articleIds}
            />
            <Indent />
            <FlatButton
              onClick={toggleExpanded}
              label={allExpanded ? "Collapse all" : "Expand all"}
              leftIcon={allExpanded ? stenaCompress : stenaExpand}
            />
          </>
        )
      }
    />
  );
};
