import * as React from "react";
import { Box, Heading } from "@stenajs-webui/core";
import { HeadingProps } from "@stenajs-webui/core/dist/components/heading/Heading";

interface Props extends HeadingProps {
  padding?: number;
}
export const PaddedHeading: React.FC<Props> = ({ padding, ...props }) => {
  return (
    <Box spacing={padding ?? 2}>
      <Heading {...props} />
    </Box>
  );
};
