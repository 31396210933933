import {
  createEntityReducer,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { AllCustomerTargetsTableRow } from "../config/RowsAndColumnsConfig";
import { Action, combineReducers } from "redux";
import { ResultListBannerState } from "@stenajs-webui/elements";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type RoutePairCustomerTargetSortedColumnState =
  | "target"
  | "name"
  | "category"
  | "salesRegion"
  | "seller"
  | "sailingCat"
  | "unitType"
  | "statNo"
  | "statNVolume"
  | "statRVolume"
  | "statVolume"
  | "country"
  | "customerNo"
  | "customerNVolume"
  | "customerRVolume"
  | "gross"
  | "customerVolume"
  | "net"
  | "fillFirst"
  | "fillLast";

export interface RouteCustomerGeneralState {
  loading: boolean;
  bannerState?: ResultListBannerState;
  isSortingDisabled: boolean;
  isWarningVisible: boolean;
}
export interface RouteCustomerTargetState {
  routeCustomerSortLoadState: EntityState<RouteCustomerGeneralState>;
  discardClickedState: number;
}

export const routeCustomerTargetReducerId = "routeCustomerTargetAllRoutes";
const createInitialCustomerTargetTableRow = () => ({
  target: null,
  gross: undefined,
  net: undefined,
  statVolume: undefined,
  salesRegion: "",
  customerVolume: undefined,
  sailingCat: "",
  sailingCatCode: "",
  customerNVolume: undefined,
  customerNo: undefined,
  country: undefined,
  unitType: undefined,
  statRVolume: undefined,
  statNVolume: undefined,
  statNo: undefined,
  seller: "",
  name: "",
  customerRVolume: undefined,
  category: "",
  id: "",
  year: new Date().getUTCFullYear(),
  routePairCode: "",
});

export interface CustomerTargetState {
  persisted: Record<string, AllCustomerTargetsTableRow | undefined>;
  edited: Record<string, AllCustomerTargetsTableRow | undefined>;
  listOrder: Array<string>;
}

const initialState: CustomerTargetState = {
  persisted: {},
  edited: {},
  listOrder: [],
};
const slice = createSlice({
  name: routeCustomerTargetReducerId,
  initialState,
  reducers: {
    revert(state, action: PayloadAction<{ id: string }>) {
      state.edited[action.payload.id] = state.persisted[action.payload.id];
    },
    revertAll(state) {
      state.edited = state.persisted;
    },
    setValue(
      state,
      action: PayloadAction<
        { id: string } & Partial<AllCustomerTargetsTableRow>
      >
    ) {
      state.edited[action.payload.id] = {
        ...createInitialCustomerTargetTableRow(),
        ...state.edited[action.payload.id],
        ...action.payload,
      };
    },
    setState(state, action: PayloadAction<Array<AllCustomerTargetsTableRow>>) {
      state.listOrder = action.payload.map((tableRow) => tableRow.id);
      action.payload.forEach((tableRow) => {
        state.edited[tableRow.id] = tableRow;
        state.persisted[tableRow.id] = tableRow;
      });
    },
    clear(state) {
      state.listOrder = initialState.listOrder;
      state.edited = initialState.edited;
      state.persisted = initialState.persisted;
    },
  },
});

export const {
  reducer: customerTargetReducer,
  actions: customerTargetActions,
} = slice;

export const routeCustomerTargetReducer = reducerIdGate(
  routeCustomerTargetReducerId,
  combineReducers({
    routeCustomerSortLoadState: createEntityReducer<RouteCustomerGeneralState>({
      loading: false,
      isSortingDisabled: false,
      isWarningVisible: false,
    }),
    discardClickedState: (state: number = 0, action: Action) => {
      switch (action.type) {
        case "INCREMENT_DISCARD_CLICKED":
          return state + 1;
        default:
          return state;
      }
    },
  })
);
