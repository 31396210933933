import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import { Box, Row } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import React from "react";
import { useSelector } from "react-redux";
import { routeTargetFilterSettings } from "../../config/RouteTargetFilterConfig";
import { RoutePairCustomerTargetBulkEditing } from "./RoutePairCustomerTargetBulkEditing";
import { RoutePairCustomerTargetSortedColumnState } from "./redux/reducers";
import { routeCustomerTargetSelectors } from "./redux/selectors";

interface Props {
  numShowing: number;
  tableContext: TableContext<RoutePairCustomerTargetSortedColumnState>;
  loading?: boolean;
}

export const RoutePairCustomerTargetTableHeader: React.FC<Props> = ({
  numShowing,
  loading = false,
  tableContext,
}) => {
  const selectedRowIds = useSelector(
    routeCustomerTargetSelectors.getSelectedRowIds
  );
  const isRowsSelected = selectedRowIds.length > 0;

  const borderBottom = `1px solid ${cssColor("--lhds-color-ui-300")}`;
  const borderTop = `1px solid ${cssColor("--lhds-color-ui-300")}`;

  return (
    <Box
      //FIXME: when CardHeader has the necessary props for sticky.
      justifyContent={"space-between"}
      top={98}
      left={0}
      zIndex={ZIndex.lowest}
      position={"sticky"}
      background={"var(--lhds-color-ui-50)"}
      borderRadius={"var(--swui-border-radius)"}
    >
      <CardHeader
        text={loading ? "Loading..." : `${numShowing} customer targets`}
      />

      <Box width={"100%"}>
        {isRowsSelected && (
          <RoutePairCustomerTargetBulkEditing
            countSelected={selectedRowIds.length}
            selectedRowIds={selectedRowIds}
            tableContext={tableContext}
          />
        )}
        {!isRowsSelected && (
          <Row
            alignItems="start"
            justifyContent="start"
            spacing={SpacingValues.TWELVE}
            indent={IndentValues.TWENTYFOUR}
            borderBottom={borderBottom}
            borderTop={borderTop}
          >
            <FppSearchFilter settings={routeTargetFilterSettings} />
          </Row>
        )}
      </Box>
    </Box>
  );
};
