import { useMemo } from "react";
import { StoreState } from "../../../../config/redux/RootReducer";
import { useSelector } from "react-redux";
import { isEqual, values } from "lodash";

const selector = (state: StoreState) => state.customerRoute.routeAgreements;

export const useHasChangesInRouteAgreements = () => {
  const routeAgreementsState = useSelector(selector);

  const someRouteAgreementsHaveChanges = useMemo(() => {
    const editableStateList = values(routeAgreementsState);
    return editableStateList.some((state) => {
      const areEqual = isEqual(state.editable, state.persisted);
      return !areEqual;
    });
  }, [routeAgreementsState]);

  return {
    someRouteAgreementsHaveChanges,
  };
};
