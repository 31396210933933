import { stripLineFromSellerName } from "@/common/transformers/SellerNameTransformer";
import { StatisticsAdvancedDataListItem } from "../config/StatisticsByAdvancedTableConfig";
import { UseAdvancedStatisticsDataListQuery } from "@/gql/graphql";

export const transformAdvancedStatisticsDataFilterResultToTableRow = (
  dataForTableRow: UseAdvancedStatisticsDataListQuery["statistics"]["advancedList"]["byFilter"]["items"]["0"]
): StatisticsAdvancedDataListItem => {
  return {
    id: dataForTableRow.id,
    customerName: dataForTableRow.customerName,
    negStatus: dataForTableRow.negotiationStatus,
    custNo: dataForTableRow.custNo,
    custIndex: dataForTableRow.custIndex,
    statNo: dataForTableRow.statNo,
    parentNo: dataForTableRow.parentNo,
    seller: stripLineFromSellerName(dataForTableRow.seller ?? "").toUpperCase(),
    route: dataForTableRow.routePairCode,
    sailing: dataForTableRow.sailingCategoryCode,
    unit: dataForTableRow.unitCategoryCode,
    gross: dataForTableRow.avgGrossMeterPrice,
    net: dataForTableRow.avgNetMeterPrice,
    target: dataForTableRow.targetInPercent,
    rate: dataForTableRow.actualOutcomeInPercent,
    volume12Month: dataForTableRow.last12MonthVol,
    volumeStat12Month: dataForTableRow.last12MonthStatVol,
    expVolume: dataForTableRow.expectedVol,
  };
};
