import { SpacingValues } from "@/common/utils/SpacingValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Indent, Spacing } from "@stenajs-webui/core";
import {
  MediumIcon,
  SecondaryButton,
  stenaAngleLeft,
} from "@stenajs-webui/elements";
import {
  PageHeader as NewPageHeader,
  PageHeading,
} from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import { Property } from "csstype";
import * as React from "react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  title: ReactNode;
  breadcrumbs?: ReactNode;
  loading?: boolean;
  contentAbove?: ReactNode;
  contentLeft?: ReactNode;
  contentNearRight?: ReactNode;
  contentRight?: ReactNode;
  tabs?: ReactNode;
  backButton?: boolean;
  infoRow?: ReactNode;
  bottomSpacing?: boolean;
  noSpaceAfterComponent?: boolean;
  sticky?: boolean;
  zIndex?: Property.ZIndex;
  offsetTop?: number;
  offsetLeft?: number;
  width?: Property.Width;
  icon?: MediumIcon;
}

export const PageHeader: React.FC<Props> = ({
  breadcrumbs,
  tabs,
  title,
  contentAbove,
  contentNearRight,
  contentRight,
  contentLeft,
  backButton,
  sticky,
  zIndex,
  offsetTop,
  offsetLeft,
  noSpaceAfterComponent,
  width,
  icon,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        width={width}
        left={offsetLeft ?? undefined}
        background={cssColor("--lhds-color-ui-50")}
        top={offsetTop ?? undefined}
        position={sticky ? "sticky" : undefined}
        zIndex={zIndex ? zIndex : ZIndex.lowest}
      >
        {contentAbove}
        <NewPageHeader
          renderPageHeading={() => (
            <PageHeading
              icon={icon}
              heading={title as any}
              contentRight={
                <>
                  {contentNearRight && (
                    <>
                      {contentNearRight}
                      <Indent />
                    </>
                  )}
                  {contentRight}
                </>
              }
              contentLeft={
                backButton ? (
                  <>
                    <SecondaryButton
                      leftIcon={stenaAngleLeft}
                      onClick={() => navigate(-1)}
                    />
                    <Indent />
                    {contentLeft}
                  </>
                ) : (
                  contentLeft
                )
              }
            />
          )}
          renderTabs={tabs ? () => tabs : undefined}
          renderBreadCrumbs={breadcrumbs ? () => breadcrumbs : undefined}
        />
      </Box>
      {noSpaceAfterComponent ? null : <Spacing num={SpacingValues.TWELVE} />}
    </>
  );
};
