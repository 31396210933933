import { EditableEntityState } from "@stenajs-webui/redux";
import { groupBy, values } from "lodash";

import { AgreementArticlesRouteAgreementFragment } from "@/gql/graphql";
import { allTypesValue } from "../constants/constants";
import { formatSailingTypeCodes } from "../formatters/formatSailingTypesString";
import {
  AgreementArticlesCustomer,
  AgreementArticlesRouteAgreement,
  AgreementArticlesRoutes,
  AgreementArticlesTableItem,
  AgreementRouteArticle,
  ArticlePrice,
  ArticleValiditys,
} from "../types";

export const transformAgreementRouteArticlePricesTableRow = (
  customer: AgreementArticlesCustomer,
  routeAgreement: AgreementArticlesRouteAgreement,
  routes: AgreementArticlesRoutes,
  article: AgreementRouteArticle,
  articlePrice: ArticlePrice,
  articleValidity: ArticleValiditys
): EditableEntityState<AgreementArticlesTableItem> => {
  const agrRoutePriceValidities = articleValidity.filter(
    (validity) => validity.agrRouteArticlePriceId === articlePrice.id
  );
  const groupedAgrRoutePriceValidities = groupBy(
    agrRoutePriceValidities,
    (article) => article.agrRouteArticlePriceId
  );
  const groupedValiditiesList = values(groupedAgrRoutePriceValidities);
  const articlePriceValidities = groupedValiditiesList.flatMap((t) =>
    t.reduce((a, b) =>
      a.validFrom && b.validFrom && a.validFrom > b.validFrom ? a : b
    )
  );

  return {
    id: articlePrice.id,
    editable: {
      id: articlePrice.id,
      agrRouteArticleId: article.id,
      agrRouteArticlePriceValidityId: articlePriceValidities[0]
        ? articlePriceValidities[0].id
        : "",
      agrRouteName: routeAgreement.name,
      agrRouteArticlePriceId: articlePrice.id,
      agrRouteValidFrom: routeAgreement.valid.start?.isoString ?? "",
      agrRouteValidTo: routeAgreement.valid.end?.isoString ?? "",
      articleCode: article.articleType.code,
      currencyCode: customer?.invoiceCurrency.code ?? "",
      distanceTypeCode: routes[0].distanceType?.id ?? null,
      distanceTypeDescription: routes[0].distanceType?.description ?? null,
      lengthFrom: articlePrice.lengthFrom,
      lengthTo: articlePrice.lengthTo,
      routeDescription:
        routes[0] && routes[1]
          ? `${routes[0].code}, ${routes[1].code}`
          : routes[0].code,
      routePairCode: routes[0].routePair.code,
      routePairDescription: routes[0].routePair.description,
      rowVersion: routeAgreement.rowVersion,
      sailingTypesString: formatSailingTypeCodes(
        routeAgreement.sailingTypeCodes
      ),
      price: articlePriceValidities[0]
        ? articlePriceValidities[0].price
        : Number(articlePrice.price.amount),
      validFrom: articlePriceValidities[0]
        ? articlePriceValidities[0].validFrom
        : "",
      vehicleTypeCode: articlePrice.vehicleType?.code ?? null,
      vehicleTypeDescription:
        articlePrice.vehicleType === null
          ? allTypesValue
          : articlePrice.vehicleType?.shortDescription,
      weightFrom: articlePrice.weightFrom,
      weightTo: articlePrice.weightTo,
      newPrice: "",
      newValidFrom: "",
      agrRouteId: routeAgreement.id,
    },
    persisted: {
      id: articlePrice.id,
      agrRouteArticleId: article.id,
      agrRouteArticlePriceValidityId: articlePriceValidities[0]
        ? articlePriceValidities[0].id
        : "",
      agrRouteName: routeAgreement.name,
      agrRouteArticlePriceId: articlePrice.id,
      agrRouteValidFrom: routeAgreement.valid.start?.isoString ?? "",
      agrRouteValidTo: routeAgreement.valid.end?.isoString ?? "",
      articleCode: article.articleType.code,
      currencyCode: customer?.invoiceCurrency.code ?? "",
      distanceTypeCode: routes[0].distanceType?.id ?? null,
      distanceTypeDescription: routes[0].distanceType?.description ?? null,
      lengthFrom: articlePrice.lengthFrom,
      lengthTo: articlePrice.lengthTo,
      routeDescription:
        routes[0] && routes[1]
          ? `${routes[0].code}, ${routes[1].code}`
          : routes[0].code,
      routePairCode: routes[0].routePair.code,
      routePairDescription: routes[0].routePair.description,
      rowVersion: routeAgreement.rowVersion,
      sailingTypesString: formatSailingTypeCodes(
        routeAgreement.sailingTypeCodes
      ),
      price: articlePriceValidities[0]
        ? articlePriceValidities[0].price
        : Number(articlePrice.price.amount),
      validFrom: articlePriceValidities[0]
        ? articlePriceValidities[0].validFrom
        : "",
      vehicleTypeCode: articlePrice.vehicleType?.code ?? null,
      vehicleTypeDescription:
        articlePrice.vehicleType === null
          ? allTypesValue
          : articlePrice.vehicleType?.shortDescription,
      weightFrom: articlePrice.weightFrom,
      weightTo: articlePrice.weightTo,
      newPrice: "",
      newValidFrom: "",
      agrRouteId: routeAgreement.id,
    },
  };
};

export const transformUpdateResultToAgreementRouteArticlePriceTableItem = (
  custInvoiceCurrency: string,
  routeAgreement: AgreementArticlesRouteAgreementFragment,
  routes: AgreementArticlesRouteAgreementFragment["routes"],
  article: AgreementArticlesRouteAgreementFragment["articles"]["0"],
  articlePrice: AgreementArticlesRouteAgreementFragment["articles"]["0"]["prices"]["0"],
  articleValidity: AgreementArticlesRouteAgreementFragment["articles"]["0"]["validities"]
): EditableEntityState<AgreementArticlesTableItem> => {
  const agrRoutePriceValidities = articleValidity.filter(
    (validity) => validity.agrRouteArticlePriceId === articlePrice.id
  );
  const groupedAgrRoutePriceValidities = groupBy(
    agrRoutePriceValidities,
    (article) => article.agrRouteArticlePriceId
  );
  const groupedValiditiesList = values(groupedAgrRoutePriceValidities);
  const articlePriceValidities = groupedValiditiesList.flatMap((t) =>
    t.reduce((a, b) =>
      a.validFrom && b.validFrom && a.validFrom > b.validFrom ? a : b
    )
  );

  return {
    id: articlePrice.id,
    editable: {
      id: articlePrice.id,
      agrRouteArticleId: article.id,
      agrRouteArticlePriceValidityId: articlePriceValidities[0]
        ? articlePriceValidities[0].id
        : "",
      agrRouteName: routeAgreement.name,
      agrRouteArticlePriceId: articlePrice.id,
      agrRouteValidFrom: routeAgreement.valid.start?.isoString ?? "",
      agrRouteValidTo: routeAgreement.valid.end?.isoString ?? "",
      articleCode: article.articleType.code,
      currencyCode: custInvoiceCurrency,
      distanceTypeCode: routes[0].distanceType?.id ?? null,
      distanceTypeDescription: routes[0].distanceType?.description ?? null,
      lengthFrom: articlePrice.lengthFrom,
      lengthTo: articlePrice.lengthTo,
      routeDescription: `${routes[0].code}, ${routes[1].code}`,
      routePairCode: routes[0].routePair.code,
      routePairDescription: routes[0].routePair.description,
      rowVersion: routeAgreement.rowVersion,
      sailingTypesString: formatSailingTypeCodes(
        routeAgreement.sailingTypeCodes
      ),
      price: articlePriceValidities[0]
        ? articlePriceValidities[0].price
        : Number(articlePrice.price.amount),
      validFrom: articlePriceValidities[0]
        ? articlePriceValidities[0].validFrom
        : "",
      vehicleTypeCode: articlePrice.vehicleType?.code ?? null,
      vehicleTypeDescription:
        articlePrice.vehicleType === null
          ? allTypesValue
          : articlePrice.vehicleType?.shortDescription,
      weightFrom: articlePrice.weightFrom,
      weightTo: articlePrice.weightTo,
      newPrice: "",
      newValidFrom: "",
      agrRouteId: routeAgreement.id,
    },
    persisted: {
      id: articlePrice.id,
      agrRouteArticleId: article.id,
      agrRouteArticlePriceValidityId: articlePriceValidities[0]
        ? articlePriceValidities[0].id
        : "",
      agrRouteName: routeAgreement.name,
      agrRouteArticlePriceId: articlePrice.id,
      agrRouteValidFrom: routeAgreement.valid.start?.isoString ?? "",
      agrRouteValidTo: routeAgreement.valid.end?.isoString ?? "",
      articleCode: article.articleType.code,
      currencyCode: custInvoiceCurrency,
      distanceTypeCode: routes[0].distanceType?.id ?? null,
      distanceTypeDescription: routes[0].distanceType?.description ?? null,
      lengthFrom: articlePrice.lengthFrom,
      lengthTo: articlePrice.lengthTo,
      routeDescription: `${routes[0].code}, ${routes[1].code}`,
      routePairCode: routes[0].routePair.code,
      routePairDescription: routes[0].routePair.description,
      rowVersion: routeAgreement.rowVersion,
      sailingTypesString: formatSailingTypeCodes(
        routeAgreement.sailingTypeCodes
      ),
      price: articlePriceValidities[0]
        ? articlePriceValidities[0].price
        : Number(articlePrice.price.amount),
      validFrom: articlePriceValidities[0]
        ? articlePriceValidities[0].validFrom
        : "",
      vehicleTypeCode: articlePrice.vehicleType?.code ?? null,
      vehicleTypeDescription:
        articlePrice.vehicleType === null
          ? allTypesValue
          : articlePrice.vehicleType?.shortDescription,
      weightFrom: articlePrice.weightFrom,
      weightTo: articlePrice.weightTo,
      newPrice: "",
      newValidFrom: "",
      agrRouteId: routeAgreement.id,
    },
  };
};
