import {
  DeleteRebateAgreementDocumenteMutation,
  DeleteRebateAgreementDocumenteMutationVariables,
} from "@/gql/graphql";
import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const mutation = gql`
  mutation DeleteRebateAgreementDocumente(
    $documentId: ID!
    $customerNumber: Int!
  ) {
    productPrice {
      agreementDocuments {
        deleteRebateAgreementDocument(
          documentId: $documentId
          customerNumber: $customerNumber
        ) {
          __typename
          ... on DeleteAgreementDocumentErrorResult {
            errors {
              message
              path
            }
          }
          ... on DeleteAgreementDocumentSuccessResult {
            success
          }
        }
      }
    }
  }
`;

export const useDeleteRebateAgreementDocumentMutation = () => {
  const [mutate, { loading }] = useMutation<
    DeleteRebateAgreementDocumenteMutation,
    DeleteRebateAgreementDocumenteMutationVariables
  >(mutation);
  const deleteRebateAgreementDocument = useCallback(
    (customerNumber: number, documentId: string) => {
      return mutate({
        variables: {
          customerNumber,
          documentId,
        },
      });
    },
    [mutate]
  );
  return {
    deleteRebateAgreementDocument,
    loading,
  };
};
