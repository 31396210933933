import { FlatButton, stenaPlusCircle } from "@stenajs-webui/elements";
import * as React from "react";
import { useDispatch } from "react-redux";
import { noShowLateHandlingRoutePricesActions } from "./redux/actions";

interface Props {
  routePairCode: string;
  disabled?: boolean;
}

export const NoShowLateHandlingCreateValidFromDateButton: React.FC<Props> = ({
  routePairCode,
  disabled,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <FlatButton
        leftIcon={stenaPlusCircle}
        onClick={() =>
          dispatch(
            noShowLateHandlingRoutePricesActions.setAddValidFromModalOpen({
              isOpen: true,
              routePairCode,
            })
          )
        }
        size={"small"}
        disabled={disabled}
      />
    </>
  );
};
