import { createEntityActions, reducerIdGateAction } from "@stenajs-webui/redux";
import {
  RouteTargetPageGeneralState,
  routeTargetPageReducerId,
} from "./reducers";

export const routeTargetPageActions = {
  setCurrentRoutePairCode: (fields: Partial<RouteTargetPageGeneralState>) =>
    reducerIdGateAction(
      routeTargetPageReducerId,
      createEntityActions<RouteTargetPageGeneralState>().setEntityFields(fields)
    ),
};
