import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Indent, Spacing } from "@stenajs-webui/core";
import { StandardTable, TableContext } from "@stenajs-webui/grid";
import {
  RouteAgreementsTableConfigColumnKeys,
  routeAgreementsTableConfig,
} from "../common/route-agreements-table-config";
import { ReportScreenHeadagreementFragment } from "@/gql/graphql";

export const RouteAgreementsTable = ({
  headerAgreementRoutes,
  routesAgreementTableContext,
}: {
  headerAgreementRoutes: ReportScreenHeadagreementFragment["routeAgreements"];
  routesAgreementTableContext: TableContext<RouteAgreementsTableConfigColumnKeys>;
}) => {
  return (
    <Spacing num={SpacingValues.SIXTEEN}>
      <Indent num={IndentValues.EIGHT}>
        <StandardTable
          tableId="route-agreements-table"
          items={headerAgreementRoutes}
          config={routeAgreementsTableConfig}
          tableContext={routesAgreementTableContext}
        />
      </Indent>
    </Spacing>
  );
};
