import { Tag, TagVariant } from "@stenajs-webui/elements";
import * as React from "react";
import { unreachable } from "../../lib/unreachable";
import { startCase } from "lodash";
import { SailingTypeCode } from "@/gql/graphql";

interface Props {
  statusCode: SailingTypeCode;
}

const getChipVariantByStatusCode = (
  statusCode: SailingTypeCode
): TagVariant => {
  switch (statusCode) {
    case SailingTypeCode.O:
      return "passive";
    case SailingTypeCode.P:
      return "passive";
    case SailingTypeCode.S:
      return "passive";
    case SailingTypeCode.M:
      return "passive";
    default:
      return unreachable(statusCode);
  }
};

export const SailingStatusChip: React.FC<Props> = ({ statusCode }) => {
  return (
    <Tag
      variant={getChipVariantByStatusCode(statusCode)}
      label={startCase(statusCode.toLowerCase())}
    />
  );
};
