import {
  formatDateStringRangeWithSwedishTimeZone,
  formatDateStringWithSwedishTimeZone,
} from "@/common/dates/formatters";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { agreementDocumentsTableCell } from "../../agreement-documents/components/AgreementDocumentsTableCell";
import { DeleteRebateAgreementDocumentButton } from "../components/DeleteRebateAgreementDocumentButton";
import { DownloadPdfButton } from "../components/DownloadPdfButton";
import { PreviewPdfLink } from "../components/PreviewPdfLink";
import { RebateAgreementDocumentsTableItem } from "../models";
import { RebateAgreementDocumentsTableColumnKeys } from "./RowsAndColumnsConfig";

export const rebateAgreementDocumentsTableConfig = (
  error: FreightPricingPortalError | undefined,
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >,
  setDeletedListItems: React.Dispatch<React.SetStateAction<String[]>>
) => {
  const rebateAgreementDocumentsTableConfig: StandardTableConfig<
    RebateAgreementDocumentsTableItem,
    RebateAgreementDocumentsTableColumnKeys
  > = {
    enableGridCell: false,
    initialSortOrder: "createdAt",
    initialSortOrderDesc: true,
    keyResolver: (item) => item.id,
    columns: {
      fileName: {
        itemValueResolver: (item) => item.fileName,
        renderCell: ({ label, value, item }) => (
          <PreviewPdfLink item={item} error={error} setError={setError} />
        ),
        columnLabel: "Filename",
      },
      negotiationYear: {
        itemValueResolver: (item) => item.negotiationYear,
        renderCell: ({ label, value, item }) =>
          agreementDocumentsTableCell(item.negotiationYear),
        columnLabel: "Negotiation year",
      },
      validity: {
        itemValueResolver: (item) => item.rebateAgreementValidFromDate,
        renderCell: ({ label, value, item }) =>
          agreementDocumentsTableCell(
            formatDateStringRangeWithSwedishTimeZone({
              dateStringFrom: item.rebateAgreementValidFromDate,
              dateStringTo: item.rebateAgreementValidToDate,
            })
          ),
        minWidth: "165px",
        columnLabel: "Validity",
      },
      createdBy: {
        itemValueResolver: (item) => item.writeHistory.createdBy.id,
        renderCell: ({ label, value, item }) =>
          agreementDocumentsTableCell(item.writeHistory.createdBy.id),
        columnLabel: "Created by",
      },
      createdAt: {
        itemValueResolver: (item) => item.writeHistory.createdAt.isoString,
        renderCell: ({ label, value, item }) =>
          agreementDocumentsTableCell(
            formatDateStringWithSwedishTimeZone({
              dateString: item.writeHistory.createdAt.isoString,
              options: {
                dateStyle: "short",
                timeStyle: "short",
              },
            })
          ),
        minWidth: "130px",
        columnLabel: "Created",
      },
      download: {
        itemValueResolver: (item) => item.id,
        renderCell: ({ label, value, item }) => (
          <DownloadPdfButton item={item} error={error} setError={setError} />
        ),
        columnLabel: "",
        width: "40px",
        justifyContentCell: "flex-end",
      },
      deleteDocument: createColumnConfig((item) => item, {
        columnLabel: "",
        renderCell: ({ label, item }) => (
          <DeleteRebateAgreementDocumentButton
            documentId={item.id}
            fileName={item.fileName}
            setDeletedListItems={setDeletedListItems}
          />
        ),
        width: "40px",
        disableGridCell: true,
      }),
    },
    columnOrder: [
      "fileName",
      "negotiationYear",
      "validity",
      "createdBy",
      "createdAt",
      "download",
      "deleteDocument",
    ],
  };
  return rebateAgreementDocumentsTableConfig;
};
