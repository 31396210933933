import { css, Global } from "@emotion/react";
import * as React from "react";
import { Helmet } from "react-helmet";
import { RouteTargetsOverviewList } from "./RouteTargetsOverviewList";

interface Props {}

export const RouteTargetsOverviewScreen: React.FC<Props> = () => {
  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="All routes - Route targets" />
      <RouteTargetsOverviewList />
    </>
  );
};
