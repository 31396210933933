import * as React from "react";
import { Indent, Text } from "@stenajs-webui/core";
import { RateSheetTableRowState } from "../reducer";
import { ModifiedHighlightText } from "./ModifiedHighlightText";
import {
  MAX_WEIGHT,
  MIN_WEIGHT,
} from "../add-vehicle-type/models/AddVehicleTypeFormModel";

interface Props {
  item: RateSheetTableRowState;
}

export const WeightCell: React.FC<Props> = ({ item }) => {
  if (
    (item.editable.weightFrom == null ||
      item.editable.weightFrom === MIN_WEIGHT) &&
    (item.editable.weightTo == null || item.editable.weightTo >= MAX_WEIGHT)
  ) {
    if (
      (item.persisted?.weightFrom == null ||
        item.persisted?.weightFrom === MIN_WEIGHT) &&
      (item.persisted?.weightTo == null ||
        item.persisted?.weightTo >= MAX_WEIGHT)
    ) {
      return <ModifiedHighlightText editable={"Any"} persisted={"Any"} />;
    } else {
      return <ModifiedHighlightText editable={"Any"} persisted={""} />;
    }
  }
  return (
    <>
      <ModifiedHighlightText
        editable={item.editable.weightFrom}
        persisted={item.persisted?.weightFrom}
      />
      <Indent num={0.5}>
        <Text>to</Text>
      </Indent>
      <ModifiedHighlightText
        editable={item.editable.weightTo}
        persisted={item.persisted?.weightTo}
      />
    </>
  );
};
