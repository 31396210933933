import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  CustomerHeaderQuery,
  CustomerHeaderQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment CustomerHeaderCustomersById on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    crmLink
    isParent
    custStatus {
      id
      name
    }
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    statisticalGrouping {
      id
    }
    negotiationStatusByYear(year: $year) {
      id
      name
    }
    invoiceCurrency {
      id
      code
    }
    seller {
      id
      name
    }
    salesRegion {
      id
      code
      name
    }
    isStandardCustomer
    hasRebateAgreements
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }

  query CustomerHeader($customerId: ID!, $year: Int!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...CustomerHeaderCustomersById
        }
      }
    }
  }
`;

export const useCustomer = (customerId: string, year: number) => {
  const { loading, data } = useQuery<
    CustomerHeaderQuery,
    CustomerHeaderQueryVariables
  >(query, {
    variables: {
      customerId,
      year,
    },
  });
  return { loading, customer: data?.productPrice.customers.byId };
};
