import { Box } from "@stenajs-webui/core";
import {
  TrackerArgs,
  trackEvent,
} from "../../../../../common/utils/tracking/helper/GaTracker";
import * as React from "react";
import { Link } from "../../../../../common/navigation/Link";
import styles from "../LinkCell.module.css";

interface Props {
  label: string;
  to: string;
  gaTracker?: TrackerArgs;
}

export const LinkCell: React.FC<Props> = function LinkCell({
  label,
  to,
  gaTracker,
}) {
  const gaTrackEvent = () => {
    trackEvent({
      action: gaTracker?.action ?? "",
      value: gaTracker?.value,
    });
  };

  return (
    <Box className={styles.linkCell} display={"initial"} indent={1}>
      <Link title={label} to={to} onClick={gaTrackEvent}>
        {label}
      </Link>
    </Box>
  );
};
