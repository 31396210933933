import { GridContainer } from "@/common/components/grid-container/GridContainer";
import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import { NoHeadAgreementExistBox } from "@/features/product-and-price/info-boxes/head-agreement/NoHeadAgreementExistBox";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { useHeadAgreementsByCustomer } from "../../../head-agreement/list/hooks/UseHeadAgreementsSwitcher";
import { rateSheetStandardTableWrapperActions } from "../../../rate-sheet/redux";
import { CustomerHeader } from "../../common/customer-header/CustomerHeader";
import { CustomerDetailsHeadAgreementsList } from "./CustomerDetailsHeadAgreementsList";
import {
  CustomerDetailsPanelQuery,
  CustomerDetailsPanelQueryVariables,
} from "@/gql/graphql";
interface Props {
  customerId: string;
}

const query = gql`
  fragment CustomerDetailsPanelCustomers on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    isParent
    custStatus {
      id
      name
    }
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    statisticalGrouping {
      id
    }
    invoiceCurrency {
      id
      code
    }
    seller {
      id
      name
    }
    salesRegion {
      id
      code
      name
    }
    crmLink
    isStandardCustomer
    hasRebateAgreements
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }
  query CustomerDetailsPanel($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...CustomerDetailsPanelCustomers
        }
      }
    }
  }
`;

export const CustomerDetailsPanel: React.FC<Props> = ({ customerId }) => {
  const { loading, error, data } = useQuery<
    CustomerDetailsPanelQuery,
    CustomerDetailsPanelQueryVariables
  >(query, {
    variables: {
      customerId,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rateSheetStandardTableWrapperActions.tableRows.clearAllRecords());
  }, [dispatch]);

  const navigate = useNavigate();

  const { headAgreements, loading: headAgreementsByCustomerLoading } =
    useHeadAgreementsByCustomer(customerId);

  const customer = data?.productPrice.customers.byId;

  if (loading || headAgreementsByCustomerLoading) {
    return (
      <Spacing num={6}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!customer) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={"No such customer."} />
      </Spacing>
    );
  }

  const navigateToHeadAgreement = (headAgreementId: string) => {
    navigate(
      routeFactory.productAndPrice.customer.customerHeadAgreement({
        customerId,
        headAgreementId,
      })
    );
  };

  return (
    <>
      <CustomerHeader customerId={customerId} />
      <PageLayout>
        {headAgreements.length === 0 && (
          <GridContainer boxMinWidth={300}>
            <NoHeadAgreementExistBox customerId={customerId} />
            <CustomerInfoPairBox
              customer={customer}
              popoverEnabled={true}
              documentButtonEnabled
            />
          </GridContainer>
        )}
        <CustomerDetailsHeadAgreementsList
          headAgreements={headAgreements}
          onSelectHeadAgreement={navigateToHeadAgreement}
        />
      </PageLayout>
    </>
  );
};
