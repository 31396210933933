import React from "react";
import { NoShowLateHandlingHandleBookingsRow } from "../config/RowsAndColumnsConfig";
import { CustomerDataTableCheckbox } from "./CustomerDataTableCheckbox";

interface Props {
  customerNo: string;
  bookings: Array<NoShowLateHandlingHandleBookingsRow>;
  disableCashCustomers: boolean;
}

export const CustomerCheckboxWrapper: React.VFC<Props> = ({
  bookings,
  customerNo,
  disableCashCustomers,
}) => {
  return (
    <CustomerDataTableCheckbox
      customerNo={customerNo}
      bookings={bookings}
      disabled={disableCashCustomers}
    />
  );
};
