import { Indent, Text } from "@stenajs-webui/core";
import React from "react";

export const CreateStandardNumberTableCellNoShowLateHandlingHandleBookings = (
  rowValue: number | undefined | null
) => {
  if (!rowValue) {
    return (
      <Indent>
        <Text>{0}</Text>
      </Indent>
    );
  }

  return (
    <Indent>
      <Text>{rowValue}</Text>
    </Indent>
  );
};
