import { Indent, Row, Space, Text } from "@stenajs-webui/core";
import {
  Banner,
  Icon,
  InputSpinner,
  stenaCheck,
} from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { useState } from "react";
import { useLocalError } from "../../../../../../../../common/error/UseLocalError";
import { useFormModelState } from "../../../../../../../../common/hooks/UseFormModelState";
import { routeFactory } from "../../../../../../../../RouteFactory";
import { RouteAgreement } from "../../../../../../customers/customer-details/types";
import { useCopyRouteAgreementMutation } from "../UseCopyRouteAgreementMutation";
import { CopyToOtherCustomerForm } from "./CopyToOtherCustomerForm";
import { CopyToOtherCustomerResultPane } from "./CopyToOtherCustomerResultPane";
import {
  AllHeadAgreementsFragment,
  ByCustomerNumberOrIndexFragment,
} from "@/gql/graphql";

export interface CopyRouteAgreementParams {
  customer: ByCustomerNumberOrIndexFragment;
  headAgreement: AllHeadAgreementsFragment;
}

interface Props {
  routeAgreement: RouteAgreement;
  onRequestClose: () => void;
}

export const SelectCustomerAndHeadAgreementModal: React.FC<Props> = ({
  routeAgreement,
  onRequestClose,
}) => {
  const [success, setSuccess] = useState(false);
  const { localError, setLocalError, clearLocalError } = useLocalError();

  const [formData, onChangeFormData] = useFormModelState<
    Partial<CopyRouteAgreementParams>
  >({
    customer: undefined,
    headAgreement: undefined,
  });

  const [copyRouteAgreement, { loading }] = useCopyRouteAgreementMutation();

  const handleSubmit = async () => {
    if (!formData.headAgreement || !formData.customer) {
      return;
    }

    clearLocalError();
    const { data } = await copyRouteAgreement({
      variables: {
        input: {
          headAgreementId: formData.headAgreement.id,
          routeAgreementId: routeAgreement.id,
          breakDate: null,
          rowVersion: routeAgreement.rowVersion,
        },
      },
      refetchQueries:
        formData.customer.id === routeAgreement.headAgreement.customer.id
          ? ["HeadAgreementQuery"]
          : undefined,
    });

    const mutationResult = data?.productPrice.routeAgreement.copyRouteAgreement;
    if (mutationResult && "errors" in mutationResult) {
      setLocalError(
        new Error(
          mutationResult.errors.map((error) => error.message).join("\n")
        )
      );
    } else {
      setSuccess(true);
    }
  };

  return (
    <Window
      isOpen={true}
      width={"750px"}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading}
      onRequestClose={!loading ? onRequestClose : undefined}
      header={
        success ? (
          <Row alignItems={"center"}>
            <Icon icon={stenaCheck} />
            <Space num={2} />
            <Text size="large">Successfully copied agreement</Text>
          </Row>
        ) : loading ? (
          <Row alignItems={"center"}>
            <InputSpinner size={"small"} />
            <Space num={2} />
            <Text size="large">Copying...</Text>
          </Row>
        ) : (
          <Text size="large">Copy route agreement to other customer</Text>
        )
      }
    >
      {(success || loading) && formData.customer && formData.headAgreement ? (
        <CopyToOtherCustomerResultPane
          loading={loading}
          success={success}
          fromCustomerName={routeAgreement.headAgreement.customer.name}
          fromHeadAgreementNumber={
            routeAgreement.headAgreement.headAgreementNumber
          }
          toCustomerName={formData.customer.name}
          toHeadAgreementNumber={formData.headAgreement.headAgreementNumber}
          targetCustomerPath={routeFactory.productAndPrice.customer.customerHeadAgreement(
            {
              customerId: formData.customer.id,
              headAgreementId: formData.headAgreement.id,
            }
          )}
        />
      ) : (
        <CopyToOtherCustomerForm
          onChange={onChangeFormData}
          formData={formData}
          routeAgreement={routeAgreement}
          onCancel={onRequestClose}
          onRequestSubmit={handleSubmit}
        />
      )}
      {localError && (
        <>
          <Space num={2} />
          <Indent num={2}>
            <Banner text={localError.message} variant="error" />
          </Indent>
          <Space num={2} />
        </>
      )}
    </Window>
  );
};
