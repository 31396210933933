import { GraphQLError } from "graphql";
import { combineReducers } from "redux";
import {
  createEntityReducer,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";

export interface GeneralStatisticsState {
  generalState: EntityState<GeneralStatisticsDataState>;
}

export interface GeneralStatisticsDataState {
  currentYear: number;
  fetchErrors?: readonly GraphQLError[] | undefined;
}

export const generalStatisticsReducerId = "generalStatisticsReducerId";

export const generalStatisticsReducer = reducerIdGate(
  generalStatisticsReducerId,
  combineReducers({
    generalState: createEntityReducer<GeneralStatisticsDataState>({
      currentYear: new Date().getUTCFullYear(),
      fetchErrors: undefined,
    }),
  })
);
