import { StatisticsResultListColumns } from "./ResultTableConfig";

export const negotiationStatusGroup: Array<StatisticsResultListColumns> = [
  "fillFirst",
  "ownerName",
  "accounts",
  "todo",
  "started",
  "done",
];

export const negotiationStatusGroupWithDirections: Array<StatisticsResultListColumns> =
  [
    "fillFirst",
    "ownerName",
    "routePair",
    "accounts",
    "todo",
    "started",
    "done",
  ];

export const ratesGroup: Array<StatisticsResultListColumns> = [
  "averageTarget",
  "averageNegotiationRate",
  "avgMeterPrice",
  "avgMeterPriceDiffInPercent",
];

export const volumeGroup: Array<StatisticsResultListColumns> = [
  "lastYearVolume",
  "expectedVolume",
  "fillLast",
];
