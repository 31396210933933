import * as React from "react";
import { ReactNode, useState } from "react";
import {
  Box,
  Column,
  Indent,
  Row,
  Space,
  Spacing,
  Text,
  Txt,
} from "@stenajs-webui/core";
import { DateInput } from "@stenajs-webui/calendar";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";

interface Props {
  onSubmit: (breakDate: Date) => void;
  onRequestClose: () => void;
  errorBanner?: ReactNode;
  loading?: boolean;
}

export const CopyMultiLegRouteAgreementModal: React.FC<Props> = ({
  onSubmit,
  onRequestClose,
  errorBanner,
  loading,
}) => {
  const [breakDate, setBreakDate] = useState<Date | undefined>(new Date());

  const handleSubmit = () => {
    if (breakDate) {
      onSubmit(breakDate);
    }
  };

  return (
    <Window
      isOpen={true}
      width={"500px"}
      onRequestClose={onRequestClose}
      header={
        <Txt variant={"bold"} size={"large"}>
          Copy multi leg route agreement
        </Txt>
      }
    >
      <Column indent spacing>
        <Column indent spacing>
          <Text variant={"bold"}>
            Overlapping multi leg agreements are not allowed.
          </Text>
          <Spacing />
          <Text>
            You need to set a break date. The new agreement will be valid from
            the day after the selected break date.
          </Text>
          <Spacing num={4}>
            <Label text={"Set break date"}>
              <Box width={"130px"}>
                <DateInput value={breakDate} onChange={setBreakDate} />
              </Box>
            </Label>
          </Spacing>
          <Row justifyContent={"flex-end"}>
            <FlatButton onClick={onRequestClose} label={"Cancel"} />
            <Indent />
            <PrimaryButton
              onClick={handleSubmit}
              label={"Copy multi leg agreement"}
              loading={loading}
            />
          </Row>
        </Column>
        {errorBanner && (
          <>
            <Space num={4} />
            {errorBanner}
          </>
        )}
      </Column>
    </Window>
  );
};
