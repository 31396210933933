import { combineReducers } from "redux";
import {
  createEntityReducer,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";

export enum AgreementTabs {
  Route = "Route",
  MultiLeg = "Multi leg",
}

export interface CustomerAgreementTabState {
  currentTabSelected: AgreementTabs;
}

export const customerAgreementsReducerId = "customerAgreements";

export interface CustomerAgreementState {
  customerAgreementTabState: EntityState<CustomerAgreementTabState>;
}

export const customerAgreementReducer = reducerIdGate(
  customerAgreementsReducerId,
  combineReducers({
    customerAgreementTabState: createEntityReducer<CustomerAgreementTabState>({
      currentTabSelected: localStorage.getItem("AgreementTabSelected")
        ? (localStorage.getItem("AgreementTabSelected") as AgreementTabs)
        : AgreementTabs.Route,
    }),
  })
);
