import { NegotiationStatusSelect } from "@/common/components/data-driven-inputs/selects/negotiation-status-select/NegotiationStatusSelect";
import { getApolloErrorMessage } from "@/common/string/GraphQlErrorParser";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Row, Space, Spacing, Txt } from "@stenajs-webui/core";
import {
  PrimaryButton,
  ResultListBanner,
  SecondaryButton,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSetNegotiationStatus } from "../../customer-negotiation-status/hooks/UseSetNegotiationStatusMutation";
import { NegotiationStatusByYear } from "../types";

interface Props {
  customerNumbers: Array<string>;
  negotiationYear: number;
  modalClose: () => void;
  clearAll: () => void;
}

export const NegotiationStatusModalContent: React.FC<Props> = ({
  customerNumbers,
  negotiationYear,
  modalClose,
  clearAll,
}) => {
  const { loading, setNegotiationStatus } = useSetNegotiationStatus();
  const [error, setError] = useState("");
  const [selectedNegStatus, setSelectedNegStatus] = useState<
    NegotiationStatusByYear | undefined
  >();
  const { setBannerState, bannerState } = useResultListBannerState(undefined);

  useEffect(() => {
    if (error && !loading) {
      setBannerState({
        headerText: "Could not update negotiation statuses",
        items: [
          {
            text: error,
          },
        ],
      });
    }
  }, [setBannerState, error, loading]);

  const onConfirmClose = () => {
    modalClose();
    clearAll();
  };

  const onConfirm = (
    negotiationStatus: NegotiationStatusByYear | undefined
  ) => {
    if (negotiationStatus) {
      setNegotiationStatus(
        customerNumbers,
        negotiationYear,
        negotiationStatus
      ).map((status) => {
        return status
          .catch((e) => {
            if (e) {
              setError(getApolloErrorMessage(e));
              throw new Error(e);
            }
          })
          .then(() => onConfirmClose());
      });
    }
  };

  return (
    <Box minHeight={"150px"} justifyContent={"space-between"}>
      <Box width={"50%"}>
        <Txt>{"Status"}</Txt>
        <Space />
        <NegotiationStatusSelect
          value={selectedNegStatus}
          onValueChange={(status) => setSelectedNegStatus(status)}
        />
      </Box>
      {bannerState && (
        <Spacing num={SpacingValues.TWENTYFOUR}>
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          </Box>
        </Spacing>
      )}
      <Row justifyContent={"flex-end"}>
        <SecondaryButton label="Cancel" onClick={modalClose} />
        <Space num={SpaceValues.SIXTEEN} />
        <PrimaryButton
          label="Confirm"
          onClick={() => onConfirm(selectedNegStatus)}
          loading={loading}
        />
      </Row>
    </Box>
  );
};
