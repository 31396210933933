import * as React from "react";
import { useEffect } from "react";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { useDispatch, useSelector } from "react-redux";
import { MultiLegRouteAgreementDetails } from "./MultiLegRouteAgreementDetails";
import { routeAgreementDetailsSelector } from "../../route-agreement/details/redux";
import { populateEditRouteAgreementState } from "../../route-agreement/details/thunks/RouteAgreementDetailsStatePopulator";

interface Props {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementId: string;
  routeAgreementId: string;
}

export const MultiLegRouteAgreementDetailsFetcher: React.FC<Props> = (
  props
) => {
  const dispatch = useDispatch();

  const { routeAgreementId } = props;

  const {
    routeAgreement,
    progress: { loading, error },
  } = useSelector(routeAgreementDetailsSelector);

  useEffect(() => {
    dispatch(populateEditRouteAgreementState(routeAgreementId));
  }, [dispatch, routeAgreementId]);

  if (loading) {
    return (
      <Spacing num={8}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!routeAgreement) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={"No such route agreement."} />
      </Spacing>
    );
  }

  return (
    <MultiLegRouteAgreementDetails {...props} routeAgreement={routeAgreement} />
  );
};
