import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Window } from "@stenajs-webui/modal";
import {
  Column,
  Indent,
  Row,
  SeparatorLine,
  Space,
  Spacing,
  Text,
} from "@stenajs-webui/core";
import {
  FlatButton,
  InputSpinner,
  Label,
  PrimaryButton,
} from "@stenajs-webui/elements";
import { CrudStatusIndicator } from "@stenajs-webui/grid";
import { getApolloErrorMessage } from "../../../../../common/string/GraphQlErrorParser";
import { useSetNegotiationStatusForChildCustomers } from "../hooks/UseSetNegotiationStatusForChildCustomersMutation";
import { NegotiationStatusByYear } from "../../customer-list/types";
import { NegotiationStatusFetcherCustomerFragment } from "@/gql/graphql";

interface Props {
  customer: NegotiationStatusFetcherCustomerFragment;
  negotiationStatus?: NegotiationStatusByYear;
  negotiationYear: number;
  rightButton?: ReactNode;
}
export const SetChildrenNegotiationStatusModal: React.FC<Props> = ({
  customer,
  negotiationStatus,
  negotiationYear,
}) => {
  const [isModalOpen, setModelOpen] = useState<boolean>(false);
  const { error, loading, setNegotiationStatusForChildren } =
    useSetNegotiationStatusForChildCustomers();

  useEffect(() => {
    if (negotiationStatus) {
      setModelOpen(true);
    }
  }, [negotiationStatus]);

  const setConditionalChildrenNegotiationStatus = useCallback(async () => {
    if (negotiationStatus) {
      await setNegotiationStatusForChildren(
        customer.id,
        negotiationYear,
        negotiationStatus
      ).catch(() => {});
      setModelOpen(false);
    }
  }, [
    negotiationStatus,
    customer.id,
    negotiationYear,
    setNegotiationStatusForChildren,
  ]);
  return (
    <Window
      headerText={`Change negotiation status for all child accounts to ${negotiationStatus?.name}?`}
      isOpen={isModalOpen}
      onRequestClose={() => setModelOpen(false)}
      width={"580px"}
    >
      <Column indent={2} spacing>
        <Text variant={"bold"}>Parent customer info</Text>

        <Spacing num={3}>
          <Label textWidth={"58px"} text={"Name"} row>
            <Text>{customer.name}</Text>
          </Label>
          <SeparatorLine />
          <Space />

          <Label textWidth={"58px"} text={"Number"} row>
            <Text>{customer.custNo}</Text>
          </Label>
          <SeparatorLine />
          <Space />

          <Label textWidth={"58px"} text={"Index"} row>
            <Text>{customer.custIndex}</Text>
          </Label>
          <SeparatorLine />
          <Space />

          <Label textWidth={"58px"} text={"Children"} row>
            <Text>{customer.noOfChildren}</Text>
          </Label>
        </Spacing>

        <Row justifyContent={"flex-end"} alignItems={"center"}>
          {loading ? (
            <InputSpinner />
          ) : error ? (
            <CrudStatusIndicator
              crudStatus={{
                hasError: true,
                errorMessage: getApolloErrorMessage(error),
              }}
            />
          ) : null}
          <Indent />
          <FlatButton
            label={"No"}
            onClick={() => {
              setModelOpen(false);
            }}
          />
          <Indent />
          <PrimaryButton
            label={"Yes change status"}
            onClick={async () => {
              await setConditionalChildrenNegotiationStatus();
            }}
          />
        </Row>
      </Column>
    </Window>
  );
};
