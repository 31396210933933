import { parseISO } from "date-fns";

export const getYearFromDateString = (
  validStartIsoString: string | undefined,
  fallbackDate: Date = new Date()
): number => {
  const date = validStartIsoString ? parseISO(validStartIsoString) : undefined;

  return (date ?? fallbackDate).getUTCFullYear();
};
