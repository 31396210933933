import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { CopyHeadAgreementFetcher } from "./CopyHeadAgreementFetcher";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Props {}

export const CopyHeadAgreementScreen: React.FC<Props> = () => {
  const { headAgreementId } = useParams() as { headAgreementId: string };
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }

  return (
    <>
      <Helmet title="Copy - Header agreement - FPP" />
      <CopyHeadAgreementFetcher headAgreementId={headAgreementId} />
    </>
  );
};
