import * as React from "react";
import { useContext, useMemo } from "react";
import { Row } from "@stenajs-webui/core";
import { StatisticsByRoutePairDetailsHeader } from "./StatisticsByRoutePairDetailsHeader";
import { useRoutePairDetailsVolumeAndRatesQuery } from "../hooks/UseRoutePairDetailsVolumeAndRatesQuery";
import { useRoutePairDetailsSummaryQuery } from "../hooks/UseRoutePairDetailsSummaryQuery";
import { transformSelectedStatusesToNegotiationStatusCodes } from "../../../common/transformers/NegotiationStatusFormModelTransformer";
import { Helmet } from "react-helmet";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { Spinner } from "@stenajs-webui/elements";
import { StatisticsDetailsView } from "@/features/statistics/common/statistics-details-view/StatisticsDetailsView";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import {
  NegotiationStatusCode,
  UseRoutePairDetailsSummaryQuery,
} from "@/gql/graphql";

interface Props {
  routeId: string;
  year: number;
}

export const StatisticsByRoutePairDetails: React.FC<Props> = ({
  routeId,
  year,
}) => {
  const { handleCurrencyChange, currency } = useCurrencySelection();

  const { summary, loading } = useRoutePairDetailsSummaryQuery(routeId, year);

  return (
    <>
      <Helmet title="Route - Statistics - FPP" />
      <StatisticsByRoutePairDetailsHeader
        routeId={routeId}
        currency={currency}
        handleCurrencyChange={handleCurrencyChange}
      />

      {loading || !summary ? (
        <Row justifyContent={"center"}>
          <Spinner />
        </Row>
      ) : (
        <DetailsDataLoader
          currency={currency}
          routeId={routeId}
          summary={summary}
          year={year}
        />
      )}
    </>
  );
};

const DetailsDataLoader = ({
  currency,
  summary,
  year,
  routeId,
}: {
  year: number;
  summary: UseRoutePairDetailsSummaryQuery["statistics"]["routePairDetails"]["summary"];
  currency: string;
  routeId: string;
}) => {
  const { routePairSelectedStatuses, setRoutePairSelectedStatuses } =
    useContext(StatisticsContext) as StatisticsContextType;

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(
        routePairSelectedStatuses
      ),
    [routePairSelectedStatuses]
  );

  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;

  const {
    volumeAndRates,
    loading: volumeAndRatesLoading,
    avgMeterPrices,
  } = useRoutePairDetailsVolumeAndRatesQuery(
    routeId,
    currency,
    negotiationStatusCodes,
    year
  );

  return (
    <StatisticsDetailsView
      currency={currency}
      year={year}
      summary={summary}
      selectedNegotiationStatuses={routePairSelectedStatuses}
      setSelectedNegotiationStatuses={setRoutePairSelectedStatuses}
      volumeAndRates={volumeAndRates}
      volumeAndRatesLoading={volumeAndRatesLoading}
      averageMeterPrices={avgMeterPrices}
    />
  );
};
