import React, { useCallback, useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { selectBookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

export const HandleBookingStatusSelect: React.FC<Props> = ({
  value,
  onValueChange,
  ...selectProps
}) => {
  const selected = useMemo(
    () => selectBookingStatusOptions.find((o) => o.value === value),
    [value]
  );

  const onValueChangeHandler = useCallback(
    (value: string) => {
      if (onValueChange) {
        onValueChange(value);
      }
    },
    [onValueChange]
  );
  return (
    <Select
      {...selectProps}
      isLoading={false}
      options={selectBookingStatusOptions}
      value={selected}
      onChange={(option) => option && onValueChangeHandler(option?.value)}
    />
  );
};
