import { SearchFilterActionsContext } from "@stenajs-webui/filter";
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { FilterCheckboxType } from "../components/sections/FPPFilterCheckBoxList";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "../context/searchFilterSelectorContext";
import { commitSearchFilter, filterCommittedActions } from "../redux/reducer";
import {
  Customer,
  CustomerCategory,
  CustomerType,
  EmptyLoaded,
  IncludeCustomersWith,
  NegotiationStatus,
  RoutePair,
  SailingCategory,
  SalesRegion,
  Seller,
  TargetStatus,
  UnitType,
} from "../types/FilterEntitys";
import { RangeFilter } from "../types/RangeFilter";

export const useFilterWorkspace = () => {
  const dispatch = useDispatch();

  const searchFilterActions = useContext(SearchFilterActionsContext);

  const { workspaceState, setIsCommitted } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;
  const {
    customerTypes,
    includeCustomersWith,
    negotiationStatus,
    negotiationYear,
    routePairs,
    salesRegions,
    sellers,
    customers,
    sailingCategories,
    routeAgreementSailingCategories,
    unitTypes,
    averageNetPrice,
    averageGrossPrice,
    target,
    rate,
    twelveMonthVolume,
    countries,
    networkVolume,
    routeVolume,
    customerCategories,
    regions,
    statNumbersAndIndexes,
    statNetworkVolume,
    statRouteVolume,
    targetStatuses,
    vehicleTypeAll,
    vehicleTypes,
    vehicleLength,
    vehicleWeight,
    emptyLoaded,
    unacc,
    year,
    month,
    bookingNo,
    bookAndCheckInNotes,
    startDate,
    startTime,
    endTime,
    endDate,
    noShowLateHandlingType,
    noShowLateHandlingStatus,
  } = workspaceState.formModel;

  const setNegotiationStatus = (negotiationStatus: Array<NegotiationStatus>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        negotiationStatus,
      })
    );

  const setNegotiationYear = (negotiationYear: number) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        negotiationYear,
      })
    );

  const setYear = (year: number) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        year,
      })
    );

  const setMonth = (month: number) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        month,
      })
    );

  const setBookAndCheckInNotes = (
    bookAndCheckInNotes: Array<FilterCheckboxType>
  ) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        bookAndCheckInNotes,
      })
    );

  const setNoShowLateHandlingType = (
    noShowLateHandlingType: Array<FilterCheckboxType>
  ) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        noShowLateHandlingType,
      })
    );

  const setNoShowLateHandlingStatus = (
    noShowLateHandlingStatus: Array<FilterCheckboxType>
  ) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        noShowLateHandlingStatus,
      })
    );
  const setBookingNumber = (bookingNo: string) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        bookingNo,
      })
    );

  const setSellers = (sellers: Array<Seller>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        sellers,
      })
    );

  const setCustomerTypes = (customerTypes: Array<CustomerType>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        customerTypes,
      })
    );

  const setIncludeCustomersWith = (
    includeCustomersWith: Array<IncludeCustomersWith>
  ) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        includeCustomersWith,
      })
    );

  const setSalesRegions = (salesRegions: Array<SalesRegion>) =>
    dispatch(searchFilterActions.setFormModelFields({ salesRegions }));

  const setRoutePairs = (routePairs: Array<RoutePair>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        routePairs,
      })
    );

  const setCustomers = (customers: Array<Customer>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        customers,
      })
    );

  const setSailingCategories = (sailingCategories: Array<SailingCategory>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        sailingCategories,
      })
    );

  const setRouteAgreementSailingCategories = (
    routeAgreementSailingCategories: Array<SailingCategory>
  ) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        routeAgreementSailingCategories,
      })
    );

  const setUnitTypes = (unitTypes: Array<UnitType>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        unitTypes,
      })
    );

  const setAverageNetPrice = (averageNetPrice: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        averageNetPrice,
      })
    );

  const setAverageGrossPrice = (averageGrossPrice: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        averageGrossPrice,
      })
    );

  const setTarget = (target: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        target,
      })
    );

  const setRate = (rate: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        rate,
      })
    );

  const setTwelveMonthVolume = (twelveMonthVolume: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        twelveMonthVolume,
      })
    );

  const setCountries = (countries: Array<string>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        countries,
      })
    );

  const setNetworkVolume = (networkVolume: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        networkVolume,
      })
    );

  const setRouteVolume = (routeVolume: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        routeVolume,
      })
    );

  const setCustomerCategories = (customerCategories: Array<CustomerCategory>) =>
    dispatch(searchFilterActions.setFormModelFields({ customerCategories }));

  const setRegions = (regions: Array<SalesRegion>) =>
    dispatch(searchFilterActions.setFormModelFields({ regions }));

  const setStatNumbersAndIndexes = (statNumbersAndIndexes: Array<Customer>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        statNumbersAndIndexes,
      })
    );

  const setStatNetworkVolume = (statNetworkVolume: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        statNetworkVolume,
      })
    );

  const setStatRouteVolume = (statRouteVolume: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        statRouteVolume,
      })
    );

  const setTargetStatuses = (targetStatuses: Array<TargetStatus>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        targetStatuses,
      })
    );

  const setVehicleTypeAll = (vehicleTypeAll: boolean) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        vehicleTypeAll,
      })
    );

  const setVehicleTypes = (vehicleTypes: Array<CustomerCategory>) =>
    dispatch(searchFilterActions.setFormModelFields({ vehicleTypes }));

  const setVehicleLength = (vehicleLength: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        vehicleLength,
      })
    );

  const setVehicleWeight = (vehicleWeight: RangeFilter) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        vehicleWeight,
      })
    );

  const setEmptyLoaded = (emptyLoaded: Array<EmptyLoaded>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        emptyLoaded,
      })
    );

  const setUnacc = (unacc: Array<EmptyLoaded>) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        unacc,
      })
    );

  const setStartDate = (startDate: string) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        startDate,
      })
    );
  const setStartTime = (startTime: string) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        startTime,
      })
    );

  const setEndDate = (endDate: string) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        endDate,
      })
    );
  const setEndTime = (endTime: string) =>
    dispatch(
      searchFilterActions.setFormModelFields({
        endTime,
      })
    );

  const clearCommittedState = () => {
    dispatch(filterCommittedActions.clearAll());
    setIsCommitted(false);
  };
  const openFilter = () => dispatch(searchFilterActions.openFilters());
  const clearFilter = () => {
    dispatch(searchFilterActions.clearFormModel());
    setIsCommitted(false);
  };
  const clearExpandedSections = () =>
    dispatch(searchFilterActions.clearExpandedSections());
  const commit = useCallback(() => {
    dispatch(commitSearchFilter());
    setIsCommitted(true);
  }, [dispatch, setIsCommitted]);

  return {
    negotiationStatus,
    setNegotiationStatus,
    negotiationYear,
    setNegotiationYear,
    sellers,
    setSellers,
    customerTypes,
    setCustomerTypes,
    includeCustomersWith,
    setIncludeCustomersWith,
    salesRegions,
    setSalesRegions,
    routePairs,
    setRoutePairs,
    customers,
    setCustomers,
    sailingCategories,
    setSailingCategories,
    unitTypes,
    setUnitTypes,
    averageNetPrice,
    setAverageNetPrice,
    averageGrossPrice,
    setAverageGrossPrice,
    target,
    setTarget,
    rate,
    setRate,
    twelveMonthVolume,
    setTwelveMonthVolume,
    countries,
    setCountries,
    networkVolume,
    setNetworkVolume,
    routeVolume,
    setRouteVolume,
    customerCategories,
    setCustomerCategories,
    regions,
    setRegions,
    statNumbersAndIndexes,
    setStatNumbersAndIndexes,
    statNetworkVolume,
    setStatNetworkVolume,
    statRouteVolume,
    setStatRouteVolume,
    targetStatuses,
    setTargetStatuses,
    routeAgreementSailingCategories,
    setRouteAgreementSailingCategories,
    vehicleTypes,
    setVehicleTypes,
    vehicleLength,
    setVehicleLength,
    vehicleWeight,
    setVehicleWeight,
    emptyLoaded,
    setEmptyLoaded,
    unacc,
    setUnacc,
    year,
    setYear,
    setMonth,
    month,
    bookingNo,
    setBookAndCheckInNotes,
    bookAndCheckInNotes,
    setBookingNumber,
    startDate,
    startTime,
    setStartDate,
    setStartTime,
    setEndDate,
    setEndTime,
    endDate,
    endTime,
    vehicleTypeAll,
    setVehicleTypeAll,
    noShowLateHandlingStatus,
    setNoShowLateHandlingStatus,
    noShowLateHandlingType,
    setNoShowLateHandlingType,
    clearCommittedState,
    openFilter,
    clearFilter,
    clearExpandedSections,
    commit,
  };
};
