import { useQuery } from "@apollo/client";
import { Column, Spacing } from "@stenajs-webui/core";
import { ErrorScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLayout } from "../../../../common/components/page-layout/PageLayout";
import { useCustomer } from "../../customers/common/customer-header/UseCustomer";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { useHeadAgreementById } from "../../head-agreement/details/hooks/UseHeadAgreementById";
import { MultiLegViewInfoPairBoxes } from "../../info-boxes/MultiLegViewInfoPairBoxes";
import { ActiveIndicatorBox } from "../../route-agreement/details/components/ActiveIndicatorBox";
import { routeAgreementQuery } from "../../route-agreement/details/components/RouteAgreementDetailsHeader";
import { ArticlesPanel } from "../../route-agreement/details/features/articles/components/ArticlesPanel";
import { ConditionsPanel } from "../../route-agreement/details/features/conditions/components/ConditionsPanel";
import { MatrixPanel } from "../../route-agreement/details/features/matrix/components/MatrixPanel";
import { NotesPanel } from "../../route-agreement/details/features/notes/components/NotesPanel";
import { MultiLegRatesPanel } from "../../route-agreement/details/features/rates/components/MultiLegRatesPanel";
import { RoutesPanel } from "../../route-agreement/details/features/routes/components/RoutesPanel";
import { RouteAgreementForForm } from "../../route-agreement/details/types";
import { routeAgreementCanBeEdited } from "../../route-agreement/util/RouteAgreementCalculator";
import { MultiLegRouteAgreementDetailsHeader } from "./MultiLegRouteAgreementDetailsHeader";
import {
  RouteAgreementHeaderQuery,
  RouteAgreementHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementId: string;
  routeAgreementId: string;
  routeAgreement: RouteAgreementForForm;
}

export const MultiLegRouteAgreementDetails: React.FC<Props> = (props) => {
  const {
    routeAgreementId,
    routeAgreement,
    headAgreementId,
    customerId,
    multiLegAgreementId,
  } = props;

  const { data } = useQuery<
    RouteAgreementHeaderQuery,
    RouteAgreementHeaderQueryVariables
  >(routeAgreementQuery, {
    variables: {
      routeAgreementId,
      headAgreementId,
    },
  });

  const { defaultYear } = useNegotiationYears();
  const { headAgreement } = useHeadAgreementById(
    headAgreementId,
    customerId,
    defaultYear
  );
  const multiLegAgreement = headAgreement?.multiLegAgrRoutes.find(
    (mlar) => mlar.id === routeAgreement.multiLegAgrRouteId
  );

  const { customer } = useCustomer(customerId, defaultYear);
  const routeAgreementInfoBox = data?.productPrice.routeAgreement.byId;
  const editableRouteAgreement = routeAgreementCanBeEdited(routeAgreement);
  const currencyLabel = routeAgreement.currency.code;

  if (routeAgreement.multiLegAgrRouteId !== multiLegAgreementId) {
    return (
      <Spacing num={8}>
        <ErrorScreen
          text={"The route agreement doesn't match the multi leg agreements"}
        />
      </Spacing>
    );
  }

  if (!multiLegAgreement) {
    return null;
  }

  return (
    <Column>
      <MultiLegRouteAgreementDetailsHeader
        {...props}
        multiLegCode={multiLegAgreement.route.code}
        multiLegNumber={multiLegAgreement.idNumber}
      />
      <PageLayout>
        {!editableRouteAgreement && (
          <ActiveIndicatorBox status={routeAgreement.statusCode} />
        )}
        {customer && (
          <MultiLegViewInfoPairBoxes
            customer={customer}
            headAgreementId={headAgreementId}
            routeAgreement={routeAgreementInfoBox}
            multiLegAgreement={multiLegAgreement}
            switchButtonDisabled={true}
          />
        )}

        <Routes>
          <Route
            path={"rates"}
            element={
              <MultiLegRatesPanel
                routePairId={multiLegAgreement.route.code}
                routeAgreementId={routeAgreementId}
                headAgreementId={headAgreementId}
                currencyLabel={currencyLabel}
                routeAgreement={routeAgreement}
                customerId={customerId}
              />
            }
          />
          <Route
            path={"routes"}
            element={
              <RoutesPanel
                customerId={props.customerId}
                routeAgreementId={routeAgreementId}
                isMultiLegRouteAgreement={true}
                multiLegRoute={multiLegAgreement?.route.code}
                routeLeg={routeAgreement.routeDescription}
              />
            }
          />
          <Route
            path={"articles"}
            element={<ArticlesPanel routeAgreement={routeAgreement} />}
          />
          <Route path={"notes"} element={<NotesPanel />} />
          <Route path={"conditions"} element={<ConditionsPanel />} />
          <Route path={"matrix"} element={<MatrixPanel />} />
        </Routes>
      </PageLayout>
    </Column>
  );
};
