import { formatThousandsCheckNull } from "@/common/numbers";
import { IndentValues } from "@/common/utils/IndentValues";
import {
  Box,
  Column,
  Heading,
  Indent,
  Row,
  Space,
  Txt,
} from "@stenajs-webui/core";
import { Link } from "@stenajs-webui/elements";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import {
  StandardTableConfigWithGroups,
  createColumnConfig,
} from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { routePairLabelWithDescriptionForTables } from "../../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import {
  NegStatusChipMultiSelect,
  NegotiationStatusItem,
} from "../../neg-status-chip-multi-select/NegStatusChipMultiSelect";
import { ByCustomerOrVolume } from "../../utils/ByCustomerOrVolumeEnum";
import {
  currencyValue,
  negotiationStatusSumFormatter,
  percentLabelFormatterDecimalValue,
} from "../../utils/statisticsUtil";
import {
  negotiationStatusGroup,
  negotiationStatusGroupWithDirections,
  ratesGroup,
  volumeGroup,
} from "./ResultTableColumns";

export interface StatisticsResultListItem {
  ownerId: string;
  ownerName: string;
  routePair?: string;
  accounts: number;
  todo?: number;
  started?: number;
  done?: number;
  averageTarget?: number | null;
  averageNegotiationRate?: number | null;
  avgMeterPrice?: number | null;
  avgMeterPriceDiffInPercent?: number | null;
  lastYearVolume?: number;
  expectedVolume?: number | null;
}
export type StatisticsResultListColumns =
  | keyof Omit<StatisticsResultListItem, "ownerId">
  | "fillFirst"
  | "fillLast";
const border = "1px solid " + cssColor("--lhds-color-ui-300");
const rowHeight = "56px";

export const createResultTableConfig = (
  ownerColumnLabel: string,
  onClickOwner: (id: string) => void,
  currencyCode: string,
  selectedStatuses: NegotiationStatusItem[],
  setSelectedStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >,
  radioButtonChecked: ByCustomerOrVolume,
  setRadioButtonChecked: React.Dispatch<
    React.SetStateAction<ByCustomerOrVolume>
  >,
  stickyHeader?: boolean,
  useRoutePairColumn?: boolean
): StandardTableConfigWithGroups<
  StatisticsResultListItem,
  StatisticsResultListColumns
> => ({
  keyResolver: (item) => item.ownerId,
  disableSorting: false,
  headerRowOffsetTop: stickyHeader ? "0px" : undefined,
  columns: {
    fillFirst: createColumnConfig(() => "", {
      columnLabel: "",
      width: "24px",
    }),
    ownerName: createColumnConfig((item) => item.ownerName, {
      columnLabel: ownerColumnLabel,
      renderCell: ({ label, item }) => (
        <Indent>
          <Txt whiteSpace={"nowrap"}>
            <Link onClick={() => onClickOwner(item.ownerId)}>{label}</Link>
          </Txt>
        </Indent>
      ),
    }),
    routePair: {
      itemValueResolver: (item) => item.routePair,
      renderCell: ({ label, value, item }) =>
        routePairLabelWithDescriptionForTables(item.routePair ?? ""),
      columnLabel: "Directions",
      disableGridCell: true,
    },
    accounts: createColumnConfig((item) => item.accounts, {
      itemLabelFormatter: formatThousandsCheckNull,
      columnLabel: "Customers",
    }),
    todo: createColumnConfig((item) => item.todo, {
      itemLabelFormatter: negotiationStatusSumFormatter,
      minWidth: "60px",
    }),
    started: createColumnConfig((item) => item.started, {
      itemLabelFormatter: negotiationStatusSumFormatter,
      minWidth: "60px",
    }),
    done: createColumnConfig((item) => item.done, {
      itemLabelFormatter: negotiationStatusSumFormatter,
      minWidth: "60px",
    }),
    averageTarget: createColumnConfig((item) => item.averageTarget, {
      columnLabel: "Target",
      borderLeft: true,
      width: "176.66",
      minWidth: "176.66",

      itemLabelFormatter: percentLabelFormatterDecimalValue,
    }),
    averageNegotiationRate: createColumnConfig(
      (item) => item.averageNegotiationRate,
      {
        columnLabel: "Actual rate",
        width: "176.66",
        minWidth: "176.66",
        itemLabelFormatter: percentLabelFormatterDecimalValue,
      }
    ),
    avgMeterPrice: {
      itemValueResolver: ({ avgMeterPrice }) =>
        avgMeterPrice === null || avgMeterPrice === 0
          ? -Infinity
          : avgMeterPrice,
      columnLabel: "Price/m",
      renderCell: ({ item }) => currencyValue(item.avgMeterPrice, currencyCode),
      width: "176.66",
      minWidth: "176.66",
    },
    avgMeterPriceDiffInPercent: {
      itemValueResolver: ({ avgMeterPriceDiffInPercent }) =>
        avgMeterPriceDiffInPercent === null
          ? -Infinity
          : avgMeterPriceDiffInPercent,
      columnLabel: "Diff prev year",
      renderCell: ({ item }) =>
        percentLabelFormatterDecimalValue(item.avgMeterPriceDiffInPercent),
      width: "176.66",
      minWidth: "176.66",
    },
    lastYearVolume: createColumnConfig((item) => item.lastYearVolume, {
      columnLabel: "12 mon. vol.",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    expectedVolume: createColumnConfig((item) => item.expectedVolume, {
      columnLabel: "Expected vol.",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    fillLast: createColumnConfig(() => "", {
      columnLabel: "",
      width: "24px",
    }),
  },
  columnGroups: {
    negotionStatus: {
      columnOrder: useRoutePairColumn
        ? negotiationStatusGroupWithDirections
        : negotiationStatusGroup,
      render: () => (
        <Column width={"100%"}>
          <Row
            borderBottom={border}
            height={rowHeight}
            indent={IndentValues.TWENTYFOUR}
            alignItems={"center"}
          >
            <Heading variant="h4">{"Negotiation status by customers"}</Heading>
          </Row>
          <Row
            height={rowHeight}
            alignItems={"center"}
            indent={IndentValues.TWENTYFOUR}
          >
            <RadioButtonWithLabel
              label={"By customer"}
              value={"customer"}
              checked={radioButtonChecked === ByCustomerOrVolume.CUSTOMER}
              onValueChange={() =>
                setRadioButtonChecked(ByCustomerOrVolume.CUSTOMER)
              }
            />
            <Space />
            <RadioButtonWithLabel
              label={"By volume"}
              value={"volume"}
              checked={radioButtonChecked === ByCustomerOrVolume.VOLUME}
              onValueChange={() =>
                setRadioButtonChecked(ByCustomerOrVolume.VOLUME)
              }
            />
          </Row>
        </Column>
      ),
    },
    rates: {
      borderLeft: true,
      columnOrder: ratesGroup,
      render: () => (
        <Box width={"100%"}>
          <Row
            borderBottom={border}
            height={rowHeight}
            alignItems={"center"}
            indent={IndentValues.TWENTYFOUR}
          >
            <Heading variant="h4">Rates</Heading>
          </Row>
          <Row
            height={rowHeight}
            alignItems={"center"}
            indent={IndentValues.TWENTYFOUR}
          >
            <NegStatusChipMultiSelect
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
            />
          </Row>
        </Box>
      ),
    },
    volume: {
      columnOrder: volumeGroup,
      render: () => (
        <Box width={"100%"}>
          <Row borderBottom={border} height={rowHeight} alignItems={"center"}>
            <Heading variant="h4">{"Volume"}</Heading>
          </Row>
          <Row
            height={rowHeight}
            alignItems={"center"}
            indent={IndentValues.TWENTYFOUR}
          ></Row>
        </Box>
      ),
    },
  },
  columnGroupOrder: ["negotionStatus", "rates", "volume"],
});
