import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { Row, Space } from "@stenajs-webui/core";
import { Label, stenaCalendarManage } from "@stenajs-webui/elements";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

export const BookingNumberSearchFilterSection = ({
  enabled,
  label,
}: {
  enabled?: boolean;
  label?: string;
}) => {
  const { bookingNo } = useFilterWorkspace();

  return (
    <FppSearchFilterSection
      enabled={enabled}
      label={label ?? "Booking"}
      numSelected={bookingNo?.length ? 1 : undefined}
      sectionId={"bookingNo"}
      leftIcon={stenaCalendarManage}
    >
      <BookingNumberFilterPane />
    </FppSearchFilterSection>
  );
};

const BookingNumberFilterPane: React.FC<{}> = () => {
  const { bookingNo, setBookingNumber } = useFilterWorkspace();

  return (
    <Row spacing={2}>
      <Space />
      <Label text={"Booking number"} width={250}>
        <NumericTextInput value={bookingNo} onValueChange={setBookingNumber} />
      </Label>
    </Row>
  );
};
