import { AppThunk } from "../../../config/redux/RootReducer";
import { apolloClient } from "../../../apollo-client/ApolloClient";
import { statBoxesRedux } from "../redux";
import { compact, values } from "lodash";
import { calculateCustomerRouteStatisticsTable } from "../transformers/StatBoxDataTransformer";
import { mergeStatBoxQueryData } from "../transformers/StatBoxQueryDataMerger";
import { cleanGQLPriceElseNull } from "../../product-and-price/rate-sheet/util/PriceCleaner";
import { rateSheetTableSelectors } from "../../product-and-price/rate-sheet/selectors";
import { gql } from "@apollo/client";
import {
  CalculatePriceRouteAgreementInput,
  CalculatePriceRouteAgreementPriceInput,
  RefetchDataForStatBoxesForRoutePairQuery,
  RefetchDataForStatBoxesForRoutePairQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query RefetchDataForStatBoxesForRoutePair(
    $input: CalculateActualPriceChangeForAllRatesInput!
  ) {
    productPrice {
      customerRouteStatistics {
        calculateActualPriceChangeForAllRates(input: $input) {
          actualOutcomeInPercent
          expectedVolume
          id
          sailingCategoryCode
          statVolume12Months
          expectedStatVolume
          targetInPercent
          unitCategoryCode
          volume12Months
        }
      }
    }
  }
`;

export const refetchStatBoxesWithRateSheetsChanges =
  (): AppThunk => async (dispatch, getState) => {
    const pricesGroupedByRouteAgreements =
      rateSheetTableSelectors.getTableState(getState());

    const { queryParams, headAgreementId } = getState().statBoxes;

    if (!queryParams) {
      throw new Error("Failed to refetch stat boxes, query params not set.");
    }

    if (!headAgreementId) {
      throw new Error("Failed to refetch stat boxes, headAgreementId not set.");
    }

    const { year, routePairId, customerId } = queryParams;

    const routeAgreements = compact(
      values(getState().rateSheet.routeAgreements)
    ).filter((routeAgreement) => routeAgreement.multiLegAgrRouteId === null);

    if (!pricesGroupedByRouteAgreements) {
      throw new Error(`No table state available`);
    }

    dispatch(statBoxesRedux.actions.setLoading());

    const routeAgreementsInput = routeAgreements
      .map<CalculatePriceRouteAgreementInput>((routeAgreement) => ({
        sailingTypeCodes: routeAgreement.sailingTypeCodes,
        routeAgreementPrices:
          pricesGroupedByRouteAgreements[
            routeAgreement.id
          ]?.rows.map<CalculatePriceRouteAgreementPriceInput>((ra) => ({
            routeAgreementId: routeAgreement.id,
            actual: ra.editable.actual,
            additionalFrom: ra.editable.additionalFrom,
            cargoStatusCode: ra.editable.cargoStatusCode,
            lastChargedFrom: ra.editable.lastChargedFrom,
            lastMeterPrice: cleanGQLPriceElseNull(ra.editable.lastMeterPrice),
            lastPrice: cleanGQLPriceElseNull(ra.editable.lastPrice),
            routeAgreementPriceId: ra.routeAgreementPriceId,
            lengthFrom: ra.editable.lengthFrom,
            lengthTo: ra.editable.lengthTo,
            meterPrice: cleanGQLPriceElseNull(ra.editable.meterPrice),
            newPrice: cleanGQLPriceElseNull(ra.editable.newPrice),
            percent: 0,
            avgGrossPrice: cleanGQLPriceElseNull(ra.editable.avgGrossPrice),
            avgLength: ra.editable.avgLength ?? null,
            avgRebate: cleanGQLPriceElseNull(ra.editable.avgRebate),
            unaccompanied: ra.editable.unaccompanied,
            vehicleTypeCode: ra.editable.vehicleType?.id ?? "",
            volume: ra.editable.volume,
            weightFrom: ra.editable.weightFrom,
            weightTo: ra.editable.weightTo,
            routePairCode: null,
            sailingCategoryCode: null,
          })) ?? [],
      }))
      .filter((ra) => ra.routeAgreementPrices.length > 0);

    const r = await apolloClient.query<
      RefetchDataForStatBoxesForRoutePairQuery,
      RefetchDataForStatBoxesForRoutePairQueryVariables
    >({
      query,
      variables: {
        input: {
          headAgreementId,
          routePairCode: routePairId,
          validFromYear: year,
          routeAgreements: routeAgreementsInput,
        },
      },
      fetchPolicy: "network-only",
    });

    const items =
      r.data.productPrice.customerRouteStatistics
        .calculateActualPriceChangeForAllRates;

    if (!items) {
      dispatch(statBoxesRedux.actions.setNotLoading());
      return;
    }

    const prevQueryData = getState().statBoxes.queryData ?? {};

    const queryData = mergeStatBoxQueryData(prevQueryData, items);

    dispatch(statBoxesRedux.actions.setQueryData(queryData));

    const transformedData = calculateCustomerRouteStatisticsTable(
      values(queryData),
      customerId
    );

    dispatch(statBoxesRedux.actions.setStatBoxesData(transformedData));
    dispatch(statBoxesRedux.actions.setNotLoading());
  };
