import { Select, SelectProps } from "@stenajs-webui/select";
import React, { useMemo } from "react";
import { SailingCategoryOption } from "../../../../../common/components/data-driven-inputs/selects/sailing-category-departure-select/SailingCategoryTimeTableSelect";

interface Props
  extends Omit<SelectProps<any>, "value" | "onChange" | "disabled"> {
  value?: string;
  onValueChange?: (value: SailingCategoryOption) => void;
  disabled?: boolean;
}

const sailingCategories = [
  {
    label: "Peak",
    value: "P",
    type: "sailingCategory",
  },
  {
    label: "Shoulder",
    value: "S",
    type: "sailingCategory",
  },
  {
    label: "Off-Peak",
    value: "O",
    type: "sailingCategory",
  },
];

export const PriceCalculatorDepartureCategorySelect: React.FC<Props> = ({
  onValueChange,
  value,
  disabled,
  ...selectProps
}) => {
  const selected = useMemo(
    () => sailingCategories.find((o) => value === o.value),
    [value]
  );
  return (
    <Select
      {...selectProps}
      value={selected || null}
      options={sailingCategories}
      onChange={(selected: SailingCategoryOption) => onValueChange?.(selected)}
      isDisabled={disabled}
      menuPortalTarget={document.body}
    />
  );
};
