import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { CardHeader, FlatButton } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {
  heading: string;
  handleChartOpen: () => void;
  chartOpen: boolean;
  loading: boolean;
}

export const TargetSummaryHeader: React.FC<Props> = ({
  heading,
  handleChartOpen,
  chartOpen,
  loading,
}) => (
  <CardHeader
    text={heading}
    contentRight={
      <FlatButton
        leftIcon={chartOpen ? faEyeSlash : faEye}
        label={chartOpen ? "Hide chart" : "Show chart"}
        onClick={handleChartOpen}
        loading={loading}
      />
    }
  />
);
