import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { Indent, Text } from "@stenajs-webui/core";
import React from "react";
import { PriceDetailTableRow } from "./PriceDetailTableRow";
import { PriceDetailTableColumnKeys } from "./PriceDetailsTableColumnKeys";

const createCellOrFooter = (
  rowId: string,
  rowValue: string | number,
  renderFooter: string
) => {
  return (
    <Indent>
      {rowId === "FOOTER" ? (
        <Text variant={"bold"}>{renderFooter}</Text>
      ) : (
        <Text>{rowValue}</Text>
      )}
    </Indent>
  );
};

export const tableConfig: StandardTableConfig<
  PriceDetailTableRow,
  PriceDetailTableColumnKeys
> = {
  disableInfiniteList: true,
  showHeaderCheckbox: false,
  showRowCheckbox: false,
  disableSorting: true,
  rowIndent: 1,
  keyResolver: (item) => item.id,
  columns: {
    name: createColumnConfig((item) => item.name, {
      minWidth: "140px",
      renderCell: ({ label, value, item }) =>
        createCellOrFooter(item.id, item.name, "Total"),
      columnLabel: "Article",
    }),
    code: createColumnConfig((item) => item.code, {
      justifyContentHeader: "flex-end",
      justifyContentCell: "flex-end",
      renderCell: ({ label, value, item }) =>
        createCellOrFooter(item.id, item.code, ""),
      columnLabel: "Code",
      minWidth: "50px",
    }),
    priceExclVat: createColumnConfig((item) => item.priceExclVat, {
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",

      renderCell: ({ label, value, item }) =>
        createCellOrFooter(
          item.id,
          item.priceExclVat.toFixed(2),
          item.priceExclVat.toFixed(2)
        ),
      columnLabel: "Price (excl VAT)",
      minWidth: "110px",
    }),
    vatAmount: createColumnConfig((item) => item.vatAmount, {
      justifyContentHeader: "flex-end",
      justifyContentCell: "flex-end",
      renderCell: ({ label, value, item }) =>
        createCellOrFooter(
          item.id,
          item.vatAmount.toFixed(2),
          item.vatAmount.toFixed(2)
        ),
      columnLabel: "VAT amount",
      minWidth: "105px",
    }),
    vatRate: createColumnConfig((item) => item.vatRate, {
      justifyContentHeader: "flex-end",
      justifyContentCell: "flex-end",
      renderCell: ({ label, value, item }) =>
        createCellOrFooter(item.id, item.vatRate.toFixed(0) + " %", ""),
      columnLabel: "VAT rate",
      minWidth: "80px",
    }),
    priceInclVat: createColumnConfig((item) => item.priceInclVat, {
      justifyContentHeader: "flex-end",
      justifyContentCell: "flex-end",
      renderCell: ({ label, value, item }) =>
        createCellOrFooter(
          item.id,
          item.priceInclVat.toFixed(2) + " " + item.currencyCode,
          item.priceInclVat.toFixed(2) + " " + item.currencyCode
        ),
      columnLabel: "Price (incl VAT)",
      minWidth: "115px",
    }),
  },

  columnOrder: [
    "name",
    "code",
    "priceExclVat",
    "vatAmount",
    "vatRate",
    "priceInclVat",
  ],
};
