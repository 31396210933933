import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import { Card, FlatButton } from "@stenajs-webui/elements";
import {
  StandardTable,
  StandardTableAction,
  TableContext,
} from "@stenajs-webui/grid";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useShallowEqualSelector } from "../../../../../common/hooks/UseShallowEqualSelector";
import { profileSelectors } from "../../../../../common/redux/profile/selectors";
import { gtmTransmitter } from "../../../../../common/utils/GtmHelper";
import { RouteTargetTableEvents } from "../../../../../common/utils/route-target-gtm/RouterTargetTableGtmHelper";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { routePairTargetStandardTableActions } from "../../redux";
import { RoutePairCustomerTargetTableHeader } from "./RoutePairCustomerTargetTableHeader";
import { createRoutePairCustomerTargetTableConfig } from "./config/RoutePairCustomerTargetTableConfig";
import {
  AllCustomerTableColumnKeys,
  AllCustomerTargetsTableRow,
} from "./config/RowsAndColumnsConfig";
import { RoutePairNavigationYear } from "./hooks/UseRoutePairNegotiationYearStatus";
import { routeCustomerTargetSelectors } from "./redux/selectors";

interface Props {
  tableData: AllCustomerTargetsTableRow[];
  routePairNavigationYear: RoutePairNavigationYear | undefined;
  loading: boolean;
}
const getLoadState = (state: StoreState) => {
  const { isWarningVisible, bannerState } =
    routeCustomerTargetSelectors.getRouteCustomerSortLoadState(state);
  return {
    isWarningVisible,
    bannerState,
  };
};

const _RoutePairCustomerTargetTable: React.FC<Props> = ({
  tableData,
  routePairNavigationYear,
  loading,
}) => {
  const dispatch = useDispatch();

  const { openFilter } = useFilterWorkspace();

  const { currentCurrency } = useSelector(profileSelectors.getGlobal);
  const countClicked = useSelector(
    routeCustomerTargetSelectors.getDiscardCountClickedState
  );
  const isFirstRender = useRef(true);
  const { isWarningVisible, bannerState } =
    useShallowEqualSelector(getLoadState);

  const isRowsSelected = useSelector(
    routeCustomerTargetSelectors.hasSelectedRows
  );

  const routeCustomerTargetEditedData = useSelector(
    routeCustomerTargetSelectors.getEditedCustomerTargetTableRows
  );
  const routeCustomerTargetPersistedData = useSelector(
    routeCustomerTargetSelectors.getPersistedCustomerTargetTableRows
  );
  const listOrder = useSelector(routeCustomerTargetSelectors.getListOrder);
  const isDataModified = useMemo(() => {
    return listOrder.some((order) => {
      return (
        JSON.stringify(routeCustomerTargetEditedData[order]) !==
        JSON.stringify(routeCustomerTargetPersistedData[order])
      );
    });
  }, [
    listOrder,
    routeCustomerTargetEditedData,
    routeCustomerTargetPersistedData,
  ]);

  const standardTableSelector = useCallback(
    (state: StoreState) => routeCustomerTargetSelectors.getTableState(state),
    []
  );
  const standardTableState = useSelector(standardTableSelector);

  const tableContext = useMemo<TableContext<AllCustomerTableColumnKeys>>(() => {
    return {
      state: standardTableState,
      actions: routePairTargetStandardTableActions,
      dispatch: (action: StandardTableAction<AllCustomerTableColumnKeys>) => {
        dispatch(action);
      },
    };
  }, [dispatch, standardTableState]);

  const { isSortingDisabled } = useSelector(
    routeCustomerTargetSelectors.getRouteCustomerSortLoadState
  );

  const isRoutePairStatusDone = routePairNavigationYear?.status === "D";

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      gtmTransmitter({
        event: RouteTargetTableEvents.clickEvent,
        actionEvent: RouteTargetTableEvents.sortColumn,
        labelEvent: `${tableContext.state.sortOrder.sortBy}`,
      });
    }
  }, [tableContext.state.sortOrder.sortBy]);

  const config = useMemo(() => {
    return createRoutePairCustomerTargetTableConfig(
      currentCurrency,
      isRoutePairStatusDone,
      isSortingDisabled,
      isRowsSelected,
      isDataModified,
      !!isWarningVisible
    );
  }, [
    currentCurrency,
    isRoutePairStatusDone,
    isSortingDisabled,
    isRowsSelected,
    isDataModified,
    isWarningVisible,
  ]);

  return (
    <Card>
      <RoutePairCustomerTargetTableHeader
        numShowing={tableData.length}
        tableContext={tableContext}
      />

      <StandardTable
        key={countClicked}
        config={config}
        items={tableData ?? []}
        loading={loading}
        noItemsHeader={"Search for customer targets"}
        error={bannerState && new Error(`${bannerState.headerText}`)}
        noItemsContentRight={
          <FlatButton
            label={"Open filter"}
            onClick={() => {
              openFilter();
              gtmTransmitter({
                event: RouteTargetTableEvents.clickEvent,
                eventAction: RouteTargetTableEvents.openFilter,
              });
            }}
          />
        }
        noItemsLabel={
          "Please use the filters to search for customer targets.\n"
        }
        tableContext={tableContext}
      />
    </Card>
  );
};

export const RoutePairCustomerTargetTable = memo(_RoutePairCustomerTargetTable);
