import * as React from "react";
import {
  LinkProps,
  useHref,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import { Link as WebUiLink } from "@stenajs-webui/elements";
import { createPath } from "history";
import { useNavigate } from "react-router-dom";

export const Link: React.FC<Omit<LinkProps, "ref">> = ({
  children,
  ...props
}) => <LinkReactRouterBeta {...props}>{children}</LinkReactRouterBeta>;

/**
 * The public API for rendering a history-aware <a>.
 * Taken from: https://github.com/ReactTraining/react-router/blob/dev/packages/react-router-dom/index.tsx
 */
const LinkReactRouterBeta = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkWithRef(
    {
      onClick,
      replace: replaceProp = false,
      state,
      target,
      to,
      children,
      title,
      ...rest
    },
    ref
  ) {
    let href = useHref(to);
    let navigate = useNavigate();
    let location = useLocation();
    let path = useResolvedPath(to);

    function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
      if (onClick) onClick(event);
      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // Ignore everything but left clicks
        (!target || target === "_self") && // Let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // Ignore clicks with modifier keys
      ) {
        event.preventDefault();

        // If the URL hasn't changed, a regular <a> will do a replace instead of
        // a push, so do the same here.
        let replace =
          !!replaceProp || createPath(location) === createPath(path);

        navigate(to, { replace, state });
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <WebUiLink
        href={href}
        onClick={handleClick}
        ref={ref}
        target={target}
        title={title}
      >
        {children}
      </WebUiLink>
    );
  }
);

function isModifiedEvent(event: React.MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
