import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ApolloError } from "@apollo/client";
import { Box, SeparatorLine, Space } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { StandardTableAction, TableContext } from "@stenajs-webui/grid";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterRowOptionsInitialState } from "../../constants/constants";
import { agreementArticlesStandardTableActions } from "../../redux/reducer";
import { agreementArticlesSelectors } from "../../redux/selectors";
import { AgreementArticlesCustomer } from "../../types";
import { BulkUpdateAgreementArticlesToolbar } from "../bulk-update/BulkUpdateAgreementArticlesToolbar";
import { AgreementArticlesFilterRow } from "../filter/AgreementArticlesFilterRow";
import {
  FilterRowOptions,
  filteredAgreementArticles,
} from "../filter/helpers/FilteredOptions";
import { AgreementArticlesTable } from "./AgreementArticlesTable";
import { AgreementArticlesTableColumns } from "./config/RowsAndColumnsConfig";

interface Props {
  customer: AgreementArticlesCustomer;
  error: ApolloError | undefined;
}

export const AgreementArticlesTableWrapper: React.FC<Props> = ({
  customer,
  error,
}) => {
  const dispatch = useDispatch();

  const [filterOptions, setFilterOptions] = useState<FilterRowOptions>(
    filterRowOptionsInitialState
  );

  const agreementArticles = useSelector(
    agreementArticlesSelectors.getAgreementArticlesTableItems
  );

  const standardTableState = useSelector(
    agreementArticlesSelectors.getStandardTableState
  );

  const cardHeader = `${
    filteredAgreementArticles({
      agreementArticles,
      filterOptions,
    }).length
  } of ${agreementArticles.length} articles`;

  const tableContext = useMemo<
    TableContext<AgreementArticlesTableColumns>
  >(() => {
    return {
      state: standardTableState,
      actions: agreementArticlesStandardTableActions,
      dispatch: (
        action: StandardTableAction<AgreementArticlesTableColumns>
      ) => {
        dispatch(action);
      },
    };
  }, [dispatch, standardTableState]);

  useEffect(() => {
    return () => {
      dispatch(tableContext.actions.clearSelection());
    };
  }, [dispatch, tableContext.actions]);

  const selectedRows = tableContext.state.selectedIds.selectedIds.length;

  return (
    <Card>
      <CardHeader text={cardHeader} />
      <SeparatorLine />
      {selectedRows <= 0 ? (
        <>
          <CardBody>
            <AgreementArticlesFilterRow
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
            />
          </CardBody>
          <SeparatorLine />
        </>
      ) : (
        <Box position={"sticky"}>
          <BulkUpdateAgreementArticlesToolbar
            customer={customer}
            tableContext={tableContext}
            numItemsSelected={selectedRows}
          />
        </Box>
      )}
      <Box indent={IndentValues.TWENTYFOUR}>
        <AgreementArticlesTable
          error={error}
          tableContext={tableContext}
          agreementArticles={agreementArticles}
          filterOptions={filterOptions}
        />
        <Space num={SpaceValues.TWENTYFOUR} />
      </Box>
    </Card>
  );
};
