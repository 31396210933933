import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { profileActions } from "@/common/redux/profile/actions";
import { profileSelectors } from "@/common/redux/profile/selectors";

export interface YearSelection {
  year: number;
  handleYearChange: (year: number) => void;
}

export const useStatisticsYearSelect = (): YearSelection => {
  const { currentYear } = useSelector(profileSelectors.getGlobal);

  const dispatch = useDispatch();
  const [year, setYear] = useState<number>(currentYear);

  useEffect(() => {
    setYear(currentYear);
  }, [currentYear]);

  const handleYearChange = (selectedYear: number) => {
    dispatch(
      profileActions.setGlobalState({
        currentYear: selectedYear,
      })
    );
    setYear(selectedYear);
  };

  return {
    year,
    handleYearChange,
  };
};
