import { createEntityReducer, reducerIdGate } from "@stenajs-webui/redux";

export interface RouteTargetPageGeneralState {
  currentRoutePairCode: string;
  previousRoutePairCode: string;
}

export const routeTargetPageReducerId = "routeTargetPageState";

export const routeTargetPageReducer = reducerIdGate(
  routeTargetPageReducerId,
  createEntityReducer<RouteTargetPageGeneralState>({
    currentRoutePairCode: "",
    previousRoutePairCode: "",
  })
);
