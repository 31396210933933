import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { EditableEntityState } from "@stenajs-webui/redux";
import { inRange } from "lodash";
import { allTypesValue } from "../../../constants/constants";
import { AgreementArticlesTableItem } from "../../../types";

export interface FilterRowOptions {
  distanceTypeCode: string;
  articleCode: string;
  vehicleTypeCode: string | undefined;
  routePairCode: string;
  lengthFrom: string;
  lengthTo: string;
}

export const filteredAgreementArticles = ({
  agreementArticles,
  filterOptions,
}: {
  agreementArticles: EditableEntityState<AgreementArticlesTableItem>[];
  filterOptions: FilterRowOptions;
}) => {
  const articles = agreementArticles.filter((data) => {
    if (
      filterOptions.distanceTypeCode &&
      data.editable.distanceTypeCode !== filterOptions.distanceTypeCode
    )
      return false;

    if (
      filterOptions.articleCode &&
      data.editable.articleCode !== filterOptions.articleCode
    )
      return false;

    if (
      filterOptions.vehicleTypeCode &&
      filterOptions.vehicleTypeCode !== allTypesValue &&
      data.editable.vehicleTypeCode !== filterOptions.vehicleTypeCode
    )
      return false;

    if (
      filterOptions.vehicleTypeCode &&
      filterOptions.vehicleTypeCode === allTypesValue &&
      data.editable.vehicleTypeCode !== null
    )
      return false;

    if (
      filterOptions.routePairCode &&
      data.editable.routePairCode !== filterOptions.routePairCode
    )
      return false;

    if (
      (filterOptions.lengthFrom && data.editable.lengthFrom) ||
      (filterOptions.lengthFrom && data.editable.lengthFrom === 0)
    ) {
      const isInRange =
        data.editable.lengthFrom ===
          Number(commaToDotTransform(filterOptions.lengthFrom)) ||
        inRange(
          data.editable.lengthFrom,
          Number(commaToDotTransform(filterOptions.lengthFrom)),
          Infinity
        );
      if (!isInRange) return false;
    }

    if (
      (filterOptions.lengthTo && data.editable.lengthTo) ||
      (filterOptions.lengthTo && data.editable.lengthTo === 0)
    ) {
      const isInRange =
        data.editable.lengthTo ===
          Number(commaToDotTransform(filterOptions.lengthTo)) ||
        inRange(
          data.editable.lengthTo,
          0,
          Number(commaToDotTransform(filterOptions.lengthTo))
        );
      if (!isInRange) return false;
    }
    return true;
  });
  return articles;
};
