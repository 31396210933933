import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import { SeparatorLine } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import * as React from "react";
import { useMemo, useState } from "react";
import { AgreementDocumentsTabMenu } from "../../common/components/AgreementDocumentsTabMenu";
import { rebateAgreementDocumentsTableConfig } from "../config/RebateAgreementDocumentsTableConfig";
import { Customer } from "../types";
import { RebateAgreementDocumentsHeader } from "./RebateAgreementDocumentsHeader";

interface Props {
  customer: Customer;
}

export const RebateAgreementDocuments: React.FC<Props> = ({ customer }) => {
  const rebateDocuments = customer?.rebateAgreementDocuments;
  const [downloadError, setDownloadError] =
    useState<FreightPricingPortalError>();

  const [deletedListItems, setDeletedListItems] = useState<Array<String>>([]);

  const config = useMemo(() => {
    return rebateAgreementDocumentsTableConfig(
      downloadError,
      setDownloadError,
      setDeletedListItems
    );
  }, [downloadError, setDownloadError, setDeletedListItems]);

  const items = rebateDocuments?.filter((d) => {
    return !deletedListItems.includes(d.id);
  });

  return (
    <>
      <RebateAgreementDocumentsHeader
        customer={customer}
        downloadError={downloadError}
      />
      <PageLayout>
        {customer && <CustomerInfoPairBox customer={customer} />}
        <Card>
          <CardHeader
            contentLeft={
              <AgreementDocumentsTabMenu customerNo={customer?.id ?? ""} />
            }
          />
          <CardBody>
            <StandardTable
              items={items}
              config={config}
              loading={false}
              noItemsLabel={"No documents found."}
            />
          </CardBody>
          <SeparatorLine />
        </Card>
      </PageLayout>
    </>
  );
};
