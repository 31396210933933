import * as React from "react";
import { useMemo, useState } from "react";
import { Box, Indent, Row, SeparatorLine, Space } from "@stenajs-webui/core";
import {
  Banner,
  PrimaryButton,
  SecondaryButton,
} from "@stenajs-webui/elements";
import {
  createStandardTableInitialState,
  TableContext,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { useNavigate } from "react-router-dom";
import { HeadAgreementForm } from "../../common/components/HeadAgreementForm";
import { sortMultiLegAgreementsForCopy } from "../../common/util/SortMultiLegAgreementsForCopy";
import { sortRouteAgreementsForCopy } from "../../common/util/SortRouteAgreementsForCopy";
import { useCopyHeadAgreementMutation } from "../mutations/UseCopyHeadAgreementMutation";
import {
  transformFormModelToInput,
  transformToFormModel,
} from "../transformers/CopyHeadAgreementTransformers";
import { HeadAgreement } from "../types";
import { getIdsForMultiLegAgreementsToPrefill } from "../util/MultiLegSelectionPrefiller";
import { CopyHaToOtherCustomerModal } from "./copy-or-create-new-ha-to-other-customer/CopyHaToOtherCustomerModal";
import {
  SelectedMultiLegAgreementColumns,
  SelectMultiLegAgreements,
} from "./MultiLegAgreements";
import {
  SelectedRouteAgreementColumns,
  SelectRouteAgreements,
} from "./SelectRouteAgreements";
import { LoadingModalWithLock } from "@/common/components/loading-modals/LoadingModalWithLock";
import { PageSubHeader } from "@/common/components/page-header/PageSubHeader";
import { stripGqlErrorText } from "@/common/string/GraphQlErrorParser";
import { routeFactory } from "@/RouteFactory";
import {
  ByCustomerNumberOrIndexFragment,
  CopyHeadAgreementTargetCustomerQuery,
} from "@/gql/graphql";

interface Props {
  headAgreement: HeadAgreement;
  targetHeadAgreement?: CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"];
  targetCustomer?: ByCustomerNumberOrIndexFragment;
  copyHaToOtherCustomer?: boolean;
  tableContext: TableContext<SelectedRouteAgreementColumns>;
}

export const CopyHeadAgreement: React.FC<Props> = ({
  headAgreement,
  targetHeadAgreement,
  targetCustomer,
  copyHaToOtherCustomer,
  tableContext,
}) => {
  const navigate = useNavigate();
  const [formModel, setFormModel] = useState(() =>
    transformToFormModel(
      headAgreement,
      copyHaToOtherCustomer ? targetHeadAgreement : undefined,
      targetCustomer?.id
    )
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [successInModal, setSuccessInModal] = useState(false);
  const [localError, setLocalError] = useState<Error | undefined>();
  const { error, loading, copyHeadAgreement } = useCopyHeadAgreementMutation();
  const [copyAgreementResult, setCopyAgreementResult] = useState<any>();

  const initialState = useMemo(
    () =>
      createStandardTableInitialState<SelectedMultiLegAgreementColumns>(
        undefined,
        undefined,
        getIdsForMultiLegAgreementsToPrefill(headAgreement)
      ),
    [headAgreement]
  );

  const { tableContext: multiLegAgreementTableContext } =
    useLocalStateTableContext(
      "multi-leg-agreement-table-head-agreement",
      initialState
    );

  const submitHandler = async () => {
    setLocalError(undefined);
    setModalOpen(true);
    let input;
    try {
      input = transformFormModelToInput(
        headAgreement.id,
        formModel,
        tableContext.state.selectedIds.selectedIds,
        multiLegAgreementTableContext.state.selectedIds.selectedIds,
        copyHaToOtherCustomer ? targetHeadAgreement?.id : null,
        copyHaToOtherCustomer && !targetHeadAgreement?.id
          ? targetCustomer?.custNo
          : null
      );
    } catch (e) {
      setLocalError(e);
    }
    if (input) {
      try {
        const { data } = await copyHeadAgreement(input);
        if (!data) {
          setModalOpen(false);
          setLocalError(new Error("Received no data from server."));
          return;
        }
        const result = data?.productPrice.headAgreement.copyHeadAgreement;

        if (result && "errors" in result) {
          setModalOpen(false);
          setLocalError(
            new Error(result.errors.map((error) => error.message).join("\n"))
          );
        } else {
          setCopyAgreementResult(result.headAgreement);

          copyHaToOtherCustomer
            ? setSuccessInModal(true)
            : navigate(
                routeFactory.productAndPrice.customer.customerHeadAgreement({
                  customerId: result.headAgreement?.customer.id,
                  headAgreementId: result.headAgreement?.id,
                })
              );
        }
      } catch (e) {
        setLocalError(e);
      }
    }
  };

  const filteredSortedRouteAgreements = useMemo(
    () =>
      sortRouteAgreementsForCopy(headAgreement.routeAgreements).filter(
        (ra) => !ra.multiLegAgrRouteId
      ),
    [headAgreement.routeAgreements]
  );

  const sortedMultiLegAgreements = useMemo(
    () => sortMultiLegAgreementsForCopy(headAgreement.multiLegAgrRoutes),
    [headAgreement.multiLegAgrRoutes]
  );

  const {
    state: {
      selectedIds: { selectedIds },
    },
  } = tableContext;

  const {
    state: {
      selectedIds: { selectedIds: multiLegSelectedIds },
    },
  } = multiLegAgreementTableContext;

  const numSelectedRouteAgreements = selectedIds.length;
  const numSelectedMultiLegAgreements = multiLegSelectedIds.length;

  return (
    <>
      <HeadAgreementForm
        value={formModel}
        onValueChange={setFormModel}
        targetHeadAgreement={targetHeadAgreement}
        targetCustomer={targetCustomer}
        copyHaToOtherCustomer={copyHaToOtherCustomer}
      />
      <Space num={2} />
      <PageSubHeader title={"Select route agreements to include in copy"} />
      <Box>
        <SelectRouteAgreements
          routeAgreements={filteredSortedRouteAgreements}
          headAgreementId={headAgreement.id}
          tableContext={tableContext}
        />
      </Box>
      <Space num={2} />
      <PageSubHeader title={"Select multi leg agreements to include in copy"} />
      <Box>
        <SelectMultiLegAgreements
          multiLegAgreements={sortedMultiLegAgreements}
          headAgreementId={headAgreement.id}
          tableContext={multiLegAgreementTableContext}
        />
      </Box>
      <SeparatorLine />
      <Space num={2} />
      {!copyHaToOtherCustomer &&
        numSelectedRouteAgreements === 0 &&
        numSelectedMultiLegAgreements === 0 && (
          <Row justifyContent={"flex-end"} spacing={2} indent={2}>
            <Banner
              variant={"info"}
              headerText={
                "No route agreements or multi leg agreements included in copy"
              }
              text={
                "If you don't select any, an empty header agreement copy will be created."
              }
            />
          </Row>
        )}
      {(error || localError) && (
        <Row justifyContent={"flex-end"} indent={2}>
          <Banner
            variant={"error"}
            headerText={"Unable to copy header agreement"}
            text={stripGqlErrorText(
              error?.message ?? localError?.message ?? ""
            )}
          />
        </Row>
      )}

      <Row
        alignItems={"center"}
        justifyContent={"flex-end"}
        indent={2}
        spacing={2}
      >
        {!loading && (
          <SecondaryButton label={"Cancel"} onClick={() => navigate(-1)} />
        )}
        <Indent />

        {loading && (
          <LoadingModalWithLock
            label={"Copying header agreement. Thanks for your patience."}
          />
        )}
        <PrimaryButton
          label={"Copy agreement"}
          onClick={submitHandler}
          disabled={
            copyHaToOtherCustomer &&
            numSelectedRouteAgreements === 0 &&
            numSelectedMultiLegAgreements === 0
          }
        />
      </Row>
      {!loading && successInModal && !error && !localError && (
        <CopyHaToOtherCustomerModal
          loading={loading}
          open={modalOpen}
          onRequestClose={() => {
            setModalOpen(false);
          }}
          success={successInModal}
          headAgreement={headAgreement}
          formModel={formModel}
          targetCustomerPath={routeFactory.productAndPrice.customer.customerHeadAgreement(
            {
              customerId: copyAgreementResult.customer.id ?? "",
              headAgreementId: copyAgreementResult?.id,
            }
          )}
          originalCustomerPath={routeFactory.productAndPrice.customer.customerHeadAgreement(
            {
              customerId: headAgreement.customer.id,
              headAgreementId: headAgreement?.id,
            }
          )}
          targetHeadAgreement={targetHeadAgreement}
        />
      )}
    </>
  );
};
