import { useCallback, useState } from "react";
import { useBoolean } from "@stenajs-webui/core";

export type BannerErrorSetter = ReturnType<
  typeof useBannerError
>["setBannerError"];

export type BannerErrorClearer = ReturnType<
  typeof useBannerError
>["clearBannerError"];

export const useBannerError = () => {
  const [bannerErrorText, setBannerErrorText] = useState<string | undefined>(
    undefined
  );
  const [bannerErrorHeaderText, setBannerErrorHeaderText] = useState<
    string | undefined
  >(undefined);
  const [hasBannerError, setHasBannerError, setHasNoBannerError] =
    useBoolean(false);

  const setBannerTextError = useCallback(
    (headerText: string, text: string) => {
      setBannerErrorText(text);
      setBannerErrorHeaderText(headerText);
      setHasBannerError();
    },
    [setBannerErrorText, setBannerErrorHeaderText, setHasBannerError]
  );

  const setBannerError = useCallback(
    (headerText: string, error: Error) => {
      setBannerTextError(headerText, error.message);
    },
    [setBannerTextError]
  );

  const clearBannerError = useCallback(() => {
    setHasNoBannerError();
  }, [setHasNoBannerError]);

  return {
    bannerErrorText,
    bannerErrorHeaderText,
    hasBannerError,
    setBannerTextError,
    setBannerError,
    clearBannerError,
  };
};
