export const negotiationStatusCodeTransformer = (
  negotiationStatusCode: string
): string => {
  switch (negotiationStatusCode) {
    case "D":
      return "Done";
    case "T":
      return "Todo";
    case "I":
      return "In progress";
    case "RA":
      return "Ready to activate";
    case "RO":
      return "Ready to offer";
    case "P":
      return "Parked";
    case "R":
      return "Renegotiated";
    default:
      return "";
  }
};
