import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { AdditionalSurchargesTableRow } from "../SurchargesTable";

export type AdditionalSurchargeTableColumnKeys = keyof Omit<
  AdditionalSurchargesTableRow,
  "id"
>;

export const tableConfig: StandardTableConfig<
  AdditionalSurchargesTableRow,
  AdditionalSurchargeTableColumnKeys
> = {
  disableInfiniteList: true,
  keyResolver: (item) => item.id,
  showRowCheckbox: true,
  showHeaderCheckbox: true,
  columns: {
    validFrom: createColumnConfig((item) => item.validFrom, {
      columnLabel: "Valid from",
    }),
    seaAreaCode: createColumnConfig((item) => item.seaAreaCode, {
      columnLabel: "Sea area",
    }),
    currencyCode: createColumnConfig((item) => item.currencyCode, {
      columnLabel: "Currency",
    }),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
    }),
  },
  columnOrder: ["validFrom", "seaAreaCode", "currencyCode", "name"],
};
