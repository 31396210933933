import { IndentValues } from "@/common/utils/IndentValues";
import { Indent, Spacing } from "@stenajs-webui/core";
import * as React from "react";
import { FormModelChangeHandler } from "../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../PriceCalculatorFormFetcher";
import { PriceCalculatorDriversPassengersDetails } from "./sailing-details/PriceCalculatorDriversPassengersDetails";
import { PriceCalculatorSailingDetails } from "./sailing-details/PriceCalculatorSailingDetails";
import { PriceCalculatorVehicleDetails } from "./sailing-details/PriceCalculatorVehicleDetails";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorSectional: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <Indent num={IndentValues.THIRTYTWO}>
      <PriceCalculatorSailingDetails
        formData={formData}
        onChangeFormData={onChangeFormData}
        isSectional
      />

      <PriceCalculatorVehicleDetails
        isMultileg={false}
        formData={formData}
        onChangeFormData={onChangeFormData}
      />
      <Spacing />

      <PriceCalculatorDriversPassengersDetails
        formData={formData}
        onChangeFormData={onChangeFormData}
      />
    </Indent>
  );
};
