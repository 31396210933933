import React, { useState } from "react";
import { FreightPricingPortalError } from "../../../../common/error/models/FreightPricingPortalError";
import {
  BulkUpdateBookingRow,
  bulkUpdateCategorySelected,
} from "./BulkUpdateBookingRow";

export interface Props {
  bookingsSelected: number;
  localErrors: FreightPricingPortalError[];
  setLocalErrors: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError[]>
  >;
}

export const NoShowLateHandlingHandleBookingsBulkEditing: React.FC<Props> = ({
  bookingsSelected,
  localErrors,
  setLocalErrors,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    bulkUpdateCategorySelected.bookingStatus
  );

  return (
    <BulkUpdateBookingRow
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      setLocalErrors={setLocalErrors}
      localErrors={localErrors}
      bookingsSelected={bookingsSelected}
    />
  );
};
