import { isDefined } from "@/common/types/IsDefined";
import { stenaUserMultiple } from "@stenajs-webui/elements";
import { FilterEntity } from "@stenajs-webui/filter";
import { ChipMultiSelect } from "@stenajs-webui/select";
import * as React from "react";
import { useEffect, useState } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { transformQueryResultToFilterEntity } from "../../utils/transformers";
import { useLoadCustomers } from "../../hooks/UseLoadCustomers";

interface CustomerOption {
  label: string;
  value: string;
  data?: FilterEntity;
}

interface Props {
  enabled?: boolean;
  label?: string;
}
export const CustomerFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <CustomerFilterSectionInternal {...props} /> : <></>;

const CustomerFilterSectionInternal: React.FC<Props> = ({ enabled, label }) => {
  const { customers, setCustomers } = useFilterWorkspace();
  const { fetchCustomers, loading, error, data } = useLoadCustomers();
  const [inputText, setInputText] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Array<FilterEntity>>(
    []
  );

  const selectOptions = filteredOptions.map<CustomerOption>((option) => ({
    value: option.code,
    label: `${option.code} - ${option.name}`,
    data: option,
  }));

  const values = customers.map<CustomerOption>((customer) => ({
    value: customer.code,
    label: customer.name,
    data: customer,
  }));

  const onInputChange = (inputValue: string) => {
    setInputText(inputValue);
    if (inputValue.length >= 2) {
      fetchCustomers({ variables: { query: inputValue } });
    }
  };

  const onValueChange = (filterItems: CustomerOption[]) => {
    setCustomers(
      filterItems.map((fi) => (fi as CustomerOption)?.data).filter(isDefined)
    );
  };

  useEffect(() => {
    if (data) {
      const transformedData =
        data?.productPrice.customers.byCustomerNumberOrIndex.map((customer) =>
          transformQueryResultToFilterEntity(customer)
        ) ?? [];
      setFilteredOptions(transformedData);
    }
  }, [data, setFilteredOptions]);

  return (
    <FppSearchFilterSection
      sectionId={"customers"}
      label={label}
      enabled={enabled}
      numSelected={customers.length}
      leftIcon={stenaUserMultiple}
      error={error?.message}
      onRetry={fetchCustomers}
      children={
        <ChipMultiSelect
          options={selectOptions}
          loading={loading}
          value={values}
          inputValue={inputText}
          onInputChange={onInputChange}
          onValueChange={(customers) => onValueChange(customers)}
        />
      }
    />
  );
};
