import {
  CalculatePriceRouteAgreementPriceInput,
  ItemByRoutePairCodesForCustomersFragment,
  SailingTypeCode,
} from "@/gql/graphql";
import {
  groupCustomerStatisticsBySailingCategoryCode,
  groupCustomerStatisticsByUnitCategoryCode,
} from "../../../../../customer-statistics/util/CustomerStatisticsGrouper";
import { BulkEditRatePriceTableItem } from "../../../types";
import { BulkEditRatesStatBoxesData } from "../models/BulkEditRatesStatBoxData";

export interface BulkEditRatesRoutePriceVariant
  extends BulkEditRatesRoutePriceVariantSummationFields {
  id: string;
  customerId: string;
  accompanied: boolean;
}

interface BulkEditRatesRoutePriceVariantSummationFields {
  target: number | null;
  actual: number;
  volume: number;
  statVolume: number;
  expectedVolume: number;
  expectedStatVolume: number;
  routePairCode?: string | null;
  sailingTypeCode?: SailingTypeCode;
}

const sumStatisticalData = (data: ItemByRoutePairCodesForCustomersFragment[]) =>
  data.reduce<BulkEditRatesRoutePriceVariantSummationFields>(
    (acc, item) => {
      acc.expectedVolume += item.expectedVolume ?? 0;
      acc.expectedStatVolume += item.expectedStatVolume ?? 0;
      acc.statVolume += item.statVolume12Months ?? 0;
      acc.actual += item.actualOutcomeInPercent ?? 0;
      if (item.targetInPercent !== null) {
        if (acc.target === null) {
          acc.target = 0;
          acc.target += item.targetInPercent ?? 0;
        } else {
          acc.target += item.targetInPercent ?? 0;
        }
      }
      acc.volume += item.volume12Months ?? 0;
      acc.routePairCode = item.routePairCode;
      acc.sailingTypeCode = item.sailingCategoryCode;

      return acc;
    },
    {
      target: null,
      actual: 0,
      volume: 0,
      statVolume: 0,
      expectedVolume: 0,
      expectedStatVolume: 0,
      routePairCode: "",
    }
  );

const transformToBulkEditRatesRoutePriceVariant = (
  data: ItemByRoutePairCodesForCustomersFragment[],
  accompanied: boolean,
  customerId: string
): BulkEditRatesRoutePriceVariant => {
  const {
    expectedVolume,
    expectedStatVolume,
    actual,
    statVolume,
    target,
    volume,
    routePairCode,
    sailingTypeCode,
  } = sumStatisticalData(data);

  return {
    accompanied,
    actual,
    target,
    statVolume,
    volume,
    expectedVolume,
    expectedStatVolume,
    customerId,
    routePairCode,
    sailingTypeCode,
    id: data[0]?.id ?? "dummy",
  };
};

export const calculateCustomerStatisticsBulkEditRatesTable = (
  data: ItemByRoutePairCodesForCustomersFragment[],
  customerId: string,
  routePairCode: string
): BulkEditRatesStatBoxesData => {
  const customerStatisticsBySailingCategoryCode =
    groupCustomerStatisticsBySailingCategoryCode(data);

  const peak = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.peak
  );

  const shoulder = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.shoulder
  );

  const offPeak = groupCustomerStatisticsByUnitCategoryCode(
    customerStatisticsBySailingCategoryCode.offPeak
  );

  const peakData = [
    transformToBulkEditRatesRoutePriceVariant(
      peak.accompanied,
      true,
      customerId
    ),
    transformToBulkEditRatesRoutePriceVariant(
      peak.unaccompanied,
      false,
      customerId
    ),
  ];

  const shoulderData = [
    transformToBulkEditRatesRoutePriceVariant(
      shoulder.accompanied,
      true,
      customerId
    ),
    transformToBulkEditRatesRoutePriceVariant(
      shoulder.unaccompanied,
      false,
      customerId
    ),
  ];

  const offPeakData = [
    transformToBulkEditRatesRoutePriceVariant(
      offPeak.accompanied,
      true,
      customerId
    ),
    transformToBulkEditRatesRoutePriceVariant(
      offPeak.unaccompanied,
      false,
      customerId
    ),
  ];

  return {
    routePairCode,
    peakData,
    shoulderData,
    offPeakData,
  };
};

export const transformBulkEditRatePriceTableItemToPriceInput = (
  input: BulkEditRatePriceTableItem
): CalculatePriceRouteAgreementPriceInput => {
  const transformStringToPrice = (value: string | null | undefined) =>
    value != null ? { amount: value } : null;
  return {
    routeAgreementId: input.routeAgreement.id,
    actual: input.actual,
    additionalFrom: Number(input.additionalFrom),
    cargoStatusCode: input.cargoStatusCode,
    lastChargedFrom: input.lastChargedFrom,
    lastMeterPrice: transformStringToPrice(input.lastMeterPrice),
    lastPrice: transformStringToPrice(input.lastPrice),
    routeAgreementPriceId: input.id,
    lengthFrom: input.lengthFrom,
    lengthTo: input.lengthTo,
    meterPrice: transformStringToPrice(input.meterPrice),
    newPrice: transformStringToPrice(input.newPrice),
    percent: 0,
    avgGrossPrice: transformStringToPrice(input.avgGrossPrice),
    avgLength: input.avgLength ?? null,
    avgRebate: transformStringToPrice(input.avgRebate),
    unaccompanied: input.unaccompanied,
    vehicleTypeCode: input.vehicleType?.id ?? null,
    volume: input.volume,
    weightFrom: input.weightFrom,
    weightTo: input.weightTo,
    routePairCode: null,
    sailingCategoryCode: null,
  };
};
