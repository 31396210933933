import { AllCustomerTargetsTableRow } from "./RowsAndColumnsConfig";

export const nameColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.name;

export const countryColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.country;

export const customerNoColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.customerNo;

export const customerNVolumeValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.customerNVolume;

export const customerRVolumeValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.customerRVolume;

export const categoryColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.category ?? "";

export const salesRegionValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.salesRegion ?? "";

export const sellerColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.seller ?? "";

export const statNoColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.statNo ?? "";

export const statNVolumeColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.statNVolume ?? "";

export const statRVolumeColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.statRVolume;

export const sailingCatVolumeColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.sailingCat ?? "";

export const unitTypeColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.unitType ?? "";

export const statVolumeColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.statVolume;

export const customerVolumeColumnValueResolver = (
  item: AllCustomerTargetsTableRow
) => item.customerVolume;

export const netColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.net;

export const grossColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.gross;

export const targetColumnValueResolver = (item: AllCustomerTargetsTableRow) =>
  item.target;

export const getHeaderRowOffsetTop = (
  isRowsSelected: boolean,
  isWarningVisible: boolean
) => {
  if (isWarningVisible) {
    return "266px";
  }
  if (isRowsSelected) {
    return "210px";
  }
  return "216px";
};
