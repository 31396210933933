import { CustomerMultiLegAgreement } from "../types";
import { MultiLegAgreement } from "../redux";
import { WorkFlowStatusCode } from "@/gql/graphql";

export const transformGqlMultiLegToMultiLegAgreementEntity = (
  model: CustomerMultiLegAgreement
): MultiLegAgreement => {
  return {
    isSalesReady: model.workFlowStatusCode === WorkFlowStatusCode.Sready,
  };
};
