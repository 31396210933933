import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { Indent } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTable,
  StandardTableConfig,
  TableContext,
} from "@stenajs-webui/grid";
import * as React from "react";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { SelectedMultiLegAgreementColumns } from "../../copy/components/MultiLegAgreements";
import { SelectedMultiLegAgreement } from "../../copy/types";
import { WorkFlowStatusCode } from "@/gql/graphql";

interface Props {
  tableContext: TableContext<SelectedMultiLegAgreementColumns>;
  multiLegAgreements: Array<SelectedMultiLegAgreement>;
}

const activateHeadAgreementMultiLegTableConfig: StandardTableConfig<
  SelectedMultiLegAgreement,
  SelectedMultiLegAgreementColumns
> = {
  keyResolver: (item) => item.id,
  showRowCheckbox: true,
  showHeaderCheckbox: true,
  disableSorting: true,
  columns: {
    statusCode: createColumnConfig((item) => item.statusCode, {
      columnLabel: "Status",
      renderCell: ({ label, item }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={item.statusCode} />
        </Indent>
      ),
      minWidth: "90px",
    }),
    idNumber: createColumnConfig((item) => item.idNumber, {
      columnLabel: "Number",
      minWidth: "70px",
    }),
    route: createColumnConfig((item) => item.route.code, {
      columnLabel: "ML Route",
      minWidth: "100px",
    }),
    workFlowStatusCode: createColumnConfig(
      (item) =>
        item.workFlowStatusCode === WorkFlowStatusCode.Sready ? "YES" : "NO",
      {
        columnLabel: "Sales ready",
        minWidth: "82px",
      }
    ),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
      minWidth: "250px",
    }),
    valid: createColumnConfig((item) => formatGQLDateRange(item.valid), {
      columnLabel: "Validity",
      minWidth: "171px",
    }),
    currency: createColumnConfig((item) => item.currency.id, {
      minWidth: "73px",
    }),
  },
  columnOrder: [
    "statusCode",
    "idNumber",
    "route",
    "workFlowStatusCode",
    "name",
    "valid",
    "currency",
  ],
};

export const ActivateMultiLegAgreementsTable: React.FC<Props> = ({
  tableContext,
  multiLegAgreements,
}) => {
  return (
    <StandardTable
      config={activateHeadAgreementMultiLegTableConfig}
      items={multiLegAgreements}
      tableContext={tableContext}
      noItemsLabel={
        "There are no multi leg agreements under this header agreement."
      }
    />
  );
};
