import { NoShowLateHandlingTableColumnKeys } from "./RowsAndColumnsConfig";

export const noLabelColumnOrder: Array<NoShowLateHandlingTableColumnKeys> = [
  "indentLeft",
  "routePairCode",
  "routePair",
  "validFrom",
];

export const noShowColumnOrder: Array<NoShowLateHandlingTableColumnKeys> = [
  "noShowEur",
  "noShowGbp",
  "noShowSek",
  "noShowDkk",
  "noShowNok",
  "noShowPln",
];

export const lateHandlingColumnOrder: Array<NoShowLateHandlingTableColumnKeys> =
  [
    "lateHandlingEur",
    "lateHandlingGbp",
    "lateHandlingSek",
    "lateHandlingDkk",
    "lateHandlingNok",
    "lateHandlingPln",
    "dateCreated",
    "createdBy",
  ];

export const addAndDeleteValidFromColumnOrder: Array<NoShowLateHandlingTableColumnKeys> =
  ["addValidFrom", "deleteValidFrom", "indentRight"];
