import * as React from "react";
import { ReactNode } from "react";
import { Row, Text } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";

export const MiniTableCell: React.FC<{ leftContent?: ReactNode | false }> = ({
  children,
  leftContent,
}) => (
  <Row width={66} justifyContent={"flex-end"} alignItems={"center"}>
    {leftContent}
    <Text
      color={cssColor("--lhds-color-ui-500")}
      size={"small"}
      variant={"bold"}
    >
      {children}
    </Text>
  </Row>
);
