import { ApolloError } from "@apollo/client";
import { Box } from "@stenajs-webui/core";
import { StandardTable, TableContext } from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { agreementArticlesSelectors } from "../../redux/selectors";
import { AgreementArticlesTableItem } from "../../types";
import {
  FilterRowOptions,
  filteredAgreementArticles,
} from "../filter/helpers/FilteredOptions";
import { agreementArticlesTableConfig } from "./config/AgreementArticlesTableConfig";
import { AgreementArticlesTableColumns } from "./config/RowsAndColumnsConfig";

interface Props {
  error: ApolloError | undefined;
  tableContext: TableContext<AgreementArticlesTableColumns>;
  agreementArticles: EditableEntityState<AgreementArticlesTableItem>[];
  filterOptions: FilterRowOptions;
}

export const AgreementArticlesTable: React.FC<Props> = React.memo(
  ({ error, agreementArticles, tableContext, filterOptions }) => {
    const tableRowErrors = useSelector(
      agreementArticlesSelectors.getTableRowErrors
    );

    const config = useMemo(() => {
      return agreementArticlesTableConfig(tableRowErrors, agreementArticles);
    }, [tableRowErrors, agreementArticles]);

    const tableLoading = useSelector(
      agreementArticlesSelectors.getTableLoading
    );

    const tableItems = filteredAgreementArticles({
      agreementArticles,
      filterOptions,
    });

    return (
      <Box overflow={"auto"}>
        <StandardTable
          items={tableItems}
          config={config}
          error={error}
          loading={tableLoading}
          tableContext={tableContext}
          noItemsLabel={
            "No articles match your filter. Try using less options to get results."
          }
        />
      </Box>
    );
  }
);
