import { negotiationStatusCodeTransformer } from "@/common/formatters/NegotiationStatusCodeTransformer";
import { formatThousandsCheckNull } from "@/common/numbers";
import {
  currencyValue,
  percentLabelFormatterDecimalValue,
} from "@/features/statistics/common/utils/statisticsUtil";
import {
  StandardTableConfigWithNoGroups,
  createColumnConfig,
} from "@stenajs-webui/grid";

export interface StatisticsCustomerDataListItem {
  customerName?: string | null;
  negotiationStatus?: string | null;
  customerNumber: number;
  statNumber: number | null;
  parentNumber: number | null;
  sellerId: string | null;
  averageTarget?: number | null;
  averageNegotiationRate?: number | null;
  avgMeterPrice?: number | null;
  avgMeterPriceDiffInPercent?: number | null;
  lastYearVolume?: number;
  expectedVolume?: number | null;
}

export type StatisticsCustomerDataListColumns =
  | "customerName"
  | "negotiationStatus"
  | "customerNumber"
  | "statNumber"
  | "parentNumber"
  | "sellerId"
  | "averageTarget"
  | "averageNegotiationRate"
  | "avgMeterPrice"
  | "avgMeterPriceDiffInPercent"
  | "lastYearVolume"
  | "expectedVolume";

export const createByCustomerTableConfig = (
  currencyCode: string
): StandardTableConfigWithNoGroups<
  StatisticsCustomerDataListItem,
  StatisticsCustomerDataListColumns
> => ({
  keyResolver: (item) => item.customerNumber.toString(),
  disableSorting: false,
  initialSortOrder: "customerName",
  stickyHeader: true,
  rowIndent: 2,
  columns: {
    customerName: createColumnConfig((item) => item.customerName, {
      columnLabel: "Name",
    }),
    negotiationStatus: createColumnConfig(
      (item) => negotiationStatusCodeTransformer(item.negotiationStatus ?? ""),
      {
        columnLabel: "Negotiation status",
      }
    ),
    customerNumber: createColumnConfig((item) => item.customerNumber, {
      columnLabel: "Customer no.",
    }),
    statNumber: createColumnConfig((item) => item.statNumber, {
      columnLabel: "Stat no.",
    }),
    parentNumber: createColumnConfig((item) => item.parentNumber, {
      columnLabel: "Parent no.",
    }),
    sellerId: createColumnConfig((item) => item.sellerId, {
      columnLabel: "Seller",
    }),
    lastYearVolume: createColumnConfig((item) => item.lastYearVolume, {
      columnLabel: "12 mon. volume",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    expectedVolume: createColumnConfig((item) => item.expectedVolume, {
      columnLabel: "Expected volume",
      itemLabelFormatter: formatThousandsCheckNull,
    }),
    averageTarget: {
      itemValueResolver: ({ averageTarget }) => averageTarget,
      columnLabel: "Target rate",
      itemLabelFormatter: percentLabelFormatterDecimalValue,
    },
    averageNegotiationRate: createColumnConfig(
      (item) => item.averageNegotiationRate,
      {
        columnLabel: "Actual rate",
        itemLabelFormatter: percentLabelFormatterDecimalValue,
      }
    ),
    avgMeterPrice: {
      itemValueResolver: ({ avgMeterPrice }) =>
        avgMeterPrice === null || avgMeterPrice === 0
          ? -Infinity
          : avgMeterPrice,
      columnLabel: "Price/m",
      renderCell: ({ item }) => currencyValue(item.avgMeterPrice, currencyCode),
    },
    avgMeterPriceDiffInPercent: {
      itemValueResolver: ({ avgMeterPriceDiffInPercent }) =>
        avgMeterPriceDiffInPercent === null
          ? -Infinity
          : avgMeterPriceDiffInPercent,
      columnLabel: "Diff prev year",
      renderCell: ({ item }) =>
        percentLabelFormatterDecimalValue(item.avgMeterPriceDiffInPercent),
    },
  },
  columnOrder: [
    "customerName",
    "negotiationStatus",
    "customerNumber",
    "statNumber",
    "parentNumber",
    "sellerId",
    "lastYearVolume",
    "expectedVolume",
    "averageTarget",
    "averageNegotiationRate",
    "avgMeterPrice",
    "avgMeterPriceDiffInPercent",
  ],
});
