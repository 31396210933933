import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { transformCustomerItemsToTableItems } from "../components/transformers";
import {
  CustomerListForStatisticsQuery,
  CustomerListForStatisticsQueryVariables,
  StatisticsSearchCustomerListByFilterOptions,
} from "@/gql/graphql";

export const customerListForStatisticsQuery = gql`
  query CustomerListForStatistics(
    $filterOptions: StatisticsSearchCustomerListByFilterOptions!
  ) {
    statistics {
      customerList {
        byFilter(filterOptions: $filterOptions) {
          items {
            customerName
            customerNumber
            negotiationStatus
            parentNumber
            sellerId
            statNumber
            volumeAndTargetForSelected {
              averageNegotiationRate
              averageTarget
              avgMeterPrice
              avgMeterPriceDiffInPercent
              expectedVolume
              lastYearVolume
            }
          }
        }
      }
    }
  }
`;

export const useCustomerListQuery = (
  filterOptions: StatisticsSearchCustomerListByFilterOptions
) => {
  const { error, loading, data } = useQuery<
    CustomerListForStatisticsQuery,
    CustomerListForStatisticsQueryVariables
  >(customerListForStatisticsQuery, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    customerItems: transformCustomerItemsToTableItems(
      data?.statistics.customerList.byFilter.items
    ),
  };
};
