import { Card, CardBody, Tab, TabMenu } from "@stenajs-webui/elements";
import React, { useState } from "react";
import {
  BookingSearchResultData,
  SelectedTab,
} from "../../PriceCalculatorFormFetcher";
import { PriceCalcResultPaneAuditTrackErrorTab } from "../PriceCalcResultPaneAuditTrackErrorTab";
import { transformBookingResultToAuditData } from "../transformers/TransformToAuditData";
import { PriceCalculatorBookingAuditTab } from "./PriceCalculatorBookingAuditTab";
import { PriceCalculatorBookingDetailMultiLegTab } from "./PriceCalculatorBookingDetailMultiLegTab";
import { PriceCalculatorBookingDetailSectionalTab } from "./PriceCalculatorBookingDetailSectionalTab";

interface Props {
  bookingPriceCalcResult?: BookingSearchResultData;
}

export const PriceCalculatorBookingSearchResultPane: React.FC<Props> = ({
  bookingPriceCalcResult,
}) => {
  const handledError = bookingPriceCalcResult?.handledError;
  const isHandledErrorResponse = handledError !== undefined;
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.AUDIT
  );

  const displaySectionalDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    bookingPriceCalcResult &&
    bookingPriceCalcResult.consignmentData.length === 1 &&
    bookingPriceCalcResult.consignmentData[0].movementType.code === "S";

  const displayMultiLegDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    bookingPriceCalcResult &&
    bookingPriceCalcResult.consignmentData.length > 0 &&
    bookingPriceCalcResult.consignmentData[0].movementType.code === "M";

  return (
    <Card>
      <CardBody>
        <TabMenu>
          <Tab
            disabled={!!handledError}
            label={"Price details"}
            selected={selectedTab === SelectedTab.PRICE}
            onClick={() => setSelectedTab(SelectedTab.PRICE)}
          />
          <Tab
            label={"Audit track"}
            selected={selectedTab === SelectedTab.AUDIT}
            onClick={() => setSelectedTab(SelectedTab.AUDIT)}
          />
        </TabMenu>

        {bookingPriceCalcResult && isHandledErrorResponse && (
          <PriceCalcResultPaneAuditTrackErrorTab text={handledError!.error} />
        )}
      </CardBody>
      {bookingPriceCalcResult && !isHandledErrorResponse && (
        <>
          {displaySectionalDetailsTab && (
            <PriceCalculatorBookingDetailSectionalTab
              bookingSearchResultData={bookingPriceCalcResult}
            />
          )}
          {displayMultiLegDetailsTab && (
            <PriceCalculatorBookingDetailMultiLegTab
              bookingSearchResultData={bookingPriceCalcResult}
            />
          )}
          {selectedTab === SelectedTab.AUDIT && bookingPriceCalcResult && (
            <PriceCalculatorBookingAuditTab
              auditData={bookingPriceCalcResult.priceCalcData.map((priceCalc) =>
                transformBookingResultToAuditData(
                  priceCalc,
                  bookingPriceCalcResult?.consignmentData[0]
                )
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};
