import { FilterRowOptions } from "../components/filter/helpers/FilteredOptions";

export const allTypesLabel = "Vehicle type = ALL";
export const allTypesValue = "All";

export const filterRowOptionsInitialState: FilterRowOptions = {
  distanceTypeCode: "",
  articleCode: "",
  lengthFrom: "",
  lengthTo: "",
  routePairCode: "",
  vehicleTypeCode: "",
};
