import { UpdateAgreementArticlesItemInput } from "@/gql/graphql";
import { groupBy, values } from "lodash";

export const groupRouteAgreementsById = (
  routeAgreementArticles: UpdateAgreementArticlesItemInput[] | undefined
) => {
  const groupRouteAgreementById = groupBy(
    routeAgreementArticles,
    (ra) => ra.agrRouteId
  );
  const groupedRouteAgreementList = values(groupRouteAgreementById);

  return groupedRouteAgreementList.map<UpdateAgreementArticlesItemInput>(
    (ra) => {
      return {
        agrRouteId: ra[0].agrRouteId,
        rowVersion: ra[0].rowVersion,
        changes: {
          created: ra.flatMap((c) => c.changes.created.concat()),
          deleted: [],
          updated: [],
        },
      };
    }
  );
};
