import { BulkUpdateRatesFormModel } from "../models/BulkUpdateRatesFormModel";
import { parseFloatElseUndefined } from "@/common/numbers";

export const validateBulkUpdateFormModel = (
  formModel: BulkUpdateRatesFormModel
) => {
  const errors = [];
  const raiseBy = parseFloatElseUndefined(formModel.raiseBy);

  if (!formModel.raiseBy) {
    errors.push("Must specify size of price update.");
  } else {
    if (raiseBy === undefined) {
      errors.push("Invalid size of price update.");
    }
  }

  if (!formModel.raiseByType) {
    errors.push("Must specify type of price update.");
  }
  if (!formModel.raiseType) {
    errors.push("Must specify fixed or meter price.");
  }

  if (errors.length) {
    throw new Error(errors.join(" "));
  }
};
