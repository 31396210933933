import { Indent, Text } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import {
  getHighlightedPositiveNegativeBgColor,
  getHighlightedPositiveNegativeTextColor,
} from "../../../common/components/table/HighlightedPositiveNegativeCell";
import { Link } from "../../../common/navigation/Link";
import { formatPercentWithSign, formatThousands } from "@/common/numbers";
import { routeFactory } from "../../../RouteFactory";
import { RoutePriceVariant } from "../transformers/StatBoxDataTransformer";
import {
  noLabelColumnOrder,
  statVolColumnOrder,
  volumeColumnOrder,
} from "./StatBoxTableColumnOrder";

export type StatBoxTableColumn = keyof Omit<
  RoutePriceVariant,
  "id" | "customerId"
>;

export const statBoxTableConfig: StandardTableConfigWithGroups<
  RoutePriceVariant,
  StatBoxTableColumn
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: true,
  columns: {
    accompanied: createColumnConfig((item) => item.accompanied, {
      columnLabel: "",
      renderCell: ({ label, value }) => (
        <Indent>
          <Text>{value ? "Acc." : "Unacc."}</Text>
        </Indent>
      ),
      minWidth: "42px",
    }),
    target: createColumnConfig((item) => item.target, {
      itemLabelFormatter: (value, item) => {
        return item.target === null
          ? ""
          : value !== null
          ? formatPercentWithSign(value, 2)
          : "";
      },
      minWidth: "46px",
    }),
    actual: createColumnConfig((item) => item.actual, {
      columnLabel: "Actual",
      minWidth: "46px",
      itemLabelFormatter: (value) => formatPercentWithSign(value, 1, true, 1),
      renderCell: ({ label, value }) => (
        <Indent>
          <Text color={getHighlightedPositiveNegativeTextColor(value)}>
            {label}
          </Text>
        </Indent>
      ),
      backgroundResolver: (item) =>
        getHighlightedPositiveNegativeBgColor(item.actual ?? 0),
    }),
    volume: createColumnConfig((item) => item.volume, {
      columnLabel: "12m",
      borderLeft: true,
      itemLabelFormatter: formatThousands,
      minWidth: "32px",
    }),
    expectedVolume: createColumnConfig((item) => item, {
      columnLabel: "Exp.",
      minWidth: "32px",
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerExpectedVolume({
              customerId: item.customerId,
            })}
          >
            {formatThousands(item.expectedVolume)}
          </Link>
        </Indent>
      ),
    }),
    statVolume: createColumnConfig((item) => item.statVolume, {
      columnLabel: "12m",
      borderLeft: true,
      itemLabelFormatter: formatThousands,
      minWidth: "32px",
    }),
    expectedStatVolume: createColumnConfig((item) => item.expectedStatVolume, {
      columnLabel: "Exp.",
      minWidth: "32px",
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerExpectedVolume({
              customerId: item.customerId,
            })}
          >
            {formatThousands(item.expectedStatVolume)}
          </Link>
        </Indent>
      ),
    }),
  },
  columnGroups: {
    noLabelColumnGroup: {
      columnOrder: noLabelColumnOrder,
    },
    volume: {
      columnOrder: volumeColumnOrder,
      label: "Volume",
      borderLeft: true,
    },
    statVol: {
      columnOrder: statVolColumnOrder,
      label: "Stat vol.",
      borderLeft: true,
    },
  },
  columnGroupOrder: ["noLabelColumnGroup", "volume", "statVol"],
};
