import { EditRouteAgreementFormModel } from "../../common/components/models/EditRouteAgreementFormModel";
import { formatMissingFieldsMessage } from "../../../../../common/error/ErrorFormatter";

export const validateCreateRouteAgreementForm = (
  formModel: EditRouteAgreementFormModel
) => {
  const missing = [];
  const otherErrors = [];
  if (!formModel.valid.startDate) {
    missing.push("start date");
  }
  if (!formModel.valid.endDate) {
    missing.push("end date");
  }
  if (!formModel.currencyId) {
    missing.push("currency");
  }
  if (!formModel.routeIds.length) {
    missing.push("routes");
  }
  if (formModel.special) {
    if (formModel.offPeak || formModel.peak || formModel.shoulder) {
      otherErrors.push(
        "Special can only be selected if other sailing categories are not selected."
      );
    }
  } else {
    if (!formModel.offPeak && !formModel.peak && !formModel.shoulder) {
      otherErrors.push("At least one sailing category must be selected.");
    }
  }
  if (missing.length) {
    otherErrors.push(formatMissingFieldsMessage(missing));
  }
  if (otherErrors.length) {
    throw new Error(otherErrors.join(" "));
  }
};
