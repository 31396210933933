import * as React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { Spacing } from "@stenajs-webui/core";
import { canChangeHeadAgreementStatusToActive } from "../../details/features/existing-agreement/util/AgreementStatusUtil";
import { ActivateHeadAgreementContainer } from "./ActivateHeadAgreementContainer";
import {
  ActivateHeadAgreementFetcherQuery,
  ActivateHeadAgreementFetcherQueryVariables,
} from "@/gql/graphql";

interface Props {
  headAgreementId: string;
}

const query = gql`
  fragment ActivateHeadAgreementRouteAgreements on RouteAgreement {
    id
    name

    statusCode
    routeDescription
    agreementNumber
    hasMatrix
    sailingTypeCodes
    rowVersion
    routes {
      id
      code
      routePair {
        code
      }
    }
    externalDescription
    multiLegAgrRouteId
    currency {
      id
      code
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
  }

  fragment ActivateHeadAgrHeadAgr on HeadAgreement {
    id
    name
    breadCrumbName
    headAgreementNumber
    internalComments
    specifications
    condition
    lastPage
    statusCode
    rowVersion
    customer {
      id
      custIndex
      custNo
      name
      breadCrumbName
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    breadCrumbName

    routeAgreements {
      ...ActivateHeadAgreementRouteAgreements
    }
    multiLegAgrRoutes {
      id
      name
      currency {
        id
        code
        name
        shortDescription
        longDescription
      }
      statusCode
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
      dateCreated
      createdBy
      dateModified
      modifiedBy
      idNumber
      rowVersion
      workFlowStatusCode
      route {
        id
        code
        name
      }
    }
  }

  query ActivateHeadAgreementFetcher($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...ActivateHeadAgrHeadAgr
        }
      }
    }
  }
`;

export const ActivateHeadAgreementFetcher: React.FC<Props> = ({
  headAgreementId,
}) => {
  const { data, loading, error } = useQuery<
    ActivateHeadAgreementFetcherQuery,
    ActivateHeadAgreementFetcherQueryVariables
  >(query, { variables: { headAgreementId } });

  const headAgreement = data?.productPrice.headAgreement.byId;

  if (loading) {
    return (
      <Spacing num={12}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={12}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!headAgreement) {
    return (
      <Spacing num={12}>
        <ErrorScreen text={"Missing header agreement"} />
      </Spacing>
    );
  }

  if (!canChangeHeadAgreementStatusToActive(headAgreement.statusCode)) {
    return <ErrorScreen text={"Header agreement cannot be activated."} />;
  }

  return <ActivateHeadAgreementContainer headAgreement={headAgreement} />;
};
