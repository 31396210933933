import { keys } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { useCallback } from "react";
import { afterBulkUpdateRefresh } from "../thunks/AfterBulkUpdateRefresher";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";

const rateSheetStandardTableSelector = (state: StoreState) =>
  state.rateSheet.standardTable;

export const useAfterBulkUpdateBySelectionLogic = (
  updateStatBoxes: boolean = true
) => {
  const dispatch = useDispatch();
  const standardTableState = useSelector(rateSheetStandardTableSelector);

  const routeAgreementIds = keys(standardTableState);

  const afterBulkUpdateBySelection = useCallback(async () => {
    await Promise.all(
      routeAgreementIds.map(async (routeAgreementId) => {
        const routeAgreementPriceIdList =
          standardTableState[routeAgreementId]?.selectedIds.selectedIds ?? [];
        await dispatch(
          afterBulkUpdateRefresh(routeAgreementId, routeAgreementPriceIdList)
        );
      })
    );
    if (updateStatBoxes) {
      await dispatch(refetchStatBoxesWithRateSheetsChanges());
    }
  }, [routeAgreementIds, dispatch, standardTableState, updateStatBoxes]);

  return {
    afterBulkUpdateBySelection,
  };
};
