import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { useBoolean } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import { StandardTable, TableContext } from "@stenajs-webui/grid";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BulkEditRatesTableColumn,
  createBulkEditRatesTableConfig,
} from "../config/BulkEditRatesTableConfig";
import { BulkEditRatePrice } from "../types";
import { BulkEditRatesDetailView } from "./BulkEditRatesDetailView";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import {
  HeadAgreementStatusCode,
  RouteAgreementsRatesByFilterQuery,
} from "@/gql/graphql";

interface Props {
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  tableContext: TableContext<BulkEditRatesTableColumn>;
  loading: boolean;
  bulkUpdateModeEnabled: boolean;
  error: ApolloError | undefined;
  promptVisible: boolean;
}

export const BulkEditRatesTable: React.FC<Props> = React.memo(
  ({
    routeAgreementRates,
    tableContext,
    loading,
    bulkUpdateModeEnabled,
    error,
    promptVisible,
  }) => {
    const dispatch = useDispatch();
    const { width: windowSize } = useWindowSize();

    const { openFilter } = useFilterWorkspace();

    const [isOpen, open, close] = useBoolean(false);
    const [selectedAgreement, setSelectedAgreement] = useState<
      BulkEditRatePrice | undefined
    >(undefined);

    const filteredData =
      routeAgreementRates?.productPrice.routeAgreementPrice.byFilter.agrRoutePrices
        ?.filter(
          (r) => r.routeAgreement.statusCode !== HeadAgreementStatusCode.Void
        )
        .sort((a, b) => {
          if (!a.vehicleType?.code) {
            return -1;
          }

          if (!b.vehicleType?.code) {
            return 1;
          }

          if (a.vehicleType?.code === b.vehicleType?.code) {
            return 0;
          }
          return a.vehicleType?.code < b.vehicleType?.code ? -1 : 1;
        });

    const setAgreementId = useCallback(
      (routePriceId: string) => {
        setSelectedAgreement(
          routeAgreementRates?.productPrice.routeAgreementPrice.byFilter.agrRoutePrices?.find(
            (r) => r.id === routePriceId
          )
        );
      },
      [routeAgreementRates]
    );

    const config = useMemo(() => {
      return createBulkEditRatesTableConfig({
        open,
        isOpen,
        selectedAgreement,
        setRoutePriceId: setAgreementId,
        dispatch,
        bulkUpdateModeEnabled,
        windowSize,
        promptVisible,
      });
    }, [
      open,
      isOpen,
      selectedAgreement,
      setAgreementId,
      dispatch,
      bulkUpdateModeEnabled,
      windowSize,
      promptVisible,
    ]);

    return (
      <>
        <StandardTable
          config={config}
          items={filteredData ?? []}
          loading={loading}
          error={error}
          tableContext={tableContext}
          noItemsContentRight={
            <FlatButton label={"Open search"} onClick={() => openFilter()} />
          }
          noItemsHeader={
            filteredData?.length === 0
              ? "Your search did not return any results"
              : undefined
          }
          noItemsLabel={
            filteredData?.length === 0
              ? "Try using less filter options to get results"
              : "Please use the filters to search for customer statistics data."
          }
        />
        {selectedAgreement && (
          <BulkEditRatesDetailView
            routeAgreementRatesByFilter={selectedAgreement}
            close={close}
            isOpen={isOpen}
          />
        )}
      </>
    );
  }
);
