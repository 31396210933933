import { gql } from "apollo-boost";

export const statisticsListFragment = gql`
  fragment statisticsListFragment on StatisticsDetailsSummaryResult {
    byVolume {
      total
      items {
        num
        negotiationStatus {
          id
          name
        }
      }
    }
    byNumber {
      total
      items {
        num
        negotiationStatus {
          id
          name
        }
      }
    }
  }
`;
