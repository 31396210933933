import { HeadAgreementStatusCodeChip } from "@/common/components/chips/HeadAgreementStatusCodeChip";
import { MultiLegRouteAgreementsFragment } from "@/gql/graphql";
import { Indent } from "@stenajs-webui/core";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";

export type MultiLegAgreementsTableConfigColumnKeys =
  | "Status"
  | "MLRoute"
  | "Number"
  | "SalesReady"
  | "Name"
  | "Validity"
  | "Currency";

export const multiLegAggreementsTableConfig: StandardTableConfig<
  MultiLegRouteAgreementsFragment["multiLegAgrRoutes"]["0"],
  MultiLegAgreementsTableConfigColumnKeys
> = {
  keyResolver: (item) => item.id,
  showHeaderCheckbox: true,
  showRowCheckbox: true,
  columns: {
    Status: createColumnConfig((item) => item.statusCode, {
      renderCell: ({ value }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={value} />
        </Indent>
      ),
    }),
    MLRoute: createColumnConfig((item) => item.route.code),
    Number: createColumnConfig((item) => item.idNumber),
    SalesReady: createColumnConfig((item) => item.statusCode),
    Name: createColumnConfig((item) => item.name),
    Validity: createColumnConfig((item) => item.valid.end?.isoString),
    Currency: createColumnConfig((item) => item.currency.code),
  },
  columnOrder: ["Status", "MLRoute", "Number", "Name", "Validity", "Currency"],
};
