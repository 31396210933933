import { Box, Column, Txt } from "@stenajs-webui/core";
import * as React from "react";

import { PageHeading } from "@stenajs-webui/panels";

interface Props {}

export const MissingPage: React.FC<Props> = () => {
  return (
    <Column display={"flex"} alignItems={"center"}>
      <Box style={{ marginTop: "24px" }}>
        <PageHeading heading={"Page not found"} />
        <Box gap={1}>
          <Txt>If you entered a web adress, please check it is correct.</Txt>
          <Txt>
            You can use the menu or search box above to find the information you
            need.
          </Txt>
        </Box>
      </Box>
    </Column>
  );
};
