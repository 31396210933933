import { RateSheetRouteAgreementPrice } from "../types";

const cleanGQLPrice = ({ amount }: { amount: string }): { amount: string } => ({
  amount,
});

export const cleanGQLPriceElseNull = (
  price: { amount: string } | null | undefined
): { amount: string } | null =>
  price == null
    ? null
    : {
        amount: price.amount,
      };

export const cleanRatePrice = (
  p: RateSheetRouteAgreementPrice
): RateSheetRouteAgreementPrice => ({
  id: p.id,
  volume: p.volume,
  lengthFrom: p.lengthFrom,
  lengthTo: p.lengthTo,
  weightFrom: p.weightFrom,
  weightTo: p.weightTo,
  unaccompanied: p.unaccompanied,
  lastChargedFrom: p.lastChargedFrom,
  cargoStatusCode: p.cargoStatusCode,
  additionalFrom: p.additionalFrom,
  actual: p.actual,
  vehicleType: p.vehicleType
    ? {
        id: p.vehicleType.id,
        name: p.vehicleType.name,
        shortDescription: p.vehicleType.shortDescription,
        accompanied: p.vehicleType.accompanied,
        code: p.vehicleType.code,
      }
    : null,
  avgLength: p.avgLength,
  avgRebate: p.avgRebate ? cleanGQLPrice(p.avgRebate) : null,
  avgGrossPrice: p.avgGrossPrice ? cleanGQLPrice(p.avgGrossPrice) : null,
  avgNetPrice: p.avgNetPrice ? cleanGQLPrice(p.avgNetPrice) : null,
  lastPrice: p.lastPrice ? cleanGQLPrice(p.lastPrice) : null,
  lastMeterPrice: p.lastMeterPrice ? cleanGQLPrice(p.lastMeterPrice) : null,
  newPrice: cleanGQLPrice(p.newPrice),
  meterPrice: cleanGQLPrice(p.meterPrice),
});
