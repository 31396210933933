import { SailingTypeCode } from "@/gql/graphql";

export const sortSailingTypeCode = (codes: SailingTypeCode[]): number => {
  if (codes.length > 1) {
    return codes.length + 10;
  } else {
    switch (codes[0]) {
      case SailingTypeCode.P:
        return 10;
      case SailingTypeCode.O:
        return 9;
      case SailingTypeCode.S:
        return 8;
      case SailingTypeCode.M:
        return 7;
    }
  }
};
