export enum PriceCalcSearch {
  searchEvent = "Price Calc Search",
  customer = "Customer",
  booking = "Booking",
  selectedCustomer = "selectedCustomer",
}
export enum PriceCalcMoreOptions {
  searchEvent = "Price Calc More Options",
}
export enum PriceCalcPendingOffered {
  searchEvent = "Price Calc Pending Offered",
}
export enum PriceCalcMovementType {
  searchEvent = "Price Calc Select Movement Type",
  sectional = "Sectional",
  multiLeg = "Multi-leg",
}

export enum PriceCalculatorEventsAndActions {
  PriceCalculatorEvent = "Price calculator",
  ClickedCopyToClipboard = "ClickedCopyToClipboard",
}
