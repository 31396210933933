import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { parseFloatElseUndefined } from "@stenajs-webui/core";
import { Dispatch } from "react";
import {
  BulkUpdateRatesFormModel,
  RaiseByType,
  RaiseType,
} from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { bulkEditRatesActions, BulkEditRatesState } from "../redux/reducer";

const calculateAndDispatchRaiseActionByMeterPrice = (
  formModel: BulkUpdateRatesFormModel,
  previousValueMeterPrice: string,
  additionalFrom: number | null,
  routeAgreementPriceId: string,
  dispatch: Dispatch<any>
) => {
  try {
    const newValueMeterPrice = (
      formModel.raiseByType === RaiseByType.ABSOLUTE
        ? increaseByAbsoluteValue(previousValueMeterPrice, formModel.raiseBy)
        : increaseByPercent(previousValueMeterPrice, formModel.raiseBy)
    ).toFixed(formModel.numberDecimals);

    dispatch(
      bulkEditRatesActions.setValue({
        id: routeAgreementPriceId,
        meterPrice: newValueMeterPrice,
      })
    );

    const newValueFixedPrice = increaseFixedPriceBasedOnNewMeterPrice(
      newValueMeterPrice,
      additionalFrom
    ).toFixed(formModel.numberDecimals);

    dispatch(
      bulkEditRatesActions.setValue({
        id: routeAgreementPriceId,
        newPrice: newValueFixedPrice,
      })
    );
  } catch (e) {}
};

const calculateAndDispatchRaiseAction = (
  formModel: BulkUpdateRatesFormModel,
  previousValue: string,
  routeAgreementPriceId: string,
  meterPrice: boolean,
  newPrice: boolean,
  dispatch: Dispatch<any>
) => {
  try {
    const newValue = (
      formModel.raiseByType === RaiseByType.ABSOLUTE
        ? increaseByAbsoluteValue(previousValue, formModel.raiseBy)
        : increaseByPercent(previousValue, formModel.raiseBy)
    ).toFixed(formModel.numberDecimals);

    if (meterPrice) {
      dispatch(
        bulkEditRatesActions.setValue({
          id: routeAgreementPriceId,
          meterPrice: newValue,
        })
      );
    }

    if (newPrice) {
      dispatch(
        bulkEditRatesActions.setValue({
          id: routeAgreementPriceId,
          newPrice: newValue,
        })
      );
    }
  } catch (e) {}
};

export const raisePriceForRouteAgreementPrice = (
  dispatch: Dispatch<any>,
  routeAgreementPriceId: string,
  bulkEditRatesState: BulkEditRatesState,
  formModel: BulkUpdateRatesFormModel
) => {
  if (
    formModel.raiseType === RaiseType.METER_PRICE ||
    formModel.raiseType === RaiseType.BOTH
  ) {
    calculateAndDispatchRaiseAction(
      formModel,
      bulkEditRatesState.edited[routeAgreementPriceId]?.meterPrice ?? "",
      routeAgreementPriceId,
      true,
      false,
      dispatch
    );
  }

  if (
    formModel.raiseType === RaiseType.FIXED_PRICE ||
    formModel.raiseType === RaiseType.BOTH
  ) {
    calculateAndDispatchRaiseAction(
      formModel,
      bulkEditRatesState.edited[routeAgreementPriceId]?.newPrice ?? "",
      routeAgreementPriceId,
      false,
      true,
      dispatch
    );
  }

  if (formModel.raiseType === RaiseType.BY_METER_PRICE) {
    calculateAndDispatchRaiseActionByMeterPrice(
      formModel,
      bulkEditRatesState.edited[routeAgreementPriceId]?.meterPrice ?? "",
      Number(bulkEditRatesState.edited[routeAgreementPriceId]?.additionalFrom),
      routeAgreementPriceId,
      dispatch
    );
  }
};

const increaseByAbsoluteValue = (
  previousValue: string,
  valueToAddAsString: string
): number => {
  const valueToAdd = parseFloatElseUndefined(
    commaToDotTransform(valueToAddAsString)
  );
  const currentPrice = parseFloatElseUndefined(
    commaToDotTransform(previousValue)
  );
  if (valueToAdd === undefined) {
    throw new Error("Invalid value.");
  }
  return (currentPrice ?? 0) + valueToAdd;
};

const increaseFixedPriceBasedOnNewMeterPrice = (
  newMeterPrice: string,
  additionalFrom: number | null
): number => {
  const meterPrice = parseFloatElseUndefined(
    commaToDotTransform(newMeterPrice)
  );
  if (meterPrice === undefined) {
    throw new Error("Invalid value.");
  }
  return (meterPrice ?? 0) * (additionalFrom ?? 0);
};

const increaseByPercent = (value: string, percentAsString: string): number => {
  const percent = parseFloatElseUndefined(commaToDotTransform(percentAsString));
  const currentPrice = parseFloatElseUndefined(commaToDotTransform(value));
  if (percent === undefined) {
    throw new Error("Invalid percent.");
  }
  if (currentPrice === undefined) {
    throw new Error("Current price is not set, cannot increase by percent.");
  }
  return currentPrice * (1 + percent / 100);
};
