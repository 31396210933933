import * as React from "react";
import { LoadingModalWithLock } from "../../../../common/components/loading-modals/LoadingModalWithLock";

interface Props {
  numberOfEditedTargets: number;
}

export const LoadingTargetModal: React.FC<Props> = ({
  numberOfEditedTargets,
}) => {
  return (
    <LoadingModalWithLock
      label={`Saving ${numberOfEditedTargets} targets. Thanks for your patience.`}
    />
  );
};
