import { gql, useQuery } from "@apollo/client";
import { NoShowLateHandlingType } from "../../types/FilterEntitys";
import { NoShowLateHandlingTypeQuery } from "@/gql/graphql";

export const noShowLateHandlingTypeQuery = gql`
  query NoShowLateHandlingType {
    productPrice {
      noShowLateTypes {
        allCodeNoShowLateType {
          id
          code
          description
          presentationOrder
        }
      }
    }
  }
`;

export const useFetchNslhTypes = () => {
  const { data, loading, error, refetch } =
    useQuery<NoShowLateHandlingTypeQuery>(noShowLateHandlingTypeQuery, {
      fetchPolicy: "cache-first",
    });

  const nslhTypes =
    data?.productPrice.noShowLateTypes.allCodeNoShowLateType.map<NoShowLateHandlingType>(
      (r) => ({
        id: r.id,
        code: r.code,
        name: r.description,
      })
    ) ?? [];

  return { nslhTypes, error, loading, refetch };
};
