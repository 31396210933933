import { UpdateRoutePairCustomerTargetMutation } from "@/gql/graphql";

export const getRoutePairCustomerTargetSuccessResult = (
  value: UpdateRoutePairCustomerTargetMutation["productPrice"]["routePairCustomerTarget"]["updateRoutePairCustomerTargets"]
) => {
  if (value.__typename === "RoutePairCustomerTargetSuccessResult") {
    return value;
  }
  return null;
};

export const getRoutePairCustomerTargetErrorResult = (
  value: UpdateRoutePairCustomerTargetMutation["productPrice"]["routePairCustomerTarget"]["updateRoutePairCustomerTargets"]
) => {
  if (value.__typename === "ErrorResult") {
    return value;
  }
  return null;
};
