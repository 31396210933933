import { gql, useMutation } from "@apollo/client";

import { errorFragment } from "../../../../../../../../../common/error/GqlErrorFragment";
import {
  DeleteMultiLegRouteAgreementMutation,
  DeleteMultiLegRouteAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation DeleteMultiLegRouteAgreement(
    $deleteMultiLegRouteAgreementMultiLegRouteAgreementId: ID!
    $deleteMultiLegRouteAgreementRowVersion: String!
  ) {
    productPrice {
      multiLegRouteAgreement {
        deleteMultiLegRouteAgreement(
          multiLegRouteAgreementId: $deleteMultiLegRouteAgreementMultiLegRouteAgreementId
          rowVersion: $deleteMultiLegRouteAgreementRowVersion
        ) {
          ... on DeleteMultiLegRouteAgreementSuccessResult {
            success
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useDeleteMultiLegRouteAgreementMutation = () =>
  useMutation<
    DeleteMultiLegRouteAgreementMutation,
    DeleteMultiLegRouteAgreementMutationVariables
  >(query);
