import { noShowLateHandlingPercentageNumber } from "@/common/components/invoice-specification/utils/utils";
import { OverviewBooking } from "@/common/components/overview/models/OverviewBooking";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";
import { routeFactory } from "../../../../RouteFactory";
import { InternalOverviewQuery } from "@/gql/graphql";

export const transformBookingsQueryToOverviewBooking = (
  data: InternalOverviewQuery["productPrice"]["noShowLateHandling"]["nslhFollowUp"]["byFilter"]["0"],
  year: number,
  month: number
): OverviewBooking => {
  const pathToOneMonthBookings =
    routeFactory.noShowLateHandling.oneMonthBookings(
      String(data.customer.custNo),
      String(year),
      String(month)
    );

  const customerThresholdLateHandling = data.customer.customerThresholds.filter(
    (threshold) =>
      threshold.thresholdTypeCode === NoShowLateHandlingTypes.LATE_HANDL
  );
  const customerThresholdNoShow = data.customer.customerThresholds.filter(
    (threshold) =>
      threshold.thresholdTypeCode === NoShowLateHandlingTypes.NOSHOW
  );

  const customerThresholdLateHandlingPercentage =
    customerThresholdLateHandling.length !== 1
      ? null
      : customerThresholdLateHandling[0].percentage;
  const customerThresholdNoShowPercentage =
    customerThresholdNoShow.length !== 1
      ? null
      : customerThresholdNoShow[0].percentage;

  return {
    id: data.id,
    customer: {
      custNo: data.customer.custNo,
      custIndex: data.customer.custIndex,
    },
    customerThresholdLateHandlingPercentage:
      customerThresholdLateHandlingPercentage,
    customerThresholdNoShowPercentage: customerThresholdNoShowPercentage,
    routePairDescription: data.routePair.description,
    routePairCode: data.routePair.code,
    shippedVolume: data.shippedVolume,
    excludedLateHandling: data.noOfExcludedLateHandlings,
    excludedNoShow: data.noOfExcludedNoShows,
    quantityNoShow: data.noOfNoShows,
    quantityLateHandling: data.noOfLateHandlings,
    includedLateHandling:
      data.noOfLateHandlings - data.noOfExcludedLateHandlings,
    includedNoShow: data.noOfNoShows - data.noOfExcludedNoShows,
    percentageLateHandling: noShowLateHandlingPercentageNumber(
      data.noOfLateHandlings - data.noOfExcludedLateHandlings,
      data.shippedVolume
    ),
    percentageNoShow: noShowLateHandlingPercentageNumber(
      data.noOfNoShows - data.noOfExcludedNoShows,
      data.shippedVolume
    ),
    pathToOneMonthBookings: pathToOneMonthBookings,
  };
};
