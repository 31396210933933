import { formatDate } from "@/common/formatters/DateFormatter";
import { Indent, Text } from "@stenajs-webui/core";

export const createRoutePairCustomerTargetStandardTableCell = (
  rowValue: string | number | undefined | null,
  isDateField: boolean = false,
  formatter?: (value: number) => string,
  renderNullValue?: string
) => {
  if (rowValue === undefined || rowValue === null) {
    return (
      <Indent>
        <Text>{renderNullValue ? renderNullValue : ""}</Text>
      </Indent>
    );
  }

  if (isDateField) {
    return (
      <Indent>
        <Text>{formatDate(rowValue as string)}</Text>
      </Indent>
    );
  }

  return (
    <Indent>
      <Text>
        {formatter && typeof rowValue === "number"
          ? formatter(rowValue)
          : rowValue}
      </Text>
    </Indent>
  );
};
