import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  SellerListVolumeAndTargetQuery,
  SellerListVolumeAndTargetQueryVariables,
  StatisticsSearchSellerListVolumeAndTargetByFilterOptions,
} from "@/gql/graphql";

const query = gql`
  query SellerListVolumeAndTarget(
    $filterOptions: StatisticsSearchSellerListVolumeAndTargetByFilterOptions!
  ) {
    statistics {
      sellerList {
        volumeAndTarget(filterOptions: $filterOptions) {
          items {
            seller {
              id
            }
            volumeAndTargetForSelected {
              averageNegotiationRate
              averageTarget
              expectedVolume
              lastYearVolume
              volume
              avgMeterPrice
              avgMeterPriceDiffInPercent
            }
          }
        }
      }
    }
  }
`;

export const useRouteListVolumeAndTargetQuery = (
  filterOptions: StatisticsSearchSellerListVolumeAndTargetByFilterOptions
) => {
  const { error, loading, data, refetch } = useQuery<
    SellerListVolumeAndTargetQuery,
    SellerListVolumeAndTargetQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    skip: filterOptions.sellerIds.length === 0 || filterOptions.year == null,
  });

  return {
    refetch,
    error,
    loading,
    volumeAndTargets: data?.statistics.sellerList.volumeAndTarget.items,
  };
};
