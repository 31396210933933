import * as React from "react";
import { Row, Text } from "@stenajs-webui/core";
import { formatPercentWithSign } from "@/common/numbers";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  valueInDecimal: number | undefined | null;
  includePercentSign?: boolean;
}

export const HighlightedPositiveNegativeCell: React.FC<Props> = React.memo(
  ({ valueInDecimal, includePercentSign }) => {
    return (
      <Row alignItems={"center"}>
        <Text
          color={getHighlightedPositiveNegativeTextColor(
            valueInDecimal ?? null
          )}
        >
          {valueInDecimal == null
            ? "-"
            : formatPercentWithSign(valueInDecimal, 2, includePercentSign)}
        </Text>
      </Row>
    );
  }
);

export const getHighlightedPositiveNegativeTextColor = (
  actual: number | null
): string | undefined =>
  actual == null || actual > 0
    ? cssColor("--lhds-color-green-700")
    : actual < 0
    ? cssColor("--lhds-color-red-700")
    : undefined;

export const getHighlightedPositiveNegativeBgColor = (
  actual: number | null
): string | undefined =>
  actual == null || actual > 0
    ? cssColor("--lhds-color-green-50")
    : actual < 0
    ? cssColor("--lhds-color-red-50")
    : undefined;
