import {
  CreateInvoicesInput,
  CreateInvoicesMutation,
  CreateInvoicesMutationVariables,
} from "@/gql/graphql";
import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const mutation = gql`
  mutation CreateInvoices($input: CreateInvoicesInput!) {
    productPrice {
      noShowLateHandlingInvoices {
        createInvoices(input: $input) {
          ... on CreateInvoicesSuccessResult {
            success
          }
          ... on CreateInvoicesErrorResult {
            errors {
              message
              path
            }
          }
        }
      }
    }
  }
`;

export const useCreateInvoiceMutation = () => {
  const [mutate, { loading, error, data }] = useMutation<
    CreateInvoicesMutation,
    CreateInvoicesMutationVariables
  >(mutation);

  const createInvoices = useCallback(
    async (input: CreateInvoicesInput) => {
      return await mutate({
        variables: {
          input,
        },
        refetchQueries: ["NoShowLateHandlingBookingList"],
        awaitRefetchQueries: true,
      });
    },
    [mutate]
  );

  return { loading, error, createInvoices, data };
};
