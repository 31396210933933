import { Row, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardHeader,
  FlatButton,
  stenaBusinessAgreement,
  stenaSwitch,
} from "@stenajs-webui/elements";
import * as React from "react";
import { SwitchHeadAgreementModal } from "../../head-agreement/list/components/SwitchHeadAgreementModal";
import { HeadAgreementBox } from "../types";
import { HeadAgreementInfoPairPopover } from "./HeadAgreementInfoPairPopover";
import { HeadAgreementInfoCardBody } from "./HeadAgreementInfoCardBody";

interface Props {
  headAgreement: HeadAgreementBox;
  customerId: string;
  switchButtonDisabled?: boolean;
}

export const HeadAgreementInfoPairBox: React.FC<Props> = ({
  headAgreement,
  customerId,
  switchButtonDisabled,
}) => {
  const [switchModalIsOpen, setSwitchOpen, setSwitchClosed] = useBoolean(false);
  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Header agreement"}
        leftIcon={stenaBusinessAgreement}
        contentRight={
          <Row>
            <FlatButton
              leftIcon={stenaSwitch}
              onClick={setSwitchOpen}
              style={{ display: "inline-block" }}
              disabled={switchButtonDisabled}
              title={"Switch agreement"}
            />
            <HeadAgreementInfoPairPopover
              headAgreement={headAgreement}
              actionMenuOpen={actionMenuOpen}
              openActionMenu={openActionMenu}
              closeActionMenu={closeActionMenu}
            />
          </Row>
        }
      />
      <HeadAgreementInfoCardBody headAgreement={headAgreement} />
      {switchModalIsOpen && (
        <SwitchHeadAgreementModal
          customerId={customerId}
          onRequestClose={setSwitchClosed}
          selectedHeadAgreementId={headAgreement.id}
        />
      )}
    </Card>
  );
};
