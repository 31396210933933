import { CreateMultiLegAgreementRouteFormModel } from "../../common/components/create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";

export const validateCreateMultiLegAgreementRouteAgreementForm = (
  formModel: CreateMultiLegAgreementRouteFormModel
) => {
  let errors: Array<string> = [];

  // Form Model validation
  !formModel.valid.startDate && (errors = [...errors, "start date"]);
  !formModel.valid.endDate && (errors = [...errors, "end date"]);
  !formModel.currencyCode && (errors = [...errors, "currency"]);
  !formModel.headAgreementId && (errors = [...errors, "headAgreementId"]);
  !formModel.routeCode && (errors = [...errors, "routeCode"]);
  !formModel.multiLegAgreementId &&
    (errors = [...errors, "multiLegAgreementId"]);
  !formModel.selectedRouteCode && (errors = [...errors, "noSelectedRouteCode"]);

  if (errors.length) throw new Error(errors.join(" "));
};
