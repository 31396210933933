import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { CreateHeadAgreement } from "./CreateHeadAgreement";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface CreateHeadAgreementScreenParams {
  customerId: string;
}

interface Props {}

export const CreateHeadAgreementScreen: React.FC<Props> = () => {
  const { customerId } = useParams() as { customerId: string };
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }

  return (
    <>
      <Helmet title="Create HA - Customers - FPP" />
      <CreateHeadAgreement customerId={customerId} />
    </>
  );
};
