import {
  GetCopyToOtherCustomerBreadCrumbNameQuery,
  GetCopyToOtherCustomerBreadCrumbNameQueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";

const getCopyToOtherCustomerBreadCrumbNameQuery = gql`
  query GetCopyToOtherCustomerBreadCrumbName($id: ID!) {
    productPrice {
      customers {
        byId(id: $id) {
          breadCrumbName
        }
      }
    }
  }
`;

export const useGetCopyToOtherCustomerBreadCrumbNameQuery = (
  customerId: string
) => {
  const { data, loading } = useQuery<
    GetCopyToOtherCustomerBreadCrumbNameQuery,
    GetCopyToOtherCustomerBreadCrumbNameQueryVariables
  >(getCopyToOtherCustomerBreadCrumbNameQuery, {
    variables: { id: customerId },
  });

  const breadCrumbName =
    data?.productPrice.customers.byId?.breadCrumbName ?? "";

  return { breadCrumbName, loading };
};
