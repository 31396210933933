import { Box } from "@stenajs-webui/core";
import {
  ActionMenuItem,
  stenaCheck,
  stenaCheckCircle,
} from "@stenajs-webui/elements";
import { ActionMenuSecondaryButton } from "@stenajs-webui/panels";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ALL } from "../../../../../common/components/vehicle-type-icon/svg/ALL.svg";
import {
  rateSheetStandardTableSelectors,
  rateSheetStandardTableWrapperActions,
} from "../../redux";
import { bulkUpdateQuickSelectRowsByFilter } from "../thunks/BulkUpdateQuickSelectionThunk";

export const QuickSelectionMenu: React.FC = () => {
  const dispatch = useDispatch();

  const allSelected = useSelector(
    rateSheetStandardTableSelectors.allPriceRowsAreSelected
  );

  const noneSelected = useSelector(
    rateSheetStandardTableSelectors.noPriceRowsAreSelected
  );

  const accompaniedSelected = useSelector(
    rateSheetStandardTableSelectors.accompaniedPriceRowsAreSelected
  );

  const unAccompaniedSelected = useSelector(
    rateSheetStandardTableSelectors.unAccompaniedPriceRowsAreSelected
  );

  const vehicleTypeAllSelected = useSelector(
    rateSheetStandardTableSelectors.vehicleTypeAllPriceRowsAreSelected
  );

  const onClickNone = () =>
    dispatch(rateSheetStandardTableWrapperActions.clearAllCheckboxes());

  const onClickAll = () =>
    dispatch(bulkUpdateQuickSelectRowsByFilter(() => true));

  const onClickAccompanied = () =>
    dispatch(
      bulkUpdateQuickSelectRowsByFilter(
        (r) => r.editable.vehicleType?.accompanied === true
      )
    );

  const onClickUnaccompanied = () =>
    dispatch(
      bulkUpdateQuickSelectRowsByFilter(
        (r) => r.editable.vehicleType?.accompanied === false
      )
    );

  const onClickVehicleTypeIsAll = () =>
    dispatch(
      bulkUpdateQuickSelectRowsByFilter((r) => r.editable.vehicleType == null)
    );

  return (
    <ActionMenuSecondaryButton
      label="Quick selection"
      leftIcon={stenaCheckCircle}
      placement="bottom-start"
      disableArrow
      renderItems={() => (
        <Box minWidth={"200px"}>
          <ActionMenuItem
            label={"None"}
            onClick={() => onClickNone()}
            variant="standard"
            rightIcon={noneSelected ? stenaCheck : undefined}
          />
          <ActionMenuItem
            label={"All"}
            onClick={() => onClickAll()}
            rightIcon={allSelected ? stenaCheck : undefined}
          />
          <ActionMenuItem
            label={"Accompanied"}
            onClick={() => onClickAccompanied()}
            rightIcon={accompaniedSelected ? stenaCheck : undefined}
          />
          <ActionMenuItem
            label={"Unaccompanied"}
            onClick={() => onClickUnaccompanied()}
            rightIcon={unAccompaniedSelected ? stenaCheck : undefined}
          />
          <ActionMenuItem
            label={"Vehicle type = all"}
            left={<ALL height={"24px"} width={"24px"} />}
            onClick={() => onClickVehicleTypeIsAll()}
            rightIcon={vehicleTypeAllSelected ? stenaCheck : undefined}
          />
        </Box>
      )}
    />
  );
};
