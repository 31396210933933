import {
  SailingTypeCode,
  UnitCategoryCode,
  UpdateCustomerRouteStatisticsActualOutcomeInput,
} from "@/gql/graphql";
import { StatBoxesState } from "../../../customer-route-statistic-boxes/redux";

import { isDefined } from "@/common/types/IsDefined";

export const transformToUpdateCustomerRouteStatisticsActualOutcomeInput = (
  data: StatBoxesState
): UpdateCustomerRouteStatisticsActualOutcomeInput[] => {
  const offPeakInput = data.statBoxesData?.offPeakData
    .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
      (offPeak) => {
        if (!data.queryParams) return undefined;
        return {
          custNo: Number(data.queryParams?.customerId),
          year: data.queryParams?.year,
          routePairCode: data.queryParams?.routePairId,
          unitCategoryCode: offPeak.accompanied
            ? UnitCategoryCode.Acc
            : UnitCategoryCode.Unacc,
          sailingCategoryCode: SailingTypeCode.O,
          actualOutcomeInPercent: offPeak.actual * 100,
        };
      }
    )
    .filter(isDefined);
  const shoulderInput = data.statBoxesData?.shoulderData
    .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
      (shoulder) => {
        if (!data.queryParams) return undefined;
        return {
          custNo: Number(data.queryParams?.customerId),
          year: data.queryParams?.year,
          routePairCode: data.queryParams?.routePairId,
          unitCategoryCode: shoulder.accompanied
            ? UnitCategoryCode.Acc
            : UnitCategoryCode.Unacc,
          sailingCategoryCode: SailingTypeCode.S,
          actualOutcomeInPercent: shoulder.actual * 100,
        };
      }
    )
    .filter(isDefined);
  const peakInput = data.statBoxesData?.peakData
    .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
      (peak) => {
        if (!data.queryParams) return undefined;
        return {
          custNo: Number(data.queryParams?.customerId),
          year: data.queryParams?.year,
          routePairCode: data.queryParams?.routePairId,
          unitCategoryCode: peak.accompanied
            ? UnitCategoryCode.Acc
            : UnitCategoryCode.Unacc,
          sailingCategoryCode: SailingTypeCode.P,
          actualOutcomeInPercent: peak.actual * 100,
        };
      }
    )
    .filter(isDefined);
  return [
    ...(offPeakInput ?? []),
    ...(shoulderInput ?? []),
    ...(peakInput ?? []),
  ];
};
