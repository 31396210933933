import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Column, useBoolean } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import { Drawer } from "@stenajs-webui/modal";
import * as React from "react";
import { DrawerHeader } from "../../../../../common/components/drawer/DrawerHeader";
import { BulkUpdateRates } from "./BulkUpdateRates";
import { cssColor } from "@stenajs-webui/theme";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

interface Props {
  routeAgreementId: string;
  currencyLabel?: string;
  editableRouteAgreement: boolean;
}

export const BulkUpdateRatesButton: React.FC<Props> = ({
  routeAgreementId,
  currencyLabel,
  editableRouteAgreement,
}) => {
  const [isOpen, open, close] = useBoolean(false);

  return (
    <>
      <FlatButton
        label={"Bulk update"}
        onClick={open}
        disabled={!editableRouteAgreement}
      />
      <Drawer
        isOpen={isOpen}
        width={"260px"}
        onRequestClose={close}
        slideFrom={"right"}
        background={cssColor("--lhds-color-ui-50")}
        zIndex={ZIndex.highest}
      >
        {isOpen && (
          <Column overflowY={"scroll"} height={"100%"}>
            <DrawerHeader
              label={"Bulk update rates"}
              onClickClose={close}
              iconLeft={faCreditCard}
            />
            <Column indent spacing>
              <BulkUpdateRates
                routeAgreementId={routeAgreementId}
                onRequestClose={close}
                currencyLabel={currencyLabel}
              />
            </Column>
          </Column>
        )}
      </Drawer>
    </>
  );
};
