import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsListFragment } from "../../../common/fragments/ListFragment";
import {
  SellerDetailsSummaryQuery,
  SellerDetailsSummaryQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query SellerDetailsSummary($sellerId: ID!, $year: Int!) {
    statistics {
      sellerDetails {
        summary(sellerId: $sellerId, year: $year) {
          ...statisticsListFragment
        }
      }
    }
  }
  ${statisticsListFragment}
`;

export const useSellerDetailsSummaryQuery = (
  sellerId: string,
  year: number
) => {
  const { data, loading, error } = useQuery<
    SellerDetailsSummaryQuery,
    SellerDetailsSummaryQueryVariables
  >(query, { variables: { sellerId, year }, fetchPolicy: "network-only" });

  return {
    error,
    loading,
    summary: data?.statistics.sellerDetails.summary,
  };
};
