import React, { Fragment, useMemo } from "react";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import { keys } from "lodash";
import { groupRouteAgreementsBySeaArea } from "../util/RouteAgreementGrouper";
import { Box, Indent, Row, Space, Text } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTable,
  StandardTableConfig,
} from "@stenajs-webui/grid";
import { RoutePairTable } from "./RoutePairTable";
import { routeFactory } from "../../../../../../../RouteFactory";
import { formatPercentWithSign, formatThousands } from "@/common/numbers";
import { Link } from "../../../../../../../common/navigation/Link";
import { transformAgreementsToTableColumns } from "./transformers/transformers";
import { cssColor } from "@stenajs-webui/theme";
import { SpacingValues } from "@/common/utils/SpacingValues";

export interface ExistingAgreementTableColumns {
  routePairCode: string;
  customerId: string;
  headAgreementId: string;
  year: number;
  numRouteAgreements: number;
  numSalesReady: number;
  pending: number;
  offered: number;
  active: number;
  averageTargetRate: number | null;
  averageNegotiationRate: number;
  volumeLast12Months: number;
  expectedVolume: number;
}

const config: StandardTableConfig<
  ExistingAgreementTableColumns,
  | keyof Omit<
      ExistingAgreementTableColumns,
      | "headAgreementId"
      | "routePairCode"
      | "customerId"
      | "year"
      | "numRouteAgreements"
      | "pending"
      | "offered"
      | "active"
    >
  | "route"
  | "statuses"
> = {
  keyResolver: (item) => String(item.routePairCode),
  enableExpandCollapse: true,
  showHeaderExpandCollapse: true,
  disableSorting: true,
  initialSortOrder: "volumeLast12Months",
  initialSortOrderDesc: true,
  rowBackgroundResolver: (item) =>
    item.numSalesReady === item.numRouteAgreements
      ? cssColor("--lhds-color-green-50")
      : undefined,
  columns: {
    route: createColumnConfig((item) => item, {
      renderCell: ({ label, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerRoutePair({
              customerId: item.customerId,
              headAgreementId: item.headAgreementId,
              routePairId: item.routePairCode,
            })}
          >
            {item.routePairCode}
          </Link>
        </Indent>
      ),
      columnLabel: "Route",
    }),
    numSalesReady: createColumnConfig((item) => item.numSalesReady, {
      columnLabel: "Sales ready",
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Text>
            {value}/{item.numRouteAgreements}
          </Text>
        </Indent>
      ),
    }),
    statuses: createColumnConfig(
      (item) => `${item.pending} / ${item.offered} / ${item.active}`,
      {
        columnLabel: "Pending / Offered / Active",
      }
    ),
    volumeLast12Months: createColumnConfig((item) => item.volumeLast12Months, {
      columnLabel: "Vol. last 12 m",
      itemLabelFormatter: (value) => formatThousands(value),
    }),
    expectedVolume: createColumnConfig((item) => item.expectedVolume, {
      columnLabel: "Exp. vol",
      itemLabelFormatter: (value) => formatThousands(value),
    }),
    averageTargetRate: createColumnConfig(
      (item) => item.averageTargetRate ?? 0,
      {
        columnLabel: "Avg. target rate %",
        itemLabelFormatter: (value, item) => {
          return item.averageTargetRate === null
            ? ""
            : value !== null
            ? formatPercentWithSign(value, 2)
            : "";
        },
      }
    ),
    averageNegotiationRate: createColumnConfig(
      (item) => item.averageNegotiationRate,
      {
        columnLabel: "Avg. neg rate %",
        itemLabelFormatter: (value) => formatPercentWithSign(value),
      }
    ),
  },
  renderRowExpansion: (item) => (
    <Box background={cssColor("--lhds-color-ui-50")}>
      <RoutePairTable
        headAgreementId={item.headAgreementId}
        routePairCode={item.routePairCode}
        year={item.year}
        customerId={item.customerId}
      />
    </Box>
  ),
  columnOrder: [
    "route",
    "numSalesReady",
    "statuses",
    "volumeLast12Months",
    "expectedVolume",
    "averageTargetRate",
    "averageNegotiationRate",
  ],
};

interface Props {
  customerId: string;
  routeAgreements: Array<RouteAgreement>;
  headAgreementId: string;
  year: number;
}

export const ExistingAgreementList: React.FC<Props> = ({
  routeAgreements,
  customerId,
  headAgreementId,
  year,
}) => {
  const agreementsGroupedBySeaArea = useMemo(() => {
    const routeAgreementsPerSeaArea =
      groupRouteAgreementsBySeaArea(routeAgreements);
    const seaAreaKeys = keys(routeAgreementsPerSeaArea);
    seaAreaKeys.sort();
    return seaAreaKeys.map((k) => routeAgreementsPerSeaArea[k]);
  }, [routeAgreements]);

  if (agreementsGroupedBySeaArea.length === 0) {
    return (
      <Row spacing={SpacingValues.THIRTYTWO} justifyContent={"center"}>
        <Text>There are no route agreements.</Text>
      </Row>
    );
  }

  return (
    <>
      {agreementsGroupedBySeaArea.map((agreementsForSeaArea, index) => {
        const { seaArea } = agreementsForSeaArea[0].routes[0].routePair;
        return (
          <Fragment key={seaArea.id}>
            {index !== 0 && <Space num={2} />}
            <Box spacing>
              <Text
                variant={"bold"}
                size={"small"}
                color={cssColor("--lhds-color-ui-600")}
              >
                {seaArea.name.toUpperCase()}
              </Text>

              <Box overflowX={"auto"}>
                <Box display={"table"}>
                  <StandardTable
                    config={config}
                    items={transformAgreementsToTableColumns(
                      agreementsForSeaArea,
                      customerId,
                      headAgreementId,
                      year
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Fragment>
        );
      })}
    </>
  );
};
