import { BulkEditRatesStatBoxesState } from "../redux/reducer";
import { flatten } from "lodash";
import { isDefined } from "@/common/types/IsDefined";
import {
  SailingTypeCode,
  UnitCategoryCode,
  UpdateCustomerRouteStatisticsActualOutcomeInput,
} from "@/gql/graphql";

export const transformToUpdateCustomerRouteStatisticsActualOutcomeInput = (
  data: BulkEditRatesStatBoxesState
): UpdateCustomerRouteStatisticsActualOutcomeInput[] => {
  const inputData = data.statBoxesData
    ?.map<UpdateCustomerRouteStatisticsActualOutcomeInput[] | undefined>(
      (d) => {
        const offPeakInput = d.offPeakData
          .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
            (offPeak) => {
              if (!data.queryParams) return undefined;
              return {
                custNo: Number(data.queryParams?.customerId),
                year: data.queryParams?.year,
                routePairCode: d.routePairCode,
                unitCategoryCode: offPeak.accompanied
                  ? UnitCategoryCode.Acc
                  : UnitCategoryCode.Unacc,
                sailingCategoryCode: SailingTypeCode.O,
                actualOutcomeInPercent: offPeak.actual * 100,
              };
            }
          )
          .filter(isDefined);
        const shoulderInput = d.shoulderData
          .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
            (shoulder) => {
              if (!data.queryParams) return undefined;
              return {
                custNo: Number(data.queryParams?.customerId),
                year: data.queryParams?.year,
                routePairCode: d.routePairCode,
                unitCategoryCode: shoulder.accompanied
                  ? UnitCategoryCode.Acc
                  : UnitCategoryCode.Unacc,
                sailingCategoryCode: SailingTypeCode.S,
                actualOutcomeInPercent: shoulder.actual * 100,
              };
            }
          )
          .filter(isDefined);
        const peakInput = d.peakData
          .map<UpdateCustomerRouteStatisticsActualOutcomeInput | undefined>(
            (peak) => {
              if (!data.queryParams) return undefined;
              return {
                custNo: Number(data.queryParams?.customerId),
                year: data.queryParams?.year,
                routePairCode: d.routePairCode,
                unitCategoryCode: peak.accompanied
                  ? UnitCategoryCode.Acc
                  : UnitCategoryCode.Unacc,
                sailingCategoryCode: SailingTypeCode.P,
                actualOutcomeInPercent: peak.actual * 100,
              };
            }
          )
          .filter(isDefined);

        return [
          ...(offPeakInput ?? []),
          ...(shoulderInput ?? []),
          ...(peakInput ?? []),
        ];
      }
    )
    .filter(isDefined);

  return flatten(inputData);
};
