import { SpaceValues } from "@/common/utils/SpaceValues";
import { DateRange, DateRangeDualTextInput } from "@stenajs-webui/calendar";
import {
  Box,
  Row,
  SeparatorLine,
  Space,
  useBoolean,
} from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { ActionPrompt, Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MatrixFormItem } from "../MatrixFormModel";
import { matrixFormRedux } from "../MatrixFormRedux";
import { MatrixAndKeywords } from "./MatrixAndKeywords";
import { MatrixItemValidationChips } from "./MatrixItemValidationChips";
import { MatrixListItemMatricesFragment } from "@/gql/graphql";
import { format } from "date-fns";

interface Props {
  matrixItem: MatrixFormItem;
  matrix: MatrixListItemMatricesFragment;
}

export const MatrixItemForm: React.FC<Props> = ({ matrixItem, matrix }) => {
  const [visible, show, hide] = useBoolean(false);

  const dispatch = useDispatch();

  const { matrices } = useSelector(matrixFormRedux.selectors.getEntity);

  const removeMatrixItem = useCallback(() => {
    dispatch(
      matrixFormRedux.actions.setEntity({
        matrices: matrices.map<MatrixFormItem>((m) =>
          m.id === matrixItem.id ? { ...m, deleted: true } : m
        ),
      })
    );
  }, [matrixItem, matrices, dispatch]);

  const changeValidity = useCallback(
    (interval: DateRange) => {
      dispatch(
        matrixFormRedux.actions.setEntity({
          matrices: matrices.map((m) =>
            m.id === matrixItem.id
              ? {
                  ...m,
                  startDate:
                    interval.startDate &&
                    format(interval.startDate, "yyyy-MM-dd"),
                  endDate:
                    interval.endDate && format(interval.endDate, "yyyy-MM-dd"),
                }
              : m
          ),
        })
      );
    },
    [dispatch, matrices, matrixItem.id]
  );

  return (
    <Card>
      <CardHeader
        text={"Validity"}
        contentAfterHeading={
          <Row alignItems={"center"}>
            <Box width={"fit-content"}>
              <DateRangeDualTextInput
                value={{
                  startDate: matrixItem.startDate
                    ? new Date(matrixItem.startDate)
                    : undefined,
                  endDate: matrixItem.endDate
                    ? new Date(matrixItem.endDate)
                    : undefined,
                }}
                onValueChange={(v) => changeValidity(v)}
              />
            </Box>
            <Space num={SpaceValues.THIRTYTWO} />
            <MatrixItemValidationChips matrixItem={matrixItem} />
          </Row>
        }
        contentRight={
          <Popover
            visible={visible}
            onClickOutside={hide}
            placement={"left"}
            content={
              <ActionPrompt
                onNo={hide}
                onYes={() => {
                  removeMatrixItem();
                  hide();
                }}
              />
            }
          >
            <FlatButton label={"Remove"} onClick={show} />
          </Popover>
        }
      />
      <SeparatorLine color={cssColor("--lhds-color-ui-300")} />
      <CardBody>
        <MatrixAndKeywords matrix={matrix} />
      </CardBody>
    </Card>
  );
};
