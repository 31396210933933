import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";
import React from "react";
import { RouteTargetModifiedAlert } from "./RouteTargetModifiedAlert";
import { RouteTargetPage } from "./RouteTargetPage";
import { FetchRoutePairCustomerTargetFragment } from "@/gql/graphql";

interface Props {}

export type RoutePairCustomerTarget = FetchRoutePairCustomerTargetFragment;

export const RouteTargetPageWrapper: React.FC<Props> = () => {
  return (
    <SearchFilterSelectorProvider reducerId={"routeTarget"}>
      <RouteTargetModifiedAlert>
        <RouteTargetPage />
      </RouteTargetModifiedAlert>
    </SearchFilterSelectorProvider>
  );
};
