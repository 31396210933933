import { NegotiationYearSelect } from "@/common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { stenaCalendar } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
  year?: number;
  handleYearChange?: (year: number) => void;
}

export const NegotiationYearFilterSection: React.FC<Props> = ({
  enabled,
  label,
  year,
  handleYearChange,
}) => {
  const { setNegotiationYear } = useFilterWorkspace();

  const onValueChange = useCallback(
    (selectedYear: number) => {
      setNegotiationYear(selectedYear);
      handleYearChange && handleYearChange(selectedYear);
    },
    [setNegotiationYear, handleYearChange]
  );

  return (
    <FppSearchFilterSection
      sectionId={"negotiationYear"}
      enabled={enabled}
      label={label}
      leftIcon={stenaCalendar}
      children={
        <NegotiationYearSelect value={year} onValueChange={onValueChange} />
      }
    />
  );
};
