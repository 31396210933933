import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Space, Txt } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  PrimaryButton,
  stenaAngleRight,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { CustomerSelect } from "../../../../../../common/components/data-driven-inputs/selects/customer-select/CustomerSelect";
import { HeadAgreementByCustomerIdSelect } from "../../../../../../common/components/data-driven-inputs/selects/head-agreement-by-customer-select/HeadAgreementByCustomerSelect";
import { GridContainer } from "../../../../../../common/components/grid-container/GridContainer";
import { ByCustomerNumberOrIndexFragment } from "@/gql/graphql";

export interface Props {
  targetCustomer: ByCustomerNumberOrIndexFragment;
  targetHeadAgreementId: string;
  selectButtonDisabled: boolean;
  loading?: boolean;
  setTargetCustomer: React.Dispatch<
    React.SetStateAction<ByCustomerNumberOrIndexFragment>
  >;
  setTargetHeadAgreementId: React.Dispatch<React.SetStateAction<string>>;
  getTargetCustomerByHaId: () => void;
  setCreatNewHeadAgreementView: () => void;
  rightButtonOnClick: () => void;
}

export const SelectCustomerAndHeadAgreementCard: React.FC<Props> = ({
  targetCustomer,
  targetHeadAgreementId,
  selectButtonDisabled,
  loading,
  setTargetCustomer,
  setTargetHeadAgreementId,
  getTargetCustomerByHaId,
  setCreatNewHeadAgreementView,
  rightButtonOnClick,
}) => {
  return (
    <Box indent={IndentValues.TWENTYFOUR} spacing={SpacingValues.TWENTYFOUR}>
      <Card>
        <CardHeader text={"Select target customer and agreement"} />
        <CardBody>
          <GridContainer boxMinWidth={370} boxMaxWidth={"370px"}>
            <Box>
              <Txt color={cssColor("--lhds-color-ui-700")}>
                Select target customer
              </Txt>
              <Space />
              <CustomerSelect
                value={targetCustomer.id}
                onValueChange={(customer) => {
                  setTargetCustomer(customer);
                  setTargetHeadAgreementId("");
                }}
              />
            </Box>
            <Box>
              <Txt color={cssColor("--lhds-color-ui-700")}>
                Select target header agreement
              </Txt>
              <Space />
              <HeadAgreementByCustomerIdSelect
                customerId={targetCustomer.id}
                onValueChange={(headAgreement) => {
                  setTargetHeadAgreementId(headAgreement.id);
                  getTargetCustomerByHaId();
                }}
                value={targetHeadAgreementId}
                isDisabled={!targetCustomer.id}
                createNewHeadAgreementOption
                setCreatNewHeadAgreementView={() =>
                  setCreatNewHeadAgreementView()
                }
              />
            </Box>

            <Box maxWidth={"200px"} justifyContent={"flex-end"}>
              <PrimaryButton
                label="Select agreements"
                rightIcon={stenaAngleRight}
                disabled={selectButtonDisabled}
                loading={loading}
                loadingLabel={"Select agreements"}
                onClick={() => rightButtonOnClick()}
              />
            </Box>
          </GridContainer>
        </CardBody>
      </Card>
    </Box>
  );
};
