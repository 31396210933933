import { NoShowLateHandlingHandleCustomerDataTableColumnKeys } from "./RowsAndColumnsConfig";

export const customerInfoColumnOrder: Array<
  Partial<NoShowLateHandlingHandleCustomerDataTableColumnKeys>
> = [
  "checkboxColumn",
  "customerName",
  "custNo",
  "custIndex",
  "region",
  "seller",
];

export const bookingStatusColumnOrder: Array<
  Partial<NoShowLateHandlingHandleCustomerDataTableColumnKeys>
> = [
  "noOfToDo",
  "noOfToBeInvoiced",
  "noOfInvoiced",
  "noOfThreshold",
  "noOfDoNotInvoice",
  "noOfNotChargeable",
  "total",
];
