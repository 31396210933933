import { ZIndex } from "@/common/utils/ZIndexEnum";
import { cssColor } from "@stenajs-webui/theme";
import { Column, Indent, Text } from "@stenajs-webui/core";
import React from "react";
import { NegotiationYearSelect } from "../../../../common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { useDefaultYearSelection } from "../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { gtmTransmitter } from "../../../../common/utils/GtmHelper";
import { AllRouteTargetNegotiationYearChange } from "../../../../common/utils/route-target-gtm/RouteTargetGtmHelper";

interface Props {
  setYear: (year: number) => void;
}

export const NegotiationYearSelector: React.FC<Props> = ({ setYear }) => {
  const { year, handleYearChange, loading } = useDefaultYearSelection();
  const respondToYearChange = (newYear: number) => {
    gtmTransmitter({
      event: AllRouteTargetNegotiationYearChange.searchEvent,
    });
    handleYearChange(newYear);
    setYear(newYear);
  };

  return (
    <>
      <Text size="small" color={cssColor("--lhds-color-ui-500")}>
        Negotiation year
      </Text>
      <Indent num={0.5} />
      <Column width={100}>
        <NegotiationYearSelect
          isLoading={loading}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: ZIndex.highest,
            }),
          }}
          value={year}
          onValueChange={(value) => respondToYearChange(value)}
        />
      </Column>
    </>
  );
};
