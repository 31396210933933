import { Box, Column, Indent, Row, Space } from "@stenajs-webui/core";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import _ from "lodash";
import React, { useState } from "react";
import { HandleBookingExcludeReasonSelect } from "../../../../common/components/data-driven-inputs/selects/handle-booking-exclude-reason-select/HandleBookingExcludeReasonSelect";
import { ErrorDisplay } from "../../../../common/error/components/ErrorDisplay";
import { FreightPricingPortalError } from "../../../../common/error/models/FreightPricingPortalError";
import { Window } from "@stenajs-webui/modal";

interface Props {
  onExcludeReasonSet: (value: string) => void;
  onExcludeReasonCancel: () => void;
  isModalOpen: boolean;
  modalErrors: Array<FreightPricingPortalError>;
  onErrorClose?: () => void;
}

export const ExcludeReasonModalBulkUpdate: React.FC<Props> = ({
  onExcludeReasonSet,
  onExcludeReasonCancel,
  isModalOpen,
  onErrorClose,
  modalErrors,
}) => {
  const [excludeReason, setExcludeReason] = useState<string | undefined>();

  const applyButtonDisabled = (
    value: string | undefined,
    errors: FreightPricingPortalError[]
  ) => {
    return !value && errors.length === 0;
  };

  return (
    <Window
      headerText={"Do not invoice reason"}
      isOpen={isModalOpen}
      onRequestClose={() => {
        setExcludeReason(undefined);
        onExcludeReasonCancel();
      }}
      indent={3}
      spacing={2}
      width={"500px"}
      shouldCloseOnOverlayClick={false}
    >
      <Column indent={2} spacing>
        <Label text="Select reason" />
        <Box width={275}>
          <HandleBookingExcludeReasonSelect
            value={excludeReason}
            onValueChange={(value) => setExcludeReason(value)}
          />
        </Box>
        {modalErrors.length > 0 && (
          <>
            <ErrorDisplay
              error={_.first(modalErrors)!}
              heading={"Could not update booking statuses"}
              onClose={() => {
                onErrorClose?.();
              }}
            />
          </>
        )}
        <Space num={6} />
        <Row justifyContent={"flex-end"} alignItems={"center"}>
          <Indent />
          <FlatButton
            label={"Cancel"}
            onClick={() => {
              setExcludeReason(undefined);
              onExcludeReasonCancel();
            }}
          />
          <Indent />
          <PrimaryButton
            label={"Apply"}
            disabled={applyButtonDisabled(excludeReason, modalErrors)}
            onClick={() => {
              excludeReason && onExcludeReasonSet(excludeReason);
            }}
          />
        </Row>
      </Column>
    </Window>
  );
};
