import * as React from "react";
import { Indent } from "@stenajs-webui/core";
import { Icon } from "@stenajs-webui/elements";
import { breadCrumbArrow } from "../../icons/CustomIcons";
import { cssColor } from "@stenajs-webui/theme";

interface Props {}

export const ModifiedFieldSeparator: React.FC<Props> = () => {
  return (
    <Indent num={0.5}>
      <Icon
        icon={breadCrumbArrow}
        size={8}
        color={cssColor("--lhds-color-ui-700")}
      />
    </Indent>
  );
};
