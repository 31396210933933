import * as React from "react";
import { useMemo } from "react";
import { ChipMultiSelect } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import {
  CountryMultiChipSelectAllQuery,
  CountryMultiChipSelectAllActiveQuery,
} from "@/gql/graphql";

interface Props {
  value?: Array<string>;
  onValueChange?: (value: Array<string>) => void;
}

const queryAll = gql`
  query CountryMultiChipSelectAll {
    productPrice {
      country {
        all {
          id
          code
          name
        }
      }
    }
  }
`;

const queryAllActive = gql`
  query CountryMultiChipSelectAllActive {
    productPrice {
      country {
        allActive {
          id
          code
          name
        }
      }
    }
  }
`;

export const useAllCountries = () =>
  useQuery<CountryMultiChipSelectAllQuery>(queryAll);

export const useAllActiveCountries = () =>
  useQuery<CountryMultiChipSelectAllActiveQuery>(queryAllActive);

export const CountryMultiChipSelect: React.FC<Props> = ({
  onValueChange,
  value,
}) => {
  const { data, loading } = useAllCountries();

  const countries = data?.productPrice.country.all ?? [];

  const options = countries.map((r) => ({
    label: r.name,
    value: r.id,
  }));

  const selected = useMemo(() => {
    if (!value || !value.length || !options || !options.length) {
      return [];
    }
    return options
      .filter((o) => {
        return value.find((v) => o.value === v);
      })
      .map((o) => ({ label: o.value, value: o.value }));
  }, [options, value]);

  return (
    <ChipMultiSelect
      loading={loading}
      options={options}
      value={selected}
      onValueChange={(v: any) =>
        onValueChange?.(v?.map((vv: any) => vv.value) ?? [])
      }
    />
  );
};
