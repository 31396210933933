import { Row, Space, Text } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import { GridContainer } from "../../../../common/components/grid-container/GridContainer";
import { sumBy } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { BarStat } from "../../../../common/components/bar-stat-box/components/BarStat";
import { BarStatBox } from "../../../../common/components/bar-stat-box/components/BarStatBox";
import { MiniTableCell } from "../../../../common/components/bar-stat-box/components/MiniTableCell";
import { Link } from "../../../../common/navigation/Link";
import { formatThousands } from "@/common/numbers";
import { routeFactory } from "../../../../RouteFactory";
import { CustomerYearStatistics } from "../../customers/customer-details/types";
import { calculateBarStatsData } from "../util/CalculateBarChartData";

interface Props {
  data: CustomerYearStatistics[];
  customerId: string;
}

const BAR_WIDTH = 250;

export const CustomerHeaderStats: React.FC<Props> = React.memo(
  ({ data, customerId }) => {
    const { sailingCodeGroup, unitCodeGroup } = useMemo(
      () => calculateBarStatsData(data),
      [data]
    );

    return (
      <GridContainer boxMinWidth={500}>
        <BarStatBox label={"Total volume"} tableColumns={unitCodeGroup.columns}>
          <BarStat
            data={unitCodeGroup.data}
            width={BAR_WIDTH}
            label={
              <Row alignItems={"center"}>
                <Link
                  to={routeFactory.productAndPrice.customer.customerExpectedVolume(
                    {
                      customerId,
                    }
                  )}
                >
                  Expected
                </Link>
                <Space />
                <Text
                  size="small"
                  variant={"bold"}
                  color={cssColor("--lhds-color-ui-500")}
                >
                  (%)
                </Text>
              </Row>
            }
            valueKey={"expected"}
            totalSum={sumBy(unitCodeGroup.data, (g) => g.expected)}
          >
            {unitCodeGroup.data.map((d) => (
              <MiniTableCell key={d.id}>
                {formatThousands(d.expected)}
              </MiniTableCell>
            ))}
            <MiniTableCell>
              {formatThousands(sumBy(unitCodeGroup.data, (g) => g.expected))}
            </MiniTableCell>
          </BarStat>
          <Space />
          <BarStat
            data={unitCodeGroup.data}
            width={BAR_WIDTH}
            label={
              <Row alignItems={"center"}>
                <Text>Last 12m</Text>
                <Space />
                <Text variant={"bold"} color={cssColor("--lhds-color-ui-500")}>
                  (%)
                </Text>
              </Row>
            }
            valueKey={"volume12Months"}
            totalSum={sumBy(unitCodeGroup.data, (g) => g.volume12Months)}
          >
            {unitCodeGroup.data.map((d) => (
              <MiniTableCell key={d.id}>
                {formatThousands(d.volume12Months)}
              </MiniTableCell>
            ))}
            <MiniTableCell>
              {formatThousands(
                sumBy(unitCodeGroup.data, (g) => g.volume12Months)
              )}
            </MiniTableCell>
          </BarStat>
        </BarStatBox>

        <BarStatBox
          label={"Sailing type"}
          tableColumns={sailingCodeGroup.columns}
        >
          <BarStat
            data={sailingCodeGroup.data}
            width={BAR_WIDTH}
            label={
              <Row alignItems={"center"}>
                <Link
                  to={routeFactory.productAndPrice.customer.customerExpectedVolume(
                    {
                      customerId,
                    }
                  )}
                >
                  Expected
                </Link>
                <Space />
                <Text variant={"bold"} color={cssColor("--lhds-color-ui-500")}>
                  (%)
                </Text>
              </Row>
            }
            valueKey={"expected"}
            totalSum={sumBy(sailingCodeGroup.data, (g) => g.expected)}
          >
            {sailingCodeGroup.data.map((d) => (
              <MiniTableCell key={d.id}>
                {formatThousands(d.expected)}
              </MiniTableCell>
            ))}
          </BarStat>
          <Space />
          <BarStat
            data={sailingCodeGroup.data}
            width={BAR_WIDTH}
            label={
              <Row alignItems={"center"}>
                <Text>Last 12m</Text>
                <Space />
                <Text variant={"bold"} color={cssColor("--lhds-color-ui-500")}>
                  (%)
                </Text>
              </Row>
            }
            valueKey={"volume12Months"}
            totalSum={sumBy(sailingCodeGroup.data, (g) => g.volume12Months)}
          >
            {sailingCodeGroup.data.map((d) => (
              <MiniTableCell key={d.id}>
                {formatThousands(d.volume12Months)}
              </MiniTableCell>
            ))}
          </BarStat>
        </BarStatBox>
      </GridContainer>
    );
  }
);
