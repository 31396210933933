import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  RouteAgreementForRecalc,
  useMultiRecalculationOfPriceRowsIsRequiredBeforeSave,
} from "../hooks/UseRecalculationOfPriceRowsIsRequiredBeforeSave";
import { RecalculationBoxPanel } from "./RecalculationBoxPanel";
import { fetchStatsForMultiRouteAgreementCalculation } from "../thunks/StatsForMultipleRouteAgreementCalculationFetcher";
import { Spacing } from "@stenajs-webui/core";
import { fetchActualChangeForAllPriceRows } from "../../../rate-sheet/thunks/FetchActualChangeForAllPriceRows";

interface Props {
  routeAgreements: Array<RouteAgreementForRecalc>;
  customerId: string;
}

export const RecalculationBoxForMultipleRouteAgreements: React.FC<Props> = ({
  routeAgreements,
  customerId,
}) => {
  const dispatch = useDispatch();
  const { recalculationOfPriceRowsIsRequiredBeforeSave } =
    useMultiRecalculationOfPriceRowsIsRequiredBeforeSave(routeAgreements);

  const onClickRecalculate = useCallback(async () => {
    await dispatch(
      fetchStatsForMultiRouteAgreementCalculation(routeAgreements, customerId)
    );
    await dispatch(fetchActualChangeForAllPriceRows());
  }, [dispatch, routeAgreements, customerId]);

  if (!recalculationOfPriceRowsIsRequiredBeforeSave) {
    return null;
  }

  return (
    <>
      <Spacing />
      <RecalculationBoxPanel onClickRecalculate={onClickRecalculate} />
    </>
  );
};
