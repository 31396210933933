import { useQuery } from "@apollo/client";
import {
  Box,
  Column,
  Indent,
  Row,
  Space,
  Spacing,
  Txt,
  useBoolean,
} from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { Popover } from "@stenajs-webui/tooltip";
import { gql } from "apollo-boost";
import * as React from "react";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { StoreState } from "../../../../../../../config/redux/RootReducer";
import { articleFormActions } from "../actions";
import { ArticleItemFormModel } from "../models/ArticlesFormModel";
import { articleFormSelectors } from "../selectors";
import { ArticleType } from "../types";
import ArticleItemMaxPriceAndNumber from "./ArticleItemMaxPriceAndNumber";
import { ArticlePriceItemsTable } from "./ArticlePriceItemsTable";
import { FormulaPane } from "./FormulaPane";
import { cssColor } from "@stenajs-webui/theme";
import {
  FormulaFormInputsQuery,
  FormulaFormInputsQueryVariables,
} from "@/gql/graphql";

interface Props {
  articleType: ArticleType;
  articleId: string;
}

const query = gql`
  fragment FormulaFormInputsIncludes on FormulaTypeIncludes {
    drivers
    interval1PercentValue
    interval2PercentValue
    interval3PercentValue
    interval4PercentValue
    intervalAPercentValue
    intervalBPercentValue
    intervalCPercentValue
    intervalDPercentValue
    intervalLength
    maxPercent
    percent
    weight
    width
  }
  fragment FormulaById on FormulaType {
    code
    id
    description
    includes {
      ...FormulaFormInputsIncludes
    }
  }

  query FormulaFormInputs($formulaTypeId: ID!) {
    productPrice {
      formula {
        byId(id: $formulaTypeId) {
          ...FormulaById
        }
      }
    }
  }
`;

const resolveTemplateColumns = (width: number) => {
  if (width > 1500) {
    return "1fr 1fr 1fr";
  }

  if (width > 1200) {
    return "1fr 1fr";
  }

  return "1fr";
};

export const ArticleItemPane: React.FC<Props> = memo(
  ({ articleType, articleId }) => {
    const dispatch = useDispatch();
    const showSelectFormula = articleType.needsPricingData;
    const article = useSelector((state: StoreState) =>
      articleFormSelectors.getSpecificArticleState(state, articleId)
    );
    const [popoverOpen, openPopover, closePopover] = useBoolean(false);
    const { data, loading, error } = useQuery<
      FormulaFormInputsQuery,
      FormulaFormInputsQueryVariables
    >(query, {
      variables: {
        formulaTypeId: article.formulaTypeId ?? "",
      },
    });
    const { width } = useWindowSize();

    const addNewPriceHandler = useCallback(() => {
      dispatch(articleFormActions.addArticlePrice(article.id));
    }, [dispatch, article]);

    const setFields = useCallback(
      (fields: Partial<ArticleItemFormModel>) => {
        dispatch(articleFormActions.setArticleFields(articleId, fields));
      },
      [dispatch, articleId]
    );

    if (!article) {
      return (
        <Spacing num={3}>
          <ErrorScreen text={"Could not find article."} />
        </Spacing>
      );
    }

    if (loading) {
      return <LoadingScreen />;
    }

    if (error) {
      return <ErrorScreen text={error.message} />;
    }

    const formulaType = data?.productPrice.formula.byId;

    if (!formulaType && article.formulaTypeId) {
      return <ErrorScreen text={"Could not find formula type."} />;
    }

    return (
      <Box>
        <Spacing />
        <Indent num={2}>
          <Txt
            variant="bold"
            size="medium"
            color={cssColor("--lhds-color-ui-900")}
          >
            {articleType.name}
          </Txt>
        </Indent>

        <Spacing />
        <Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: resolveTemplateColumns(width),
            }}
          >
            <Box>
              <Indent num={2}>
                <Txt
                  variant="bold"
                  size="medium"
                  color={cssColor("--lhds-color-ui-900")}
                >
                  {"Vehicles"}
                </Txt>
              </Indent>
              <Spacing num={0.75} />
              <ArticlePriceItemsTable
                article={article}
                showPercent={articleType.usesPercent}
                showPrice={!articleType.usesPercent}
                addNewPriceHandler={addNewPriceHandler}
              />
            </Box>
            {width < 1200 && <Spacing />}
            <Box width={325} indent={2}>
              <Box flexDirection="row" alignItems={"start"}>
                <Txt
                  variant="bold"
                  size="medium"
                  color={cssColor("--lhds-color-ui-900")}
                >
                  {"Formula"}
                </Txt>
                {showSelectFormula && (
                  <>
                    <Space num={0.5} horizontal />
                    <Popover
                      animation={false}
                      placement={"bottom"}
                      visible={popoverOpen}
                      trigger={"hover"}
                      onClickOutside={() => {
                        closePopover();
                      }}
                      content={
                        <Box spacing={1.5} indent={2}>
                          <Row spacing>
                            <Txt
                              size="small"
                              color={cssColor("--lhds-color-ui-500")}
                            >
                              Formula:
                            </Txt>
                            <Space num={3} />
                            <Column>
                              {formulaType?.description.map(
                                (descriptionLine, index) => (
                                  <Txt
                                    key={index}
                                    variant={"caption"}
                                    color={cssColor("--lhds-color-ui-600")}
                                  >
                                    {descriptionLine}
                                  </Txt>
                                )
                              )}
                            </Column>
                          </Row>
                        </Box>
                      }
                    >
                      <FlatButton
                        leftIcon={stenaInfoCircle}
                        style={{ transform: "translateY(-5.5px)" }}
                        onClick={() => {
                          popoverOpen ? closePopover() : openPopover();
                        }}
                      />
                    </Popover>
                  </>
                )}
              </Box>
              {!showSelectFormula && (
                <Box>
                  <Space num={6} />
                  <Row>
                    <Txt size="small" color={cssColor("--lhds-color-ui-500")}>
                      No formula exist for this article.
                    </Txt>
                  </Row>
                </Box>
              )}
              {showSelectFormula && (
                <>
                  <FormulaPane
                    articleTypeId={article.articleTypeId}
                    formulaTypeId={article.formulaTypeId}
                    articleId={article.id}
                    onChangeFormulaTypeId={(formulaTypeId) =>
                      setFields({ formulaTypeId })
                    }
                    formulaType={formulaType}
                  />
                </>
              )}
            </Box>
            <Box indent={2}>
              <Txt
                variant="bold"
                size="medium"
                color={cssColor("--lhds-color-ui-900")}
              >
                {"Max price and number of"}
              </Txt>
              <Spacing num={0.75} />
              <ArticleItemMaxPriceAndNumber
                maxNumberOf={article.maxNumberOf}
                maxPrice={article.maxPrice}
                setFields={setFields}
              />
            </Box>
          </Box>
          <Spacing />
        </Box>
      </Box>
    );
  }
);
