import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { Box, Space } from "@stenajs-webui/core";
import {
  FlatButton,
  Icon,
  stenaArrowRight,
  stenaTimes,
} from "@stenajs-webui/elements";
import { TextInputBox, TextInputVariant } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useState } from "react";

interface Props {
  fromValue: string;
  toValue: string;
  onValueChangeFromValue: (value: string) => void;
  onValueChangeToValue: (value: string) => void;
}

export const AgrArticleLengthRange: React.FC<Props> = ({
  fromValue,
  toValue,
  onValueChangeFromValue,
  onValueChangeToValue,
}) => {
  const [variant, setVariant] = useState<TextInputVariant>("standard");

  const renderError = () => {
    if (
      fromValue !== "" &&
      toValue !== "" &&
      Number(commaToDotTransform(fromValue)) >
        Number(commaToDotTransform(toValue))
    ) {
      return setVariant("error");
    }
    return setVariant("standard");
  };

  return (
    <Tooltip
      disabled={variant === "standard" || (!fromValue && !toValue)}
      variant={"error"}
      label={"Input is invalid"}
    >
      <Box width={"220px"} onBlur={renderError}>
        <TextInputBox variant={fromValue && toValue ? variant : "standard"}>
          <NumericTextInput
            hideBorder
            iconRight={fromValue.length >= 1 ? stenaTimes : undefined}
            buttonRight={
              fromValue.length >= 1 && (
                <FlatButton
                  onClick={() => {
                    onValueChangeFromValue("");
                    setVariant("standard");
                  }}
                  rightIcon={stenaTimes}
                />
              )
            }
            value={fromValue}
            onValueChange={(value) => onValueChangeFromValue(value)}
            maxNumbers={3}
            maxDecimals={1}
            placeholder={"Length from"}
          />
          {fromValue && toValue && (
            <Icon
              icon={stenaArrowRight}
              size={12}
              color={cssColor("--lhds-color-ui-500")}
            />
          )}
          <Space />
          <NumericTextInput
            hideBorder
            iconRight={toValue.length >= 1 ? stenaTimes : undefined}
            buttonRight={
              toValue.length >= 1 && (
                <FlatButton
                  onClick={() => {
                    onValueChangeToValue("");
                    setVariant("standard");
                  }}
                  rightIcon={stenaTimes}
                />
              )
            }
            value={toValue}
            onValueChange={(value) => onValueChangeToValue(value)}
            maxNumbers={3}
            maxDecimals={1}
            placeholder={"Length to"}
          />
        </TextInputBox>
      </Box>
    </Tooltip>
  );
};
