import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { AdminTargetNegotiationYearSelect } from "../../../../../common/components/data-driven-inputs/selects/admin-target-negotiation-year-select/AdminTargetNegotiationYearSelect";

interface Props {
  heading: string;
  selectLabel: string;
  value: number;
  onSelectYearChange: (item: number) => void;
  defaultYearSelectionLoading: boolean;
  defaultYearSelectionError: ApolloError | undefined;
}

export const AllRoutesTableStatisticsHeader: React.FC<Props> = ({
  heading,
  selectLabel,
  value,
  onSelectYearChange,
  defaultYearSelectionError,
  defaultYearSelectionLoading,
}) => {
  return (
    <Box
      //FIXME: when CardHeader has the necessary props for sticky.
      top={98}
      left={0}
      zIndex={ZIndex.lowest}
      position={"sticky"}
      width={"100vw"}
      background={"var(--lhds-color-ui-50)"}
      borderRadius={"var(--swui-border-radius)"}
    >
      <CardHeader
        text={heading}
        contentRight={
          <AdminTargetNegotiationYearSelect
            value={value}
            selectLabel={selectLabel}
            onSelectYearChange={onSelectYearChange}
            defaultYearSelectionError={defaultYearSelectionError}
            defaultYearSelectionLoading={defaultYearSelectionLoading}
          />
        }
      />
    </Box>
  );
};
