import { gql, useQuery } from "@apollo/client";
import { SailingCategory } from "../../types/FilterEntitys";
import { SailingCategoryFilterQuery } from "@/gql/graphql";

const sailingCategoryFilterQuery = gql`
  query SailingCategoryFilter {
    productPrice {
      sailingCategory {
        all {
          id
          code
          name
        }
      }
    }
  }
`;
export const useFetchAllSailingCategories = () => {
  const { data, error, loading, refetch } =
    useQuery<SailingCategoryFilterQuery>(sailingCategoryFilterQuery, {
      fetchPolicy: "cache-first",
    });

  const sailingCategories =
    data?.productPrice.sailingCategory.all.map<SailingCategory>((r) => ({
      id: r.id,
      code: r.id,
      name: r.name,
    })) ?? [];

  return { sailingCategories, error, loading, refetch };
};
