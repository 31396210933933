import { routeFactory } from "@/RouteFactory";
import { Tab } from "@/common/components/tabs/Tab";
import { TabMenu } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {
  customerNo: string;
}

export const AgreementDocumentsTabMenu: React.FC<Props> = ({ customerNo }) => {
  const agreementDocumentsPath =
    routeFactory.productAndPrice.headAgreement.agreementDocuments({
      customerNo,
    });
  const rebateAgreementDocumentsPath =
    routeFactory.productAndPrice.headAgreement.rebateAgreementDocuments({
      customerNo,
    });
  return (
    <TabMenu>
      <Tab label={"Agreements"} path={agreementDocumentsPath} />
      <Tab label={"Rebate"} path={rebateAgreementDocumentsPath} />
    </TabMenu>
  );
};
