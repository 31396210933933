import { gql } from "@apollo/client";

export const adminTargetQuery = gql`
  query AdminTarget($byYearYear: Int!) {
    productPrice {
      targetAdmin {
        byYear(year: $byYearYear) {
          id
          year
          routePairCode
          offPeakAcc
          peakAcc
          offPeakUnAcc
          peakUnAcc
          shoulderUnAcc
          shoulderAcc
          volumeTarget
          routePair {
            id
            description
          }
        }
      }
    }
  }
`;

export const adminTargetAllRoutesMutation = gql`
  mutation AdminTargetAllRoutes(
    $updateGroupFreightTargetsInput: [UpdateGroupFreightTargetInput!]!
  ) {
    productPrice {
      adminTarget {
        updateGroupFreightTargets(input: $updateGroupFreightTargetsInput) {
          updateTargets {
            id
            routePairCode
            year
          }
        }
      }
    }
  }
`;
