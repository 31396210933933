import {
  BookingSearchResultData,
  CustomerSearchResultData,
  SearchEntityType,
} from "../PriceCalculatorFormFetcher";

interface CustomerData {
  customerSearchResult: CustomerSearchResultData | undefined;
  searchEntity: string | undefined;
}

interface BookingData {
  bookingSearchResult: BookingSearchResultData | undefined;
  searchEntity: string | undefined;
}

export const customerDataExist = ({
  customerSearchResult,
  searchEntity,
}: CustomerData) => {
  return (
    customerSearchResult &&
    customerSearchResult.formData &&
    searchEntity === SearchEntityType.CUSTOMER
  );
};

export const bookingDataExist = ({
  bookingSearchResult,
  searchEntity,
}: BookingData) => {
  return (
    (bookingSearchResult &&
      bookingSearchResult.priceCalcData.length > 0 &&
      bookingSearchResult.consignmentData.length > 0 &&
      searchEntity === SearchEntityType.BOOKING) ||
    (bookingSearchResult && bookingSearchResult.handledError)
  );
};
