import { HeadAgreementStatusCode } from "@/gql/graphql";
import { HeadAgreement } from "../types";
import { formatGQLDate } from "@/common/formatters/DateFormatter";
export const getIdsForRouteAgreementsToPrefill = (
  headAgreement: HeadAgreement
): Array<string> => {
  return headAgreement.routeAgreements
    .filter((r) => {
      return (
        formatGQLDate(r.valid.end) === formatGQLDate(headAgreement.valid.end) &&
        r.statusCode === HeadAgreementStatusCode.Active
      );
    })
    .map((r) => r.id);
};
