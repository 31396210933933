import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getNslhSearchInvoicesDateRangeInitialState } from "./utils/DateRangeInitialState";
import { DateRangeInputValues } from "@/common/components/date-range-year-and-month-input/DateRangeYearAndMonthInput";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import {
  NslhCustomerSelectFragment,
  NslhInvoiceByFilterFragment,
  QueryByInvoiceNoNslhInvoiceFragment,
} from "@/gql/graphql";

export type InvoiceSpecificationRow = NslhInvoiceByFilterFragment;

export type NslhSearchInvoicesByFilterData =
  | InvoiceSpecificationRow[]
  | undefined;
export type NslhSearchInvoicesByInvoiceNoData =
  | QueryByInvoiceNoNslhInvoiceFragment[]
  | undefined;
export type NslhSearchInvoicesCustomerData = NslhCustomerSelectFragment | null;
export type NslhSearchInvoicesCustomerNoInput = number;
export type NslhSearchInvoicesInvoiceNoInput = string;
export type NslhSearchInvoicesCustomerSelectedData =
  NslhCustomerSelectFragment | null;
export type NslhSearchInvoicesDateRangeInput = DateRangeInputValues;
export type NslhSearchInvoicesError = FreightPricingPortalError | undefined;

export interface NoShowLateHandlingSearchInvoicesState {
  nslhSearchInvoiceCustomerData: NslhSearchInvoicesCustomerData;
  nslhSearchInvoicesByFilterData: NslhSearchInvoicesByFilterData;
  nslhSearchInvoicesByInvoiceNoData: NslhSearchInvoicesByInvoiceNoData;
  nslhSearchInvoicesCustomerNoInput: NslhSearchInvoicesCustomerNoInput;
  nslhSearchInvoicesInvoiceNoInput: NslhSearchInvoicesInvoiceNoInput;
  nslhSearchInvoicesCustomerSelectedData: NslhSearchInvoicesCustomerSelectedData;
  nslhSearchInvoicesDateRangeInput: NslhSearchInvoicesDateRangeInput;
  nslhSearchInvoicesLoading: boolean;
  nslhSearchInvoicesError: NslhSearchInvoicesError;
}

const initialNoShowLateHandlingSearchInvoicesState: NoShowLateHandlingSearchInvoicesState =
  {
    nslhSearchInvoiceCustomerData: null,
    nslhSearchInvoicesByFilterData: undefined,
    nslhSearchInvoicesByInvoiceNoData: undefined,
    nslhSearchInvoicesCustomerNoInput: 0,
    nslhSearchInvoicesInvoiceNoInput: "",
    nslhSearchInvoicesCustomerSelectedData: null,
    nslhSearchInvoicesLoading: false,
    nslhSearchInvoicesError: undefined,
    nslhSearchInvoicesDateRangeInput:
      getNslhSearchInvoicesDateRangeInitialState(new Date()),
  };

export const noShowLateHandlingSearchInvoicesReducerId =
  "noShowLateHandlingSearchInvoicesReducerId";

export const noShowLateHandlingSearchInvoicesSlice = createSlice({
  name: noShowLateHandlingSearchInvoicesReducerId,
  initialState: initialNoShowLateHandlingSearchInvoicesState,
  reducers: {
    setNslhSearchInvoiceCustomerData(
      state,
      {
        payload: nslhSearchInvoicesCustomerData,
      }: PayloadAction<NslhSearchInvoicesCustomerData>
    ) {
      state.nslhSearchInvoiceCustomerData = nslhSearchInvoicesCustomerData;
    },
    setNslhSearchInvoicesByFilterData(
      state,
      {
        payload: nslhSearchInvoicesByFilterData,
      }: PayloadAction<NslhSearchInvoicesByFilterData>
    ) {
      state.nslhSearchInvoicesByFilterData = nslhSearchInvoicesByFilterData;
    },
    setNslhSearchInvoicesByInvoiceNoData(
      state,
      {
        payload: nslhSearchInvoicesByInvoiceNoData,
      }: PayloadAction<NslhSearchInvoicesByInvoiceNoData>
    ) {
      state.nslhSearchInvoicesByInvoiceNoData =
        nslhSearchInvoicesByInvoiceNoData;
    },
    setNslhSearchInvoicesCustomerNoInput(
      state,
      {
        payload: nslhSearchInvoicesCustomerNo,
      }: PayloadAction<NslhSearchInvoicesCustomerNoInput>
    ) {
      if (state.nslhSearchInvoicesCustomerSelectedData !== null) {
        state.nslhSearchInvoicesCustomerSelectedData.custNo =
          nslhSearchInvoicesCustomerNo;
      }
    },
    setNslhSearchInvoicesInvoiceNoInput(
      state,
      {
        payload: nslhSearchInvoicesInvoiceNoInput,
      }: PayloadAction<NslhSearchInvoicesInvoiceNoInput>
    ) {
      state.nslhSearchInvoicesInvoiceNoInput = nslhSearchInvoicesInvoiceNoInput;
    },
    setNslhSearchInvoicesCustomerSelectedData(
      state,
      {
        payload: nslhSearchInvoicesCustomerSelectedData,
      }: PayloadAction<NslhSearchInvoicesCustomerSelectedData>
    ) {
      state.nslhSearchInvoicesCustomerSelectedData =
        nslhSearchInvoicesCustomerSelectedData;
    },
    setNslhSearchInvoicesDateRangeInput(
      state,
      {
        payload: nslhSearchInvoicesDateRangeInput,
      }: PayloadAction<NslhSearchInvoicesDateRangeInput>
    ) {
      state.nslhSearchInvoicesDateRangeInput = nslhSearchInvoicesDateRangeInput;
    },
    setNslhSearchInvoicesLoading(
      state,
      { payload: nslhSearchInvoicesloading }: PayloadAction<boolean>
    ) {
      state.nslhSearchInvoicesLoading = nslhSearchInvoicesloading;
    },
    setNslhSearchInvoicesError(
      state,
      {
        payload: nslhSearchInvoicesError,
      }: PayloadAction<NslhSearchInvoicesError>
    ) {
      state.nslhSearchInvoicesError = nslhSearchInvoicesError;
    },
  },
});

export const {
  setNslhSearchInvoiceCustomerData,
  setNslhSearchInvoicesByFilterData,
  setNslhSearchInvoicesByInvoiceNoData,
  setNslhSearchInvoicesCustomerNoInput,
  setNslhSearchInvoicesInvoiceNoInput,
  setNslhSearchInvoicesCustomerSelectedData,
  setNslhSearchInvoicesDateRangeInput,
  setNslhSearchInvoicesLoading,
  setNslhSearchInvoicesError,
} = noShowLateHandlingSearchInvoicesSlice.actions;

export const noShowLateHandlingSearchInvoicesReducer =
  noShowLateHandlingSearchInvoicesSlice.reducer;
