import {
  AuditData,
  PriceCalculatorFormData,
} from "../../PriceCalculatorFormFetcher";
import {
  FetchConsignmentFragment,
  FetchPriceCalcFragment,
} from "@/gql/graphql";

export const transformBookingResultToAuditData = (
  data: FetchPriceCalcFragment,
  consignment: FetchConsignmentFragment
): AuditData => {
  return {
    auditText: data.auditTrail,
    departureDate: consignment.sailingArchive.departureDate,
    routeCode: consignment.sailingArchive.route.code,
    sailingCategory: consignment.sailingArchive.sailingType
      ? String(consignment.sailingArchive.sailingType)
      : "",
  };
};

export const transformCustomerResultToAuditData = (
  data: FetchPriceCalcFragment,
  formData: PriceCalculatorFormData
): AuditData => {
  return {
    auditText: data.auditTrail,
    departureDate: formData.sailingDate ? formData.sailingDate : "",
    routeCode: formData.routeCode,
    sailingCategory: formData.sailingType,
  };
};
