import { SailingCategoryTimeTableSelect } from "@/common/components/data-driven-inputs/selects/sailing-category-departure-select/SailingCategoryTimeTableSelect";
import { DateTextInput } from "@stenajs-webui/calendar";
import { Box, Text } from "@stenajs-webui/core";
import { TableCell, TableRow } from "@stenajs-webui/grid";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import {
  formDefaultState,
  PriceCalculatorFormData,
  RouteLeg,
} from "../../../PriceCalculatorFormFetcher";
import { useSailingsByRouteCodeDepartureDate } from "./hooks/UseSailingsByRouteCodeDepartureDate";
import { PriceCalculatorDepartureCategorySelect } from "./PriceCalculatorDepartureCategorySelect";
import { PriceCalculatorDepartureTimeInput } from "./PriceCalculatorDepartureTimeInput";

interface Props {
  routeLeg: RouteLeg;
  index: number;
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
}

export const PriceCalculatorLegRow: React.FC<Props> = ({
  routeLeg,
  index,
  formData,
  onChangeFormData,
  setState,
}) => {
  const { sailings, sailingDeparturesLoading, fetchSailings } =
    useSailingsByRouteCodeDepartureDate();

  const [manualDeparture, setManualDeparture] = useState(true);

  useEffect(() => {
    if (
      routeLeg.routeCode &&
      routeLeg.departureDate &&
      routeLeg.departureDate.length > 9 &&
      isNaN(new Date(routeLeg.departureDate).getUTCFullYear()) !== true
    ) {
      fetchSailings({
        variables: {
          routeCode: routeLeg.routeCode,
          departureDate: routeLeg.departureDate,
        },
      });
      setManualDeparture(true);
    }
  }, [
    routeLeg.routeCode,
    routeLeg.departureDate,
    fetchSailings,
    setManualDeparture,
  ]);

  if (!sailings) return null;

  return (
    <TableRow key={routeLeg.routeCode} alignItems={"center"}>
      <TableCell justifyContent={"flex-start"} width={56}>
        <Text size={"medium"}>{index + 1}</Text>
      </TableCell>
      <TableCell width={64}>
        <Text size={"medium"}>{routeLeg.routeCode}</Text>
      </TableCell>
      <TableCell width={137}>
        <DateTextInput
          value={routeLeg.departureDate}
          onValueChange={(value) => {
            const mergedRouteLeg = formData.routeLegs!;
            mergedRouteLeg[index].departureDate = value;
            onChangeFormData("routeLegs", mergedRouteLeg);
            setState((formData) => ({
              ...formData,
              routeLegs: formData.routeLegs?.map((routeLeg) => {
                return routeLeg.routeCode === mergedRouteLeg[index].routeCode
                  ? {
                      ...routeLeg,
                      sailingTypeCode: formDefaultState.sailingType ?? "",
                      departureTime: formDefaultState.departureTime ?? "",
                    }
                  : routeLeg;
              }),
            }));
          }}
          portalTarget={document.body}
          calendarProps={{
            highlightToday: true,
          }}
        />
      </TableCell>
      <TableCell width={160}>
        <Box width={160}>
          <SailingCategoryTimeTableSelect
            value={routeLeg.sailingTypeCode}
            onValueChange={(selectedCategory) => {
              const enterTimeManually = selectedCategory.value === "Manual";
              setManualDeparture(enterTimeManually);
              if (!enterTimeManually) {
                const mergedRouteLeg = formData.routeLegs!;
                mergedRouteLeg[index].sailingTypeCode = selectedCategory.value!;
                mergedRouteLeg[index].departureTime =
                  selectedCategory.departureTime!;
                onChangeFormData("routeLegs", mergedRouteLeg);
              }
            }}
            loading={sailingDeparturesLoading}
            sailings={sailings}
          />
        </Box>
      </TableCell>
      <TableCell width={160}>
        <Box width={160}>
          <PriceCalculatorDepartureCategorySelect
            value={manualDeparture ? routeLeg.sailingTypeCode : ""}
            onValueChange={(value) => {
              const mergedRouteLeg = formData.routeLegs!;
              mergedRouteLeg[index].sailingTypeCode = value.value!;
              onChangeFormData("routeLegs", mergedRouteLeg);
            }}
            disabled={!manualDeparture}
          />
        </Box>
      </TableCell>
      <TableCell width={140}>
        <PriceCalculatorDepartureTimeInput
          value={manualDeparture ? routeLeg.departureTime : ""}
          onValueChange={(value) => {
            const mergedRouteLeg = formData.routeLegs!;
            mergedRouteLeg[index].departureTime = value;
            onChangeFormData("routeLegs", mergedRouteLeg);
          }}
          disabled={!manualDeparture}
        />
      </TableCell>
    </TableRow>
  );
};
