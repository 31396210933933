import { StatBoxTableColumn } from "./StatBoxTableConfig";

export const noLabelColumnOrder: Array<StatBoxTableColumn> = [
  "accompanied",
  "actual",
  "target",
];

export const volumeColumnOrder: Array<StatBoxTableColumn> = [
  "volume",
  "expectedVolume",
];

export const statVolColumnOrder: Array<StatBoxTableColumn> = [
  "statVolume",
  "expectedStatVolume",
];
