import { useCallback } from "react";
import { AddVehicleTypeFormModel } from "../add-vehicle-type/models/AddVehicleTypeFormModel";
import { transformAddVehicleTypeFormToPrice } from "../transformers/AddVehicleTypeTransformer";
import { useDispatch, useSelector } from "react-redux";
import { rateSheetTableSelectors } from "../selectors";
import { rateSheetTableActions } from "../actions";
import { rateSheetStandardTableWrapperActions } from "../redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { AllVehiclesFragment } from "@/gql/graphql";

export const useRateSheetTableState = (
  routeAgreementId: string,
  allVehicleTypes: Array<AllVehiclesFragment>
) => {
  const dispatch = useDispatch();

  const pricesSelector = useCallback(
    (state: StoreState) =>
      rateSheetTableSelectors.getTableStateForRouteAgreement(
        state,
        routeAgreementId
      ),
    [routeAgreementId]
  );

  const tableRowsState = useSelector(pricesSelector);

  const addRateSheetTableItem = useCallback(
    (formModel: AddVehicleTypeFormModel, multiLegRouteAgreementId?: string) => {
      const createdPrice = transformAddVehicleTypeFormToPrice(
        formModel,
        allVehicleTypes
      );
      dispatch(
        rateSheetStandardTableWrapperActions.tableRows.recordAction(
          multiLegRouteAgreementId ?? routeAgreementId,
          rateSheetTableActions.createNewPrice(createdPrice)
        )
      );
    },
    [dispatch, routeAgreementId, allVehicleTypes]
  );

  const deleteRateSheetTableItem = useCallback(
    (id: string) => {
      dispatch(
        rateSheetStandardTableWrapperActions.tableRows.recordAction(
          routeAgreementId,
          rateSheetTableActions.markPriceAsDeleted(id)
        )
      );
    },
    [routeAgreementId, dispatch]
  );

  return {
    addRateSheetTableItem,
    deleteRateSheetTableItem,
    prices: tableRowsState?.rows,
    selectedId: tableRowsState?.selectedRowId,
  };
};
