import { Indent, Text } from "@stenajs-webui/core";
import { Icon, stenaCheck } from "@stenajs-webui/elements";
import {
  createColumnConfig,
  StandardTable,
  StandardTableColumnOptions,
  StandardTableConfig,
} from "@stenajs-webui/grid";
import * as React from "react";
import { useMemo } from "react";
import { HeadAgreementStatusCodeChip } from "../../../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { Link } from "../../../../../../../common/navigation/Link";
import { routeFactory } from "../../../../../../../RouteFactory";
import {
  RouteAgreement,
  WriteHistory,
} from "../../../../../customers/customer-details/types";
import { useHeadAgreementById } from "../../../hooks/UseHeadAgreementById";
import { RouteAgreementActionButtons } from "./RouteAgreementActionButtons";
import { cssColor } from "@stenajs-webui/theme";
import {
  formatGQLDateTime,
  formatGQLDateTimeRelative,
  formatGQLDateRange,
} from "@/common/formatters/DateFormatter";
import { formatSailingTypeCodesToString } from "@/common/formatters/SailingStatusCodeFormatter";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  routePairCode: string;
  headAgreementId: string;
  customerId: string;
  year: number;
}

const BooleanCell = ({ value }: { value: boolean }) => {
  if (!value) {
    return null;
  }

  return (
    <Indent>
      <Icon
        icon={stenaCheck}
        color={cssColor("--lhds-color-green-300")}
        size={14}
      />
    </Indent>
  );
};

const booleanCellOptions: StandardTableColumnOptions<
  RoutePairTableColumns,
  boolean,
  | keyof Pick<
      RoutePairTableColumns,
      | "agreementNumber"
      | "route"
      | "name"
      | "isSalesReady"
      | "type"
      | "status"
      | "validity"
      | "currencyCode"
      | "conditions"
      | "articles"
      | "matrix"
      | "modifiedAt"
    >
  | "actions"
> = {
  renderCell: ({ label, value }) => <BooleanCell value={value} />,
};

const config: StandardTableConfig<
  RoutePairTableColumns,
  | keyof Pick<
      RoutePairTableColumns,
      | "agreementNumber"
      | "route"
      | "name"
      | "isSalesReady"
      | "type"
      | "status"
      | "validity"
      | "currencyCode"
      | "conditions"
      | "articles"
      | "matrix"
      | "modifiedAt"
    >
  | "actions"
> = {
  keyResolver: (item) => String(item.id),
  rowIndent: 2,
  columns: {
    agreementNumber: createColumnConfig((item) => item.agreementNumber, {
      renderCell: ({ label, value, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.routeAgreement.routeAgreementDetails(
              {
                customerId: item.customerId,
                headAgreementId: item.headAgreementId,
                routePairId: item.routePairId,
                routeAgreementId: item.id,
                tab: "rates",
              }
            )}
          >
            {label}
          </Link>
        </Indent>
      ),
      columnLabel: "Agreement",
      minWidth: "80px",
    }),
    isSalesReady: createColumnConfig((item) => item.isSalesReady, {
      ...booleanCellOptions,
      columnLabel: "SR",
      minWidth: "70px",
    }),
    route: createColumnConfig((item) => item.route, {
      minWidth: "110px",
    }),
    name: createColumnConfig((item) => item.name),
    type: createColumnConfig((item) => item.type, {
      width: "70px",
    }),
    status: createColumnConfig((item) => item.status, {
      renderCell: ({ label, value }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={value} />
        </Indent>
      ),
      width: "100px",
    }),
    validity: createColumnConfig((item) => item.validity, { width: "180px" }),
    currencyCode: createColumnConfig((item) => item.currencyCode, {
      width: "90px",
      columnLabel: "Currency",
    }),
    conditions: createColumnConfig((item) => item.conditions, {
      ...booleanCellOptions,
      width: "80px",
    }),
    articles: createColumnConfig((item) => item.articles, {
      ...booleanCellOptions,
      width: "70px",
    }),
    matrix: createColumnConfig((item) => item.matrix, {
      ...booleanCellOptions,
      width: "70px",
    }),
    modifiedAt: createColumnConfig((item) => item.modifiedAt, {
      renderCell: ({ label, value }) => (
        <Indent>
          <Text title={formatGQLDateTime(value)}>
            {formatGQLDateTimeRelative(value)}
          </Text>
        </Indent>
      ),
      width: "115px",
    }),
    actions: createColumnConfig((item) => item, {
      justifyContentCell: "flex-end",
      columnLabel: "",
      renderCell: ({ label, item }) => (
        <RouteAgreementActionButtons routeAgreement={item} year={item.year} />
      ),
      width: "40px",
      disableGridCell: true,
    }),
  },
  columnOrder: [
    "agreementNumber",
    "isSalesReady",
    "route",
    "name",
    "type",
    "status",
    "validity",
    "currencyCode",
    "conditions",
    "articles",
    "matrix",
    "modifiedAt",
    "actions",
  ],
};

interface RoutePairTableColumns extends RouteAgreement {
  id: string;
  year: number;
  headAgreementId: string;
  customerId: string;
  routePairId: string;
  agreementNumber: number;
  name: string;
  route: string;
  type: string;
  status: HeadAgreementStatusCode;
  validity: string;
  currencyCode: string;
  conditions: boolean;
  articles: boolean;
  rowVersion: string;
  matrix: boolean;
  modifiedAt: WriteHistory;
}

const transformRouteAgreementsToRoutePairTableColumns = (
  routeAgreement: RouteAgreement,
  year: number
): RoutePairTableColumns => ({
  ...routeAgreement,
  id: routeAgreement.id,
  year,
  headAgreementId: routeAgreement.headAgreement.id,
  agreementNumber: routeAgreement.agreementNumber,
  customerId: routeAgreement.headAgreement.customer.id,
  name: routeAgreement.name,
  routePairId: routeAgreement.routes[0].routePair.id,
  route: routeAgreement.routes.map((r) => r.id).join(", "),
  type: formatSailingTypeCodesToString(routeAgreement.sailingTypeCodes),
  status: routeAgreement.statusCode,
  validity: formatGQLDateRange(routeAgreement.valid),
  articles: routeAgreement.hasArticles,
  conditions: routeAgreement.hasConditions,
  currencyCode: routeAgreement.currency.code,
  matrix: routeAgreement.hasMatrix,
  rowVersion: routeAgreement.rowVersion,
  modifiedAt:
    routeAgreement.writeHistory.modifiedAt ??
    routeAgreement.writeHistory.createdAt,
});

export const RoutePairTable: React.FC<Props> = ({
  headAgreementId,
  routePairCode,
  customerId,
  year,
}) => {
  const { headAgreement, loading, error } = useHeadAgreementById(
    headAgreementId,
    customerId,
    year
  );

  const routeAgreements = headAgreement?.routeAgreements ?? [];

  const filteredRouteAgreements = routeAgreements.filter(
    (ra) => !ra.multiLegAgrRouteId
  );

  const items = useMemo(
    () =>
      filteredRouteAgreements
        .filter((agreement) =>
          agreement.routes.some((route) => route.routePair.id === routePairCode)
        )
        .map((ra) => transformRouteAgreementsToRoutePairTableColumns(ra, year)),
    [filteredRouteAgreements, year, routePairCode]
  );

  return (
    <StandardTable
      loading={loading}
      error={error}
      config={config}
      items={items}
    />
  );
};
