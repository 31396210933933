import { cleanNumericTextFieldInput } from "@/common/formatters/NumericTextFieldInputCleaner";
import { TableContext } from "@stenajs-webui/grid";
import { EditableEntityState, EntityListState } from "@stenajs-webui/redux";
import { compact } from "lodash";
import { batch, useDispatch } from "react-redux";
import { AllCustomerTargetsTableRow } from "../config/RowsAndColumnsConfig";
import {
  customerTargetActions,
  RoutePairCustomerTargetSortedColumnState,
} from "../redux/reducers";
import {
  SelectedState,
  WarningState,
} from "../RoutePairCustomerTargetBulkEditing";

interface BulkUpdateFormState {
  targetValue: string;
  grossValue: string;
}

type BulkShowWarningArgs = {
  setWarningState: React.Dispatch<React.SetStateAction<WarningState>>;
  selectedRowIds: Array<string>;
  checkBoxesSelected: SelectedState;
  routeCustomerTargetData: AllCustomerTargetsTableRow[];
};

type BulkUpdateArgs = {
  setWarningState: React.Dispatch<React.SetStateAction<WarningState>>;
  formData: BulkUpdateFormState;
  selectedRowIds: Array<string>;
  checkBoxesSelected: SelectedState;
  routeCustomerTargetData: AllCustomerTargetsTableRow[];
  setFormData: (value: BulkUpdateFormState) => void;
};

interface BulkUpdateLogicHandlerHelpers {
  bulkShouldShowWarning: ({
    setWarningState,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
  }: BulkShowWarningArgs) => boolean;

  bulkUpdate: ({
    setWarningState,
    formData,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
    setFormData,
  }: BulkUpdateArgs) => void;
  bulkUpdateAfterConfirmation: ({
    setWarningState,
    formData,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
    setFormData,
  }: BulkUpdateArgs) => void;
  resetRoutePairCustomerTargets: (
    routeCustomerTargetData: EntityListState<
      EditableEntityState<AllCustomerTargetsTableRow>
    >
  ) => void;
}

interface FieldValues {
  targetValue: string;
  grossValue: string;
}

const initialFormState: FieldValues = {
  targetValue: "",
  grossValue: "",
};

interface UseBulkUpdateProps {
  tableContext: TableContext<RoutePairCustomerTargetSortedColumnState>;
}

export const useBulkUpdateLogicHandler = ({
  tableContext,
}: UseBulkUpdateProps): BulkUpdateLogicHandlerHelpers => {
  const dispatch = useDispatch();

  const bulkShouldShowWarning = ({
    setWarningState,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
  }: BulkShowWarningArgs) => {
    setWarningState((prevState) => ({
      ...prevState,
      displayRowsWithoutGrossPriceWarning: false,
      displayAlreadyEditedWarning: false,
      numberOfAlreadyEdited: 0,
    }));

    if (checkBoxesSelected.targetSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });

      const selectedRowsWithTargetSet = compact(
        selectedRows.map((sr) => {
          if (sr.target) {
            return sr;
          }
          return undefined;
        })
      );

      if (selectedRowsWithTargetSet.length > 0) {
        setWarningState((prevState) => ({
          ...prevState,
          displayAlreadyEditedWarning: true,
          numberOfAlreadyEdited: selectedRowsWithTargetSet.length,
        }));

        return true;
      }
    } else if (checkBoxesSelected.grossSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });
      const selectedRowsWithTargetSet = compact(
        selectedRows.map((sr) => {
          if (sr.target) {
            return sr;
          }

          return undefined;
        })
      );

      if (selectedRows.some((sr) => !sr.gross)) {
        setWarningState((prevState) => ({
          ...prevState,
          displayRowsWithoutGrossPriceWarning: true,
        }));

        return true;
      }

      if (selectedRowsWithTargetSet.length > 0) {
        setWarningState((prevState) => ({
          ...prevState,
          displayAlreadyEditedWarning: true,
          numberOfAlreadyEdited: selectedRowsWithTargetSet.length,
        }));

        return true;
      }
    }
    return false;
  };

  const bulkUpdate = ({
    setWarningState,
    formData,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
    setFormData,
  }: BulkUpdateArgs) => {
    setWarningState((prevState) => ({
      ...prevState,
      displayRowsWithoutGrossPriceWarning: false,
      displayAlreadyEditedWarning: false,
      numberOfAlreadyEdited: 0,
    }));

    if (checkBoxesSelected.targetSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });

      batch(() => {
        selectedRows.forEach((rctd) => {
          if (rctd.id && selectedRowIds.includes(rctd.id)) {
            dispatch(
              customerTargetActions.setValue({
                id: rctd.id,
                target: Number(
                  cleanNumericTextFieldInput(formData.targetValue)
                ),
              })
            );
          }
        });
      });

      setWarningState((prevState) => ({
        ...prevState,
        isValidGrossInput: true,
        isValidTargetInput: true,
      }));

      setFormData(initialFormState);
      tableContext.dispatch(tableContext.actions.clearSelection());
    } else if (checkBoxesSelected.grossSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });

      batch(() => {
        selectedRows.forEach((rctd) => {
          if (rctd.gross && rctd.id) {
            const grossValue = cleanNumericTextFieldInput(formData.grossValue);
            const percentualIncreaseOfTarget =
              Number(grossValue) === 0
                ? 0
                : (Number(grossValue) / rctd.gross) * 100;

            dispatch(
              customerTargetActions.setValue({
                id: rctd.id,
                target: percentualIncreaseOfTarget,
              })
            );
          }
        });
      });

      setWarningState((prevState) => ({
        ...prevState,
        isValidGrossInput: true,
        isValidTargetInput: true,
      }));
      setFormData(initialFormState);
    }
  };

  const bulkUpdateAfterConfirmation = ({
    setWarningState,
    formData,
    selectedRowIds,
    checkBoxesSelected,
    routeCustomerTargetData,
    setFormData,
  }: BulkUpdateArgs) => {
    setWarningState((prevState) => ({
      ...prevState,
      displayRowsWithoutGrossPriceWarning: false,
      displayAlreadyEditedWarning: false,
      numberOfAlreadyEdited: 0,
    }));

    if (checkBoxesSelected.targetSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });

      batch(() => {
        selectedRows.forEach((rctd) => {
          if (rctd.id && selectedRowIds.includes(rctd.id)) {
            dispatch(
              customerTargetActions.setValue({
                id: rctd.id,
                target: Number(
                  cleanNumericTextFieldInput(formData.targetValue)
                ),
              })
            );
          }
        });
      });

      setWarningState((prevState) => ({
        ...prevState,
        isValidGrossInput: true,
        isValidTargetInput: true,
        displayAlreadyEditedWarning: false,
        displayRowsWithoutGrossPriceWarning: false,
      }));

      setFormData(initialFormState);
      tableContext.dispatch(tableContext.actions.clearSelection());
    } else if (checkBoxesSelected.grossSelected) {
      const selectedRows = [...routeCustomerTargetData].filter((rctd) => {
        return selectedRowIds.includes(rctd.id ?? "");
      });

      if (selectedRows.some((sr) => !sr.gross)) {
        setWarningState((prevState) => ({
          ...prevState,
          displayRowsWithoutGrossPriceWarning: true,
        }));

        return;
      }

      batch(() => {
        selectedRows.forEach((rctd) => {
          if (rctd.gross && rctd.id) {
            const grossValue = cleanNumericTextFieldInput(formData.grossValue);
            const percentualIncreaseOfTarget =
              Number(grossValue) === 0
                ? 0
                : (Number(grossValue) / rctd.gross) * 100;

            dispatch(
              customerTargetActions.setValue({
                id: rctd.id,
                target: percentualIncreaseOfTarget,
              })
            );
          }
        });
      });

      setWarningState((prevState) => ({
        ...prevState,
        isValidGrossInput: true,
        isValidTargetInput: true,
        displayAlreadyEditedWarning: false,
        displayRowsWithoutGrossPriceWarning: false,
      }));

      setFormData(initialFormState);
      tableContext.dispatch(tableContext.actions.clearSelection());
    }
  };

  const resetRoutePairCustomerTargets = () => {
    dispatch(customerTargetActions.revertAll());
  };

  return {
    bulkShouldShowWarning,
    bulkUpdate,
    bulkUpdateAfterConfirmation,
    resetRoutePairCustomerTargets,
  };
};
