import _ from "lodash";

import { SailingCategoryStatisticsObjectType } from "../SailingCategoryStatisticsTable/sailingCategoryStatisticsTableConfig";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { NegotiationStatusListItem } from "../config/details/StatisticsByNegotiationStatusTablesConfig";
import { NegotiationStatusItem } from "../neg-status-chip-multi-select/NegStatusChipMultiSelect";
import { Status } from "@/features/search-filter/types/FilterEntitys";
import {
  UseSalesRegionDetailsSummaryQuery,
  UseSalesRegionDetailsVolumeAndRatesQuery,
} from "@/gql/graphql";

const sortOrder = ["Peak", "Shoulder", "OffPeak", "Total"];
const sortRateAndTargetList = (array: SailingCategoryStatisticsObjectType[]) =>
  array.sort(
    (a, b) => sortOrder.indexOf(a.sailingCat) - sortOrder.indexOf(b.sailingCat)
  );

const upperCaseFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase().concat(string.slice(1));

export const getVolumeAndRateItems = (
  volumeChange:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["volumeAndRates"]["volumeChange"]["tables"]
    | undefined
) => {
  const expectedVolume: SailingCategoryStatisticsObjectType[] = [];
  const lastYearVolume: SailingCategoryStatisticsObjectType[] = [];
  if (!volumeChange) {
    return { expectedVolume, lastYearVolume };
  }
  for (let [key, values] of Object.entries(
    _.omit(volumeChange, "__typename")
  )) {
    expectedVolume.push({
      sailingCat: upperCaseFirstLetter(key),
      acc: values.acc.expected || 0,
      unacc: values.unacc.expected || 0,
      id: key + "rates",
      total: values.total.expected || 0,
    });
    sortRateAndTargetList(expectedVolume);

    lastYearVolume.push({
      sailingCat: upperCaseFirstLetter(key),
      acc: values.acc.lastYear || 0,
      unacc: values.unacc.lastYear || 0,
      id: key + "target",
      total: values.total.lastYear || 0,
    });
    sortRateAndTargetList(lastYearVolume);
  }

  return { expectedVolume, lastYearVolume };
};
export const getTargetAndRateItems = (
  rateChange:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["volumeAndRates"]["rateChange"]["tables"]
    | undefined
) => {
  const rates: SailingCategoryStatisticsObjectType[] = [];
  const targets: SailingCategoryStatisticsObjectType[] = [];
  if (!rateChange) {
    return { rates, targets };
  }

  for (let [key, values] of Object.entries(_.omit(rateChange, "__typename"))) {
    rates.push({
      sailingCat: upperCaseFirstLetter(key),
      acc: values.acc.rate || 0,
      unacc: values.unacc.rate || 0,
      id: key + "rates",
      total: values.total.rate || 0,
    });
    sortRateAndTargetList(rates);

    targets.push({
      sailingCat: upperCaseFirstLetter(key),
      acc: values.acc.target || 0,
      unacc: values.unacc.target || 0,
      id: key + "target",
      total: values.total.target || 0,
    });
    sortRateAndTargetList(targets);
  }
  return { rates, targets };
};

export const getNegotiationTypeValuesByVolume = (
  byVolume: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"]["byVolume"]
) => {
  let doneValueByVolume = 0;
  let todoValueByVolume = 0;
  let inProgressValueByVolume = 0;

  byVolume.items.forEach((item) => {
    switch (item.negotiationStatus.id) {
      case "D":
        doneValueByVolume += item.num || 0;
        break;

      case "R":
        doneValueByVolume += item.num || 0;
        break;

      case "T":
        todoValueByVolume += item.num || 0;
        break;

      default:
        inProgressValueByVolume += item.num || 0;
        break;
    }
  });

  return {
    doneValueByVolume,
    todoValueByVolume,
    inProgressValueByVolume,
  };
};

export const getNegotiationTypePercentageByVolume = (
  byVolume: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"]["byVolume"]
) => {
  const { doneValueByVolume, inProgressValueByVolume, todoValueByVolume } =
    getNegotiationTypeValuesByVolume(byVolume);
  const sum = doneValueByVolume + inProgressValueByVolume + todoValueByVolume;
  return {
    donePercentageByVolume: calculatePercentageOfSum(doneValueByVolume, sum),
    inProgressPercentageByVolume: calculatePercentageOfSum(
      inProgressValueByVolume,
      sum
    ),
    todoPercentageByVolume: calculatePercentageOfSum(todoValueByVolume, sum),
  };
};

export const getNegotiationTypeValuesByCustomer = (
  byNumber: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"]["byNumber"]
) => {
  let doneValueByCustomer = 0;
  let todoValueByCustomer = 0;
  let inprogressValueByCustomer = 0;

  byNumber.items.forEach((item) => {
    switch (item.negotiationStatus.id) {
      case "D":
        doneValueByCustomer += item.num || 0;
        break;
      case "R":
        doneValueByCustomer += item.num || 0;
        break;

      case "T":
        todoValueByCustomer += item.num || 0;
        break;

      default:
        inprogressValueByCustomer += item.num || 0;
        break;
    }
  });

  return {
    doneValueByCustomer,
    todoValueByCustomer,
    inprogressValueByCustomer,
  };
};

export const getNegotiationTypePercentageByCustomer = (
  byCustomer: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"]["byNumber"]
) => {
  const {
    doneValueByCustomer,
    inprogressValueByCustomer,
    todoValueByCustomer,
  } = getNegotiationTypeValuesByCustomer(byCustomer);
  const sum =
    doneValueByCustomer + inprogressValueByCustomer + todoValueByCustomer;
  return {
    donePercentageByCustomer: calculatePercentageOfSum(
      doneValueByCustomer,
      sum
    ),
    inProgressPercentageByCustomer: calculatePercentageOfSum(
      inprogressValueByCustomer,
      sum
    ),
    todoPercentageByCustomer: calculatePercentageOfSum(
      todoValueByCustomer,
      sum
    ),
  };
};

export const oneDecimalNoTrailingZero = (number: number) =>
  parseFloat(number.toFixed(1));
export const calculatePercentageOfSum = (part: number, sum: number) =>
  oneDecimalNoTrailingZero((part / sum) * 100);

export const percentLabelFormatterDecimalValue = (
  value: number | null | undefined
): string =>
  value === null || value === undefined
    ? "-"
    : `${
        value > 0
          ? "+" +
            dotToCommaTransform(oneDecimalNoTrailingZero(value).toString())
          : dotToCommaTransform(oneDecimalNoTrailingZero(value).toString())
      }%`;

export const avgMeterPriceLabelFormatter = (
  avgMeterPrice: number | undefined | null,
  avgMeterPriceDiffInPercent: number | undefined | null,
  currencyCode: string
): string =>
  avgMeterPrice === undefined || avgMeterPrice === null
    ? "-"
    : avgMeterPrice && avgMeterPriceDiffInPercent
    ? `${dotToCommaTransform(
        parseFloat(avgMeterPrice.toFixed(2)).toString()
      )} ${currencyCode} (${percentLabelFormatterDecimalValue(
        avgMeterPriceDiffInPercent
      )})`
    : `${dotToCommaTransform(
        parseFloat(avgMeterPrice.toFixed(2)).toString()
      )} ${currencyCode}`;

export const negotiationStatusSumFormatter = (
  value: number | undefined
): string =>
  value
    ? `${dotToCommaTransform(oneDecimalNoTrailingZero(value).toString())}%`
    : "-";

const getNegotiationStatusNameFromId = (neogitationStatusId: string) => {
  switch (neogitationStatusId) {
    case "D":
      return "done";
    case "I":
      return "inProgress";
    case "P":
      return "parked";
    case "T":
      return "todo";
    case "R":
      return "renegotiation";
    case "RO":
      return "readyToOffer";
    case "RA":
      return "readyToActivate";
    default:
      throw new Error("Unknown negotiation status");
  }
};

export const getNegotiationStatusListItemFromSummary = (
  items: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"]["byNumber"]["items"],
  year: string
) => {
  let negotiationStatusListItem = {
    id: year,
    todo: 0,
    inProgress: 0,
    parked: 0,
    readyToOffer: 0,
    readyToActivate: 0,
    done: 0,
    renegotiation: 0,
  };

  const sum = _.sumBy(items, "num");
  items.forEach((item) => {
    const num = item.num || 0;

    return _.assign(negotiationStatusListItem, {
      [getNegotiationStatusNameFromId(item.negotiationStatus.id)]:
        calculatePercentageOfSum(num, sum),
    });
  });
  return negotiationStatusListItem as NegotiationStatusListItem;
};

export const transformStatusItemsToFilterStatus = (
  statuses: NegotiationStatusItem[]
): Status[] =>
  statuses
    ? statuses.map((status) => ({
        id: status.value,
        code: status.value,
        name: status.label,
      }))
    : [];

export const currencyValue = (
  value: number | undefined | null,
  currency: string
): string =>
  value
    ? `${dotToCommaTransform(value.toFixed(2).toString())} ${currency}`
    : `-`;
