import { useMemo } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { Box, Row, Space } from "@stenajs-webui/core";
import { formatDateWithDashOnInput } from "@/common/dates/formatters";
import { DateTextInput } from "@stenajs-webui/calendar";
import { Label, stenaClock } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

export const DepartureInputSection: React.FC<{
  enabled?: boolean;
  label?: string;
}> = ({ enabled, label }) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    startTime,
    endTime,
    setEndTime,
    setStartTime,
  } = useFilterWorkspace();

  const prevStartDateLength = useMemo(() => {
    return startDate ? startDate?.length : 0;
  }, [startDate]);

  const prevEndDateLength = useMemo(() => {
    return endDate ? endDate.length : 0;
  }, [endDate]);

  return (
    <FppSearchFilterSection
      sectionId={"departureInput"}
      enabled={enabled}
      label={label ?? "Departure"}
      leftIcon={stenaClock}
    >
      <Row indent spacing={2} flexDirection="column">
        <DateTimeBox
          dateLabel={"Date (from)"}
          timeLabel={"Time (from)"}
          time={startTime}
          date={startDate}
          setDate={(v: string) => {
            const newValue = formatDateWithDashOnInput(v, prevStartDateLength);
            setStartDate(newValue);
          }}
          setTime={setStartTime}
        />
        <Space num={2} />
        <DateTimeBox
          dateLabel={"Date (to)"}
          timeLabel={"Time (to)"}
          time={endTime}
          date={endDate}
          setDate={(v: string) => {
            const newValue = formatDateWithDashOnInput(v, prevEndDateLength);
            setEndDate(newValue);
          }}
          setTime={setEndTime}
        />
      </Row>
    </FppSearchFilterSection>
  );
};

const DateTimeBox = ({
  dateLabel,
  timeLabel,
  date,
  time,
  setDate,
  setTime,
}: {
  dateLabel: string;
  timeLabel: string;
  date?: string;
  time?: string;
  setDate: (date: string) => void;
  setTime: (time: string) => void;
}) => {
  return (
    <Box flexDirection="row">
      <Label text={dateLabel}>
        <DateTextInput
          onValueChange={(v) => setDate(v)}
          value={date}
          dateFormat={"yyyy-mm-dd"}
          hideCalenderIcon
        />
      </Label>
      <Space num={2} />
      <Label text={timeLabel} width={100}>
        <TextInput
          type="time"
          value={time}
          onValueChange={setTime}
          // onDone={enableSearchButton ? undefined : commit}
        />
      </Label>
    </Box>
  );
};
