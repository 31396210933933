export const testIdConstantsWebapp = {
  globalCustomerSearch: "global-customer-search",
  loginButton: "login-button",
  logOutButton: "log-out-button",
  userMenuButton: "user-menu-button",
  navBar: "nav-bar",
  menuLinkRouteTargets: "menu-link-route-targets",
  menuLinkNegotiationPlan: "menu-link-negotiation-plan",
  menuLinkStatistics: "menu-link-statistics",
  menuLinkPriceCalculator: "menu-link-price-calculator",
  menuLinkNSLHRoutePrices: "menu-link-nslh-route-prices",
  menuLinkNSLHHandleBookings: "menu-link-nslh-handle-bookings",
  menuLinkNSLHFollowUp: "menu-link-nslh-follow-up",
  menuLinkAdminGroupFreightTargets: "menu-link-admin-group-freight-targets",
  statisticsTotalCustomersResult: "statistics-total-customers-result",
  routeTargetsOverviewNegotiationYear:
    "route-targets-overview-negotiation-year",
  negotiationPlanTotalCustomersResult:
    "negotiation-plan-total-customers-result",
  priceCalculatorSearchFieldHeader: "price-calculator-search-field-header",
  priceCalculatorCustomerSearchField: "price-calculator-customer-search-field",
  priceCalculatorSectionalRouteSelect:
    "price-calculator-sectional-route-select",
  priceCalculatorSectionalTimetableSelect:
    "price-calculator-sectional-timetable-select",
  priceCalculatorSectionalSailingCategorySelect:
    "price-calculator-sectional-sailing-category-select",
  priceCalculatorSectionalDepartureTimeInput:
    "price-calculator-sectional-departure-time-input",
  priceCalculatorCalculatePriceButton:
    "price-calculator-calculate-price-button",
  priceCalculatorResultTripInformation:
    "price-calculator-result-trip-information",
  priceCalculatorResultVehicleType: "price-calculator-result-vehicle-type",
  priceCalculatorResultPriceTable: "price-calculator-result-price-table",
  headerAgreementActionsMenu: "header-agreement-actions-menu",
  headerAgreementActionsMenuCopyAgreement:
    "header-agreement-actions-menu-copy-agreement",
  headerAgreementActionsMenuCopyToOtherCustomer:
    "header-agreement-actions-menu-copy-to-other-customer",
  headerAgreementActionsMenuEditAgreement:
    "header-agreement-actions-menu-edit-agreement",
  headerAgreementActionsMenuNewAgreement:
    "header-agreement-actions-menu-new-agreement",
  headerAgreementActionsMenuAgreementArticles:
    "header-agreement-actions-menu-agreement-articles",
  headerAgreementActionsMenuCreateOffer:
    "header-agreement-actions-menu-create-offer",
  headerAgreementActionsMenuCreateConfirmation:
    "header-agreement-actions-menu-create-confirmation",
  headerAgreementActionsMenuSetToPending:
    "header-agreement-actions-menu-set-to-pending",
  headerAgreementActionsMenuActivate: "header-agreement-actions-menu-activate",
  headerAgreementActionsMenuSetToVoid:
    "header-agreement-actions-menu-set-to-void",
  headerAgreementActionsMenuDelete: "header-agreement-actions-menu-delete",
  nameInput: "name-input",
  validityInput: "validity-input",
  createHeaderAgreementCreateButton: "create-header-agreement-create-button",
  createHeaderAgreementCancelButton: "create-header-agreement-cancel-button",
  agreementReportSpecificationsArea: "agreement-report-specification-area",
  agreementReportConditionArea: "agreement-report-condition-area",
  agreementReportLastPageArea: "agreement-report-last-page-area",
  InternalNotes: "internal-notes",
  deleteHeaderAgreementModalYesButton:
    "delete-header-agreement-modal-yes-button",
  editHeaderAgreementSaveAgreementButton:
    "edit-header-agreement-save-agreement-button",
  editHeaderAgreementCancelButton: "edit-header-agreement-cancel-button",
  newRouteAgreementButton: "new-route-agreement-button",
  createRouteAgreementModalCancelButton:
    "create-route-agreement-modal-cancel-button",
  createRouteAgreementModalCreateAgreementButton:
    "create-route-agreement-modal-create-agreement-button",
  errorMessageText: "error-message-text",
  createRouteAgreementSailingCategoryPeak:
    "create-route-agreement-sailing-category-peak",
  createRouteAgreementSailingCategoryOffpeak:
    "create-route-agreement-sailing-category-offpeak",
  createRouteAgreementSailingCategoryShoulder:
    "create-route-agreement-sailing-category-shoulder",
  createRouteAgreementCurrencySelector:
    "create-route-agreement-currency-selector",
  createRouteAgreementSelectRoute: "create-route-agreement-select-route",
  createRouteAgreementNameTextBox: "create-route-agreement-name-text-box",
  breadcrumbCustomer: "breadcrumb-customer",
  routeAgreementRatesTab: "route-agreement-rates-tab",
  routeAgreementRoutesTab: "route-agreement-routes-tab",
  createRouteRatesAddVehicleType: "create-route-rates-add-vehicle-type",
  createRouteRatesAddVehicleAddButton:
    "create-route-rates-add-vehicle-add-button",
  createRouteRatesSelectVehicleType:
    "create-route-rates-select-vehicle-add-button",
  createRouteRatesRecalculateStatistics:
    "create-route-rates-recalculate-statistics",
  routeAgreementSaveButton: "route-agreement-save-button",
  createRouteRatesAddVehicleLengthFrom: "create-route-rates-length-from",
  createRouteRatesAddVehicleLengthTo: "create-route-rates-length-to",
  createRouteRatesAddVehicleWeightFrom: "create-route-rates-weight-from",
  createRouteRatesAddVehicleWeightTo: "create-route-rates-weight-to",
  createRouteRatesErrorBanner: "create-route-rates-error-banner",
  routeAgreementDiscardButton: "route-agreement-discard-button",
  routePriceAddNewValidFromDateYearSelect:
    "route-price-add-new-valid-from-date-year-select",
  routePriceIncludePriceRadioButton: "route-price-include-price-radio-button",
  routePriceDoNotIncludePriceRadioButton:
    "route-price-do-not-include-price-radio-button",
  routePriceAddNewValidFromDateConfirmButton:
    "route-price-add-new-valid-from-date-button-confirm",
  routePriceDeleteNewValidFromDateConfirmButton:
    "route-price-delete-new-valid-from-date-button-confirm",
};
