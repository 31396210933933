import { ItemByRoutePairCodesForCustomersFragment } from "@/gql/graphql";
import { BulkEditRatesStatBoxQueryData } from "../redux/reducer";

export const mergeBulkEditRatesStatBoxQueryData = (
  startData: BulkEditRatesStatBoxQueryData,
  itemsToMerge: Array<ItemByRoutePairCodesForCustomersFragment>
): BulkEditRatesStatBoxQueryData => {
  return itemsToMerge.reduce<BulkEditRatesStatBoxQueryData>((sum, item) => {
    return {
      ...sum,
      [item.id]: item,
    };
  }, startData);
};
