import React from "react";
import { useParams } from "react-router-dom";
import { ErrorScreen } from "@stenajs-webui/panels";
import { CustomerMultilegRouteFetcherWrapper } from "./CustomerMultilegRouteFetcherWrapper";
import { Helmet } from "react-helmet";

interface Props {}

export const CustomerMultilegRoutePairScreen: React.FC<Props> = () => {
  const { headAgreementId, customerId, multiLegAgreementId } = useParams() as {
    headAgreementId: string;
    customerId: string;
    multiLegAgreementId: string;
  };
  if (!customerId) {
    return <ErrorScreen text={"Missing custIndex."} />;
  }
  if (!multiLegAgreementId) {
    return <ErrorScreen text={"Missing multi leg id."} />;
  }
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }

  return (
    <>
      <Helmet title="Customer@Route - Multi leg - Customer - FPP" />
      <CustomerMultilegRouteFetcherWrapper
        customerId={customerId}
        headAgreementId={headAgreementId}
        multiLegAgreementId={multiLegAgreementId}
      />
    </>
  );
};
