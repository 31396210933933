import React from "react";
import { Spacing, Text } from "@stenajs-webui/core";

const transformedAuditText = (parsedString: string, index: number) => {
  const asterisks = "***************";
  if (parsedString.substr(0, asterisks.length) === asterisks) {
    const searchRegExp = new RegExp("\\*", "g");
    const textWithoutAsteriks = parsedString.replace(searchRegExp, "");
    return (
      <Spacing key={index}>
        <Text variant={"bold"}>{textWithoutAsteriks}</Text>
      </Spacing>
    );
  }
  return <Text key={index}>{parsedString}</Text>;
};

export const transformAuditTrackStringToDisplayableJsx = (
  auditTrackText: string
) => {
  return auditTrackText
    .split("\r\n")
    .map((str, index) => transformedAuditText(str, index));
};
