import { useQuery } from "@apollo/client";
import {
  SurchargeDocumentsQuery,
  SurchargeDocumentsQueryVariables,
} from "@/gql/graphql";
import { surchargeDocumentsQuery } from "@/features/admin/upload-surcharge-documents/SurchargeDocumentsView";

interface FilterSurchargeProps {
  headerAgreementValidFromYear: number;
  headerAgreementValidToYear: number;
  surchargeDocuments: SurchargeDocumentsQuery["productPrice"]["additionalCharges"]["all"];
}

interface isSurchargeValidForHeaderAgreementInput {
  headerAgreementValidFromYear: number;
  headerAgreementValidToYear: number;
  surchargeYear: number;
}
export const isSurchargeValidForHeaderAgreement = ({
  headerAgreementValidFromYear,
  headerAgreementValidToYear,
  surchargeYear,
}: isSurchargeValidForHeaderAgreementInput) =>
  headerAgreementValidFromYear <= surchargeYear &&
  headerAgreementValidToYear >= surchargeYear;

export const filterAdditionalSurchargeDocuments = ({
  surchargeDocuments,
  headerAgreementValidFromYear,
  headerAgreementValidToYear,
}: FilterSurchargeProps) =>
  surchargeDocuments.filter((doc) => {
    const surchargeYear = Number(doc.validFrom?.split("-").at(0));
    return isSurchargeValidForHeaderAgreement({
      surchargeYear,
      headerAgreementValidFromYear,
      headerAgreementValidToYear,
    });
  });

export const useAdditionalSurchargeDocuments = (
  yearValidFrom: number,
  yearValidTo: number
) => {
  const { data, loading, error } = useQuery<
    SurchargeDocumentsQuery,
    SurchargeDocumentsQueryVariables
  >(surchargeDocumentsQuery);

  const additionalSurchargeDocuments = filterAdditionalSurchargeDocuments({
    surchargeDocuments: data?.productPrice.additionalCharges.all ?? [],
    headerAgreementValidFromYear: yearValidFrom,
    headerAgreementValidToYear: yearValidTo,
  });

  return {
    additionalSurchargeDocuments,
    loading,
    error,
  };
};
