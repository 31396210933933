import * as React from "react";
import { useCallback } from "react";
import {
  ActionMenu,
  ActionMenuItem,
  FlatButton,
  stenaDots,
} from "@stenajs-webui/elements";
import { Row, Space } from "@stenajs-webui/core";
import { useDispatch } from "react-redux";
import { rateSheetStandardTableWrapperActions } from "../redux";
import { rateSheetTableActions } from "../actions";
import { RateSheetTableRowState } from "../reducer";
import {
  Tippy,
  tippyStyles,
  useTippyInstance,
} from "../../../../common/components/tooltip/tippy";
import { EditRateRowButton } from "../edit-rate-row/component/EditRateRowButton";

interface Props {
  currencyLabel?: string;
  routeAgreementId: string;
  routeAgreementPriceId: string;
  rateSheetTableRowState: RateSheetTableRowState;
  editableRouteAgreement: boolean;
  disableStatBoxes?: boolean;
}

export const RateSheetActionButtons: React.FC<Props> = ({
  routeAgreementId,
  routeAgreementPriceId,
  rateSheetTableRowState,
  currencyLabel,
  editableRouteAgreement,
  disableStatBoxes,
}) => {
  const dispatch = useDispatch();
  const [tippyRef, tippyInstance] = useTippyInstance<HTMLDivElement>();

  const onDeleteHandler = useCallback(() => {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.markPriceAsDeleted(
          rateSheetTableRowState.routeAgreementPriceId
        )
      )
    );
  }, [
    dispatch,
    routeAgreementId,
    rateSheetTableRowState.routeAgreementPriceId,
  ]);

  const onUndeleteHandler = useCallback(() => {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.removeDeletedMark(
          rateSheetTableRowState.routeAgreementPriceId
        )
      )
    );
  }, [
    dispatch,
    routeAgreementId,
    rateSheetTableRowState.routeAgreementPriceId,
  ]);

  const onCopyHandler = useCallback(() => {
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        rateSheetTableActions.makeCopyOfRow(
          rateSheetTableRowState.routeAgreementPriceId
        )
      )
    );
  }, [
    dispatch,
    routeAgreementId,
    rateSheetTableRowState.routeAgreementPriceId,
  ]);

  const actionsDisabled = !editableRouteAgreement;

  return (
    <Row>
      <EditRateRowButton
        rateSheetTableRowState={rateSheetTableRowState}
        currencyLabel={currencyLabel}
        routeAgreementId={routeAgreementId}
        routeAgreementPriceId={routeAgreementPriceId}
        editableRouteAgreement={editableRouteAgreement}
        disableStatBoxes={disableStatBoxes}
      />
      <Space />
      <Tippy
        interactive
        placement={"bottom"}
        className={tippyStyles.noPadding}
        trigger={"click"}
        theme={"light"}
        disabled={actionsDisabled}
        content={
          <ActionMenu>
            <ActionMenuItem
              label={"Copy vehicle"}
              onClick={() => {
                onCopyHandler();
                tippyInstance.current?.hide();
              }}
            />
            {rateSheetTableRowState.deleted ? (
              <ActionMenuItem
                label={"Undo delete"}
                onClick={() => {
                  onUndeleteHandler();
                  tippyInstance.current?.hide();
                }}
              />
            ) : (
              <ActionMenuItem
                label={"Delete vehicle"}
                onClick={() => {
                  onDeleteHandler();
                  tippyInstance.current?.hide();
                }}
              />
            )}
          </ActionMenu>
        }
      >
        <div ref={tippyRef}>
          <FlatButton leftIcon={stenaDots} disabled={actionsDisabled} />
        </div>
      </Tippy>
    </Row>
  );
};
