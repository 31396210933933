import { InfoBoxItem } from "../InfoBoxItem";

export const CustomerBaseInformation: React.FC<{
  custIndex: string;
  custNo: number;
  custStatNo: number | string | null;
}> = ({ custIndex, custNo, custStatNo }) => (
  <>
    <InfoBoxItem leftContent={"Index"} rightContent={custIndex} />
    <InfoBoxItem leftContent={"No."} rightContent={custNo} />
    <InfoBoxItem leftContent={"Stat. No"} rightContent={custStatNo} />
  </>
);
