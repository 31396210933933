import React from "react";
import { useParams } from "react-router-dom";
import { OverviewScreenWrapper } from "./overview/OverviewScreenWrapper";
import { SearchInvoicesScreen } from "./search-invoices/SearchInvoicesScreen";
import { NslhInvoiceTab } from "./config/tabConfig";

interface Props {}

export const NslhFollowUpWrapper: React.FC<Props> = () => {
  const { tab } = useParams() as { tab: string };

  if (!tab || tab === NslhInvoiceTab.overview) {
    return <OverviewScreenWrapper />;
  }

  return <SearchInvoicesScreen />;
};
