import { gql, useQuery } from "@apollo/client";
import { SalesRegion } from "../../types/FilterEntitys";
import { SalesRegionFilterQuery } from "@/gql/graphql";

export const salesRegionFilterQuery = gql`
  query SalesRegionFilter {
    productPrice {
      salesRegion {
        all {
          id
          name
          shortName
        }
      }
    }
  }
`;

export const useFetchAllRegions = () => {
  const { data, error, loading, refetch } = useQuery<SalesRegionFilterQuery>(
    salesRegionFilterQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  const salesRegions =
    data?.productPrice.salesRegion.all.map<SalesRegion>((r) => ({
      id: r.id,
      code: r.shortName,
      name: `${r.shortName.toUpperCase()} - ${r.name}`,
    })) ?? [];

  return { salesRegions, error, loading, refetch };
};
