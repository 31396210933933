import * as React from "react";
import { css, Global } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { routeCustomerTargetActions } from "../features/route-pair-target-table/redux/actions";
import { RouteTargetPageWrapper } from "./RouteTargetPageWrapper";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export const RouteTargetScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { routePairCode } = useParams() as { routePairCode: string };
  useEffect(() => {
    dispatch(
      routeCustomerTargetActions.setRouteCustomerTargetState({
        bannerState: undefined,
      })
    );
  });

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title={`Route targets - ${routePairCode}`} />
      <RouteTargetPageWrapper />
    </>
  );
};
