import {
  createInternalStandardTableActions,
  createStandardTableActions,
  createStandardTableInitialState,
  createStandardTableReducer,
  StandardTableState,
} from "@stenajs-webui/grid";
import { combineReducers } from "redux";
import { CustomerListTableColumnKeys } from "./config/CustomerListTableConfig";
import {
  createFppSearchFilterReducer,
  SearchFilterState,
} from "@/features/search-filter/redux/reducer";

export interface CustomerListTableState {
  standardTable: StandardTableState<CustomerListTableColumnKeys>;
}

export const customerListStandardTableId = "customerListStandardTable";
export const customerListStandardTableActions = createStandardTableActions(
  customerListStandardTableId,
  createInternalStandardTableActions<CustomerListTableColumnKeys>()
);

export const customerListStandardTableInitialState =
  createStandardTableInitialState<CustomerListTableColumnKeys>("name", false);

export interface CustomerListState {
  filter: SearchFilterState;
  standardTable: StandardTableState<CustomerListTableColumnKeys>;
}

export const customerListReducer = combineReducers<CustomerListState>({
  filter: createFppSearchFilterReducer("customerList"),
  standardTable: createStandardTableReducer<CustomerListTableColumnKeys>(
    customerListStandardTableId,
    customerListStandardTableInitialState
  ),
});
