import { DateInput } from "@/common/components/date-input/DateInput";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { parseDate } from "@internationalized/date";
import { Indent } from "@stenajs-webui/core";
import { UseGridCellResult } from "@stenajs-webui/grid";
import {
  EditableEntityState,
  ModifiedFieldItemState,
} from "@stenajs-webui/redux";
import { cssColor } from "@stenajs-webui/theme";
import { isValid } from "date-fns";
import { isEqual } from "lodash";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { agreementArticlesActions } from "../../redux/reducer";
import { AgreementArticlesTableItem } from "../../types";
import {
  isBeforeTomorrow,
  validFromDateError,
} from "../bulk-update/validators/validators";
import { AgreementArticlesModifiedField } from "./AgreementArticlesModifiedField";
import { CreateAgreementArticlePriceItemInput } from "@/gql/graphql";

export type EditableAgreementArticlesTableFields = "newPrice" | "newValidFrom";

interface AgreementArticlesTableCellProps {
  entityId: string;
  value: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  field: EditableAgreementArticlesTableFields;
  persistedValue: string;
  editableValue: string;
  tableRowErrors?: CreateAgreementArticlePriceItemInput[];
  agreementArticles?: EditableEntityState<AgreementArticlesTableItem>[];
}

export const AgreementArticlesTableCell: React.FC<
  AgreementArticlesTableCellProps
> = ({
  entityId,
  value,
  isEditable,
  gridCell,
  persistedValue,
  editableValue,
  field,
  tableRowErrors,
  agreementArticles,
}) => {
  const dispatch = useDispatch();
  const [keyDown, setKeyDown] = useState("");

  const editableItem = agreementArticles?.find(
    (i) => i.id === entityId
  )?.editable;
  const persistedItem = agreementArticles?.find(
    (i) => i.id === entityId
  )?.persisted;
  const hasNoChanges = isEqual(editableItem, persistedItem);

  const modifiedField: ModifiedFieldItemState =
    editableValue === persistedValue
      ? { id: "" }
      : {
          id: "",
          modified: true,
          newValue: value,
          originalValue: dotToCommaTransform(persistedValue),
        };

  const renderBorderError = () => {
    const tableItemError = tableRowErrors?.find(
      (rowItem) => rowItem.agrRouteArticlePriceId === entityId
    );
    if (!hasNoChanges && tableItemError) {
      if (
        (field === "newPrice" &&
          tableItemError?.changes.price === null &&
          gridCell.editorValue === "") ||
        (field === "newPrice" &&
          tableItemError?.changes.price?.value.amount &&
          gridCell.editorValue === "")
      ) {
        return `1px solid ${cssColor("--lhds-color-red-500")}`;
      } else if (
        (field === "newValidFrom" &&
          tableItemError?.changes.validFrom === null &&
          gridCell.editorValue === "") ||
        (field === "newValidFrom" &&
          tableItemError?.changes.validFrom?.value &&
          !isValid(new Date(gridCell.editorValue))) ||
        isBeforeTomorrow(gridCell.editorValue)
      ) {
        return `1px solid ${cssColor("--lhds-color-red-500")}`;
      }
    }
  };

  const onKeyDown = (key: string) => {
    setKeyDown(key);
    return key === "Enter"
      ? onDone()
      : key === "Escape"
      ? setTimeout(() => gridCell.stopEditingAndRevert(), 1)
      : null;
  };

  const onDone = useCallback(() => {
    dispatch(
      agreementArticlesActions.setValue({
        id: entityId,
        fields: {
          [field]: gridCell.editorValue,
        },
      })
    );
    if (hasNoChanges && tableRowErrors && tableRowErrors?.length >= 1) {
      dispatch(
        agreementArticlesActions.clearTableRowError({
          id: entityId,
        })
      );
    }
    gridCell.stopEditingAndMove("right");
  }, [dispatch, field, entityId, gridCell, hasNoChanges, tableRowErrors]);

  const currentValue =
    gridCell.editorValue && field === "newValidFrom"
      ? formatDateStringWithSwedishTimeZone({
          dateString: gridCell.editorValue,
        })
      : "Not set"
      ? gridCell.editorValue
      : "Not set";

  return isEditable && gridCell.isEditing ? (
    <Indent row alignItems={"center"}>
      {field === "newPrice" ? (
        <NumericTextInput
          onValueChange={(value) =>
            gridCell.setEditorValue(dotToCommaTransform(value))
          }
          useNegativeValues
          maxDecimals={2}
          value={dotToCommaTransform(gridCell.editorValue)}
          onDone={onDone}
          onEsc={gridCell.stopEditingAndRevert}
          autoFocus
          selectAllOnMount={!gridCell.lastKeyEvent}
        />
      ) : (
        <DateInput
          defaultValue={
            gridCell.editorValue && isValid(new Date(gridCell.editorValue))
              ? parseDate(gridCell.editorValue)
              : undefined
          }
          onChange={(value) =>
            gridCell.setEditorValue(value === null ? "" : value.toString())
          }
          autoFocus
          onKeyDown={(event) => onKeyDown(event.key)}
          onBlur={() => {
            keyDown !== "Escape" && onDone();
          }}
        />
      )}
    </Indent>
  ) : (
    <Indent
      row
      alignItems={"center"}
      border={renderBorderError()}
      height={"100%"}
      width={"100%"}
    >
      <AgreementArticlesModifiedField
        value={dotToCommaTransform(currentValue)}
        modifiedField={modifiedField}
        isEditable={isEditable}
        error={
          field === "newValidFrom" && validFromDateError(gridCell.editorValue)
        }
      />
    </Indent>
  );
};
