import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { ByRouteTypesFragment, RouteSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  routeTypes?: string[];
  excludeOnlyAsPartOfMultiLegRoutes?: boolean;
  includeNotValid?: boolean;
  onValueChange?: (value: string) => void;
  labelFormat?: (route: ByRouteTypesFragment) => string;
}

const query = gql`
  fragment ByRouteTypes on Route {
    id
    code
    name
    routeType
    routePair {
      id
      code
      seaArea {
        id
        name
      }
    }
  }
  query RouteSelect(
    $routeTypes: [String!]!
    $excludeOnlyAsPartOfMultiLegRoutes: Boolean!
    $includeNotValid: Boolean!
  ) {
    productPrice {
      route {
        byRouteTypes(
          routeTypes: $routeTypes
          excludeOnlyAsPartOfMultiLegRoutes: $excludeOnlyAsPartOfMultiLegRoutes
          includeNotValid: $includeNotValid
        ) {
          ...ByRouteTypes
        }
      }
    }
  }
`;

export const RouteSelect: React.FC<Props> = ({
  value,
  routeTypes,
  excludeOnlyAsPartOfMultiLegRoutes,
  includeNotValid,
  onValueChange,
  labelFormat,
  ...selectProps
}) => {
  const { data, loading } = useQuery<RouteSelectQuery>(query, {
    variables: {
      routeTypes: routeTypes ?? ["O", "P"],
      excludeOnlyAsPartOfMultiLegRoutes:
        excludeOnlyAsPartOfMultiLegRoutes ?? true,
      includeNotValid: includeNotValid ?? false,
    },
    fetchPolicy: "network-only",
  });

  const routes = useMemo(() => {
    return data?.productPrice.route.byRouteTypes ?? [];
  }, [data]);

  const options = useMemo(() => {
    return routes.map((r) => ({
      label: labelFormat ? labelFormat(r) : r.code + " - " + r.name,
      value: r.id,
    }));
  }, [routes, labelFormat]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected || null}
      onChange={(v: any) => onValueChange?.(v.value)}
      {...selectProps}
    />
  );
};
