import { EditableEntityState, EntityListState } from "@stenajs-webui/redux";
import { groupBy } from "lodash";
import { NoShowLateHandlingPricesRow } from "../config/RowsAndColumnsConfig";

export const createValidFromValidator = (
  allNoShowHandlingPrices: EntityListState<
    EditableEntityState<NoShowLateHandlingPricesRow>
  >,
  routePairCode: string,
  yearOrMonth: { year: number; month: number }
) => {
  const allNoShowHandlingIds = allNoShowHandlingPrices.map(
    (item) => item.persisted.id
  );

  const groupedIdsByRoutePairCode = groupBy(allNoShowHandlingIds, (group) =>
    group?.slice(0, 3)
  );

  const routePairCodesAndValidFromDates = Object.entries(
    groupedIdsByRoutePairCode
  ).map((item) => {
    return {
      routePairCode: item[0],
      validFrom: item[1],
    };
  });

  const isCurrentlySelectedRoutePairCode =
    routePairCodesAndValidFromDates.filter(
      (selected) => selected.routePairCode === routePairCode
    );

  const getValidFromDatesBySelectedRoutePairCode =
    isCurrentlySelectedRoutePairCode.flatMap((validFromDates) => {
      return validFromDates.validFrom.map((validFrom) => {
        return {
          year: parseInt(validFrom.slice(4, 8)),
          month: parseInt(validFrom.slice(9, 11)),
        };
      });
    });

  const validFromAlreadyExists = getValidFromDatesBySelectedRoutePairCode.find(
    (validFrom) =>
      validFrom.year === yearOrMonth.year &&
      validFrom.month === yearOrMonth.month
  );

  return validFromAlreadyExists ? true : false;
};
