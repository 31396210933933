import { NumericAgrVehicleLengthTextInput } from "@/common/components/numeric-agr-vehicle-length-text-input/NumericAgrVehicleLengthTextInput";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { Column, Indent, Row, Space } from "@stenajs-webui/core";
import {
  Icon,
  Label,
  PrimaryButton,
  stenaArrowWideRight,
} from "@stenajs-webui/elements";
import * as React from "react";
import { AccUnaccSelect } from "../../../../../common/components/data-driven-inputs/selects/acc-unacc-select/AccUnaccSelect";
import { CargoStatusSelect } from "../../../../../common/components/data-driven-inputs/selects/cargo-status-select/CargoStatusSelect";
import { VehicleTypeWithAllSelect } from "../../../../../common/components/data-driven-inputs/selects/vehicle-type-with-all-select/VehicleTypeWithAllSelect";
import { FormModelCallbackChangeHandler } from "../../../../../common/forms/types";
import {
  transformCargoStatusCodeToEntity,
  transformCargoStatusEntityToCargoStatusCode,
} from "../../../../../common/transformers/CargoStatusTransformer";
import {
  transformAccUnaccCodeToEntity,
  transformAccUnaccEntityToCode,
} from "../../../../../common/transformers/UnaccompaniedTransformer";
import { EditRateFormModel } from "../models/EditRateFormModel";

interface Props {
  value: EditRateFormModel;
  onValueChange: FormModelCallbackChangeHandler<EditRateFormModel>;
  onClickApplyChanges: () => void;
  loading: boolean;
}

export const EditRate: React.FC<Props> = ({
  value,
  onValueChange,
  onClickApplyChanges,
  loading = false,
}) => {
  return (
    <Column indent={2} spacing={2}>
      <Label text={"Select vehicle type"}>
        <VehicleTypeWithAllSelect
          value={value.vehicleTypeId}
          onValueChange={(vehicleTypeId) =>
            onValueChange((oldValue) => ({ ...oldValue, vehicleTypeId }))
          }
        />
      </Label>
      <Space num={2} />
      <Label text={"Length"}>
        <Row alignItems={"center"}>
          <NumericAgrVehicleLengthTextInput
            value={dotToCommaTransform(value.lengthFrom)}
            onValueChange={(lengthFrom) =>
              onValueChange((oldValue) => ({ ...oldValue, lengthFrom }))
            }
          />
          <Indent>
            <Icon icon={stenaArrowWideRight} size={12} />
          </Indent>
          <NumericAgrVehicleLengthTextInput
            value={dotToCommaTransform(value.lengthTo)}
            onValueChange={(lengthTo) =>
              onValueChange((oldValue) => ({ ...oldValue, lengthTo }))
            }
          />
        </Row>
      </Label>
      <Space num={2} />
      <Label text={"Weight"}>
        <Row alignItems={"center"}>
          <NumericAgrVehicleWeightTextInput
            value={value.weightFrom}
            onValueChange={(weightFrom) =>
              onValueChange((oldValue) => ({ ...oldValue, weightFrom }))
            }
          />
          <Indent>
            <Icon icon={stenaArrowWideRight} size={12} />
          </Indent>
          <NumericAgrVehicleWeightTextInput
            value={value.weightTo}
            onValueChange={(weightTo) =>
              onValueChange((oldValue) => ({ ...oldValue, weightTo }))
            }
          />
        </Row>
      </Label>

      <Space num={2} />

      <Label text={"ELB"}>
        <CargoStatusSelect
          value={transformCargoStatusEntityToCargoStatusCode(value)}
          onValueChange={(v) => {
            onValueChange((oldValue) => ({
              ...oldValue,
              ...transformCargoStatusCodeToEntity(v),
            }));
          }}
        />
      </Label>

      <Space num={2} />

      <Label text={"Acc/Unacc"}>
        <AccUnaccSelect
          value={transformAccUnaccEntityToCode(value)}
          onValueChange={(v) =>
            onValueChange({ ...value, ...transformAccUnaccCodeToEntity(v) })
          }
        />
      </Label>

      <Space num={4} />

      <Row justifyContent={"center"}>
        <PrimaryButton
          label={"Apply changes"}
          onClick={onClickApplyChanges}
          loading={loading}
        />
      </Row>
    </Column>
  );
};
