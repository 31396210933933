import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { Box, Column, Row, Text } from "@stenajs-webui/core";
import { Icon, stenaArrowRight } from "@stenajs-webui/elements";
import { ReactElement } from "react";

interface Props<TValue> {
  labelFrom?: string;
  labelTo?: string;
  value: TValue;
  valueFrom?: number;
  valueTo?: number;
  onValueChange?: (value: TValue) => void;
  maxDecimals?: number;
  maxNumbers?: number;
}

export function RangeControl<TValue>({
  labelFrom,
  labelTo,
  onValueChange,
  value,
  valueFrom,
  valueTo,
  maxDecimals,
  maxNumbers,
}: Props<TValue>): ReactElement | null {
  const onTextInputValueChange = (
    variableName: string,
    inputValue?: number
  ) => {
    onValueChange && onValueChange({ ...value, [variableName]: inputValue });
  };

  return (
    <>
      <Row alignItems={"center"}>
        <Column>
          {labelFrom && <Text size={"small"}>{labelFrom}</Text>}
          <NumericTextInput
            value={typeof valueFrom !== "undefined" ? String(valueFrom) : ""}
            onValueChange={(valueFrom) =>
              onTextInputValueChange(
                "from",
                valueFrom ? Number(valueFrom) : undefined
              )
            }
            maxDecimals={maxDecimals ?? 0}
            maxNumbers={maxNumbers ?? 3}
          />
        </Column>
        <Column height={"100%"} justifyContent={"flex-end"}>
          <Box height={"38px"} justifyContent={"center"} indent>
            <Icon icon={stenaArrowRight} />
          </Box>
        </Column>
        <Column>
          {labelTo && <Text size={"small"}>{labelTo}</Text>}
          <NumericTextInput
            value={typeof valueTo !== "undefined" ? String(valueTo) : ""}
            onValueChange={(valueTo) =>
              onTextInputValueChange(
                "to",
                valueTo ? Number(valueTo) : undefined
              )
            }
            maxDecimals={maxDecimals ?? 0}
            maxNumbers={maxNumbers ?? 3}
          />
        </Column>
      </Row>
    </>
  );
}
