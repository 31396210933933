import * as React from "react";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingPanel } from "@stenajs-webui/panels";
import { useHeadAgreementById } from "../hooks/UseHeadAgreementById";
import { ExistingAgreementListBox } from "../features/existing-agreement/components/ExistingAgreementListBox";

interface Props {
  customerId: string;
  headAgreementId: string;
  year: number;
}

export const RouteAgreementList: React.FC<Props> = React.memo(
  ({ customerId, headAgreementId, year }) => {
    const { headAgreement, loading, error } = useHeadAgreementById(
      headAgreementId,
      customerId,
      year
    );

    const routeAgreements = headAgreement?.routeAgreements ?? [];
    const multiLegAgreements = headAgreement?.multiLegAgrRoutes ?? [];

    const filteredRouteAgreementsWithoutMultiLegId = routeAgreements.filter(
      (ra) => !ra.multiLegAgrRouteId
    );

    const showSpinner = loading && headAgreementId !== headAgreement?.id;

    if (showSpinner) {
      return (
        <Spacing num={6}>
          <LoadingPanel />
        </Spacing>
      );
    }

    if (error) {
      return (
        <Spacing num={6}>
          <ErrorScreen text={error.message} />
        </Spacing>
      );
    }

    if (!headAgreement) {
      return (
        <Spacing num={6}>
          <ErrorScreen text={"No such header agreement."} />
        </Spacing>
      );
    }

    return (
      <ExistingAgreementListBox
        customerId={customerId}
        routeAgreements={filteredRouteAgreementsWithoutMultiLegId}
        multiLegAgreements={multiLegAgreements}
        headAgreementId={headAgreementId}
        year={year}
      />
    );
  }
);
