import { AllNoShowLateHandlingPricesFragment } from "@/gql/graphql";
import { chain } from "lodash";

export interface NsLhRowData {
  routePairCode: string;
  siblings: AllNoShowLateHandlingPricesFragment[];
}

export const transformNoShowLateHandlingRoutePricesQueryToNoShowLateHandlingPrices =
  (
    noShowLateHandlingPricesRows:
      | AllNoShowLateHandlingPricesFragment[]
      | undefined
  ): Array<NsLhRowData> => {
    if (noShowLateHandlingPricesRows) {
      return chain(noShowLateHandlingPricesRows)
        .groupBy("routePairCode")
        .map((value, key) => ({
          routePairCode: key,
          siblings: value.sort(
            (a, b) => Date.parse(b.validFrom) - Date.parse(a.validFrom)
          ),
        }))
        .value();
    }

    return [];
  };

export const transformNoShowLateHandlingSiblingsRowToNoShowLateHandlingOption =
  (siblingsRow: AllNoShowLateHandlingPricesFragment) => {
    return { value: siblingsRow.id, label: siblingsRow.validFrom };
  };
