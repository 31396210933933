import { Row } from "@stenajs-webui/core";
import { FlatButton, stenaTrash } from "@stenajs-webui/elements";
import * as React from "react";
import { useState } from "react";
import { RouteAgreement } from "../../../../../../customers/customer-details/types";
import { ConfirmRouteAgreementDeleteModal } from "../ConfirmRouteAgreementDeleteModal";

interface Props {
  routeAgreement: RouteAgreement;
  year: number;
}

export const MultiLegAgreementRouteAgreementDelete: React.FC<Props> = ({
  routeAgreement,
  year,
}) => {
  const {
    headAgreement: { id: headAgreementId },
  } = routeAgreement;
  const [modalState, setModalState] = useState<"closed" | "confirmDelete">(
    "closed"
  );

  const closeModal = () => {
    setModalState("closed");
  };

  return (
    <Row>
      {modalState === "confirmDelete" && (
        <ConfirmRouteAgreementDeleteModal
          onRequestClose={closeModal}
          routeAgreement={routeAgreement}
          year={year}
          headAgreementId={headAgreementId}
        />
      )}
      <FlatButton
        size={"large"}
        leftIcon={stenaTrash}
        onClick={() => {
          setModalState("confirmDelete");
        }}
      />
    </Row>
  );
};
