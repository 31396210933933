import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Indent, Row, Space, Spacing, Txt } from "@stenajs-webui/core";
import { CheckboxWithLabel, RadioButtonWithLabel } from "@stenajs-webui/forms";
import { FormattingSize, ReportOptionProps } from "../common/types";

export const ReportOptions = ({
  reportOptions,
  setReportOptions,
}: ReportOptionProps) => {
  const onSmallSelected = () => {
    setReportOptions({
      ...reportOptions,
      formattingSize: FormattingSize.Small,
      showSeaAreaHeader: true,
      includeArticle: false,
      includeMatrix: false,
      showAcc: false,
      showLoadedStatus: false,
    });
  };

  const onSmallversionChecked =
    reportOptions.formattingSize === FormattingSize.Small;

  return (
    <>
      <Spacing num={SpaceValues.SIXTEEN} />
      <Row>
        <Indent num={IndentValues.TWELVE} />
        <Box>
          <Txt>Layout </Txt>
          <Space num={SpaceValues.SIXTEEN} />
          <Row>
            <RadioButtonWithLabel
              label={"Small"}
              checked={reportOptions.formattingSize === FormattingSize.Small}
              onValueChange={onSmallSelected}
            />
            <Indent />
            <RadioButtonWithLabel
              label={"Large"}
              checked={reportOptions.formattingSize === FormattingSize.Large}
              onValueChange={() =>
                setReportOptions({
                  ...reportOptions,
                  formattingSize: FormattingSize.Large,
                })
              }
            />
          </Row>
        </Box>
        <Indent num={8} />
        <Box>
          <Txt>Include</Txt>
          <Space num={SpaceValues.SIXTEEN} />
          <Row>
            <CheckboxWithLabel
              checked={reportOptions.includeArticle}
              onValueChange={() =>
                setReportOptions({
                  ...reportOptions,
                  includeArticle: !reportOptions.includeArticle,
                })
              }
              label={"Articles"}
              disabled={onSmallversionChecked === true}
            />
            <Indent num={IndentValues.SIXTEEN} />
            <CheckboxWithLabel
              checked={reportOptions.includeMatrix}
              onValueChange={() =>
                setReportOptions({
                  ...reportOptions,
                  includeMatrix: !reportOptions.includeMatrix,
                })
              }
              label={"Matrix"}
              disabled={onSmallversionChecked === true}
            />
            <Indent num={IndentValues.SIXTEEN} />
            <CheckboxWithLabel
              checked={reportOptions.showAcc}
              onValueChange={() =>
                setReportOptions({
                  ...reportOptions,
                  showAcc: !reportOptions.showAcc,
                })
              }
              label={"Accompanied/Unaccompanied"}
              disabled={onSmallversionChecked === true}
            />
            <Indent num={IndentValues.SIXTEEN} />
            <CheckboxWithLabel
              checked={reportOptions.showLoadedStatus}
              onValueChange={() =>
                setReportOptions({
                  ...reportOptions,
                  showLoadedStatus: !reportOptions.showLoadedStatus,
                })
              }
              label={"Empty/Loaded"}
              disabled={onSmallversionChecked === true}
            />
          </Row>
        </Box>
      </Row>
      <Space num={SpacingValues.EIGHT} />
    </>
  );
};
