import { Box } from "@stenajs-webui/core";
import { Drawer } from "@stenajs-webui/modal";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { DrawerHeader } from "../../../../../common/components/drawer/DrawerHeader";
import * as React from "react";
import { BulkEditRatePrice } from "../types";
import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { ZIndex } from "@/common/utils/ZIndexEnum";

interface Props {
  routeAgreementRatesByFilter: BulkEditRatePrice;
  isOpen: boolean;
  close: () => void;
}

export const BulkEditRatesDetailView: React.FC<Props> = ({
  routeAgreementRatesByFilter,
  isOpen,
  close,
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      background={cssColor("--lhds-color-ui-50")}
      slideFrom={"right"}
      onRequestClose={close}
      zIndex={ZIndex.highest}
      width={"400px"}
    >
      <Box>
        <DrawerHeader label={"Route agreement info"} onClickClose={close} />
        <Box indent={2}>
          <InfoPair
            leftContent={"Number"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={
              routeAgreementRatesByFilter?.routeAgreement.agreementNumber
            }
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Status"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={
              <HeadAgreementStatusCodeChip
                statusCode={
                  routeAgreementRatesByFilter?.routeAgreement.statusCode
                }
                size={"small"}
              />
            }
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Route"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={
              routeAgreementRatesByFilter?.routeAgreement.routes[0].routePair
                .code
            }
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Directions"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={
              routeAgreementRatesByFilter?.routeAgreement.routeDescription
            }
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Name"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={routeAgreementRatesByFilter?.routeAgreement.name}
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Validity"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={`${routeAgreementRatesByFilter?.routeAgreement.valid.start?.isoString} - ${routeAgreementRatesByFilter?.routeAgreement.valid.end?.isoString}`}
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Currency"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={
              routeAgreementRatesByFilter?.routeAgreement.currency.code
            }
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
          <InfoPair
            leftContent={"Avg. rebate"}
            leftTextSize={"medium"}
            leftTextVariant={"bold"}
            leftWidth={"30%"}
            rightContent={dotToCommaTransform(
              routeAgreementRatesByFilter?.routeAgreement.prices[0].avgRebate
                ?.amount ?? "0"
            )}
            rightTextSize={"medium"}
            rightWidth={"70%"}
            rowContainerWidth={"100%"}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
