import {
  createColumnConfig,
  StandardTable,
  StandardTableConfig,
  TableContext,
} from "@stenajs-webui/grid";
import { SelectedRouteAgreement } from "../../copy/types";
import { SelectedRouteAgreementColumns } from "../../copy/components/SelectRouteAgreements";
import { Indent } from "@stenajs-webui/core";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { formatSailingTypeCodesToString } from "../../../../../common/formatters/SailingStatusCodeFormatter";
import * as React from "react";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";

interface Props {
  tableContext: TableContext<SelectedRouteAgreementColumns>;
  routeAgreements: Array<SelectedRouteAgreement>;
}

export const activateHeadAgreementRouteAgreementsTableConfig: StandardTableConfig<
  SelectedRouteAgreement,
  SelectedRouteAgreementColumns
> = {
  keyResolver: (item) => item.id,
  showRowCheckbox: true,
  showHeaderCheckbox: true,
  columns: {
    statusCode: createColumnConfig((item) => item.statusCode, {
      columnLabel: "Status",
      renderCell: ({ label, item }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={item.statusCode} />
        </Indent>
      ),
      width: "100px",
    }),
    agreementNumber: createColumnConfig((item) => item.agreementNumber, {
      columnLabel: "Agreement",
      width: "100px",
    }),
    routeDescription: createColumnConfig((item) => item.routeDescription, {
      columnLabel: "Route",
      width: "150px",
    }),
    name: createColumnConfig((item) => item.name),
    externalDescription: createColumnConfig(
      (item) => item.externalDescription,
      {
        columnLabel: "Ext. desc.",
      }
    ),
    sailingTypeCodes: createColumnConfig((item) => item.sailingTypeCodes, {
      itemLabelFormatter: (value) => formatSailingTypeCodesToString(value),
      columnLabel: "Type",
      width: "90px",
    }),
    valid: createColumnConfig((item) => formatGQLDateRange(item.valid), {
      columnLabel: "Validity",
      width: "190px",
    }),
    currency: createColumnConfig((item) => item.currency.id, {
      width: "110px",
    }),
    hasMatrix: createColumnConfig((item) => item.hasMatrix, {
      columnLabel: "Matrix",
      width: "70px",
      itemLabelFormatter: (value) => (value ? "Y" : ""),
    }),
  },
  columnOrder: [
    "statusCode",
    "agreementNumber",
    "routeDescription",
    "name",
    "externalDescription",
    "sailingTypeCodes",
    "valid",
    "currency",
    "hasMatrix",
  ],
};

export const ActivateRouteAgreementsTable: React.FC<Props> = ({
  tableContext,
  routeAgreements,
}) => {
  return (
    <StandardTable
      config={activateHeadAgreementRouteAgreementsTableConfig}
      items={routeAgreements}
      tableContext={tableContext}
      noItemsLabel={
        "There are no route agreements under this header agreement."
      }
    />
  );
};
