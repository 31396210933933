import { CustomerListTableColumnKeys } from "./config/CustomerListTableConfig";
import { Customer, CustomerInTable } from "./types";

export const transformCustomerToTableCustomer = (
  customer: Customer
): CustomerInTable => ({
  ...customer,
  expectedVolume: getExpectedVolume(customer),
  sellerPresentationOrder: customer.seller.name + customer.custNo,
});

const getExpectedVolume = (customer: Customer): number | null => {
  let allAreNull = true;
  let sum = 0;

  for (let statItem of customer.statisticsByFilter.items) {
    if (statItem.expectedVolume != null) {
      allAreNull = false;
    }
    sum += statItem.expectedVolume ?? 0;
  }

  return allAreNull ? null : sum;
};

export const transformColumnKeysToColumnLabel = (
  columnKeys: CustomerListTableColumnKeys
) => {
  switch (columnKeys) {
    case "name":
      return "Name";
    case "negotiationStatus":
      return "Neg status";
    case "custNo":
      return "Cust. no.";
    case "custIndex":
      return "Index";
    case "isParent":
      return "Type";
    case "statisticalGrouping":
      return "Stat. no.";
    case "parent":
      return "Parent no.";
    case "volume12Months":
      return "12 m. vol";
    case "seller":
      return "Seller";
    case "statVolume12Months":
      return "12 m. stat vol.";
    case "expectedVolume":
      return "Exp. vol.";
    case "activeHeadAgreementByYear":
      return "Agr";
    default:
      return "";
  }
};
