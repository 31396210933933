import * as React from "react";
import { Row, Space, Text } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { formatGQLDate } from "@/common/formatters/DateFormatter";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ActivateHeadAgrHeadAgrFragment } from "@/gql/graphql";
interface Props {
  headAgreement: ActivateHeadAgrHeadAgrFragment;
}

export const ActivateHeadAgreementInformationSection: React.FC<Props> = ({
  headAgreement,
}) => {
  return (
    <>
      <Row>
        <Label text={"Name"}>
          <Text>{headAgreement.name}</Text>
        </Label>
        <Space num={SpaceValues.FORTYEIGHT} />
        <Label text={"Valid from"}>
          <Text>{formatGQLDate(headAgreement.valid.start)}</Text>
        </Label>
        <Space num={SpaceValues.FORTYEIGHT} />
        <Label text={"Valid to"}>
          <Text>{formatGQLDate(headAgreement.valid.end)}</Text>
        </Label>
      </Row>
      <Space num={SpaceValues.FORTYEIGHT} />
      <Row>
        <Label text={"Agreement report specifications area"} flex={1}>
          <Text>{headAgreement.specifications}</Text>
        </Label>
        <Label text={"Agreement report condition area"} flex={1}>
          <Text>{headAgreement.condition}</Text>
        </Label>
      </Row>
      <Space num={SpaceValues.FORTYEIGHT} />
      <Row>
        <Label text={"Agreement report last page area"} flex={1}>
          <Text>{headAgreement.lastPage}</Text>
        </Label>
        <Space num={SpaceValues.TWENTYFOUR} />
        <Label text={"Agreement report internal notes"} flex={1}>
          <Text>{headAgreement.internalComments}</Text>
        </Label>
      </Row>
      <Space num={SpaceValues.FORTY} />
    </>
  );
};
