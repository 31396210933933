import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { QueryLazyOptions } from "@apollo/client";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setNslhOverviewError,
  setNslhOverviewLoading,
} from "../redux/reducers";
import { OverviewSearchRow } from "./OverviewSearchRow";
import { InternalOverviewQueryVariables } from "@/gql/graphql";

interface Props {
  loading: boolean;
  error: FreightPricingPortalError | undefined;
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >;
  fetchOverviewData: (
    options?: QueryLazyOptions<InternalOverviewQueryVariables> | undefined
  ) => void;
}

export const OverviewFilterBar: React.FC<Props> = ({
  loading,
  error,
  setError,
  fetchOverviewData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNslhOverviewLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(setNslhOverviewError(error));
  }, [error, dispatch]);

  const handleSearchByFilter = useCallback(
    (input: InternalOverviewQueryVariables) => {
      setError(undefined);
      fetchOverviewData({
        variables: input,
      });
    },
    [fetchOverviewData, setError]
  );

  return <OverviewSearchRow handleSearch={handleSearchByFilter} />;
};
