import { gql, useQuery } from "@apollo/client";
import { VehicleType } from "../../types/FilterEntitys";
import { VehicleTypesFilterQuery } from "@/gql/graphql";

const vehicleTypesFilterQuery = gql`
  query VehicleTypesFilter {
    productPrice {
      vehicleType {
        all {
          id
          code
          accompanied
          shortDescription
        }
      }
    }
  }
`;

export const useFetchAllVehicleTypes = () => {
  const { data, error, loading, refetch } = useQuery<VehicleTypesFilterQuery>(
    vehicleTypesFilterQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  const items =
    data?.productPrice.vehicleType.all.map<VehicleType>((r) => ({
      id: r.id,
      code: r.code,
      name: r.shortDescription,
      accompanied: r.accompanied,
    })) ?? [];

  return { items, loading, error, refetch };
};
