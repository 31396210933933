import React, { useCallback, useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { HandleBookingExcludeReasonsQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string | undefined) => void;
}

const query = gql`
  query HandleBookingExcludeReasons {
    productPrice {
      noShowLateHandlingExcludeReason {
        all {
          id
          code
          description
          presentationOrder
        }
      }
    }
  }
`;

export const HandleBookingExcludeReasonSelect: React.FC<Props> = ({
  value,
  onValueChange,
  ...selectProps
}) => {
  const { data, loading } = useQuery<HandleBookingExcludeReasonsQuery>(query);
  const all = useMemo(() => {
    return data?.productPrice.noShowLateHandlingExcludeReason.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return all
      .map((s) => ({
        label: s.description,
        value: s.code,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [all]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  const onValueChangeHandler = useCallback(
    (value: string) => {
      if (onValueChange) {
        onValueChange(value);
      }
    },
    [onValueChange]
  );
  return (
    <Select
      {...selectProps}
      isLoading={loading}
      options={options}
      value={selected}
      onChange={(option) => option && onValueChangeHandler(option?.value)}
    />
  );
};
