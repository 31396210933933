import { gql } from "apollo-boost";
import { apolloClient } from "../../../../apollo-client/ApolloClient";
import { AppThunk } from "../../../../config/redux/RootReducer";
import { createUpdateFormInputForSalesReady } from "../../route-agreement/details/transformer/FormToInputTransformer";
import { getModifiedOrNull } from "../../route-agreement/details/utils/InputTransformerHelpers";
import { customerRouteRedux } from "../redux";
import {
  UpdateRouteAgreementsInRoutePairPageMutation,
  UpdateRouteAgreementsInRoutePairPageMutationVariables,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import {
  rateSheetTablePricesFragment,
  routeAgreementPageFragment,
} from "../../route-agreement/details/fragments/RouteAgreementPageFragment";

const mutation = gql`
  ${errorFragment}
  ${rateSheetTablePricesFragment}
  ${routeAgreementPageFragment}
  mutation UpdateRouteAgreementsInRoutePairPage(
    $input: UpdateRouteAgreementInput!
  ) {
    productPrice {
      routeAgreement {
        updateRouteAgreement(input: $input) {
          __typename
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRouteAgreementSuccessResult {
            routeAgreement {
              ...RouteAgreementPageRouteAgreement
              valid {
                start {
                  isoString
                }
              }
              rowVersion
              isSalesReady
              prices {
                ...RateSheetTablePrices
              }
            }
          }
        }
      }
    }
  }
`;

interface UpdatedData {
  rowVersion: string;
  id: string;
}

export const saveCustomerRoutePairSalesReady =
  (updatedData: Array<UpdatedData>): AppThunk =>
  async (dispatch, getState) => {
    let totalErrors: Array<Error> = [];

    await Promise.all(
      updatedData.map(async (data: UpdatedData) => {
        try {
          let errorsForRouteAgreement: Array<Error> = [];

          const state = getState().customerRoute.routeAgreements[data.id];

          if (!state) {
            throw new Error("Invalid state for route agreement " + data.id);
          }
          const isSalesReady = getModifiedOrNull(
            state.editable.isSalesReady,
            state.persisted.isSalesReady
          );
          if (isSalesReady != null) {
            const input = createUpdateFormInputForSalesReady(
              data.id,
              data.rowVersion ?? state.editable.rowVersion,
              isSalesReady.value
            );
            const r = await apolloClient.mutate<
              UpdateRouteAgreementsInRoutePairPageMutation,
              UpdateRouteAgreementsInRoutePairPageMutationVariables
            >({ mutation, variables: { input } });

            if (r.errors && r.errors[0]) {
              errorsForRouteAgreement = [
                ...errorsForRouteAgreement,
                ...r.errors,
              ];
            }
            const updateRouteAgreementResult =
              r.data?.productPrice.routeAgreement.updateRouteAgreement;

            if (
              updateRouteAgreementResult &&
              "errors" in updateRouteAgreementResult
            ) {
              errorsForRouteAgreement = [
                ...errorsForRouteAgreement,
                ...updateRouteAgreementResult.errors.map(
                  (error) => new Error(error.message)
                ),
              ];
            }

            if (!errorsForRouteAgreement.length) {
              await dispatch(
                customerRouteRedux.actions.commitChangesForRouteAgreement(
                  data.id
                )
              );
            } else {
              totalErrors = [...totalErrors, ...errorsForRouteAgreement];
            }
          }
        } catch (e) {
          totalErrors = [...totalErrors, e];
        }
      })
    );

    if (totalErrors.length) {
      dispatch(customerRouteRedux.actions.setError(totalErrors[0]));
    }
  };
