import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import { ApolloQueryResult } from "@apollo/client";
import { Box, Row, SeparatorLine } from "@stenajs-webui/core";
import { CardBody, CardHeader } from "@stenajs-webui/elements";
import { TableContext } from "@stenajs-webui/grid";
import * as React from "react";
import { customerListSearchFilterSettings } from "../config/CustomerFilterConfig";
import { CustomerListTableColumnKeys } from "../config/CustomerListTableConfig";
import { BulkUpdateCustomersToolbar } from "./BulkUpdateCustomersToolbar";
import { CustomerListQueryVariables, CustomerListQuery } from "@/gql/graphql";

interface Props {
  numShowing: number;
  tableContext: TableContext<CustomerListTableColumnKeys>;
  selectedIds: Array<string>;
  negotiationYear: number;
  refetch: (
    variables?: Partial<CustomerListQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<CustomerListQuery>>;
  loading?: boolean;
}

export const CustomerListHeader: React.FC<Props> = ({
  numShowing,
  tableContext,
  selectedIds,
  negotiationYear,
  refetch,
  loading,
}) => {
  return (
    <Box
      //FIXME: when CardHeader has the necessary props for sticky.
      top={0}
      left={0}
      zIndex={ZIndex.lowest}
      position={"sticky"}
      width={"100vw"}
      background={"var(--lhds-color-ui-50)"}
      borderRadius={"var(--swui-border-radius)"}
    >
      <CardHeader
        text={loading ? "Loading..." : `${numShowing} customers`}
        data-testid={
          loading
            ? null
            : testIdConstantsWebapp.negotiationPlanTotalCustomersResult
        }
      />

      {selectedIds.length ? (
        <BulkUpdateCustomersToolbar
          customerNumbers={selectedIds}
          tableContext={tableContext}
          negotiationYear={negotiationYear}
          refetch={refetch}
        />
      ) : (
        <>
          <SeparatorLine />
          <CardBody height={"56px"} justifyContent={"center"}>
            <Row alignItems={"center"}>
              <FppSearchFilter settings={customerListSearchFilterSettings} />
            </Row>
          </CardBody>
          <SeparatorLine />
        </>
      )}
    </Box>
  );
};
