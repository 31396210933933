import * as React from "react";
import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import { CustomerRoute } from "./CustomerRoute";
import { populateRoutePairRateTablesState } from "../thunks/RoutePairRatesPopulator";
import { useDispatch } from "react-redux";
import { routeAgreementStatusIsNotVoid } from "../util/RouteAgreementsFilter";
import { clearAndFetchStatBoxes } from "../../../customer-route-statistic-boxes/thunks/FetchStatBoxes";
import { statBoxesRedux } from "../../../customer-route-statistic-boxes/redux";
import {
  CustomerRouteFetcherQuery,
  CustomerRouteFetcherQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  routePairId: string;
  headAgreementId: string;
}

const query = gql`
  fragment CustomerRouteFetcherRouteAgreement on RouteAgreement {
    id
    rowVersion
    name
    agreementNumber
    routeDescription
    sailingTypeCodes
    statusCode
    currency {
      id
      code
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    hasConditions
    hasArticles
    routes {
      id
      routePair {
        id
      }
    }
    multiLegAgrRouteId
  }

  fragment CustomerRouteFetcherHeadAgreement on HeadAgreement {
    id
    statusCode
    name
    headAgreementNumber
    breadCrumbName
    hasSomeRouteAgreementsWithMultiLeg
    statusCode
    rowVersion
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }

  query CustomerRouteFetcher($headAgreementId: ID!, $routePairId: ID!) {
    productPrice {
      routeAgreement {
        byHeadAgreementAndRoutePair(
          headAgreementId: $headAgreementId
          routePairId: $routePairId
        ) {
          ...CustomerRouteFetcherRouteAgreement
        }
      }
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...CustomerRouteFetcherHeadAgreement
        }
      }
    }
  }
`;

export const CustomerRouteFetcher: React.FC<Props> = ({
  headAgreementId,
  routePairId,
  customerId,
}) => {
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery<
    CustomerRouteFetcherQuery,
    CustomerRouteFetcherQueryVariables
  >(query, {
    variables: {
      headAgreementId,
      routePairId,
    },
  });

  const statBoxesYear =
    data?.productPrice.headAgreement.byId?.valid.start?.isoString;

  useEffect(() => {
    if (statBoxesYear) {
      dispatch(populateRoutePairRateTablesState(routePairId, headAgreementId));
      dispatch(
        clearAndFetchStatBoxes({
          customerId,
          routePairId,
          year: new Date(statBoxesYear).getUTCFullYear(),
        })
      );
    }
    dispatch(statBoxesRedux.actions.setHeadAgreementId(headAgreementId));
  }, [dispatch, routePairId, headAgreementId, statBoxesYear, customerId]);

  const headAgreement = data?.productPrice.headAgreement.byId;

  const routeAgreements = useMemo(() => {
    const allRouteAgreements =
      data?.productPrice.routeAgreement.byHeadAgreementAndRoutePair ?? [];

    return allRouteAgreements.filter(routeAgreementStatusIsNotVoid);
  }, [data]);

  if (loading) {
    return (
      <Spacing num={8}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!headAgreement) {
    return (
      <Spacing num={8}>
        <ErrorScreen text={"No such header agreement."} />
      </Spacing>
    );
  }

  return (
    <CustomerRoute
      routePairId={routePairId}
      headAgreement={headAgreement}
      customerId={customerId}
      routeAgreements={routeAgreements}
    />
  );
};
