import { OverviewBooking } from "@/common/components/overview/models/OverviewBooking";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateSelectInputValues, initialDateInputState } from "./utils";
import { NslhCustomerSelectFragment } from "@/gql/graphql";

export type NslhOverviewCustomerData = NslhCustomerSelectFragment | null;
export type NslhOverviewCustomerSelectedData =
  NslhCustomerSelectFragment | null;
export type NslhOverviewData = OverviewBooking[] | null;
export type NslhOverviewError = FreightPricingPortalError | undefined;

export interface NslhInternalOverviewState {
  nslhOverviewDateRangeInput: DateSelectInputValues;
  nslhOverviewCustomerData: NslhOverviewCustomerData;
  nslhOverviewCustomerSelectedData: NslhOverviewCustomerSelectedData;
  nslhOverviewAccountCardData: NslhOverviewCustomerSelectedData;
  nslhOverviewData: NslhOverviewData;
  nslhOverviewLoading: boolean;
  nslhOverviewError: NslhOverviewError;
}

const initialNslhInternalOverviewState: NslhInternalOverviewState = {
  nslhOverviewDateRangeInput: initialDateInputState,
  nslhOverviewCustomerData: null,
  nslhOverviewCustomerSelectedData: null,
  nslhOverviewAccountCardData: null,
  nslhOverviewData: null,
  nslhOverviewLoading: false,
  nslhOverviewError: undefined,
};

export const internalNslhOverviewReducerId = "internalNslhOverviewReducerId";

export const nslhInternalOverviewSlice = createSlice({
  name: internalNslhOverviewReducerId,
  initialState: initialNslhInternalOverviewState,
  reducers: {
    setNslhOverviewDateRangeInput(
      state,
      { payload: dateSelectData }: PayloadAction<Partial<DateSelectInputValues>>
    ) {
      state.nslhOverviewDateRangeInput = {
        ...state.nslhOverviewDateRangeInput,
        ...dateSelectData,
      };
    },
    setNslhOverviewCustomerSelectedData(
      state,
      {
        payload: nslhOverviewCustomerSelectedData,
      }: PayloadAction<NslhOverviewCustomerSelectedData>
    ) {
      state.nslhOverviewCustomerSelectedData = nslhOverviewCustomerSelectedData;
    },
    setNslhOverviewAccountCardData(
      state,
      {
        payload: nslhOverviewAccountCardData,
      }: PayloadAction<NslhOverviewCustomerSelectedData>
    ) {
      state.nslhOverviewAccountCardData = nslhOverviewAccountCardData;
    },
    setNslhOverviewData(
      state,
      { payload: nslhOverviewData }: PayloadAction<NslhOverviewData>
    ) {
      state.nslhOverviewData = nslhOverviewData;
    },
    setNslhOverviewLoading(
      state,
      { payload: nslhOverviewloading }: PayloadAction<boolean>
    ) {
      state.nslhOverviewLoading = nslhOverviewloading;
    },
    setNslhOverviewError(
      state,
      { payload: nslhOverviewError }: PayloadAction<NslhOverviewError>
    ) {
      state.nslhOverviewError = nslhOverviewError;
    },
  },
});

export const {
  setNslhOverviewDateRangeInput,
  setNslhOverviewCustomerSelectedData,
  setNslhOverviewAccountCardData,
  setNslhOverviewData,
  setNslhOverviewLoading,
  setNslhOverviewError,
} = nslhInternalOverviewSlice.actions;

export const nslhInternalOverviewReducer = nslhInternalOverviewSlice.reducer;
