import * as React from "react";
import { Row, Space, Text } from "@stenajs-webui/core";
import { Icon, stenaArrowRight } from "@stenajs-webui/elements";
import { Link } from "../../../../../../../../common/navigation/Link";
import { cssColor } from "@stenajs-webui/theme";

interface CopyToOtherCustomerResultPaneProps {
  loading: boolean;
  success: boolean;
  fromCustomerName: string;
  fromHeadAgreementNumber: number;
  toCustomerName: string;
  toHeadAgreementNumber: number;
  targetCustomerPath: string;
}

export const CopyToOtherCustomerResultPane: React.FC<
  CopyToOtherCustomerResultPaneProps
> = ({
  loading,
  success,
  fromCustomerName,
  fromHeadAgreementNumber,
  targetCustomerPath,
  toCustomerName,
  toHeadAgreementNumber,
}) => {
  const color = loading
    ? cssColor("--lhds-color-ui-500")
    : cssColor("--lhds-color-ui-700");

  return (
    <Row
      indent={2}
      spacing={2}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Row alignItems={"center"}>
        <Text variant={"bold"} color={color}>
          {fromCustomerName}, {fromHeadAgreementNumber}
        </Text>
        <Space num={4} />
        <Icon icon={stenaArrowRight} size={12} color={color} />
        <Space num={4} />
        <Text variant={"bold"} color={color}>
          {toCustomerName}, {toHeadAgreementNumber}
        </Text>
      </Row>

      {success && <Link to={targetCustomerPath}>Go to target customer</Link>}
    </Row>
  );
};
