import { Dispatch, useCallback } from "react";
import {
  BulkUpdateRatesFormModel,
  RaiseByType,
  RaiseType,
} from "../models/BulkUpdateRatesFormModel";
import { batch, useDispatch, useSelector } from "react-redux";
import { parseFloatElseUndefined } from "@/common/numbers";
import { rateSheetStandardTableWrapperActions } from "../../redux";
import { RateSheetTableAction, rateSheetTableActions } from "../../actions";
import { RecordObjectAction, ReducerIdGateAction } from "@stenajs-webui/redux";
import { RateSheetTableRowState } from "../../reducer";
import { rateSheetTableSelectors } from "../../selectors";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { commaToDotTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { HeadAgreementStatusCode } from "@/gql/graphql";

export interface RouteAgreementToBulkUpdate {
  id: string;
  prices: Array<RouteAgreementPriceToBulkUpdate>;
  statusCode: HeadAgreementStatusCode;
}

interface RouteAgreementPriceToBulkUpdate {
  id: string;
  meterPrice: { amount: string };
  newPrice: { amount: string };
  unaccompanied: boolean | null;
  vehicleType: {
    id: string;
    accompanied: boolean;
  } | null;
}

export const useBulkUpdateRatesLogic = (routeAgreementId: string) => {
  const dispatch = useDispatch();

  const selector = useCallback(
    (state: StoreState) =>
      rateSheetTableSelectors.getTableRowsStateForRouteAgreement(
        state,
        routeAgreementId
      ),
    [routeAgreementId]
  );

  const rateSheetState = useSelector(selector);

  const bulkUpdateRates = useCallback(
    (formModel: BulkUpdateRatesFormModel): Array<string> => {
      if (!rateSheetState) {
        throw new Error("Could not find info on what rates to update.");
      }

      const routeAgreementPriceIdList: Array<string> = [];

      batch(() => {
        rateSheetState.forEach((rateSheetTableRowState) => {
          const { routeAgreementPriceId, editable } = rateSheetTableRowState;

          if (!routeAgreementPriceShouldBeBulkUpdated(editable, formModel)) {
            return;
          }

          raisePriceForRouteAgreementPrice(
            dispatch,
            routeAgreementId,
            routeAgreementPriceId,
            rateSheetTableRowState,
            formModel
          );

          routeAgreementPriceIdList.push(routeAgreementPriceId);
        });
      });

      return routeAgreementPriceIdList;
    },
    [dispatch, rateSheetState, routeAgreementId]
  );

  return {
    bulkUpdateRates,
  };
};

const routeAgreementPriceShouldBeBulkUpdated = (
  routeAgreementPrice: RouteAgreementPriceToBulkUpdate,
  formModel: BulkUpdateRatesFormModel
) => {
  if (formModel.vehicleTypeIsNull && routeAgreementPrice.vehicleType == null) {
    return true;
  }

  if (formModel.acc && routeAgreementPrice.vehicleType?.accompanied === true) {
    return true;
  }

  if (
    formModel.unacc &&
    routeAgreementPrice.vehicleType?.accompanied === false
  ) {
    return true;
  }

  return false;
};

const calculateAndDispatchRaiseAction = (
  formModel: BulkUpdateRatesFormModel,
  previousValue: string,
  routeAgreementPriceId: string,
  dispatch: Dispatch<
    ReducerIdGateAction<RecordObjectAction<RateSheetTableAction>>
  >,
  routeAgreementId: string,
  actionCreator:
    | typeof rateSheetTableActions.setEditableNewPriceEntity
    | typeof rateSheetTableActions.setEditableMeterPriceEntity
) => {
  try {
    const newValue = (
      formModel.raiseByType === RaiseByType.ABSOLUTE
        ? increaseByAbsoluteValue(
            previousValue,
            commaToDotTransform(formModel.raiseBy)
          )
        : increaseByPercent(
            previousValue,
            commaToDotTransform(formModel.raiseBy)
          )
    ).toFixed(formModel.numberDecimals);
    const action = actionCreator(routeAgreementPriceId, newValue);
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        action
      )
    );
  } catch (e) {}
};

const calculateAndDispatchRaiseActionByMeterPrice = (
  formModel: BulkUpdateRatesFormModel,
  previousValueMeterPrice: string,
  additionalFrom: number | null,
  routeAgreementPriceId: string,
  dispatch: Dispatch<
    ReducerIdGateAction<RecordObjectAction<RateSheetTableAction>>
  >,
  routeAgreementId: string,
  actionCreatorMeterPrice: typeof rateSheetTableActions.setEditableMeterPriceEntity,
  actionCreatorNewPrice: typeof rateSheetTableActions.setEditableNewPriceEntity
) => {
  try {
    const newValueMeterPrice = (
      formModel.raiseByType === RaiseByType.ABSOLUTE
        ? increaseByAbsoluteValue(
            previousValueMeterPrice,
            commaToDotTransform(formModel.raiseBy)
          )
        : increaseByPercent(
            previousValueMeterPrice,
            commaToDotTransform(formModel.raiseBy)
          )
    ).toFixed(formModel.numberDecimals);
    const actionMeterPrice = actionCreatorMeterPrice(
      routeAgreementPriceId,
      newValueMeterPrice
    );
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        actionMeterPrice
      )
    );

    const newValueFixedPrice = increaseFixedPriceBasedOnNewMeterPrice(
      newValueMeterPrice,
      additionalFrom
    ).toFixed(formModel.numberDecimals);
    const actionFixedPrice = actionCreatorNewPrice(
      routeAgreementPriceId,
      newValueFixedPrice
    );
    dispatch(
      rateSheetStandardTableWrapperActions.tableRows.recordAction(
        routeAgreementId,
        actionFixedPrice
      )
    );
  } catch (e) {}
};

export const raisePriceForRouteAgreementPrice = (
  dispatch: Dispatch<
    ReducerIdGateAction<RecordObjectAction<RateSheetTableAction>>
  >,
  routeAgreementId: string,
  routeAgreementPriceId: string,
  rateSheetTableRowState: RateSheetTableRowState,
  formModel: BulkUpdateRatesFormModel
) => {
  if (
    formModel.raiseType === RaiseType.METER_PRICE ||
    formModel.raiseType === RaiseType.BOTH
  ) {
    calculateAndDispatchRaiseAction(
      formModel,
      rateSheetTableRowState.editable.meterPrice.amount,
      routeAgreementPriceId,
      dispatch,
      routeAgreementId,
      rateSheetTableActions.setEditableMeterPriceEntity
    );
  }

  if (
    formModel.raiseType === RaiseType.FIXED_PRICE ||
    formModel.raiseType === RaiseType.BOTH
  ) {
    calculateAndDispatchRaiseAction(
      formModel,
      rateSheetTableRowState.editable.newPrice.amount,
      routeAgreementPriceId,
      dispatch,
      routeAgreementId,
      rateSheetTableActions.setEditableNewPriceEntity
    );
  }

  if (formModel.raiseType === RaiseType.BY_METER_PRICE) {
    calculateAndDispatchRaiseActionByMeterPrice(
      formModel,
      rateSheetTableRowState.editable.meterPrice.amount,
      rateSheetTableRowState.editable.additionalFrom,
      routeAgreementPriceId,
      dispatch,
      routeAgreementId,
      rateSheetTableActions.setEditableMeterPriceEntity,
      rateSheetTableActions.setEditableNewPriceEntity
    );
  }
};

const increaseByAbsoluteValue = (
  previousValue: string,
  valueToAddAsString: string
): number => {
  const valueToAdd = parseFloatElseUndefined(valueToAddAsString);
  const currentPrice = parseFloatElseUndefined(previousValue);
  if (valueToAdd === undefined) {
    throw new Error("Invalid value.");
  }
  return (currentPrice ?? 0) + valueToAdd;
};

const increaseFixedPriceBasedOnNewMeterPrice = (
  newMeterPrice: string,
  additionalFrom: number | null
): number => {
  const meterPrice = parseFloatElseUndefined(newMeterPrice);
  if (meterPrice === undefined) {
    throw new Error("Invalid value.");
  }
  return (meterPrice ?? 0) * (additionalFrom ?? 0);
};

const increaseByPercent = (value: string, percentAsString: string): number => {
  const percent = parseFloatElseUndefined(percentAsString);
  const currentPrice = parseFloatElseUndefined(value);
  if (percent === undefined) {
    throw new Error("Invalid percent.");
  }
  if (currentPrice === undefined) {
    throw new Error("Current price is not set, cannot increase by percent.");
  }
  return currentPrice * (1 + percent / 100);
};
