import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { Box, Row, SeparatorLine } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { SearchFilterContext } from "@stenajs-webui/filter";
import * as React from "react";
import { useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useCurrencySelection } from "../../../../common/redux/profile/hooks/UseCurrencySelection";
import { useStatisticsYearSelect } from "../../common/hooks/UseStatisticsYearSelector";
import { useAdvancedStatisticsDataListQuery } from "../hooks/useAdvancedStatisticsDataListQuery";
import { advancedStatisticsListDataFilterVariablesTransformer } from "../transformers/AdvancedStatisticsListDataFilterVariablesTransformer";
import { transformAdvancedStatisticsDataFilterResultToTableRow } from "../transformers/TransformResultToTableRow";
import { StatisticsByAdvancedTable } from "./StatisticsByAdvancedTable";
import { statisticsByAdvancedFilterSettings } from "../config/StatisticsByAdvancedFIlterConfig";

interface Props {}

export const StatisticsByAdvancedListCard: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { year } = useStatisticsYearSelect();
  const { currency } = useCurrencySelection();

  const { committedState, workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { advancedStatisticsData, error, loading, loadAdvancedStatisticsData } =
    useAdvancedStatisticsDataListQuery(
      advancedStatisticsListDataFilterVariablesTransformer(
        { ...committedState },
        year,
        currency
      )
    );

  const tableRowItems = useMemo(
    () =>
      advancedStatisticsData?.map((csd) =>
        transformAdvancedStatisticsDataFilterResultToTableRow(csd)
      ),
    [advancedStatisticsData]
  );

  return (
    <PageLayout>
      <Helmet title="Advanced - Statistics - FPP" />
      <SearchFilterContext
        state={workspaceState}
        actions={createFppSearchFilterActions(
          "statistics.statisticsByAdvanced"
        )}
        dispatch={dispatch}
      >
        <Card minWidth={"1680px"}>
          <CardHeader
            text={
              loading ? "Loading..." : `${tableRowItems?.length ?? 0} Results`
            }
          />
          <CardBody>
            <Row>
              <Box>
                <FppSearchFilter
                  settings={statisticsByAdvancedFilterSettings}
                />
              </Box>
            </Row>
          </CardBody>
          <SeparatorLine />
          <StatisticsByAdvancedTable
            committedState={committedState}
            advancedStatisticsData={advancedStatisticsData}
            currentCurrency={currency}
            error={error}
            loadAdvancedStatisticsData={loadAdvancedStatisticsData}
            loading={loading}
          />
        </Card>
      </SearchFilterContext>
    </PageLayout>
  );
};
