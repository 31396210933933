import { AppThunk } from "../../../../../../../config/redux/RootReducer";
import { batch } from "react-redux";
import { rateSheetTableActions } from "../../../../../rate-sheet/actions";
import { rateSheetStandardTableWrapperActions } from "../../../../../rate-sheet/redux";

export const clearAllPriceStatisticsForRouteAgreement =
  (routeAgreementId: string): AppThunk =>
  async (dispatch, getState) => {
    const prices = getState().rateSheet.table[routeAgreementId];

    batch(() => {
      prices?.rows.forEach((price) => {
        dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            routeAgreementId,
            rateSheetTableActions.setEditablePriceFields(price.editable.id, {
              volume: null,
              avgGrossPrice: null,
              lastPrice: null,
              lastMeterPrice: null,
              lastChargedFrom: null,
            })
          )
        );
      });
    });
  };

export const clearPriceStatisticsForRouteAgreementPrice =
  (routeAgreementId: string, routeAgreementPriceId: string): AppThunk =>
  async (dispatch, getState) => {
    const prices = getState().rateSheet.table[routeAgreementId];

    batch(() => {
      prices?.rows
        .filter((p) => p.editable.id === routeAgreementPriceId)
        .forEach((price) => {
          dispatch(
            rateSheetStandardTableWrapperActions.tableRows.recordAction(
              routeAgreementId,
              rateSheetTableActions.setEditablePriceFields(price.editable.id, {
                volume: null,
                avgGrossPrice: null,
                lastPrice: null,
                lastMeterPrice: null,
                lastChargedFrom: null,
              })
            )
          );
        });
    });
  };
