import React, { useEffect, useState } from "react";

interface Props {
  waitBeforeShow?: number;
  children: any;
}

export const DelayedRender: React.FC<Props> = ({
  waitBeforeShow = 1,
  children,
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);

    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
