import { PriceCalculatorFormData } from "../PriceCalculatorFormFetcher";
import { FormModelChangeHandler } from "../../../common/forms/types";
import * as React from "react";
import { Indent, Spacing } from "@stenajs-webui/core";
import { Banner, ValueTable, ValueTableItem } from "@stenajs-webui/elements";
import styles from "./PriceCalculatorBookingForm.module.css";
import {
  transformToCountryCode,
  transformToHazardousGoodsWeight,
  transformToLoadingPreferenceCode,
} from "../price-calculator-result-panel/transformers/PriceCalculatorFormResultTransformer";
import { parseTimeString } from "../common/transformers/time";
import { ConsignmentQuery } from "@/gql/graphql";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  consignment?: ConsignmentQuery;
}
export const PriceCalculatorBookingForm: React.FC<Props> = ({
  consignment,
}) => {
  const consignmentData = consignment?.priceCalc.consignments.byId;

  if (!consignmentData) {
    return (
      <Indent num={3} width={"100%"} spacing={2}>
        <Banner
          variant={"info"}
          headerText={"Price not calculated yet"}
          text={
            "Please enter a booking number and select a consignment version to calculate a price for it"
          }
        />
      </Indent>
    );
  }

  const ancillariesOrGoodsExist = () => {
    return (
      consignmentData.consignmentGoods.length > 0 ||
      consignmentData.consignmentArticles.some((ca) => {
        return ca.articleType.type === "ANC";
      })
    );
  };

  const goods = consignmentData.consignmentGoods;
  const ancillaries = consignmentData.consignmentArticles.filter(
    (ca) => ca.articleType.type === "ANC"
  );

  return (
    <Indent num={5} width={"100%"} className={styles.bookingForm}>
      <Spacing />
      <ValueTable header={"Booking details"}>
        <ValueTableItem
          label={"Booking number"}
          value={consignmentData?.bookingNum}
        />
        <ValueTableItem
          label={"Booking status"}
          value={consignmentData?.statusCode}
        />
        <ValueTableItem
          label={"Invoice number"}
          value={
            consignmentData.consignmentLegInvoice
              ? consignmentData.consignmentLegInvoice?.invoiceNo
              : "-"
          }
        />
        <ValueTableItem
          label={"Customer reference"}
          value={consignmentData?.customerReference}
        />
        <ValueTableItem
          label={"Movement type"}
          value={consignmentData?.movementType.description}
        />
      </ValueTable>

      <Spacing />
      <ValueTable header={"Sailing"}>
        <ValueTableItem
          label={"Route"}
          value={consignmentData?.sailingArchive.route.name}
        />
        <ValueTableItem
          label={"Date"}
          value={consignmentData?.sailingArchive.departureDate}
        />
        <ValueTableItem
          label={"Departure time"}
          value={parseTimeString(consignmentData?.sailingArchive.departureTime)}
        />
      </ValueTable>

      <Spacing />
      <ValueTable header={"Vehicle"}>
        <ValueTableItem
          label={"Vehicle Type"}
          value={consignmentData?.vehicleType.shortDescription}
        />
        <ValueTableItem
          label={"Vehicle reg"}
          value={consignmentData?.vehicleRegNo}
        />
        <ValueTableItem
          label={"Trailer reg"}
          value={consignmentData?.trailerRegNo}
        />
        <ValueTableItem
          label={"Vehicle length"}
          value={consignmentData?.length}
        />
        <ValueTableItem
          label={"Vehicle width"}
          value={consignmentData?.width}
        />
        <ValueTableItem
          label={"Vehicle weight"}
          value={consignmentData?.weight}
        />
        <ValueTableItem
          label={"Loading preference"}
          value={transformToLoadingPreferenceCode(
            consignmentData.consignmentLoadingPreferences
          )}
        />
      </ValueTable>

      <Spacing />
      <ValueTable header={"Drivers and passengers"}>
        <ValueTableItem label={"Adults"} value={consignmentData?.numOfAdults} />
        <ValueTableItem
          label={"Children"}
          value={consignmentData?.numOfChildren}
        />
        <ValueTableItem
          label={"Infants"}
          value={consignmentData?.numOfInfants}
        />
        <ValueTableItem
          label={"Number of sleepers"}
          value={consignmentData?.numOfSleepers}
        />
      </ValueTable>

      <Spacing />
      <ValueTable header={"Cargo Details"}>
        <ValueTableItem
          label={"Hazardous goods"}
          value={
            consignmentData?.consignmentHazardousGoods.length > 0 ? "YES" : "NO"
          }
        />
        <ValueTableItem
          label={"HG weight/line"}
          value={transformToHazardousGoodsWeight(
            consignmentData?.consignmentHazardousGoods
          )}
        />
        <ValueTableItem
          label={"Number of rows"}
          value={consignmentData?.numOfInfants}
        />
        <ValueTableItem
          label={"Qualified goods"}
          value={consignmentData?.qualifiedGoods ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Animals"}
          value={consignmentData?.numOfInfants}
        />
        <ValueTableItem
          label={"Animal"}
          value={consignmentData?.numOfSleepers}
        />
      </ValueTable>

      <Spacing />
      <ValueTable header={"Conditions"}>
        <ValueTableItem
          label={"Standby"}
          value={consignmentData?.standby ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Transit"}
          value={consignmentData?.transit ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Sender country"}
          value={
            consignmentData.consignmentAddresses.length > 0
              ? transformToCountryCode(consignmentData.consignmentAddresses)
              : "-"
          }
        />
        <ValueTableItem
          label={"Receiver country"}
          value={
            consignmentData.consignmentAddresses.length > 0
              ? transformToCountryCode(
                  consignmentData.consignmentAddresses,
                  false
                )
              : "-"
          }
        />
      </ValueTable>
      <Spacing />
      {ancillariesOrGoodsExist() && (
        <ValueTable header={"Goods & Ancillaries"}>
          {goods.map((gd) => (
            <ValueTableItem
              key={gd.id}
              label={`${gd.description} ${gd.goodsCode}`}
              value={gd.weight}
            />
          ))}
          {ancillaries.map((anc) => (
            <ValueTableItem
              key={anc.id}
              label={`${anc.articleType.name} ${anc.articleCode}`}
              value={anc.quantity}
            />
          ))}
        </ValueTable>
      )}
      <Spacing num={4} />
    </Indent>
  );
};
