import { CountryMultiChipSelect } from "@/common/components/data-driven-inputs/selects/country-multi-select/CountryMultiChipSelect";
import { stenaMapNavigation } from "@stenajs-webui/elements";
import * as React from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const CountryFilterSection: React.FC<Props> = ({ enabled, label }) => {
  const { countries, setCountries } = useFilterWorkspace();

  const onCountryCodesValueChange = (countryCodes: string[]) => {
    setCountries(countryCodes);
  };

  return (
    <FppSearchFilterSection
      sectionId={"countries"}
      label={label}
      enabled={enabled}
      numSelected={countries.length}
      leftIcon={stenaMapNavigation}
      children={
        <CountryMultiChipSelect
          value={countries}
          onValueChange={onCountryCodesValueChange}
        />
      }
    />
  );
};
