import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { DateRangeDualTextInput } from "@stenajs-webui/calendar";
import { Box, Indent, Row, Space, Text, Txt } from "@stenajs-webui/core";
import { CardBody, Label } from "@stenajs-webui/elements";
import {
  CheckboxWithLabel,
  RadioButtonWithLabel,
  TextArea,
  TextInput,
} from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { CurrencyRadioButtonRow } from "../../../../../../common/components/data-driven-inputs/radio-buttons/CurrencyRadioButtonRow";
import { RouteInRoutePairSelect } from "../../../../../../common/components/data-driven-inputs/selects/route-in-pair-select/RouteInRoutePairSelect";
import { StoreState } from "../../../../../../config/redux/RootReducer";
import { EditRouteAgreementFormModel } from "../models/EditRouteAgreementFormModel";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { RouteAgreementTypeCode } from "@/gql/graphql";

interface Props {
  value: EditRouteAgreementFormModel;
  setFields: (value: Partial<EditRouteAgreementFormModel>) => void;
  disableType: boolean;
}

const selector = (state: StoreState) =>
  state.routeAgreementDetails.addNameClicked;

export const EditRouteAgreementForm: React.FC<Props> = ({
  value,
  setFields,
  children,
  disableType,
}) => {
  const addNameClicked = useSelector(selector);
  const nameInputFieldRefHolder = useRef<HTMLInputElement>(null);
  const otherThanSpecialIsSelected =
    value.peak || value.offPeak || value.shoulder;

  useEffect(() => {
    if (nameInputFieldRefHolder.current) {
      nameInputFieldRefHolder.current.focus();
    }
  }, [addNameClicked]);

  return (
    <CardBody>
      <Row alignItems={"flex-start"}>
        <Box>
          <Text
            color={cssColor("--tjara")}
            variant={"bold"}
            size={"small"}
            whiteSpace={"nowrap"}
          >
            {"Select route"}
          </Text>
          <Space />
          <Row alignItems={"center"}>
            <RouteInRoutePairSelect
              value={value.routeIds}
              onValueChange={(routeIds) => setFields({ routeIds })}
              closeMenuOnSelect={false}
              includePartnerRoutes={value.includePartnerRoutes}
            />
            <Space num={SpaceValues.SIXTEEN} />
            <CheckboxWithLabel
              label={"Include partner routes"}
              value={value.includePartnerRoutes}
              onValueChange={(includePartnerRoutes) =>
                setFields({ includePartnerRoutes })
              }
            />
          </Row>
        </Box>
      </Row>
      <Space num={SpaceValues.THIRTYTWO} />
      <Label text={"Name"} optional={true}>
        <Box width={"500px"}>
          <TextInput
            inputRef={nameInputFieldRefHolder}
            value={value.name}
            onValueChange={(name) => setFields({ name })}
            data-testid={testIdConstantsWebapp.createRouteAgreementNameTextBox}
          />
        </Box>
      </Label>
      <Space num={SpaceValues.THIRTYTWO} />
      <Txt
        color={cssColor("--lhds-color-ui-600")}
        size={"small"}
        whiteSpace={"nowrap"}
      >
        Validity
      </Txt>
      <Space />
      <Box width={"fit-content"}>
        <DateRangeDualTextInput
          value={{
            endDate: new Date(value.valid.endDate ?? ""),
            startDate: new Date(value.valid.startDate ?? ""),
          }}
          onValueChange={(valid) =>
            setFields({
              valid: {
                endDate: valid.endDate?.toString(),
                startDate: valid.startDate?.toString(),
              },
            })
          }
        />
      </Box>
      <Space num={SpaceValues.THIRTYTWO} />
      <Label text={"Agreement currency"}>
        <Box>
          <CurrencyRadioButtonRow
            value={value.currencyId}
            onValueChange={(currencyId) => setFields({ currencyId })}
          />
        </Box>
      </Label>
      <Space num={SpaceValues.THIRTYTWO} />
      <Label text={"Type"}>
        <Row alignItems={"center"}>
          <RadioButtonWithLabel
            label={"Conditional"}
            checked={value.type === RouteAgreementTypeCode.Cond}
            onValueChange={() =>
              setFields({ type: RouteAgreementTypeCode.Cond })
            }
            disabled={disableType}
          />
          <Space num={SpaceValues.TWENTYFOUR} />
          <RadioButtonWithLabel
            label={"Base"}
            checked={value.type === RouteAgreementTypeCode.Base}
            onValueChange={() =>
              setFields({ type: RouteAgreementTypeCode.Base })
            }
            disabled={disableType}
          />
        </Row>
      </Label>
      <Space num={SpaceValues.THIRTYTWO} />
      <Label text={"Sailing cat."}>
        <Row alignItems={"center"}>
          <CheckboxWithLabel
            label={"Peak"}
            value={value.peak}
            disabled={value.special}
            onValueChange={(peak) => setFields({ peak })}
            data-testid={
              testIdConstantsWebapp.createRouteAgreementSailingCategoryPeak
            }
          />
          <Space num={SpaceValues.TWENTYFOUR} />
          <CheckboxWithLabel
            label={"Off-Peak"}
            value={value.offPeak}
            disabled={value.special}
            onValueChange={(offPeak) => setFields({ offPeak })}
            data-testid={
              testIdConstantsWebapp.createRouteAgreementSailingCategoryOffpeak
            }
          />
          <Space num={SpaceValues.TWENTYFOUR} />
          <CheckboxWithLabel
            label={"Shoulder"}
            value={value.shoulder}
            disabled={value.special}
            onValueChange={(shoulder) => setFields({ shoulder })}
            data-testid={
              testIdConstantsWebapp.createRouteAgreementSailingCategoryShoulder
            }
          />
          <Indent num={IndentValues.TWENTYFOUR}>
            <Text color={cssColor("--lhds-color-ui-500")}>or</Text>
          </Indent>
          <CheckboxWithLabel
            label={"Special"}
            value={value.special}
            disabled={otherThanSpecialIsSelected}
            onValueChange={(special) => setFields({ special })}
          />
        </Row>
      </Label>
      <Space num={SpaceValues.THIRTYTWO} />
      <Label text={"External description"} optional>
        <Box width={"500px"}>
          <TextArea
            rows={5}
            value={value.externalDescription}
            onValueChange={(externalDescription) =>
              setFields({ externalDescription })
            }
          />
        </Box>
      </Label>
      {children && (
        <>
          <Space num={SpaceValues.SIXTEEN} />
          {children}
        </>
      )}
    </CardBody>
  );
};
