import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { allCargoStatusCodes } from "../../../../transformers/CargoStatusTransformer";
import { capitalize } from "lodash";
import { CargoStatusCode } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: CargoStatusCode;
  onValueChange?: (value: CargoStatusCode) => void;
}

const options = allCargoStatusCodes.map((s) => ({
  label: capitalize(s),
  value: s,
}));

export const CargoStatusSelect: React.FC<Props> = ({
  value,
  onValueChange,
  ...selectProps
}) => {
  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      {...selectProps}
      options={options}
      value={selected}
      onChange={(item: any) =>
        onValueChange &&
        onValueChange(allCargoStatusCodes.find((u) => u === item.value)!)
      }
    />
  );
};
