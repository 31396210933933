import { Space, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  stenaIntermodal,
} from "@stenajs-webui/elements";
import * as React from "react";
import { HeadAgreementStatusCodeChip } from "../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { InfoBoxItem } from "../InfoBoxItem";
import { MultiLegAgreementBox } from "../types";
import { MultiLegAgreementInfoPairPopover } from "./MultiLegAgreementInfoPairPopover";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";

interface Props {
  multiLegAgreement: MultiLegAgreementBox;
}

export const MultiLegAgreementInfoPairBox: React.FC<Props> = ({
  multiLegAgreement,
}) => {
  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Multi leg agreement"}
        leftIcon={stenaIntermodal}
        contentRight={
          <MultiLegAgreementInfoPairPopover
            mutliLegAgreement={multiLegAgreement}
            actionMenuOpen={actionMenuOpen}
            openActionMenu={openActionMenu}
            closeActionMenu={closeActionMenu}
          />
        }
      />
      <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
        <HeadAgreementStatusCodeChip
          statusCode={multiLegAgreement.statusCode}
          size={"small"}
          key={"head-agreement-infobox-key-0"}
        />
        <Space />
        <InfoBoxItem
          leftContent={"Validity"}
          rightContent={formatDateStringRangeWithSwedishTimeZone({
            dateStringFrom: multiLegAgreement.valid.start?.isoString,
            dateStringTo: multiLegAgreement.valid.end?.isoString,
          })}
          key={"head-agreement-infobox-key-1"}
        />
        <InfoBoxItem
          leftContent={"Route"}
          rightContent={multiLegAgreement.route.code}
          key={"head-agreement-infobox-key-2"}
        />
      </CardBody>
    </Card>
  );
};
