import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../../../common/error/GqlErrorFragment";
import {
  ActivateRouteAgreementMutation,
  ActivateRouteAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation ActivateRouteAgreement(
    $routeAgreement: ActivateRouteAgreementInput!
  ) {
    productPrice {
      routeAgreement {
        activateRouteAgreement(routeAgreement: $routeAgreement) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRouteAgreementSuccessResult {
            routeAgreement {
              id
              statusCode
              rowVersion
            }
          }
        }
      }
    }
  }
`;

export const useActivateRouteAgreementMutation = () =>
  useMutation<
    ActivateRouteAgreementMutation,
    ActivateRouteAgreementMutationVariables
  >(query);
