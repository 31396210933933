import * as React from "react";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { Box, Spacing } from "@stenajs-webui/core";
import { CopyHeadAgreementContainer } from "./CopyHeadAgreementContainer";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { CopyHeadAgreementHeader } from "./CopyHeadAgreementHeader";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Helmet } from "react-helmet";
import {
  ByCustomerNumberOrIndexFragment,
  CopyHeadAgreementDataQuery,
  CopyHeadAgreementDataQueryVariables,
  CopyHeadAgreementTargetCustomerQuery,
  CopyHeadAgreementTargetCustomerQueryVariables,
} from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  targetHeadAgreement?: CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"];
  targetCustomer?: ByCustomerNumberOrIndexFragment;
  copyHaToOtherCustomer?: boolean;
}

const copyHeadAgreementQuery = gql`
  fragment CopyAgreementMultiLegRouteAgreements on MultiLegRouteAgreement {
    id
    name
    currency {
      id
      code
      name
      shortDescription
      longDescription
    }
    statusCode
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    dateCreated
    createdBy
    dateModified
    modifiedBy
    idNumber
    rowVersion
    workFlowStatusCode
    route {
      id
      code
      name
    }
  }
  fragment CopyAgreementRouteAgreements on RouteAgreement {
    id
    name

    statusCode
    routeDescription
    agreementNumber
    hasMatrix
    sailingTypeCodes

    externalDescription
    multiLegAgrRouteId
    currency {
      id
      code
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
  }
  fragment CopyAgreementCustomer on Customer {
    id
    custIndex
    custNo
    name
    breadCrumbName
  }
  fragment CopyHeadAgreementDataHeadAgreement on HeadAgreement {
    id
    name
    breadCrumbName
    headAgreementNumber
    internalComments
    specifications
    condition
    lastPage
    statusCode

    customer {
      ...CopyAgreementCustomer
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }

    breadCrumbName

    routeAgreements {
      ...CopyAgreementRouteAgreements
    }
    multiLegAgrRoutes {
      ...CopyAgreementMultiLegRouteAgreements
    }
  }
  query CopyHeadAgreementData($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          ...CopyHeadAgreementDataHeadAgreement
        }
      }
    }
  }
`;

const copyHeadAgreementTargetCustomerQuery = gql`
  query CopyHeadAgreementTargetCustomer($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          name
          internalComments
          specifications
          condition
          lastPage
          statusCode
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
        }
      }
    }
  }
`;

export const useCopyHeadAgreementTargetCustomerQuery = (
  targetHeadAgreementId: string
) => {
  const [getTargetCustomerByQuery, { data, loading }] = useLazyQuery<
    CopyHeadAgreementTargetCustomerQuery,
    CopyHeadAgreementTargetCustomerQueryVariables
  >(copyHeadAgreementTargetCustomerQuery, {
    variables: {
      headAgreementId: targetHeadAgreementId,
    },
  });

  const targetHeadAgreement = data?.productPrice.headAgreement.byId;

  return { getTargetCustomerByQuery, targetHeadAgreement, loading };
};

export const CopyHeadAgreementFetcher: React.FC<Props> = ({
  headAgreementId,
  targetHeadAgreement,
  targetCustomer,
  copyHaToOtherCustomer,
}) => {
  const { data, loading, error } = useQuery<
    CopyHeadAgreementDataQuery,
    CopyHeadAgreementDataQueryVariables
  >(copyHeadAgreementQuery, { variables: { headAgreementId } });

  const headAgreement = data?.productPrice.headAgreement.byId;

  if (loading) {
    return (
      <Spacing num={12}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={12}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!headAgreement?.customer) {
    return <ErrorScreen text={"Header agreement has no customer."} />;
  }

  return (
    <>
      {copyHaToOtherCustomer ? (
        <Box
          indent={IndentValues.TWENTYFOUR}
          spacing={SpacingValues.TWENTYFOUR}
        >
          <Card>
            <CardHeader text={"Target customer and agreement"} />
            <CardBody>
              <CopyHeadAgreementContainer
                headAgreement={headAgreement}
                targetHeadAgreement={targetHeadAgreement}
                copyHaToOtherCustomer={copyHaToOtherCustomer}
                targetCustomer={targetCustomer}
              />
            </CardBody>
          </Card>
        </Box>
      ) : (
        <>
          <Helmet title="Copy HA - Customer - FPP" />
          <CopyHeadAgreementHeader
            customer={headAgreement.customer}
            headAgreement={headAgreement}
          />
          <Box
            indent={IndentValues.TWENTYFOUR}
            spacing={SpacingValues.TWENTYFOUR}
          >
            <Card>
              <CardHeader text={"Options"} />
              <CardBody>
                <CopyHeadAgreementContainer headAgreement={headAgreement} />
              </CardBody>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};
