import { RouteInRoutePairSelectQuery } from "@/gql/graphql";
import { groupBy } from "lodash";

export const getFirstSelectedRoutePair = (
  selectedRouteId: string | undefined,
  routes: Omit<
    RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"],
    "__typename"
  >
):
  | RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"]["0"]["routePair"]
  | undefined => {
  if (selectedRouteId === undefined) {
    return undefined;
  }

  return routes.find((route) => route.id === selectedRouteId)?.routePair;
};

export const getOptionsGroupedBySeaArea = (
  routes: RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"]
) => {
  const routesBySeaArea = groupBy(
    routes,
    (route) => route.routePair.seaArea.id
  );

  return Object.values(routesBySeaArea).map((routes) => ({
    label: routes[0].routePair.seaArea.name,
    options: routes.map(getOptionForRoute),
  }));
};

export const getOptionsForSelectedRouteId = (
  selectedRouteId: string,
  routes: RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"]
) => {
  const selectedRoutePairId = getFirstSelectedRoutePair(
    selectedRouteId,
    routes
  )?.id;

  if (!selectedRoutePairId) {
    return [];
  }

  return routes
    .filter((route) => route.routePair.id === selectedRoutePairId)
    .map(getOptionForRoute);
};

export const getOptionForRoute = (
  route: RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"]["0"]
) => ({
  label: route.name,
  value: route.id,
});

export const getOptionsForSelect = (
  selectedRouteIds: Array<string> | undefined,
  routes: RouteInRoutePairSelectQuery["productPrice"]["route"]["byRouteTypes"]
) => {
  if (selectedRouteIds === undefined || selectedRouteIds.length === 0) {
    return getOptionsGroupedBySeaArea(routes);
  }
  const [firstSelectedRouteId] = selectedRouteIds;
  return getOptionsForSelectedRouteId(firstSelectedRouteId, routes);
};
