import { HeadAgreementByIdFragment } from "@/gql/graphql";
import {
  CreateMultiLegAgreementRouteFormModel,
  emptyCreateMultiLegAgreementRouteFormModel,
} from "../../../common/components/create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";

export const createMultiLegFormModel = (
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"],
  selectedRoudeCode?: string,
  name?: string
): CreateMultiLegAgreementRouteFormModel => {
  return {
    ...emptyCreateMultiLegAgreementRouteFormModel,
    currencyCode: multiLegAgreement.currency.code,
    name: name
      ? name
      : multiLegAgreement.routeLegs[0]
      ? multiLegAgreement.routeLegs[0].legRoute.name
      : "",
    multiLegAgreementId: multiLegAgreement.id,
    externalDescription: "",
    headAgreementId: multiLegAgreement.headAgreement.id,
    routeCode: multiLegAgreement.route.code,
    valid: {
      endDate: multiLegAgreement.valid?.end?.isoString
        ? new Date(multiLegAgreement.valid.end?.isoString)
        : undefined,
      startDate: multiLegAgreement.valid?.start?.isoString
        ? new Date(multiLegAgreement.valid.start?.isoString)
        : undefined,
    },
    selectedRouteCode:
      selectedRoudeCode ?? multiLegAgreement.routeLegs[0].legRoute.code,
  };
};
