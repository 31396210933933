import * as React from "react";
import { ErrorScreen } from "@stenajs-webui/panels";
import { CustomerExpectedVolumePane } from "./CustomerExpectedVolumePane";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface CustomerExpectedVolumeScreenParams {
  customerId: string;
}

interface Props {}

export const CustomerExpectedVolumeScreen: React.FC<Props> = () => {
  const { customerId } = useParams() as { customerId: string };

  if (!customerId) {
    return <ErrorScreen text={"No customerId provided"} />;
  }

  return (
    <>
      <Helmet title="Expected volume - Customer - FPP" />
      <CustomerExpectedVolumePane customerId={customerId} />
    </>
  );
};
