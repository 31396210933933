import { Box } from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { InfoBoxItem } from "../InfoBoxItem";
import { MultiLegAgreementBox } from "../types";
import { replace } from "lodash";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";
interface Props {
  mutliLegAgreement: MultiLegAgreementBox;
  actionMenuOpen: boolean;
  openActionMenu: () => void;
  closeActionMenu: () => void;
}

export const MultiLegAgreementInfoPairPopover: React.FC<Props> = ({
  mutliLegAgreement,
  actionMenuOpen,
  openActionMenu,
  closeActionMenu,
}) => {
  const formatUserName = (userName: string) => {
    const str = replace(userName, "LINE\\", "");
    return str.toUpperCase();
  };

  return (
    <Popover
      placement={"right"}
      animation={false}
      visible={actionMenuOpen}
      disablePadding
      onClickOutside={closeActionMenu}
      appendTo={document.body}
      content={
        <Box indent={2} spacing={1}>
          <InfoBoxItem
            leftContent={"Name"}
            rightContent={mutliLegAgreement.name.toUpperCase()}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Cur."}
            rightContent={mutliLegAgreement.currency.code}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"No."}
            rightContent={mutliLegAgreement.idNumber ?? ""}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created"}
            rightContent={
              formatDateStringWithSwedishTimeZone({
                dateString: mutliLegAgreement.dateCreated,
                options: {
                  dateStyle: "short",
                  timeStyle: "short",
                },
              }) ?? ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created by"}
            rightContent={
              mutliLegAgreement.createdBy
                ? formatUserName(mutliLegAgreement.createdBy)
                : ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified"}
            rightContent={
              (mutliLegAgreement.dateModified &&
                formatDateStringWithSwedishTimeZone({
                  dateString: mutliLegAgreement.dateModified,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                })) ??
              ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified by"}
            rightContent={
              mutliLegAgreement.modifiedBy
                ? formatUserName(mutliLegAgreement.modifiedBy)
                : ""
            }
            popoverEnabled
          />
        </Box>
      }
    >
      <Box width={16}>
        <FlatButton
          leftIcon={stenaInfoCircle}
          onClick={actionMenuOpen ? closeActionMenu : openActionMenu}
        />
      </Box>
    </Popover>
  );
};
