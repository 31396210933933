import { Box } from "@stenajs-webui/core";
import { Icon, InputSpinner, stenaAngleDown } from "@stenajs-webui/elements";
import { CrudStatusIndicator } from "@stenajs-webui/grid";
import { GaActionTable } from "../../../../../common/utils/tracking/enums/actions/GaActionTable";
import { GaValueSelects } from "../../../../../common/utils/tracking/enums/values/GaValueSelects";
import { trackEvent } from "../../../../../common/utils/tracking/helper/GaTracker";
import * as React from "react";
import { useState } from "react";
import { CellFieldClickGate } from "../../../../../common/components/cell-field-click-gate/CellFieldClickGate";
import { NegotiationStatusSelect } from "../../../../../common/components/data-driven-inputs/selects/negotiation-status-select/NegotiationStatusSelect";
import { getApolloErrorMessage } from "../../../../../common/string/GraphQlErrorParser";
import { NegotiationStatusByYear } from "../../customer-list/types";
import { useSetNegotiationStatus } from "../hooks/UseSetNegotiationStatusMutation";
import { SetChildrenNegotiationStatusModal } from "./SetChildrenNegotiationStatusModal";
import { GaActionDropdown } from "../../../../../common/utils/tracking/enums/actions/GaActionDropdown";
import { getYear } from "date-fns";
import { NegotiationStatusFetcherCustomerFragment } from "@/gql/graphql";

interface Props {
  customer: NegotiationStatusFetcherCustomerFragment;
  negotiationYear: number;
  border?: string;
  borderRadius?: string;
  isInTable: boolean;
}

export const CustomerNegotiationStatusEditor: React.FC<Props> = ({
  customer,
  border,
  borderRadius,
  negotiationYear,
  isInTable,
}) => {
  const { error, loading, setNegotiationStatus } = useSetNegotiationStatus();
  const [negStatus, setNegStatus] = useState<
    NegotiationStatusByYear | undefined
  >(undefined);

  const year = negotiationYear ?? getYear(new Date());

  const setCustomerNegotiationStatus = (
    negotiationStatus: NegotiationStatusByYear | undefined
  ) => {
    if (negotiationStatus) {
      if (isInTable) {
        trackEvent({
          action: GaActionTable.inlineEditSelect,
          value: GaValueSelects.negotiationStatus,
        });
      } else {
        trackEvent({
          action: GaActionDropdown.inlineEditSelect,
          value: GaValueSelects.negotiationStatus,
        });
      }

      setNegotiationStatus([customer.id], year, negotiationStatus).map(
        (status) => {
          return status.catch((e) => {
            console.error(e);
          });
        }
      );

      if (customer?.isParent && customer.noOfChildren > 0) {
        setNegStatus(negotiationStatus);
      }
    }
  };

  return (
    <>
      <SetChildrenNegotiationStatusModal
        negotiationStatus={negStatus}
        negotiationYear={year}
        customer={customer}
      />
      <CellFieldClickGate
        disableClick={loading}
        label={customer.negotiationStatusByYear?.name ?? ""}
        border={border}
        borderRadius={borderRadius}
        right={
          loading ? (
            <InputSpinner />
          ) : error ? (
            <CrudStatusIndicator
              crudStatus={{
                hasError: true,
                errorMessage: getApolloErrorMessage(error),
              }}
            />
          ) : (
            <Icon icon={stenaAngleDown} size={14} />
          )
        }
        renderContent={({ onRequestClose }) => (
          <Box width={"100%"}>
            <NegotiationStatusSelect
              value={customer.negotiationStatusByYear ?? undefined}
              onValueChange={(e) => setCustomerNegotiationStatus(e)}
              autoFocus
              openMenuOnFocus
              onMenuClose={onRequestClose}
              menuPortalTarget={document.body}
            />
          </Box>
        )}
      />
    </>
  );
};
