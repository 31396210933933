import React, { ErrorInfo } from "react";

export interface FallbackProps {
  error?: Error;
  resetErrorBoundary: () => void;
}

export interface ErrorBoundaryProps {
  onReset?: () => void;
  onError?: (error: Error, info: ErrorInfo) => void;
  renderFallback: (props: FallbackProps) => React.ReactElement<any, any> | null;
}

const initialState = { error: undefined };

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error?: Error }
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state = initialState;
  resetErrorBoundary = () => {
    this.props.onReset?.();
    this.reset();
  };

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info);
  }

  render() {
    const { error } = this.state;
    const { renderFallback } = this.props;

    if (error != null) {
      const props: FallbackProps = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (typeof renderFallback === "function") {
        return renderFallback(props);
      } else {
        throw new Error("react-error-boundary requires a renderFallback prop");
      }
    }

    return this.props.children;
  }
}
