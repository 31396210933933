import { convertEditorValueToValidInput } from "@/common/numbers";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Indent } from "@stenajs-webui/core";
import { TextInput } from "@stenajs-webui/forms";
import {
  StandardTableCellRenderer,
  UseGridCellResult,
} from "@stenajs-webui/grid";
import {
  EditableEntityState,
  ModifiedFieldItemState,
} from "@stenajs-webui/redux";
import { Tooltip } from "@stenajs-webui/tooltip";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue } from "../../../common/regex/InputFieldValidation";
import { convertValueForTableCellWithOnlyTwoDecimals } from "../../../common/transformers/TableCellTransformers";
import { NoShowLateHandlingRoutePricesModifiedField } from "./NoShowLateHandlingRoutePricesModifiedField";
import { NoShowLateHandlingPricesRow } from "./config/RowsAndColumnsConfig";
import { noShowLateHandlingRoutePricesActions } from "./redux/actions";

type EditableNoShowLateHandlingTableFields =
  | "noShowEur"
  | "noShowGbp"
  | "noShowSek"
  | "noShowDkk"
  | "noShowNok"
  | "noShowPln"
  | "lateHandlingEur"
  | "lateHandlingGbp"
  | "lateHandlingSek"
  | "lateHandlingDkk"
  | "lateHandlingNok"
  | "lateHandlingPln";

type AcceptedTypes = string | undefined | number | null;

export const createNoShowLateHandlingTableCellRenderer =
  (
    field: EditableNoShowLateHandlingTableFields
  ): StandardTableCellRenderer<
    AcceptedTypes,
    EditableEntityState<NoShowLateHandlingPricesRow>
  > =>
  ({ label, item, gridCell, isEditable, value, zIndex, isSelected }) => {
    const originalValue = item.persisted[field];
    const editableValue = item.editable[field];
    const id = item.persisted.id;
    const classNames = (isEditable ? "isEditable" : "notEditable").concat(
      ` ${field}`
    );

    return (
      <div className={classNames}>
        <NoShowLateHandlingRoutePricesTableCell
          persistedValue={String(originalValue ?? "")}
          editableValue={String(editableValue ?? "")}
          value={label}
          entityId={id}
          gridCell={gridCell}
          isEditable={isEditable}
          field={field}
        />
      </div>
    );
  };

interface NoShowLateHandlingTableCellProps {
  entityId: string;
  value: string;
  isEditable?: boolean;
  gridCell: UseGridCellResult<string>;
  field: EditableNoShowLateHandlingTableFields;
  persistedValue: string;
  editableValue: string;
}

export const NoShowLateHandlingRoutePricesTableCell: React.FC<
  NoShowLateHandlingTableCellProps
> = ({
  entityId,
  value,
  isEditable,
  gridCell,
  persistedValue,
  editableValue,
  field,
}) => {
  const dispatch = useDispatch();
  const [isValidInput, setIsValidInput] = useState<boolean>(true);

  const modifiedField: ModifiedFieldItemState =
    editableValue === persistedValue
      ? { id: "" }
      : { id: "", modified: true, newValue: value };

  const onDone = useCallback(() => {
    const validationResult =
      inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue.test(
        gridCell.editorValue
      );

    if (!validationResult) {
      setIsValidInput(true);
      gridCell.revertEditorValue();
      gridCell.stopEditing();
      return;
    }

    dispatch(
      noShowLateHandlingRoutePricesActions.setFieldsForRow(entityId, {
        [field]: convertEditorValueToValidInput(gridCell.editorValue),
      })
    );
    gridCell.stopEditingAndMove("right");
  }, [dispatch, field, entityId, gridCell]);

  const validateInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult =
      inputFieldValidationWithoutPlusAndMinusSignsAndNoLimitRegardingMaxValue.test(
        e.target.value
      );
    setIsValidInput(validationResult);
  };

  const currentValue = gridCell.editorValue
    ? convertValueForTableCellWithOnlyTwoDecimals(Number(gridCell.editorValue))
    : "Not set";

  return isEditable && gridCell.isEditing ? (
    <Tooltip
      label={"Please provide a valid input"}
      zIndex={ZIndex.high}
      variant="warning"
      disabled={isValidInput}
    >
      <Indent flex={1} row alignItems={"center"} width={100}>
        <TextInput
          onValueChange={gridCell.setEditorValue}
          onChange={(e) => validateInputOnChange(e)}
          value={gridCell.editorValue}
          onDone={onDone}
          onEsc={gridCell.stopEditingAndRevert}
          autoFocus
          selectAllOnMount={!gridCell.lastKeyEvent}
          variant={!isValidInput ? "warning" : "standard"}
        />
      </Indent>
    </Tooltip>
  ) : (
    <Indent row alignItems={"center"} width={100}>
      <NoShowLateHandlingRoutePricesModifiedField
        value={currentValue || "Not set"}
        modifiedField={modifiedField}
        isEditable={isEditable}
      />
    </Indent>
  );
};
