import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import {
  oneDecimalNoTrailingZero,
  percentLabelFormatterDecimalValue,
} from "../utils/statisticsUtil";
import { formatThousands } from "@/common/numbers";
export interface SailingCategoryStatisticsObjectType {
  id: string;
  sailingCat: string;
  acc: number;
  unacc: number;
  total: number;
}
export const sailingCategoryStatisticsTableConfig = (
  displayPercentageAfterValue: boolean
): StandardTableConfig<
  SailingCategoryStatisticsObjectType,
  "sailingCat" | "acc" | "unacc" | "total"
> => {
  const displayValue = (value: number) =>
    displayPercentageAfterValue
      ? percentLabelFormatterDecimalValue(value)
      : value === 0
      ? "-"
      : formatThousands(oneDecimalNoTrailingZero(value));

  return {
    keyResolver: (item) => String(item.id),
    disableSorting: false,
    disableInfiniteList: true,
    columns: {
      sailingCat: createColumnConfig((item) => item.sailingCat, {
        columnLabel: "",
        borderLeft: true,
      }),
      acc: createColumnConfig((item) => displayValue(item.acc), {
        columnLabel: "Acc",
        borderLeft: true,
      }),
      unacc: createColumnConfig((item) => displayValue(item.unacc), {
        columnLabel: "Unacc",
        borderLeft: true,
      }),
      total: createColumnConfig((item) => displayValue(item.total), {
        columnLabel: "Total",
        borderLeft: true,
      }),
    },
    columnOrder: ["sailingCat", "acc", "unacc", "total"],
  };
};
