import * as React from "react";
import { CustomerRouteStatisticsBox } from "./CustomerRouteStatisticsBox";
import { StoreState } from "../../../config/redux/RootReducer";
import { useSelector } from "react-redux";
import { GridContainer } from "../../../common/components/grid-container/GridContainer";

interface Props {}

const selector = (state: StoreState) => state.statBoxes;

export const CustomerRouteStatisticsBoxes: React.FC<Props> = () => {
  const { statBoxesData } = useSelector(selector);

  return (
    <GridContainer boxMinWidth={500}>
      <CustomerRouteStatisticsBox
        sailingTypeCode={"P"}
        sailingTypeName={"Peak"}
        items={statBoxesData?.peakData}
      />
      <CustomerRouteStatisticsBox
        sailingTypeCode={"S"}
        sailingTypeName={"Shoulder"}
        items={statBoxesData?.shoulderData}
      />
      <CustomerRouteStatisticsBox
        sailingTypeCode={"O"}
        sailingTypeName={"Off-peak"}
        items={statBoxesData?.offPeakData}
      />
    </GridContainer>
  );
};
