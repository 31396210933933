import { useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  ResultListBanner,
  SecondaryButton,
  stenaPen,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useCallback } from "react";
import { Placement } from "tippy.js";
import { SwitchHeadAgreementModal } from "../../list/components/SwitchHeadAgreementModal";
import { useChangeHeadAgreementStatusMutation } from "../hooks/UseChangeHeadAgreementStatusMutation";
import { DeleteHeadAgreementModal } from "./DeleteHeadAgreementModal";
import { HeadAgreementMenu } from "./HeadAgreementMenu";
import { uniq } from "lodash";
import { LoadingModalWithLock } from "@/common/components/loading-modals/LoadingModalWithLock";
import { stripGqlErrorText } from "@/common/string/GraphQlErrorParser";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import {
  CustomerHeaderCustomersByIdFragment,
  HeadAgreementInfoRowSectionFragment,
  HeadAgreementStatusCode,
} from "@/gql/graphql";

interface Props {
  customer: CustomerHeaderCustomersByIdFragment | null | undefined;
  headAgreement: HeadAgreementInfoRowSectionFragment;
  placement?: Placement;
}

export const HeadAgreementActionButtons: React.FC<Props> = ({
  customer,
  headAgreement,
  placement = "left",
}) => {
  const [switchModalIsOpen, setSwitchOpen, setSwitchClosed] = useBoolean(false);
  const [deleteModalIsOpen, setDeleteOpen, setDeleteClosed] = useBoolean(false);

  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);
  const [loading, setLoading, setNotLoading] = useBoolean(false);

  const [changeHeadAgreementStatus] = useChangeHeadAgreementStatusMutation();

  const {
    bannerState,
    setBannerState,
    setBannerResultWithTexts,
    clearBannerResult,
  } = useResultListBannerState(undefined);

  const onChangeStatus = useCallback(
    async (statusCode: HeadAgreementStatusCode) => {
      setLoading();
      closeActionMenu();
      clearBannerResult();

      try {
        const response = await changeHeadAgreementStatus({
          variables: {
            headAgreementId: headAgreement.id,
            rowVersion: headAgreement.rowVersion,
            status: statusCode,
          },
          refetchQueries: ["HeadAgreement"],
          awaitRefetchQueries: true,
        });

        const updateResult =
          response.data?.productPrice.headAgreement.changeHeadAgreementStatus;
        if (updateResult && "errors" in updateResult) {
          setBannerState({ headerText: "Failed to update status" });
          const distinctErrorMessages = uniq(
            updateResult.errors.map((error) => error.message)
          );
          setBannerResultWithTexts(distinctErrorMessages);
        }
      } catch (e) {
        setBannerState({ headerText: "Failed to update status" });
        setBannerResultWithTexts([stripGqlErrorText(e.message)]);
      } finally {
        setNotLoading();
      }
    },
    [
      closeActionMenu,
      setLoading,
      setNotLoading,
      clearBannerResult,
      setBannerState,
      setBannerResultWithTexts,
      changeHeadAgreementStatus,
      headAgreement.id,
      headAgreement.rowVersion,
    ]
  );

  return (
    <Popover
      visible={!!bannerState || loading}
      disablePadding
      placement={placement}
      animation={false}
      content={
        loading ? (
          <LoadingModalWithLock
            label={
              "Changing status of header agreement. Thanks for your patience."
            }
          />
        ) : (
          bannerState && (
            <ResultListBanner
              bannerState={bannerState}
              variant={"error"}
              contentRight={
                <FlatButton label={"Close"} onClick={clearBannerResult} />
              }
            />
          )
        )
      }
    >
      <>
        <Popover
          placement={"left"}
          animation={false}
          visible={actionMenuOpen}
          disablePadding
          onClickOutside={closeActionMenu}
          appendTo={document.body}
          content={
            <HeadAgreementMenu
              headAgreement={headAgreement}
              customer={customer}
              onOpenSwitchModal={setSwitchOpen}
              onOpenDeleteModal={setDeleteOpen}
              onRequestClose={closeActionMenu}
              onChangeStatus={onChangeStatus}
            />
          }
        >
          <SecondaryButton
            label="Actions"
            leftIcon={stenaPen}
            onClick={actionMenuOpen ? closeActionMenu : openActionMenu}
            data-testid={testIdConstantsWebapp.headerAgreementActionsMenu}
          />
        </Popover>

        {switchModalIsOpen && (
          <SwitchHeadAgreementModal
            customerId={customer?.id ?? ""}
            onRequestClose={setSwitchClosed}
            selectedHeadAgreementId={headAgreement.id}
          />
        )}
        {deleteModalIsOpen && (
          <DeleteHeadAgreementModal
            headAgreement={headAgreement}
            onRequestClose={setDeleteClosed}
          />
        )}
      </>
    </Popover>
  );
};
