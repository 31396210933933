import { SortArticleBy } from "./components/ArticleSortBySelect";
import {
  ArticleItemFormModel,
  ArticlePriceFormModel,
  ArticlesFormModel,
  ArticlesFormulaDataFormModel,
} from "./models/ArticlesFormModel";

export type ArticleFormAction =
  | ArticleFormSetFormModelAction
  | ArticleFormRemoveArticleTypeAction
  | ArticleFormSetArticleTypesKeepArticlesAction
  | ArticleFormSetArticleFieldsAction
  | ArticleFormSetArticlePriceFieldsAction
  | ArticleFormAddArticlePriceAction
  | ArticleFormSetFormulaDataFieldsAction
  | ArticleFormDeleteArticlePriceAction
  | ArticleFormCopyArticlePriceAction
  | ArticleFormSortByAction
  | ArticleFormToggleExpandedCardAction
  | ArticleFormToggleAllExpandedCardAction
  | ArticleFormTriggerSortByAction;

interface ArticleFormSetFormModelAction {
  type: "ARTICLE_FORM:SET_FORM_MODEL";
  formModel: ArticlesFormModel;
}
interface ArticleFormRemoveArticleTypeAction {
  type: "ARTICLE_FORM:REMOVE_ARTICLE_TYPE";
  articleId: string;
}

interface ArticleFormSetArticleTypesKeepArticlesAction {
  type: "ARTICLE_FORM:SET_ARTICLE_TYPES_KEEP_ARTICLES";
  articleIds: Array<string>;
}

interface ArticleFormToggleExpandedCardAction {
  type: "ARTICLE_FORM:TOGGLE_EXPANDED_CARD";
  articleId: string;
}

interface ArticleFormToggleAllExpandedCardAction {
  type: "ARTICLE_FORM:TOGGLE_ALL_EXPANDED_CARD";
}

interface ArticleFormSetArticleFieldsAction {
  type: "ARTICLE_FORM:SET_ARTICLE_FIELDS";
  articleId: string;
  fields: Partial<ArticleItemFormModel>;
}

interface ArticleFormSetArticlePriceFieldsAction {
  type: "ARTICLE_FORM:SET_ARTICLE_PRICE_FIELDS";
  articlePriceId: string;
  articleId: string;
  fields: Partial<ArticlePriceFormModel>;
}

interface ArticleFormAddArticlePriceAction {
  type: "ARTICLE_FORM:ADD_ARTICLE_PRICE";
  articleId: string;
}

interface ArticleFormSetFormulaDataFieldsAction {
  type: "ARTICLE_FORM:SET_FORMULA_DATA_FIELDS";
  articleId: string;
  fields: Partial<ArticlesFormulaDataFormModel>;
}

interface ArticleFormDeleteArticlePriceAction {
  type: "ARTICLE_FORM:DELETE_ARTICLE_PRICE";
  articlePriceId: string;
}

interface ArticleFormCopyArticlePriceAction {
  type: "ARTICLE_FORM:COPY_ARTICLE_PRICE";
  articlePriceId: string;
}

interface ArticleFormSortByAction {
  type: "ARTICLE_FORM:SORT_ARTICLES_BY";
  sortBy: SortArticleBy;
}

interface ArticleFormTriggerSortByAction {
  type: "ARTICLE_FORM:UPDATE_SORT_ARTICLES_BY";
}

export const articleFormActions = {
  setFormModel: (
    formModel: ArticlesFormModel
  ): ArticleFormSetFormModelAction => ({
    type: "ARTICLE_FORM:SET_FORM_MODEL",
    formModel,
  }),
  removeArticleType: (
    articleId: string
  ): ArticleFormRemoveArticleTypeAction => ({
    type: "ARTICLE_FORM:REMOVE_ARTICLE_TYPE",
    articleId,
  }),
  setArticleTypesKeepArticles: (
    articleIds: Array<string>
  ): ArticleFormSetArticleTypesKeepArticlesAction => ({
    type: "ARTICLE_FORM:SET_ARTICLE_TYPES_KEEP_ARTICLES",
    articleIds,
  }),
  setArticleFields: (
    articleId: string,
    fields: Partial<ArticleItemFormModel>
  ): ArticleFormSetArticleFieldsAction => ({
    type: "ARTICLE_FORM:SET_ARTICLE_FIELDS",
    articleId,
    fields,
  }),
  setArticlePriceFields: (
    articleId: string,
    articlePriceId: string,
    fields: Partial<ArticlePriceFormModel>
  ): ArticleFormSetArticlePriceFieldsAction => ({
    type: "ARTICLE_FORM:SET_ARTICLE_PRICE_FIELDS",
    articleId,
    articlePriceId,
    fields,
  }),
  addArticlePrice: (articleId: string): ArticleFormAddArticlePriceAction => ({
    type: "ARTICLE_FORM:ADD_ARTICLE_PRICE",
    articleId,
  }),
  setFormulaDataFields: (
    articleId: string,
    fields: Partial<ArticlesFormulaDataFormModel>
  ): ArticleFormSetFormulaDataFieldsAction => ({
    type: "ARTICLE_FORM:SET_FORMULA_DATA_FIELDS",
    articleId,
    fields,
  }),
  toggleExpandedCard: (
    articleId: string
  ): ArticleFormToggleExpandedCardAction => ({
    type: "ARTICLE_FORM:TOGGLE_EXPANDED_CARD",
    articleId,
  }),
  toggleAllExpandedCard: (): ArticleFormToggleAllExpandedCardAction => ({
    type: "ARTICLE_FORM:TOGGLE_ALL_EXPANDED_CARD",
  }),
  copyArticlePrice: (
    articlePriceId: string
  ): ArticleFormCopyArticlePriceAction => ({
    type: "ARTICLE_FORM:COPY_ARTICLE_PRICE",
    articlePriceId,
  }),
  deleteArticlePrice: (
    articlePriceId: string
  ): ArticleFormDeleteArticlePriceAction => ({
    type: "ARTICLE_FORM:DELETE_ARTICLE_PRICE",
    articlePriceId,
  }),
  sortArticleForm: (sortBy: SortArticleBy): ArticleFormSortByAction => ({
    type: "ARTICLE_FORM:SORT_ARTICLES_BY",
    sortBy,
  }),
  triggerArticleSorting: (): ArticleFormTriggerSortByAction => ({
    type: "ARTICLE_FORM:UPDATE_SORT_ARTICLES_BY",
  }),
};
