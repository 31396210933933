import * as React from "react";
import { CreateMultiLegAgreementRouteFormModel } from "../../common/components/create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";
import { CreateMultiLegRouteAgreement } from "./CreateMultiLegRouteAgreement";
import { createMultiLegFormModel } from "./transformers/CreateMultiLegFormModel";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  customerId: string;
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  name?: string;
  selectedRouteCode?: string;
  onRequestClose: () => void;
}

export const CreateMultiLegRouteAgreementSetup: React.FC<Props> = (props) => {
  const { multiLegAgreement, selectedRouteCode, name } = props;

  const multiLegFormModel: CreateMultiLegAgreementRouteFormModel =
    createMultiLegFormModel(multiLegAgreement, selectedRouteCode, name);

  const routeLegOptions: Array<string> = multiLegAgreement.routeLegs.map(
    (routeLeg) => routeLeg.legRoute.code
  );

  return (
    <CreateMultiLegRouteAgreement
      {...props}
      multiLegAgreement={multiLegAgreement}
      multiLegFormModel={multiLegFormModel}
      routeLegOptions={routeLegOptions}
    />
  );
};
