import { combineReducers } from "redux";
import {
  createFppSearchFilterReducer,
  SearchFilterState,
} from "@/features/search-filter/redux/reducer";

export interface StatisticsBySellerState {
  filter: SearchFilterState;
}

export const statisticsBySellerReducer =
  combineReducers<StatisticsBySellerState>({
    filter: createFppSearchFilterReducer("statistics.statisticsBySeller"),
  });
