import { useArraySet } from "@stenajs-webui/core";
import { Tag, stenaSailingRoute } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import { CollapsibleWithCheckbox } from "@stenajs-webui/panels";
import { groupBy, reject } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { RoutePair } from "../../types/FilterEntitys";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useFetchAllRoutePairs } from "../../hooks/FilterFetchers/UseFetchAllRoutePairs";

const RoutePairBySeaAreaCollapsible = ({
  seaArea,
  groupedRoutePair,
  selectedRoutePairs,
  setRoutePairs,
  checkboxClickHandler,
}: {
  seaArea: string;
  groupedRoutePair: RoutePair[];
  selectedRoutePairs: RoutePair[];
  setRoutePairs: (routePairs: RoutePair[]) => void;
  checkboxClickHandler: (_: boolean, id: string) => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const allChecked = groupedRoutePair.filter((pair) =>
    selectedRoutePairs.find((selected) => pair.id === selected.id)
  );

  const handleGroupChecked = () => {
    if (allChecked.length > 0) {
      setRoutePairs(
        reject(selectedRoutePairs, (pair) =>
          allChecked.find((p) => p.id === pair.id)
        ) as RoutePair[]
      );
    } else {
      setRoutePairs(selectedRoutePairs.concat(groupedRoutePair));
    }
  };

  return (
    <CollapsibleWithCheckbox
      value={allChecked.length === groupedRoutePair.length}
      indeterminate={
        allChecked.length > 0 && allChecked.length !== groupedRoutePair.length
      }
      onChange={handleGroupChecked}
      label={seaArea}
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)}
      contentRight={
        allChecked.length > 0 && (
          <Tag label={String(allChecked.length)} size="small" />
        )
      }
    >
      <FilterCheckboxList maxHeight={"400px"}>
        {groupedRoutePair.map((routePair) => {
          return (
            <FilterCheckbox
              key={routePair.id}
              value={
                !!selectedRoutePairs.find(
                  (selectedRoutePair) => selectedRoutePair.id === routePair.id
                )
              }
              label={routePair.name}
              onValueChange={(v) => checkboxClickHandler(v, routePair.id)}
            />
          );
        })}
      </FilterCheckboxList>
    </CollapsibleWithCheckbox>
  );
};

interface Props {
  enabled?: boolean;
  label?: string;
}
export const RouteFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <RouteFilterSectionInternal {...props} /> : <></>;

const RouteFilterSectionInternal: React.FC<Props> = ({ enabled, label }) => {
  const { routePairs, setRoutePairs } = useFilterWorkspace();
  const { items, loading, error, refetch } = useFetchAllRoutePairs();

  const grouped = groupBy(items, (item) => item.seaArea?.name);

  const { toggle } = useArraySet(
    routePairs,
    setRoutePairs,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = items.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };

  return (
    <FppSearchFilterSection
      sectionId={"routePairs"}
      label={label}
      enabled={enabled}
      numSelected={routePairs.length}
      leftIcon={stenaSailingRoute}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={Object.keys(grouped).map((seaArea) => {
        return (
          <React.Fragment key={seaArea}>
            <RoutePairBySeaAreaCollapsible
              selectedRoutePairs={routePairs}
              setRoutePairs={setRoutePairs}
              seaArea={seaArea}
              checkboxClickHandler={checkboxClickHandler}
              groupedRoutePair={grouped[seaArea]}
            />
          </React.Fragment>
        );
      })}
    />
  );
};
