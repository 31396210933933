import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { errorFragment } from "../../../../common/error/GqlErrorFragment";
import {
  UpdateExpectedVolumesInput,
  UpdateExpectedVolumesMutation,
  UpdateExpectedVolumesMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  ${errorFragment}
  mutation UpdateExpectedVolumes($input: UpdateExpectedVolumesInput!) {
    productPrice {
      expectedVolume {
        updateExpectedVolumes(input: $input) {
          ... on UpdateExpectedVolumesSuccessResult {
            volumes {
              id
              expectedVolume
              volume12Months
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useUpdateExpectedVolumesMutation = () => {
  const [mutate, { error, loading }] = useMutation<
    UpdateExpectedVolumesMutation,
    UpdateExpectedVolumesMutationVariables
  >(mutation);

  const updateExpectedVolumes = useCallback(
    (input: UpdateExpectedVolumesInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );

  return {
    updateExpectedVolumes,
    error,
    loading,
  };
};
