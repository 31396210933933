import * as React from "react";
import { useContext, useMemo } from "react";
import { Row } from "@stenajs-webui/core";
import { StatisticsBySalesRegionDetailsHeader } from "./StatisticsBySalesRegionDetailsHeader";
import { useSalesRegionDetailsVolumeAndRatesQuery } from "../hooks/UseSalesRegionDetailsVolumeAndRatesQuery";
import { useSalesRegionDetailsSummaryQuery } from "../hooks/UseSalesRegionDetailsSummaryQuery";
import { Helmet } from "react-helmet";
import { Spinner } from "@stenajs-webui/elements";
import { transformSelectedStatusesToNegotiationStatusCodes } from "@/features/statistics/common/transformers/NegotiationStatusFormModelTransformer";
import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { StatisticsDetailsView } from "@/features/statistics/common/statistics-details-view/StatisticsDetailsView";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import {
  NegotiationStatusCode,
  UseSalesRegionDetailsSummaryQuery,
} from "@/gql/graphql";

interface Props {
  salesRegionId: string;
  year: number;
}

export const StatisticsBySalesRegionDetails: React.FC<Props> = ({
  salesRegionId,
  year,
}) => {
  const { summary, loading } = useSalesRegionDetailsSummaryQuery(
    salesRegionId,
    year
  );
  const { handleCurrencyChange, currency } = useCurrencySelection();

  return (
    <>
      <Helmet title="Sales Regions - Statistics - FPP" />
      <StatisticsBySalesRegionDetailsHeader
        salesRegionId={salesRegionId}
        currency={currency}
        handleCurrencyChange={handleCurrencyChange}
      />
      {loading || !summary ? (
        <Row justifyContent={"center"}>
          <Spinner />
        </Row>
      ) : (
        <DetailsDataLoader
          currency={currency}
          year={year}
          salesRegionId={salesRegionId}
          summary={summary}
        />
      )}
    </>
  );
};

const DetailsDataLoader = ({
  currency,
  salesRegionId,
  summary,
  year,
}: {
  year: number;
  salesRegionId: string;
  summary: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"];
  currency: string;
}) => {
  const { salesRegionSelectedStatuses, setSalesRegionSelectedStatuses } =
    useContext(StatisticsContext) as StatisticsContextType;

  const transformedNegotiationStatusCodes = useMemo(
    () =>
      transformSelectedStatusesToNegotiationStatusCodes(
        salesRegionSelectedStatuses
      ),
    [salesRegionSelectedStatuses]
  );

  const negotiationStatusCodes =
    transformedNegotiationStatusCodes.length === 0
      ? Object.values(NegotiationStatusCode)
      : transformedNegotiationStatusCodes;
  const {
    volumeAndRates,
    loading: volumeAndRatesLoading,
    averageMeterPrices,
  } = useSalesRegionDetailsVolumeAndRatesQuery(
    salesRegionId,
    currency,
    negotiationStatusCodes,
    year
  );

  return (
    <StatisticsDetailsView
      currency={currency}
      year={year}
      summary={summary}
      selectedNegotiationStatuses={salesRegionSelectedStatuses}
      setSelectedNegotiationStatuses={setSalesRegionSelectedStatuses}
      volumeAndRates={volumeAndRates}
      volumeAndRatesLoading={volumeAndRatesLoading}
      averageMeterPrices={averageMeterPrices}
    />
  );
};
