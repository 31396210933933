import { DateRange } from "@stenajs-webui/calendar";

export interface CreateMultiLegAgreementFormModel {
  name: string;
  headAgreementId: string;
  currencyCode: string;
  routeCode: string;
  valid: DateRange;
}

export interface EditMultiLegAgreementFormModel {
  name: string;
  multiLegAgreementId: string;
  currencyCode: string;
  rowVersion: string;
  valid: DateRange;
}

export const emptyMultiLegAgreementFormModel: CreateMultiLegAgreementFormModel =
  {
    name: "",
    headAgreementId: "",
    currencyCode: "EUR",
    routeCode: "",
    valid: {},
  };
