import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { profileSelectors } from "../selectors";
import { profileActions } from "../actions";

export interface CurrencySelection {
  currency: string;
  handleCurrencyChange: (currency: string) => void;
}

export const useCurrencySelection = (): CurrencySelection => {
  const { currentCurrency } = useSelector(profileSelectors.getGlobal);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(currentCurrency);

  useEffect(() => {
    setCurrency(currentCurrency);
  }, [currentCurrency]);

  const handleCurrencyChange = (selectedCurrency: string) => {
    dispatch(
      profileActions.setGlobalState({
        currentCurrency: selectedCurrency,
      })
    );
    setCurrency(selectedCurrency);
  };

  return {
    currency,
    handleCurrencyChange,
  };
};
