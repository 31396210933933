import { gql } from "apollo-boost";

import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../common/error/GqlErrorFragment";
import {
  UpdateHeadAgreementInput,
  UpdateHeadAgreementMutation,
  UpdateHeadAgreementMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  ${errorFragment}
  mutation UpdateHeadAgreement($input: UpdateHeadAgreementInput!) {
    productPrice {
      headAgreement {
        updateHeadAgreement(input: $input) {
          ... on UpdateHeadAgreementSuccessResult {
            headAgreement {
              id
              condition
              lastPage
              rowVersion
              specifications
              internalComments
              valid {
                start {
                  isoString
                }
                end {
                  isoString
                }
              }
              name
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

export const useUpdateHeadAgreementMutation = () => {
  const [mutate, { error, loading }] = useMutation<
    UpdateHeadAgreementMutation,
    UpdateHeadAgreementMutationVariables
  >(mutation);

  const updateHeadAgreement = useCallback(
    (input: UpdateHeadAgreementInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );

  return {
    error,
    loading,
    updateHeadAgreement,
  };
};
