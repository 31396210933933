import * as React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { CustomerNegotiationStatusEditor } from "./CustomerNegotiationStatusEditor";
import { ErrorMessage } from "../../../../../common/components/validation-error-message/ErrorMessage";
import { InputSpinner } from "@stenajs-webui/elements";
import { Spacing } from "@stenajs-webui/core";
import {
  CustomerNegotiationStatusEditorFetcherQuery,
  CustomerNegotiationStatusEditorFetcherQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  negotiationYear: number;
  border?: string;
  borderRadius?: string;
}

const query = gql`
  fragment NegotiationStatusFetcherCustomer on Customer {
    id
    isParent
    name
    custNo
    custIndex
    noOfChildren
    negotiationStatusByYear(year: $year) {
      id
      name
      presentationOrder
    }
  }
  query CustomerNegotiationStatusEditorFetcher($customerId: ID!, $year: Int!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...NegotiationStatusFetcherCustomer
        }
      }
    }
  }
`;

export const CustomerNegotiationStatusEditorFetcher: React.FC<Props> = ({
  customerId,
  negotiationYear,
  border,
  borderRadius,
}) => {
  const { data, loading, error } = useQuery<
    CustomerNegotiationStatusEditorFetcherQuery,
    CustomerNegotiationStatusEditorFetcherQueryVariables
  >(query, { variables: { customerId, year: negotiationYear } });

  const customer = data?.productPrice.customers.byId;

  if (error) {
    return (
      <Spacing>
        <ErrorMessage text={error.message} />
      </Spacing>
    );
  }

  if (loading) {
    return (
      <Spacing>
        <InputSpinner />
      </Spacing>
    );
  }

  if (!customer) {
    return null;
  }

  return (
    <CustomerNegotiationStatusEditor
      customer={customer}
      border={border}
      borderRadius={borderRadius}
      negotiationYear={negotiationYear}
      isInTable={false}
    />
  );
};
