import * as React from "react";
import { useMemo } from "react";
import { MultiSelect, MultiSelectProps } from "@stenajs-webui/select";

import { gql, useQuery } from "@apollo/client";
import {
  CountryMultiSelectAllQuery,
  CountryMultiSelectAllActiveQuery,
} from "@/gql/graphql";

interface Props extends Omit<MultiSelectProps<any>, "value" | "onChange"> {
  value?: Array<string>;
  onValueChange?: (value: Array<string>) => void;
  optionsToRemove?: Array<string>;
}

const queryAll = gql`
  query CountryMultiSelectAll {
    productPrice {
      country {
        all {
          id
          code
          name
        }
      }
    }
  }
`;

const queryAllActive = gql`
  query CountryMultiSelectAllActive {
    productPrice {
      country {
        allActive {
          id
          code
          name
        }
      }
    }
  }
`;

export const useAllCountries = () =>
  useQuery<CountryMultiSelectAllQuery>(queryAll);

export const useAllActiveCountries = () =>
  useQuery<CountryMultiSelectAllActiveQuery>(queryAllActive);

export const CountryMultiSelect: React.FC<Props> = ({
  onValueChange,
  value,
  optionsToRemove = [],
  ...multiSelectProps
}) => {
  const { data, loading } = useAllCountries();

  const countries = useMemo(() => {
    return data?.productPrice.country.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return countries
      .filter((c) => optionsToRemove.indexOf(c.code) < 0)
      .map((r) => ({
        label: r.name,
        value: r.id,
      }));
  }, [countries, optionsToRemove]);

  const selected = useMemo(() => {
    if (!value || !value.length || !options || !options.length) {
      return [];
    }
    return options.filter((o) => {
      return value.find((v) => o.value === v);
    });
  }, [options, value]);

  return (
    <MultiSelect
      isLoading={loading}
      options={options}
      value={selected}
      onChange={(v: any) =>
        onValueChange?.(v?.map((vv: any) => vv.value) ?? [])
      }
      {...multiSelectProps}
    />
  );
};
