import { css, Global } from "@emotion/react";
import * as React from "react";
import { NoShowLateHandlingHandleBookingsScreen } from "./NoShowLateHandlingHandleBookingsScreen";
import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";

interface Props {}

export const NoShowLateHandlingHandleBookingsPanel: React.FC<Props> = () => {
  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <SearchFilterSelectorProvider
        reducerId={"noShowLateHandlingHandleBookings"}
      >
        <NoShowLateHandlingHandleBookingsScreen />
      </SearchFilterSelectorProvider>
    </>
  );
};
