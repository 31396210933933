import { ExecutionResult, gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import {
  UpdateRoutePairCustomerTargetInput,
  TargetRoutePairInput,
  UpdateRoutePairCustomerTargetMutation,
  UpdateRoutePairCustomerTargetMutationVariables,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import { fetchRoutePairCustomerTargetFragment } from "../../features/route-pair-target-table/redux/thunks/CustomerRouteTargetFetcher";

const mutation = gql`
  ${fetchRoutePairCustomerTargetFragment}
  ${errorFragment}
  mutation UpdateRoutePairCustomerTarget(
    $input: UpdateRoutePairCustomerTargetInput!
    $filter: TargetRoutePairInput!
  ) {
    productPrice {
      routePairCustomerTarget {
        updateRoutePairCustomerTargets(input: $input, filter: $filter) {
          __typename
          ... on RoutePairCustomerTargetSuccessResult {
            targetRoutePairs {
              ...FetchRoutePairCustomerTarget
            }
          }
          ... on ErrorResult {
            ...ErrorFragment
          }
        }
      }
    }
  }
`;

interface UseUpdateRoutePairCustomerTargetMutation {
  updateRoutePairCustomerTargets: (
    input: UpdateRoutePairCustomerTargetInput,
    filter: TargetRoutePairInput
  ) => Promise<ExecutionResult<UpdateRoutePairCustomerTargetMutation>>;
  loading: boolean;
  error: FreightPricingPortalError[] | undefined;
}

export const useUpdateRoutePairCustomerTargetMutation =
  (): UseUpdateRoutePairCustomerTargetMutation => {
    const [mutate, { loading, error }] = useMutation<
      UpdateRoutePairCustomerTargetMutation,
      UpdateRoutePairCustomerTargetMutationVariables
    >(mutation);

    const fppError = useMemo(() => {
      return error && transformApolloErrorToFreightPricingPortalErrors(error);
    }, [error]);

    const updateRoutePairCustomerTargets = useCallback(
      (
        input: UpdateRoutePairCustomerTargetInput,
        filter: TargetRoutePairInput
      ) => {
        return mutate({
          variables: {
            input,
            filter,
          },
          refetchQueries: ["TargetSummary"],
        });
      },
      [mutate]
    );

    return {
      updateRoutePairCustomerTargets,
      loading,
      error: fppError,
    };
  };
