import { apolloClient } from "@/apollo-client/ApolloClient";
import { AppThunk } from "../../../../../../../config/redux/RootReducer";
import { adminTargetActions } from "../actions";
import { adminTargetAllRoutesMutation } from "./gql";
import {
  AdminTargetAllRoutesMutation,
  AdminTargetAllRoutesMutationVariables,
  UpdateGroupFreightTargetInput,
} from "@/gql/graphql";

export type UpdateAllRoutesAdminData = Array<UpdateGroupFreightTargetInput>;

export const updateAllRoutesAdminTarget =
  (data: UpdateAllRoutesAdminData): AppThunk =>
  async (dispatch) => {
    dispatch(adminTargetActions.setProgress({ loading: true }));

    try {
      const { errors } = await apolloClient.mutate<
        AdminTargetAllRoutesMutation,
        AdminTargetAllRoutesMutationVariables
      >({
        variables: {
          updateGroupFreightTargetsInput: data,
        },
        mutation: adminTargetAllRoutesMutation,
      });

      if (errors && errors.length) {
        dispatch(
          adminTargetActions.setProgress({
            bannerState: {
              headerText: "Could not fetch all routes data",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        adminTargetActions.setProgress({
          bannerState: {
            headerText: "Could not fetch all routes data",
            items: [{ text: error.message }],
          },
        })
      );
    } finally {
      dispatch(adminTargetActions.setProgress({ loading: false }));
    }
  };
