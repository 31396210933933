interface EntityWithAccUnacc {
  unaccompanied: boolean;
  accompanied: boolean;
}

export type AccUnaccCode = "BOTH" | "ACC" | "UNACC";

export const allAccUnaccCodes: Array<AccUnaccCode> = ["BOTH", "ACC", "UNACC"];
export const accUnaccLabels: Record<AccUnaccCode, string> = {
  BOTH: "Both",
  ACC: "Accompanied",
  UNACC: "Unaccompanied",
};

export const transformAccUnaccEntityToUnaccBooleanOrNull = ({
  accompanied,
  unaccompanied,
}: EntityWithAccUnacc): boolean | null =>
  transformAccUnaccToUnaccBooleanOrNull(accompanied, unaccompanied);

export const transformAccUnaccToUnaccBooleanOrNull = (
  accompanied: boolean,
  unaccompanied: boolean
): boolean | null => {
  if (accompanied && !unaccompanied) {
    return false;
  }
  if (!accompanied && unaccompanied) {
    return true;
  }
  return null;
};

export const transformUnaccBooleanToAccUnaccEntity = (
  unaccompanied: boolean | null
): EntityWithAccUnacc => {
  if (unaccompanied === true) {
    return {
      unaccompanied: true,
      accompanied: false,
    };
  }
  if (unaccompanied === false) {
    return {
      unaccompanied: false,
      accompanied: true,
    };
  }
  return {
    accompanied: true,
    unaccompanied: true,
  };
};

export const transformAccUnaccCodeToEntity = (
  code: AccUnaccCode
): EntityWithAccUnacc => {
  switch (code) {
    case "ACC":
      return {
        accompanied: true,
        unaccompanied: false,
      };

    case "UNACC":
      return {
        unaccompanied: true,
        accompanied: false,
      };

    case "BOTH":
      return {
        accompanied: true,
        unaccompanied: true,
      };
  }
};

export const transformAccUnaccCodeToUnaccompaniedBoolean = (
  code: AccUnaccCode
): boolean | null => {
  switch (code) {
    case "ACC":
      return false;

    case "UNACC":
      return true;

    case "BOTH":
      return null;
  }
};

export const transformAccUnaccEntityToCode = (
  entity: EntityWithAccUnacc
): AccUnaccCode => {
  const b = transformAccUnaccEntityToUnaccBooleanOrNull(entity);
  if (b === true) {
    return "UNACC";
  }
  if (b === false) {
    return "ACC";
  }
  return "BOTH";
};

export const transformAccUnaccEntityToSingleLetter = (code: string): string => {
  switch (code.toUpperCase()) {
    case "ACC":
      return "A";
    case "UNACC":
      return "U";
    default:
      return "";
  }
};
