import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { Indent } from "@stenajs-webui/core";
import { Checkbox } from "@stenajs-webui/forms";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { NoShowLateHandlingHandleBookingsRow } from "../config/RowsAndColumnsConfig";
import { toggleCheckedCustomerRowStateForCustomer } from "../redux/reducers";
import {
  CheckboxState,
  createNoShowLateHandlingBookingSelectionStateSelectors,
} from "../redux/selectors";

interface Props {
  customerNo: string;
  bookings: Array<NoShowLateHandlingHandleBookingsRow>;
  disabled: boolean;
}

const _CustomerDataTableCheckbox: React.FC<Props> = ({
  customerNo,
  bookings,
  disabled,
}) => {
  const dispatch = useDispatch();
  const rowSelectionState = useSelector(
    useCallback(
      (state: StoreState) =>
        createNoShowLateHandlingBookingSelectionStateSelectors.getNoShowLateHandlingCustomerSelectionState(
          state,
          customerNo,
          bookings.map((b) => b.bookingNo)
        ),
      [customerNo, bookings]
    )
  );

  const updateSelectionState = () => {
    // If the name for status 'not charge' changes in database in the future the string comparison from the enum down below has to be renamed.
    const customerBookingsWithStatusElseThanNotChargeable = bookings.filter(
      (bk) => bk.handlingStatus !== BookingStatusOptions.NOTCHARGE
    );

    dispatch(
      toggleCheckedCustomerRowStateForCustomer({
        customerNo,
        bookingNos: customerBookingsWithStatusElseThanNotChargeable.map(
          (b) => b.bookingNo
        ),
      })
    );
  };

  return (
    <Indent>
      <Checkbox
        size="small"
        checked={rowSelectionState === CheckboxState.CHECKED}
        indeterminate={rowSelectionState === CheckboxState.PARTIALLY_CHECKED}
        onChange={updateSelectionState}
        disabled={disabled}
      />
    </Indent>
  );
};

export const CustomerDataTableCheckbox = memo(_CustomerDataTableCheckbox);
