import { routeFactory } from "../../../RouteFactory";
import { NslhInvoiceTab } from "../config/tabConfig";

export const NslhRouteConstants = {
  invoiceSpecification: routeFactory.noShowLateHandling.followUpTabView(
    NslhInvoiceTab.invoiceSpecification
  ),
  overview: routeFactory.noShowLateHandling.followUpTabView(
    NslhInvoiceTab.overview
  ),
};
