export enum RaiseType {
  FIXED_PRICE = "FIXED_PRICE",
  METER_PRICE = "METER_PRICE",
  BOTH = "BOTH",
  BY_METER_PRICE = "BY_METER_PRICE",
}

export enum RaiseByType {
  PERCENT = "PERCENT",
  ABSOLUTE = "ABSOLUTE",
}

export interface BulkUpdateRatesFormModel {
  raiseType: RaiseType;
  acc: boolean;
  unacc: boolean;
  vehicleTypeIsNull: boolean;
  raiseBy: string;
  raiseByType: RaiseByType;
  numberDecimals: number;
}

export const emptyBulkUpdateRatesFormModel: BulkUpdateRatesFormModel = {
  raiseType: RaiseType.FIXED_PRICE,
  acc: true,
  unacc: true,
  vehicleTypeIsNull: false,
  raiseBy: "",
  raiseByType: RaiseByType.PERCENT,
  numberDecimals: 2,
};
