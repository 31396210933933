import * as React from "react";
import { useCallback, useState } from "react";
import { Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { ValidationErrorMessage } from "../../../../../common/components/validation-error-message/ValidationErrorMessage";
import { EditMultiLegAgreementFormModel } from "../../common/components/edit-and-create-multi-leg-agreement-form/model/EditAndCreateMultiLegAgreementFormModel";
import { EditMultiLegAgreementForm } from "../../common/components/edit-and-create-multi-leg-agreement-form/EditMultiLegAgreementForm";
import { editMultiLegRouteAgreementMutationInput } from "../../common/components/edit-and-create-multi-leg-agreement-form/edit-multi-leg-agreement-form-sections/transformers/EditMultiLegRouteAgreementMutationInput";
import { useEditMultiLegRouteAgreement } from "../../common/hooks/UseEditMultiLegRouteAgreementMutation";
import { uniq } from "lodash";

interface Props {
  multiLegFormModel: EditMultiLegAgreementFormModel;
  onRequestClose: () => void;
  customerId: string;
  headAgreementId: string;
  year: number;
}

export const EditMultiLegAgreement: React.FC<Props> = ({
  multiLegFormModel,
  onRequestClose,
  customerId,
  headAgreementId,
  year,
}) => {
  const [validationError, setValidationError] = useState<Error | undefined>();

  const [formModel, setFormModel] = useState<EditMultiLegAgreementFormModel>({
    ...multiLegFormModel,
  });

  const setFields = useCallback(
    (fields: Partial<EditMultiLegAgreementFormModel>) => {
      setFormModel({ ...formModel, ...fields });
    },
    [setFormModel, formModel]
  );

  const { editMultiLegRouteAgreement, loading, error } =
    useEditMultiLegRouteAgreement();

  const onSubmitHandler = async () => {
    setValidationError(undefined);
    try {
      const updateMultiLegRouteAgreementInput =
        editMultiLegRouteAgreementMutationInput(formModel);

      if (updateMultiLegRouteAgreementInput) {
        const { data } = await editMultiLegRouteAgreement(
          updateMultiLegRouteAgreementInput,
          customerId,
          headAgreementId,
          year
        );

        const editResult =
          data?.productPrice.multiLegRouteAgreement
            .updateMultiLegRouteAgreement;

        if (editResult && "errors" in editResult) {
          setValidationError(
            new Error(
              uniq(editResult.errors.map((error) => error.message)).join("\n")
            )
          );
        } else if (editResult) {
          onRequestClose();
        }
      }
    } catch (e) {
      setValidationError(e);
    }
  };

  return (
    <Column spacing indent>
      <EditMultiLegAgreementForm formData={formModel} setFields={setFields} />
      <Spacing />
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <ValidationErrorMessage
          error={error || validationError}
          width={"260px"}
        />
        <Row alignItems={"center"}>
          <FlatButton label={"Cancel"} onClick={onRequestClose} />
          <Indent />
          <PrimaryButton
            label={"Save changes"}
            onClick={onSubmitHandler}
            loading={loading}
          />
        </Row>
      </Row>
      <Space num={2} />
    </Column>
  );
};
