import { HeadAgreementStatusCode } from "@/gql/graphql";

export const canDeleteAgreement = (status: HeadAgreementStatusCode) => {
  return status === HeadAgreementStatusCode.Pending;
};

export const canChangeHeadAgreementStatusToActive = (
  status: HeadAgreementStatusCode
) => {
  return (
    status === HeadAgreementStatusCode.Offered ||
    status === HeadAgreementStatusCode.Pending
  );
};

export const canChangeRouteAgreementStatusToActive = (
  parentStatus: HeadAgreementStatusCode,
  status: HeadAgreementStatusCode
) => {
  return (
    status !== HeadAgreementStatusCode.Void &&
    parentStatus === HeadAgreementStatusCode.Active &&
    (status === HeadAgreementStatusCode.Offered ||
      status === HeadAgreementStatusCode.Pending)
  );
};

export const canChangeStatusToPending = (
  validFromDate: Date,
  status: HeadAgreementStatusCode
) => {
  return (
    status !== HeadAgreementStatusCode.Void &&
    (status === HeadAgreementStatusCode.Active ||
      status === HeadAgreementStatusCode.Offered) &&
    validFromDate > new Date()
  );
};

export const canChangeStatusToVoid = (status: HeadAgreementStatusCode) => {
  return status === HeadAgreementStatusCode.Active;
};
