import * as React from "react";
import { Children } from "react";
import { Box, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { Dot } from "../../Dot";
import { MiniTableCell } from "./MiniTableCell";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";

export interface BarChartTableColumn {
  label: string;
  dotColor?: string;
}

interface Props {
  label: string;
  tableColumns: BarChartTableColumn[];
}

export const BarStatBox: React.FC<Props> = ({
  label,
  tableColumns,
  children,
}) => {
  return (
    <Card flex={1}>
      <CardHeader text={label} variant={"compact"} />
      <CardBody variant={"compact"}>
        <Row indent>
          <Row width={120} />
          <Indent />
          <Row width={250} />
          {tableColumns.map(({ dotColor, label }) => (
            <MiniTableCell
              key={label}
              leftContent={
                dotColor && (
                  <Box style={{ paddingRight: 4 }}>
                    <Dot color={dotColor} size={10} />
                  </Box>
                )
              }
            >
              {label}
            </MiniTableCell>
          ))}
        </Row>

        <Space />
        {Children.map(children, (child) => (
          <Spacing num={0.5}>{child}</Spacing>
        ))}
      </CardBody>
    </Card>
  );
};
