import {
  createFppSearchFilterReducer,
  SearchFilterState,
} from "@/features/search-filter/redux/reducer";
import {
  createInternalStandardTableActions,
  createStandardTableActions,
  createStandardTableInitialState,
  createStandardTableReducer,
  StandardTableState,
} from "@stenajs-webui/grid";
import { combineReducers } from "redux";
import {
  RouteTargetPageGeneralState,
  routeTargetPageReducer,
} from "./components/redux/reducers";
import { AllCustomerTableColumnKeys } from "./features/route-pair-target-table/config/RowsAndColumnsConfig";
import {
  routeCustomerTargetReducer,
  RouteCustomerTargetState,
} from "./features/route-pair-target-table/redux/reducers";

export interface RouteTargetState {
  filter: SearchFilterState;
  list: RouteCustomerTargetState;
  routeTargetPageState: RouteTargetPageGeneralState;
  standardTable: StandardTableState<AllCustomerTableColumnKeys>;
}

export const routePairTargetStandardTableId = "routePairTargetStandardTable";
export const routePairTargetStandardTableActions = createStandardTableActions(
  routePairTargetStandardTableId,
  createInternalStandardTableActions<AllCustomerTableColumnKeys>()
);

export const routePairTargetStandardTableInitialState =
  createStandardTableInitialState<AllCustomerTableColumnKeys>();

export const routeTargetReducer = combineReducers<RouteTargetState>({
  filter: createFppSearchFilterReducer("routeTarget"),
  list: routeCustomerTargetReducer,
  routeTargetPageState: routeTargetPageReducer,
  standardTable: createStandardTableReducer<AllCustomerTableColumnKeys>(
    routePairTargetStandardTableId
  ),
});
