import {
  createEditableEntityActions,
  createEditableEntityReducer,
  createEntityActions,
  createEntityListActions,
  createEntityListReducer,
  createEntityReducer,
  createRecordObjectActions,
  createRecordObjectReducer,
  EditableEntitySetEditableEntityFieldsAction,
  EditableEntityState,
  EntityListState,
  EntityState,
  RecordObjectState,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../../config/redux/RootReducer";
import { combineReducers } from "redux";
import { ExpectedVolumeTableRow } from "../types";
import { ResultListBannerState } from "@stenajs-webui/elements";

interface ProgressState {
  loading: boolean;
  bannerState?: ResultListBannerState;
}

interface RoutePairMeta {
  hasVolume: boolean;
}

interface ExpectedVolumeEntity {
  routePairMeta: EntityState<RoutePairMeta>;
  volumes: EntityListState<EditableEntityState<ExpectedVolumeTableRow>>;
}

export type ExpectedVolumesByRoutePair =
  RecordObjectState<ExpectedVolumeEntity>;

export const expectedVolumeReducerId = "expectedVolume";

export interface ExpectedVolumeState {
  expectedVolumesByRoutePair: RecordObjectState<{
    routePairMeta: EntityState<RoutePairMeta>;
    volumes: EntityListState<EditableEntityState<ExpectedVolumeTableRow>>;
  }>;
  progress: EntityState<ProgressState>;
}

export const expectedVolumeReducer = reducerIdGate(
  expectedVolumeReducerId,
  combineReducers({
    expectedVolumesByRoutePair: createRecordObjectReducer(
      combineReducers({
        routePairMeta: createEntityReducer<RoutePairMeta>({
          hasVolume: false,
        }),
        volumes: createEntityListReducer(
          createEditableEntityReducer<ExpectedVolumeTableRow>({
            id: "",
            customerIsParent: false,
            customerIndex: "",
            customerNo: "",
            customerName: "",
          })
        ),
      })
    ),
    progress: createEntityReducer<ProgressState>({
      loading: false,
      bannerState: undefined,
    }),
  })
);

export const expectedVolumeSelectors = {
  getState: (state: StoreState): ExpectedVolumeState => state.expectedVolume,
  getExpectedVolumeForRoutePairId: (
    state: StoreState,
    routePairId: string
  ): ExpectedVolumeEntity | undefined =>
    state.expectedVolume.expectedVolumesByRoutePair[routePairId],
  getExpectedVolumesAreAllEmpty: (state: StoreState): boolean =>
    Object.values(state.expectedVolume.expectedVolumesByRoutePair).every(
      (entry) => !entry.routePairMeta.hasVolume
    ),
  getProgress: (state: StoreState): EntityState<ProgressState> =>
    state.expectedVolume.progress,
};

export const expectedVolumeActions = {
  clearAll: () =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createRecordObjectActions().clearAllRecords()
    ),
  setRowsForRoutePair: (
    routePairId: string,
    rows: Array<ExpectedVolumeTableRow>
  ) =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createRecordObjectActions().recordAction(
        routePairId,
        createEntityListActions<
          EditableEntityState<ExpectedVolumeTableRow>
        >().setList(
          rows.map((r) => ({
            id: r.id,
            editable: r,
            persisted: r,
          }))
        )
      )
    ),
  setRoutePairMetaFields: (
    routePairId: string,
    fields: Partial<RoutePairMeta>
  ) =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createRecordObjectActions().recordAction(
        routePairId,
        createEntityActions<RoutePairMeta>().setEntityFields(fields)
      )
    ),
  setFieldsForRow: (
    routePairId: string,
    expectedVolumeId: string,
    fields: Partial<ExpectedVolumeTableRow>
  ) =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createRecordObjectActions().recordAction(
        routePairId,
        createEntityListActions<
          ExpectedVolumeTableRow,
          EditableEntitySetEditableEntityFieldsAction<ExpectedVolumeTableRow>
        >().actionByFieldsMatch(
          { id: expectedVolumeId },
          createEditableEntityActions<ExpectedVolumeTableRow>().setEditableEntityFields(
            fields
          )
        )
      )
    ),
  clearExpectedVolume: (routePairId: string) =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createRecordObjectActions().recordAction(
        routePairId,
        createEntityListActions<
          ExpectedVolumeTableRow,
          EditableEntitySetEditableEntityFieldsAction<ExpectedVolumeTableRow>
        >().actionOnAll(
          createEditableEntityActions<ExpectedVolumeTableRow>().setEditableEntityFields(
            {
              accompaniedExpectedVolPeak: undefined,
              accompaniedExpectedVolShoulder: undefined,
              accompaniedExpectedVolOffPeak: undefined,
              unaccompaniedExpectedVolPeak: undefined,
              unaccompaniedExpectedVolShoulder: undefined,
              unaccompaniedExpectedVolOffPeak: undefined,
            }
          )
        )
      )
    ),
  setProgress: (fields: Partial<ProgressState>) =>
    reducerIdGateAction(
      expectedVolumeReducerId,
      createEntityActions<ProgressState>().setEntityFields(fields)
    ),
};
