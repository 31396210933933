import { createEntityActions, reducerIdGateAction } from "@stenajs-webui/redux";
import {
  RouteCustomerGeneralState,
  routeCustomerTargetReducerId,
} from "./reducers";

export const routeCustomerTargetActions = {
  setRouteCustomerTargetState: (fields: Partial<RouteCustomerGeneralState>) =>
    reducerIdGateAction(
      routeCustomerTargetReducerId,
      createEntityActions<RouteCustomerGeneralState>().setEntityFields(fields)
    ),
  incrementDiscardClicked: () =>
    reducerIdGateAction(routeCustomerTargetReducerId, {
      type: "INCREMENT_DISCARD_CLICKED",
    }),
};
