import React from "react";
import { useSelector } from "react-redux";
import { profileSelectors } from "../common/redux/profile/selectors";
import { ErrorPage } from "../LoginPage";

export const ProfileCurrencyErrorBoundary: React.FC = () => {
  const { fetchErrors } = useSelector(profileSelectors.getGlobal);

  if (!fetchErrors || fetchErrors.length === 0) {
    return <></>;
  }

  const errorMessage = fetchErrors
    ?.map((fe) => {
      return fe.message;
    })
    .join(" ");

  return <ErrorPage text={errorMessage} />;
};
