import React, { useMemo } from "react";
import { AsyncSelect, SelectProps } from "@stenajs-webui/select";
import { useApolloClient } from "@apollo/client";

import { queryCustomersByQuery } from "./hooks/UseNslhCustomersByQuery";
import {
  NslhCustomersSelectQuery,
  NslhCustomersSelectQueryVariables,
} from "@/gql/graphql";

interface CustomerOption {
  label: string;
  value: string;
  data: NslhCustomersSelectQuery["productPrice"]["customers"]["byCustomerNumberOrIndex"]["0"];
}

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value:
    | NslhCustomersSelectQuery["productPrice"]["customers"]["byCustomerNumberOrIndex"]["0"]
    | null;
  customerNumber: string;
  setCustomerNumber: (custNo: string) => void;
  onValueChange: (
    value: NslhCustomersSelectQuery["productPrice"]["customers"]["byCustomerNumberOrIndex"]["0"]
  ) => void;
  placeholder?: string;
  isClearable?: boolean;
}

const isValidLength = (inputValue: string) => {
  if (inputValue.length === 0) {
    return "Search for customer number or customer index";
  }
  if (inputValue.length < 2) {
    return "Type at least 2 characters to search";
  }

  return "No customers found";
};

export const NslhCustomerSearchInput: React.FC<Props> = ({
  customerNumber,
  setCustomerNumber,
  value,
  onValueChange,
  placeholder,
  isClearable,
  ...selectProps
}) => {
  const client = useApolloClient();

  // HANDLE POSSIBLE ERRORS AND LOADING!
  const loadOptions = async (inputValue: string): Promise<CustomerOption[]> => {
    if (inputValue.length < 2) {
      return [];
    }

    const result = await client.query<
      NslhCustomersSelectQuery,
      NslhCustomersSelectQueryVariables
    >({
      query: queryCustomersByQuery,
      variables: { query: inputValue },
    });

    return (
      result.data?.productPrice.customers.byCustomerNumberOrIndex.map<CustomerOption>(
        (customer) => ({
          data: customer,
          label: `${customer.name} (${customer.custIndex} ${customer.custNo})`,
          value: customer.id,
        })
      ) ?? []
    );
  };

  const selectedOption: CustomerOption | undefined = useMemo(() => {
    if (!value) {
      return undefined;
    }
    return {
      data: value,
      label: `${value.name} (${value.custIndex} ${value.custNo})`,
      value: value.id,
    };
  }, [value]);

  return (
    <AsyncSelect
      value={selectedOption}
      onInputChange={(val) => {
        if (val) {
          setCustomerNumber(val);
        }
      }}
      loadOptions={loadOptions}
      isClearable={isClearable}
      placeholder={
        placeholder ?? "Search for customer number or customer index"
      }
      onChange={(v: any) => {
        if (!v) {
          setCustomerNumber("");
        } else if (onValueChange && v) {
          onValueChange(v.data);
        }
      }}
      noOptionsMessage={({ inputValue }: { inputValue: string }) =>
        isValidLength(inputValue)
      }
      {...selectProps}
    />
  );
};
