import { StoreState } from "../../../../../config/redux/RootReducer";
import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { priceRowNeedsRecalculation } from "../util/RecalculationIsRequiredCalculator";
import { routeAgreementCanBeEditedByStatusCode } from "../../../route-agreement/util/RouteAgreementCalculator";
import { useDebounce } from "@stenajs-webui/core";
import { HeadAgreementStatusCode } from "@/gql/graphql";

export const useRecalculationOfPriceRowsIsRequiredBeforeSave = (
  routeAgreementId: string,
  editableRouteAgreement: boolean
) => {
  const selector = useCallback(
    (state: StoreState) => state.rateSheet.table[routeAgreementId]?.rows,
    [routeAgreementId]
  );

  const rates = useSelector(selector);

  const recalculationOfPriceRowsIsRequiredBeforeSave = useMemo(() => {
    if (!rates) {
      return false;
    }
    if (!editableRouteAgreement) {
      return false;
    }
    return rates.some(priceRowNeedsRecalculation);
  }, [rates, editableRouteAgreement]);

  return {
    recalculationOfPriceRowsIsRequiredBeforeSave,
  };
};

const stateSelector = (state: StoreState) => state.rateSheet.table;

export interface RouteAgreementForRecalc {
  id: string;
  statusCode: HeadAgreementStatusCode;
}

export const useMultiRecalculationOfPriceRowsIsRequiredBeforeSave = (
  routeAgreements: Array<RouteAgreementForRecalc>
) => {
  const ratesPerRouteAgreementId = useSelector(stateSelector);

  const recalculationOfPriceRowsIsRequiredBeforeSave = useMemo(
    () =>
      routeAgreements.some((routeAgreement) => {
        const rates = ratesPerRouteAgreementId[routeAgreement.id];
        if (!rates) {
          return false;
        }
        if (!routeAgreementCanBeEditedByStatusCode(routeAgreement.statusCode)) {
          return false;
        }
        return rates.rows.some(priceRowNeedsRecalculation);
      }),
    [ratesPerRouteAgreementId, routeAgreements]
  );

  const debounced = useDebounce(
    recalculationOfPriceRowsIsRequiredBeforeSave,
    1000
  );

  return {
    recalculationOfPriceRowsIsRequiredBeforeSave: debounced,
  };
};
