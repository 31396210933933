import { combineReducers } from "redux";
import {
  SearchFilterState,
  createFppSearchFilterReducer,
} from "@/features/search-filter/redux/reducer";

export interface StatisticsByCustomerState {
  filter: SearchFilterState;
}

export const statisticsByCustomerReducer =
  combineReducers<StatisticsByCustomerState>({
    filter: createFppSearchFilterReducer("statistics.statisticsByCustomer"),
  });
