import { useCallback, useState } from "react";
import { ErrorWithPath } from "./BannerErrorWithLinks";

export const useLocalError = () => {
  const [localError, setLocalError] = useState<Error | undefined>(undefined);

  const clearLocalError = useCallback(() => {
    setLocalError(undefined);
  }, [setLocalError]);

  return {
    localError,
    setLocalError,
    clearLocalError,
  };
};
export const useLocalErrorWithPath = () => {
  const [localErrorWithPath, setLocalErrorWithPath] = useState<
    ErrorWithPath[] | undefined
  >(undefined);

  const clearLocalErrorWithPath = () => setLocalErrorWithPath(undefined);

  return {
    localErrorWithPath,
    setLocalErrorWithPath,
    clearLocalErrorWithPath,
  };
};
