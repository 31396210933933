export const TESTENVIRONMENT_URLS = [
  "https://portal-sl-freight-core-dev.apps.ocp.aws.stena.io",
  "https://portal-sl-freight-core-qa.apps.ocp.aws.stena.io",
  "https://portal-sl-freight-core-acc.apps.ocp.aws.stena.io",
  "https://portal-sl-freight-core-prod-test.apps.ocp.aws.stena.io",
  "http://localhost:3000",
];

export const PRODENVIRONMENT_URLS = [
  "https://freightpricingplatform.stenaline.com/",
  "https://portal-sl-freight-core-prod.apps.ocp.aws.stena.io/",
];

export const environment = {
  isAuthDisabled: () => import.meta.env.REACT_APP_AUTH_DISABLED === "true",
  isEnvironmentDev: () =>
    import.meta.env.REACT_APP_TESTENVIRONMENT === "true" ||
    TESTENVIRONMENT_URLS.filter((url) => window.location.href.includes(url))
      .length > 0,
  isEnvironmentProd: () =>
    import.meta.env.REACT_APP_TESTENVIRONMENT === "false" ||
    PRODENVIRONMENT_URLS.filter((url) => window.location.href.includes(url))
      .length > 0,
};
