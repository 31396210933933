import * as React from "react";
import { PageHeader } from "../../common/components/page-header/PageHeader";
import { Crumb } from "../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../RouteFactory";
import { BreadCrumbs, stenaCalculate } from "@stenajs-webui/elements";

export const PriceCalculatorHeader: React.FC = () => {
  return (
    <PageHeader
      title={"Price Calculator"}
      icon={stenaCalculate}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"price calculator"}
            path={routeFactory.priceCalculator.priceCalculatorRoot()}
          />
        </BreadCrumbs>
      }
      bottomSpacing={false}
    />
  );
};
