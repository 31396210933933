import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { useFetchAllNoShowLateHandlingStatuses } from "@/features/search-filter/hooks/FilterFetchers/UseFetchAllNoShowLateHandlingStatuses";
import { useFetchNslhTypes } from "@/features/search-filter/hooks/FilterFetchers/UseFetchNslhTypes";
import {
  NoShowLateHandlingStatus,
  NoShowLateHandlingType,
} from "@/features/search-filter/types/FilterEntitys";
import { Card } from "@stenajs-webui/elements";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NOSHOW_LATE_EDIT } from "../../../common/claims/constants";
import { hasClaim } from "../../../common/claims/utils";
import { PageLayout } from "../../../common/components/page-layout/PageLayout";
import { UnAuthorizedView } from "../../../common/components/unauthorized-view/UnAuthorizedView";
import { FreightPricingPortalError } from "../../../common/error/models/FreightPricingPortalError";
import { profileSelectors } from "../../../common/redux/profile/selectors";
import { NoShowLateHandlingHandleBookingsCustomerDataTable } from "./NoShowLateHandlingHandleBookingsCustomerDataTable";
import { NoShowLateHandlingHandleBookingsHeader } from "./NoShowLateHandlingHandleBookingsHeader";
import { NoShowLateHandlingHandleBookingsTableHeader } from "./NoShowLateHandlingHandleBookingsTableHeader";
import { useNoShowLateHandlingBookingListQuery } from "./hooks/UseNoShowLateHandlingBookingListQuery";
import { clearSelectionState } from "./redux/reducers";
import { noShowLateHandlingBookingListDataFilterVariablesTransformer } from "./transformers/NoShowLateHandlingBookingListDataFilterVariablesTransformer";

interface Props {}

export interface HandlingCategories {
  handlingStatuses?: Array<NoShowLateHandlingStatus>;
  handlingTypes?: Array<NoShowLateHandlingType>;
  error?: string;
}

export const NoShowLateHandlingHandleBookingsScreen: React.FC<Props> = () => {
  const { committedState, isCommitted } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { claims } = useSelector(profileSelectors.getGlobal);
  const dispatch = useDispatch();

  const [handlingCategories, setHandlingCategories] =
    useState<HandlingCategories>({
      handlingStatuses: [],
      handlingTypes: [],
      error: undefined,
    });
  const [localErrors, setLocalErrors] = useState<FreightPricingPortalError[]>(
    []
  );
  const { handlingStatuses, error: handlingStatusesError } =
    useFetchAllNoShowLateHandlingStatuses();
  const { nslhTypes: handlingTypes, error: nslhTypesError } =
    useFetchNslhTypes();

  useEffect(() => {
    setHandlingCategories((prevHandlingCategories) => ({
      ...prevHandlingCategories,
      error: undefined,
    }));

    setHandlingCategories({
      handlingStatuses,
      handlingTypes,
      error: handlingStatusesError?.message ?? nslhTypesError?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committedState]);

  useEffect(() => {
    dispatch(clearSelectionState());
  }, [dispatch]);

  const { error, loading, noShowLateHandlingBookingData } =
    useNoShowLateHandlingBookingListQuery(
      noShowLateHandlingBookingListDataFilterVariablesTransformer(
        committedState
      ),
      isCommitted
    );

  const numberOfCustomers =
    noShowLateHandlingBookingData?.map((data) => data.customer.custNo).length ??
    0;

  const numberOfBookings =
    noShowLateHandlingBookingData?.map((data) => data.customerBookings).flat()
      .length ?? 0;

  const bookingsLoading = loading && !noShowLateHandlingBookingData;

  return (
    <>
      <Helmet title="Handle bookings - NSLH - FPP" />
      <NoShowLateHandlingHandleBookingsHeader />
      {hasClaim(claims, [NOSHOW_LATE_EDIT]) ? (
        <PageLayout>
          <Card>
            <NoShowLateHandlingHandleBookingsTableHeader
              customers={numberOfCustomers}
              bookings={numberOfBookings}
              noShowLateHandlingBookingsData={
                noShowLateHandlingBookingData ?? []
              }
              localErrors={localErrors}
              setLocalErrors={setLocalErrors}
            />
            <NoShowLateHandlingHandleBookingsCustomerDataTable
              handlingCategories={handlingCategories}
              error={error}
              loading={bookingsLoading}
              noShowLateHandlingBookingsData={
                noShowLateHandlingBookingData ?? []
              }
              filterSearchApplied={isCommitted}
            />
          </Card>
        </PageLayout>
      ) : (
        <UnAuthorizedView />
      )}
    </>
  );
};
