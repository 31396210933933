import { EditableEntityState } from "@stenajs-webui/redux";
import { NoShowLateHandlingPricesRow } from "../config/RowsAndColumnsConfig";

export const dataHasBeenModified = (
  item: EditableEntityState<NoShowLateHandlingPricesRow>,
  checkIfCellHasValue?: boolean
) => {
  const editableCells = [
    item.editable.lateHandlingDkk,
    item.editable.lateHandlingEur,
    item.editable.lateHandlingGbp,
    item.editable.lateHandlingNok,
    item.editable.lateHandlingPln,
    item.editable.lateHandlingSek,
    item.editable.noShowDkk,
    item.editable.noShowEur,
    item.editable.noShowGbp,
    item.editable.noShowNok,
    item.editable.noShowPln,
    item.editable.noShowSek,
  ];

  const currentValueIsAboveZero =
    editableCells.filter((currentValue) => currentValue > 0).length ===
    editableCells.length;

  if (
    (JSON.stringify(item.editable) !== JSON.stringify(item.persisted) &&
      !item.persisted.newlyAddedRow &&
      checkIfCellHasValue &&
      currentValueIsAboveZero) ||
    (!checkIfCellHasValue &&
      JSON.stringify(item.editable) !== JSON.stringify(item.persisted)) ||
    (!checkIfCellHasValue && item.persisted.newlyAddedRow) ||
    (checkIfCellHasValue &&
      item.persisted.newlyAddedRow &&
      currentValueIsAboveZero)
  ) {
    return true;
  }
  return false;
};
