import {
  NoShowLateHandlingBookingListQuery,
  NoShowLateHandlingBookingListQueryVariables,
  NoShowLateHandlingBookingsByFilterOptions,
} from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setNoShowLateHandlingBookingsData } from "../redux/reducers";

export const handleBookingsFragment = gql`
  fragment HandleBookingData on HandleBookingBookingData {
    id
    codeNoShowLateType {
      id
      code
      description
    }
    consignmentNo
    handlingStatus
    consignment {
      id
      sailingArchive {
        departureDate
        departureTime
        route {
          code
        }
      }
    }
    month
    bookingNo
    bookingVersion
    route {
      id
      code
      routePair {
        id
        code
      }
    }
    departureDate
    departureTime
    vehicleType {
      id
      code
      shortDescription
    }
    vehicleReg
    trailerReg
    customerReference
    revisedDate
    revisedTime
    excludeReason {
      id
      code
      description
    }
    loadingListMessage
    note
    lateHandlingDate
    lateHandlingUser
    dateModified
    modifiedBy
  }
`;

export const noShowLateHandlingBookingDataQuery = gql`
  ${handleBookingsFragment}
  fragment NslhBookingData on NoShowLateHandlingHandleBookingsData {
    id
    customer {
      id
      custIndex
      custNo
      name
      modeOfPay
    }
    region
    seller
    noOfToDo
    noOfToBeInvoiced
    noOfInvoiced
    noOfDoNotInvoice
    noOfThreshold
    noOfNotChargeable
    customerBookings {
      ...HandleBookingData
    }
  }
  query NoShowLateHandlingBookingList(
    $filterOptions: NoShowLateHandlingBookingsByFilterOptions!
  ) {
    productPrice {
      noShowLateHandlingBookings {
        byFilter(filterOptions: $filterOptions) {
          bookingsData {
            ...NslhBookingData
          }
        }
      }
    }
  }
`;

export const useNoShowLateHandlingBookingListQuery = (
  filterOptions: NoShowLateHandlingBookingsByFilterOptions,
  isCommitted: boolean
) => {
  const dispatch = useDispatch();

  const { loading, data, error } = useQuery<
    NoShowLateHandlingBookingListQuery,
    NoShowLateHandlingBookingListQueryVariables
  >(noShowLateHandlingBookingDataQuery, {
    variables: { filterOptions },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const bookingsData =
        data.productPrice.noShowLateHandlingBookings?.byFilter.bookingsData;
      dispatch(setNoShowLateHandlingBookingsData(bookingsData));
    },
    skip: !isCommitted,
  });

  return {
    error,
    loading,
    noShowLateHandlingBookingData:
      data?.productPrice.noShowLateHandlingBookings?.byFilter.bookingsData,
  };
};
