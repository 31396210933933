import { gql, useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../../../common/error/GqlErrorFragment";
import {
  CopyRouteAgreementMutation,
  CopyRouteAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation CopyRouteAgreement($input: CopyRouteAgreementInput!) {
    productPrice {
      routeAgreement {
        copyRouteAgreement(input: $input) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on CopyRouteAgreementSuccessResult {
            routeAgreement {
              id
            }
          }
        }
      }
    }
  }
`;

export const useCopyRouteAgreementMutation = () =>
  useMutation<CopyRouteAgreementMutation, CopyRouteAgreementMutationVariables>(
    query
  );
