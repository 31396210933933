import { AppThunk } from "../../../../../config/redux/RootReducer";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { batch } from "react-redux";
import { customerRouteRedux } from "../../../customer-route/redux";
import { rateSheetStandardTableWrapperActions } from "../../../rate-sheet/redux";
import { rateSheetTableActions } from "../../../rate-sheet/actions";
import { cleanRatePrice } from "../../../rate-sheet/util/PriceCleaner";
import { transformRouteAgreementToRoutesForm } from "../features/routes/RoutesFormModel";
import {
  MultilegRouteAgreementRouteAgreementFetcherQuery,
  MultilegRouteAgreementRouteAgreementFetcherQueryVariables,
} from "@/gql/graphql";
import { customerRouteRouteAgreementFragment } from "../fragments/RouteAgreementPageFragment";

const query = gql`
  ${customerRouteRouteAgreementFragment}
  fragment MultilegRouteAgrAgreements on MultiLegRouteAgreement {
    id
    routeAgreements {
      ...CustomerRouteRouteAgreement
    }
  }
  query MultilegRouteAgreementRouteAgreementFetcher(
    $multiLegRouteAgreementId: ID!
  ) {
    productPrice {
      multiLegRouteAgreement {
        byId(id: $multiLegRouteAgreementId) {
          ...MultilegRouteAgrAgreements
        }
      }
    }
  }
`;
export const populateMultiLegRouteAgreementRouteAgreementState =
  (multiLegRouteAgreementId: string): AppThunk =>
  async (dispatch) => {
    const r = await apolloClient.query<
      MultilegRouteAgreementRouteAgreementFetcherQuery,
      MultilegRouteAgreementRouteAgreementFetcherQueryVariables
    >({
      query,
      variables: {
        multiLegRouteAgreementId,
      },
      fetchPolicy: "network-only",
    });

    const routeAgreements =
      r.data.productPrice.multiLegRouteAgreement.byId?.routeAgreements;

    if (routeAgreements) {
      batch(() => {
        dispatch(customerRouteRedux.actions.clearAll());
        dispatch(rateSheetStandardTableWrapperActions.clearAll());

        dispatch(
          rateSheetStandardTableWrapperActions.setRouteAgreements(
            routeAgreements
          )
        );

        routeAgreements.forEach((routeAgreement) => {
          dispatch(
            rateSheetStandardTableWrapperActions.tableRows.recordAction(
              routeAgreement.id,
              rateSheetTableActions.populateWithPersistedPrices(
                routeAgreement.prices.map(cleanRatePrice)
              )
            )
          );
          dispatch(
            customerRouteRedux.actions.setRouteAgreementModel(
              routeAgreement.id,
              {
                ...transformRouteAgreementToRoutesForm(routeAgreement),
                rowVersion: routeAgreement.rowVersion,
              }
            )
          );
        });
      });
    }
  };
