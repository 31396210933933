import { CustomerStatisticsByFilterItemsFragment } from "@/gql/graphql";
import { StatBoxQueryData } from "../redux";

export const mergeStatBoxQueryData = (
  startData: StatBoxQueryData,
  itemsToMerge: Array<CustomerStatisticsByFilterItemsFragment>
): StatBoxQueryData => {
  return itemsToMerge.reduce<StatBoxQueryData>((sum, item) => {
    return {
      ...sum,
      [item.id]: item,
    };
  }, startData);
};
