import { OverviewDateSelect } from "@/common/components/overview/OverviewDateSelect";
import { Column } from "@stenajs-webui/core";
import { PrimaryButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NslhCustomerSearchInput } from "../../components/customer-select/NslhCustomerSearchInput";
import {
  setNslhOverviewAccountCardData,
  setNslhOverviewCustomerSelectedData,
  setNslhOverviewDateRangeInput,
} from "../redux/reducers";
import { createNslhInternalOverviewStateSelectors } from "../redux/selectors";
import {
  InternalOverviewQueryVariables,
  NslhCustomerSelectFragment,
} from "@/gql/graphql";

export interface Props {
  handleSearch: (input: InternalOverviewQueryVariables) => void;
}

export const OverviewSearchRow: React.FC<Props> = ({ handleSearch }) => {
  const dispatch = useDispatch();

  const selectedCustomer = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewCustomerSelectedData
  );

  const loading = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewLoading
  );

  const dateRangeData = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewDateRangeInput
  );

  const setCustomerNumberFromDropDown = useCallback(
    (value: NslhCustomerSelectFragment) => {
      dispatch(setNslhOverviewCustomerSelectedData(value));
    },
    [dispatch]
  );

  const onCustomerNumberChange = useCallback(
    (custNo: string) => {
      if (!custNo) {
        dispatch(setNslhOverviewCustomerSelectedData(null));
      }
    },
    [dispatch]
  );

  const searchActiveForByFilter =
    selectedCustomer?.custNo && dateRangeData.year && dateRangeData.month;

  return (
    <>
      <Column width={400}>
        <NslhCustomerSearchInput
          isClearable
          placeholder="Number/index"
          value={selectedCustomer!}
          customerNumber={String(selectedCustomer?.custNo)}
          setCustomerNumber={onCustomerNumberChange}
          onValueChange={setCustomerNumberFromDropDown}
        />
      </Column>
      <OverviewDateSelect
        monthValue={dateRangeData.month}
        yearValue={dateRangeData.year}
        onYearValueChange={(year) =>
          dispatch(setNslhOverviewDateRangeInput({ year }))
        }
        onMonthValueChange={(month) =>
          dispatch(setNslhOverviewDateRangeInput({ month }))
        }
      />
      <PrimaryButton
        label={"Search"}
        disabled={!searchActiveForByFilter}
        loadingLabel={"Search"}
        loading={loading}
        onClick={() => {
          const inputIsValid = selectedCustomer?.custNo;
          if (inputIsValid) {
            handleSearch({
              custNo: selectedCustomer!.custNo,
              year: dateRangeData.year,
              month: dateRangeData.month,
            });
            dispatch(setNslhOverviewAccountCardData(selectedCustomer));
          }
        }}
      />
    </>
  );
};
