import { NotesFormModel } from "./NotesFormModel";
import { getModifiedOrNull } from "../../utils/InputTransformerHelpers";
import { NotesFormInput } from "@/gql/graphql";

export const transformNotesFormToInput = (
  formModel: NotesFormModel,
  persisted: NotesFormModel
): NotesFormInput => {
  return {
    comment: getModifiedOrNull(formModel.comment, persisted.comment),
    externalComment: getModifiedOrNull(
      formModel.externalComment,
      persisted.externalComment
    ),
    note: getModifiedOrNull(formModel.note, persisted.note),
  };
};
