import React, { useMemo } from "react";
import { StandardTable, TableContext } from "@stenajs-webui/grid";

import {
  AdditionalSurchargeTableColumnKeys,
  tableConfig,
} from "./common/surcharges-table-config";
import { SurchargeDocumentsQuery } from "@/gql/graphql";

interface Props {
  additionalSurchargeDocuments?: SurchargeDocumentsQuery["productPrice"]["additionalCharges"]["all"];
  tableContext: TableContext<AdditionalSurchargeTableColumnKeys>;
  loading: boolean;
}

export interface AdditionalSurchargesTableRow {
  id: string;
  name: string | null;
  validFrom: string | null;
  currencyCode: string | null;
  seaAreaCode: string | null;
}

export const SurchargesTable: React.FC<Props> = ({
  additionalSurchargeDocuments,
  tableContext,
  loading,
}) => {
  const tableRows = useMemo(
    () =>
      additionalSurchargeDocuments?.map<AdditionalSurchargesTableRow>(
        (additionalSurchargeDocument) => ({
          id: additionalSurchargeDocument.id,
          currencyCode: additionalSurchargeDocument.currency,
          seaAreaCode: additionalSurchargeDocument.seaArea?.name ?? "",
          name: additionalSurchargeDocument.fileName,
          validFrom: additionalSurchargeDocument.validFrom,
        })
      ),
    [additionalSurchargeDocuments]
  );

  return (
    <StandardTable
      tableContext={tableContext}
      config={tableConfig}
      items={tableRows}
      loading={loading}
    />
  );
};
