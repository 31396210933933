import { MonthSelect } from "@/common/components/data-driven-inputs/selects/month-select/MonthSelect";
import { Box, Spacing } from "@stenajs-webui/core";
import { SelectProps, Select } from "@stenajs-webui/select";
import { useCallback, useMemo } from "react";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { stenaCalendar } from "@stenajs-webui/elements";

export const YearAndMonthFilterSection = ({
  enabled,
  label,
  handleYearChange,
  handleMonth,
  year,
  month,
}: {
  enabled?: boolean;
  label?: string;
  year: number;
  handleYearChange: (year: number) => void;
  handleMonth: (year: number) => void;
  month: number;
}) => {
  return (
    <FppSearchFilterSection
      enabled={enabled}
      label={label ?? "Year & month"}
      leftIcon={stenaCalendar}
      sectionId={"yearMonth"}
    >
      <Box flexDirection="row" width="100%" justifyContent="space-between">
        <Box width={110}>
          <PrevCurrentNextYearFilterPane
            year={year}
            handleYearChange={handleYearChange}
          />
        </Box>
        <Box width={190}>
          <MonthFilterPane month={month} handleMonthChange={handleMonth} />
        </Box>
      </Box>
    </FppSearchFilterSection>
  );
};

interface Props {
  year?: number;
  handleYearChange: (year: number) => void;
}

const PrevCurrentNextYearFilterPane: React.FC<Props> = ({
  year,
  handleYearChange,
}) => {
  const onValueChange = useCallback(
    (selectedYear: number) => {
      handleYearChange(selectedYear);
    },
    [handleYearChange]
  );

  return (
    <Spacing num={2} indent>
      <PrevCurrentNextYearSelect value={year} onValueChange={onValueChange} />
    </Spacing>
  );
};

interface NegotiationYearSelectProps
  extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: number;
  onValueChange?: (value: number) => void;
}

const years = [
  new Date().getUTCFullYear() - 1,
  new Date().getUTCFullYear(),
  new Date().getUTCFullYear() + 1,
];
const yearOptions = years.map((year) => ({
  label: year,
  value: year,
}));

const PrevCurrentNextYearSelect: React.FC<NegotiationYearSelectProps> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const selected = useMemo(
    () => yearOptions.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      menuPortalTarget={document.body}
      {...selectProps}
      options={yearOptions}
      value={selected}
      onChange={(item: any) => onValueChange && onValueChange(item.value)}
    />
  );
};

interface MonthFilterPaneProps {
  month?: number;
  handleMonthChange: (month: number) => void;
}

const MonthFilterPane: React.FC<MonthFilterPaneProps> = ({
  month,
  handleMonthChange,
}) => {
  const onValueChange = useCallback(
    (selectedMonth: number) => {
      handleMonthChange(selectedMonth);
    },
    [handleMonthChange]
  );

  return (
    <Spacing num={2} indent>
      <MonthSelect value={month} onValueChange={onValueChange} />
    </Spacing>
  );
};
