import { orderBy } from "lodash";
import { sortSailingTypeCode } from "./SortSailingTypeCode";
import { CopyHeadAgreementDataHeadAgreementFragment } from "@/gql/graphql";

type SortableRouteAgreement = Pick<
  CopyHeadAgreementDataHeadAgreementFragment["routeAgreements"]["0"],
  "routeDescription" | "sailingTypeCodes" | "valid"
>;

export const sortRouteAgreementsForCopy = <T extends SortableRouteAgreement>(
  routeAgreements: T[]
): T[] =>
  orderBy(
    routeAgreements,
    [
      (a) => a.routeDescription,
      (a) => sortSailingTypeCode(a.sailingTypeCodes),
      (a) => a.valid.end,
    ],
    ["asc", "desc", "asc"]
  );
