import { Column } from "@stenajs-webui/core";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { refetchStatBoxesWithRateSheetsChanges } from "../../../../customer-route-statistic-boxes/thunks/RefetchStatBoxesWithRateSheetsChanges";
import { emptyBulkUpdateRatesFormModel } from "../../bulk-update-rates/models/BulkUpdateRatesFormModel";
import { RateSheetTableRowState } from "../../reducer";
import { fetchActualChangeForSinglePriceRow } from "../../thunks/FetchActualChangeForSinglePriceRow";
import { RaisePrice } from "./RaisePrice";

interface Props {
  onRequestClose: () => void;
  rateSheetTableRowState: RateSheetTableRowState;
  routeAgreementId: string;
  routeAgreementPriceId: string;
  currencyLabel?: string;
  disableStatBoxes?: boolean;
}

export const RateEditPanelPrice: React.FC<Props> = ({
  onRequestClose,
  routeAgreementId,
  routeAgreementPriceId,
  currencyLabel,
  disableStatBoxes,
}) => {
  const dispatch = useDispatch();
  const [raisePriceFormModel, setRaisePriceFormModel] = useState(
    emptyBulkUpdateRatesFormModel
  );

  const onSuccessCallback = async () => {
    await dispatch(
      fetchActualChangeForSinglePriceRow(
        routeAgreementId,
        routeAgreementPriceId
      )
    );

    if (!disableStatBoxes) dispatch(refetchStatBoxesWithRateSheetsChanges());
    onRequestClose();
  };

  return (
    <Column>
      <RaisePrice
        onSuccess={onSuccessCallback}
        currencyLabel={currencyLabel}
        value={raisePriceFormModel}
        onValueChange={setRaisePriceFormModel}
        routeAgreementId={routeAgreementId}
        routeAgreementPriceId={routeAgreementPriceId}
      />
    </Column>
  );
};
