import { Box, Text } from "@stenajs-webui/core";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";

import * as React from "react";

interface Props {
  isEditable?: boolean;
  value?: string;
  modifiedField?: ModifiedFieldItemState;
}

export const AllStatisticsTableModifiedField: React.FC<Props> = ({
  isEditable,
  value,
  modifiedField,
}) => {
  const fieldValue = !value ? "---" : value;

  return (
    <Box display="grid" justifyContent="flex-end">
      <Text
        color={isEditable ? "var(--swui-primary-action-color)" : undefined}
        variant={modifiedField?.modified ? "bold" : undefined}
      >
        {fieldValue}
      </Text>
    </Box>
  );
};
