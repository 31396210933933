import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNslhInvoiceQueryByInvoiceNumber } from "../hooks/UseNslhInvoiceQueryByInvoiceNumber";
import {
  setNslhSearchInvoicesByFilterData,
  setNslhSearchInvoicesError,
  setNslhSearchInvoicesLoading,
} from "../redux/reducers";
import { InvoiceSearchRow } from "./InvoiceSearchRow";
import { useNslhInvoiceQueryByFilter } from "../hooks/UseNslhInvoiceQueryByFilter";
import { NslhInvoiceByFilterInput } from "@/gql/graphql";

export const SearchInvoiceFilterBar: React.FC = () => {
  const dispatch = useDispatch();

  const {
    fetchInvoicesByCustomerNumberDateRange,
    setError: setErrorByFilter,
    error: errorByFilter,
    loading: loadingByFilter,
  } = useNslhInvoiceQueryByFilter();

  const {
    fetchNslhInvoicesByInvoiceNumber,
    setError: setErrorByInvoiceNo,
    error: errorByInvoiceNumber,
    loading: loadingByInvoiceNumber,
  } = useNslhInvoiceQueryByInvoiceNumber();

  const loading = loadingByFilter || loadingByInvoiceNumber;
  const error = errorByFilter || errorByInvoiceNumber;

  useEffect(() => {
    dispatch(setNslhSearchInvoicesLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(setNslhSearchInvoicesError(error));
  }, [error, dispatch]);
  const handleSearchByCustomerNumberAndDateRange = useCallback(
    (input: NslhInvoiceByFilterInput) => {
      setErrorByFilter(undefined);
      fetchInvoicesByCustomerNumberDateRange({
        variables: {
          input,
        },
      });
    },
    [fetchInvoicesByCustomerNumberDateRange, setErrorByFilter]
  );

  const handleSearchByInvoiceNumber = useCallback(
    (invoiceNumber: string) => {
      setErrorByInvoiceNo(undefined);
      dispatch(setNslhSearchInvoicesByFilterData(undefined));

      fetchNslhInvoicesByInvoiceNumber({
        variables: {
          id: invoiceNumber,
        },
      });
    },
    [setErrorByInvoiceNo, fetchNslhInvoicesByInvoiceNumber, dispatch]
  );

  return (
    <InvoiceSearchRow
      handleSearchByCustomerNumberAndDateRange={(
        input: NslhInvoiceByFilterInput
      ) => handleSearchByCustomerNumberAndDateRange(input)}
      handleSearchByInvoiceNumber={(invoiceNo) =>
        handleSearchByInvoiceNumber(invoiceNo)
      }
    />
  );
};
