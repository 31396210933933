import { AppThunk } from "../../../../../config/redux/RootReducer";
import { fetchBulkEditRatesActualChangeForSinglePriceRow } from "./FetchActualChangeForSinglePriceRow";

export const fetchBulkEditRatesActualChangeForAllPriceRows =
  (): AppThunk => async (dispatch, getState) => {
    const tableState = getState().bulkEditRatesState;
    const selectedPricesIds =
      getState().bulkEditRatesTableState.standardTable.selectedIds.selectedIds;

    await Promise.all(
      selectedPricesIds.map((selectedPrice) => {
        const routeAgreementId =
          tableState.edited[selectedPrice]?.routeAgreement.id;

        return selectedPricesIds.map((routeAgreementPriceId) =>
          dispatch(
            fetchBulkEditRatesActualChangeForSinglePriceRow(
              routeAgreementId ?? "",
              routeAgreementPriceId
            )
          )
        );
      })
    );
  };
