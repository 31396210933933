import { Text } from "@stenajs-webui/core";
import { ModifiedFieldItemState } from "@stenajs-webui/redux";

import * as React from "react";

interface Props {
  isEditable?: boolean;
  value?: string;
  modifiedField?: ModifiedFieldItemState;
}

const fieldValueResolver = (value: string | undefined) => {
  return !value || value === "0,00" ? "Not set" : value;
};

export const NoShowLateHandlingRoutePricesModifiedField: React.FC<Props> = ({
  isEditable,
  value,
  modifiedField,
}) => {
  return (
    <Text
      color={isEditable ? "var(--swui-primary-action-color)" : undefined}
      variant={modifiedField?.modified ? "bold" : undefined}
    >
      {fieldValueResolver(value)}
    </Text>
  );
};
