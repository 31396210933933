import { CargoStatusCode } from "@/gql/graphql";

interface CargoStatusEntity {
  empty: boolean;
  loaded: boolean;
}

export const allCargoStatusCodes = [
  CargoStatusCode.Both,
  CargoStatusCode.Empty,
  CargoStatusCode.Loaded,
];

export const transformCargoStatusEntityToCargoStatusCode = ({
  empty,
  loaded,
}: CargoStatusEntity): CargoStatusCode =>
  transformCargoStatusToCargoStatusCode(empty, loaded);

export const transformCargoStatusToCargoStatusCode = (
  empty: boolean,
  loaded: boolean
): CargoStatusCode => {
  if (empty && !loaded) {
    return CargoStatusCode.Empty;
  }
  if (!empty && loaded) {
    return CargoStatusCode.Loaded;
  }
  return CargoStatusCode.Both;
};

export const transformCargoStatusCodeToEntity = (
  cargoStatusCode: CargoStatusCode
): CargoStatusEntity => {
  switch (cargoStatusCode) {
    case CargoStatusCode.Loaded:
      return {
        loaded: true,
        empty: false,
      };
    case CargoStatusCode.Empty:
      return {
        loaded: false,
        empty: true,
      };
    case CargoStatusCode.Both:
      return {
        loaded: true,
        empty: true,
      };
    default:
      throw new Error("Could not transform cargo status code, invalid code.");
  }
};
