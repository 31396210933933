import { Indent, Row, Spacing, Txt, useBoolean } from "@stenajs-webui/core";
import { FlatButton, PrimaryButton } from "@stenajs-webui/elements";
import { EditableEntityState } from "@stenajs-webui/redux";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../config/redux/RootReducer";
import { NoShowLateHandlingPricesRow } from "./config/RowsAndColumnsConfig";
import { NoShowLateHandlingIncludePricesRadioButtons } from "./NoShowLateHandlingIncludePricesRadioButtons";
import { NoShowLateHandlingNewDateSelect } from "./NoShowLateHandlingNewDateSelect";
import {
  noShowLateHandlingRoutePricesActions,
  noShowLateHandlingRoutePricesCurrentlySelectedActions,
} from "./redux/actions";
import { CurrentlySelectedSibling, newlyAddedRowState } from "./redux/reducers";
import { createValidFromValidator } from "./util/CreateValidFromValidator";
import { currentMonth, currentYear } from "./util/GetDatesForSelect";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import { Window } from "@stenajs-webui/modal";

interface Props {
  routePairCode: string;
  item: EditableEntityState<NoShowLateHandlingPricesRow>;
}

const currentlySelectedDataSelector = (state: StoreState) =>
  state.noShowLateHandlingPricesCurrentlySelected.selected;
const addValidFromModalSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.addValidFromModal.isOpen;

const allNoShowHandlingPriceDataSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.allNoShowHandlingPriceData;

const getValidFromAndId = (
  yearOrMonth: { year: number; month: number },
  routePairCode: string
) => {
  const addZeroToMonth = yearOrMonth.month <= 9 ? "0" : "";
  return {
    id: `${routePairCode}:${yearOrMonth.year}-${addZeroToMonth}${yearOrMonth.month}-01`,
    validFrom: `${yearOrMonth.year}-${addZeroToMonth}${yearOrMonth.month}-01`,
  };
};

export const NoShowLateHandlingCreateValidFromDateModal: React.FC<Props> = ({
  routePairCode,
  item,
}) => {
  const currentlySelectedData = useSelector(currentlySelectedDataSelector);
  const isModalOpen = useSelector(addValidFromModalSelector);
  const allNoShowHandlingPrices = useSelector(
    allNoShowHandlingPriceDataSelector
  );
  const dispatch = useDispatch();

  const [yearOrMonth, setYearOrMonth] = useState({
    year: currentYear,
    month: currentMonth,
  });
  const [isChecked, setChecked, setUnchecked] = useBoolean(false);

  const id = getValidFromAndId(yearOrMonth, routePairCode).id;
  const validFrom = getValidFromAndId(yearOrMonth, routePairCode).validFrom;

  const onRequestClose = () => {
    setYearOrMonth({ year: currentYear, month: currentMonth });
    setUnchecked();
    dispatch(
      noShowLateHandlingRoutePricesActions.setAddValidFromModalOpen({
        isOpen: false,
        routePairCode,
      })
    );
  };

  const validFromAlreadyExists = useMemo(() => {
    return createValidFromValidator(
      allNoShowHandlingPrices,
      routePairCode,
      yearOrMonth
    );
  }, [allNoShowHandlingPrices, routePairCode, yearOrMonth]);

  const updatedCurrentlySelectedData = currentlySelectedData
    .flat()
    .map((data): CurrentlySelectedSibling => {
      if (data.routePairCode === routePairCode) {
        return {
          currentlySelectedId: id,
          routePairCode,
        };
      }
      return data;
    });
  const onAddNewValidFromDate = () => {
    if (isChecked) {
      batch(() => {
        dispatch(
          noShowLateHandlingRoutePricesActions.addNewSiblingRow({
            id,
            editable: {
              ...item.editable,
              id,
              validFrom,
              dateCreated: "",
              createdBy: "",
              newlyAddedRow: true,
            },
            persisted: {
              ...item.persisted,
              id,
              validFrom,
              dateCreated: "",
              createdBy: "",
              newlyAddedRow: true,
            },
          })
        );
        dispatch(
          noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
            updatedCurrentlySelectedData
          )
        );
        dispatch(
          noShowLateHandlingRoutePricesActions.setAddValidFromModalOpen({
            isOpen: false,
            routePairCode,
          })
        );
      });

      setYearOrMonth({ year: currentYear, month: currentMonth });
      setUnchecked();
    } else {
      batch(() => {
        dispatch(
          noShowLateHandlingRoutePricesActions.addNewSiblingRow({
            id,
            editable: {
              ...newlyAddedRowState,
              id,
              routePairCode,
              validFrom,
              routePair: item.editable.routePair,
            },
            persisted: {
              ...newlyAddedRowState,
              id,
              routePairCode,
              validFrom,
              routePair: item.persisted.routePair,
            },
          })
        );
        dispatch(
          noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
            updatedCurrentlySelectedData
          )
        );
        dispatch(
          noShowLateHandlingRoutePricesActions.setAddValidFromModalOpen({
            isOpen: false,
            routePairCode,
          })
        );
      });

      setYearOrMonth({ year: currentYear, month: currentMonth });
      setUnchecked();
    }
  };
  return (
    <Window
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      header={
        <Txt
          size={"large"}
          variant={"bold"}
        >{`New valid from date (${routePairCode})`}</Txt>
      }
      width={"500px"}
      indent={3}
      spacing={3}
    >
      <NoShowLateHandlingNewDateSelect
        yearValue={yearOrMonth.year}
        onYearValueChange={(value) =>
          value === currentYear
            ? setYearOrMonth({ year: value, month: currentMonth })
            : setYearOrMonth({ ...yearOrMonth, year: value })
        }
        monthValue={yearOrMonth.month}
        onMonthValueChange={(value) =>
          setYearOrMonth({ ...yearOrMonth, month: value })
        }
      />
      <Spacing num={2} />
      <NoShowLateHandlingIncludePricesRadioButtons
        isChecked={isChecked}
        setChecked={setChecked}
        setUnchecked={setUnchecked}
      />
      <Spacing num={2} />
      <Row display={"flex"} justifyContent={"flex-end"}>
        <FlatButton label={"Cancel"} onClick={onRequestClose} />
        <Indent />
        <Tooltip
          label={"The chosen valid from date already exists"}
          disabled={!validFromAlreadyExists}
        >
          <PrimaryButton
            label={"Set valid from date"}
            data-testid={
              testIdConstantsWebapp.routePriceAddNewValidFromDateConfirmButton
            }
            onClick={onAddNewValidFromDate}
            disabled={validFromAlreadyExists}
          />
        </Tooltip>
      </Row>
    </Window>
  );
};
