import {
  createEntityActions,
  createEntityReducer,
  createEntitySelectors,
  EntityState,
  reducerIdGate,
} from "@stenajs-webui/redux";
import { StoreState } from "../../../../../../config/redux/RootReducer";
import { wrapActionsWithReducerIdGate } from "../../../../../../common/redux/ReducerIdGateActionWrapper";
import { emptyMatrixFormModel, MatrixFormModel } from "./MatrixFormModel";

export type MatrixFormState = EntityState<MatrixFormModel>;

const reducerId = "routeAgreementDetails.matrix";

export const matrixFormRedux = {
  reducer: reducerIdGate(
    reducerId,
    createEntityReducer<MatrixFormModel>(emptyMatrixFormModel)
  ),
  actions: wrapActionsWithReducerIdGate(reducerId, {
    ...createEntityActions<MatrixFormModel>(),
  }),
  selectors: createEntitySelectors<StoreState, MatrixFormModel>(
    (state) => state.routeAgreementDetails.workspace.matrix
  ),
};
