import * as React from "react";
import { ReactNode } from "react";
import { BoxProps, Row, Text, useBoolean } from "@stenajs-webui/core";

interface RenderContentOptions {
  onRequestClose: () => void;
}

interface Props extends Omit<BoxProps, "right"> {
  label: string;
  right?: ReactNode;
  disableClick?: boolean;
  renderContent: (options: RenderContentOptions) => ReactNode;
}

export const CellFieldClickGate: React.FC<Props> = ({
  label,
  right,
  renderContent,
  disableClick,
  ...boxProps
}) => {
  const [visible, show, hide] = useBoolean(false);

  if (visible) {
    return <>{renderContent({ onRequestClose: hide })}</>;
  }
  return (
    <Row
      {...boxProps}
      indent
      onClick={disableClick ? undefined : show}
      style={disableClick ? undefined : { cursor: "pointer" }}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
      height={"100%"}
    >
      <Row>
        <Text>{label}</Text>
      </Row>
      {right && <Row alignItems={"center"}>{right}</Row>}
    </Row>
  );
};
