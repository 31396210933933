import { MatrixFormItem } from "../MatrixFormModel";
import { areIntervalsOverlapping } from "date-fns";

export const matrixItemDateIsOverlapping = (
  matrixItem: MatrixFormItem,
  allMatrixItems: Array<MatrixFormItem>
): boolean => {
  if (!matrixItem.startDate) {
    return false;
  }
  if (!matrixItem.endDate) {
    return false;
  }
  if (allMatrixItems.length === 0) {
    return false;
  }

  return allMatrixItems
    .filter((m) => !m.deleted)
    .some((m) => {
      if (m.id === matrixItem.id) {
        return false;
      }
      if (!matrixItem.startDate || !matrixItem.endDate) {
        return false;
      }
      if (!m.startDate || !m.endDate) {
        return false;
      }

      return areIntervalsOverlapping(
        { start: new Date(m.startDate), end: new Date(m.endDate) },
        {
          start: new Date(matrixItem.startDate),
          end: new Date(matrixItem.endDate),
        },
        { inclusive: true }
      );
    });
};
