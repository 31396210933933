import { handleBookingsFragment } from "@/features/no-show-late-handling/handle-bookings/hooks/UseNoShowLateHandlingBookingListQuery";
import {
  UpdateHandleBookingStatusMutation,
  UpdateHandleBookingStatusMutationVariables,
  UpdateBookingHandlingStatusInput,
} from "@/gql/graphql";

import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
const mutation = gql`
  ${handleBookingsFragment}
  mutation UpdateHandleBookingStatus(
    $input: [UpdateBookingHandlingStatusInput!]!
  ) {
    productPrice {
      noShowLateHandlingBookings {
        updateBookingHandlingStatus(input: $input) {
          ... on UpdateBookingHandlingStatusSuccessResult {
            handleBookingBookingData {
              ...HandleBookingData
            }
          }
          ... on UpdateBookingHandlingStatusErrorResult {
            errors {
              message
              path
            }
          }
        }
      }
    }
  }
`;

export const useHandleBookingStatus = () => {
  const [mutate, { loading: bookingStatusLoading }] = useMutation<
    UpdateHandleBookingStatusMutation,
    UpdateHandleBookingStatusMutationVariables
  >(mutation);
  const setBookingStatus = useCallback(
    async (input: Array<UpdateBookingHandlingStatusInput>) => {
      return await mutate({
        variables: {
          input: input,
        },
        refetchQueries: ["NoShowLateHandlingBookingList"],
        awaitRefetchQueries: true,
      });
    },
    [mutate]
  );

  return {
    bookingStatusLoading,
    setBookingStatus,
  };
};
