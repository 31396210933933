import { formatDateString } from "@/common/dates/formatters";
import { EditMultiLegAgreementFormModel } from "../../model/EditAndCreateMultiLegAgreementFormModel";

export interface EditMultiLegRouteAgreementInput {
  multiLegRouteAgreementId: string;
  rowVersion: string;
  formModel: {
    currencyCode: {
      value: string;
    };
    validity: {
      value: {
        startDate: string;
        endDate: string;
      };
    };
    name: {
      value: string;
    };
  };
}

export const editMultiLegRouteAgreementMutationInput = (
  formModel: EditMultiLegAgreementFormModel
): EditMultiLegRouteAgreementInput => {
  return {
    multiLegRouteAgreementId: formModel.multiLegAgreementId,
    rowVersion: formModel.rowVersion,
    formModel: {
      currencyCode: {
        value: formModel.currencyCode,
      },
      name: {
        value: formModel.name,
      },
      validity: {
        value: {
          startDate: formatDateString(formModel.valid.startDate!),
          endDate: formatDateString(formModel.valid.endDate!),
        },
      },
    },
  };
};
