import {
  conditionsFormRedux,
  ConditionsFormState,
} from "./features/conditions/ConditionsFormRedux";
import { Action, combineReducers } from "redux";
import {
  routesFormRedux,
  RoutesFormState,
} from "./features/routes/RoutesFormRedux";
import {
  notesFormRedux,
  NotesFormState,
} from "./features/notes/NotesFormRedux";
import {
  articlesFormReducer,
  ArticlesFormState,
} from "./features/articles/reducer";
import {
  createEntityActions,
  createEntityReducer,
  createEntitySelectors,
  EntityState,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import {
  emptyRouteAgreementFormModel,
  RouteAgreementFormModel,
} from "./models/RouteAgreementFormModel";
import { StoreState } from "../../../../config/redux/RootReducer";
import { wrapActionsWithReducerIdGate } from "../../../../common/redux/ReducerIdGateActionWrapper";
import {
  matrixFormRedux,
  MatrixFormState,
} from "./features/matrix/MatrixFormRedux";
import { RouteAgreementDetailsStatePopulatorQuery } from "@/gql/graphql";

type RouteAgreement =
  RouteAgreementDetailsStatePopulatorQuery["productPrice"]["routeAgreement"]["byId"];
interface ProgressState {
  loading: boolean;
  error?: Error;
}

export interface RouteAgreementDetailsState {
  routeAgreement: EntityState<RouteAgreement | null>;
  progress: EntityState<ProgressState>;
  original: EntityState<RouteAgreementFormModel>;
  workspace: {
    conditions: ConditionsFormState;
    articles: ArticlesFormState;
    routes: RoutesFormState;
    notes: NotesFormState;
    matrix: MatrixFormState;
  };
  recalculationProgress: EntityState<ProgressState>;
  addNameClicked: number;
}

export const routeAgreementDetailsRouteAgreementReducerId =
  "routeAgreementDetails.routeAgreement";
export const routeAgreementDetailsProgressReducerId =
  "routeAgreementDetails.progress";
export const routeAgreementDetailsRecalculationProgressReducerId =
  "routeAgreementDetails.recalculationProgress";
const reducerId = "routeAgreementDetails.original";

export const routeAgreementDetailsAddNameClickedReducerId =
  "routeAgreementDetails.addNameClicked";

export const routeAgreementDetailsOriginalActions =
  wrapActionsWithReducerIdGate(reducerId, {
    ...createEntityActions<RouteAgreementFormModel>(),
  });

export const routeAgreementDetailsRouteAgreementActions =
  wrapActionsWithReducerIdGate(routeAgreementDetailsRouteAgreementReducerId, {
    ...createEntityActions<RouteAgreement | null>(),
  });

export const routeAgreementDetailsProgressActions =
  wrapActionsWithReducerIdGate(routeAgreementDetailsProgressReducerId, {
    ...createEntityActions<ProgressState>(),
  });

export const routeAgreementDetailsRecalculationProgressActions =
  wrapActionsWithReducerIdGate(
    routeAgreementDetailsRecalculationProgressReducerId,
    {
      ...createEntityActions<ProgressState>(),
    }
  );

export const routeAgreementDetailsAddNameClicked = {
  incrementAddNameClicked: () =>
    reducerIdGateAction(routeAgreementDetailsAddNameClickedReducerId, {
      type: "INCREMENT_ADD_NAME_CLICKED",
    }),
};

export const routeAgreementsAddNameClickedSelector = (
  state: StoreState
): number => state.routeAgreementDetails.addNameClicked;

export const routeAgreementDetailsSelector = (state: StoreState) =>
  state.routeAgreementDetails;

export const routeAgreementOriginalSelectors = createEntitySelectors<
  StoreState,
  RouteAgreementFormModel
>((state) => state.routeAgreementDetails.original);

export const routeAgreementDetailsReducer = combineReducers({
  routeAgreement: reducerIdGate(
    routeAgreementDetailsRouteAgreementReducerId,
    createEntityReducer<RouteAgreement | null>(null)
  ),
  progress: reducerIdGate(
    routeAgreementDetailsProgressReducerId,
    createEntityReducer<ProgressState>({ loading: false })
  ),
  original: reducerIdGate(
    reducerId,
    createEntityReducer(emptyRouteAgreementFormModel)
  ),
  workspace: combineReducers({
    conditions: conditionsFormRedux.reducer,
    articles: articlesFormReducer,
    routes: routesFormRedux.reducer,
    notes: notesFormRedux.reducer,
    matrix: matrixFormRedux.reducer,
  }),
  recalculationProgress: reducerIdGate(
    routeAgreementDetailsRecalculationProgressReducerId,
    createEntityReducer<ProgressState>({ loading: false })
  ),
  addNameClicked: reducerIdGate(
    routeAgreementDetailsAddNameClickedReducerId,
    (state: number = 0, action: Action) => {
      switch (action.type) {
        case "INCREMENT_ADD_NAME_CLICKED":
          return state + 1;
        default:
          return state;
      }
    }
  ),
});
