import React from "react";
import { Box } from "@stenajs-webui/core";
import { AuditData } from "../../PriceCalculatorFormFetcher";
import { transformAuditTrackStringToDisplayableJsx } from "../transformers/TransformAuditTrackText";

interface Props {
  auditData: AuditData[] | null;
}

export const PriceCalculatorBookingAuditTab: React.FC<Props> = ({
  auditData,
}) => {
  return (
    <>
      {auditData?.map((leg, index) => (
        <Box spacing={2} indent={3} key={index}>
          {transformAuditTrackStringToDisplayableJsx(leg.auditText)}
        </Box>
      ))}
    </>
  );
};
