import { Box, SeparatorLine, Spacing } from "@stenajs-webui/core";
import { Banner, Card, CardBody, stenaDetails } from "@stenajs-webui/elements";
import { LoadingScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { memo, useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  ArticleTypesSelect,
  useAllArticleTypes,
} from "../../../../../../../common/components/data-driven-inputs/selects/article-types-multi-select/ArticleTypesSelect";
import { StoreState } from "../../../../../../../config/redux/RootReducer";
import { routeAgreementCanBeEdited } from "../../../../util/RouteAgreementCalculator";
import { articleFormActions } from "../actions";
import { articleFormSelectors } from "../selectors";
import { ArticleItemPane } from "./ArticleItemPane";
import { SortArticleBy } from "./ArticleSortBySelect";
import { ArticlesSelectBox } from "./ArticlesSelectBox";
import { ExpansionPane } from "./ExpansionPane";
import {
  ArticleTypesMultiSelectQuery,
  RouteAgreementDetailsStateFragment,
} from "@/gql/graphql";

interface Props {
  routeAgreement: RouteAgreementDetailsStateFragment;
}

export const ArticlesPanel: React.FC<Props> = ({ routeAgreement }) => {
  const [sortedBy, setSortedBy] = useState<SortArticleBy>(
    SortArticleBy.CodeAsc
  );

  const onSortedValueChange = (value: SortArticleBy) => {
    setSortedBy(value);
  };

  const { articles, articleIds } = useSelector(articleFormSelectors.getState);

  const { data, loading } = useAllArticleTypes();

  const dispatch = useDispatch();

  const isEditable = routeAgreementCanBeEdited(routeAgreement);

  const allArticleTypes = data?.productPrice.articleType.all ?? [];

  return (
    <Card>
      <Helmet title="Articles - Route agreement - FPP" />
      <ArticlesSelectBox
        disabled={!isEditable}
        sortedBy={sortedBy}
        setSortedBy={onSortedValueChange}
      />
      <SeparatorLine />
      <Box>
        {loading && (
          <Spacing num={8}>
            <LoadingScreen />
          </Spacing>
        )}

        {articleIds.length === 0 ? (
          <CardBody>
            <Banner
              variant="info"
              text="No articles added."
              icon={stenaDetails}
              contentRight={
                <ArticleTypesSelect
                  onValueChange={(selectedArticles) =>
                    dispatch(
                      articleFormActions.setArticleTypesKeepArticles(
                        selectedArticles
                      )
                    )
                  }
                  isDisabled={!isEditable}
                  articleState={Object.values(articles)}
                  selectedArticleIds={articleIds}
                />
              }
            />
          </CardBody>
        ) : (
          articleIds.map((articleId) => {
            return (
              <ExpandableArticleItemPane
                key={articleId}
                allArticleTypes={allArticleTypes}
                articleId={articleId}
                isEditable={isEditable}
              />
            );
          })
        )}
      </Box>
    </Card>
  );
};

interface ExpandableArticleItemPaneProps {
  isEditable: boolean;
  allArticleTypes: ArticleTypesMultiSelectQuery["productPrice"]["articleType"]["all"];
  articleId: string;
}

const ExpandableArticleItemPane: React.FC<ExpandableArticleItemPaneProps> =
  memo(({ allArticleTypes, articleId, isEditable }) => {
    const dispatch = useDispatch();
    const articleTypeId = useSelector(
      (state: StoreState) =>
        articleFormSelectors.getSpecificArticleState(state, articleId)
          .articleTypeId
    );

    const onClickRemove = useCallback(() => {
      dispatch(articleFormActions.removeArticleType(articleId));
    }, [articleId, dispatch]);

    const articleType = allArticleTypes.find((a) => a.id === articleTypeId);

    if (!articleType) {
      return null;
    }

    return (
      <ExpansionPane
        title={articleType.code}
        onClickRemove={onClickRemove}
        removeDisabled={!isEditable}
        articleId={articleId}
        articleType={articleType}
      >
        <ArticleItemPane articleType={articleType} articleId={articleId} />
      </ExpansionPane>
    );
  });
