import { SpaceValues } from "@/common/utils/SpaceValues";
import { Text, Row, SeparatorLine, Space, Column } from "@stenajs-webui/core";
import { ReportOption } from "../agreement-selection/common/types";
import { CardHeader, stenaLockClosed } from "@stenajs-webui/elements";
import { LoadingModal } from "@stenajs-webui/modal";
import StepContainer from "../../common/components/StepContainer";
import StepCardBody from "../../common/components/StepCardBody";
import { SubscriberContactSubscriberFragment } from "@/gql/graphql";

interface SummaryStepProps {
  lastPageText: string;
  reportType: string;
  routeAgreementIds: string[];
  multilegRouteAgreementids: string[];
  formattingSize: "Large" | "Small";
  specificationsText: string;
  conditionsText: string;
  includeThresholds: boolean;
  includeCreditLimit: boolean;
  reportOptions?: ReportOption;
  isCompletingWizard: boolean;
  custName: string;
  custNo: number;
  selectedCustomerSubscriber: SubscriberContactSubscriberFragment | undefined;
  childrenForReport: string[];
  selectedSurchargeDocuments: string[];
}

export const SummaryStep = ({
  custName,
  custNo,
  lastPageText,
  reportType,
  routeAgreementIds,
  multilegRouteAgreementids,
  formattingSize,
  specificationsText,
  conditionsText,
  includeThresholds,
  includeCreditLimit,
  reportOptions,
  isCompletingWizard,
  selectedCustomerSubscriber,
  childrenForReport,
  selectedSurchargeDocuments,
}: SummaryStepProps) => {
  const agreementDetails = [
    `${reportOptions?.showAcc ? "Accompanied/Unaccompanied" : ""}`,
    `${reportOptions?.includeArticle ? "Articles" : ""}`,
    `${reportOptions?.includeMatrix ? "Matrix" : ""}`,
    `${reportOptions?.showLoadedStatus ? "Empty/Loaded" : ""}`,
  ]
    .filter((details) => details !== "")
    .join(", ");

  const suscriberFullName = `${selectedCustomerSubscriber?.firstName ?? ""} ${
    selectedCustomerSubscriber?.surName ?? ""
  }`;
  const custNoInDocumentName = custName.replace(/\s+/g, "");

  return (
    <StepContainer>
      {isCompletingWizard && (
        <LoadingModal
          headerText={"Creating agreement..."}
          headerIconLeft={stenaLockClosed}
        />
      )}
      <CardHeader text="Summary" />
      <StepCardBody>
        <SummaryInformationRow
          leftLabel={"Contact"}
          leftValue={childrenForReport.length > 0 ? "N/A" : suscriberFullName}
          rightLabel={"Last page note"}
          rightValue={lastPageText !== "" ? "Yes" : "No"}
          topSpace={false}
        />
        <SummaryInformationRow
          leftLabel={"Credit limit"}
          leftValue={includeCreditLimit === true ? "Yes" : "No"}
          rightLabel={"Agreements included"}
          rightValue={`${routeAgreementIds.length} route agreements / ${multilegRouteAgreementids.length} multi leg agreements`}
        />

        <SummaryInformationRow
          leftLabel={"Agreement details"}
          leftValue={agreementDetails}
          rightLabel={"Surcharges"}
          rightValue={selectedSurchargeDocuments.length > 0 ? "Yes" : "No"}
        />

        <SummaryInformationRow
          leftLabel={"Threshold info"}
          leftValue={includeThresholds === true ? "Yes" : "No"}
          rightLabel={"Number of children selected"}
          rightValue={`${childrenForReport.length}`}
        />
        <Space num={SpaceValues.EIGHT} />
        <Row>
          <SummaryInformationColumn
            label={"Document layout"}
            value={formattingSize}
          />
        </Row>
        <Space num={SpaceValues.EIGHT} />
        <SeparatorLine width="1px" />
        <Space num={SpaceValues.EIGHT} />
        <Row>
          <SummaryInformationColumn
            label={"Additional specifications"}
            value={specificationsText === "" ? "No" : "Yes"}
          />
        </Row>
        <Space num={SpaceValues.EIGHT} />
        <SeparatorLine width="1px" />
        <Space num={SpaceValues.EIGHT} />
        <Row>
          <SummaryInformationColumn
            label={"Document name"}
            value={
              childrenForReport.length > 0
                ? "N/A"
                : `Stena_Line_${
                    reportType === "offer" ? "Offer" : "Confirmation"
                  }_${custNoInDocumentName}(${custNo})`
            }
          />
        </Row>
        <Space num={SpaceValues.EIGHT} />
      </StepCardBody>
    </StepContainer>
  );
};

const SummaryInformationRow = ({
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
  topSpace = true,
}: {
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
  topSpace?: boolean;
}) => (
  <>
    {topSpace && <Space num={SpaceValues.EIGHT} />}
    <Row>
      <SummaryInformationColumn label={leftLabel} value={leftValue} />
      <SummaryInformationColumn label={rightLabel} value={rightValue} />
    </Row>
    <Space num={SpaceValues.EIGHT} />
    <SeparatorLine width="1px" />
  </>
);

const SummaryInformationColumn = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <Column width={"50%"}>
    <Row>
      <Column width={"30%"}>
        <Text>{label}</Text>
      </Column>
      <Column>
        <Text variant="bold">{value}</Text>
      </Column>
    </Row>
  </Column>
);
