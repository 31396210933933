import * as React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./index-print.css";
import { App } from "./App";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

createRoot(document.getElementById("root")!).render(<App />);
