import * as React from "react";
import { Spacing } from "@stenajs-webui/core";
import { LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { CreateMultiLegAgreement } from "./CreateMultiLegAgreement";
import {
  CreateMultiLegAgreementFetcherQuery,
  CreateMultiLegAgreementFetcherQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
  onClickCancel?: () => void;
}

const query = gql`
  query CreateMultiLegAgreementFetcher($headAgreementId: ID!) {
    productPrice {
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
        }
      }
    }
  }
`;

export const CreateMultiLegAgreementFetcher: React.FC<Props> = (props) => {
  const { data, loading } = useQuery<
    CreateMultiLegAgreementFetcherQuery,
    CreateMultiLegAgreementFetcherQueryVariables
  >(query, {
    variables: {
      headAgreementId: props.headAgreementId,
    },
  });

  if (loading) {
    return (
      <Spacing num={6}>
        <LoadingScreen />
      </Spacing>
    );
  }

  const valid = data?.productPrice.headAgreement.byId?.valid;

  return (
    <CreateMultiLegAgreement
      {...props}
      initialStartDate={
        valid?.start?.isoString ? new Date(valid?.start?.isoString) : undefined
      }
      initialEndDate={
        valid?.end?.isoString ? new Date(valid?.end?.isoString) : undefined
      }
    />
  );
};
