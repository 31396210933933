import { Box, Spacing } from "@stenajs-webui/core";
import * as React from "react";
import { allFormulaFields } from "../util/AllFormulaFields";
import { FormulaValueInput } from "./FormulaValueInput";
import { FormulaByIdFragment } from "@/gql/graphql";

interface Props {
  articleTypeId: string;
  articleId: string;
  formulaType: Omit<FormulaByIdFragment, "__typename">;
}

export const FormulaFormInputs: React.FC<Props> = ({
  articleTypeId,
  articleId,
  formulaType,
}) => {
  return (
    <Box>
      {allFormulaFields.map(
        (field) =>
          formulaType.includes[field] && (
            <FormulaValueInput
              key={field}
              field={field}
              articleTypeId={articleTypeId}
              articleId={articleId}
            />
          )
      )}
      <Spacing />
    </Box>
  );
};
