import { DateRangeYearAndMonthInput } from "@/common/components/date-range-year-and-month-input/DateRangeYearAndMonthInput";
import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { InvoiceSpecificationInvoiceNumberInput } from "@/common/components/invoice-specification/components/InvoiceSpecificationInvoiceNumberInput";
import { StoreState } from "@/config/redux/RootReducer";
import { PrimaryButton } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NslhCustomerSearchInput } from "../../components/customer-select/NslhCustomerSearchInput";
import {
  setNslhSearchInvoicesByInvoiceNoData,
  setNslhSearchInvoicesCustomerNoInput,
  setNslhSearchInvoicesCustomerSelectedData,
  setNslhSearchInvoicesDateRangeInput,
  setNslhSearchInvoicesInvoiceNoInput,
} from "../redux/reducers";
import { createNoShowLateHandlingSearchInvoicesStateSelectors } from "../redux/selectors";
import {
  NslhCustomerSelectFragment,
  NslhInvoiceByFilterInput,
} from "@/gql/graphql";

export interface Props {
  handleSearchByCustomerNumberAndDateRange: (
    input: NslhInvoiceByFilterInput
  ) => void;
  handleSearchByInvoiceNumber: (invoiceNumber: string) => void;
}

export const InvoiceSearchRow: React.FC<Props> = ({
  handleSearchByCustomerNumberAndDateRange,
  handleSearchByInvoiceNumber,
}) => {
  const dispatch = useDispatch();

  const selectedCustomer = useSelector((state: StoreState) =>
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesCustomerSelectedData(
      state
    )
  );

  const invoiceNumber = useSelector((state: StoreState) =>
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesInvoiceNoInput(
      state
    )
  );

  const loading = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesLoading
  );

  const dateRangeData = useSelector(
    createNoShowLateHandlingSearchInvoicesStateSelectors.getNslhSearchInvoicesDateRangeInput
  );

  const setCustomerNumberFromDropDown = useCallback(
    (value: NslhCustomerSelectFragment) => {
      dispatch(setNslhSearchInvoicesCustomerSelectedData(value));
    },
    [dispatch]
  );

  const onCustomerNumberChange = useCallback(
    (custNo: string) => {
      if (!custNo) {
        dispatch(setNslhSearchInvoicesCustomerSelectedData(null));
      }
      dispatch(setNslhSearchInvoicesCustomerNoInput(Number(custNo)));
    },
    [dispatch]
  );

  const onInvoiceNumberChange = useCallback(
    (invoiceNo: string) => {
      dispatch(setNslhSearchInvoicesInvoiceNoInput(invoiceNo));
      dispatch(setNslhSearchInvoicesByInvoiceNoData(undefined));
    },
    [dispatch]
  );

  const searchActiveForByFilter =
    selectedCustomer?.custNo &&
    dateRangeData.monthFrom &&
    dateRangeData.monthTo &&
    dateRangeData.yearFrom &&
    dateRangeData.yearTo;

  return (
    <>
      <InfoPair
        justifyContent={"unset"}
        alignItems={"center"}
        spacingTopAndBottom={0}
        indentBetweenRows={0.5}
        leftContent={"Invoice number"}
        leftTextWhiteSpace="nowrap"
        leftTextColor={cssColor("--lhds-color-ui-700")}
        leftTextVariant="standard"
        leftTextSize="small"
        leftJustifyContent={"flex-end"}
        indentAfterComponent={0}
        rightContent={
          <InvoiceSpecificationInvoiceNumberInput
            setInvoiceNumber={onInvoiceNumberChange}
            invoiceNumber={invoiceNumber}
            handleSearchByInvoiceNumber={handleSearchByInvoiceNumber}
            isInternalView
          />
        }
        rightContentUseCustomComponent
        rightWidth={"160px"}
        rightFlexDirection="column"
        borderBottom={false}
      />

      <InfoPair
        justifyContent={"unset"}
        alignItems={"center"}
        spacingTopAndBottom={0}
        indentBetweenRows={0.5}
        leftContent={"Customer index or number"}
        leftTextWhiteSpace="nowrap"
        leftTextColor={cssColor("--lhds-color-ui-700")}
        leftTextVariant="standard"
        leftTextSize="small"
        leftJustifyContent={"flex-end"}
        indentAfterComponent={0}
        rightContent={
          <NslhCustomerSearchInput
            isClearable
            placeholder="Number/index"
            value={selectedCustomer!}
            customerNumber={String(selectedCustomer?.custNo)}
            setCustomerNumber={onCustomerNumberChange}
            onValueChange={setCustomerNumberFromDropDown}
          />
        }
        rightContentUseCustomComponent
        rightWidth={"360px"}
        rightFlexDirection="column"
        borderBottom={false}
      />

      <InfoPair
        justifyContent={"unset"}
        alignItems={"center"}
        spacingTopAndBottom={0}
        indentBetweenRows={0.5}
        leftContent={"Months"}
        leftTextWhiteSpace="nowrap"
        leftTextColor={cssColor("--lhds-color-ui-700")}
        leftTextVariant="standard"
        leftTextSize="small"
        leftJustifyContent={"flex-end"}
        indentAfterComponent={0}
        rightContent={
          <DateRangeYearAndMonthInput
            setDateRangeValues={(value) =>
              dispatch(setNslhSearchInvoicesDateRangeInput(value))
            }
            value={dateRangeData}
          />
        }
        rightContentUseCustomComponent
        rightWidth={"220px"}
        rightFlexDirection="column"
        borderBottom={false}
      />

      <PrimaryButton
        label={"Search"}
        disabled={!(searchActiveForByFilter || invoiceNumber)}
        loadingLabel={"Search"}
        loading={loading}
        onClick={() => {
          if (invoiceNumber) {
            handleSearchByInvoiceNumber(invoiceNumber);
          } else {
            const { monthFrom, monthTo, yearFrom, yearTo } = dateRangeData;
            const nslhInvoiceByFilterInput: NslhInvoiceByFilterInput = {
              custNos: [Number(selectedCustomer?.custNo)],
              fromMonth: Number(monthFrom),
              toMonth: Number(monthTo),
              fromYear: Number(yearFrom),
              toYear: Number(yearTo),
            };
            dispatch(
              setNslhSearchInvoicesCustomerSelectedData(selectedCustomer)
            );
            handleSearchByCustomerNumberAndDateRange(nslhInvoiceByFilterInput);
          }
        }}
      />
    </>
  );
};
