import * as React from "react";
import { COLUMN_WIDTH } from "./WeekDaysColumn";
import { Column, Text } from "@stenajs-webui/core";
import { MatrixTimeslot } from "../../../types";

import {
  formatDuration,
  formatTimeRange,
  formatTimeRangeShort,
  parseDecimalTime,
  parseMinutes,
} from "./util";
import { Tippy } from "../../../../../../../common/components/tooltip/tippy";
import { cssColor } from "@stenajs-webui/theme";
import { getWeekDayForString } from "@/common/dates/WeekDays";

interface Props {
  timeslots: MatrixTimeslot[];
}

export const MatrixTableTimeslotBars: React.FC<Props> = ({ timeslots }) => (
  <>
    {timeslots.map((slot, index) => {
      const startHour = Math.floor(parseDecimalTime(slot.timeFrom));
      const endHour = Math.ceil(parseDecimalTime(slot.timeTo));
      const dayOfWeek = getWeekDayForString(slot.weekDay)?.dayOfWeek ?? 0;

      const decimalMinutesStart = parseMinutes(slot.timeFrom) / 60;
      const decimalMinutesEnd = parseMinutes(slot.timeTo) / 60;

      return (
        <div
          key={index}
          style={{
            borderRadius: "var(--swui-border-radius)",
            background: cssColor("--lhds-color-blue-500"),
            gridColumn: `${startHour + 2} / ${endHour + 2}`,
            gridRow: `${dayOfWeek + 1} / span 1`,
            display: "flex",
            marginLeft: COLUMN_WIDTH * decimalMinutesStart,
            marginRight: COLUMN_WIDTH * decimalMinutesEnd,
          }}
        >
          <Tippy
            theme={"light"}
            content={
              <Column>
                <Text variant={"bold"}>
                  {formatTimeRange(slot.timeFrom, slot.timeTo)}
                </Text>
                <Text>{formatDuration(slot.timeFrom, slot.timeTo)}</Text>
              </Column>
            }
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                padding: "0 6px",
              }}
            >
              <Text
                variant={"bold"}
                size={"small"}
                color={cssColor("--lhds-color-ui-50")}
              >
                {formatTimeRangeShort(slot.timeFrom, slot.timeTo)}
              </Text>
            </div>
          </Tippy>
        </div>
      );
    })}
  </>
);
