import * as React from "react";
import { Column, Spacing } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { NotesFormModel } from "../NotesFormModel";

interface Props {
  value: NotesFormModel;
  setFields: (fields: Partial<NotesFormModel>) => void;
}

export const NotesForm: React.FC<Props> = ({ setFields, value }) => {
  return (
    <Column>
      <Label text={"Note"}>
        <TextInput
          value={value.note}
          onValueChange={(note) => setFields({ note })}
        />
      </Label>
      <Spacing />
      <Label text={"Comment"}>
        <TextInput
          value={value.comment}
          onValueChange={(comment) => setFields({ comment })}
        />
      </Label>
      <Spacing />
      <Label text={"Comment (external)"}>
        <TextInput
          value={value.externalComment}
          onValueChange={(externalComment) => setFields({ externalComment })}
        />
      </Label>
      <Spacing />
    </Column>
  );
};
