import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { Box, Indent, Txt } from "@stenajs-webui/core";
import { Icon, InputSpinner, stenaAngleDown } from "@stenajs-webui/elements";
import { CrudStatusIndicator } from "@stenajs-webui/grid";
import React, { useCallback, useState } from "react";
import { CellFieldClickGate } from "../../../../../../common/components/cell-field-click-gate/CellFieldClickGate";
import { HandleBookingStatusSelect } from "../../../../../../common/components/data-driven-inputs/selects/handle-booking-status-select/HandleBookingStatusSelect";
import { FreightPricingPortalError } from "../../../../../../common/error/models/FreightPricingPortalError";
import { transformFreightPricingPortalErrorsToString } from "../../../../../../common/error/transformers/TransformFreightPricingPortalErrorsToString";
import { HandleBookingStatusModal } from "./HandleBookingStatusModal";
import { useHandleBookingStatus } from "./hooks/UseHandleBookingStatus";
import { IconSize } from "@/common/components/icons/IconSize";

interface Props {
  handlingStatusCode?: string;
  handlingStatusDescription?: string;
  bookingNo: number;
  cashCustomer: boolean;
  disabled: boolean;
}

const allowedEditStatuses = [
  BookingStatusOptions.TODO,
  BookingStatusOptions.TOINVOICE,
  BookingStatusOptions.DONOTINV,
  BookingStatusOptions.THRESHOLD,
  BookingStatusOptions.NOTCHARGE,
];

export const HandleBookingStatusEditor: React.FC<Props> = ({
  handlingStatusCode,
  handlingStatusDescription,
  bookingNo,
  cashCustomer,
  disabled,
}) => {
  const [isModalOpen, setModelOpen] = useState<boolean>(false);
  const [localErrors, setLocalErrors] = useState<FreightPricingPortalError[]>(
    []
  );

  const { bookingStatusLoading, setBookingStatus } = useHandleBookingStatus();

  const updateHandleBookingInformation = useCallback(
    async (handlingStatus: string, excludeReasonCode?: string) => {
      const { data } = await setBookingStatus([
        {
          bookingNo,
          handlingStatus,
          excludeReasonCode: excludeReasonCode ?? null,
        },
      ]);
      const result =
        data?.productPrice.noShowLateHandlingBookings
          .updateBookingHandlingStatus;

      if (result && "errors" in result) {
        setLocalErrors([
          {
            message: "Unable to update booking statuses",
            errors: result.errors,
          },
        ]);
      } else {
        setModelOpen(false);
        setLocalErrors([]);
      }
    },
    [bookingNo, setBookingStatus]
  );

  const onExcludeReasonSet = async (excludeReasonCode: string) => {
    await updateHandleBookingInformation(
      BookingStatusOptions.DONOTINV,
      excludeReasonCode
    );
  };

  const onExcludeReasonCancel = () => {
    setLocalErrors([]);
    setModelOpen(false);
  };

  const updateHandleBookingStatusChange = async (
    handleBookingStatus: string
  ) => {
    if (handleBookingStatus === BookingStatusOptions.DONOTINV) {
      setLocalErrors([]);
      setModelOpen(true);
    } else {
      await updateHandleBookingInformation(handleBookingStatus);
    }
  };

  const editStatusAllowed =
    handlingStatusCode === BookingStatusOptions.TODO ||
    (!disabled &&
      allowedEditStatuses.includes(
        (handlingStatusCode as BookingStatusOptions) ?? ""
      ));

  const editorSpinnerActive = bookingStatusLoading && !isModalOpen;

  return (
    <>
      {editStatusAllowed && !cashCustomer ? (
        <>
          <HandleBookingStatusModal
            isModalOpen={isModalOpen}
            onExcludeReasonCancel={onExcludeReasonCancel}
            onExcludeReasonSet={(value) => onExcludeReasonSet(value)}
            localErrors={localErrors}
            loading={bookingStatusLoading}
            onErrorClose={() => {
              setLocalErrors([]);
            }}
          />
          <CellFieldClickGate
            disableClick={bookingStatusLoading}
            label={handlingStatusDescription ?? ""}
            border={undefined}
            borderRadius={undefined}
            right={
              editorSpinnerActive ? (
                <InputSpinner />
              ) : localErrors.length > 0 ? (
                <CrudStatusIndicator
                  crudStatus={{
                    hasError: true,
                    errorMessage:
                      transformFreightPricingPortalErrorsToString(localErrors),
                  }}
                />
              ) : (
                <Icon icon={stenaAngleDown} size={IconSize.Small} />
              )
            }
            renderContent={({ onRequestClose }) => (
              <Box width={"100%"}>
                <HandleBookingStatusSelect
                  value={handlingStatusCode}
                  autoFocus
                  openMenuOnFocus
                  onValueChange={async (handleBookingStatus) => {
                    await updateHandleBookingStatusChange(handleBookingStatus);
                  }}
                  onMenuClose={onRequestClose}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}
          />
        </>
      ) : (
        <Indent>
          <Txt>{handlingStatusDescription}</Txt>
        </Indent>
      )}
    </>
  );
};
