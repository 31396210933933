import { Card } from "@stenajs-webui/elements";

interface StepContainerProps {
  width?: string;
}
const StepContainer: React.FC<StepContainerProps> = ({ children, width }) => {
  return (
    <Card overflow={"auto"} width={width || "100%"}>
      {children}
    </Card>
  );
};

export default StepContainer;
