import { gql } from "apollo-boost";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { customerMultiLegRouteFetcherQuery } from "../components/CustomerMultilegRouteFetcherWrapper";
import {
  CustomerMultilegRouteFetcherQuery,
  CustomerMultilegRouteFetcherQueryVariables,
  UpdateRateSheetsForMultiLegRouteAgreementMutation,
  UpdateRateSheetsForMultiLegRouteAgreementMutationVariables,
  UpdateRateSheetsInput,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import {
  customerRouteRouteAgreementFragment,
  rateSheetTablePricesFragment,
} from "../../route-agreement/details/fragments/RouteAgreementPageFragment";

const mutation = gql`
  ${errorFragment}
  ${rateSheetTablePricesFragment}
  ${customerRouteRouteAgreementFragment}
  mutation UpdateRateSheetsForMultiLegRouteAgreement(
    $input: UpdateRateSheetsInput!
  ) {
    productPrice {
      routeAgreementPrice {
        updateMultiLegRateSheets(input: $input) {
          __typename
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRateSheetsSuccessResult {
            routeAgreement {
              ...CustomerRouteRouteAgreement
              prices {
                ...RateSheetTablePrices
              }
            }
          }
        }
      }
    }
  }
`;

export const useUpdateRateSheetsForMultiLegRouteAgreementMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    UpdateRateSheetsForMultiLegRouteAgreementMutation,
    UpdateRateSheetsForMultiLegRouteAgreementMutationVariables
  >(mutation);

  const updateRateSheetsForMultiLegRouteAgreement = useCallback(
    (
      input: UpdateRateSheetsInput,
      multiLegAgreementId: string,
      headAgreementId: string,
      customerId: string,
      year: number
    ) => {
      return mutate({
        variables: {
          input,
        },
        update: (cache, { data }) => {
          const mutationResult =
            data?.productPrice.routeAgreementPrice.updateMultiLegRateSheets;
          if (!(mutationResult && "errors" in mutationResult)) {
            const query = cache.readQuery<
              CustomerMultilegRouteFetcherQuery,
              CustomerMultilegRouteFetcherQueryVariables
            >({
              query: customerMultiLegRouteFetcherQuery,
              variables: {
                multiLegAgreementId: multiLegAgreementId,
                headAgreementId: headAgreementId,
                customerId: customerId,
                year: year,
              },
            });

            if (query && query.productPrice.multiLegRouteAgreement.byId) {
              const newQuery: CustomerMultilegRouteFetcherQuery = {
                ...query,
                productPrice: {
                  ...query.productPrice,
                  multiLegRouteAgreement: {
                    ...query.productPrice.multiLegRouteAgreement,
                    byId: {
                      ...query.productPrice.multiLegRouteAgreement.byId,
                      headAgreement: {
                        ...query.productPrice.multiLegRouteAgreement.byId
                          ?.headAgreement,
                        customer: {
                          ...query.productPrice.multiLegRouteAgreement.byId
                            ?.headAgreement.customer,
                        },
                      },
                      routeAgreements: {
                        ...query.productPrice.multiLegRouteAgreement.byId
                          .routeAgreements,
                      },
                      routeLegs: {
                        ...query.productPrice.multiLegRouteAgreement.byId
                          .routeLegs,
                      },
                      route: {
                        ...query.productPrice.multiLegRouteAgreement.byId.route,
                      },
                    },
                  },
                },
              };

              cache.writeQuery({
                query: customerMultiLegRouteFetcherQuery,
                variables: {
                  multiLegAgreementId: multiLegAgreementId,
                  headAgreementId: headAgreementId,
                },
                data: newQuery,
              });
            }
          }
        },
      });
    },
    [mutate]
  );

  return {
    updateRateSheetsForMultiLegRouteAgreement,
    loading,
    error,
  };
};
