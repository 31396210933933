import {
  ActionMenu,
  ActionMenuItem,
  SecondaryButton,
  stenaFilePdfLabel,
  stenaPen,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { HeaderAgreementPickerModal } from "../head-agreement-picker/HeadAgreementPickerModal";
import { routeFactory } from "@/RouteFactory";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

type SimplifiedHeadAgreementActionButtonsProps = {
  parentCustomerId: string;
};

export const SimplifiedHeadAgreementActionButtons = ({
  parentCustomerId,
}: SimplifiedHeadAgreementActionButtonsProps) => {
  const { customerId } = useParams() as { customerId: string };
  const navigate = useNavigate();

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const [reportType, setReportType] = useState("");
  const [isHeaderAgreementPickerOpen, setIsHeaderAgreementPickerOpen] =
    useState(false);

  return (
    <>
      <Popover
        placement={"left"}
        animation={false}
        visible={isActionMenuOpen}
        disablePadding
        onClickOutside={() => setIsActionMenuOpen(false)}
        appendTo={document.body}
        content={
          <ActionMenu>
            <ActionMenuItem
              label={"New agreement"}
              leftIcon={stenaFilePdfLabel}
              onClick={() =>
                navigate(
                  routeFactory.productAndPrice.headAgreement.createHeadAgreement(
                    {
                      customerId,
                    }
                  )
                )
              }
            />
            <ActionMenuItem
              label={"Create Offer"}
              leftIcon={stenaFilePdfLabel}
              onClick={() => {
                setReportType("offer");
                setIsHeaderAgreementPickerOpen(true);
                setIsActionMenuOpen(false);
              }}
            />
            <ActionMenuItem
              label={"Create Confirmation"}
              leftIcon={stenaFilePdfLabel}
              onClick={() => {
                setReportType("confirmation");
                setIsHeaderAgreementPickerOpen(true);
                setIsActionMenuOpen(false);
              }}
            />
          </ActionMenu>
        }
      >
        <SecondaryButton
          label="Actions"
          leftIcon={stenaPen}
          onClick={() => setIsActionMenuOpen(!isActionMenuOpen)}
        />
      </Popover>
      <HeaderAgreementPickerModal
        isOpen={isHeaderAgreementPickerOpen}
        setIsModalOpen={setIsHeaderAgreementPickerOpen}
        parentCustomerId={parentCustomerId}
        reportType={reportType}
      />
    </>
  );
};
