import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

import { useState } from "react";
import { Customer } from "../types";
import { Spacing } from "@stenajs-webui/core";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { ChildCustomers } from "./ChildCustomers";
import {
  ChildCustomersQuery,
  ChildCustomersQueryVariables,
} from "@/gql/graphql";
import { ChildCustomerFragment } from "../fragments/ChildCustomersFragments";

const query = gql`
  ${ChildCustomerFragment}
  query ChildCustomers($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...ChildCustomers
        }
      }
    }
  }
`;

export interface ChildCustomersScreenParams {
  customerId: string;
}

export const ChildCustomersScreen: React.FC = () => {
  const { customerId } = useParams() as { customerId: string };
  const [customer, setCustomer] = useState<Customer | null>(null);
  const childCustomers = customer?.childCustomers ?? [];

  const { error, loading } = useQuery<
    ChildCustomersQuery,
    ChildCustomersQueryVariables
  >(query, {
    fetchPolicy: "cache-and-network",
    variables: {
      customerId,
    },
    onCompleted(data) {
      setCustomer(data.productPrice.customers.byId);
    },
  });

  if (loading) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (!customerId) {
    return <ErrorScreen text={error?.message} />;
  }

  return (
    <>
      <Helmet title="Child Customers - Customer - FPP" />
      {customer && (
        <ChildCustomers
          customer={customer}
          childCustomers={childCustomers}
          error={error}
        />
      )}
    </>
  );
};
