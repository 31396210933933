import * as React from "react";
import { KeyboardEvent, useMemo, useState } from "react";
import { TextInput } from "@stenajs-webui/forms";
import { Box, Indent, Row, Text } from "@stenajs-webui/core";
import { Select } from "@stenajs-webui/select";
import { useLazyQuery } from "@apollo/client";
import { gql } from "apollo-boost";

import { FlatButton, Spinner, stenaSearch } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { KeyboardKeyConstant } from "@/common/utils/KeyboardConstants";
import {
  GetConsignmentVersionsQuery,
  GetConsignmentVersionsQueryVariables,
} from "@/gql/graphql";

interface Props {
  onSelectConsignmentVersion: (
    bookingNum: number,
    consignmentVersion: number
  ) => void;
  isLoading: boolean;
}
const query = gql`
  query GetConsignmentVersions($bookingNum: ID!) {
    priceCalc {
      consignmentVersions {
        byId(id: $bookingNum) {
          versions
        }
      }
    }
  }
`;

interface VersionOption {
  label: string;
  value: string;
}
export const PriceCalculatorBookingSearch: React.FC<Props> = ({
  onSelectConsignmentVersion,
  isLoading,
}) => {
  const [fetchVersions, { data, loading }] = useLazyQuery<
    GetConsignmentVersionsQuery,
    GetConsignmentVersionsQueryVariables
  >(query, {
    onCompleted: () => setHasSearched(true),
    onError: () => setHasSearched(true),
  });

  const [hasSearched, setHasSearched] = useState(false);
  const [bookingNum, setBookingNum] = useState<string>("");
  const [versionNum, setVersionNum] = useState<string>("");

  const versions = data?.priceCalc?.consignmentVersions?.byId?.versions;
  const versionOptions = useMemo(
    () =>
      versions?.map((version) => ({
        label: `Version: ${version}`,
        value: version.toString(),
      })),
    [versions]
  );

  const selected = useMemo(
    () => versionOptions?.find((o) => o.value === versionNum) ?? "",
    [versionOptions, versionNum]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeyConstant.ENTER) {
      submitBookingNumber();
    }
  };

  const submitBookingNumber = () => {
    setHasSearched(false);
    fetchVersions({
      variables: {
        bookingNum: bookingNum ?? "",
      },
    });
  };

  return (
    <Row width={450} alignItems={"center"}>
      <Box width={150}>
        <TextInput
          value={bookingNum && bookingNum}
          onValueChange={(value) => setBookingNum(value)}
          variant={loading ? "loading" : "standard"}
          iconRight={stenaSearch}
          onKeyDown={handleKeyDown}
          buttonRight={
            <FlatButton
              onClick={() => submitBookingNumber()}
              rightIcon={stenaSearch}
            />
          }
        />
      </Box>
      <Indent />
      {versionOptions && versionOptions?.length > 0 ? (
        <Box>
          <Row>
            <Box width={120}>
              <Select
                value={selected}
                options={versionOptions}
                onChange={(version) => {
                  const consignmentVersion = (version as VersionOption).value;
                  setVersionNum(consignmentVersion);

                  onSelectConsignmentVersion(
                    parseInt(bookingNum),
                    parseInt(consignmentVersion)
                  );
                }}
              />
            </Box>

            {isLoading && (
              <Box spacing={1} indent={1}>
                <Spinner size={"tiny"} />
              </Box>
            )}
          </Row>
        </Box>
      ) : (
        <Box height={"2rem"} justifyContent={"center"}>
          {hasSearched && (
            <Text variant={"bold"} color={cssColor("--lhds-color-red-500")}>
              No booking with that number exist
            </Text>
          )}
        </Box>
      )}
    </Row>
  );
};
