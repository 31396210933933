import { Routes, Route } from "react-router";
import { StatisticsByRoutePairDetailsScreen } from "../statistics-by-route-pair/details/components/StatisticsByRoutePairDetailsScreen";
import { StatisticsBySalesRegionDetailsScreen } from "../statistics-by-sales-region/details/components/StatisticsBySalesRegionDetailsScreen";
import { StatisticsBySellerDetailsScreen } from "../statistics-by-seller/details/components/StatisticsBySellerDetailsScreen";
import { StatisticsScreen } from "./StatisticsScreen";
import { SearchFilterSelectorProvider } from "@/features/search-filter/context/searchFilterSelectorContext";
import {
  createFppSearchFilterActions,
  initialSearchFilterFormModel,
} from "@/features/search-filter/redux/reducer";
import {
  SearchFilterContext,
  createSearchFilterInitialState,
} from "@stenajs-webui/filter";
import { useDispatch } from "react-redux";
import { StatisticsProvider } from "../StatisticsContext";

export const StatisticsRouter = () => {
  return (
    <StatisticsProvider>
      <SearchFilterSelectorProvider
        reducerId={"statistics.statisticsByCustomer"}
      >
        <StatisticsRoutes />
      </SearchFilterSelectorProvider>
    </StatisticsProvider>
  );
};

const StatisticsRoutes = () => {
  const dispatch = useDispatch();
  return (
    <SearchFilterContext
      state={createSearchFilterInitialState(initialSearchFilterFormModel)}
      actions={createFppSearchFilterActions("statistics.statisticsByCustomer")}
      dispatch={dispatch}
    >
      <Routes>
        <Route path={"/*"} element={<StatisticsScreen />} />
        <Route
          path={"seller/:sellerId"}
          element={<StatisticsBySellerDetailsScreen />}
        />
        <Route
          path={"route/:routeId"}
          element={<StatisticsByRoutePairDetailsScreen />}
        />
        <Route
          path={"/sales-region/:salesRegionId"}
          element={<StatisticsBySalesRegionDetailsScreen />}
        />
      </Routes>
    </SearchFilterContext>
  );
};
