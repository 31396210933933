import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routesFormRedux } from "../features/routes/RoutesFormRedux";
import { RoutesFormModel } from "../features/routes/RoutesFormModel";
import { routeAgreementUpdateShouldTriggerRecalculation } from "../features/routes/util/RouteAgreementRecalculationDecider";
import { clearAllPriceStatisticsForRouteAgreement } from "../features/routes/thunks/ClearAllPriceStatisticsForRouteAgreement";
import { SwitchForRouteAgreementSalesReady } from "../../../../../common/components/data-driven-inputs/switches/SwitchForRouteAgreementSalesReady";
import { RouteAgreement } from "../../../customers/customer-details/types";
import { Indent, Row, Text } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  routeAgreementId: string;
  routeAgreement: Pick<RouteAgreement, "statusCode">;
}

export const SalesReadySwitch: React.FC<Props> = ({
  routeAgreementId,
  routeAgreement,
}) => {
  const dispatch = useDispatch();
  const routesFormModel = useSelector(routesFormRedux.selectors.getEntity);

  const setFields = useCallback(
    (fields: Partial<RoutesFormModel>) => {
      dispatch(routesFormRedux.actions.setEntityFields(fields));

      if (routeAgreementUpdateShouldTriggerRecalculation(fields)) {
        dispatch(clearAllPriceStatisticsForRouteAgreement(routeAgreementId));
      }
    },
    [dispatch, routeAgreementId]
  );

  return (
    <Row alignItems={"center"}>
      <SwitchForRouteAgreementSalesReady
        routeAgreement={routeAgreement}
        value={routesFormModel.isSalesReady}
        onValueChange={(isSalesReady) => setFields({ isSalesReady })}
      />
      <Indent num={0.5} />
      <Text color={cssColor("--lhds-color-ui-500")}>Sales ready</Text>
    </Row>
  );
};
