import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createInternalStandardTableActions,
  createStandardTableActions,
  createStandardTableInitialState,
  createStandardTableReducer,
  StandardTableState,
} from "@stenajs-webui/grid";

import { combineReducers } from "redux";
import { BulkEditRatesTableColumn } from "../config/BulkEditRatesTableConfig";
import { BulkEditRatePriceTableItem } from "../types";
import { CargoStatusCode, HeadAgreementStatusCode } from "@/gql/graphql";

export interface BulkEditRatesTableState {
  standardTable: StandardTableState<BulkEditRatesTableColumn>;
}

export const bulkEditRatesStandardTableId = "bulkEditRatesStandardTable";
export const bulkEditRatesStandardTableActions = createStandardTableActions(
  bulkEditRatesStandardTableId,
  createInternalStandardTableActions<BulkEditRatesTableColumn>()
);

export const bulkEditRatesStandardTableInitialState =
  createStandardTableInitialState<BulkEditRatesTableColumn>();

export const bulkEditRatesTableReducer =
  combineReducers<BulkEditRatesTableState>({
    standardTable: createStandardTableReducer<BulkEditRatesTableColumn>(
      bulkEditRatesStandardTableId
    ),
  });

export const bulkEditRatesReducerId = "bulkEditRates";
const createInitialBulkEditRatesTableItem = (): BulkEditRatePriceTableItem => ({
  id: "",
  actual: null,
  additionalFrom: null,
  avgGrossPrice: null,
  avgLength: null,
  cargoStatusCode: CargoStatusCode.Both,
  lastChargedFrom: null,
  lastMeterPrice: null,
  lastPrice: null,
  lengthFrom: null,
  lengthTo: null,
  avgRebate: null,
  meterPrice: "",
  newPrice: "",
  volume: null,
  unaccompanied: null,
  vehicleType: null,
  weightFrom: null,
  weightTo: null,
  routeAgreement: {
    id: "",
    agreementNumber: 0,
    currency: {
      id: "",
      code: "",
    },
    name: "",
    prices: [],
    routeDescription: "",
    routes: [],
    rowVersion: "",
    sailingTypeCodes: [],
    statusCode: HeadAgreementStatusCode.Active,
    isSalesReady: false,
    valid: {
      start: null,
      end: null,
    },
  },
});

export interface BulkEditRatesState {
  persisted: Record<string, BulkEditRatePriceTableItem | undefined>;
  edited: Record<string, BulkEditRatePriceTableItem | undefined>;
  bulkEditRatesLoading: {
    bulkUpdateLoading: boolean;
    saveChangesLoading: boolean;
  };
  saveErrors?: Array<Error>;
}

const initialState: BulkEditRatesState = {
  persisted: {},
  edited: {},
  bulkEditRatesLoading: {
    bulkUpdateLoading: false,
    saveChangesLoading: false,
  },
};

const bulkEditRatesSlice = createSlice({
  name: bulkEditRatesReducerId,
  initialState,
  reducers: {
    revert(state, action: PayloadAction<{ id: string }>) {
      state.edited[action.payload.id] = state.persisted[action.payload.id];
    },
    revertAll(state) {
      state.edited = state.persisted;
    },
    setValue(
      state,
      action: PayloadAction<
        { id: string } & Partial<BulkEditRatePriceTableItem>
      >
    ) {
      state.edited[action.payload.id] = {
        ...createInitialBulkEditRatesTableItem(),
        ...state.edited[action.payload.id],
        ...action.payload,
      };
    },
    setState(state, action: PayloadAction<Array<BulkEditRatePriceTableItem>>) {
      action.payload.forEach((tableRow) => {
        state.edited[tableRow.id] = tableRow;
        state.persisted[tableRow.id] = tableRow;
      });
    },
    clear(state) {
      state.edited = initialState.edited;
      state.persisted = initialState.persisted;
    },
    setBulkUpdateRatesLoading(
      state,
      { payload: loading }: PayloadAction<boolean>
    ) {
      state.bulkEditRatesLoading.bulkUpdateLoading = loading;
    },
    setSaveChangesLoading(state, { payload: loading }: PayloadAction<boolean>) {
      state.bulkEditRatesLoading.saveChangesLoading = loading;
    },
    setSaveError(state, { payload: error }: PayloadAction<Error | undefined>) {
      state.saveErrors = error ? [error] : undefined;
    },
    setSaveErrors(state, { payload: errors }: PayloadAction<Array<Error>>) {
      state.saveErrors = errors;
    },
    clearSaveErrors(state) {
      state.saveErrors = undefined;
    },
  },
});

export const { reducer: bulkEditRatesReducer, actions: bulkEditRatesActions } =
  bulkEditRatesSlice;
