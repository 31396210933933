import * as React from "react";
import { Box, Indent, Row } from "@stenajs-webui/core";
import { VehicleTypeIcon } from "../../../../common/components/vehicle-type-icon/VehicleTypeIcon";
import { ModifiedHighlightText } from "./ModifiedHighlightText";
import { RateSheetTableRowState } from "../reducer";
import { Tooltip } from "@stenajs-webui/tooltip";

interface Props {
  item: RateSheetTableRowState;
}

export const VehicleTypeCell: React.FC<Props> = ({ item }) => {
  return (
    <Row alignItems={"center"} indent>
      <Box justifyContent={"flex-start"} justifyItems={"center"} width={"32px"}>
        <Tooltip
          label={
            item.editable.vehicleType?.shortDescription ?? "All vehicle types"
          }
        >
          <VehicleTypeIcon vehicleTypeCode={item.editable.vehicleType?.id} />
        </Tooltip>
      </Box>
      <Indent />
      <ModifiedHighlightText
        persisted={item.persisted?.vehicleType?.id ?? "All"}
        editable={item.editable.vehicleType?.id ?? "All"}
      />
    </Row>
  );
};
