import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  StatisticsSearchSalesRegionListByFilterOptions,
  UseSalesRegionListQuery,
  UseSalesRegionListQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseSalesRegionList(
    $filterOptions: StatisticsSearchSalesRegionListByFilterOptions!
  ) {
    statistics {
      salesRegionList {
        byFilter(filterOptions: $filterOptions) {
          items {
            salesRegion {
              id
              code
              name
            }
            negotiationStatusStatistics {
              accounts
              statistics {
                negotiationStatus {
                  id
                  name
                }
                numberVolumeStatistics {
                  percentageOfAccount
                  percentageOfVolume
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useSalesRegionListQuery = (
  filterOptions: StatisticsSearchSalesRegionListByFilterOptions
) => {
  const { error, loading, data } = useQuery<
    UseSalesRegionListQuery,
    UseSalesRegionListQueryVariables
  >(query, {
    variables: { filterOptions },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip:
      filterOptions.salesRegionCodes.length === 0 || filterOptions.year == null,
  });

  return {
    error,
    loading,
    salesRegionItems: data?.statistics.salesRegionList.byFilter?.items,
  };
};
