import { CopyAgreementMultiLegRouteAgreementsFragment } from "@/gql/graphql";
import { orderBy } from "lodash";

type SortableMultiLegAgreement = Pick<
  CopyAgreementMultiLegRouteAgreementsFragment,
  "name" | "valid"
>;
export const sortMultiLegAgreementsForCopy = <
  T extends SortableMultiLegAgreement
>(
  multiLegAgreements: Array<T>
): T[] =>
  orderBy(
    multiLegAgreements,
    [(a) => a.name, (a) => a.valid.end],
    ["asc", "desc", "asc"]
  );
