import { useSelector } from "react-redux";
import { routeCustomerTargetSelectors } from "../redux/selectors";
import { useMemo } from "react";

export const useOrderedCustomerTargetTableRows = () => {
  const idList = useSelector(routeCustomerTargetSelectors.getListOrder);
  const tableRows = useSelector(
    routeCustomerTargetSelectors.getPersistedCustomerTargetTableRows
  );
  return useMemo(() => {
    return idList.map((order) => tableRows[order]);
  }, [idList, tableRows]);
};
