import {
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import * as React from "react";
import { useMemo } from "react";
import { HeadAgreement } from "../types";
import { getIdsForRouteAgreementsToPrefill } from "../util/RouteSelectionPrefiller";
import { CopyHeadAgreement } from "./CopyHeadAgreement";
import { SelectedRouteAgreementColumns } from "./SelectRouteAgreements";
import {
  ByCustomerNumberOrIndexFragment,
  CopyHeadAgreementTargetCustomerQuery,
} from "@/gql/graphql";

interface Props {
  headAgreement: HeadAgreement;
  targetHeadAgreement?: CopyHeadAgreementTargetCustomerQuery["productPrice"]["headAgreement"]["byId"];
  targetCustomer?: ByCustomerNumberOrIndexFragment;
  copyHaToOtherCustomer?: boolean;
}

export const CopyHeadAgreementContainer: React.FC<Props> = React.memo(
  ({
    headAgreement,
    copyHaToOtherCustomer,
    targetHeadAgreement,
    targetCustomer,
  }) => {
    const initialState = useMemo(
      () =>
        createStandardTableInitialState<SelectedRouteAgreementColumns>(
          undefined,
          undefined,
          getIdsForRouteAgreementsToPrefill(headAgreement)
        ),
      [headAgreement]
    );

    const { tableContext } =
      useLocalStateTableContext<SelectedRouteAgreementColumns>(
        "CopyHeadAgreementTable",
        initialState
      );

    return (
      <>
        <CopyHeadAgreement
          headAgreement={headAgreement}
          targetHeadAgreement={targetHeadAgreement}
          tableContext={tableContext}
          copyHaToOtherCustomer={copyHaToOtherCustomer}
          targetCustomer={targetCustomer}
        />
      </>
    );
  }
);
