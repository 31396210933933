import {
  createEditableEntityActions,
  createEditableEntityReducer,
  createEntityActions,
  createEntityReducer,
  createRecordObjectActions,
  createRecordObjectReducer,
  EditableEntityState,
  RecordObjectState,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import {
  EditRouteAgreementFormModel,
  emptyEditRouteAgreementFormModel,
} from "../route-agreement/common/components/models/EditRouteAgreementFormModel";
import { AppThunk } from "../../../config/redux/RootReducer";
import { keys } from "lodash";
import { batch } from "react-redux";
import { combineReducers } from "redux";

const reducerId = "customerRoute";
const reducerIdProgress = "customerRoute.progress";

export interface CustomerRouteState {
  progress: ProgressState;
  routeAgreements: RecordObjectState<EditableEntityState<RouteAgreementState>>;
}

interface ProgressState {
  loading: boolean;
  errors?: Array<Error>;
}

interface RouteAgreementState extends EditRouteAgreementFormModel {
  rowVersion: string;
}

export const customerRouteRedux = {
  reducer: combineReducers({
    progress: reducerIdGate(
      reducerIdProgress,
      createEntityReducer<ProgressState>({ loading: false })
    ),
    routeAgreements: reducerIdGate(
      reducerId,
      createRecordObjectReducer(
        createEditableEntityReducer<RouteAgreementState>(
          { ...emptyEditRouteAgreementFormModel, rowVersion: "" },
          ""
        )
      )
    ),
  }),
  actions: {
    setLoading: (loading: boolean) =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields({ loading })
      ),
    setError: (error: Error | undefined) =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields(
          error ? { errors: [error] } : { errors: undefined }
        )
      ),
    setErrors: (errors: Array<Error>) =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields({ errors: errors })
      ),
    clearErrors: () =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields({
          errors: undefined,
        })
      ),
    clearAll: (): AppThunk => async (dispatch) => {
      dispatch(
        reducerIdGateAction(
          reducerId,
          createRecordObjectActions().clearAllRecords()
        )
      );
      dispatch(
        reducerIdGateAction(
          reducerIdProgress,
          createEntityActions<ProgressState>().setEntityFields({
            errors: undefined,
            loading: false,
          })
        )
      );
    },
    commitAllChanges: (): AppThunk => async (dispatch, getState) => {
      const routeAgreementIdList = keys(getState().customerRoute);
      batch(() => {
        routeAgreementIdList.forEach((routeAgreementId) => {
          dispatch(
            reducerIdGateAction(
              reducerId,
              createRecordObjectActions().recordAction(
                routeAgreementId,
                createEditableEntityActions<RouteAgreementState>().commitEditableEntityToPersisted()
              )
            )
          );
        });
      });
    },
    commitChangesForRouteAgreement:
      (routeAgreementId: string): AppThunk =>
      async (dispatch) => {
        dispatch(
          reducerIdGateAction(
            reducerId,
            createRecordObjectActions().recordAction(
              routeAgreementId,
              createEditableEntityActions<RouteAgreementState>().commitEditableEntityToPersisted()
            )
          )
        );
      },
    discardChanges: (): AppThunk => async (dispatch, getState) => {
      const routeAgreementIdList = keys(
        getState().customerRoute.routeAgreements
      );
      batch(() => {
        routeAgreementIdList.forEach((routeAgreementId) =>
          dispatch(
            reducerIdGateAction(
              reducerId,
              createRecordObjectActions().recordAction(
                routeAgreementId,
                createEditableEntityActions<RouteAgreementState>().revertEditableEntity()
              )
            )
          )
        );
        dispatch(
          reducerIdGateAction(
            reducerIdProgress,
            createEntityActions<ProgressState>().setEntityFields({
              errors: undefined,
            })
          )
        );
      });
    },
    setRouteAgreementModel: (
      routeAgreementId: string,
      formModel: RouteAgreementState
    ) =>
      reducerIdGateAction(
        reducerId,
        createRecordObjectActions().recordAction(
          routeAgreementId,
          createEditableEntityActions<RouteAgreementState>().setEntity(
            formModel
          )
        )
      ),
    setSalesReady: (routeAgreementId: string, isSalesReady: boolean) =>
      reducerIdGateAction(
        reducerId,
        createRecordObjectActions().recordAction(
          routeAgreementId,
          createEditableEntityActions<RouteAgreementState>().setEditableEntityFields(
            { isSalesReady }
          )
        )
      ),
    setVersion: (routeAgreementId: string, version: string) =>
      reducerIdGateAction(
        reducerId,
        createRecordObjectActions().recordAction(
          routeAgreementId,
          createEditableEntityActions<RouteAgreementState>().setEditableEntityFields(
            { rowVersion: version }
          )
        )
      ),
  },
};
