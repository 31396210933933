import { Txt } from "@stenajs-webui/core";
import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { useParams } from "react-router-dom";
import { CreateMultiLegRouteAgreementSetup } from "./CreateMultiLegRouteAgreementSetup";
import { HeadAgreementByIdFragment } from "@/gql/graphql";

interface Props {
  multiLegAgreement: HeadAgreementByIdFragment["multiLegAgrRoutes"]["0"];
  isOpen: boolean;
  name?: string;
  selectedRouteCode?: string;
  onRequestClose: () => void;
}

export const CreateMultiLegRouteAgreementModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  name,
  selectedRouteCode,
  ...props
}) => {
  const { customerId } = useParams() as { customerId: string };

  return (
    <Window
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      indent={2}
      width={"100%"}
      header={
        <Txt variant={"bold"} size={"large"}>
          {`Create multi leg route agreement (${props.multiLegAgreement.route.code})`}
        </Txt>
      }
    >
      <CreateMultiLegRouteAgreementSetup
        {...props}
        customerId={customerId}
        onRequestClose={onRequestClose}
        name={name}
        selectedRouteCode={selectedRouteCode}
      />
    </Window>
  );
};
