import { IconSize } from "@/common/components/icons/IconSize";
import { formatThousands } from "@/common/numbers";
import { Indent, Text } from "@stenajs-webui/core";
import { Icon, stenaBan } from "@stenajs-webui/elements";
import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { sumBy } from "lodash";
import { routeFactory } from "../../../../../RouteFactory";
import { Link } from "../../../../../common/navigation/Link";
import { GaActionTable } from "../../../../../common/utils/tracking/enums/actions/GaActionTable";
import { GaValuesTableColumns } from "../../../../../common/utils/tracking/enums/values/GaValuesTableColumns";
import { CustomerNegotiationStatusEditor } from "../../customer-negotiation-status/components/CustomerNegotiationStatusEditor";
import { CustomerInTable } from "../types";
import { LinkCell } from "../util/LinkCell";
import { CustomerStatusCode } from "@/gql/graphql";

export type CustomerListTableColumnKeys =
  | keyof Pick<
      CustomerInTable,
      | "name"
      | "custIndex"
      | "custNo"
      | "seller"
      | "parent"
      | "statisticalGrouping"
      | "isParent"
      | "activeHeadAgreementByYear"
      | "expectedVolume"
    >
  | "blocked"
  | "negotiationStatus"
  | "volume12Months"
  | "statVolume12Months"
  | "expectedVolume"
  | "fill";

export const customerListTableConfig = (negotiationYear: number) => {
  const customerListTableConfig: StandardTableConfig<
    CustomerInTable,
    CustomerListTableColumnKeys
  > = {
    keyResolver: (item) => item.id,
    stickyHeader: true,
    headerRowOffsetTop: "112px",
    showRowCheckbox: true,
    showHeaderCheckbox: true,
    columns: {
      blocked: {
        itemValueResolver: (item) =>
          item.negotiationStatusByYear?.presentationOrder,
        columnLabel: "",
        borderLeft: false,
        justifyContentCell: "center",
        width: "24px",
        sticky: true,
        renderCell: ({ label, item }) =>
          item.custStatus?.code === CustomerStatusCode.Blocked && (
            <Icon
              icon={stenaBan}
              color={cssColor("--lhds-color-red-500")}
              size={IconSize.Small}
            />
          ),
      },
      name: {
        itemValueResolver: (item) => item.name,
        columnLabel: "Name",
        width: "250px",
        left: "24px",
        sticky: true,
        renderCell: ({ label, item }) => (
          <LinkCell
            label={label}
            to={
              item.preselectedHeadAgreement
                ? routeFactory.productAndPrice.customer.customerHeadAgreement({
                    customerId: item.id,
                    headAgreementId: item.preselectedHeadAgreement.id,
                  })
                : routeFactory.productAndPrice.customer.customerNoHeadAgreement(
                    {
                      customerId: item.id,
                    }
                  )
            }
            gaTracker={{
              action: GaActionTable.linkClicked,
              value: GaValuesTableColumns.name,
            }}
          />
        ),
      },
      negotiationStatus: createColumnConfig(
        (item) => item.negotiationStatusByYear?.presentationOrder,
        {
          columnLabel: "Neg status",
          borderLeft: true,
          minWidth: "110px",
          renderCell: ({ label, value, item }) => (
            <CustomerNegotiationStatusEditor
              customer={item}
              isInTable={true}
              negotiationYear={negotiationYear}
            />
          ),
        }
      ),
      custNo: createColumnConfig((item) => item.custNo, {
        columnLabel: "Cust. no.",
        minWidth: "100px",
        borderLeft: true,
      }),
      custIndex: createColumnConfig((item) => item.custIndex, {
        columnLabel: "Index",
        minWidth: "95px",
      }),
      isParent: createColumnConfig((item) => item.isParent, {
        columnLabel: "Type",
        minWidth: "70px",
        justifyContentCell: "flex-start",
        justifyContentHeader: "flex-start",
        itemLabelFormatter: (value) => (value ? "P" : "C"),
      }),
      statisticalGrouping: createColumnConfig(
        (item) => item.statisticalGrouping?.id,
        {
          minWidth: "90px",
          columnLabel: "Stat. no.",
        }
      ),
      parent: createColumnConfig((item) => item.parent?.custNo, {
        columnLabel: "Parent no.",
        minWidth: "105px",
      }),
      volume12Months: createColumnConfig(
        (item) =>
          sumBy(item.statisticsByFilter.items, (item) => item.volume12Months),
        {
          borderLeft: true,
          itemLabelFormatter: formatThousands,
          columnLabel: "12 m. vol",
          minWidth: "95px",
          justifyContentCell: "flex-end",
          justifyContentHeader: "flex-end",
        }
      ),
      seller: createColumnConfig((item) => item.sellerPresentationOrder, {
        minWidth: "80px",
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>{item.seller.name}</Text>
          </Indent>
        ),
      }),
      statVolume12Months: createColumnConfig(
        (item) =>
          sumBy(
            item.statisticsByFilter.items,
            (item) => item.statVolume12Months ?? 0
          ),
        {
          itemLabelFormatter: formatThousands,
          columnLabel: "12 m. stat vol.",
          minWidth: "125px",
          justifyContentCell: "flex-end",
          justifyContentHeader: "flex-end",
        }
      ),
      expectedVolume: createColumnConfig((item) => item.expectedVolume, {
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Link
                to={routeFactory.productAndPrice.customer.customerExpectedVolume(
                  {
                    customerId: item.id,
                  }
                )}
              >
                {value != null ? formatThousands(value) : "Set"}
              </Link>
            </Indent>
          );
        },
        columnLabel: "Exp. vol.",
        minWidth: "90px",
        justifyContentCell: "flex-end",
        justifyContentHeader: "flex-end",
      }),
      activeHeadAgreementByYear: createColumnConfig(
        (item) => item.activeHeadAgreementByYear,
        {
          columnLabel: "Agr",
          minWidth: "90px",
          justifyContentCell: "flex-start",
          justifyContentHeader: "flex-start",
          borderLeft: true,
          itemLabelFormatter: (value) => (value ? "Y" : "N"),
        }
      ),
      fill: createColumnConfig((item) => "", {
        columnLabel: "",
        width: "24px",
      }),
    },
    columnOrder: [
      "blocked",
      "name",
      "negotiationStatus",
      "custNo",
      "custIndex",
      "statisticalGrouping",
      "parent",
      "seller",
      "activeHeadAgreementByYear",
      "isParent",
      "volume12Months",
      "statVolume12Months",
      "expectedVolume",
      "fill",
    ],
  };
  return customerListTableConfig;
};
