import { gql } from "apollo-boost";
import { apolloClient } from "../../../../../../../apollo-client/ApolloClient";
import { AppThunk } from "../../../../../../../config/redux/RootReducer";
import { compact, first, groupBy, sortBy, values } from "lodash";
import { bulkEditRatesStatBoxesActions } from "../redux/reducer";
import {
  calculateCustomerStatisticsBulkEditRatesTable,
  transformBulkEditRatePriceTableItemToPriceInput,
} from "../transformers/BulkEditRatesStatBoxDataTransformer";
import { mergeBulkEditRatesStatBoxQueryData } from "../transformers/BulkEditRatesStatBoxQueryDataMerger";
import {
  CalculatePriceRouteAgreementPriceInput,
  RefetchDataForBulkEditStatBoxesQuery,
  RefetchDataForBulkEditStatBoxesQueryVariables,
  RouteAgreementsByRoutePairCode,
} from "@/gql/graphql";

const query = gql`
  query RefetchDataForBulkEditStatBoxes(
    $input: CalculateActualPriceChangeForAllBulkEditRatesInput!
  ) {
    productPrice {
      customerRouteStatistics {
        calculateActualPriceChangeForAllBulkEditRates(input: $input) {
          actualOutcomeInPercent
          expectedVolume
          id
          sailingCategoryCode
          statVolume12Months
          expectedStatVolume
          targetInPercent
          unitCategoryCode
          volume12Months
          routePairCode
        }
      }
    }
  }
`;

export const refetchStatBoxesWithBulkEditRatesChanges =
  (): AppThunk => async (dispatch, getState) => {
    const tableState = getState().bulkEditRatesState.edited;
    const tableListState = Object.values(tableState);

    const { queryParams, headAgreementId } = getState().bulkEditRatesStatBoxes;

    if (!queryParams) {
      throw new Error("Failed to refetch stat boxes, query params not set.");
    }

    if (!headAgreementId) {
      throw new Error("Failed to refetch stat boxes, headAgreementId not set.");
    }

    const { year, customerId } = queryParams;

    const routePrice = compact(values(tableListState));

    if (!tableState) {
      throw new Error(`No table state available`);
    }

    dispatch(bulkEditRatesStatBoxesActions.setLoading(true));

    const routePricesByRoutePairCode = groupBy(
      routePrice,
      (r) => r.routeAgreement.routes[0].routePair.code
    );

    const routeAgreementsByRoutePairCode = Object.keys(
      routePricesByRoutePairCode
    )
      .map<RouteAgreementsByRoutePairCode>((routePairCode) => {
        const routePrices = routePricesByRoutePairCode[routePairCode] ?? [];
        const routePricesBySailingTypeCodes = groupBy(
          routePrices,
          (routePrice) =>
            sortBy(routePrice.routeAgreement.sailingTypeCodes).join()
        );

        return {
          routePairCode,
          routeAgreements: values(routePricesBySailingTypeCodes).map(
            (routePrices) => {
              return {
                routeAgreementPrices:
                  routePrices.map<CalculatePriceRouteAgreementPriceInput>(
                    (rap) =>
                      transformBulkEditRatePriceTableItemToPriceInput(rap)
                  ),
                sailingTypeCodes:
                  first(routePrices)?.routeAgreement.sailingTypeCodes ?? [],
              };
            }
          ),
        };
      })
      .filter((ra) => ra.routeAgreements.length > 0);

    const statBoxes = await apolloClient.query<
      RefetchDataForBulkEditStatBoxesQuery,
      RefetchDataForBulkEditStatBoxesQueryVariables
    >({
      query,
      variables: {
        input: {
          headAgreementId,
          routeAgreementsByRoutePairCode: routeAgreementsByRoutePairCode,
          validFromYear: year,
        },
      },
      fetchPolicy: "no-cache",
    });

    const items =
      statBoxes.data.productPrice.customerRouteStatistics
        .calculateActualPriceChangeForAllBulkEditRates;

    if (!items) {
      dispatch(bulkEditRatesStatBoxesActions.setLoading(false));
      return;
    }

    const prevQueryData = getState().bulkEditRatesStatBoxes.queryData ?? {};
    const queryData = mergeBulkEditRatesStatBoxQueryData(prevQueryData, items);

    dispatch(bulkEditRatesStatBoxesActions.setQueryData(queryData));

    const groupByRoutePairCodes = groupBy(queryData, (i) => i.routePairCode);
    const groupedList = Object.keys(groupByRoutePairCodes).map(
      (routePairCode) =>
        calculateCustomerStatisticsBulkEditRatesTable(
          groupByRoutePairCodes[routePairCode],
          customerId,
          routePairCode
        )
    );

    dispatch(bulkEditRatesStatBoxesActions.setStatBoxesData(groupedList));
    dispatch(bulkEditRatesStatBoxesActions.setLoading(false));
  };
