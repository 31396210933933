import * as React from "react";
import { useParams } from "react-router-dom";
import { ErrorScreen } from "@stenajs-webui/panels";
import { StatisticsBySalesRegionDetails } from "./StatisticsBySalesRegionDetails";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";

interface Props {}

interface StatisticsBySalesRegionDetailsScreenParams {
  salesRegionId?: string;
  year?: string;
}

export const StatisticsBySalesRegionDetailsScreen: React.FC<Props> = () => {
  const { salesRegionId } =
    useParams() as StatisticsBySalesRegionDetailsScreenParams;
  const { year } = useStatisticsYearSelect();

  if (!salesRegionId) {
    return <ErrorScreen text={"No such sales region."} />;
  }

  return (
    <>
      <StatisticsBySalesRegionDetails
        salesRegionId={salesRegionId}
        year={year}
      />
    </>
  );
};
