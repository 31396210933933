import { cssColor } from "@stenajs-webui/theme";
import { Indent, Text } from "@stenajs-webui/core";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { routePairLabelWithDescriptionForTables } from "../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import { Link } from "../../../../common/navigation/Link";
import {
  formatPercent,
  formatPercentWithSign,
  formatThousands,
} from "@/common/numbers";
import { routeFactory } from "../../../../RouteFactory";
import { RouteTargetStatusEditor } from "../features/route-target-status/components/RouteTargetStatusEditor";
import { TargetOverview } from "../types";

export type RouteTargetsListTableColumnKeys =
  | keyof Omit<TargetOverview, "id" | "__typename">
  | "fillFirst"
  | "fillLast";

const noLabelColumnOrder: Array<RouteTargetsListTableColumnKeys> = [
  "fillFirst",
  "routePairCode",
  "routePair",
  "negotiationStatus",
  "completionRate",
  "noOfAccounts",
  "last12MonthVol",
];

const groupFreightColumnOrder: Array<RouteTargetsListTableColumnKeys> = [
  "volumeTargetGm",
  "weightedTargetGm",
];

const customerFreightColumnOrder: Array<RouteTargetsListTableColumnKeys> = [
  "weightedTargetCustomer",
  "fillLast",
];

const customerTargetFontColor = (item: TargetOverview): string => {
  if (
    parseFloat(String(item?.weightedTargetGm ?? 0)).toFixed(2) <
    parseFloat(String(item?.weightedTargetCustomer ?? 0)).toFixed(2)
  ) {
    return cssColor("--lhds-color-green-500");
  }
  if (
    parseFloat(String(item?.weightedTargetGm ?? 0)).toFixed(2) >
    parseFloat(String(item?.weightedTargetCustomer ?? 0)).toFixed(2)
  ) {
    return cssColor("--lhds-color-red-500");
  }

  return "";
};

const customerTargetBackgroundColor = (item: TargetOverview): string => {
  if (
    parseFloat(String(item?.weightedTargetGm ?? 0)).toFixed(2) <
    parseFloat(String(item?.weightedTargetCustomer ?? 0)).toFixed(2)
  ) {
    return cssColor("--lhds-color-green-50");
  }

  if (
    parseFloat(String(item?.weightedTargetGm ?? 0)).toFixed(2) >
    parseFloat(String(item?.weightedTargetCustomer ?? 0)).toFixed(2)
  ) {
    return cssColor("--lhds-color-red-50");
  }

  return "";
};

export const routeTargetOverviewTableConfig: StandardTableConfig<
  TargetOverview,
  RouteTargetsListTableColumnKeys,
  "noLabelGroup" | "groupFreightTargets" | "customerTargets"
> = {
  keyResolver: (item) => item.routePairCode,
  stickyHeader: true,
  headerRowOffsetTop: "53px",
  disableInfiniteList: true,
  columns: {
    fillFirst: createColumnConfig((item) => "", {
      columnLabel: "",
      width: "24px",
    }),
    routePairCode: createColumnConfig((item) => item.routePairCode, {
      columnLabel: "Route",
      renderCell: ({ label, value, item }) => (
        <Indent overflow={"hidden"}>
          <Text whiteSpace={"nowrap"}>
            <Link
              to={routeFactory.targetingTool.routeTargets.routeTarget({
                routePairCode: item.routePairCode,
              })}
              title={label}
            >
              {label}
            </Link>
          </Text>
        </Indent>
      ),
    }),
    routePair: {
      itemValueResolver: (item) => item.routePair,
      justifyContentHeader: "flex-start",
      justifyContentCell: "flex-start",
      renderCell: ({ label, value, item }) =>
        routePairLabelWithDescriptionForTables(item.routePair.description),
      columnLabel: "Directions",
      width: "220px",
      disableGridCell: true,
    },
    negotiationStatus: createColumnConfig(
      (item) => item.negotiationStatus?.description,
      {
        columnLabel: "Status",
        minWidth: "110px",
        borderLeft: true,
        renderCell: ({ label, value, item }) => (
          <RouteTargetStatusEditor targetOverview={item} />
        ),
      }
    ),
    completionRate: createColumnConfig((item) => item.completionRate, {
      columnLabel: "Completion rate (%)",
      borderLeft: true,
      minWidth: "160px",
      itemLabelFormatter: (value) => formatPercent(value / 100, 2, false, 2),
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",
    }),
    noOfAccounts: createColumnConfig((item) => item.noOfAccounts, {
      columnLabel: "Customers",
      itemLabelFormatter: formatThousands,
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",
      minWidth: "105px",
      borderLeft: true,
    }),
    last12MonthVol: createColumnConfig((item) => item.last12MonthVol, {
      columnLabel: "12 month volume",
      minWidth: "145px",
      borderLeft: true,
      itemLabelFormatter: formatThousands,
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",
    }),
    volumeTargetGm: createColumnConfig((item) => item.volumeTargetGm, {
      columnLabel: "Volume",
      minWidth: "90px",
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",
      itemLabelFormatter: (value) =>
        value !== null && value !== undefined
          ? formatPercentWithSign(value / 100, 2, false, 2)
          : "",
    }),
    weightedTargetGm: createColumnConfig((item) => item.weightedTargetGm, {
      columnLabel: "Rate (weighted)",
      minWidth: "135px",
      borderLeft: true,
      justifyContentCell: "flex-end",
      justifyContentHeader: "flex-end",
      itemLabelFormatter: (value) =>
        value !== null && value !== undefined
          ? formatPercentWithSign(value / 100, 2, false, 2)
          : "",
    }),
    weightedTargetCustomer: createColumnConfig(
      (item) => item.weightedTargetCustomer,
      {
        columnLabel: "Rate (weighted)",
        minWidth: "135px",
        justifyContentCell: "flex-end",
        justifyContentHeader: "flex-end",
        backgroundResolver: (targetOverview) => {
          return customerTargetBackgroundColor(targetOverview);
        },
        renderCell: ({ label, value, item }) => {
          return (
            <Text color={customerTargetFontColor(item)}>
              {value !== null && value !== undefined
                ? formatPercentWithSign(value / 100, 2, false, 2)
                : ""}
            </Text>
          );
        },
      }
    ),
    fillLast: createColumnConfig((item) => "", {
      columnLabel: "",
      width: "24px",
    }),
  },
  columnOrder: [
    "routePairCode",
    "routePair",
    "negotiationStatus",
    "completionRate",
    "noOfAccounts",
    "last12MonthVol",
    "volumeTargetGm",
    "weightedTargetGm",
    "weightedTargetCustomer",
  ],
  columnGroups: {
    noLabelGroup: {
      columnOrder: noLabelColumnOrder,
    },
    groupFreightTargets: {
      borderLeft: true,
      columnOrder: groupFreightColumnOrder,
      label: "Group freight targets (%)",
    },
    customerTargets: {
      borderLeft: true,
      columnOrder: customerFreightColumnOrder,
      label: "Customer targets (%)",
    },
  },
  columnGroupOrder: ["noLabelGroup", "groupFreightTargets", "customerTargets"],
};
