import { gql } from "apollo-boost";

import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import {
  UpdateRateSheetsForRouteAgreementMutation,
  UpdateRateSheetsForRouteAgreementMutationVariables,
  UpdateRateSheetsInput,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import {
  rateSheetTablePricesFragment,
  customerRouteRouteAgreementFragment,
} from "../../route-agreement/details/fragments/RouteAgreementPageFragment";

const mutation = gql`
  ${errorFragment}
  ${rateSheetTablePricesFragment}
  ${customerRouteRouteAgreementFragment}
  mutation UpdateRateSheetsForRouteAgreement($input: UpdateRateSheetsInput!) {
    productPrice {
      routeAgreementPrice {
        updateRateSheets(input: $input) {
          __typename
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRateSheetsSuccessResult {
            routeAgreement {
              ...CustomerRouteRouteAgreement
              prices {
                ...RateSheetTablePrices
              }
            }
          }
        }
      }
    }
  }
`;

export const useUpdateRateSheetsForRouteAgreementMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    UpdateRateSheetsForRouteAgreementMutation,
    UpdateRateSheetsForRouteAgreementMutationVariables
  >(mutation);

  const updateRateSheetsForRouteAgreement = useCallback(
    (input: UpdateRateSheetsInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );

  return {
    updateRateSheetsForRouteAgreement,
    loading,
    error,
  };
};
