import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { errorFragment } from "../../../../../../../common/error/GqlErrorFragment";
import {
  DeleteRouteAgreementMutation,
  DeleteRouteAgreementMutationVariables,
} from "@/gql/graphql";

const query = gql`
  ${errorFragment}
  mutation DeleteRouteAgreement($routeAgreementId: ID!, $rowVersion: String!) {
    productPrice {
      routeAgreement {
        deleteRouteAgreement(
          routeAgreementId: $routeAgreementId
          rowVersion: $rowVersion
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on DeleteRouteAgreementSuccessResult {
            success
          }
        }
      }
    }
  }
`;

export const useDeleteRouteAgreementMutation = () =>
  useMutation<
    DeleteRouteAgreementMutation,
    DeleteRouteAgreementMutationVariables
  >(query);
