import { ApolloProvider } from "@apollo/client";
import { Column, Spacing } from "@stenajs-webui/core";
import { LoadingScreen } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";

import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { apolloClient, msalInstance } from "./apollo-client/ApolloClient";
import { AppRoutes } from "./AppRoutes";
import { fetchNegotiationYearsForProfile } from "./common/redux/profile/thunks/FetchNegotiationYearsForProfile";
import { AuthMsalGate } from "./config/auth/AuthMsalGate";
import { UserProfileGate } from "./config/auth/UserProfileGate";
import { ErrorBoundary } from "./config/ErrorBoundary";
import { ProfileCurrencyErrorBoundary } from "./config/ProfileCurrencyErrorBoundary";
import { createReduxStore } from "./config/redux/StoreFactory";
import { NegotiationYearGate } from "./features/product-and-price/customers/common/negotiation-year/NegotiationYearGate";
import { GoogleAnalyticsPageTracker } from "./GoogleAnalyticsPageTracker";
import { ErrorPage } from "./LoginPage";
import { addGA4 } from "./common/utils/tracking/helper/GaTracker";
import {
  defaultTheme,
  Provider as ReactSpectrumProvider,
} from "@adobe/react-spectrum";
import reactSpectrumStyles from "../src/common/components/date-input/DateInput.module.css";
import { NotificationCenter } from "@/features/notification-center/NotificationCenter";
import { NotificationSubscriber } from "@/features/notification-center/NotificationSubscriber";
import { NavBar } from "./features/nav-bar/NavBar";
import { browserHistory } from "./history";

const { persistor, store } = createReduxStore();

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const removeDeprecatedKeysFromLocalStorage = () => {
  const keysDeleted: boolean = JSON.parse(
    localStorage.getItem("keysDeleted") || "false"
  );
  if (!keysDeleted) {
    Object.keys(localStorage).forEach((key) => {
      if (!key.includes("persist")) {
        localStorage.removeItem(key);
      }
    });
    localStorage.setItem("keysDeleted", JSON.stringify(true));
  }
};

export const App: React.FC = () => {
  const locale = navigator.language;

  useEffect(() => {
    addGA4();
    fetchNegotiationYearsForProfile();
    removeDeprecatedKeysFromLocalStorage();
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ReactSpectrumProvider
          locale={locale}
          theme={defaultTheme}
          UNSAFE_className={reactSpectrumStyles.lightTheme}
          colorScheme="light"
        >
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <ErrorBoundary
              renderFallback={(error) => (
                <ErrorPage text={error.error?.message ?? ""} />
              )}
            >
              <HistoryRouter history={browserHistory}>
                <AuthMsalGate msalInstance={msalInstance}>
                  <UserProfileGate
                    spinner={
                      <Spacing num={16}>
                        <LoadingScreen />
                      </Spacing>
                    }
                  >
                    <NegotiationYearGate
                      spinner={
                        <Spacing num={16}>
                          <LoadingScreen />
                        </Spacing>
                      }
                    >
                      <NotificationSubscriber />
                      <Column
                        background={cssColor("--lhds-color-ui-200")}
                        minHeight={"100vh"}
                        className={"main-column"}
                      >
                        <NavBar msalInstance={msalInstance} />
                        <ProfileCurrencyErrorBoundary />
                        <ErrorBoundary
                          renderFallback={(error) => (
                            <ErrorPage text={error.error?.message ?? ""} />
                          )}
                        >
                          <AppRoutes />
                          <NotificationCenter />
                          <GoogleAnalyticsPageTracker />
                        </ErrorBoundary>
                      </Column>
                    </NegotiationYearGate>
                  </UserProfileGate>
                </AuthMsalGate>
              </HistoryRouter>
            </ErrorBoundary>
          </PersistGate>
        </ReactSpectrumProvider>
      </Provider>
    </ApolloProvider>
  );
};
