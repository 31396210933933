export enum RouteTargetsSearchFilterSelectFacet {
  interactiveEvent = "Route Target Filtering Applied",
  countryFilter = "Country",
  customerFilter = "Number/index",
  networkVolumeFilter = "Network volume (customer)",
  routeVolumeFilter = "Route volume (customer)",
  categoryFilter = "Category",
  regionFilter = "Region",
  sellerFilter = "Seller",
  statNumberFilter = "Stat number",
  statNetworkVolumeFilter = "Stat network volume",
  statRouteVolumeFilter = "Stat route volume",
  sailingCategoryFilter = "Sailing category",
  unitTypesFilter = "Unit type",
  averageGrossFilter = "Average gross price/m",
  averageNetFilter = "Average net price/m",
  targetFilter = "Target",
  targetStatusFilter = "Target status",
}

export enum RouteTargetFilterButtons {
  buttonClicked = "Route Filter Button Clicked",
  searchClick = "ClickSearch",
  clearFilter = "ClearFilter",
  closeFilter = "CloseFilter",
}

export enum RouteTargetFilterData {
  filterDataClicked = "Search Data Route Target Filter",
}
