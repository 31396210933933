import { stenaBusinessNslh } from "@stenajs-webui/elements";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { FPPFilterCheckBoxList } from "./FPPFilterCheckBoxList";
import { useFetchNslhTypes } from "../../hooks/FilterFetchers/UseFetchNslhTypes";

interface Props {
  enabled?: boolean;
  label?: string;
}
export const NoShowLateHandlingTypeSection: React.FC<Props> = (props) =>
  props.enabled ? <NoShowLateHandlingTypeSectionInternal {...props} /> : <></>;
const NoShowLateHandlingTypeSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { loading, nslhTypes: items, refetch } = useFetchNslhTypes();
  const { noShowLateHandlingType, setNoShowLateHandlingType } =
    useFilterWorkspace();
  return (
    <FppSearchFilterSection
      sectionId={"noShowLateHandlingType"}
      enabled={enabled}
      label={label ?? "Handling type"}
      leftIcon={stenaBusinessNslh}
      loading={loading}
      onRetry={refetch}
      numSelected={noShowLateHandlingType.length}
    >
      <FPPFilterCheckBoxList
        checkboxes={items}
        selectedValues={noShowLateHandlingType}
        setValue={setNoShowLateHandlingType}
      />
    </FppSearchFilterSection>
  );
};
