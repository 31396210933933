import { CustomerYearStatistics } from "../../customers/customer-details/types";
import { BarStatsData } from "../../../../common/components/bar-stat-box/components/BarStat";
import { sumBy } from "lodash";

export const sumExpectedVsLastYear = (
  stats: CustomerYearStatistics[],
  id: string,
  color: string
): BarStatsData => {
  return {
    id,
    color,
    expected: sumBy(stats, (s) => s.expectedVolume ?? 0),
    statVolume12Months: sumBy(stats, (s) => s.statVolume12Months ?? 0),
    volume12Months: sumBy(stats, (s) => s.volume12Months ?? 0),
  };
};
