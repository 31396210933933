import { flatMap } from "lodash";
import { GrossNetData } from "../TargetSummaryChart";
import { transformCodeAndNumber } from "../transformers";
import {
  CategoryDataFragment,
  CustomerDataPointsFragment,
  SailingTypeCode,
  UnitCategoryCode,
} from "@/gql/graphql";

const findCategoryData = (
  entries: CategoryDataFragment[],
  scc: SailingTypeCode,
  ucc: UnitCategoryCode
): CategoryDataFragment | undefined =>
  entries.find((e) => e.sailingTypeCode === scc && e.unitCategoryCode === ucc);

const findOrDefaultCategory = (
  entries: CategoryDataFragment[],
  scc: SailingTypeCode,
  ucc: UnitCategoryCode
): CategoryDataFragment =>
  findCategoryData(entries, scc, ucc) ?? {
    unitCategoryCode: ucc,
    sailingTypeCode: scc,
    gmTarget: null,
    noOfAccounts: 0,
    customerTarget: null,
    customerDataPoints: null,
  };

const fixTargetChartData = (
  entries: CategoryDataFragment[],
  scc: SailingTypeCode,
  ucc: UnitCategoryCode
): {
  categoryData: CategoryDataFragment;
  customerDataPoints: CustomerDataPointsFragment[];
} => {
  const data = findOrDefaultCategory(entries, scc, ucc);

  return {
    categoryData: data,
    customerDataPoints:
      data.customerDataPoints?.filter(
        (cdp) => cdp.avgGrossMeterPrice != null && cdp.avgNetMeterPrice != null
      ) ?? [],
  };
};

const getGrossNetData = (
  data: CategoryDataFragment,
  customerDataPoints: CustomerDataPointsFragment[]
): GrossNetData[] => {
  if (!customerDataPoints.length) {
    return [
      {
        netData: {
          group: transformCodeAndNumber(
            customerDataPoints.length,
            data.unitCategoryCode,
            data.sailingTypeCode
          ),
          key: 0,
          value: 0,
        },
        grossData: {
          group: transformCodeAndNumber(
            customerDataPoints.length,
            data.unitCategoryCode,
            data.sailingTypeCode
          ),
          key: 0,
          value: 0,
        },
      },
    ];
  }

  return customerDataPoints.map((cdp) => ({
    netData: {
      group: transformCodeAndNumber(
        customerDataPoints.length,
        data.unitCategoryCode,
        data.sailingTypeCode
      ),
      key: cdp?.custNo,
      value: cdp.avgNetMeterPrice ?? undefined,
    },
    grossData: {
      group: transformCodeAndNumber(
        customerDataPoints.length,
        data.unitCategoryCode,
        data.sailingTypeCode
      ),
      key: cdp.custNo,
      value: cdp.avgGrossMeterPrice ?? undefined,
    },
  }));
};
interface ResponseObject {
  compactData: Array<GrossNetData>;
}

export const useChartComputation = (
  targetChartData: Array<CategoryDataFragment> | undefined
): ResponseObject | undefined => {
  if (!targetChartData) {
    return undefined;
  }

  const categoryData = {
    offPeakUnAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.O,
      UnitCategoryCode.Unacc
    ),
    offPeakAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.O,
      UnitCategoryCode.Acc
    ),
    shoulderUnAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.S,
      UnitCategoryCode.Unacc
    ),
    shoulderAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.S,
      UnitCategoryCode.Acc
    ),
    peakUnAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.P,
      UnitCategoryCode.Unacc
    ),
    peakAcc: fixTargetChartData(
      targetChartData,
      SailingTypeCode.P,
      UnitCategoryCode.Acc
    ),
  };

  if (
    Object.values(categoryData).every(
      ({ customerDataPoints }) => customerDataPoints.length === 0
    )
  ) {
    return undefined;
  }

  const compactData = flatMap(
    Object.values(categoryData),
    ({ categoryData, customerDataPoints }) =>
      getGrossNetData(categoryData, customerDataPoints)
  );

  return { compactData };
};
