import * as React from "react";
import { Select } from "@stenajs-webui/select";

interface Props {
  value?: number;
  onValueChange?: (value: number) => void;
}

const formatLabel = (num: number): string => {
  if (num === 0) {
    return "No decimals";
  }
  if (num === 1) {
    return "One decimal";
  }
  if (num === 2) {
    return "Two decimals";
  }
  return `${num} decimals`;
};

const options = [
  {
    label: "Two decimals",
    value: 2,
  },
  {
    label: "One decimal",
    value: 1,
  },
  {
    label: "No decimals",
    value: 0,
  },
];

export const NumberDecimalsSelect: React.FC<Props> = ({
  onValueChange,
  value,
}) => {
  return (
    <Select
      value={value != null ? { label: formatLabel(value), value } : undefined}
      onChange={(v: any) => onValueChange?.(v.value)}
      options={options}
    />
  );
};
