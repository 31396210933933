import * as React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { hasClaim } from "../../../common/claims/utils";
import { UnAuthorizedView } from "../../../common/components/unauthorized-view/UnAuthorizedView";
import { profileSelectors } from "../../../common/redux/profile/selectors";
import { SurchargeDocumentsView } from "./SurchargeDocumentsView";
import { UPDATE_ROUTE_PRICES } from "@/common/claims/constants";

interface Props {}

export const SurchargeDocumentsScreen: React.FC<Props> = () => {
  const { claims } = useSelector(profileSelectors.getGlobal);

  return (
    <>
      <Helmet title="Surcharge documents" />
      {hasClaim(claims, [UPDATE_ROUTE_PRICES]) ? (
        <SurchargeDocumentsView />
      ) : (
        <UnAuthorizedView />
      )}
    </>
  );
};
