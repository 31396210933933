import styled from "@emotion/styled";

interface Props {
  children?: React.ReactNode;
}

export const PageLayout = styled.div<Props>`
  > * {
    margin: 0 24px 24px 24px;
  }
`;
