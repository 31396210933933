import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import {
  transformAncillaryCosts,
  transformSurchargeCosts,
} from "./TransformPriceCalculatorResult";
import {
  calculateSeaFreightIncludingVatAmount,
  calculateSeaFreightVatAmount,
  calculateSeaFreightVatRate,
} from "../helpers/PriceCalculatorHelper";
import { FetchPriceCalcFragment } from "@/gql/graphql";

export const transformQueryToPriceDetailTableRow = (
  priceCalcData: FetchPriceCalcFragment
) => {
  return [
    ...priceCalcData.ancillaryRecords.map<PriceDetailTableRow>(
      (record, index) => transformAncillaryCosts(record, priceCalcData, index)
    ),
    ...priceCalcData.surchargeRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformSurchargeCosts(
          record,
          priceCalcData,
          index + priceCalcData.ancillaryRecords.length
        )
    ),
  ];
};

export const transformPriceDetailTableRows = (
  priceCalculatorData: FetchPriceCalcFragment,
  priceDetailTableRows: PriceDetailTableRow[]
): PriceDetailTableRow[] => {
  priceDetailTableRows.sort((rowA, rowB) => {
    if (rowA.name < rowB.name) {
      return -1;
    }
    if (rowA.name > rowB.name) {
      return 1;
    }
    return 0;
  });
  return [
    {
      id: "SEAFREIGHT",
      name: "Sea freight",
      code: "SEA",
      priceInclVat: calculateSeaFreightIncludingVatAmount(priceCalculatorData),
      currencyCode: priceCalculatorData.baseCurrencyCode,
      vatRate: calculateSeaFreightVatRate(priceCalculatorData),
      vatAmount: calculateSeaFreightVatAmount(priceCalculatorData),
      priceExclVat: priceCalculatorData.seaFreightPrice,
    },
    ...priceDetailTableRows,
    {
      currencyCode: priceCalculatorData.baseCurrencyCode,
      vatAmount: priceCalculatorData.totalVat,
      priceInclVat:
        priceCalculatorData.totalPrice + priceCalculatorData.totalVat,
      priceExclVat: priceCalculatorData.totalPrice,
      code: "",
      vatRate: 0,
      id: "FOOTER",
      name: "Total",
    },
  ];
};
