import { CreateMultiLegAgreementFormModel } from "../../common/components/edit-and-create-multi-leg-agreement-form/model/EditAndCreateMultiLegAgreementFormModel";

export const validateCreateMultiLegRouteAgreementForm = (
  formModel: CreateMultiLegAgreementFormModel
) => {
  let errors: Array<string> = [];

  // Form Model validation
  !formModel.valid.startDate && (errors = [...errors, "start date"]);
  !formModel.valid.endDate && (errors = [...errors, "end date"]);
  !formModel.currencyCode && (errors = [...errors, "currency"]);
  !formModel.headAgreementId && (errors = [...errors, "headAgreementId"]);
  !formModel.routeCode && (errors = [...errors, "ML route"]);

  if (errors.length) throw new Error("Missing " + errors.join(" "));
};
