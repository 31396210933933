import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { routeFactory } from "../../../../../RouteFactory";
import { HeadAgreementListSwitcher } from "./HeadAgreementListSwitcher";
import { useNavigate } from "react-router-dom";
import { useHeadAgreementsByCustomer } from "../hooks/UseHeadAgreementsSwitcher";

interface Props {
  customerId: string;
  onRequestClose: () => void;
  selectedHeadAgreementId?: string;
}

export const SwitchHeadAgreementModal: React.FC<Props> = ({
  customerId,
  selectedHeadAgreementId,
  onRequestClose,
}) => {
  const navigate = useNavigate();
  const navigateToHeadAgreement = (headAgreementId: string) => {
    navigate(
      routeFactory.productAndPrice.customer.customerHeadAgreement({
        customerId,
        headAgreementId,
      })
    );
  };

  const { headAgreements, error, loading } =
    useHeadAgreementsByCustomer(customerId);

  return (
    <Window
      headerText="Switch header agreement"
      draggable
      isOpen={true}
      width={"800px"}
      onRequestClose={onRequestClose}
      indent={0}
    >
      <HeadAgreementListSwitcher
        headAgreements={headAgreements}
        error={error}
        loading={loading}
        selectedHeadAgreementId={selectedHeadAgreementId}
        onSelectHeadAgreement={navigateToHeadAgreement}
      />
    </Window>
  );
};
