import { AllCustomerTableColumnKeys } from "./RowsAndColumnsConfig";

export const customerNameColumnOrder: Array<AllCustomerTableColumnKeys> = [
  "fillFirst",
  "name",
];

export const customerInfoColumnOrder: Array<AllCustomerTableColumnKeys> = [
  "country",
  "customerNo",
  "customerNVolume",
  "customerRVolume",
  "category",
  "salesRegion",
  "seller",
];
export const statInfoColumnOrder: Array<AllCustomerTableColumnKeys> = [
  "statNo",
  "statNVolume",
  "statRVolume",
];

export const sailingUnitSpecificColumnOrder: Array<AllCustomerTableColumnKeys> =
  ["sailingCat", "unitType", "statVolume", "customerVolume"];

export const currencyColumnOrder: Array<AllCustomerTableColumnKeys> = [
  "net",
  "gross",
];

export const noLabelGroupTertiaryOrder: Array<AllCustomerTableColumnKeys> = [
  "target",
  "fillLast",
];
