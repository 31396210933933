import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql, useQuery } from "@apollo/client";
import { FormulaTypeSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string | undefined) => void;
}

const query = gql`
  query FormulaTypeSelect {
    productPrice {
      formula {
        all {
          code
          description
          id
          name
          includes {
            drivers
            interval1PercentValue
            interval2PercentValue
            interval3PercentValue
            interval4PercentValue
            intervalAPercentValue
            intervalBPercentValue
            intervalCPercentValue
            intervalDPercentValue
            intervalLength
            maxPercent
            percent
            weight
            width
          }
        }
      }
    }
  }
`;

export const FormulaTypeSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<FormulaTypeSelectQuery>(query);

  const formulae = useMemo(() => {
    return data?.productPrice.formula.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    return [
      {
        label: "No formula",
        value: undefined,
      },
      ...formulae.map((f) => ({
        label: f.code + " - " + f.name,
        value: f.id,
        data: f,
      })),
    ];
  }, [formulae]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected}
      menuPlacement={"auto"}
      menuPortalTarget={document.body}
      onChange={(v: any) => onValueChange?.(v.value)}
      {...selectProps}
    />
  );
};
