import { AppThunk } from "../../../../config/redux/RootReducer";
import { gql } from "apollo-boost";
import { apolloClient } from "../../../../apollo-client/ApolloClient";
import { expectedVolumeActions } from "../redux/ExpectedVolumeRedux";
import { collectExpectedVolumeRows } from "../util/ExpectedVolumeDataCollector";
import { batch } from "react-redux";
import {
  ExpectedVolumeFetcherQuery,
  ExpectedVolumeFetcherQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment relatedCustomers on Customer {
    id
    custIndex
    custNo
    name
    isParent
  }
  fragment expectedVolume on ExpectedVolumeResult {
    volumes {
      id
      customer {
        id
      }
      expectedVolume
      routePair {
        id
      }
      sailingCategoryCode
      unitCategoryCode
      volume12Months
      year
    }
  }
  query ExpectedVolumeFetcher($customerId: ID!, $negotiationYear: Int!) {
    productPrice {
      routePair {
        allWithStatusOwn {
          id
          code
        }
      }
      customers {
        byId(id: $customerId) {
          id
          relatedCustomers {
            ...relatedCustomers
          }
          expectedVolumeByFilter(negotiationYear: $negotiationYear) {
            ...expectedVolume
          }
        }
      }
    }
  }
`;

export const fetchExpectedVolume =
  (customerId: string, negotiationYear: number): AppThunk =>
  async (dispatch) => {
    dispatch(
      expectedVolumeActions.setProgress({
        loading: true,
        bannerState: undefined,
      })
    );
    dispatch(expectedVolumeActions.clearAll());

    try {
      const { data, errors } = await apolloClient.query<
        ExpectedVolumeFetcherQuery,
        ExpectedVolumeFetcherQueryVariables
      >({
        variables: {
          customerId,
          negotiationYear,
        },
        fetchPolicy: "network-only",
        query,
      });

      if (errors && errors.length) {
        dispatch(
          expectedVolumeActions.setProgress({
            bannerState: {
              headerText: "Could not fetch expected volume",
              items: errors.map((e) => ({ text: e.message })),
            },
          })
        );
      } else {
        const customer = data.productPrice.customers.byId;
        const routePairs = data.productPrice.routePair.allWithStatusOwn;

        if (customer) {
          const { expectedVolumeByFilter, relatedCustomers } = customer;
          const collection = collectExpectedVolumeRows(
            routePairs,
            expectedVolumeByFilter,
            relatedCustomers
          );
          batch(() => {
            collection.forEach((c) => {
              dispatch(
                expectedVolumeActions.setRowsForRoutePair(
                  c.routePairId,
                  c.expectedVolumeTableRows
                )
              );
              dispatch(
                expectedVolumeActions.setRoutePairMetaFields(c.routePairId, {
                  hasVolume: c.routePairHasVolume,
                })
              );
            });
          });
        }
      }
    } catch (error) {
      dispatch(
        expectedVolumeActions.setProgress({
          bannerState: {
            headerText: "Could not fetch expected volume",
            items: [{ text: error.message }],
          },
        })
      );
    } finally {
      dispatch(expectedVolumeActions.setProgress({ loading: false }));
    }
  };
