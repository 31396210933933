import * as React from "react";
import { Box } from "@stenajs-webui/core";
import { IconSize } from "./IconSize";
import { Icon, stenaBan } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  size: IconSize;
}

export const BlockedIcon: React.FC<Props> = ({ size }) => {
  const sizeInPx = size === IconSize.Small ? 16 : 24;
  const innerIconSize = sizeInPx - 8;
  return (
    <Box
      background={cssColor("--lhds-color-red-50")}
      border={`1px solid ${cssColor("--lhds-color-red-500")}`}
      borderRadius={3}
      alignItems={"center"}
      justifyContent={"center"}
      width={sizeInPx + "px"}
      height={sizeInPx + "px"}
    >
      <Icon
        icon={stenaBan}
        color={cssColor("--lhds-color-red-500")}
        size={innerIconSize}
      />
    </Box>
  );
};
