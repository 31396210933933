import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { customerStatisticsFragment } from "../../../customer-statistics/fragment";
import {
  CustomerGetByFilterOptions,
  CustomerListQuery,
  CustomerListQueryVariables,
} from "@/gql/graphql";

const query = gql`
  fragment CustomerListNegotationStatusByYear on NegotiationStatus {
    id
    name
    presentationOrder
  }
  fragment CustomersForCustomerList on Customer {
    id
    custIndex
    custNo
    custStatus {
      id
      name
      code
    }
    name
    seller {
      id
      name
    }
    noOfChildren
    isParent
    parent {
      id
      custNo
    }
    preselectedHeadAgreement {
      id
    }
    negotiationStatusByYear(year: $negotiationYear) {
      ...CustomerListNegotationStatusByYear
    }
    statisticalGrouping {
      id
    }
    activeHeadAgreementByYear(year: $lastNegotiationYear) {
      id
    }
    statisticsByFilter(year: $negotiationYear) {
      items {
        ...CustomerStatistics
      }
    }
  }
  query CustomerList(
    $filter: CustomerGetByFilterOptions!
    $negotiationYear: Int!
    $lastNegotiationYear: Int!
  ) {
    productPrice {
      customers {
        byFilter(filter: $filter) {
          items {
            ...CustomersForCustomerList
          }
        }
      }
    }
  }
  ${customerStatisticsFragment}
`;

export const useCustomerListQuery = (
  filter: CustomerGetByFilterOptions,
  canSearch: boolean
) => {
  const { error, loading, data, refetch } = useQuery<
    CustomerListQuery,
    CustomerListQueryVariables
  >(query, {
    variables: {
      filter,
      negotiationYear: filter.negotiationYear,
      lastNegotiationYear: filter.negotiationYear - 1,
    },
    skip: !canSearch,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  return {
    error,
    loading,
    result: data?.productPrice.customers.byFilter,
    refetch,
  };
};
