import {
  SpecificationInformationQuery,
  SpecificationInformationQueryVariables,
} from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";

export const SpecificationInformationScreenQuery = gql`
  query SpecificationInformation($byIdId: ID!, $year: Int!, $month: Int!) {
    productPrice {
      customers {
        byId(id: $byIdId) {
          id
          creditLimit
          termsOfPaymentDays
          termsOfPaymentMonths
          customerThresholds(year: $year, month: $month) {
            id
            percentage
            thresholdTypeCode
            validFrom
          }
        }
      }
    }
  }
`;

export const useSpecificationInformation = (
  customerId: string,
  year: number,
  month: number
) => {
  const { data, loading, error } = useQuery<
    SpecificationInformationQuery,
    SpecificationInformationQueryVariables
  >(SpecificationInformationScreenQuery, {
    variables: {
      byIdId: customerId,
      year,
      month,
    },
  });

  const customerInfo = data?.productPrice.customers.byId;

  return {
    customerInfo,
    loading,
    error,
  };
};
