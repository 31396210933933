import { useCallback, useState } from "react";

export const useFadeState = <S>(
  initialValue: S,
  defaultTimeout: number
): [S, (v: S) => void] => {
  const [value, setValue] = useState<S>(initialValue);

  const wrappedSetter = useCallback(
    (newValue: S, timeout?: number) => {
      setValue(newValue);
      setTimeout(() => setValue(initialValue), timeout ?? defaultTimeout);
    },
    [initialValue, defaultTimeout]
  );

  return [value, wrappedSetter];
};
