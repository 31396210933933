import { IndentValues } from "@/common/utils/IndentValues";
import { Column, Indent, Text } from "@stenajs-webui/core";
import { Banner } from "@stenajs-webui/elements";
import { TableHeadItem, TableHeadRow } from "@stenajs-webui/grid";
import React, { Fragment } from "react";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { PriceCalculatorLegRow } from "./PriceCalculatorLegRow";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
}

export const PriceCalculatorLegsTable: React.FC<Props> = ({
  formData,
  onChangeFormData,
  setState,
}) => {
  if (!formData.multiLegRouteData) {
    return (
      <Indent num={4}>
        <Banner
          headerText={"Select a route"}
          text={"Legs will be shown here"}
          variant={"info"}
        />
      </Indent>
    );
  }
  return (
    <Column indent={IndentValues.TWENTYFOUR}>
      <TableHeadRow>
        <TableHeadItem justifyContent={"flex-start"} width={56}>
          <Text size={"small"} variant={"bold"}>
            #
          </Text>
        </TableHeadItem>
        <TableHeadItem width={64}>
          <Text size={"small"} variant={"bold"}>
            Leg
          </Text>
        </TableHeadItem>
        <TableHeadItem width={139}>
          <Text size={"small"} variant={"bold"}>
            Date
          </Text>
        </TableHeadItem>
        <TableHeadItem width={160}>
          <Text size={"small"} variant={"bold"}>
            Timetable
          </Text>
        </TableHeadItem>
        <TableHeadItem width={160}>
          <Text size={"small"} variant={"bold"}>
            Departure Category
          </Text>
        </TableHeadItem>
        <TableHeadItem width={140}>
          <Text size={"small"} variant={"bold"}>
            Departure Time
          </Text>
        </TableHeadItem>
      </TableHeadRow>
      {formData?.routeLegs?.map((routeLeg, index) => (
        <Fragment key={index}>
          <PriceCalculatorLegRow
            routeLeg={routeLeg}
            index={index}
            formData={formData}
            onChangeFormData={onChangeFormData}
            setState={setState}
          />
        </Fragment>
      ))}
    </Column>
  );
};
