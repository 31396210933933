import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box } from "@stenajs-webui/core";
import { Card, CardHeader } from "@stenajs-webui/elements";
import { LoadingScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { EditMultiLegRouteAgreementForm } from "../../../../../multileg-agreement/details/edit-multi-leg-route-agreement-form/EditMultiLegRouteAgreementForm";
import { EditRouteAgreementForm } from "../../../../common/components/edit-route-agreement-form/EditRouteAgreementForm";
import { useCanCustomerChangeRouteAgreementTypeQuery } from "../../../../common/hooks/UseCanCustomerChangeRouteAgreementTypeQuery";
import { RoutesFormModel } from "../RoutesFormModel";
import { routesFormRedux } from "../RoutesFormRedux";
import { clearAllPriceStatisticsForRouteAgreement } from "../thunks/ClearAllPriceStatisticsForRouteAgreement";
import { routeAgreementUpdateShouldTriggerRecalculation } from "../util/RouteAgreementRecalculationDecider";

interface Props {
  customerId: string;
  routeAgreementId: string;
  isMultiLegRouteAgreement: boolean;
  multiLegRoute?: string;
  routeLeg?: string;
}

export const RoutesPanel: React.FC<Props> = ({
  customerId,
  routeAgreementId,
  isMultiLegRouteAgreement,
  multiLegRoute,
  routeLeg,
}) => {
  const dispatch = useDispatch();

  const { loading, canChangeRouteAgreementType } =
    useCanCustomerChangeRouteAgreementTypeQuery(customerId);

  const routesFormModel = useSelector(routesFormRedux.selectors.getEntity);

  const setFields = useCallback(
    (fields: Partial<RoutesFormModel>) => {
      dispatch(routesFormRedux.actions.setEntityFields(fields));

      if (routeAgreementUpdateShouldTriggerRecalculation(fields)) {
        dispatch(clearAllPriceStatisticsForRouteAgreement(routeAgreementId));
      }
    },
    [dispatch, routeAgreementId]
  );

  return (
    <>
      <Helmet title="Routes - Route agreement - FPP" />
      <Card>
        <CardHeader text={"Routes"} />
        {loading ? (
          <LoadingScreen />
        ) : !isMultiLegRouteAgreement ? (
          <EditRouteAgreementForm
            value={routesFormModel}
            setFields={setFields}
            disableType={!canChangeRouteAgreementType}
          />
        ) : (
          <Box spacing={SpacingValues.TWENTYFOUR}>
            <EditMultiLegRouteAgreementForm
              value={routesFormModel}
              setFields={setFields}
              multiLegRoute={multiLegRoute ?? ""}
              routeLeg={routeLeg ?? ""}
              disableType={!canChangeRouteAgreementType}
            />
          </Box>
        )}
      </Card>
    </>
  );
};
