import * as React from "react";
import { PriceCalculatorHeader } from "./PriceCalculatorHeader";
import { PriceCalculatorFormFetcher } from "./PriceCalculatorFormFetcher";
import { Helmet } from "react-helmet";

interface Props {}

export const PriceCalculatorScreen: React.FC<Props> = () => {
  return (
    <>
      <Helmet title="Price Calculator - FPP" />
      <PriceCalculatorHeader />
      <PriceCalculatorFormFetcher />
    </>
  );
};
