import { ZIndex } from "@/common/utils/ZIndexEnum";
import { LoadingModal } from "@stenajs-webui/modal";
import React from "react";
import { stenaLockClosed } from "@stenajs-webui/elements";

interface Props {
  label: string;
}

export const LoadingModalWithLock: React.FC<Props> = ({ label }) => {
  return (
    <>
      <LoadingModal
        headerText={label}
        headerIconLeft={stenaLockClosed}
        style={{
          overlay: { zIndex: ZIndex.highest },
        }}
      />
    </>
  );
};
