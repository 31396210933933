export const formatShortCargoStatus = (cargoStatusCode: string): string => {
  switch (cargoStatusCode) {
    case "BOTH":
      return "B";
    case "EMPTY":
      return "E";
    case "LOADED":
      return "L";
    default:
      return "N/A";
  }
};
