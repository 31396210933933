import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateMultiLegRouteAgreementMutation,
  UpdateMultiLegRouteAgreementMutationVariables,
  UpdateRouteAgreementInput,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";
import {
  rateSheetTablePricesFragment,
  routeAgreementPageFragment,
} from "../fragments/RouteAgreementPageFragment";

const mutation = gql`
  ${errorFragment}
  ${routeAgreementPageFragment}
  ${rateSheetTablePricesFragment}
  mutation UpdateMultiLegRouteAgreement($input: UpdateRouteAgreementInput!) {
    productPrice {
      routeAgreement {
        updateRouteAgreementConnectedToMultiLeg(input: $input) {
          __typename
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateRouteAgreementSuccessResult {
            routeAgreement {
              ...RouteAgreementPageRouteAgreement
              valid {
                start {
                  isoString
                }
              }
              prices {
                ...RateSheetTablePrices
              }
            }
          }
        }
      }
    }
  }
`;

export const useUpdateMultiLegRouteAgreementMutation = () => {
  const [mutate, { loading, error }] = useMutation<
    UpdateMultiLegRouteAgreementMutation,
    UpdateMultiLegRouteAgreementMutationVariables
  >(mutation);
  const updateMultiLegRouteAgreement = useCallback(
    (input: UpdateRouteAgreementInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate]
  );

  return {
    updateMultiLegRouteAgreement,
    loading: loading,
    error: error,
  };
};
