import * as React from "react";

interface DotProps {
  color: string;
  size?: number;
}

export const Dot: React.FC<DotProps> = ({ color, size = 4 }) => (
  <div
    style={{
      borderRadius: "50%",
      width: size,
      height: size,
      backgroundColor: color,
    }}
  />
);
