import {
  BookingStatusOptions,
  SelectBookingStatusOption,
  selectBookingStatusOptions,
} from "@/common/collections/HandlingBookingStatuses";
import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Row, Space, useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  PrimaryButton,
  ResultListBanner,
  Spinner,
  stenaCheck,
} from "@stenajs-webui/elements";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import { Select } from "@stenajs-webui/select";
import { first } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModalWithLock } from "../../../../common/components/loading-modals/LoadingModalWithLock";
import { FreightPricingPortalError } from "../../../../common/error/models/FreightPricingPortalError";
import { StoreState } from "../../../../config/redux/RootReducer";
import {
  calculateThreshold,
  createInvoices,
  handleBookingStatus,
} from "../helpers/BulkUpdateBookingRowHelper";
import { useCalculateThresholdMutation } from "../hooks/UseCalculateThresholdMutation";
import { useCreateInvoiceMutation } from "../hooks/UseCreateInvoiceMutation";
import { clearSelectionState } from "../redux/reducers";
import { CreateNoteModal } from "./CreateNoteModal";
import { ExcludeReasonModalBulkUpdate } from "./ExcludeReasonModalBulkUpdate";
import { useHandleBookingStatus } from "./features/handle-booking-status/hooks/UseHandleBookingStatus";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";

interface Props {
  bookingsSelected: number;
  localErrors: FreightPricingPortalError[];
  setLocalErrors: React.Dispatch<
    React.SetStateAction<Array<FreightPricingPortalError>>
  >;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
}

export const bulkUpdateCategorySelected = {
  bookingStatus: "bookingStatus",
  threshold: "threshold",
  invoice: "invoice",
};

const selector = (state: StoreState) =>
  state.noShowLateHandlingBookingAndCheckboxSelection.checkBoxSelectionState;

export const BulkUpdateBookingRow: React.FC<Props> = ({
  bookingsSelected,
  localErrors,
  setLocalErrors,
  setSelectedOption,
  selectedOption,
}) => {
  const [modalErrors, setModalErrors] = useState<FreightPricingPortalError[]>(
    []
  );

  const dispatch = useDispatch();
  const [selectedBookingStatus, setSelectedBookingStatus] =
    useState<SelectBookingStatusOption>(selectBookingStatusOptions[0]);
  const deselectAllBookings = useCallback(() => {
    dispatch(clearSelectionState());
  }, [dispatch]);

  const { width } = useWindowSize();
  const selectedState = useSelector(selector);

  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;
  const [excludeReasonModalOpen, setExcludeReasonModalOpen] = useState(false);

  const [noteModalOpen, setNoteModalOpen, setNoteModalClosed] =
    useBoolean(false);
  const [noteValue, setNoteValue] = useState("");
  const [errorHeading, setErrorHeading] = useState("");

  const { setBookingStatus, bookingStatusLoading } = useHandleBookingStatus();

  const {
    loading: calculateThresholdLoading,
    calculateThreshold: calculateThresholdMutation,
  } = useCalculateThresholdMutation();

  const {
    loading: createInvoiceLoading,
    createInvoices: createInvoicesMutation,
  } = useCreateInvoiceMutation();

  const handleBookingOrCustomerUpdate = useCallback(async () => {
    switch (selectedOption) {
      case bulkUpdateCategorySelected.bookingStatus:
        if (selectedBookingStatus.value === BookingStatusOptions.DONOTINV) {
          setExcludeReasonModalOpen(true);
          setModalErrors([]);
          return;
        } else {
          return handleBookingStatus(
            selectedState,
            selectedBookingStatus,
            setBookingStatus,
            setLocalErrors,
            setModalErrors,
            setExcludeReasonModalOpen,
            deselectAllBookings
          );
        }
      case bulkUpdateCategorySelected.threshold:
        return calculateThreshold(
          selectedState,
          committedState,
          calculateThresholdMutation,
          setLocalErrors
        );
      case bulkUpdateCategorySelected.invoice:
        setLocalErrors([]);
        return setNoteModalOpen();
      default:
        return;
    }
  }, [
    selectedOption,
    selectedBookingStatus,
    selectedState,
    committedState,
    calculateThresholdMutation,
    setLocalErrors,
    setNoteModalOpen,
    setBookingStatus,
    deselectAllBookings,
  ]);

  const onExcludeReasonSet = async (excludeReason: string) => {
    await handleBookingStatus(
      selectedState,
      selectedBookingStatus,
      setBookingStatus,
      setLocalErrors,
      setModalErrors,
      setExcludeReasonModalOpen,
      deselectAllBookings,
      excludeReason
    );
  };

  const onExcludeReasonCancel = () => {
    setModalErrors([]);
    setExcludeReasonModalOpen(false);
  };

  const errorDisplayHeading = () => {
    switch (selectedOption) {
      case bulkUpdateCategorySelected.bookingStatus:
        return setErrorHeading("Unable to update booking statuses");
      case bulkUpdateCategorySelected.invoice:
        return setErrorHeading("Unable to create invoices");
      case bulkUpdateCategorySelected.threshold:
        return setErrorHeading("Unable to calculate thresholds");
    }
  };

  const renderContent = () => (
    <Row alignItems="center">
      {renderSetBookings()}
      <Space num={SpaceValues.TWENTYFOUR} />
      {renderCalculateBookings()}
      <Space num={SpaceValues.TWENTYFOUR} />
      {isLoading && (
        <LoadingModalWithLock label={"Thanks for your patience."} />
      )}

      <PrimaryButton
        left={
          isLoading && (
            <Spinner size={"tiny"} color={"var(--lhds-color-ui-500)"} />
          )
        }
        leftIcon={isLoading ? stenaCheck : undefined}
        label={isLoading ? "Applying" : "Apply"}
        onClick={() => {
          handleBookingOrCustomerUpdate();
          errorDisplayHeading();
        }}
        disabled={isLoading}
      />
    </Row>
  );

  const renderCalculateBookings = () => (
    <>
      <Box>
        <RadioButtonWithLabel
          label={"Calculate threshold"}
          value={bulkUpdateCategorySelected.threshold}
          checked={selectedOption === bulkUpdateCategorySelected.threshold}
          onValueChange={setSelectedOption}
          disabled={isLoading}
        />
      </Box>
      <Space num={SpaceValues.TWELVE} />
      <Box>
        <RadioButtonWithLabel
          label={"Create invoices"}
          value={bulkUpdateCategorySelected.invoice}
          checked={selectedOption === bulkUpdateCategorySelected.invoice}
          onValueChange={setSelectedOption}
          disabled={isLoading}
        />
      </Box>
    </>
  );

  const renderSetBookings = () => (
    <>
      <Box>
        <RadioButtonWithLabel
          label={"Set booking status to"}
          value={bulkUpdateCategorySelected.bookingStatus}
          checked={selectedOption === bulkUpdateCategorySelected.bookingStatus}
          onValueChange={(val) => {
            if (val) {
              setSelectedOption(val);
            }
          }}
        />
      </Box>
      <Space num={SpaceValues.TWELVE} />
      <Box width={width < 1280 ? 80 : 165}>
        <Select
          options={selectBookingStatusOptions}
          defaultValue={selectBookingStatusOptions[0]}
          value={selectedBookingStatus}
          isDisabled={
            selectedOption !== bulkUpdateCategorySelected.bookingStatus ||
            isLoading
          }
          menuPortalTarget={document.body}
          onChange={(val) => {
            if (val) {
              setSelectedBookingStatus(val);
            }
          }}
        />
      </Box>
    </>
  );

  const isLoading =
    bookingStatusLoading || createInvoiceLoading || calculateThresholdLoading;
  return (
    <>
      <SelectedItemsActionsPanel
        numItemsSelected={bookingsSelected}
        afterLabelContent={
          <FlatButton
            label="Clear all"
            onClick={() => {
              deselectAllBookings();
              setLocalErrors([]);
            }}
          />
        }
        rightContent={bookingsSelected > 0 && renderContent()}
      />
      {localErrors.length > 0 && (
        <>
          <ResultListBanner
            variant={"error"}
            bannerState={{
              headerText: errorHeading,
              text: first(localErrors)?.errors[0].message,
            }}
          />
        </>
      )}

      <ExcludeReasonModalBulkUpdate
        isModalOpen={excludeReasonModalOpen}
        onExcludeReasonSet={onExcludeReasonSet}
        onExcludeReasonCancel={onExcludeReasonCancel}
        onErrorClose={() => setModalErrors([])}
        modalErrors={modalErrors}
      />

      <CreateNoteModal
        isOpen={noteModalOpen}
        noteValue={noteValue}
        setNoteValue={setNoteValue}
        onSave={() => {
          setNoteModalClosed();
          createInvoices(
            selectedState,
            committedState,
            noteValue,
            createInvoicesMutation,
            setLocalErrors,
            deselectAllBookings
          );
          setNoteValue("");
        }}
        onRequestClose={() => {
          setNoteModalClosed();
          setNoteValue("");
        }}
      />
    </>
  );
};
