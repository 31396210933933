import { Box, Column, Row, SeparatorLine, Txt } from "@stenajs-webui/core";
import {
  TableCell,
  TableHeadItem,
  TableHeadRow,
  TableRow,
} from "@stenajs-webui/grid";
import {
  currencyValue,
  percentLabelFormatterDecimalValue,
} from "../utils/statisticsUtil";
import { LoadingScreen } from "@stenajs-webui/panels";
import { Banner } from "@stenajs-webui/elements";
import { UseSalesRegionDetailsVolumeAndRatesQuery } from "@/gql/graphql";
const borderLeft = "1px solid var(--lhds-color-ui-300)";

interface StatisticsPricePerMeterTableProps {
  item:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["threeYearsAverageMeterPrices"]
    | undefined;
  loading: boolean;
  year: number;
  currency: string;
}
export const StatisticAverageMeterPricesTable: React.FC<
  StatisticsPricePerMeterTableProps
> = ({ item, loading, year, currency }) => {
  return (
    <Column>
      {loading ? (
        <LoadingScreen />
      ) : !item ? (
        <Row justifyContent={"center"}>
          <Box width={"232.2px"}>
            <Banner variant="info" text="There is no data available." />
          </Box>
        </Row>
      ) : (
        <Column>
          <TableHeadRow>
            <TableHeadItem width={"calc(100%/3)"} />
            <TableHeadItem label={"Previous year"} width={"calc(100%/3)"} />
            <TableHeadItem label={"Avg. Price/m"} width={"calc(100%/3)"} />
          </TableHeadRow>
          <TableRow>
            <TableCell width={"100%"}>
              <Txt>{year}</Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {percentLabelFormatterDecimalValue(
                  item?.averageMeterPriceDiffInPercentFromPreviousYear
                )}
              </Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {currencyValue(item?.averageMeterPriceRequestedYear, currency)}
              </Txt>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={"100%"}>
              <Txt>{year - 1}</Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {percentLabelFormatterDecimalValue(
                  item?.averageMeterPriceDiffInPercentFromPreviousYearMinus1
                )}
              </Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {currencyValue(
                  item?.averageMeterPriceRequestedYearMinus1,
                  currency
                )}
              </Txt>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={"100%"}>
              <Txt>{year - 2}</Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {percentLabelFormatterDecimalValue(
                  item?.averageMeterPriceDiffInPercentFromPreviousYearMinus2
                )}
              </Txt>
            </TableCell>
            <TableCell width={"100%"} borderLeft={borderLeft}>
              <Txt>
                {currencyValue(
                  item?.averageMeterPriceRequestedYearMinus2,
                  currency
                )}
              </Txt>
            </TableCell>
          </TableRow>
          {item && <SeparatorLine />}
        </Column>
      )}
    </Column>
  );
};
