import { EditRateFormModel } from "../models/EditRateFormModel";
import { RateSheetRouteAgreementPrice } from "../../types";
import {
  transformAccUnaccEntityToUnaccBooleanOrNull,
  transformUnaccBooleanToAccUnaccEntity,
} from "../../../../../common/transformers/UnaccompaniedTransformer";
import {
  transformCargoStatusCodeToEntity,
  transformCargoStatusEntityToCargoStatusCode,
} from "../../../../../common/transformers/CargoStatusTransformer";
import { parseFloatElseThrow } from "@/common/numbers";
import { AllVehiclesFragment } from "@/gql/graphql";

export const transformPriceToFormModel = (
  price: RateSheetRouteAgreementPrice
): EditRateFormModel => ({
  lengthFrom: String(price.lengthFrom),
  lengthTo: String(price.lengthTo),
  weightFrom: String(price.weightFrom),
  weightTo: String(price.weightTo),
  vehicleTypeId: price.vehicleType?.id ?? null,
  ...transformCargoStatusCodeToEntity(price.cargoStatusCode),
  ...transformUnaccBooleanToAccUnaccEntity(price.unaccompanied),
});

export const transformFormModelToPrice = (
  price: EditRateFormModel,
  allVehicleTypes: Array<AllVehiclesFragment>
): Partial<RateSheetRouteAgreementPrice> => ({
  vehicleType: allVehicleTypes.find((v) => v.id === price.vehicleTypeId),
  lengthFrom: parseFloatElseThrow(price.lengthFrom),
  lengthTo: parseFloatElseThrow(price.lengthTo),
  weightFrom: parseFloatElseThrow(price.weightFrom),
  weightTo: parseFloatElseThrow(price.weightTo),
  unaccompanied: transformAccUnaccEntityToUnaccBooleanOrNull(price),
  cargoStatusCode: transformCargoStatusEntityToCargoStatusCode(price),
});
