import { Indent, Text } from "@stenajs-webui/core";

export const agreementDocumentsTableCell = (
  rowValue: string | number | undefined | null
) => {
  if (!rowValue) {
    return (
      <Indent>
        <Text>{""}</Text>
      </Indent>
    );
  }

  return (
    <Indent>
      <Text>{rowValue}</Text>
    </Indent>
  );
};
