import { css, Global } from "@emotion/react";
import { Row, Spacing } from "@stenajs-webui/core";
import { LoadingScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchInvoiceSpecification } from "./hooks/UseFetchInvoiceSpecifications";
import { transformByInvoiceNumbersToInvoiceSpecification } from "./transformers/TransformByInvoiceNumbersToInvoiceSpecification";
import { NslhRouteConstants } from "../utils/NslhRoutesConstants";
import { ViewSpecificationScreenHeader } from "@/common/components/invoice-specification/components/ViewSpecificationScreenHeader";
import { ViewSpecificationView } from "@/common/components/invoice-specification/components/ViewSpecificationView";
import { ErrorDisplay } from "@/common/error/components/ErrorDisplay";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import { Helmet } from "react-helmet";

export const ViewSpecificationScreen: React.FC = () => {
  const { invoiceNumber } = useParams() as { invoiceNumber: string };

  const { invoiceSpecifications, loading, error } =
    useFetchInvoiceSpecification(invoiceNumber);

  const [showExcluded, setShowExcluded] = useState(false);

  const onSetExcludedBookings = () => {
    setShowExcluded(!showExcluded);
  };

  if (loading) {
    return (
      <>
        <ViewSpecificationScreenHeader
          excludedBookings={showExcluded}
          setExcludedBookings={onSetExcludedBookings}
          pageHeading={"Invoice specification"}
          breadCrumbLabel={"Invoice specification"}
          previousRoute={NslhRouteConstants.invoiceSpecification}
        />
        <Spacing />
        <LoadingScreen />
      </>
    );
  }

  if (error) {
    const fppError = transformApolloErrorToFreightPricingPortalErrors(error);
    return (
      <>
        <ViewSpecificationScreenHeader
          excludedBookings={showExcluded}
          setExcludedBookings={onSetExcludedBookings}
          pageHeading={"Invoice specification"}
          breadCrumbLabel={"Invoice specification"}
          previousRoute={NslhRouteConstants.invoiceSpecification}
        />
        <Spacing />
        <Row justifyContent={"center"}>
          <ErrorDisplay
            error={fppError && fppError.length > 0 ? fppError[0] : error}
          />
        </Row>
      </>
    );
  }

  if (invoiceSpecifications === null) {
    return (
      <ViewSpecificationScreenHeader
        excludedBookings={showExcluded}
        setExcludedBookings={onSetExcludedBookings}
        pageHeading={"Invoice specification"}
        breadCrumbLabel={"Invoice specification"}
        previousRoute={NslhRouteConstants.invoiceSpecification}
      />
    );
  }

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="View specification - NSLH - FPP" />
      <ViewSpecificationScreenHeader
        excludedBookings={showExcluded}
        setExcludedBookings={onSetExcludedBookings}
        pageHeading={`Invoice specification (${invoiceNumber})`}
        breadCrumbLabel={"Invoice specification"}
        previousRoute={NslhRouteConstants.invoiceSpecification}
      />
      <ViewSpecificationView
        invoiceSpecificationData={invoiceSpecifications!.map(
          transformByInvoiceNumbersToInvoiceSpecification
        )}
        showExcludedBookings={showExcluded}
      />
    </>
  );
};
