import { format, isBefore, isValid, parse, startOfTomorrow } from "date-fns";
import { BulkUpdateAgreementArticlesFormModel } from "../models/BulkUpdateAgreementArticlesFormModel";

export const tomorrow = startOfTomorrow();
export const validMinDate = format(tomorrow, "yyyy-MM-dd");
export const isBeforeTomorrow = (validFromDate: string) =>
  isBefore(parse(validFromDate, "yyyy-MM-dd", tomorrow), tomorrow);

export const applyChangesDisabled = (
  formModel: BulkUpdateAgreementArticlesFormModel
) => {
  if (formModel.raiseByPercent === "" && formModel.raiseByAbsolute === "") {
    return true;
  }
  if (
    !formModel.validFromDate ||
    (formModel.validFromDate &&
      !isValid(new Date(formModel.validFromDate?.toString()))) ||
    (formModel.validFromDate &&
      isBeforeTomorrow(formModel.validFromDate.toString()))
  ) {
    return true;
  }

  return false;
};

export const validFromDateError = (validFromDate: string) => {
  if (
    validFromDate &&
    (!isValid(new Date(validFromDate)) || isBeforeTomorrow(validFromDate))
  ) {
    return true;
  }
  return false;
};
