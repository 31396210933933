import { gql } from "@apollo/client";

export const noShowLateHandlingRoutePricesQuery = gql`
  fragment allNoShowLateHandlingPrices on NoShowLateHandlingPrices {
    id
    routePairCode
    validFrom
    noShowDkk
    noShowEur
    noShowSek
    noShowNok
    noShowPln
    noShowGbp
    lateHandlingDkk
    lateHandlingEur
    lateHandlingSek
    lateHandlingNok
    lateHandlingPln
    lateHandlingGbp
    createdBy
    dateCreated
    routePair {
      description
    }
  }
  query NoShowLateHandlingRoutePrices {
    productPrice {
      routePair {
        allWithStatusOwn {
          id
          code
          description
        }
      }
      noShowLateHandlingPrices {
        all {
          ...allNoShowLateHandlingPrices
        }
      }
    }
  }
`;

export const noShowLateHandlingAllRoutePricesMutation = gql`
  mutation NoShowLateHandlingAllRoutePrices(
    $updateRoutePricesInput: [UpdateRoutePricesInput!]!
  ) {
    productPrice {
      noShowLateHandlingPrices {
        updateRoutePrices(input: $updateRoutePricesInput) {
          ... on UpdateRoutePricesSuccessResult {
            updatePrices {
              id
              routePairCode
              validFrom
            }
          }
          ... on UpdateRoutePricesErrorResult {
            errors {
              message
              path
            }
          }
        }
      }
    }
  }
`;
