import { AppThunk } from "../../../../config/redux/RootReducer";
import { rateSheetStandardTableWrapperActions } from "../../rate-sheet/redux";
import { rateSheetTableActions } from "../../rate-sheet/actions";
import { cleanRatePrice } from "../../rate-sheet/util/PriceCleaner";
import { batch } from "react-redux";
import { gql } from "apollo-boost";
import { customerRouteRouteAgreementFragment } from "../../route-agreement/details/fragments/RouteAgreementPageFragment";
import { apolloClient } from "../../../../apollo-client/ApolloClient";

import { routeAgreementStatusIsNotVoid } from "../util/RouteAgreementsFilter";
import { customerRouteRedux } from "../redux";
import { transformRouteAgreementToRoutesForm } from "../../route-agreement/details/features/routes/RoutesFormModel";
import {
  RoutePairRatesPopulatorQuery,
  RoutePairRatesPopulatorQueryVariables,
} from "@/gql/graphql";

const query = gql`
  ${customerRouteRouteAgreementFragment}
  query RoutePairRatesPopulator($headAgreementId: ID!, $routePairId: ID!) {
    productPrice {
      routeAgreement {
        byHeadAgreementAndRoutePair(
          headAgreementId: $headAgreementId
          routePairId: $routePairId
        ) {
          ...CustomerRouteRouteAgreement
        }
      }
    }
  }
`;

export const populateRoutePairRateTablesState =
  (routePairId: string, headAgreementId: string): AppThunk =>
  async (dispatch) => {
    const r = await apolloClient.query<
      RoutePairRatesPopulatorQuery,
      RoutePairRatesPopulatorQueryVariables
    >({
      query,
      variables: {
        routePairId,
        headAgreementId,
      },
      fetchPolicy: "network-only",
    });

    const routeAgreements =
      r.data.productPrice.routeAgreement.byHeadAgreementAndRoutePair.filter(
        routeAgreementStatusIsNotVoid
      );

    batch(() => {
      dispatch(customerRouteRedux.actions.clearAll());
      dispatch(rateSheetStandardTableWrapperActions.clearAll());

      dispatch(
        rateSheetStandardTableWrapperActions.setRouteAgreements(routeAgreements)
      );

      routeAgreements.forEach((routeAgreement) => {
        dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            routeAgreement.id,
            rateSheetTableActions.populateWithPersistedPrices(
              routeAgreement.prices.map(cleanRatePrice)
            )
          )
        );
        dispatch(
          customerRouteRedux.actions.setRouteAgreementModel(routeAgreement.id, {
            ...transformRouteAgreementToRoutesForm(routeAgreement),
            rowVersion: routeAgreement.rowVersion,
          })
        );
      });
    });
  };
