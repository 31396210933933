import {
  dotToCommaTransform,
  formatThousandsFromStringOrNumber,
  removeTrailingCommaOrDot,
} from "@/common/formatters/NumericTextFieldInputCleaner";
import { ScreenSizes } from "@/common/utils/ScreenSizesEnum";
import { Indent } from "@stenajs-webui/core";
import {
  StandardTableConfigWithGroups,
  createColumnConfig,
} from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { Dispatch } from "react";
import { formatUnacc } from "../../../../../common/formatters/AccUnaccFormatter";
import { formatShortCargoStatus } from "../../../../../common/formatters/CargoStatusFormatter";
import { formatSailingTypeCodesToString } from "../../../../../common/formatters/SailingStatusCodeFormatter";
import { BulkEditRatesActualChangeInPercent } from "../components/BulkEditRatesActualChangeInPercent";
import { bulkEditRatesStandardTableCell } from "../components/BulkEditRatesStandardTableCell";
import { createBulkEditRatesTableCell } from "../components/BulkEditRatesTableCell";
import { bulkEditRatesActions } from "../redux/reducer";
import { fetchBulkEditRatesActualChangeForSinglePriceRow } from "../thunks/FetchActualChangeForSinglePriceRow";
import { BulkEditRatePrice } from "../types";
import { vehicleLength, vehicleTypeCell, weightFromTo } from "../utils/helpers";
import { HeadAgreementStatusCode } from "@/gql/graphql";

const routeAgreementIsEditable = (
  item: BulkEditRatePrice,
  bulkUpdateModeEnabled?: boolean
) => {
  if (bulkUpdateModeEnabled) {
    return false;
  }
  if (
    item?.routeAgreement.statusCode === HeadAgreementStatusCode.Active ||
    item?.routeAgreement.statusCode === HeadAgreementStatusCode.Void
  ) {
    return false;
  }
  return true;
};

export type BulkEditRatesTableColumn =
  | keyof Omit<
      BulkEditRatePrice,
      | "id"
      | "lengthFrom"
      | "lengthTo"
      | "weightFrom"
      | "weightTo"
      | "routeAgreement"
      | "avgLength"
      | "avgRebate"
      | "__typename"
    >
  | "length"
  | "weight"
  | "type"
  | "direction";

interface TableConfigVariables {
  open: () => void;
  isOpen: boolean;
  selectedAgreement: BulkEditRatePrice | undefined;
  setRoutePriceId: (routePriceId: string) => void;
  dispatch: Dispatch<any>;
  bulkUpdateModeEnabled: boolean;
  windowSize: number;
  promptVisible: boolean;
}

export const createBulkEditRatesTableConfig = ({
  open,
  isOpen,
  selectedAgreement,
  setRoutePriceId,
  dispatch,
  bulkUpdateModeEnabled,
  windowSize,
  promptVisible,
}: TableConfigVariables): StandardTableConfigWithGroups<
  BulkEditRatePrice,
  BulkEditRatesTableColumn
> => {
  return {
    headerRowOffsetTop:
      promptVisible && bulkUpdateModeEnabled
        ? "242px"
        : bulkUpdateModeEnabled
        ? "154px"
        : "98px",
    stickyHeader: true,
    enableGridCell: true,
    showHeaderCheckbox: true,
    showRowCheckbox: true,
    stickyCheckboxColumn:
      windowSize <= ScreenSizes.tabletAndDesktop ? true : false,
    stickyColumnGroups:
      windowSize <= ScreenSizes.tabletAndDesktop ? "first" : undefined,
    checkboxDisabledResolver: (item) => !routeAgreementIsEditable(item),
    keyResolver: (item) => item.id,
    rowBackgroundResolver: (item) =>
      isOpen && selectedAgreement?.id === item.id
        ? cssColor("--lhds-color-blue-50")
        : undefined,

    columns: {
      vehicleType: {
        itemValueResolver: (item) => item.vehicleType?.code,
        columnLabel: "Vehicle type",
        disableGridCell: true,
        width: "107px",
        renderCell: ({ label, item }) =>
          vehicleTypeCell(item, setRoutePriceId, open),
      },
      type: {
        itemValueResolver: (item) =>
          formatSailingTypeCodesToString(item.routeAgreement.sailingTypeCodes),
        itemLabelFormatter: (value, item) =>
          formatSailingTypeCodesToString(item.routeAgreement.sailingTypeCodes),
        columnLabel: "Type",
        width: "65px",
        disableGridCell: true,
      },
      direction: {
        itemValueResolver: (item) => item.routeAgreement.routeDescription,
        columnLabel: "Direction",
        disableGridCell: true,
        width: "115px",
        renderCell: ({ label, item }) =>
          bulkEditRatesStandardTableCell(item.routeAgreement.routeDescription),
      },
      length: createColumnConfig((item) => item, {
        renderCell: ({ label, value, item }) => vehicleLength(item),
        disableGridCell: true,
        columnLabel: "Length (m)",
        minWidth: "99px",
      }),
      weight: createColumnConfig((item) => item, {
        renderCell: ({ label, value, item }) => weightFromTo(item),
        disableGridCell: true,
        columnLabel: "Weight (kg)",
        minWidth: "103px",
      }),
      cargoStatusCode: createColumnConfig((item) => item.cargoStatusCode, {
        columnLabel: "ELB",
        disableGridCell: true,
        minWidth: "56px",
        renderCell: ({ label, value, item }) =>
          bulkEditRatesStandardTableCell(
            formatShortCargoStatus(item.cargoStatusCode)
          ),
      }),
      unaccompanied: createColumnConfig((item) => item.unaccompanied, {
        columnLabel: "Unacc",
        minWidth: "56px",
        disableGridCell: true,
        renderCell: ({ label, value, item }) =>
          bulkEditRatesStandardTableCell(formatUnacc(item.unaccompanied)),
      }),
      volume: createColumnConfig((item) => item.volume, {
        disableGridCell: true,
        columnLabel: "Volume",
        minWidth: "74px",
        renderCell: ({ label, value, item }) =>
          bulkEditRatesStandardTableCell(
            formatThousandsFromStringOrNumber(item.volume)
          ),
      }),
      avgGrossPrice: createColumnConfig((item) => item?.avgGrossPrice?.amount, {
        disableGridCell: true,
        minWidth: "97px",
        columnLabel: "Avg. gross",
        renderCell: ({ label, value, item }) =>
          bulkEditRatesStandardTableCell(
            dotToCommaTransform(item?.avgGrossPrice?.amount ?? "")
          ),
      }),
      lastPrice: createColumnConfig((item) => item.lastPrice?.amount, {
        disableGridCell: true,
        columnLabel: "Price",
        minWidth: "70px",
        renderCell: ({ label, value, item }) =>
          bulkEditRatesStandardTableCell(
            dotToCommaTransform(item.lastPrice?.amount ?? "")
          ),
      }),
      lastMeterPrice: createColumnConfig(
        (item) =>
          dotToCommaTransform(
            formatThousandsFromStringOrNumber(item.lastMeterPrice?.amount)
          ),
        {
          disableGridCell: true,
          minWidth: "84px",
          columnLabel: "M. price",
        }
      ),
      lastChargedFrom: createColumnConfig(
        (item) =>
          dotToCommaTransform(
            formatThousandsFromStringOrNumber(item.lastChargedFrom)
          ),
        {
          disableGridCell: true,
          columnLabel: "Ch. from",
          minWidth: "87px",
        }
      ),
      newPrice: createColumnConfig((item) => item.newPrice.amount, {
        columnLabel: "New price",
        borderLeft: true,
        disableGridCell: bulkUpdateModeEnabled,
        isEditable: (item) =>
          routeAgreementIsEditable(item, bulkUpdateModeEnabled),
        width: "150px",
        renderCell: ({ label, value, item, gridCell }) =>
          createBulkEditRatesTableCell(
            item.id,
            gridCell,
            routeAgreementIsEditable(item, bulkUpdateModeEnabled),
            "newPrice"
          ),
        gridCellOptions: {
          allowedInputType: "numeric",
        },
        onChange: (item, value) => {
          dispatch(
            bulkEditRatesActions.setValue({
              id: item.id,
              newPrice: value != null ? removeTrailingCommaOrDot(value) : value,
            })
          );
          dispatch(
            fetchBulkEditRatesActualChangeForSinglePriceRow(
              item.routeAgreement.id,
              item.id
            )
          );
        },
      }),
      meterPrice: createColumnConfig((item) => item.meterPrice.amount, {
        renderCell: ({ label, value, item, gridCell, isEditable }) =>
          createBulkEditRatesTableCell(
            item.id,
            gridCell,
            routeAgreementIsEditable(item, bulkUpdateModeEnabled),
            "meterPrice"
          ),
        gridCellOptions: {
          allowedInputType: "numeric",
        },
        onChange: (item, value) => {
          dispatch(
            bulkEditRatesActions.setValue({
              id: item.id,
              meterPrice:
                value != null ? removeTrailingCommaOrDot(value) : value,
            })
          );
          dispatch(
            fetchBulkEditRatesActualChangeForSinglePriceRow(
              item.routeAgreement.id,
              item.id
            )
          );
        },
        width: "150px",
        columnLabel: "M. price",
        disableGridCell: bulkUpdateModeEnabled,
        isEditable: (item) =>
          routeAgreementIsEditable(item, bulkUpdateModeEnabled),
      }),
      additionalFrom: createColumnConfig((item) => item.additionalFrom, {
        renderCell: ({ label, value, item, gridCell }) =>
          createBulkEditRatesTableCell(
            item.id,
            gridCell,
            routeAgreementIsEditable(item, bulkUpdateModeEnabled),
            "additionalFrom"
          ),
        gridCellOptions: {
          allowedInputType: "numeric",
        },
        onChange: (item, value) => {
          dispatch(
            bulkEditRatesActions.setValue({
              id: item.id,
              additionalFrom:
                value != null ? removeTrailingCommaOrDot(value) : value,
            })
          );
          dispatch(
            fetchBulkEditRatesActualChangeForSinglePriceRow(
              item.routeAgreement.id,
              item.id
            )
          );
        },
        disableGridCell: bulkUpdateModeEnabled,
        isEditable: (item) =>
          routeAgreementIsEditable(item, bulkUpdateModeEnabled),
        width: "150px",
        columnLabel: "Add. from",
      }),
      actual: createColumnConfig((item) => item.actual ?? 0, {
        borderLeft: true,
        disableGridCell: true,
        minWidth: "110px",
        columnLabel: "Actual (%)",
        renderCell: ({ label, value, item }) => (
          <Indent>
            <BulkEditRatesActualChangeInPercent entityId={item.id} />
          </Indent>
        ),
      }),
    },
    columnGroups: {
      firstNoLabelColumnGroup: {
        columnOrder: ["vehicleType", "type", "direction"],
      },
      secondNoLabelColumnGroup: {
        columnOrder: ["length", "weight", "cargoStatusCode", "unaccompanied"],
      },
      thirdNoLabelColumnGroup: {
        columnOrder: [
          "volume",
          "avgGrossPrice",
          "lastPrice",
          "lastMeterPrice",
          "lastChargedFrom",
        ],
        borderLeft: true,
      },
      fourthNoLabelColumnGroup: {
        columnOrder: ["newPrice", "meterPrice", "additionalFrom"],
        borderLeft: true,
      },
      fifthNoLabelColumnGroup: {
        columnOrder: ["actual"],
        borderLeft: true,
      },
    },
    columnGroupOrder: [
      "firstNoLabelColumnGroup",
      "secondNoLabelColumnGroup",
      "thirdNoLabelColumnGroup",
      "fourthNoLabelColumnGroup",
      "fifthNoLabelColumnGroup",
    ],
  };
};
