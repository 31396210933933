import * as React from "react";
import { Switch, SwitchProps } from "@stenajs-webui/forms";
import { RouteAgreement } from "../../../../features/product-and-price/customers/customer-details/types";
import { routeAgreementCanBeEditedByStatusCode } from "../../../../features/product-and-price/route-agreement/util/RouteAgreementCalculator";

interface Props extends SwitchProps {
  routeAgreement: Pick<RouteAgreement, "statusCode">;
}

export const SwitchForRouteAgreementSalesReady: React.FC<Props> = ({
  routeAgreement,
  disabled,
  ...switchProps
}) => {
  const disabledByRa = !routeAgreementCanBeEditedByStatusCode(
    routeAgreement.statusCode
  );

  return <Switch disabled={disabledByRa || disabled} {...switchProps} />;
};
