import * as React from "react";
import {
  createColumnConfig,
  StandardTable,
  StandardTableConfig,
  TableContext,
} from "@stenajs-webui/grid";
import { SelectedRouteAgreement } from "../types";
import { HeadAgreementStatusCodeChip } from "../../../../../common/components/chips/HeadAgreementStatusCodeChip";
import { formatSailingTypeCodesToString } from "../../../../../common/formatters/SailingStatusCodeFormatter";
import { Indent } from "@stenajs-webui/core";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { CopyAgreementRouteAgreementsFragment } from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  tableContext: TableContext<SelectedRouteAgreementColumns>;
  routeAgreements: CopyAgreementRouteAgreementsFragment[];
}

export type SelectedRouteAgreementColumns = keyof Omit<
  SelectedRouteAgreement,
  "id" | "multiLegAgrRouteId" | "__typename"
>;

const config: StandardTableConfig<
  SelectedRouteAgreement,
  SelectedRouteAgreementColumns
> = {
  keyResolver: (item) => item.id,
  showRowCheckbox: true,
  showHeaderCheckbox: true,
  disableSorting: true,
  columns: {
    statusCode: createColumnConfig((item) => item.statusCode, {
      columnLabel: "Status",
      renderCell: ({ label, item }) => (
        <Indent>
          <HeadAgreementStatusCodeChip statusCode={item.statusCode} />
        </Indent>
      ),
      minWidth: "90px",
    }),
    agreementNumber: createColumnConfig((item) => item.agreementNumber, {
      columnLabel: "Number",
      minWidth: "70px",
    }),
    routeDescription: createColumnConfig((item) => item.routeDescription, {
      columnLabel: "Route",
      minWidth: "100px",
    }),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
      minWidth: "250px",
    }),
    externalDescription: createColumnConfig(
      (item) => item.externalDescription,
      {
        columnLabel: "Ext. desc.",
        minWidth: "250px",
      }
    ),
    sailingTypeCodes: createColumnConfig((item) => item.sailingTypeCodes, {
      itemLabelFormatter: (value) => formatSailingTypeCodesToString(value),
      columnLabel: "Type",
      minWidth: "60px",
    }),
    valid: createColumnConfig((item) => formatGQLDateRange(item.valid), {
      columnLabel: "Validity",
      minWidth: "171px",
    }),
    currency: createColumnConfig((item) => item.currency.id, {
      minWidth: "73px",
    }),
    hasMatrix: createColumnConfig((item) => item.hasMatrix, {
      columnLabel: "Matrix",
      minWidth: "51px",
      itemLabelFormatter: (value) => (value ? "Y" : ""),
    }),
  },
  columnOrder: [
    "statusCode",
    "agreementNumber",
    "routeDescription",
    "name",
    "externalDescription",
    "sailingTypeCodes",
    "valid",
    "currency",
    "hasMatrix",
  ],
};

export const SelectRouteAgreements: React.FC<Props> = ({
  tableContext,
  routeAgreements,
}) => {
  return (
    <StandardTable
      config={config}
      items={routeAgreements}
      tableContext={tableContext}
      noItemsLabel={
        "There are no route agreements under this header agreement."
      }
    />
  );
};
