import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { createNslhInternalOverviewStateSelectors } from "../redux/selectors";

import { transformBookingsQueryToOverviewBooking } from "../transformers/TransformBookingsQueryToOverviewBooking";
import { setNslhOverviewData } from "../redux/reducers";
import { useState } from "react";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { transformApolloErrorToFreightPricingPortalErrors } from "@/common/error/transformers/TransformApolloErrorToFreightPricingPortalErrors";
import { OverviewBooking } from "@/common/components/overview/models/OverviewBooking";
import {
  InternalOverviewQuery,
  InternalOverviewQueryVariables,
} from "@/gql/graphql";

const queryInternalOverview = gql`
  query InternalOverview($custNo: Int!, $year: Int!, $month: Int!) {
    productPrice {
      noShowLateHandling {
        nslhFollowUp {
          byFilter(input: { custNo: $custNo, month: $month, year: $year }) {
            id
            customer {
              id
              custNo
              custIndex
              customerThresholds(year: $year, month: $month) {
                thresholdTypeCode
                percentage
              }
            }
            routePair {
              id
              code
              description
            }
            shippedVolume
            noOfNoShows
            noOfExcludedNoShows
            noOfLateHandlings
            noOfExcludedLateHandlings
            nslhBookingVolume(year: $year, month: $month) {
              id
              thresholdNoShowPercentage
              thresholdLateHandleBookings
              thresholdLateHandlePercentage
              thresholdNoShowBookings
            }
          }
        }
      }
    }
  }
`;

export const useFetchInternalOverviewBookings = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<FreightPricingPortalError | undefined>(
    undefined
  );
  const dateInput = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewDateRangeInput
  );

  const customer = useSelector(
    createNslhInternalOverviewStateSelectors.getNslhOverviewCustomerSelectedData
  );
  const [fetchOverviewData, { loading }] = useLazyQuery<
    InternalOverviewQuery,
    InternalOverviewQueryVariables
  >(queryInternalOverview, {
    fetchPolicy: "cache-and-network",
    variables: {
      custNo: customer?.custNo ?? 0,
      year: dateInput.year,
      month: dateInput.month,
    },
    onError: (err) => {
      if (err) {
        const fppError = transformApolloErrorToFreightPricingPortalErrors(err);
        if (fppError.length > 0) {
          setError(transformApolloErrorToFreightPricingPortalErrors(err)[0]);
        }
        return;
      }
    },
    onCompleted: (data) => {
      const overviewBookings: OverviewBooking[] =
        data.productPrice.noShowLateHandling.nslhFollowUp.byFilter.map(
          (booking) =>
            transformBookingsQueryToOverviewBooking(
              booking,
              dateInput.year,
              dateInput.month
            )
        );

      dispatch(setNslhOverviewData(overviewBookings));
    },
  });

  return {
    fetchOverviewData,
    error,
    setError,
    loading,
  };
};
