import { useDispatch, useSelector } from "react-redux";
import { Column } from "@stenajs-webui/core";
import { getNotificationsList, removeNotificationById } from "./redux/slice";
import { NotificationItem } from "../..//features/notification-center/NotificationItem";
import { ZIndex } from "@/common/utils/ZIndexEnum";

export const NotificationCenter = () => {
  const notifications = useSelector(getNotificationsList);
  const dispatch = useDispatch();

  const onCloseClicked = (removeId: string) => {
    dispatch(removeNotificationById({ id: removeId }));
  };

  return (
    <Column
      className={"notification-center"}
      zIndex={ZIndex.highest}
      position={"absolute"}
      right={"16px"}
      top={"48px"}
    >
      {notifications.map((n) => (
        <NotificationItem
          key={n.id}
          subscriptionPayload={n}
          onClose={onCloseClicked}
        />
      ))}
    </Column>
  );
};
