import * as React from "react";
import { Indent, Row } from "@stenajs-webui/core";
import { PriceCalculatorFormData } from "../../../PriceCalculatorFormFetcher";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import FormGroup from "../../../../../common/forms/FormGroup";
import { NumericTextInputNoScroll } from "../../../../../common/components/forms/NumericTextInputNoScroll";
import { InputFieldSetWidthLabeled } from "../../PriceCalculatorFormPanel";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorDriversPassengersDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <FormGroup title={"Drivers and passengers"}>
      <Row>
        <InputFieldSetWidthLabeled width={80} label={"Adults (16+)"}>
          <NumericTextInputNoScroll
            value={formData.passengersAdults?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersAdults", parseInt(value))
            }
            min={0}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />
        <InputFieldSetWidthLabeled width={80} label={"Children (4-15)"}>
          <NumericTextInputNoScroll
            value={formData.passengersChildren?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersChildren", parseInt(value))
            }
            min={0}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />
        <InputFieldSetWidthLabeled width={80} label={"Infants (0-3)"}>
          <NumericTextInputNoScroll
            value={formData.passengersInfants?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersInfants", parseInt(value))
            }
            min={0}
          />
        </InputFieldSetWidthLabeled>

        <Indent num={1.5} />
        <InputFieldSetWidthLabeled width={80} label={"No of sleepers"}>
          <NumericTextInputNoScroll
            value={formData.passengersSleepers?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersSleepers", parseInt(value))
            }
            min={0}
          />
        </InputFieldSetWidthLabeled>
      </Row>
    </FormGroup>
  );
};
