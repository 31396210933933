import {
  ByRouteRowItem,
  NumberVolumeStatItem,
} from "../../statistics-by-route-pair/list/types";
import { StatisticsResultListItem } from "../config/list/ResultTableConfig";
import { NegotiationStatusListItem } from "../config/details/StatisticsByNegotiationStatusTablesConfig";
import { ByCustomerOrVolume } from "../utils/ByCustomerOrVolumeEnum";
import { NegotiationStatusCode } from "@/gql/graphql";

const getStat = (
  row: Pick<ByRouteRowItem, "negotiationStatusStatistics">,
  negotiationStatus: NegotiationStatusCode
): NumberVolumeStatItem => {
  const item = row.negotiationStatusStatistics.statistics.find(
    (t) => t.negotiationStatus.id === negotiationStatus
  );

  return (
    item?.numberVolumeStatistics ?? {
      percentageOfAccount: 0,
      percentageOfVolume: 0,
    }
  );
};

export const transformResultCommonFieldsToTableRow = (
  row: Pick<ByRouteRowItem, "negotiationStatusStatistics">,
  checkedRadioButton: ByCustomerOrVolume
): Omit<StatisticsResultListItem, "ownerId" | "ownerName"> => {
  return {
    accounts: row.negotiationStatusStatistics.accounts,
    todo:
      checkedRadioButton === ByCustomerOrVolume.CUSTOMER
        ? getStat(row, NegotiationStatusCode.T)?.percentageOfAccount
        : getStat(row, NegotiationStatusCode.T)?.percentageOfVolume,
    done:
      checkedRadioButton === ByCustomerOrVolume.CUSTOMER
        ? getStat(row, NegotiationStatusCode.D)?.percentageOfAccount +
          getStat(row, NegotiationStatusCode.R)?.percentageOfAccount
        : getStat(row, NegotiationStatusCode.D)?.percentageOfVolume +
          getStat(row, NegotiationStatusCode.R)?.percentageOfVolume,
    started:
      checkedRadioButton === ByCustomerOrVolume.CUSTOMER
        ? getStat(row, NegotiationStatusCode.I)?.percentageOfAccount +
          getStat(row, NegotiationStatusCode.P)?.percentageOfAccount +
          getStat(row, NegotiationStatusCode.Ra)?.percentageOfAccount +
          getStat(row, NegotiationStatusCode.Ro)?.percentageOfAccount
        : getStat(row, NegotiationStatusCode.I)?.percentageOfVolume +
          getStat(row, NegotiationStatusCode.P)?.percentageOfVolume +
          getStat(row, NegotiationStatusCode.Ra)?.percentageOfVolume +
          getStat(row, NegotiationStatusCode.Ro)?.percentageOfVolume,
  };
};

export const transformNegotiationStatusesToTableRow = (
  row: Pick<ByRouteRowItem, "negotiationStatusStatistics">,
  byCustomer?: boolean
): Omit<NegotiationStatusListItem, "id"> => {
  return {
    todo: byCustomer
      ? getStat(row, NegotiationStatusCode.T)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.T)?.percentageOfVolume,
    done: byCustomer
      ? getStat(row, NegotiationStatusCode.D)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.D)?.percentageOfVolume,
    inProgress: byCustomer
      ? getStat(row, NegotiationStatusCode.I)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.I)?.percentageOfVolume,
    parked: byCustomer
      ? getStat(row, NegotiationStatusCode.P)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.P)?.percentageOfVolume,
    readyToActivate: byCustomer
      ? getStat(row, NegotiationStatusCode.Ra)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.Ra)?.percentageOfVolume,
    readyToOffer: byCustomer
      ? getStat(row, NegotiationStatusCode.Ro)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.Ro)?.percentageOfVolume,
    renegotiation: byCustomer
      ? getStat(row, NegotiationStatusCode.R)?.percentageOfAccount
      : getStat(row, NegotiationStatusCode.R)?.percentageOfVolume,
  };
};
