import { StoreState } from "@/config/redux/RootReducer";
import { StandardTableState } from "@stenajs-webui/grid";
import { CustomerListTableColumnKeys } from "./config/CustomerListTableConfig";
import { customerListStandardTableInitialState } from "./redux";

export const customerListSelectors = {
  getTableState: (
    state: StoreState
  ): StandardTableState<CustomerListTableColumnKeys> =>
    state.customerList.standardTable ?? customerListStandardTableInitialState,
};
