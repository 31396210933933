import { formatDateString } from "@/common/dates/formatters";
import { validateCreateMultiLegAgreementRouteAgreementForm } from "../../../../../components/util/ValidateCreateMultiLegAgreementRouteAgreementForm";
import { CreateMultiLegAgreementRouteFormModel } from "../../../create-multi-leg-agreement-route-form/model/CreateMultiLegAgreementRouteFormModel";
import { RouteAgreementTypeCode } from "@/gql/graphql";

export interface CreateMultiLegAgreementRouteInput {
  headAgreementId: string;
  routeIds: Array<string>;
  name: string;
  type: RouteAgreementTypeCode;
  externalDescription: string;
  currencyCode: string;
  routeCode: string;
  validFrom: string;
  validTo: string;
  peak: boolean;
  offPeak: boolean;
  shoulder: boolean;
  special: boolean;
  multiLegAgrRouteId: string;
}

export const createMultiLegAgreementRouteMutationInput = (
  formModel: CreateMultiLegAgreementRouteFormModel
): CreateMultiLegAgreementRouteInput => {
  validateCreateMultiLegAgreementRouteAgreementForm(formModel);
  return {
    headAgreementId: formModel.headAgreementId,
    externalDescription: formModel.externalDescription,
    multiLegAgrRouteId: formModel.multiLegAgreementId,
    offPeak: formModel.offPeak,
    peak: formModel.peak,
    shoulder: formModel.shoulder,
    special: formModel.special,
    routeIds: [formModel.selectedRouteCode],
    type: formModel.type,
    currencyCode: formModel.currencyCode,
    name: formModel.name,
    routeCode: formModel.routeCode,
    validFrom: formatDateString(formModel.valid.startDate!),
    validTo: formatDateString(formModel.valid.endDate!),
  };
};
