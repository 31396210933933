import { DateStringRange } from "@stenajs-webui/calendar";
import { uuid } from "../../../../../../common/uuid/UuidFactory";
import { RouteAgreementForForm } from "../../types";
import { RouteAgreementMatricesFragment } from "@/gql/graphql";

export interface MatrixFormModel {
  matrices: Array<MatrixFormItem>;
}

export interface MatrixFormItem {
  id: string;
  persisted: boolean;
  deleted: boolean;
  startDate?: string;
  endDate?: string;
  matrixId: string;
}

export const createEmptyMatrixItem = (
  id: string,
  matrixId: string,
  valid?: DateStringRange
): MatrixFormItem => ({
  ...createEmptyMatrixFormItem(),
  id,
  matrixId,
  startDate: valid?.startDate,
  endDate: valid?.endDate,
});

export const emptyMatrixFormModel: MatrixFormModel = {
  matrices: [],
};

export const createEmptyMatrixFormItem = (): MatrixFormItem => ({
  id: uuid(),
  persisted: false,
  deleted: false,
  matrixId: "",
});

export const transformRouteAgreementToMatrixForm = (
  routeAgreement: RouteAgreementForForm
): MatrixFormModel => ({
  matrices: routeAgreement.matrices.map(transformMatrixItem),
});

const transformMatrixItem = (
  matrixItem: RouteAgreementMatricesFragment
): MatrixFormItem => ({
  matrixId: matrixItem.matrix.id,
  persisted: true,
  deleted: false,
  id: matrixItem.id,
  startDate: matrixItem.valid.start?.isoString ?? "",
  endDate: matrixItem.valid.end?.isoString ?? "",
});
