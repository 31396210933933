import { ErrorScreen } from "@stenajs-webui/panels";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CustomerRouteFetcher } from "./CustomerRouteFetcher";

export interface CustomerRouteScreenParams {
  customerId: string;
  headAgreementId: string;
  routePairId: string;
}

interface Props {}

export const CustomerRoutePairScreen: React.FC<Props> = () => {
  const { headAgreementId, customerId, routePairId } = useParams() as {
    headAgreementId: string;
    customerId: string;
    routePairId: string;
  };
  if (!customerId) {
    return <ErrorScreen text={"Missing custIndex."} />;
  }
  if (!routePairId) {
    return <ErrorScreen text={"Missing routeCode."} />;
  }
  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }

  return (
    <>
      <Helmet title="Customer@Route - Customer - FPP" />
      <CustomerRouteFetcher
        routePairId={routePairId}
        customerId={customerId}
        headAgreementId={headAgreementId}
      />
    </>
  );
};
