import { Indent, Text } from "@stenajs-webui/core";
import {
  createColumnConfig,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import { EditableEntityState } from "@stenajs-webui/redux";
import { sumBy } from "lodash";
import * as React from "react";
import { FC } from "react";
import { Link } from "../../../../common/navigation/Link";
import { limitStringLength } from "../../../../common/string/StringLimiteter";
import { routeFactory } from "../../../../RouteFactory";
import { useHeadAgreementsByCustomer } from "../../head-agreement/list/hooks/UseHeadAgreementsSwitcher";
import { createEditableCellRenderer } from "../components/EditableTableCell";
import { ExpectedVolumeTableRow } from "../types";
import {
  accompaniedColumnOrder,
  accompaniedExpectedColumnOrder,
  noLabelColumnOrder,
  unaccompaniedColumnOrder,
  unaccompaniedExpectedColumnOrder,
} from "./ExpectedVolumeColumnOrder";

export type ExpectedVolumeColumns =
  | keyof Omit<ExpectedVolumeTableRow, "id">
  | "indentLeft"
  | "indentRight";

const RoutePairTableCustomerLink: FC<{
  label: string;
  customer: EditableEntityState<ExpectedVolumeTableRow>;
}> = ({ label, customer }) => {
  const { defaultHeadAgreement } = useHeadAgreementsByCustomer(
    customer?.id ?? ""
  );
  return (
    <Link
      title={label}
      to={routeFactory.productAndPrice.customer.customerHeadAgreement({
        customerId: customer?.id ?? "",
        headAgreementId: defaultHeadAgreement?.id ?? "",
      })}
    >
      {limitStringLength(label, 40)}
    </Link>
  );
};

export const createExpectedVolumeTableConfig = (
  routePairId: string,
  yearIsEditable: boolean
): StandardTableConfigWithGroups<
  EditableEntityState<ExpectedVolumeTableRow>,
  ExpectedVolumeColumns
> => ({
  keyResolver: (item) => item.editable.id,
  disableSorting: true,
  disableInfiniteList: true,
  enableGridCell: true,
  columns: {
    indentLeft: createColumnConfig((item) => "", {
      columnLabel: "",
      width: "16px",
      disableGridCell: true,
    }),
    customerName: createColumnConfig((item) => item.editable.customerName, {
      columnLabel: "Customer",
      disableGridCell: true,
      width: "270px",
      renderCell: ({ label, value, item }) => (
        <Indent overflow={"hidden"}>
          <Text whiteSpace={"nowrap"}>
            <RoutePairTableCustomerLink label={label} customer={item} />
          </Text>
        </Indent>
      ),
    }),
    customerNo: createColumnConfig((item) => item.editable.customerNo, {
      columnLabel: "Cust. no.",
      disableGridCell: true,
      width: "80px",
    }),
    customerIndex: createColumnConfig((item) => item.editable.customerIndex, {
      columnLabel: "Cust. index",
      disableGridCell: true,
      width: "131px",
    }),
    customerIsParent: createColumnConfig(
      (item) => item.editable.customerIsParent,
      {
        columnLabel: "Type",
        itemLabelFormatter: (value) => (value ? "P" : "C"),
        disableGridCell: true,
        width: "66px",
        summaryText: () => "Total",
      }
    ),
    accompanied12MonthVolPeak: createColumnConfig(
      (item) => item.editable.accompanied12MonthVolPeak,
      {
        columnLabel: "Peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        borderLeft: true,
        minWidth: "65px",
        summaryText: ({ items }) =>
          String(
            sumBy(items, (item) => item.editable.accompanied12MonthVolPeak ?? 0)
          ),
      }
    ),
    accompanied12MonthVolShoulder: createColumnConfig(
      (item) => item.editable.accompanied12MonthVolShoulder,
      {
        columnLabel: "Shoulder",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        minWidth: "65px",
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.accompanied12MonthVolShoulder ?? 0
            )
          ),
      }
    ),
    accompanied12MonthVolOffPeak: createColumnConfig(
      (item) => item.editable.accompanied12MonthVolOffPeak,
      {
        columnLabel: "Off peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        minWidth: "70px",
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.accompanied12MonthVolOffPeak ?? 0
            )
          ),
      }
    ),
    accompaniedExpectedVolPeak: createColumnConfig(
      (item) => String(item.editable.accompaniedExpectedVolPeak ?? ""),
      {
        columnLabel: "Peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        borderLeft: true,
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "accompaniedExpectedVolPeak",
              routePairId
            ),
        width: "90px",
        disableGridCell: !yearIsEditable,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.accompaniedExpectedVolPeak ?? 0
            )
          ),
      }
    ),
    accompaniedExpectedVolShoulder: createColumnConfig(
      (item) => String(item.editable.accompaniedExpectedVolShoulder ?? ""),
      {
        columnLabel: "Shoulder",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "accompaniedExpectedVolShoulder",
              routePairId
            ),
        width: "120px",
        disableGridCell: !yearIsEditable,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.accompaniedExpectedVolShoulder ?? 0
            )
          ),
      }
    ),
    accompaniedExpectedVolOffPeak: createColumnConfig(
      (item) => String(item.editable.accompaniedExpectedVolOffPeak ?? ""),
      {
        columnLabel: "Off peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "accompaniedExpectedVolOffPeak",
              routePairId
            ),
        width: "116px",
        disableGridCell: !yearIsEditable,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.accompaniedExpectedVolOffPeak ?? 0
            )
          ),
      }
    ),
    unaccompanied12MonthVolPeak: createColumnConfig(
      (item) => item.editable.unaccompanied12MonthVolPeak,
      {
        columnLabel: "Peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        borderLeft: true,
        minWidth: "65px",
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompanied12MonthVolPeak ?? 0
            )
          ),
      }
    ),
    unaccompanied12MonthVolShoulder: createColumnConfig(
      (item) => item.editable.unaccompanied12MonthVolShoulder,
      {
        columnLabel: "Shoulder",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        minWidth: "65px",
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompanied12MonthVolShoulder ?? 0
            )
          ),
      }
    ),
    unaccompanied12MonthVolOffPeak: createColumnConfig(
      (item) => item.editable.unaccompanied12MonthVolOffPeak,
      {
        columnLabel: "Off peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        minWidth: "70px",
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompanied12MonthVolOffPeak ?? 0
            )
          ),
      }
    ),
    unaccompaniedExpectedVolPeak: createColumnConfig(
      (item) => String(item.editable.unaccompaniedExpectedVolPeak ?? ""),
      {
        columnLabel: "Peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        borderLeft: true,
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "unaccompaniedExpectedVolPeak",
              routePairId
            ),
        width: "90px",
        disableGridCell: !yearIsEditable,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompaniedExpectedVolPeak ?? 0
            )
          ),
      }
    ),
    unaccompaniedExpectedVolShoulder: createColumnConfig(
      (item) => String(item.editable.unaccompaniedExpectedVolShoulder ?? ""),
      {
        columnLabel: "Shoulder",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "unaccompaniedExpectedVolShoulder",
              routePairId
            ),
        width: "120px",
        disableGridCell: !yearIsEditable,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompaniedExpectedVolShoulder ?? 0
            )
          ),
      }
    ),
    unaccompaniedExpectedVolOffPeak: createColumnConfig(
      (item) => String(item.editable.unaccompaniedExpectedVolOffPeak ?? ""),
      {
        columnLabel: "Off peak",
        justifyContentHeader: "flex-end",
        justifyContentCell: "flex-end",
        isEditable: true,
        renderCell: !yearIsEditable
          ? undefined
          : createEditableCellRenderer(
              "unaccompaniedExpectedVolOffPeak",
              routePairId
            ),
        width: "116px",
        disableGridCell: !yearIsEditable,
        summaryCellColSpan: 44444,
        summaryText: ({ items }) =>
          String(
            sumBy(
              items,
              (item) => item.editable.unaccompaniedExpectedVolOffPeak ?? 0
            )
          ),
      }
    ),
    indentRight: createColumnConfig((item) => "", {
      columnLabel: "",
      width: "16px",
      disableGridCell: true,
    }),
  },

  columnGroups: {
    noLabelColumnGroup: {
      columnOrder: noLabelColumnOrder,
    },
    accompanied: {
      columnOrder: accompaniedColumnOrder,
      label: "Acc - 12m vol.",
      borderLeft: true,
    },
    accompaniedExpected: {
      columnOrder: accompaniedExpectedColumnOrder,
      label: "Acc - Expected vol.",
      borderLeft: true,
    },
    unaccompanied: {
      columnOrder: unaccompaniedColumnOrder,
      label: "UnAcc - 12m vol.",
      borderLeft: true,
    },
    unaccompaniedExpected: {
      columnOrder: unaccompaniedExpectedColumnOrder,
      label: "UnAcc - Expected vol.",
      borderLeft: true,
    },
  },
  columnGroupOrder: [
    "noLabelColumnGroup",
    "accompanied",
    "accompaniedExpected",
    "unaccompanied",
    "unaccompaniedExpected",
  ],
});
