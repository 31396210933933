import { Row, Space } from "@stenajs-webui/core";
import { PrimaryButton } from "@stenajs-webui/elements";
import { flatMap, values } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModalWithLock } from "../../../../common/components/loading-modals/LoadingModalWithLock";
import { StoreState } from "../../../../config/redux/RootReducer";
import { refetchStatBoxes } from "../../../customer-route-statistic-boxes/thunks/FetchStatBoxes";
import { customerRouteRedux } from "../../customer-route/redux";
import {
  routeAgreementHasChanges,
  transformRateSheetChangesToInput,
} from "../../customer-route/transformers/ModifiedToInputTransformer";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { rateSheetTableSelectors } from "../../rate-sheet/selectors";
import { getModifiedOrNull } from "../../route-agreement/details/utils/InputTransformerHelpers";
import { useChangeMultiLegAgreementWorkFlowStatusMutation } from "../hooks/UseChangeMultiLegAgreementWorkFlowStatusMutation";
import { useUpdateRateSheetsForMultiLegRouteAgreementMutation } from "../hooks/UseUpdateRateSheetsForMultiLegRouteAgreementMutation";
import { updateUpdatedRateSheetsInState } from "../thunks/UpdateUpdatedRateSheetsInState";
import { CustomerMultilegRouteDiscardChangesButton } from "./CustomerMultilegRouteDiscardChangesButton";
import { usePrompt } from "@/common/hooks/usePrompt";
import {
  HeadAgreementForMultilegFragment,
  RouteAgreementsForMultilegRouteFragment,
  UpdateRateSheetsRouteAgreementItemInput,
  WorkFlowStatusCode,
} from "@/gql/graphql";

interface Props {
  headAgreement: HeadAgreementForMultilegFragment;
  routePairId: string;
  multiLegAgreementToSave: RouteAgreementsForMultilegRouteFragment;
  canSave: boolean;
  customerId: string;
}

const customerRouteSelector = (state: StoreState) => state.customerRoute;

export const CustomerMultilegRouteSaveButton: React.FC<Props> = ({
  multiLegAgreementToSave,
  routePairId,
  headAgreement,
  canSave,
  customerId,
}) => {
  const dispatch = useDispatch();
  const { defaultYear } = useNegotiationYears();
  const rateSheetState = useSelector(rateSheetTableSelectors.getTableState);
  const customerRouteState = useSelector(customerRouteSelector);
  const loading = customerRouteState.progress.loading;
  const { updateRateSheetsForMultiLegRouteAgreement } =
    useUpdateRateSheetsForMultiLegRouteAgreementMutation();

  const [changeMultiLegRouteAgreementWorkFlowStatus] =
    useChangeMultiLegAgreementWorkFlowStatusMutation();

  const hasChangesInRateSheets = useMemo(() => {
    const list = flatMap(values(rateSheetState), (v) => v.rows);
    return list.some((s) => s?.modified);
  }, [rateSheetState]);

  const multiLegAgreement = useSelector(
    (state: StoreState) => state.customerMultiLegRoute.multiLegAgreement
  );

  const hasChanges = hasChangesInRateSheets || canSave;

  useEffect(() => {
    multiLegAgreementToSave.routeAgreements.map((multiLegRouteAgreement) => {
      const ratesheetTableRowState = rateSheetState[multiLegRouteAgreement.id];

      if (
        !hasChanges &&
        ratesheetTableRowState &&
        !ratesheetTableRowState.rows.find(
          (item) => item.modified && item.persisted === null
        )
      ) {
        dispatch(customerRouteRedux.actions.clearErrors());
      }
      return [];
    });
  }, [multiLegAgreementToSave, dispatch, rateSheetState, hasChanges]);

  const submitHandler = async () => {
    dispatch(customerRouteRedux.actions.clearErrors());
    dispatch(customerRouteRedux.actions.setLoading(true));
    let errors = [];
    try {
      const hasWorkflowStateChanged =
        multiLegAgreement.editable.isSalesReady !==
        multiLegAgreement.persisted.isSalesReady;
      if (hasWorkflowStateChanged) {
        const { data } = await changeMultiLegRouteAgreementWorkFlowStatus({
          variables: {
            changeMultiLegRouteAgreementWorkFlowStatusInput: {
              multiLegRouteAgreementId: multiLegAgreementToSave.id,
              rowVersion: multiLegAgreementToSave.rowVersion,
              workFlowStatusCode: multiLegAgreement.editable.isSalesReady
                ? WorkFlowStatusCode.Sready
                : null,
            },
          },
        });

        const mutationResult =
          data?.productPrice.multiLegRouteAgreement
            .changeMultiLegRouteAgreementSalesReadyStatus;
        if (mutationResult && "errors" in mutationResult) {
          errors.push(
            `Failed to change sales ready status. ${mutationResult.errors.join(
              ". "
            )}`
          );
        }
      }

      const routeAgreementsToSave = multiLegAgreementToSave.routeAgreements;

      const routeAgreements = routeAgreementsToSave
        .map<UpdateRateSheetsRouteAgreementItemInput>((routeAgreement) => {
          const rateSheetTableRowState = rateSheetState[routeAgreement.id];

          if (!rateSheetTableRowState) {
            throw new Error("Missing information.");
          }
          const changes = transformRateSheetChangesToInput(
            rateSheetTableRowState.rows
          );

          const routeAgreementState =
            customerRouteState.routeAgreements[routeAgreement.id];

          return {
            routeAgreementId: routeAgreement.id,
            rowVersion: routeAgreement.rowVersion,
            isSalesReady: getModifiedOrNull(
              routeAgreementState?.editable.isSalesReady,
              routeAgreementState?.persisted?.isSalesReady
            ),
            changes,
          };
        })
        .filter(
          (ra) =>
            routeAgreementHasChanges(ra) ||
            customerRouteState.routeAgreements[ra.routeAgreementId]?.editable
              .isSalesReady !==
              customerRouteState.routeAgreements[ra.routeAgreementId]?.persisted
                .isSalesReady
        );

      if (!multiLegAgreementToSave.id) return;

      const { data: rateSheetData } =
        await updateRateSheetsForMultiLegRouteAgreement(
          {
            routeAgreements,
          },
          multiLegAgreementToSave.id,
          headAgreement.id,
          customerId,
          defaultYear
        );

      const updatedRateSheets =
        rateSheetData?.productPrice.routeAgreementPrice
          .updateMultiLegRateSheets;

      if (updatedRateSheets) {
        dispatch(updateUpdatedRateSheetsInState(updatedRateSheets));
      }

      const errorsRateSheet =
        rateSheetData?.productPrice.routeAgreementPrice.updateMultiLegRateSheets.flatMap(
          (sheet) => {
            if (sheet.__typename === "ErrorResult") {
              return sheet.errors;
            } else {
              return [];
            }
          }
        );

      if (errorsRateSheet && errorsRateSheet.length > 0) {
        errors = [...errors, ...errorsRateSheet.map((err) => err.message)];
      }

      if (errors && errors.length > 0) {
        dispatch(customerRouteRedux.actions.setLoading(false));
        dispatch(
          customerRouteRedux.actions.setErrors(errors.map((e) => new Error(e)))
        );
        return;
      }
    } catch (e) {
      dispatch(customerRouteRedux.actions.setError(e));
    } finally {
      dispatch(customerRouteRedux.actions.setLoading(false));
    }
  };

  const afterDiscardHandler = useCallback(() => {
    dispatch(customerRouteRedux.actions.clearErrors());
    dispatch(refetchStatBoxes());
  }, [dispatch]);

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: hasChanges,
  });

  return (
    <Row alignItems={"center"}>
      {loading && <LoadingModalWithLock label={"Saving rates..."} />}

      <Space num={2} />
      <CustomerMultilegRouteDiscardChangesButton
        disabled={!hasChanges}
        onDiscard={afterDiscardHandler}
      />
      <Space num={2} />
      <PrimaryButton
        disabled={!hasChanges}
        label={"Save changes"}
        onClick={submitHandler}
        loading={loading}
      />
    </Row>
  );
};
