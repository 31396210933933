import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Indent, Row, Space, Txt, useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  PrimaryButton,
  ResultItem,
  ResultListBanner,
  stenaTrash,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { Window } from "@stenajs-webui/modal";
import { useParams } from "react-router-dom";
import { useDeleteAgreementDocumentMutation } from "../hooks/UseDeleteAgreementDocumentMutation";

interface Props {
  documentId: string;
  fileName: string;
  setDeletedListItems: React.Dispatch<React.SetStateAction<String[]>>;
}

export const DeleteAgreementDocumentButton: React.FC<Props> = ({
  documentId,
  fileName,
  setDeletedListItems,
}) => {
  const [isOpen, open, close] = useBoolean(false);
  const { customerId } = useParams() as { customerId: string };

  const { deleteAgreementDocument, loading } =
    useDeleteAgreementDocumentMutation();
  const { setBannerState, bannerState } = useResultListBannerState(undefined);

  const onDeleteDocument = async () => {
    if (customerId && documentId) {
      const { data } = await deleteAgreementDocument(
        Number(customerId),
        documentId
      );

      const result =
        data?.productPrice.agreementDocuments.deleteAgreementDocument;

      if (result && "errors" in result) {
        setBannerState({
          headerText: "Could not delete document",
          items: result.errors.map<ResultItem>((error) => ({
            text: error.message,
          })),
        });
      } else {
        setDeletedListItems((prevArray) => [...prevArray, documentId]);
        close();
      }
    }
  };

  return (
    <>
      <FlatButton leftIcon={stenaTrash} onClick={open} size={"medium"} />
      <Window
        isOpen={isOpen}
        width={"100%"}
        spacing={3}
        indent={3}
        onRequestClose={close}
        header={
          <Txt variant={"bold"} size={"large"}>
            Delete {fileName}
          </Txt>
        }
      >
        <Txt>{"Are you sure you want to delete this document?"}</Txt>
        <Space num={1} />
        {bannerState && (
          <>
            <Space />
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              <ResultListBanner bannerState={bannerState} variant={"error"} />
            </Box>
            <Space num={SpaceValues.SIXTEEN} />
          </>
        )}
        <Row display={"flex"} justifyContent={"flex-end"}>
          <FlatButton label={"No"} onClick={close} />
          <Indent />
          <PrimaryButton
            label={"Yes"}
            onClick={onDeleteDocument}
            loading={loading}
          />
        </Row>
      </Window>
    </>
  );
};
