import { SpaceValues } from "@/common/utils/SpaceValues";
import { Column, SeparatorLine, Space } from "@stenajs-webui/core";
import {
  StandardTable,
  StandardTableConfigWithGroups,
} from "@stenajs-webui/grid";
import * as React from "react";
import {
  StatisticsResultListColumns,
  StatisticsResultListItem,
} from "../config/list/ResultTableConfig";

interface Props {
  items: StatisticsResultListItem[] | undefined;
  config: StandardTableConfigWithGroups<
    StatisticsResultListItem,
    StatisticsResultListColumns
  >;
  loading: boolean;
  noItemsLabel?: string;
}

export const ResultTable: React.FC<Props> = ({
  items,
  config,
  loading,
  noItemsLabel,
}) => {
  return (
    <Column>
      <StandardTable
        items={items}
        config={config}
        loading={loading}
        noItemsLabel={noItemsLabel}
      />
      {items && !loading ? <SeparatorLine /> : null}
      <Space num={SpaceValues.TWENTYFOUR} vertical />
    </Column>
  );
};
