import { apolloClient } from "../../../../../apollo-client/ApolloClient";
import { AppThunk, StoreState } from "../../../../../config/redux/RootReducer";
import { isEqual } from "lodash";
import { actualChangeForPriceRowQuery } from "../../thunks/FetchActualChangeForSinglePriceRow";

import { refetchStatBoxesWithBulkEditRatesChanges } from "../components/stat-boxes/thunks/RefetchStatBoxesWithBulkEditRatesChanges";

import { bulkEditRatesActions } from "../redux/reducer";
import { bulkEditRatesSelectors } from "../redux/selectors";
import {
  ActualChangeForPriceRowQuery,
  ActualChangeForPriceRowQueryVariables,
} from "@/gql/graphql";

const getBulkEditRatesCalculateActualForPriceRowQueryVariables = (
  state: StoreState,
  routeAgreementId: string,
  routeAgreementPriceId: string
): ActualChangeForPriceRowQueryVariables => {
  const editableRowState =
    bulkEditRatesSelectors.getEditedBulkEditRatesTableRows(state)[
      routeAgreementPriceId
    ];

  if (!editableRowState) {
    throw new Error(
      `No row state available for price. routeAgreementId=${routeAgreementId} routeAgreementPriceId=${routeAgreementPriceId}`
    );
  }

  const avgGrossPrice = editableRowState.avgGrossPrice ?? null;
  const oldFixPrice = editableRowState?.lastPrice ?? null;
  const oldVarPrice = editableRowState?.lastMeterPrice ?? null;

  return {
    additionalFrom: Number(editableRowState.additionalFrom) ?? 0,
    meterPrice: parseFloat(editableRowState.meterPrice ?? 0),
    newPrice: parseFloat(editableRowState.newPrice ?? 0),
    avgGrossPrice: avgGrossPrice == null ? null : parseFloat(avgGrossPrice),
    avgLength: editableRowState.avgLength ?? null,
    oldAdditionalFrom: editableRowState?.lastChargedFrom ?? null,
    oldFixPrice: oldFixPrice == null ? null : parseFloat(oldFixPrice),
    oldVarPrice: oldVarPrice == null ? null : parseFloat(oldVarPrice),
  };
};

export const fetchBulkEditRatesActualChangeForSinglePriceRow =
  (routeAgreementId: string, routeAgreementPriceId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const variables: ActualChangeForPriceRowQueryVariables =
        getBulkEditRatesCalculateActualForPriceRowQueryVariables(
          getState(),
          routeAgreementId,
          routeAgreementPriceId
        );
      const r = await apolloClient.query<
        ActualChangeForPriceRowQuery,
        ActualChangeForPriceRowQueryVariables
      >({
        query: actualChangeForPriceRowQuery,
        fetchPolicy: "network-only",
        variables,
      });

      const actual =
        r.data?.productPrice.actualPriceChange.calculateActualPriceChange ??
        null;

      const latestVariables: ActualChangeForPriceRowQueryVariables =
        getBulkEditRatesCalculateActualForPriceRowQueryVariables(
          getState(),
          routeAgreementId,
          routeAgreementPriceId
        );

      const userHasNotChangedInputs = isEqual(latestVariables, variables);

      if (userHasNotChangedInputs) {
        dispatch(
          bulkEditRatesActions.setValue({
            id: routeAgreementPriceId,
            actual,
          })
        );
      }
      dispatch(refetchStatBoxesWithBulkEditRatesChanges());
    } catch (e) {
      console.debug(e);
    }
  };
