import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { Box, Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { NumberDecimalsSelect } from "../../../../../common/components/data-driven-inputs/selects/number-decimals-select/NumberDecimalsSelect";
import { RaiseByTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-by-type-select/RaiseByTypeSelect";
import { RaiseTypeSelect } from "../../../../../common/components/data-driven-inputs/selects/raise-type-select/RaiseTypeSelect";
import { BulkUpdateRatesFormModel } from "../models/BulkUpdateRatesFormModel";

interface Props {
  value: BulkUpdateRatesFormModel;
  onValueChange: (value: BulkUpdateRatesFormModel) => void;
  currencyLabel?: string;
  disableFilters?: boolean;
}

export const BulkUpdateRatesForm: React.FC<Props> = ({
  value,
  onValueChange,
  currencyLabel,
  disableFilters,
}) => (
  <Column>
    <Label text={"Raise"}>
      <RaiseTypeSelect
        value={value.raiseType}
        onValueChange={(raiseType) => onValueChange({ ...value, raiseType })}
      />
    </Label>
    <Spacing />
    {!disableFilters && (
      <>
        <CheckboxWithLabel
          value={value.acc}
          label={"Accompanied"}
          onValueChange={(acc) => onValueChange({ ...value, acc })}
        />
        <Space />
        <CheckboxWithLabel
          value={value.unacc}
          label={"Unaccompanied"}
          onValueChange={(unacc) => onValueChange({ ...value, unacc })}
        />
        <Space />
        <CheckboxWithLabel
          value={value.vehicleTypeIsNull}
          label={"Vehicle type = All"}
          onValueChange={(vehicleTypeIsNull) =>
            onValueChange({ ...value, vehicleTypeIsNull })
          }
        />
        <Spacing />
      </>
    )}
    <Row alignItems={"center"}>
      <Box width={"60px"}>
        <Label text={"By"}>
          <NumericTextInput
            value={value.raiseBy}
            onValueChange={(raiseBy) => onValueChange({ ...value, raiseBy })}
          />
        </Label>
      </Box>
      <Indent />
      <Box flex={1} height="100%" justifyContent="flex-end">
        <RaiseByTypeSelect
          currencyLabel={currencyLabel}
          value={value.raiseByType}
          onValueChange={(raiseByType) =>
            onValueChange({ ...value, raiseByType })
          }
        />
      </Box>
    </Row>
    <Spacing />
    <Label text={"Rounding precision"}>
      <NumberDecimalsSelect
        value={value.numberDecimals}
        onValueChange={(numberDecimals) =>
          onValueChange({ ...value, numberDecimals })
        }
      />
    </Label>
  </Column>
);
