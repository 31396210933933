import {
  createEntityActions,
  createEntityReducer,
  reducerIdGate,
  reducerIdGateAction,
} from "@stenajs-webui/redux";
import { combineReducers } from "redux";

export interface NegotiationYearState {
  progress: ProgressState;
}

interface ProgressState {
  loading: boolean;
  errors?: Array<Error>;
}

const reducerIdProgress = "selectNegotationYear.progress";

export const negotiationYearRedux = {
  reducer: combineReducers({
    progress: reducerIdGate(
      reducerIdProgress,
      createEntityReducer<ProgressState>({ loading: false })
    ),
  }),
  actions: {
    setLoading: (loading: boolean) =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields({ loading })
      ),
    setError: (error: Error | undefined) =>
      reducerIdGateAction(
        reducerIdProgress,
        createEntityActions<ProgressState>().setEntityFields(
          error ? { errors: [error] } : { errors: undefined }
        )
      ),
  },
};
