import { Box, Row, Text } from "@stenajs-webui/core";
import * as React from "react";
import { cssColor } from "@stenajs-webui/theme";
import BoxPlot from "./legend-components/BoxPlot.svg";
import EdgeLeftLine from "./legend-components/EdgeLeftLine.svg";
import BoxPart from "./legend-components/Box.svg";
import MedianLine from "./legend-components/MedianLine.svg";
import EdgeRightLine from "./legend-components/EdgeRightLine.svg";
import OutlierDot from "./legend-components/OutlierDot.svg";
import { FlatButton, stenaTimes } from "@stenajs-webui/elements";

interface Props {
  handleCloseLegend: () => void;
}

const valueTextSecondary = (): string => {
  return (
    "**" +
    Array(1).fill("\xa0").join("") +
    "High values - Average price/m > Q3 + 3*(Q3 - Q1)"
  );
};

export const Legend: React.FC<Props> = ({ handleCloseLegend }) => {
  const valueTextFirst = (): string => {
    return (
      "*" +
      Array(3).fill("\xa0").join("") +
      "High values - Average price/m > Q3 + 1,5*(Q3 - Q1)"
    );
  };

  return (
    <Row display="flex" flexDirection="column" width="392px">
      <Box
        borderBottom={`1px solid ${cssColor("--lhds-color-ui-300")}`}
        display="flex"
        spacing={1}
      >
        <Box
          indent={3}
          spacing={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text size="medium" variant="bold">
            {"Legend"}
          </Text>
          <FlatButton
            onClick={handleCloseLegend}
            leftIcon={stenaTimes}
            style={{ transform: "translateX(12px)" }}
          />
        </Box>
      </Box>
      <Box
        indent={2}
        spacing={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Text>{"Acc(500)"}</Text>
        <img src={BoxPlot} alt="Box Plot" />
      </Box>
      <Box indent={2.8} display="flex" flexDirection="row" height="25px">
        <Text style={{ marginRight: "50px" }}>{"500"}</Text>
        <Text>{"No. of prices"}</Text>
      </Box>
      <Box indent={1} display="flex" flexDirection="row" alignItems="center">
        <img src={EdgeLeftLine} alt="Edge line" />
        <Text>{"0 - 25%"}</Text>
      </Box>
      <Box indent={1} display="flex" flexDirection="row" alignItems="center">
        <img src={BoxPart} alt="Box" />
        <Text>{"25 - 75%"}</Text>
      </Box>
      <Box indent={1} display="flex" flexDirection="row" alignItems="center">
        <img src={MedianLine} alt="Median line" />
        <Text>{"Median value"}</Text>
      </Box>
      <Box indent={1} display="flex" flexDirection="row" alignItems="center">
        <img src={EdgeRightLine} alt="Edge right line" />
        <Text>{"75 - 100%"}</Text>
      </Box>
      <Box indent={1} display="flex" flexDirection="row" alignItems="center">
        <img src={OutlierDot} alt="Outlier dot" />
        <Text>{"Outlier*"}</Text>
      </Box>
      <Box indent={2.7} spacing={1} display="flex" flexDirection="column">
        <Text>{`${valueTextFirst()}`}</Text>
        <Text style={{ marginLeft: "19px" }}>
          {"Low values - Average price/m < Q3 - 1,5*(Q3 - Q1)"}
        </Text>
      </Box>
      <Box indent={2.7} spacing={1} display="flex" flexDirection="column">
        <Text>{valueTextSecondary()}</Text>
        <Text style={{ marginLeft: "19px" }}>
          {"Low values - Average price/m < Q3 - 3*(Q3 - Q1)"}
        </Text>
      </Box>
    </Row>
  );
};
