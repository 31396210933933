import { DateValue } from "@internationalized/date";

export enum RaiseByType {
  PERCENT = "PERCENT",
  ABSOLUTE = "ABSOLUTE",
}

export interface BulkUpdateAgreementArticlesFormModel {
  raiseByPercent: string;
  raiseByAbsolute: string;
  raiseByType: RaiseByType;
  validFromDate: DateValue | undefined;
}

export const emptyBulkUpdateAgreementArticlesFormModel: BulkUpdateAgreementArticlesFormModel =
  {
    raiseByPercent: "",
    raiseByAbsolute: "",
    raiseByType: RaiseByType.ABSOLUTE,
    validFromDate: undefined,
  };
