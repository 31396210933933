import { useCurrencySelection } from "@/common/redux/profile/hooks/UseCurrencySelection";
import { SpaceValues } from "@/common/utils/SpaceValues";

import { FppSearchFilter } from "@/features/search-filter/components/FppSearchFilter";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import {
  SalesRegion,
  Seller,
  Status,
} from "@/features/search-filter/types/FilterEntitys";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { useQuery } from "@apollo/client";
import { Box, Row, SeparatorLine, Space, Txt } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
} from "@stenajs-webui/elements";
import { SearchFilterContext } from "@stenajs-webui/filter";
import { StandardTable } from "@stenajs-webui/grid";
import { StandardTableExcelExportButton } from "@stenajs-webui/grid-export";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { statisticsByCustomerFilterSettings } from "../config/StatisticsByCustomerFilterConfig";
import { createByCustomerTableConfig } from "../config/StatisticsByCustomerTableConfig";

import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import {
  CustomerListForStatisticsQuery,
  CustomerListForStatisticsQueryVariables,
} from "@/gql/graphql";
import { customerListForStatisticsQuery } from "../hooks/useCustomerListQuery";
import { transformCustomerItemsToTableItems } from "./transformers";

const getSalesRegions = (salesRegions?: SalesRegion[]) =>
  salesRegions ? salesRegions.map((salesRegion) => Number(salesRegion.id)) : [];

const getSellerIds = (sellers: Seller[] | undefined) =>
  sellers ? sellers.map((seller) => seller.id) : [];

const getStatus = (statuses: Status[]) => statuses.map((status) => status.code);

export const StatisticsByCustomerTable: React.FC = () => {
  const { year } = useStatisticsYearSelect();
  const { currency } = useCurrencySelection();
  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { openFilter } = useFilterWorkspace();

  const { data, loading, error } = useQuery<
    CustomerListForStatisticsQuery,
    CustomerListForStatisticsQueryVariables
  >(customerListForStatisticsQuery, {
    variables: {
      filterOptions: {
        negotiationYear: year,
        salesRegionCodes: getSalesRegions(committedState.salesRegions),
        currencyCode: currency,
        negotiationStatuses: getStatus(committedState.negotiationStatus),
        sellerIds: getSellerIds(committedState.sellers),
        routePairCodes: committedState.routePairs.map((u) => u.id),
      },
    },
    skip:
      !committedState.salesRegions.length &&
      !committedState.sellers.length &&
      !committedState.routePairs.length,
    fetchPolicy: "network-only",
  });

  const config = useMemo(
    () => createByCustomerTableConfig(currency),
    [currency]
  );

  const customerList = useMemo(
    () =>
      transformCustomerItemsToTableItems(
        data?.statistics.customerList.byFilter.items ?? []
      ),
    [data?.statistics.customerList.byFilter.items]
  );
  return (
    <Card minWidth={"1140px"}>
      <CardHeader
        text={"Customer"}
        contentRight={
          <Row alignItems={"center"}>
            <Txt>Export to Excel </Txt>
            <StandardTableExcelExportButton
              config={config}
              items={customerList}
            />
          </Row>
        }
      />
      <CardBody>
        <Row>
          <Box>
            <FilterInternal />
          </Box>
        </Row>
      </CardBody>
      <SeparatorLine />
      <StandardTable
        items={customerList}
        config={config}
        loading={loading}
        error={error}
        noItemsHeader={
          committedState.salesRegions.length ||
          committedState.routePairs.length ||
          committedState.salesRegions.length
            ? "Your search did not return any results"
            : "Search for customers"
        }
        noItemsContentRight={
          <FlatButton label={"Open filter"} onClick={() => openFilter()} />
        }
        noItemsLabel={
          committedState.salesRegions.length ||
          committedState.routePairs.length ||
          committedState.salesRegions.length
            ? "No customers found"
            : "Please select seller, region or route to search for customers"
        }
      />
      {customerList && !loading ? <SeparatorLine /> : null}
      <Space num={SpaceValues.TWENTYFOUR} vertical />
    </Card>
  );
};

const FilterInternal = () => {
  const { workspaceState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;
  const dispatch = useDispatch();

  return (
    <SearchFilterContext
      state={workspaceState}
      actions={createFppSearchFilterActions("statistics.statisticsByCustomer")}
      dispatch={dispatch}
    >
      <FppSearchFilter settings={statisticsByCustomerFilterSettings} />
    </SearchFilterContext>
  );
};
