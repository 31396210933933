import * as React from "react";
import { Select } from "@stenajs-webui/select";
import { startCase, upperFirst } from "lodash";
import { RaiseType } from "../../../../../features/product-and-price/rate-sheet/bulk-update-rates/models/BulkUpdateRatesFormModel";

interface Props {
  value?: RaiseType;
  onValueChange?: (value: RaiseType) => void;
}

const allRaiseTypes: Array<RaiseType> = [
  RaiseType.FIXED_PRICE,
  RaiseType.METER_PRICE,
  RaiseType.BOTH,
  RaiseType.BY_METER_PRICE,
];

const formatLabel = (type: RaiseType): string =>
  upperFirst(startCase(type).toLowerCase());

const options = allRaiseTypes.map((r) => ({
  label: formatLabel(r),
  value: r,
}));

export const RaiseTypeSelect: React.FC<Props> = ({ onValueChange, value }) => {
  return (
    <Select
      value={value && { label: formatLabel(value), value }}
      onChange={(v: any) => onValueChange?.(v.value)}
      options={options}
    />
  );
};
