import { AppThunk } from "../../../../config/redux/RootReducer";
import { keys } from "lodash";
import { fetchActualChangeForSinglePriceRow } from "./FetchActualChangeForSinglePriceRow";

export const fetchActualChangeForAllPriceRows =
  (): AppThunk => async (dispatch, getState) => {
    const tableState = getState().rateSheet.table;
    const routeAgreementIds = keys(tableState);

    await Promise.all(
      routeAgreementIds.map((routeAgreementId) => {
        const routeAgreementPriceIds = tableState[routeAgreementId].rows.map(
          (r) => r.routeAgreementPriceId
        );
        return routeAgreementPriceIds.map((routeAgreementPriceId) =>
          dispatch(
            fetchActualChangeForSinglePriceRow(
              routeAgreementId,
              routeAgreementPriceId
            )
          )
        );
      })
    );
  };
