import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { formatThousands } from "@/common/numbers";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  Box,
  Column,
  Heading,
  Indent,
  Row,
  SeparatorLine,
  Space,
} from "@stenajs-webui/core";
import { Card, CardBody, CardHeader } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import { ChipMultiSelectOption } from "@stenajs-webui/select";
import NegoitaionStatusTextContainer from "../NegotiationStatusTextContainer";
import PillDiagram from "../PillDiagram";
import { SailingCategoryStatisticsTable } from "../SailingCategoryStatisticsTable/SailingCategoryStatisticsTable";
import { StatisticAverageMeterPricesTable } from "../average-meter-prices-table/StatisticsAverageMeterPricesTable";
import { createNegotiationStatusTableConfig } from "../config/details/StatisticsByNegotiationStatusTablesConfig";
import {
  NegStatusChipMultiSelect,
  NegotiationStatusItem,
} from "../neg-status-chip-multi-select/NegStatusChipMultiSelect";
import {
  getNegotiationStatusListItemFromSummary,
  getNegotiationTypePercentageByCustomer,
  getNegotiationTypePercentageByVolume,
  getTargetAndRateItems,
  getVolumeAndRateItems,
  percentLabelFormatterDecimalValue,
} from "../utils/statisticsUtil";
import { testIdConstantsWebapp } from "@/common/test-id-constants/testIdConstants";
import {
  UseSalesRegionDetailsSummaryQuery,
  UseSalesRegionDetailsVolumeAndRatesQuery,
} from "@/gql/graphql";

export const StatisticsDetailsView = ({
  year,
  summary,
  currency,
  selectedNegotiationStatuses,
  setSelectedNegotiationStatuses,
  volumeAndRates,
  volumeAndRatesLoading,
  averageMeterPrices,
}: {
  selectedNegotiationStatuses: NegotiationStatusItem[];
  setSelectedNegotiationStatuses: React.Dispatch<
    React.SetStateAction<NegotiationStatusItem[]>
  >;
  averageMeterPrices?:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["threeYearsAverageMeterPrices"]
    | undefined;
  year: number;
  summary: UseSalesRegionDetailsSummaryQuery["statistics"]["salesRegionDetails"]["summary"];
  currency: string;
  volumeAndRatesLoading: boolean;
  volumeAndRates:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["volumeAndRates"]
    | undefined;
}) => {
  const {
    donePercentageByVolume,
    inProgressPercentageByVolume,
    todoPercentageByVolume,
  } = getNegotiationTypePercentageByVolume(summary.byVolume);
  const {
    donePercentageByCustomer,
    inProgressPercentageByCustomer,
    todoPercentageByCustomer,
  } = getNegotiationTypePercentageByCustomer(summary.byNumber);

  const negotiationPercentageByCustomer =
    getNegotiationStatusListItemFromSummary(
      summary.byNumber.items,
      year.toString()
    );

  const negotiationStatusListByVolume = getNegotiationStatusListItemFromSummary(
    summary.byVolume.items,
    year.toString()
  );

  return (
    <PageLayout>
      <Card>
        <CardHeader text="Negotiation Status" />
        <CardBody minWidth={388}>
          <Row justifyContent={"space-between"}>
            <Column>
              <Space num={SpacingValues.EIGHT} />
              <Heading
                variant="h4"
                data-testid={
                  testIdConstantsWebapp.statisticsTotalCustomersResult
                }
              >{`By ${formatThousands(
                summary.byNumber.total
              )} customers`}</Heading>
              <Space num={SpaceValues.SIXTEEN} />
              <PillDiagram
                doneStatus={donePercentageByCustomer}
                startedStatus={inProgressPercentageByCustomer}
                todoStatus={todoPercentageByCustomer}
              />
              <Space />
            </Column>
            <Space num={SpaceValues.SIXTEEN} horizontal />
            <Column width={"70%"}>
              <StandardTable
                config={createNegotiationStatusTableConfig()}
                items={[negotiationPercentageByCustomer]}
              />
              <SeparatorLine />
            </Column>
          </Row>
          <Space num={SpaceValues.TWENTYFOUR} />
          <Row justifyContent={"space-between"}>
            <Column>
              <Space num={SpaceValues.EIGHT} />
              <Heading variant="h4">
                By volume {formatThousands(summary.byVolume.total)} units
              </Heading>
              <Space num={SpaceValues.SIXTEEN} />
              <PillDiagram
                doneStatus={donePercentageByVolume}
                startedStatus={inProgressPercentageByVolume}
                todoStatus={todoPercentageByVolume}
              />
              <Space />
            </Column>
            <Space num={SpaceValues.SIXTEEN} horizontal />
            <Column width={"70%"}>
              <StandardTable
                config={createNegotiationStatusTableConfig()}
                items={[negotiationStatusListByVolume]}
              />
              <SeparatorLine />
            </Column>
          </Row>
          <Space num={SpaceValues.SIXTEEN} />
          <NegoitaionStatusTextContainer />
        </CardBody>
      </Card>

      <VolumeAndRatesDetailsTables
        loading={volumeAndRatesLoading}
        volumeAndRates={volumeAndRates}
        averageMeterPrices={averageMeterPrices}
        year={year}
        currency={currency}
        selectedNegotiationStatuses={selectedNegotiationStatuses}
        setSelectedNegotiationStatuses={setSelectedNegotiationStatuses}
      />
    </PageLayout>
  );
};

const VolumeAndRatesDetailsTables = ({
  volumeAndRates,
  selectedNegotiationStatuses,
  averageMeterPrices,
  setSelectedNegotiationStatuses,
  loading,
  year,
  currency,
}: {
  volumeAndRates:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["volumeAndRates"]
    | undefined;
  averageMeterPrices:
    | UseSalesRegionDetailsVolumeAndRatesQuery["statistics"]["salesRegionDetails"]["threeYearsAverageMeterPrices"]
    | undefined;
  selectedNegotiationStatuses: ChipMultiSelectOption[];
  setSelectedNegotiationStatuses: React.Dispatch<
    React.SetStateAction<ChipMultiSelectOption[]>
  >;
  loading: boolean;
  year: number;
  currency: string;
}) => {
  const { rates, targets } = getTargetAndRateItems(
    volumeAndRates?.rateChange.tables
  );
  const { expectedVolume, lastYearVolume } = getVolumeAndRateItems(
    volumeAndRates?.volumeChange.tables
  );
  const totalTargetValue = percentLabelFormatterDecimalValue(
    volumeAndRates?.rateChange.tables.total.total.target
  );

  const totalRateValue = percentLabelFormatterDecimalValue(
    volumeAndRates?.rateChange.tables.total.total.rate
  );

  const totalVolumeLastYear = formatThousands(
    volumeAndRates?.volumeChange.tables.total.total.lastYear || 0
  );

  const totalVolumeExpected = formatThousands(
    volumeAndRates?.volumeChange.tables.total.total.expected || 0
  );
  return (
    <Card>
      <CardHeader text={"Rates & Volume"} />
      <CardBody>
        <Box>
          <Row spacing={SpacingValues.TWENTYFOUR}>
            <NegStatusChipMultiSelect
              selectedStatuses={selectedNegotiationStatuses}
              setSelectedStatuses={setSelectedNegotiationStatuses}
            />
          </Row>
        </Box>
        <Row spacing={SpacingValues.TWENTYFOUR}>
          <SailingCategoryStatisticsTable
            displayPercentageAfterValue={true}
            loading={loading}
            headerTitle={`Target rate: ${totalTargetValue}`}
            tableItems={targets}
          />
          <Indent num={IndentValues.TWELVE} />
          <SailingCategoryStatisticsTable
            displayPercentageAfterValue={true}
            loading={loading}
            headerTitle={`Actual rate: ${totalRateValue}`}
            tableItems={rates}
          />
        </Row>
        <Row spacing={SpacingValues.TWENTYFOUR}>
          <SailingCategoryStatisticsTable
            loading={loading}
            headerTitle={`Expected volume: ${totalVolumeExpected} units`}
            tableItems={expectedVolume}
          />
          <Indent num={IndentValues.TWELVE} />
          <SailingCategoryStatisticsTable
            loading={loading}
            headerTitle={`12 month volume: ${totalVolumeLastYear} units`}
            tableItems={lastYearVolume}
          />
        </Row>
        <Row spacing={SpacingValues.TWENTYFOUR}>
          <Box width={"calc(50% - 12px)"}>
            <CardHeader text="Price/m" />
            <Box indent={IndentValues.TWENTYFOUR}>
              <StatisticAverageMeterPricesTable
                item={averageMeterPrices}
                loading={loading}
                year={year}
                currency={currency}
              />
            </Box>
          </Box>
        </Row>
      </CardBody>
    </Card>
  );
};
