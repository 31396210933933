import { FormModelChangeHandler } from "../../../../../../../../common/forms/types";
import { RouteAgreement } from "../../../../../../customers/customer-details/types";
import * as React from "react";
import { Column, Indent, Row, Space, Spacing } from "@stenajs-webui/core";
import { PageHeaderItem } from "../../../../../../../../common/components/page-header/PageHeaderItem";
import { formatLongSailingType } from "../../../../../../../../common/formatters/SailingStatusCodeFormatter";
import { CustomerSelect } from "../../../../../../../../common/components/data-driven-inputs/selects/customer-select/CustomerSelect";
import { HeadAgreementByCustomerIdSelect } from "../../../../../../../../common/components/data-driven-inputs/selects/head-agreement-by-customer-select/HeadAgreementByCustomerSelect";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import { CopyRouteAgreementParams } from "./SelectCustomerAndHeadAgreementModal";

interface CopyToOtherCustomerFormProps {
  formData: Partial<CopyRouteAgreementParams>;
  onChange: FormModelChangeHandler<Partial<CopyRouteAgreementParams>>;
  routeAgreement: RouteAgreement;
  onCancel: () => void;
  onRequestSubmit: () => void;
}

export const CopyToOtherCustomerForm: React.FC<
  CopyToOtherCustomerFormProps
> = ({ routeAgreement, formData, onChange, onRequestSubmit, onCancel }) => {
  return (
    <Column indent={2} spacing={2}>
      <Label text={"Agreement to copy"}>
        <Row>
          <PageHeaderItem
            label={"Routes"}
            value={routeAgreement.routes.map((r) => r.id).join(" ")}
          />
          <Space num={3} />
          <PageHeaderItem
            label={"Agr.no"}
            value={routeAgreement.agreementNumber}
          />
          <Space num={3} />
          <PageHeaderItem label={"Name"} value={routeAgreement.name} />
          <Space num={3} />
          <PageHeaderItem
            label={"Type"}
            value={routeAgreement.sailingTypeCodes
              .map((code) => formatLongSailingType(code))
              .join(", ")}
          />
        </Row>
      </Label>
      <Spacing num={2} />
      <Column>
        <Label text={"Select target customer"}>
          <CustomerSelect
            value={formData.customer?.id}
            onValueChange={(option) => onChange("customer", option)}
          />
        </Label>
      </Column>
      <Spacing num={2} />
      <Column>
        <Label text={"Select target header agreement"}>
          <HeadAgreementByCustomerIdSelect
            key={formData.customer?.id}
            customerId={formData.customer?.id}
            onValueChange={(option) => onChange("headAgreement", option)}
            value={formData.headAgreement?.id}
          />
        </Label>
      </Column>

      <Spacing num={2} />

      <Row justifyContent={"flex-end"}>
        <FlatButton onClick={onCancel} label={"Cancel"} />
        <Indent />
        <PrimaryButton
          disabled={!formData.headAgreement}
          onClick={onRequestSubmit}
          label={"Copy agreement"}
        />
      </Row>
    </Column>
  );
};
