import { Box, Column, Indent, Row, Space } from "@stenajs-webui/core";
import { FlatButton, Label, PrimaryButton } from "@stenajs-webui/elements";
import React, { useEffect, useState } from "react";
import { HandleBookingExcludeReasonSelect } from "../../../../../../common/components/data-driven-inputs/selects/handle-booking-exclude-reason-select/HandleBookingExcludeReasonSelect";
import { FreightPricingPortalError } from "../../../../../../common/error/models/FreightPricingPortalError";
import { ErrorDisplay } from "../../../../../../common/error/components/ErrorDisplay";
import _ from "lodash";
import { Window } from "@stenajs-webui/modal";

interface Props {
  onExcludeReasonSet: (value: string) => void;
  onExcludeReasonCancel: () => void;
  isModalOpen: boolean;
  localErrors: Array<FreightPricingPortalError>;
  loading?: boolean;
  onErrorClose?: () => void;
}

export const HandleBookingStatusModal: React.FC<Props> = ({
  onExcludeReasonSet,
  onExcludeReasonCancel,
  isModalOpen,
  localErrors,
  loading = false,
  onErrorClose,
}) => {
  const [excludeReason, setExcludeReason] = useState<string | undefined>();
  const applyButtonDisabled = (
    value: string | undefined,
    errors: FreightPricingPortalError[]
  ) => {
    return !value && errors.length === 0;
  };

  useEffect(() => setExcludeReason(undefined), []);

  return (
    <Window
      headerText={"Do not invoice reason"}
      isOpen={isModalOpen}
      onRequestClose={onExcludeReasonCancel}
      indent={3}
      spacing={2}
      width={"500px"}
      shouldCloseOnOverlayClick={false}
    >
      <Column indent={2} spacing>
        <Label text="Select reason" />
        <Box width={275}>
          <HandleBookingExcludeReasonSelect
            value={excludeReason}
            onValueChange={(value) => setExcludeReason(value)}
          />
        </Box>
        {localErrors.length > 0 && (
          <>
            <Space num={3} />
            <ErrorDisplay
              error={_.first(localErrors)!}
              heading={"Could not update booking status"}
              onClose={() => {
                onErrorClose?.();
              }}
            />
          </>
        )}
        <Space num={6} />
        <Row justifyContent={"flex-end"} alignItems={"center"}>
          <Indent />
          <FlatButton
            label={"Cancel"}
            onClick={() => {
              setExcludeReason(undefined);
              onExcludeReasonCancel();
            }}
          />
          <Indent />
          <PrimaryButton
            label={"Apply"}
            disabled={applyButtonDisabled(excludeReason, localErrors)}
            onClick={() => excludeReason && onExcludeReasonSet(excludeReason)}
            loading={loading}
          />
        </Row>
      </Column>
    </Window>
  );
};
