import { useArraySet } from "@stenajs-webui/core";
import { stenaStatusInProgress } from "@stenajs-webui/elements";
import { FilterCheckbox, FilterCheckboxList } from "@stenajs-webui/filter";
import * as React from "react";
import { useFetchAllNegotiationStatuses } from "../../hooks/FilterFetchers/UseFetchAllNegotiationStatuses";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { FppSearchFilterSection } from "../FppSearchFilterSection";

interface Props {
  enabled?: boolean;
  label?: string;
}

export const NegotiationStatusFilterSection: React.FC<Props> = (props) =>
  props.enabled ? <NegotiationStatusFilterSectionInternal {...props} /> : <></>;

const NegotiationStatusFilterSectionInternal: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { negotiationStatus, setNegotiationStatus } = useFilterWorkspace();
  const { negotiationStatuses, error, loading, refetch } =
    useFetchAllNegotiationStatuses();

  const { toggle } = useArraySet(
    negotiationStatus,
    setNegotiationStatus,
    (a, b) => a.id === b.id
  );

  const checkboxClickHandler = (_: boolean, id: string) => {
    const entityToToggle = negotiationStatuses.find((item) => item.id === id);
    if (entityToToggle) {
      toggle(entityToToggle);
    }
  };
  return (
    <FppSearchFilterSection
      sectionId={"negotiationStatus"}
      label={label}
      enabled={enabled}
      numSelected={negotiationStatus.length}
      leftIcon={stenaStatusInProgress}
      error={error?.message}
      loading={loading}
      onRetry={refetch}
      children={
        <FilterCheckboxList maxHeight={"400px"}>
          {negotiationStatuses.map((item) => (
            <FilterCheckbox
              key={item.id}
              label={item.name}
              value={
                !!negotiationStatus.find((status) => status.id === item.id)
              }
              onValueChange={(v) => checkboxClickHandler(v, item.id)}
            />
          ))}
        </FilterCheckboxList>
      }
    />
  );
};
