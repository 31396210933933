import { isDefined } from "@/common/types/IsDefined";
import { stenaUserMultiple } from "@stenajs-webui/elements";
import { FilterEntity } from "@stenajs-webui/filter";
import { ChipMultiSelect } from "@stenajs-webui/select";
import * as React from "react";
import { useEffect, useState } from "react";
import { useFilterWorkspace } from "../../hooks/UseFilterWorkspace";
import { transformQueryResultToFilterEntity } from "../../utils/transformers";
import { FppSearchFilterSection } from "../FppSearchFilterSection";
import { useLoadStats } from "../../hooks/UseLoadStats";

interface CustomerOption {
  label: string;
  value: string;
  data?: FilterEntity;
}

interface Props {
  enabled?: boolean;
  label?: string;
}

export const StatNumberAndIndexFilterSection: React.FC<Props> = ({
  enabled,
  label,
}) => {
  const { statNumbersAndIndexes, setStatNumbersAndIndexes } =
    useFilterWorkspace();
  const { fetchStats, loading, error, data } = useLoadStats();
  const [inputText, setInputText] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Array<FilterEntity>>(
    []
  );

  const selectOptions = filteredOptions.map<CustomerOption>((option) => ({
    value: option.code,
    label: `${option.code} - ${option.name}`,
    data: option,
  }));

  const values = statNumbersAndIndexes.map<CustomerOption>((customer) => ({
    value: customer.code,
    label: customer.name,
    data: customer,
  }));

  const onInputChange = (inputValue: string) => {
    setInputText(inputValue);
    if (inputValue.length >= 2) {
      fetchStats({ variables: { query: inputValue } });
    }
  };

  const onValueChange = (filterItems: CustomerOption[]) => {
    setStatNumbersAndIndexes(
      filterItems.map((fi) => (fi as CustomerOption)?.data).filter(isDefined)
    );
  };

  useEffect(() => {
    if (data) {
      const transformedData =
        data?.productPrice.customers.byStatCustomerNumberOrIndex.map(
          (customer) => transformQueryResultToFilterEntity(customer)
        ) ?? [];
      setFilteredOptions(transformedData);
    }
  }, [data, setFilteredOptions]);

  return (
    <FppSearchFilterSection
      sectionId={"statNumbersAndIndexes"}
      label={label}
      enabled={enabled}
      numSelected={statNumbersAndIndexes.length}
      leftIcon={stenaUserMultiple}
      error={error?.message}
      onRetry={fetchStats}
      children={
        <ChipMultiSelect
          options={selectOptions}
          loading={loading}
          value={values}
          inputValue={inputText}
          onInputChange={onInputChange}
          onValueChange={(customers) => onValueChange(customers)}
        />
      }
    />
  );
};
