import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsListFragment } from "../../../common/fragments/ListFragment";
import {
  UseSalesRegionDetailsSummaryQuery,
  UseSalesRegionDetailsSummaryQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseSalesRegionDetailsSummary($salesRegionId: ID!, $year: Int!) {
    statistics {
      salesRegionDetails {
        summary(salesRegionId: $salesRegionId, year: $year) {
          ...statisticsListFragment
        }
      }
    }
  }
  ${statisticsListFragment}
`;

export const useSalesRegionDetailsSummaryQuery = (
  salesRegionId: string,
  year: number
) => {
  const { data, loading, error } = useQuery<
    UseSalesRegionDetailsSummaryQuery,
    UseSalesRegionDetailsSummaryQueryVariables
  >(query, { variables: { salesRegionId, year }, fetchPolicy: "network-only" });

  return {
    error,
    loading,
    summary: data?.statistics.salesRegionDetails.summary,
  };
};
