import { StandardTableConfig, createColumnConfig } from "@stenajs-webui/grid";
import { ChildSelectorTableColumnKeys } from "./RowAndColumnConfig";
import { ChildCustomersByStatusFragment } from "@/gql/graphql";

export const ChildSelectorTableConfig: StandardTableConfig<
  ChildCustomersByStatusFragment,
  ChildSelectorTableColumnKeys
> = {
  disableInfiniteList: true,
  keyResolver: (item) => String(item.id),
  showRowCheckbox: true,
  showHeaderCheckbox: true,
  columns: {
    id: createColumnConfig((item) => item.id, {}),
    custNo: createColumnConfig((item) => item.custNo, {
      columnLabel: "Account no.",
    }),
    custIndex: createColumnConfig((item) => item.custIndex, {
      minWidth: "200px",
    }),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
      minWidth: "200px",
    }),
    ownAgreement: createColumnConfig(
      (item) => (item.activeHeadAgreementByYear?.id ? "Y" : "N"),
      {
        columnLabel: "Own agr.",
      }
    ),
    contactEmail: createColumnConfig(
      (item) =>
        item.subscriberContactsForReport.length > 0
          ? item.subscriberContactsForReport[0].email
          : "",
      {
        columnLabel: "Contact e-mail",
      }
    ),
    docExists: createColumnConfig(
      (item) => (item.hasDocumentByInput ? "Y" : "N"),
      {
        columnLabel: "Doc exists",
      }
    ),
  },
  columnOrder: [
    "custNo",
    "custIndex",
    "name",
    "ownAgreement",
    "contactEmail",
    "docExists",
  ],
};
