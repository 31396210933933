import * as React from "react";
import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import { Select, SelectProps } from "@stenajs-webui/select";
import { VehicleTypeWithAllSelectQuery } from "@/gql/graphql";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string | null;
  onValueChange?: (value: string | null | undefined) => void;
}

const query = gql`
  query VehicleTypeWithAllSelect {
    productPrice {
      vehicleType {
        all {
          id
          shortDescription
          code
        }
      }
    }
  }
`;

export const VehicleTypeWithAllSelect: React.FC<Props> = ({
  value,
  onValueChange,
  ...selectProps
}) => {
  const { data, loading } = useQuery<VehicleTypeWithAllSelectQuery>(query);
  const all = useMemo(() => {
    return data?.productPrice.vehicleType.all ?? [];
  }, [data]);

  const vehicleTypeOptions = useMemo(
    () =>
      all.map((s) => ({
        label: s.shortDescription,
        value: s.id,
      })),
    [all]
  );

  const options = useMemo(() => {
    return [
      {
        label: "All",
        options: [{ label: "All vehicle types", value: null }],
      },
      { label: "Vehicle types", options: vehicleTypeOptions },
    ];
  }, [vehicleTypeOptions]);

  const selected = useMemo(() => {
    if (value === null) {
      return options[0].options[0];
    } else {
      return vehicleTypeOptions.find((o) => o.value === value);
    }
  }, [options, value, vehicleTypeOptions]);

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selected as any}
      onChange={(v: any) => onValueChange?.(v.value)}
      {...selectProps}
    />
  );
};
