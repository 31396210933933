import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { statisticsDetailsFragment } from "../../../common/fragments/DetailsFragment";
import {
  NegotiationStatusCode,
  UseSalesRegionDetailsVolumeAndRatesQuery,
  UseSalesRegionDetailsVolumeAndRatesQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query UseSalesRegionDetailsVolumeAndRates(
    $salesRegionId: ID!
    $currencyCode: String!
    $negotiationStatuses: [NegotiationStatusCode!]!
    $year: Int!
  ) {
    statistics {
      salesRegionDetails {
        volumeAndRates(
          salesRegionId: $salesRegionId
          currencyCode: $currencyCode
          negotiationStatusCodes: $negotiationStatuses
          year: $year
        ) {
          ...statisticsDetailsFragment
        }
        threeYearsAverageMeterPrices(
          salesRegionId: $salesRegionId
          currencyCode: $currencyCode
          year: $year
          negotiationStatusCodes: $negotiationStatuses
        ) {
          averageMeterPriceRequestedYear
          averageMeterPriceRequestedYearMinus1
          averageMeterPriceRequestedYearMinus2
          averageMeterPriceDiffInPercentFromPreviousYear
          averageMeterPriceDiffInPercentFromPreviousYearMinus1
          averageMeterPriceDiffInPercentFromPreviousYearMinus2
        }
      }
    }
  }
  ${statisticsDetailsFragment}
`;

export const useSalesRegionDetailsVolumeAndRatesQuery = (
  salesRegionId: string,
  currencyCode: string,
  negotiationStatusCodes: Array<NegotiationStatusCode>,
  year: number
) => {
  const { data, error, loading } = useQuery<
    UseSalesRegionDetailsVolumeAndRatesQuery,
    UseSalesRegionDetailsVolumeAndRatesQueryVariables
  >(query, {
    variables: {
      salesRegionId,
      currencyCode,
      negotiationStatuses: negotiationStatusCodes,
      year,
    },
    fetchPolicy: "network-only",
  });

  return {
    error,
    loading,
    volumeAndRates: data?.statistics.salesRegionDetails.volumeAndRates,
    averageMeterPrices:
      data?.statistics.salesRegionDetails.threeYearsAverageMeterPrices,
  };
};
