import { AncillarySelect } from "@/common/components/data-driven-inputs/selects/ancillary-select/AncillarySelect";
import { GoodsSelect } from "@/common/components/data-driven-inputs/selects/goods-select/GoodsSelect";
import { NumericTextInputNoScroll } from "@/common/components/forms/NumericTextInputNoScroll";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { FormModelChangeHandler } from "@/common/forms/types";
import { IndentValues } from "@/common/utils/IndentValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { PriceCalculatorFormData } from "@/features/price-calculator/PriceCalculatorFormFetcher";
import { Box, Column, Indent, Row, Spacing } from "@stenajs-webui/core";
import {
  FlatButton,
  Label,
  SecondaryButton,
  stenaMinus,
  stenaPlusCircle,
} from "@stenajs-webui/elements";
import { Tooltip } from "@stenajs-webui/tooltip";
import * as React from "react";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}
export const GoodsAndAncillary: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const isGoodsWeightEmpty = (index: number) => {
    // Since it is acceptable to send goods with 0 weight we do not a display warning
    // when weight is 0. Therefor we do the !isZero check.
    const isGoodsWeightZero =
      Number(formData?.conditionsGoods?.[index]?.weight) === 0;
    return !formData?.conditionsGoods?.[index]?.weight && !isGoodsWeightZero;
  };

  const isAncillaryQuantityEmpty = (index: number) => {
    return !formData?.conditionsAncillary?.[index]?.quantity;
  };
  return (
    <Indent num={IndentValues.THIRTYTWO}>
      <Row>
        <Label text={"Goods code "} width={200} />
        <Indent num={1.5} />
        <Label text={"Weight (kg)"} width={200} />
      </Row>
      {formData?.conditionsGoods?.map((goods, index) => (
        <Box key={index}>
          <Row>
            <Box width={200}>
              <GoodsSelect
                value={formData?.conditionsGoods?.[index].goodsCode}
                onValueChange={(value) => {
                  const mergedConditionsGoods = formData.conditionsGoods;
                  if (mergedConditionsGoods) {
                    mergedConditionsGoods[index] = {
                      ...mergedConditionsGoods[index],
                      goodsCode: value,
                    };
                    onChangeFormData("conditionsGoods", mergedConditionsGoods);
                  }
                }}
                menuPortalTarget={document.body}
              />
            </Box>
            <Indent num={1.5} />
            <Box width={200}>
              <Tooltip
                label={
                  isGoodsWeightEmpty(index) ? "Please provide a weight" : ""
                }
                zIndex={ZIndex.lowest}
                variant="warning"
                disabled={!isGoodsWeightEmpty(index)}
              >
                <NumericAgrVehicleWeightTextInput
                  value={formData?.conditionsGoods?.[index]?.weight}
                  onValueChange={(value) => {
                    const mergedConditionsGoods = formData.conditionsGoods;
                    if (mergedConditionsGoods) {
                      mergedConditionsGoods[index] = {
                        ...mergedConditionsGoods[index],
                        weight: value,
                      };
                      onChangeFormData(
                        "conditionsGoods",
                        mergedConditionsGoods
                      );
                    }
                  }}
                  variant={isGoodsWeightEmpty(index) ? "warning" : "standard"}
                />
              </Tooltip>
            </Box>

            <Indent num={1.5} />
            <Box>
              <FlatButton
                leftIcon={stenaMinus}
                onClick={() =>
                  onChangeFormData("conditionsGoods", [
                    ...(formData?.conditionsGoods || []).filter(
                      (item, formIndex) => index !== formIndex
                    ),
                  ])
                }
              />
            </Box>
          </Row>
          <Spacing />
        </Box>
      ))}
      <Indent />
      <Spacing num={0.5} />
      <Column width={200}>
        <SecondaryButton
          label={"Add goods"}
          leftIcon={stenaPlusCircle}
          onClick={() => {
            onChangeFormData("conditionsGoods", [
              ...(formData?.conditionsGoods || []),
              { goodsCode: "", weight: "0" },
            ]);
          }}
          size={"medium"}
        />
      </Column>

      <Spacing num={1.5} />

      <Row>
        <Label text={"Ancillary code "} width={200} />
        <Indent num={1.5} />
        <Label text={"Quantity"} width={200} />
      </Row>

      {formData?.conditionsAncillary?.map((goods, index) => (
        <Box key={index}>
          <Row>
            <Box width={200}>
              <AncillarySelect
                value={formData?.conditionsAncillary?.[index].ancillaryCode}
                onValueChange={(value) => {
                  const mergedAncillaries = formData.conditionsAncillary;
                  if (mergedAncillaries) {
                    mergedAncillaries[index] = {
                      ...mergedAncillaries[index],
                      ancillaryCode: value,
                    };
                    onChangeFormData("conditionsAncillary", mergedAncillaries);
                  }
                }}
                menuPortalTarget={document.body}
              />
            </Box>

            <Indent num={1.5} />
            <Box width={200}>
              <Tooltip
                label={
                  isAncillaryQuantityEmpty(index)
                    ? "Please provide a quantity above 0"
                    : ""
                }
                zIndex={ZIndex.lowest}
                variant="warning"
                disabled={!isAncillaryQuantityEmpty(index)}
              >
                <NumericTextInputNoScroll
                  value={formData?.conditionsAncillary?.[
                    index
                  ]?.quantity.toString()}
                  onValueChange={(value) => {
                    const mergedAncillaries = formData.conditionsAncillary;
                    if (mergedAncillaries) {
                      mergedAncillaries[index] = {
                        ...mergedAncillaries[index],
                        quantity: parseInt(value),
                      };
                      onChangeFormData(
                        "conditionsAncillary",
                        mergedAncillaries
                      );
                    }
                  }}
                  min={1}
                  hideButtons
                  variant={
                    isAncillaryQuantityEmpty(index) ? "warning" : "standard"
                  }
                />
              </Tooltip>
            </Box>
            <Indent num={1.5} />
            <Box>
              <FlatButton
                leftIcon={stenaMinus}
                onClick={() =>
                  onChangeFormData("conditionsAncillary", [
                    ...(formData?.conditionsAncillary || []).filter(
                      (item, formIndex) => index !== formIndex
                    ),
                  ])
                }
              />
            </Box>
          </Row>
          <Spacing />
        </Box>
      ))}
      <Indent />
      <Spacing num={0.5} />

      <Column width={200}>
        <SecondaryButton
          label={"Add ancillary"}
          leftIcon={stenaPlusCircle}
          onClick={() => {
            onChangeFormData("conditionsAncillary", [
              ...(formData?.conditionsAncillary || []),
              { ancillaryCode: "", quantity: 1 },
            ]);
          }}
          size={"medium"}
        />
      </Column>
    </Indent>
  );
};
