import * as React from "react";
import { useMemo } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import {
  AccUnaccCode,
  accUnaccLabels,
  allAccUnaccCodes,
} from "../../../../transformers/UnaccompaniedTransformer";
import { CSSObject } from "@emotion/react";
import { ZIndex } from "@/common/utils/ZIndexEnum";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: AccUnaccCode;
  onValueChange?: (value: AccUnaccCode) => void;
}

const options = allAccUnaccCodes.map((s) => ({
  label: accUnaccLabels[s],
  value: s,
}));

export const AccUnaccSelect: React.FC<Props> = ({
  value,
  onValueChange,
  ...selectProps
}) => {
  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base: CSSObject) => ({
          ...base,
          zIndex: ZIndex.highest,
        }),
      }}
      {...selectProps}
      options={options}
      value={selected}
      onChange={(item: any) =>
        onValueChange &&
        onValueChange(allAccUnaccCodes.find((u) => u === item.value)!)
      }
    />
  );
};
