import { TextInputProps } from "@stenajs-webui/forms";
import React from "react";
import { NumericTextInput } from "../numeric-text-input/NumericTextInput";

interface Props extends TextInputProps {
  dataTestid?: string;
}

export const NumericAgrVehicleLengthTextInput: React.FC<Props> = ({
  value,
  variant,
  onValueChange,
  dataTestid,
}) => {
  return (
    <NumericTextInput
      value={value}
      maxNumbers={3}
      maxDecimals={1}
      onValueChange={onValueChange}
      variant={variant}
      dataTestid={dataTestid}
    />
  );
};
