import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { gql, useQuery } from "@apollo/client";
import { SeparatorLine, Spacing } from "@stenajs-webui/core";
import { Select, SelectProps } from "@stenajs-webui/select";
import { first, orderBy } from "lodash";
import * as React from "react";
import { useLayoutEffect, useMemo } from "react";
import { components, OptionProps } from "react-select";
import { useLatest } from "../../../../hooks/UseLatest";
import {
  AllHeadAgreementsFragment,
  HeadAgreementByCustomerIdSelectQuery,
  HeadAgreementByCustomerIdSelectQueryVariables,
} from "@/gql/graphql";

const Option = (options: OptionProps) => {
  const headAgreementOptions = options.data as HeadAgreementOptions;

  return (
    <>
      {headAgreementOptions.id === "new-HA" ? (
        <>
          <components.Option {...options}>{options.children}</components.Option>
          <SeparatorLine />
        </>
      ) : (
        <components.Option {...options}>{options.children}</components.Option>
      )}
    </>
  );
};

const formatOptionLabel = (options: HeadAgreementOptions) =>
  options.id === "new-HA" ? (
    <>
      <Spacing />
      {options.label}
      <Spacing num={0.5} />
    </>
  ) : (
    options.label
  );

export interface HeadAgreementOptions {
  label: string;
  value: string;
  id: number | string;
  data?: AllHeadAgreementsFragment;
}

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  customerId?: string;
  createNewHeadAgreementOption?: boolean;
  setCreatNewHeadAgreementView?: () => void;
  onValueChange?: (value: AllHeadAgreementsFragment) => void;
}

const query = gql`
  fragment AllHeadAgreements on HeadAgreement {
    id
    headAgreementNumber
    statusCode
    name
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
  }
  query HeadAgreementByCustomerIdSelect($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          allHeadAgreements {
            ...AllHeadAgreements
          }
        }
      }
    }
  }
`;

export const HeadAgreementByCustomerIdSelect: React.FC<Props> = ({
  onValueChange,
  setCreatNewHeadAgreementView,
  value,
  createNewHeadAgreementOption,
  customerId,
  ...selectProps
}) => {
  const { data, loading } = useQuery<
    HeadAgreementByCustomerIdSelectQuery,
    HeadAgreementByCustomerIdSelectQueryVariables
  >(query, {
    variables: { customerId: customerId ?? "" },
    skip: !customerId,
  });

  const onChangeCallback = useLatest(onValueChange);

  const headAgreements = useMemo(() => {
    return data?.productPrice.customers.byId?.allHeadAgreements ?? [];
  }, [data]);

  const options = useMemo(() => {
    const sortedHa = orderBy(
      headAgreements,
      [(ha) => ha.valid.end?.isoString],
      ["desc"]
    ).map(
      (ha, index): HeadAgreementOptions => ({
        label: `${formatGQLDateRange(ha.valid)} — ${ha.name} — ${
          ha.statusCode
        }`,
        value: ha.id,
        data: ha,
        id: index,
      })
    );
    createNewHeadAgreementOption &&
      sortedHa.unshift({
        label: "Create a new header agreement",
        value: "Create a new header agreement",
        id: "new-HA",
      });

    return sortedHa;
  }, [headAgreements, createNewHeadAgreementOption]);

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  useLayoutEffect(() => {
    if (!selected) {
      const value = first(options)?.data;
      if (value && onChangeCallback.current) {
        onChangeCallback.current(value);
      }
    }
  }, [options, selected, onChangeCallback]);

  return (
    <Select
      isLoading={loading}
      isDisabled={loading}
      options={options}
      formatOptionLabel={(options: HeadAgreementOptions) =>
        formatOptionLabel(options)
      }
      components={{ Option }}
      placeholder={""}
      value={selected ?? ""}
      onChange={(v: any) =>
        setCreatNewHeadAgreementView &&
        createNewHeadAgreementOption &&
        v.id === "new-HA"
          ? setCreatNewHeadAgreementView()
          : onValueChange?.(v.data)
      }
      {...selectProps}
    />
  );
};
