import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";

import {
  ChangeMultiLegAgreementStatusMutation,
  ChangeMultiLegAgreementStatusMutationVariables,
} from "@/gql/graphql";
import { errorFragment } from "@/common/error/GqlErrorFragment";

const query = gql`
  ${errorFragment}
  mutation ChangeMultiLegAgreementStatus(
    $changeMultiLegRouteAgreementStatusInput: ChangeMultiLegRouteAgreementStatusInput!
  ) {
    productPrice {
      multiLegRouteAgreement {
        changeMultiLegRouteAgreementStatus(
          input: $changeMultiLegRouteAgreementStatusInput
        ) {
          ... on ErrorResult {
            ...ErrorFragment
          }
          ... on UpdateMultiLegRouteAgreementSuccessResult {
            multiLegAgreement {
              id
              statusCode
              rowVersion
            }
          }
        }
      }
    }
  }
`;

export const useChangeMultiLegAgreementStatusMutation = () =>
  useMutation<
    ChangeMultiLegAgreementStatusMutation,
    ChangeMultiLegAgreementStatusMutationVariables
  >(query);
